import { put } from 'redux-saga/effects'

import actions from '../actions/chatNotification'
import { generateSagas } from './utils'

// No api use yet
const api = {}

const chat = [
  [
    actions.read,
    function* (_) {
      yield put(actions.read.success())
      return
    },
  ],
  [
    actions.create,
    function* (_) {
      yield put(actions.create.success())
      return
    },
  ],

  [
    actions.update,
    function* (_) {
      yield put(actions.update.success())
    },
  ],

  [
    actions.delete,
    function* (_) {
      yield put(actions.delete.success())
    },
  ],
]

export default generateSagas([...chat])
