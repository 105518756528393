import { str } from './utils'

const gameOfflineTransformer = (actions, actionType, requestPayload) => {
  let type = '',
    payload = {}

  switch (
    actionType // eslint-disable-line default-case
  ) {
    case str(actions.updateGameRoster.request): {
      type = str(actions.updateGameRoster.success)
      payload = {
        id: requestPayload.id,
        rosterId: requestPayload.rosterId,
        data: {
          id: requestPayload.rosterId,
          team_id: requestPayload.form.team_id,
          game_id: requestPayload.id,
          players: requestPayload.form.fullPlayersList.map((player) => {
            const updatedPlayer =
              requestPayload.form.players.find(
                (p) => p.player_id === player.id
              ) || {}
            return {
              // API returns an id key, not player_id
              ...player,
              ...updatedPlayer,
            }
          }),
        },
      }
      break
    }

    case str(actions.updateGameRosterPlayer.request): {
      type = str(actions.updateGameRosterPlayer.success)
      payload = {
        id: requestPayload.id,
        rosterId: requestPayload.rosterId,
        playerId: requestPayload.playerId,
        data: {
          id: requestPayload.playerId,
          ...requestPayload.form,
        },
      }
      break
    }

    case str(actions.createGameRosterPlayer.request): {
      type = str(actions.createGameRosterPlayer.success)
      payload = {
        id: requestPayload.id,
        rosterId: requestPayload.rosterId,
        playerId: requestPayload.playerId,
        data: {
          id: requestPayload.playerId,
          ...requestPayload.form,
        },
      }
      break
    }
  }

  return { type, payload }
}

export default gameOfflineTransformer
