import { useCallback, useEffect, useState } from 'react'
import req from '@sportninja/common/api/request'
import dayjs from 'dayjs'

export const useGetSuspensionsByPlayer = () => {
  const [playerIds, setPlayerIds] = useState([])
  const [finishLoadingPlayerIds, setFinishLoadingPlayerIds] = useState(false)
  const [loading, setLoading] = useState(true)
  const [suspensions, setSuspensions] = useState([])
  const [isSuspended, setIsSuspended] = useState(false)

  useEffect(() => {
    req('/users/me/players').then((response) => {
      const playerIds = response.data.reduce((collector, p) => {
        collector[p.player_id] = p.player_id
        return collector
      }, {})
      setPlayerIds(Object.keys(playerIds))
      setFinishLoadingPlayerIds(true)
    })
  }, [])

  const refresh = useCallback(async () => {
    if (playerIds?.length > 0) {
      setLoading(true)
      try {
        const promises = []
        playerIds.forEach((playerId) => {
          if (!playerId) {
            return
          }
          promises.push(req(`/suspensions/player/${playerId}`))
        })
        const arrayOfSuspensions = await Promise.all(promises)

        const condensed = arrayOfSuspensions
          .reduce((collector, { data }) => {
            collector.push(...data)
            return collector
          }, [])
          .sort((a, b) => {
            const dateA = dayjs(a.end_date)
            const dateB = dayjs(b.end_date)
            if (dateA.isBefore(dateB)) {
              return -1
            } else if (dateA.isAfter(dateB)) {
              return 1
            } else {
              return 0
            }
          })

        setSuspensions(condensed)
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
      }
    } else {
      finishLoadingPlayerIds && setLoading(false)
    }
  }, [playerIds])

  useEffect(() => {
    refresh()
  }, [refresh])

  return { loading, suspensions, refresh, isSuspended }
}

const useGetSuspensions = (scheduleId, routeName = 'suspensions') => {
  const [loading, setLoading] = useState(true)
  const [suspensions, setSuspensions] = useState([])

  const refresh = useCallback(async () => {
    if (scheduleId) {
      setLoading(true)
      try {
        const response = await req(`/suspensions/schedule/${scheduleId}`)
        setSuspensions(response.data)
        setLoading(false)
      } finally {
        setLoading(false)
      }
    }
  }, [scheduleId])

  useEffect(() => {
    if (window.location.pathname.includes(routeName)) {
      refresh()
    }
  }, [refresh, window.location.pathname])

  return { loading, suspensions, refresh }
}

export default useGetSuspensions
