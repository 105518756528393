/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useEffect, useState } from 'react'
import req from '@sportninja/common/api/request'
import dayjs from 'dayjs'

import { Flex } from '../Layout'
import Icon from '../Icon'
import LoadingSpinner from '../LoadingSpinner'

const processDuration = (rawDuration) => {
  const d =
    typeof rawDuration === 'string' ? parseInt(rawDuration, 10) : rawDuration

  const inMinutes = Math.floor(d / 60)
  const remainingSeconds = Math.round(d % 60)

  let min = inMinutes,
    sec = remainingSeconds
  if (inMinutes === 0) min = '00'
  else if (inMinutes < 10) min = `0${min}`

  if (remainingSeconds === 0) sec = '00'
  if (remainingSeconds < 10) sec = `0${sec}`

  return [min, sec].join(':')
}

const Highlights = ({ requestIds, requestUrl }) => {
  const [loaded, setLoaded] = useState(false)
  const [highlights, setHighlights] = useState([])
  const [selectedHighlight, setSelectedHighlight] = useState({})

  useEffect(() => {
    setLoaded(false)

    if (requestIds?.length > 0) {
      const promises = []
      requestIds.forEach((id) => {
        promises.push(req(`/players/${id}/highlights`))
      })

      Promise.all(promises).then((items) => {
        if (items.length > 0) {
          const results = items.reduce(
            (collector, { data: listOfHighlights }) => {
              return collector.concat(listOfHighlights)
            },
            []
          )
          setHighlights(results)
          setSelectedHighlight(results[0])
          setLoaded(true)
        }
      })
    } else if (requestUrl) {
      req(`/${requestUrl}/highlights`).then(({ data: results }) => {
        if (results.length > 0) {
          setHighlights(results)
          setSelectedHighlight(results[0])
        }
        setLoaded(true)
      })
    } else {
      setLoaded(true)
    }
  }, [requestIds, requestUrl])

  if (highlights.length === 0) {
    return (
      <Flex
        alignItems='center'
        justifyContent='center'
        css={css`
          min-height: 98px;
          margin-top: 24px;
          padding: 20px 16px;
          background-color: #27303e;
          border: 1px solid #3f4753;
          border-radius: 8px;
        `}
      >
        {!loaded ? (
          <LoadingSpinner size={4} />
        ) : (
          'There are no highlights available'
        )}
      </Flex>
    )
  }

  return (
    <div
      css={css`
        margin: 24px 0 40px;
      `}
    >
      <div
        css={css`
          margin-bottom: 20px;
          position: relative;
        `}
      >
        {selectedHighlight?.video_url && (
          <video
            key={selectedHighlight.video_url}
            controls
            css={css`
              width: 100%;
              border-radius: 8px;
            `}
            poster={selectedHighlight.thumbnail_url}
          >
            <source src={selectedHighlight.video_url} type='video/mp4' />
            Sorry, your browser doesn&apos;t support embedded videos.
          </video>
        )}
        {selectedHighlight?.title && (
          <div
            style={{
              padding: '8px 12px',
              borderRadius: 4,
              position: 'absolute',
              top: 20,
              right: 20,
              fontWeight: 'bold',
              fontSize: 20,
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
            }}
          >
            {selectedHighlight?.title}
          </div>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
        }}
      >
        <div style={{ overflow: 'auto' }}>
          <ul style={{ display: 'flex', flexDirection: 'row' }}>
            {highlights.map((i) => {
              const isSelected = i.id === selectedHighlight.id

              return (
                <li
                  key={i.id}
                  onClick={() => {
                    if (!isSelected) setSelectedHighlight(i)
                  }}
                  style={{
                    cursor: 'pointer',
                    marginRight: 12,
                  }}
                >
                  <div
                    css={css`
                      position: relative;
                      height: 110px;
                      border: 2px solid transparent;
                      border-radius: 8px;

                      &:hover {
                        border-color: white;
                      }
                    `}
                  >
                    <img
                      src={i.thumbnail_url}
                      height='100%'
                      style={{ borderRadius: 8 }}
                    />
                    <div
                      style={{
                        fontSize: 13,
                        letterSpacing: 0.5,
                        position: 'absolute',
                        bottom: 4,
                        left: 4,
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        padding: '2px 4px',
                        borderRadius: 3,
                      }}
                    >
                      {processDuration(i.video_duration)}
                    </div>
                    {isSelected && (
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: 'rgba(0, 0, 0, 0.7)',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 3,
                        }}
                      >
                        <Icon name='play' color='white' fontSize={40} />
                      </div>
                    )}
                  </div>
                  <div
                    css={css`
                      margin: 8px 4px 4px;
                      font-weight: bold;
                    `}
                  >
                    {i.title}
                  </div>
                  <div style={{ margin: 4, fontSize: 12, opacity: 0.75 }}>
                    {dayjs(i.created_at).format('ddd, MMM D')}
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
        <div
          css={css`
            position: absolute;
            content: '';
            height: 100%;
            min-width: 100px;
            background: linear-gradient(
              to left,
              rgba(25, 26, 30, 1) 0%,
              rgba(25, 26, 30, 0.7) 50%,
              rgba(25, 26, 30, 0) 100%
            );
            right: 0;
          `}
        />
      </div>
    </div>
  )
}

export default Highlights
