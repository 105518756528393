import React from 'react'
import PropTypes from 'prop-types'
// import { withRouter } from 'react-router-dom'

class ScrollToTop extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    // location: PropTypes.shape({
    //   pathname: PropTypes.string.isRequired
    // }).isRequired
  }

  // This doesn't do anything on mobile --- disable it until we have time to fix
  // componentDidUpdate(prevProps) {
  //   if (this.props.location.pathname !== prevProps.location.pathname) {
  //     window.scrollTo(0, 0)
  //   }
  // }

  render() {
    return this.props.children
  }
}

export default ScrollToTop
// export default withRouter(ScrollToTop)
