import React from 'react'
import { t } from '@sportninja/common/i18n'

import { SelectFormInput } from './Select'

const PeriodPicker = ({ isSoccer = false, periods, ...formProps }) => {
  return (
    <SelectFormInput {...formProps}>
      <option value=''>
        {isSoccer ? 'Select a half' : t('Web:selectAPeriod')}:
      </option>
      <option key='line' disabled>
        -----
      </option>
      {periods &&
        periods.map((period) => {
          return (
            <option key={period.id} value={period.id}>
              {period.period_type.name}
            </option>
          )
        })}
    </SelectFormInput>
  )
}

export default PeriodPicker
