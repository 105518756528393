/** @jsxImportSource @emotion/react */
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { Alert, Select, Space, Spin, Radio, Tabs, Progress } from 'antd'
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Button as SNButton } from 'src/components/Button'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { CopyOutlined } from '@ant-design/icons'
import {
  heading3,
  paragraphMedium,
  paragraphSmall,
  paragraphXSmall,
  weightRegular,
  weightSemiBold,
} from 'src/components/css'
import PlayerConflictItem from '../Conflicts/PlayerConflict/index'
import TeamConflictItem from '../Conflicts/TeamConflict'
import LocationConflictItem from '../Conflicts/LocationConflict'
import TeamOfficialConflictItem from '../Conflicts/TeamOfficialConflict'
import { set } from 'mobx'

const hasConflicts = (conflicts) => Object.keys(conflicts).length > 0

const generateResolutions = (conflicts, uidKey) => {
  return Object.entries(conflicts).map(([daysmart_id, uid]) => {
    let resolution_action = 'match'
    if (uid === '998') {
      resolution_action = 'new'
      uid = undefined
    } else if (uid === '999') {
      resolution_action = 'skip'
      uid = undefined
    }
    return {
      daysmart_id,
      ...(uid !== undefined && { [uidKey]: uid }),
      resolution_action,
    }
  })
}

const ConflictsForm = ({
  daySmartImportModalRef,
  readItem,
  readyEntityCount,
  account,
  ORG_ID,
  conflicts,
  selectedDaySmartSeason,
  sportId,
  genericSportId,
  daySmartSendInvitation = false,
}) => {
  const { facilities, players, team_officials, teams } = conflicts
  const [progress, setProgress] = useState(0)
  const [daySmartError, setDaySmartError] = useState(null)
  const [isSyncingDaySmart, setIsSyncingDaySmart] = useState(false)
  const [daySmartSuccess, setDaySmartSuccess] = useState(false)
  const [selectedPlayerConflicts, setSelectedPlayerConflicts] = useState({})

  useEffect(() => {
    try {
      if (players && players.data && players.data.length > 0) {
        // handlePlayerRadioChange: (daysmartId: number, value: string) => void
        for (const player of players.data) {
          let maxConflict = null
          for (const conflict of player.player_conflicts) {
            if (
              conflict.team_details &&
              (!maxConflict ||
                conflict.team_details.length > maxConflict.team_details.length)
            ) {
              maxConflict = conflict
            }
          }
          if (maxConflict) {
            handlePlayerRadioChange(player.daysmart_id, maxConflict.uid)
          }
        }
      }
    } catch (e) {
      console.error(e)
    }
  }, [])

  const handlePlayerRadioChange = (playerId, conflictUid) => {
    setSelectedPlayerConflicts((prevState) => ({
      ...prevState,
      [playerId]: conflictUid,
    }))
  }
  const hasSelectedAllPlayerConflicts = useMemo(() => {
    return players?.data?.every((player) => {
      const playerId = player.daysmart_id
      return selectedPlayerConflicts[playerId] !== undefined
    })
  }, [players.data, selectedPlayerConflicts])
  const setAllPlayerConflictsToCreate = () => {
    const newSelectedPlayerConflicts = {}
    for (const player of players.data) {
      newSelectedPlayerConflicts[player.daysmart_id] = '998'
    }
    setSelectedPlayerConflicts(newSelectedPlayerConflicts)
  }
  const setAllPlayerConflictsToIgnore = () => {
    const newSelectedPlayerConflicts = {}
    for (const player of players.data) {
      newSelectedPlayerConflicts[player.daysmart_id] = '999'
    }
    setSelectedPlayerConflicts(newSelectedPlayerConflicts)
  }
  const setAllTeamConflictsToCreate = () => {
    const newSelectedTeamConflicts = {}
    for (const team of teams.data) {
      newSelectedTeamConflicts[team.daysmart_id] = '998'
    }
    setSelectedTeamConflicts(newSelectedTeamConflicts)
  }
  const setAllTeamConflictsToIgnore = () => {
    const newSelectedTeamConflicts = {}
    for (const team of teams.data) {
      newSelectedTeamConflicts[team.daysmart_id] = '999'
    }
    setSelectedTeamConflicts(newSelectedTeamConflicts)
  }
  const setAllLocationConflictsToCreate = () => {
    const newSelectedLocationConflicts = {}
    for (const location of facilities.data) {
      newSelectedLocationConflicts[location.daysmart_id] = '998'
    }
    setSelectedLocationConflicts(newSelectedLocationConflicts)
  }
  const setAllLocationConflictsToIgnore = () => {
    const newSelectedLocationConflicts = {}
    for (const location of facilities.data) {
      newSelectedLocationConflicts[location.daysmart_id] = '999'
    }
    setSelectedLocationConflicts(newSelectedLocationConflicts)
  }
  const [selectedTeamConflicts, setSelectedTeamConflicts] = useState({})
  const handleTeamRadioChange = (teamId, conflictUid) => {
    setSelectedTeamConflicts((prevState) => ({
      ...prevState,
      [teamId]: conflictUid,
    }))
  }
  const hasSelectedAllTeamConflicts = useMemo(() => {
    return teams?.data?.every((team) => {
      const teamId = team.daysmart_id
      return selectedTeamConflicts[teamId] !== undefined
    })
  }, [teams.data, selectedTeamConflicts])
  const [selectedLocationConflicts, setSelectedLocationConflicts] = useState({})
  const handleLocationRadioChange = (locationId, conflictUid) => {
    setSelectedLocationConflicts((prevState) => ({
      ...prevState,
      [locationId]: conflictUid,
    }))
  }
  const hasSelectedAllLocationConflicts = useMemo(() => {
    return facilities?.data?.every((location) => {
      const locationId = location.daysmart_id
      return selectedLocationConflicts[locationId] !== undefined
    })
  }, [facilities.data, selectedLocationConflicts])

  // Team Official
  const [selectedTeamOfficialConflicts, setSelectedTeamOfficialConflicts] =
    useState({})
  const handleTeamOfficialRadioChange = (teamOfficialId, conflictUid) => {
    setSelectedTeamOfficialConflicts((prevState) => ({
      ...prevState,
      [teamOfficialId]: conflictUid,
    }))
  }
  const setAllTeamOfficialsConflictsToCreate = () => {
    const newSelectedConflicts = {}
    for (const team_official of team_officials.data) {
      newSelectedConflicts[team_official.daysmart_id] = '998'
    }
    selectedTeamOfficialConflicts(newSelectedConflicts)
  }
  const setAllTeamOfficialsConflictsToIgnore = () => {
    const newSelectedConflicts = {}
    for (const team_officials of team_officials.data) {
      newSelectedConflicts[team_officials.daysmart_id] = '999'
    }
    selectedTeamOfficialConflicts(newSelectedConflicts)
  }
  const hasSelectedAllTeamOfficialsConflicts = useMemo(() => {
    return team_officials?.data?.every((team_official) => {
      const teamOfficialId = team_official.daysmart_id
      return selectedTeamOfficialConflicts[teamOfficialId] !== undefined
    })
  }, [team_officials.data, selectedTeamOfficialConflicts])

  const fakeProgress = (targetProgress) => {
    let currentProgress = progress
    const interval = setInterval(() => {
      currentProgress += 5
      setProgress(currentProgress)

      if (currentProgress >= targetProgress) {
        clearInterval(interval)
      }
    }, 1000)
  }

  const onSyncDaySmart = async () => {
    try {
      setProgress(0)
      fakeProgress(100)
      setDaySmartError(null)
      setIsSyncingDaySmart(true)
      // const options = [
      //   hasConflicts(selectedPlayerConflicts) ? 'players' : null,
      //   hasConflicts(selectedTeamConflicts) ? 'teams' : null,
      //   hasConflicts(selectedLocationConflicts) ? 'facilities' : null,
      // ].filter(Boolean)
      const options = [
        'schedule',
        'players',
        'addresses',
        'games',
        'teams',
        'team_officials',
      ]
      if (daySmartSendInvitation) {
        options.push('invite_players_team_officials')
      }
      const playerConflictsResolutions = generateResolutions(
        selectedPlayerConflicts,
        'player_uid'
      )
      const teamConflictsResolutions = generateResolutions(
        selectedTeamConflicts,
        'team_uid'
      )
      const locationConflictsResolutions = generateResolutions(
        selectedLocationConflicts,
        'facility_uid'
      )
      const teamOfficialConflictsResolutions = generateResolutions(
        selectedTeamOfficialConflicts,
        'team_official_uid'
      )
      const resolutions = [
        ...playerConflictsResolutions,
        ...teamConflictsResolutions,
        ...locationConflictsResolutions,
        ...teamOfficialConflictsResolutions,
      ]

      await req(`/organizations/${ORG_ID}/daysmart-import-season`, {
        method: 'POST',
        body: JSON.stringify({
          sport_id: sportId,
          generic_sport_id: genericSportId,
          season_id: selectedDaySmartSeason,
          options: options,
          resolutions,
        }),
      })
      setDaySmartSuccess(true)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setDaySmartError(errorMessage)
      setDaySmartSuccess(false)
    } finally {
      setProgress(100)
      setIsSyncingDaySmart(false)
    }
  }

  const renderPlayerConflicts = useCallback(() => {
    return players.data.map((player, index) => (
      <PlayerConflictItem
        key={`${player?.daysmart_id}_${index}`}
        player={player}
        selectedPlayerConflicts={selectedPlayerConflicts}
        handlePlayerRadioChange={handlePlayerRadioChange}
        setAllPlayerConflictsToCreate={setAllPlayerConflictsToCreate}
        setAllPlayerConflictsToIgnore={setAllPlayerConflictsToIgnore}
      />
    ))
  }, [
    players,
    selectedPlayerConflicts,
    handlePlayerRadioChange,
    setAllPlayerConflictsToCreate,
    setAllPlayerConflictsToIgnore,
  ])

  const renderTeamConflicts = useCallback(() => {
    return teams.data.map((team, index) => (
      <TeamConflictItem
        key={`${team?.daysmart_id}_${index}`}
        team={team}
        selectedTeamConflicts={selectedTeamConflicts}
        handleTeamRadioChange={handleTeamRadioChange}
        setAllTeamConflictsToCreate={setAllTeamConflictsToCreate}
        setAllTeamConflictsToIgnore={setAllTeamConflictsToIgnore}
      />
    ))
  }, [
    teams,
    selectedTeamConflicts,
    handleTeamRadioChange,
    setAllTeamConflictsToCreate,
    setAllTeamConflictsToIgnore,
  ])

  const renderLocationConflicts = useCallback(() => {
    return facilities.data.map((facility, index) => (
      <LocationConflictItem
        key={`${facility?.daysmart_id}_${index}`}
        location={facility}
        selectedLocationConflicts={selectedLocationConflicts}
        handleLocationRadioChange={handleLocationRadioChange}
        setAllLocationConflictsToCreate={setAllLocationConflictsToCreate}
        setAllLocationConflictsToIgnore={setAllLocationConflictsToIgnore}
      />
    ))
  }, [
    facilities,
    selectedLocationConflicts,
    handleLocationRadioChange,
    setAllLocationConflictsToCreate,
    setAllLocationConflictsToIgnore,
  ])

  const renderTeamOfficialConflicts = useCallback(() => {
    return team_officials.data.map((team_official, index) => (
      <TeamOfficialConflictItem
        key={`${team_official?.daysmart_id}_${index}`}
        team_official={team_official}
        selectedTeamOfficialConflicts={selectedTeamOfficialConflicts}
        handleTeamOfficialRadioChange={handleTeamOfficialRadioChange}
        setAllTeamOfficialsConflictsToCreate={
          setAllTeamOfficialsConflictsToCreate
        }
        setAllTeamOfficialsConflictsToIgnore={
          setAllTeamOfficialsConflictsToIgnore
        }
      />
    ))
  }, [
    team_officials,
    selectedTeamOfficialConflicts,
    handleTeamOfficialRadioChange,
    setAllTeamOfficialsConflictsToCreate,
    setAllTeamOfficialsConflictsToIgnore,
  ])

  const tabs = useMemo(
    () => [
      teams?.count &&
        teams?.count > 0 && {
          key: '1',
          label: `Teams (${Object.keys(selectedTeamConflicts)?.length || 0} / ${
            teams?.count
          })`,
          children: renderTeamConflicts(),
        },
      players?.count &&
        players?.count > 0 && {
          key: '2',
          label: `Players (${
            Object.keys(selectedPlayerConflicts)?.length || 0
          } / ${players?.count})`,
          children: renderPlayerConflicts(),
        },
      team_officials?.count &&
        team_officials?.count > 0 && {
          key: '3',
          label: `Team Officials (${
            Object.keys(selectedTeamOfficialConflicts)?.length || 0
          } / ${team_officials?.count})`,
          children: renderTeamOfficialConflicts(),
        },

      facilities?.count &&
        facilities?.count > 0 && {
          key: '4',
          label: `Locations (${
            Object.keys(selectedLocationConflicts)?.length || 0
          } / ${facilities?.count})`,
          children: renderLocationConflicts(),
        },
    ],
    [
      players,
      teams,
      facilities,
      team_officials,
      selectedPlayerConflicts,
      selectedTeamConflicts,
      selectedLocationConflicts,
      selectedTeamOfficialConflicts,
      renderPlayerConflicts,
      renderTeamConflicts,
      renderLocationConflicts,
      renderTeamOfficialConflicts,
    ]
  )

  const onChangeTabs = (key) => {}

  return (
    <div
      css={css`
        width: 50vw;
        background: #26303e;
        display: flex;
        flex-direction: column;
        transition: all 0.1s ease-in-out;
        border-radius: 8px;
        padding-top: 16px;
      `}
    >
      <div
        css={css`
          padding-left: 16px;
          padding-right: 16px;
          margin-bottom: 16px;
          display: flex;
          flex: 1;
          flex-direction: column;
          height: 100%;
          max-height: 550px;
        `}
      >
        <div
          css={css`
            margin-bottom: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              align-self: stretch;
            `}
          >
            <p
              css={css`
                ${heading3}
                ${weightSemiBold}
            color: ${colors.WHITE};
              `}
            >
              Duplicate Review
            </p>
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 8px;
              `}
            >
              <button
                onClick={() => {
                  if (daySmartSuccess) {
                    readItem(ENTITY_TYPES.org, ORG_ID)
                    readyEntityCount()
                    daySmartImportModalRef?.current?.closeModal()
                    return
                  }
                  daySmartImportModalRef?.current?.closeModal()
                }}
              >
                <CloseOutlined
                  fontSize={20}
                  style={{
                    color: colors.WHITE,
                    fontSize: 20,
                  }}
                />
              </button>
              <button
                onClick={() => {
                  window.open(
                    'https://sportninja.zendesk.com/hc/en-us/articles/25378814243988-DaySmart-Integration-NEW',
                    '_blank'
                  )
                }}
              >
                <QuestionCircleOutlined
                  fontSize={20}
                  style={{
                    color: colors.WHITE,
                    fontSize: 20,
                  }}
                />
              </button>
            </div>
          </div>
          <div
            css={css`
              border-bottom: 1px solid ${colors.SOFT_STEEL};
              margin-bottom: 16px;
              margin-top: 16px;
            `}
          />
          <p
            css={css`
              ${paragraphMedium}
              ${weightRegular}
              color: ${colors.WHITE};
            `}
          >
            Importing season data from DaySmart will overwrite the data if it
            exists. This action is irreversible.
          </p>
        </div>
        {!isSyncingDaySmart && !daySmartSuccess ? (
          <div
            css={css`
              overflow-y: auto;
            `}
          >
            <Tabs
              defaultActiveKey='1'
              items={tabs}
              onChange={onChangeTabs}
              style={{ width: '100%', minHeight: 400 }}
              tabBarStyle={{
                height: 40,
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: colors.SOFT_STEEL,
                backgroundColor: colors.HEADER,
                textTransform: 'uppercase',
              }}
            />
          </div>
        ) : null}
        <div
          css={css`
            padding-top: 16px;
            align-self: flex-end;
            width: 100%;
            position: sticky;
            bottom: 0;
            background-color: ${colors.HEADER};
            z-index: 100;
          `}
        >
          {daySmartError ? (
            <Space
              direction='vertical'
              style={{ width: '100%', marginBottom: 16 }}
            >
              <Alert message={daySmartError} type='error' showIcon />
            </Space>
          ) : null}
          {daySmartSuccess ? (
            <Space
              direction='vertical'
              style={{ width: '100%', marginBottom: 16 }}
            >
              <Alert
                message={'Season imported successfully'}
                type='success'
                showIcon
                closable
              />
            </Space>
          ) : null}
          {isSyncingDaySmart ? (
            <div
              css={css`
                padding-bottom: 16px;
                padding-left: 16px;
                padding-right: 16px;
                gap: 16px;
                display: flex;
                flex-direction: column;
                align-items: center;
              `}
            >
              <span
                css={css`
                  color: var(--Neutrals-neutrals-0, #fff);
                  text-align: center;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 145%;
                `}
              >
                Sending data
              </span>
              {/* <Spin tip='Loading' /> */}
              <Progress
                percent={progress}
                trailColor={colors.ATTENDANCE_GRAY}
                strokeColor={colors.PRIMARY}
              />
            </div>
          ) : null}
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              width: 100%;
            `}
          >
            {!daySmartSuccess ? (
              <SNButton
                onClick={(e) => {
                  e.preventDefault()
                  daySmartImportModalRef?.current?.closeModal()
                }}
                label={'Cancel'}
                variant='secondary'
                disabled={isSyncingDaySmart || daySmartSuccess}
              />
            ) : null}
            <SNButton
              onClick={async () => {
                if (daySmartSuccess) {
                  readItem(ENTITY_TYPES.org, ORG_ID)
                  readyEntityCount()
                  daySmartImportModalRef?.current?.closeModal()
                  return
                }
                await onSyncDaySmart()
              }}
              type='submit'
              label={daySmartSuccess ? 'Ok' : 'Confirm'}
              disabled={
                !selectedDaySmartSeason ||
                isSyncingDaySmart ||
                !hasSelectedAllPlayerConflicts ||
                !hasSelectedAllTeamConflicts ||
                !hasSelectedAllLocationConflicts ||
                !hasSelectedAllTeamOfficialsConflicts
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConflictsForm
