import req from '@sportninja/common/api/request'
import { t } from '@sportninja/common/i18n'
import React, {
  createRef,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  findStats,
  translateStatsResponse,
  separateUpperAndLowerPools,
} from '@sportninja/common/utils/stats'
import { useQueryState } from '../utils'
import BracketFilter from './BracketFilter'
import Brackets from './Brackets'
import { EmptySchedule } from '../EmptySchedule'

const ScheduleBracket = ({ id, canUpdate }) => {
  const parentRef = useRef(null)
  const innerRef = useRef(null)
  const [loadingFilter, setLoadingFilter] = useState(true)
  const [loadingBracket, setLoadingBracket] = useState(true)
  const [selected, setSelected] = useState({})
  const [rounds, setRounds] = useState([])
  const [competitionType, setCompetitionType] = useState(
    t('common:competition')
  )
  const [comps, setComps] = useState([])
  const [selectedComp, setSelectedComp] = useQueryState('schedule_id', '')
  const [stats, setStats] = useState({})

  const hasAutoSelected = useRef(selectedComp)

  const handle = (response) => {
    let finalRounds = response?.data.map((round) => {
      const pools = round?.pools?.data?.map((pool) => {
        const ref = createRef(null)
        return {
          ref,
          ...pool,
        }
      })
      return {
        ...round,
        pools,
      }
    })
    finalRounds = separateUpperAndLowerPools(finalRounds)
    setRounds(finalRounds)
  }

  /*
    Store an object like this:
    {
      "pool1_id": {
        "team1_id": {
          "scoreString": "0-0-1",
          "points": 1
        },
        "team2_id": {
          "scoreString": "0-0-1",
          "points": 1
        }
      }
    }
  */
  const handleStats = (response) => {
    if (!response?.data) {
      return
    }
    const aggregatedStats = translateStatsResponse(response)
    const teamsByPool = {}
    aggregatedStats.forEach((teamPerPool) => {
      const { team, stats, pool } = teamPerPool
      const wins = findStats(stats, 'win')
      const losses = findStats(stats, 'loss')
      const ties = findStats(stats, 'tie')
      const points = findStats(stats, 'points')
      teamsByPool[pool.id] = Object.assign({}, teamsByPool[pool.id], {
        [team.id]: {
          scoreString: `${wins}-${losses}-${ties}`,
          points,
        },
      })
    })

    setStats(teamsByPool)
  }

  useEffect(() => {
    const init = async () => {
      setLoadingFilter(true)
      try {
        const { data: compStructure } = await req(
          `/schedules/${id}/children/dropdown`
        )
        const levelContainingTeams = compStructure[compStructure.length - 1]

        if (levelContainingTeams) {
          setCompetitionType(levelContainingTeams.name)
          setComps(levelContainingTeams.schedules || [])
        }
      } finally {
        setLoadingFilter(false)
      }
    }
    init()
  }, [id])

  useEffect(() => {
    if (
      !loadingFilter &&
      !selectedComp &&
      comps.length > 0 &&
      !hasAutoSelected.current
    ) {
      setSelectedComp(comps[0].id)
      hasAutoSelected.current = true
    }
  }, [selectedComp, loadingFilter, comps])

  useEffect(() => {
    const combined = async () => {
      setLoadingBracket(true)
      try {
        const results = await Promise.all([
          await req(`/schedules/${selectedComp}/tournaments`),
          await req(`/schedules/${selectedComp}/tournaments/stats`),
        ])
        typeof results[0] !== 'undefined' && handle(results[0])
        typeof results[1] !== 'undefined' && handleStats(results[1])
      } finally {
        setLoadingBracket(false)
      }
    }
    if (selectedComp.length > 0) combined()
  }, [selectedComp])

  const checkScrollRoom = useCallback(() => {
    const availableWidth = parentRef?.current?.clientWidth
    const innerWidth = innerRef?.current?.clientWidth

    if (availableWidth < innerWidth) {
      parentRef?.current?.classList.add('show-right-fade')
    } else {
      parentRef?.current?.classList.remove('show-right-fade')
    }
  }, [])

  useEffect(() => {
    if (!loadingBracket) {
      checkScrollRoom()
    }
  }, [checkScrollRoom, loadingBracket])

  useEffect(() => {
    window.addEventListener('resize', checkScrollRoom)

    return () => {
      window.removeEventListener('resize', checkScrollRoom)
    }
  }, [checkScrollRoom])

  const onScroll = useCallback((e) => {
    const availableWidth = parentRef?.current?.clientWidth
    const innerWidth = innerRef?.current?.clientWidth

    const left = e.target.scrollLeft
    const availableScroll = innerWidth - availableWidth

    if (availableWidth > innerWidth) {
      parentRef?.current?.classList.remove('show-left-fade')
      parentRef?.current?.classList.remove('show-right-fade')
    } else if (left >= availableScroll) {
      e.target.classList.remove('show-right-fade')
      e.target.classList.add('show-left-fade')
    } else if (left === 0) {
      e.target.classList.remove('show-left-fade')
      e.target.classList.add('show-right-fade')
    } else if (left !== 0 || left < availableScroll) {
      e.target.classList.add('show-right-fade')
      e.target.classList.add('show-left-fade')
    }
  }, [])

  return (
    <Fragment>
      <BracketFilter
        comps={comps}
        setSelectedComp={(c) => {
          setSelectedComp(c.id)
        }}
        selectedComp={selectedComp}
        competitionType={competitionType}
      />
      {rounds.length === 0 && !loadingBracket ? (
        <EmptySchedule isPlayoff scheduleId={id} canUpdate={canUpdate} />
      ) : (
        <Brackets
          onScroll={onScroll}
          loadingBracket={loadingBracket}
          competitionType={competitionType}
          innerRef={innerRef}
          parentRef={parentRef}
          selectedComp={selectedComp}
          rounds={rounds}
          selected={selected}
          setSelected={setSelected}
          stats={stats}
        />
      )}
    </Fragment>
  )
}

export default ScheduleBracket
