import { str } from './utils'

const teamOfflineTransformer = (actions, actionType, requestPayload) => {
  let type = '',
    payload = {}

  switch (
    actionType // eslint-disable-line default-case
  ) {
    case str(actions.roster.player.create.request): {
      type = str(actions.roster.player.create.success)
      payload = {
        id: requestPayload.id,
        data: {
          id: requestPayload.form.uid,
          name_first: requestPayload.form.name_first,
          name_last: requestPayload.form.name_last,
          birth_date: requestPayload.form.birth_date,
          image: null,
          player_number: requestPayload.form.player_number,
          is_affiliate: requestPayload.form.is_affiliate,
          is_injured: requestPayload.form.is_injured,
          is_suspended: requestPayload.form.is_suspended,
          email: requestPayload.form.email,
          player_type: requestPayload.player_type,
        },
      }
      break
    }
  }

  return { type, payload }
}

export default teamOfflineTransformer
