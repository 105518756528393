import req from '@sportninja/common/api/request'
import type { SignUpData } from '../steps/newMember'
import type { BodyType, BusinessInfoInputFields } from '../steps/businessInfo'
import BannerController from 'src/components/Banner/BannerController'
import Banner from 'src/components/Banner'
import { getErrorMessage } from '@sportninja/common/utils/utils'

export type BusinessAccountData = Omit<SignUpData, 'context' | 'contextId'> &
  BusinessInfoInputFields & {
    context: {
      name: string
      id: string
    }
  }

export type Country = {
  id: number
  name: string
  name_full: string
  iso_3166_2: string
  iso_3166_3: string
  capital: string
  citizenship: string
  currency_symbol: string
  is_visible: boolean
}

export type Province = {
  id: number
  country_id: number
  name: string
  iso_3166_2: string
  geonames_id: number
  geonames_code: number
}

class Service {
  token = '_OuYnZrqBasSrZZvkquk07-djWmIpvuleTVYFQE7RMlh3VX02twwGbXiMeufN4KXVb4'
  countryList: Country[] = []
  authToken: string | null = null

  constructor() {
    this.getCountryList()
  }

  private async getCountryList() {
    try {
      const response = await req('/countries')

      this.countryList = response
    } catch (e) {
      this.countryList = []
      console.error(e)
    }
  }

  public async signUpMember(data: SignUpData) {
    const body = JSON.stringify({
      name_first: data.firstName,
      name_last: data.lastName,
      email: data.email,
      password: data.password,
      confirm_password: data.confirmPassword,
      birth_date: data.dateOfBirth,
      user_signup_type: data.userSignUpType || null,
      context: data.context,
      context_id: data.contextId,
      account: {},
    })

    try {
      const response = await req('/signup', {
        method: 'POST',
        body,
        requiresAuth: false,
      })

      return response
    } catch (e: any) {
      const errorMessage = getErrorMessage(e)
      BannerController.add(({ ...props }) => (
        <Banner
          duration={undefined}
          onClose={undefined}
          onClick={undefined}
          subChildren={undefined}
          {...props}
          type='failed'
        >
          {errorMessage}
        </Banner>
      ))
    }

    return 'ERROR'
  }

  public async signUpBusinessAccount(data: BodyType) {
    const body = JSON.stringify({
      name_first: data.firstName,
      name_last: data.lastName,
      email: data.email,
      password: data.password,
      confirm_password: data.confirmPassword,
      birth_date: data.dateOfBirth,
      context: data.context.name,
      context_id: data.context.id,
      account: {
        // full_name: data.fullName,
        full_name: `${data.firstName} ${data.lastName}`,
        title: data.title,
        email: data.email,
        phone_number: data.phone,
        company_name: data.companyName,
        org_abbreviation: data.orgAbbreviation,
        address_street: data.street,
        address_country: data.country,
        address_state_province: data.state,
        address_city: data.city,
        address_postal_code: data.zipCode,
      },
    })

    try {
      const response = await req('/signup', {
        method: 'POST',
        body,
        requiresAuth: false,
      })

      return response
    } catch (e: any) {
      const errorMessage = getErrorMessage(e)
      BannerController.add(({ ...props }) => (
        <Banner
          duration={undefined}
          onClose={undefined}
          onClick={undefined}
          subChildren={undefined}
          {...props}
          type='failed'
        >
          {errorMessage}
        </Banner>
      ))
    }

    return 'ERROR'
  }

  public getCountries() {
    return this.countryList
  }

  public async getStates(countryId: number) {
    try {
      const response: Province[] | [] = await req(
        `/countries/${countryId}/provinces`
      )

      return response
    } catch (e) {
      console.error(e)
      return []
    }
  }
}

export const RequestsService = new Service()
