/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import { useCallback, useState } from 'react'
import { Flex } from '../../Layout'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'

import LoadingSpinner from '../../LoadingSpinner'
import OptionButtonIcon from './Icon'
import colors from '@sportninja/common/constants/appColors'
import { Tooltip } from 'antd'

const OptionButtonFavourite = ({ entityType, item, isFavourite }) => {
  const [loading, setLoading] = useState(false)
  const [isFav, setIsFav] = useState(isFavourite)

  const onClick = async () => {
    setLoading(true)
    try {
      await req(`/favorites/${entityType}/${item.id}`, {
        method: isFav ? 'DELETE' : 'POST',
      })
      setIsFav(!isFav)
    } finally {
      setLoading(false)
    }
  }

  const entityName = useCallback(() => {
    switch (entityType) {
      case ENTITY_TYPES.org:
        return ' organization'
      case ENTITY_TYPES.team:
        return ' team'
      case ENTITY_TYPES.schedule:
        return ' competition'
      case ENTITY_TYPES.game:
        return ' game'
      default:
        return ''
    }
  }, [entityType])

  return (
    <Flex noFlex alignItems='center' justifyContent='center'>
      {loading ? (
        <div style={{ width: 18 }}>
          <LoadingSpinner size={1.3} borderSize={1.8} />
        </div>
      ) : (
        <div
          css={css`
            position: relative;
            display: inline-block;
          `}
        >
          <Tooltip
            title={`${isFav ? 'Unfollow' : 'Follow'}${entityName()}`}
            mouseEnterDelay={1}
            placement='bottom'
          >
            <OptionButtonIcon
              faType={isFav ? 'fas' : 'far'}
              name='star'
              onClick={onClick}
            />
          </Tooltip>
          <span
            className='following-tooltip'
            css={css`
              visibility: hidden;
              min-width: 120px;
              background-color: ${colors.HEADER_BG_2};
              color: #fff;
              text-align: center;
              text-transform: capitalize;
              border-radius: 6px;
              padding: 8px 6px;
              position: absolute;
              z-index: 1;
              transition: visibility 0.1s ease-in-out;
            `}
          >
            {`${isFav ? 'Unfollow' : 'Follow'}${entityName()}`}
          </span>
        </div>
      )}
    </Flex>
  )
}

export default OptionButtonFavourite
