import FormSheet from '../../Form/FormSheet'
import OptionButtonIcon from './Icon'

const OptionButtonEdit = ({ Delete, form, onSubmit, title, subTitle }) => {
  return (
    <FormSheet
      customDeleteModal={Delete}
      Button={({ onClick }) => {
        return (
          <OptionButtonIcon name='pencil' onClick={onClick} title={title} />
        )
      }}
      form={form}
      label={title}
      onSubmit={onSubmit}
      title={title}
      subTitle={subTitle}
    />
  )
}

export default OptionButtonEdit
