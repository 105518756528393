/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { isCanlan, isSN } from '@sportninja/common/utils/customer-name'

import { zIndex } from './css'
import { FormButton } from './Form/css'
import Icon from './Icon'

const OutdatedHandler = ({ inFrame, outdated }) => {
  if (!outdated) return false

  return (
    <div
      className='outdated-handler-container'
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 80px;
        left: 0;
        right: 0;
        z-index: ${zIndex.modal};
      `}
    >
      <div
        className='outdated-handler'
        css={css`
          width: 280px;
          border-radius: ${isCanlan ? 0 : 3}px;
          background-color: rgba(24, 26, 29, 0.85);
          box-shadow: 0 2px 10px 0 #0e0f11;
          padding: 16px 20px;
          text-align: center;
          border: 1px solid rgba(255, 255, 255, 0.8);
        `}
      >
        <div
          css={css`
            margin-bottom: 14px;
          `}
        >
          <p>A new and improved version</p>
          <p
            css={css`
              margin-top: 4px;
            `}
          >
            of the {inFrame ? 'widget' : 'app'} is now available.
          </p>
        </div>
        <div
          css={css`
            padding: 0 50px;
          `}
        >
          <FormButton
            onClick={() => {
              window.location.reload()
            }}
            isSubmit
            css={css`
              height: 36px;
              display: flex;
              font-size: 16px;
              color: ${inFrame && isSN && 'black'};
            `}
          >
            Refresh
            <div
              css={css`
                margin-left: 6px;
                padding-bottom: 2px;
              `}
            >
              <Icon
                name='sync'
                fontSize={13}
                className={isCanlan ? 'outdated-handler-refresh-icon' : ''}
              />
            </div>
          </FormButton>
        </div>
      </div>
    </div>
  )
}

export default OutdatedHandler
