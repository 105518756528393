/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useEffect, useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import colors from '@sportninja/common/constants/appColors'

import { InputWrapper, Label, inputCss, Right } from '../Form/css'
import { Flex } from '../Layout'
import Icon from '../Icon'

const Picker = ({
  children,
  disabled,
  hasError,
  hideClear,
  label,
  noFlex,
  onReset,
  placeholder,
  readOnly,
  required,
  right,
  value,
  icon,
}) => {
  const pickerRef = useRef(null)
  const [open, setOpen] = useState(false)
  const sharedInputProps = { disabled, hasError, noFlex, readOnly }

  const isInViewport = useCallback((el) => {
    if (el) {
      const rect = el.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      )
    }
  }, [open])

  useEffect(() => {
    if (open) {
      const el = document.getElementsByClassName('date-picker-contents')[0]

      const isVisible = isInViewport(el)

      if (!isVisible) {
        el.style.top = 'calc(-850% + 1px)'
      }
    }
  }, [open])

  useEffect(() => {
    const handler = (e) => {
      if (!pickerRef?.current?.contains(e.target)) {
        setOpen(false)
        document.removeEventListener('click', handler)
      }
    }
    if (open) document.addEventListener('click', handler)
    return () => {
      document.removeEventListener('click', handler)
    }
  }, [open])

  return (
    <InputWrapper
      {...sharedInputProps}
      ref={pickerRef}
      className='picker-wrapper'
    >
      <Label className='picker-label' {...sharedInputProps}>
        {label}
        {required ? ' *' : ''}
      </Label>
      <div
        className='picker-container'
        css={css`
          width: 100%;
          position: relative;
          cursor: pointer;
        `}
      >
        <Flex
          alignItems='center'
          justifyContent='space-between'
          className='picker-value-container'
          css={inputCss(sharedInputProps)}
          onClick={() => {
            !disabled && setOpen(!open)
          }}
        >
          {/* Thanks Safari for this width: 100% shenanigans. */}
          <div
            className='picker-value'
            css={css`
              width: 100%;
            `}
          >
            {value ? (
              value
            ) : (
              <span
                className='picker-placeholder'
                css={css`
                  opacity: 0.4;
                `}
              >
                {placeholder}
              </span>
            )}
          </div>
          {
            icon ? (
              <div css={{
                marginRight: !hideClear && value ? 8 : 0,
              }}>
                {icon}
              </div>
            ) : null
          }
          {!hideClear && value && (
            <button
              type='button'
              onClick={onReset}
              className='picker-clear-button'
            >
              <Icon
                name='times'
                color={colors.DEFAULT_FLAIR}
                fontSize={18}
                faType='far'
                css={css`
                  transform: translateX(-2px);
                `}
              />
            </button>
          )}
        </Flex>
        {open && children}
        {right && <Right>{right}</Right>}
      </div>
    </InputWrapper>
  )
}

Picker.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  hideClear: PropTypes.bool,
  noFlex: PropTypes.bool,
  onReset: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
}

export default Picker
