const Periods = {
  1: '1st',
  2: '2nd',
  3: '3rd',
  4: 'OT1',
  5: 'OT2',
  6: 'OT3',
  7: 'OT4',
  8: 'OT5',
  9: 'OT6',
  10: 'OT7',
  11: 'OT8',
  12: 'OT9',
  13: 'PS',
  14: '1st',
  15: '2nd',
  16: 'ET1',
  17: 'ET2',
  18: '1st',
}

export default Periods
