export const createImage = (src, alt, styles = {}) => {
  const img = document.createElement('img')
  img.src = src
  img.alt = alt
  Object.assign(img.style, styles)
  return img
}

export const createButton = (text, styles = {}) => {
  const btn = document.createElement('button')
  btn.innerText = text
  Object.assign(btn.style, styles)
  return btn
}

export const createDiv = (styles = {}) => {
  const div = document.createElement('div')
  Object.assign(div.style, styles)
  return div
}
