// @ts-nocheck
/* eslint-disable react-native/no-inline-styles */
/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Alert, Checkbox, Space } from 'antd'
import React, { useEffect } from 'react'
import { Button } from 'src/components/Button'
import { Separator } from 'src/components/NewScheduleForm/GeneralForm'
import { heading5, heading6, weightSemiBold } from 'src/components/css'
import { TextInput } from 'src/components/ts-components/TextInput'
import '../styles.scss'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import req from '@sportninja/common/api/request'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { ClearOutlined } from '@ant-design/icons'

type IntegrationsFormPros = {
  onCancelPressed: () => void
  triggerRefresh: () => void
  orgId: string
}

const IntegrationsForm: React.FC<IntegrationsFormPros> = ({
  onCancelPressed,
  triggerRefresh,
  orgId,
}) => {
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isSyncing, setIsSyncing] = React.useState(false)
  const [fieldErrors, setFieldErrors] = React.useState<string[]>([])
  const [customError, setCustomError] = React.useState({})
  const [daysmartApiKey, setDaysmartApiKey] = React.useState('')
  const [daysmartSecretKey, setDaysmartSecretKey] = React.useState('')
  const [acceptedTerms, setAcceptedTerms] = React.useState(false)
  const [isLoadingTest, setIsLoadingTest] = React.useState(false)
  const [testSuccess, setTestSuccess] = React.useState(false)
  const [testSubmitted, setTestSubmitted] = React.useState(false)
  const [existingDaysmartConnection, setExistingDaysmartConnection] =
    React.useState(false)
  const [credentialsCleared, setCredentialsCleared] = React.useState(false)

  const onSubmit = async () => {
    try {
      setError('')
      setIsLoading(true)
      const result = await req(`/organizations/${orgId}/daysmart-api-keys`, {
        method: 'POST',
        body: JSON.stringify({
          api_key: daysmartApiKey,
          api_secret: daysmartSecretKey,
        }),
      })
      setTestSuccess(false)
      setTestSubmitted(false)
      setSuccess(true)
      setExistingDaysmartConnection(true)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setError(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  const onClearCredentials = async () => {
    try {
      setError('')
      setIsLoading(true)
      const result = await req(`/organizations/${orgId}/daysmart-api-keys`, {
        method: 'DELETE',
        body: JSON.stringify({
          api_key: null,
          api_secret: null,
        }),
      })
      setDaysmartApiKey('')
      setDaysmartSecretKey('')
      setTestSubmitted(false)
      setAcceptedTerms(false)
      setCredentialsCleared(true)
      setExistingDaysmartConnection(false)
      setTimeout(() => {
        setCredentialsCleared(false)
      }, 2000)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setError(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  const onCancel = () => {}

  const onPressTest = async () => {
    try {
      setError('')
      setIsLoadingTest(true)
      const result = await req(
        `/organizations/${orgId}/daysmart-authentication-test`,
        {
          method: 'POST',
          body: JSON.stringify({
            api_key: daysmartApiKey,
            api_secret: daysmartSecretKey,
          }),
        }
      )
      setTestSuccess(true)
      setTestSubmitted(true)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setError(errorMessage)
    } finally {
      setIsLoadingTest(false)
    }
  }

  useEffect(() => {
    const fetchDaysmartKeys = async () => {
      try {
        const { data } = await req(`/organizations/${orgId}/daysmart-api-keys`)
        const { api_key, api_secret } = data
        if (api_key) {
          setDaysmartApiKey(api_key)
        }
        if (api_secret) {
          setDaysmartSecretKey(api_secret)
        }
        if (api_key && api_secret) {
          setAcceptedTerms(true)
          setExistingDaysmartConnection(true)
        }
      } catch (e) {
        const errorMessage = getErrorMessage(e)
        setError(errorMessage)
      }
    }
    if (orgId) {
      fetchDaysmartKeys()
    }
  }, [orgId])

  return (
    <div
      css={css`
        padding-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        max-height: 550px;
        max-width: 800px;
        gap: 16px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 16px;
        `}
      >
        {/* Registration ON/OFF */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 16px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <p
              css={css`
                ${heading6}
                ${weightSemiBold}
                   color: ${colors.WHITE};
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'BarlowCondensed'};
              `}
            >
              Daysmart
            </p>
          </div>
          <div
            css={css`
              display: flex;
              flex: 1;
              flex-direction: column;
              height: 100%;
            `}
          >
            <div
              css={css`
                gap: 16px;
                display: flex;
                flex-direction: column;
              `}
            >
              <p
                css={css`
                  color: var(--Neutrals-neutrals-0, #fff);
                  font-size: 18px;
                  font-weight: 500;
                  line-height: 145%; /* 26.1px */
                `}
              >
                Please enter your credentials before proceeding:
              </p>
              <TextInput
                name='daysmart_api_key'
                label='DaySmart API Key *'
                autoComplete='off'
                value={daysmartApiKey}
                setValue={setDaysmartApiKey}
                fieldErrors={fieldErrors}
                customError={customError}
              />
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                `}
              >
                <TextInput
                  name='daysmart_secret_key'
                  label='API Secret *'
                  autoComplete='off'
                  value={daysmartSecretKey}
                  setValue={setDaysmartSecretKey}
                  fieldErrors={fieldErrors}
                  customError={customError}
                  type='password'
                />
                <p
                  css={css`
                    color: var(--Foundation-Primary-primary-300, #f2b400);
                    text-align: right;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 145%; /* 17.4px */
                    cursor: pointer;
                    margin-top: 8px;
                  `}
                  onClick={() => {
                    window.open(
                      'https://sportninja.zendesk.com/hc/en-us/articles/25659641630484-Creating-a-DaySmart-API-Key',
                      '_blank'
                    )
                  }}
                >
                  Where to find this in DaySmart?
                </p>
              </div>
              <div
                css={css`
                  border-bottom: 1px solid ${colors.SOFT_STEEL};
                `}
              />
              <Checkbox
                onChange={() => setAcceptedTerms(!acceptedTerms)}
                checked={acceptedTerms}
              >
                By proceeding with this process, you agree to the{' '}
                <a
                  href='https://www.sportninja.com/integration-terms'
                  target='_blank'
                  rel='noopener noreferrer'
                  css={css`
                    color: #f2b400;
                  `}
                >
                  Terms and Conditions
                </a>{' '}
                of the DaySmart Integration.
              </Checkbox>

              <div
                css={css`
                  margin-top: 20px;
                  align-self: flex-end;
                  width: 100%;
                  position: sticky;
                  bottom: 0;
                  background-color: ${colors.HEADER};
                  z-index: 100;
                  padding-top: 16px;
                `}
              >
                {testSubmitted ? (
                  <Space
                    direction='vertical'
                    style={{ width: '100%', marginBottom: 16 }}
                  >
                    <Alert
                      message={
                        'API key validated. You can now connect your account.'
                      }
                      type='info'
                      showIcon
                      closable
                    />
                  </Space>
                ) : null}
                {error ? (
                  <Space
                    direction='vertical'
                    style={{ width: '100%', marginBottom: 16 }}
                  >
                    <Alert message={error} type='error' showIcon closable />
                  </Space>
                ) : null}
                {success ? (
                  <Space
                    direction='vertical'
                    style={{ width: '100%', marginBottom: 16 }}
                  >
                    <Alert
                      message={'Daysmart API key connected!'}
                      type='success'
                      showIcon
                      closable
                    />
                  </Space>
                ) : null}
                {credentialsCleared ? (
                  <Space
                    direction='vertical'
                    style={{ width: '100%', marginBottom: 16 }}
                  >
                    <Alert
                      message={'Daysmart Credentials Cleared!'}
                      type='info'
                      showIcon
                      closable
                    />
                  </Space>
                ) : null}
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    width: 100%;
                    /* if error, margin bottom 16px */
                    margin-bottom: ${error ? '16px' : '0px'};
                  `}
                >
                  {existingDaysmartConnection ? (
                    <Button
                      onClick={async () => {
                        await onClearCredentials()
                      }}
                      label={'Clear Credentials'}
                      variant='secondary'
                      isLoading={isLoadingTest}
                      disabled={isLoading || isLoadingTest || !acceptedTerms}
                      customCss={css`
                        background-color: firebrick;
                        border-color: firebrick;
                      `}
                    />
                  ) : null}
                  <Button
                    onClick={async () => {
                      await onPressTest()
                    }}
                    label={'Test'}
                    variant='secondary'
                    isLoading={isLoadingTest}
                    disabled={isLoading || isLoadingTest || !acceptedTerms}
                  />
                  <Button
                    onClick={async () => {
                      await onSubmit()
                    }}
                    type='submit'
                    label='Connect'
                    isLoading={isLoading}
                    disabled={isLoading || !acceptedTerms || !testSuccess}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IntegrationsForm
