/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { Input, Alert } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { EventCard } from 'src/components/RegistrationEventCard'
import { EventProgress } from 'src/components/RegistrationEventProgress'
import { RegistrationContext } from '../RegistrationContext'
import { PageTitle, REGISTRATION_STEPS, paragraphMediumStyle } from './Main'
import { heading3, weightRegular } from 'src/components/css'

const reSendCode = async (email) => {
  await req('/signup/resend', {
    method: 'POST',
    body: JSON.stringify({ email }),
  })
}

const validateCode = async (email, code) => {
  try {
    await req('/signup/validate', {
      method: 'POST',
      body: JSON.stringify({ email, token: code }),
    })
    return true
  } catch (e) {
    if (e?.message === 'This email has already been verified') {
      return true
    }
    throw e
  }
}

const checkIfUserIsVerified = async () => {
  try {
    const { data } = await req('/signup/status/validate')
    if (data?.is_validated) {
      return true
    } else {
      throw new Error('User is not verified')
    }
  } catch (e) {
    if (e?.message === 'This email has already been verified') {
      return true
    }
    throw e
  }
}

const ConfirmYourEmail = () => {
  const {
    setStep,
    registration,
    email,
    updateSessionConfirmSubmission,
    deleteSession,
  } = useContext(RegistrationContext)
  const [error, setError] = useState(null)
  const [timeLeft, setTimeLeft] = useState(30)

  useEffect(() => {
    checkIfUserIsVerified()
      .then(() => {
        updateSessionConfirmSubmission()
          .then(() => {
            setStep(REGISTRATION_STEPS.REGISTER_REVIEW)
          })
          .catch((e) => {
            if (e?.status === 410) {
              // localStorage.removeItem('registration_session')
              deleteSession()
              window.location.reload()
              return
            }
            const errorMessage = getErrorMessage(e)
            setError(`${errorMessage}`)
          })
      })
      .catch(() => {
        console.log('User is not verified')
      })
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      checkIfUserIsVerified()
        .then(() => {
          updateSessionConfirmSubmission()
            .then(() => {
              clearInterval(interval)
              setStep(REGISTRATION_STEPS.REGISTER_REVIEW)
            })
            .catch((e) => {
              if (e?.status === 410) {
                // localStorage.removeItem('registration_session')
                deleteSession()
                window.location.reload()
                return
              }
              const errorMessage = getErrorMessage(e)
              setError(`${errorMessage}`)
              clearInterval(interval)
            })
        })
        .catch(() => {
          console.log('User is not verified')
        })
    }, 30000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    let interval
    if (timeLeft === 30) {
      interval = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev === 0) {
            clearInterval(interval)
          }
          return prev - 1
        })
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [timeLeft])

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev === 0) {
          clearInterval(interval)
        }
        return prev - 1
      })
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        background-color: ${colors.REGISTRATION_SECOND_BACKGROUND};
        width: 1080px;
        padding: 0 35px 35px 35px;
        border-radius: 16px 16px 0px 0px;
        @media (max-width: 768px) {
          min-width: 0;
          width: 100%;
        }
      `}
    >
      <EventCard eventInfo={registration} />
      <EventProgress eventProgress={4} />
      <PageTitle
        title='Verify Your Email'
        subtitle={`We sent a six-digit code to ${email} Please enter it below to verify your email address.`}
      />
      <div
        css={css`
          max-width: 300px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          align-self: center;
          margin-bottom: 32px;
          @media (max-width: 768px) {
            width: 100%;
          }
          .ant-otp-input {
            ${heading3}
            ${weightRegular}
            font-size: 32px!important;
            width: 61px !important;
            height: 64px !important;
            padding: 16px;
            border-radius: 4px;
            color: ${colors.WHITE};
            @media (max-width: 768px) {
              width: 40px !important;
              height: 40px !important;
              font-size: 16px !important;
            }
          }
        `}
      >
        <Input.OTP
          length={6}
          onChange={async (value) => {
            try {
              await validateCode(email, value)
              updateSessionConfirmSubmission()
                .then(() => {
                  setStep(REGISTRATION_STEPS.REGISTER_REVIEW)
                })
                .catch((e) => {
                  if (e?.status === 410) {
                    // localStorage.removeItem('registration_session')
                    deleteSession()
                    window.location.reload()
                    return
                  }
                  const errorMessage = getErrorMessage(e)
                  setError(`${errorMessage}`)
                })
            } catch (e) {
              const errorMessage = getErrorMessage(e)
              // This email has already been verified
              if (errorMessage === 'This email has already been verified') {
                updateSessionConfirmSubmission()
                  .then(() => {
                    setStep(REGISTRATION_STEPS.REGISTER_REVIEW)
                    return
                  })
                  .catch((new_error) => {
                    if (new_error?.status === 410) {
                      // localStorage.removeItem('registration_session')
                      deleteSession()
                      window.location.reload()
                      return
                    }
                    const newErrorMessage = getErrorMessage(new_error)
                    setError(`${newErrorMessage}`)
                  })
                return
              }
              setError(`${errorMessage}`)
            }
          }}
        />
      </div>
      {timeLeft <= 0 ? (
        <p
          css={css`
            ${paragraphMediumStyle}
            color: ${colors.WHITE};
            align-self: stretch;
            text-align: center;
            margin-bottom: 16px;
            cursor: pointer;
          `}
          onClick={async () => {
            try {
              await reSendCode(email)
            } catch (e) {
              const errorMessage = getErrorMessage(e)
              setError(`${errorMessage}`)
            } finally {
              setTimeLeft(30)
            }
          }}
        >
          Don&apos;t see it?{' '}
          <span
            css={css`
              color: ${colors.PRIMARY_300};
            `}
          >
            Send a new code
          </span>
        </p>
      ) : (
        <p
          css={css`
            ${paragraphMediumStyle}
            color: ${colors.WHITE};
            align-self: stretch;
            text-align: center;
            margin-bottom: 16px;
          `}
        >
          Don’t see it? Send a new code in {timeLeft} seconds
        </p>
      )}
      {error ? (
        <div
          css={css`
            margin-bottom: 16px;
            width: 100%;
            /* on mobile, limit the width even more */
            @media (max-width: 768px) {
              max-width: 300px;
            }
          `}
        >
          <Alert message={error} type='error' showIcon />
        </div>
      ) : null}
    </div>
  )
}

export default ConfirmYourEmail
