export const playoffsStrings = {
  SINGLE_ELIMINATION: 'Single Elimination',
  DOUBLE_ELIMINATION: 'Double Elimination',
  CANLAN: 'Canlan',
}

export const playoffsIds = {
  'Single Elimination': 1,
  'Double Elimination': 2,
  Canlan: 3,
}

export const bracketType = {
  UPPER: 0,
  LOWER: 1,
}
