import { produce } from 'immer'

const initialState = {
  loaded: false,
}

export default (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case 'APP_LOADED':
        draft.loaded = true
        break
      default:
        break
    }
  })
