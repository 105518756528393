/** @jsxImportSource @emotion/react */
// @ts-nocheck
import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { observer } from 'mobx-react-lite'
import { useMobxStore } from 'src/state'
import { useLocation } from 'react-router-dom'
import { Details } from './Details'
import { Subscription } from './Subscription'
import { Billing } from './Billing'
import { ConnectedAccount } from './ConnectedAccount'
import LoadingSpinner from 'src/components/LoadingSpinner'
import Helmet from 'src/components/Helmet'
import { Taxes } from './Taxes'
import { Waivers } from './Waivers'
import { Integrations } from './Integrations'
import { Alert, Space } from 'antd'
import { isCanlan } from '@sportninja/common/utils/customer-name'

interface NavButtonProps {
  isFocused: boolean
  label: string
  onClick: () => void
}

const NavButton = ({ isFocused, label, onClick }: NavButtonProps) => (
  <button
    onClick={onClick}
    css={css`
      height: 40px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-right: 24px;
    `}
  >
    <p
      css={css`
        color: ${isFocused ? colors.WHITE : colors.ATTENDANCE_GRAY};
        font-family: 'Rift', sans-serif;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        padding-top: 8px;
      `}
    >
      {label}
    </p>
    <div
      css={css`
        opacity: ${isFocused ? 1 : 0};
        height: 3px;
        width: 24px;
        background-color: ${colors.DEFAULT_FLAIR};
        align-self: center;
      `}
    />
  </button>
)

export const GeneralSettings = observer(() => {
  const {
    me: {
      state: { account, loading },
      fetchMe,
    },
    payment: { fetchPaymentList },
  } = useMobxStore()

  const isPlanetIce = account?.uid === 'plOANE94mT2pe3gd'

  const [currentPage, setCurrentPage] = useState(0)
  const { search } = useLocation()

  const params = useMemo(() => {
    const regex = /\?origin=([^&]+)/
    const match = regex.exec(search)

    if (match) {
      const origin = match[1]

      return {
        origin,
      }
    }
  }, [search])

  useLayoutEffect(() => {
    fetchMe()
    fetchPaymentList()
  }, [])

  useLayoutEffect(() => {
    if (params?.origin === 'onboarding') {
      setCurrentPage(3)
    }
  }, [params])

  const renderPage = useCallback(() => {
    switch (currentPage) {
      case 0:
        return <Details />
      case 1:
        return <Subscription />
      case 2:
        return <Billing />
      case 3:
        return <ConnectedAccount />
      case 4:
        return <Taxes />
      case 5:
        return <Waivers />
      case 6:
        return <Integrations setCurrentPage={setCurrentPage} />
      default:
        return <div />
    }
  }, [currentPage])

  if (loading) {
    return (
      <div
        css={css`
          background-color: ${colors.HEADER};
          padding: 16px;
          border-radius: 8px;
          border: 1px solid ${colors.SOFT_STEEL};
          max-width: 820px;
          margin: 0 auto;
          margin-top: 50px;
        `}
      >
        <LoadingSpinner size={6} />
      </div>
    )
  }

  return (
    <>
      <Helmet title='Account Settings' />
      <main
        css={css`
          margin-top: 50px;
          padding-bottom: 50px;
        `}
      >
        <div
          css={css`
            background-color: ${colors.HEADER};
            padding: 16px;
            max-width: 820px;
            margin: 0 auto;
            gap: 16px;
            border-radius: 8px 8px 0 0;
            border: 1px solid ${colors.SOFT_STEEL};
          `}
        >
          <h1
            css={css`
              color: ${colors.WHITE};
              font-family: 'Rift', sans-serif;
              font-size: 40px;
              font-weight: 500;
            `}
          >
            ACCOUNT SETTINGS
          </h1>
          {!account?.full_name ? (
            <Space
              direction='vertical'
              style={{ width: '100%', marginTop: 16 }}
            >
              <Alert
                message='We had a problem loading your account information. Contact SportNinja Support.'
                type='warning'
                showIcon
              />
            </Space>
          ) : null}
        </div>
        <div
          css={css`
            background-color: ${colors.HEADER_BG_2};
            max-width: 820px;
            margin: 0 auto;
            height: 40px;
            border-right: 1px solid ${colors.SOFT_STEEL};
            border-bottom: 1px solid ${colors.SOFT_STEEL};
            border-left: 1px solid ${colors.SOFT_STEEL};
            padding-left: 16px;
            padding-right: 16px;
            border-radius: 0 0 8px 8px;
            display: flex;
            align-items: center;
            flex-shrink: 0;
          `}
        >
          <NavButton
            label='Details'
            onClick={() => setCurrentPage(0)}
            isFocused={currentPage === 0}
          />
          <NavButton
            label='Subscription'
            onClick={() => setCurrentPage(1)}
            isFocused={currentPage === 1}
          />
          <NavButton
            label='Billing'
            onClick={() => setCurrentPage(2)}
            isFocused={currentPage === 2}
          />
          <NavButton
            label='Payment Provider'
            onClick={() => setCurrentPage(3)}
            isFocused={currentPage === 3}
          />
          <NavButton
            label='Taxes'
            onClick={() => setCurrentPage(4)}
            isFocused={currentPage === 4}
          />
          <NavButton
            label='Waivers'
            onClick={() => setCurrentPage(5)}
            isFocused={currentPage === 5}
          />
          {!isCanlan && !isPlanetIce ? (
            <NavButton
              label='Integrations'
              onClick={() => setCurrentPage(6)}
              isFocused={currentPage === 6}
            />
          ) : null}
        </div>
        <div>{renderPage()}</div>
      </main>
    </>
  )
})
