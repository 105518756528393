import produce from 'immer'
import merge from 'lodash.merge'

import auth from '../actions/auth'
import userActions from '../actions/user'
import org from '../actions/org'
import team from '../actions/team'
import schedule from '../actions/schedule'
import fav from '../actions/fav'
import usersActions from '../actions/users'
import { ENTITY_TYPES } from '../sagas/utils'
import entityImageReducer from './sub-reducers/entity-image'
import venuesReducer from './sub-reducers/venues'
import usersReducer from './sub-reducers/users'
import { getSharedReducer as feedReducer } from './feed'
import { getSharedReducer as favReducer } from './fav'
import searchReducer from './search'
import { setChildListById, roleToEntity } from './helpers'
import { str } from './utils'
import parsers from './parsers'
import { arrayToObject } from '../utils/utils'
import officialsReducer from './sub-reducers/officials'

const reducer = (draft, { type, payload }) => {
  const orgParser = parsers[ENTITY_TYPES.org]

  switch (
    type // eslint-disable-line default-case
  ) {
    /* ACTIONS FROM OTHER REDUCERS */
    case str(team.create.success): {
      const parentId = payload && payload.data && payload.data.organization_id

      if (parentId) {
        // Update parent organization with this new child team
        const parent = (draft[parentId] = draft[parentId] || {})
        parent.teams = parent.teams || []
        parent.teams.push(payload.data.id)
        parent.teams_count = ++parent.teams_count || 1
      }
      return
    }

    case str(team.delete.success): {
      const { id, parentId } = payload
      const parent = draft[parentId]

      if (parent && Array.isArray(parent.teams)) {
        const childIndex = parent.teams.findIndex((i) => i === id)

        // Remove child team from org teams list
        parent.teams.splice(childIndex, 1)
        parent.teams_count--
      }

      return
    }

    case str(schedule.read.success): {
      if (typeof payload.data.organization !== 'undefined') {
        draft[payload.data.organization.id] = merge(
          draft[payload.data.organization.id],
          orgParser(payload.data.organization)
        )
      }
      return
    }

    case str(schedule.create.success): {
      const {
        data: { id, organization_id },
      } = payload
      const parentOrg = (draft[organization_id] = draft[organization_id] || {})
      parentOrg.schedules = parentOrg.schedules || []
      parentOrg.schedules.push(id)
      return
    }

    case str(schedule.delete.success): {
      const { id, parentId } = payload
      const parent = draft[parentId]

      if (parent && Array.isArray(parent.schedules)) {
        const childIndex = parent.schedules.findIndex((i) => i === id)
        // Remove child schedule from org schedule list
        parent.schedules.splice(childIndex, 1)
      }

      return
    }

    case str(auth.logout.success):
      return {}

    case str(userActions.readRoles.success):
    case str(usersActions.readRoles.success):
      return merge(draft, roleToEntity('organization_id', payload, orgParser))

    case str(userActions.readOrgs.success):
      return merge(draft, arrayToObject(payload.data, orgParser))

    case str(team.schedules.read.success): {
      const orgs = {}
      payload.data.forEach((team) => {
        if (!team.organization) return
        const parsed = orgParser(team.organization)
        orgs[parsed.id] = parsed
      })
      return merge(draft, arrayToObject(orgs))
    }
    /* END ACTIONS FROM OTHER REDUCERS */

    case str(org.create.success): {
      const { data } = payload

      if (data.organization) {
        // Update parent organization with this new child organization
        let parent = draft[data.organization.id]

        // It's possible the parent organization doesn't yet exist in store
        if (!parent) {
          parent = draft[data.organization.id] = {}
        }

        // Create the array attribute in case it doesn't exist
        parent.children = parent.children || []
        parent.children.push(data.id)
        parent.children_count = ++parent.children_count || 1
      }

      draft[data.id] = orgParser(data)
      return
    }

    case str(org.delete.success): {
      const { id, parentId } = payload

      // If the org has a parent...
      if (parentId) {
        const parent = draft[parentId]

        if (parent && Array.isArray(parent.children)) {
          const childIndex = parent.children.findIndex((i) => i === id)
          // Delete org id from the parent's children list
          parent.children.splice(childIndex, 1)
          parent.children_count--
        }
      }

      // Delete the org itself
      delete draft[id]
      return
    }

    case str(org.read.success):
    case str(org.update.success):
      draft[payload.id] = merge(draft[payload.id], orgParser(payload.data))
      return

    case str(org.children.read.success):
      // We perform this iteration here instead of in the saga per immer's advice
      // see https://github.com/mweststrate/immer#pitfalls (point #7)
      return setChildListById(draft, payload, 'children', (c) => {
        // Take advantage of this iteration cycle to add child organizations to
        // the top level map
        draft[c.id] = merge(draft[c.id], orgParser(c))
        return c.id
      })

    case str(org.teams.read.success):
      return setChildListById(draft, payload, 'teams')

    case str(org.schedules.read.success):
      return setChildListById(draft, payload, 'schedules')

    case str(org.games.read.success):
      return setChildListById(draft, payload, 'games')
  }
}

export default (state = {}, action) =>
  produce(state, (draft) => {
    entityImageReducer(org.image)(draft, action)
    venuesReducer(org.venues)(draft, action)
    usersReducer(org.users)(draft, action)
    searchReducer(ENTITY_TYPES.org)(draft, action)
    favReducer(fav.org, ENTITY_TYPES.org)(draft, action)
    feedReducer(org.feed)(draft, action)
    officialsReducer(org.officials)(draft, action)

    return reducer(draft, action)
  })
