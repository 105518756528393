/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import cn from 'classnames'
import { Input } from 'antd'

import { Flex } from '../Layout'

const SuspensionInput = ({
  value,
  onChange,
  label,
  required,
  disabled,
  id,
  className,
  placeholder,
  height = 46,
  wrapCss,
  rightAlign,
  ...inputProps
}) => {
  return (
    <Flex className='add-report-row' column css={wrapCss}>
      <label
        htmlFor={id}
        css={css`
          min-height: 12px;
          font-size: 12px;
          line-height: 12px;
          margin-bottom: 8px;
        `}
      >
        {label}
        {required ? ' *' : ''}
      </label>
      <Input
        disabled={disabled}
        className={cn('suspension-search-input', className)}
        placeholder={placeholder}
        css={css`
          padding: 8px 16px;
          height: ${height}px;
          background: #27303e;
          border: ${isCanlan ? 2 : 1}px solid ${colors.SOFT_STEEL};
          border-radius: 4px;
          letter-spacing: normal;

          &:focus,
          &:hover {
            border-color: white;
          }

          ${rightAlign &&
          css`
            text-align: right;
          `}
        `}
        id={id}
        value={value}
        onChange={onChange}
        required
        {...inputProps}
      />
    </Flex>
  )
}

export default SuspensionInput
