import { ROUTES } from '@sportninja/common/constants/app'
import { t } from '@sportninja/common/i18n'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import React, { useEffect, useState } from 'react'
import req from '@sportninja/common/api/request'

import { tutorialKeys } from '@sportninja/common/constants/userGuiding'
import {
  getTutorial,
  setTutorial,
} from '@sportninja/common/utils/TutorialHelper'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import storage from '@sportninja/common/utils/storage'
import { driver } from 'driver.js'
import { observer } from 'mobx-react-lite'
import { getHomeScreenSteps } from 'src/constants/TutorialSteps'
import { useMobxStore } from 'src/state'
import '../../styles/tutorial-styles.scss'
import { RECENTS_KEY_NAME } from '../utils'
import HomeSidebarNewPost from './HomeSidebarNewPost'
import HomeSidebarRecent from './HomeSidebarRecent'
import { SidebarHeader, SidebarInner, SidebarWrap } from './SidebarComponents'

const isRunningOnLocalhost = () => {
  return (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  )
}

const filterForAdminAndUpdatePermission = (response) => {
  const { data, meta } = response
  if (!data || !meta || !meta?.permissions) {
    return []
  }

  const onlyNonSandbox = data.filter(
    (org) => !org.name_full.includes('Sandbox')
  )

  return onlyNonSandbox.filter((d) => {
    const permission = meta.permissions[d?.id]
    return permission?.update || permission?.admin
  })
}

const HomeSidebar = observer(({ user, refreshFeed, isLoggedIn }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const [orgs, setOrgs] = useState([])
  const [scheds, setSched] = useState([])
  const [teams, setTeams] = useState([])

  useEffect(() => {
    setLoading(true)
    setError(false)
    const promises = [
      req('/users/me/organizations'),
      req('/users/me/schedules'),
      req('/users/me/teams'),
    ]

    Promise.all(promises)
      .then(([org, sched, teams]) => {
        setOrgs(filterForAdminAndUpdatePermission(org))
        setSched(filterForAdminAndUpdatePermission(sched))
        setTeams(filterForAdminAndUpdatePermission(teams))
        setLoading(false)
      })
      .catch((e) => {
        setError(e?.message || 'There was a problem.')
      })
  }, [])

  const driverObj = driver({
    allowClose: false,
    popoverClass: 'driverjs-theme',
    showProgress: false,
  })
  const [recents, setRecents] = useState({})
  const {
    me: {
      state: { account, me },
    },
  } = useMobxStore()

  useEffect(() => {
    const startTutorial = async () => {
      try {
        const tutorialInfo = await getTutorial(
          tutorialKeys.NEW_ACCOUNT_WELCOME_TO_SPORTNINJA_TUTORIAL_COUNT
        )
        let tutorialCount = 1
        if (tutorialInfo?.tutorialStep !== null) {
          tutorialCount = Number(tutorialInfo?.tutorialStep?.value)
          if (tutorialCount >= 2) {
            console.log('Return 001', tutorialCount)
            return
          }
          await setTutorial(
            tutorialKeys.NEW_ACCOUNT_WELCOME_TO_SPORTNINJA_TUTORIAL_COUNT,
            tutorialCount + 1
          )
        } else {
          await setTutorial(
            tutorialKeys.NEW_ACCOUNT_WELCOME_TO_SPORTNINJA_TUTORIAL_COUNT,
            1
          )
        }
        if (tutorialCount >= 2) {
          console.log('Return 002', tutorialCount)
          return
        }
        // This value will be only set to true when the user accepts the
        // invite as a business user
        const isBusinessUserSignUp = await getTutorial(
          tutorialKeys.NEW_ACCOUNT_USER_BUSINESS
        )
        if (isBusinessUserSignUp.tutorialStep.value === 'true') {
          setTimeout(() => {
            const steps = getHomeScreenSteps(
              driverObj,
              account?.organization?.url.split('/').pop()
            )
            driverObj.setSteps(steps)
            driverObj.drive()
          }, 1000)
        }
      } catch (e) {
        console.error(e)
      }
    }
    const shouldRun = me && me?.account_id && !isCanlan && isLoggedIn
    if (shouldRun) {
      startTutorial()
    }
  }, [me, storage])

  useEffect(() => {
    try {
      const jsonFromStorage = localStorage.getItem(RECENTS_KEY_NAME)
      if (jsonFromStorage) {
        const object = JSON.parse(jsonFromStorage)
        if (object?.uid === user.id) {
          setRecents(object)
        } else {
          localStorage.removeItem(RECENTS_KEY_NAME)
        }
      }
    } catch (e) {}
  }, [])

  return (
    <SidebarWrap>
      <SidebarInner>
        <SidebarHeader
          imageId={user?.imageId}
          name={user?.fullName}
          subTitle='View profile'
          to={ROUTES.PROFILE.ROOT}
        />
        <HomeSidebarNewPost
          refreshFeed={refreshFeed}
          loading={loading}
          error={error}
          orgs={orgs}
          scheds={scheds}
          teams={teams}
        />
        {orgs.length > 0 && (
          <HomeSidebarRecent
            tutorialId='tutorial-your-league'
            type={`Your League${orgs.length > 1 ? 's' : ''}/Organization${
              orgs.length > 1 ? 's' : ''
            }`}
            iconName='users'
            hideDefaultTitle
            seeMoreRoute={ROUTES.ORGS.ROOT}
            baseRoute={ROUTES.ORG_ROOT}
            recents={orgs.map((org) => ({
              id: org.id,
              fullName: org.name_full,
              imageId: org.image?.full_path,
              onClick: () => {
                driverObj.destroy()
              },
            }))}
            // recents={[
            //   {
            //     id: account?.organization?.url.split('/').pop(),
            //     fullName: account?.organization?.name,
            //     imageId: account?.organization?.image,
            //     onClick: () => {
            //       driverObj.destroy()
            //     },
            //   },
            // ]}
            displayGetStarted={true}
          />
        )}
        {recents[ENTITY_TYPES.org] &&
          recents[ENTITY_TYPES.org].filter(
            (item) => item.id !== account?.organization?.url.split('/').pop()
          ).length > 0 && (
            <HomeSidebarRecent
              type={t('common:organization')}
              iconName='users'
              seeMoreRoute={ROUTES.ORGS.ROOT}
              baseRoute={ROUTES.ORG_ROOT}
              recents={recents[ENTITY_TYPES.org].filter(
                (item) =>
                  item.id !== account?.organization?.url.split('/').pop()
              )}
            />
          )}
        {!isCanlan && me?.account_id ? (
          <HomeSidebarRecent
            type='Sandbox Leagues'
            iconName='users'
            hideDefaultTitle
            seeMoreRoute={ROUTES.SANDBOX}
            baseRoute={ROUTES.SANDBOX}
            recents={[
              {
                id: 'soccer-sandbox-leagues',
                fullName: 'Soccer Demo League',
                onClick: () => {
                  driverObj.destroy()
                },
              },
              {
                id: 'hockey-sandbox-leagues',
                fullName: 'Hockey Demo League',
              },
            ]}
          />
        ) : null}
        {recents[ENTITY_TYPES.schedule] &&
          recents[ENTITY_TYPES.schedule]?.length > 0 && (
            <HomeSidebarRecent
              type={t('common:competition')}
              iconName='calendar-alt'
              seeMoreRoute={ROUTES.SCHEDULES.ROOT}
              baseRoute={ROUTES.SCHEDULE_ROOT}
              recents={recents[ENTITY_TYPES.schedule]}
            />
          )}
        {recents[ENTITY_TYPES.team] &&
          recents[ENTITY_TYPES.team]?.length > 0 && (
            <HomeSidebarRecent
              type={t('common:team')}
              iconName='user-friends'
              seeMoreRoute={ROUTES.TEAMS.ROOT}
              baseRoute={ROUTES.TEAM_ROOT}
              recents={recents[ENTITY_TYPES.team]}
            />
          )}
      </SidebarInner>
    </SidebarWrap>
  )
})

export default HomeSidebar
