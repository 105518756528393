import req from '@sportninja/common/api/request'

class Service {
  async createRegistration({ level = 'schedule', scheduleId, info }) {
    const url =
      level === 'schedule'
        ? `/registration/${scheduleId}/admin`
        : `/registration/${scheduleId}/admin/division`

    const response = await req(url, {
      method: 'POST',
      body: JSON.stringify(info),
    })

    return response.data
  }

  async updateRegistration({ level = 'schedule', scheduleId, info }) {
    const url =
      level === 'schedule'
        ? `/registration/${scheduleId}/admin`
        : `/registration/${scheduleId}/admin/division`
    const response = await req(url, {
      method: 'PUT',
      body: JSON.stringify(info),
    })
    return response.data
  }

  async getRegistrationSettings({ level = 'schedule', scheduleId }) {
    const url =
      level === 'schedule'
        ? `/registration/${scheduleId}/admin`
        : `/registration/${scheduleId}/admin/division`

    const response = await req(url)

    return response.data
  }

  async getDivisions({ scheduleId }) {
    const response = await req(`/registration/${scheduleId}/admin/detailed`)
    const analytics = await req(`/registration/${scheduleId}/admin/analytics`)

    return {
      response: response.data,
      analytics: analytics.data,
    }
  }

  async updateTeamRegStatus({
    scheduleId,
    teamId,
    scheduleRegistrationId,
    status,
    conditions = null,
    conditionType = null,
    divisionId,
    teamRosterId,
  }) {
    let response
    const body = {
      schedule_registration_id: scheduleRegistrationId,
      team_id: teamId,
      conditions,
      condition_type_id: conditionType,
      status,
      team_roster_id: teamRosterId,
    }

    if (divisionId) {
      response = await req(`/registration/${scheduleId}`, {
        method: 'PUT',
        body: JSON.stringify({
          ...body,
          division_id: divisionId,
        }),
      })
    } else {
      response = await req(`/registration/${scheduleId}`, {
        method: 'PUT',
        body: JSON.stringify({
          ...body,
        }),
      })
    }

    return response.data
  }

  async checkIfHasPaymentAvailable() {
    const deposits = await req('/users/me/deposits/pending')
    const installments = await req('/users/me/installments/pending')

    return {
      deposits: deposits.data,
      installments: installments?.data,
    }
  }
}

export const RegistrationService = new Service()
