import { call, put } from 'redux-saga/effects'
import req from '@sportninja/common/api/request'

import { processForm, wait } from '../utils'

const updateApi = async (entityType, id, body) => await req(`/${entityType}/${id}`, { method: 'PUT', body })

const updatePrimaryApi = async (entityType, primaryType, id, body) =>
  await req(`/${entityType}/${id}/${primaryType}/primary`, { method: 'PUT', body })

/**
 * Redux-saga helper to edit an existing entity (e.g. Organization, Team)
 *
 * Calls the provided API request method with the provided form object
 * Automatically calls 'SUCCESS' action on success
 * Automatically updates the current Tab with the entity ID on success
 *
 * @param {function} method - The API request method to call with the form body
 * @param {string} successAction - The redux action to call with the success response from API
 * @param {object} body - Typically the object returned from DetailsForm; maps detail field names to values
 * @param {string} id - Entity ID to use when editing entity information
 * @param {*} [args] - Optional additional arguments to be passed to `method`
 */
function* update(entityType, successAction, id, body, ...args) {
  // Only call set if we have form values to PUT
  if (Object.keys(body).length === 0) return

  if (!body.name_full && body.name) body.name_full = body.name
  else if (!body.name && body.name_full) body.name = body.name_full

  const response = yield call(updateApi, entityType, id, JSON.stringify(body), ...args)

  yield put(successAction({ id, data: response.data, args }))

  return response
}

function* updatePrimary(entityType, primaryType, action, id, primaryId) {
  if (typeof primaryId === 'undefined') return

  const body = JSON.stringify({ id: primaryId })

  try {
    yield call(updatePrimaryApi, entityType, primaryType, id, body)
    yield put(action.success({ id, primaryId }))
  } catch(error) {
    yield put(action.failure({ error }))
  }
}

export const updateEntityFactory = (entityType, actions) => function* (payload) {
  const { id, form: { imageData, delete_image, ...rest } } = payload

  if (typeof imageData !== 'undefined') {
    yield wait(actions.image.create.request, id, imageData)
  } else if (delete_image) {
    yield wait(actions.image.delete.request, id)
  }

  const { body, primaryId, primaryLocation } = processForm(rest)

  const response = yield call(update, entityType, actions.update.success, id, body)

  if (actions.users && actions.users.updatePrimary) yield call(updatePrimary, entityType, 'users', actions.users.updatePrimary, id, primaryId)

  // DEPRECATED
  if (actions.venues && actions.venues.updatePrimary) yield call(updatePrimary, entityType, 'venues', actions.venues.updatePrimary, id, primaryLocation)

  return response
}
