/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import React, { useEffect } from 'react'
import req from '@sportninja/common/api/request'
import { t } from '@sportninja/common/i18n'
import parsers from '@sportninja/common/reducers/parsers'
import { getAllOfficialTypes } from '@sportninja/common/selectors/types'
import { connect } from 'react-redux'

import { officialForm } from '../Form/form-configs'
import FormSheet from '../Form/FormSheet'
import NewButton from '../Page/NewButton'
import { InviteAction } from '../ActionPanel/ActionPanelActions'
import useGetListItems from './useGetListItems'
import OptionButtonEdit from './OptionButtons/Edit'
import OptionButtonDelete from './OptionButtons/Delete'
import List from '.'
import PersonCell from './PersonCell'
import MoreOptions from './OptionButtons/More'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { Empty } from 'antd'

export const AddOfficial = ({
  permission,
  entityType,
  entityId,
  onComplete,
  isSoccer = false,
  isOrg = false,
  sportId = null,
}) => {
  const onAddOfficial = async (...args) => {
    const [values] = args
    const { message_text, official_type_id, ...form } = values
    if (typeof message_text === 'string') {
      form.message = message_text
    }
    if (typeof official_type_id === 'string') {
      form.official_type_id = parseInt(official_type_id, 10)
    } else {
      form.official_type_id = official_type_id
    }
    const body = JSON.stringify(form)
    const response = await req(`/${entityType}/${entityId}/officials`, {
      method: 'POST',
      body,
    })
    onComplete()
    return response
  }

  return (
    permission.admin && (
      <FormSheet
        key='add'
        Button={({ onClick }) => <NewButton onClick={onClick} />}
        form={officialForm({}, isSoccer, isOrg, sportId)}
        label={t('Web:createOfficial')}
        onSubmit={onAddOfficial}
        title={t('GameSetup:createANewOfficial')}
      />
    )
  )
}

const OfficialList = ({
  entityType,
  entityId,
  slug,
  refreshKey,
  isSoccer = false,
  isOrg = false,
  sportId,
  officialTypes,
}) => {
  const listProps = useGetListItems(slug)
  const { sort, direction, getItems, items } = listProps

  useEffect(() => {
    getItems()
  }, [refreshKey, sort, direction])

  const headers = [
    {
      text: t('common:name'),
      sizePercent: 34,
      sort: 'name_first',
      Cell: PersonCell,
    },
    {
      text: t('common:role'),
      sizePercent: 19,
      minWidth: 100,
      sort: 'official_type_id',
      accessor: (item) => {
        return officialTypes.find((t) => t.id === item.official_type)?.name_full
      },
    },
    {
      text: t('common:email'),
      sizePercent: 34,
      minWidth: 240,
      sort: 'email',
      accessor: 'email',
    },
    {
      text: 'Invite Status',
      sizePercent: 13,
      sort: 'is_accepted',
      minWidth: 80,
      accessor: (item) => {
        let status = '-'
        if (item?.is_invited) {
          status = 'Invited'
        }
        if (item?.is_accepted) {
          status = 'Accepted'
        }

        return status
      },
    },
  ]

  const options = (item) => {
    const slug = `/${entityType}/${entityId}/officials/${item.id}`

    const onEdit = async (...args) => {
      const [values] = args

      const body = JSON.stringify(values)
      const response = await req(slug, {
        method: 'PUT',
        body,
      })
      await getItems()
      return response
    }

    const opts = [
      <OptionButtonEdit
        key='edit'
        form={officialForm(parsers.official(item), isSoccer, isOrg, sportId)}
        onSubmit={onEdit}
        title={t('Web:editOfficial')}
      />,
      <InviteAction
        key='invite'
        invite={{
          is_invited: item.is_invited,
          is_accepted: item.is_accepted,
          invitation_id: item.invitation_id,
        }}
        user={{
          id: item.id,
          name_first: item.name_first,
          name_last: item.name_last,
          official_type_id: item.official_type,
          email: item?.email,
        }}
        slug={slug}
        onComplete={getItems}
      />,
      <MoreOptions
        key='more'
        options={[
          {
            key: 'remove',
            Component: OptionButtonDelete,
            componentProps: {
              buttonText: 'remove',
              slug: `/${entityType}/${entityId}/officials/${item.id}`,
              iconName: 'user-minus',
              title: `Remove ${item.full_name}`,
              type: t('common:official'),
              itemId: item.id,
              onComplete: getItems,
            },
            title: 'remove',
          },
        ]}
      />,
    ]

    return opts.filter((o) => o !== undefined)
  }

  return (
    <>
      {items.length > 0 ? (
        <List
          headers={headers}
          options={options}
          listProps={listProps}
          noItemsText={t('common:official', { count: 2 })}
        />
      ) : (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            height: 500px;
          `}
        >
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state, { sportId }) => {
  return {
    officialTypes: getAllOfficialTypes(state),
  }
}

export default connect(mapStateToProps)(OfficialList)
