export type User = {
  id: string
  name: string
}

export type PendingDeposit = {
  id: string
  status: string | null
  created_at: string
  user: User
  root_schedule_id: string
  root_schedule_name: string
  schedule_id: string
  schedule_name: string
  team_id: string
  team_name: string
  team_image: string | null
  deposit_amount: number
  deposit_currency: number
  due_date: string
}

export type PendingInstallment = {
  id: string
  status: string | null
  created_at: string
  user: User
  root_schedule_id: string
  root_schedule_name: string
  schedule_id: string
  schedule_name: string
  team_id: string
  team_name: string
  team_image: string | null
  payment_amount: number
  deposit_currency: number
  due_date: string
}

export interface PlayerConflictOptions {
  uid: string
  sport_id: number | null
  name_full: string
  created_at: string
  updated_at: string
  team_details?: string
}

export interface PlayerConflict {
  daysmart_id: number
  name_full: string
  email: string
  birth_date: string | null
  player_conflicts: PlayerConflictOptions[]
}

export interface TeamConflictOptions {
  uid: string
  name: string
  created_at: string
  updated_at: string
}

export interface TeamConflict {
  daysmart_id: number
  name: string
  team_conflicts: TeamConflictOptions[]
}

export interface FacilityConflictOptions {
  uid: string
  name: string
  created_at: string
  updated_at: string
}

export interface FacilityConflict {
  daysmart_id: number
  name: string
  facility_conflicts: FacilityConflictOptions[]
}

export interface TeamOfficialConflictOptions {
  uid: string
  name: string
  name_full: string
  created_at: string
  updated_at: string
}

export interface TeamOfficialConflict {
  daysmart_id: number
  name: string
  name_full: string
  team_official_conflicts: TeamOfficialConflictOptions[]
}

export interface Conflicts {
  count: number
  players: {
    count: number
    data: PlayerConflict[]
  }
  team_officials: {
    count: number
    data: TeamOfficialConflict[]
  }
  teams: {
    count: number
    data: TeamConflict[]
  }
  facilities: {
    count: number
    data: FacilityConflict[]
  }
}

export interface Success {
  count: number
  data: any[]
}

export interface Failures {
  count: number
  data: any[]
}

export interface DaysmartResponse {
  success: Success
  conflicts: Conflicts
  failures: Failures
}

export type Currency = {
  id: string
  name: string
  name_full: string | null
  abbreviation: string | null
}

export type Division = {
  id: string
  name: string
  schedule_id: string
  is_registration_on: number
  organization_id: string
  organization_name: string
  official_governing_body_event_number: string | null
  currency: Currency
  day_of_week: {
    id: string
    name: string
    name_full: string | null
    abbreviation: string | null
  }
  division_gender: {
    id: string
    name: string
    name_full: string | null
    abbreviation: string | null
  }
  max_teams: number
  team_count: number
  teams: any
  deposit: number
  deposit_required: boolean
  single_payment: boolean
  team_cost: number
  division_age: {
    id: string
    name: string
    name_full: string | null
    abbreviation: string | null
  }
  max_players_per_team: number
  max_team_rep_per_team: number
  installments: number
  installment_start_date: string | null
  installment_frequency: string | null
}

export type RootSchedule = {
  id: string
  name: string
  name_full: string
  type: string
  abbreviation: string
  email: string | null
  website: string | null
  phone: string | null
  starts_at: string
  can_create_referees: boolean
  can_create_scorekeepers: boolean
  ends_at: string
  is_tournament: boolean
  tournament_status: number
  tournament_can_sync_regular_season: boolean
  owned_by_account_id: string
  is_public: boolean
  is_registration_on: boolean
  sport_id: string
  generic_sport_id: string | null
  generic_sport_name: string | null
  organization_id: string
  parent_id: string | null
  image: string | null
  settings: any[]
  deposit: number | null
  online_registration_open: boolean
  season_currency: string | null
  season_type: string | null
  tax_rate: string | null
  division_group: string | null
  division_age: string | null
  division_gender: string | null
  team_cost: number | null
  sort_order: number
  day_of_week: string | null
  is_active: boolean
  salesforce_id: string | null
  is_favorite: boolean
  games_count: number
  teams_count: number
}

export type AvailableTax = {
  id: string
  name: string
  description: string
  tax_rate: string
  currency: Currency
}

export type Registration = {
  id: string
  name: string
  schedule_id: string
  root_schedule_id: string
  root_schedule: RootSchedule
  is_registration_on: boolean
  organization_id: string
  sport_id: string
  organization_name: string
  official_governing_body_event_number: string
  currency: Currency
  standard_cost: number
  payment_currency: Currency
  payment_type: {
    id: string
    name: string
    name_full: string | null
    abbreviation: string | null
  }
  is_live: boolean
  image?: Image
  starts_at: string
  ends_at: string
  custom_link: string
  dark_theme: boolean
  push_notification: boolean
  player_registration_allowed: boolean
  bcc_registrations_to: string
  available_taxes: AvailableTax[]
  taxes: AvailableTax[]
  location: string
}

export type Event = {
  id: string
  name: string
  abbreviation: string
  image?: Image
  type: string
  sport_id: string
  registration: Registration
  divisions: Division[]
  location: string
}

type ImageFormat = {
  id: string
  filename: string
  full_path: string
  mime_type: string
  width: number
  height: number
}

type Image = {
  id: string
  filename: string
  full_path: string
  mime_type: string
  width: number
  height: number
  formats: ImageFormat[]
}

export enum RegistrationSteps {
  CREATED = 'created',
  SELECT_YOUR_DIVISION = 'select_division',
  SIGN_UP = 'sign_up',
  TEAM_REGISTER = 'team_register',
  REGISTRATION_PAYMENT = 'payment',
  VALIDATE_EMAIL = 'validate_email',
  CONFIRM = 'confirm',
}

export interface GameTiming {
  default_period_length: number
  default_overtime_length: number
}

export interface GameSignatures {
  skip_official: boolean
  skip_team_official: boolean
}

export interface GameSettings {
  signatures: GameSignatures
  timing: GameTiming
  skip_lineup: boolean
  require_officials: boolean
}

export interface StatsPoints {
  ties: number
  wins: number
  losses: number
  overtime_loss: number
  overtime_win: number
  shootout_loss: number
}

export interface StatsGoalsProps {
  forfeit: number
}

export interface StatsGoals {
  per: StatsGoalsProps
}

export interface StatsSettings {
  goals: StatsGoals
  points: StatsPoints
}

export interface Settings {
  game: GameSettings
  stats: StatsSettings
}

export interface SportSettings {
  id: string
  name: string
  settings: Settings
}

export interface VersionInfo {
  minimum_version: string
  suggested_version: string
}

export interface ApiInfo {
  current_version: string
}

export interface VersionConfig {
  web: VersionInfo
  mobile: VersionInfo
  api: ApiInfo
}
