/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import { useCallback, useEffect, useState } from 'react'

// import { FormButton } from '../../../../components/Form/css'
import { Flex } from '../../../../components/Layout'
import { playerSort } from '../../../../components/List/PlayerList'
import LoadingSpinner from '../../../../components/LoadingSpinner'
// import AddPlayerPanel from '../AddPlayerPanel'
import RosterEditRow from '../RosterEditRow'
import ConfirmChange from './ConfirmChange'
import RosterEditingTeamHeader from './RosterEditingTeamHeader'

const getShouldWarnIfNotPlaying = (
  playerId,
  goals = [],
  offenses = [],
  goalieChanges = []
) => {
  const hasGoalOrAssist = goals.some(
    (g) =>
      g.shot.player_id === playerId ||
      g.assists.some((a) => a.player.id === playerId)
  )
  const hasOffense = offenses.some(
    (o) =>
      o.player_id === playerId || o.penalty.served_by_player_id === playerId
  )
  const hasGoalieChange = goalieChanges.some(
    (gc) => gc.goalie_id === playerId || gc.previous_goalie_id === playerId
  )
  return { hasGoalOrAssist, hasOffense, hasGoalieChange }
}

const RosterEditingTeam = ({
  gameId,
  gameRosterId,
  playerTypes,
  setDirty,

  goals,
  offenses,
  goalieChanges,
}) => {
  const [gameRoster, setGameRoster] = useState([])
  const [sort, setSort] = useState('player_number')
  const [direction, setDirection] = useState('asc')
  const [isAdding, setIsAdding] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  const onLoad = useCallback(async () => {
    if (!gameId || !gameRosterId) {
      return false
    }

    const response = await req(
      `/games/${gameId}/rosters/${gameRosterId}/players`
    )
    setGameRoster(response.data)
    setLoading(false)
  }, [gameId, gameRosterId])

  useEffect(() => {
    onLoad()
  }, [onLoad])

  const selectedSort = useCallback(
    (a, b) => {
      return playerSort(sort, direction)(a, b)
    },
    [sort, direction]
  )

  const performChange = async (playerId, settings) => {
    setDirty(true)
    const body = JSON.stringify(settings)
    await req(
      `/games/${gameId}/rosters/${gameRosterId}/players/${playerId}`,
      { method: 'PUT', body }
    )
    await onLoad()
  }

  const onPlayerChange = async (playerId, settings) => {
    if (settings.is_injured || !settings.is_playing) {
      const { hasGoalOrAssist, hasOffense, hasGoalieChange } =
        getShouldWarnIfNotPlaying(playerId, goals, offenses, goalieChanges)
      if (hasGoalOrAssist || hasOffense || hasGoalieChange) {
        setIsConfirmationOpen({
          playerId,
          settings,
          hasGoalOrAssist,
          hasOffense,
          hasGoalieChange,
        })
        return
      }
    }

    await performChange(playerId, settings)
  }

  const players = [],
    affiliates = [],
    existingPlayerNums = []

  let startingGoalie = false

  if (Array.isArray(gameRoster?.players)) {
    gameRoster?.players.sort(selectedSort).forEach((player) => {
      if (player.is_starting && player.player_type.is_goalie === 1) {
        startingGoalie = player
      }

      if (player.is_starting || player.is_playing) {
        if (!existingPlayerNums.includes(player.player_number)) {
          existingPlayerNums.push(player.player_number)
        }
      }

      if (player.is_affiliate) {
        affiliates.push(player)
      } else {
        players.push(player)
      }
    })
  }

  return (
    <Flex
      column
      justifyContent='space-between'
      css={css`
        margin-top: 36px;
        min-height: 500px;
        max-height: calc(100vh - 280px);
        overflow-y: auto;
        position: relative;
      `}
    >
      {loading ? (
        <LoadingSpinner
          css={css`
            margin-top: 40px;
          `}
        />
      ) : (
        <>
          <table
            css={css`
              ${isConfirmationOpen &&
              css`
                filter: blur(1.4px);
              `}
              td,
              th {
                text-align: center;
                vertical-align: middle;
                height: 50px;
                border: 1px solid gray;
              }

              tbody:nth-of-type(1) tr:nth-of-type(1) td {
                border-top: none !important;
              }

              thead th {
                border-top: none !important;
                border-bottom: none !important;
                box-shadow: inset 0 1px 0 gray, inset 0 -1px 0 gray;
                padding: 1px 0;
              }

              ${isEditing && 'pointer-events: none;'}
            `}
          >
            <RosterEditingTeamHeader
              setSort={setSort}
              setDirection={setDirection}
              sort={sort}
              direction={direction}
            />
            <tbody
              css={css`
                max-height: calc(100vh - 280px);
                overflow: auto;
              `}
            >
              {players.map((player) => {
                const shouldWarnIfNotPlaying = getShouldWarnIfNotPlaying(
                  player,
                  goals,
                  offenses,
                  goalieChanges
                )
                return (
                  <RosterEditRow
                    key={player.id}
                    player={player}
                    onPlayerChange={onPlayerChange}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    existingPlayerNums={existingPlayerNums}
                    playerTypes={playerTypes}
                    startingGoalie={startingGoalie}
                    shouldWarnIfNotPlaying={shouldWarnIfNotPlaying}
                  />
                )
              })}
              {affiliates.length > 0 && (
                <tr>
                  <td colSpan='6'>Affiliates</td>
                </tr>
              )}
              {affiliates.map((player) => {
                return (
                  <RosterEditRow
                    key={player.id}
                    player={player}
                    onPlayerChange={onPlayerChange}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    existingPlayerNums={existingPlayerNums}
                    playerTypes={playerTypes}
                    startingGoalie={startingGoalie}
                  />
                )
              })}
            </tbody>
          </table>

          {/* <Flex
            noFlex
            justifyContent='center'
            alignItems='center'
            css={css`
              margin-top: 16px;
            `}
          >
            <FormButton
              css={css`
                font-size: 14px;
                height: 40px;
                width: 240px;
              `}
            >
              + Add new player to game roster
            </FormButton>
          </Flex> */}

          {isEditing && (
            <div
              onClick={() => {
                setIsEditing(false)
              }}
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.2);
                z-index: 1;
              `}
            ></div>
          )}
        </>
      )}
      {isConfirmationOpen && (
        <ConfirmChange
          onCancel={() => {
            setIsConfirmationOpen(false)
          }}
          onConfirm={async () => {
            const { playerId, settings } = isConfirmationOpen
            await performChange(playerId, settings)
            setIsConfirmationOpen(false)
          }}
          status={isConfirmationOpen}
        />
      )}
    </Flex>
  )
}

export default RosterEditingTeam
