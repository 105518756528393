import { ROUTES } from '@sportninja/common/constants/app'
import React from 'react'
import { Link } from 'react-router-dom'

import { FormButton } from '../../components/Form/css'
import Helmet from '../../components/Helmet'
import Icon from '../../components/Icon'

import './index.scss'

const NoMatch = () => {
  return (
    <div className='sn-main-content coming-soon flex'>
      <Helmet title='Page Not Found' />
      <h3 className='coming-soon-title'>
        Sorry, this page isn&apos;t available.
      </h3>
      <p className='coming-soon-body'>
        The link you followed may be broken, or the page may have been removed.{' '}
      </p>
      <Icon name='ghost' className='coming-soon-ghost' fontSize={40} />

      <Link className='coming-soon-link' to={ROUTES.HOME_ROOT}>
        <FormButton className='coming-soon-btn'>Go Home</FormButton>
      </Link>
    </div>
  )
}

export default NoMatch
