import req from '@sportninja/common/api/request'

class Service {
  async fetchPaymentMethods(accountId: string) {
    try {
      const response = await req(`/account/${accountId}/payment-method`)

      return response?.data
    } catch (err) {
      console.error(err)
    }
  }

  async createNewCard(accountId: string, stripePaymentId: string) {
    try {
      const response = await req(`/account/${accountId}/payment-method`, {
        method: 'POST',
        body: JSON.stringify({ payment_method_id: stripePaymentId }),
      })

      return response?.data
    } catch (err) {
      console.error(err)
    }
  }

  async updateDefaultCard(accountId: string, cardId: string) {
    try {
      const response = await req(`/account/${accountId}/payment-method`, {
        method: 'PUT',
        body: JSON.stringify({ payment_method_id: cardId }),
      })

      return response?.data
    } catch (err) {
      console.error(err)
    }
  }

  async deleteCard(accountId: string, cardId: string) {
    try {
      const response = await req(`/account/${accountId}/payment-method`, {
        method: 'DELETE',
        body: JSON.stringify({ payment_method_id: cardId }),
      })

      return response?.data
    } catch (err) {
      console.error(err)
    }
  }
}

export const PaymentService = new Service()
