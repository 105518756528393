import { generateActions } from './utils'

// A list of all available actions for "favorites". The array describes
// the arguments that are passed to the REQUEST action
const favActionDefinition = {
  org: {
    create:    [ 'id' ],
    readAll:   [ 'page', 'sort', 'direction' ],
    delete:    [ 'id' ]
  },

  schedule: {
    create:    [ 'id' ],
    readAll:   [ 'page', 'sort', 'direction' ],
    delete:    [ 'id' ]
  },

  team: {
    create:    [ 'id' ],
    readAll:   [ 'page', 'sort', 'direction' ],
    delete:    [ 'id' ]
  }
}

const favActions = generateActions(['FAV'], favActionDefinition)

export default favActions
