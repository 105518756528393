import React from 'react'

import { Mobile } from '../Responsive'
import DesktopCard from './DesktopCard'
import MobileCard from './MobileCard'

const Card = ({ ...props }) => {
  return (
    <Mobile>
      {(isMobile) => isMobile ?
        <MobileCard {...props} />
        :
        <DesktopCard {...props} />
      }
    </Mobile>
  )
}

export default Card
