/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { useEffect, useState } from 'react'
import Icon from '../Icon'

interface Props {
  onChange: (value: boolean) => void
  label: string
}

export const CheckBox = ({ onChange, label }: Props) => {
  const [selected, setSelected] = useState(false)

  useEffect(() => {
    onChange(selected)
  }, [selected])

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 8px;
      `}
    >
      <div
        onClick={() => setSelected(!selected)}
        css={css`
          cursor: pointer;
          width: 20px;
          height: 20px;
          border: 1px solid ${colors.DEFAULT_FLAIR};
          border-radius: 4px;
          background-color: ${selected ? colors.DEFAULT_FLAIR : 'transparent'};
          justify-content: center;
          align-items: center;
          display: flex;
        `}
      >
        {selected && (
          <Icon name='check' color={colors.HEADER_BG_2} fontSize={15} />
        )}
      </div>
      <p
        css={css`
          color: ${colors.WHITE};
          font-size: 14px;
        `}
      >
        {label}
      </p>
    </div>
  )
}
