/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import { ROUTES, baseRoles } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import useGetPlayerTeams from '@sportninja/common/hooks/use-get-player-teams'
import { getAllPlayerTypes } from '@sportninja/common/selectors/types'
import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Flex } from '../../components/Layout'
import LoadingSpinner from '../../components/LoadingSpinner'
import Picture from '../../components/Picture'
import { font } from '../../components/css'
import { Title } from './StatsBySeason'
import { getMembershipsByRoles } from '@sportninja/common/utils/utils'

const Card = ({ to, iconName, imageId, title, lineOne, subtitles = [] }) => {
  return (
    <Flex
      noFlex
      as={Link}
      alignItems='center'
      to={to}
      css={css`
        width: 304px;
        min-height: 88px;

        background: ${colors.HEADER};
        border: 1px solid ${colors.SOFT_STEEL};
        border-radius: 8px;
        margin-right: 16px;
        margin-bottom: 16px;
        padding: 16px;

        &:last-of-type {
          margin: 0;
        }

        &:hover {
          border-color: white;
        }
      `}
    >
      <Picture square fit imageId={imageId} iconName={iconName} />
      <Flex
        column
        noFlex
        justifyContent='center'
        css={css`
          margin-left: 8px;
        `}
      >
        <p
          css={css`
            font-weight: 700;
            font-size: 16px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          `}
        >
          {title}
        </p>
        {lineOne && (
          <p
            css={css`
              margin-top: 4px;
              font-weight: 500;
              font-size: 12px;
              color: ${colors.LINK_WATER};
            `}
          >
            {lineOne}
          </p>
        )}
        {subtitles.map((item, index) => (
          <p
            key={index}
            css={css`
              margin-top: 4px;
              font-weight: 500;
              font-size: 12px;
              color: ${colors.LINK_WATER};
            `}
          >
            {item}
          </p>
        ))}
      </Flex>
    </Flex>
  )
}

const ProfileMembership = ({ playerTypes }) => {
  const [loading, setLoading] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)
  const [roles, setRoles] = useState([])
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState(null)

  const loadUserInfo = useCallback(
    async (lPage = 1, shouldRefresh = true) => {
      try {
        if (shouldRefresh) {
          setLoading(true)
        }
        const { data, meta } = await req(`/users/me/user-roles?page=${lPage}`)
        const lData = [...roles, ...data]
        setRoles(lData)
        setPagination(meta.pagination)
      } finally {
        if (shouldRefresh) {
          setLoading(false)
        }
      }
    },
    [page, roles]
  )

  const loadMore = async () => {
    if (page && pagination && pagination && page < pagination.total_pages) {
      setLoadingMore(true)
      setPage(page + 1)
      loadUserInfo(page + 1, false).finally(() => {
        setLoadingMore(false)
      })
    }
  }

  useEffect(() => {
    loadUserInfo()
  }, [])

  const [playerTeams] = useGetPlayerTeams(playerTypes)

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        min-height: 98px;
        margin-top: 24px;
        padding: 20px 16px;
        background-color: ${colors.HEADER};
        border: 1px solid ${colors.SOFT_STEEL};
        border-radius: 8px;
      `}
    >
      {loading ? (
        <LoadingSpinner size={4} />
      ) : roles.length === 0 && playerTeams.length === 0 ? (
        <Flex alignItems='center' justifyContent='center'>
          There are no roles or team memberships to display
        </Flex>
      ) : (
        <>
          <Title>
            Membership{' '}
            {pagination && pagination?.count ? `(${pagination.count})` : ''}
          </Title>
          <Flex
            flexWrap
            css={css`
              margin-top: 24px;
            `}
          >
            {roles.map((item) => {
              let subtitles = getMembershipsByRoles(item?.roles)
              let iconName, to
              if (item.type === 'team') {
                iconName = 'user-friends'
                to = `${ROUTES.TEAM_ROOT}/${item.id}`
              } else if (item.type === 'schedule') {
                iconName = 'calendar-alt'
                to = `${ROUTES.SCHEDULE_ROOT}/${item.id}`
              } else if (item.type === 'organization') {
                iconName = 'users'
                to = `${ROUTES.ORG_ROOT}/${item.id}`
              } else {
                iconName = 'user-friends'
                to = `${ROUTES.PLAYER_ROOT}/${item.id}`
              }
              return (
                <Card
                  to={to}
                  key={`${item.id}-${item.role_type_id}`}
                  title={item?.name_full}
                  lineOne={item?.organization?.name_full}
                  imageId={item?.image}
                  iconName={iconName}
                  subtitles={subtitles}
                />
              )
            })}
          </Flex>
        </>
      )}
      {page && pagination && pagination && page < pagination.total_pages ? (
        <button
          css={css`
            display: flex;
            align-self: center;
            align-items: center;
            justify-content: center;
            width: 30%;
            height: 36px;
            margin-top: 24px;
            border: 2px solid ${colors.DEFAULT_FLAIR};
            border-radius: 8px;
            color: ${colors.WHITE};
            ${font.title}
            font-weight: 700;
            font-size: 16px;
            ${loadingMore &&
            css`
              cursor: wait;
            `}
          `}
          onClick={async () => loadMore()}
        >
          {loadingMore ? 'Loading...' : 'Load More'}
        </button>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    playerTypes: getAllPlayerTypes(state),
  }
}

export default connect(mapStateToProps)(ProfileMembership)
