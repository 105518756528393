import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const PasswordReveal = ({ className, onClick, passwordRevealed }) => (
  <div
    aria-label='show password button'
    className={cn('password-reveal icon', className)}
    onClick={onClick}
    title='show password button'>
    <i className={`fas fa-${passwordRevealed ? 'eye-slash' : 'eye'}`} />
  </div>
)

PasswordReveal.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  passwordRevealed: PropTypes.bool.isRequired
}

export default PasswordReveal
