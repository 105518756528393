import React from 'react'
import { SelectFormInput } from '../Select'

const GenericSelect = ({ options = [], disabled, disabledByDefault = false, ...selectFormProps }) => {
  return (
    <SelectFormInput disabled={disabledByDefault || disabled} {...selectFormProps}>
      <option value='' disabled></option>
      {options && options.map((o) => {
        return <option key={o} value={o}>{o}</option>
      })}
    </SelectFormInput>
  )
}

export default GenericSelect
