/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Input as AntInput } from 'antd'
import React from 'react'
import { paragraphSmall, weightRegular } from 'src/components/css'
import { ErrorLabel } from '../ErrorLabel'

type TextInputProps = {
  name: string
  value: string
  setValue: (value: string) => void
  fieldErrors: any
  customError: any
  label: string
  type?: string
  maxLength?: number
  disabled?: boolean
  autoComplete?: string
}

export const TextInput: React.FC<TextInputProps> = ({
  name,
  value,
  setValue,
  fieldErrors,
  customError,
  label,
  type = 'text',
  maxLength = 255,
  disabled = false,
  autoComplete = 'off',
}) => {
  return (
    <>
      <p
        css={css`
          ${paragraphSmall}
          ${weightRegular}
                color: ${colors.WHITE};
          align-self: stretch;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        `}
      >
        {label}
      </p>
      <AntInput
        status={fieldErrors[name] || fieldErrors?.includes(name) ? 'error' : ''}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        type={type}
        maxLength={maxLength}
        disabled={disabled}
        autoComplete={autoComplete}
      />
      <ErrorLabel
        fieldErrors={fieldErrors}
        customError={customError}
        name={name}
      />
    </>
  )
}
