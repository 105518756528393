/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { RegistrationSettingsTitle } from '../Schedule/EditRegistration'
import { Flex } from '../../components/Layout'
import SuspensionSelect from '../../components/Suspensions/SuspensionSelect'
import SuspensionInput from '../../components/Suspensions/SuspensionInput'
import { useCallback, useState, useEffect } from 'react'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
// import DatePicker from '../../components/DatePicker'
import ToggleSection from '../Schedule/EditRegistration/ToggleSection'
import FormInput from '../../components/Form/FormInput'
import Icon from '../../components/Icon'
import SuspensionSubmitButton from '../../components/Suspensions/SuspensionSubmitButton'
import { useMobxStore } from '../../state'
import { Link, useHistory, useLocation } from 'react-router-dom'
import CurrencyInput from '../../components/CurrencyInput'
import req from '@sportninja/common/api/request'
import { Select, Input, DatePicker, Alert } from 'antd'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const checkConnectedAccount = async (accountId) => {
  const response = await req(`/account/${accountId}/onboarding`)

  return response
}

const SetupRegistration = () => {
  // LEFT COLUMN
  const [enableRegistration, setEnableRegistration] = useState(false)
  const [registrationBy, setRegistrationBy] = useState(1)
  const [maxTeamSlots, setMaxTeamSlots] = useState(0)
  const [currency, setCurrency] = useState(1)
  const [costPerRegistration, setCostPerRegistration] = useState(null)
  const [eventNumber, setEventNumber] = useState(null)
  // const [dateFormat, setDateFormat] = useState(0)
  const [dateRange, setDateRange] = useState({
    starts_at: dayjs().utc(),
    ends_at: dayjs().utc(),
  })

  // RIGHT COLUMN
  const [paymentCurrency, setPaymentCurrency] = useState(1)
  const [paymentMethod, setPaymentMethod] = useState(2)
  const [useDarkTheme, setUseDarkTheme] = useState(false)
  const [goLive, setGoLive] = useState(false)
  const [backgroundImage, setBackgroundImage] = useState(null)
  // const [sendPush, setSendPush] = useState(false)
  const [allowPlayerRegistration, setAllowPlayerRegistration] = useState(false)
  const [taxes, setTaxes] = useState([])
  const [isVerifyingConnectedAccount, setIsVerifyingConnectedAccount] =
    useState(true)

  // New Stuff
  const [bccEmailInput, setBccEmailInput] = useState('')
  const [bccEmails, setBccEmails] = useState([])
  const [defaultTaxes, setDefaultTaxes] = useState([])

  const location = useLocation()
  const history = useHistory()

  const {
    registrationTypes: {
      state: { currency_type, registration_payment_issue_type },
    },
    registration: {
      createRegistration,
      state: { scheduleOwner, availableTaxes },
    },
    me: {
      state: { account, me, connectedAccount },
      setConnectedAccount,
    },
  } = useMobxStore()

  const [connectedAccountError, setConnectedAccountError] = useState({
    onboarded: connectedAccount,
    link: '',
  })

  const saveBackgroundImage = useCallback(async () => {
    try {
      let reader = new FileReader()

      reader.onloadend = async () => {
        const imageData = reader.result
        if (!imageData) {
          return
        }

        await req(`/registration/${location.state?.scheduleId}/admin/image`, {
          method: 'POST',
          body: imageData,
        })
      }

      reader.readAsDataURL(backgroundImage)
    } catch (err) {
      console.error('There was an error uploading the image', err)
    }
  }, [backgroundImage, location.state?.scheduleId])

  const onConfirmPress = useCallback(() => {
    createRegistration({
      level: 'schedule',
      scheduleId: location.state?.scheduleId,
      info: {
        player_registration_allowed: allowPlayerRegistration,
        registration_by: registrationBy,
        is_registration_on: enableRegistration,
        official_governing_body_event_number: eventNumber,
        currency: currency,
        standard_cost: costPerRegistration,
        payment_currency: paymentCurrency,
        payment_type: paymentMethod,
        is_live: goLive,
        starts_at: dateRange.starts_at,
        ends_at: dateRange.ends_at,
        custom_link: 'SportNinja',
        dark_theme: useDarkTheme,
        push_notification: false,
        max_teams: maxTeamSlots || 0,
        taxes: defaultTaxes?.map((el) => ({ id: el })) || [],
        bcc_registrations_to: bccEmails ? bccEmails?.join(',') : '',
      },
    })
  }, [
    enableRegistration,
    maxTeamSlots,
    currency,
    costPerRegistration,
    eventNumber,
    allowPlayerRegistration,
    dateRange,
    goLive,
    registrationBy,
    paymentCurrency,
    paymentMethod,
    useDarkTheme,
    taxes,
    defaultTaxes,
    bccEmails,
  ])

  useEffect(() => {
    if (!connectedAccount) {
      checkConnectedAccount(scheduleOwner || me?.account_id)
        .then((response) => {
          setConnectedAccount(response?.onboarded)
          setConnectedAccountError({
            link: response?.url || '',
            onboarded: response?.onboarded,
          })
        })
        .catch((e) => {
          if (me?.account_id) {
            console.error(e)
            alert(
              `There was an error loading the registration. ${
                e?.message || ''
              } Please try again.`
            )
          }
        })
        .finally(() => {
          setIsVerifyingConnectedAccount(false)
        })
    } else {
      setIsVerifyingConnectedAccount(false)
    }
  }, [me?.account_id, connectedAccount, scheduleOwner, setConnectedAccount])

  if (!location.state?.scheduleId) {
    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-top: 10%;
        `}
      >
        <p>{"You don't have permission to access it"}</p>
        <button onClick={() => history.goBack()}>
          <p
            css={css`
              margin-top: 20px;
              color: ${colors.DEFAULT_FLAIR};
            `}
          >
            Go back
          </p>
        </button>
      </div>
    )
  }

  return (
    <section
      id='registration'
      css={css`
        width: 100%;
        height: 100%;

        @media (max-width: 768px) {
          padding-bottom: 50px;
          overflow-y: auto;
        }
      `}
    >
      <div
        id='registration-body'
        css={css`
          display: flex;
          border-bottom: 1px solid ${colors.SOFT_STEEL};
          max-width: 70%;
          margin: 0 auto;
          padding: 80px 0 32px 0;

          @media (max-width: 768px) {
            flex-direction: column;
          }
        `}
      >
        <div
          id='registration-body-left'
          css={css`
            flex: 1;
            padding-right: 20px;
            min-width: 50%;
          `}
        >
          {/* We removed the toggle from here since now we are handling this at the competition form */}
          {/* <div
            css={css`
              margin-bottom: 20px;
            `}
          >
            <ToggleSection
              title='Registration Option'
              description='When turned ON, users can use the registration flow to create or include their teams for this competition.'
              isEnabled={enableRegistration}
              onClick={() => setEnableRegistration((o) => !o)}
              loading={isVerifyingConnectedAccount}
              disabled={
                isVerifyingConnectedAccount || !connectedAccountError.onboarded
              }
            />
            {!connectedAccountError.onboarded &&
              !isVerifyingConnectedAccount && (
                <div
                  css={css`
                    .ant-alert-message {
                      padding: 0 10px 0 0;
                    }
                  `}
                >
                  <Alert
                    message={
                      me?.account_id === scheduleOwner
                        ? 'Please configure your payment provider to enable registration.'
                        : 'Please ask the league owner to configure the payment provider to enable registration.'
                    }
                    type='warning'
                    showIcon
                    action={
                      me?.account_id === scheduleOwner && (
                        <Link
                          css={css`
                            color: ${colors.DEFAULT_FLAIR};
                            display: inline-flex;
                            gap: 8px;
                            align-items: baseline;
                          `}
                          to={'/account?origin=onboarding'}
                        >
                          Configure Payment
                          <Icon
                            name='external-link'
                            fontSize={14}
                            color={colors.DEFAULT_FLAIR}
                          />
                        </Link>
                      )
                    }
                    css={css`
                      margin-top: 16px;
                    `}
                  />
                </div>
              )}
          </div> */}
          {!connectedAccountError.onboarded && !isVerifyingConnectedAccount && (
            <div
              css={css`
                margin-bottom: 20px;
              `}
            >
              <div
                css={css`
                  .ant-alert-message {
                    padding: 0 10px 0 0;
                  }
                `}
              >
                <Alert
                  message={
                    me?.account_id === scheduleOwner
                      ? 'Please configure your payment provider to enable registration.'
                      : 'Please ask the league owner to configure the payment provider to enable registration.'
                  }
                  type='warning'
                  showIcon
                  action={
                    me?.account_id === scheduleOwner && (
                      <Link
                        css={css`
                          color: ${colors.DEFAULT_FLAIR};
                          display: inline-flex;
                          gap: 8px;
                          align-items: baseline;
                        `}
                        to={'/account?origin=onboarding'}
                      >
                        Configure Payment
                        <Icon
                          name='external-link'
                          fontSize={14}
                          color={colors.DEFAULT_FLAIR}
                        />
                      </Link>
                    )
                  }
                  css={css`
                    margin-top: 16px;
                  `}
                />
              </div>
            </div>
          )}
          {/* <RegistrationSettingsTitle>General Info</RegistrationSettingsTitle> */}
          <RegistrationSettingsTitle>
            Registration Start / End
          </RegistrationSettingsTitle>
          <div
            css={css`
              display: flex;
              gap: 20px;
              margin-bottom: 18px;

              @media (max-width: 768px) {
                flex-direction: column;
                gap: 0;
                margin: 8px 0 12px 0;
              }
            `}
          >
            <div
              css={css`
                flex: 1;
                min-width: 290px;
                .ant-picker {
                  background-color: ${colors.HEADER};
                }
              `}
            >
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                size='large'
                placeholder={['Registration Start', 'Registration End']}
                onChange={(e) => {
                  if (e) {
                    const [starts_at, ends_at] = e
                    setDateRange({
                      starts_at: dayjs(starts_at).utc().format('YYYY-MM-DD'),
                      ends_at: dayjs(ends_at).utc().format('YYYY-MM-DD'),
                    })
                  }
                }}
              />
            </div>
          </div>
          <div
            css={css`
              margin-top: 16px;
              margin-bottom: 16px;
            `}
          >
            <ToggleSection
              title='Go Live'
              description={
                goLive
                  ? 'Registration Feature will be immediately available.'
                  : dateRange?.starts_at && dateRange?.ends_at
                  ? `Registration Feature will be available between ${dayjs(
                      dateRange?.starts_at
                    )?.format('MMMM DD, YYYY')} to ${dayjs(
                      dateRange?.ends_at
                    )?.format('MMMM DD, YYYY')}`
                  : 'You can go live when you are ready to accept registrations.'
              }
              isEnabled={goLive}
              onClick={() => setGoLive((o) => !o)}
            />
          </div>
          <div
            css={css`
              display: flex;
              gap: 20px;

              @media (max-width: 768px) {
                flex-direction: column;
                gap: 0;
                margin: 8px 0 12px 0;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                flex: 1;
                gap: 8px;
                margin-bottom: 16px;

                .ant-select {
                  background-color: ${colors.HEADER};
                }
              `}
            >
              <p>Registration by *</p>
              <Select
                value={registrationBy}
                onChange={(e) => {
                  setRegistrationBy(e)
                }}
                options={[
                  { label: 'Per Team', value: 1 },
                  // Removing based on decision made in a meeting on
                  // 2024-03-27
                  // { label: 'Per Player', value: 2 },
                ]}
              />
            </div>
          </div>
          <div
            css={css`
              margin-bottom: 20px;
            `}
          >
            <ToggleSection
              title='Allow Player Registration'
              description='Players will be able to register themselves on a team with an invite.'
              isEnabled={allowPlayerRegistration}
              onClick={() => setAllowPlayerRegistration((o) => !o)}
            />
          </div>
          {/* <div
            css={css`
              display: flex;
              flex-direction: column;
              flex: 1;
              gap: 8px;
              margin-bottom: 16px;

              .ant-input {
                background-color: ${colors.HEADER};
              }
            `}
          >
            <p>Official Governing Body Event Number (Canada/US)</p>
            <Input
              type='number'
              min={0}
              step={1}
              value={eventNumber}
              onChange={({ target }) => setEventNumber(target.value)}
            />
            <div
              css={css`
                margin-top: 8px;
              `}
            />
            <p>Taxes</p>
            <div
              css={css`
                .ant-select {
                  background-color: ${colors.HEADER};
                  width: 100%;
                }
              `}
            >
              <Select
                mode='multiple'
                showSearch={false}
                onChange={(e) => {
                  setTaxes(e.map((el) => ({ id: el })))
                }}
                options={availableTaxes?.map((el) => ({
                  label: el.name,
                  value: el.id,
                }))}
              />
            </div>
          </div> */}
          <div
            css={css`
              display: flex;
              flex-direction: column;
              flex: 1;
              gap: 8px;
              margin-bottom: 16px;

              .ant-input {
                background-color: ${colors.HEADER};
              }
            `}
          >
            <p>
              BCC Registration Emails: (comma separated, press Enter to add)
            </p>
            <Input
              type='email'
              value={bccEmailInput}
              onChange={({ target }) => setBccEmailInput(target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  // Also add to the email list
                  // also check if the email is not already in the list
                  if (!bccEmails.includes(bccEmailInput)) {
                    const emails = bccEmailInput.split(',')
                    setBccEmails([...bccEmails, ...emails])
                  }
                  // clear the input
                  setBccEmailInput('')
                }
              }}
            />
            {/* Render the bcc emails side by side, separated by commas. It also should have an X button to remove the email */}
            <div
              css={css`
                display: flex;
                flex-wrap: wrap;
                gap: 8px;
              `}
            >
              {bccEmails.map((email) => (
                <div
                  key={email}
                  css={css`
                    background-color: ${colors.HEADER};
                    border-radius: 4px;
                    padding: 8px;
                    display: flex;
                    flex-direction: row;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                  `}
                >
                  {email}
                  <div
                    css={css`
                      cursor: pointer;
                      margin-left: 8px;
                      color: ${colors.FIERY_RED};
                    `}
                    onClick={() => {
                      setBccEmails(bccEmails.filter((e) => e !== email))
                    }}
                  >
                    X
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          id='registration-body-right'
          css={css`
            flex: 1;
            padding-left: 20px;
            min-width: 50%;

            @media (max-width: 768px) {
              padding-left: 0px;
              margin-top: 20px;
            }
          `}
        >
          <RegistrationSettingsTitle>
            Payments From SportNinja
          </RegistrationSettingsTitle>
          <div
            css={css`
              display: flex;
              width: 100%;
              justify-content: space-between;

              @media (max-width: 768px) {
                flex-direction: column;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                flex: 1;
                gap: 8px;
                margin-bottom: 16px;

                .ant-select {
                  background-color: ${colors.HEADER};
                }
              `}
            >
              <p>Currency</p>
              <Select
                value={paymentCurrency}
                onChange={(e) => {
                  setPaymentCurrency(e)
                }}
                options={currency_type.map((el) => ({
                  label: el.name,
                  value: Number(el.id),
                }))}
              />
            </div>
            <div
              css={css`
                width: 16px;
              `}
            />
            <div
              css={css`
                display: flex;
                flex-direction: column;
                flex: 1;
                gap: 8px;
                margin-bottom: 16px;

                .ant-select {
                  background-color: ${colors.HEADER};
                }
              `}
            >
              <p>Issue payment as</p>
              <Select
                value={paymentMethod}
                onChange={(e) => {
                  setPaymentMethod(e)
                }}
                options={registration_payment_issue_type.map((el) => ({
                  label: el.name,
                  value: Number(el.id),
                }))}
              />
            </div>
          </div>
          <p>Taxes</p>
          <div
            css={css`
              .ant-select {
                background-color: ${colors.HEADER};
                width: 100%;
                margin-bottom: 8px;
                margin-top: 8px;
              }
            `}
          >
            <Select
              mode='multiple'
              value={defaultTaxes}
              showSearch={false}
              onChange={(e) => {
                if (e) {
                  setDefaultTaxes(e)
                }
              }}
              options={availableTaxes?.map((el) => ({
                label: el.name,
                value: el.id,
              }))}
            />
          </div>
          <RegistrationSettingsTitle
            css={css`
              margin-top: 20px;
            `}
          >
            Extra Options
          </RegistrationSettingsTitle>
          <div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-right: 16px;
                flex: 1;

                input {
                  display: inline;
                  appearance: none;
                  opacity: 0;
                  width: 1px;
                }
              `}
            >
              <span
                css={css`
                  font-weight: 500;
                  font-size: 14px;
                  margin-bottom: 8px;
                `}
              >
                Custom Cover
              </span>
              <Flex>
                <label
                  htmlFor='background-image'
                  css={css`
                    display: flex;
                    flex: 1;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px 16px;
                    height: 40px;
                    background: ${colors.HEADER};
                    border: 1px solid ${colors.SOFT_STEEL};
                    border-radius: 4px;
                    cursor: pointer;

                    transition: border-color 0.15s ease-in-out;

                    i {
                      transition: color 0.15s ease-in-out;
                    }

                    &:hover {
                      border-color: ${colors.DEFAULT_FLAIR};

                      i {
                        color: white;
                      }
                    }
                  `}
                >
                  {backgroundImage?.name || 'Select an image'}
                  <Icon
                    name='file-upload'
                    color={colors.DEFAULT_FLAIR}
                    fontSize={16}
                  />
                </label>

                <input
                  type='file'
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      setBackgroundImage(e.target.files[0])
                    }
                  }}
                  id='background-image'
                />
              </Flex>
            </div>
            {/* <div
              css={css`
                width: 100%;
                margin-top: 35px;
              `}
            >
              <ToggleSection
                title='Dark Theme'
                description='A dark theme will set Dark background and text in white.'
                isEnabled={useDarkTheme}
                onClick={() => setUseDarkTheme((o) => !o)}
              />
            </div> */}
          </div>
        </div>
      </div>
      <div
        id='registration-footer'
        css={css`
          margin-top: 32px;
        `}
      >
        <Flex alignItems='center' justifyContent='center'>
          <SuspensionSubmitButton onClick={() => history.goBack()}>
            Cancel
          </SuspensionSubmitButton>
          <SuspensionSubmitButton
            disabled={!dateRange.starts_at || !dateRange.ends_at}
            onClick={async () => {
              onConfirmPress()
              await saveBackgroundImage()
              history.goBack()
            }}
            isSolid
            css={css`
              margin-left: 40px;
            `}
          >
            Confirm
          </SuspensionSubmitButton>
        </Flex>
      </div>
    </section>
  )
}

export default SetupRegistration
