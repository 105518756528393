/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useEffect, useState } from 'react'
import colors from '@sportninja/common/constants/appColors'
import req from '@sportninja/common/api/request'
import { t } from '@sportninja/common/i18n'

import { font, zIndex } from '../../../components/css'
import { FormButton } from '../../../components/Form/css'
import { Flex } from '../../../components/Layout'
import Icon from '../../../components/Icon'
import LoadingSpinner from '../../../components/LoadingSpinner'
import dayjs from 'dayjs'

const RosterEntry = ({ children, onClick, isActive }) => {
  return (
    <Flex
      noFlex
      alignItems='center'
      justifyContent='space-between'
      onClick={onClick}
      css={css`
        flex-shrink: 0;
        min-height: 40px;
        margin-bottom: 16px;
        padding: 10px 12px;
        border-radius: 4px;
        transition: background-color 0.2s ease-in-out;

        background-color: rgba(255, 255, 255, ${isActive ? 0.1 : 0.05});

        ${typeof onClick === 'function' &&
        css`
          cursor: pointer;

          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
        `}
      `}
    >
      {children}
      {isActive && <Icon name='check-circle' color={colors.GAME_SETUP_GREEN} />}
    </Flex>
  )
}

const EditPanelRosterSelect = ({
  error,
  onChange,
  onSave,
  sourceRosterForCopy,
  setSourceRosterForCopy,
  teamId,
  loading: submitting,
  scheduleId,
}) => {
  const [loading, setLoading] = useState(true)
  const [rosters, setRosters] = useState([])
  const [hasExistingCompRoster, setHasExistingCompRoster] = useState(false)
  const [team, setTeam] = useState(null)

  const isPlaceholderTeam = (t) => {
    if (!t) return false
    if (t?.is_placeholder === undefined) {
      console.error("Team is missing is_placeholder attribute")
      return false
    }
    return t?.is_placeholder
  }

  useEffect(() => {
    const getTeam = async () => {
      setLoading(true)
      try {
        const response = await req(`/teams/${teamId}`)
        if (response && response?.data) {
          setTeam(response?.data)
        } else {
          setTeam(null)
        }
      } catch (e) {
        console.error(e)
        setTeam(null)
      }finally {
        setLoading(false)
      }
    }
    if (teamId) {
      getTeam()
    }
  }, [teamId])


  useEffect(() => {
    const getRosters = async () => {
      setLoading(true)

      try {
        const data = await req(`/teams/${teamId}/rosters`)
        const mapped = data.map(({ uid, ...d }) => {
          return {
            ...d,
            id: uid,
          }
        })

        const existingRoster = mapped.find((m) => {
          return m.schedule_uid === scheduleId
        })

        setHasExistingCompRoster(existingRoster)
        setRosters(mapped)
      } finally {
        setLoading(false)
      }
    }
    if (teamId) {
      getRosters()
    }
  }, [teamId])

  return (
    <div
      css={css`
        position: absolute;
        z-index: ${zIndex.base};
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      `}
    >
      <div
        css={css`
          padding: 20px;
          display: flex;
          flex-direction: column;
          flex: 1;
          height: 100%;
        `}
      >
        <h1
          css={css`
            ${font.title};
            font-size: 24px;
            font-weight: bold;
          `}
        >
          {t('common: competition')} Roster Creation
        </h1>

        {loading ? (
          <LoadingSpinner
            size={3}
            borderSize={2}
            css={css`
              margin: 24px 0;
            `}
          />
        ) : hasExistingCompRoster ? (
          <div
            css={css`
              margin: 12px 0;
              line-height: 15px;
            `}
          >
            <p
              css={css`
                margin-bottom: 12px;
              `}
            >
              We found an existing roster linked to this competition.
              You&apos;re all set!
            </p>
            <RosterEntry>{hasExistingCompRoster?.name}</RosterEntry>
          </div>
        ) : (
          <>
            <p
              css={css`
                margin: 12px 0;
                line-height: 15px;
              `}
            >
              {
                isPlaceholderTeam(team) ? (
                  <>
                    You&apos;re creating a new {t('common:competition')} for your team. 
                    <br />
                    <br />
                  </>
                ) : (
                  <>
                    We need to create a new roster for this {t('common:competition')}.
                    Select an existing team roster to clone or create a new roster below.
                  </>
                )
              }
            </p>
            <Flex
              column
              css={css`
                margin-bottom: 16px;
                overflow: auto;
              `}
            >
              {
                !isPlaceholderTeam(team) ? (
                  <>
                    {
                      rosters && rosters.length > 0 ? (
                        <>
                          <p
                            css={css`
                              ${font.title}
                              font-weight: bold;
                              margin-bottom: 12px;
                            `}
                          >
                            Select an Existing Roster
                          </p>
                          <div
                            css={css`
                              overflow: auto;
                              border: 1px solid ${colors.SOFT_STEEL};
                              border-radius: 4px;
                              padding: 16px 16px 0px 16px;
                            `}
                          >
                            {rosters.length > 0 &&
                              rosters.map((roster) => {
                                let subTitle = 'Unlinked Roster'

                                if (roster.starts_at) {
                                  const starts = dayjs(
                                    roster.starts_at,
                                    'YYYY-MM-DD hh:mm:ss'
                                  )
                                  const ends = dayjs(roster.ends_at, 'YYYY-MM-DD hh:mm:ss')
                                  subTitle = `${starts.format(
                                    'MMM D, YYYY'
                                  )} - ${ends.format('MMM D, YYYY')}`
                                }
                                return (
                                  <RosterEntry
                                    key={roster.id}
                                    onClick={() => {
                                      setSourceRosterForCopy(
                                        sourceRosterForCopy === roster.id
                                          ? false
                                          : roster.id
                                      )
                                    }}
                                    isActive={sourceRosterForCopy === roster.id}
                                  >
                                    <div>
                                      {roster?.schedule_name || roster.name}
                                      <p
                                        css={css`
                                          margin-top: 2px;
                                          opacity: 0.7;
                                        `}
                                      >
                                        {subTitle}
                                      </p>
                                    </div>
                                  </RosterEntry>
                                )
                              })}
                          </div>
                        </>
                      ) : null
                    }
                    {
                      rosters && rosters.length > 0 ? (
                        <p
                        css={css`
                          margin: 12px 0 16px;
                          text-align: center;
                          ${font.title}
                          font-weight: bold;
                        `}
                      >
                        - Or -
                      </p>
                      ) : null
                    }   
                    <RosterEntry
                      isActive={sourceRosterForCopy === 'NEW'}
                      onClick={() => {
                        setSourceRosterForCopy(
                          sourceRosterForCopy === 'NEW' ? false : 'NEW'
                        )
                      }}
                    >
                      Create a new empty roster
                    </RosterEntry>
                  </>
                ) : null
              }
            </Flex>
          </>
        )}
        <Flex column noFlex justifyContent='center'>
          {error && (
            <p
              css={css`
                text-align: center;
                color: ${colors.ERROR};
                margin-bottom: 12px;
              `}
            >
              Error: {error}
            </p>
          )}
          <Flex noFlex alignItems='center'>
            <FormButton
              disabled={loading}
              css={css`
                align-self: flex-end;
              `}
              onClick={() => {
                setSourceRosterForCopy(false)
                onChange({
                  target: {
                    name: 'selected_teams',
                    value: [],
                  },
                })
              }}
            >
              Cancel
            </FormButton>
            <FormButton
              disabled={
                loading || (!isPlaceholderTeam(team) && (!hasExistingCompRoster && !sourceRosterForCopy))
              }
              busy={submitting}
              isSubmit
              css={css`
                align-self: flex-end;
              `}
              onClick={onSave}
            >
              Add Team
            </FormButton>
          </Flex>
        </Flex>
      </div>
    </div>
  )
}

export default EditPanelRosterSelect
