/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Flex } from '../../../components/Layout'
import { ROUND_TYPE } from './constants'
import Pool from './Pool'

const Round = ({
  round,
  roundNumber,
  selected,
  setSelected,
  stats,
  shouldDisplayScoreInsteadOfPoints = false,
  hasLowerPoolsOnAnyRounds = false,
}) => {
  const { pools, lowerPools, upperPools } = round
  const poolData = pools || []
  let upperPoolData = []
  let lowerPoolData = []
  if (upperPools.length === 0 && lowerPools.length === 0) {
    upperPoolData = poolData
  } else {
    upperPoolData = upperPools || []
    lowerPoolData = lowerPools || []
  }
  return (
    <Flex column>
      <Flex
        noFlex
        alignItems='center'
        justifyContent='center'
        className='round-name'
        css={css`
          font-weight: 500;
          font-size: 12px;
          text-transform: uppercase;
          margin-bottom: 16px;
          height: 28px;
          background-color: ${colors.HEADER};
        `}
      >
        {round.round_type_id === 1
          ? round.name_full
          : ROUND_TYPE[round.round_type_id]}
      </Flex>
      <Flex />
      <Flex column justifyContent='space-evenly' className='round'>
        {upperPoolData.map((pool) => {
          return (
            <Pool
              pool={pool}
              key={pool.id}
              roundNumber={roundNumber}
              roundType={round.round_type_id}
              setSelected={setSelected}
              selected={selected}
              stats={stats}
              round={round}
              isLowerPool={false}
              shouldDisplayScoreInsteadOfPoints={
                shouldDisplayScoreInsteadOfPoints
              }
              hasLowerPool={hasLowerPoolsOnAnyRounds}
            />
          )
        })}
      </Flex>
      <Flex />
      <Flex
        column
        justifyContent='space-evenly'
        className='round'
        css={css`
          margin-top: 16px;
        `}
      >
        {lowerPoolData.map((pool) => {
          return (
            <Pool
              pool={pool}
              key={pool.id}
              roundNumber={roundNumber}
              roundType={round.round_type_id}
              setSelected={setSelected}
              selected={selected}
              stats={stats}
              round={round}
              isLowerPool={true}
              shouldDisplayScoreInsteadOfPoints={
                shouldDisplayScoreInsteadOfPoints
              }
              hasLowerPool={hasLowerPoolsOnAnyRounds}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

export default Round
