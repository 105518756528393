/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { t } from '@sportninja/common/i18n'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { Link } from 'react-router-dom'

import { Flex } from '../../components/Layout'
import { InitialsThumbnail } from '../../components/List/PersonCell'
import Picture from '../../components/Picture'
import { media } from '../../components/Responsive'
import { Title } from './SearchSection'

const getIconName = (type) => {
  switch (type) {
    case 'team':
      return 'user-friends'
    case 'organization':
      return 'users'
    case 'player':
      return 'user'
    case 'schedule':
      return 'calendar-alt'
    default:
      return 'user'
  }
}

const SearchTopResult = ({ item, onClick }) => {
  const imageId = getImageThumbnailId(item)
  const isPlayerType = item.type === 'player'

  let route = '',
    subTitle = '',
    entityType = '',
    initials = ''
  switch (item.type) {
    case 'player': {
      route = `${ROUTES.PLAYER_ROOT}`
      entityType = ENTITY_TYPES.player
      const [first, last] = item.name.split(' ')
      const fInitial = first?.charAt(0)
      const lInitial = last?.charAt(0)
      initials = `${fInitial}${lInitial}`

      if (item?.teams?.length > 0) {
        subTitle = (
          <>
            #{item.teams[0].jersey_number} - {item.teams[0].position}
            <div
              css={css`
                margin-top: 2px;
              `}
            >
              {item.teams[0].name}
            </div>
          </>
        )
      }
      break
    }
    case 'schedule': {
      route = `${ROUTES.SCHEDULE_ROOT}`
      entityType = ENTITY_TYPES.schedule
      let str = item?.organization ? item?.organization?.name : ''

      if (item?.schedule_type_name !== 'Season' && item?.root) {
        str += ` > ${item?.root?.name}`
      }

      str += `${str.length > 0 ? ' • ' : ''}${item.schedule_type_name}`
      subTitle = str
      break
    }
    case 'venue':
      subTitle = 'Location'
      break
    case 'team_official':
      subTitle = 'Team Official'
      break
    case 'organization':
      route = `${ROUTES.ORG_ROOT}`
      entityType = ENTITY_TYPES.org
      subTitle = item?.parent?.name || t('common:organization')
      break
    case 'team':
      route = `${ROUTES.TEAM_ROOT}`
      entityType = ENTITY_TYPES.team
      subTitle = item?.organization?.name
      break
  }

  return (
    <div
      css={css`
        width: 304px;
        margin-top: 40px;
        margin-left: 40px;

        ${media.mobile} {
          width: unset;
        }
      `}
    >
      <Title text='Top Result' />
      <Flex
        as={item.type !== 'venue' && item.type !== 'team_official' ? Link : 'div'}
        to={`${route}/${item.id}`}
        alignItems='center'
        css={css`
          height: 136px;
          border: 1px solid ${colors.SOFT_STEEL};
          border-radius: 8px;
          background-color: ${colors.HEADER};
          padding: 16px;
          margin-top: 24px;
          transition: all 0.1s ease-in-out;
          cursor: ${item.type === 'venue' || item.type === 'team_official'
            ? 'not-allowed;'
            : 'pointer;'}

          ${item.type !== 'venue' &&
          item.type !== 'team_official' &&
          `
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
          `}
        `}
        onClick={() => {
          if (item.type !== 'venue' && item.type !== 'team_official') {
            onClick({
              entityType,
              id: item.id,
              imageId,
              initials,
              name: item.name,
            })
          }
        }}
      >
        {isPlayerType && !imageId && initials ? (
          <InitialsThumbnail size={80}>{initials}</InitialsThumbnail>
        ) : (
          <Picture
            size='medium'
            imageId={imageId}
            square={item.type !== 'player'}
            fit={item.type !== 'player'}
            iconName={getIconName(item.type)}
          />
        )}
        <div
          css={css`
            margin-left: 16px;
          `}
        >
          <div
            css={css`
              font-size: 16px;
              font-weight: 700;
            `}
          >
            {item.name}
          </div>
          <div
            css={css`
              font-size: 12px;
              font-weight: 500;
              color: ${colors.LINK_WATER};
              text-transform: capitalize;
              margin-top: 4px;
            `}
          >
            {subTitle}
          </div>
        </div>
      </Flex>
    </div>
  )
}

export default SearchTopResult
