import React from 'react'
import { connect } from 'react-redux'
import { getOffenseTypesBySportId } from '@sportninja/common/selectors/types'
import { toArray } from '@sportninja/common/utils/utils'
import { t } from '@sportninja/common/i18n'

import { SelectFormInput } from './Select'

const OffensePicker = ({
  isSoccer = false,
  offenseTypes,
  ...selectFormProps
}) => {
  return (
    <SelectFormInput {...selectFormProps}>
      <option value='' disabled>
        {isSoccer ? 'Select a Foul Type' : t('Web:selectPenaltyType')}:
      </option>
      <option disabled>-----</option>
      {offenseTypes.map((o) => {
        return (
          <option key={o.id} value={o.id}>
            {o.name_full}
          </option>
        )
      })}
    </SelectFormInput>
  )
}

const mapStateToProps = (state, { sportId }) => {
  return {
    offenseTypes: getOffenseTypesBySportId(state, sportId),
  }
}

export default connect(mapStateToProps)(OffensePicker)
