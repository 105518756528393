import React, { useEffect, useState } from 'react'
import { useRef } from 'react'

export const SearchContext = React.createContext(undefined)

const SearchContextProvider = ({ children, isLoggedIn }) => {
  const [isActive, setIsActive] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchFilter, setSearchFilter] = useState('')

  const searchInputRef = useRef(null)

  useEffect(() => {
    if (!isLoggedIn) {
      setIsActive(false)
      setSearchText('')
      setSearchFilter('')
    }
  }, [isLoggedIn])

  const value = {
    isActive,
    setIsActive: (value) => {
      setIsActive((prev) => (typeof value === 'boolean' ? value : !prev))
    },
    searchText,
    setText: setSearchText,
    filter: searchFilter,
    setFilter: setSearchFilter,
    searchInputRef,
  }

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}

export default SearchContextProvider
