/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { CloseButton } from '../Sheet/css'
import { Flex } from '../Layout'
import Modal from './index'
import { media } from '../Responsive'
class TextModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  toggleOpen = (forceState) => {
    if (this.state.isOpen && typeof this.props.onClose === 'function') {
      this.props.onClose()
    }

    this.setState((prevState) => ({
      isOpen: typeof forceState === 'boolean' ? forceState : !prevState.isOpen,
    }))
  }

  render() {
    const {
      children,
      className,
      hideClose,
      link,
      title,
      titleRight,
      unmountOnClose,
      toggle,
      titleSeparator,
    } = this.props

    const isModalOpen = !!this.props.isOpen || this.state.isOpen
    const toggleOpen = typeof toggle === 'function' ? toggle : this.toggleOpen

    return (
      <>
        <Modal isOpen={isModalOpen} onClose={toggleOpen}>
          <div className={classnames('sn-modal', className)}>
            {!hideClose && (
              <CloseButton
                onClick={toggleOpen}
                aria-label='close modal'
                title='close modal'
                css={css`
                  position: absolute;
                  top: -8px;
                  right: 24px;
                `}
              >
                <span className='sr-only'>close modal</span>
                <img
                  alt='Close menu'
                  src='/images/close.svg'
                  title='Close menu'
                />
              </CloseButton>
            )}
            <Flex
              className='sn-modal-title'
              css={css`
                ${media.mobile} {
                  flex-direction: column;
                }
              `}
            >
              {((unmountOnClose && isModalOpen) || !unmountOnClose) &&
                title && <h1>{title}</h1>}
              {((unmountOnClose && isModalOpen) || !unmountOnClose) &&
                titleRight}
            </Flex>
            {((unmountOnClose && isModalOpen) || !unmountOnClose) &&
              titleSeparator}
            <div>
              {unmountOnClose
                ? isModalOpen
                  ? typeof children === 'function'
                    ? children(toggleOpen)
                    : children
                  : false
                : typeof children === 'function'
                ? children(toggleOpen)
                : children}
            </div>
          </div>
        </Modal>
        {link && link(toggleOpen)}
      </>
    )
  }
}

TextModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  className: PropTypes.string,
  hideClose: PropTypes.bool,
  isOpen: PropTypes.bool,
  link: PropTypes.func,
  title: PropTypes.string,
  toggle: PropTypes.func,
}

export default TextModal
