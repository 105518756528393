import merge from 'lodash.merge'

import { str } from '../utils'
import parsers from '../parsers'

const officialsReducer =
  (actions) =>
  (draft, { type, payload }) => {
    switch (type) {
      case str(actions.create.success):
        draft[payload.id] = draft[payload.id] || {}
        draft[payload.id].officials = draft[payload.id].officials || []
        draft[payload.id].officials.push(parsers.official(payload.data))
        return

      case str(actions.read.success):
        draft[payload.id] = draft[payload.id] || {}
        draft[payload.id].officials = parsers.official(payload.data)
        return

      case str(actions.update.success): {
        const { data, id, officialId } = payload

        draft[id] = draft[id] || {}
        draft[id].officials = draft[id].officials || []
        let official = draft[id].officials.find((p) => p.id === officialId)

        if (official) official = merge(official, parsers.official(data))
        else draft[id].officials.push(parsers.official(data))

        return
      }

      case str(actions.delete.success): {
        const officials = draft[payload.id].officials

        if (Array.isArray(officials)) {
          const officialIndex = officials.findIndex(
            (p) => p.id === payload.officialId
          )
          if (officialIndex > -1) officials.splice(officialIndex, 1)
        }

        return
      }
    }
  }

export default officialsReducer
