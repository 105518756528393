import 'react-app-polyfill/ie11' // This import needs to come first
import 'core-js/features/array/includes'
import 'core-js/features/array/find'
import 'core-js/features/object/entries'
import 'core-js/features/string/starts-with'

// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
;(function (arr) {
  arr.forEach(function (item) {
    if (Object.prototype.hasOwnProperty.call(item, 'remove')) {
      return
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode !== null) {
          this.parentNode.removeChild(this)
        }
      },
    })
  })
})([Element.prototype, CharacterData.prototype, DocumentType.prototype])
