/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useCallback, useEffect } from 'react'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { captionLarge, paragraphMedium, weightBold } from '../css'
import { Alert, Space, Input } from 'antd'
import { SelectInput } from '../ts-components/SelectInput'
import { ColorPickerInput } from '../ts-components/ColorPickerInput'
import { CheckBoxInput } from '../ts-components/CheckBoxInput'

const { TextArea } = Input

const closeStyle = {
  color: colors.WHITE,
  fontSize: 20,
}
interface GeneratePluginModalProps {
  onPressClose?: () => void
  scheduleId: string
  isOrganization: boolean
  isTeam: boolean
}

const wixIntegrationTemplate = `
const init = () => {
  const mainContent = document.createElement('div');
  mainContent.id = 'main-content';
  mainContent.className = 'main-content';

  const scriptTag = document.createElement('script');
  scriptTag.src = 'https://plugin.sportninja.com/sportninja-plugin.js';
  scriptTag.type = 'text/javascript';
  mainContent.appendChild(scriptTag);

  const script = document.createElement('script');
  script.innerHTML = \`
  setTimeout(() => {
    window.snPlugin.init()
    window.snPlugin.renderOrganization({
      scheduleId: "{schedule_id}",
      backgroundColor: "#{background_color}",
      textColor: "#{text_color}",
      headerBackgroundColor: "#{header_background_color}",
      headerTextColor: "#{header_text_color}",
      primaryColor: "#{primary_color}",
      borderColor: "#{border_color}",
      useCanlanProvider: true,
    });
  }, 1000)
;\`;
  script.type = 'text/javascript';
  mainContent.appendChild(script);
  return mainContent;
}

class SNPluginJs extends HTMLElement {
  constructor(){
      super();
  }
  connectedCallback(){
      this.appendChild(init());
  }
}

customElements.define('sn-plugin', SNPluginJs);
`

const genericIntegrationTemplate = `
    <div id="main-content" class="main-content">
    <script src="https://plugin.sportninja.com/sportninja-plugin.js" type="text/javascript"></script>
    <script type="text/javascript">
        window.snPlugin.init();
        window.snPlugin.renderOrganization({
            scheduleId: "{schedule_id}",
            backgroundColor: "#{background_color}",
            textColor: "#{text_color}",
            headerBackgroundColor: "#{header_background_color}",
            headerTextColor: "#{header_text_color}",
            primaryColor: "#{primary_color}",
            borderColor: "#{border_color}",
            useCanlanProvider: true,
            disableSuspensionsTab: true,
            disableScheduleTab: true,
            disableStandingsTab: true,
            disableStatisticsTab: true,
            disableAnnouncementsTab: true,
        });
    </script>
    </div>
`

const GeneratePluginModal: React.FC<GeneratePluginModalProps> = ({
  onPressClose = () => {},
  scheduleId,
  isOrganization = false,
  isTeam = false,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>('')
  const [selectedWebsiteType, setSelectedWebsiteType] = React.useState<
    number | null
  >(null)
  const [primaryColor, setPrimaryColor] = React.useState<string>('F2B400')
  const [textColor, setTextColor] = React.useState<string>('27303E')
  const [headerBackgroundColor, setHeaderBackgroundColor] =
    React.useState<string>('F2B400')
  const [headerTextColor, setHeaderTextColor] = React.useState<string>('27303E')
  const [borderColor, setBorderColor] = React.useState<string>('27303E')
  const [backgroundColor, setBackgroundColor] = React.useState<string>('FFFFFF')
  const [pluginTag, setPluginTag] = React.useState<string>('')
  const [displaySchedule, setDisplaySchedule] = React.useState<boolean>(true)
  const [displayStandings, setDisplayStandings] = React.useState<boolean>(true)
  const [displayStatistics, setDisplayStatistics] =
    React.useState<boolean>(true)
  const [displaySuspensions, setDisplaySuspensions] =
    React.useState<boolean>(true)
  const [displayAnnouncements, setDisplayAnnouncements] =
    React.useState<boolean>(true)

  const [copied, setCopied] = React.useState<boolean>(false)

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 3000)
    }
  }, [copied])

  const generatePluginTag = useCallback(() => {
    if (selectedWebsiteType === 2) {
      if (isTeam) {
        return wixIntegrationTemplate
          .replace('{schedule_id}', scheduleId)
          .replace('{background_color}', backgroundColor)
          .replace('{text_color}', textColor)
          .replace('{header_background_color}', headerBackgroundColor)
          .replace('{header_text_color}', headerTextColor)
          .replace('{primary_color}', primaryColor)
          .replace('{border_color}', borderColor)
          .replace('scheduleId:', 'teamId:')
          .replace(!isCanlan ? 'useCanlanProvider: true,' : '', '')
          .replace(
            'disableSuspensionsTab: true,',
            displaySuspensions ? '' : 'disableSuspensionsTab: true,'
          )
          .replace(
            'disableScheduleTab: true,',
            displaySchedule ? '' : 'disableScheduleTab: true,'
          )
          .replace(
            'disableStandingsTab: true,',
            displayStandings ? '' : 'disableStandingsTab: true,'
          )
          .replace(
            'disableStatisticsTab: true,',
            displayStatistics ? '' : 'disableStatisticsTab: true,'
          )
          .replace(
            'disableAnnouncementsTab: true,',
            displayAnnouncements ? '' : 'disableAnnouncementsTab: true,'
          )
      }
      if (isOrganization) {
        return wixIntegrationTemplate
          .replace('{schedule_id}', scheduleId)
          .replace('{background_color}', backgroundColor)
          .replace('{text_color}', textColor)
          .replace('{header_background_color}', headerBackgroundColor)
          .replace('{header_text_color}', headerTextColor)
          .replace('{primary_color}', primaryColor)
          .replace('{border_color}', borderColor)
          .replace('scheduleId:', 'orgId:')
          .replace(!isCanlan ? 'useCanlanProvider: true,' : '', '')
          .replace(
            'disableSuspensionsTab: true,',
            displaySuspensions ? '' : 'disableSuspensionsTab: true,'
          )
          .replace(
            'disableScheduleTab: true,',
            displaySchedule ? '' : 'disableScheduleTab: true,'
          )
          .replace(
            'disableStandingsTab: true,',
            displayStandings ? '' : 'disableStandingsTab: true,'
          )
          .replace(
            'disableStatisticsTab: true,',
            displayStatistics ? '' : 'disableStatisticsTab: true,'
          )
          .replace(
            'disableAnnouncementsTab: true,',
            displayAnnouncements ? '' : 'disableAnnouncementsTab: true,'
          )
      }
      return wixIntegrationTemplate
        .replace('{schedule_id}', scheduleId)
        .replace('{background_color}', backgroundColor)
        .replace('{text_color}', textColor)
        .replace('{header_background_color}', headerBackgroundColor)
        .replace('{header_text_color}', headerTextColor)
        .replace('{primary_color}', primaryColor)
        .replace('{border_color}', borderColor)
        .replace(!isCanlan ? 'useCanlanProvider: true,' : '', '')
        .replace(
          'disableSuspensionsTab: true,',
          displaySuspensions ? '' : 'disableSuspensionsTab: true,'
        )
        .replace(
          'disableScheduleTab: true,',
          displaySchedule ? '' : 'disableScheduleTab: true,'
        )
        .replace(
          'disableStandingsTab: true,',
          displayStandings ? '' : 'disableStandingsTab: true,'
        )
        .replace(
          'disableStatisticsTab: true,',
          displayStatistics ? '' : 'disableStatisticsTab: true,'
        )
        .replace(
          'disableAnnouncementsTab: true,',
          displayAnnouncements ? '' : 'disableAnnouncementsTab: true,'
        )
    }
    if (isTeam) {
      return genericIntegrationTemplate
        .replace('{schedule_id}', scheduleId)
        .replace('{background_color}', backgroundColor)
        .replace('{text_color}', textColor)
        .replace('{header_background_color}', headerBackgroundColor)
        .replace('{header_text_color}', headerTextColor)
        .replace('{primary_color}', primaryColor)
        .replace('{border_color}', borderColor)
        .replace('scheduleId:', 'teamId:')
        .replace(!isCanlan ? 'useCanlanProvider: true,' : '', '')
        .replace(
          'disableSuspensionsTab: true,',
          displaySuspensions ? '' : 'disableSuspensionsTab: true,'
        )
        .replace(
          'disableScheduleTab: true,',
          displaySchedule ? '' : 'disableScheduleTab: true,'
        )
        .replace(
          'disableStandingsTab: true,',
          displayStandings ? '' : 'disableStandingsTab: true,'
        )
        .replace(
          'disableStatisticsTab: true,',
          displayStatistics ? '' : 'disableStatisticsTab: true,'
        )
        .replace(
          'disableAnnouncementsTab: true,',
          displayAnnouncements ? '' : 'disableAnnouncementsTab: true,'
        )
    }
    if (isOrganization) {
      return genericIntegrationTemplate
        .replace('{schedule_id}', scheduleId)
        .replace('{background_color}', backgroundColor)
        .replace('{text_color}', textColor)
        .replace('{header_background_color}', headerBackgroundColor)
        .replace('{header_text_color}', headerTextColor)
        .replace('{primary_color}', primaryColor)
        .replace('{border_color}', borderColor)
        .replace('scheduleId:', 'orgId:')
        .replace(!isCanlan ? 'useCanlanProvider: true,' : '', '')
        .replace(
          'disableSuspensionsTab: true,',
          displaySuspensions ? '' : 'disableSuspensionsTab: true,'
        )
        .replace(
          'disableScheduleTab: true,',
          displaySchedule ? '' : 'disableScheduleTab: true,'
        )
        .replace(
          'disableStandingsTab: true,',
          displayStandings ? '' : 'disableStandingsTab: true,'
        )
        .replace(
          'disableStatisticsTab: true,',
          displayStatistics ? '' : 'disableStatisticsTab: true,'
        )
        .replace(
          'disableAnnouncementsTab: true,',
          displayAnnouncements ? '' : 'disableAnnouncementsTab: true,'
        )
    }
    return genericIntegrationTemplate
      .replace('{schedule_id}', scheduleId)
      .replace('{background_color}', backgroundColor)
      .replace('{text_color}', textColor)
      .replace('{header_background_color}', headerBackgroundColor)
      .replace('{header_text_color}', headerTextColor)
      .replace('{primary_color}', primaryColor)
      .replace('{border_color}', borderColor)
      .replace(!isCanlan ? 'useCanlanProvider: true,' : '', '')
      .replace(
        'disableSuspensionsTab: true,',
        displaySuspensions ? '' : 'disableSuspensionsTab: true,'
      )
      .replace(
        'disableScheduleTab: true,',
        displaySchedule ? '' : 'disableScheduleTab: true,'
      )
      .replace(
        'disableStandingsTab: true,',
        displayStandings ? '' : 'disableStandingsTab: true,'
      )
      .replace(
        'disableStatisticsTab: true,',
        displayStatistics ? '' : 'disableStatisticsTab: true,'
      )
      .replace(
        'disableAnnouncementsTab: true,',
        displayAnnouncements ? '' : 'disableAnnouncementsTab: true,'
      )
  }, [
    selectedWebsiteType,
    isTeam,
    isOrganization,
    scheduleId,
    backgroundColor,
    textColor,
    headerBackgroundColor,
    headerTextColor,
    primaryColor,
    borderColor,
    displaySuspensions,
    displaySchedule,
    displayStandings,
    displayStatistics,
    displayAnnouncements,
  ])

  useEffect(() => {
    setPluginTag(generatePluginTag())
  }, [
    scheduleId,
    backgroundColor,
    textColor,
    headerBackgroundColor,
    headerTextColor,
    primaryColor,
    borderColor,
    generatePluginTag,
  ])

  return (
    <div
      css={css`
        background-color: ${colors.SECONDARY_300};
        min-width: 40vw;
        max-width: 700px;
        max-height: 80vh;
        /* border-radius: 8px; */
        overflow-y: auto;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          gap: 8px;
          align-self: stretch;
          position: sticky;
          top: 0;
          background-color: ${colors.HEADER};
          padding-top: 16px;
          padding-left: 16px;
          padding-right: 16px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          z-index: 100;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
          `}
        >
          <h1
            css={css`
              font-size: 32px;
              font-family: ${isCanlan
                ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
              font-weight: 600;
              color: ${colors.WHITE};
            `}
          >
            {'Generate JS Plugin Tag'}
          </h1>
          <div
            css={css`
              display: flex;
              gap: 16px;
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 16px;
              `}
            >
              <button
                disabled={isLoading}
                onClick={() => {
                  window.open(
                    'https://sportninja.zendesk.com/hc/en-us/articles/28293315971860-SportNinja-Website-Plugin-v3-0',
                    '_blank'
                  )
                }}
              >
                <QuestionCircleOutlined style={closeStyle} />
              </button>
            </div>
            <div
              css={css`
                display: flex;
                gap: 16px;
              `}
            >
              <button
                disabled={isLoading}
                onClick={() => {
                  setError('')
                  setIsLoading(false)
                  onPressClose()
                }}
              >
                <CloseOutlined style={closeStyle} />
              </button>
            </div>
          </div>
        </div>
        <div
          css={css`
            border-bottom: 1px solid ${colors.SOFT_STEEL};
            width: 100%;
            margin-top: 8px;
            margin-bottom: 8px;
          `}
        />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 14px;
          align-self: stretch;
          padding: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            width: 100%;
          `}
        >
          <SelectInput
            label={'Website Type'}
            disabled={false}
            options={[
              { label: 'Generic', value: '1' },
              { label: 'Wix', value: '2' },
            ]}
            name={'websiteType'}
            value={selectedWebsiteType ? selectedWebsiteType.toString() : '1'}
            showSearch={false}
            onChange={(value) => {
              setSelectedWebsiteType(Number(value))
            }}
            fieldErrors={[]}
            customError={{}}
          />
        </div>
        {/* <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            width: 100%;
          `}
        >
          <SelectInput
            label={'Theme'}
            disabled={false}
            options={[
              { label: 'Standard', value: '1' },
              { label: 'Custom', value: '2' },
            ]}
            name={'selectedTheme'}
            value={selectedTheme ? selectedTheme.toString() : '1'}
            showSearch={false}
            onChange={(value) => {
              setSelectedTheme(Number(value))
            }}
            fieldErrors={[]}
            customError={{}}
          />
        </div> */}
        <p
          css={css`
            ${paragraphMedium}
            ${weightBold}
            color: ${colors.WHITE};
            align-self: stretch;
          `}
        >
          Tabs Customization
        </p>
        <div
          css={css`
            display: flex;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              gap: 8px;
            `}
          >
            <CheckBoxInput
              customError={{}}
              label='Schedule'
              name='schedule'
              value={displaySchedule}
              fieldErrors={[]}
              disabled={false}
              onChange={() => {
                setDisplaySchedule(!displaySchedule)
              }}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              gap: 8px;
            `}
          >
            <CheckBoxInput
              customError={{}}
              label='Standings'
              name='standings'
              value={displayStandings}
              fieldErrors={[]}
              disabled={false}
              onChange={() => {
                setDisplayStandings(!displayStandings)
              }}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              gap: 8px;
            `}
          >
            <CheckBoxInput
              customError={{}}
              label='Statistics'
              name='statistics'
              value={displayStatistics}
              fieldErrors={[]}
              disabled={false}
              onChange={() => {
                setDisplayStatistics(!displayStatistics)
              }}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              gap: 8px;
            `}
          >
            <CheckBoxInput
              customError={{}}
              label='Suspensions'
              name='suspensions'
              value={displaySuspensions}
              fieldErrors={[]}
              disabled={false}
              onChange={() => {
                setDisplaySuspensions(!displaySuspensions)
              }}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              gap: 8px;
            `}
          >
            <CheckBoxInput
              customError={{}}
              label='Announcements'
              name='announcements'
              value={displayAnnouncements}
              fieldErrors={[]}
              disabled={false}
              onChange={() => {
                setDisplayAnnouncements(!displayAnnouncements)
              }}
            />
          </div>
        </div>
        <p
          css={css`
            ${paragraphMedium}
            ${weightBold}
            color: ${colors.WHITE};
            align-self: stretch;
          `}
        >
          Theme Customization
        </p>
        <div
          css={css`
            display: flex;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
            `}
          >
            <ColorPickerInput
              name='primaryColor'
              value={primaryColor}
              setValue={setPrimaryColor}
              fieldErrors={[]}
              customError={{}}
              label='Primary Color'
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
            `}
          >
            <ColorPickerInput
              name='textColor'
              value={textColor}
              setValue={setTextColor}
              fieldErrors={[]}
              customError={{}}
              label='Text Color'
            />
          </div>
        </div>

        <div
          css={css`
            display: flex;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
            `}
          >
            <ColorPickerInput
              name='headerBackgroundColor'
              value={headerBackgroundColor}
              setValue={setHeaderBackgroundColor}
              fieldErrors={[]}
              customError={{}}
              label='Header Background Color'
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
            `}
          >
            <ColorPickerInput
              name='headerTextColor'
              value={headerTextColor}
              setValue={setHeaderTextColor}
              fieldErrors={[]}
              customError={{}}
              label='Header Text Color'
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
            `}
          >
            <ColorPickerInput
              name='borderColor'
              value={borderColor}
              setValue={setBorderColor}
              fieldErrors={[]}
              customError={{}}
              label='Border Color'
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
            `}
          >
            <ColorPickerInput
              name='backgroundColor'
              value={backgroundColor}
              setValue={setBackgroundColor}
              fieldErrors={[]}
              customError={{}}
              label='Background Color'
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            align-self: stretch;
          `}
        >
          <p
            css={css`
              ${paragraphMedium}
              ${weightBold}
            color: ${colors.WHITE};
              align-self: stretch;
            `}
          >
            Copy this code and include it on your website:
          </p>
          {/* <a
            css={css`
              ${paragraphSmall}
              ${weightRegular}
              color: ${colors.PRIMARY_300};
            `}
            href='https://sportninja.zendesk.com/hc/en-us/articles/20790362886164-Website-Widget-Web-Plugin-Integration-Guide-NEW'
            target='_blank'
            rel='noreferrer'
          >
            Learn More
          </a> */}
        </div>
        <TextArea
          value={pluginTag}
          autoSize={{ minRows: 3 }}
          disabled={true}
          css={css`
            cursor: pointer !important;
          `}
        />
        <div
          css={css`
            border-bottom: 1px solid ${colors.SOFT_STEEL};
            height: 1px;
            width: 100%;
          `}
        />
        {error ? (
          <Space
            direction='vertical'
            // eslint-disable-next-line react-native/no-inline-styles
            style={{ width: '100%', marginBottom: 8 }}
          >
            <Alert
              message={error || 'An error occurred. Please try again.'}
              type='error'
              showIcon
              closable
            />
          </Space>
        ) : null}
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 16px;
            align-self: stretch;
            position: sticky;
            bottom: 0;
            z-index: 100;
            background-color: ${colors.HEADER};
            padding-bottom: 16px;
            padding-top: 16px;
          `}
        >
          {/* Cancel Button */}
          <div
            css={css`
              max-width: 80px;
              display: flex;
              padding: 8px 24px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              border-radius: 8px;
              border: 1px solid var(--Neutrals-neutrals-0, #fff);
              cursor: ${isLoading ? 'not-allowed' : 'pointer'};
              opacity: ${isLoading ? 0.7 : 1};
            `}
            onClick={() => {
              if (isLoading) {
                return
              }

              setError('')
              setIsLoading(false)
              onPressClose()
            }}
          >
            <p
              css={css`
                ${captionLarge}
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                color: ${colors.WHITE};
              `}
            >
              Close
            </p>
          </div>
          <div
            css={css`
              max-width: 80px;
              display: flex;
              padding: 8px 24px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              border-radius: 8px;
              background: ${colors.PRIMARY_300};
              cursor: ${isLoading ? 'not-allowed' : 'pointer'};
              opacity: ${isLoading ? 0.7 : 1};
            `}
            onClick={() => {
              navigator.clipboard.writeText(pluginTag).then(
                () => {
                  setCopied(true)
                },
                (err) => {
                  setError(err.message)
                }
              )
            }}
          >
            <>
              <p
                css={css`
                  ${captionLarge}
                  font-family: ${isCanlan
                    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                    : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                  color: ${colors.WHITE};
                `}
              >
                {copied ? 'Copied!' : 'Copy'}
              </p>
            </>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneratePluginModal
