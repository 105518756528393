import merge from 'lodash.merge'

import { str } from '../utils'
import parsers from '../parsers'

const venuesReducer =
  (actions) =>
  (draft, { type, payload }) => {
    switch (
      type // eslint-disable-line default-case
    ) {
      case str(actions.update.success): {
        const { id, data } = payload
        draft[id] = draft[id] || {}
        const locations = draft[id].locations || []

        const venueIndex = locations.findIndex((a) => a.id === data.id)
        const parsedData = parsers.venue(data)

        // If we don't find the address by id, then it's new so push it onto the array
        if (venueIndex === -1) {
          locations.push(parsedData)
        } else {
          merge(locations[venueIndex], parsedData)
          locations[venueIndex].facilities = parsedData.facilities
        }

        draft[id].locations = locations

        return
      }

      case str(actions.delete.success): {
        const { id, venueId } = payload
        draft[id] = draft[id] || {}
        const locations = draft[id].locations || []
        const venueIndex = locations.findIndex((v) => v.id === venueId)
        locations.splice(venueIndex, 1)
        return
      }

      // DEPRECATED
      case str(actions.updatePrimary.success): {
        const { id, primaryId } = payload
        const venueIndex = draft[id].locations.findIndex(
          (a) => a.id === primaryId
        )

        // Pull the address out of the array
        let currVenue = draft[id].locations.splice(venueIndex, 1)[0]
        // Update it
        currVenue.is_primary = true
        // Update the rest of the list
        draft[id].locations.forEach((venue) => (venue.is_primary = false))
        // Put the primary at the front
        draft[id].locations = [currVenue, ...draft[id].locations]
        return
      }
    }
  }

export default venuesReducer
