/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import { t } from '@sportninja/common/i18n'
import { colors } from '@sportninja/common/constants/appColors'

import { SectionTitle } from '../../components/Card/css'
import { font } from '../../components/css'

const Number = styled.div`
  opacity: ${(props) => (props.faded ? '0.42' : '1')};
  ${font.title};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 30px;
  letter-spacing: 1.33px;
  text-align: ${(props) => (props.isRight ? 'right' : 'left')};
  flex-basis: 33%;
`

const Title = styled.div`
  ${font.title};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.83px;
  text-align: center;
  flex-basis: 33%;
`

const Bar = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 100px;
  height: 16px;
  margin: ${(props) => (props.isRight ? '0 0 0 6.5px' : '0 6.5px 0 0')};
  flex-shrink: 1;
`

const StatRow = ({ left = 0, right = 0, title, bottomRow }) => {
  const total = left + right
  let leftSize = 100 * (left / total)
  let rightSize = 100 * (right / total)

  leftSize = isNaN(leftSize) ? 0 : leftSize
  rightSize = isNaN(rightSize) ? 0 : rightSize

  if (leftSize === 0 && rightSize === 0) {
    leftSize = 50
    rightSize = 50
  } else if (leftSize === 0) leftSize = 8
  else if (rightSize === 0) rightSize = 8

  return (
    <div
      css={css`
        margin-bottom: ${bottomRow ? '0' : '18'}px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <Number faded={left < right}>{left}</Number>
        <div>
          <Title>{title}</Title>
        </div>
        <Number faded={left > right} isRight>
          {right}
        </Number>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          margin-top: 8px;
        `}
      >
        <Bar
          className='game-details-stats-bar left'
          color='white'
          css={{ width: `${`${leftSize}`}%` }}
        />
        <Bar
          className='game-details-stats-bar right'
          color={colors.PRIMARY}
          css={{ width: `${`${rightSize}`}%`, opacity: 0.9 }}
          isRight
        />
      </div>
    </div>
  )
}

const GameDetailsStats = ({
  game,
  isMobile,
  isSoccer = false,
  isGenericGame = false,
}) => {
  if (!game.started) {
    return false
  }

  return (
    <div
      css={css`
        min-width: 271px;
        ${isMobile
          ? `
          padding: 0 24px;
        `
          : 'margin-left: 16px;'}
        margin-bottom: 40px;
      `}
    >
      <SectionTitle
        css={css`
          position: unset;
          margin-bottom: 13px;
        `}
      >
        {t('GameScreen:teamStats')}
      </SectionTitle>
      <StatRow
        left={game.homeTeamScore}
        right={game.visitingTeamScore}
        title={isGenericGame ? 'Points' : t('common:goal', { count: 2 })}
      />
      <StatRow
        left={game.homeOffenseCount}
        right={game.visitingOffenseCount}
        title={
          isSoccer || isGenericGame
            ? 'Fouls'
            : t('common:penalty', { count: 2 })
        }
      />
      <StatRow
        left={game.homeShotCount}
        right={game.visitingShotCount}
        title={t('common:shot', { count: 2 })}
        bottomRow
      />
    </div>
  )
}

export default GameDetailsStats
