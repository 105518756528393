const getLanguageDetector = {
  type: 'languageDetector',
  async: true,
  detect: (callback) => {
    // We will get back a string like "en-US". We
    // return a string like "en" to match our language
    // files.
    // TODO: Somehow detect this, or add a UI element to toggle it.
    callback('en')
  },
  init: () => {},
  cacheUserLanguage: () => {},
}

export default getLanguageDetector
