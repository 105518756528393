/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useEffect, useRef, useState } from 'react'
import Hls from 'hls.js'
import dayjs from 'dayjs'
import req from '@sportninja/common/api/request'

import { Flex } from '../../components/Layout'
import LoadingSpinner from '../../components/LoadingSpinner'
import { media } from '../../components/Responsive'

const LiveVideo = ({ gameId, hasCompleted, startedAt }) => {
  const videoRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    setLoading(true)
    setError(false)

    let hls
    const video = videoRef?.current

    const canPlayHandler = () => {
      setLoading(false)
    }

    if (video && typeof video?.addEventListener === 'function') {
      video.addEventListener('canplay', canPlayHandler)
    }

    const mountVideo = (link) => {
      if (typeof link !== 'string') {
        setError(true)
        setLoading(false)
        return
      }

      if (Hls.isSupported()) {
        hls = new Hls()
        hls.loadSource(link)
        hls.attachMedia(video)
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          video.play()
        })
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = link
        video.addEventListener('loadedmetadata', function () {
          video.play()
        })
      }
    }

    const getDiff = (gameDateTime, dateTimeString) => {
      if (typeof dateTimeString !== 'string') return 0
      else {
        return Math.abs(
          dayjs(
            dateTimeString.split('[')[0],
            'YYYY-MM-DD[T]HH:mm:ss[.]SSSZ'
          ).diff(gameDateTime)
        )
      }
    }

    const onRecordedVideo = (broadcasts = []) => {
      if (broadcasts.length === 0) {
        return
      }

      const gameDateTime = dayjs(startedAt, 'YYYY-MM-DD[T]HH:mm:ssZ')
      const copy = [...broadcasts]

      copy.sort((a, b) => {
        const aDiff = getDiff(gameDateTime, a?.beginDate),
          bDiff = getDiff(gameDateTime, b?.beginDate)
        if (aDiff < bDiff) return -1
        else if (aDiff > bDiff) return 1
        return 0
      })

      const url = copy?.[0]?.url

      if (url) {
        mountVideo(url)
      } else {
        setError(true)
      }
    }

    const onLiveVideo = (response) => {
      const { streamLink } = response || {}
      mountVideo(streamLink)
    }

    req(`/games/${gameId}/${hasCompleted ? 'recorded' : 'live'}-broadcast`)
      .then(hasCompleted ? onRecordedVideo : onLiveVideo)
      .catch((e) => {
        setError(true)
        setLoading(false)
      })

    return () => {
      if (video && typeof video?.removeEventListener === 'function') {
        video.removeEventListener('canplay', canPlayHandler)
        if (typeof video?.stop === 'function') {
          video.stop()
        }
        hls?.destroy()
      }
    }
  }, [gameId, hasCompleted])

  return (
    <Flex
      justifyContent='center'
      css={css`
        padding: 0 100px 40px;
        ${media.mobile} {
          padding: 0 0 40px;
        }
      `}
    >
      <div
        style={{
          maxWidth: 1456,
          width: '100%',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {loading && (
          <LoadingSpinner
            style={{
              position: 'absolute',
              height: 'inherit',
              top: 0,
              bottom: 0,
            }}
          />
        )}
        {error && (
          <Flex
            alignItems='center'
            style={{
              position: 'absolute',
              height: 'inherit',
              top: 0,
              bottom: 0,
            }}
          >
            There was a problem loading video.
          </Flex>
        )}
        <video
          muted
          autoPlay
          controls
          ref={videoRef}
          style={{
            height: 'inherit',
            width: 'inherit',
            opacity: !loading && !error ? 1 : 0,
          }}
        >
          Sorry, your browser doesn&apos;t support streaming video
        </video>
      </div>
    </Flex>
  )
}

export default LiveVideo
