/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import isPropValid from '@emotion/is-prop-valid'

import {
  breakpoints,
  COL_GUTTER_UNIT,
  COL_GUTTER,
  COL_WIDTH,
  Mobile,
  media,
  minMedia,
  COL_PADDING,
} from '../Responsive'

export const Flex = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop),
})((props) => ({
  position: props.isRelative && 'relative',
  display: 'flex',
  flex: !props.noFlex && 1,
  justifyContent: props.justifyContent,
  alignItems: props.alignItems,
  flexDirection: props.flexDirection
    ? props.flexDirection
    : props.column && !props.row
    ? 'column'
    : props.row
    ? 'row'
    : undefined,
  flexWrap: props.flexWrap && 'wrap',
  width: props.width,
  height: props.height,
}))

const ContainerWrap = styled(Flex)`
  width: 100vw;

  ${breakpoints.slice(0, -1).map((bp, idx) => {
    // e.g. if bp is 1280, then prevBp is 1491 (i.e. 1492 - 1)
    const prevBp = idx !== 0 ? breakpoints[idx - 1] - 1 : undefined

    return css`
      @media only screen and (min-width: ${bp}px) ${prevBp &&
        ` and (max-width: ${prevBp}px)`} {
        .wrap {
          width: ${bp - COL_GUTTER_UNIT - COL_PADDING}px;
          max-width: ${bp - COL_GUTTER_UNIT - COL_PADDING}px;
        }
      }
    `
  })}
`

export const Container = ({
  className,
  children,
  forceDesktop,
  noWrap,
  wrapCss,
  ...props
}) => {
  const DesktopContent = (
    <ContainerWrap className={className} justifyContent='center'>
      <Flex noFlex flexWrap={!noWrap} className='wrap' css={wrapCss} {...props}>
        {children}
      </Flex>
    </ContainerWrap>
  )

  return forceDesktop ? (
    DesktopContent
  ) : (
    <Mobile>
      {(isMobile) =>
        isMobile ? (
          <div
            className={className}
            css={css`
              padding: 0 24px;
            `}
            {...props}
          >
            {children}
          </div>
        ) : (
          DesktopContent
        )
      }
    </Mobile>
  )
}

const getColumnWidth = (size) => COL_WIDTH * size + (size - 1) * COL_GUTTER

const columnSizing = ({ fullWidth, columns = 1 }) => {
  // Don't set a min/max width on the element if fullWidth - just allow it to flex
  // and fill the space
  if (fullWidth) return

  if (Array.isArray(columns)) {
    return css`
      /* Eight columns */
      min-width: ${getColumnWidth(columns[0])}px;
      max-width: ${getColumnWidth(columns[0])}px;

      ${columns[1] &&
      css`
        ${minMedia.ten} {
          min-width: ${getColumnWidth(columns[1])}px;
          max-width: ${getColumnWidth(columns[1])}px;
        }
      `}

      ${columns[2] &&
      css`
        ${minMedia.twelve} {
          min-width: ${getColumnWidth(columns[2])}px;
          max-width: ${getColumnWidth(columns[2])}px;
        }
      `}

      ${columns[3] &&
      css`
        ${minMedia.fourteen} {
          min-width: ${getColumnWidth(columns[3])}px;
          max-width: ${getColumnWidth(columns[3])}px;
        }
      `}
    `
  } else {
    return css`
      min-width: ${getColumnWidth(columns)}px;
      max-width: ${getColumnWidth(columns)}px;
    `
  }
}

export const Column = styled(Flex)`
  ${media.mobile} {
    width: 100%;
  }

  /* For everything above mobile breakpoint... */
  ${minMedia.eight} {
    ${columnSizing}
    margin-left: ${COL_GUTTER_UNIT}px;
    margin-right: ${COL_GUTTER_UNIT}px;

    > &:first-of-type {
      margin-left: 0 !important;
    }

    > &:last-of-type {
      margin-right: 0 !important;
    }
  }
`
