/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { t } from '@sportninja/common/i18n'
import parsers from '@sportninja/common/reducers/parsers'
import { connect } from 'react-redux'
import orgActions from '@sportninja/common/actions/org'
import scheduleActions from '@sportninja/common/actions/schedule'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import req from '@sportninja/common/api/request'
import { NewMapCard } from '../NewMapCard'
import ListStatusView from '../List/StatusView'
import useGetListItems from '../List/useGetListItems'
import { Flex } from '../Layout'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { NewModal } from '../NewModal'
import LoadingSpinner from '../LoadingSpinner'
import { NewLocationAddressSelector } from '../NewLocationAddressSelector'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { CloseOutlined } from '@ant-design/icons'
import typeActions from '@sportninja/common/actions/types'

const sorter = (a, b) => {
  const nameA = a.name_full,
    nameB = b.name_full
  if (nameA < nameB) {
    return -1
  } else if (nameA > nameB) {
    return 1
  }

  return 0
}

const convertData = (originalObject, facilitiesArray) => {
  const newObject = {
    id: originalObject.id,
    name_full: originalObject.name_full,
    street_1: originalObject.address.street_1,
    city: originalObject.address.city,
    postal_code: originalObject.address.postal_code,
    country_id: originalObject.address.country_id.toString(), // Converting to string as per example
    timezone: originalObject.timezone,
    province_id: originalObject?.address?.province_id.toString(),
    newFacilities: {},
    facilities: [],
  }
  facilitiesArray.forEach((facility, index) => {
    if (facility.id) {
      newObject.facilities.push(facility)
    } else {
      newObject.newFacilities[`facility_${index}`] = {
        name: facility.name,
      }
    }
  })

  return newObject
}

const Venues = ({
  entityType,
  id,
  slug,
  refreshKey,
  triggerRefresh,
  query,

  updateOrgVenue,
  deleteOrgVenue,
  updateScheduleVenue,
  deleteScheduleVenue,
  parentPermission,
  readCountries,
  readProvinces,
}) => {
  const { loading, error, items, sort, direction, getItems } = useGetListItems(
    slug,
    { query }
  )
  const [selectedVenue, setSelectedVenue] = useState(null)
  const [isLoadingVenue, setIsLoadingVenue] = useState(false)

  const locationModalRef = useRef(null)

  const entity = useMemo(
    () => (entityType === ENTITY_TYPES.org ? 'organizations' : 'schedules'),
    [entityType]
  )

  const updatePermission =
    parentPermission?.update || parentPermission?.admin ? true : false

  useEffect(() => {
    getItems(1, query)
  }, [refreshKey, sort, direction])

  let onUpdate, onDelete

  if (entityType === ENTITY_TYPES.org) {
    onUpdate = updateOrgVenue
    onDelete = deleteOrgVenue
  } else if (entityType === ENTITY_TYPES.schedule) {
    onUpdate = updateScheduleVenue
    onDelete = deleteScheduleVenue
  }

  const onUpdateVenue = async (
    venue,
    facilities,
    entityId,
    entity,
    selectedVenue
  ) => {
    try {
      setIsLoadingVenue(true)
      const newObject = convertData(
        { id: selectedVenue?.id, ...venue },
        facilities
      )
      const response = await onUpdate(id, selectedVenue?.id, newObject)
    } catch (e) {
      console.error(e)
      alert(
        `An error occurred when updating the venue: ${
          e?.message || 'Unknown error'
        }`
      )
    } finally {
      setIsLoadingVenue(false)
      locationModalRef?.current?.closeModal()
      triggerRefresh()
    }
  }

  const onDeleteVenue = useCallback(async (venueId) => {
    if (window.confirm('Are you sure you want to delete this location?')) {
      try {
        await req(`/${entity}/${id}/venues/${venueId}`, {
          method: 'DELETE',
        })
      } catch (error) {
        console.error(error)
        alert(
          `An error occurred when deleting the venue: ${
            error?.message || 'Unknown error'
          }`
        )
      } finally {
        triggerRefresh()
      }
    }
  }, [])

  useEffect(() => {
    readCountries()
  }, [])

  return (
    <>
      <Flex
        row
        flexWrap
        css={css`
          gap: 20px;
          padding-top: 16px;
          justify-content: flex-start;
        `}
      >
        {loading || error || items.length === 0 ? (
          <div style={{ margin: '0 auto' }}>
            <ListStatusView
              loading={loading}
              error={error}
              zeroItems={items.length === 0}
              noItemsText={t('common:location', { count: 2 })}
            />
          </div>
        ) : (
          items.sort(sorter).map((item) => {
            const venue = parsers.venue(item)
            return (
              <NewMapCard
                name={venue?.fullName}
                description={venue?.addressString}
                latitude={venue?.coords?.[0] || null}
                longitude={venue?.coords?.[1] || null}
                key={venue.id}
                onDeleteClick={() => onDeleteVenue(venue.id)}
                onUpdateClick={() => {
                  setSelectedVenue(venue)
                  locationModalRef?.current?.openModal()
                }}
                updatePermission={updatePermission}
              />
            )
          })
        )}
      </Flex>
      <NewModal ref={locationModalRef} shouldCloseOnOverlayClick={false}>
        <div
          css={css`
            width: 40vw;
            background: no-repeat fixed linear-gradient(#282e38, #181a1d);
            max-height: 80vh;
            // border-radius: 8px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            transition: all 0.1s ease-in-out;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-bottom: 16px;
              border-bottom: 1px solid ${colors.SOFT_STEEL};
              margin-bottom: 16px;
            `}
          >
            <h1
              css={css`
                font-size: 36px;
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'Rift, Arial, Helvetica, sans-serif'};
                font-weight: 700;
              `}
            >
              {t('Web:editLocation')}
            </h1>
            <button
              onClick={() => locationModalRef.current.closeModal()}
              disabled={isLoadingVenue}
              css={css`
                margin-bottom: 20px;
              `}
            >
              <CloseOutlined
                fontSize={20}
                style={{
                  color: colors.WHITE,
                  fontSize: 20,
                }}
              />
            </button>
          </div>
          <div
            css={css`
              flex: 1;
              flex-grow: 1;
              display: flex;
              transition: all 0.1s ease-in-out;
            `}
          >
            {isLoadingVenue ? (
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex: 1;
                `}
              >
                <LoadingSpinner />
              </div>
            ) : (
              <NewLocationAddressSelector
                initialState={{
                  address_line1: selectedVenue?.address?.street_1 || '',
                  address_line2: selectedVenue?.address?.street_2 || '',
                  city: selectedVenue?.address?.city || '',
                  postcode: selectedVenue?.address?.postal_code || '',
                  country: selectedVenue?.address?.country?.name || '',
                  latitude: selectedVenue?.address?.latitude || 0,
                  longitude: selectedVenue?.address?.longitude || 0,
                  name: selectedVenue?.fullName || '',
                  state: selectedVenue?.address?.province?.name || '',
                  timezone: selectedVenue?.timezone || '',
                  selectedVenue: selectedVenue,
                  entityId: id,
                  entityType: entity,
                }}
                initialFacilities={selectedVenue?.facilities || []}
                onCancel={() => locationModalRef?.current?.closeModal()}
                onSubmit={onUpdateVenue}
                readReduxProvinces={readProvinces}
              />
            )}
          </div>
        </div>
      </NewModal>
    </>
  )
}

Venues.propTypes = {
  entityType: PropTypes.string,
  id: PropTypes.string.isRequired,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  venues: PropTypes.array,
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateOrgVenue: bindActionToPromise(
      dispatch,
      orgActions.venues.update.request
    ),
    deleteOrgVenue: bindActionToPromise(
      dispatch,
      orgActions.venues.delete.request
    ),
    updateScheduleVenue: bindActionToPromise(
      dispatch,
      scheduleActions.venues.update.request
    ),
    deleteScheduleVenue: bindActionToPromise(
      dispatch,
      scheduleActions.venues.delete.request
    ),
    readCountries: bindActionToPromise(dispatch, typeActions.countries.request),
    readProvinces: bindActionToPromise(dispatch, typeActions.provinces.request),
  }
}

export default connect(null, mapDispatchToProps)(Venues)
