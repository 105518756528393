import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { RegistrationInviteService, delay } from './service'

const SESSION_TOKEN = 'session_token'

export class RegistrationInviteStore {
  rootStore
  step = 0
  isLogged = false
  eventInfo = {}
  user = {
    email: '',
    password: '',
    token: '',
  }
  userExtraInfo = {}
  playerExtraInfo = {}
  userTeams = []
  playerTeams = []
  skillLevels = []
  isLoading = true
  isLoadingStep = false
  error = null
  newTeamId = null

  selectedTeamPlayerRosterId = null
  sports = []

  submittedInfo = {
    divisionId: null,
    teamName: '',
    teamLogo: undefined,
    costPerTeam: '',
    deposit: '',
    divisionName: '',
    teamId: '',
    billingInfo: null,
    singlePayment: false,
  }

  constructor(rootStore) {
    this.rootStore = rootStore

    makeObservable(this, {
      step: observable,
      user: observable,
      userExtraInfo: observable,
      playerExtraInfo: observable,
      isLoading: observable,
      error: observable,
      isLogged: observable,
      isLoadingStep: observable,
      eventInfo: observable,
      userTeams: observable,
      playerTeams: observable,
      submittedInfo: observable,
      skillLevels: observable,
      newTeamId: observable,
      selectedTeamPlayerRosterId: observable,

      fetchEventInfo: action,
      setSports: action,
      setNewTeamId: action,
      login: action,
      confirmTeamRepStep: action,
      confirmPlayerStep: action,
      confirmTeamAndDivisionSelect: action,
      confirmPlayerSelect: action,
      pushToStep: action,
      clearError: action,

      state: computed,
    })
  }

  pushToStep = (step) => {
    this.step = step
  }

  confirmTeamPlayerRosterId = (rosterId) => {
    runInAction(() => {
      this.selectedTeamPlayerRosterId = rosterId
      this.step = 4.5
    })
  }

  clearError = () => {
    runInAction(() => {
      this.error = null
    })
  }

  confirmTeamAndDivisionSelect = ({
    teamOfficials,
    teamId,
    registrationId,
    scheduleId,
    info,
    couponCodeId = null,
  }) => {
    this.isLoadingStep = true
    this.error = null

    RegistrationInviteService.registerTeam({
      registrationId,
      scheduleId,
      teamId,
      teamOfficials,
      couponCodeId,
      zipCode: info.zipCode,
      country: info.country,
    })
      .then((response) => {
        runInAction(() => {
          this.step = 2
          this.submittedInfo = {
            ...info,
            billingInfo: response?.billing,
          }
        })
      })
      .catch((error) => {
        let message = null

        if (error.message) {
          message = error.message
        }

        if (error.invalid_fields) {
          const failedField = Object.keys(error.invalid_fields)[0].split('.')[0]

          if (failedField === 'officials') {
            message =
              'Something went wrong creating the team officials. Please try again later.'
          } else {
            message =
              'Something went wrong. Please try again later. [invalid_fields]'
          }
        }

        runInAction(() => {
          // this.step = 3
          this.error = message || 'Register Failed'
        })
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadingStep = false
        })
      })
  }

  confirmTeamRepStep = ({ scheduleId }) => {
    this.isLoadingStep = true

    const shouldFetchUserInfo = Object.keys(this.userExtraInfo).length === 0
    RegistrationInviteService.getUserInformation(
      shouldFetchUserInfo,
      scheduleId
    )
      .then((response) => {
        runInAction(() => {
          this.userExtraInfo = response?.user || this.userExtraInfo
          this.userTeams = response?.teams
          this.step = 1
        })
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadingStep = false
        })
      })
  }

  confirmPlayerStep = ({ scheduleId }) => {
    this.isLoadingStep = true
    const shouldFetchUserInfo = Object.keys(this.userExtraInfo).length === 0

    RegistrationInviteService.getPlayerInformation(
      shouldFetchUserInfo,
      scheduleId
    )
      .then((response) => {
        runInAction(() => {
          this.userExtraInfo = response?.user || this.userExtraInfo
          this.playerExtraInfo = response?.player
          this.playerTeams = response?.teams
          this.skillLevels = response?.skillLevel
          this.step = 4
        })
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadingStep = false
        })
      })
  }

  setNewTeamId = (teamId) => {
    runInAction(() => {
      this.newTeamId = teamId
    })
  }

  confirmPlayerSelect = ({ endpointInfo, playerInfo }) => {
    this.isLoadingStep = true

    RegistrationInviteService.registerPlayer({
      rosterId: endpointInfo.rosterId,
      scheduleId: endpointInfo.scheduleId,
      teamId: endpointInfo.teamId,
      playerInfo,
    })
      .then(() => {
        runInAction(() => {
          this.step = 2
          this.submittedInfo = {
            ...playerInfo,
            ...endpointInfo,
          }
        })
      })
      .catch((error) => {
        console.error(error)
        runInAction(() => {
          this.step = 3
          this.error =
            error.statusCode === 422 ? error.message : 'Register Failed'
        })
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadingStep = false
        })
      })
  }

  setSports = (sports) => {
    runInAction(() => {
      this.sports = sports
    })
  }

  fetchEventInfo = (inviteId) => {
    runInAction(() => {
      this.isLoading = true
    })

    const existingToken = localStorage.getItem(SESSION_TOKEN)

    RegistrationInviteService.getInviteInfo(inviteId)
      .then(({ invite }) => {
        runInAction(() => {
          this.eventInfo = invite
          this.isLogged = !!existingToken
        })
      })
      .catch((error) => {
        console.error(error)
        runInAction(() => {
          this.error = JSON.stringify(error)
        })
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }

  login = (email, password, loginFunction) => {
    this.isLoading = true
    this.user.email = email
    this.user.password = password

    loginFunction({ email, password }).then((response) => {
      console.log('RESPONSE')
    })

    // RegistrationInviteService.login(email, password)
    //   .then((response) => {
    //     localStorage.setItem(SESSION_TOKEN, response.access_token)

    //     runInAction(() => {
    //       this.user.token = response.access_token
    //       this.userExtraInfo = response.user
    //       this.isLogged = true
    //       this.error = null
    //     })
    //   })
    //   .catch((error) => {
    //     console.error(error)
    //     runInAction(() => {
    //       this.error = JSON.stringify(error)
    //     })
    //   })
    //   .finally(() => {
    //     runInAction(() => {
    //       this.isLoading = false
    //     })
    //   })
  }

  get state() {
    return {
      step: this.step,
      user: this.user,
      error: this.error,
      userExtraInfo: this.userExtraInfo,
      playerExtraInfo: this.playerExtraInfo,
      eventInfo: this.eventInfo,
      isLoading: this.isLoading,
      isLogged: this.isLogged,
      isLoadingStep: this.isLoadingStep,
      userTeams: this.userTeams,
      playerTeams: this.playerTeams,
      skillLevels: this.skillLevels,
      newTeamId: this.newTeamId,
      submittedInfo: this.submittedInfo,
      selectedTeamPlayerRosterId: this.selectedTeamPlayerRosterId,
      sports: this.sports,
    }
  }
}
