/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Input as AntInput } from 'antd'
import React from 'react'
import { paragraphSmall, weightRegular } from 'src/components/css'
import { ErrorLabel } from '../ErrorLabel'

type TextAreaInputProps = {
  name: string
  value: string
  setValue: (value: string) => void
  fieldErrors: any
  customError: any
  label: string
  placeholder?: string
  maxLength?: number
}

const { TextArea } = AntInput

export const TextAreaInput: React.FC<TextAreaInputProps> = ({
  name,
  value,
  setValue,
  fieldErrors,
  customError,
  label,
  placeholder,
  maxLength = 255,
}) => {
  const [characterCount, setCharacterCount] = React.useState(0)

  React.useEffect(() => {
    setCharacterCount(value.length)
  }, [value])

  return (
    <>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        `}
      >
        <p
          css={css`
            ${paragraphSmall}
            ${weightRegular}
                color: ${colors.WHITE};
            align-self: stretch;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `}
        >
          {label}
        </p>
        <p
          css={css`
            ${paragraphSmall}
            ${weightRegular}
                color: ${colors.LIGHTER_GRAY};
            align-self: stretch;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `}
        >
          {characterCount}/{maxLength}
        </p>
      </div>
      <TextArea
        status={fieldErrors[name] || fieldErrors?.includes(name) ? 'error' : ''}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        maxLength={maxLength}
        rows={4}
        placeholder={placeholder}
      />
      <ErrorLabel
        fieldErrors={fieldErrors}
        customError={customError}
        name={name}
      />
    </>
  )
}
