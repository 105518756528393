import req from '@sportninja/common/api/request'

class Service {
  async fetchTypes() {
    const response = await req('/types/registration')

    return response
  }
}

const TypesService = new Service()

export { TypesService }
