import { createSelector } from 'reselect'

import { getAuth, getFeed } from './base'
import { createChildListSelector } from './utils'

export const getUserFeed = createSelector(
  [ getFeed, getAuth ],
  (feed, auth) => auth.feed.map((id) => feed[id])
)

export const getFeedItemById = (state, id) => state.feed[id] || {}

export const makeGetFeed = (selector) => createChildListSelector(
  getFeed, selector, 'feed'
)

export const makeGetFeedItemById = () => createSelector(getFeedItemById, (feedItem) => feedItem || {})
