/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Icon from 'src/components/Icon'
import Select from 'react-select'
import stripeLogo from '../RegistrationPayment/stripe_logo.svg'
import { SetupIntent, Stripe, loadStripe } from '@stripe/stripe-js'
import { observer } from 'mobx-react-lite'
import { useMobxStore } from 'src/state'
import { Button } from 'src/components/Button'
import { Input } from 'src/components/Input'
import { Slider } from 'src/components/Slider'
import { useForm } from 'react-hook-form'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { handleCardIcon } from './PaymentManagerModal'

import purchaseComplete from './purchase_completed.svg'
import purchaseFailed from './purchase_failed.svg'

type SubscriptionResponse = {
  subscription: {
    name: string
    stripe_id: string
    stripe_status: string
    stripe_price: string
    quantity: number | null
    trial_ends_at: string | null
    ends_at: string | null
    account_id: number
    updated_at: string
    created_at: string
    id: number
  }
}

type CouponCheckResponse = {
  id?: string
  name?: string
  percent_off?: number
}

interface ModalHeaderProps {
  onCloseClick: () => void
  title: string
  hideSupportButton?: boolean
  titleStyles?: string
}

export const ModalHeader = ({
  title,
  onCloseClick,
  hideSupportButton = false,
  titleStyles,
}: ModalHeaderProps) => {
  return (
    <div
      css={css`
        display: flex;
        padding-bottom: 16px;
        border-bottom: 1px solid ${colors.SOFT_STEEL};
        justify-content: space-between;
        align-items: flex-start;
      `}
    >
      <p
        css={css`
          font-family: Rift;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          text-transform: uppercase;

          ${titleStyles};
        `}
      >
        {title}
      </p>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
        `}
      >
        {!hideSupportButton && (
          <a
            href='https://meetings.hubspot.com/james-goodfellow/onboarding-and-product-qa'
            target='_blank'
            rel='noreferrer'
          >
            <Icon
              name='question-circle'
              faType='far'
              fontSize={24}
              color={colors.ATTENDANCE_GRAY}
            />
          </a>
        )}
        <button onClick={onCloseClick}>
          <Icon name='times' fontSize={24} color={colors.ATTENDANCE_GRAY} />
        </button>
      </div>
    </div>
  )
}

const Label = ({
  isUltimate = false,
  isFree,
  text,
}: {
  isUltimate: boolean
  isFree: boolean
  text: string
}) => (
  <div
    css={css`
      padding: 8px 0;
    `}
  >
    <p
      css={css`
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 4px;
      `}
    >
      {isUltimate ? 'Ultimate Plan' : isFree ? 'Free Plan' : 'Pro Plan'}
    </p>
    <span
      css={css`
        font-size: 12px;
        color: ${colors.ATTENDANCE_GRAY};
      `}
    >
      {text}
    </span>
  </div>
)

async function createPaymentIntent() {
  const response = await req('/payment/subscription/intent')
  const clientSecret = response.intent

  return clientSecret
}

interface Props {
  onCloseModal: () => void
  onCompareClick?: () => void
  onChangeCardsPress?: () => void
  hideComparePlansButton?: boolean
  userHasCard?: boolean
  isUltimate?: boolean
  isFree?: boolean
}

export const UpgradeModal = observer(
  ({
    onCloseModal,
    onCompareClick,
    onChangeCardsPress,
    hideComparePlansButton,
    userHasCard,
    isUltimate,
    isFree,
  }: Props) => {
    const {
      me: {
        state: { account },
        fetchMe,
      },
      subscription: {
        state: { plans },
        getPlans,
      },
      payment: {
        state: { paymentList },
      },
    } = useMobxStore()

    useLayoutEffect(() => {
      if (plans === null) {
        getPlans()
      }
    }, [plans])

    const [completed, setCompleted] = useState(false)
    const [isProcessingPayment, setIsProcessingPayment] = useState(false)
    const [failed, setFailed] = useState(false)
    const [isUltimatePlan, setIsUltimatePlan] = useState<boolean>(
      isUltimate ?? false
    )
    const [isFreePlan, setIsFreePlan] = useState(isFree ?? false)
    const [isLoadingCoupon, setIsLoadingCoupon] = useState(false)
    // const [isMonthly, setIsMonthly] = useState(account?.subscription?.interval === 'year' ? false : true)
    const [isMonthly, setIsMonthly] = useState(true)
    const [coupon, setCoupon] = useState('')
    const [stripeErrorMessage, setStripeErrorMessage] = useState('')
    const [planDetails, setPlanDetails] = useState({
      monthly: {
        value: 0,
        discount: 0,
      },
      yearly: {
        value: 0,
        discount: 0,
      },
    })

    const [sliderValue, setSliderValue] = useState(0)

    const userPlan = useRef<string>('')
    // const userRecurrence = useRef<string>(isFree ? 'monthly' : 'yearly')
    const userRecurrence = useRef<string>('monthly')
    const userCoupon = useRef<string>('')

    const [selectedPlan, setSelectedPlan] = useState<
      'free' | 'professional' | 'ultimate'
    >(isUltimate ? 'ultimate' : 'professional')

    const currentDefaultPaymentMethodId = useMemo(
      () => paymentList.find((payment) => payment.default),
      [paymentList]
    )

    // PLANS

    const professionalPlan = useMemo(
      () => plans?.find((plan) => plan.id === 2),
      [plans]
    )

    const ultimatePlan = useMemo(
      () => plans?.find((plan) => plan.id === 3),
      [plans]
    )

    // USER ACCOUNT TYPES

    const isProMonthly = useMemo(
      () =>
        account?.subscription?.interval === 'month' &&
        account?.subscription_type?.id === 2,
      [account]
    )

    const isUltimateMonthly = useMemo(
      () =>
        account?.subscription?.interval === 'month' &&
        account?.subscription_type?.id === 3,
      [account]
    )

    const isProYearly = useMemo(
      () =>
        account?.subscription?.interval === 'year' &&
        account?.subscription_type?.id === 2,
      [account]
    )

    const isUltimateYearly = useMemo(
      () =>
        account?.subscription?.interval === 'year' &&
        account?.subscription_type?.id === 3,
      [account]
    )

    // ENABLE/DISABLE STRIPE FIELD

    const hideStripePayment = useMemo(
      () => account?.subscription?.status === 'active' && userHasCard,
      [account, userHasCard]
    )

    // BOOTSTRAP MODAL

    const bootstrap = useCallback(async () => {
      if (completed) {
        return
      }

      setPlanDetails({
        monthly: {
          value: isUltimatePlan
            ? ultimatePlan?.price_monthly ?? 0
            : professionalPlan?.price_monthly ?? 0,
          discount: 0,
        },
        yearly: {
          value: isUltimatePlan
            ? ultimatePlan?.price_yearly ?? 0
            : professionalPlan?.price_yearly ?? 0,
          discount: 0,
        },
      })

      if (hideStripePayment) {
        return
      }

      const clientSecret = await createPaymentIntent()

      let stripeClient: Stripe | null = null

      const host = window.location.hostname || window.location.host
      if (
        __DEV__ ||
        !['app.sportninja.com', 'canlanstats.sportninja.com'].includes(host)
      ) {
        console.log('LOADING DEV')
        stripeClient = await loadStripe(
          process.env.REACT_APP_STRIPE_KEY_DEV as string
        )
      } else {
        stripeClient = await loadStripe(
          process.env.REACT_APP_STRIPE_KEY as string
        )
      }

      if (!stripeClient) {
        return
      }

      const elements = stripeClient.elements({
        clientSecret,
        appearance: {
          theme: 'night',
        },
      })

      const cardElement = elements.create('card', {
        hidePostalCode: true,
        style: {
          base: {
            color: '#fff',
            fontWeight: 300,
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
              color: '#fce883',
            },
            '::placeholder': {
              color: '#CFD7E0',
            },
          },
          invalid: {
            iconColor: '#FFC7EE',
            color: '#FFC7EE',
          },
        },
      })

      const addressElement = elements.create('address', {
        mode: 'billing',
      })

      const cardDiv = document.querySelector('#card-element')
      const addressDiv = document.querySelector('#address-element')

      if (!cardDiv || !addressDiv) {
        return
      }

      cardElement.mount('#card-element')
      addressElement.mount('#address-element')
      const stripeForm = document.querySelector('#payment-form')

      stripeForm?.addEventListener('submit', async (e) => {
        e.preventDefault()
        setIsProcessingPayment(true)
        setStripeErrorMessage('')

        if (!stripeClient) {
          return
        }

        try {
          const { setupIntent, error } = await stripeClient.confirmCardSetup(
            clientSecret,
            {
              payment_method: {
                card: cardElement,
                billing_details: {
                  name: account?.full_name,
                },
              },
            }
          )

          if (error) {
            if (error?.message) {
              setIsProcessingPayment(false)

              return setStripeErrorMessage(error.message)
            } else if (!error?.setup_intent) {
              setIsProcessingPayment(false)
              console.error('[STP: 000] ', JSON.stringify(error))
              setCompleted(true)
              setFailed(true)

              return
            }
          }

          let setupIntentResponse: SetupIntent | undefined
          if (!setupIntent) {
            setupIntentResponse = error.setup_intent
          } else {
            setupIntentResponse = setupIntent
          }

          const response: SubscriptionResponse = await req(
            '/payment/subscription/subscribe',
            {
              method: 'POST',
              body: JSON.stringify({
                payment_method: setupIntentResponse?.payment_method,
                plan: userPlan.current,
                // recurrence: userRecurrence.current,
                recurrence: 'monthly',
                coupon_code: userCoupon.current,
              }),
            }
          )

          if (response?.subscription) {
            setIsProcessingPayment(false)
            setCompleted(true)
          } else {
            setIsProcessingPayment(false)
            setCompleted(true)
            setFailed(true)
            console.error('[SBS: 001] ', response)
          }
        } catch (error) {
          setIsProcessingPayment(false)
          console.error('[STP: 001] ', JSON.stringify(error))
          setCompleted(true)
          setFailed(true)
        }
      })
    }, [
      plans,
      account,
      selectedPlan,
      coupon,
      ultimatePlan,
      professionalPlan,
      isUltimatePlan,
      isMonthly,
      isProcessingPayment,
      stripeErrorMessage,
    ])

    const handlePlans = useCallback(
      (value: number) => {
        if (value === 1) {
          setIsFreePlan(true)
          setIsUltimatePlan(false)

          setSelectedPlan('free')
          userPlan.current = 'free'
          userRecurrence.current = 'monthly'
        } else if (value === 2) {
          setIsUltimatePlan(false)
          setIsFreePlan(false)

          setPlanDetails({
            monthly: {
              value: professionalPlan?.price_monthly ?? 0,
              discount: 0,
            },
            yearly: {
              value: professionalPlan?.price_yearly ?? 0,
              discount: 0,
            },
          })

          setSelectedPlan('professional')
          userPlan.current = 'professional'

          if (isProMonthly) {
            // setIsMonthly(false)
            setIsMonthly(true)
          }

          if (isProYearly) {
            setIsMonthly(true)
          }
        } else if (value === 3) {
          setIsUltimatePlan(true)
          setIsFreePlan(false)

          setPlanDetails({
            monthly: {
              value: ultimatePlan?.price_monthly ?? 0,
              discount: 0,
            },
            yearly: {
              value: ultimatePlan?.price_yearly ?? 0,
              discount: 0,
            },
          })

          setSelectedPlan('ultimate')
          userPlan.current = 'ultimate'

          if (isUltimateMonthly) {
            // setIsMonthly(false)
            setIsMonthly(true)
          }

          if (isUltimateYearly) {
            setIsMonthly(true)
          }
        }
      },
      [professionalPlan, ultimatePlan, isFreePlan, isUltimatePlan, isMonthly]
    )

    const { control, handleSubmit, setValue } = useForm()

    const onCouponCodeSubmit = useCallback(
      async (data: any) => {
        if (data.coupon === '') {
          return
        }

        setIsLoadingCoupon(true)

        if (data.coupon === coupon) {
          alert(`Coupon code ${data.coupon} is already in use`)
          setIsLoadingCoupon(false)
          setValue('coupon', '')

          return
        }

        userCoupon.current = data.coupon

        try {
          const response: CouponCheckResponse = await req(
            '/payment/coupon/validate',
            {
              method: 'POST',
              body: JSON.stringify({
                coupon_code: data.coupon,
              }),
            }
          )

          if (!response?.id) {
            alert(`Coupon code ${data.coupon} is invalid`)
            setIsLoadingCoupon(false)
            setValue('coupon', '')

            return
          }

          if (response.percent_off === 0) {
            alert(`Coupon code: ${data.coupon} is not valid for this plan`)
            setIsLoadingCoupon(false)
            setValue('coupon', '')

            return
          }

          const discount = response?.percent_off ?? 1
          const monthlyValue = planDetails?.monthly.value ?? 0
          const yearlyValue = planDetails?.yearly.value ?? 0

          setPlanDetails({
            monthly: {
              value: monthlyValue - monthlyValue * (discount / 100),
              discount,
            },
            yearly: {
              value: yearlyValue - yearlyValue * (discount / 100),
              discount,
            },
          })

          setCoupon(data.coupon)
          setValue('coupon', '')
          setIsLoadingCoupon(false)
        } catch (err) {
          console.error('[VLD: 002] ', JSON.stringify(err))
        }
      },
      [planDetails]
    )

    const options = useMemo(() => {
      if (account?.subscription_type.id === 1) {
        return [
          {
            label: (
              <Label
                isUltimate={false}
                isFree={false}
                text={`$${isMonthly ? '50.00' : '500.00'} USD/500 Participants`}
              />
            ),
            value: 2,
          },
          {
            label: (
              <Label
                isUltimate
                isFree={false}
                text={`$${isMonthly ? '99.00' : '999.00'} USD/500 Participants`}
              />
            ),
            value: 3,
          },
        ]
      }

      return [
        {
          label: (
            <Label
              isUltimate={false}
              isFree
              text={'Free up to 50 participants'}
            />
          ),
          value: 1,
        },
        {
          label: (
            <Label
              isUltimate={false}
              isFree={false}
              text={`$${isMonthly ? '50.00' : '500.00'} USD/500 Participants`}
            />
          ),
          value: 2,
        },
        {
          label: (
            <Label
              isUltimate
              isFree={false}
              text={`$${isMonthly ? '99.00' : '999.00'} USD/500 Participants`}
            />
          ),
          value: 3,
        },
      ]
    }, [isMonthly, account])

    const selectedValue = useMemo(() => {
      if (isFreePlan) {
        return {
          label: (
            <Label
              isUltimate={false}
              isFree
              text={'Free up to 50 participants'}
            />
          ),
          value: 1,
        }
      }
      if (isMonthly && !isUltimatePlan) {
        return {
          label: (
            <Label
              isUltimate={false}
              isFree={false}
              text={`$${Math.round(
                planDetails.monthly.value
              )}.00 USD/500 Participants`}
            />
          ),
          value: 2,
        }
      }

      if (!isMonthly && !isUltimatePlan) {
        return {
          label: (
            <Label
              isUltimate={false}
              isFree={false}
              text={`$${Math.round(
                planDetails.yearly.value
              )}.00 USD/500 Participants`}
            />
          ),
          value: 2,
        }
      }

      if (isMonthly && isUltimatePlan) {
        return {
          label: (
            <Label
              isUltimate
              isFree={false}
              text={`$${Math.round(
                planDetails.monthly.value
              )}.00 USD/500 Participants`}
            />
          ),
          value: 3,
        }
      }

      if (!isMonthly && isUltimatePlan) {
        return {
          label: (
            <Label
              isUltimate
              isFree={false}
              text={`$${Math.round(
                planDetails.yearly.value
              )}.00 USD/500 Participants`}
            />
          ),
          value: 3,
        }
      }
    }, [isMonthly, isUltimatePlan, isFreePlan, planDetails])

    const removeCoupon = useCallback(() => {
      setValue('coupon', '')
      setCoupon('')
      userCoupon.current = ''

      setPlanDetails({
        monthly: {
          value: isUltimatePlan
            ? ultimatePlan?.price_monthly ?? 0
            : professionalPlan?.price_monthly ?? 0,
          discount: 0,
        },
        yearly: {
          value: isUltimatePlan
            ? ultimatePlan?.price_yearly ?? 0
            : professionalPlan?.price_yearly ?? 0,
          discount: 0,
        },
      })
    }, [professionalPlan, ultimatePlan, isUltimatePlan])

    const onSwapPlan = useCallback(async () => {
      setIsProcessingPayment(true)

      try {
        const response: SubscriptionResponse = await req(
          '/payment/subscription/swap',
          {
            method: 'POST',
            body: JSON.stringify({
              plan: isFreePlan ? 'free' : selectedPlan,
              recurrence: isFreePlan
                ? 'monthly'
                : isMonthly
                ? 'monthly'
                : 'yearly',
              coupon_code: coupon,
            }),
          }
        )

        if (response?.subscription) {
          setIsProcessingPayment(false)
          setCompleted(true)
        } else {
          setIsProcessingPayment(false)
          setCompleted(true)
          setFailed(true)
          console.error('[SWP: 001] ', response)
        }
      } catch (error) {
        setIsProcessingPayment(false)
        setCompleted(true)
        setFailed(true)
        console.error('[STP: 002] ', JSON.stringify(error))
      }
    }, [
      planDetails,
      isUltimatePlan,
      selectedPlan,
      coupon,
      isFreePlan,
      isMonthly,
      isProcessingPayment,
    ])

    useEffect(() => {
      setValue('coupon', '')
      setCoupon('')

      if (account?.subscription?.interval === 'month') {
        // setIsMonthly(false)
        setIsMonthly(true)
      } else {
        setIsMonthly(true)
      }

      setPlanDetails({
        monthly: {
          value: isUltimatePlan
            ? ultimatePlan?.price_monthly ?? 0
            : professionalPlan?.price_monthly ?? 0,
          discount: 0,
        },
        yearly: {
          value: isUltimatePlan
            ? ultimatePlan?.price_yearly ?? 0
            : professionalPlan?.price_yearly ?? 0,
          discount: 0,
        },
      })
    }, [isUltimatePlan, account?.subscription?.interval])

    // const shouldShowMonthlyPlan = useCallback(() => {
    //   let display = 'flex'

    //   if (!isUltimatePlan && isProMonthly) {
    //     display = 'none'
    //   }

    //   if (isUltimatePlan && isUltimateMonthly) {
    //     display = 'none'
    //   }

    //   return display
    // }, [isUltimatePlan, isMonthly, isProMonthly, isUltimateMonthly])

    // const shouldShowYearlyPlan = useCallback(() => {
    //   let display = 'flex'

    //   if (!isUltimatePlan && isProYearly) {
    //     display = 'none'
    //   }

    //   if (isUltimatePlan && isUltimateYearly) {
    //     display = 'none'
    //   }

    //   return display
    // }, [isUltimatePlan, isMonthly, isProMonthly, isUltimateMonthly])

    useEffect(() => {
      userPlan.current = isUltimate ? 'ultimate' : 'professional'
      bootstrap()
    }, [])

    return (
      <div
        css={css`
          width: ${hideStripePayment ? '512px' : '1024px'};
          padding: 16px;
          background: no-repeat fixed linear-gradient(#282e38, #181a1d);
          display: flex;
          flex-direction: column;
          max-height: 90vh;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 0.8em;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          }

          &::-webkit-scrollbar-thumb {
            background-color: ${colors.SOFT_STEEL};
          }
        `}
      >
        <ModalHeader
          onCloseClick={() => {
            onCloseModal()
            fetchMe(true)
          }}
          title='Upgrade your plan'
        />
        <div
          css={css`
            flex: 1;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 16px;
            display: ${completed ? 'flex' : 'none'};
          `}
        >
          {failed ? (
            <img src={purchaseFailed} />
          ) : (
            <img src={purchaseComplete} />
          )}
          <p
            css={css`
              color: ${colors.DEFAULT_FLAIR};
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
            `}
          >
            {failed
              ? 'An error happened, try again later.'
              : 'Purchase Completed'}
          </p>
        </div>
        <div
          css={css`
            padding-top: 50px;
            display: ${completed ? 'flex' : 'none'};
          `}
        >
          <Button
            label='DONE'
            onClick={() => {
              onCloseModal()
              fetchMe()
            }}
          />
        </div>
        <div
          css={css`
            display: ${completed ? 'none' : 'flex'};
            gap: 16px;
            flex: 1;
            padding-top: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              flex: 2;
              justify-content: space-between;
            `}
          >
            <div>
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                `}
              >
                <p>Select Your Plan</p>

                {hideComparePlansButton ? null : (
                  <div
                    onClick={onCompareClick}
                    css={css`
                      cursor: pointer;
                      flex-wrap: wrap;
                      flex-shrink: 1;
                    `}
                  >
                    <p
                      css={css`
                        color: ${colors.DEFAULT_FLAIR};
                      `}
                    >
                      Compare Plans
                    </p>
                  </div>
                )}
              </div>

              <div
                css={css`
                  margin: 16px 0;
                `}
              >
                <Select
                  defaultValue={{
                    label: (
                      <Label
                        isUltimate={isUltimatePlan}
                        isFree={isFreePlan}
                        text={
                          isFreePlan
                            ? 'Free up to 50 participants'
                            : isUltimatePlan
                            ? `$${
                                isMonthly ? '99.00' : '999.00'
                              } US/500 Participants / ${
                                isMonthly ? 'month' : 'year'
                              }`
                            : `$${
                                isMonthly ? '50.00' : '500.00'
                              } US/500 Participants / ${
                                isMonthly ? 'month' : 'year'
                              }`
                        }
                      />
                    ),
                    value: isUltimatePlan ? 3 : isFreePlan ? 1 : 2,
                  }}
                  value={selectedValue}
                  options={options}
                  onChange={(x) => {
                    if (x?.value) {
                      handlePlans(x.value)
                    }
                  }}
                  styles={{
                    container: (baseStyles) => ({
                      ...baseStyles,
                      width: '100%',
                    }),
                    control: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: colors.HEADER,
                      borderColor: colors.SOFT_STEEL,
                    }),
                    singleValue: (baseStyles) => ({
                      ...baseStyles,
                      color: colors.WHITE,
                    }),
                    menu: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: colors.HEADER,
                      borderColor: colors.SOFT_STEEL,
                      zIndex: 999999999999999,
                    }),
                    option: (baseStyles, { isFocused }) => ({
                      ...baseStyles,
                      backgroundColor: isFocused
                        ? colors.SOFT_STEEL
                        : undefined,
                    }),
                  }}
                />
              </div>

              {!isFreePlan && !isFree && (
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    gap: 16px;
                  `}
                >
                  <div
                    css={css`
                      margin-bottom: 16px;
                      display: flex;
                      flex-direction: column;
                      gap: 16px;
                      flex: 5;
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        justify-content: space-between;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                      `}
                    >
                      <p>Cost Estimator</p>
                    </div>
                    <Slider
                      min={1}
                      max={20}
                      onChange={setSliderValue}
                      value={sliderValue}
                      hideValue
                      threshold={1}
                    />
                    <p
                      css={css`
                        text-align: center;
                        font-size: 14px;
                        font-weight: 500;
                        color: ${colors.ATTENDANCE_GRAY};
                      `}
                    >
                      {new Intl.NumberFormat('en-CA').format(
                        (sliderValue || 1) * 500
                      )}{' '}
                      participants
                    </p>
                  </div>
                  <div
                    css={css`
                      flex: 1;
                      display: flex;
                      flex-direction: column;
                      gap: 8px;
                      align-items: flex-end;
                      padding-top: 16px;
                    `}
                  >
                    <p>
                      {isMonthly
                        ? `$${new Intl.NumberFormat('en-CA').format(
                            Math.round(
                              (sliderValue || 1) * planDetails.monthly.value
                            )
                          )}.00`
                        : `$${new Intl.NumberFormat('en-CA').format(
                            Math.round(
                              (sliderValue || 1) * planDetails.yearly.value
                            )
                          )}.00`}
                    </p>
                    <p>{sliderValue <= 1 ? 'to start' : 'Estimated'}</p>
                  </div>
                </div>
              )}

              {/* REMOVING PERIOD SWITCH */}

              {/* <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                `}
              >
                <p>Billing Period</p>
              </div>

              <div
                css={css`
                  margin: 16px 0;
                  border: 1px solid ${colors.SOFT_STEEL};
                  border-radius: 8px;
                  padding: 4px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 4px;
                `}
              >
                {isFreePlan ? (
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      border-radius: 4px;
                      background-color: ${colors.DEFAULT_FLAIR};
                      flex: 1;
                      padding: 8px 0;
                      justify-content: center;
                      align-items: center;
                      cursor: pointer;
                      transition: background-color 0.2s ease-in-out;
                    `}
                  >
                    <p
                      css={css`
                        font-family: 'Rift', sans-serif;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                      `}
                    >
                      free
                    </p>
                  </div>
                ) : (
                  <>
                    <div
                      onClick={() => {
                        setIsMonthly(true)
                        userRecurrence.current = 'monthly'
                      }}
                      css={css`
                        display: ${shouldShowMonthlyPlan()};
                        flex-direction: column;
                        border-radius: 4px;
                        background-color: ${isMonthly
                          ? colors.DEFAULT_FLAIR
                          : 'transparent'};
                        flex: 1;
                        padding: 8px 0;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        transition: background-color 0.2s ease-in-out;
                      `}
                    >
                      <p
                        css={css`
                          font-family: 'Rift', sans-serif;
                          font-size: 20px;
                          font-style: normal;
                          font-weight: 700;
                          color: ${colors.WHITE};
                        `}
                      >
                        monthly{' '}
                        {planDetails.monthly.discount > 0
                          ? `- ${planDetails.monthly.discount}% off`
                          : ''}
                      </p>
                      <span
                        css={css`
                          font-size: 14px;
                          margin-top: 4px;
                          font-style: normal;
                          color: ${!isMonthly
                            ? colors.ATTENDANCE_GRAY
                            : colors.HEADER};
                          font-weight: 400;
                        `}
                      >
                        ${Math.round(planDetails.monthly.value)}.00
                      </span>
                    </div>
                    <div
                      onClick={() => {
                        setIsMonthly(false)
                        userRecurrence.current = 'yearly'
                      }}
                      css={css`
                        display: ${shouldShowYearlyPlan()};
                        flex-direction: column;
                        border-radius: 4px;
                        background-color: ${!isMonthly
                          ? colors.DEFAULT_FLAIR
                          : 'transparent'};
                        flex: 1;
                        padding: 8px 0;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        transition: background-color 0.2s ease-in-out;
                      `}
                    >
                      <p
                        css={css`
                          font-family: 'Rift', sans-serif;
                          font-size: 20px;
                          font-style: normal;
                          font-weight: 700;
                          color: ${colors.WHITE};
                        `}
                      >
                        annual{' '}
                        {planDetails.yearly.discount > 0
                          ? `- ${planDetails.yearly.discount}% off`
                          : ''}
                      </p>
                      <span
                        css={css`
                          font-size: 14px;
                          margin-top: 4px;
                          font-style: normal;
                          font-weight: 400;
                          color: ${isMonthly
                            ? colors.ATTENDANCE_GRAY
                            : colors.HEADER};
                        `}
                      >
                        ${Math.round(planDetails.yearly.value)}.00
                      </span>
                    </div>
                  </>
                )}
              </div> */}

              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  align-items: center;
                  margin-top: 32px;
                `}
              >
                <p
                  css={css`
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    color: ${colors.ATTENDANCE_GRAY};
                  `}
                >
                  Subscribe to SportNinja
                </p>
                {isFreePlan ? (
                  <div
                    css={css`
                      margin-top: 8px;
                      display: flex;
                      align-items: center;
                      gap: 4px;
                    `}
                  >
                    <p
                      css={css`
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 700;
                        color: ${colors.WHITE};
                      `}
                    >
                      FREE
                    </p>
                  </div>
                ) : (
                  <div
                    css={css`
                      margin-top: 8px;
                      display: flex;
                      align-items: center;
                      gap: 4px;
                    `}
                  >
                    <p
                      css={css`
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 700;
                        color: ${colors.WHITE};
                      `}
                    >
                      {isMonthly
                        ? `$${Math.round(planDetails.monthly.value)}.00`
                        : `$${Math.round(planDetails.yearly.value)}.00`}
                    </p>
                    <span>
                      per <br />
                      {isMonthly ? 'month' : 'year'}
                    </span>
                  </div>
                )}
              </div>

              {!isFreePlan && !isFree && (
                <div
                  css={css`
                    display: flex;
                    gap: 8px;
                    margin-top: 16px;
                  `}
                >
                  <Icon
                    name='info-circle'
                    fontSize={16}
                    color={colors.ATTENDANCE_GRAY}
                  />
                  <p
                    css={css`
                      color: ${colors.ATTENDANCE_GRAY};
                      font-size: 11px;
                      font-style: normal;
                      font-weight: 500;
                    `}
                  >
                    {
                      'Your monthly subscription charge is based on the total number of unique participants during the monthly billing period as well as future competitions. Subscription levels are tiered in increments of 500 participants and calculated based upon the product-level you have chosen.'
                    }
                  </p>
                </div>
              )}

              {userHasCard && (
                <div
                  css={css`
                    margin-top: 32px;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      justify-content: space-between;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      margin-bottom: 6px;
                    `}
                  >
                    <p>Payment Method</p>

                    <div
                      onClick={onChangeCardsPress}
                      css={css`
                        cursor: pointer;
                        flex-wrap: wrap;
                        flex-shrink: 1;
                      `}
                    >
                      <p
                        css={css`
                          color: ${colors.DEFAULT_FLAIR};
                        `}
                      >
                        Change
                      </p>
                    </div>
                  </div>
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      gap: 8px;
                      padding: 8px;
                      border: 1px solid ${colors.SOFT_STEEL};
                      border-radius: 8px;
                      flex: 1;
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        gap: 8px;
                      `}
                    >
                      <Icon
                        name={handleCardIcon(
                          currentDefaultPaymentMethodId?.card_type
                        )}
                        faType='fab'
                        fontSize={24}
                        color={colors.WHITE}
                      />
                      <div>
                        <p
                          css={css`
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            color: ${colors.WHITE};
                          `}
                        >
                          **** **** ****{' '}
                          {currentDefaultPaymentMethodId?.last_four}
                        </p>
                        <p
                          css={css`
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            color: ${colors.ATTENDANCE_GRAY};
                          `}
                        >
                          {String(
                            currentDefaultPaymentMethodId?.exp_month
                          ).padStart(2, '0')}
                          /{currentDefaultPaymentMethodId?.exp_year}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!isFreePlan && (
                <>
                  <form
                    css={css`
                      display: flex;
                      align-items: flex-end;
                      border-top: 1px solid ${colors.SOFT_STEEL};
                      margin-top: 16px;
                      padding-top: 16px;
                      gap: 16px;
                    `}
                  >
                    <Input
                      control={control}
                      disabled={isLoadingCoupon}
                      fieldName='coupon'
                      label='Coupon Code'
                      placeholder='Enter coupon code'
                    />
                    <button
                      onClick={handleSubmit(onCouponCodeSubmit)}
                      type='submit'
                      disabled={isLoadingCoupon}
                      css={css`
                        padding-top: 12px;
                        background-color: ${colors.DEFAULT_FLAIR};
                        padding: 8px 16px;
                        border-radius: 4px;
                        min-height: 40px;
                        margin-bottom: 8px;
                      `}
                    >
                      {isLoadingCoupon ? (
                        <div
                          css={css`
                            padding: 0 12px;
                          `}
                        >
                          <LoadingSpinner size={1.8} rgbColorTop='#fff' />
                        </div>
                      ) : (
                        <span
                          css={css`
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            color: ${colors.WHITE};
                          `}
                        >
                          Apply
                        </span>
                      )}
                    </button>
                  </form>
                  {coupon && (
                    <div
                      css={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 8px 0px;
                        padding-bottom: 16px;
                        border-bottom: 1px solid ${colors.SOFT_STEEL};
                      `}
                    >
                      <span
                        css={css`
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 700;
                          color: ${colors.WHITE};
                        `}
                      >
                        {coupon}{' '}
                        <span
                          css={css`
                            font-weight: 400;
                            color: ${colors.ATTENDANCE_GRAY};
                          `}
                        >
                          -{' '}
                          {isMonthly
                            ? planDetails.monthly.discount
                            : planDetails.yearly.discount}
                          % off
                        </span>
                      </span>
                      <button onClick={removeCoupon}>
                        <span
                          css={css`
                            color: ${colors.DEFAULT_FLAIR};
                          `}
                        >
                          REMOVE
                        </span>
                      </button>
                    </div>
                  )}
                </>
              )}

              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding-top: 16px;
                `}
              >
                <p
                  css={css`
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                  `}
                >
                  Total Due
                </p>
                <p
                  css={css`
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                  `}
                >
                  {isFreePlan
                    ? '$0.00 USD'
                    : isMonthly
                    ? `$${Math.round(planDetails.monthly.value)}.00 USD`
                    : `$${Math.round(planDetails.yearly.value)}.00 USD`}
                </p>
              </div>
            </div>
            <div>
              {hideStripePayment && (
                <div
                  css={css`
                    margin: 30px 0;
                  `}
                >
                  <Button
                    onClick={onSwapPlan}
                    label='Upgrade Now'
                    isLoading={isProcessingPayment}
                    disabled={isProcessingPayment || isLoadingCoupon}
                  />
                </div>
              )}
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <p>Powered by</p>
                <img
                  src={stripeLogo}
                  css={css`
                    margin-left: 6px;
                  `}
                />
              </div>
            </div>
          </div>
          {hideStripePayment ? (
            <div />
          ) : (
            <>
              <div
                css={css`
                  width: 1px;
                  display: flex;
                  background-color: ${colors.SOFT_STEEL};
                `}
              />
              <div
                css={css`
                  display: flex;
                  flex: 2;
                  flex-direction: column;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    margin-bottom: 16px;
                  `}
                >
                  <p>Card Information</p>
                </div>
                <form
                  id='payment-form'
                  css={css`
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    flex-grow: 1;
                  `}
                >
                  <div
                    id='card-element'
                    css={css`
                      border: 1px solid ${colors.SOFT_STEEL};
                      padding: 10px;
                      border-radius: 4px;
                      background-color: #30303d;
                      box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.75);
                    `}
                  />
                  <div id='payment-element' />
                  <div
                    css={css`
                      display: flex;
                      justify-content: space-between;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      margin: 32px 0 16px 0;
                    `}
                  >
                    <p>Billing Address</p>
                  </div>
                  <div id='address-element' />
                  <div
                    css={css`
                      margin-top: 50px;
                    `}
                  >
                    {stripeErrorMessage && (
                      <p
                        css={css`
                          color: ${colors.ERROR};
                          margin-bottom: 32px;
                          text-transform: capitalize;
                        `}
                      >
                        • {stripeErrorMessage}
                      </p>
                    )}
                    <Button
                      label='Upgrade Now'
                      id='submit'
                      type='submit'
                      isLoading={isProcessingPayment}
                      disabled={isProcessingPayment || isLoadingCoupon}
                    />
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }
)
