/** @jsxImportSource @emotion/react */
import React from 'react'
import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import { ACTIONS } from '@sportninja/common/reducers/game-timeline-parser'
import { t } from '@sportninja/common/i18n'
import { colors } from '@sportninja/common/constants/appColors'
import deepEqual from 'fast-deep-equal'
import cn from 'classnames'

import { Flex } from '../../../components/Layout'
import { font, flairColor } from '../../../components/css'
import Picture from '../../../components/Picture'
import { COL_GUTTER_UNIT } from '../../../components/Responsive'
import FormSheet from '../../../components/Form/FormSheet'
import { InitialsThumbnail } from '../../../components/ThumbnailAndName'
import Icon from '../../../components/Icon'
import { ReactComponent as ArrowUpTurn } from './arrow-upturn.svg'
import { SidewayText } from '../GamePageHeaderMatchupCss'

export const Assists = ({ assists }) => {
  if (!assists || assists.length === 0) return 'Unassisted'

  const bodyText = assists
    .map((assist) => {
      let num = ''
      if (assist.number) num += `#${assist.number} - `
      return `${num}${assist.nameFirst} ${assist.nameLast} (${assist.positionAbbrev})`
    })
    .join(' / ')

  return `Assist${assists.length > 1 ? 's' : ''}: ${bodyText}`
}

const EventText = styled(Flex)`
  ${(props) => (props.right ? 'margin-right' : 'margin-left')}: 12px;
`

const TextWrapper = styled.div`
  ${(props) => props.right && 'text-align: right'};
`

const Score = styled.div`
  min-width: 50px;
  margin: 0 16px;
  opacity: ${(props) => (props.didScore ? 0.45 : 0.14)};
  ${font.title};
  font-size: 56px;
  font-weight: 500;
  letter-spacing: 3.22px;
  text-align: center;
`

const TimeAndAction = styled.div`
  margin: 0 auto;
  margin-top: 3px;
  font-size: 16px;
  letter-spacing: 0.92px;
  line-height: 19px;
  text-transform: uppercase;
  text-align: center;
  min-width: 95px;

  .goal {
    color: ${flairColor};
  }
`

export const Pencil = ({ className }) => (
  <div
    className={cn('pencil-icon', className)}
    css={css`
      padding: 12px;
      border-radius: 50%;
      margin: 0 8px;
    `}
  >
    <Icon name='pencil-alt' fontSize={20} color={flairColor} />
  </div>
)

const TimelineEvent = ({
  event,
  isEditMode,
  localHomeScore,
  localVisitingScore,
  onDelete,
  onEdit,
  editForm,
  editTitle,
  right,
  goalTypes,
  teamImageId,
  isSoccer = false,
}) => {
  if (!event) {
    return false
  }
  const {
    action,
    assists,
    edited,
    duration,
    imageId,
    initials,
    name,
    nextGoalie,
    number,
    time,
    type,
    goal_type_id,
    positionAbbrev,
  } = event

  // Temporary we are using goalie changes as substitution on soccer
  const actionStrings = {
    [ACTIONS.goal]: t('common:goal'),
    [ACTIONS.goalieChange]: isSoccer
      ? 'Substitution'
      : t('common:goalieChange'),
    [ACTIONS.penalty]: isSoccer ? 'Foul' : t('common:penalty'),
  }

  const isGoal = action === 'Goal'
  const isGoalie = action === ACTIONS.goalieChange
  const isEdited = typeof edited !== 'undefined'

  const TimelineEditButton = ({ onClick }) => (
    <Flex
      onClick={isEditMode ? onClick : undefined}
      alignItems='center'
      className='timeline-event'
      css={css`
        flex: 1 1 50%;
        height: 100%;
        flex-direction: ${right ? 'row-reverse' : 'row'};
        background-color: rgba(255, 255, 255, 0.05);

        &,
        .pencil-icon {
          transition: background-color 0.2s ease-in-out;
        }

        ${isEditMode &&
        css`
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
            cursor: pointer;
            box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.25);

            .pencil-icon {
              background-color: rgba(255, 255, 255, 0.08);
            }
          }
        `}
      `}
    >
      <SidewayText
        right={right}
        css={css`
          padding: 0 12px;
        `}
      >
        {right ? 'Visitor' : 'Home'}
      </SidewayText>
      {imageId ? (
        <Picture size='xsmall' imageId={imageId} />
      ) : name || number ? (
        <InitialsThumbnail size={34}>{initials}</InitialsThumbnail>
      ) : (
        <Picture
          size='xsmall'
          imageId={teamImageId}
          iconName='user-friends'
          square
          fit
        />
      )}
      <EventText column right={right}>
        <TextWrapper
          right={right}
          css={css`
            ${font.title}
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 1.64px;
            margin-bottom: 6px;
          `}
        >
          {isGoalie
            ? nextGoalie
              ? `#${nextGoalie.number} - ${nextGoalie.name} (${
                  nextGoalie?.positionAbbrev || '-'
                })`
              : t('Scoring:emptyNet')
            : name || number
            ? `${number ? `#${number} - ` : ''}${
                name ? name : ''
              } (${positionAbbrev})`
            : 'Bench'}
        </TextWrapper>
        <TextWrapper
          right={right}
          css={css`
            font-size: 14px;
            letter-spacing: 0.37px;
            opacity: 0.7;
          `}
        >
          {action === 'Penalty' ? (
            `${duration ? `${duration} min - ` : ''}${type}`
          ) : isGoalie ? (
            name ? (
              `#${number} - ${name} (${positionAbbrev})`
            ) : (
              t('Scoring:emptyNet')
            )
          ) : (
            <Assists assists={assists} />
          )}
        </TextWrapper>
        {isEdited && (
          <TextWrapper
            right={right}
            css={css`
              margin-top: 6px;
              color: ${colors.PRIMARY};
              font-size: 14px;
              letter-spacing: 0.37px;
            `}
          >
            Edited by {edited.by} on {edited.at}
          </TextWrapper>
        )}
      </EventText>
      {isGoalie && (
        <Flex
          noFlex
          column
          css={css`
            padding: 0 8px;
          `}
        >
          <ArrowUpTurn height='24px' width='32px' />
          <ArrowUpTurn
            height='24px'
            width='32px'
            css={css`
              path {
                fill: #ff2e2e;
              }
              transform: scaleY(-1);
              margin-top: 8px;
            `}
          />
        </Flex>
      )}
      {event?.card ? (
        <div
          style={{
            marginRight: 8,
            marginLeft: 8,
            borderRadius: 8,
            width: '40px',
            height: '60px',
            backgroundColor:
              event?.card?.type?.abbreviation === 'RC'
                ? 'red'
                : colors.AMERICAN_YELLOW,
          }}
        ></div>
      ) : null}
      {isEditMode && <Pencil />}
    </Flex>
  )

  return (
    <Flex
      flexDirection={right ? 'row-reverse' : 'row'}
      justifyContent='center'
      alignItems='center'
      css={css`
        min-height: ${isEdited ? 96 : 76}px;
        position: relative;
        margin: 0 ${COL_GUTTER_UNIT}px 2px;
      `}
      className={right ? 'right' : ''}
    >
      <FormSheet
        form={editForm}
        label={editTitle}
        onDelete={onDelete}
        onSubmit={onEdit}
        title={editTitle}
        Button={TimelineEditButton}
      />

      <div
        className='timeline-edit-btn-text'
        css={css`
          color: white;
          ${font.body};
          font-size: 16px;
          letter-spacing: 0.92px;
          line-height: 19px;
          text-transform: uppercase;
          min-width: ${isEditMode ? 108 : 260}px;
        `}
      >
        <Flex
          alignItems='center'
          justifyContent={isEditMode ? 'flex-start' : 'center'}
        >
          {!isEditMode && isGoal && (
            <Score didScore={!right}>{localHomeScore}</Score>
          )}
          <TimeAndAction>
            <div
              className={`timeline-action-text${isGoal ? ' goal' : ''}`}
              css={css`
                color: ${isGoal && flairColor};
              `}
            >
              {actionStrings[action]}
            </div>
            {isGoal && typeof goal_type_id !== 'undefined' && (
              <div
                css={css`
                  font-size: 12px;
                  line-height: 13px;
                  margin-top: 4px;
                `}
              >
                {goalTypes?.find((type) => type?.id === goal_type_id)?.name}
              </div>
            )}
            <div
              css={css`
                margin-top: 6px;
              `}
            >
              {time}
            </div>
          </TimeAndAction>
          {!isEditMode && isGoal && (
            <Score didScore={right}>{localVisitingScore}</Score>
          )}
        </Flex>
      </div>
      <Flex
        css={css`
          flex: 1 1 50%;
        `}
      />
    </Flex>
  )
}

const equalityCheck = (prevEvent, nextEvent) => {
  return deepEqual(prevEvent, nextEvent)
}

export default React.memo(TimelineEvent, equalityCheck)
