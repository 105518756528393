/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { border, borderColor, zIndex } from '../css'

import Icon from '../Icon'
import { Flex } from '../Layout'
import { CELL_WIDTH, FIRST_CELL_WIDTH } from './list-constants'

export const ListHeader = ({
  headers,
  options,
  setSort,
  setDirection,
  sort,
  direction,
  headerBorder = false,
  width,
  initialDirection,
  isMobile,
  shouldStickyHeader = false,
  alternativeOptionsIcon = false,
}) => {
  return (
    <Flex
      row
      alignItems='center'
      justifyContent='space-between'
      className='list-header'
      css={css`
        position: relative;
        margin-bottom: 16px;
        padding: 8px 0;

        ${shouldStickyHeader &&
        css`
          position: sticky;
          top: 64px;
          margin-bottom: 16px;
          padding: 15px 0;
          z-index: ${zIndex.base};
          background-color: ${colors.HEADER_BG_2};
        `}

        ${isMobile &&
        css`
          &,
          & .right-buffer {
            background-color: ${colors.HEADER};
            border-top: 1px solid ${colors.SOFT_STEEL};
          }
          border-top: 1px solid ${colors.SOFT_STEEL};
          width: ${width}px;
          padding: 0;
          margin: 0;
          height: 56px;
        `}

        ${headerBorder &&
        css`
          height: unset !important;
          padding: 8px 0;
          margin-bottom: 16px;
          border-bottom: 1px solid white;
          border-top: 0 !important;
          margin-bottom: 0 !important;
        `}
      `}
    >
      <div
        className='left-buffer'
        css={css`
          position: absolute;
          width: 8px;
          top: 0;
          bottom: 0px;
          left: -8px;
          ${shouldStickyHeader && `background-color: ${colors.HEADER_BG_2}`}
          ${headerBorder && 'border-bottom: 1px solid white;'}
        `}
      />
      {headers.map((header, idx) => {
        const canSort = typeof setSort === 'function'

        return (
          <Flex
            noFlex
            row
            alignItems='center'
            key={header.text}
            className={idx === 0 ? 'header-first-cell' : ''}
            css={css`
              font-weight: 700;
              text-transform: uppercase;
              width: ${header.sizePercent}%;
              cursor: ${canSort && header.sort ? 'pointer' : 'auto'};
              padding-right: 10px;

              ${isMobile &&
              css`
                min-width: ${header.minWidth || CELL_WIDTH}px;
                width: unset;

                ${idx === 0 &&
                css`
                  min-width: ${header.minWidth || FIRST_CELL_WIDTH}px;
                  height: inherit;
                  position: sticky;
                  left: 0;
                  background-color: ${colors.HEADER};
                  border-right: 1px solid ${colors.SOFT_STEEL};
                  margin-right: 12px;
                  box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.25);
                  clip-path: inset(0px -3px 0 0);
                  padding-left: 8px;
                  padding-right: 8px;
                  z-index: 1;
                `}
              `}
            `}
            onClick={() => {
              if (canSort && header.sort) {
                setSort(header.sort)
                setDirection(
                  header.sort === sort
                    ? direction === 'desc'
                      ? 'asc'
                      : 'desc'
                    : initialDirection
                )
              }
            }}
          >
            <Flex
              noFlex
              css={css`
                position: relative;

                &:hover {
                  .header-tooltip {
                    opacity: 1;
                  }
                }
              `}
            >
              {header.Header ? <header.Header /> : header.text}
              {header.sort && sort === header.sort && (
                <Icon
                  name={direction === 'desc' ? 'caret-down' : 'caret-up'}
                  css={css`
                    margin-left: 3px;
                  `}
                />
              )}
              {header.toolTip && (
                <div
                  className='header-tooltip'
                  css={css`
                    z-index: ${zIndex.base};
                    position: absolute;
                    top: 18px;
                    right: 0;
                    opacity: 0;
                    min-width: max-content;
                    transition: opacity 0.2s ease-in-out;
                    background-color: ${colors.HEADER};
                    border: 1px solid ${colors.ATTENDANCE_GRAY};
                    border-radius: 3px;
                    padding: 4px;
                    font-size: 13px;
                    text-align: right;
                  `}
                >
                  {header.toolTip}
                </div>
              )}
            </Flex>
          </Flex>
        )
      })}

      {typeof options === 'function' && !alternativeOptionsIcon && (
        <Flex
          noFlex
          row
          alignItems='center'
          css={css`
            font-weight: 700;
            text-transform: uppercase;
            width: 150px;
            min-width: 90px;
            margin-left: 8px;
            justify-content: flex-end;
            margin-right: 12px;
          `}
        >
          Options
        </Flex>
      )}
      {typeof options === 'function' && alternativeOptionsIcon && (
        <Flex
          noFlex
          row
          alignItems='center'
          css={css`
            font-weight: 700;
            text-transform: uppercase;
            width: 50px;
            margin-left: 8px;
            justify-content: flex-end;
            margin-right: 12px;
          `}
        >
          <Icon
            name={'cog'}
            css={css`
              margin-left: 3px;
            `}
          />
        </Flex>
      )}
      <div
        className='right-buffer'
        css={css`
          position: absolute;
          width: 8px;
          top: 0px;
          bottom: 0px;
          right: -8px;
          ${shouldStickyHeader && `background-color: ${colors.HEADER_BG_2}`}
          ${headerBorder && 'border-bottom: 1px solid white;'}
        `}
      />
    </Flex>
  )
}

export default ListHeader
