/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { Input } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import headerImage from './download_app_header.png'
import androidQrCode from './google_play.png'
import iOSQrCode from './app_store.png'
import colors from '@sportninja/common/constants/appColors'
import req from '@sportninja/common/api/request'
import PhoneInputComponent from '../PhoneFormComponent'
import QRCode from 'react-qr-code'
import { isCanlan } from '@sportninja/common/utils/customer-name'

const DownloadAppModal = ({
  onPressClose = () => {},
  gameId = null,
  isTutorial = false,
}) => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isSendingSms, setIsSendingSms] = useState(false)
  const [sendSmsError, setSendSmsError] = useState(null)
  const [sendSmsSuccess, setSendSmsSuccess] = useState(false)

  useEffect(() => {
    if (sendSmsError) {
      setTimeout(() => {
        setSendSmsError(null)
      }, [5000])
    }
  }, [sendSmsError])

  useEffect(() => {
    if (sendSmsSuccess) {
      setTimeout(() => {
        setSendSmsSuccess(null)
      }, [5000])
    }
  }, [sendSmsSuccess])

  const onPressSendSms = async () => {
    if (!phoneNumber || phoneNumber?.length === 0) {
      setSendSmsError('Please enter a phone number')
      return
    }
    try {
      setIsSendingSms(true)
      const result = await req(`/games/${gameId}/sms`, {
        method: 'POST',
        body: JSON.stringify({
          phone_number: phoneNumber,
        }),
      })
      setSendSmsSuccess(true)
    } catch (e) {
      setSendSmsError(e?.message || 'Something went wrong')
    } finally {
      setIsSendingSms(false)
    }
  }

  return (
    <div
      css={css`
        background-color: #27303e;
        min-height: 40vh;
        min-width: 40vw;
        max-width: 700px;
        border-radius: 16px;
      `}
    >
      <style>
        {`
          .ant-input {
            background-color: ${colors.HEADER};
          }
        `}
      </style>
      <button onClick={onPressClose}>
        <CloseOutlined
          fontSize={20}
          style={{
            color: colors.WHITE,
            fontSize: 20,
            position: 'absolute',
            top: 35,
            right: 35,
            cursor: 'pointer',
            zIndex: 1,
            transition: 'all 0.2s ease-in-out',
            opacity: 0.8,
            ':hover': {
              opacity: 1,
            },
          }}
        />
      </button>
      <img
        src={headerImage}
        alt='score'
        css={css`
          width: 100%;
          height: 220px;
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
        `}
      />
      {isTutorial ? (
        <div
          css={{
            padding: '24px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '24px',
          }}
        >
          <span
            css={css`
              color: var(--caution-medium, #f2b400);
              text-align: center;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%; /* 28.8px */
            `}
          >
            Download the App to Start Scoring and much more!
          </span>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 100%;
              height: 100%;
              gap: 24px;
            `}
          >
            {/* QR Code and texts */}
            <div
              css={css`
                display: flex;
                flex-direction: row;
                gap: 32px;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <img
                  src={androidQrCode}
                  alt='google-play-qr-code'
                  css={css`
                    width: 104px;
                    height: 104px;
                    border-radius: 8px;
                  `}
                />
                <span
                  css={css`
                    color: var(--neutrals-white, #fff);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%; /* 21.6px */
                  `}
                >
                  Android
                </span>
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <img
                  src={iOSQrCode}
                  alt='app-store-qr-code'
                  css={css`
                    width: 104px;
                    height: 104px;
                    border-radius: 8px;
                  `}
                />
                <span
                  css={css`
                    color: var(--neutrals-white, #fff);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%; /* 21.6px */
                  `}
                >
                  iOS
                </span>
              </div>
            </div>
            {/* Text and Send SMS button */}
            <div
              css={css`
                display: flex;
                gap: 8px;
                flex-direction: column;
              `}
            >
              <span
                css={css`
                  color: var(--neutrals-white, #fff);
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 120%; /* 21.6px */
                `}
              >
                Scan the QR code with your phone&rsquo;s camera or send directly
                to your mobile number.
              </span>
              <div
                css={css`
                  display: flex;
                  gap: 8px;
                  flex-direction: row;
                `}
              >
                <PhoneInputComponent
                  namePhone={'phone'}
                  phoneValue={phoneNumber}
                  onChangePhone={(e) => setPhoneNumber(e.target.value)}
                  setPhoneValue={setPhoneNumber}
                  containerStyle={{ width: '50%', maxWidth: 220 }}
                  inputStyle={{
                    height: 46,
                    borderRadius: 2,
                    display: 'flex',
                    backgroundColor: 'transparent',
                    color: colors.WHITE,
                    fontSize: 16,
                    letterSpacing: 0.74,
                    maxWidth: 220,
                    width: '100%',
                  }}
                  disableDropdown={true}
                />
                <button
                  onClick={() => onPressSendSms()}
                  disabled={isSendingSms}
                  css={css`
                    width: 50%;
                    padding: 8 8 16 16;
                    border-radius: 4px;
                    background: var(--caution-medium, #f2b400);
                    color: var(--neutrals-white, #fff);
                    font-family: Rift;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: 0.9px;
                    text-transform: uppercase;
                  `}
                >
                  {isSendingSms ? 'Loading...' : 'Send'}
                </button>
              </div>
              {sendSmsError ? (
                <span
                  css={css`
                    color: ${colors.FIERY_RED};
                    text-align: right;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%; /* 14.4px */
                  `}
                >
                  {sendSmsError}
                </span>
              ) : null}
              {sendSmsSuccess ? (
                <span
                  css={css`
                    color: ${colors.GREEN_GOBLIN};
                    text-align: right;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%; /* 14.4px */
                  `}
                >
                  {'Success! Check your SMS.'}
                </span>
              ) : null}
              <span
                css={css`
                  color: var(--neutrals-gray-40, #9fa3a9);
                  text-align: right;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 120%; /* 14.4px */
                `}
              >
                *Charges may be applied
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div
          css={{
            padding: '24px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '24px',
          }}
        >
          <span
            css={css`
              color: var(--caution-medium, #f2b400);
              text-align: center;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%; /* 28.8px */
            `}
          >
            Check this game in the app to score and much more!
          </span>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
              gap: 24px;
            `}
          >
            {/* QR Code and texts */}
            <div
              css={css`
                display: flex;
                flex-direction: row;
                gap: 32px;
                align-self: center;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <div
                  css={css`
                    border: 2px solid ${colors.WHITE};
                  `}
                >
                  <QRCode
                    size={256}
                    css={css`
                      width: 104px;
                      height: 104px;
                    `}
                    value={
                      isCanlan
                        ? `https://canlanstats.sportninja.com/game/${gameId}`
                        : `https://app.sportninja.com/game/${gameId}`
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DownloadAppModal
