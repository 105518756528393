/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { getBirthDateString } from '@sportninja/common/reducers/helpers'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'

import { font } from '../../css'
import Icon from '../../Icon'
import { Flex } from '../../Layout'

const Choice = ({ children, name, value, rightContent, onClick }) => {
  return (
    <Flex
      alignItems='center'
      css={css`
        min-height: 26px;

        label {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          min-height: inherit;
          cursor: pointer;
          padding: 0 8px;
          margin-left: -8px;
          border-radius: 4px;

          transition: background-color 0.2s ease-in-out;

          &:hover {
            background-color: rgba(255, 255, 255, 0.05);
          }
        }

        input[type='radio'] {
          margin: 0 8px 0 0;
          font: inherit;
          color: white;
          width: 14px;
          height: 14px;
          border: 1.5px solid white;
          border-radius: 50%;
          display: grid;
          place-content: center;

          &::before {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em ${colors.DEFAULT_FLAIR};
          }

          &:checked {
            border-color: ${colors.DEFAULT_FLAIR};
            ::before {
              transform: scale(1);
            }
          }
        }

        .link {
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
        }

        &:hover .link {
          opacity: 1;
        }
      `}
    >
      <label onClick={onClick}>
        <input type='radio' name={name} value={value} />
        {children}
      </label>
      {rightContent}
    </Flex>
  )
}

const Pip = () => (
  <div
    css={css`
      height: 4px;
      width: 4px;
      background-color: ${colors.PALE_SKY};
      border-radius: 50%;
      margin-left: 8px;
    `}
  />
)

const SmallText = ({ children, isMismatched }) => {
  return (
    <>
      {children && <Pip />}

      <div
        css={css`
          margin-left: 8px;
          font-size: 14px;
          color: ${isMismatched ? colors.DEFAULT_FLAIR : '#9fa3a9'};
        `}
      >
        {children}
      </div>
    </>
  )
}

const ImportTeamRosterDuplicatePlayer = ({
  line,
  player,
  playerTypes,
  teamName,
  setCompleteSteps,
  shouldHighlightDiff,
  importPlayerId,
  importTeamName,
  importTeamRosterName,
}) => {
  const {
    sn_player_id,
    birth_date,
    first_name,
    last_name,
    player_conflicts,
    player_type_id,
  } = player

  const isUpdatingExistingPlayer =
    typeof sn_player_id === 'string' &&
    typeof importPlayerId === 'string' &&
    sn_player_id.length > 0 &&
    importPlayerId.length > 0 &&
    sn_player_id === importPlayerId

  const hasPlayerTypeMismatch =
    shouldHighlightDiff &&
    player_conflicts.reduce((collector, conflict) => {
      if (collector) return collector
      const value = conflict?.team_player_rosters?.[0]?.pivot?.player_type_id
      if (!value) return collector
      return player_type_id !== value
    }, false)

  const hasBirthDateMismatch =
    shouldHighlightDiff &&
    player_conflicts.reduce((collector, conflict) => {
      if (collector) return collector
      const value = conflict.birth_date
      if (!value) return collector
      return dayjs(birth_date).isSame(dayjs(value))
    }, false)

  const hasPlayerIdMismatch =
    shouldHighlightDiff &&
    player_conflicts.reduce((collector, conflict) => {
      if (collector) return collector
      const value = conflict.uid
      if (!value) return collector
      return sn_player_id !== value
    }, false)

  const birthDateAsString = getBirthDateString(birth_date)

  const handleClick = () => {
    setCompleteSteps(line)
  }

  return (
    <div
      css={css`
        margin-left: 8px;
      `}
    >
      <Flex alignItems='center'>
        <div
          css={css`
            ${font.title}
            font-weight: 500;
            font-size: 20px;
            margin-bottom: 4px;
          `}
        >
          {first_name} {last_name}
        </div>
        {teamName ? <SmallText>{teamName}</SmallText> : null}
        {importTeamName ? <SmallText>{importTeamName}</SmallText> : null}
        {importTeamRosterName ? (
          <SmallText>{importTeamRosterName}</SmallText>
        ) : null}
        {birth_date && (
          <SmallText isMismatched={hasBirthDateMismatch}>
            {birthDateAsString?.toUpperCase()?.includes('INVALID DATE')
              ? `Invalid Date | ${birth_date}`
              : birthDateAsString}
          </SmallText>
        )}
        <SmallText isMismatched={hasPlayerTypeMismatch}>
          {playerTypes?.find((t) => t?.id === player_type_id)?.name_full}
        </SmallText>

        <SmallText isMismatched={hasPlayerIdMismatch}>{sn_player_id}</SmallText>
      </Flex>
      <div>
        {player_conflicts.map((conflict) => {
          const {
            birth_date,
            name_first,
            name_last,
            team_player_rosters,
            uid,
            import_2_id = '',
          } = conflict

          const teamRosterStrings = team_player_rosters
            .map((roster) => {
              return roster?.name
            })
            .join(', ')

          const teamStrings = team_player_rosters
            .map((roster) => {
              return roster?.team?.name
            })
            .join(', ')

          const existingPlayerTypeId =
            team_player_rosters?.[0]?.pivot?.player_type_id

          const birthDateAsString = getBirthDateString(birth_date)

          return (
            <Choice
              onClick={handleClick}
              key={conflict?.uid}
              name={line}
              value={JSON.stringify(conflict)}
              rightContent={
                <Link
                  rel='noreferrer'
                  target='_blank'
                  to={`${ROUTES.PLAYER_ROOT}/${uid}`}
                  className='link'
                  css={css`
                    display: flex;
                    align-items: center;
                    height: 100%;
                    padding: 0 8px;
                    transition: background-color 0.2s ease-in-out;
                    border-radius: 4px;

                    margin-left: auto;
                    font-size: 14px;
                    color: ${colors.DEFAULT_FLAIR};

                    &:hover {
                      background-color: rgba(255, 255, 255, 0.05);
                      color: white;
                      i {
                        color: white;
                      }
                    }
                  `}
                >
                  <Icon
                    name='external-link'
                    fontSize={12}
                    color={colors.DEFAULT_FLAIR}
                    css={css`
                      margin-right: 8px;
                    `}
                  />
                  Open
                </Link>
              }
            >
              <div
                css={css`
                  ${first_name !== name_first ||
                  (last_name !== name_last &&
                    css`
                      color: ${colors.DEFAULT_FLAIR};
                    `)}
                `}
              >
                {name_first} {name_last}
              </div>
              {birth_date ? (
                <SmallText
                  isMismatched={
                    shouldHighlightDiff &&
                    !dayjs(birth_date).isSame(dayjs(conflict.birth_date))
                  }
                >
                  {birthDateAsString?.toUpperCase()?.includes('INVALID DATE')
                    ? `Invalid Date | ${birth_date}`
                    : birthDateAsString}
                </SmallText>
              ) : null}
              <SmallText>{teamStrings ? teamStrings : 'No Team'}</SmallText>
              {teamRosterStrings ? (
                <SmallText>{teamRosterStrings}</SmallText>
              ) : null}
              {existingPlayerTypeId && (
                <SmallText
                  isMismatched={
                    shouldHighlightDiff &&
                    existingPlayerTypeId !== player_type_id
                  }
                >
                  {
                    playerTypes.find((t) => t?.id === existingPlayerTypeId)
                      ?.name_full
                  }
                </SmallText>
              )}

              <SmallText
                isMismatched={shouldHighlightDiff && uid !== sn_player_id}
              >
                {uid}
              </SmallText>
              {import_2_id && import_2_id !== '' ? (
                <SmallText
                  isMismatched={shouldHighlightDiff && uid !== sn_player_id}
                >
                  {`Salesforce-id: ${import_2_id}`}
                </SmallText>
              ) : null}
            </Choice>
          )
        })}
        <Choice onClick={handleClick} name={line} value='create'>
          {isUpdatingExistingPlayer ? (
            <>
              Update existing player
              <SmallText>{importPlayerId}</SmallText>
            </>
          ) : (
            'Create a new player'
          )}
        </Choice>
        <Choice onClick={handleClick} name={line} value='ignore'>
          Ignore (do not import)
        </Choice>
      </div>
    </div>
  )
}

export default ImportTeamRosterDuplicatePlayer
