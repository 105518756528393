import { str } from './utils'
import { produce } from 'immer'
import actions from '../actions/notifications'

const initialState = {
  notifications: [],
  listAllLoading: false,
  listAllError: null,
  readNotificationLoading: false,
  readNotificationError: null,
}

export default (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case str(actions.listAll.request):
        draft.listAllLoading = true
        draft.listAllError = null
        break
      case str(actions.listAll.success):
        draft.notifications = payload.notifications.data
        draft.listAllLoading = false
        draft.listAllError = null
        break
      case str(actions.listAll.failure):
      case str(actions.readAll.failure):
        draft.notifications = []
        draft.listAllLoading = false
        draft.listAllError = payload.error
        break
      case str(actions.readNotification.request):
        draft.readNotificationLoading = true
        draft.readNotificationError = null
        break
      case str(actions.readNotification.success): {
        const notificationIdx = draft.notifications.findIndex(
          (notification) => notification.id === payload.notification.id
        )
        draft.notifications[notificationIdx] = payload.notification
        draft.readNotificationLoading = false
        draft.readNotificationError = null
        break
      }
      case str(actions.readNotification.failure):
        draft.readNotificationLoading = false
        draft.readNotificationError = payload.error
        break
      case str(actions.readAll.request):
        draft.listAllLoading = true
        draft.listAllError = null
        break
      case str(actions.readAll.success):
        draft.notifications = payload.notifications.data
        draft.listAllLoading = false
        draft.listAllError = null
        break
      default:
        break
    }
  })
