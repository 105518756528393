/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import React from 'react'
import PropTypes from 'prop-types'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { t } from '@sportninja/common/i18n'
import {
  ROLE_TYPE_ID_TO_STRING,
  ROLE_TYPE_LIST,
  ROLE_TYPE_STRING_TO_ID,
} from '@sportninja/common/constants/app'

import GameTeamPicker from '../../pages/Game/GameTeamPicker'
import PicturePicker from '../PicturePicker'
import DatePicker, { datePickerRequired } from '../DatePicker'
import DateAndDurationPicker from '../DateAndDurationPicker'
import SearchBox from '../SearchBox'
import DOB from '../DOB'
import PositionSelect from '../Select/PositionSelect'
import CountryProvinceSelects from '../Select/CountryProvinceSelects'
import HeightPicker from '../HeightPicker'
import PrivacySettings from '../PrivacySettings'
import PeriodClockTime, { periodClockValidator } from '../PeriodClockTime'
import GoalieChangePicker from '../GoalieChangePicker'
import GoalPicker from '../GoalPicker'
import PenaltyPicker from '../PenaltyPicker'
import InviteFormField from '../InviteFormField'
import PeriodPicker from '../PeriodPicker'
import YesNoToggler from '../YesNoToggler'
import GenericSelect from '../Select/GenericSelect'
import VenueFacilityPicker from '../VenueFacilityPicker'
import FacilityList from '../VenueFacilityPicker/FacilityList'
import ConfirmPassword, {
  PasswordOnly,
  passwordsMatch,
} from './ConfirmPassword'
import banners from './banner-helpers'
import GameTypeSelect from './GameTypeSelect'
import FormInput from './FormInput'
import OfficialSelect from './OfficialSelect'
import TimezoneSelect from './TimezoneSelect'
import FeedItemParentPicker from './FeedItemParentPicker'
import TimePicker from './TimePicker'
import GameScheduleSelect from './GameScheduleSelect'
import { playoffsStrings } from '@sportninja/common/constants/playoffTypes'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { Flex } from '../Layout'
import colors from '@sportninja/common/constants/appColors'
import InvitationFormField from '../InvitationFormField'
import dayjs from 'dayjs'
import LinkPlayerToParent from '../LinkPlayerToParent'
import ScheduleTypeFormField from '../ScheduleTypeFormField'
import { Checkbox } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

const _entityDetails = (values) => [
  {
    name: 'phone',
    label: t('common:phone'),
    placeholder: '236 555 5555',
    defaultValue: values.phone,
    autoComplete: 'off',
  },
  {
    name: 'website',
    label: t('common:website'),
    placeholder: 'www.sport.com',
    defaultValue: values.website,
    autoComplete: 'off',
  },
]

const _userNameFields = (values, isReadOnly) => {
  const isNew = Object.keys(values).length === 0

  // Read only only if this is an existing user, and they have accepted their
  // invitation to SportNinja (and thus control their own name)
  const readOnly =
    isReadOnly ||
    (!isNew &&
      values.invite &&
      (values.isValidated || values.invite.accepted === true) &&
      values.invite.invited === true)

  return [
    {
      name: 'name_first',
      label: t('AccountScreen:firstName'),
      defaultValue: values.nameFirst,
      placeholder: 'Pat',
      required: !readOnly || isNew,
      readOnly,
    },
    {
      name: 'name_last',
      label: t('AccountScreen:lastName'),
      defaultValue: values.nameLast,
      placeholder: 'Quinn',
      required: !readOnly || isNew,
      readOnly,
    },
  ]
}

const _parentOrgFields = (values) => ({
  name: 'parent_id',
  component: SearchBox,
  label: t('common:parentOrganization'),
  placeholder: `${t('Web:selectAParentOrg')}...`,
  data: values.orgs,
  defaultValue: values.parentName,
  displayString: 'fullName',
  readOnly: true,
  required: false,
})

export const gameForm = (values = {}, isNew, sportId = null) => {
  const fields = [
    [
      {
        component: DateAndDurationPicker,
        label: t('Web:startDateAndTime'),
        datePlaceholder: `${t('Web:selectADateAndTime')}...`,
        defaultValues: {
          starts_at: {
            value: dayjs(values.startsAt),
            label: t('Web:startDateAndTime'),
            timezone: values?.location?.timezone,
            required: true,
          },
          ends_at: {
            value: values.endsAt,
            label: t('common:duration'),
            required: true,
          },
        },
      },
    ],
    [
      {
        component: GameTeamPicker,
        disabled: values.started || values.completed,
        defaultValues: {
          home_team_id: {
            value: values.homeTeamId,
            label: t('Web:homeTeam'),
            required: true,
          },
          visiting_team_id: {
            value: values.visitingTeamId,
            label: t('Web:visitingTeam'),
            required: true,
          },
          parentId: values.rootSchedule?.id,
        },
      },
    ],
    [
      {
        name: 'venue_id',
        label: t('util:capitalize', { text: t('common:location') }),
        component: VenueFacilityPicker,
        required: true,
        defaultValues: {
          venue_id: {
            value: values.location?.id,
          },
          location: values.location,
          facilityId: values.facilityId,
          parentId: isNew ? values.parentId : values.rootSchedule?.id,
        },
        organization_id: values.organization_id,
      },
    ],
    [
      {
        name: 'league_game_number',
        label: t('common:gameNumber'),
        defaultValue: values.gameNumber,
        placeholder: '#####',
        required: false,
      },
      {
        component: GameTypeSelect,
        defaultValue: values.gameType,
        sportId: sportId,
      },
    ],
    [
      {
        name: 'if_necessary_status',
        defaultValue: isNew ? false : values.ifNecessaryStatus,
        component: () => (
          <div>
            <span style={{ fontSize: 16, color: colors.WHITE }}>
              {t('Web:ifNecessary')}
            </span>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 10,
              }}
            >
              <div style={{ maxWidth: '90%' }}>
                <span style={{ color: colors.ATTENDANCE_GRAY, fontSize: 14 }}>
                  When turned ON, indicate game may not be required based on
                  prior results.
                </span>
              </div>
              <Flex
                as='button'
                type='button'
                onClick={values.onSelectIfNecessary}
                id='game-list-todays-game-toggle'
                className={values.ifNecessaryStatus ? 'active' : ''}
                alignItems='center'
                style={{
                  position: 'relative',
                  height: 21,
                  width: 33,
                  transition: 'background-color 0.1s, border 0.1s ease-in-out',
                  backgroundColor:
                    values.ifNecessaryStatus && colors.DEFAULT_FLAIR,
                  border: `2px solid ${
                    values.ifNecessaryStatus ? colors.DEFAULT_FLAIR : 'white'
                  }`,
                  borderRadius: 25,
                  marginLeft: 16,
                }}
              >
                <div
                  id='game-list-todays-game-toggle-pip'
                  className={values.ifNecessaryStatus ? 'active' : ''}
                  style={{
                    backgroundColor: colors.WHITE,
                    height: 11,
                    width: 11,
                    borderRadius: '50%',
                    transition:
                      'transform 0.1s, background-color 0.1s ease-in-out',
                    transform: `translateX(${
                      values.ifNecessaryStatus ? 22 : 2
                    }px)`,
                  }}
                />
              </Flex>
            </div>
          </div>
        ),
      },
    ],
    [
      {
        name: 'schedule_id',
        defaultValue: isNew ? undefined : values.parentId,
        component: GameScheduleSelect,
        gameInfo: values,
        noFlex: true,
      },
    ],
    // [ { type: 'break' } ],
    // [ { component: PrivacySettings, defaultValues: values, entityType: ENTITY_TYPES.game }]
  ]

  return { fields, banner: isNew ? banners.gameCreate() : banners.gameUpdate() }
}

export const orgForm = (
  values = {},
  isNew,
  includeParentField = true,
  enablePrivateLeagues = true
) => {
  const picturePicker = {
    component: PicturePicker,
    noFlex: !isNew,
    imageId: values.imageId,
  }

  let fields = [
    [
      {
        name: 'name_full',
        label: t(
          // SN-501: The assumption is that if we are displaying the parent org
          // field, then this is a sub-org and so we can change the label appropriately
          includeParentField ? 'Web:subOrgName' : 'Web:organizationName'
        ),
        placeholder: 'e.g. North Shore Winter Club',
        required: true,
        defaultValue: values.fullName,
        autoComplete: 'off',
      },
      {
        name: 'abbreviation',
        label: t('Web:abbreviationXOrLess', { x: 8 }),
        placeholder: 'e.g. NSWC',
        required: true,
        defaultValue: values.abbrev,
        maxLength: 8,
        autoComplete: 'off',
      },
    ],
    _entityDetails(values),
    isNew && includeParentField
      ? [_parentOrgFields(values), picturePicker]
      : [picturePicker],
    [{ type: 'break' }],
    [
      {
        component: PrivacySettings,
        defaultValues: values,
        disabled: !enablePrivateLeagues,
        entityType: ENTITY_TYPES.org,
        privacySettingOverride: isNew ? values.isParentPublic : undefined,
      },
    ],
  ]

  return {
    fields,
    banner: isNew
      ? banners.entityCreate(ENTITY_TYPES.org)
      : banners.entityUpdate(),
  }
}

const SelfDOB = ({ onChange, ...props }) => (
  <DOB
    onValidDOB={(value) => onChange({ target: { name: 'birth_date', value } })}
    {...props}
  />
)

const PlayerDOB = ({ onChange, ...props }) => (
  <DOB
    checkAge={false}
    required={false}
    onValidDOB={(value) => onChange({ target: { name: 'birth_date', value } })}
    {...props}
  />
)

SelfDOB.propTypes = {
  onChange: PropTypes.func.isRequired,
}

PlayerDOB.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export const passwordForm = () => {
  return {
    fields: [
      [
        {
          name: 'password_old',
          label: t('AccountScreen:currentPassword'),
          autoComplete: 'old-password',
          required: true,
          component: PasswordOnly,
        },
      ],
      [
        {
          component: ConfirmPassword,
          passwordLabel: t('AccountScreen:newPassword'),
          validators: [passwordsMatch],
        },
      ],
    ],
    banner: banners.passwordUpdate(),
  }
}

export const selfForm = (values = {} /*showPrivacySettings*/) => {
  let baseForm = [
    [
      {
        name: 'name_first',
        label: t('AccountScreen:firstName'),
        placeholder: 'Pat',
        required: true,
        defaultValue: values.nameFirst,
        autoComplete: 'given_name',
      },
      {
        name: 'name_last',
        label: t('AccountScreen:lastName'),
        placeholder: 'Quinn',
        required: true,
        defaultValue: values.nameLast,
        autoComplete: 'family_name',
      },
    ],
    [
      { component: HeightPicker, defaultValue: values.height },
      {
        type: 'number',
        min: 0,
        step: 1,
        name: 'weight',
        label: t('common:weight'),
        defaultValue: values.weight,
        right: 'lbs',
      },
    ],
    [
      {
        name: 'birth_date',
        component: SelfDOB,
        required: true,
        defaultValue: values.birthDate,
      },
      {
        component: PicturePicker,
        imageId: values.imageId,
        placeholder: t('Web:profileImage'),
      },
    ],
  ]

  // if (showPrivacySettings) {
  // baseForm = [
  //   ...baseForm,
  //   [ { type: 'break' } ],
  //   [ { component: PrivacySettings, defaultValues: values, readOnlyPrivacyIfHidden: true, showVisibility: true }]
  // ]
  // }

  return { fields: baseForm, banner: banners.profile() }
}

const addInviteFormField = (
  values,
  form,
  required = false,
  hideHelp = false
) => {
  // If the user has already been invited, then display their email
  // and phone number
  if (values.invite && values.invite.invited) {
    form.push([
      {
        namePhone: 'phone_number',
        nameEmail: 'email',
        component: InvitationFormField,
        defaultValuePhone: values.phone_number,
        defaultValueEmail: values.email,
        disabledEmail: true,
        hideHelpPhone: true,
        hideHelpEmail: true,
      },
    ])
    return
  }
  form.push([
    {
      namePhone: 'phone_number',
      nameEmail: 'email',
      component: InvitationFormField,
      defaultValuePhone: values.phone_number,
      defaultValueEmail: values.email,
      hideHelpPhone: true,
      requiredEmail: required,
      hideHelpEmail: hideHelp,
    },
  ])
}

export const userForm = (values = {}) => {
  const isNew = Object.keys(values).length === 0
  const isUserOwner = values.roleType === ROLE_TYPE_ID_TO_STRING[0]
  const types = isUserOwner
    ? ROLE_TYPE_LIST.filter((type) => type.id === 0)
    : ROLE_TYPE_LIST.filter((type) => type.id !== 0)

  const fields = [
    _userNameFields(values),
    [
      {
        component: OfficialSelect,
        name: 'role_type_id',
        label: 'Role',
        defaultValue: ROLE_TYPE_STRING_TO_ID[values?.roleType],
        types: types.filter((type) => type.id !== 7),
        noFlex: true,
        required: !isUserOwner,
        disabledByDefault: isUserOwner,
      },
    ],
  ]

  // If the user doesn't already have an email associated, then add the invite form
  if (typeof values.email !== 'string' || values.email.length === 0) {
    addInviteFormField(values, fields, true, true)
  } else {
    fields.push([
      {
        namePhone: 'phone_number',
        nameEmail: 'email',
        component: InvitationFormField,
        defaultValuePhone: values.phone_number,
        defaultValueEmail: values.email,
        disabledEmail: true,
        hideHelpPhone: true,
        hideHelpEmail: true,
      },
    ])
  }

  fields.push([
    {
      name: 'warning_message',
      component: () => {
        return (
          <div style={{ marginTop: 16, fontSize: 13 }}>
            Including an email address will send a new invitation to this user.{' '}
            {t('Web:invitedToRegister')}
          </div>
        )
      },
    },
  ])

  return { fields, banner: isNew ? banners.userCreate() : banners.userUpdate() }
}

export const playerForm = (
  values = {},
  existingPlayerAdd = false,
  requireEmail = false,
  hasAdminPermission = false,
  sportName = 'hockey',
  triedToCreateExistingPlayer = false,
  numberOfSubmissions = 0,
  sportId = null
) => {
  const isNew = Object.keys(values).length === 0

  const fields = [
    // triedToCreateExistingPlayer && isNew
    //   ? [
    //       {
    //         name: 'link_to_existing_player',
    //         component: LinkPlayerToParent,
    //       },
    //     ]
    //   : [],
    _userNameFields(values, existingPlayerAdd),
    [
      {
        name: 'player_number',
        label: t('common:playerNumber'),
        placeholder: '99',
        required: true,
        type: 'number',
        defaultValue: values.number,
        max: 999,
        min: 0,
      },
      {
        name: 'player_type_id',
        label: t('common:position'),
        component: PositionSelect,
        defaultValue: values?.playerTypeId
          ? values?.playerTypeId
          : sportName === 'hockey'
          ? 4
          : sportName === 'soccer'
          ? 9
          : null,
        required: true,
        sportName,
        sportId,
      },
    ],
  ]

  if (!existingPlayerAdd) {
    fields.push([
      { component: HeightPicker, defaultValue: values.height },
      {
        type: 'number',
        min: 0,
        step: 1,
        name: 'weight',
        label: t('common:weight'),
        defaultValue: values.weight,
        right: 'lbs',
      },
    ])
    fields.push([
      {
        name: 'birth_date',
        component: PlayerDOB,
        defaultValue: values.birthDate,
      },
      {
        component: PicturePicker,
        imageId: values.imageId,
        placeholder: t('common:image'),
      },
    ])
  }

  if (hasAdminPermission) {
    const customerIdentifier = {
      name: 'customer_identifier',
      label: 'Customer Identifier',
      defaultValue: values.customer_identifier,
      placeholder: 'e.g. 38183811',
      style: {
        alignItems: 'flex-start',
      },
    }
    const injured = {
      name: 'is_injured',
      component: YesNoToggler,
      defaultValue: values.is_injured,
      label: t('common:injured'),
    }

    fields.push([customerIdentifier, injured])
  }

  fields.push([
    {
      name: 'is_affiliate',
      component: YesNoToggler,
      defaultValue: values.isAffiliate,
      label: t('common:affiliate'),
      bodyText: t('Web:affiliateDescription'),
      noFlex: true, //!hasAdminPermission,
    },
  ])

  if (isCanlan) {
    fields.push([
      {
        name: 'sf_id',
        label: 'Salesforce ID',
        defaultValue: values?.sf_id || '',
        placeholder: 'e.g. 0010Z00002agcJEFAY',
        required: false,
      },
    ])
  }

  if (!values.a) {
    fields.push([
      {
        namePhone: 'phone_number',
        nameEmail: 'email',
        component: InvitationFormField,
        defaultValuePhone: values.phone_number,
        defaultValueEmail: values.email,
        disabledEmail: values?.invite?.accepted || triedToCreateExistingPlayer,
        hideHelpPhone: true,
        hideHelpEmail: true,
      },
    ])
  }

  fields.push([
    {
      name: 'warning_message',
      component: () => {
        return (
          <div style={{ marginTop: 16, fontSize: 13 }}>
            Including an email address will send a new invitation to this user.{' '}
            {t('Web:invitedToRegister')}
          </div>
        )
      },
    },
  ])

  if (triedToCreateExistingPlayer && isNew && !isCanlan) {
    fields.push([
      {
        name: 'link_to_existing_player',
        component: (props) => (
          <LinkPlayerToParent
            {...props}
            numberOfSubmissions={numberOfSubmissions}
          />
        ),
      },
    ])
  }

  return {
    fields,
    banner: isNew ? banners.playerCreate() : banners.playerUpdate(),
  }
}

const DAY_OF_WEEK_OPTIONS = [
  'N/A',
  'Friday',
  'Friday - AM',
  'Friday - PM',
  'Monday',
  'Monday - AM',
  'Monday - PM',
  'Monday - Saturday',
  'Saturday',
  'Saturday - AM',
  'Saturday - PM',
  'Sunday',
  'Sunday - AM',
  'Sunday - PM',
  'Sunday - Thursday',
  'Thursday',
  'Thursday - AM',
  'Thursday - PM',
  'Tuesday',
  'Tuesday - AM',
  'Tuesday - PM',
  'Wednesday',
  'Wednesday - AM',
  'Wednesday - PM',
  'Weekdays',
  'Weekends',
  'Monday/Lundi',
  'Tuesday /Mardi',
  'Wednesday/Mercredi',
  'Friday/Vendredi',
  'Saturday Afternoon/Samedi Jour',
  'Saturday Night/Samedi Soir',
  'Sunday Afternoon/Dimanche Jour',
  'Sunday Night/Dimanche Soir',
  'Women Sunday Morning/Dimanche Matin Femme',
  'Individual Sat Night/Individuelle Samedi Soir',
  'Individual Sun Night/Individuelle Dimanche Soir',
  'Thursday/Jeudi',
]

const COMP_TYPE_OPTIONS = ['Season', 'Conference', 'Division']

export const canlanDivisionForm = (
  values = {},
  disabledFields = {},
  showDivisionFields = false,
  parentNodeType = null
) => {
  const settings = values.settings || {}
  const divisionFields = showDivisionFields
    ? [
        [
          {
            component: GenericSelect,
            name: 'division_group',
            label: 'Division Group',
            defaultValue: values.division_group,
            options: ['A', 'B', 'C', 'D', 'E', 'ASHL Hockey 101'],
            required: false,
          },
          {
            component: GenericSelect,
            name: 'division_age',
            label: 'Division Age',
            defaultValue: values.division_age,
            options: ['Open', '30+', '40+', '50+', '60+'],
            required: true,
          },
        ],
        [
          {
            component: GenericSelect,
            name: 'division_gender',
            label: 'Division Gender',
            defaultValue: values.division_gender,
            options: ['Men', 'Women', 'Coed'],
            required: true,
          },
          {
            name: 'deposit',
            type: 'float',
            label: 'Deposit ($)',
            placeholder: '0.00',
            defaultValue: values.deposit || 0,
            component: (props) => {
              const {
                component,
                values: _values,
                disabled,
                hasError,
                errors,
                onChange,
                ...input
              } = props
              return (
                <FormInput
                  input={input}
                  values={values}
                  hasError={hasError}
                  errors={errors}
                  onChange={onChange}
                  onBlur={(e) => {
                    const newValue = e.target.value
                    const formattedValue =
                      newValue && newValue !== ''
                        ? parseFloat(newValue).toFixed(2)
                        : 0
                    e.target.value = formattedValue
                    onChange(e)
                  }}
                />
              )
            },
          },
        ],
        [
          {
            name: 'team_cost',
            type: 'float',
            label: 'Team Cost ($)',
            placeholder: '0.00',
            defaultValue: values.team_cost || 0,
            component: (props) => {
              const {
                component,
                values: _values,
                disabled,
                hasError,
                errors,
                onChange,
                ...input
              } = props
              return (
                <FormInput
                  input={input}
                  values={values}
                  hasError={hasError}
                  errors={errors}
                  onChange={onChange}
                  onBlur={(e) => {
                    const newValue = e.target.value
                    const formattedValue =
                      newValue && newValue !== ''
                        ? parseFloat(newValue).toFixed(2)
                        : 0
                    e.target.value = formattedValue
                    onChange(e)
                  }}
                />
              )
            },
          },
          {
            name: 'is_active',
            component: YesNoToggler,
            defaultValue:
              typeof values.is_active !== 'undefined' ? values.is_active : true,
            label: 'Is active?',
          },
        ],
      ]
    : []

  let competitionTypes = disabledFields.subtitle
    ? COMP_TYPE_OPTIONS
    : COMP_TYPE_OPTIONS.slice(1)

  if (parentNodeType) {
    competitionTypes = competitionTypes.filter(
      (type) => type.toLowerCase() !== parentNodeType.toLowerCase()
    )
  }

  const lineTwo = [
    {
      component: GenericSelect,
      name: 'subtitle',
      label: 'Competition Type',
      defaultValue: values.subtitle,
      options: competitionTypes,
      readOnly: disabledFields.subtitle,
      disabled: disabledFields.subtitle,
      required: true,
    },
  ]

  if (showDivisionFields) {
    lineTwo.push({
      component: GenericSelect,
      name: 'day_of_week',
      label: 'Day of Week',
      defaultValue: values.day_of_week,
      options: DAY_OF_WEEK_OPTIONS,
      required: true,
    })
  }

  const fields = [
    [
      {
        required: true,
        name: 'title',
        label: 'Competition Name',
        placeholder: 'e.g. Western Conference, Monday C1, etc.',
        defaultValue: values.title,
        readOnly: disabledFields.title,
        disabled: disabledFields.title,
      },
    ],
    lineTwo,
    ...divisionFields,
    [
      {
        name: 'lock_roster_date',
        component: DatePicker,
        datePlaceholder: '',
        label: 'Roster Lock Date',
        useRange: false,
        hideTime: true,
        defaultValues: {
          starts_at: { label: 'Select a Date', value: values.lock_roster_date },
        },
        validators: [],
        changeOnReset: true,
      },
    ],
  ]

  if (!disabledFields.game_cutoff) {
    fields.push([
      {
        name: 'game_cutoff',
        defaultValue: settings.game_cutoff,
        label: 'Minimum Game Cutoff',
        placeholder: 'Min. # of games for team playoff eligibility',
      },
    ])
  }
  if (isCanlan && showDivisionFields) {
    fields.push(
      [
        // Removing as requested on: https://sportninja.atlassian.net/browse/SN-3238
        // {
        //   component: GenericSelect,
        //   name: 'season_type',
        //   label: 'Season Type',
        //   defaultValue: values.season_type,
        //   options: ['Spring / Summer', 'Fall / Winter', 'Tournament'],
        //   required: true,
        // },
        {
          name: 'online_registration_open',
          label: 'Online Registration Open?',
          component: YesNoToggler,
          defaultValue: values.online_registration_open,
        },
      ]
      // Removing this for now, as requested on https://sportninja.atlassian.net/browse/SN-2874
      // [
      //   {
      //     component: GenericSelect,
      //     name: 'season_currency',
      //     label: 'Season Currency',
      //     defaultValue: values.season_currency,
      //     options: ['CAD', 'USD'],
      //   },
      //   {
      //     name: 'tax_rate',
      //     label: 'Tax Rate (%)',
      //     defaultValue: values.tax_rate,
      //     placeholder: '13.0',
      //   },
      // ]
    )
  }
  fields.push([
    {
      name: 'sort_order',
      defaultValue: values?.sort_order,
      label: 'Sort Order',
      placeholder: 'Sort Order',
      type: 'number',
    },
  ])

  return fields
}

export const scheduleForm = (
  values = {},
  isNew = false,
  displayCanlanForm = false,
  defaultSportType = 'Ice Hockey',
  enablePlayoffs = false,
  disablePlayoffCheckbox = false,
  sportOptions = ['Ice Hockey', 'Soccer', 'Generic'],
  sportId = null,
  fullSportOptions = []
) => {
  const lastLine = [{ component: PicturePicker, imageId: values.imageId }]

  if (!isNew && enablePlayoffs) {
    lastLine.push({
      name: 'is_tournament',
      defaultValue: values.isTournament,
      type: 'checkbox',
      label: 'Playoff Competition?',
      disabled: disablePlayoffCheckbox,
    })
  }

  let fields = [
    [
      {
        name: 'name_full',
        label: t('Web:competitionName'),
        placeholder: '2019 Pre-Season',
        required: true,
        defaultValue: values.fullName,
        autoComplete: 'off',
        id: 'tutorial-schedule-name',
      },
    ],
    [
      {
        component: ScheduleTypeFormField,
        default_sport_type: sportId,
        default_generic_sport_type: values?.generic_sport_id,
        sportTypeOptions: fullSportOptions,
        disabled: !isNew,
      },
    ],
    // [
    //   {
    //     component: GenericSelect,
    //     name: 'sport_type',
    //     label: 'Sport Type',
    //     options: sportOptions,
    //     required: true,
    //     disabled: !isNew,
    //     defaultValue: defaultSportType,
    //   },
    // ],
    // [
    //   {
    //     component: GenericSelect,
    //     name: 'generic_sport_type',
    //     label: 'Generic Type',
    //     options: ['Basketball', 'Pickleball', 'Volleyball', 'Other'],
    //     required: true,
    //     disabled: !isNew,
    //     defaultValue: defaultSportType,
    //   },
    // ],
    [
      {
        component: DatePicker,
        datePlaceholder: `${t('Web:selectAStartAndEndDate')}...`,
        label: `${t('Web:startAndEndDate')} *`,
        useRange: true,
        hideTime: true,
        defaultValues: {
          starts_at: { label: t('Web:startDate'), value: values.start },
          ends_at: { label: t('Web:endDate'), value: values.end },
        },
        validators: [datePickerRequired],
        id: 'tutorial-schedule-dates',
      },
      {
        name: 'abbreviation',
        label: t('Web:abbreviationXOrLess', { x: 8 }),
        placeholder: 'e.g. NSWC',
        defaultValue: values.abbrev,
        maxLength: 8,
        autoComplete: 'off',
        required: true,
        id: 'tutorial-schedule-abbrev',
      },
    ],
    _entityDetails(values),
    [{ type: 'break' }],
    lastLine,
    [{ type: 'break' }],
    [
      {
        component: PrivacySettings,
        defaultValues: values,
        entityType: ENTITY_TYPES.schedule,
        privacySettingOverride: isNew ? values.isParentPublic : undefined,
      },
    ],
    [{ type: 'break' }],
    [
      !isCanlan && {
        name: 'is_registration_on',
        defaultValue: values?.is_registration_on,
        component: ({ onChange, ...props }) => {
          return (
            <div>
              <FormInput
                onChange={(value) => {
                  onChange({
                    target: {
                      name: 'is_registration_on',
                      value: value?.target?.checked,
                    },
                  })
                }}
                input={{
                  name: 'is_registration_on',
                  label: 'Turn on Registration?',
                  type: 'checkbox',
                  defaultValue: values?.is_registration_on,
                }}
                {...props}
              />
              <div style={{ height: 8 }} />
              <label
                style={{
                  fontSize: 12,
                  color: colors.LIGHTER_GRAY,
                }}
              >
                When turned ON, users can use the registration flow to create
                their teams for this competition.
              </label>
            </div>
          )
        },
      },
    ],
  ]

  if (displayCanlanForm) {
    fields = fields.concat([
      [{ type: 'break' }],
      [
        {
          component: GenericSelect,
          name: 'season_type',
          label: 'Season Type',
          defaultValue: values.season_type,
          options: ['Spring / Summer', 'Fall / Winter', 'Tournament'],
          required: true,
        },
        {
          name: 'online_registration_open',
          label: 'Online Registration Open?',
          component: YesNoToggler,
          defaultValue: values.online_registration_open,
        },
      ],
      [
        {
          component: GenericSelect,
          name: 'season_currency',
          label: 'Season Currency',
          defaultValue: values.season_currency,
          options: ['CAD', 'USD'],
        },
        {
          name: 'tax_rate',
          label: 'Tax Rate (%)',
          defaultValue: values.tax_rate,
          placeholder: '13.0',
        },
      ],
    ])
  }

  return {
    fields,
    banner: isNew
      ? banners.entityCreate(ENTITY_TYPES.schedule)
      : banners.entityUpdate(),
  }
}

export const scheduleCloneForm = (values = {}, enablePlayoffs = true) => {
  const settings = values.settings || {}

  const playoffTypesOptions = isCanlan
    ? [
        playoffsStrings.SINGLE_ELIMINATION,
        playoffsStrings.DOUBLE_ELIMINATION,
        playoffsStrings.CANLAN,
      ]
    : [playoffsStrings.SINGLE_ELIMINATION, playoffsStrings.DOUBLE_ELIMINATION]

  let fields = [
    [
      {
        name: 'name_full',
        label: t('Web:competitionName'),
        placeholder: '2019 Pre-Season',
        required: true,
        defaultValue: `Copy of ${values.fullName}`,
        autoComplete: 'off',
      },
      {
        name: 'abbreviation',
        label: t('Web:abbreviationXOrLess', { x: 8 }),
        placeholder: 'e.g. NSWC',
        defaultValue: values.abbrev,
        maxLength: 8,
        autoComplete: 'off',
      },
    ],
    [
      {
        component: DatePicker,
        datePlaceholder: `${t('Web:selectAStartAndEndDate')}...`,
        label: `${t('Web:startAndEndDate')} *`,
        useRange: true,
        hideTime: true,
        defaultValues: {
          starts_at: { label: t('Web:startDate'), value: values.start },
          ends_at: { label: t('Web:endDate'), value: values.end },
        },
        validators: [datePickerRequired],
      },
    ],
    enablePlayoffs
      ? [
          {
            component: (props) => {
              const { component, ...rest } = props
              return (
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                  `}
                >
                  <Checkbox
                    {...rest}
                    name={'is_tournament'}
                    defaultValue={values.isTournament}
                  >
                    Playoff Competition? (Enhanced Version)
                  </Checkbox>
                  <div
                    css={css`
                      display: flex;
                      gap: 4px;
                      align-items: center;
                      justify-content: flex-start;
                    `}
                  >
                    <InfoCircleOutlined color={colors.PRIMARY} />
                    <a
                      href='https://sportninja.zendesk.com/hc/en-us/articles/13351227846164-Playoffs-Tournaments-Set-up-Options'
                      target='_blank'
                      rel='noreferrer'
                      css={css`
                        font-size: 12px;
                        color: ${colors.LIGHTER_GRAY};
                        line-height: 16px;
                        text-decoration: underline;
                      `}
                    >
                      Review details here before selecting
                    </a>
                    <span
                      css={css`
                        font-size: 12px;
                        color: ${colors.LIGHTER_GRAY};
                        line-height: 16px;
                      `}
                    >
                      (leave OFF for simple version)
                    </span>
                  </div>
                </div>
              )
            },
          },
        ]
      : [],
  ]

  // return { fields, banner: banners.scheduleCloned(ENTITY_TYPES.schedule) }
  return { fields }
}

export const teamForm = (
  values = {},
  isNew,
  defaultSportType = 'Ice Hockey',
  sportOptions = ['Ice Hockey', 'Soccer', 'Generic'],
  fullSportOptions = [],
  sportId = null
) => {
  const fields = [
    [
      {
        name: 'name_full',
        label: t('common:teamName'),
        placeholder: 'NSWC Bantam A1',
        required: true,
        defaultValue: values.fullName,
        autoComplete: 'off',
        id: 'tutorial-team-name',
      },
    ],
    // [
    //   {
    //     component: GenericSelect,
    //     name: 'sport_type',
    //     label: 'Sport Type',
    //     defaultValue: defaultSportType,
    //     options: sportOptions,
    //     required: true,
    //   },
    // ],
    [
      {
        component: ScheduleTypeFormField,
        default_sport_type: sportId,
        default_generic_sport_type: values?.generic_sport_id,
        sportTypeOptions: fullSportOptions,
        disabled: !isNew,
      },
    ],
    _entityDetails(values),
    [
      {
        name: 'email',
        label: t('common:email'),
        placeholder: t('common:email'),
        defaultValue: values.email,
        autoComplete: 'email',
      },
      { component: PicturePicker, imageId: values.imageId },
    ],
  ]

  const lastLine = []

  if (values?.permission?.delete) {
    lastLine.push({
      name: 'is_placeholder',
      defaultValue: values.isPlaceholder,
      type: 'checkbox',
      label: 'Placeholder?',
    })
  }

  if (lastLine.length > 0) {
    fields.push(lastLine)
  }

  if (isCanlan) {
    fields.push([
      {
        name: 'sf_id',
        label: 'Salesforce ID',
        defaultValue: values?.sf_id || '',
        placeholder: 'e.g. 0010Z00002agcJEFAY',
        required: false,
      },
    ])
  }

  fields.push([{ type: 'break' }])

  // fields.push([
  //   {
  //     component: PrivacySettings,
  //     defaultValues: values,
  //     entityType: ENTITY_TYPES.team,
  //     showVisibility: false,
  //     privacySettingOverride: isNew ? values.isParentPublic : undefined,
  //   },
  // ])

  return {
    fields,
    banner: isNew
      ? banners.entityCreate(ENTITY_TYPES.team)
      : banners.entityUpdate(),
  }
}

export const venueForm = (values = {}, parentId, entityType) => {
  const isNew = Object.keys(values).length === 0
  const address = values.address || {}

  const fields = [
    [
      {
        name: 'name_full',
        label: t('common:name'),
        placeholder: 'e.g. Southside Arena',
        required: true,
        defaultValue: values.fullName,
        autoComplete: 'off',
      },
    ],
    [
      {
        name: 'street_1',
        label: t('common:addressLine1'),
        required: true,
        defaultValue: address.street_1,
      },
      {
        name: 'street_2',
        label: t('common:addressLine2'),
        defaultValue: address.street_2,
      },
    ],
    [
      {
        name: 'city',
        label: t('common:city'),
        required: true,
        defaultValue: address.city,
      },
      {
        name: 'postal_code',
        label: t('common:postalCode'),
        required: true,
        defaultValue: address.postal_code,
      },
    ],
    [
      {
        component: CountryProvinceSelects,
        defaultValues: {
          province_id: {
            value: address.province ? address.province.id : undefined,
          },
          country_id: {
            value: address.country ? address.country.id : undefined,
          },
        },
      },
    ],
    [
      {
        component: TimezoneSelect,
        defaultValue: values.timezone,
        noFlex: true,
      },
    ],
    [{ type: 'break' }],
    [
      {
        component: FacilityList,
        facilities: values.facilities,
        venueId: values.id,
        existingFacilities: values.facilities,
        parentId,
        parentEntityType: entityType,
      },
    ],
  ]

  return {
    fields,
    banner: isNew ? banners.venueCreate() : banners.venueUpdate(),
  }
}

export const scoringPeriodForm = (values) => {
  return [
    [
      {
        name: 'duration',
        label: `${t('Web:periodLength')} (Min / Sec)`,
        required: true,
        component: PeriodClockTime,
        defaultValue: values.duration,
        noFlex: true,
        validators: [periodClockValidator],
        isDuration: true,
      },
    ],
  ]
}

const addPeriodAndTime = (
  values = {},
  form,
  includePeriod = false,
  includeOccurredAt = false,
  isSoccer = false
) => {
  const lineOne = [
    {
      name: 'period_clock_time',
      label: isSoccer ? 'Clock Time (Min/Sec)' : 'Period Clock Time (Min/Sec)',
      component: PeriodClockTime,
      noFlex: !includePeriod && !includeOccurredAt,
      required: true,
      defaultValue: values.gameTime,
    },
  ]

  const occurred = {
    name: 'occurred_at',
    component: TimePicker,
    noFlex: includePeriod,
    label: 'Time of the goal (Optional)',
    defaultValue: values.occurredAt,
    started_at: values.started_at,
  }

  if (includePeriod) {
    lineOne.unshift({
      name: 'period',
      label: isSoccer ? 'Half' : t('common:period'),
      component: PeriodPicker,
      required: true,
      defaultValue: values.period,
      periods: values.periods,
      isSoccer,
    })
  } else if (includeOccurredAt) {
    lineOne.push(occurred)
  }

  form.unshift(lineOne)

  if (includePeriod && includeOccurredAt) {
    form.splice(1, 0, [occurred])
  }
}

export const scoringGoalieChangeForm = (
  values = {},
  options = {},
  isSoccer = false,
  sportId = null
) => {
  const fields = [
    [
      {
        component: GoalieChangePicker,
        defaultValues: {
          previous_goalie_id: values.playerId,
          goalie_id: values.nextGoalie && values.nextGoalie.id,
        },
        roster: values.roster,
        isSoccer,
        sportId,
      },
    ],
  ]

  addPeriodAndTime(values, fields, options.includePeriod, false, isSoccer)

  return fields
}

export const scoringGoalForm = (
  values = {},
  options = {},
  isSoccer = false,
  sportId = null
) => {
  const { includePeriod } = options

  const fields = [
    [
      {
        component: GoalPicker,
        defaultValues: {
          player_id: values.playerId,
          assists: values.assists,
          goal_type_id: values.goal_type_id,
          occurred_at: values.occurred_at,
        },
        roster: values.roster,
        isSoccer,
        sportId,
      },
    ],
  ]

  addPeriodAndTime(values, fields, includePeriod, true, isSoccer)

  return fields
}

export const scoringPenaltyForm = (
  values = {},
  options = {},
  isSoccer,
  sportId
) => {
  const fields = [
    [
      {
        component: PenaltyPicker,
        defaultValues: {
          amount: values.duration,
          offense_type_id: values.typeId,
          player_id: values.playerId,
          served_by_player_id: values.serverId,
          card_type_id: values?.card?.type?.id,
        },
        roster: values.roster,
        isSoccer,
        sportId,
      },
    ],
  ]

  addPeriodAndTime(values, fields, options.includePeriod, false, isSoccer)

  return fields
}

export const scoringShotForm = (values = {}) => {
  const fields = [
    [
      {
        name: 'amount',
        label: 'Shots',
        required: true,
        noFlex: true,
        defaultValue: values.amount,
        type: 'number',
        min: values.min,
        max: 100,
      },
    ],
  ]

  return fields
}

export const scoringGoalsForm = (values = {}) => {
  const fields = [
    [
      {
        name: 'amount',
        label: 'Goals',
        required: true,
        noFlex: true,
        defaultValue: values.amount,
        type: 'number',
        min: values.min,
        max: 150,
      },
    ],
  ]

  return fields
}

export const scoringFoulsForm = (values = {}) => {
  const fields = [
    [
      {
        name: 'amount',
        label: 'Fouls',
        required: true,
        noFlex: true,
        defaultValue: values.amount,
        type: 'number',
        min: values.min,
        max: 150,
      },
    ],
  ]

  return fields
}

export const feedItemForm = (
  values = {},
  displayParentForm = false,
  targetAudience = null
) => {
  const fields = [
    [{ name: 'title', label: t('common:title'), defaultValue: values.title }],

    [
      {
        name: 'body',
        label: t('common:text'),
        node: 'textarea',
        defaultValue: values.body,
      },
    ],
    [
      {
        name: 'url',
        label: t('common:url'),
        defaultValue: values.url,
        autoCapitalize: 'none',
      },
    ],
    [
      {
        component: PicturePicker,
        noFlex: true,
        imageId: values.imagePath,
        placeholder: t('common:image'),
      },
    ],
  ]

  if (targetAudience) {
    fields.unshift([
      {
        name: 'target_audience',
        label: 'TargetAudience',
        component: () => {
          return (
            <div>
              <label style={{ fontSize: 16, color: colors.WHITE }}>
                Target Audience:
              </label>{' '}
              <label style={{ fontSize: 16, color: colors.WHITE }}>
                {targetAudience}
              </label>
            </div>
          )
        },
        defaultValue: targetAudience || 0,
      },
    ])
  }

  if (displayParentForm) {
    fields.unshift([
      {
        component: FeedItemParentPicker,
        name: 'parent_id',
        label: 'Parent',
        defaultValue: values.parentId,
        required: true,
        orgs: values.orgs,
        teams: values.teams,
        scheds: values.scheds,
      },
    ])
  }

  return fields
}

export const playerViewForm = (values = {}) => {
  return [
    [
      {
        name: 'league_registration_number',
        defaultValue: values.leagueNum,
        label: t('common:leagueRegistrationNumber'),
      },
      {
        name: 'is_suspended',
        component: YesNoToggler,
        defaultValue: values.is_suspended,
        label: t('util:capitalize', { text: t('common:suspended') }),
      },
    ],
  ]
}

export const teamOfficialForm = (values = {}, sportId = null) => {
  const isNew = Object.keys(values).length === 0
  const fields = [
    _userNameFields(values),
    [
      {
        name: 'team_official_type_id',
        label: 'Role',
        isTeamOfficialType: true,
        component: OfficialSelect,
        defaultValue: values?.teamOfficialTypeId,
        noFlex: true,
        required: true,
        sportId,
      },
    ],
  ]

  // If the team official doesn't already have an email associated, then add the invite form
  if (typeof values.email !== 'string' || values.email.length === 0) {
    addInviteFormField(values, fields, false, true)
  } else {
    // Otherwise, just a disabled email field to display the email address associated
    fields.push([
      {
        namePhone: 'phone_number',
        nameEmail: 'email',
        component: InvitationFormField,
        defaultValuePhone: values.phone_number,
        defaultValueEmail: values.email,
        disabledEmail: values?.invited === true,
        hideHelpPhone: true,
        hideHelpEmail: true,
      },
    ])
  }

  fields.push([
    {
      name: 'warning_message',
      component: () => {
        return (
          <div style={{ marginTop: 16, fontSize: 13 }}>
            Including an email address will send a new invitation to this user.{' '}
            {t('Web:invitedToRegister')}
          </div>
        )
      },
    },
  ])

  return {
    fields,
    banner: isNew ? banners.teamOfficialCreate() : banners.teamOfficialUpdate(),
  }
}

export const officialForm = (
  values = {},
  isSoccer = false,
  isOrg = false,
  sportId = null
) => {
  const isNew = Object.keys(values).length === 0
  const fields = [
    _userNameFields(values),
    [
      {
        name: 'official_type_id',
        label: 'Role',
        component: OfficialSelect,
        defaultValue: values.officialTypeId,
        noFlex: true,
        required: true,
        isSoccer,
        sportId,
        isOrg,
      },
    ],
  ]

  // If the team official doesn't already have an email associated, then add the invite form
  if (typeof values.email !== 'string' || values.email.length === 0) {
    addInviteFormField(values, fields, false, true)
  } else {
    // Otherwise, just a disabled email field to display the email address associated
    fields.push([
      {
        namePhone: 'phone_number',
        nameEmail: 'email',
        component: InvitationFormField,
        defaultValuePhone: values.phone_number,
        defaultValueEmail: values.email,
        disabledEmail: true,
        hideHelpPhone: true,
        hideHelpEmail: true,
      },
    ])
  }

  fields.push([
    {
      name: 'warning_message',
      component: () => {
        return (
          <div style={{ marginTop: 16, fontSize: 13 }}>
            Including an email address will send a new invitation to this user.{' '}
            {t('Web:invitedToRegister')}
          </div>
        )
      },
    },
  ])

  return {
    fields,
    banner: isNew ? banners.officialCreate() : banners.officialUpdate(),
  }
}
