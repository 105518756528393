import { produce } from 'immer'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import scoring from '../actions/scoring'
import { getOfflineId, str } from './utils'
import gameActions from '../actions/game'
import teamActions from '../actions/team'
import shootoutActions from '../actions/shootout'

dayjs.extend(duration)

const initialState = {
  forcedOffline: false,
  isConnected: true,
  isSyncingEvents: false,
  lastScoredGameId: false,
  offlineGames: {},
}

const parseClockTime = (duration) => {
  const dur = dayjs.duration(duration)
  return dur.format('HH:mm:ss')
}

const getAPITypeFromActionString = (actionString) => {
  switch (actionString) {
    case str(scoring.shots.create.request):
    case str(scoring.shots.delete.request):
      return 'shot'
    case str(scoring.goals.create.request):
    case str(scoring.goals.update.request):
    case str(scoring.goals.delete.request):
      return 'goal'
    case str(scoring.assists.create.request):
    case str(scoring.assists.update.request):
    case str(scoring.assists.delete.request):
      return 'assist'
    case str(scoring.periods.create.request):
    case str(scoring.periods.update.request):
      return 'period'
    case str(scoring.penalties.create.request):
    case str(scoring.penalties.update.request):
    case str(scoring.penalties.delete.request):
      return 'offense'
    case str(scoring.goalieChanges.create.request):
    case str(scoring.goalieChanges.update.request):
    case str(scoring.goalieChanges.delete.request):
      return 'goalie_change'
    case str(gameActions.updateGameRoster.request):
    case str(teamActions.roster.player.create.request):
      return 'player_team_roster'
    case str(gameActions.updateGameRosterPlayer.request):
      return 'player_team_roster_player'
    case str(scoring.signatures.create.request):
      return 'signature'
    case str(shootoutActions.create.request):
    case str(shootoutActions.update.request):
    case str(shootoutActions.delete.request):
    case str(shootoutActions.end.request):
      return 'shootout'
    default:
      return
  }
}

const getAPIActionFromActionString = (actionString) => {
  switch (actionString) {
    case str(scoring.goals.create.request):
    case str(scoring.shots.create.request):
    case str(scoring.assists.create.request):
    case str(scoring.periods.create.request):
    case str(scoring.penalties.create.request):
    case str(scoring.goalieChanges.create.request):
    case str(teamActions.roster.player.create.request):
    case str(scoring.signatures.create.request):
    case str(shootoutActions.create.request):
      return 'create'
    case str(gameActions.updateGameRosterPlayer.request):
    case str(scoring.goals.update.request):
    case str(scoring.assists.update.request):
    case str(scoring.periods.update.request):
    case str(scoring.penalties.update.request):
    case str(scoring.goalieChanges.update.request):
    case str(gameActions.updateGameRoster.request):
    case str(shootoutActions.update.request):
      return 'update'
    case str(scoring.goals.delete.request):
    case str(scoring.shots.delete.request):
    case str(scoring.assists.delete.request):
    case str(scoring.penalties.delete.request):
    case str(scoring.goalieChanges.delete.request):
    case str(shootoutActions.delete.request):
      return 'delete'
    case str(shootoutActions.end.request):
      return 'end'
    default:
      return
  }
}

export default (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case 'FORCE_OFFLINE':
        draft.forcedOffline = payload
        draft.isConnected = !payload
        break

      case 'LAST_SCORED_GAME':
        draft.lastScoredGameId = payload
        break

      case 'IS_CONNECTED':
        draft.isConnected = payload
        break

      case 'RESET_EVENTS':
        draft.offlineGames = {}
        break

      case 'IS_SYNCING_EVENTS':
        draft.isSyncingEvents = payload
        break

      case 'FETCH_OFFLINE_MODE': {
        const eventPayload = payload.prevAction.payload
        const eventAction = payload.prevAction.type

        const actionString = str(eventAction)
        draft.offlineGames = draft.offlineGames || {}

        let gameId
        if (actionString === str(teamActions.roster.player.create.request)) {
          gameId = eventPayload?.form?.gameId
        } else {
          gameId = eventPayload?.id
        }

        if (!gameId) {
          throw new Error('Offline queued events must have a game id supplied!')
        }

        const events = draft.offlineGames[gameId] || []

        const baseEvent = {
          type: getAPITypeFromActionString(actionString),
          action: getAPIActionFromActionString(actionString),
        }

        if (!baseEvent?.type || !baseEvent?.action) {
          throw new Error(
            'Offline queued events must have a valid type and action!'
          )
        }

        switch (actionString) {
          // Goals
          case str(scoring.goals.create.request): {
            const assists = []
            if (eventPayload.form.first_assist) {
              assists.push({
                uid: eventPayload.form.first_assist.uid,
                player_id: eventPayload.form.first_assist.playerId,
                assist_type_id: 1,
              })
            }
            if (eventPayload.form.second_assist) {
              assists.push({
                uid: eventPayload.form.second_assist.uid,
                player_id: eventPayload.form.second_assist.playerId,
                assist_type_id: 2,
              })
            }

            events.push({
              ...baseEvent,
              id: eventPayload.form.uid,
              event_properties: {
                period_id: eventPayload.periodId,
                team_id: eventPayload.form.team_id,
                player_id: eventPayload.form.player_id,
                period_clock_time: eventPayload.form.period_clock_time,
                occurred_at: eventPayload.form.occurred_at,
                goal_type_id: eventPayload.form.goal_type_id,
                assists,
              },
            })
            break
          }
          case str(scoring.goals.update.request): {
            let shot

            if (eventPayload.form.player_id) {
              shot = {
                player_id: eventPayload.form.player_id,
              }
            }

            events.push({
              ...baseEvent,
              id: eventPayload.goalId,
              event_properties: {
                period_clock_time: eventPayload.form.period_clock_time,
                goal_type_id: eventPayload.form.goal_type_id,
                shot,
              },
            })
            break
          }
          case str(scoring.goals.delete.request): {
            events.push({
              ...baseEvent,
              id: eventPayload.goalId,
              event_properties: {
                occurred_at: eventPayload.occurredAt,
              },
            })
            break
          }

          // Assists
          case str(scoring.assists.create.request): {
            events.push({
              ...baseEvent,
              id: getOfflineId(),
              event_properties: {
                game_id: eventPayload.id,
                goal_id: eventPayload.goalId,
                player_id: eventPayload.form.playerId,
                assist_type_id: eventPayload.assistType,
              },
            })
            break
          }
          case str(scoring.assists.update.request): {
            events.push({
              ...baseEvent,
              id: eventPayload.form.assistId,
              event_properties: {
                player_id: eventPayload.form.playerId,
              },
            })
            break
          }
          case str(scoring.assists.delete.request): {
            events.push({
              ...baseEvent,
              id: eventPayload.assistId,
            })
            break
          }

          // Shots
          case str(scoring.shots.create.request):
            events.push({
              ...baseEvent,
              id: eventPayload.uid,
              event_properties: {
                period_id: eventPayload.periodId,
                team_id: eventPayload.teamId,
                occurred_at: eventPayload.occurredAt,
                opposing_goalie_id: eventPayload.opposingGoalieId,
              },
            })
            break
          case str(scoring.shots.delete.request): {
            events.push({
              ...baseEvent,
              id: eventPayload.shotId,
            })
            break
          }

          // Offenses
          case str(scoring.penalties.create.request): {
            events.push({
              ...baseEvent,
              id: eventPayload.form.uid,
              event_properties: {
                period_id: eventPayload.periodId,
                offense_type_id: eventPayload.form.offense_type_id,
                offense_type_name_full:
                  eventPayload.form.offense_type_name_full,
                player_id: eventPayload.form.player_id,
                period_clock_time: eventPayload.form.period_clock_time,
                occurred_at: eventPayload.form.occurred_at,
                team_id: eventPayload.form.team_id,
                penalty: {
                  amount: eventPayload.form.amount,
                  served_by_player_id: eventPayload.form.served_by_player_id,
                  penalty_type_id: 1,
                },
              },
            })
            break
          }

          case str(scoring.penalties.update.request): {
            events.push({
              ...baseEvent,
              id: eventPayload.penaltyId,
              event_properties: {
                offense_type_id: eventPayload.form.offense_type_id,
                offense_type_name_full:
                  eventPayload.form.offense_type_name_full,
                player_id: eventPayload.form.player_id,
                period_clock_time: eventPayload.form.period_clock_time,
                team_id: eventPayload.form.team_id,
                penalty: {
                  amount: eventPayload.form.amount,
                  served_by_player_id: eventPayload.form.served_by_player_id,
                },
              },
            })
            break
          }

          case str(scoring.penalties.delete.request): {
            events.push({
              ...baseEvent,
              id: eventPayload.penaltyId,
            })
            break
          }

          // Goalie Changes
          case str(scoring.goalieChanges.create.request): {
            events.push({
              ...baseEvent,
              id: eventPayload.form.uid,
              event_properties: {
                period_id: eventPayload.periodId,
                previous_goalie_id: eventPayload.form.previous_goalie_id,
                goalie_id: eventPayload.form.goalie_id,
                period_clock_time: eventPayload.form.period_clock_time,
                occurred_at: eventPayload.form.occurred_at,
                team_id: eventPayload.form.team_id,
              },
            })
            break
          }
          case str(scoring.goalieChanges.update.request): {
            events.push({
              ...baseEvent,
              id: eventPayload.goalieChangeId,
              event_properties: {
                previous_goalie_id: eventPayload.form.previous_goalie_id,
                goalie_id: eventPayload.form.goalie_id,
                period_clock_time: eventPayload.form.period_clock_time,
              },
            })
            break
          }
          case str(scoring.goalieChanges.delete.request): {
            events.push({
              ...baseEvent,
              id: eventPayload.goalieChangeId,
            })
            break
          }

          // Periods
          case str(scoring.periods.create.request): {
            events.push({
              ...baseEvent,
              id: eventPayload.form.uid,
              event_properties: {
                started_at: eventPayload.form.started_at,
                duration: eventPayload.form.duration,
                period_type_id: eventPayload.form.period_type_id,
                clock_time: parseClockTime(eventPayload.form.duration),
              },
            })
            break
          }
          case str(scoring.periods.update.request): {
            events.push({
              ...baseEvent,
              id: eventPayload.periodId,
              event_properties: {
                ...eventPayload.form,
              },
            })
            break
          }

          // Game Player Roster Update
          case str(gameActions.updateGameRoster.request): {
            events.push({
              ...baseEvent,
              event_properties: {
                game_player_roster_id: eventPayload.rosterId,
                team_id: eventPayload.form.team_id,
                players: eventPayload.form.players,
              },
            })
            break
          }

          case str(gameActions.updateGameRosterPlayer.request): {
            const { queueForOffline, ...info } = eventPayload.form
            events.push({
              ...baseEvent,
              event_properties: {
                game_player_roster_id: eventPayload.rosterId,
                player_id: eventPayload.playerId,
                ...info,
              },
            })
            break
          }

          // Team Player Roster Create
          case str(teamActions.roster.player.create.request): {
            events.push({
              ...baseEvent,
              id: eventPayload.form.uid,
              event_properties: {
                team_id: eventPayload.id,
                team_player_roster_id: eventPayload.rosterId,
                name_first: eventPayload.form.name_first,
                name_last: eventPayload.form.name_last,
                email: eventPayload.form.email,
                birth_date: eventPayload.form.birth_date,
                is_affiliate: eventPayload.form.is_affiliate,
                player_number: eventPayload.form.player_number,
                player_type_id: eventPayload.form.player_type_id,
                customer_identifier: eventPayload.form.customer_identifier,
              },
            })
            break
          }

          // Create game signature
          case str(scoring.signatures.create.request): {
            events.push({
              ...baseEvent,
              id: eventPayload.userId,
              event_properties: {
                official_id: eventPayload.userId,
                signature_image: eventPayload.data,
              },
            })
            break
          }

          case str(shootoutActions.create.request): {
            events.push({
              ...baseEvent,
              id: eventPayload.form.uid,
              event_properties: {
                home: eventPayload.form.home,
                player_id: eventPayload.form.player_id,
                goalie_id: eventPayload.form.goalie_id,
                goal: eventPayload.form.goal,
              },
            })
            break
          }

          case str(shootoutActions.update.request): {
            events.push({
              ...baseEvent,
              id: eventPayload.shotId,
              event_properties: {
                home: eventPayload.form.home,
                player_id: eventPayload.form.player_id,
                goalie_id: eventPayload.form.goalie_id,
                goal: eventPayload.form.goal,
              },
            })
            break
          }

          case str(shootoutActions.delete.request): {
            events.push({
              ...baseEvent,
              id: eventPayload.shotId,
            })
            break
          }

          case str(shootoutActions.end.request): {
            events.push(baseEvent)
            break
          }

          default:
            break
        }

        draft.offlineGames[gameId] = events
        break
      }

      default:
        break
    }
  })
