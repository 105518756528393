/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useState } from 'react'
import { connect } from 'react-redux'
import { getGoalieTypeIdForSport } from '@sportninja/common/selectors/types'
import { toFilteredArray } from '@sportninja/common/utils/utils'
import { t } from '@sportninja/common/i18n'

import { ReactComponent as ArrowUpTurn } from '../pages/Game/Timeline/arrow-upturn.svg'
import { SelectFormInput } from './Select'

const isGoalie = (playerType, goalieTypeId) => {
  const pType =
    typeof playerType === 'string' ? parseInt(playerType, 10) : playerType
  const gType =
    typeof goalieTypeId === 'string' ? parseInt(goalieTypeId, 10) : goalieTypeId

  return pType === gType
}

const GoalieChangePicker = ({
  defaultValues,
  disabled,
  goalieTypeId,
  onChange,
  roster,
  isSoccer = false,
}) => {
  console.log('goalieTypeId', goalieTypeId)
  let goalies = []
  if (roster) {
    // On soccer we are using goalie changes events as substitution
    // as requested by Jeff
    if (isSoccer) {
      goalies = toFilteredArray(roster, ({ playerTypeId }) => true)
    } else {
      goalies = toFilteredArray(roster, ({ playerTypeId }) =>
        isGoalie(playerTypeId, goalieTypeId)
      )
    }
  }

  // TODO: Fix the case of a "new" event: these will be undefined, but we need to
  // detect undefined as "null" or an "empty net" in cases of editing a goalie change!!
  const [goalieOut, setGoalieOut] = useState(defaultValues.previous_goalie_id)
  const [goalieIn, setGoalieIn] = useState(defaultValues.goalie_id)

  const handleChange = (setState, event) => {
    const value = event.target.value

    // We need to clear the set value if a user selects the same goalie or empty net
    if (setState === setGoalieIn && value === goalieOut) {
      setGoalieOut('fakeValue')
    } else if (setState === setGoalieOut && value === goalieIn) {
      setGoalieIn('fakeValue')
    }

    setState(value)
    onChange(event)
  }

  return (
    // FormRows are display: flex so we need to add a single block-level element
    // to make these full width.
    <div
      css={css`
        width: 100%;
      `}
    >
      <div
        css={css`
          position: relative;
        `}
      >
        <SelectFormInput
          css={css`
            padding: 0 !important;
            select {
              padding-left: 44px;
            }
          `}
          defaultValue={goalieOut}
          disabled={disabled}
          label={
            isSoccer ? 'Current Player Status' : t('Web:currentGoalieStatus')
          }
          name='previous_goalie_id'
          onChange={handleChange.bind(this, setGoalieOut)}
          required
        >
          <option value=''>
            {isSoccer
              ? 'Select current player status'
              : t('Web:selectCurrentGoalieStatus')}
            :
          </option>
          <option disabled>-----</option>
          {goalies.map((goalie) => {
            return (
              <option key={goalie.id} value={goalie.id}>
                {goalie.number} - {goalie.fullName} (
                {goalie?.positionAbbrev || '-'})
              </option>
            )
          })}
          <option value='null'>{t('Scoring:emptyNet')}</option>
        </SelectFormInput>
        <ArrowUpTurn
          css={css`
            transform: scaleY(-1);
            path {
              fill: #ff2e2e;
            }
            position: absolute;
            top: 35px;
            left: 14px;
            width: 19px;
            height: 14px;
          `}
        />
      </div>
      <div
        css={css`
          width: 100%;
          position: relative;
        `}
      >
        <SelectFormInput
          css={css`
            padding: 0 !important;
            margin-top: 20px;

            select {
              padding-left: 44px;
            }
          `}
          defaultValue={goalieIn}
          disabled={disabled}
          label={isSoccer ? 'New Player Status' : t('Web:newGoalieStatus')}
          name='goalie_id'
          onChange={handleChange.bind(this, setGoalieIn)}
          required
        >
          <option value=''>
            {isSoccer
              ? 'Select new player status'
              : t('Web:selectNewGoalieStatus')}
            :
          </option>
          <option disabled>-----</option>
          {goalies.map((goalie) => {
            return (
              <option key={goalie.id} value={goalie.id}>
                {goalie.number} - {goalie.fullName} (
                {goalie?.positionAbbrev || '-'})
              </option>
            )
          })}
          <option value='null'>Empty Net</option>
        </SelectFormInput>
        <ArrowUpTurn
          css={css`
            position: absolute;
            top: 33px;
            left: 14px;
            width: 19px;
            height: 14px;
          `}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state, { sportId }) => {
  console.log('sportId', sportId)
  return {
    goalieTypeId: getGoalieTypeIdForSport(state, sportId),
  }
}

export default connect(mapStateToProps)(GoalieChangePicker)
