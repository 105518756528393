import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { DragHandle } from './DragHandle'
import styled from 'styled-components'
import { css } from '@emotion/react'

const DraggingRow = styled.td`
  background: linear-gradient(#282e38, #181a1d);
`

const TableData = styled.td``

const LastTableData = styled.td`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  margin-top: 2px !important;
`

export const DraggableTableRow = ({ row }) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({
    id: row.original.id,
  })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  }
  return (
    <tr ref={setNodeRef} style={style} {...row.getRowProps()}>
      {isDragging ? (
        <DraggingRow colSpan={row.cells.length}>&nbsp;</DraggingRow>
      ) : (
        row.cells.map((cell, i) => {
          // Get the last item in the array
          const isLast = i === row.cells.length - 1
          if (isLast) {
            return (
              <LastTableData {...cell.getCellProps()} key={i}>
                <span>{cell.render('Cell')}</span>
                <DragHandle {...attributes} {...listeners} />
              </LastTableData>
            )
          }
          return (
            <TableData {...cell.getCellProps()} key={i}>
              {cell.render('Cell')}
            </TableData>
          )
        })
      )}
    </tr>
  )
}
