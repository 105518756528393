/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useCallback, useEffect, useRef, useState } from 'react'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'

const ProgressBar = ({ completed }) => {
  return (
    <div
      css={css`
        height: 35px;
        width: '100%';
        background-color: #e0e0de;
        border-radius: 5px;
      `}
    >
      <div
        css={css`
          height: 100%;
          width: ${completed}%;
          background-color: ${colors.DEFAULT_FLAIR};
          border-radius: inherit;
          text-align: right;
          transition: width 1s ease-in-out;
          justify-content: center;
          align-items: center;
          display: flex;
        `}
      >
        <span
          css={css`
            font-family: ${isCanlan
              ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
              : 'Rift, Arial, Helvetica, sans-serif'};
            color: white;
            font-size: 15px;
            font-weight: 'bold';
          `}
        >
          {completed > 0 ? `${completed}%` : ''}
        </span>
      </div>
    </div>
  )
}

let gameIndex = 0
let gamesSuccessfullyProcessed = 0
let lastIndexWithError = -1

export default function Scoresheets({ history, location }) {
  const [loading, setLoading] = useState(true)
  const [hasError, setErrorExists] = useState(false)
  const [readyToPrint, setReadyToPrint] = useState(false)
  const [totalGamesProcessed, setTotalGamesProcessed] = useState(-1)
  const [abortControllers, setAbortControllers] = useState<AbortController[]>(
    []
  )
  const [HTMLLength] = useState(
    (location && location.state && location.state.games.length) || 0
  )

  let games = (location && location.state && location.state.games) || []
  const isSoccer = location.state?.isSoccer || false

  const iframeContainer = useRef<HTMLIFrameElement>(null)

  const loadGames = useCallback(async () => {
    try {
      for (const i in games) {
        if (Number(i) > lastIndexWithError) {
          setTotalGamesProcessed((prev) => prev + 1)
          const abortController = new AbortController()
          const signal = abortController.signal
          setAbortControllers((state) => {
            state.push(abortController)
            return state
          })

          gameIndex = Number(i)

          const response = await req(
            `/games/${games[Number(i)]}/scoresheets/html`,
            {
              parseJSON: false,
              signal,
            }
          )

          const html = await response.text()

          const iframe = document.createElement('iframe')
          const div = document.createElement('div')
          iframe.style.background = 'white'
          iframe.style.position = 'absolute'

          iframe.width = '100%'
          iframe.height = '100%'

          if (isSoccer) {
            iframe.style.transform = 'scale(0.60)'
            iframe.style.transformOrigin = '0 0'
            iframe.width = '166.5%'
            iframe.height = '166.5%'

            div.style.position = 'relative'
          }

          div.className = 'iframe-container'
          div.id = `iframe-container-${i}`

          div.appendChild(iframe)
          iframeContainer.current?.appendChild(div)

          iframe.contentWindow?.document.open()
          iframe.contentWindow?.document.write(html)
          iframe.contentWindow?.document.close()

          gamesSuccessfullyProcessed += 1
        }
      }

      setTotalGamesProcessed(games.length)
      setReadyToPrint(true)
    } catch (error) {
      if (games.length <= 1) {
        setErrorExists(true)
        setLoading(false)
      } else {
        saveGameWithErrorAndProceedToNextGame()
      }
    }
  }, [isSoccer])

  const saveGameWithErrorAndProceedToNextGame = useCallback(() => {
    lastIndexWithError = gameIndex

    loadGames()
  }, [])

  useEffect(() => {
    if (readyToPrint && !hasError) {
      setLoading(false)
      setTimeout(() => {
        if (gamesSuccessfullyProcessed > 0) {
          window.print()
        }
      }, 100)
    }
  }, [readyToPrint, hasError])

  useEffect(() => {
    loadGames()

    return () => {
      gameIndex = 0
      gamesSuccessfullyProcessed = 0
      lastIndexWithError = -1

      abortControllers.forEach((controller) => {
        controller.abort()
      })
    }
  }, [])

  return (
    <>
      <div
        css={css`
          padding: 50px;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          @media only print {
            visibility: hidden;
          }
        `}
      >
        {loading ? (
          <>
            <h1
              css={css`
                font-size: 40px;
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'Rift, Arial, Helvetica, sans-serif'};
                margin-bottom: 2em;
                font-weight: bold;
              `}
            >
              Your scoresheets are being processed. Please wait.
            </h1>
            <div
              css={css`
                width: 60%;
              `}
            >
              <ProgressBar
                completed={Math.round(
                  (totalGamesProcessed / games.length) * 100
                )}
              />
            </div>
          </>
        ) : (
          <>
            <h1
              css={css`
                font-size: 40px;
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'Rift, Arial, Helvetica, sans-serif'};
                margin-bottom: 2em;
                font-weight: bold;
                text-align: center;
              `}
            >
              {hasError || gamesSuccessfullyProcessed === 0
                ? 'There is a problem processing the scoresheet, please try again later.'
                : 'Your scoresheets are ready to download.'}
            </h1>
            <p
              css={css`
                font-size: 14px;
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'Rift, Arial, Helvetica, sans-serif'};
                margin-bottom: 2.5em;
                font-weight: bold;
              `}
            >
              A total of {gamesSuccessfullyProcessed} / {HTMLLength} scoresheets
              were generated.
            </p>
            {gamesSuccessfullyProcessed > 0 && (
              <a
                css={css`
                  font-size: 15px;
                  font-weight: bold;
                  color: ${colors.DEFAULT_FLAIR};
                  margin-left: 8px;
                  margin-bottom: 2.5em;
                  font-family: Arial, Helvetica, sans-serif;
                `}
                onClick={() => window.print()}
              >
                {hasError
                  ? `Show the ${gamesSuccessfullyProcessed} games processed`
                  : 'Click here to open print dialog again.'}
              </a>
            )}
            <button
              type='button'
              onClick={() => {
                games = []
                gamesSuccessfullyProcessed = 0
                gameIndex = 0
                history.goBack()
              }}
              css={css`
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'Rift, Arial, Helvetica, sans-serif'};
                font-size: 24px;
                font-weight: bold;
                color: white;
                margin-left: 8px;
              `}
            >
              {'<'} Back
            </button>
          </>
        )}
      </div>
      <div
        css={css`
          visibility: hidden;
          height: 100vh;
          width: 100%;
          position: absolute;
          top: 0;
          overflow: hidden;

          @media only print {
            visibility: visible;
            overflow: visible;
          }
        `}
        ref={iframeContainer}
      />
    </>
  )
}
