/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Picture from '../Picture'
import { Flex } from '../Layout'
import { font } from '../css'
import { sectionAndSubTitleText } from './css'
import { InitialsThumbnail } from '../ThumbnailAndName'

const MobileCard = ({
  children,
  titleCss,
  hideImage,
  iconName,
  imageId,
  initials,
  isEntity,
  mobileSubTitle,
  sectionTitle,
  subTitle,
  title,
  to,
}) => {
  return (
    <Fragment>
      {sectionTitle && (
        <div
          css={[
            css`
              ${sectionAndSubTitleText}
              margin-bottom: 20px;

              :not(:nth-of-type(1)) {
                margin-top: 60px;
              }
            `,
            titleCss,
          ]}
        >
          {sectionTitle}
        </div>
      )}
      <Flex
        as='article'
        alignItems='center'
        css={css`
          width: 100%;
          margin-bottom: 28px;
        `}
      >
        {!hideImage && !imageId && initials ? (
          <InitialsThumbnail>{initials}</InitialsThumbnail>
        ) : (
          <Picture
            iconName={iconName}
            imageId={imageId}
            fit={isEntity}
            size='xsmall'
            square={isEntity}
          />
        )}
        <Flex
          as={to && Link}
          to={to ? to : undefined}
          column
          css={css`
            margin: 0 16px;
          `}
          aria-label={`Visit the ${title} details page`}
        >
          <h2
            css={css`
              ${font.title}
              font-size: 20px;
              font-weight: 500;
              letter-spacing: 0.64px;
              line-height: 20px;
              text-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.22);
              word-break: break-word;
            `}
          >
            {title}
          </h2>
          {(mobileSubTitle || subTitle) && (
            <h3
              css={css`
                color: #b0b1b4;
                font-size: 14px;
                line-height: 14px;

                margin-top: 8px;
              `}
            >
              {mobileSubTitle || subTitle}
            </h3>
          )}
        </Flex>
        {children}
      </Flex>
    </Fragment>
  )
}

MobileCard.propTypes = {
  backgroundText: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
  customCss: PropTypes.node,
  customPicture: PropTypes.node,
  hideImage: PropTypes.bool,
  iconName: PropTypes.string,
  imageId: PropTypes.string,
  isEntity: PropTypes.bool,
  sectionTitle: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  subTitle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  mobileSubTitle: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
}

export default MobileCard
