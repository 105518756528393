/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import { Fragment, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import ActionButton from '../ActionButton'
import Sheet from '../Sheet'
import DeleteModal from '../Modal/DeleteModal'

import Form from './index'

export const SheetInnerForm = ({
  toggleOpen = () => {},
  setDirty,
  customDeleteModal,
  deleteTitle,
  deleteMessage,
  deleteButtonText,
  disableToggleOnCancel = false,
  form,
  onCancel,
  onDelete,
  onError,
  submitText,
  onSubmit,
  preForm,
}) => {
  // In the case that the parent component was deleted, don't do any of
  // this
  // if (!mounted.current) return false

  const hasFieldsProperty =
    form && Object.prototype.hasOwnProperty.call(form, 'fields')
  const hasBannerProperty =
    form && Object.prototype.hasOwnProperty.call(form, 'banner')

  const submit = async (...args) => {
    const response = await onSubmit(...args)

    if (!response) {
      return
    }

    if (hasBannerProperty) {
      form.banner(response, ...args)
    }

    // Force close the Sheet since we know we're done here
    toggleOpen({ forceClose: true })

    return response
  }

  return (
    <Fragment>
      {preForm}
      <Form
        form={hasFieldsProperty ? form.fields : form}
        onCancel={() => {
          onCancel()
          !disableToggleOnCancel && toggleOpen()
        }}
        onError={onError}
        onSubmit={submit}
        submitText={submitText}
        setDirty={setDirty}
      />
      {(onDelete || customDeleteModal) && (
        <div
          css={css`
            align-self: flex-end;
            /* Align action button to bottom of page */
            margin-top: auto;
            padding-top: 40px;
          `}
        >
          {customDeleteModal ? (
            customDeleteModal
          ) : (
            <DeleteModal
              buttonText={deleteButtonText}
              message={deleteMessage}
              onDelete={onDelete}
              title={deleteTitle}
            />
          )}
        </div>
      )}
    </Fragment>
  )
}

const FormSheet = ({
  customDeleteModal,
  hideButton = false,
  isOpen,
  busy,
  Button,
  className,
  deleteTitle,
  deleteMessage,
  deleteButtonText,
  form,
  hideClose,
  icon,
  label,
  onCancel = () => {},
  onDelete,
  onError,
  onSubmit,
  position,
  preForm,
  title,
  subTitle,
  submitText,
  alwaysShowIconLabel = false,
}) => {
  const mounted = useRef(true)

  useEffect(() => {
    return () => {
      if (mounted) {
        mounted.current = false
      }
    }
  }, [])

  return (
    <Sheet
      hideButton={hideButton}
      hideClose={hideClose}
      isOpen={isOpen}
      className={className}
      button={(toggleOpen) => {
        return Button ? (
          <Button onClick={toggleOpen} />
        ) : (
          <ActionButton
            busy={busy}
            icon={icon}
            onClick={toggleOpen}
            label={label}
            title={title}
            alwaysShowLabel={alwaysShowIconLabel}
          />
        )
      }}
      position={position}
      title={title}
      subTitle={subTitle}
    >
      {(toggleOpen, setDirty) => (
        <SheetInnerForm
          toggleOpen={toggleOpen}
          setDirty={setDirty}
          customDeleteModal={customDeleteModal}
          deleteTitle={deleteTitle}
          deleteMessage={deleteMessage}
          deleteButtonText={deleteButtonText}
          form={form}
          onCancel={onCancel}
          submitText={submitText}
          onDelete={onDelete}
          onError={onError}
          onSubmit={onSubmit}
          preForm={preForm}
        />
      )}
    </Sheet>
  )
}

FormSheet.propTypes = {
  busy: PropTypes.bool,
  Button: PropTypes.func,
  className: PropTypes.string,
  customDeleteModal: PropTypes.object,
  hideClose: PropTypes.bool,
  icon: PropTypes.string,
  form: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  onError: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  position: PropTypes.string,
  preForm: PropTypes.node,
  title: PropTypes.string.isRequired,
}

export default FormSheet
