/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'

import ImportCSVErrorTable from './ImportCSVErrorTable'

const ImportCSVError = ({ errors = [] }) => {
  return (
    <div
      css={css`
        overflow: auto;
        margin-bottom: 20px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      `}
    >
      <ImportCSVErrorTable
        header={
          <tr>
            <th>Line #</th>
            <th className='description'>Problem(s)</th>
          </tr>
        }
        body={
          Array.isArray(errors) &&
          errors?.map(({ line, conflicts, ...error }, i) => {
            return (
              <tr key={i} className='row'>
                <td className='col-1'>{line}</td>
                <td>
                  {Object.values(error).map((message, j) => {
                    return (
                      <div className='message' key={`message-${j}`}>
                        - {message}
                      </div>
                    )
                  })}
                </td>
              </tr>
            )
          })
        }
      />
    </div>
  )
}

export default ImportCSVError
