import type { Reducer } from 'react'

export type State = {
  step: number
  context: {
    name: string
    id: string
  }
}

type ActionTypes = 'next' | 'back' | 'set' | 'setContext'

export type Action = {
  type: ActionTypes
  payload?: any
}

export const stepController: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'next':
      return { ...state, step: state.step + 1 }
    case 'back':
      return { ...state, step: state.step - 1 }
    case 'set':
      return { ...state, step: action.payload }
    case 'setContext':
      return { ...state, context: action.payload }
    default:
      return state
  }
}
