export const StatTypes = {
  GOAL: {
    id: 1,
    name: 'Goals',
    abbreviation: 'G',
    description:
      'A goal is awarded to the last player on the scoring team to touch the puck prior to the puck entering the net.',
    sportId: 'hockey',
  },
  GAMES_PLAYED: {
    id: 2,
    name: 'Games Played',
    abbreviation: 'GP',
    description: '# of Games where the goaltender played at least 1 sec.',
    sportId: 'hockey',
  },
  ASSIST: {
    id: 3,
    name: 'Assists',
    abbreviation: 'A',
    description:
      'An assist is awarded to the player or players (maximum of two) who touch the puck prior to the goal, provided no defender plays or possesses the puck in between.',
    sportId: 'hockey',
  },
  POINTS: {
    id: 4,
    name: 'Points',
    abbreviation: 'P',
    description: 'The sum total of goals and assists.',
    sportId: 'hockey',
  },
  PENALTY_MINUTES: {
    id: 5,
    name: 'Penalty Minutes',
    abbreviation: 'PiM',
    description: 'Total # of Penalty minutes of all penalties.',
    sportId: 'hockey',
  },
  AVERAGE_POINTS_PER_GAME: {
    id: 6,
    name: 'Average Points Per Game',
    abbreviation: 'PTS/G',
    description: 'Points divided by Games Played.',
    sportId: 'hockey',
  },
  WIN: {
    id: 7,
    name: 'Wins',
    abbreviation: 'W',
    description:
      'This is attributed when the end-score of a team is higher than the end-score of the opposing team.',
    sportId: 'hockey',
  },
  LOSS: {
    id: 8,
    name: 'Losses',
    abbreviation: 'L',
    description:
      'This is attributed when the end-score of a team is lower than the end-score of the opposing team.',
    sportId: 'hockey',
  },
  TIE: {
    id: 9,
    name: 'Ties',
    abbreviation: 'T',
    description:
      'This is attributed when the end-score of a team is equal to the end-score of the opposing team.',
    sportId: 'hockey',
  },
  GOALS_AGAINST_AVERAGE: {
    id: 10,
    name: 'Goals Against Average',
    abbreviation: 'GAA',
    description: 'Goals against * 60 / Minutes played.',
    sportId: 'hockey',
  },
  GOALS_FOR: {
    id: 11,
    name: 'Goals For',
    abbreviation: 'GF',
    description: '# of Goals Scored.',
    sportId: 'hockey',
  },
  GOALS_AGAINST: {
    id: 12,
    name: 'Goals Against',
    abbreviation: 'GA',
    description: '# of Goals Scored by opposing teams.',
    sportId: 'hockey',
  },
  SAVE: {
    id: 13,
    name: 'Saves',
    abbreviation: 'SV',
    description:
      '# of Shots taken by the opposing team LESS the goals scored by the opposing team.',
    sportId: 'hockey',
  },
  SHOTS_AGAINST: {
    id: 14,
    name: 'Shots Against',
    abbreviation: 'SA',
    description: 'Total number of shots against',
    sportId: 'hockey',
  },
  BENCH_PENALTY_MINUTES: {
    id: 29,
    name: 'Bench Penalty Minutes',
    abbreviation: 'BPM',
    description: "Total # of penalty minutes awarded to the 'Bench'",
    sportId: 'hockey',
  },
  OVERTIME_LOSSES: {
    id: 30,
    name: 'Overtime Losses',
    abbreviation: 'OTL',
    description: '# of games lost in Overtime',
    sportId: 'hockey',
  },
  OVERTIME_WINS: {
    id: 31,
    name: 'Overtime Wins',
    abbreviation: 'OTW',
    description: '# of games won in Overtime',
    sportId: 'hockey',
  },
  REGULATION_AND_OVERTIME_WINS: {
    id: 33,
    name: 'Regulation & Overtime Wins',
    abbreviation: 'ROW',
    description: 'Regulation Wins + OT Wins',
    sportId: 'hockey',
  },
  GOAL_DIFFERENTIAL: {
    id: 34,
    name: 'Goal Differential',
    abbreviation: 'DIFF',
    description: 'Goals for less goals against (could be negative)',
    sportId: 'hockey',
  },
  FIRST_GOAL: {
    id: 35,
    name: 'First Goal',
    abbreviation: 'FG',
    description:
      '# of 1st Goals awarded that: are the first goal of the game for either team',
    sportId: 'hockey',
  },
  MINUTES_PLAYED: {
    id: 36,
    name: 'Minutes Played',
    abbreviation: 'MP',
    altAbbreviation: 'Min',
    description: 'Total # of minutes played',
    sportId: 'hockey',
  },
  SHUTOUTS: {
    id: 37,
    name: 'Shutouts',
    abbreviation: 'SO',
    description:
      '# of Games where the goaltender was the ONLY goaltender to play - and did not give up a single goal',
    sportId: 'hockey',
  },
  TEAM_POINTS: {
    id: 38,
    name: 'Points',
    abbreviation: 'PTS',
    description: 'Team Points based on Wins/Losses/Ties.',
    sportId: 'hockey',
  },
  WINNING_PERCENTAGE: {
    id: 39,
    name: 'Winning Percentage',
    abbreviation: 'WPCT',
    description: 'Fraction of games a team has won.',
    sportId: 'hockey',
  },
  SHOOTOUT_LOSS: {
    id: 40,
    name: 'Shootout Loss',
    abbreviation: 'SOL',
    description: 'Fraction of games a team has lost in shootouts.',
    sportId: 'hockey',
  },
  IN_DIVISION_WINNING_PERCENTAGE: {
    id: 41,
    name: 'In-Division Winning Percentage',
    abbreviation: 'WPCTD',
    description: 'Fraction of games a team has won.',
    sportId: 'hockey',
  },
  GAME_PERCENTAGE: {
    id: 42,
    name: 'Game Percentage',
    abbreviation: 'GPCT',
    description:
      'Total number of goals for divided by the total number of goals for and against.',
    sportId: 'hockey',
  },
  GAMES_FORFEITED: {
    id: 43,
    name: 'Games Forfeited',
    abbreviation: 'GFD',
    description: 'Point for having won a game by forfeit of the opponent.',
    sportId: 'hockey',
  },
  ADJUSTED_GAMES_PLAYED: {
    id: 44,
    name: 'Adjusted Games Played',
    abbreviation: 'AGP',
    description: 'Won Games by Forfeited + Games Played',
    sportId: 'hockey',
  },
  SHORT_HANDED_GOALS: {
    id: 45,
    name: 'Short Handed Goals',
    abbreviation: 'SHG',
    description:
      'Number of goals a player made when his team had fewer players on the ice',
    sportId: 'hockey',
  },
  POWER_PLAY_GOALS: {
    id: 46,
    name: 'Power Play Goals',
    abbreviation: 'PPG',
    description:
      'Number of goals the player made while his team was on a power play',
    sportId: 'hockey',
  },
  RED_CARDS: {
    id: 76,
    name: 'Red Cards',
    abbreviation: 'RC',
    description: 'Number of red cards that were received',
    sportId: 'soccer',
  },
  YELLOW_CARDS: {
    id: 77,
    name: 'Yellow Cards',
    abbreviation: 'YC',
    description: 'Number of yellow cards that were received',
    sportId: 'soccer',
  },
  FOULS_COMMITTED: {
    id: 78,
    name: 'Fouls Committed',
    abbreviation: 'FC',
    description: 'Number of fouls committed',
    sportId: 'soccer',
  },
  FOULS_SUFFERED: {
    id: 79,
    name: 'Fouls Suffered',
    abbreviation: 'FS',
    description: 'Number of fouls suffered',
    sportId: 'soccer',
  },
  GOALS_FOR_AVERAGE: {
    id: 80,
    name: 'Goals For Average',
    abbreviation: 'GFA',
    description: 'Goals divided by Matches Played',
    sportId: 'soccer',
  },
  SAVE_PERCENTAGE: {
    id: 32,
    name: 'Save Percentage',
    abbreviation: 'SV%',
    description: 'SV / SA',
    sportId: 'hockey',
  },
  REGULATION_AND_EXTRATIME_WINS: {
    id: 64,
    name: 'Regulation & Extratime Wins',
    abbreviation: 'REW',
    description: 'Regulation Wins + ET Wins',
    sportId: 'soccer',
  },
  GOAL_DIFFERENTIAL_SPORT2: {
    id: 65,
    name: 'Goal Differential',
    abbreviation: 'GD',
    description: 'Goals for less goals against (could be negative)',
    sportId: 'soccer',
  },
  FIRST_GOAL_SPORT2: {
    id: 66,
    name: 'First Goal',
    abbreviation: 'FG',
    description:
      '# of 1st Goals awarded that: are the first goal of the match for either team',
    sportId: 'soccer',
  },
  MINUTES_PLAYED_SPORT2: {
    id: 67,
    name: 'Minutes Played',
    abbreviation: 'Mins',
    description: 'Total # of minutes played',
    sportId: 'soccer',
  },
  CLEAN_SHEET: {
    id: 68,
    name: 'Clean Sheets',
    abbreviation: 'CS',
    description:
      '# of matches where the goalkeeper was the ONLY goalkeeper to play - and did not give up a single goal',
    sportId: 'soccer',
  },
  WINNING_PERCENTAGE_SPORT2: {
    id: 70,
    name: 'Winning Percentage',
    abbreviation: 'W%',
    description: 'Fraction of matches a team has won',
    sportId: 'soccer',
  },
  SHOOTOUT_LOSS_SPORT2: {
    id: 71,
    name: 'Shootout Loss',
    abbreviation: 'SOL',
    description: 'Fraction of games a team has lost in shootouts.',
    sportId: 'soccer',
  },
  IN_DIVISION_WINNING_PERCENTAGE_SPORT2: {
    id: 72,
    name: 'In-Division Winning Percentage',
    abbreviation: 'DW%',
    description: 'Fraction of matches a team has won',
    sportId: 'soccer',
  },
  MATCH_PERCENTAGE: {
    id: 73,
    name: 'Match Percentage',
    abbreviation: 'M%',
    description:
      'Total number of goals for divided by the total number of goals for and against.',
    sportId: 'soccer',
  },
  MATCHES_FORFEITED: {
    id: 74,
    name: 'Matches Forfeited',
    abbreviation: 'MFD',
    description: 'Point for having won a match by forfeit of the opponent',
    sportId: 'soccer',
  },
  ADJUSTED_MATCHES_PLAYED: {
    id: 75,
    name: 'Adjusted Matches Played',
    abbreviation: 'AMP',
    description: 'Won Matches by Forfeited + Games Played',
    sportId: 'soccer',
  },
  EXTRA_TIME_LOSSES: {
    id: 61,
    name: 'Extra Time Losses',
    abbreviation: 'ETL',
    description: '# of matches lost in Extra time',
    sportId: 'soccer',
  },
  EXTRA_TIME_WINS: {
    id: 62,
    name: 'Extra Time Wins',
    abbreviation: 'ETW',
    description: '# of matches won in Extra time',
    sportId: 'soccer',
  },
  MATHES_PLAYED: {
    id: 48,
    name: 'Matches Played',
    abbreviation: 'MP',
    description: '# of matches played for at least 1 second',
    sportId: 'soccer',
  },
  GOALS_FOR_SOCCER: {
    id: 56,
    name: 'Goals For',
    abbreviation: 'GF',
    description: '# of Goals Scored',
    sportId: 'soccer',
  },
  SAVE_PERCENTAGE_SOCCER: {
    id: 32,
    name: 'Save Percentage',
    abbreviation: 'SV%',
    description: 'SV / SA',
    sportId: 'soccer',
  },
}

export const standingsHockeyColumns = [
  'GP',
  'W',
  'L',
  'T',
  'OTL',
  'SOL',
  'PTS',
  'WPCT',
  'WPCTD',
  'GF',
  'GA',
  'GPCT',
  'PiM',
  'OTW',
]

export const standingsSoccerColumns = [
  'MP',
  'W',
  'L',
  'D',
  'GF',
  'GA',
  'GD',
  'W%',
  'DW%',
  'PTS',
]

export const skaterStatisticsHockeyColumns = [
  'GP',
  'G',
  'A',
  'P',
  'PiM',
  'PPG',
  'PTS/G',
]

export const goalieStatisticsHockeyColumns = [
  'GP',
  'W',
  'L',
  'T',
  'OTL',
  'GAA',
  'SV%',
  'SO',
  'MP',
  'PiM',
  // 'PPG',
  'SA',
  'SV',
  'GA',
]

export const playerStatisticsSoccerColumns = [
  'MP',
  'Mins',
  'G',
  'A',
  // 'GF',
  'GFA',
  // 'GA',
  // 'GAA',
  'FC',
  'YC',
  'RC',
]

export const keeperStatisticsSoccerColumns = [
  'MP',
  'Mins',
  'GA',
  'GAA',
  'SV%',
  'SV',
  'CS',
  'W',
  'L',
  'D',
]

export const skaterSummaryHockeyColumns = ['GP', 'G', 'A', 'P', 'PiM', 'PPG']

export const goalieSummaryHockeyColumns = [
  'GP',
  'W',
  'L',
  'T',
  'OTL',
  'SO',
  'MP',
  'PiM',
  // 'PPG',
  'SA',
  'SV',
  'GA',
]

export const playerSummarySoccerColumns = [
  'MP',
  'Mins',
  'G',
  'A',
  // 'GA',
  // 'GF',
  'FC',
  'RC',
  'YC',
]

export const keeperSummarySoccerColumns = [
  'MP',
  'Mins',
  'GF',
  'GA',
  'SV',
  'CS',
  'W',
  'L',
  'D',
]
