/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useI18n } from '@shopify/react-i18n'
import colors from '@sportninja/common/constants/appColors'
import dayjs from 'dayjs'
import { toJS } from 'mobx'
import { useLayoutEffect, useState } from 'react'

const DivisionTeamsTotalPaid = ({ item, currentDivision }) => {
  const [totalPaid, setTotalPaid] = useState(0)
  const [currency, setCurrency] = useState({ name: 'cad' })
  const [teamCost, setTeamCost] = useState(0)
  const [hasPaymentDelayed, setHasPaymentDelayed] = useState(false)

  const [i18n] = useI18n()

  useLayoutEffect(() => {
    setCurrency(currentDivision?.registration?.currency)
    setTeamCost(currentDivision?.registration?.team_cost ?? 0)

    item?.billing_schedule?.forEach((el) => {
      if (el.paid) {
        setTotalPaid((prev) => prev + el.subtotal)
      } else {
        const today = dayjs()
        const dueDate = dayjs(el?.due_date)

        if (dueDate.isBefore(today)) {
          setHasPaymentDelayed(true)
        }
      }
    })
  }, [])

  return (
    <div
      css={css`
        min-width: 104px;
        padding-right: 8px;
      `}
    >
      <div
        css={css`
          font-weight: 500;
          font-size: 16px;
        `}
      >
        {i18n.formatCurrency(totalPaid, {
          currency: currency?.name,
          form: 'short',
        })}
      </div>
      <div
        css={css`
          margin-top: 4px;
          position: relative;
          height: 4px;
          border-radius: 2px;
          background-color: ${hasPaymentDelayed
            ? colors.ERROR_LIGHT
            : colors.SUCCESS_GREEN};
        `}
      >
        <div
          css={css`
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: ${(totalPaid / teamCost) * 100}%;
            background-color: ${hasPaymentDelayed ? colors.ERROR : '#048039'};
            border-radius: 2px;
          `}
        />
      </div>
    </div>
  )
}

export default DivisionTeamsTotalPaid
