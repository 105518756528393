import { str } from './utils'

const shootoutOfflineTransformer = (actions, actionType, requestPayload) => {
  let type = '',
    payload = {}

  switch (
    actionType // eslint-disable-line default-case
  ) {
    case str(actions.create.request): {
      type = str(actions.create.success)
      payload = {
        id: requestPayload.id,
        data: {
          id: requestPayload.form.uid,
          home: requestPayload.form.home,
          player: { id: requestPayload.form.player_id },
          goalie: { id: requestPayload.form.goalie_id },
          goal: requestPayload.form.goal,
        },
      }
      break
    }
    case str(actions.update.request): {
      const { goal, player_id, goalie_id } = requestPayload.form

      type = str(actions.update.success)
      payload = {
        id: requestPayload.id,
        shotId: requestPayload.shotId,
        data: {},
      }

      if (typeof goal !== 'undefined') {
        payload.data.goal = goal
      }

      if (player_id) {
        payload.data.player = { id: player_id }
      }

      if (goalie_id) {
        payload.data.goalie = { id: goalie_id }
      }
      break
    }

    case str(actions.delete.request): {
      type = str(actions.delete.success)
      payload = {
        id: requestPayload.id,
        shotId: requestPayload.shotId,
      }
      break
    }

    case str(actions.end.request): {
      type = str(actions.end.success)
      payload = {
        id: requestPayload.id,
      }
      break
    }
  }

  return { type, payload }
}

export default shootoutOfflineTransformer
