const keys = {
  RECENT_SEARCHES: 'sn:recent_searches',
}

export const initialRecentsState = { uid: '', terms: [], items: [] }

export const getRecentSearches = () => {
  try {
    const jsonValue = localStorage.getItem(keys.RECENT_SEARCHES)
    return jsonValue !== null ? JSON.parse(jsonValue) : initialRecentsState
  } catch (e) {
    if (__DEV__) {
      console.error(e)
      return []
    }
  }
}

export const storeRecentSearchTerm = (value) => {
  try {
    const store = getRecentSearches()
    const existingIndex = store?.terms?.findIndex((i) => i === value)

    if (existingIndex > -1) {
      store?.terms?.splice(existingIndex, 1)
    }

    store?.terms?.unshift(value)

    const toJson = {
      ...store,
      terms:
        store?.terms?.length > 10 ? store?.terms?.slice(0, -1) : store?.terms,
    }

    localStorage.setItem(keys.RECENT_SEARCHES, JSON.stringify(toJson))

    return toJson
  } catch (e) {
    if (__DEV__) {
      console.error(e)
    }
    return
  }
}

export const storeRecentSearchItem = (value) => {
  try {
    const store = getRecentSearches()
    const existingIndex = store?.items?.findIndex((i) => i.id === value.id)

    if (existingIndex > -1) {
      store?.items?.splice(existingIndex, 1)
    }

    store?.items?.unshift(value)

    const toJson = {
      ...store,
      items:
        store?.items?.length > 10 ? store?.items?.slice(0, -1) : store?.items,
    }

    localStorage.setItem(keys.RECENT_SEARCHES, JSON.stringify(toJson))

    return toJson
  } catch (e) {
    if (__DEV__) {
      console.error(e)
    }
    return
  }
}

export const clearRecentSearchTerm = (term) => {
  const store = getRecentSearches()
  const existingIndex = store?.terms?.findIndex((i) => i === term)

  if (existingIndex > -1) {
    store?.terms?.splice(existingIndex, 1)
  }

  localStorage.setItem(keys.RECENT_SEARCHES, JSON.stringify(store))
  return store
}

export const clearRecentSearchItem = (id) => {
  const store = getRecentSearches()
  const existingIndex = store?.items?.findIndex((i) => i?.id === id)

  if (existingIndex > -1) {
    store?.items?.splice(existingIndex, 1)
  }

  localStorage.setItem(keys.RECENT_SEARCHES, JSON.stringify(store))
  return store
}

export const clearRecentSearches = (userId) => {
  try {
    localStorage.setItem(
      keys.RECENT_SEARCHES,
      JSON.stringify({ ...initialRecentsState, uid: userId })
    )
  } catch (e) {
    if (__DEV__) {
      console.error(e)
    }
  }
}
