import React from 'react'
import { ConfigProvider, theme } from 'antd'
import type { ThemeConfig } from 'antd'
import colors from '@sportninja/common/constants/appColors'
import { observer } from 'mobx-react-lite'
import { useMobxStore } from 'src/state'

const THEME = (isDarkMode: boolean): ThemeConfig => ({
  algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
  token: {
    colorPrimary: colors.DEFAULT_FLAIR,
    colorPrimaryHover: colors.PRIMARY_LIGHT,
    colorBgContainer: colors.HEADER,
    colorBorder: colors.SOFT_STEEL,
    colorLink: colors.DEFAULT_FLAIR,
    borderRadius: 4,
    borderRadiusLG: 8,
    borderRadiusSM: 4,
    borderRadiusXS: 2,
  },
  components: {
    Button: {
      primaryShadow: 'none',
      boxShadow: 'none',
      boxShadowSecondary: 'none',
      boxShadowTertiary: 'none',
      defaultShadow: 'none',
      controlHeight: 40,
      defaultBg: 'transparent',
      colorPrimaryBg: colors.DEFAULT_FLAIR,
      defaultBorderColor: colors.DEFAULT_FLAIR,
    },
    Input: {
      controlHeight: 40,
      colorBgContainer: 'transparent',
      colorBgContainerDisabled: 'transparent',
      colorTextDisabled: colors.ATTENDANCE_GRAY,
    },
    Select: {
      controlHeight: 40,
      colorBgContainer: 'transparent',
      colorBgContainerDisabled: 'transparent',
      colorTextDisabled: colors.ATTENDANCE_GRAY,
    },
    DatePicker: {
      controlHeight: 40,
      colorBgContainer: 'transparent',
      colorBgContainerDisabled: 'transparent',
      colorTextDisabled: colors.ATTENDANCE_GRAY,
    },
    Popover: {
      colorBgElevated: colors.SOFT_STEEL,
    },
  },
})

interface Props {
  children: React.ReactNode | JSX.Element
}

export const ThemeProvider = observer(({ children }: Props) => {
  const {
    appStore: {
      state: { isDarkMode },
    },
  } = useMobxStore()

  return <ConfigProvider theme={THEME(isDarkMode)}>{children}</ConfigProvider>
})
