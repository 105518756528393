import { produce } from 'immer'
import merge from 'lodash.merge'

import auth from '../actions/auth'
import userActions from '../actions/user'
import team from '../actions/team'
import org from '../actions/org'
import schedule from '../actions/schedule'
import teams from '../actions/teams'
import game from '../actions/game'
import fav from '../actions/fav'
import usersActions from '../actions/users'
import { ENTITY_TYPES } from '../sagas/utils'
import { arrayToObject } from '../utils/utils'
import entityImageReducer from './sub-reducers/entity-image'
import venuesReducer from './sub-reducers/venues'
import usersReducer from './sub-reducers/users'
import { getSharedReducer as feedReducer } from './feed'
import { getSharedReducer as favReducer } from './fav'
import searchReducer from './search'
import { setChildListById, roleToEntity, getImageThumbnailId } from './helpers'
import { prepareOfflineTransformer, str } from './utils'
import parsers, { activePlayerRoster } from './parsers'
import teamOfflineTransformer from './team-offline-handler'

const reducer = (draft, { type: _type, payload: _payload, meta }) => {
  const teamParser = parsers[ENTITY_TYPES.team]

  const { type, payload } = prepareOfflineTransformer(
    _type,
    _payload,
    meta,
    team,
    teamOfflineTransformer,
    draft
  )

  switch (type) {
    // Handle an organization's set of teams here
    case str(org.teams.read.success):
    case str(schedule.teams.read.success):
      return merge(draft, arrayToObject(payload.data, teamParser))

    case str(auth.logout.success):
      return {}

    case str(schedule.teams.create.success):
      draft[payload.data.id] = merge(
        draft[payload.data.id],
        teamParser(payload.data)
      )
      return

    case str(userActions.readRoles.success):
    case str(usersActions.readRoles.success):
      return merge(draft, roleToEntity('team_id', payload, teamParser))

    case str(userActions.readTeams.success):
      return merge(draft, arrayToObject(payload.data, teamParser))

    case str(team.games.read.success):
    case str(schedule.games.read.success):
    case str(org.games.read.success):
    case str(game.readAll.success):
    case str(game.readCustom.success):
    case str(game.readPast.success):
    case str(game.readUpcoming.success):
    case str(game.create.success):
    case str(game.read.success):
    case str(game.readPublic.success):
    case str(game.update.success): {
      const addTeamInfoFromGame = (game) => {
        let homeTeam, visitingTeam
        if (game.homeTeam !== null) {
          homeTeam = parsers[ENTITY_TYPES.team](game.homeTeam)
        } else {
          homeTeam = parsers[ENTITY_TYPES.team]({
            is_placeholder: true,
            ...game.homeTeamSlot,
          })
        }
        if (game.visitingTeam !== null) {
          visitingTeam = parsers[ENTITY_TYPES.team](game.visitingTeam)
        } else {
          visitingTeam = parsers[ENTITY_TYPES.team]({
            is_placeholder: true,
            ...game.visitingTeamSlot,
          })
        }

        if (homeTeam) draft[homeTeam.id] = merge(draft[homeTeam.id], homeTeam)
        if (visitingTeam)
          draft[visitingTeam.id] = merge(draft[visitingTeam.id], visitingTeam)
      }

      if (Array.isArray(payload.data)) payload.data.forEach(addTeamInfoFromGame)
      else addTeamInfoFromGame(payload.data)

      // Don't forget to set the list of games that belong to this particular team
      if (type === str(team.games.read.success)) {
        setChildListById(draft, payload, 'games')
      }

      return
    }
    /* END ACTIONS FROM OTHER REDUCERS */

    case str(teams.readBySchedule.success):
      // Response data is an array of orgs, containing teams
      payload.data.forEach((org) => {
        if (!org.teams) return
        merge(draft, arrayToObject(org.teams), teamParser)
      })
      return

    case str(team.create.success):
      draft[payload.data.id] = teamParser(payload.data)
      return

    case str(team.read.success):
    case str(team.update.success): {
      draft[payload.id] = merge(draft[payload.id], teamParser(payload.data))
      return
    }

    case str(team.delete.success):
      delete draft[payload.id]
      return

    case str(team.roster.readPlayers.success): {
      draft[payload.id] = merge(
        draft[payload.id],
        parsers.teamPlayerRoster(payload.data)
      )
      return
    }

    case str(team.roster.readTeamOfficials.success): {
      draft[payload.id].officials = draft[payload.id].officials || []
      merge(
        draft[payload.id].officials,
        parsers.teamOfficial(payload?.data?.team_officials)
      )

      draft[payload.id].officialRosterId = payload?.data?.id
      return
    }

    case str(team.roster.teamOfficial.create.success): {
      draft[payload.id] = draft[payload.id] || {}
      draft[payload.id].officials = draft[payload.id].officials || []
      draft[payload.id].officials.push(parsers.teamOfficial(payload.data))
      return
    }

    case str(team.roster.teamOfficial.update.success): {
      const { data, id, teamOfficialId } = payload

      draft[id] = draft[id] || {}
      draft[id].officials = draft[id].officials || []
      let teamOfficial = draft[id].officials.find(
        (o) => o.id === teamOfficialId
      )

      if (teamOfficial)
        teamOfficial = merge(teamOfficial, parsers.teamOfficial(data))
      else draft[id].officials.push(parsers.teamOfficial(data))

      return
    }

    case str(team.roster.teamOfficial.delete.success): {
      const teamOfficials = draft[payload.id].officials

      if (Array.isArray(teamOfficials)) {
        const teamOfficialIndex = teamOfficials.findIndex(
          (p) => p.id === payload.teamOfficialId
        )
        if (teamOfficialIndex > -1) teamOfficials.splice(teamOfficialIndex, 1)
      }

      return
    }

    case str(team.roster.player.create.success): {
      const newPlayerRoster = activePlayerRoster({
        players: [payload.data],
      })

      draft[payload.id] = draft[payload.id] || {}
      draft[payload.id].roster = draft[payload.id].roster || []

      let playerIdx = draft[payload.id].roster.findIndex(
        (player) => player.id === payload.data.id
      )

      if (playerIdx !== -1) {
        draft[payload.id].roster.splice(playerIdx, 1)
      }

      draft[payload.id].roster.push(newPlayerRoster.roster[0])
      draft[payload.id].playerCache = merge(
        draft[payload.id].playerCache,
        newPlayerRoster.cache
      )
      return
    }

    case str(team.roster.player.update.success): {
      const newPlayerRoster = activePlayerRoster({
        players: [payload.data],
      })

      draft[payload.id] = draft[payload.id] || {}
      draft[payload.id].roster = draft[payload.id].roster || []

      let existingPlayer = draft[payload.id].roster.find(
        (player) => player.id === payload.data.id
      )
      if (existingPlayer) {
        existingPlayer = merge(existingPlayer, newPlayerRoster.roster[0])
      } else {
        draft[payload.id].roster.push(newPlayerRoster.roster[0])
      }

      draft[payload.id].playerCache = merge(
        draft[payload.id].playerCache,
        newPlayerRoster.cache
      )
      return
    }

    case str(team.roster.player.delete.success): {
      draft[payload.id] = draft[payload.id] || {}

      if (draft[payload.id].hasOwnProperty('roster')) {
        const playerIndex = draft[payload.id].roster.findIndex(
          (p) => p.id === payload.playerId
        )
        if (playerIndex > -1) draft[payload.id].roster.splice(playerIndex, 1)
      }

      if (draft[payload.id].hasOwnProperty('playerCache')) {
        delete draft[payload.id].playerCache[payload.playerId]
      }

      return
    }

    case str(team.roster.player.image.create.success): {
      draft[payload.teamId] = draft[payload.teamId] || {}
      draft[payload.teamId].roster = draft[payload.teamId].roster || []

      let existingPlayer = draft[payload.teamId].roster.find(
        (player) => player.id === payload.playerId
      )
      if (existingPlayer) {
        existingPlayer.imageId = getImageThumbnailId({ image: payload.data })
      }
      return
    }

    case str(team.roster.player.image.delete.success): {
      draft[payload.teamId] = draft[payload.teamId] || {}
      draft[payload.teamId].roster = draft[payload.teamId].roster || []

      let existingPlayer = draft[payload.teamId].roster.find(
        (player) => player.id === payload.playerId
      )
      if (existingPlayer) {
        delete existingPlayer.imageId
      }
      return
    }

    case str(team.schedules.read.success):
      return setChildListById(draft, payload, 'schedules')
  }
}

export default (state = {}, action) =>
  produce(state, (draft) => {
    entityImageReducer(team.image)(draft, action)
    venuesReducer(team.venues)(draft, action)
    usersReducer(team.users)(draft, action)
    searchReducer(ENTITY_TYPES.team)(draft, action)
    favReducer(fav.team, ENTITY_TYPES.team)(draft, action)
    feedReducer(team.feed)(draft, action)

    return reducer(draft, action)
  })
