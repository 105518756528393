import styled from '@emotion/styled/macro'
import isPropValid from '@emotion/is-prop-valid'

import { Flex } from '../Layout'
import { backgroundColorLight, font, loadingSpinner, zIndex } from '../css'

export const PANEL_HEIGHT = 240
export const PANEL_WIDTH = 192
export const PANEL_FADE_DURATION_MS = 200

export const Panel = styled(Flex)`
  width: ${PANEL_WIDTH}px;
  position: absolute;

  border-radius: 4px;
  box-shadow: 0 2px 10px 0 #0e0f11;
  background-color: ${backgroundColorLight};
  z-index: ${zIndex.overlay};

  ${(props) => (props.fitsBottom ? 'top: calc(100% + 0px)' : 'bottom: 100%')};
  right: ${(props) => !props.fitsRight && '0px'};
  left: ${(props) => props.fitsRight && '0px'};

  transition: opacity ${PANEL_FADE_DURATION_MS}ms;
  opacity: 0;

  /* CSSTransition classes */
  &.panel-enter-active,
  &.panel-enter-done {
    opacity: 1;
  }
`

export const Button = styled('button', {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'loading',
})`
  height: 60px;

  color: #fff;
  ${font.body}
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1.64px;
  line-height: 20px;
  text-align: left;
  text-transform: uppercase;
  transition: background 0.1s;

  padding-left: 20px;

  &:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  &[disabled] {
    opacity: 0.5;
    cursor: initial;
  }

  :hover:not([disabled]) {
    background-color: rgba(255, 255, 255, 0.1);
  }

  ${(props) => props.loading && loadingSpinner};
`

export const CircleSpan = styled.span`
  display: inline-block;
  border-radius: 50%;
  height: 4px;
  width: 4px;
  background-color: white;

  margin: 0 2px;

  transition: background-color 0.2s ease-in-out;
`
