import css from '@emotion/css/macro'
import { keyframes } from '@emotion/react'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'

export const backgroundColor = '#181A1D'
export const backgroundColorRgb = '24, 26, 29'
export const backgroundColorLight = '#282E38'
export const borderColor = 'rgba(255, 255, 255, 0.1)'
export const border = `1px solid ${borderColor};`
export const cardColor = 'rgba(255, 255, 255, 0.05)'
export const flairColor = isCanlan ? '#004fff' : '#FFA300'
export const flairColorLight = isCanlan ? '#007fff' : '#FEE84E'
export const flairColorRgb = isCanlan ? '0, 79, 255' : '255, 255, 255'
export const flairColorLightRgb = isCanlan ? '79, 134, 255' : '254, 232, 78'
export const textColor = {
  grey: '#A5A5A6',
}

const sportninjaFont = {
  title: css`
    font-family: 'Rift', Arial, Helvetica, sans-serif;
  `,
  body: css`
    font-family: 'Inter', Arial, Helvetica, sans-serif;
  `,
}

const canlanFont = {
  title: css`
    font-family: 'Neue Plak Condensed', Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
  `,
  body: css`
    font-family: 'Gilroy', Arial, Helvetica, sans-serif;
  `,
}

export const font = isCanlan ? canlanFont : sportninjaFont

export const errorColor = colors.ERROR_LIGHT

export let MENU_HEIGHT = 50
export const setMenuHeight = (newHeight) => {
  MENU_HEIGHT = newHeight
}
export const menuHeight = `${MENU_HEIGHT}px`

export const linkStyle = css`
  text-decoration: underline;
  cursor: pointer;
`

// z-indexes begin at 1000 because of react-leaflet :(
const Z_INDEX_BASE = 1000
export const zIndex = {
  base: Z_INDEX_BASE,
  menu: Z_INDEX_BASE + 10,
  header: Z_INDEX_BASE + 20,
  overlay: Z_INDEX_BASE + 30,
  modal: Z_INDEX_BASE + 40,
}

const loader = keyframes`
  from { transform: rotate(0deg);   }
  to   { transform: rotate(359deg); }
`

export const loadingSpinner = css`
  color: transparent !important;
  cursor: default;
  position: relative;

  &:after {
    position: absolute;
    content: '';
    height: 1em;
    width: 1em;
    left: calc(50% - (1em / 2));
    top: calc(50% - (1em / 2));

    border: 2px rgba(${flairColorRgb}, 0.25) solid;
    border-top: 2px rgba(${flairColorRgb}, 1) solid;
    border-radius: 50%;
    animation: ${loader} 800ms infinite linear;
  }
`

export const downArrowCss = css`
  position: absolute;
  right: 12px;
  top: calc(50% - 3px);
  font-size: 10px;
  pointer-events: none;
  color: rgba(255, 255, 255, 0.8);
  *:hover ~ & {
    color: unset;
  }
`

export const weightRegular = css`
  font-weight: 400;
`

export const weightMedium = css`
  font-weight: 500;
`

export const weightBold = css`
  font-weight: 700;
`

export const weightSemiBold = css`
  font-weight: 600;
`
export const displayLarge = css`
  ${font.title}
  font-size: 56px;
  line-height: 100%;
`

export const displaySmall = css`
  ${font.title}
  font-size: 48px;
  line-height: 100%;
`
export const heading1 = css`
  ${font.title}
  font-size: 40px;
  line-height: 100%;
`
export const heading2 = css`
  ${font.title}
  font-size: 36px;
  line-height: 100%;
`
export const heading3 = css`
  ${font.title}
  font-size: 32px;
  line-height: 120%;
`

export const heading4 = css`
  ${font.title}
  font-size: 28px;
  line-height: 120%;
`
export const heading5 = css`
  ${font.title}
  font-size: 24px;
  line-height: 120%;
`

export const heading6 = css`
  ${font.title}
  font-size: 20px;
  line-height: 120%;
`

export const paragraphLarge = css`
  ${font.body}
  font-size: 18px;
  line-height: 145%;
`

export const paragraphMedium = css`
  ${font.body}
  font-size: 16px;
  line-height: 145%;
`

export const paragraphSmall = css`
  ${font.body}
  font-size: 14px;
  line-height: 145%;
`

export const paragraphXSmall = css`
  ${font.body}
  font-size: 12px;
  line-height: 145%;
`

export const captionLarge = css`
  ${font.body}
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 12%;
  font-weight: 600;
  text-transform: uppercase;
`

export const captionSmall = css`
  ${font.body}
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 12%;
  font-weight: 600;
  text-transform: uppercase;
`

export const captionXSmall = css`
  ${font.body}
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 12%;
  font-weight: 600;
  text-transform: uppercase;
`

export const xxsmallSoftShadow = css`
  box-shadow: 0px 1.5px 4px -1px rgba(16, 25, 40, 0.07);
`

export const xsmallSoftShadow = css`
  box-shadow: 0px 5px 13px -5px rgba(16, 25, 40, 0.05),
    0px 2px 4px -1px rgba(16, 25, 40, 0.02);
`

export const smallSoftShadow = css`
  box-shadow: 0px 10px 18px -2px rgba(16, 25, 40, 0.07);
`

export const mediumSoftShadow = css`
  box-shadow: 0px 14px 22px -9px rgba(16, 25, 40, 0.14),
    0px 0px 3px -1px rgba(16, 25, 40, 0.04);
`

export const largeSoftShadow = css`
  box-shadow: 0px 16px 24px -4px rgba(16, 25, 40, 0.08),
    0px 4px 6px -2px rgba(16, 25, 40, 0.03);
`

export const xlargeSoftShadow = css`
  box-shadow: 0px 24px 32px -4px rgba(16, 25, 40, 0.08),
    0px 8px 8px -4px rgba(16, 25, 40, 0.03);
`

export const xxlargeSoftShadow = css`
  box-shadow: 0px 32px 54px -12px rgba(16, 25, 40, 0.18);
`
export const xxxlargeSoftShadow = css`
  box-shadow: 0px 40px 72px -12px rgba(16, 25, 40, 0.14);
`

export const xxSmallHardShadow = css`
  box-shadow: 0px 2px 5px -2px rgba(16, 25, 40, 0.06),
    0px 2px 7px 0px rgba(16, 25, 40, 0.05),
    0px 0px 0px 1px rgba(16, 25, 40, 0.05);
`

export const xSmallHardShadow = css`
  box-shadow: 0px 2px 12px -1px rgba(16, 25, 40, 0.1),
    0px 2px 2px -1px rgba(16, 25, 40, 0.04),
    0px 0px 0px 1px rgba(16, 25, 40, 0.05);
`

export const smallHardShadow = css`
  box-shadow: 0px 6px 16px 0px rgba(16, 25, 40, 0.08),
    0px 0px 0px 1px rgba(16, 25, 40, 0.05);
`

export const mediumHardShadow = css`
  box-shadow: 0px 16px 24px -6px rgba(16, 25, 40, 0.08),
    0px 0px 3px -1px rgba(16, 25, 40, 0.04),
    0px 0px 0px 1px rgba(16, 25, 40, 0.05);
`

export const blurXSmall = css`
  backdrop-filter: blur(1px);
`

export const blurSmall = css`
  backdrop-filter: blur(2px);
`

export const blurMedium = css`
  backdrop-filter: blur(4px);
`

export const blurLarge = css`
  backdrop-filter: blur(8px);
`

export const blurXLarge = css`
  backdrop-filter: blur(10px);
`
export const tableStyles = css`
  .ant-table-content {
    th {
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 100%;
      color: ${colors.WHITE};
      background-color: transparent !important;
    }
    tbody > tr {
      font-size: 16px;
      font-weight: 500;
      line-height: 100%;
      color: ${colors.WHITE};
    }
    td {
      border-bottom: 1px solid ${'#4C535F'} !important;
    }
  }
`
