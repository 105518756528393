/** @jsxImportSource @emotion/react */
import {
  CheckOutlined,
  ClockCircleOutlined,
  DownOutlined,
  RightOutlined,
  UserOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { formatCurrency, formatDate } from '@sportninja/common/utils/utils'
import { Alert, Image, Input, QRCode, Tooltip } from 'antd'
import { useContext, useState } from 'react'
import { EventCard } from 'src/components/RegistrationEventCard'
import { EventProgress } from 'src/components/RegistrationEventProgress'
import { RegistrationContext } from '../RegistrationContext'
import {
  PageTitle,
  buttonStyle,
  headingSixStyle,
  headingStyle,
  paragraphMediumStyle,
  paragraphSmallBoldStyle,
  paragraphSmallStyle,
} from './Main'
import { ROUTES } from '@sportninja/common/constants/app'

async function updateTeamLogo(teamId, base64) {
  const res = await req(`/teams/${teamId}/image`, {
    method: 'POST',
    body: base64,
  })

  return res
}

const NextContent = ({ title, content, route }) => {
  const [isOpened, setIsOpened] = useState(false)
  if (route) {
    return (
      <a
        href={route}
        target='_blank'
        rel='noreferrer'
        css={css`
          display: flex;
          padding: 8px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          border-radius: 8px;
          background: ${colors.VERY_DARK_GRAYISH_BLUE};
          flex-direction: column;
          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        <div
          onClick={() => setIsOpened(!isOpened)}
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            cursor: pointer;
            @media (max-width: 768px) {
              width: 100%;
            }
          `}
        >
          {content ? (
            <>
              {isOpened ? (
                <DownOutlined
                  css={css`
                    color: ${colors.PRIMARY};
                  `}
                />
              ) : (
                <RightOutlined
                  css={css`
                    color: ${colors.PRIMARY};
                  `}
                />
              )}
            </>
          ) : (
            <RightOutlined
              css={css`
                color: ${colors.PRIMARY};
              `}
            />
          )}
          <p
            css={css`
              ${paragraphMediumStyle}
              color: ${colors.PRIMARY};
            `}
          >
            {title}
          </p>
        </div>
        {isOpened && content ? <>{content}</> : null}
      </a>
    )
  }
  return (
    <div
      css={css`
        display: flex;
        padding: 8px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        background: ${colors.VERY_DARK_GRAYISH_BLUE};
        flex-direction: column;
        @media (max-width: 768px) {
          width: 100%;
        }
      `}
    >
      <div
        onClick={() => setIsOpened(!isOpened)}
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          cursor: pointer;
          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        {isOpened ? (
          <DownOutlined
            css={css`
              color: ${colors.PRIMARY};
            `}
          />
        ) : (
          <RightOutlined
            css={css`
              color: ${colors.PRIMARY};
            `}
          />
        )}
        <p
          css={css`
            ${paragraphMediumStyle}
            color: ${colors.PRIMARY};
          `}
        >
          {title}
        </p>
      </div>
      {isOpened && content ? <>{content}</> : null}
    </div>
  )
}

const accept = 'image/*'

const RegisterReview = () => {
  const {
    division,
    selectedTeam,
    registration,
    session,
    email,
    deleteSession,
  } = useContext(RegistrationContext)

  const {
    team_name,
    division_name,
    subtotal,
    paid,
    team_id,
    billing_schedule,
    root_schedule_id,
  } = session
  const [installmentScheduleOpen, setInstallmentScheduleOpen] = useState(false)
  const iosAppLink = isCanlan
    ? 'https://apps.apple.com/ca/app/canlan-sports-stats/id1606251092'
    : 'https://apps.apple.com/br/app/sportninja/id1436064312'
  const androidAppLink = isCanlan
    ? 'https://play.google.com/store/apps/details?id=com.sportninjainc.canlansports'
    : 'https://play.google.com/store/apps/details?id=com.sportninjainc.sportninja'
  const [newTeamImage, setNewTeamImage] = useState(null)
  const [newTeamLoading, setNewTeamLoading] = useState(false)
  const [newTeamError, setNewTeamError] = useState(null)
  const [newTeamSuccess, setNewTeamSuccess] = useState(null)

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        background-color: ${colors.REGISTRATION_SECOND_BACKGROUND};
        width: 1080px;
        padding: 0 35px 35px 35px;
        border-radius: 16px 16px 0px 0px;
        @media (max-width: 768px) {
          min-width: 0;
          width: 100%;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          margin-top: 16px;
          margin-bottom: -8px;
        `}
      >
        <a
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
          `}
          href={`${ROUTES.PROFILE.ROOT}`}
          target='_blank'
          rel='noreferrer'
        >
          <UserOutlined
            css={css`
              color: ${colors.PRIMARY};
            `}
          />
          <p
            css={css`
              ${paragraphSmallStyle}
              color: ${colors.PRIMARY};
            `}
          >
            {email}{' '}
          </p>
        </a>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
          `}
          onClick={() => {
            deleteSession()
            window.location.reload()
          }}
        >
          <PlusCircleOutlined
            css={css`
              color: ${colors.PRIMARY_300};
            `}
          />
          <p
            css={css`
              ${paragraphSmallStyle}
              color: ${colors.PRIMARY};
            `}
          >
            Register a New Team
          </p>
        </div>
      </div>
      <EventCard eventInfo={registration} />
      <EventProgress eventProgress={5} />
      <PageTitle
        title='Registration Submitted'
        subtitle={
          "Congratulations! Your account has been successfully verified. We've also emailed you the registration status and the receipt for your payment."
        }
      />
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
          /* change the flex direction on mobile */
          @media (max-width: 768px) {
            flex-direction: column;
            width: 100%;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
            @media (max-width: 768px) {
              width: 100%;
              align-items: center;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              padding: 16px;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              border-radius: 8px;
              border: 1px solid ${colors.VERY_DARK_GRAYISH_BLUE};
              margin-bottom: 16px;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 8px;
              `}
            >
              <Image
                src={
                  getImageThumbnailId(selectedTeam) ||
                  '/images/generic_placeholders/placeholder_team.png'
                }
                // eslint-disable-next-line react-native/no-inline-styles
                style={{
                  width: 24,
                  height: 24,
                  borderRadius: 4,
                  padding: 2,
                  backgroundColor: 'white',
                }}
                onError={(e) => {
                  e.target.onerror = null
                  e.target.src =
                    '/images/generic_placeholders/placeholder_competition.png'
                }}
              />
              <p
                css={css`
                  ${paragraphMediumStyle}
                  overflow: hidden;
                  color: ${colors.WHITE};
                  text-overflow: ellipsis;
                `}
              >
                {team_name}
              </p>
            </div>
            <p
              css={css`
                ${paragraphSmallBoldStyle}
                overflow: hidden;
                color: ${colors.WHITE};
                text-overflow: ellipsis;
                overflow: hidden;
              `}
            >
              Division:{' '}
              <span
                css={css`
                  ${paragraphSmallStyle}
                  overflow: hidden;
                  color: ${colors.WHITE};
                  text-overflow: ellipsis;
                `}
              >
                {division_name}
              </span>
            </p>
            <p
              css={css`
                ${paragraphSmallBoldStyle}
                overflow: hidden;
                color: ${colors.WHITE};
                text-overflow: ellipsis;
              `}
            >
              Cost Per Team:{' '}
              <span
                css={css`
                  ${paragraphSmallStyle}
                  overflow: hidden;
                  color: ${colors.WHITE};
                  text-overflow: ellipsis;
                `}
              >
                {formatCurrency(division?.team_cost, division?.currency?.name)}
              </span>
            </p>
            {subtotal ? (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                `}
              >
                <p
                  css={css`
                    ${paragraphSmallBoldStyle}
                    overflow: hidden;
                    color: ${colors.WHITE};
                    text-overflow: ellipsis;
                  `}
                >
                  Deposit:{' '}
                  <span
                    css={css`
                      ${paragraphSmallStyle}
                      overflow: hidden;
                      color: ${colors.WHITE};
                      text-overflow: ellipsis;
                    `}
                  >
                    {formatCurrency(subtotal, division?.currency?.name)}
                  </span>
                </p>
                {paid ? (
                  <div
                    css={css`
                      display: flex;
                      padding: 2px 8px;
                      align-items: center;
                      gap: 4px;
                      border-radius: 4px;
                      border: 1px solid ${colors.GREEN_APPLE};
                    `}
                  >
                    <CheckOutlined
                      css={css`
                        color: ${colors.GREEN_APPLE};
                      `}
                    />
                    <p
                      css={css`
                        ${paragraphSmallStyle}
                        color: ${colors.GREEN_APPLE}
                      `}
                    >
                      Paid
                    </p>
                  </div>
                ) : (
                  <Tooltip
                    title='Your payment is currently being processed. You should receive a confirmation email within a few minutes.'
                    placement='right'
                  >
                    <div
                      css={css`
                        display: flex;
                        padding: 2px 8px;
                        align-items: center;
                        gap: 4px;
                        border-radius: 4px;
                        border: 1px solid ${colors.AMERICAN_YELLOW};
                        cursor: help;
                      `}
                    >
                      <ClockCircleOutlined
                        css={css`
                          color: ${colors.AMERICAN_YELLOW};
                        `}
                      />
                      <p
                        css={css`
                          ${paragraphSmallStyle}
                          color: ${colors.AMERICAN_YELLOW}
                        `}
                      >
                        Pending
                      </p>
                    </div>
                  </Tooltip>
                )}
              </div>
            ) : null}
          </div>
          {billing_schedule && billing_schedule.length > 0 ? (
            <div
              css={css`
                display: flex;
                padding: 8px;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;
                border-radius: 8px;
                background: ${colors.VERY_DARK_GRAYISH_BLUE};
                margin-bottom: 16px;
                /* on mobile the gap should be 8 */
                @media (max-width: 768px) {
                  gap: 8px;
                }
              `}
            >
              {/* Title */}
              <div
                onClick={() =>
                  setInstallmentScheduleOpen(!installmentScheduleOpen)
                }
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  cursor: pointer;
                `}
              >
                {installmentScheduleOpen ? (
                  <DownOutlined
                    size={24}
                    css={css`
                      color: ${colors.WHITE};
                    `}
                  />
                ) : (
                  <RightOutlined
                    size={24}
                    css={css`
                      color: ${colors.WHITE};
                    `}
                  />
                )}
                <p
                  css={css`
                    ${paragraphMediumStyle}
                    color: ${colors.WHITE}
                  `}
                >
                  Payment Schedule
                </p>
              </div>
              {installmentScheduleOpen ? (
                <div
                  css={css`
                    width: 100%;
                    height: 1px;
                    background: ${colors.CHARADE};
                  `}
                />
              ) : null}
              {installmentScheduleOpen &&
                billing_schedule?.map((b, index) => (
                  <div
                    key={index}
                    css={css`
                      display: flex;
                      padding: 0px 8px;
                      flex-direction: column;
                      justify-content: center;
                      align-items: flex-start;
                      gap: 8px;
                      align-self: stretch;
                    `}
                  >
                    <p
                      css={css`
                        ${paragraphSmallStyle}
                        color: ${colors.WHITE}
                      `}
                    >
                      <span
                        css={css`
                          ${paragraphSmallBoldStyle}
                        `}
                      >
                        {b?.payment_type === 'deposit' ? 'Deposit' : 'Payment'}{' '}
                        {b?.payment_type !== 'deposit' ? index : ''}:{' '}
                      </span>
                      {formatCurrency(b?.subtotal, division?.currency?.name)}{' '}
                      due {formatDate(b?.due_date)}
                    </p>
                  </div>
                ))}
              {installmentScheduleOpen ? (
                <>
                  <div
                    css={css`
                      width: 100%;
                      height: 1px;
                      background: ${colors.CHARADE};
                    `}
                  />
                  <p
                    css={css`
                      ${paragraphSmallStyle}
                      color: ${colors.STORM_GRAY}
                    `}
                  >
                    All monthly payments will now be paid online.
                  </p>
                </>
              ) : null}
            </div>
          ) : null}
        </div>
        <div
          css={css`
            background: ${colors.VERY_DARK_GRAYISH_BLUE};
            min-height: 300px;
            width: 1px;
            /* change the orientation no mobile */
            @media (max-width: 768px) {
              width: 100%;
              height: 1px;
              min-height: 0;
            }
          `}
        />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            flex: 1 0 0;
            @media (max-width: 768px) {
              width: 100%;
            }
          `}
        >
          <p
            css={css`
              ${headingStyle}
              color: ${colors.WHITE};
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
            `}
          >
            {"What's Next?"}
          </p>
          <NextContent
            title='Download the Mobile App'
            content={
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  align-items: flex-start;
                  @media (max-width: 768px) {
                    width: 100%;
                  }
                `}
              >
                <p
                  css={css`
                    ${paragraphMediumStyle}
                    color: ${colors.WHITE};
                  `}
                >
                  Download the mobile app to receive notifications and updates
                  on your registration.
                </p>
                <div
                  css={css`
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                    `}
                  >
                    <QRCode
                      value={androidAppLink}
                      bgColor={colors.HEADER}
                      bordered={true}
                      css={css`
                        margin-bottom: 4px;
                      `}
                    />
                    <p
                      css={css`
                        ${paragraphSmallStyle}
                        color: ${colors.WHITE};
                      `}
                    >
                      Android
                    </p>
                  </div>
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                    `}
                  >
                    <QRCode
                      bgColor={colors.HEADER}
                      bordered={true}
                      value={iosAppLink}
                      css={css`
                        margin-bottom: 4px;
                      `}
                    />
                    <p
                      css={css`
                        ${paragraphSmallStyle}
                        color: ${colors.WHITE};
                      `}
                    >
                      iOS
                    </p>
                  </div>
                </div>
              </div>
            }
          />
          <NextContent
            title='Update Team Logo'
            content={
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  align-items: flex-start;
                  width: 100%;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: flex-end;
                    gap: 8px;
                    flex: 1 0 0;
                    align-self: stretch;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      gap: 4px;
                      flex: 1 0 0;
                    `}
                  >
                    <Input
                      name='teamLogo'
                      placeholder=''
                      autoComplete='off'
                      autoCorrect='off'
                      autoCapitalize='none'
                      type='file'
                      id='team-logo'
                      accept={accept}
                      onChange={(e) => setNewTeamImage(e.target.files[0])}
                      css={css`
                        cursor: pointer;
                      `}
                      disabled={newTeamLoading || newTeamSuccess}
                    />
                  </div>
                  {newTeamImage ? (
                    <div
                      css={css`
                        margin-bottom: 2px;
                        border: 1px solid ${colors.ATTENDANCE_GRAY};
                        border-radius: 4px;
                        width: 40px;
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 4px;
                      `}
                    >
                      <Image
                        src={URL.createObjectURL(newTeamImage)}
                        preview={true}
                      />
                    </div>
                  ) : null}
                </div>
                {newTeamImage && !newTeamSuccess ? (
                  <button
                    css={css`
                      ${buttonStyle}
                      ${headingSixStyle}
                      width: 100%;
                      cursor: pointer;
                      align-self: stretch;
                    `}
                    onClick={async () => {
                      setNewTeamLoading(true)
                      setNewTeamError(null)
                      setNewTeamSuccess(null)
                      try {
                        await updateTeamLogo(team_id, newTeamImage)
                        setNewTeamSuccess('Team logo updated successfully')
                      } catch (e) {
                        setNewTeamError(
                          e?.message ||
                            'An error occurred while updating the team logo'
                        )
                      } finally {
                        setNewTeamLoading(false)
                      }
                    }}
                    disabled={newTeamLoading}
                  >
                    Upload logo
                  </button>
                ) : null}
                {newTeamSuccess ? (
                  <Alert
                    message={'Logo Uploaded Successfully'}
                    type='success'
                    showIcon
                    css={css`
                      width: 100%;
                    `}
                  />
                ) : null}
                {newTeamError ? (
                  <Alert
                    message={newTeamError || 'An error occurred'}
                    type='error'
                    showIcon
                    css={css`
                      width: 100%;
                    `}
                  />
                ) : null}
              </div>
            }
          />
          <NextContent
            route={`${ROUTES.TEAM_ROOT}/${team_id}/roster`}
            title='Go To My Team'
            content={null}
          />
          <NextContent
            route={`${ROUTES.SCHEDULE_ROOT}/${root_schedule_id}/standings`}
            title='Go To My Competition'
            content={null}
          />
        </div>
      </div>
    </div>
  )
}

export default RegisterReview
