import { combineReducers } from 'redux'

import game from './game'
import org from './org'
import schedule from './schedule'
import team from './team'
import users from './users'
import auth from './auth'
import fav from './fav'
import types from './types'
import feed from './feed'
import notifications from './notifications'
import appError from './appError'
import offline from './offline'
import shootout from './shootout'
import app from './app'
import chat from './chat-notifications'

export default combineReducers({
  game,
  org,
  schedule,
  team,
  users,
  auth,
  fav,
  types,
  feed,
  notifications,
  appError,
  app,
  offline,
  shootout,
  chat,
})
