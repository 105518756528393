/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import colors from '@sportninja/common/constants/appColors'
import cn from 'classnames'
import { ROUTES } from '@sportninja/common/constants/app'

import Select from '../../../components/Select'
import DatePicker from '../../../components/DatePicker'
import { Flex } from '../../../components/Layout'
import Picture from '../../../components/Picture'
import Icon from '../../../components/Icon'
import SuspensionSubmitButton from '../../../components/Suspensions/SuspensionSubmitButton'
import { font } from '../../../components/css'
import {
  GAME_SLOT_STATUS,
  ROUND_TYPE,
  TOURNAMENT_STATUS,
} from '../ScheduleBracket/constants'
import { IS_DEVELOPMENT } from '../../../utils/utils'
import Tooltip from '../../../components/StatisticsTable/Tooltip'
import { isCanlan } from '@sportninja/common/utils/customer-name'

const NewGameTeamSlot = ({
  homeDidWin,
  visitingDidWin,
  homeImageId,
  visitingImageId,
  homeTeamName,
  visitingTeamName,
  isEnded,
  homeTeamScore = 0,
  visitingTeamScore = 0,
  disabled = false,
  onPressTeamModal = () => {},
  game,
}) => {
  return (
    <td className='team'>
      <div
        css={css`
          margin-top: 2px;
          margin-bottom: 2px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            cursor: ${'pointer'};
          `}
          onClick={() => {
            const data = {
              game: game,
              isHome: true,
              ...game?.homeTeamSlot,
            }
            onPressTeamModal(data)
          }}
          onMouseEnter={() => {
            const element = document.getElementById(
              `home-team-name-${game?.id}`
            )
            if (element) {
              element.style.color = colors.PRIMARY
            }
          }}
          onMouseLeave={() => {
            const element = document.getElementById(
              `home-team-name-${game?.id}`
            )
            if (element) {
              element.style.color = homeDidWin
                ? 'var(--Neutrals-White, #fff)'
                : 'var(--Neutrals-White, #fff)'
            }
          }}
        >
          <Picture
            square
            size='xxxsmall'
            imageId={homeImageId}
            css={css`
              margin: 6px;
            `}
            iconName='user-friends'
          />
          <div
            css={css`
              display: flex;
              flex-direction: column;
              width: 160px;
            `}
          >
            <span
              id={`home-team-name-${game?.id}`}
              css={css`
                color: #fff;
                font-family: 'Maison Neue', sans-serif !important;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%; // Adjust this value as needed
                display: block; // Or inline-block, depending on your layout
                /* text-decoration: ${'underline'}; */
              `}
            >
              {homeTeamName}
            </span>
            <span
              css={css`
                overflow: hidden;
                color: ${colors.ATTENDANCE_GRAY};
                text-overflow: ellipsis;
                font-family: 'Maison Neue', sans-serif !important;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              `}
            >
              Home
            </span>
          </div>
          <span
            css={css`
              color: ${homeDidWin
                ? colors.PRIMARY
                : 'var(--Neutrals-White, #fff)'};
              font-family: ${isCanlan
                ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                : 'Rift, Arial, Helvetica, sans-serif'};
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 100%; /* 18px */
              letter-spacing: 0.9px;
              text-transform: uppercase;
            `}
          >
            {homeTeamScore?.toString()}
          </span>
        </div>
        {/* Separator */}
        <div
          css={css`
            /* height: 2px; */
          `}
        />
        {/* Visiting Row */}
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            cursor: ${'pointer'};
          `}
          onClick={() => {
            const data = {
              game: game,
              isHome: false,
              ...game?.visitingTeamSlot,
            }
            onPressTeamModal(data)
          }}
          onMouseEnter={() => {
            const element = document.getElementById(
              `visiting-team-name-${game?.id}`
            )
            if (element) {
              element.style.color = colors.PRIMARY
            }
          }}
          onMouseLeave={() => {
            const element = document.getElementById(
              `visiting-team-name-${game?.id}`
            )
            if (element) {
              element.style.color = visitingDidWin
                ? 'var(--Neutrals-White, #fff)'
                : 'var(--Neutrals-White, #fff)'
            }
          }}
        >
          <Picture
            square
            size='xxxsmall'
            imageId={visitingImageId}
            css={css`
              margin: 6px;
            `}
            iconName='user-friends'
          />
          <div
            css={css`
              display: flex;
              flex-direction: column;
              width: 160px;
            `}
          >
            <span
              id={`visiting-team-name-${game?.id}`}
              css={css`
                color: #fff;
                font-family: 'Maison Neue', sans-serif !important;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%; // Adjust this value as needed
                display: block; // Or inline-block, depending on your layout
                /* text-decoration: ${'underline'}; */
              `}
            >
              {visitingTeamName}
            </span>
            <span
              css={css`
                overflow: hidden;
                color: ${colors.ATTENDANCE_GRAY};
                text-overflow: ellipsis;
                font-family: 'Maison Neue', sans-serif !important;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              `}
            >
              Visitor
            </span>
          </div>
          <span
            css={css`
              color: ${visitingDidWin
                ? colors.PRIMARY
                : 'var(--Neutrals-White, #fff)'};
              font-family: ${isCanlan
                ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                : 'Rift, Arial, Helvetica, sans-serif'};
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 100%; /* 18px */
              letter-spacing: 0.9px;
              text-transform: uppercase;
            `}
          >
            {visitingTeamScore?.toString()}
          </span>
        </div>
      </div>
    </td>
  )
}

const GameSlotTeamCell = ({
  didWin,
  imageId,
  teamName,
  isEnded,
  teamScore,
  isHome,
}) => {
  return (
    <td className='team'>
      <Flex
        flexDirection={isHome ? 'row' : 'row-reverse'}
        // noFlex
        alignItems='center'
        justifyContent='flex-end'
        css={css`
          width: 200px;
        `}
      >
        <Flex
          css={css`
            text-align: ${isHome ? 'right' : 'left'};
            margin: 0 4px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 14px;
            text-wrap: wrap;
          `}
        >
          {teamName}
        </Flex>
        {true && (
          <Picture
            square
            size='xxxsmall'
            imageId={imageId}
            css={css`
              margin: 6px;
            `}
            iconName='user-friends'
          />
        )}
        {true && (
          <div
            css={css`
              position: relative;
              ${font.title}
              font-weight: 700;
              font-size: 20px;
              text-align: center;
              margin: 0 6px;
            `}
          >
            <div
              css={css`
                text-align: center;
                min-width: 16px;
              `}
            >
              {isEnded ? teamScore : '-'}
            </div>
            {didWin && (
              <div
                css={css`
                  position: absolute;
                  height: 2px;
                  width: calc(100% - 1px);
                  background-color: ${colors.DEFAULT_FLAIR};
                `}
              />
            )}
          </div>
        )}
      </Flex>
    </td>
  )
}

const GameSlot = ({
  availableVenues,
  form = {},
  handleSetForm,
  game,
  submitLoading,
  advanceLoading,
  onApprove,
  onDebugAdvance,
  idx,
  prevGameIsComplete,
  prevGameHasBye = false,
  tournamentStatus,
  onPressCopy,
  onPressTeamModal = () => {},
  isSingleOrDoubleTournament = false,
}) => {
  const {
    id: gameId,
    game_id: SNGameUID,
    ended_at,
    game_number,
    starts_at,
    venue_id,
    facility_id,
    homeTeamSlot = {},
    visitingTeamSlot = {},
    round,
    pool,
    home_team_score,
    visiting_team_score,
    status,
    has_bye = false,
  } = game

  const displayApprovalButton =
    ended_at && status === GAME_SLOT_STATUS.Ready_To_Play
  const advanceButtonsEnabled =
    (idx === 0 || prevGameIsComplete || prevGameHasBye) &&
    !ended_at &&
    status === GAME_SLOT_STATUS.Ready_To_Play &&
    tournamentStatus === TOURNAMENT_STATUS.IN_PROGRESS
  const isDirty = Object.keys(form).length > 0

  const roundName =
    round?.round_type_id > 1
      ? ROUND_TYPE[round?.round_type_id]
      : round?.name_full?.replace('Round ', '')
  const poolName = round?.round_type_id > 1 || !pool?.letter ? '-' : pool.letter
  const selectedVenue = form?.venue_id || game?.venue_id
  const selectedFacility = Object.prototype.hasOwnProperty.call(
    form,
    'facility_id'
  )
    ? form.facility_id
    : facility_id || null
  const selectedVenueObject = availableVenues.find(
    (v) => v.id === selectedVenue
  )

  const onChange = (key, { target }) => {
    handleSetForm(key, target?.value)
  }

  const homeImageId = getImageThumbnailId(homeTeamSlot)
  const visitingImageId = getImageThumbnailId(visitingTeamSlot)

  return (
    <tr
    // className={`${submitLoading || game?.status === 2 ? 'is-disabled' : ''}`}
    >
      <td className='game-number'>
        {game_number}
        {isDirty && (
          <Icon
            name='pencil-alt'
            color='rgba(255, 255, 255, 0.4)'
            css={css`
              position: absolute;
              bottom: calc(50% - 7px);
              left: -24px;
            `}
          />
        )}
      </td>

      <td className={cn('date-picker', { 'is-edited': form?.starts_at })}>
        <DatePicker
          disabled={submitLoading || game?.status === 2 ? true : false}
          hideClear={typeof starts_at === 'string'}
          changeOnReset
          datePlaceholder='Select a date'
          defaultValue={starts_at}
          onChange={onChange.bind(this, 'starts_at')}
          timezone={selectedVenueObject?.timezone}
        />
      </td>

      <td className='venue'>
        <Select
          value={form.venue_id || venue_id || ''}
          onChange={(e) => {
            onChange('venue_id', e)
            onChange('facility_id', { target: { value: '' } })
          }}
          className={cn({ 'is-edited': form?.venue_id })}
          disabled={submitLoading || game?.status === 2 ? true : false}
        >
          <option disabled value=''>
            Select a venue
          </option>
          <option disabled>-----</option>
          {availableVenues.map((venue) => {
            return (
              <option key={venue.id} value={venue.id}>
                {venue.name_full}
              </option>
            )
          })}
        </Select>
      </td>

      <td className='facility'>
        <Select
          value={
            Object.prototype.hasOwnProperty.call(form, 'facility_id')
              ? form.facility_id
              : facility_id || ''
          }
          onChange={onChange.bind(this, 'facility_id')}
          className={cn({ 'is-edited': form?.facility_id })}
          disabled={submitLoading || game?.status === 2 ? true : false}
        >
          <option disabled value=''>
            {selectedVenueObject?.facilities?.length === 0
              ? 'Select venue first'
              : 'Select a facility'}
          </option>
          <option disabled value=''>
            -----
          </option>
          {selectedVenueObject?.facilities?.map((facility) => {
            return (
              <option key={facility.id} value={facility.id}>
                {facility.name}
              </option>
            )
          })}
        </Select>
      </td>
      {/* <Tooltip
        text={'Copy this line venue and facility to all other gameslots'}
        textClassName={css`
          background-color: black;
        `}
      > */}
      <td>
        <SuspensionSubmitButton
          noBorder={true}
          width='16px'
          fontSize='16px'
          busy={advanceLoading === gameId}
          disabled={
            !selectedVenue ||
            !selectedFacility ||
            submitLoading ||
            game?.status === 2
          }
          onClick={() =>
            onPressCopy(
              selectedVenue,
              Object.prototype.hasOwnProperty.call(form, 'facility_id')
                ? form.facility_id
                : facility_id || null
            )
          }
          type='button'
        >
          <Icon
            faType='fa'
            name={'clone'}
            color={colors.PRIMARY}
            fontSize={14}
          />
        </SuspensionSubmitButton>
      </td>
      {/* </Tooltip> */}
      <NewGameTeamSlot
        isEnded={ended_at}
        homeTeamName={homeTeamSlot?.name_full}
        visitingTeamName={visitingTeamSlot?.name_full}
        homeTeamScore={home_team_score}
        visitingTeamScore={visiting_team_score}
        homeImageId={homeImageId}
        visitingImageId={visitingImageId}
        homeDidWin={home_team_score > visiting_team_score}
        visitingDidWin={visiting_team_score > home_team_score}
        disabled={submitLoading || game?.status === 2}
        onPressTeamModal={onPressTeamModal}
        game={game}
      />
      {/* If necessary we can bring the old approach back */}
      {/* <GameSlotTeamCell
        isHome
        teamName={homeTeamSlot?.name_full}
        isEnded={ended_at}
        imageId={homeImageId}
        teamScore={home_team_score}
        didWin={home_team_score > visiting_team_score}
      />
      <GameSlotTeamCell
        teamName={visitingTeamSlot?.name_full}
        isEnded={ended_at}
        imageId={visitingImageId}
        teamScore={visiting_team_score}
        didWin={visiting_team_score > home_team_score}
      /> */}
      <td className='td-center approve'>
        {displayApprovalButton ? (
          <SuspensionSubmitButton
            width='75px'
            fontSize='16px'
            busy={advanceLoading === gameId}
            disabled={!ended_at}
            onClick={onApprove}
            type='button'
          >
            Approve
          </SuspensionSubmitButton>
        ) : status === GAME_SLOT_STATUS.Processed ? (
          <Icon
            faType='fa'
            name={'check'}
            color={colors.PRIMARY}
            fontSize={14}
          />
        ) : (
          '-'
        )}
      </td>
      <td className='td-center round'>{roundName}</td>
      {!isSingleOrDoubleTournament ? (
        <td className='td-center pool'>{poolName}</td>
      ) : null}
      <td className='td-center game-status'>
        {SNGameUID ? (
          <a
            href={`${ROUTES.GAME_ROOT}/${SNGameUID}`}
            target='_blank'
            rel='noreferrer'
            className={cn({ 'is-ended': ended_at })}
          >
            {ended_at || has_bye ? 'Final' : 'Upcoming'}
            <Icon
              name='external-link'
              faType='far'
              fontSize={12}
              css={css`
                margin-left: 6px;
              `}
            />
          </a>
        ) : (
          'Upcoming'
        )}
      </td>
      {IS_DEVELOPMENT && (
        <td className='td-center approve'>
          {advanceButtonsEnabled ? (
            <Flex>
              <SuspensionSubmitButton
                width='60px'
                fontSize='16px'
                busy={advanceLoading === gameId}
                disabled={!homeTeamSlot?.team_id || !visitingTeamSlot?.team_id}
                onClick={onDebugAdvance.bind(this, homeTeamSlot?.team_id)}
                type='button'
              >
                Home
              </SuspensionSubmitButton>
              <SuspensionSubmitButton
                width='60px'
                fontSize='16px'
                busy={advanceLoading === gameId}
                disabled={!homeTeamSlot?.team_id || !visitingTeamSlot?.team_id}
                onClick={onDebugAdvance.bind(this, visitingTeamSlot?.team_id)}
                css={css`
                  margin-left: 8px;
                `}
                type='button'
              >
                Visitor
              </SuspensionSubmitButton>
            </Flex>
          ) : (
            '-'
          )}
        </td>
      )}
    </tr>
  )
}

export default GameSlot
