/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import { t } from '@sportninja/common/i18n'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { FormButton } from '../../components/Form/css'
import { Flex } from '../../components/Layout'
import LoadingSpinner from '../../components/LoadingSpinner'

const Scoresheet = ({ gameNumber, id }) => {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)
  const [download, setDownload] = useState(false)
  const [scoresheetUrl, setScoresheetUrl] = useState(null)

  useEffect(() => {
    const openScoresheet = async () => {
      try {
        setLoaded(false)
        const response = await req(`/games/${id}/scoresheets/html`, {
          parseJSON: false,
        })
        if (!response || !response.url) {
          throw new Error(
            'An error occurred while opening the scoresheet. Please contact SportNinja support.'
          )
        }
        setScoresheetUrl(response?.url)
      } catch (e) {
        setError(
          e?.message ||
            'An error occurred while opening the scoresheet. Contact SportNinja support.'
        )
      } finally {
        setLoaded(true)
      }
    }
    if (id) {
      openScoresheet()
    }
  }, [id])

  const handleOpen = async () => {
    try {
      setDownload(true)
      const response = await req(`/games/${id}/scoresheets/pdf`, {
        parseJSON: false,
      })
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `Game #${gameNumber} scoresheet.pdf`
      link.click()
    } catch (e) {
      setError(
        e?.message || 'An error occurred while downloading the scoresheet'
      )
    } finally {
      setDownload(false)
    }
  }

  return (
    <Flex
      column
      alignItems='center'
      css={css`
        margin: 0 20px 20px;
        overflow: auto;
        /* min-height: 100vh; */
      `}
    >
      {error ? (
        <div
          css={css`
            text-align: center;
          `}
        >
          There was a problem displaying the scoresheet.
        </div>
      ) : loaded ? (
        <>
          <Flex
            justifyContent='center'
            css={css`
              width: 200px;
              margin: 0 auto 20px;
              overflow: auto;
            `}
          >
            <FormButton
              disabled={download || !loaded}
              busy={download}
              type='button'
              onClick={async () => await handleOpen()}
              css={css`
                background-image: none;
                background-color: black;
              `}
            >
              {t('common:download')}
            </FormButton>
          </Flex>
          <iframe
            style={{
              background: 'white',
              overflow: 'auto',
            }}
            height={720}
            width={1280}
            src={scoresheetUrl}
          />
        </>
      ) : (
        <LoadingSpinner />
      )}
    </Flex>
  )
}

Scoresheet.propTypes = {
  id: PropTypes.string.isRequired,
}

export default Scoresheet
