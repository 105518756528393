import React, { useEffect, useState } from 'react'
import { getHeaders } from '@sportninja/common/api/request'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import EnvHandler from '@sportninja/common/utils/env-handler-setup'
import Pusher from 'pusher-js'

export const PusherContext = React.createContext(undefined)

const PusherContextProvider = ({ children, isLoggedIn }) => {
  const [isReady, setIsReady] = useState(false)
  const [client, setClient] = useState(null)
  const [error, setError] = useState(false)

  useEffect(() => {
    const setup = async () => {
      const url = await EnvHandler.get()
      const headers = await getHeaders(true)
      const authorization = headers.get('authorization')

      try {
        // eslint-disable-next-line no-undef
        const pusher = new Pusher(__DEV__ ? 'xxx' : 'jeUGBp9MNxK', {
          // eslint-disable-next-line no-undef
          wsHost: __DEV__
            ? window.location.hostname
            : isCanlan
            ? 'canlan-ws.sportninja.net'
            : 'metal-chat.sportninja.net',
          wsPort: 6501,
          wssPort: 6001,
          forceTLS: false,
          disableStats: true,
          enabledTransports: ['ws', 'wss'],
          authEndpoint: `${url}/auth/broadcasting/team`,
          auth: {
            headers: {
              Authorization: authorization,
              Accept: 'application/json',
            },
          },
        })

        pusher.connection.bind('connected', (e) => {
          setError(false)
          setClient(pusher)
          setIsReady(true)
          // eslint-disable-next-line no-undef
          if (__DEV__) {
            console.log('[ws] connection established', e)
          }
        })

        pusher.connection.bind('error', (err) => {
          // eslint-disable-next-line no-undef
          if (__DEV__) {
            console.log('[ws] connection bind error', err)
          }
          setIsReady(false)
          setError(true)
        })
      } catch (e) {
        console.log(e)
        return null
      }
    }

    if (isLoggedIn) {
      setup()
    } else {
      // eslint-disable-next-line no-undef
      if (__DEV__) {
        console.log('[ws] not logged in')
      }

      if (client && typeof client.disconnect === 'function') {
        client.disconnect()
        // eslint-disable-next-line no-undef
        if (__DEV__) {
          console.log('[ws] disconnected')
        }
      }
    }
  }, [isLoggedIn])

  return (
    <PusherContext.Provider value={{ client, isReady, error }}>
      {children}
    </PusherContext.Provider>
  )
}

export default PusherContextProvider
