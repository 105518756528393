const Roles = {
  1: 'Referee',
  2: 'Scorekeeper',
  3: 'Timekeeper',
  7: 'Head Referee',
  8: 'Assistant Referee',
  9: 'Fourth Official',
  10: 'Referee',
}

export default Roles
