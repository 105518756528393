/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import PropTypes from 'prop-types'

import { Flex } from './Layout'
import { InputWrapper } from './Form/css'
import { media } from './Responsive'

const containerCss = css`
  max-height: 52px;
  flex-direction: row;
  align-self: flex-end;

  ${media.mobile} {
    align-self: initial;
  }

  :hover {
    .file-input-btn {
      border-color: white;
    }
  }

  .file-input-btn {
    label {
      justify-content: center;
      align-items: center;

      height: 100%;
      width: 100%;
    }

    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      z-index: -1;
    }
  }
`

const buttonCss = css`
  position: relative;

  height: 52px;
  width: 52px;
  margin-right: 14px;

  color: white;
  font-size: 16px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 50%;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

const textCss = css`
  font-size: 14px;
  letter-spacing: 0.37px;

  cursor: pointer;
`

const FileInput = ({ backgroundImage, children, disabled, htmlFor, labelText, noFlex, fileName }) => {
  const disabledCss = disabled && css`cursor: default;`

  return (
    <Flex alignItems='center' noFlex={noFlex} css={containerCss} disabled={disabled}>
      <button disabled={disabled} type='button' className='file-input-btn' css={[
        buttonCss,
        backgroundImage && css`
          background-image: url(${backgroundImage});
          color: transparent;
        `,
        disabledCss
      ]}>
        {children}
      </button>
      <Flex column as='label' htmlFor={htmlFor} css={[ textCss, disabledCss ]}>
        {fileName}
        {!fileName &&
          <div css={css`text-decoration: underline;`}>
            {labelText}
          </div>
        }
      </Flex>
    </Flex>
  )
}

FileInput.propTypes = {
  backgroundImage: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  fileName: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  htmlFor: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  noFlex: PropTypes.bool
}

export default FileInput
