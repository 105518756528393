/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'

import { font } from '../../components/css'
import Icon from '../../components/Icon'
import { Flex } from '../../components/Layout'
import { media } from '../../components/Responsive'
import { ReactComponent as Clipboard } from './search-graphic.svg'

const SearchTopTitle = (
  <h1
    css={css`
      ${font.title}
      font-weight: 700;
      font-size: 44px;
      line-height: 44px;
      letter-spacing: 0.015em;
      text-transform: uppercase;

      ${media.mobile} {
        font-size: 30px;
        line-height: 30px;
      }
    `}
  >
    Search Results
  </h1>
)

const SearchTop = ({
  searchText,
  numResults,
  onClose,
  onResetFilter,
  isFilterActive,
}) => {
  const CloseButton = (
    <div
      css={css`
        display: flex;
        align-items: center;
        height: 48px;
        margin-left: auto;

        ${media.mobile} {
          height: 44px;
        }
      `}
    >
      <button
        type='button'
        css={css`
          ${font.title}
          font-weight: 700;
          font-size: 20px;
          color: white;
          text-transform: uppercase;
          border: 2px solid white;
          padding: 0 16px;
          height: 100%;

          transition: all 0.1s ease-in-out;

          &:hover {
            background-color: white;
            color: black;
          }
        `}
        onClick={onClose}
      >
        Close
      </button>
    </div>
  )

  if (numResults === 0) {
    return (
      <Flex column alignItems='center' justifyContent='center'>
        {CloseButton}
        <Clipboard
          css={css`
            margin: 40px 0;
            transform: translateX(-12px);
          `}
        />
        {SearchTopTitle}
        <p
          css={css`
            margin-top: 32px;
            font-weight: 500;
            font-size: 20px;
            color: #9fa3a9;
            text-align: center;
          `}
        >
          We didn&apos;t find anything related to &quot;
          <span
            css={css`
              color: white;
            `}
          >
            {searchText}
          </span>
          &quot;.
        </p>
        {isFilterActive && (
          <p
            onClick={onResetFilter}
            css={css`
              margin-top: 32px;
              font-size: 18px;
              color: white;
              text-align: center;
              cursor: pointer;

              span {
                text-decoration: underline;
              }
            `}
          >
            <span>Clear your filter</span> and try again?
          </p>
        )}
      </Flex>
    )
  }

  return (
    <Flex
      noFlex
      row
      alignItems='center'
      css={css`
        margin-bottom: 16px;
        margin-right: 16px;
      `}
    >
      <Icon
        name='search'
        color='white'
        fontSize={46}
        className='search-top-mag-glass'
        css={css`
          margin-right: 18px;
        `}
      />
      <div>
        {SearchTopTitle}
        <p
          css={css`
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.105em;
            text-transform: uppercase;
            color: ${colors.ATTENDANCE_GRAY};
            margin-top: 8px;
            min-height: 12px;

            @keyframes fade {
              0% {
                opacity: 1;
              }
              100% {
                opacity: 0;
              }
            }
            ${typeof numResults !== 'number' &&
            `
              animation-name: fade;
              animation-duration: 0.7s;
              animation-timing-function: ease-in-out;
              animation-iteration-count: infinite;
              animation-direction: alternate;

              background-color: rgba(255, 255, 255, 0.15);
              border-radius: 8px;
              width: 60px;
            `}
          `}
        >
          {typeof numResults === 'number'
            ? `${numResults} result${numResults > 1 ? 's' : ''}`
            : ''}
        </p>
      </div>
      {CloseButton}
    </Flex>
  )
}

export default SearchTop
