/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import React, { useState, useRef } from 'react'
import { ROUTES } from '@sportninja/common/constants/app'
import { t } from '@sportninja/common/i18n'

import Page from '../../components/Page'
import ScheduleList from './ScheduleList'
import { connect } from 'react-redux'
import {
  getHockeySport,
  getSoccerSport,
} from '@sportninja/common/selectors/types'
import { NewModal } from 'src/components/NewModal'
import NewScheduleForm from 'src/components/NewScheduleForm'
import GeneratePluginModal from 'src/components/GeneralPluginModal'

const Schedules = ({ hockeySport, soccerSport }) => {
  const [scheduleCount, setScheduleCount] = useState('')
  const [favCount, setFavCount] = useState('')
  const [scheduleInitialState, setScheduleInitialState] = useState(null)
  const [syncSalesforce, setSyncSalesforce] = useState(false)
  const [onTriggerRefresh, setOnTriggerRefresh] = useState(() => {})
  const competitionModal = useRef(null)
  const generatePluginTagModalRef = useRef(null)
  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const onClickWebPluginOnScheduleList = (schedule) => {
    setSelectedSchedule(schedule)
    generatePluginTagModalRef?.current?.openModal()
  }

  const pages = [
    {
      to: `${ROUTES.SCHEDULES.ROOT}/my`,
      text: `${t('common:competition', { count: 2 })} ${
        scheduleCount ? `(${scheduleCount})` : ''
      }`,
      component: (
        <ScheduleList
          key='schedules'
          slug='/users/me/schedules'
          setCount={setScheduleCount}
          onEditSchedule={() => {
            competitionModal.current.openModal()
          }}
          setInitialState={setScheduleInitialState}
          setOnTriggerRefresh={setOnTriggerRefresh}
          onClickWebPluginOnScheduleList={onClickWebPluginOnScheduleList}
          setSyncSalesforce={setSyncSalesforce}
        />
      ),
    },
    {
      to: `${ROUTES.SCHEDULES.FAVOURITES}`,
      text: `following ${favCount ? `(${favCount})` : ''}`,
      component: (
        <ScheduleList
          key='favs'
          slug='/favorites/schedules'
          setCount={setFavCount}
        />
      ),
    },
  ]

  const BASE_ROUTE = `${ROUTES.SCHEDULES.ROOT}`

  return (
    <>
      <Page
        baseRoute={BASE_ROUTE}
        pages={pages}
        baseTitle={t('common:competition', { count: 2 })}
        title={t('util:capitalize', {
          text: t('common:competition', { count: 2 }),
        })}
      />
      <NewModal ref={competitionModal} shouldCloseOnOverlayClick={true}>
        <div
          css={css`
            width: 55vw;
            background: #26303e;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            transition: all 0.1s ease-in-out;
            /* border-radius: 8px; */
            padding-top: 16px;
          `}
        >
          <NewScheduleForm
            initialState={scheduleInitialState || null}
            onCancel={() => {
              setScheduleInitialState(null)
              competitionModal?.current?.closeModal()
            }}
            triggerRefresh={() => {
              onTriggerRefresh()
            }}
            displayCanlanForm={syncSalesforce}
          />
        </div>
      </NewModal>
      <NewModal
        ref={generatePluginTagModalRef}
        shouldCloseOnOverlayClick={false}
      >
        <GeneratePluginModal
          onPressClose={() => {
            generatePluginTagModalRef?.current?.closeModal()
            setSelectedSchedule(null)
          }}
          scheduleId={selectedSchedule?.id}
          isOrganization={!selectedSchedule}
        />
      </NewModal>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    hockeySportId: getHockeySport(state)?.id,
    soccerSportId: getSoccerSport(state)?.id,
    hockeySport: getHockeySport(state),
    soccerSport: getSoccerSport(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedules)
