import { applyMiddleware, compose, createStore } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import * as Sentry from '@sentry/react'

export let store

const configureStore = (rootReducer, rootSaga, history) => {
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
  })

  const sagaMiddleware = createSagaMiddleware()

  const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose

  store = createStore(
    connectRouter(history)(rootReducer),
    composeSetup(
      applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware
      ),
      sentryReduxEnhancer
    )
  )

  sagaMiddleware.run(rootSaga)

  return store
}

export default configureStore
