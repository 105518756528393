/** @jsxImportSource @emotion/react */

import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { DEFAULT_PUBLIC_SETTING } from '@sportninja/common/sagas/helpers'
import { t } from '@sportninja/common/i18n'

import { Flex } from '../Layout'
import Icon from '../Icon'
import Toggler, { TogglerWrap } from '../Toggler'
import PrivacySettingsExample from './PrivacySettingsExample'

const getText = (isVisible, isPublic, entityType) => {
  const STRINGS = {
    [ENTITY_TYPES.org]: {
      public: t('WebPrivacySettings:orgPublic'),
      private: t('WebPrivacySettings:orgPrivate'),
    },
    [ENTITY_TYPES.schedule]: {
      public: t('WebPrivacySettings:schedulePublic'),
      private: t('WebPrivacySettings:schedulePrivate'),
    },
    [ENTITY_TYPES.team]: {
      public: t('WebPrivacySettings:teamPublic'),
      private: t('WebPrivacySettings:teamPrivate'),
    },
    [ENTITY_TYPES.game]: {
      public: t('WebPrivacySettings:gamePublic'),
      private: t('WebPrivacySettings:gamePrivate'),
    },
  }

  // Handle entity strings separately with STRINGS object
  if (entityType) return STRINGS[entityType][isPublic ? 'public' : 'private']

  // User profile:
  let str = t('WebPrivacySettings:userProfileAll')

  if (!isPublic && isVisible) {
    str = t('WebPrivacySettings:userProfileVisible')
  } else if (!isPublic && !isVisible) {
    str = t('WebPrivacySettings:userProfilePrivate')
  }

  return str
}

// See common/sagas/helpers.js:createEntity for more information about how this
// component interacts with components/Form
const PrivacySettings = ({
  defaultValues,
  disabled,
  entityType,
  onChange,
  privacySettingOverride,
  readOnlyPrivacyIfHidden = false,
  showVisibility = false,
  values,
}) => {
  const isSelf = typeof entityType === 'undefined'

  let isVisible = null,
    setIsVisible = () => {}

  if (showVisibility) {
    // eslint-disable-next-line no-extra-semi
    ;[isVisible, setIsVisible] = useState(
      typeof defaultValues.isVisible === 'boolean'
        ? defaultValues.isVisible
        : true
    )
  }
  const [isPublic, setIsPublic] = useState(
    typeof privacySettingOverride === 'boolean'
      ? privacySettingOverride
      : typeof defaultValues.isPublic === 'boolean'
      ? defaultValues.isPublic
      : DEFAULT_PUBLIC_SETTING
  )

  // For the user's profile and teams only, privacy toggle appears as a single, not-toggle-able
  // button if visibility is set to false. (since if you aren't visible, you are private
  // by default)
  const fullWidthRight = readOnlyPrivacyIfHidden && !isVisible

  const mounted = useRef(false)
  useEffect(() => {
    if (mounted.current) {
      if (isVisible !== null) {
        onChange({ target: { name: 'is_visible', value: isVisible } })
      }
      onChange({ target: { name: 'is_public', value: isPublic } })
    }

    mounted.current = true
  }, [isVisible, isPublic])

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <h2
        css={css`
          font-size: 13px;
          letter-spacing: 1.34px;
          text-transform: uppercase;
        `}
      >
        {t('Web:privacySettings')}
      </h2>
      <Flex
        css={css`
          margin-top: 16px;

          ${TogglerWrap} {
            flex: 0 1 50%;
          }

          @media only screen and (max-width: 413px) {
            flex-direction: column;
          }
        `}
      >
        <Toggler
          disabled={disabled}
          activeBtn={isPublic && !fullWidthRight ? 'left' : 'right'}
          left={t('Web:public')}
          right={
            <Flex justifyContent='center' alignItems='center'>
              <Icon
                name='lock'
                fontSize={16}
                css={css`
                  position: absolute;
                  left: 12px;
                  opacity: ${fullWidthRight ? 1 : 0};
                `}
              />
              <span>{t('Web:private')}</span>
            </Flex>
          }
          onClick={setIsPublic}
          fullWidthRight={fullWidthRight}
          title={t('Web:privacy')}
        />

        {showVisibility && (
          <Toggler
            disabled={disabled}
            activeBtn={isVisible ? 'left' : 'right'}
            css={css`
              margin: 20px 0 0 0;
              @media only screen and (min-width: 414px) {
                margin: 0 0 0 20px;
              }
            `}
            left={t('Web:visible')}
            right={t('Web:hidden')}
            onClick={(isLeft) => {
              setIsVisible(isLeft)
              setIsPublic(isLeft)
            }}
            title={t('Web:visibility')}
          />
        )}
      </Flex>
      <div
        css={css`
          color: #ffffff;
          font-size: 13px;
          letter-spacing: 0.76px;
          line-height: 13px;
          margin-top: 16px;
        `}
      >
        <p>{getText(isVisible, isPublic, entityType)}</p>
        {isSelf && (
          <p
            css={css`
              margin: 14px 0 6px 0;
              color: #a7a7a7;
              font-size: 11px;
            `}
          >
            {t('Web:example')}:
          </p>
        )}
      </div>
      {isSelf && (
        <PrivacySettingsExample
          defaultValues={defaultValues}
          isPublic={isPublic}
          isSelf={isSelf}
          isVisible={isVisible}
          values={values}
        />
      )}
    </div>
  )
}

PrivacySettings.propTypes = {
  defaultValues: PropTypes.object,
  disabled: PropTypes.bool,
  entityType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  readOnlyPrivacyIfHidden: PropTypes.bool,
  showVisibility: PropTypes.bool,
  values: PropTypes.object,
  privacySettingOverride: PropTypes.bool,
}

export default PrivacySettings
