import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import 'react-native-get-random-values'

import { getOfflineId, str } from '../utils'

dayjs.extend(duration)

const parseClockTime = (duration) => {
  const dur = dayjs.duration(duration)
  return dur.format('HH:mm:ss')
}

const scoringTransformer = (actions, actionType, requestPayload) => {
  let type = '',
    payload = {}

  switch (
    actionType // eslint-disable-line default-case
  ) {
    case str(actions.periods.create.request): {
      type = str(actions.periods.create.success)
      payload = {
        id: requestPayload.id,
        data: {
          id: requestPayload.form.uid,
          started_at: requestPayload.form.started_at,
          duration: requestPayload.form.duration,
          ended_at: null,
          period_type: {
            id: requestPayload.form.period_type_id,
            name: requestPayload.form.name,
            name_full: requestPayload.form.name_full,
          },
          clock_time: parseClockTime(requestPayload.form.duration),
        },
      }
      break
    }

    case str(actions.periods.update.request): {
      type = str(actions.periods.update.success)
      payload = {
        id: requestPayload.id,
        periodId: requestPayload.periodId,
        data: {
          ended_at: requestPayload.form.ended_at,
          clock_time: requestPayload.form.clock_time,
          duration: requestPayload.form.duration,
        },
      }
      break
    }

    case str(actions.signatures.create.request): {
      type = str(actions.signatures.create.success)
      payload = {
        id: requestPayload.id,
        data: {
          id: requestPayload.userId,
          official: { id: requestPayload.userId },
        },
      }
      break
    }

    // We handle these inside the goal action, since these will never be dispatched when offline
    // case str(actions.assists.create.request): {
    //   type = str(actions.assists.create.success)

    //   break
    // }

    // case str(actions.assists.update.request): {
    //   type = str(actions.assists.update.success)

    //   break
    // }

    // case str(actions.assists.delete.request): {
    //   type = str(actions.assists.delete.success)

    //   break
    // }

    case str(actions.goals.create.request): {
      type = str(actions.goals.create.success)

      const { first_assist, second_assist } = requestPayload.form
      const assists = []

      if (first_assist && first_assist.playerId) {
        assists.push({
          id: first_assist.uid,
          type: { id: '1' },
          player: { id: first_assist.playerId },
        })
      }
      if (second_assist && second_assist.playerId) {
        assists.push({
          id: second_assist.uid,
          type: { id: '2' },
          player: { id: second_assist.playerId },
        })
      }

      payload = {
        id: requestPayload.id,
        teamId: requestPayload.form.team_id,
        data: {
          id: requestPayload.form.uid,
          period_clock_time: requestPayload.form.period_clock_time,
          occurred_at: requestPayload.form.occurred_at,
          goal_type_id: requestPayload.form.goal_type_id,
          shot: {
            id: getOfflineId(),
            team_id: requestPayload.form.team_id,
            period_id: requestPayload.periodId,
            period_clock_time: requestPayload.form.period_clock_time,
            occurred_at: requestPayload.form.occurred_at,
            player_id: requestPayload.form.player_id,
          },
          assists,
        },
      }
      break
    }

    case str(actions.goals.update.request): {
      type = str(actions.goals.update.success)
      const { first_assist, second_assist, goal_type_id } =
        requestPayload?.form || {}

      const assists = []
      if (first_assist) {
        // Only delete if we have a playerId to delete - if this is undefined then
        // the field was touched but then cleared.
        if (first_assist.assistId === null) {
          assists.push({
            id: getOfflineId(),
            type: { id: '1' },
            player: { id: first_assist.playerId },
          })
        } else if (first_assist.playerId !== '') {
          assists.push({
            id: first_assist.assistId,
            type: { id: '1' },
            player: { id: first_assist.playerId },
          })
        }
      }

      if (second_assist) {
        // Only delete if we have a playerId to delete - if this is undefined then
        // the field was touched but then cleared.
        if (second_assist.assistId === null) {
          assists.push({
            id: getOfflineId(),
            type: { id: '2' },
            player: { id: second_assist.playerId },
          })
        } else if (second_assist.playerId !== '') {
          assists.push({
            id: second_assist.assistId,
            type: { id: '2' },
            player: { id: second_assist.playerId },
          })
        }
      }

      const data = { assists, goal_type_id }
      if (requestPayload.form.period_clock_time)
        data.period_clock_time = requestPayload.form.period_clock_time
      if (requestPayload.form.player_id)
        data.shot = { player_id: requestPayload.form.player_id }

      payload = {
        id: requestPayload.id,
        goalId: requestPayload.goalId,
        data,
      }
      break
    }

    case str(actions.goals.delete.request): {
      type = str(actions.goals.delete.success)
      payload = {
        id: requestPayload.id,
        teamId: requestPayload.teamId,
        goalId: requestPayload.goalId,
      }
      break
    }

    case str(actions.goalieChanges.create.request): {
      type = str(actions.goalieChanges.create.success)
      payload = {
        id: requestPayload.id,
        data: {
          id: requestPayload.form.uid,
          period_clock_time: requestPayload.form.period_clock_time,
          occurred_at: requestPayload.form.occurred_at,
          previous_goalie_id: requestPayload.form.previous_goalie_id,
          period_id: requestPayload.periodId,
          goalie_id: requestPayload.form.goalie_id,
        },
      }
      break
    }

    case str(actions.goalieChanges.update.request): {
      type = str(actions.goalieChanges.update.success)
      payload = {
        id: requestPayload.id,
        goalieChangeId: requestPayload.goalieChangeId,
        data: {
          period_clock_time: requestPayload.form.period_clock_time,
          previous_goalie_id: requestPayload.form.previous_goalie_id,
          goalie_id: requestPayload.form.goalie_id,
        },
      }
      break
    }

    case str(actions.goalieChanges.delete.request): {
      type = str(actions.goalieChanges.delete.success)
      payload = {
        id: requestPayload.id,
        teamId: requestPayload.teamId,
        goalieChangeId: requestPayload.goalieChangeId,
      }
      break
    }

    case str(actions.penalties.create.request): {
      type = str(actions.penalties.create.success)
      payload = {
        id: requestPayload.id,
        teamId: requestPayload.form.team_id,
        data: {
          id: requestPayload.form.uid,
          period_clock_time: requestPayload.form.period_clock_time,
          occurred_at: requestPayload.form.occurred_at,
          team_id: requestPayload.form.team_id,
          period_id: requestPayload.periodId,
          player_id: requestPayload.form.player_id,
          offense_type: {
            id: requestPayload.form.offense_type_id,
            name_full: requestPayload.form.offense_type_name_full,
          },
          penalty: {
            served_by_player_id: requestPayload.form.served_by_player_id,
            amount: requestPayload.form.amount,
          },
        },
      }
      break
    }

    case str(actions.penalties.update.request): {
      type = str(actions.penalties.update.success)
      const data = {}

      if (requestPayload.form.period_clock_time)
        data.period_clock_time = requestPayload.form.period_clock_time

      if (requestPayload.form.player_id) {
        data.player_id = requestPayload.form.player_id
      } else if (requestPayload.form.player_id === null) {
        data.player_id = null
      }

      if (requestPayload.form.offense_type_id)
        data.offense_type = { id: requestPayload.form.offense_type_id }
      if (requestPayload.form.offense_type_name_full)
        data.offense_type = {
          ...(data.offense_type || {}),
          name_full: requestPayload.form.offense_type_name_full,
        }
      if (requestPayload.form.served_by_player_id)
        data.penalty = {
          served_by_player_id: requestPayload.form.served_by_player_id,
        }
      if (Object.prototype.hasOwnProperty.call(requestPayload.form, 'amount'))
        data.penalty = {
          ...(data.penalty || {}),
          amount: requestPayload.form.amount,
        }

      payload = {
        id: requestPayload.id,
        penaltyId: requestPayload.penaltyId,
        data,
      }
      break
    }

    case str(actions.penalties.delete.request): {
      type = str(actions.penalties.delete.success)
      payload = {
        id: requestPayload.id,
        teamId: requestPayload.teamId,
        penaltyId: requestPayload.penaltyId,
      }
      break
    }

    case str(actions.shots.create.request): {
      type = str(actions.shots.create.success)
      payload = {
        id: requestPayload.id,
        teamId: requestPayload.teamId,
        data: {
          id: requestPayload.uid,
          game_id: requestPayload.id,
          team_id: requestPayload.teamId,
          period_id: requestPayload.periodId,
          opposing_goalie_id: requestPayload.opposingGoalieId,
          occurred_at: requestPayload.occurredAt,
        },
      }
      break
    }

    case str(actions.shots.delete.request): {
      type = str(actions.shots.delete.success)
      payload = {
        id: requestPayload.id,
        teamId: requestPayload.teamId,
        shotId: requestPayload.shotId,
      }
      break
    }
  }

  return { type, payload }
}

export default scoringTransformer
