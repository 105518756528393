/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { t } from '@sportninja/common/i18n'
import { ROUTES } from '@sportninja/common/constants/app'
import queryString from 'query-string'

import AuthWrap from '../../components/AuthWrap'
import ResendVerifyEmail from '../../components/ResendVerifyEmail'
import Helmet from '../../components/Helmet'
import { H1, BodyText } from '../../components/AuthWrap/css'
import LoadingSpinner from '../../components/LoadingSpinner'
import { FormButton } from '../../components/Form/css'
import AuthSecondaryLink from '../../components/AuthWrap/AuthSecondaryLink'
import { getToken } from '../ResetPassword'

const VerifyEmail = ({ history, verify }) => {
  const [step, setStep] = useState(0)
  const { search } = useLocation()
  const params = useMemo(() => {
    const url = /\?token=([^&]+)&context=([^&]+)&context_id=([^&]+)/
    const urlTokenOnly = /\?token=([^&]+)/
    const urlMatch = url.exec(search)
    const tokenMatch = urlTokenOnly.exec(search)

    if (urlMatch) {
      const token = urlMatch?.[1]
      const context = urlMatch?.[2]
      const contextId = urlMatch?.[3]

      return {
        token,
        context,
        contextId,
      }
    }

    if (tokenMatch) {
      const token = tokenMatch?.[1]

      return {
        token,
      }
    }
  }, [search])

  // Run on mount only
  useEffect(() => {
    if (!params?.token || typeof params?.token !== 'string') {
      // error
      setStep(1)
    } else {
      verify(params.token)
        .then(() => {
          // Show success message
          setStep(3)
          // Wait a bit, then redirect to homepage
          setTimeout(setStep, 2000, 4)
        })
        .catch((e) => {
          // Already verified
          if (
            e.status === 400 &&
            e.message === 'This email has already been verified'
          ) {
            setStep(2)
          } else {
            // Generic error, show 'resend verify email component'
            setStep(1)
          }
        })
    }
  }, [params])

  const navigator = useMemo(() => {
    if (params?.context === 'registration') {
      return `/registration/${params?.contextId}`
    } else {
      return ROUTES.HOME
    }
  }, [params])

  const goToLogin = useCallback(() => history.push(ROUTES.LOGIN))

  const title = t('Auth:verifyEmail')
  return (
    <AuthWrap title={title}>
      <Helmet title={title} />
      <H1>{t('Auth:startStretching')}</H1>
      <BodyText>
        {
          [
            <Fragment key='step-0'>
              <div
                css={css`
                  padding-bottom: 32px;
                `}
              >
                {t('Auth:verifyingAccount')}...
              </div>
              <LoadingSpinner />
            </Fragment>,

            <Fragment key='step-1'>
              <p className='error'>{t('errors:invalidVerifyLink')}</p>
              <p>{t('Auth:enterYourEmailAddress')}</p>
              <ResendVerifyEmail />
              <AuthSecondaryLink
                buttonText={t('common:login')}
                onClick={goToLogin}
                text={t('Auth:orIfYouAlreadyHaveAnAccount')}
              />
            </Fragment>,

            <p key='step-2'>
              {t('errors:emailAlreadyVerified')}
              <FormButton
                as={Link}
                to={ROUTES.LOGIN}
                css={css`
                  display: flex;
                  width: 50%;
                  margin: 32px auto 0;
                `}
              >
                {t('Auth:goToLogin')}
              </FormButton>
            </p>,

            // This needs to be a div, since we can't nest another div inside a <p>
            <div className='body-text-step' key='step-3'>
              <div
                css={css`
                  padding-bottom: 32px;
                `}
              >
                {t('Auth:thanksForVerifying')}
              </div>
              <LoadingSpinner />
            </div>,

            <Redirect key='step-4' to={navigator} />,
          ][step]
        }
      </BodyText>
    </AuthWrap>
  )
}

VerifyEmail.propTypes = {
  location: PropTypes.object.isRequired,
  verify: PropTypes.func.isRequired,
}

export default VerifyEmail
