import React, { useEffect, useState } from 'react'
import { convertToImperial, convertToMetres } from '@sportninja/common/reducers/helpers'
import { t } from '@sportninja/common/i18n'

import { AdjacentInputWrapper, InputWrapper, Label } from '../Form/css'
import { Flex } from '../Layout'

const HeightPicker = ({ defaultValue, disabled, onChange }) => {
  const [ ft, inches ] = convertToImperial(defaultValue)
  const [ height, setHeight ] = useState({ ft, inches })

  useEffect(() => {
    const [ ft, inches ] = convertToImperial(defaultValue)
    setHeight({ ft, inches })
  }, [ defaultValue ])

  const handleChange = (key, e) => {
    const val = e.target.value

    setHeight((state) => {
      const newState = { ...state, [key]: val }
      const value = convertToMetres(newState)

      if (value) onChange({ target: { name: 'height', value } })

      return newState
    })
  }

  return (
    <InputWrapper disabled={disabled}>
      <Label disabled={disabled}>{t('common:height')}</Label>
      <Flex>
        <AdjacentInputWrapper>
          <input
            disabled={disabled}
            value={typeof height.ft === 'number' ? height.ft : undefined}
            onChange={handleChange.bind(this, 'ft')}
            className='left'
            type='number'
            min={0}
            max={10}
            step={1} />
            <span>ft</span>
        </AdjacentInputWrapper>
        <AdjacentInputWrapper>
          <input
            disabled={disabled}
            value={typeof height.inches === 'number' ? height.inches : undefined}
            onChange={handleChange.bind(this, 'inches')}
            className='right'
            type='number'
            min={0}
            max={12}
            step={1} />
            <span>in</span>
        </AdjacentInputWrapper>
      </Flex>
    </InputWrapper>
  )
}

export default HeightPicker
