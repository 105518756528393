import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'

import { Flex } from '../../components/Layout'
import {
  displayLarge,
  font,
  heading5,
  paragraphSmall,
  weightBold,
} from '../../components/css'
import colors from '@sportninja/common/constants/appColors'

export const Wrap = styled(Flex)`
  min-height: ${(props) => props.isDesktop && '138px'};
  max-height: ${(props) => props.isDesktop && '138px'};
  padding: 0 8px;
  margin-bottom: 14px;
  border-radius: 4px;
  color: white;
  flex: 1 1 100%;

  /* background: linear-gradient(
      -80deg,
      transparent 0% 50%,
      rgba(82, 90, 105, 0.82) 50%,
      rgba(142, 160, 190, 0.52)
    ),
    linear-gradient(
      270deg,
      rgba(77, 86, 99, 0.72) 0%,
      rgba(59, 65, 75, 0.72) 50%,
      rgba(51, 55, 63, 0.72) 100%
    ); */

  backdrop-filter: blur(50px);
  border-radius: 8px;

  &.in-progress {
    /* color: black; */

    a {
      /* color: black; */
    }

    /* background: linear-gradient(
        -80deg,
        transparent 0% 50%,
        rgba(255, 255, 255, 0.52) 50%,
        rgb(236, 236, 236, 0.52)
      ),
      linear-gradient(
        270deg,
        rgba(213, 213, 213, 0.72) 0%,
        rgba(235, 235, 235, 0.72) 48.6%,
        rgba(228, 228, 228, 0.72) 100%
      ); */
  }

  &.completed {
    /* background: rgba(22, 23, 23, 0.7); */
  }

  ${(props) =>
    !props.isDesktop &&
    css`
      margin-bottom: 0;
      box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.28);
      padding: 2px 12px 10px;
    `}
`

export const TeamName = styled.div`
  ${heading5}
  ${weightBold}
  letter-spacing: 0.36px;

  &.left {
    margin-left: 12px;
    margin-right: 8px;
  }

  &.right {
    text-align: right;
    margin-left: 8px;
    margin-right: 12px;
  }
`

export const SidewayText = styled.div`
  ${(props) => props.right && 'transform: rotate(180deg);'}
  color: #BCBCBC;
  ${font.body}
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.71px;
  line-height: 16px;
  text-align: center;
  writing-mode: vertical-lr;
  text-orientation: sideways-right;

  .in-progress & {
    color: #7d7d7d;
  }
`

export const imageCss = css`
  margin: 0 8px;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.4);
`

export const mobileImageCss = css`
  box-shadow: 0 0 8px 0 rgba(18, 27, 38, 0.27), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`

export const IndicatorLine = styled.div`
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -6px;
  height: 4px;
  width: 100%;
  background-color: ${colors.PRIMARY};
`

export const Score = styled(Flex)`
  position: relative;
  text-align: center;
  ${displayLarge}
  ${weightBold}
  ${(props) =>
    props.isDesktop
      ? css`
          width: 33%;
          min-width: 33%;
          max-width: 33%;
          font-size: 84px;
        `
      : css`
          align-items: flex-end;
          width: 24px;
          font-size: 40px;
        `}

  .completed & {
    color: #a7a7a7;
  }

  &.winner {
    color: white;

    ${IndicatorLine} {
      display: block;
    }
  }
`

export const Final = styled.div`
  ${weightBold}
  text-align: center;
  text-transform: uppercase;

  ${(props) =>
    props.isDesktop
      ? css`
          ${heading5}
          width: 33%;
          max-width: 33%;
          min-width: 33%;
          color: #dcdcdc;
          margin: 6px 12px 0;
        `
      : css`
          ${paragraphSmall}
          padding-top: 4px;
          margin: 0 8px;
          color: #ffffff;
          max-width: 40%;
        `}
`

export const Time = styled.div`
  text-align: center;

  ${(props) =>
    props.isDesktop
      ? css`
          width: 33%;
          min-width: 33%;
          max-width: 33%;
          font-size: 20px;
          letter-spacing: 2px;
          line-height: 20px;
          margin: 0 12px;
        `
      : css`
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding-top: 4px;
          margin: 0 8px;
          font-size: 14px;
          letter-spacing: 0.71px;
          line-height: 14px;
          min-width: 42px;
        `}
`

export const VS = styled.div`
  ${font.title}
  font-weight: bold;
  line-height: 44px;
  text-align: center;

  ${(props) =>
    props.isDesktop
      ? css`
          font-size: 68px;
          letter-spacing: 0.99px;
        `
      : css`
          display: flex;
          align-items: flex-end;
          font-size: 47px;
          letter-spacing: 0.68px;
        `}
`

export const MobileHomeVisitorText = styled.div`
  margin-bottom: 2px;
  text-transform: uppercase;
  color: #c0c4c8;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.59px;
  line-height: 16px;
  text-align: center;

  .in-progress &,
  .completed & {
    color: #878d94;
  }
`
