// A helper utility for displaying banners
// Usage: BannerController.add(component, { options }) where component is a React component
// See src/components/Banner/index.jsx for props that are provided to the component
const BannerController = {
  listener: {},

  add(component, options) {
    this.listener.add(component, options)
  },

  remove(idx) {
    this.listener.remove(idx)
  },

  set(add, remove) {
    this.listener.add = add
    this.listener.remove = remove
  },
}

export default BannerController
