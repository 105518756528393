import { call, put } from 'redux-saga/effects'

import actions from '../actions/shootout'
import { generateSagas } from './utils'
import attemptScoringRequest from './attempt-scoring-request'
import scoringRequest from './scoring-request'
import req from '../api/request'

const api = {
  read: async (id) => {
    return await req(`/games/${id}/shootout`)
  },
  create: async (id, body) => {
    return await scoringRequest(`/games/${id}/shootout`, {
      method: 'POST',
      body,
    })
  },
  update: async (id, shotId, body) => {
    return await scoringRequest(`/games/${id}/shootout/${shotId}`, {
      method: 'PUT',
      body,
    })
  },
  delete: async (id, shotId) => {
    return await scoringRequest(`/games/${id}/shootout/${shotId}`, {
      method: 'DELETE',
    })
  },

  end: async (id) => {
    return await scoringRequest(`/games/${id}/shootout/end`, {
      method: 'PUT',
    })
  },
}

const shootout = [
  [
    actions.read,
    function* (payload) {
      const { id } = payload
      const response = yield call(api.read, id)

      yield put(actions.read.success({ id, data: response.data }))
      return response
    },
  ],
  [
    actions.create,
    function* (payload) {
      const { id, form } = payload

      return yield attemptScoringRequest(
        function* () {
          const response = yield call(api.create, id, JSON.stringify(form))
          yield put(actions.create.success({ id, data: response.data }))

          return response
        },
        { type: actions.create.request, payload }
      )
    },
  ],

  [
    actions.update,
    function* (payload) {
      const { id, shotId, form } = payload

      return yield attemptScoringRequest(
        function* () {
          const response = yield call(
            api.update,
            id,
            shotId,
            JSON.stringify(form)
          )
          yield put(
            actions.update.success({
              id,
              shotId,
              data: response.data,
            })
          )

          return response
        },
        { type: actions.update.request, payload }
      )
    },
  ],

  [
    actions.delete,
    function* (payload) {
      const { id, shotId } = payload

      return yield attemptScoringRequest(
        function* () {
          const response = yield call(api.delete, id, shotId)
          yield put(actions.delete.success({ id, shotId }))

          return response
        },
        { type: actions.delete.request, payload }
      )
    },
  ],

  [
    actions.end,
    function* (payload) {
      const { id } = payload

      return yield attemptScoringRequest(
        function* () {
          const response = yield call(api.end, id)
          yield put(actions.end.success({ id }))

          return response
        },
        { type: actions.end.request, payload }
      )
    },
  ],
]

export default generateSagas([...shootout])
