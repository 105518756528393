/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import Icon from 'src/components/Icon'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { useMobxStore } from 'src/state'
import { Checkbox } from 'antd'

type Response = {
  url?: string
  message: string
  onboarded: boolean
}

type AccountDetails = {
  message: string
  verified: boolean
  onboarded: boolean
  account: {
    id: string
    object: string
    business_profile: {
      mcc: string
      name: string
      support_address: {
        city: string
        country: string
        line1: string
        line2: any | null
        postal_code: string
        state: string
      }
      support_email: any | null
      support_phone: string
      support_url: any | null
      url: string
    }
    capabilities: {
      acss_debit_payments: string
      affirm_payments: string
      afterpay_clearpay_payments: string
      bancontact_payments: string
      card_payments: string
      cartes_bancaires_payments: string
      cashapp_payments: string
      eps_payments: string
      giropay_payments: string
      ideal_payments: string
      klarna_payments: string
      link_payments: string
      p24_payments: string
      sepa_debit_payments: string
      transfers: string
      us_bank_account_ach_payments: string
    }
    charges_enabled: boolean
    controller: {
      is_controller: boolean
      type: string
    }
    country: string
    created: number
    default_currency: string
    details_submitted: boolean
    email: string
    external_accounts: {
      object: string
      data: [
        {
          id: string
          object: string
          account: string
          account_holder_name: any | null
          account_holder_type: any | null
          account_type: any | null
          available_payout_methods: string[]
          bank_name: string
          country: string
          currency: string
          default_for_currency: boolean
          fingerprint: string
          future_requirements: {
            currently_due: any[]
            errors: any[]
            past_due: any[]
            pending_verification: any[]
          }
          last4: string
          metadata: any[]
          requirements: {
            currently_due: any[]
            errors: any[]
            past_due: any[]
            pending_verification: any[]
          }
          routing_number: string
          status: string
        }
      ]
      has_more: false
      total_count: number
      url: string
    }
    future_requirements: {
      alternatives: any[]
      current_deadline: any | null
      currently_due: any[]
      disabled_reason: any | null
      errors: any[]
      eventually_due: any[]
      past_due: any[]
      pending_verification: any[]
    }
    metadata: any[]
    payouts_enabled: boolean
    requirements: {
      alternatives: any[]
      current_deadline: any | null
      currently_due: any[]
      disabled_reason: any | null
      errors: any[]
      eventually_due: string[]
      past_due: any[]
      pending_verification: any[]
    }
    settings: {
      bacs_debit_payments: any[]
      branding: {
        icon: any | null
        logo: any | null
        primary_color: any | null
        secondary_color: any | null
      }
      card_issuing: {
        tos_acceptance: {
          date: any | null
          ip: any | null
        }
      }
      card_payments: {
        decline_on: {
          avs_failure: boolean
          cvc_failure: boolean
        }
        statement_descriptor_prefix: string
        statement_descriptor_prefix_kana: any | null
        statement_descriptor_prefix_kanji: any | null
      }
      dashboard: {
        display_name: string
        timezone: string
      }
      payments: {
        statement_descriptor: string
        statement_descriptor_kana: any | null
        statement_descriptor_kanji: any | null
      }
      payouts: {
        debit_negative_balances: boolean
        schedule: {
          delay_days: number
          interval: string
        }
        statement_descriptor: any | null
      }
      sepa_debit_payments: any[]
    }
    tos_acceptance: {
      date: number
    }
    type: string
  }
}

const fetchConnectedAccountInfo = async (
  accountId: string
): Promise<Response> => {
  try {
    const response: Response = await req(`/account/${accountId}/onboarding`)

    return response
  } catch (error: any) {
    throw new Error(JSON.stringify(error))
  }
}

const fetchStripeAccountInfo = async (
  accountId: string
): Promise<AccountDetails> => {
  try {
    const response: AccountDetails = await req(`/account/${accountId}/details`)

    return response
  } catch (error: any) {
    throw new Error(JSON.stringify(error))
  }
}

const Loader = () => (
  <div
    css={css`
      background-color: ${colors.HEADER};
      padding: 16px;
      border-radius: 8px;
      border: 1px solid ${colors.SOFT_STEEL};
      max-width: 820px;
      margin: 0 auto;
      margin-top: 16px;
    `}
  >
    <LoadingSpinner size={3} />
  </div>
)

const AccountInfoRow = ({
  label,
  value,
  capitalize = true,
  uppercase = false,
}: {
  label: string
  value: string
  capitalize?: boolean
  uppercase?: boolean
}) => {
  if (value === '') {
    return null
  }

  return (
    <div
      css={css`
        display: flex;
        gap: 4px;
      `}
    >
      <p
        css={css`
          font-size: 14px;
          font-weight: 700;
        `}
      >
        {label}:
      </p>
      <p
        css={css`
          font-size: 14px;
          text-transform: ${capitalize
            ? 'capitalize'
            : uppercase
            ? 'uppercase'
            : 'none'};
        `}
      >
        {value}
      </p>
    </div>
  )
}

const AccountInfo = ({ accountId }: { accountId: string }) => {
  const [loading, setLoading] = useState(true)
  const [stripeAccount, setStripeAccount] = useState<AccountDetails | null>(
    null
  )
  const [error, setError] = useState(false)

  useEffect(() => {
    if (accountId) {
      fetchStripeAccountInfo(accountId)
        .then((response) => {
          setStripeAccount(response)
        })
        .catch((error) => {
          console.error('Error on Fetching Connected Account', error)
          setError(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [accountId])

  if (loading) {
    return <Loader />
  }

  if (error) {
    return (
      <div
        css={css`
          background-color: ${colors.HEADER};
          padding: 16px;
          border-radius: 8px;
          border: 1px solid ${colors.SOFT_STEEL};
          max-width: 820px;
          margin: 0 auto;
          margin-top: 16px;
        `}
      >
        <p>Something goes wrong</p>
      </div>
    )
  }

  return (
    <div
      css={css`
        background-color: ${colors.HEADER};
        padding: 16px;
        border-radius: 8px;
        border: 1px solid ${colors.SOFT_STEEL};
        max-width: 820px;
        margin: 0 auto;
        margin-top: 16px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div>
          <p
            css={css`
              font-family: 'Rift', sans-serif;
              font-size: 24px;
              font-weight: 700;
            `}
          >
            Payment Provider
          </p>
          <p
            css={css`
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 100%; /* 12px */
              margin-top: 4px;
              color: ${colors.ATTENDANCE_GRAY};
            `}
          >
            Link your payment processing account to manage registration
            transactions.
          </p>
        </div>
      </div>
      <div
        css={css`
          margin-top: 16px;
          padding-top: 16px;
          border-top: 1px solid ${colors.SOFT_STEEL};
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <p
            css={css`
              font-family: 'Rift', sans-serif;
              font-size: 24px;
              font-weight: 700;
            `}
          >
            Stripe
          </p>
          <a
            css={css`
              display: flex;
              gap: 6px;
              align-items: center;
            `}
            href='https://dashboard.stripe.com/'
            target='_blank'
            rel='noreferrer'
          >
            <p
              css={css`
                font-size: 14px;
                color: ${colors.DEFAULT_FLAIR};
              `}
            >
              Go to Stripe Dashboard
            </p>
            <Icon name='external-link-alt' color={colors.DEFAULT_FLAIR} />
          </a>
        </div>

        <div
          css={css`
            margin-top: 16px;
            display: flex;
            gap: 16px;
          `}
        >
          <div
            css={css`
              padding: 16px;
              border-radius: 8px;
              border: 1px dashed ${colors.SOFT_STEEL};
              flex: 1;
            `}
          >
            <p
              css={css`
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 12px */
                padding-bottom: 8px;
              `}
            >
              Business Info
            </p>
            <div
              css={css`
                display: flex;
              `}
            >
              <div
                css={css`
                  flex: 1;
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                `}
              >
                {stripeAccount?.account?.business_profile?.name && (
                  <p
                    css={css`
                      font-size: 24px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 100%; /* 24px */
                      font-family: 'Rift', sans-serif;
                    `}
                  >
                    {stripeAccount?.account?.business_profile?.name}
                  </p>
                )}
                {stripeAccount?.account?.business_profile?.support_address
                  ?.city && (
                  <AccountInfoRow
                    label='City'
                    value={
                      stripeAccount?.account?.business_profile?.support_address
                        ?.city || ''
                    }
                  />
                )}
                {stripeAccount?.account?.business_profile?.support_address
                  ?.state && (
                  <AccountInfoRow
                    label='State/Province'
                    value={
                      stripeAccount?.account?.business_profile?.support_address
                        ?.state || ''
                    }
                  />
                )}
                {stripeAccount?.account?.business_profile?.support_address
                  ?.country && (
                  <AccountInfoRow
                    label='Country'
                    value={
                      stripeAccount?.account?.business_profile?.support_address
                        ?.country || ''
                    }
                  />
                )}
                {stripeAccount?.account?.business_profile?.support_phone && (
                  <AccountInfoRow
                    label='Phone'
                    value={
                      stripeAccount?.account?.business_profile?.support_phone ||
                      ''
                    }
                  />
                )}
                {stripeAccount?.account?.business_profile?.support_email && (
                  <AccountInfoRow
                    label='Email'
                    capitalize={false}
                    value={
                      stripeAccount?.account?.business_profile?.support_email ||
                      ''
                    }
                  />
                )}
                {stripeAccount?.account?.business_profile?.url && (
                  <AccountInfoRow
                    label='Website'
                    capitalize={false}
                    value={stripeAccount?.account?.business_profile?.url || ''}
                  />
                )}
              </div>
            </div>
          </div>

          <div
            css={css`
              padding: 16px;
              border-radius: 8px;
              border: 1px dashed ${colors.SOFT_STEEL};
              flex: 1;
            `}
          >
            <p
              css={css`
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 12px */
                padding-bottom: 8px;
              `}
            >
              Account Details
            </p>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 8px;
              `}
            >
              <AccountInfoRow
                value={stripeAccount?.account?.type || ''}
                label='Account Type'
              />
              <AccountInfoRow
                value={stripeAccount?.account?.default_currency || ''}
                label='Default Currency'
                capitalize={false}
                uppercase
              />
              <AccountInfoRow
                capitalize={false}
                value={stripeAccount?.account?.email || ''}
                label='Account Email'
              />
              <Checkbox
                checked={stripeAccount?.account?.details_submitted}
                css={css`
                  cursor: default !important;
                  font-family: 'Maison Neue', sans-serif !important;
                  color: ${colors.WHITE};
                `}
              >
                Details Submitted
              </Checkbox>
              <Checkbox
                checked={stripeAccount?.account?.charges_enabled}
                css={css`
                  cursor: default !important;
                  font-family: 'Maison Neue', sans-serif !important;
                  color: ${colors.WHITE};
                `}
              >
                Can Receive Payments
              </Checkbox>
              <Checkbox
                checked={stripeAccount?.account?.payouts_enabled}
                css={css`
                  cursor: default !important;
                  font-family: 'Maison Neue', sans-serif !important;
                  color: ${colors.WHITE};
                `}
              >
                Payouts Enabled
              </Checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ConnectedAccount = observer(() => {
  const {
    me: {
      state: { account },
    },
  } = useMobxStore()
  const [loading, setLoading] = useState(true)
  const [stripeLink, setStripeLink] = useState<string | any | null>(null)
  const [isOnboarded, setIsOnboarded] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (account?.uid) {
      fetchConnectedAccountInfo(account?.uid)
        .then((response) => {
          if (!response.onboarded) {
            setStripeLink(response.url!)
          }
          setIsOnboarded(response.onboarded)
        })
        .catch((error) => {
          console.error('Error on Fetching Connected Account', error)
          setError(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [account])

  if (loading) {
    return <Loader />
  }

  if (error) {
    return (
      <div
        css={css`
          background-color: ${colors.HEADER};
          padding: 16px;
          border-radius: 8px;
          border: 1px solid ${colors.SOFT_STEEL};
          max-width: 820px;
          margin: 0 auto;
          margin-top: 16px;
        `}
      >
        <p>Something goes wrong</p>
      </div>
    )
  }

  if (isOnboarded && account?.uid) {
    return <AccountInfo accountId={account?.uid} />
  }

  return (
    <div
      css={css`
        background-color: ${colors.HEADER};
        padding: 16px;
        border-radius: 8px;
        border: 1px solid ${colors.SOFT_STEEL};
        max-width: 820px;
        margin: 0 auto;
        margin-top: 16px;
      `}
    >
      <a
        css={css`
          color: ${colors.DEFAULT_FLAIR};
          font-size: 16px;
          display: flex;
          gap: 8px;
          align-items: center;
        `}
        href={`${stripeLink}`}
        target='_self'
      >
        <p>Connect your Stripe account</p>
        <Icon name='external-link-alt' color={colors.DEFAULT_FLAIR} />
      </a>
    </div>
  )
})
