import { produce } from 'immer'
import merge from 'lodash.merge'

import shootoutActions from '../actions/shootout'
import actions from '../actions/shootout'
import { prepareOfflineTransformer, str } from './utils'
import shootoutOfflineTransformer from './shootout-offline-handler'

const shootoutReducer = (draft, { type: _type, payload: _payload, meta }) => {
  const { type, payload } = prepareOfflineTransformer(
    _type,
    _payload,
    meta,
    shootoutActions,
    shootoutOfflineTransformer,
    draft
  )

  switch (
    type // eslint-disable-line default-case
  ) {
    case str(actions.read.success): {
      const { id: gameId, data } = payload
      draft[gameId] = draft[gameId] || {}
      draft[gameId].data = data
      return
    }

    case str(actions.create.success): {
      const { id: gameId, data } = payload
      draft[gameId] = draft[gameId] || {}
      draft[gameId].data = draft[gameId].data || []
      draft[gameId].data.push(data)
      return
    }

    case str(actions.update.success): {
      const { id: gameId, shotId, data } = payload
      const shootout = draft[gameId].data

      const shotIdx = shootout.findIndex((s) => {
        return s.id === shotId
      })

      if (shotIdx > -1) {
        merge(shootout[shotIdx], data)
      }
      return
    }

    case str(actions.delete.success): {
      const { id: gameId, shotId } = payload
      const shootout = draft[gameId].data

      const shotIdx = shootout.findIndex((s) => {
        return s.id === shotId
      })

      if (shotIdx > -1) {
        shootout.splice(shotIdx, 1)
      }
      return
    }

    case str(actions.end.success): {
      draft[payload.id] = draft[payload.id] || {}
      draft[payload.id].ended = true
      return
    }

    case 'SET_SHOOTOUT_GOALIE': {
      draft[payload.id] = draft[payload.id] || {}

      if (payload.isHome) {
        draft[payload.id].homeGoalieId = payload.data
      } else {
        draft[payload.id].visitorGoalieId = payload.data
      }
      return
    }
  }
}

export default (state = {}, action) =>
  produce(state, (draft) => {
    return shootoutReducer(draft, action)
  })
