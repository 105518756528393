/** @jsxImportSource @emotion/react */

import { ClassNames } from '@emotion/react'

interface Props {
  className?: string
  color?: string
  hoverColor?: string
  faType?: string
  fontSize?: number
  name: string
  title?: string
}

const Icon = ({
  className,
  color = 'white',
  hoverColor,
  fontSize,
  name,
  title,
  faType = 'fas',
  ...props
}: Props) => (
  <ClassNames>
    {({ css, cx }) => (
      <i
        className={cx(
          className,
          `${faType} fa-${name}`,
          css`
            color: ${color};
            font-size: ${fontSize ? `${fontSize}px` : 'inherit'};
            ${hoverColor &&
            `@media (hover: hover) {
                :hover {
                  color: ${hoverColor};
                }
              }`}
          `
        )}
        title={title}
        {...props}
      />
    )}
  </ClassNames>
)

export default Icon
