
import React, { useEffect, useState } from 'react'

import { AdjacentInputWrapper, InputWrapper, Label } from './Form/css'
import { Flex } from './Layout'
import { timeStringFromMS } from '@sportninja/common/utils/utils'

export const periodClockValidator = (values) => {
  let message = false

  if (values.duration === 0) message = 'Please enter a period length greater than 0'
  return message && { duration: message }
}

const parseClockTime = (clockTime) => {
  if (!clockTime || clockTime.length === 0) return [ '', '' ]

  const bits = clockTime.split(':')
  if (bits.length === 2) {
    return [ parseInt(bits[0], 10), parseInt(bits[1], 10) ]
  }

  if (bits.length === 3) {
    return [ parseInt(bits[1], 10), parseInt(bits[2], 10) ]
  }
}

const padNum = (num) => {
  const str = `${num}`
  if (str.length < 2) return `0${str}`
  else return str
}

const getClockTime = ({ min, sec }) => {
  return `00:${padNum(min)}:${padNum(sec)}`
}

const PeriodClockTime = ({ defaultValue, disabled, hasError, isDuration, label, name, noFlex, onChange, required }) => {
  const [ min, sec ] = isDuration ? timeStringFromMS(defaultValue, true) : parseClockTime(defaultValue)
  const [ time, setTime ] = useState({ min, sec })

  useEffect(() => {
    const [ min, sec ] = isDuration ? timeStringFromMS(defaultValue, true) : parseClockTime(defaultValue)
    setTime({ min, sec })
  }, [ defaultValue ])

  const handleChange = (key, e) => {
    const val = e.target.value

    setTime((state) => {
      const newState = { ...state, [key]: val }
      // Convert to milliseconds
      const value = isDuration ?
        ((parseInt(newState.min, 10) * 60) + parseInt(newState.sec, 10)) * 1000
        :
        getClockTime(newState)

      onChange({ target: { name, value } })

      return newState
    })
  }

  return (
    <InputWrapper disabled={disabled} noFlex={noFlex}>
      <Label disabled={disabled}>{label}{required ? ' *' : ''}</Label>
      <Flex>
        <AdjacentInputWrapper disabled={disabled}>
          <input
            disabled={disabled}
            value={time.min}
            onChange={handleChange.bind(this, 'min')}
            className={`left ${hasError ? 'error' : ''}`}
            type='number'
            min={0}
            max={60}
            step={1}
            required />
            <span>{isDuration ? 'min' : ':'}</span>
        </AdjacentInputWrapper>
        <AdjacentInputWrapper disabled={disabled}>
          <input
            disabled={disabled}
            value={time.sec}
            onChange={handleChange.bind(this, 'sec')}
            className={`right ${hasError ? 'error' : ''}`}
            type='number'
            min={0}
            max={59}
            step={1}
            required />
            {isDuration && <span>sec</span>}
        </AdjacentInputWrapper>
      </Flex>
    </InputWrapper>
  )
}

export default PeriodClockTime
