import { generateActions } from './utils'
import * as shared from './shared-definitions'

// A list of all available actions for the schedule entity. The array describes
// the arguments that are passed to the REQUEST action
export const definition = {
  create: ['form'],
  read: ['id'],
  update: ['id', 'form'],
  delete: ['id', 'parentId', 'forceDelete'],

  games: {
    read: [
      'teamId',
      'starts_at',
      'ends_at',
      'id',
      'page',
      'sort',
      'direction',
      'tz',
    ],
  },

  teams: {
    create: ['id', 'form'],
    read: ['id', 'page', 'sort', 'direction'],
    delete: ['id', 'team_id', 'forceDelete'],
  },

  ...shared.entityImageDefinition,
  ...shared.venuesDefinition,
  ...shared.usersDefinition,
  ...shared.feedDefinition,
  ...shared.officialsDefinition,
}

export default generateActions(['SCHED'], definition)
