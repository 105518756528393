/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import { t } from '@sportninja/common/i18n'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import flattenDeep from 'lodash.flattendeep'
import cn from 'classnames'
import { colors } from '@sportninja/common/constants/appColors'
import { convertToImperial } from '@sportninja/common/reducers/helpers'

import { backgroundColorLight, borderColor, font } from '../../components/css'
import Icon from '../../components/Icon'
import { Flex } from '../../components/Layout'
import LoadingSpinner from '../../components/LoadingSpinner'
import { media } from '../../components/Responsive'

export const getAttributes = ({ leaguestat_id, salesforce_id } = {}) => {
  const attr = {
    nameFirst: t('AccountScreen:firstName'),
    nameMiddle: t('AccountScreen:middleName'),
    nameLast: t('AccountScreen:lastName'),
    birthDate: t('Auth:dateOfBirth'),
    email: t('common:email'),
    height: t('common:height'),
    weight: t('common:weight'),
    id: {
      label: 'Player ID',
      readOnly: true,
    },
  }

  if (typeof leaguestat_id === 'number') {
    attr.leaguestat_id = {
      label: 'LS ID',
      readOnly: true,
    }
  }
  if (typeof salesforce_id === 'string' && salesforce_id.length > 0) {
    attr.salesforce_id = {
      label: 'Salesforce ID',
      readOnly: true,
    }
  }

  return attr
}

const columns = {
  Team: {
    accessor: 'teamName',
    width: 110,
  },
  Season: {
    accessor: 'seasonName',
    width: 120,
  },
  'Start - End': {
    accessor: '',
    width: 100,
  },
  GP: {
    accessor: 'gamesPlayed',
    width: 30,
  },
}

const MergePlayersItem = ({
  idx,
  isSelectedPlayer,
  player,
  onRemove,
  onRowClick,
  setSelectedPlayer,
}) => {
  const [loading, setLoading] = useState(false)
  const [teams, setTeams] = useState([])
  const [error, setError] = useState(false)

  const attributes = getAttributes(player)

  const id = player.id

  useEffect(() => {
    const process = (data) => {
      return Object.values(data)
        .filter((d) => d.has_skater_stats || d.has_goalie_stats)
        .map(({ team, season, stats }) => {
          const gpStat = stats.find((stat) => stat.abbr === 'GP')

          return {
            teamName: team.name_full,
            teamId: team.id,
            seasonId: season.id,
            seasonName: season.name_full,
            seasonStart: dayjs(season.starts_at).format("MMM D, [']YY"),
            seasonEnd: dayjs(season.ends_at).format("MMM D, [']YY"),
            gamesPlayed: gpStat ? gpStat.value : 0,
          }
        })
    }

    setLoading(true)
    const promises = []
    promises.push(req(`/players/${id}/stats/canlan?goalie=0`).then(process))
    promises.push(req(`/players/${id}/stats/canlan?goalie=1`).then(process))
    Promise.all(promises)
      .then((data) => {
        setTeams(flattenDeep(data))
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        setError(true)
      })
  }, [id])

  return (
    <div
      css={css`
        margin: 20px 20px 20px 0;

        ${media.mobile} {
          margin: 0;
          margin-bottom: 20px;
        }
      `}
    >
      <Flex
        justifyContent='center'
        css={css`
          margin-bottom: 8px;
        `}
      >
        <div
          onClick={() => setSelectedPlayer(isSelectedPlayer ? false : id)}
          css={css`
            border: 1px solid ${borderColor};
            border-radius: 2px;
            padding: 16px 12px;
            cursor: pointer;
            ${isSelectedPlayer && 'border-color: white;'}

            :hover {
              background-color: rgba(255, 255, 255, 0.05);
            }
          `}
        >
          Keep Player {idx + 1}
          <Icon
            name={isSelectedPlayer ? 'check-circle' : 'circle'}
            color={isSelectedPlayer ? colors.PRIMARY : backgroundColorLight}
            css={css`
              border: 1px solid ${isSelectedPlayer ? 'transparent' : 'white'};
              border-radius: 50%;
              margin-left: 12px;
              width: 16px;
            `}
          />
        </div>
        <button
          onClick={onRemove}
          title='Remove player from list'
          css={css`
            border: 1px solid ${borderColor};
            border-radius: 2px;
            padding: 16px 16px;
            cursor: pointer;
            margin-left: 8px;
            :hover {
              background-color: rgba(255, 255, 255, 0.05);
            }
          `}
        >
          <Icon name='times' fontSize={18} />
        </button>
      </Flex>
      <div
        css={css`
          min-width: 275px;
          border: 1px solid ${borderColor};
          border-radius: 2px;
          padding: 16px 12px 8px;

          .title {
            display: flex;
            justify-content: space-between;
            font-weight: bold;
            ${font.title}
            text-transform: uppercase;
            font-size: 18px;
            margin-bottom: 16px;
            text-align: center;
            padding: 0 4px;
          }

          .row {
            display: flex;
            justify-content: space-between;
            padding: 16px 8px 16px 10px;
            margin-bottom: 4px;
            border: 1px solid transparent;
            border-radius: 2px;

            &.can-click {
              cursor: pointer;
              &:hover {
                background-color: rgba(255, 255, 255, 0.05);
              }
            }

            &.is-clicked {
              border-color: white;
            }

            &:nth-of-type(odd) {
              background-color: rgba(255, 255, 255, 0.025);
            }
          }
        `}
      >
        {Object.keys(attributes).map((attribute) => {
          let value = player[attribute]
          if (attribute === 'birthDate' && value)
            value = dayjs(value).format('MMM D, YYYY')
          else if (attribute === 'height' && value) {
            const [ft, inch] = convertToImperial(value)
            value = `${ft}'${inch}"`
          } else if (attribute === 'weight' && value) value = `${value} lbs`
          const isRowClicked = !!player?.properties?.includes(attribute)
          const canClick =
            value !== null &&
            typeof value !== 'undefined' &&
            !attributes[attribute]?.readOnly

          const label =
            typeof attributes[attribute] === 'object'
              ? attributes[attribute]?.label
              : attributes[attribute]

          return (
            <div
              key={attribute}
              className={cn('row', {
                'is-clicked': isRowClicked,
                'can-click': canClick,
              })}
              onClick={() => {
                if (!canClick) return false
                onRowClick(id, attribute, isRowClicked)
              }}
            >
              <span
                css={css`
                  ${font.title}
                  font-weight: bold;
                  text-transform: uppercase;
                `}
              >
                {label}:
              </span>
              <span>
                {value || '-'}
                {value && canClick ? (
                  <Icon
                    name={isRowClicked ? 'check-circle' : 'circle'}
                    color={isRowClicked ? colors.PRIMARY : backgroundColorLight}
                    css={css`
                      border: 1px solid
                        ${isRowClicked ? 'transparent' : 'white'};
                      border-radius: 50%;
                      width: 16px;
                      margin-left: 12px;
                    `}
                  />
                ) : (
                  <div
                    css={css`
                      margin-left: 32px;
                    `}
                  />
                )}
              </span>
            </div>
          )
        })}
      </div>
      <div
        css={css`
          border: 1px solid ${borderColor};
          border-radius: 2px;
          padding: 16px 12px 4px;
          margin-top: 8px;
        `}
      >
        <Flex
          css={css`
            margin-bottom: 8px;
          `}
        >
          {Object.keys(columns).map((headerText) => {
            const width = columns[headerText].width
            return (
              <div
                key={headerText}
                css={css`
                  width: ${width}px;
                  margin: 0 8px;
                  ${font.title}
                  text-transform: uppercase;
                  font-weight: bold;
                `}
              >
                {headerText}
              </div>
            )
          })}
        </Flex>
        {error ? (
          <div
            css={css`
              text-align: center;
              padding: 16px;
            `}
          >
            Could not load statistics
          </div>
        ) : loading ? (
          <LoadingSpinner size={3} style={{ marginTop: 8 }} />
        ) : teams.length === 0 ? (
          <div
            css={css`
              text-align: center;
              padding: 16px;
            `}
          >
            No statistics available
          </div>
        ) : (
          teams.map((team, idx) => {
            return (
              <Flex key={idx}>
                {Object.keys(columns).map((key, idx) => {
                  const accessor = columns[key].accessor
                  let value = team[accessor]
                  if (key === 'Start - End')
                    value = `${team.seasonStart} - ${team.seasonEnd}`
                  return (
                    <div
                      key={idx}
                      css={css`
                        width: ${columns[key].width}px;
                        margin: 0 8px 8px;
                      `}
                    >
                      {value}
                    </div>
                  )
                })}
              </Flex>
            )
          })
        )}
      </div>
    </div>
  )
}

export default MergePlayersItem
