import { useMemo } from 'react'
import { walk } from '@nosferatu500/react-sortable-tree'

const getNodeKey = ({ node }) => node.id

const canNodeHaveChildren = ({ nodeType }) => {
  if (nodeType === 'Team') {
    return false
  }
  return true
}

const getCurrentNode = (treeData, path, newData = false) => {
  if (!Array.isArray(path)) {
    return {}
  }
  const currentLevel = treeData.find((node) => node.id === path[0])
  if (path.length > 1) {
    return getCurrentNode(currentLevel.children, path.slice(1))
  } else {
    if (newData) {
      return { ...currentLevel, ...newData }
    }
    return currentLevel
  }
}

const parseTeam = (team, parentScheduleId) => {
  const mapped = {
    expanded: true,
    scheduleId: parentScheduleId,
    id: `${parentScheduleId}-${team.id}`,
    teamId: team.id,
    title: team.name,
    subtitle: 'Team',
    nodeType: 'Team',
    seed: team.seed,
    seedOrigin: team.seed_origin,
  }

  if (team.image) {
    mapped.image = team.image
  }

  return mapped
}

const teamSorter = (teamA, teamB) => {
  const keyToSortBy = 'seed'
  const valueA = teamA[keyToSortBy]
  const valueB = teamB[keyToSortBy]
  if (valueA < valueB) {
    return -1
  } else if (valueA > valueB) {
    return 1
  }
  return 0
}

const transformTree = (children, isLevelExpanded = true) => {
  return children.map((schedule) => {
    const mapped = {
      id: schedule.id,
      title: schedule.name_full,
      subtitle: schedule.type,
      expanded: isLevelExpanded,
      settings: schedule.settings,
      day_of_week: schedule.day_of_week,
      deposit: schedule.deposit,
      division_age: schedule.division_age,
      division_gender: schedule.division_gender,
      division_group: schedule.division_group,
      team_cost: schedule.team_cost,
      is_active: schedule.is_active,
      salesforce_id: schedule?.salesforce_id,
      season_type: schedule?.season_type,
      online_registration_open: schedule?.online_registration_open,
      season_currency: schedule?.season_currency,
      tax_rate: schedule?.tax_rate,
      sort_order: schedule?.sort_order,
      lock_roster_date: schedule?.lock_roster_date,
    }

    if (Array.isArray(schedule.teams)) {
      mapped.children = schedule.teams
        .sort(teamSorter)
        .map((team) => parseTeam(team, schedule.id))
    } else if (schedule.children && schedule.children.data) {
      mapped.children = transformTree(schedule.children.data)
    }

    return mapped
  })
}

const isTeamNode = (node) => {
  if (!node) {
    return false
  }
  return node.nodeType === 'Team'
}

const hasKey = (object, key) => {
  return Object.prototype.hasOwnProperty.call(object, key)
}

const getTreeInfo = (treeData) => {
  return useMemo(() => {
    const teams = [],
      depthMetadata = []
    let lastTreeIndex = 0

    walk({
      treeData,
      getNodeKey: utils.getNodeKey,
      callback: ({ node, path }) => {
        lastTreeIndex++

        if (utils.isTeamNode(node)) {
          teams.push(node.id)
        } else {
          const depthIndex = path.length - 1
          const dataIndex = depthMetadata[depthIndex]

          if (!dataIndex) {
            depthMetadata[depthIndex] = {
              count: 1,
              type: node.subtitle,
              settings: node.settings,
              nodeIds: [node.id],
              nodes: [node],
            }
          } else {
            depthMetadata[depthIndex].count++
            depthMetadata[depthIndex].nodeIds.push(node.id)
            depthMetadata[depthIndex].nodes.push(node)
          }
        }
      },
      ignoreCollapsed: false,
    })

    return {
      lastTreeIndex,
      teams,
      depthMetadata,
      compDepthCount: depthMetadata.length,
    }
  }, [treeData])
}

const utils = {
  getNodeKey,
  canNodeHaveChildren,
  getCurrentNode,
  getTreeInfo,
  hasKey,
  transformTree,
  isTeamNode,
  parseTeam,
}

export default utils
