/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import colors from '@sportninja/common/constants/appColors'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { ENTITY_TYPES, ENTITY_TYPE_INFO } from '@sportninja/common/sagas/utils'
import { getFeedPostDate } from '@sportninja/common/utils/utils'
import { t } from '@sportninja/common/i18n'
import req from '@sportninja/common/api/request'
import { isCanlan } from '@sportninja/common/utils/customer-name'

import Picture from '../../components/Picture'
import Icon from '../../components/Icon'
import { Flex } from '../../components/Layout'
import LoadingSpinner from '../../components/LoadingSpinner'
import FormSheet from '../../components/Form/FormSheet'
import { feedItemForm } from '../../components/Form/form-configs'
import DeleteModal from '../../components/Modal/DeleteModal'
import sportninjaLogo from './sportninja-post.png'
import canlanLogo from './canlan-post.png'
import verifiedBanner from './verified.png'

const HomePost = ({
  canEdit,
  canDelete,
  post,
  isMobile,
  refreshFeed,
  entityType,
  entityId,
  isInFrame,
}) => {
  const {
    id,
    body,
    created_at,
    imagePath,
    imageWidth,
    imageHeight,
    is_liked,
    parent,
    parent_id,
    parent_type,
    url,
    title,
  } = post

  const [isLiked, setIsLiked] = useState(is_liked)
  const [likeInProgress, setLikeInProgress] = useState(false)
  const [likeCount, setLikeCount] = useState(post?.like_count)

  const onLikePressed = async () => {
    setLikeInProgress(true)
    try {
      if (isLiked) {
        await req(`/social-feed/${id}/like`, { method: 'DELETE' })
        setIsLiked(false)
        if (likeCount > 0) {
          setLikeCount(likeCount - 1)
        }
      } else {
        await req(`/social-feed/${id}/like`, { method: 'POST' })
        setIsLiked(true)
        if (likeCount >= 0) {
          setLikeCount(likeCount + 1)
        }
      }
    } finally {
      setLikeInProgress(false)
    }
  }

  let iconName, to
  switch (parent_type) {
    case 'organization':
      iconName = 'users'
      to = ENTITY_TYPE_INFO[ENTITY_TYPES.org].linkRoot
      break
    case 'schedule':
      iconName = 'calendar-alt'
      to = ENTITY_TYPE_INFO[ENTITY_TYPES.schedule].linkRoot
      break
    case 'team':
      iconName = 'user-friends'
      to = ENTITY_TYPE_INFO[ENTITY_TYPES.team].linkRoot
      break
  }

  let formattedHost
  if (url) {
    const parsedUrl = url ? new URL(url) : url
    const host = parsedUrl?.host
    formattedHost = host?.replace(/^www\./, '')
  }

  const isAppPost = parent === null

  // Determine what we need to scale the image by
  const scaleFactor = imageWidth / (isMobile ? window.innerWidth - 48 : 766)
  // New height is the minimum of its original height or the scaled height
  const height = Math.min(
    500,
    Math.round(Math.min(imageHeight, imageHeight / scaleFactor))
  )

  return (
    <article
      className='home-post'
      css={css`
        position: relative;

        margin-bottom: 16px;
        padding: 16px;
        background: #27303e;
        border: 1px solid ${colors.SOFT_STEEL};
        border-radius: 8px;
      `}
    >
      {/*  Verified banner */}
      {isAppPost && (
        <Flex
          alignItems='center'
          justifyContent='center'
          css={css`
            position: absolute;
            top: 0;
            right: 16px;
          `}
        >
          <img src={verifiedBanner} />
          <Icon
            name='badge-check'
            color='white'
            fontSize={21}
            css={css`
              position: absolute;
              transform: translateX(1px) translateY(-4px);
            `}
          />
        </Flex>
      )}

      {/* Logo, parent name, and post date */}
      <Flex
        as={parent_id ? Link : undefined}
        noFlex
        alignItems='center'
        to={to ? `${to}/${parent_id}` : undefined}
        css={css`
          margin-bottom: 16px;
        `}
      >
        {isAppPost ? (
          <img
            src={isCanlan ? canlanLogo : sportninjaLogo}
            css={css`
            border-radius: ${isCanlan && '4px;'}
            height: 40px;
            width; 40px;
          `}
          />
        ) : (
          <Picture
            square
            fit
            size='kxsmall'
            imageId={getImageThumbnailId(parent)}
            iconName={iconName}
          />
        )}
        <div
          css={css`
            margin-left: 14px;
          `}
        >
          <div
            css={css`
              font-weight: 700;
              font-size: 18px;
              line-height: 18px;
              margin-bottom: 8px;
            `}
          >
            {parent?.name || t('common:appName')}
          </div>
          <div
            css={css`
              opacity: 0.7;
              font-size: 13px;
            `}
            title={dayjs(created_at).format('h:mm A - MMMM D, YYYY')}
          >
            {getFeedPostDate(created_at)}
          </div>
        </div>
      </Flex>

      {/* Title */}
      <h2
        css={css`
          font-weight: 700;
          font-size: 18px;
          line-height: 18px;

          margin-bottom: 16px;
        `}
      >
        {title}
      </h2>

      {/* Body */}
      <p
        className='home-post-body'
        css={css`
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;

          color: ${colors.LINK_WATER};
        `}
      >
        {body}
      </p>

      {/* Image */}
      {imagePath && (
        <div
          css={css`
            background: center / contain no-repeat url(${imagePath});
            width: 100%;
            height: ${height}px;
            border-radius: 8px;
            margin-top: 16px;
          `}
        />
      )}

      {/* Web URL and link */}
      {formattedHost && (
        <Flex
          as='a'
          noFlex
          href={url}
          target='_blank'
          rel='noopener noreferrer'
          css={css`
            margin-top: 18px;
          `}
        >
          <div
            className='home-post-url'
            css={css`
              font-weight: 500;
              font-size: 12px;
              color: ${colors.DEFAULT_FLAIR};
              margin-right: 8px;
            `}
          >
            {formattedHost}
          </div>
          <Icon
            name='external-link'
            color={colors.DEFAULT_FLAIR}
            fontSize={12}
          />
        </Flex>
      )}

      {/* Like Button */}
      {!isInFrame && (
        <Flex
          noFlex
          row
          css={css`
            margin-top: 16px;
            height: 20px;
          `}
        >
          {likeInProgress ? (
            <div
              css={css`
                height: 20px;
                width: 20px;
                position: relative;
              `}
            >
              <LoadingSpinner size={1.4} borderSize={2} />
            </div>
          ) : (
            <button
              onClick={likeInProgress ? undefined : onLikePressed}
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <Icon
                faType={isLiked ? 'fas' : 'far'}
                name='heart'
                fontSize={20}
              />
              {likeCount > 0 ? (
                <span
                  css={css`
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 16px;
                    margin-left: 8px;
                    color: ${colors.DEFAULT_FLAIR};
                  `}
                >
                  {likeCount}
                </span>
              ) : null}
            </button>
          )}
          <Flex row justifyContent='flex-end'>
            {canEdit && entityType && entityId && (
              <FormSheet
                Button={({ onClick }) => (
                  <button
                    onClick={onClick}
                    css={css`
                      margin-right: 12px;
                    `}
                  >
                    <Icon faType='far' name='pencil' fontSize={20} />
                  </button>
                )}
                form={feedItemForm(post, false)}
                label={t('Web:editPost')}
                title={t('Web:editPost')}
                onSubmit={async ({ imageData, ...form }) => {
                  const body = JSON.stringify(form)
                  await req(`/${entityType}/${entityId}/social-feed/${id}`, {
                    method: 'PUT',
                    body,
                  })
                  if (imageData) {
                    await req(`/social-feed/${id}/image`, {
                      method: 'POST',
                      body: imageData,
                    })
                  }
                  await refreshFeed()
                }}
              />
            )}
            {canDelete && entityType && entityId && (
              <DeleteModal
                title='Delete Post'
                message='Are you sure you want to delete this post? This cannot be undone.'
                onDelete={async () => {
                  await req(`/${entityType}/${entityId}/social-feed/${id}`, {
                    method: 'DELETE',
                  })
                  await refreshFeed()
                }}
                Button={({ ...props }) => (
                  <button {...props}>
                    <Icon faType='far' name='trash' fontSize={20} />
                  </button>
                )}
              />
            )}
          </Flex>
        </Flex>
      )}
    </article>
  )
}

export default HomePost
