/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import req from '@sportninja/common/api/request'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { useCallback, useEffect, useState } from 'react'

import { font } from '../css'
import Icon from '../Icon'
import LoadingSpinner from '../LoadingSpinner'
import { media } from '../Responsive'
import { Tooltip } from 'antd'

const FavouriteButton = ({
  initialState,
  entityType,
  entityId,
  isHeader = true,
}) => {
  const [loading, setIsLoading] = useState(false)
  const [isFollowing, setIsFollowing] = useState(initialState)

  useEffect(() => {
    setIsFollowing(initialState)
  }, [initialState])

  const entityName = useCallback(() => {
    switch (entityType) {
      case ENTITY_TYPES.org:
        return ' organization'
      case ENTITY_TYPES.team:
        return ' team'
      case ENTITY_TYPES.schedule:
        return ' competition'
      case ENTITY_TYPES.game:
        return ' game'
      default:
        return ''
    }
  }, [entityType])

  return (
    <Tooltip
      title={`${isFollowing ? 'Unfollow' : 'Follow'}${entityName()}`}
      mouseEnterDelay={1}
    >
      <div
        css={css`
          position: relative;
          display: inline-block;
        `}
      >
        <button
          disabled={loading}
          className='header-action'
          type='button'
          onClick={async () => {
            try {
              setIsLoading(true)
              await req(`/favorites/${entityType}/${entityId}`, {
                method: isFollowing ? 'DELETE' : 'POST',
              })
              setIsFollowing(!isFollowing)
            } finally {
              setIsLoading(false)
            }
          }}
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            padding-bottom: 3px;

            ${font.title}
            font-weight: 600;
            font-size: 18px;

            letter-spacing: 0.015em;
            text-transform: uppercase;
            color: white;

            transition: border-color 0.1s ease-in-out;

            &:hover:not([disabled]) {
              border-color: white;

              i {
                color: white;
              }
            }

            opacity: ${loading ? 0.5 : 1};
            &,
            .is--loading {
              cursor: ${loading ? 'not-allowed' : 'pointer'};
            }

            ${media.mobile} {
              border: 1px solid ${colors.DEFAULT_FLAIR};
              border-radius: 4px;
              padding-bottom: 0;
              i {
                font-size: 13px;
                color: white;
              }
            }
          `}
        >
          {loading ? (
            <LoadingSpinner size={1} borderSize={2} />
          ) : (
            <Icon
              faType={isFollowing ? 'fas' : 'far'}
              name='star'
              fontSize={18}
              color={colors.DEFAULT_FLAIR}
            />
          )}
        </button>
      </div>
    </Tooltip>
  )
}

export default FavouriteButton
