import req from '@sportninja/common/api/request'
import { setSessionToken } from '@sportninja/common/api/account'

export const delay = (time) => {
  time = time || 2000
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}

class Service {
  async login(email, password) {
    const response = await req('/auth/login', {
      method: 'POST',
      body: JSON.stringify({ email, password }),
    })

    await setSessionToken(response?.access_token)

    return response
  }

  async getInviteInfo(inviteId) {
    const response = await req(`/registratiton/${inviteId}`)

    // const sports = await req('/sports')
    // return response.data

    return {
      invite: response.data,
    }
  }

  async getUserInformation(shouldFetchUserInfo = true, scheduleId) {
    const user = shouldFetchUserInfo ? await req('/users/me') : null
    const teams = await req(
      `/users/me/team-officials/registration/schedule/${scheduleId}`
    )

    return {
      user: user?.data,
      teams: teams?.data,
    }
  }

  async getPlayerInformation(shouldFetchUserInfo = true, scheduleId) {
    const user = shouldFetchUserInfo ? await req('/users/me') : null
    const teams = await req(`/registration/${scheduleId}/admin/player/teams`)
    const skillLevel = await req('/sports/skill-level-tiers')

    return {
      user: user?.data,
      teams: teams?.data,
      player: {},
      skillLevel: skillLevel?.data,
    }
  }

  async registerTeam({
    teamId,
    registrationId,
    scheduleId,
    teamOfficials,
    couponCodeId = null,
    zipCode,
    country,
  }) {
    const composeTeamOfficials = [
      ...teamOfficials.map((el) => {
        return {
          email: el.email,
          name_first: el.firstName,
          name_last: el.lastName,
          phone: el.phone,
          team_official_type_id: Number(el.type),
          is_treasurer: el.isTreasurer,
        }
      }),
    ]

    const response = await req(`/registration/${scheduleId}`, {
      method: 'POST',
      body: JSON.stringify({
        team_id: teamId,
        schedule_registration_id: registrationId,
        coupon_code_id: couponCodeId,
        is_deposit_required: true,
        is_comp: false,
        postal_code: zipCode,
        country_code: country,
        officials: composeTeamOfficials,
      }),
    })

    const teamPlayerRoster = response?.data?.team_player_roster_id
    // await req(
    //   `/teams/${teamId}/rosters/${teamPlayerRoster}/team-officials/registration`,
    //   {
    //     method: 'POST',
    //     body: JSON.stringify({ officials: composeTeamOfficials }),
    //   }
    // )

    const billing = await req(
      `/teams/${teamId}/rosters/${teamPlayerRoster}/billing`
    )

    return {
      ...response.data,
      billing: billing?.data,
    }
  }
}

export const RegistrationInviteService = new Service()
