import { call, put } from 'redux-saga/effects'
import req from '@sportninja/common/api/request'

import actions from '../actions/game'
import { readFactory } from './helpers/read'
import { toISOString } from '../utils/utils'
import {
  generateSagas,
  getYearInTheFuture,
  getThisVeryMoment,
  ENTITY_TYPES,
} from './utils'
import attemptScoringRequest from './attempt-scoring-request'

const ENTITY_TYPE = ENTITY_TYPES.game
const nextYearOfGames = toISOString(getYearInTheFuture())

const api = {
  readPublic: async (id) => await req(`/public/games/${id}`),
  readAll: async (timespan, page = 1, order) =>
    await req(`/games/follow?starts_${timespan}&page=${page}&order=${order}`),
  readCustom: async (timespan, page = 1, order = 'asc', perPage = 15) =>
    await req(
      `/games/follow?starts_${timespan}&page=${page}&order=${order}&per_page=${perPage}`
    ),
  create: async (id, body) =>
    await req(`/schedules/${id}/games`, { method: 'POST', body }),
  update: async (id, body) =>
    await req(`/games/${id}`, { method: 'PUT', body }),
  delete: async (id) => await req(`/games/${id}`, { method: 'DELETE' }),

  readGameRoster: async (id, rosterId) =>
    await req(`/games/${id}/rosters/${rosterId}`),
  readGameRosters: async (id, isPublicRoute) => {
    return await req(`/${isPublicRoute ? 'public/' : ''}games/${id}/rosters`)
  },

  updateGameRoster: async (id, rosterId, body) =>
    await req(`/games/${id}/rosters/${rosterId}/players`, {
      method: 'PUT',
      body,
      timeout: 10000,
    }),
  updateGameRosterPlayer: async (id, rosterId, playerId, body) =>
    await req(`/games/${id}/rosters/${rosterId}/players/${playerId}`, {
      method: 'PUT',
      body,
      timeout: 10000,
    }),
  createGameRosterPlayer: async (id, rosterId, playerId, body) =>
    await req(`/games/${id}/rosters/${rosterId}/players/${playerId}`, {
      method: 'POST',
      body,
      timeout: 10000,
    }),
  deleteGameRoster: async (id, rosterId) =>
    await req(`/games/${id}/rosters/${rosterId}`, { method: 'DELETE' }),

  readTimeline: async (id) => await req(`/games/${id}/timeline`),
  readPublicTimeline: async (id) => await req(`/public/games/${id}/timeline`),

  readSignatures: async (id) => await req(`/games/${id}/signatures`),
}

export const convertTimesToISOStrings = (form, keys) => {
  return Object.keys(form).reduce((collector, keyName) => {
    const value = form[keyName]
    if (value && keys.includes(keyName)) {
      collector[keyName] = toISOString(value)
    } else {
      collector[keyName] = value
    }
    return collector
  }, {})
}

const _gameAddAndUpdate = (method, isAdd = false) =>
  function* (payload) {
    const { id, form } = payload

    const body = convertTimesToISOStrings(form, [
      'starts_at',
      'started_at',
      'ends_at',
      'ended_at',
    ])

    // For web: if no game_type_id was selected, set the default value as 2 (i.e. regular season)
    if (isAdd && !body.game_type_id) {
      body.game_type_id = 2
    } else if (Object.prototype.hasOwnProperty.call(body, 'game_type_id')) {
      body.game_type_id = parseInt(body.game_type_id, 10)
    }

    const response = yield call(api[method], id, JSON.stringify(body))
    yield put(actions[method].success({ id, data: response.data }))
    return response
  }

const game = [
  [
    actions.readCustom,
    function* ({ timespan, page, order, perPage }) {
      const response = yield call(
        api.readCustom,
        timespan,
        page,
        order,
        perPage
      )
      yield put(actions.readCustom.success({ data: response.data }))
      return response
    },
  ],

  [
    actions.readAll,
    function* () {
      const response = yield call(api.readAll, `before=${nextYearOfGames}`)
      yield put(actions.readAll.success({ data: response.data }))
      return response
    },
  ],

  [
    actions.readPast,
    function* ({ page, sort, direction }) {
      const response = yield call(
        api.readAll,
        `before=${getThisVeryMoment()}`,
        page,
        direction
      )
      yield put(actions.readPast.success({ data: response.data }))
      return response
    },
  ],

  [
    actions.readUpcoming,
    function* ({ page, sort, direction }) {
      const response = yield call(
        api.readAll,
        `after=${getThisVeryMoment()}`,
        page,
        direction
      )
      yield put(actions.readUpcoming.success({ data: response.data }))
      return response
    },
  ],

  [actions.create, _gameAddAndUpdate('create', true)],
  [actions.read, readFactory(ENTITY_TYPE, actions.read)],
  [
    actions.readPublic,
    function* (payload) {
      const { id } = payload
      const { data, meta } = yield call(api.readPublic, id)

      let hierarchy
      if (meta && meta.hierarchy) hierarchy = meta.hierarchy

      const mergedData = { ...data, hierarchy }
      yield put(actions.readPublic.success({ id, data: mergedData }))
      return data
    },
  ],
  [actions.update, _gameAddAndUpdate('update')],
  [
    actions.delete,
    function* (payload) {
      // id is competition id
      const { id, gameId } = payload

      const response = yield call(api.delete, gameId)
      yield put(actions.delete.success({ id, gameId }))

      return response
    },
  ],

  [
    actions.readGameRoster,
    function* (payload) {
      const { id, rosterId } = payload

      const response = yield call(api.readGameRoster, id, rosterId)

      yield put(
        actions.readGameRoster.success({
          id,
          rosterId,
          data: response.data,
        })
      )
    },
  ],

  [
    actions.readGameRosters,
    function* (payload) {
      const { id, isPublicRoute } = payload

      const response = yield call(api.readGameRosters, id, isPublicRoute)

      yield put(
        actions.readGameRosters.success({
          id,
          data: response.data,
        })
      )

      return response
    },
  ],

  [
    actions.updateGameRoster,
    function* (payload) {
      // Extract fullPlayersList so it is not sent when flushing offline queue
      const { id, rosterId, form } = payload
      const { fullPlayersList, queueForOffline, ...body } = form

      const method = function* () {
        const response = yield call(
          api.updateGameRoster,
          id,
          rosterId,
          JSON.stringify(body)
        )
        yield put(
          actions.updateGameRoster.success({
            id,
            rosterId,
            data: response.data,
          })
        )

        return response
      }

      if (queueForOffline) {
        return yield attemptScoringRequest(
          function* () {
            return yield method()
          },
          {
            type: actions.updateGameRoster.request,
            payload,
          }
        )
      } else {
        return yield method()
      }
    },
  ],

  [
    actions.updateGameRosterPlayer,
    function* (payload) {
      // Extract fullPlayersList so it is not sent when flushing offline queue
      const { id, rosterId, playerId, form } = payload
      const { fullPlayersList, queueForOffline, ...body } = form

      const method = function* () {
        const response = yield call(
          api.updateGameRosterPlayer,
          id,
          rosterId,
          playerId,
          JSON.stringify(body)
        )
        yield put(
          actions.updateGameRosterPlayer.success({
            id,
            rosterId,
            playerId,
            data: response.data,
          })
        )

        return response
      }

      if (queueForOffline) {
        return yield attemptScoringRequest(
          function* () {
            return yield method()
          },
          {
            type: actions.updateGameRosterPlayer.request,
            payload,
          }
        )
      } else {
        return yield method()
      }
    },
  ],

  [
    actions.createGameRosterPlayer,
    function* (payload) {
      // Extract fullPlayersList so it is not sent when flushing offline queue
      const { id, rosterId, playerId, form } = payload
      const { fullPlayersList, queueForOffline, ...body } = form

      const method = function* () {
        const response = yield call(
          api.createGameRosterPlayer,
          id,
          rosterId,
          playerId,
          JSON.stringify(body)
        )
        yield put(
          actions.createGameRosterPlayer.success({
            id,
            rosterId,
            playerId,
            data: response.data,
          })
        )

        return response
      }

      if (queueForOffline) {
        return yield attemptScoringRequest(
          function* () {
            return yield method()
          },
          {
            type: actions.createGameRosterPlayer.request,
            payload,
          }
        )
      } else {
        return yield method()
      }
    },
  ],

  [
    actions.deleteGameRoster,
    function* (payload) {
      const { id, rosterId } = payload

      const response = yield call(api.deleteGameRoster, id, rosterId)
      yield put(actions.deleteGameRoster.success({ id, rosterId }))
      return response
    },
  ],

  [
    actions.readTimeline,
    function* (payload) {
      const { id } = payload
      const response = yield call(api.readTimeline, id)

      yield put(actions.readTimeline.success({ id, data: response.data }))
      return response
    },
  ],

  [
    actions.readPublicTimeline,
    function* (payload) {
      const { id } = payload
      const response = yield call(api.readPublicTimeline, id)

      yield put(actions.readPublicTimeline.success({ id, data: response.data }))
      return response
    },
  ],

  [
    actions.readSignatures,
    function* (payload) {
      const { id } = payload
      const response = yield call(api.readSignatures, id)

      yield put(actions.readSignatures.success({ id, data: response.data }))
      return response
    },
  ],
]

export default generateSagas([...game])
