/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import { ROUTES } from '@sportninja/common/constants/app'
import { t } from '@sportninja/common/i18n'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import parsers from '@sportninja/common/reducers/parsers'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { useEffect, useMemo, useState } from 'react'

import { useSports } from '@sportninja/common/hooks/useSports'
import { getAllGenericSportProperties } from '@sportninja/common/selectors/types'
import { connect } from 'react-redux'
import EntityFormDelete from '../../components/Form/EntityFormDelete'
import { teamForm } from '../../components/Form/form-configs'
import { Flex } from '../../components/Layout'
import List from '../../components/List'
import OptionButtonDelete from '../../components/List/OptionButtons/Delete'
import OptionButtonEdit from '../../components/List/OptionButtons/Edit'
import OptionButtonFavourite from '../../components/List/OptionButtons/Favourite'
import MoreOptions, {
  MoreOptionListItem,
} from '../../components/List/OptionButtons/More'
import useGetListItems from '../../components/List/useGetListItems'
import Picture from '../../components/Picture'
import EditEntitySubTitle from '../EditEntitySubTitle'

export const TeamCell = ({ item }) => (
  <Flex alignItems='center'>
    <Picture
      size='kxsmall'
      square
      fit
      imageId={getImageThumbnailId(item)}
      iconName='user-friends'
      css={css`
        margin-right: 8px;
      `}
    />
    {item.name}
  </Flex>
)

const TeamList = ({
  setCount = () => {},
  parentId,
  slug,
  refreshKey,
  genericSports,
  onClickWebPluginOnTeamList = () => {},
}) => {
  const { sports, sportOptions } = useSports()
  const [filter, setFilter] = useState('')
  const listProps = useGetListItems(slug, {
    onComplete: (response) => setCount(response.meta.pagination.count),
  })
  const { sort, direction, getItems, permissions, debouncedSearchTerm } =
    listProps

  const sportFilterList = useMemo(() => {
    const sportOptions =
      sports
        ?.map((sport) => ({
          text: sport.name_full,
          value: sport.id,
          is_generic: false,
        }))
        .filter((value) => value?.text !== 'Generic') || []
    const genericSportId = sports?.find((s) => s.name_full === 'Generic')?.id
    genericSports.forEach((sport) => {
      if (!sportOptions.find((s) => s.value === sport.id)) {
        sportOptions.push({
          text: `${sport.name} (Basic)`,
          value: sport.id,
          is_generic: true,
          generic_sport_id: genericSportId,
        })
      }
    })
    return [{ text: 'All', value: '' }, ...sportOptions]
  }, [sports, genericSports])

  useEffect(() => {
    getItems()
  }, [refreshKey, sort, direction, debouncedSearchTerm])

  useEffect(() => {
    if (filter === '') {
      getItems()
    } else {
      // First, lets find the item on the sportFilterList
      const sport = sportFilterList.find((s) => s.value === filter)
      if (!sport?.is_generic) {
        getItems(1, { sport_id: filter })
      } else {
        getItems(1, {
          sport_id: sport?.generic_sport_id,
          generic_sport_id: filter,
        })
      }
    }
  }, [filter])

  const headers = [
    {
      text: t('common:name'),
      sizePercent: 50,
      sort: 'name_full',
      Cell: TeamCell,
      to: (item) => ({
        pathname: `${ROUTES.TEAM_ROOT}/${item.id}`,
        state: { fromParent: parentId },
      }),
    },
    // Removing the parent org as requested by Jeff
    // {
    //   text: t('common:parentOrg'),
    //   sizePercent: 50,
    //   minWidth: FIRST_CELL_WIDTH,
    //   sort: 'organization.name_full',
    //   accessor: 'organization.name_full',
    //   to: (item) => `${ROUTES.ORG_ROOT}/${item.organization.id}`,
    // },
    {
      text: 'Sport',
      sizePercent: 50,
      sort: 'sport_id',
      accessor: (item) =>
        item?.generic_sport_name
          ? `${item?.generic_sport_name} (Basic)`
          : sports?.find((sport) => sport.id === item?.sport_id)?.name_full,
    },
    // {
    //   text: t('common:player', { count: 2 }),
    //   sizePercent: 8,
    //   sort: 'players_count',
    //   accessor: 'players_count',
    // },
  ]

  const renderGeneratePluginTag = ({ onClick }) => {
    return (
      <MoreOptionListItem
        onClick={() => {
          onClick()
        }}
        iconName='plug'
        faType='far'
        title={'Generate JS Plugin Tag'}
      />
    )
  }

  const options = (item) => {
    const itemSportFullName = sports?.find(
      (sport) => sport.id === item?.sport_id
    )?.name_full
    const permission = permissions[item.id]

    const onEdit = async (...args) => {
      const [values] = args
      const { imageData, ...form } = values

      if (!form?.name) {
        form.name = form.name_full
      }

      if (form?.delete_image) {
        await req(`/teams/${item.id}/image`, {
          method: 'DELETE',
        })
      }

      const body = JSON.stringify(form)
      const response = await req(`/teams/${item.id}`, {
        method: 'PUT',
        body,
      })
      if (imageData) {
        await req(`/teams/${item.id}/image`, {
          method: 'POST',
          body: imageData,
        })
      }
      await getItems()
      return response
    }

    const moreOptions = []

    if (permission?.update) {
      moreOptions.push({
        key: 'plugin',
        Component: renderGeneratePluginTag,
        componentProps: {
          Button: renderGeneratePluginTag,
          schedule: item,
          scheduleId: item.id,
          onClick: () => {
            onClickWebPluginOnTeamList(item)
          },
        },
      })
    }

    if (permission?.delete) {
      moreOptions.push({
        key: 'delete',
        Component: OptionButtonDelete,
        componentProps: {
          useForceDelete: true,
          unmountOnClose: true,
          entityType: ENTITY_TYPES.team,
          itemId: item.id,
          title: `Delete ${item.name_full}`,
          type: t('common:team'),
          iconName: 'trash',
          onComplete: getItems,
        },
      })
    }

    const opts = [
      permission.update && (
        <OptionButtonEdit
          key='edit'
          form={teamForm(
            parsers[ENTITY_TYPES.team]({ ...item, permission }),
            false,
            itemSportFullName,
            sportOptions,
            sports,
            parsers[ENTITY_TYPES.team]({ ...item, permission }).sport_id
          )}
          onSubmit={onEdit}
          title={t('Web:editTeam')}
          subTitle={
            <EditEntitySubTitle id={item.id} entityType={ENTITY_TYPES.team} />
          }
          Delete={
            permission.delete && (
              <EntityFormDelete
                entityType={ENTITY_TYPES.team}
                itemId={item.id}
                title={`Delete ${item.name_full}`}
                type={t('common:team')}
                // onComplete={getItems}
                onComplete={() => {
                  window.location.reload()
                }}
              />
            )
          }
        />
      ),
      <OptionButtonFavourite
        key='fav'
        entityType={ENTITY_TYPES.team}
        item={item}
        isFavourite={item.is_favorite}
      />,
      <MoreOptions key='more' options={moreOptions} />,
    ]

    return opts.filter((o) => o !== undefined)
  }

  return (
    <List
      headers={headers.filter((o) => o !== undefined)}
      options={options}
      listProps={listProps}
      noItemsText={t('common:team', { count: 2 })}
      showSearch
      showSportFilter={true}
      sportFilterOptions={sportFilterList}
      setSportFilter={setFilter}
      sportFilter={filter}
    />
  )
}

const mapStateToProps = (state) => {
  const genericSports = getAllGenericSportProperties(state)
  return {
    genericSports,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamList)
