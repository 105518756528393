/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'

const ToolbarButton = ({ onClick, children }) => {
  return (
    <button
      type='button'
      onClick={onClick}
      css={css`
        width: 34px;
        transition: background-color 0.15s ease-in-out;
        border-radius: 4px;
        margin-right: 12px;

        i {
          transition: transform 0.15s ease-in-out;

          &.is-closed {
            transform: rotate(-90deg);
          }
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
      `}
    >
      {children}
    </button>
  )
}

export default ToolbarButton
