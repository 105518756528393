import merge from 'lodash.merge'
import { generateActions } from './utils'

// A list of all available actions for the game entity. The array describes
// the arguments that are passed to the REQUEST action
const onlineDefinition = {
  readAll: [],
  readCustom: ['timespan', 'page', 'order', 'perPage'],
  readPast: ['page', 'sort', 'direction'],
  readUpcoming: ['page', 'sort', 'direction'],
  create: ['id', 'form'],
  read: ['id'],
  readPublic: ['id'],
  update: ['id', 'form'],
  delete: ['id', 'gameId'],

  readGameRoster: ['id', 'rosterId'],
  readGameRosters: ['id', 'isPublicRoute'],
  deleteGameRoster: ['id', 'rosterId'],

  readTimeline: ['id'],
  readPublicTimeline: ['id'],

  readSignatures: ['id'],
}

const offlineDefinition = {
  updateGameRoster: ['id', 'rosterId', 'form'],
  updateGameRosterPlayer: ['id', 'rosterId', 'playerId', 'form'],
  createGameRosterPlayer: ['id', 'rosterId', 'playerId', 'form'],
}

export const definition = merge({}, onlineDefinition, offlineDefinition)

const onlineActions = generateActions(['GAME'], onlineDefinition)
const offlineActions = generateActions(['GAME'], offlineDefinition, true)
const gameActions = merge({}, onlineActions, offlineActions)

export default gameActions
