/* eslint-disable no-unreachable */
/* eslint-disable react-native/no-inline-styles */
/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import React, { useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import withInvite, {
  inviteStateProps,
  inviteDispatchProps,
} from '@sportninja/common/components/Invite'
import { t } from '@sportninja/common/i18n'
import {
  ROLE_TYPE_ID_TO_STRING,
  ROUTES,
} from '@sportninja/common/constants/app'
import { invitationType } from '@sportninja/common/constants/invitationType'
import { isCanlan } from '@sportninja/common/utils/customer-name'

import LoadingSpinner from '../../components/LoadingSpinner'
import Helmet from '../../components/Helmet'
import { FormButton } from '../../components/Form/css'
import Icon from '../../components/Icon'
import Waiver from './Waiver'
// import InvitePicker from './InvitePicker'
// import InviteCreateNew from './InviteCreateNew'

import './index.scss'
import './index-mobile.scss'
import { Image, Spin } from 'antd'
import { SelectInput } from 'src/components/ts-components/SelectInput'
import colors from '@sportninja/common/constants/appColors'
import {
  heading1,
  heading6,
  paragraphLarge,
  paragraphMedium,
  paragraphXSmall,
  weightBold,
  weightRegular,
  weightSemiBold,
} from 'src/components/css'
import { CheckBoxInput } from 'src/components/ts-components/CheckBoxInput'

const entityType = {
  ORGANIZATION: 'Organization',
  TEAM: 'Team',
  COMPETITION: 'Competition',
  PLAYER: 'Player',
}

export const getAnOrA = (type) => {
  if (
    typeof type === 'string' &&
    type.length > 0 &&
    ['a', 'e', 'i', 'o', 'u'].includes(type[0].toLowerCase())
  ) {
    return 'an'
  }
  return 'a'
}

const INVITE_ALREADY_ACCEPTED = 'This invitation has already been accepted.'

const getRouteDestination = (invitationTypeId, entityId) => {
  try {
    const inviteType = invitationType.find(
      (item) => String(item.id) === String(invitationTypeId)
    )
    if (inviteType) {
      let screen
      switch (inviteType.destination) {
        case entityType.ORGANIZATION:
          screen = ROUTES.ORG_PAGES.replace(/:id/, entityId)
          break
        case entityType.TEAM:
          screen = ROUTES.TEAM_PAGES.replace(/:id/, entityId)
          break
        case entityType.COMPETITION:
          screen = ROUTES.SCHEDULE_PAGES.replace(/:id/, entityId)
          break
        default:
          break
      }
      return screen
    }
  } catch (e) {
    return ROUTES.HOME
  }
}

/**
 * Determines whether the submit button should be disabled based on the provided parameters.
 *
 * @param {Object} options - The options object.
 * @param {boolean} options.shouldDisplayWaiver - Indicates whether the waiver should be displayed.
 * @param {boolean} options.hasScrolled - Indicates whether the user has scrolled.
 * @param {boolean} options.loading - Indicates whether the data is being loaded.
 * @param {boolean} options.ask_position - Indicates whether the position should be asked.
 * @param {string|null} options.selectedPosition - The selected position.
 * @returns {boolean} - True if the submit button should be disabled, false otherwise.
 */
const disableSubmitButton = ({
  shouldDisplayWaiver = false,
  hasScrolled = false,
  loading = false,
  ask_position = false,
  selectedPosition = null,
  acceptedWaiver = false,
}) => {
  if (shouldDisplayWaiver) {
    if (acceptedWaiver) {
      return false
    }
    return true
  }
  if (loading) {
    return true
  }
  if (ask_position && !selectedPosition) {
    return true
  }
  return false
}

const getText = (shouldDisplayWaiver = false, ask_position = false) => {
  if (shouldDisplayWaiver && ask_position) {
    return 'To accept this invitation, please choose your position and accept the waiver terms and conditions:'
  }
  if (shouldDisplayWaiver) {
    return 'To accept this invitation, please accept the waiver terms and conditions:'
  }
  if (ask_position) {
    return 'To accept this invitation, please choose your position:'
  }
  return ''
}

/**
 * Returns the invite type name based on the provided parameters.
 *
 * @param {Object} params - The parameters object.
 * @param {Object} params.invitee - The invitee object.
 * @param {string} params.roleName - The role name.
 * @param {boolean} params.ask_position - Indicates if position is asked.
 * @param {boolean} params.isPlayer - Indicates if the invitee is a player.
 * @param {boolean} params.isTeamOfficial - Indicates if the invitee is a team official.
 * @param {Array} params.teamOfficialsTypes - The array of team official types.
 * @param {Array} params.playerTypes - The array of player types.
 * @param {string} params.selectedPosition - The selected position.
 * @returns {string} The invite type name.
 */
const getInviteTypeName = ({
  invitee,
  roleName,
  ask_position,
  isPlayer,
  isTeamOfficial,
  teamOfficialsTypes,
  playerTypes,
  selectedPosition,
}) => {
  if (ask_position) {
    if (isPlayer) {
      return playerTypes.find((type) => type.id === selectedPosition)?.name_full
    }
    if (isTeamOfficial) {
      return teamOfficialsTypes.find((type) => type.id === selectedPosition)
        ?.name_full
    }
  }
  if (invitee.type !== 'Player' && roleName) {
    return roleName
  } else {
    return invitee.type
  }
}

const Invite = ({
  accepted,
  acceptedNames,
  error,
  // existingUserIdx,
  invitee,
  inviterName,
  // isInviteForSelf,
  // isNewInvite,
  loading,
  managedUsers,
  message,
  onConfirm,
  // onCreate,
  roleTypeId,
  self,
  shouldDisplayWaiver,
  invite,
  acceptedFormat,
  waiverData,
  sports,
  sport_id,
  player_id,
  ask_position,
  team_official_id,
  team_official_type_id,
  player_type_id,
  destination,
}) => {
  const invitationDestination =
    invitationType?.find((item) => String(item.id) === String(invite?.type?.id))
      ?.destination || null
  // const shouldDisplayWaiver = true
  // const ask_position = true
  const sport = sports[sport_id] || {}
  const playerTypes = useMemo(() => sport.players || [], [sport.players])
  const teamOfficialsTypes = useMemo(
    () => sport.teamOfficials || [],
    [sport.teamOfficials]
  )

  const isPlayer = !!player_id
  const isTeamOfficial = !!team_official_id
  const [hasScrolled, setHasScrolled] = useState(false)
  const [selectedPosition, setSelectedPosition] = useState(
    isPlayer ? playerTypes[0]?.id : teamOfficialsTypes[0]?.id || null
  )
  const [acceptedWaiver, setAcceptedWaiver] = useState(false)
  const image = destination?.image?.full_path || null

  useEffect(() => {
    if (playerTypes && playerTypes.length > 0 && isPlayer) {
      setSelectedPosition(playerTypes[0]?.id)
    }
  }, [isPlayer, playerTypes])

  useEffect(() => {
    if (teamOfficialsTypes && teamOfficialsTypes.length > 0 && isTeamOfficial) {
      setSelectedPosition(teamOfficialsTypes[0]?.id)
    }
  }, [isTeamOfficial, teamOfficialsTypes])

  if (loading.page) {
    return <LoadingSpinner fullScreen />
  }

  const classes = cn('invite-section left flex flex-dir-col', {
    'remove-border': error || accepted || invitee.type !== 'player',
  })

  const removeTheFromName = inviterName.replace(/$the[/s]/i, '')
  const roleName = ROLE_TYPE_ID_TO_STRING?.[roleTypeId]
  const inviteName = getInviteTypeName({
    invitee,
    roleName,
    ask_position,
    isPlayer,
    isTeamOfficial,
    teamOfficialsTypes,
    playerTypes,
    selectedPosition,
  })

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        padding: 40px 24px;
      `}
    >
      {error?.title === INVITE_ALREADY_ACCEPTED ? (
        <div
          css={css`
            display: flex;
            width: 480px;
            max-width: 50vw;
            padding: 40px 24px;
            flex-direction: column;
            align-items: center;
            gap: 24px;
            border-radius: 16px;
            border: 1px solid ${colors.SECONDARY_300};
            background: ${colors.SECONDARY_400};
            @media (max-width: 768px) {
              max-width: 100vw;
            }
          `}
        >
          <Image
            width={125}
            height={96}
            src='/images/unavailable_warning.png'
            fallback='/images/unavailable_warning.png'
            preview={false}
          />
          <p
            css={css`
              ${heading1}
              color: ${colors.WHITE};
              text-align: center;
              font-family: ${isCanlan
                ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif; !important'
                : 'BarlowCondensed, Arial, Helvetica, sans-serif; !important'};
            `}
          >
            INVITATION UNAVAILABLE
          </p>
          <p
            css={css`
              ${paragraphLarge}
              color: ${colors.WHITE};
              text-align: center;
            `}
          >
            This invitation has already been accepted and is no longer
            available.
          </p>
          <div
            css={css`
              background: ${colors.HEADER};
              height: 1px;
              width: 100%;
            `}
          />
          <button
            onClick={() => {
              window.location.href = getRouteDestination(
                error?.invite?.type?.id,
                error?.invite?.destination?.id
              )
            }}
            css={css`
              display: flex;
              padding: 8px 24px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              align-self: stretch;
              border-radius: 8px;
              border: 2px solid ${colors.PRIMARY};
              background: ${colors.PRIMARY};
            `}
          >
            <span
              css={css`
                ${heading6}
                ${weightSemiBold}
                color: ${colors.WHITE};
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif; !important'
                  : 'BarlowCondensed, Arial, Helvetica, sans-serif; !important'};
                text-transform: uppercase;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              `}
            >
              {invitationDestination
                ? `GO TO ${invitationDestination}`
                : 'GO TO PAGE'}
            </span>
          </button>
        </div>
      ) : error ? (
        <div
          css={css`
            display: flex;
            flex: 1;
            width: 480px;
            max-width: 40vw;
            padding: 40px 24px;
            flex-direction: column;
            align-items: center;
            gap: 24px;
            border-radius: 16px;
            border: 1px solid ${colors.SECONDARY_300};
            background: ${colors.SECONDARY_400};
            @media (max-width: 768px) {
              max-width: 100vw;
            }
          `}
        >
          <Image
            width={125}
            height={96}
            src='/images/unavailable_warning.png'
            fallback='/images/unavailable_warning.png'
            preview={false}
          />
          <p
            css={css`
              ${heading1}
              color: ${colors.WHITE};
              text-align: center;
              font-family: ${isCanlan
                ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif; !important'
                : 'BarlowCondensed, Arial, Helvetica, sans-serif; !important'};
              text-transform: uppercase;
            `}
          >
            {error?.title}
          </p>
          <p
            css={css`
              ${paragraphLarge}
              color: ${colors.WHITE};
              text-align: center;
            `}
          >
            {error.message}
          </p>
          <div
            css={css`
              background: ${colors.HEADER};
              height: 1px;
              width: 100%;
            `}
          />
          <button
            onClick={() => {
              window.location.href = ROUTES.HOME
            }}
            css={css`
              display: flex;
              padding: 8px 24px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              align-self: stretch;
              border-radius: 8px;
              border: 2px solid ${colors.PRIMARY};
              background: ${colors.PRIMARY};
            `}
          >
            <span
              css={css`
                ${heading6}
                ${weightSemiBold}
              color: ${colors.WHITE};
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif; !important'
                  : 'BarlowCondensed, Arial, Helvetica, sans-serif; !important'};
              `}
            >
              GO HOME
            </span>
          </button>
        </div>
      ) : (
        <div
          css={css`
            display: flex;
            width: 600px;
            padding: 40px 24px;
            flex-direction: column;
            align-items: center;
            gap: 24px;
            border-radius: 16px;
            border: 1px solid ${colors.SECONDARY_300};
            background: ${colors.SECONDARY_400};
            @media (max-width: 768px) {
              max-width: 100vw;
              min-width: 0;
            }
          `}
        >
          <>
            <Image
              width={80}
              height={80}
              css={css`
                border-radius: 8px;
              `}
              src={image}
              fallback='/images/generic_placeholders/placeholder_team.png'
              preview={false}
            />

            <p
              css={css`
                color: ${error ? colors.ERROR_LIGHT : colors.WHITE};
                text-align: center;
                text-transform: uppercase;
                ${heading1}
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif; !important'
                  : 'BarlowCondensed, Arial, Helvetica, sans-serif; !important'};
              `}
            >
              {error
                ? error.title || t('errors:somethingWentWrong')
                : accepted
                ? 'Ready To Go!'
                : t('Web:joinTheX', {
                    x: inviterName,
                    interpolation: { escapeValue: false },
                  })}
            </p>
          </>
          {/* {!accepted && !error ? (
              <div
                css={css`
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 16px;
                  align-self: stretch;
                  flex: 1 0 0;
                `}
              >
              <Image
                width={80}
                height={80}
                css={css`
                  border-radius: 8px;
                `}
                src='error'
                fallback='/images/generic_placeholders/placeholder_team.png'
                preview={false}
              />

              <p
                css={css`
                  ${heading1}
                  ${weightSemiBold}
                  color: ${error ? colors.ERROR_LIGHT : colors.WHITE};
                  text-align: flex-start;
                  text-transform: uppercase;
                  font-family: ${isCanlan
                    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif; !important'
                    : 'BarlowCondensed, Arial, Helvetica, sans-serif; !important'};
                `}
              >
                <span
                  css={css`
                    ${heading4}
                    ${weightMedium}
                    font-family: ${isCanlan
                      ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif; !important'
                      : 'BarlowCondensed, Arial, Helvetica, sans-serif; !important'};
                  `}
                >
                  {'Join the'}
                </span>
                <br />
                {removeTheFromName}
              </p>
            </div>
          ) : (
            <>
              <Image
                width={80}
                height={80}
                css={css`
                  border-radius: 8px;
                `}
                src='error'
                fallback='/images/generic_placeholders/placeholder_team.png'
                preview={false}
              />

              <p
                css={css`
                  color: ${error ? colors.ERROR_LIGHT : colors.WHITE};
                  text-align: center;
                  text-transform: uppercase;
                  ${heading1}
                  font-family: ${isCanlan
                    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif; !important'
                    : 'BarlowCondensed, Arial, Helvetica, sans-serif; !important'};
                `}
              >
                {error
                  ? error.title || t('errors:somethingWentWrong')
                  : accepted
                  ? 'Ready To Go!'
                  : t('Web:joinTheX', {
                      x: inviterName,
                      interpolation: { escapeValue: false },
                    })}
              </p>
            </>
          )} */}
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 4px;
                align-self: stretch;
              `}
            >
              <p
                css={css`
                  color: ${colors.PRIMARY};
                  text-align: center;
                  ${paragraphLarge}
                  ${weightBold}
                align-self: stretch;
                `}
              >
                {acceptedNames.first || invitee.nameFirst}{' '}
                {acceptedNames.last || invitee.nameLast}{' '}
              </p>
              {invitee?.email ? (
                <p
                  css={css`
                    ${paragraphXSmall}
                    ${weightRegular}
                    color: ${colors.WHITE};
                    text-align: center;
                    align-self: center;
                  `}
                >
                  ({invitee?.email})
                </p>
              ) : null}
            </div>
            {!error && !accepted ? (
              <p
                css={css`
                  ${paragraphLarge}
                  ${weightRegular}
                color: ${colors.WHITE};
                  text-align: center;
                  align-self: center;
                `}
              >
                {t('Web:isInvitedToJoin')} {t('common:the')}{' '}
                <span
                  css={css`
                    ${paragraphLarge}
                    ${weightBold}
                  color: ${colors.PRIMARY};
                  `}
                >
                  {removeTheFromName}
                </span>{' '}
                as a{' '}
                {invitee.type !== 'Player' && roleName
                  ? roleName
                  : invitee.type}
              </p>
            ) : null}
            {!error && accepted ? (
              <p
                css={css`
                  ${paragraphLarge}
                  ${weightRegular}
                color: ${colors.WHITE};
                  text-align: center;
                  align-self: stretch;
                `}
              >
                is now a{' '}
                <span
                  css={css`
                    ${paragraphLarge}
                    ${weightBold}
                  color: ${colors.PRIMARY};
                  `}
                >
                  {inviteName}
                </span>{' '}
                of{' '}
                <span
                  css={css`
                    ${paragraphLarge}
                    ${weightBold}
                  color: ${colors.PRIMARY};
                  `}
                >
                  {removeTheFromName}
                </span>
              </p>
            ) : null}
          </div>
          <div
            css={css`
              background: ${colors.HEADER};
              height: 1px;
              width: 100%;
            `}
          />
          {!accepted && !error ? (
            <p
              css={css`
                ${paragraphMedium}
                ${weightRegular}
                    color: ${colors.WHITE};
                text-align: center;
                align-self: center;
              `}
            >
              {getText(shouldDisplayWaiver, ask_position)}
            </p>
          ) : null}
          {invitee.type === 'Player' &&
          isPlayer &&
          ask_position &&
          !accepted ? (
            <>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  width: 100%;
                `}
              >
                {/* <p
                  css={css`
                    ${paragraphMedium}
                    ${weightRegular}
                    color: ${colors.WHITE};
                    text-align: center;
                    align-self: center;
                  `}
                >
                  To accept this invitation, please select your position:
                </p> */}
                <SelectInput
                  label={'Position:'}
                  disabled={false}
                  options={playerTypes.map((type) => ({
                    label: type.name_full,
                    value: type.id,
                  }))}
                  name={'position'}
                  value={selectedPosition}
                  showSearch={false}
                  onChange={(value) => {
                    setSelectedPosition(value)
                  }}
                  fieldErrors={[]}
                  customError={{}}
                  style={css`
                    background-color: ${colors.HEADER}!important;
                  `}
                />
              </div>
            </>
          ) : null}
          {isTeamOfficial && ask_position && !accepted ? (
            <>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  width: 100%;
                `}
              >
                <SelectInput
                  label={'Position:'}
                  disabled={false}
                  options={teamOfficialsTypes.map((type) => ({
                    label: type.name_full,
                    value: type.id,
                  }))}
                  name={'position'}
                  value={selectedPosition}
                  showSearch={false}
                  onChange={(value) => {
                    setSelectedPosition(value)
                  }}
                  fieldErrors={[]}
                  customError={{}}
                  style={css`
                    background-color: ${colors.HEADER}!important;
                  `}
                />
              </div>
            </>
          ) : null}
          {shouldDisplayWaiver && !accepted && (
            <Waiver
              hasScrolled={hasScrolled}
              setHasScrolled={setHasScrolled}
              waiverData={waiverData}
            />
          )}
          {shouldDisplayWaiver && !accepted ? (
            <div
              css={css`
                display: flex;
                flex-direction: row;
                gap: 8px;
                width: 100%;
                align-items: center;
                justify-content: start;
              `}
            >
              <CheckBoxInput
                customError={{}}
                fieldErrors={[]}
                disabled={false}
                label='I have read and agree to the waiver terms and conditions.'
                name='accepted_waiver'
                onChange={(value) => setAcceptedWaiver(value.target.checked)}
                value={acceptedWaiver}
                checkBoxStyle={css`
                  width: unset;
                  font-size: 14px;
                  line-height: 17px;
                `}
              />
            </div>
          ) : null}
          <div
            css={css`
              display: flex;
              align-items: flex-start;
              gap: 16px;
              align-self: stretch;
            `}
          >
            <button
              disabled={loading.accept}
              css={css`
                display: flex;
                padding: 8px 24px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                flex: 1 0 0;
                border-radius: 8px;
                border: 2px solid ${colors.WHITE};
                ${loading.accept ? 'opacity: 0.5;' : ''}
              `}
              onClick={() => {
                window.location.href = ROUTES.HOME
              }}
            >
              <span
                css={css`
                  color: ${colors.WHITE};
                  ${heading6}
                  ${weightSemiBold}
                  font-family: ${isCanlan
                    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif; !important'
                    : 'BarlowCondensed, Arial, Helvetica, sans-serif; !important'};
                `}
              >
                {accepted ? 'GO HOME' : 'NOT NOW'}
              </span>
            </button>
            <button
              disabled={disableSubmitButton({
                shouldDisplayWaiver,
                hasScrolled,
                loading: loading.accept,
                ask_position,
                selectedPosition,
                acceptedWaiver,
              })}
              onClick={() => {
                if (accepted) {
                  window.location.href = getRouteDestination(
                    invite?.type?.id,
                    invite?.destination?.id
                  )
                } else {
                  // onConfirm.bind(this, self.id, selectedPosition)
                  onConfirm(self.id, selectedPosition)
                }
              }}
              css={css`
                display: flex;
                padding: 8px 24px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                flex: 1 0 0;
                align-self: stretch;
                border-radius: 8px;
                border: 2px solid
                  ${disableSubmitButton({
                    shouldDisplayWaiver,
                    hasScrolled,
                    loading: loading.accept,
                    ask_position,
                    selectedPosition,
                    acceptedWaiver,
                  })
                    ? colors.SECONDARY_300
                    : colors.PRIMARY};
                background: ${disableSubmitButton({
                  shouldDisplayWaiver,
                  hasScrolled,
                  loading: loading.accept,
                  ask_position,
                  selectedPosition,
                  acceptedWaiver,
                })
                  ? colors.SECONDARY_300
                  : colors.PRIMARY};
                ${disableSubmitButton({
                  shouldDisplayWaiver,
                  hasScrolled,
                  loading: loading.accept,
                  ask_position,
                  selectedPosition,
                  acceptedWaiver,
                })
                  ? 'opacity: 0.5;'
                  : ''}
              `}
            >
              {loading.accept ? (
                <Spin
                  style={{
                    color: colors.WHITE,
                  }}
                />
              ) : (
                <span
                  css={css`
                    ${heading6}
                    ${weightSemiBold}
                    font-family: ${isCanlan
                      ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif; !important'
                      : 'BarlowCondensed, Arial, Helvetica, sans-serif; !important'};
                    color: ${colors.WHITE};
                    text-transform: uppercase;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  `}
                >
                  {accepted ? `GO TO ${invitationDestination}` : 'JOIN'}
                </span>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  )

  return (
    <div className='invite-v2'>
      <Helmet title='Invitation' />
      <div className={classes}>
        <h1 className='invite-title' style={{ whiteSpace: 'pre-line' }}>
          {error
            ? error.title || t('errors:somethingWentWrong')
            : t('Web:joinTheX', {
                x: inviterName,
                interpolation: { escapeValue: false },
              })}
          {error?.title === INVITE_ALREADY_ACCEPTED ? (
            <>
              <Link
                className={cn(
                  'sn-button invite-btn invite-finish-btn is--outline',
                  { 'is-canlan': isCanlan }
                )}
                to={getRouteDestination(
                  error?.invite?.type?.id,
                  error?.invite?.destination?.id
                )}
                title={t('util:capitalize', { text: 'Go to page' })}
              >
                {t('util:capitalize', { text: 'Go to page' })}
              </Link>
            </>
          ) : null}
        </h1>
        <h2 className='invite-sub-title' style={{ marginBottom: '12px' }}>
          {accepted ? (
            <>
              {t('Web:invitationAccepted')}
              <Link
                className={cn(
                  'sn-button invite-btn invite-finish-btn is--outline',
                  { 'is-canlan': isCanlan }
                )}
                to={getRouteDestination(
                  invite?.type?.id,
                  invite?.destination?.id
                )}
                title={t('util:capitalize', { text: t('routeNames:profile') })}
              >
                {t('util:capitalize', { text: 'Go to page' })}
              </Link>
            </>
          ) : error ? (
            error.message
          ) : (
            <>
              <span className='invite-highlight'>
                {acceptedNames.first || invitee.nameFirst}{' '}
                {acceptedNames.last || invitee.nameLast}{' '}
              </span>
              {invitee?.email && (
                <span className='invitee-email'>({invitee.email}) </span>
              )}
              {t('Web:isInvitedToJoin')} {t('common:the')}{' '}
              <span className='invite-highlight'>{removeTheFromName}</span>
              {invitee.type && (
                <>
                  {' '}
                  as {getAnOrA(invitee.type)}{' '}
                  <span className='invite-highlight'>
                    {invitee.type !== 'player' && roleName
                      ? roleName
                      : invitee.type}
                  </span>
                </>
              )}
              {message ? ':' : '.'}
              {message && (
                <div
                  style={{
                    borderRadius: 4,
                    backgroundColor: 'rgba(255, 255, 255, 0.15)',
                    padding: '12px',
                    marginTop: 16,
                  }}
                >
                  <Icon
                    name='comment-alt'
                    fontSize={16}
                    color='rgba(255, 255, 255, 0.5)'
                    style={{ marginRight: 12 }}
                  />
                  {message}
                </div>
              )}
              {managedUsers && (
                <div className='invite-mobile-scroller'>
                  <span className='icon'>
                    <i className='fas fa-angle-down' />
                  </span>
                </div>
              )}
            </>
          )}
        </h2>
        {!error && !accepted && (
          //   invitee.type === 'player' ? (
          //   <InvitePicker
          //     disabled={loading.accept}
          //     isNewInvite={isNewInvite}
          //     isInviteForSelf={isInviteForSelf}
          //     existingUserIdx={existingUserIdx}
          //     managedUsers={managedUsers}
          //     onConfirm={onConfirm}
          //     self={self}
          //   />
          // ) : (
          <div className='accept-button-wrap'>
            {shouldDisplayWaiver && (
              <Waiver
                hasScrolled={hasScrolled}
                setHasScrolled={setHasScrolled}
                waiverData={waiverData}
              />
            )}
            <FormButton
              busy={loading.accept}
              disabled={(shouldDisplayWaiver && !hasScrolled) || loading.accept}
              isSubmit
              className='accept-button'
              onClick={onConfirm.bind(this, self.id)}
              // title={
              //   !hasScrolled
              //     ? 'Read to the bottom of the waiver before joining.'
              //     : ''
              // }
            >
              {t('Web:join')}
            </FormButton>
          </div>
        )}
      </div>
      {/* {!error && !accepted && invitee.type === 'player' && (
        <div className='invite-section right flex flex-dir-col'>
          <InviteCreateNew
            disabled={loading.accept}
            invitee={invitee}
            onCreate={onCreate}
            self={self}
          />
        </div>
      )} */}
    </div>
  )
}

export default connect(
  inviteStateProps,
  inviteDispatchProps
)(withInvite(Invite))
