import React from 'react'
import { DragHandle } from './DragHandle'
import styled from 'styled-components'

const StyledStaticData = styled.td`
  background: linear-gradient(#282e38, #181a1d);
`

const StyledStaticTableRow = styled.tr`
  background: linear-gradient(#282e38, #181a1d);
`

export const StaticTableRow = ({ row }) => {
  return (
    <StyledStaticTableRow {...row.getRowProps()}>
      {row.cells.map((cell, i) => {
        if (i === 0) {
          return (
            <StyledStaticData {...cell.getCellProps()} key={i}>
              <DragHandle isDragging />
              <span>{cell.render('Cell')}</span>
            </StyledStaticData>
          )
        }
        return (
          <StyledStaticData {...cell.getCellProps()} key={i}>
            {cell.render('Cell')}
          </StyledStaticData>
        )
      })}
    </StyledStaticTableRow>
  )
}
