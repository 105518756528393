export const invitationType = [
  {
    id: 1,
    destination: 'Team',
    name: 'Invite Player to Team',
    name_full: 'Invite Player to join a Team',
  },
  {
    id: 2,
    destination: 'Team',
    name: 'Invite User to Team',
    name_full: 'Invite User to join a Team',
  },
  {
    id: 3,
    destination: 'Competition',
    name: 'Invite Team to Competition',
    name_full: 'Invite Team to join a Competition',
  },
  {
    id: 4,
    destination: 'Competition',
    name: 'Invite User to Competition',
    name_full: 'Invite User to join a Competition',
  },
  {
    id: 6,
    destination: 'Organization',
    name: 'Invite User to Organization',
    name_full: 'Invite User to join an Organization',
  },
  {
    id: 7,
    destination: 'Organization',
    name: 'Invite Official to Organization',
    name_full: 'Invite Official to join an Organization',
  },
  {
    id: 8,
    destination: 'Competition',
    name: 'Invite Official to Competition',
    name_full: 'Invite Official to join a Competition',
  },
  {
    id: 9,
    destination: 'Team',
    name: 'Invite Team Official to Team',
    name_full: 'Invite Team Official to join a Team',
  },
  {
    id: 10,
    destination: 'Team',
    name: 'Invite Team Rep to Team',
    name_full: 'Invite Team Rep to join a Team',
  },
]
