/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { ModalHeader } from 'src/pages/GeneralSettings/UpgradeModal'
import {
  Input,
  Radio,
  Button,
  Switch,
  Select,
  Checkbox,
  Alert,
  Space,
} from 'antd'
import Icon from 'src/components/Icon'
import { observer } from 'mobx-react-lite'
import { useI18n } from '@shopify/react-i18n'
import Banner from 'src/components/Banner'
import BannerController from 'src/components/Banner/BannerController'
import req from '@sportninja/common/api/request'
import { useMobxStore } from 'src/state'
import PhoneInput from 'src/components/PhoneInput'
import { RequestsService } from 'src/pages/Onboarding/SignUp/requests'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import debounce from 'lodash/debounce'

async function createTeam({
  name,
  sportId,
  scheduleId,
  organizationId,
}): Promise<Team> {
  const res = await req('/teams', {
    method: 'POST',
    body: JSON.stringify({
      sport_id: sportId,
      name: name,
      schedule_id: scheduleId,
      organization_id: organizationId,
      is_public: true,
      is_visible: true,
    }),
  })

  return res.data
}

async function fetchTeams({ scheduleId, query = '', isGlobal = false }) {
  const res = await req(
    `/registration/${scheduleId}/admin/search-not-registered?query=${query}&is_global=${
      isGlobal ? 1 : 0
    }`
  )

  return res.data
}

async function registerTeam({
  scheduleId,
  teamId,
  couponCodeId,
  registrationId,
  compRegistration,
  autoApproval,
  country,
  zipCode,
  officials,
}) {
  const res = await req(`/registration/${scheduleId}`, {
    method: 'POST',
    body: JSON.stringify({
      team_id: teamId,
      schedule_registration_id: registrationId,
      coupon_code_id: couponCodeId,
      is_deposit_required: autoApproval,
      is_comp: compRegistration,
      country_code: country,
      postal_code: zipCode,
      officials,
    }),
  })

  return res.data
}

type Team = {
  id: string
  uid: string
  name: string
  name_full: string
  abbreviation: null | string
  is_public: boolean
  is_visible: boolean
  sport_id: string
  organization_id: string
  updated_at: string
  is_favorite: boolean
  hierarchy: {
    uid: string
    name: string
    abbreviation: string
  }[]
}

type NewTeamOfficial = {
  phone?: string
  email: string
  firstName: string
  lastName: string
  type: string
  title: string
  zipCode: string
  country: string
}

type TeamOfficial = {
  phone?: string | null
  email: string
  firstName: string
  lastName: string
  title: string
  type: string
  zipCode: string
  country: string
}

interface Props {
  onAddTeamClick: () => void
  onCloseClick: () => void
  division: any
  sportId: string
}

export const AddTeamModalContent = observer(
  ({ onCloseClick, onAddTeamClick, division, sportId }: Props) => {
    const [isLoadingTeams, setIsLoadingTeams] = useState(false)
    const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)
    const [teamOptions, setTeamOptions] = useState<Team[]>([])
    const [autoApproval, setAutoApproval] = useState(true)
    const [compRegistration, setCompRegistration] = useState(false)
    const [couponCode, setCouponCode] = useState('')
    const [loadingCoupon, setLoadingCoupon] = useState(false)
    const [couponInfo, setCouponInfo] = useState({
      discount: 0,
      type: '',
      id: '',
    })
    const [teamOfficialInformation, setTeamOfficialInformation] = useState<
      TeamOfficial[]
    >([])
    const [newTeamOfficialErrorMessage, setNewTeamOfficialErrorMessage] =
      useState('')
    const [newTeamOfficialInfo, setNewTeamOfficialInfo] =
      useState<NewTeamOfficial>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        type: '',
        title: '',
        zipCode: '',
        country: '',
      })
    const [isCreatingNewTeamOfficial, setIsCreatingNewTeamOfficial] =
      useState(false)
    const [isCreatingNewTeam, seIsCreatingNewTeam] = useState(false)
    const [newTeamName, setNewTeamName] = useState('')
    const [isLoadingNewTeamCreation, setLoadingNewTeamCreation] =
      useState(false)
    const [creatingNewTeamErrorMessage, setCreatingNewTeamErrorMessage] =
      useState('')
    const [isSaving, setIsSaving] = useState(false)
    const [treasureTeamOfficial, setTreasureTeamOfficial] = useState('')
    const [searchRootOrg, setSearchRootOrg] = useState(false)
    const [searchValue, setSearchValue] = useState<string | null>(null)
    const [error, setError] = useState<string | boolean | null>(false)

    useEffect(() => {
      if (error) {
        const timer = setTimeout(() => {
          setError(false)
        }, 5000)
        return () => clearTimeout(timer)
      }
    }, [error])

    const [i18n] = useI18n()
    const {
      registration: {
        state: { registration },
      },
      registrationInvite: {
        state: { sports },
      },
    } = useMobxStore()

    const onCancel = () => {
      setAutoApproval(false)
      setCompRegistration(false)
      setCouponCode('')
      onCloseClick()
      setIsSaving(false)
    }

    const teamOfficialTypes = useMemo(() => {
      const selectedSport = sports?.find((sport) => sport.sport_id === sportId)

      const teamOfficialsTypes = selectedSport?.data?.find(
        (el: any) => el.team_official_types
      )?.team_official_types

      const types = [
        ...teamOfficialsTypes.map((el) => {
          return {
            label: el.name,
            value: el.id,
          }
        }),
      ]

      return types
    }, [sports, sportId])

    const onSubmit = useCallback(async () => {
      setIsSaving(true)

      try {
        const { schedule_id } = registration as any

        const selectedTreasureTeamOfficial = teamOfficialInformation.find(
          (el) => el.email === treasureTeamOfficial
        )

        const composeTeamOfficials = [
          ...teamOfficialInformation.map((el) => {
            return {
              email: el.email,
              is_treasurer: el.email === treasureTeamOfficial,
              name_first: el.firstName,
              name_last: el.lastName,
              phone: el.phone,
              team_official_type_id: Number(el.type),
            }
          }),
        ]

        await registerTeam({
          autoApproval,
          compRegistration,
          couponCodeId: couponInfo.id,
          registrationId: division?.registration?.id,
          scheduleId: schedule_id,
          teamId: selectedTeam?.uid || selectedTeam?.id,
          country: selectedTreasureTeamOfficial?.country,
          zipCode: selectedTreasureTeamOfficial?.zipCode,
          officials: composeTeamOfficials,
        })

        // const teamPlayerRoster = response?.team_player_roster_id
        // await req(
        //   `/teams/${
        //     selectedTeam?.uid || selectedTeam?.id
        //   }/rosters/${teamPlayerRoster}/team-officials/registration`,
        //   {
        //     method: 'POST',
        //     body: JSON.stringify({ officials: composeTeamOfficials }),
        //   }
        // )

        setIsSaving(false)
        onAddTeamClick()
      } catch (e) {
        setIsSaving(false)
        setError(getErrorMessage(e))
        console.error('ERROR: ', e)
      }
    }, [
      autoApproval,
      compRegistration,
      couponCode,
      selectedTeam,
      registration,
      treasureTeamOfficial,
      teamOfficialInformation,
    ])

    const countries = useMemo(() => {
      const countryList = RequestsService.getCountries()

      const us = countryList.find((el) => el.iso_3166_2 === 'US')
      const cad = countryList.find((el) => el.iso_3166_2 === 'CA')

      if (us && cad) {
        countryList.unshift(us, cad)
      }

      return countryList
    }, [])

    const validateCouponCode = useCallback(async () => {
      if (couponCode === '') {
        return
      }

      const { schedule_id } = registration as any

      setLoadingCoupon(true)

      try {
        const res = await req(`/registration/${schedule_id}/coupon/verify`, {
          method: 'POST',
          body: JSON.stringify({
            code: couponCode,
          }),
        })

        setCouponInfo({
          discount: res?.data?.discount,
          type: res?.data?.discount_type,
          id: res?.data?.id,
        })
      } catch (e: any) {
        console.error('CPN CD: ', e?.message)
        return BannerController.add(({ ...props }) => (
          <Banner
            duration={undefined}
            onClose={undefined}
            onClick={undefined}
            subChildren={undefined}
            {...props}
            type='failed'
          >
            {e?.message}
          </Banner>
        ))
      } finally {
        setLoadingCoupon(false)
      }
    }, [couponCode, loadingCoupon, registration, division])

    const filterOption = (
      input: string,
      option?: { label: string; value: string }
    ) => {
      return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    }

    const createNewTeam = useCallback(async () => {
      setCreatingNewTeamErrorMessage('')
      setLoadingNewTeamCreation(true)
      const { schedule_id, organization_id } = registration as any

      try {
        const response = await createTeam({
          organizationId: organization_id,
          name: newTeamName,
          scheduleId: schedule_id,
          sportId: sportId,
        })

        setSelectedTeam(response)
        setIsLoadingTeams(true)

        const res = await fetchTeams({
          scheduleId: schedule_id,
        })

        setTeamOptions(res)

        setIsLoadingTeams(false)
        setLoadingNewTeamCreation(false)
        setNewTeamName('')
        seIsCreatingNewTeam(false)
      } catch (e: any) {
        setLoadingNewTeamCreation(false)
        setCreatingNewTeamErrorMessage(
          e?.message?.includes('already exists')
            ? 'Team name already in use. Choose another.'
            : e?.message
        )
      }
    }, [newTeamName, sportId, registration])

    const searchTeams = useCallback(
      async (value: string) => {
        const { schedule_id } = registration as any

        const res = await fetchTeams({
          scheduleId: schedule_id,
          isGlobal: searchRootOrg,
          query: value,
        })

        setTeamOptions(res)
      },
      [searchRootOrg, searchValue]
    )

    useEffect(() => {
      const search = async (searchGlobal: boolean) => {
        setIsLoadingTeams(true)
        const { schedule_id } = registration as any
        const res = await fetchTeams({
          scheduleId: schedule_id,
          isGlobal: searchGlobal,
          query: searchValue ?? '',
        })

        setTeamOptions(res)

        setIsLoadingTeams(false)
      }

      if (searchRootOrg) {
        search(true)
      } else {
        search(false)
      }
    }, [searchRootOrg, searchValue])

    useLayoutEffect(() => {
      const bootstrap = async () => {
        setIsLoadingTeams(true)
        const { schedule_id } = registration as any
        const res = await fetchTeams({
          scheduleId: schedule_id,
        })

        setTeamOptions(res)

        setIsLoadingTeams(false)
      }

      bootstrap()
    }, [registration, sportId])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
      debounce((nextValue) => searchTeams(nextValue), 500),
      []
    )

    const handleSearch = (string) => {
      debouncedSearch(string)
    }

    return (
      <div
        css={css`
          width: 65vw;
          padding: 24px;
          border-radius: 8px;
          background: no-repeat fixed linear-gradient(#282e38, #181a1d);

          @media (max-width: 1200px) {
            width: 90vw;
          }
        `}
      >
        <ModalHeader
          title='Register Team'
          hideSupportButton
          onCloseClick={
            isCreatingNewTeamOfficial || isSaving ? () => {} : onCancel
          }
        />
        <div
          css={css`
            display: flex;
            flex: 1;
            gap: 16px;
            margin: 24px 0;
          `}
        >
          <div
            css={css`
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
            `}
          >
            <div
              css={css`
                flex: 1;
                padding-bottom: 12px;
                border-bottom: 1px solid ${colors.SOFT_STEEL};
              `}
            >
              {isCreatingNewTeam ? (
                <>
                  <p
                    css={css`
                      margin-bottom: 8px;
                      color: ${colors.WHITE};
                    `}
                  >
                    New Team
                  </p>
                  <div
                    css={css`
                      display: flex;
                      align-items: flex-start;
                      gap: 8px;
                    `}
                  >
                    <div
                      css={css`
                        flex: 1;
                      `}
                    >
                      <Input
                        placeholder='Enter the team name'
                        onChange={(e) => setNewTeamName(e.target.value)}
                        value={newTeamName}
                      />
                      <p
                        css={css`
                          color: ${colors.RED};
                          font-size: 12px;
                          margin-top: 8px;
                        `}
                      >
                        {creatingNewTeamErrorMessage}
                      </p>
                    </div>
                    <Button
                      type='primary'
                      loading={isLoadingNewTeamCreation}
                      disabled={isLoadingNewTeamCreation}
                      onClick={createNewTeam}
                    >
                      {isLoadingNewTeamCreation ? 'Creating' : 'Create'}
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      margin-bottom: 8px;
                    `}
                  >
                    <p
                      css={css`
                        color: ${colors.WHITE};
                      `}
                    >
                      Team *
                    </p>

                    <div
                      css={css`
                        .ant-checkbox + span {
                          padding-inline-end: 0px;
                        }
                      `}
                    >
                      <Checkbox
                        onChange={() => setSearchRootOrg(!searchRootOrg)}
                        checked={searchRootOrg}
                      >
                        Search on Root Organization
                      </Checkbox>
                    </div>
                  </div>
                  <div
                    css={css`
                      .ant-select-selection-item > #hierarchy_item {
                        display: none;
                      }
                    `}
                  >
                    <Select
                      css={css`
                        width: 100%;
                      `}
                      showSearch
                      allowClear
                      disabled={isLoadingTeams}
                      defaultValue={selectedTeam?.uid || selectedTeam?.id}
                      loading={isLoadingTeams}
                      placeholder='Select a team'
                      optionFilterProp='children'
                      // onSearch={debounce(searchTeams, 300)}
                      onSearch={handleSearch}
                      value={searchValue}
                      onChange={setSearchValue}
                      dropdownRender={(menu) => menu}
                      onSelect={(e) => {
                        const team = teamOptions.find(
                          (el) =>
                            el.uid === e.toString() || el.id === e.toString()
                        )

                        if (team) {
                          setSelectedTeam(team)
                        }
                      }}
                      filterOption={filterOption}
                    >
                      {teamOptions.map((team) => {
                        return (
                          <Select.Option
                            key={team.uid || team.id}
                            label={team.name_full}
                          >
                            {team.name_full}
                            <div
                              id='hierarchy_item'
                              css={css`
                                display: flex;
                                align-items: center;
                                gap: 4px;
                              `}
                            >
                              {searchRootOrg &&
                                team?.hierarchy.map((el) => {
                                  return (
                                    <span
                                      css={css`
                                        font-size: 10px;
                                        color: ${colors.ATTENDANCE_GRAY};
                                      `}
                                      key={el.uid}
                                    >
                                      {el?.name}
                                      {' >'}
                                    </span>
                                  )
                                })}
                              <span
                                css={css`
                                  font-size: 10px;
                                  color: ${colors.ATTENDANCE_GRAY};
                                `}
                              >
                                {team?.id || team?.uid}
                              </span>
                            </div>
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </div>
                </>
              )}
              <div
                css={css`
                  display: flex;
                  width: 100%;
                  justify-content: flex-end;
                  .ant-btn {
                    padding: 0;
                  }
                `}
              >
                <Button
                  type='link'
                  // disabled={isLoadingNewTeamCreation}
                  onClick={() => {
                    if (isCreatingNewTeam) {
                      setNewTeamName('')
                      setLoadingNewTeamCreation(false)
                    }

                    seIsCreatingNewTeam(!isCreatingNewTeam)
                  }}
                >
                  {isCreatingNewTeam ? 'Cancel' : 'Or create a new team'}
                </Button>
              </div>
            </div>
            <div
              css={css`
                flex: 3.5;
                margin-top: 16px;
              `}
            >
              <p
                css={css`
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  letter-spacing: 0.9px;
                  color: ${colors.WHITE};
                `}
              >
                TEAM OFFICIALS
              </p>
              {teamOfficialInformation.length === 0 && (
                <p
                  css={css`
                    margin-top: 12px;
                    color: ${colors.ATTENDANCE_GRAY};
                    font-size: 12px;
                    font-style: italic;
                  `}
                >
                  At least 1 team official should be selected *
                </p>
              )}
              <div
                css={css`
                  border-radius: 8px;
                  border: 1px solid ${colors.SOFT_STEEL};
                  overflow: hidden;
                  margin-top: 12px;
                `}
              >
                {teamOfficialInformation.map((el, index) => {
                  return (
                    <div
                      key={el.email}
                      css={css`
                        width: 100%;
                        padding: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background-color: ${index % 2 === 0
                          ? 'transparent'
                          : '#292E35'};
                      `}
                    >
                      <p>
                        {el.firstName} - {el.title}
                      </p>
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                          gap: 30px;
                        `}
                      >
                        <div
                          css={css`
                            display: flex;
                            align-items: center;
                            gap: 8px;
                          `}
                        >
                          <p
                            css={css`
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 500;
                              line-height: normal;
                            `}
                          >
                            Treasurer
                          </p>
                          <Switch
                            size='small'
                            checked={String(el.email) === treasureTeamOfficial}
                            onChange={(e) => {
                              setTreasureTeamOfficial(e ? String(el.email) : '')
                            }}
                          />
                        </div>
                        <button
                          onClick={() => {
                            const newTeamOfficialInformation = [
                              ...teamOfficialInformation,
                            ]
                            newTeamOfficialInformation.splice(index, 1)
                            setTeamOfficialInformation(
                              newTeamOfficialInformation
                            )
                          }}
                        >
                          <Icon name='trash' color={colors.RED} />
                        </button>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div
                css={css`
                  display: flex;
                  width: 100%;
                  justify-content: flex-end;
                  .ant-btn {
                    padding: 0;
                  }
                `}
              >
                <Button
                  type='link'
                  onClick={() => setIsCreatingNewTeamOfficial(true)}
                >
                  Create new team official
                </Button>
              </div>
            </div>
          </div>

          <div
            css={css`
              height: auto;
              width: 1px;
              background-color: ${colors.SOFT_STEEL};
            `}
          />

          {isCreatingNewTeamOfficial ? (
            <div
              css={css`
                flex: 1;
                display: flex;
                flex-direction: column;
              `}
            >
              <p
                css={css`
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  letter-spacing: 0.9px;
                  margin-bottom: 16px;
                  color: ${colors.WHITE};
                `}
              >
                NEW TEAM OFFICIAL
              </p>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                `}
              >
                <div
                  css={css`
                    flex: 1;
                  `}
                >
                  <p
                    css={css`
                      margin-bottom: 8px;
                    `}
                  >
                    First Name *
                  </p>
                  <Input
                    value={newTeamOfficialInfo.firstName}
                    onChange={(e) => {
                      setNewTeamOfficialInfo((prev) => {
                        return {
                          ...prev,
                          firstName: e.target.value,
                        }
                      })
                    }}
                  />
                </div>
                <div
                  css={css`
                    flex: 1;
                  `}
                >
                  <p
                    css={css`
                      margin-bottom: 8px;
                    `}
                  >
                    Last Name *
                  </p>
                  <Input
                    value={newTeamOfficialInfo.lastName}
                    onChange={(e) => {
                      setNewTeamOfficialInfo((prev) => {
                        return {
                          ...prev,
                          lastName: e.target.value,
                        }
                      })
                    }}
                  />
                </div>
              </div>
              <div>
                <p
                  css={css`
                    margin: 12px 0;
                  `}
                >
                  Email Address *
                </p>
                <Input
                  value={newTeamOfficialInfo.email}
                  onChange={(e) => {
                    setNewTeamOfficialInfo((prev) => {
                      return {
                        ...prev,
                        email: e.target.value,
                      }
                    })
                  }}
                />
              </div>
              <div
                css={css`
                  display: flex;
                  gap: 8px;
                  align-items: center;
                `}
              >
                <div
                  css={css`
                    flex: 1;
                    margin: 16px 0;
                  `}
                >
                  <p
                    css={css`
                      margin-bottom: 8px;
                    `}
                  >
                    Title *
                  </p>
                  <Select
                    css={css`
                      width: 100%;
                    `}
                    onChange={(e) => {
                      const officialInfo = teamOfficialTypes.find(
                        (el) => el.value === e
                      )

                      setNewTeamOfficialInfo((prev) => {
                        return {
                          ...prev,
                          type: officialInfo.value,
                          title: officialInfo.label,
                        }
                      })
                    }}
                    placeholder='Select...'
                    options={teamOfficialTypes ?? []}
                  />
                </div>
                <div
                  css={css`
                    flex: 1;
                  `}
                >
                  <p
                    css={css`
                      margin-bottom: 8px;
                    `}
                  >
                    Phone Number
                  </p>
                  <PhoneInput
                    value={newTeamOfficialInfo.phone}
                    onChange={(e) => {
                      setNewTeamOfficialInfo((prev) => {
                        return {
                          ...prev,
                          phone: e,
                        }
                      })
                    }}
                  />
                </div>
              </div>
              <div
                css={css`
                  display: flex;
                  gap: 8px;
                  align-items: center;
                  margin-bottom: 16px;
                `}
              >
                <div
                  css={css`
                    flex: 1;
                  `}
                >
                  <p
                    css={css`
                      margin-bottom: 8px;
                    `}
                  >
                    Country *
                  </p>
                  <Select
                    css={css`
                      width: 100%;
                    `}
                    showSearch
                    filterOption={filterOption}
                    placeholder='Select...'
                    onChange={(e) => {
                      setNewTeamOfficialInfo((prev) => {
                        return {
                          ...prev,
                          country: e,
                        }
                      })
                    }}
                    options={
                      countries
                        ? countries.map((el) => {
                            return {
                              label: el?.name,
                              value: el?.iso_3166_2,
                            }
                          })
                        : []
                    }
                  />
                </div>
                <div
                  css={css`
                    flex: 1;
                  `}
                >
                  <p
                    css={css`
                      margin-bottom: 8px;
                    `}
                  >
                    Zip/Postal *
                  </p>
                  <Input
                    value={newTeamOfficialInfo.zipCode}
                    onChange={(e) => {
                      setNewTeamOfficialInfo((prev) => {
                        return {
                          ...prev,
                          zipCode: e.target.value,
                        }
                      })
                    }}
                  />
                </div>
              </div>
              <div
                css={css`
                  display: flex;
                  gap: 20px;
                  margin-top: 24px;
                  align-items: center;
                `}
              >
                <Button
                  block
                  type='default'
                  onClick={() => {
                    setIsCreatingNewTeamOfficial(false)
                  }}
                >
                  <p
                    css={css`
                      font-family: Rift;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 100%; /* 18px */
                      text-transform: uppercase;
                      color: ${colors.WHITE};
                    `}
                  >
                    CLOSE
                  </p>
                </Button>
                <Button
                  block
                  type='primary'
                  onClick={() => {
                    setNewTeamOfficialErrorMessage('')

                    if (newTeamOfficialInfo.firstName === '') {
                      setNewTeamOfficialErrorMessage('Please enter a name')

                      return
                    }

                    if (newTeamOfficialInfo.lastName === '') {
                      setNewTeamOfficialErrorMessage(
                        'Please enter the last name'
                      )

                      return
                    }

                    if (newTeamOfficialInfo.title === '') {
                      setNewTeamOfficialErrorMessage(
                        'Please enter a Team Official Title'
                      )

                      return
                    }

                    if (newTeamOfficialInfo.email === '') {
                      setNewTeamOfficialErrorMessage('Please enter an Email')

                      return
                    }

                    if (
                      !newTeamOfficialInfo.country ||
                      newTeamOfficialInfo.country === ''
                    ) {
                      setNewTeamOfficialErrorMessage('Please select a Country')

                      return
                    }

                    if (
                      !newTeamOfficialInfo.zipCode ||
                      newTeamOfficialInfo.zipCode === ''
                    ) {
                      setNewTeamOfficialErrorMessage(
                        'Please enter a Zip/Postal Code'
                      )

                      return
                    }

                    const emailRegex = /\S+@\S+\.\S+/

                    if (!emailRegex.test(newTeamOfficialInfo.email)) {
                      setNewTeamOfficialErrorMessage(
                        'Please enter a valid Email'
                      )

                      return
                    }

                    const existingEmail = teamOfficialInformation.find(
                      (el) => el.email === newTeamOfficialInfo.email
                    )

                    if (existingEmail) {
                      setNewTeamOfficialErrorMessage(
                        'This email is already in use'
                      )

                      return
                    }

                    setTeamOfficialInformation((prev) => {
                      return [
                        ...prev,
                        {
                          phone: newTeamOfficialInfo.phone || null,
                          email: newTeamOfficialInfo.email,
                          firstName: newTeamOfficialInfo.firstName,
                          lastName: newTeamOfficialInfo.lastName,
                          title: newTeamOfficialInfo.title,
                          type: newTeamOfficialInfo.type,
                          zipCode: newTeamOfficialInfo.zipCode,
                          country: newTeamOfficialInfo.country,
                        },
                      ]
                    })

                    teamOfficialInformation.length === 0 &&
                      setTreasureTeamOfficial(newTeamOfficialInfo.email)

                    setNewTeamOfficialInfo({
                      firstName: '',
                      lastName: '',
                      email: '',
                      phone: '',
                      type: '',
                      title: '',
                      zipCode: '',
                      country: '',
                    })

                    setIsCreatingNewTeamOfficial(false)
                  }}
                >
                  <p
                    css={css`
                      font-family: Rift;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 100%; /* 18px */
                      text-transform: uppercase;
                      color: ${colors.WHITE};
                    `}
                  >
                    SAVE TEAM OFFICIAL
                  </p>
                </Button>
              </div>
              {newTeamOfficialErrorMessage !== '' && (
                <p
                  css={css`
                    margin-top: 16px;
                    color: ${colors.RED};
                  `}
                >
                  {newTeamOfficialErrorMessage}
                </p>
              )}
            </div>
          ) : (
            <div
              css={css`
                flex: 1;
                display: flex;
                flex-direction: column;
              `}
            >
              <div
                css={css`
                  padding-bottom: 12px;
                  border-bottom: 1px solid ${colors.SOFT_STEEL};
                `}
              >
                <p
                  css={css`
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: 0.9px;
                    color: ${colors.WHITE};
                  `}
                >
                  ADMIN OPTIONS
                </p>
                <div
                  css={css`
                    display: flex;
                    align-items: flex-end;
                    gap: 12px;
                    justify-content: space-between;
                  `}
                >
                  <div
                    css={css`
                      margin-top: 16px;
                      align-items: flex-start;
                      text-align: left;
                    `}
                  >
                    <p
                      css={css`
                        color: ${colors.WHITE};
                        font-size: 16px;
                        font-style: normal;
                        line-height: normal;
                      `}
                    >
                      Auto-approval
                    </p>
                    <p
                      css={css`
                        color: ${colors.WHITE};
                        font-size: 16px;
                        font-style: normal;
                        line-height: normal;
                        opacity: 0.5;
                        margin-top: 8px;
                      `}
                    >
                      Team is auto-approved. Deposit not required upfront.
                    </p>
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        margin-top: 8px;
                      `}
                    >
                      <Radio.Group
                        onChange={(e) => setAutoApproval(e.target.value)}
                        value={autoApproval}
                      >
                        <Radio value={false}>Yes</Radio>
                        <Radio value={true}>No</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  <div
                    css={css`
                      height: 7em;
                      width: 1px;
                      align-self: center;
                      background-color: ${colors.SOFT_STEEL};
                    `}
                  />
                  <div
                    css={css`
                      align-items: flex-start;
                      text-align: left;
                    `}
                  >
                    <p
                      css={css`
                        color: ${colors.WHITE};
                        font-size: 16px;
                        font-style: normal;
                        line-height: normal;
                      `}
                    >
                      Complimentary Registration
                    </p>
                    <p
                      css={css`
                        color: ${colors.WHITE};
                        font-size: 16px;
                        font-style: normal;
                        line-height: normal;
                        opacity: 0.5;
                        margin-top: 8px;
                      `}
                    >
                      Team is auto-approved. Add Team at no Cost.
                    </p>
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        margin-top: 8px;
                      `}
                    >
                      <Radio.Group
                        onChange={(e) => setCompRegistration(e.target.value)}
                        value={compRegistration}
                      >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div
                css={css`
                  margin-top: 12px;
                `}
              >
                <p
                  css={css`
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: 0.9px;
                    color: ${colors.WHITE};
                  `}
                >
                  REGISTRATION INFO
                </p>
                <div
                  css={css`
                    margin-top: 12px;
                    display: flex;
                    align-items: flex-end;
                    gap: 12px;
                  `}
                >
                  <div
                    css={css`
                      flex: 20;
                    `}
                  >
                    <p
                      css={css`
                        margin-bottom: 8px;
                        color: ${colors.WHITE};
                      `}
                    >
                      Coupon Code
                    </p>
                    <Input
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                  </div>
                  <div
                    css={css`
                      flex: 1;
                    `}
                  >
                    <Button
                      type='primary'
                      loading={loadingCoupon}
                      disabled={loadingCoupon}
                      onClick={validateCouponCode}
                      icon={<Icon name='check' color={colors.WHITE} />}
                    />
                  </div>
                </div>
              </div>
              <div
                css={css`
                  background-color: ${colors.SOFT_STEEL};
                  display: flex;
                  padding: 16px;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  border-radius: 4px;
                  margin-top: 16px;
                `}
              >
                <p
                  css={css`
                    font-weight: 700;
                  `}
                >
                  Cost per team:{' '}
                  <span
                    css={css`
                      font-weight: 400;
                      text-decoration: ${couponInfo.discount !== 0
                        ? 'line-through'
                        : 'none'};
                    `}
                  >
                    {division?.registration?.team_cost &&
                    division?.registration?.team_cost !== '-'
                      ? i18n.formatCurrency(division.registration.team_cost, {
                          currency: division?.registration?.currency?.name,
                          form: 'explicit',
                        })
                      : '-'}
                  </span>
                  {couponInfo.discount !== 0 && (
                    <span
                      css={css`
                        text-decoration: none !important;
                        font-weight: 400;
                      `}
                    >
                      {' - ' +
                        i18n.formatCurrency(
                          division?.registration?.team_cost -
                            (couponInfo.discount
                              ? couponInfo.type === 'amount'
                                ? Number(couponInfo.discount)
                                : (Number(division?.registration?.team_cost) *
                                    Number(couponInfo.discount)) /
                                  100
                              : 0),
                          {
                            currency: division?.registration?.currency?.name,
                            form: 'explicit',
                          }
                        )}
                    </span>
                  )}
                </p>
                {couponInfo.discount !== 0 && (
                  <p
                    css={css`
                      font-weight: 700;
                    `}
                  >
                    Discount:{' '}
                    <span
                      css={css`
                        font-weight: 400;
                      `}
                    >
                      {couponInfo?.type === 'amount'
                        ? i18n.formatCurrency(couponInfo.discount, {
                            currency: division?.registration?.currency?.name,
                            form: 'explicit',
                          })
                        : `${couponInfo.discount}%`}
                    </span>
                  </p>
                )}
                <p
                  css={css`
                    font-weight: 700;
                  `}
                >
                  Deposit:{' '}
                  <span
                    css={css`
                      font-weight: 400;
                    `}
                  >
                    {division?.registration?.deposit &&
                    division?.registration?.deposit !== '-'
                      ? i18n.formatCurrency(division.registration.deposit, {
                          currency: division?.registration?.currency?.name,
                          form: 'explicit',
                        })
                      : '-'}
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>
        {error ? (
          <Space direction='vertical' style={{ width: '100%' }}>
            <Alert description={error} type='error' showIcon />
          </Space>
        ) : null}
        <div
          css={css`
            margin-top: 16px;
            padding-top: 16px;
            border-top: 1px solid ${colors.SOFT_STEEL};
            display: flex;
            gap: 24px;
            align-items: center;
            justify-content: center;
          `}
        >
          <div
            css={css`
              width: 30%;
            `}
          >
            <Button
              block
              type='default'
              onClick={onCancel}
              disabled={isSaving || isCreatingNewTeamOfficial}
            >
              <p
                css={css`
                  font-family: Rift;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 100%; /* 18px */
                  text-transform: uppercase;
                  color: ${colors.WHITE};
                `}
              >
                CANCEL
              </p>
            </Button>
          </div>
          <div
            css={css`
              width: 30%;
            `}
          >
            <Button
              block
              htmlType='submit'
              type='primary'
              disabled={
                loadingCoupon ||
                isSaving ||
                isCreatingNewTeamOfficial ||
                teamOfficialInformation.length === 0 ||
                treasureTeamOfficial === '' ||
                selectedTeam === null
              }
              loading={isSaving}
              onClick={onSubmit}
            >
              <p
                css={css`
                  font-family: Rift;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 100%; /* 18px */
                  text-transform: uppercase;
                  color: ${colors.WHITE};
                `}
              >
                {isSaving ? '' : 'SUBMIT'}
              </p>
            </Button>
          </div>
        </div>
      </div>
    )
  }
)
