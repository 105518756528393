import { call, put } from 'redux-saga/effects'

import actions from '../actions/teams'

import req from '@sportninja/common/api/request'
import { generateSagas } from './utils'

export const api = {
  readBySchedule: async (id) => await req(`/teams?schedule_id=${id}`)
}

const teams = [
  [actions.readBySchedule, function* ({ id }) {
    const response = yield call(api.readBySchedule, id)
    yield put(actions.readBySchedule.success({ data: response.data }))
    return response
  }],
]

export default generateSagas([
  ...teams,
])
