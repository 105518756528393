import { createContext, useContext } from 'react'

import { RegistrationStore } from './registration'
import { RegistrationInviteStore } from './registrationInvite'
import { RegistrationTeamStore } from './registrationTeam'
import { RegistrationTypesStore } from './registrationTypes'
import { MeStore } from './me'
import { SubscriptionStore } from './subscription'
import { PaymentStore } from './payment'
import { AppStore } from './app'

export class RootStore {
  registration: RegistrationStore
  registrationInvite: RegistrationInviteStore
  registrationTeam: RegistrationTeamStore
  registrationTypes: RegistrationTypesStore
  me: MeStore
  subscription: SubscriptionStore
  payment: PaymentStore
  appStore: AppStore

  constructor() {
    this.registration = new RegistrationStore(this)
    this.registrationInvite = new RegistrationInviteStore(this)
    this.registrationTeam = new RegistrationTeamStore(this)
    this.registrationTypes = new RegistrationTypesStore(this)
    this.me = new MeStore(this)
    this.subscription = new SubscriptionStore(this)
    this.payment = new PaymentStore(this)
    this.appStore = new AppStore(this)
  }
}

export const Store = new RootStore()

const StoreContext = createContext(Store)

export const useMobxStore = () => useContext(StoreContext)
