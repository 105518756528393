/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import deepEqual from 'fast-deep-equal'
import { t } from '@sportninja/common/i18n'
import req from '@sportninja/common/api/request'

import Icon from '../Icon'
import { InputWrapper, Label } from '../Form/css'
import { Flex } from '../Layout'
import { zIndex } from '../css'
import { Scroller, SearchBoxInput } from './css'

const SearchBoxButton = ({ className, iconName, isHidden, onClick, title }) => (
  <button
    tabIndex='-1'
    onClick={onClick}
    title={title}
    type='button'
    css={[
      css`
        position: absolute;
        align-self: center;
        right: 20px;
        font-size: 16px;
        color: white;
        opacity: 0.6;

        :hover {
          opacity: 1;
        }

        z-index: ${zIndex.base};
      `,
      isHidden &&
        css`
          display: none;
        `,
    ]}
    className={className}
  >
    <span className='sr-only'>{title}</span>
    <Icon name={iconName} />
  </button>
)

// eslint-disable-next-line react/display-name
const ScrollerItem = React.forwardRef(
  ({ children, item, nested, onClick, isSelected }, ref) => {
    return (
      <div
        ref={ref}
        key={item.id}
        className={`item ${nested ? 'nested' : ''} ${
          isSelected ? 'active' : ''
        }`}
        onClick={onClick.bind(this, item)}
      >
        {children}
      </div>
    )
  }
)

class SearchBox extends React.Component {
  state = {
    error: false,
    loading: true,
    search: '',
    data: null,
    isOpen: false,
    index: -1,
  }

  maxSelectedIndex = 0
  mounted = false

  input = null
  scroller = null
  selected = React.createRef()

  componentDidMount() {
    this.mounted = true

    const { data, requestMethod, requestUrl } = this.props
    if (!data && (requestMethod || requestUrl)) {
      const promise = requestMethod ? requestMethod() : req(requestUrl)
      promise
        .then((response = {}) => {
          this.onDataReceived(response.data)
        })
        .catch((error) => {
          this.setState({ error: error.message, loading: false })
        })
    } else {
      this.onDataReceived(data)
    }
  }

  componentWillUnmount() {
    this.mounted = false
    document.removeEventListener('keydown', this.onKeyDown)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isOpen && !prevState.isOpen) {
      document.addEventListener('keydown', this.onKeyDown, true)
    } else if (!this.state.isOpen) {
      if (this.state.index !== -1) this.setState({ index: -1 })
      document.removeEventListener('keydown', this.onKeyDown)
    }

    if (this.props.data && !deepEqual(this.props.data, this.state.data)) {
      this.onDataReceived(this.props.data, this.props.defaultValue)
    }

    if (
      this.props.defaultValue &&
      this.props.defaultValue !== prevProps.defaultValue
    ) {
      this.onDataReceived(this.props.data, this.props.defaultValue)
    }
  }

  onDataReceived = (data) => {
    if (this.props.defaultValue && data) {
      const defaultItem = data.find((d) => d.id === this.props.defaultValue)
      defaultItem &&
        this.setState({ search: defaultItem[this.props.displayString] })
    } else {
      this.setState({ search: '' })
    }

    this.setState({ data, loading: false })
  }

  onItemSelected = (selection, shouldClose = true) => {
    this.setState({ search: selection[this.props.displayString] || '' })
    this.props.onChange({
      target: { name: this.props.name, value: selection.id },
    })

    if (shouldClose) {
      this.setState({ isOpen: false })
      this.input.blur()
    }
  }

  onKeyDown = (event) => {
    if (
      !this.state.isOpen ||
      !['ArrowUp', 'ArrowDown', 'Enter'].includes(event.code)
    )
      return // Escape key

    event.preventDefault()

    if (event.code === 'Enter') {
      event.stopPropagation()
      event.stopImmediatePropagation()
      if (this.selected && this.selected.current) this.selected.current.click()
    } else if (event.code === 'ArrowUp') {
      this.setState((prevState) => ({
        index: Math.max(0, prevState.index - 1),
      }))
    } else {
      this.setState((prevState) => ({
        index: Math.min(this.maxSelectedIndex, prevState.index + 1),
      }))
    }
  }

  getFilteredData = () => {
    const { data, index, search } = this.state
    if (!data || typeof data.length == 'undefined') return []

    const { displayString } = this.props

    let items = [],
      length = 0
    if (data[0] && data[0].label) {
      items = data
        .filter((item) => {
          if (
            search.length === 0 ||
            item.label.toLowerCase().indexOf(search.toLowerCase()) >= 0
          )
            return true

          const searching = item.data.filter((i) => {
            return (
              i[displayString].toLowerCase().indexOf(search.toLowerCase()) >= 0
            )
          })

          if (searching && searching.length > 0) return true

          return false
        })
        .map((item) => (
          <Fragment key={item.id}>
            <div className='label'>{item.label}</div>
            {item.data
              .filter((i) => {
                return (
                  i[displayString]
                    .toLowerCase()
                    .indexOf(search.toLowerCase()) >= 0
                )
              })
              .map((i, idx) => {
                const currIndex = length
                const isSelected = index === currIndex
                length++
                return (
                  <ScrollerItem
                    key={idx}
                    isSelected={isSelected}
                    item={i}
                    ref={isSelected ? this.selected : undefined}
                    nested
                    onClick={this.onItemSelected}
                  >
                    {i[displayString]}
                  </ScrollerItem>
                )
              })}
          </Fragment>
        ))
    } else {
      items = data
        .filter((item) => {
          if (search.length === 0 || typeof item[displayString] !== 'string')
            return true
          return (
            item[displayString].toLowerCase().indexOf(search.toLowerCase()) >= 0
          )
        })
        .map((item, idx) => {
          const isSelected = idx === index
          length++
          return (
            <ScrollerItem
              key={idx}
              isSelected={isSelected}
              item={item}
              ref={isSelected ? this.selected : undefined}
              onClick={this.onItemSelected}
            >
              {item[displayString]}
            </ScrollerItem>
          )
        })
    }

    this.maxSelectedIndex = length - 1
    return items
  }

  render() {
    const { error, loading, search, data, isOpen } = this.state
    const {
      bottomContent,
      disabled,
      hasError,
      label,
      noFlex,
      placeholder,
      readOnly,
      required,
    } = this.props

    const sharedInputProps = {
      disabled: disabled || this.state.loading,
      hasError,
      noFlex,
      readOnly,
    }
    const turnOffAutoComplete = {
      autoCapitalize: 'off',
      autoCorrect: 'off',
      autoComplete: 'off',
      spellCheck: 'off',
    }

    const filteredData = isOpen ? this.getFilteredData(data) : []

    return (
      <InputWrapper {...sharedInputProps}>
        {label && (
          <Label {...sharedInputProps}>
            {label}
            {required ? ' *' : ''}
          </Label>
        )}
        <Flex
          css={css`
            position: relative;
          `}
        >
          <SearchBoxInput
            ariaAutoComplete='list'
            {...turnOffAutoComplete}
            onBlur={() => {
              setTimeout(() => {
                this.mounted && this.setState({ isOpen: false })
              }, 250)
            }}
            onFocus={() => this.setState({ isOpen: true })}
            onChange={({ target: { value } }) =>
              this.setState({ search: value })
            }
            placeholder={loading ? `${t('common:loading')}...` : placeholder}
            value={search}
            ref={(r) => (this.input = r)}
            type='search'
            {...sharedInputProps}
          />

          <p
            css={css`
              color: red;
              margin: 12px 0 0 0 !important;
              opacity: 1 !important;
            `}
          >
            {error}
          </p>

          {!readOnly && isOpen && (
            <Scroller>
              <div className='outer'>
                <div
                  className='ignore-scroll-lock inner'
                  ref={(r) => (this.scroller = r)}
                >
                  {filteredData.length > 0 ? (
                    filteredData
                  ) : (
                    <div
                      css={css`
                        opacity: 0.6;
                      `}
                      className='item none-found'
                    >
                      {t('DiscoverScreen:noResults')}.
                    </div>
                  )}
                </div>
              </div>
              {bottomContent}
            </Scroller>
          )}

          {!readOnly && (
            <Fragment>
              <SearchBoxButton
                title={t('common:clear')}
                isHidden={search.length === 0}
                iconName='times'
                css={css`
                  right: 56px;
                  font-size: 18px;
                `}
                onClick={this.onItemSelected.bind(this, {}, false)}
              />
              <SearchBoxButton
                iconName='search'
                title={t('common:search')}
                css={css`
                  pointer-events: none;
                `}
              />
            </Fragment>
          )}
        </Flex>
      </InputWrapper>
    )
  }
}

SearchBox.defaultProps = {
  displayString: 'name_full',
}

SearchBox.propTypes = {
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  displayString: PropTypes.string,
  hasError: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  noFlex: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  requestMethod: PropTypes.func,
  requestUrl: PropTypes.string,
  required: PropTypes.bool,
}

export default SearchBox
