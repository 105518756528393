/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex } from 'src/components/Layout'
import { useMobxStore } from 'src/state'
import backgroundImage from './sandbox_background.jpg'
import backgroundImageSoccer from './sandbox_background_soccer.jpg'
import { store } from '@sportninja/common/store'
import {
  getHockeySport,
  getSoccerSport,
} from '@sportninja/common/selectors/types'

const FADE_INTERVAL_MS = 1750
const WORD_CHANGE_INTERVAL_MS = FADE_INTERVAL_MS * 2

const Sandbox = () => {
  const [error, setError] = useState(null)
  const [progress, setProgress] = useState(0)
  const [textIndex, setTextIndex] = useState(0)
  const [textOpacity, setTextOpacity] = useState(0)
  const [textAnimation, setTextAnimation] = useState('')
  const [fadeProp, setFadeProp] = useState({ fade: 'fade-in' })
  const [wordOrder, setWordOrder] = useState(0)
  const history = useHistory()
  const {
    me: {
      state: { account, me },
    },
  } = useMobxStore()
  const isSoccer = useMemo(() => {
    return window.location.pathname.includes('soccer')
  }, [])
  const hockeySportId = getHockeySport(store.getState())?.id
  const soccerSportId = getSoccerSport(store.getState())?.id
  const WORDS_TO_ANIMATE = isSoccer
    ? [
        'trimming the grass...',
        'selecting all star players...',
        'buying tickets...',
        'Reticulating splines...',
        'trimming the grass...',
        'selecting all star players...',
        'buying tickets...',
        'Reticulating splines...',
      ]
    : [
        'preparing the rink...',
        'selecting all star players...',
        'buying tickets...',
        'Reticulating splines...',
        'preparing the rink...',
        'selecting all star players...',
        'buying tickets...',
        'Reticulating splines...',
      ]

  useEffect(() => {
    if (error && error !== null) {
      setTimeout(() => {
        history.push('/')
      }, 5000)
    }
  }, [error])

  useEffect(() => {
    const createSandbox = async () => {
      try {
        const result = await req(
          `/account/${me?.account_id}/sandbox/${
            isSoccer ? soccerSportId : hockeySportId
          }`
        )
        if (result && result?.organization_id) {
          console.log('Redirecting with sandbox tour')
          history.push(`/org/${result?.organization_id}?sandbox_tour=1`)
        } else {
          setError(
            'There was a problem creating your sandbox (no organization id found). Contact SportNinja support.'
          )
        }
      } catch (e) {
        console.error(e)
        setError(
          e?.message ||
            'There was a problem creating your sandbox. Contact SportNinja support.'
        )
      }
    }
    if (me?.account_id) {
      createSandbox()
    } else {
      setError(
        'There was a problem creating your sandbox (No account_id). Contact/sportNinja support'
      )
    }
  }, [me?.account_id, isSoccer])

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 1)
    }, 250)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const fadeTimeout = setInterval(() => {
      fadeProp.fade === 'fade-in'
        ? setFadeProp({ fade: 'fade-out' })
        : setFadeProp({ fade: 'fade-in' })
    }, FADE_INTERVAL_MS)

    return () => clearInterval(fadeTimeout)
  }, [fadeProp])

  useEffect(() => {
    const wordTimeout = setInterval(() => {
      setWordOrder(
        (prevWordOrder) => (prevWordOrder + 1) % WORDS_TO_ANIMATE.length
      )
    }, WORD_CHANGE_INTERVAL_MS)

    return () => clearInterval(wordTimeout)
  }, [])

  return (
    <Flex
      column
      fullWidth
      height='100vh'
      justifyContent='center'
      alignItems='center'
      css={css`
        width: 100%;
        background-image: url(${isSoccer
          ? backgroundImageSoccer
          : backgroundImage});
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      `}
    >
      <div
        css={css`
          opacity: 0.9;
          background: var(
            --Gradient1,
            linear-gradient(180deg, #282e38 0%, #181a1d 100%)
          );
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
        `}
      >
        <div
          css={css`
            width: 250px;
          `}
        >
          <h2
            css={css`
              margin-bottom: 16px;
            `}
          >
            {error ? (
              <span
                css={css`
                  color: var(--neutrals-white, ${colors.FIERY_RED});
                  font-family: Rift;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 100%; /* 24px */
                  letter-spacing: 0.36px;
                  text-transform: uppercase;
                `}
              >
                {error}. You will be redirected shortly.
              </span>
            ) : (
              <span
                css={css`
                  color: var(--neutrals-white, #fff);
                  font-family: Rift;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 100%; /* 24px */
                  letter-spacing: 0.36px;
                  text-transform: uppercase;
                `}
              >
                {WORDS_TO_ANIMATE[wordOrder]}
              </span>
            )}
          </h2>
          <div
            style={{
              width: 250,
              height: '15px',
              backgroundColor: colors.HEADER,
              borderRadius: '24px',
            }}
          >
            <div
              style={{
                maxWidth: 250,
                width: `${progress}%`,
                height: '15px',
                backgroundColor: '#F2B400',
                borderRadius: '24px',
              }}
            />
          </div>
        </div>
      </div>
    </Flex>
  )
}

export default observer(Sandbox)
