import req from '@sportninja/common/api/request'
import type { Account } from './index'

class Service {
  async updateAccount(
    id: string,
    data: Omit<
      Account,
      | 'uid'
      | 'subscription_type'
      | 'subscription'
      | 'subscription_usage'
      | 'payment_method'
      | 'subscription_upcoming_invoice'
      | 'participants'
      | 'taxes'
    >
  ) {
    const res = await req(`/account/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    })

    return res.data
  }
}

export const MeService = new Service()
