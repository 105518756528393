/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import colors from '@sportninja/common/constants/appColors'
import { t } from '@sportninja/common/i18n'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { Link } from 'react-router-dom'
import { font } from '../../components/css'
import Icon from '../../components/Icon'
import { Flex } from '../../components/Layout'
import LoadingSpinner from '../../components/LoadingSpinner'
import Picture from '../../components/Picture'
import Tooltip from '../../components/StatisticsTable/Tooltip'

export const SidebarWrap = styled.div`
  width: 256px;
  min-width: 256px;
  position: relative;
  margin-left: 16px;
`

export const SidebarInner = styled.div`
  width: inherit;
  position: fixed;
  padding: 16px;
  background: #27303e;
  border: 1px solid ${colors.SOFT_STEEL};
  border-radius: 8px;
  max-height: 85vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.SOFT_STEEL};
  }
`

export const SidebarHeader = ({
  isEntity,
  iconName,
  imageId,
  name,
  to,
  subTitle,
}) => {
  return (
    <Flex row>
      <Picture
        imageId={imageId}
        size='kxsmall'
        iconName={iconName}
        square={isEntity}
        fit={isEntity}
      />
      <Flex
        column
        justifyContent='center'
        css={css`
          margin-left: 8px;
          ${isEntity &&
          css`
            margin-bottom: 16px;
          `}
        `}
      >
        <div
          css={css`
            ${font.title}
            font-weight: 700;
            font-size: 24px;

            letter-spacing: 0.015em;
            text-transform: uppercase;

            color: ${colors.WHITE};
          `}
        >
          {name}
        </div>
        {to && (
          <Link
            to={to}
            css={css`
              font-weight: 500;
              font-size: 12px;
              color: ${colors.DEFAULT_FLAIR};
              margin-top: 4px;
              &:hover {
                color: white;
              }
            `}
          >
            {subTitle}
          </Link>
        )}
      </Flex>
    </Flex>
  )
}

export const NewPostButton = ({ isDisabled, isLoading, ...props }) => {
  return (
    <button
      disabled={isDisabled || isLoading}
      type='button'
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 36px;
        border: 2px solid ${colors.DEFAULT_FLAIR};
        border-radius: 8px;
        color: ${colors.WHITE};
        ${font.title}
        font-weight: 700;
        font-size: 16px;

        ${isLoading &&
        css`
          cursor: wait;
        `}

        ${isDisabled &&
        css`
          cursor: not-allowed;
        `}

        ${(isLoading || isDisabled) &&
        css`
          opacity: 0.7;
        `}

        ${!isLoading &&
        !isDisabled &&
        css`
          &:hover {
            border-color: ${colors.WHITE};
          }
        `}
      `}
      {...props}
    >
      {isLoading ? (
        <div
          css={css`
            width: 19px;
            margin-right: 7px;
            transform: translateX(-3px);
          `}
        >
          <LoadingSpinner size={1.2} borderSize={1.5} />
        </div>
      ) : (
        <Icon
          name='pencil'
          color={colors.WHITE}
          fontSize={15}
          css={css`
            margin-right: 11px;
          `}
        />
      )}
      New Announcement
    </button>
  )
}

export const LoadingButton = () => {
  return <NewPostButton isLoading />
}

export const DisabledButton = () => {
  return (
    <Tooltip
      text={`You do not belong to any ${t('common:competition', {
        count: 2,
      })}, ${t('common:organization', { count: 2 })}, or ${t('common:team', {
        count: 2,
      })}.`}
      css={css`
        cursor: not-allowed;
      `}
      textClassName={css`
        max-width: 300px;
        background-color: rgba(0, 0, 0, 0.7);

        &::after {
          border-top-color: rgba(0, 0, 0, 0.7);
        }
      `}
      tipColor='rgba(0, 0, 0, 0.7)'
    >
      <div
        css={css`
          pointer-events: none;
        `}
      >
        <NewPostButton isDisabled />
      </div>
    </Tooltip>
  )
}
