/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { ROUTES } from '@sportninja/common/constants/app'
import { t } from '@sportninja/common/i18n'
import React, { useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { NewModal } from 'src/components/NewModal'
import NewOrgForm from 'src/components/NewOrgForm'
import { createOrg, editOrg } from 'src/helpers/EntityCreationHelper'
import { useMobxStore } from 'src/state'
import Page from '../../components/Page'
import OrgList from './OrgList'
import GeneratePluginModal from 'src/components/GeneralPluginModal'

const Organizations = () => {
  const [orgCount, setOrgCount] = useState('')
  const [refreshKey, setRefreshKey] = useState(0)
  const [initialState, setInitialState] = useState(null)
  const orgModalRef = useRef(null)
  const generatePluginTagModalRef = useRef(null)
  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const onClickWebPluginOnScheduleList = (schedule) => {
    setSelectedSchedule(schedule)
    generatePluginTagModalRef?.current?.openModal()
  }

  const {
    me: {
      state: { account },
    },
  } = useMobxStore()

  const triggerRefresh = () => {
    setRefreshKey((key) => ++key)
  }

  const actions = useMemo(() => {
    return []
  }, [])

  const pages = useMemo(
    () => [
      {
        to: `${ROUTES.ORGS.ROOT}/my`,
        text: `${t('common:organization', { count: 2 })} ${
          orgCount ? `(${orgCount})` : ''
        }`,
        component: (
          <OrgList
            key='orgs'
            slug='/users/me/organizations'
            setCount={setOrgCount}
            refreshKey={refreshKey}
            setInitialState={setInitialState}
            onEditOrg={() => {
              orgModalRef.current.openModal()
            }}
            onClickWebPluginOnScheduleList={onClickWebPluginOnScheduleList}
          />
        ),
        actions: actions,
      },
      {
        to: `${ROUTES.ORGS.FAVOURITES}`,
        text: 'following',
        component: (
          <OrgList
            key='favs'
            slug='/favorites/organizations'
            setCount={() => {
              console.log('setFavCount')
            }}
            setInitialState={setInitialState}
            onEditOrg={() => {
              orgModalRef.current.openModal()
            }}
          />
        ),
      },
    ],
    [orgCount, refreshKey, actions, setOrgCount, setInitialState, orgModalRef]
  )

  const BASE_ROUTE = ROUTES.ORGS.ROOT

  return (
    <>
      <Page
        baseRoute={BASE_ROUTE}
        pages={pages}
        baseTitle={t('common:organization', { count: 2 })}
        title={t('util:capitalize', {
          text: t('common:organization', { count: 2 }),
        })}
      />
      <NewModal ref={orgModalRef} shouldCloseOnOverlayClick={true}>
        <div
          css={css`
            width: 50vw;
            max-width: 800px;
            background: #26303e;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            transition: all 0.1s ease-in-out;
            /* border-radius: 8px; */
            padding-top: 16px;
          `}
        >
          <NewOrgForm
            initialState={initialState || null}
            onSubmit={initialState ? editOrg : createOrg}
            onCancel={() => {
              setInitialState(null)
              orgModalRef.current.closeModal()
            }}
            triggerRefresh={triggerRefresh}
            reloadPage={true}
            account={account}
            orgModalRef={orgModalRef}
          />
        </div>
      </NewModal>
      <NewModal
        ref={generatePluginTagModalRef}
        shouldCloseOnOverlayClick={false}
      >
        <GeneratePluginModal
          onPressClose={() => {
            generatePluginTagModalRef?.current?.closeModal()
            setSelectedSchedule(null)
          }}
          scheduleId={selectedSchedule?.id}
          isOrganization={true}
        />
      </NewModal>
    </>
  )
}

export default observer(Organizations)
