import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ROUTES } from '@sportninja/common/constants/app'

// If our App local state says we aren't authenticated, redirect to the login
// page instead of viewing the provided Route/component
class PrivateRoute extends React.Component {
  constructor(props) {
    super(props)
    this.renderRouteIfAuthenticated = this.renderRouteIfAuthenticated.bind(this)
  }

  // Refactored out of the main render method to ease unit testing
  renderRouteIfAuthenticated(props) {
    const { isAuthenticated, component: Component, ...rest } = this.props
    return isAuthenticated ? (
      <Component {...props} {...rest} />
    ) : (
      <Redirect
        to={{
          pathname: ROUTES.LOGIN,
          state: { from: props.location },
        }}
      />
    )
  }

  render() {
    const { component: Component, isAuthenticated, ...rest } = this.props // eslint-disable-line no-unused-vars

    return <Route {...rest} render={this.renderRouteIfAuthenticated} />
  }
}

export default PrivateRoute
