
import { generateActions } from './utils'

// The arrays describe the arguments that are passed to the REQUEST action
const searchActionDefinition = {
  search: [ 'url', 'entityType' ]
}

const searchActions = generateActions(['SEARCH'], searchActionDefinition)

export default searchActions
