/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'

import Icon from '../Icon'
import { media } from '../Responsive'
import { Tooltip } from 'antd'

const EditButton = ({ onClick, title }) => {
  return (
    <Tooltip title={`Edit ${title}`} mouseEnterDelay={1} placement='bottom'>
      <button
        className='header-action'
        type='button'
        title={`Edit ${title}`}
        onClick={onClick}
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 32px;
          ${media.mobile} {
            border: 1px solid ${colors.DEFAULT_FLAIR};
            border-radius: 4px;
            i {
              font-size: 13px;
              color: white;
            }
          }
          &:hover {
            i {
              color: white;
            }
          }
        `}
      >
        <Icon name='pencil' color={colors.DEFAULT_FLAIR} fontSize={18} />
      </button>
    </Tooltip>
  )
}

export default EditButton
