/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Flex } from '../Layout'
import Picture from '../Picture'
import { font } from '../css'

import { backgroundCss, sectionAndSubTitleText, SectionTitle } from './css'
import CardContainer from './CardContainer'
import { InitialsThumbnail } from '../ThumbnailAndName'

const DesktopCard = ({
  backgroundText,
  children,
  customCss,
  customPicture,
  iconName,
  imageId,
  isEntity,
  sectionTitle,
  subTitle,
  title,
  titleCss,
  to,
  initials,
}) => (
  // Card container
  <CardContainer customCss={customCss}>
    {/* Section title, e.g. "My Teams" */}
    {sectionTitle && <SectionTitle css={titleCss}>{sectionTitle}</SectionTitle>}
    {/* Player number background (optional) */}
    <Flex alignItems='center' justifyContent='center' css={backgroundCss}>
      {backgroundText}
    </Flex>
    {/* Image */}
    <Flex
      as={to ? Link : undefined}
      to={to ? to : undefined}
      tabIndex={-1}
      alignItems='center'
      justifyContent='center'
      noFlex
      css={css`
        height: 150px;
        max-height: 150px;
        padding: 8px 8px 14px;
      `}
    >
      {customPicture ? (
        customPicture
      ) : imageId || !initials ? (
        <Picture
          imageId={imageId}
          fill={isEntity}
          iconName={iconName}
          size='large'
          square={isEntity}
        />
      ) : (
        <InitialsThumbnail size={108}>{initials}</InitialsThumbnail>
      )}
    </Flex>
    {/* Text container */}
    <Flex
      alignItems='center'
      column
      css={css`
        margin: 0 8px 12px;
      `}
    >
      {/* Main title */}
      <Flex
        as={to ? Link : 'h2'}
        aria-label={`Visit the ${title} details page`}
        to={to ? to : undefined}
        css={css`
          ${font.title}
          font-size: 24px;
          font-weight: 500;
          letter-spacing: 0.64px;
          line-height: 22px;
          margin-bottom: 12px;

          text-align: center;
          word-break: break-word;

          display: -webkit-box;
          -webkit-line-clamp: 3;
          -moz-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          max-height: 68px; /* magic number for 3 lines */
        `}
      >
        {title}
      </Flex>
      {/* Sub-title */}
      <h3
        className='card-sub-title'
        css={css`
          ${sectionAndSubTitleText}
          text-align: center;
          margin-top: auto;
          margin-bottom: 7px;

          display: -webkit-box;
          -webkit-line-clamp: ${isEntity ? 2 : 4};
          -moz-line-clamp: ${isEntity ? 2 : 4};
          line-clamp: ${isEntity ? 2 : 4};
          -webkit-box-orient: vertical;
          overflow: hidden;
          max-height: ${isEntity ? 30 : 50}px; /* magic number for 2/3 lines */
        `}
      >
        {subTitle}
      </h3>
      {/* Bottom text or buttons */}
      {children}
    </Flex>
  </CardContainer>
)

DesktopCard.propTypes = {
  backgroundText: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
  customCss: PropTypes.object,
  customPicture: PropTypes.node,
  iconName: PropTypes.string,
  imageId: PropTypes.string,
  isEntity: PropTypes.bool,
  sectionTitle: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  subTitle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
}

export default DesktopCard
