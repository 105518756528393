import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { arrayToObject, toArray } from '../utils/utils'

dayjs.extend(customParseFormat)

export const ACTIONS = {
  goal: 'Goal',
  goalieChange: 'Goalie Change',
  penalty: 'Penalty',
  substitution: 'Substitution',
}

const timelineSorter = (a, b) => {
  if (a.gameTime > b.gameTime) return -1
  else if (a.gameTime < b.gameTime) return 1

  // SN-942: Goals should occur before goalie changes, if occurred at identical
  // clock time
  if (a.action === ACTIONS.goal && b.action === ACTIONS.goalieChange) {
    return -1
  } else if (b.action === ACTIONS.goal && a.action === ACTIONS.goalieChange) {
    return 1
  }

  return 0
}

// `period_clock_time` is of format 'hh:mm:ss' but we want to strip any leading
// zeroes of the hour (or strip hours if we are still dealing in minutes)
const formatClockTime = (event) => {
  if (
    !event ||
    !event.period_clock_time ||
    typeof event.period_clock_time !== 'string'
  )
    return ''

  const time = event.period_clock_time
  const slices = time.split(':')
  if (slices?.length === 3 && slices[0] === '00') {
    return slices.slice(1).join(':')
  } else return time
}

const parseAudit = (event) => {
  if (!event || !event.last_audit) return {}
  const audit = event.last_audit
  const user = audit.user

  return {
    edited: {
      at: dayjs(audit.created_at).format("h:mm a - MMM. D, 'YY"),
      by: Object.prototype.hasOwnProperty.call(user, 'name_first')
        ? `${user.name_first} ${user.name_last}`
        : false,
    },
  }
}

const getBaseInfo = (event, player) => {
  let initials = ''
  if (player?.nameFirst) {
    initials += player.nameFirst[0]
  }
  if (player?.nameLast) {
    initials += player.nameLast[0]
  }

  return {
    id: event.id,
    imageId: player.imageId,
    number: player.number,
    name: player.fullName,
    nameFirst: player.nameFirst,
    nameLast: player.nameLast,
    gameTime: event.period_clock_time,
    time: formatClockTime(event),
    playerId: player.id,
    teamId: player.teamId || event?.shot?.team_id || event?.team_id,
    initials,
    occurredAt: event.occurred_at || event?.shot?.occurred_at,
    positionAbbrev: player?.positionAbbrev || '-',

    ...parseAudit(event),
  }
}

const getFromCache = (cache, playerId, teamId) => {
  try {
    const player = cache?.find((p) => {
      if (teamId) {
        return p.teamId === teamId && p.id === playerId
      } else {
        return p.id === playerId
      }
    })
    return player || {}
  } catch (e) {
    // With this, we avoid the app to crash if the backend is not updated
    console.error(e)
    const cp = cache[playerId]
    return cp || {}
  }
}
const getPeriodId = (event) => event.period_id || event.period.id

const parseAssists = (goal, playerCache) => {
  if (!goal.assists || goal.assists.length === 0) {
    return []
  }

  return goal.assists
    .filter((a) => Object.prototype.hasOwnProperty.call(a, 'player'))
    .map((a) => {
      let player = getFromCache(playerCache, a.player.id, goal.shot.team_id)
      if (Object.keys(player).length === 0) {
        player = {
          id: a.player.id,
          number: a.player.player_number,
          nameFirst: a.player.name_first,
          nameLast: a.player.name_last,
        }
      }
      return {
        assistId: a.id,
        ...player,
      }
    })
}

export const goalParser = (goal, playerCache) => {
  // A shot must exist or we're gonna have a bad time
  if (!goal || !goal.shot) return

  let player = getFromCache(playerCache, goal.shot.player_id, goal.shot.team_id)

  return {
    ...getBaseInfo(goal, player),
    action: ACTIONS.goal,
    goal_type_id: goal?.goal_type_id,
    assists: parseAssists(goal, playerCache),
  }
}

const parseGoal = (goal, playerCache, periods) => {
  // A shot must exist or we're gonna have a bad time
  if (!goal || !goal.shot) return

  const event = goalParser(goal, playerCache)

  const periodId = goal.shot.period_id
  const period = periods[periodId]
  period.events.push(event)
}

const parseOffense = (offense, playerCache, periods) => {
  let player = getFromCache(playerCache, offense.player_id, offense.team_id)
  const server = getFromCache(
    playerCache,
    offense.penalty.served_by_player_id,
    offense.team_id
  )

  const event = {
    ...getBaseInfo(offense, player),
    action: ACTIONS.penalty,
    duration: offense.penalty.amount,
    serverId: offense.penalty.served_by_player_id,
    serverName: server.fullName,
    serverNumber: server.number,
    type: offense?.offense_type?.name_full,
    typeId: offense?.offense_type?.id,
    card: offense?.penalty?.card,
  }
  const period = periods[getPeriodId(offense)]
  period.events.push(event)
}

const parseGoalieChange = (change, playerCache, periods) => {
  const previous = getFromCache(
    playerCache,
    change.previous_goalie_id,
    change.team_id
  )
  const next = change.goalie_id
    ? getFromCache(playerCache, change.goalie_id, change.team_id)
    : null

  let event = {
    ...getBaseInfo(change, previous),
    action: ACTIONS.goalieChange,
  }

  if (next) {
    event.nextGoalie = { ...next, name: next.fullName }
    if (!event.teamId) event.teamId = next.teamId
  }

  const period = periods[getPeriodId(change)]

  period.events.push(event)
}

/**
 *
 * @param {object} game - the game from the API
 * @param {object} playerCache - player ID: player info
 * @returns {object} a sorted array of periods, containing period information;
 * importantly, an 'events' array of sorted goals, assists, penalties occurring in a period
 */
export const getGameTimeline = (
  game,
  homeRoster,
  visitorRoster,
  isSoccer = false
) => {
  if (!game || !game?.periods || typeof game?.periods?.length === 'undefined') {
    return []
  }

  const periods = arrayToObject(game?.periods, (period) => ({
    ...period,
    homeShotCount: 0,
    visitingShotCount: 0,
    events: [],
  }))

  const homeArray = toArray(homeRoster)
  const visitorArray = toArray(visitorRoster)
  const playerCache = [...homeArray, ...visitorArray]

  game?.goalieChanges?.forEach((change) => {
    parseGoalieChange(change, playerCache, periods)
  })
  game?.goals?.forEach((goal) => {
    parseGoal(goal, playerCache, periods)
  })
  game?.offenses?.forEach((offense) => {
    parseOffense(offense, playerCache, periods)
  })

  for (const p in periods) {
    periods[p].homeOffenseCount = game?.offenses?.filter((o) => {
      return o.team_id === game?.homeTeam?.id && o?.period_id === p
    }).length
    periods[p].visitingOffenseCount = game?.offenses.filter(
      (o) => o.team_id === game?.visitingTeam?.id && o?.period_id === p
    ).length
    periods[p].homeShotCount = periods[p]?.shots_home_count
    periods[p].visitingShotCount = periods[p]?.shots_visiting_count
    periods[p].events.sort(timelineSorter)
  }

  // Soccer, we should reverse the order of the periods and events
  const reversedPeriods = {}
  if (isSoccer) {
    for (const p in periods) {
      reversedPeriods[p] = {
        ...periods[p],
        // events: periods[p].events.reverse(),
      }
    }
    return toArray(reversedPeriods).reverse()
  }

  return isSoccer ? reversedPeriods : toArray(periods)
}
