import req from '@sportninja/common/api/request'
import { call, put } from 'redux-saga/effects'

import actions from '../actions/types'
import {
  defaultSportIdForHockey,
  defaultSportsResponse,
  defaultTypesForHockey,
} from '../utils/default-types'
import { generateSagas } from './utils'

export const api = {
  countries: async () => await req('/countries'),
  provinces: async (countryId) =>
    await req(`/countries/${countryId}/provinces`),
  timezones: async (countryId) =>
    await req(`/countries/${countryId}/timezones`),
  sports: async () => await req('/sports', { timeout: 5000 }),
  typesBySport: async (sportId) =>
    await req(`/sports/${sportId}/types`, { timeout: 5000 }),
  allTypes: async () => await req('/sports/types', { timeout: 5000 }),
}

const types = [
  [
    actions.countries,
    function* () {
      const countries = yield call(api.countries)
      yield put(actions.countries.success({ countries }))
      return countries
    },
  ],

  [
    actions.provinces,
    function* (payload) {
      const { countryId } = payload
      const provinces = yield call(api.provinces, countryId)
      yield put(actions.provinces.success({ countryId, provinces }))
      return provinces
    },
  ],

  [
    actions.timezones,
    function* (payload) {
      const { countryId } = payload
      const timezones = yield call(api.timezones, countryId)
      yield put(actions.timezones.success({ countryId, timezones }))
      return timezones
    },
  ],
  [
    actions.sports,
    function* () {
      try {
        const response = yield call(api.sports)
        yield put(actions.sports.success({ data: response?.data }))
        return response
      } catch (e) {
        yield put(actions.sports.success({ data: defaultSportsResponse.data }))
        return defaultSportsResponse
      }
    },
  ],
  [
    actions.typesBySport,
    function* (payload) {
      try {
        const { sportId } = payload
        const response = yield call(api.typesBySport, sportId)
        yield put(actions.typesBySport.success({ id: sportId, data: response }))
        return response
      } catch (e) {
        yield put(
          actions.typesBySport.success({
            id: defaultSportIdForHockey,
            data: defaultTypesForHockey,
          })
        )
        return defaultTypesForHockey
      }
    },
  ],
  [
    actions.allTypes,
    function* () {
      try {
        const response = yield call(api.allTypes)
        yield put(actions.allTypes.success({ data: response }))
        return response
      } catch (e) {
        yield put(
          actions.typesBySport.success({
            id: defaultSportIdForHockey,
            data: defaultTypesForHockey,
          })
        )
        return defaultTypesForHockey
      }
    },
  ],
]

export default generateSagas([...types])
