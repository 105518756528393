import { produce } from 'immer'

import authActions from '../actions/auth'
import userActions from '../actions/user'
import feedActions from '../actions/feed'
import orgActions from '../actions/org'
import scheduleActions from '../actions/schedule'
import teamActions from '../actions/team'

import { str } from './utils'
import { setChildListById } from './helpers'

const login = {
  loading: false,
  error: false,
  success: false,
  newUserInviteId: false,
}
const user = {}
const managedUsers = {}
const roles = {
  schedule: [],
  org: [],
  team: [],
}
const feed = []
const relatedTeams = []
const relatedOrgs = []
const relatedSchedules = []

const initialState = {
  user,
  managedUsers,
  login,
  roles,
  feed,
  relatedTeams,
  relatedOrgs,
  relatedSchedules,
  inFrame: false,
  tabBarVisible: true,
}

export default (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (
      type // eslint-disable-line default-case
    ) {
      case 'TOGGLE_TAB_BAR':
        draft.tabBarVisible =
          typeof payload === 'boolean' ? payload : !draft.tabBarVisible
        return

      case 'IN_FRAME':
        draft.inFrame = payload
        return

      case str(authActions.login.request):
        draft.login = { ...draft.login, error: false, loading: true }
        return

      case str(authActions.login.success): {
        draft.login = { ...login, success: true }
        draft.user = payload
        return
      }

      case str(authActions.login.failure):
        draft.login = { ...draft.login, loading: false, error: payload.error }
        return

      case str(authActions.logout.success):
        draft.user = user
        draft.managedUsers = managedUsers
        draft.roles = roles
        draft.feed = []

        // We specifically set these, instead of spread, so we don't clobber the
        // loading state that might be happening
        draft.login.error = false
        draft.login.success = false
        return

      case str(authActions.setNewUserInviteId.request): {
        draft.login = {
          ...draft.login,
          newUserInviteId: payload.newUserInviteId,
        }
        return
      }

      case str(userActions.read.success): {
        draft.user = payload.data
        return
      }

      case str(userActions.readManagedUsers.success):
        setChildListById(draft, { id: 'managedUsers', data: payload.data })
        return

      case str(userActions.readOrgs.success):
        setChildListById(draft, { id: 'relatedOrgs', data: payload.data })
        return

      case str(userActions.readSchedules.success):
        setChildListById(draft, { id: 'relatedSchedules', data: payload.data })
        return

      case str(userActions.readTeams.success):
        setChildListById(draft, { id: 'relatedTeams', data: payload.data })
        return

      case str(feedActions.readAll.success): {
        setChildListById(draft, { id: 'feed', data: payload.data })
        return
      }

      case str(orgActions.feed.create.success):
      case str(scheduleActions.feed.create.success):
      case str(teamActions.feed.create.success): {
        const data = payload.data
        const isFavorite = data && data.parent && data.parent.is_favorite
        if (isFavorite) draft.feed.push(data.id)
        return
      }

      case str(orgActions.feed.delete.success):
      case str(scheduleActions.feed.delete.success):
      case str(teamActions.feed.delete.success): {
        const feedItemIdx = draft.feed.findIndex(
          (i) => i === payload.feedItemId
        )
        if (feedItemIdx > -1) {
          draft.feed.splice(feedItemIdx, 1)
        }
        return
      }
    }
  })
