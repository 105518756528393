import req from '@sportninja/common/api/request'
import actions from '../actions/notifications'
import { call, put } from 'redux-saga/effects'
import { generateSagas } from './utils'

export const api = {
  listAll: async () => await req('/notifications'),
  readNotification: async (notificationId) =>
    await req(`/notifications/${notificationId}`),
  readAll: async () => await req('/notifications/read'),
}

const notifications = [
  [
    actions.listAll,
    function* () {
      try {
        const result = yield call(api.listAll)
        yield put(actions.listAll.success({ notifications: result }))
        return result
      } catch (error) {
        yield put(actions.listAll.failure({ error: error.message }))
        return error
      }
    },
  ],
  [
    actions.readNotification,
    function* ({ notificationId }) {
      try {
        const result = yield call(api.readNotification, `${notificationId}`)
        yield put(actions.readNotification.success({ notification: result }))
        return result
      } catch (error) {
        yield put(actions.readNotification.failure({ error: error.message }))
        return error
      }
    },
  ],
  [
    actions.readAll,
    function* () {
      try {
        const result = yield call(api.readAll)
        yield put(actions.readAll.success({ notifications: result }))
        return result
      } catch (error) {
        yield put(actions.readAll.failure({ error: error.message }))
        return error
      }
    },
  ],
]

export default generateSagas([...notifications])
