/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import cn from 'classnames'

import { zIndex } from '../css'
import { Flex } from '../Layout'
import { CELL_WIDTH, FIRST_CELL_WIDTH } from './list-constants'

const ListCell = ({
  as,
  sizePercent,
  fontSize = 16,
  children,
  isSorted,
  minWidth,
  isFirstCell,
  bgColor,
  listCellCss,
  isMobile,
  ...props
}) => {
  return (
    <Flex
      as={as}
      noFlex
      alignItems='center'
      className={cn('list-cell', {
        'list-cell-sorted': isSorted,
        'first-cell': isFirstCell,
      })}
      css={css`
        height: inherit;
        width: ${sizePercent}%;

        font-size: ${fontSize}px;
        word-break: break-word;
        padding-right: 8px;

        ${isMobile &&
        css`
          min-width: ${minWidth || CELL_WIDTH}px;
          max-width: ${minWidth || CELL_WIDTH}px;

          padding-right: 8px;
          width: unset;
          font-size: 14px;
          ${isFirstCell &&
          css`
            min-width: ${minWidth || FIRST_CELL_WIDTH}px;
            max-width: ${minWidth || FIRST_CELL_WIDTH}px;
            position: sticky;
            left: 0;
            background-color: ${colors.HEADER};
            border-right: 1px solid ${colors.SOFT_STEEL};
            margin-right: 12px;
            box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.25);
            clip-path: inset(0px -3px 0 0);
            padding-left: 8px;
            padding-right: 8px;
            z-index: ${zIndex.base};
          `}
        `}

        ${listCellCss}

        ${isSorted &&
        css`
          color: ${colors.DEFAULT_FLAIR};
        `}
      `}
      {...props}
    >
      {children}
    </Flex>
  )
}

export default ListCell
