/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import classnames from 'classnames'

import './index.scss'

interface Props {
  borderSize?: number
  className?: string
  fullScreen?: boolean
  size: number
  style?: any
  rgbColor?: string
  rgbColorTop?: string
}

const LoadingSpinner = ({
  className,
  rgbColor,
  rgbColorTop,
  fullScreen = false,
  borderSize,
  size,
  style = {},
}: Props) => {
  const classes = classnames('loading-spinner is--loading', className, {
    'is--fullscreen': fullScreen,
  })

  return (
    <div
      className={classes}
      style={style}
      css={css`
        & {
          height: ${size}em;
        }

        &.loading-spinner.is--loading.is--loading:after {
          height: ${size}em;
          width: ${size}em;
          left: calc(50% - (${size}em / 2));
          top: calc(50% - (${size}em / 2));
          border-width: ${borderSize}px;

          ${rgbColor && `border-color: ${rgbColor};`}
          ${rgbColorTop && `border-top-color: ${rgbColorTop};`}
        }
      `}
    >
      <span className='sr-only'>Loading</span>
    </div>
  )
}

export default LoadingSpinner
