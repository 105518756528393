import styled from '@emotion/styled/macro'

import { backgroundColor, font, zIndex, backgroundColorLight } from '../css'
import { Flex } from '../Layout'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  z-index: ${zIndex.overlay};
  background-color: black;

  pointer-events: none;
  opacity: 0;

  transition: opacity ${(props) => props.duration || 300}ms;

  &.overlay-enter-active,
  &.overlay-enter-done {
    pointer-events: auto;
    opacity: 0.85;
  }
`

const settings = {
  top: {
    transform: 'translateY(-100%)',
    borderRadius: '0 0 2px 2px',
    boxShadow: 'rgb(0,0,0) 0px 4px 35px 0',
    left: 0,
    right: 0,
    top: 0,
  },
  right: {
    transform: 'translateX(100%)',
    borderRadius: '2px 0 0 2px',
    boxShadow: 'rgb(0,0,0) -4px 0px 35px 0',
    top: 0,
    right: 0,
  },
  bottom: {
    transform: 'translateY(100%)',
    borderRadius: '2px 2px 0 0',
    boxShadow: 'rgb(0,0,0) 0px -4px 35px 0',
    left: 0,
    right: 0,
    bottom: 0,
  },
  left: {
    transform: 'translateX(-100%)',
    borderRadius: '0 2px 2px 0',
    boxShadow: 'rgb(0,0,0) 4px 0px 35px 0',
    top: 0,
    left: 0,
    bottom: 0,
  },
}

export const SheetContainer = styled.div`
  position: fixed;

  height: ${(props) => props.height || '100%'};
  width: ${(props) =>
    props.width ? props.width : props.isMobile ? '100%' : '656px'};
  max-width: ${(props) =>
    props.width ? props.width : props.isMobile ? '100%' : '656px'};

  overflow: hidden;
  z-index: ${zIndex.modal};

  background: ${(props) =>
    props.noGradient
      ? backgroundColorLight
      : `linear-gradient(${backgroundColorLight}, ${backgroundColor})`};

  ${({ position }) => settings[position]};

  transition: transform ${(props) => props.duration}ms;

  &.sheet-enter-active,
  &.sheet-enter-done {
    transform: translate(0%);
  }
`

export const ScrollingContainer = styled(Flex)`
  position: relative;
  height: 100%;
  padding: ${(props) => (props.isMobile ? '40px 24px' : '6vh 32px')} 48px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  pointer-events: ${(props) => !props.open && 'none'};
`

export const SheetTitle = styled.h2`
  ${font.title}
  font-size: ${(props) => (props.isMobile ? 32 : 32)}px;
  font-weight: bold;
  letter-spacing: 0.93px;
  margin-bottom: ${(props) => (props.isMobile ? 16 : 16)}px;
`

export const CloseButton = styled.button`
  position: fixed;
  right: 32px;
  top: -8px;

  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  font-size: 44px;

  /*
   * ScrollingContainer's overflow: auto property will prevent touch events from
   * occurring on SiteButton. So, stack it at the top with a big z-index
   */
  z-index: 10000 !important;
`
