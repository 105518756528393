/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { font } from '../../../components/css'
import Icon from '../../../components/Icon'
import { Flex } from '../../../components/Layout'

const RegistrationIndicatorCard = ({
  iconName,
  iconSize = 25,
  iconColor = colors.ATTENDANCE_GRAY,
  iconType,
  title,
  mainText,
  subText,
  hasIncreased,
  renderContent,
  customHeight,
  onEditPress,
  image,
}) => {
  return (
    <Flex
      alignItems='center'
      css={css`
        padding: 16px;
        max-height: ${customHeight || '102px'};
        min-height: 102px;
        margin-bottom: 16px;
        /* max-width: 270px; */

        background: ${colors.HEADER};
        border-radius: 8px;
      `}
    >
      <div
        css={css`
          width: 32px;
          margin-right: 16px;
          text-align: center;
        `}
      >
        {image ? (
          <div
            css={css`
              width: 42px;
              height: 42px;
              border-radius: 50%;
              overflow: hidden;
              background-color: ${colors.WHITE};
              padding: 1px;
            `}
          >
            <img
              alt={title}
              src={image}
              css={css`
                width: 100%;
                height: 100%;
                border-radius: 50%;
              `}
            />
          </div>
        ) : (
          <Icon
            name={iconName}
            fontSize={iconSize}
            color={iconColor}
            faType={iconType}
          />
        )}
      </div>
      <div
        css={css`
          width: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
          `}
        >
          <span
            css={css`
              font-weight: 700;
              font-size: 14px;
              color: ${colors.DEFAULT_FLAIR};
              text-transform: uppercase;
            `}
          >
            {title}
          </span>
          {onEditPress && (
            <button onClick={onEditPress}>
              <Icon name='pen' color={colors.DEFAULT_FLAIR} fontSize={12} />
            </button>
          )}
        </div>
        {renderContent ? (
          renderContent()
        ) : (
          <>
            <div
              css={css`
                ${font.title}
                font-weight: 700;
                font-size: 24px;
                letter-spacing: 0.05em;
                margin-top: 4px;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 85%;
              `}
            >
              {mainText}
            </div>
            {subText && (
              <Flex
                noFlex
                alignItems='center'
                css={css`
                  height: 24px;
                  margin-top: 2px;
                `}
              >
                <div
                  css={css`
                    text-align: center;
                    width: 24px;
                  `}
                >
                  <Icon
                    name={hasIncreased ? 'caret-up' : 'caret-down'}
                    color={
                      hasIncreased ? colors.SUCCESS_GREEN : colors.ERROR_LIGHT
                    }
                  />
                </div>
                <div
                  css={css`
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 12px;
                    color: ${colors.ATTENDANCE_GRAY};
                  `}
                >
                  {subText}
                </div>
              </Flex>
            )}
          </>
        )}
      </div>
    </Flex>
  )
}

export default RegistrationIndicatorCard
