/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import { Fragment, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { t } from '@sportninja/common/i18n'
import { EMAIL_LENGTH_MAX } from '@sportninja/common/constants/app'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import authActions from '@sportninja/common/actions/auth'

import Form from '../Form'
import { BodyText } from '../AuthWrap/css'

const ResendVerifyEmail = ({ email, resend }) => {
  const form = [
    [{
      name: 'email', label: t('common:email'), autoComplete: 'email', type: 'email',
      autoCorrect: 'off', autoCapitalize: 'none', required: true,
      maxLength: EMAIL_LENGTH_MAX, defaultValue: email
    }]
  ]

  const [ error, setError ] = useState(false)
  const [ success, setSuccess ] = useState(false)

  const onSubmit = useCallback(async (form = {}) => {
    try {
      const email = form.email
      await resend(email)
      setError(false)
      setSuccess(email)
    } catch(e) {
      setSuccess(false)

      if (e.status === 400 && e.invalid_fields && e.invalid_fields.validated_at) {
        setError(t('errors:emailAlreadyVerified'))
      } else if (e.status === 404) {
        setError(t('errors:couldNotFindAccount'))
      } else {
        setError(e.message)
      }
    }
  })

  return (
    <Fragment>
      <Form
        css={css`flex: initial;`}
        cancelText={false}
        doNotDisable
        form={form}
        hideCancel
        onSubmit={onSubmit}
        submitText={t('Auth:resendEmail')} />
      <BodyText css={css`margin-top: 16px;`}>
        {!error && success && <p>{t('Auth:confirmEmailSent')}</p>}
        {!success && error && <p className='error'>{error}</p>}
      </BodyText>
    </Fragment>
  )
}

ResendVerifyEmail.propTypes = {
  email: PropTypes.string,
  resend: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => {
  return {
    resend: bindActionToPromise(dispatch, authActions.resend.request)
  }
}

export default connect(null, mapDispatchToProps)(ResendVerifyEmail)
