/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'

import Icon from '../../../../components/Icon'
import { Flex } from '../../../../components/Layout'

const Cell = ({ className, isActive, children, direction, onClick }) => {
  return (
    <th
      className={`cell ${className ? className : ''}`}
      css={css`
        cursor: ${typeof onClick === 'function' ? 'pointer' : 'normal'};
        text-transform: uppercase;
        font-weight: bold;
        width: 100px;

        &.name {
          width: 225px;
        }

        &.num {
          width: 70px;
        }

        &.position {
          width: 110px;
        }

        &.status {
          width: 78px;
          font-size: 13px;
        }
      `}
      onClick={onClick}
    >
      <Flex
        alignItems='center'
        justifyContent='center'
        css={css`
          margin-top: 2px;
        `}
      >
        {children}
        {direction && (
          <div
            css={css`
              margin-top: -2px;
              margin-left: 4px;

              i {
                opacity: 0.3;
                margin: -5px 0;
                display: block;

                &.active {
                  opacity: 1;
                }
              }
            `}
          >
            <Icon
              name='caret-up'
              className={isActive && direction === 'asc' ? 'active' : ''}
            />
            <Icon
              name='caret-down'
              className={isActive && direction === 'desc' ? 'active' : ''}
            />
          </div>
        )}
      </Flex>
    </th>
  )
}

const RosterEditingTeamHeader = ({
  setSort,
  setDirection,
  sort,
  direction,
}) => {
  const handleSort = (sortType) => {
    if (sort === sortType) {
      setDirection((d) => (d === 'asc' ? 'desc' : 'asc'))
    } else {
      setSort(sortType)
      setDirection('asc')
    }
  }

  return (
    <thead
      css={css`
        position: sticky;
        top: 0;
        z-index: 1;
      `}
    >
      <tr
        css={css`
          th {
            background-color: ${colors.HEADER};
          }
        `}
      >
        <Cell
          direction={direction}
          isActive={sort === 'name_last'}
          onClick={handleSort.bind(this, 'name_last')}
          className='name'
        >
          Name
        </Cell>
        <Cell
          direction={direction}
          isActive={sort === 'player_number'}
          onClick={handleSort.bind(this, 'player_number')}
          className='num'
        >
          #
        </Cell>
        <Cell
          direction={direction}
          isActive={sort === 'player_type.id'}
          onClick={handleSort.bind(this, 'player_type.id')}
          className='position'
        >
          Position
        </Cell>
        <Cell className='status'>Starting</Cell>
        <Cell className='status'>Playing</Cell>
        <Cell className='status'>Injured</Cell>
      </tr>
    </thead>
  )
}

export default RosterEditingTeamHeader
