import React, { useEffect } from 'react'
import req from '@sportninja/common/api/request'
import { t } from '@sportninja/common/i18n'
import parsers from '@sportninja/common/reducers/parsers'
import { ROLE_TYPE_ID_TO_STRING } from '@sportninja/common/constants/app'

import { userForm } from '../Form/form-configs'
import FormSheet from '../Form/FormSheet'
import NewButton from '../Page/NewButton'
import { InviteAction } from '../ActionPanel/ActionPanelActions'
import useGetListItems from './useGetListItems'
import OptionButtonEdit from './OptionButtons/Edit'
import OptionButtonDelete from './OptionButtons/Delete'
import List from '.'
import PersonCell from './PersonCell'
import MoreOptions from './OptionButtons/More'

export const AddUser = ({ permission, entityType, entityId, onComplete }) => {
  const onAddUser = async (...args) => {
    const [values] = args
    const { message_text, role_type_id, ...form } = values

    if (typeof role_type_id === 'string') {
      form.role_type_id = parseInt(role_type_id, 10)
    } else {
      form.role_type_id = role_type_id
    }
    const body = JSON.stringify(form)
    const response = await req(`/${entityType}/${entityId}/permissions`, {
      method: 'POST',
      body,
    })
    onComplete()
    return response
  }

  return (
    permission.admin && (
      <FormSheet
        key='add'
        Button={({ onClick }) => <NewButton onClick={onClick} />}
        form={userForm({})}
        label={t('Web:inviteUser')}
        onSubmit={onAddUser}
        title={t('Web:inviteUser')}
      />
    )
  )
}

const UserList = ({ entityType, entityId, slug, refreshKey }) => {
  const listProps = useGetListItems(slug)
  const { sort, direction, getItems } = listProps

  useEffect(() => {
    getItems()
  }, [refreshKey, sort, direction])

  const headers = [
    {
      text: t('common:name'),
      sizePercent: 34,
      // sort: 'name_last',
      Cell: PersonCell,
    },
    {
      text: t('common:role'),
      sizePercent: 19,
      // sort: 'role_type_id',
      minWidth: 100,
      accessor: (item) => {
        return ROLE_TYPE_ID_TO_STRING[item.role_type_id]
      },
    },
    {
      text: t('common:email'),
      sizePercent: 34,
      // sort: 'email',
      minWidth: 240,
      accessor: 'email',
    },
    {
      text: 'Invite',
      sizePercent: 13,
      // sort: 'is_accepted',
      minWidth: 80,
      accessor: (item) => {
        let status = '--'
        if (item?.is_invited) status = 'Invited'
        if (item?.is_accepted) status = 'Accepted'

        return status
      },
    },
  ]

  const options = (item) => {
    if (item.role_type_id === 0) return []
    let slug = `/${entityType}/${entityId}/permissions/${item.id}`

    if (!item?.is_accepted) {
      slug = `/invitations/${item.invitation_id}`
    }

    const onEdit = async (...args) => {
      const [values] = args
      const body = JSON.stringify(values)
      const response = await req(slug, {
        method: 'PUT',
        body,
      })
      await getItems()
      return response
    }

    const opts = [
      <OptionButtonEdit
        key='edit'
        form={userForm(parsers.user(item))}
        onSubmit={onEdit}
        title={t('Web:editUser')}
      />,
      <InviteAction
        key='invite'
        invite={{
          is_invited: item.is_invited,
          is_accepted: item.is_accepted,
          invitation_id: item.invitation_id,
        }}
        user={{
          id: item.id,
          name_first: item.name_first,
          name_last: item.name_last,
          role_type_id: item.role_type,
          email: item?.email,
        }}
        slug={slug}
        onComplete={getItems}
      />,
      <MoreOptions
        key='more'
        options={[
          {
            key: 'remove',
            Component: OptionButtonDelete,
            componentProps: {
              buttonText: 'remove',
              slug,
              itemId:
                item?.is_accepted && item?.is_accepted === true
                  ? item.id
                  : item.invitation_id,
              iconName: 'user-minus',
              title: `Remove ${item.name_first} ${item.name_last}`,
              type: t('common:user'),
              onComplete: getItems,
            },
          },
        ]}
      />,
    ]

    return opts.filter((o) => o !== undefined)
  }

  return (
    <List
      headers={headers.filter((o) => o !== undefined)}
      options={options}
      listProps={listProps}
      noItemsText={t('common:user', { count: 2 })}
    />
  )
}

export default UserList
