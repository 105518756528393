/* eslint-disable no-dupe-keys */
/** @jsxImportSource @emotion/react */
import req from '@sportninja/common/api/request'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'

import css from '@emotion/css/macro'
import { ROUTES } from '@sportninja/common/constants/app'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { Avatar, Table, Typography } from 'antd'
import cn from 'classnames'
import Icon from 'src/components/Icon'
import { Flex } from 'src/components/Layout'
import {
  SortOrderButton as SNSortOrderButton,
  InFrameSortOrderButton,
} from 'src/components/List/GameList/GameListFilters'

const PlayerStatistics = ({
  history,
  id,
  homeTeamId,
  visitingTeamId,
  isSoccer = false,
  defaultSortOrder,
  isInFrame = false,
  sport,
}) => {
  const SortOrderButton = isInFrame ? InFrameSortOrderButton : SNSortOrderButton
  const [loading, setLoading] = useState(false)
  const [isHome, setIsHome] = useState(true)
  const [playerType, setPlayerType] = useState('skater')
  const [skaterData, setSkaterData] = useState([])
  const [goalieData, setGoalieData] = useState([])
  const isHockey = sport?.name === 'hockey'

  const skaterTableData = useMemo(
    () => [
      ...skaterData.map((item, index) => ({
        key: index,
        player: item,
        team: item,
        PN: item?.player?.player_number ?? '#',
        // SKATER DATA
        GP: item?.stats?.find((s) => s.abbr === 'GP')?.value ?? 0,
        G: item?.stats?.find((s) => s.abbr === 'G')?.value ?? 0,
        A: item?.stats?.find((s) => s.abbr === 'A')?.value ?? 0,
        P: item?.stats?.find((s) => s.abbr === 'P')?.value ?? 0,
        PiM: item?.stats?.find((s) => s.abbr === 'PiM')?.value ?? 0,
        PPG: item?.stats?.find((s) => s.abbr === 'PPG')?.value ?? 0,
        'PTS/G': item?.stats?.find((s) => s.abbr === 'PTS/G')?.value ?? 0,
        // GOALIE DATA
        W: item?.stats?.find((s) => s.abbr === 'W')?.value ?? 0,
        L: item?.stats?.find((s) => s.abbr === 'L')?.value ?? 0,
        T: item?.stats?.find((s) => s.abbr === 'T')?.value ?? 0,
        OTL: item?.stats?.find((s) => s.abbr === 'OTL')?.value ?? 0,
        GAA: item?.stats?.find((s) => s.abbr === 'GAA')?.value ?? 0,
        'SV%': item?.stats?.find((s) => s.abbr === 'SV%')?.value ?? 0,
        SO: item?.stats?.find((s) => s.abbr === 'SO')?.value ?? 0,
        MP: item?.stats?.find((s) => s.abbr === 'MP')?.value ?? 0,
        SA: item?.stats?.find((s) => s.abbr === 'SA')?.value ?? 0, // Saves Average
        SV: item?.stats?.find((s) => s.abbr === 'SV')?.value ?? 0,
        GA: item?.stats?.find((s) => s.abbr === 'GA')?.value ?? 0,
        // SPECIFIC SOCCER DATA
        'G/M': item?.stats?.find((s) => s.abbr === 'G/M')?.value ?? 0,
        GCA: item?.stats?.find((s) => s.abbr === 'GCA')?.value ?? 0,
        SA: item?.stats?.find((s) => s.abbr === 'SA')?.value ?? 0, // Shots Against
        SV: item?.stats?.find((s) => s.abbr === 'SV')?.value ?? 0,
        SO: item?.stats?.find((s) => s.abbr === 'SO')?.value ?? 0,
        YC: item?.stats?.find((s) => s.abbr === 'YC')?.value ?? 0,
        FC: item?.stats?.find((s) => s.abbr === 'FC')?.value ?? 0,
        GC: item?.stats?.find((s) => s.abbr === 'GC')?.value ?? 0,
        FG: item?.stats?.find((s) => s.abbr === 'FG')?.value ?? 0,
        RC: item?.stats?.find((s) => s.abbr === 'RC')?.value ?? 0,
        TP: item?.stats?.find((s) => s.abbr === 'TP')?.value ?? 0,
        CS: item?.stats?.find((s) => s.abbr === 'CS')?.value ?? 0,
        D: item?.stats?.find((s) => s.abbr === 'D')?.value ?? 0,
        Mins: item?.stats?.find((s) => s.abbr === 'Mins')?.value ?? 0,
        GF: item?.stats?.find((s) => s.abbr === 'GF')?.value ?? 0,
        GFA: item?.stats?.find((s) => s.abbr === 'GFA')?.value ?? 0,
      })),
    ],
    [skaterData]
  )

  const goalieTableData = useMemo(
    () => [
      ...goalieData.map((item, index) => ({
        key: index,
        player: item,
        team: item,
        PN: item?.player?.player_number ?? '#',
        // SKATER DATA
        GP: item?.stats?.find((s) => s.abbr === 'GP')?.value ?? 0,
        G: item?.stats?.find((s) => s.abbr === 'G')?.value ?? 0,
        A: item?.stats?.find((s) => s.abbr === 'A')?.value ?? 0,
        P: item?.stats?.find((s) => s.abbr === 'P')?.value ?? 0,
        PiM: item?.stats?.find((s) => s.abbr === 'PiM')?.value ?? 0,
        PPG: item?.stats?.find((s) => s.abbr === 'PPG')?.value ?? 0,
        'PTS/G': item?.stats?.find((s) => s.abbr === 'PTS/G')?.value ?? 0,
        // GOALIE DATA
        W: item?.stats?.find((s) => s.abbr === 'W')?.value ?? 0,
        L: item?.stats?.find((s) => s.abbr === 'L')?.value ?? 0,
        T: item?.stats?.find((s) => s.abbr === 'T')?.value ?? 0,
        OTL: item?.stats?.find((s) => s.abbr === 'OTL')?.value ?? 0,
        GAA: item?.stats?.find((s) => s.abbr === 'GAA')?.value ?? 0,
        'SV%': item?.stats?.find((s) => s.abbr === 'SV%')?.value ?? 0,
        SO: item?.stats?.find((s) => s.abbr === 'SO')?.value ?? 0,
        MP: item?.stats?.find((s) => s.abbr === 'MP')?.value ?? 0,
        SA: item?.stats?.find((s) => s.abbr === 'SA')?.value ?? 0, // Saves Average
        SV: item?.stats?.find((s) => s.abbr === 'SV')?.value ?? 0,
        GA: item?.stats?.find((s) => s.abbr === 'GA')?.value ?? 0,
        // SPECIFIC SOCCER DATA
        'G/M': item?.stats?.find((s) => s.abbr === 'G/M')?.value ?? 0,
        GCA: item?.stats?.find((s) => s.abbr === 'GCA')?.value ?? 0,
        SA: item?.stats?.find((s) => s.abbr === 'SA')?.value ?? 0, // Shots Against
        SV: item?.stats?.find((s) => s.abbr === 'SV')?.value ?? 0,
        SO: item?.stats?.find((s) => s.abbr === 'SO')?.value ?? 0,
        YC: item?.stats?.find((s) => s.abbr === 'YC')?.value ?? 0,
        FC: item?.stats?.find((s) => s.abbr === 'FC')?.value ?? 0,
        GC: item?.stats?.find((s) => s.abbr === 'GC')?.value ?? 0,
        FG: item?.stats?.find((s) => s.abbr === 'FG')?.value ?? 0,
        RC: item?.stats?.find((s) => s.abbr === 'RC')?.value ?? 0,
        TP: item?.stats?.find((s) => s.abbr === 'TP')?.value ?? 0,
        CS: item?.stats?.find((s) => s.abbr === 'CS')?.value ?? 0,
        D: item?.stats?.find((s) => s.abbr === 'D')?.value ?? 0,
        Mins: item?.stats?.find((s) => s.abbr === 'Mins')?.value ?? 0,
        GF: item?.stats?.find((s) => s.abbr === 'GF')?.value ?? 0,
        GFA: item?.stats?.find((s) => s.abbr === 'GFA')?.value ?? 0,
      })),
    ],
    [goalieData]
  )

  const commonColumns = [
    {
      title: '#',
      key: 'PN',
      dataIndex: 'PN',
      fixed: 'left',
      width: 50,
      sorter: (a, b, sortOrder) => a.PN - b.PN,
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: {
        title: 'Player Number',
      },
    },
    {
      title: 'Player',
      key: 'player',
      dataIndex: 'player',
      sorter: (a, b, sortOrder) =>
        a.player.player.name_first.localeCompare(b.player.player.name_first),
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (item) => {
        return (
          <Link
            to={`${ROUTES.PLAYER_ROOT}/${item?.player?.id}`}
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
            `}
          >
            <Avatar
              src={
                getImageThumbnailId(item?.player) ||
                '/images/placeholders/hockey/player.png'
              }
              icon={<Icon name='user' fontSize={14} />}
              css={css`
                border: 1px solid #dbdbdb;
                background-color: white;
                margin: 0;
              `}
            />
            <Typography.Text strong>
              {item?.player?.name_first} {item?.player?.name_last}
            </Typography.Text>
          </Link>
        )
      },
      fixed: 'left',
      width: 270,
    },
  ]

  const skaterColumns = [
    {
      title: 'GP',
      key: 'GP',
      sorter: (a, b, sortOrder) => a.GP - b.GP,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'GP',
      showSorterTooltip: {
        title: 'Games Played',
      },
    },
    {
      title: 'G',
      key: 'G',
      sorter: (a, b, sortOrder) => a.G - b.G,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'G',
      showSorterTooltip: {
        title: 'Goals',
      },
    },
    {
      title: 'A',
      key: 'A',
      sorter: (a, b, sortOrder) => a.A - b.A,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'A',
      showSorterTooltip: {
        title: 'Assists',
      },
    },
    {
      title: 'PTS',
      key: 'P',
      sorter: (a, b, sortOrder) => a.P - b.P,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'P',
      defaultSortOrder: 'descend',
      showSorterTooltip: {
        title: 'Points',
      },
    },
    {
      title: 'PIM',
      key: 'PiM',
      sorter: (a, b, sortOrder) => a.PiM - b.PiM,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'PiM',
      showSorterTooltip: {
        title: 'Penalty Minutes',
      },
    },
    {
      title: 'PPG',
      key: 'PPG',
      sorter: (a, b, sortOrder) => a.PPG - b.PPG,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'PPG',
      showSorterTooltip: {
        title: 'Power Play Goals',
      },
    },
  ]

  const goalieColumns = [
    {
      title: 'GP',
      key: 'GP',
      sorter: (a, b, sortOrder) => a.GP - b.GP,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'GP',
      showSorterTooltip: {
        title: 'Games Played',
      },
    },
    {
      title: 'W',
      key: 'W',
      sorter: (a, b, sortOrder) => a.W - b.W,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'W',
      defaultSortOrder: 'descend',
      showSorterTooltip: {
        title: 'Wins',
      },
    },
    {
      title: 'L',
      key: 'L',
      sorter: (a, b, sortOrder) => a.L - b.L,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'L',
      showSorterTooltip: {
        title: 'Losses',
      },
    },
    {
      title: 'T',
      key: 'T',
      sorter: (a, b, sortOrder) => a.T - b.T,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'T',
      showSorterTooltip: {
        title: 'Ties',
      },
    },
    {
      title: 'OTL',
      key: 'OTL',
      sorter: (a, b, sortOrder) => a.OTL - b.OTL,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'OTL',
      showSorterTooltip: {
        title: 'Overtime Losses',
      },
    },
    {
      title: 'SV%',
      key: 'SV%',
      width: 70,
      sorter: (a, b, sortOrder) => a['SV%'] - b['SV%'],
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'SV%',
      showSorterTooltip: {
        title: 'Save Percentage',
      },
    },
    {
      title: 'SO',
      key: 'SO',
      sorter: (a, b, sortOrder) => a.SO - b.SO,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'SO',
      showSorterTooltip: {
        title: 'Shutouts',
      },
    },
    {
      title: 'MIN',
      key: 'MP',
      width: 70,
      sorter: (a, b, sortOrder) => a.MP - b.MP,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'MP',
      showSorterTooltip: {
        title: 'Minutes Played',
      },
    },
    {
      title: 'PIM',
      key: 'PiM',
      sorter: (a, b, sortOrder) => a.PiM - b.PiM,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'PiM',
      showSorterTooltip: {
        title: 'Penalty Minutes',
      },
    },
    {
      title: 'SA',
      key: 'SA',
      sorter: (a, b, sortOrder) => a.SA - b.SA,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'SA',
      showSorterTooltip: {
        title: 'Shots Against',
      },
    },
    {
      title: 'SV',
      key: 'SV',
      sorter: (a, b, sortOrder) => a.SV - b.SV,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'SV',
      showSorterTooltip: {
        title: 'Saves',
      },
    },
    {
      title: 'GA',
      key: 'GA',
      sorter: (a, b, sortOrder) => a.GA - b.GA,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'GA',
      showSorterTooltip: {
        title: 'Goals Against',
      },
    },
  ]

  const playerColumns = [
    // Removed as requested by Jeff on 2024.03.13
    // {
    //   title: 'MP',
    //   key: 'MP',
    //   sorter: (a, b, sortOrder) => {
    //     setSortBy('MP')
    //     const direction = sortOrder === 'ascend' ? 'asc' : 'desc'
    //     setDirection(direction)
    //   },
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   dataIndex: 'MP',
    //   showSorterTooltip: {
    //     title: 'Matches Played',
    //   },
    // },
    {
      title: 'Mins',
      key: 'Mins',
      sorter: (a, b, sortOrder) => a.Mins - b.Mins,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'Mins',
      showSorterTooltip: {
        title: 'Minutes Played',
      },
    },
    {
      title: 'G',
      key: 'G',
      sorter: (a, b, sortOrder) => a.G - b.G,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      dataIndex: 'G',
      showSorterTooltip: {
        title: 'Goals',
      },
    },
    {
      title: 'A',
      key: 'A',
      sorter: (a, b, sortOrder) => a.A - b.A,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'A',
      showSorterTooltip: {
        title: 'Assists',
      },
    },
    // {
    //   title: 'GFA',
    //   key: 'GFA',
    //   sorter: (a, b, sortOrder) => {
    //     setSortBy('GFA')
    //     const direction = sortOrder === 'ascend' ? 'asc' : 'desc'
    //     setDirection(direction)
    //   },
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   dataIndex: 'GFA',
    //   showSorterTooltip: {
    //     title: 'Goals For Average',
    //   },
    // },
    // {
    //   title: 'GA',
    //   key: 'GA',
    //   sorter: (a, b, sortOrder) => {
    //     setSortBy('GA')
    //     const direction = sortOrder === 'ascend' ? 'asc' : 'desc'
    //     setDirection(direction)
    //   },
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   dataIndex: 'GA',
    //   showSorterTooltip: {
    //     title: 'Goals Against',
    //   },
    // },
    // {
    //   title: 'GAA',
    //   key: 'GAA',
    //   sorter: (a, b, sortOrder) => {
    //     setSortBy('GAA')
    //     const direction = sortOrder === 'ascend' ? 'asc' : 'desc'
    //     setDirection(direction)
    //   },
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   dataIndex: 'GAA',
    //   showSorterTooltip: {
    //     title: 'Goals Against Average',
    //   },
    // },
    {
      title: 'FC',
      key: 'FC',
      sorter: (a, b, sortOrder) => a.FC - b.FC,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'FC',
      showSorterTooltip: {
        title: 'Fouls Committed',
      },
    },
    {
      title: 'YC',
      key: 'YC',
      sorter: (a, b, sortOrder) => a.YC - b.YC,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'YC',
      showSorterTooltip: {
        title: 'Yellow Cards',
      },
    },
    {
      title: 'RC',
      key: 'RC',
      sorter: (a, b, sortOrder) => a.RC - b.RC,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'RC',
      showSorterTooltip: {
        title: 'Red Cards',
      },
    },
  ]

  const keeperColumns = [
    // Removed as requested by Jeff on 2024.03.13
    // {
    //   title: 'MP',
    //   key: 'MP',
    //   sorter: (a, b, sortOrder) => {
    //     setSortBy('MP')
    //     const direction = sortOrder === 'ascend' ? 'asc' : 'desc'
    //     setDirection(direction)
    //   },
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   dataIndex: 'MP',
    //   showSorterTooltip: {
    //     title: 'Matches Played',
    //   },
    // },
    {
      title: 'Mins',
      key: 'Mins',
      sorter: (a, b, sortOrder) => a.Mins - b.Mins,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'Mins',
      showSorterTooltip: {
        title: 'Minutes Played',
      },
    },
    // Removed as requested by Ronaldo and Denys on 2024-03-06
    // {
    //   title: 'GF',
    //   key: 'GF',
    //   sorter: (a, b, sortOrder) => {
    //     setSortBy('GF')
    //     const direction = sortOrder === 'ascend' ? 'asc' : 'desc'
    //     setDirection(direction)
    //   },
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   defaultSortOrder: 'descend',
    //   dataIndex: 'GF',
    //   showSorterTooltip: {
    //     title: 'Goals For',
    //   },
    // },
    // {
    //   title: 'GFA',
    //   key: 'GFA',
    //   sorter: (a, b, sortOrder) => {
    //     setSortBy('GFA')
    //     const direction = sortOrder === 'ascend' ? 'asc' : 'desc'
    //     setDirection(direction)
    //   },
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   dataIndex: 'GFA',
    //   showSorterTooltip: {
    //     title: 'Goals For Average',
    //   },
    // },
    {
      title: 'GA',
      key: 'GA',
      sorter: (a, b, sortOrder) => a.GA - b.GA,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'GA',
      showSorterTooltip: {
        title: 'Goals Against',
      },
    },
    // Removed as requested by Ronaldo and Denys on 2024-03-06
    // {
    //   title: 'GAA',
    //   key: 'GAA',
    //   sorter: (a, b, sortOrder) => {
    //     setSortBy('GAA')
    //     const direction = sortOrder === 'ascend' ? 'asc' : 'desc'
    //     setDirection(direction)
    //   },
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   dataIndex: 'GAA',
    //   showSorterTooltip: {
    //     title: 'Goals Against Average',
    //   },
    // },
    {
      title: 'SA',
      key: 'SA',
      sorter: (a, b, sortOrder) => a.SA - b.SA,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'SA',
      showSorterTooltip: {
        title: 'Shots Against',
      },
    },
    {
      title: 'SV',
      key: 'SV',
      sorter: (a, b, sortOrder) => a.SV - b.SV,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'SV',
      showSorterTooltip: {
        title: 'Saves',
      },
    },
    {
      title: 'SV%',
      key: 'SV%',
      sorter: (a, b, sortOrder) => a['SV%'] - b['SV%'],
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'SV%',
      showSorterTooltip: {
        title: 'Save Percentage',
      },
    },
    {
      title: 'CS',
      key: 'CS',
      sorter: (a, b, sortOrder) => a.CS - b.CS,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'CS',
      showSorterTooltip: {
        title: 'Clean Sheets',
      },
    },
    {
      title: 'W',
      key: 'W',
      sorter: (a, b, sortOrder) => a.W - b.W,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'W',
      showSorterTooltip: {
        title: 'Wins',
      },
    },
    {
      title: 'L',
      key: 'L',
      sorter: (a, b, sortOrder) => a.L - b.L,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'L',
      showSorterTooltip: {
        title: 'Loss',
      },
    },
    {
      title: 'D',
      key: 'D',
      sorter: (a, b, sortOrder) => a.D - b.D,
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'D',
      showSorterTooltip: {
        title: 'Draws',
      },
    },
  ]

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const teamId = isHome ? homeTeamId : visitingTeamId
        const skater = await req(`/games/${id}/stats/players/team/${teamId}`, {
          query: { goalie: 0 },
        })
        const goalie = await req(`/games/${id}/stats/players/team/${teamId}`, {
          query: { goalie: 1 },
        })
        setSkaterData(skater?.data)
        setGoalieData(goalie?.data)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [isHome, homeTeamId, visitingTeamId, id])

  const renderSkaterTable = () => {
    return (
      <Table
        columns={commonColumns.concat(isSoccer ? playerColumns : skaterColumns)}
        bordered
        loading={loading}
        size='small'
        scroll={{ x: 600 }}
        dataSource={skaterTableData}
        pagination={false}
      />
    )
  }

  const renderGoalieTable = () => {
    return (
      <Table
        columns={commonColumns.concat(isSoccer ? keeperColumns : goalieColumns)}
        bordered
        loading={loading}
        size='small'
        scroll={{ x: 600 }}
        dataSource={goalieTableData}
        pagination={false}
      />
    )
  }

  return (
    <div
      css={css`
        gap: 16px;
        margin-bottom: 24px;
        width: 1450px;
        max-width: 1450px;
        margin: 0 auto;
        min-height: 100vh;
      `}
    >
      <div
        css={css`
          display: flex;
        `}
      >
        <Flex
          column
          noFlex
          css={css`
            margin-right: 16px;
            .ant-btn-default {
              color: rgba(0, 0, 0, 0.88) !important;
              background-color: transparent !important;
            }

            button {
              text-transform: capitalize !important;
            }
          `}
        >
          <label
            css={css`
              height: 12px;
              font-size: 12px;
              line-height: 12px;
            `}
          >
            Team
          </label>
          <Flex
            css={css`
              display: flex;
              margin-top: 8px;
              gap: 8px;
            `}
          >
            <SortOrderButton
              disabled={loading}
              type={isHome ? 'primary' : 'default'}
              className={cn({ sorted: isHome })}
              onClick={() => {
                setIsHome(true)
              }}
            >
              {'Home'}
            </SortOrderButton>
            <SortOrderButton
              disabled={loading}
              type={!isHome ? 'primary' : 'default'}
              className={cn({ sorted: !isHome })}
              onClick={() => {
                setIsHome(false)
              }}
            >
              {'Visiting'}
            </SortOrderButton>
          </Flex>
        </Flex>
        <Flex
          column
          noFlex
          css={css`
            margin-right: 16px;
            .ant-btn-default {
              color: rgba(0, 0, 0, 0.88) !important;
              background-color: transparent !important;
            }

            button {
              text-transform: capitalize !important;
            }
          `}
        >
          <label
            css={css`
              height: 12px;
              font-size: 12px;
              line-height: 12px;
            `}
          >
            Player Type
          </label>
          <Flex
            css={css`
              display: flex;
              margin-top: 8px;
              gap: 8px;
            `}
          >
            <SortOrderButton
              disabled={loading}
              type={playerType === 'skater' ? 'primary' : 'default'}
              className={cn({ sorted: playerType === 'skater' })}
              onClick={() => {
                setPlayerType('skater')
              }}
            >
              {isSoccer || !isHockey ? 'Player' : 'Skater'}
            </SortOrderButton>
            <SortOrderButton
              disabled={loading}
              type={playerType !== 'skater' ? 'primary' : 'default'}
              className={cn({ sorted: playerType !== 'skater' })}
              onClick={() => {
                setPlayerType('goalie')
              }}
            >
              {isSoccer ? 'Keeper' : 'Goalie'}
            </SortOrderButton>
          </Flex>
        </Flex>
      </div>
      {playerType === 'skater' ? (
        <div
          css={css`
            margin-top: 24px;
            margin-bottom: 24px;
          `}
        >
          {renderSkaterTable()}
        </div>
      ) : null}
      {playerType === 'goalie' ? (
        <div
          css={css`
            margin-top: 24px;
            margin-bottom: 24px;
          `}
        >
          {renderGoalieTable()}
        </div>
      ) : null}
    </div>
  )
}

PlayerStatistics.propTypes = {
  history: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  homeTeamId: PropTypes.string.isRequired,
  visitingTeamId: PropTypes.string.isRequired,
}

export default withRouter(PlayerStatistics)
