import { generateActions } from './utils'

const userDefinition = {
  read: [],
  readRoles: ['page'],
  readManagedUsers: [],
  readOrgs: ['page', 'sort', 'direction'],
  readSchedules: ['page', 'sort', 'direction'],
  readTeams: ['page', 'sort', 'direction'],
}

export const userActions = generateActions(['USER'], userDefinition)

export default userActions
