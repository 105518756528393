/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import colors from '@sportninja/common/constants/appColors'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import { font } from '../css'
import { Flex } from '../Layout'
import { Mobile } from '../Responsive'
import { useRef, useState } from 'react'
import { useLayoutEffect } from 'react'

const linkCss = css`
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${font.title}
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-right: 32px;
  color: ${colors.ATTENDANCE_GRAY};
  text-align: center;

  transition: color 0.1s ease-in-out;

  &.is-team-or-schedule,
  &.is-profile {
    font-size: 16px;
  }

  .indicator {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    transition: opacity 0.1s ease-in-out;
    opacity: 0;
  }

  &.is-profile .indicator {
    bottom: 0px;
  }

  .indicator-line {
    width: 25px;
    height: 2px;
    background-color: ${colors.DEFAULT_FLAIR};
    transform: translateX(-1px);
  }

  &:hover,
  &.active {
    color: ${colors.WHITE};
    .indicator {
      opacity: 1;
    }
  }

  &.is-private-page {
    color: ${colors.DEFAULT_FLAIR};
    opacity: 0.7;

    &:hover,
    &.active {
      color: ${colors.DEFAULT_FLAIR};
      opacity: 1;
    }
  }
`

const Loading = styled.div`
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation-name: fade;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;

  min-height: 14px;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  width: 60px;
  margin-right: 32px;
`

const PageMenu = ({
  isTeamOrSchedule,
  isSchedule,
  loading,
  baseRoute,
  pages = [],
  actions,
  isProfile,
  isInFrame,
  preserveQueryFor = [],
}) => {
  const [hideShadow, setHideShadow] = useState(false)
  const actionsRef = useRef(null)

  useLayoutEffect(() => {
    if (actionsRef?.current?.getBoundingClientRect()?.width === 0) {
      setHideShadow(true)
    }

    return () => {
      setHideShadow(false)
    }
  }, [actions])

  return (
    <Mobile>
      {(isMobile) => {
        return (
          <Flex
            id='sandbox-tabs'
            row
            alignItems='center'
            css={css`
              height: ${isProfile ? 40 : 38}px;
              border-bottom: 1px solid
                ${isProfile ? 'transparent' : colors.SOFT_STEEL};

              ${isTeamOrSchedule &&
              css`
                margin: 0 -88px;
                padding: 0 88px;
                height: 48px;
              `};

              ${isMobile &&
              `
                margin: 0;
                padding: 0;
                position: relative;
                height: 44px;

                a:first-of-type {
                  margin-left: 16px;
                }

                &::after {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 0px;
                  content: '';
                  width: 40px;
                  background: linear-gradient(
                    to left,
                    ${isInFrame ? 'white' : colors.HEADER_BG_2},
                    ${isInFrame ? 'white' : colors.HEADER_BG_2} 20%,
                    transparent
                  );
                  pointer-events: none;
                }
              `}

              ${isMobile &&
              isTeamOrSchedule &&
              `
                background-color: ${isInFrame ? 'white' : '#1b1f25'};
              `}
            `}
          >
            {loading ? (
              <Flex
                noFlex
                justifyContent='center'
                css={linkCss}
                id='sandbox-tabs-schedule'
              >
                <Loading />
                <Loading />
                <Loading />
              </Flex>
            ) : (
              <Flex
                noFlex
                css={css`
                  height: inherit;
                  overflow: auto;

                  &::-webkit-scrollbar {
                    height: 0.5em;
                  }

                  &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                  }

                  &::-webkit-scrollbar-thumb {
                    background-color: ${colors.SOFT_STEEL};
                  }
                `}
                id='sandbox-tabs-schedule'
              >
                <Flex row>
                  {pages.map((page, idx) => {
                    let to = page.to
                    if (idx === 0) {
                      to = baseRoute
                    }

                    if (preserveQueryFor.includes(page.to)) {
                      to = {
                        pathname: to,
                        search: window.location.search,
                      }
                    }

                    return (
                      <NavLink
                        id={page?.tutorialId}
                        exact
                        key={page.to}
                        to={to}
                        isActive={(match, location) => {
                          if (location.pathname === page.to) return true
                          if (idx === 0 && location.pathname === baseRoute)
                            return true
                          return false
                        }}
                        css={linkCss}
                        className={cn({
                          'is-team-or-schedule': isTeamOrSchedule,
                          'is-profile': isProfile,
                          'is-private-page': page.privatePage,
                        })}
                      >
                        {page.text}
                        <div className='indicator'>
                          <div className='indicator-line' />
                        </div>
                      </NavLink>
                    )
                  })}
                </Flex>
              </Flex>
            )}

            {!isMobile && isTeamOrSchedule && !isInFrame && actions && (
              <div
                ref={actionsRef}
                css={css`
                  position: relative;
                  display: flex;
                  margin-left: auto;

                  .header-action {
                    margin-left: 16px;
                  }

                  ${isSchedule &&
                  `
                    &::before {
                      position: absolute;
                      top: -8px;
                      left: -42px;
                      bottom: -7px;
                      content: '';
                      width: ${hideShadow ? 0 : 42}px;
                      background: linear-gradient(
                        to left,
                        ${colors.HEADER_BG_2},
                        ${colors.HEADER_BG_2} 20%,
                        transparent
                      );
                      pointer-events: none;
                    }
                  `}
                `}
              >
                {actions}
              </div>
            )}
          </Flex>
        )
      }}
    </Mobile>
  )
}

export default PageMenu
