/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { Link } from 'react-router-dom'

import { font } from '../../css'
import Icon from '../../Icon'
import { Flex } from '../../Layout'
import GameListRowTeam from './GameListRowTeam'
import GameListRowTime from './GameListRowTime'

const Score = ({ children, isWinner }) => {
  return (
    <Flex
      column
      alignItems='center'
      css={css`
        flex-basis: 25%;
        ${font.title}
        font-weight: 700;
        font-size: 64px;
        letter-spacing: 0.015em;
      `}
    >
      <span>
        {children}
        {isWinner && (
          <div
            className='score-indicator'
            css={css`
              height: 4px;
              background-color: ${colors.DEFAULT_FLAIR};
            `}
          />
        )}
      </span>
    </Flex>
  )
}

const GameListRow = ({
  game,
  gameTypes,
  gameStatusTypes,
  hasNextSibling,
  isSoccer = false,
  isHockey = false,
  isFirstGame = false,
  onClickFirstGame = () => {},
}) => {
  const fullGameType = gameTypes?.find((gameType) => {
    return parseInt(gameType?.id, 10) === game?.game_type_id
  })

  const homeTeamWon =
    game.home_team_score > game.visiting_team_score || game.game_status_id === 3
  const visitingTeamWon =
    game.home_team_score < game.visiting_team_score || game.game_status_id === 2

  return (
    <Flex
      alignItems='stretch'
      justifyContent='space-between'
      className='game-list-row'
      css={css`
        margin-left: -8px;
        margin-right: -8px;
        min-height: ${hasNextSibling ? 102 : 92}px;
        ${hasNextSibling &&
        `
          padding-bottom: 16px;
          border-bottom: 1px solid #3F4753;
          margin-bottom: 16px;
        `}
      `}
    >
      <GameListRowTeam
        team={game.homeTeam}
        isHome
        teamSlot={game.homeTeamSlot}
      />
      <Flex
        onClick={async () => {
          if (isFirstGame) {
            await onClickFirstGame()
          }
        }}
        id={isFirstGame ? 'sandbox-schedule-games' : ''}
        as={Link}
        to={`${ROUTES.GAME_ROOT}/${game.id}`}
        row
        alignItems='center'
        justifyContent='center'
        css={css`
          width: 50%;
          height: inherit;
          padding: 8px;
          transition: background-color 0.2s ease-in-out;

          &:hover {
            background-color: rgba(255, 255, 255, 0.05);
            border-radius: 8px;
          }
        `}
      >
        <Score isWinner={homeTeamWon}>{game.home_team_score}</Score>
        <Flex
          column
          css={css`
            flex-basis: 50%;
            text-align: center;
            font-weight: 700;
            font-size: 12px;
            line-height: 12px;
            color: #9fa3a9;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 8px;
            `}
          >
            <p
              css={css`
                text-transform: uppercase;
                margin-left: 1px;
              `}
            >
              {game.schedule.name}
            </p>
            <p
              css={css`
                text-transform: uppercase;
              `}
            >
              {game?.league_game_number
                ? `Game #${game?.league_game_number} - `
                : ''}
              {fullGameType?.name}{' '}
              {game.if_necessary_status && (
                <span
                  css={css`
                    text-transform: lowercase;
                  `}
                >
                  (if Necessary)
                </span>
              )}
            </p>
          </div>

          <GameListRowTime
            game={game}
            gameStatusTypes={gameStatusTypes}
            isSoccer={isSoccer}
            isHockey={isHockey}
          />

          <div
            css={css`
              font-weight: 500;
              font-size: 14px;
              margin-top: 4px;
            `}
          >
            <Icon name='map-marker-alt' color='#9FA3A9' fontSize={15} />
            <p
              css={css`
                display: inline-block;
                margin-top: 2px;
                margin-left: 5px;
              `}
            >
              {game?.venue?.name}
              {game.facility ? ` - ${game.facility.name}` : ''}
            </p>
          </div>
        </Flex>
        <Score isWinner={visitingTeamWon}>{game.visiting_team_score}</Score>
      </Flex>
      <GameListRowTeam
        team={game.visitingTeam}
        teamSlot={game.visitingTeamSlot}
      />
    </Flex>
  )
}

export default GameListRow
