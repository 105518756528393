import { generateActions } from './utils'
import * as shared from './shared-definitions'

// A list of all available actions for the organization entity. The array describes
// the arguments that are passed to the REQUEST action
const orgActionDefinition = {
  create: ['form'],
  read: ['id'],
  update: ['id', 'form'],
  delete: ['id', 'parentId', 'forceDelete'],

  children: {
    read: ['id', 'page', 'sort', 'direction'],
  },

  schedules: {
    read: ['id', 'page', 'sort', 'direction'],
  },

  teams: {
    read: ['id', 'page', 'sort', 'direction'],
  },

  games: {
    read: ['id', 'page', 'sort', 'direction', 'startDate', 'endDate', 'tz'],
  },

  ...shared.entityImageDefinition,
  ...shared.venuesDefinition,
  ...shared.usersDefinition,
  ...shared.feedDefinition,
  ...shared.officialsDefinition,
}

const orgActions = generateActions(['ORG'], orgActionDefinition)

export default orgActions
