/** @jsxImportSource @emotion/react */
import { Fragment } from 'react'
import PropTypes from 'prop-types'
import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import { timeStringFromMS } from '@sportninja/common/utils/utils'
import { ACTIONS } from '@sportninja/common/reducers/game-timeline-parser'
import { colors } from '@sportninja/common/constants/appColors'

import { Flex } from '../../../components/Layout'
import Picture from '../../../components/Picture'
import { font, flairColor } from '../../../components/css'
import Icon from '../../../components/Icon'
import FormSheet from '../../../components/Form/FormSheet'
import { scoringPeriodForm } from '../../../components/Form/form-configs'
import { Assists } from './TimelineEvent'
import { ReactComponent as ArrowUpTurn } from './arrow-upturn.svg'
import { SidewayText } from '../GamePageHeaderMatchupCss'
import { t } from '@sportninja/common/i18n'
import TimelineFouls from './TimelineFouls'
import TimelineGoals from './TimelineGoals'

const Line = styled.div`
  flex: 1;
  height: 1px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.1);
`

const Period = ({ children }) => {
  return (
    <Flex
      fullWidth
      noFlex
      row
      alignItems='center'
      justifyContent='center'
      css={css`
        margin: 20px 0;
      `}
    >
      <Line />
      <div
        css={css`
          width: 140px;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.81px;
          text-align: center;
          text-transform: uppercase;
        `}
      >
        {children}
      </div>
      <Line />
    </Flex>
  )
}

const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  min-height: 70px;
  margin: 5px 4px;
  background-color: rgba(
    255,
    255,
    255,
    ${(props) => (props.isGoal ? 0.2 : 0.08)}
  );
  border-radius: 3px;

  .container {
    padding: 12px 8px 12px 0;
  }

  .title {
    ${font.title}
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1.8px;
  }

  .body {
    margin-top: 4px;
    color: #d0d0d0;
    font-size: 13px;
    letter-spacing: 0.34px;
  }

  .time {
    font-size: 16px;
    letter-spacing: 1.31px;
    text-align: right;
  }

  .action {
    font-weight: 600;
    text-align: right;
    text-transform: uppercase;
  }

  .goal {
    color: ${colors.PRIMARY};
  }
`

const EditButton = ({ onClick }) => (
  <div
    css={css`
      width: 41px;
      position: relative;
      background-color: rgba(210, 221, 235, 0.08);
    `}
  >
    <button
      type='button'
      onClick={onClick}
      css={css`
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      `}
    >
      <Icon name='pencil-alt' color={flairColor} fontSize={19} />
    </button>
  </div>
)

const PeriodLength = ({ isEditMode, onDelete, onSubmit, duration, title }) => {
  const length = timeStringFromMS(duration)

  return (
    <Card
      alignItems='center'
      css={css`
        min-height: 0;
      `}
    >
      <Flex
        justifyContent='space-between'
        css={css`
          padding: 15px 8px 15px 18px;
        `}
      >
        <div className='title'>{title} length:</div>
        <div
          className='time'
          css={css`
            margin: auto 0;
          `}
        >
          {length}
        </div>
      </Flex>
      {isEditMode && (
        <FormSheet
          label={`Edit ${title} length`}
          form={scoringPeriodForm({ duration })}
          onSubmit={onSubmit}
          onDelete={onDelete}
          deleteTitle='Delete Period'
          deleteMessage={`Are you sure you want to delete ${title}? ${t(
            'Web:deletionCantBeUndone'
          )}`}
          title={`Edit ${title} length`}
          Button={({ onClick }) => <EditButton onClick={onClick} />}
        />
      )}
    </Card>
  )
}

const Event = ({
  event,
  iconName,
  imageId,
  isEditMode,
  editForm,
  onDelete,
  onEdit,
  editTitle,
  right,
  goalTypes,
}) => {
  const isGoal = event.action === ACTIONS.goal
  const isGoalie = event.action === ACTIONS.goalieChange
  const isEdited = typeof event.edited !== 'undefined'

  return (
    <Card isGoal={isGoal}>
      {/* Content area */}
      <Flex className='container'>
        <SidewayText
          css={css`
            padding-left: 4px;
            padding-right: 6px;
          `}
        >
          {right ? 'Visitor' : 'Home'}
        </SidewayText>
        <Picture
          iconName={iconName}
          size='xsmall'
          imageId={imageId}
          square
          fit
          css={css`
            align-self: center;
          `}
        />
        <div
          css={css`
            width: 100%;
            margin-left: 12px;
          `}
        >
          <Flex justifyContent='space-between'>
            <div
              css={css`
                padding-right: 8px;
              `}
            >
              <div className='title'>
                {isGoalie
                  ? event.nextGoalie
                    ? `#${event.nextGoalie.number} - ${event.nextGoalie.name}`
                    : 'Empty Net'
                  : `#${event.number} - ${event.name}`}
                {isGoalie && (
                  <ArrowUpTurn
                    height='15px'
                    width='21px'
                    css={css`
                      margin-left: 10px;
                    `}
                  />
                )}
              </div>
              <div className='body'>
                {event.action === ACTIONS.penalty ? (
                  `${event.duration ? `${event.duration} min - ` : ''}${
                    event.type
                  }`
                ) : isGoalie ? (
                  event.name ? (
                    `#${event.number} - ${event.name}`
                  ) : (
                    'Empty Net'
                  )
                ) : (
                  <Assists assists={event.assists} />
                )}
                {isGoalie && (
                  <ArrowUpTurn
                    height='15px'
                    width='21px'
                    css={css`
                      margin-left: 10px;
                      path {
                        fill: #ff2e2e;
                      }
                      transform: scaleY(-1) translateY(-6px);
                    `}
                  />
                )}
              </div>
            </div>
            <div
              css={css`
                margin-top: 2px;
              `}
            >
              <div className='time'>{event.time}</div>
              <div className={`body action ${isGoal ? 'goal' : ''}`}>
                {event.action}
              </div>
              {isGoal &&
                typeof event?.goal_type_id !== 'undefined' &&
                event.goal_type_id !== 1 && (
                  <div
                    css={css`
                      font-size: 12px;
                      line-height: 13px;
                      text-align: right;
                      margin-top: 2px;
                    `}
                  >
                    {
                      goalTypes?.find((type) => type?.id === event.goal_type_id)
                        ?.name
                    }
                  </div>
                )}
            </div>
          </Flex>
          {isEdited && (
            <div className='body goal'>
              Edited by {event.edited.by} on {event.edited.at}
            </div>
          )}
        </div>
      </Flex>
      {isEditMode && (
        <FormSheet
          form={editForm}
          onDelete={onDelete}
          onSubmit={onEdit}
          label={editTitle}
          title={editTitle}
          Button={({ onClick }) => <EditButton onClick={onClick} />}
        />
      )}
    </Card>
  )
}

Event.propTypes = {
  event: PropTypes.object.isRequired,
  right: PropTypes.bool.isRequired,
}

const Shot = ({ count, imageId, isReverse }) => (
  <Flex alignItems='center' flexDirection={isReverse ? 'row-reverse' : 'row'}>
    <Picture
      iconName='user-friends'
      imageId={imageId}
      size='xsmall'
      square
      fit
    />
    <div
      css={css`
        ${isReverse ? 'margin-right' : 'margin-left'}: 15px;
      `}
    >
      {count}
    </div>
  </Flex>
)

const ShotCount = ({ images, isFinal, periodName, shots }) => {
  return (
    <Card
      alignItems='center'
      css={css`
        min-height: 60px;
        padding: 0 8px;

        ${font.title}
        font-size: 42px;
        font-weight: 500;
        letter-spacing: 3.44px;
      `}
    >
      <Shot count={shots.home} imageId={images.home} />

      <Flex
        column
        alignItems='center'
        justifyContent='center'
        css={css`
          ${font.body}
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 1.07px;
          text-align: center;
          text-transform: uppercase;
        `}
      >
        {!isFinal && <div>{periodName}</div>}
        <div>Shots</div>
        {isFinal && <div>Final</div>}
      </Flex>

      <Shot count={shots.visiting} imageId={images.visiting} isReverse />
    </Card>
  )
}

const GoalsCount = ({ images, isFinal, periodName, shots }) => {
  return (
    <Card
      alignItems='center'
      css={css`
        min-height: 60px;
        padding: 0 8px;

        ${font.title}
        font-size: 42px;
        font-weight: 500;
        letter-spacing: 3.44px;
      `}
    >
      <Shot count={shots.home} imageId={images.home} />

      <Flex
        column
        alignItems='center'
        justifyContent='center'
        css={css`
          ${font.body}
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 1.07px;
          text-align: center;
          text-transform: uppercase;
        `}
      >
        {!isFinal && <div>{periodName}</div>}
        <div>Goals</div>
        {isFinal && <div>Final</div>}
      </Flex>

      <Shot count={shots.visiting} imageId={images.visiting} isReverse />
    </Card>
  )
}

const FoulsCount = ({ images, isFinal, periodName, shots }) => {
  return (
    <Card
      alignItems='center'
      css={css`
        min-height: 60px;
        padding: 0 8px;

        ${font.title}
        font-size: 42px;
        font-weight: 500;
        letter-spacing: 3.44px;
      `}
    >
      <Shot count={shots.home} imageId={images.home} />

      <Flex
        column
        alignItems='center'
        justifyContent='center'
        css={css`
          ${font.body}
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 1.07px;
          text-align: center;
          text-transform: uppercase;
        `}
      >
        {!isFinal && <div>{periodName}</div>}
        <div>Fouls</div>
        {isFinal && <div>Final</div>}
      </Flex>

      <Shot count={shots.visiting} imageId={images.visiting} isReverse />
    </Card>
  )
}

const MobileTimeline = ({
  images,
  finalShots,
  isEditMode,
  visitingId,
  timeline,
  goalTypes,
  getSheetInfo,
  onEditPeriodDuration,
  onDeletePeriod,
  isSoccer,
  isGenericGame = false,
}) => {
  return (
    // This design calls for top margin of 20 - and each period indicator has
    // 20 margin top/bottom.
    <div
      css={css`
        margin-top: -40px;
        /* make scrolable */
        overflow-y: auto;
        margin-bottom: 60px;
      `}
      className='timeline-container'
    >
      {timeline &&
        timeline.map((period, key) => {
          const periodName = period.period_type.name_full

          return (
            <Fragment key={key}>
              <Period>{periodName}</Period>
              <PeriodLength
                isEditMode={isEditMode}
                duration={period.duration}
                onSubmit={onEditPeriodDuration.bind(this, period.id)}
                onDelete={onDeletePeriod.bind(this, period.id)}
                title={periodName}
              />
              {isGenericGame ? (
                <>
                  <GoalsCount
                    images={images}
                    shots={{
                      home: period.goals_home_count,
                      visiting: period.goals_visiting_count,
                    }}
                  />
                  <FoulsCount
                    images={images}
                    shots={{
                      home: period.homeOffenseCount,
                      visiting: period.visitingOffenseCount,
                    }}
                  />
                </>
              ) : null}
              {!isGenericGame &&
                period.events.map((event, idx) => {
                  const isVisiting = event.teamId === visitingId
                  const imageId = isVisiting ? images.visiting : images.home
                  const { form, onDelete, onSubmit, title } =
                    getSheetInfo(event)

                  return (
                    <Event
                      key={idx}
                      editForm={form}
                      onDelete={onDelete}
                      onEdit={onSubmit}
                      editTitle={title}
                      event={event}
                      iconName='user-friends'
                      imageId={imageId}
                      isEditMode={isEditMode}
                      right={event.teamId === visitingId}
                      goalTypes={goalTypes}
                    />
                  )
                })}
              <ShotCount
                images={images}
                shots={{
                  home: period.homeShotCount,
                  visiting: period.visitingShotCount,
                }}
              />
            </Fragment>
          )
        })}
      <ShotCount isFinal images={images} shots={{ ...finalShots }} />
    </div>
  )
}

MobileTimeline.propTypes = {
  images: PropTypes.shape({
    home: PropTypes.string,
    visiting: PropTypes.string,
  }).isRequired,
  isEditMode: PropTypes.bool.isRequired,
  visitingId: PropTypes.string.isRequired,
  timeline: PropTypes.array.isRequired,
}

export default MobileTimeline
