export const guides = {
  NEW_ACCOUNT_WELCOME_TO_SPORTNINJA: 101468,
  NEW_ACCOUNT_ORGANIZATION: 101545,
}

export const tutorialKeys = {
  NEW_ACCOUNT_WELCOME_TO_SPORTNINJA: 'NEW_ACCOUNT_WELCOME_TO_SPORTNINJA',
  NEW_ACCOUNT_ORGANIZATION: 'NEW_ACCOUNT_ORGANIZATION',
  NEW_ACCOUNT_WELCOME_TO_SPORTNINJA_TUTORIAL_COUNT: 'WELCOME_TUTORIAL_COUNT',
  NEW_ACCOUNT_USER_BUSINESS: 'NEW_ACCOUNT_USER_BUSINESS',
  NEW_ACCOUNT_USER_PLAYER: 'NEW_ACCOUNT_USER_PLAYER',
  NEW_ACCOUNT_PRODUCT_TOUR_STEP_1: 'NEW_ACCOUNT_PRODUCT_TOUR_STEP_1',
  NEW_ACCOUNT_PRODUCT_TOUR_STEP_2: 'NEW_ACCOUNT_PRODUCT_TOUR_STEP_2',
  NEW_ACCOUNT_PRODUCT_TOUR_STEP_3: 'NEW_ACCOUNT_PRODUCT_TOUR_STEP_3',
  NEW_ACCOUNT_CREATE_YOUR_LEAGUE_STEP_1:
    'NEW_ACCOUNT_CREATE_YOUR_LEAGUE_STEP_1',
}
