/* eslint-disable react-native/no-inline-styles */
/** @jsxImportSource @emotion/react */
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { Alert, Select, Space, Progress } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button as SNButton } from 'src/components/Button'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import ConflictsForm from './ConflictsForm'
import { SelectInput } from '../ts-components/SelectInput'
import { paragraphSmallStyle } from 'src/pages/RegistrationInviteV2/steps/Main'
import { weightSemiBold } from '../css'
import { CheckBoxInput } from '../ts-components/CheckBoxInput'

const NewDaySmartForm = ({
  daySmartImportModalRef,
  readItem,
  readyEntityCount,
  account,
  ORG_ID,
  account_id = null,
  genericSports,
  sports,
}) => {
  const [daySmartSeasons, setDaySmartSeasons] = useState([])
  const [selectedDaySmartSeason, setSelectedDaySmartSeason] = useState(null)
  const [isLoadingDaySmart, setIsLoadingDaySmart] = useState(false)
  const [daySmartError, setDaySmartError] = useState(null)
  const [isSyncingDaySmart, setIsSyncingDaySmart] = useState(false)
  const [daySmartSuccess, setDaySmartSuccess] = useState(false)
  const [conflicts, setConflicts] = useState({ count: 0 })
  const [progress, setProgress] = useState(0)
  const [daySmartSendInvitation, setDaySmartSendInvitation] = useState(false)
  const sportOptions = useMemo(
    () =>
      sports && genericSports
        ? [
            ...sports
              .map((option) => ({
                ...option,
                name: `${option.name_full} (Full Stats)`,
                is_generic: false,
              }))
              .filter(
                (option) => !option.name.toLowerCase().includes('generic')
              ),
            ...genericSports.map((option) => ({
              ...option,
              name: `${option.name} (Basic Stats)`,
              is_generic: true,
            })),
          ]
        : [],
    [sports, genericSports]
  )
  const selectOptions = [
    {
      label: (
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <span
            css={css`
              ${paragraphSmallStyle}
              ${weightSemiBold}
            color: ${colors.PRIMARY_300};
              text-transform: uppercase;
            `}
          >
            Full Stats
          </span>
          <QuestionCircleOutlined
            style={{
              fontSize: 16,
              color: colors.PRIMARY_300,
              cursor: 'pointer',
            }}
            onClick={() => {
              window.open(
                'https://sportninja.zendesk.com/hc/en-us/articles/6347822302484-Scoring-Score-a-Game-on-SportNinja-Mobile-only',
                '_blank'
              )
            }}
          />
        </div>
      ),
      title: 'fullStats',
      options: sports
        .map((option) => ({
          value: option.id,
          label: `${option.name_full}`,
          is_generic: false,
        }))
        .filter((option) => !option.label.toLowerCase().includes('generic'))
        ?.sort((a, b) => {
          return a?.label?.localeCompare(b?.label)
        }),
    },
    {
      label: (
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <span
            css={css`
              ${paragraphSmallStyle}
              ${weightSemiBold}
            color: ${colors.PRIMARY_300};
              text-transform: uppercase;
            `}
          >
            Basic Stats
          </span>
          <QuestionCircleOutlined
            style={{
              fontSize: 16,
              color: colors.PRIMARY_300,
              cursor: 'pointer',
            }}
            onClick={() => {
              window.open(
                'https://sportninja.zendesk.com/hc/en-us/articles/28411302723476-Scoring-Basic-Stats-Score-a-Game-on-SportNinja-Mobile-only',
                '_blank'
              )
            }}
          />
        </div>
      ),
      title: 'basicStats',
      options: genericSports
        .map((option) => ({
          value: option.id,
          label: `${option.name}`,
          is_generic: true,
        }))
        ?.sort((a, b) => {
          return a?.label?.localeCompare(b?.label)
        }),
    },
  ]
  const [selectedSport, setSelectedSport] = useState(null)
  // We are disabling the sport select if this is an Daysmart season
  // that has a sport_id or generic_sport_id ( it was already imported )
  const [shouldDisableSportSelect, setShouldDisableSportSelect] =
    useState(false)
  const [sportId, setSportId] = useState(sportOptions[0]?.id || null)
  const [genericSportId, setGenericSportId] = useState(null)
  const genericId = sports?.find((sport) =>
    sport?.name.toLowerCase().includes('generic')
  )?.id

  let interval

  const fakeProgress = (targetProgress) => {
    let currentProgress = progress
    interval = setInterval(() => {
      currentProgress += 5
      setProgress(currentProgress)

      if (currentProgress >= targetProgress) {
        clearInterval(interval)
      }
    }, 1000)
  }

  const onSyncDaySmart = async () => {
    try {
      setDaySmartError(null)
      setProgress(0)
      fakeProgress(100)
      setIsSyncingDaySmart(true)

      const options = daySmartSendInvitation
        ? ['invite_players_team_officials']
        : []

      const { data } = await req(
        `/organizations/${ORG_ID}/daysmart-import-season`,
        {
          method: 'POST',
          body: JSON.stringify({
            season_id: selectedDaySmartSeason,
            sport_id: sportId,
            generic_sport_id: genericSportId,
            options,
          }),
        }
      )
      if (data?.conflicts?.count > 0) {
        setProgress(100)
        setConflicts(data?.conflicts)
        return
      }
      setProgress(100)
      setDaySmartSuccess(true)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setDaySmartError(errorMessage)
    } finally {
      setIsSyncingDaySmart(false)
      clearInterval(interval)
    }
  }

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  // This function is used to filter the options in the select dropdown
  // when we group the options
  const filterOptionGroup = useCallback((search, optionOrGroup) => {
    const isGroup = Array.isArray(optionOrGroup.options)
    if (isGroup) {
      return false
    }
    return optionOrGroup.label.toLowerCase().includes(search.toLowerCase())
  }, [])

  useEffect(() => {
    const fetchDaySmartSeasons = async () => {
      try {
        setIsLoadingDaySmart(true)
        const { data } = await req(
          `/organizations/${ORG_ID}/daysmart-seasons-search`
        )
        if (data) {
          setDaySmartSeasons(
            data?.seasons?.map((item) => {
              return {
                label: `${item?.name}`,
                value: item?.season_id,
                ...item,
              }
            })
          )
        }
      } catch (e) {
        const errorMessage = getErrorMessage(e)
        setDaySmartError(errorMessage)
      } finally {
        setIsLoadingDaySmart(false)
      }
    }
    if (account_id) {
      fetchDaySmartSeasons()
    }
  }, [account_id, ORG_ID])

  // We run this effect to set the sport_id and generic_sport_id
  // if this is a season that was already imported
  // And ensure that the re-imported season has the same sport_id
  useEffect(() => {
    if (selectedDaySmartSeason) {
      const selectedDaySmartSeasonData = daySmartSeasons.find(
        (season) => season?.value === selectedDaySmartSeason
      )
      if (selectedDaySmartSeasonData?.sport_id) {
        const selectedSportFromDaySmarData = sports.find(
          (sport) => sport.id === selectedDaySmartSeasonData.sport_id
        )
        if (
          selectedSportFromDaySmarData.name.toLowerCase().includes('generic')
        ) {
          setSportId(genericId)
          setGenericSportId(selectedDaySmartSeasonData.generic_sport_id)
          setSelectedSport(selectedDaySmartSeasonData.generic_sport_id)
          setShouldDisableSportSelect(true)
        } else {
          setSelectedSport(selectedDaySmartSeasonData.sport_id)
          setSportId(selectedDaySmartSeasonData.sport_id)
          setGenericSportId(null)
          setShouldDisableSportSelect(true)
        }
      } else {
        setShouldDisableSportSelect(false)
      }
    }
  }, [daySmartSeasons, genericId, selectedDaySmartSeason, sports])

  if (conflicts.count > 0) {
    return (
      <ConflictsForm
        ORG_ID={ORG_ID}
        account={account}
        conflicts={conflicts}
        daySmartImportModalRef={daySmartImportModalRef}
        readItem={readItem}
        readyEntityCount={readyEntityCount}
        selectedDaySmartSeason={selectedDaySmartSeason}
        sportId={sportId}
        genericSportId={genericSportId}
        daySmartSendInvitation={daySmartSendInvitation}
      />
    )
  }

  return (
    <div
      css={css`
        width: 45vw;
        background: #26303e;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        transition: all 0.1s ease-in-out;
        border-radius: 8px;
        padding-top: 16px;
      `}
    >
      <button
        onClick={() => {
          if (daySmartSuccess) {
            readItem(ENTITY_TYPES.org, ORG_ID)
            readyEntityCount()
            daySmartImportModalRef?.current?.closeModal()
            return
          }
          daySmartImportModalRef?.current?.closeModal()
        }}
        css={css`
          position: fixed;
          right: 40px;
          top: 40px;
          background: none;
          border: none;
        `}
      >
        <CloseOutlined
          fontSize={20}
          style={{
            color: colors.WHITE,
            fontSize: 20,
          }}
        />
      </button>
      <button
        onClick={() => {
          window.open(
            'https://sportninja.zendesk.com/hc/en-us/articles/25378814243988-DaySmart-Integration-NEW',
            '_blank'
          )
        }}
        css={css`
          position: fixed;
          right: 70px;
          top: 40px;
          background: none;
          border: none;
        `}
      >
        <QuestionCircleOutlined
          fontSize={20}
          style={{
            color: colors.WHITE,
            fontSize: 20,
          }}
        />
      </button>
      <div
        css={css`
          padding-left: 16px;
          padding-right: 16px;
          padding-bottom: 16px;
          display: flex;
          flex: 1;
          flex-direction: column;
          height: 100%;
          overflow-y: auto;
          max-height: 550px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            align-items: center;
          `}
        >
          <p
            css={css`
              font-size: 36px;
              font-family: ${isCanlan
                ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                : 'Rift, Arial, Helvetica, sans-serif'};
              font-weight: 700;
            `}
          >
            {'DaySmart Season Import'}
          </p>
        </div>
        <div
          css={css`
            border-bottom: 1px solid ${colors.SOFT_STEEL};
            margin-bottom: 16px;
            margin-top: 16px;
          `}
        />
        {!isSyncingDaySmart ? (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              margin-bottom: 8px;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <span
                css={css`
                  color: #fff;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 145%; /* 20.3px */
                `}
              >
                DaySmart Season Info
              </span>
              <span
                css={css`
                  color: var(--Foundation-Secondary-secondary-25, #a6aab0);
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 145%; /* 17.4px */
                `}
              >
                Search by name or season ID
              </span>
            </div>
          </div>
        ) : null}
        {!isSyncingDaySmart ? (
          <Select
            filterOption={filterOption}
            optionFilterProp='label'
            showSearch={true}
            onChange={(e) => {
              // addPending(e)
              setSelectedDaySmartSeason(e)
            }}
            options={daySmartSeasons}
            value={selectedDaySmartSeason}
            placeholder={'Select a DaySmart Season'}
            disabled={isLoadingDaySmart || isSyncingDaySmart || daySmartSuccess}
          />
        ) : null}
        {selectedDaySmartSeason && !isSyncingDaySmart ? (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
              margin-top: 16px;
            `}
          >
            <SelectInput
              label={'Sport*'}
              disabled={
                isLoadingDaySmart ||
                isSyncingDaySmart ||
                daySmartSuccess ||
                shouldDisableSportSelect
              }
              options={selectOptions}
              name={'sport'}
              value={selectedSport}
              showSearch={true}
              filterOption={filterOptionGroup}
              onChange={(value) => {
                const selectedSportFromSelect = sportOptions?.find(
                  (s) => s.id === value
                )
                if (selectedSportFromSelect.is_generic) {
                  setSportId(genericId)
                  setGenericSportId(selectedSportFromSelect.id)
                } else {
                  setSportId(selectedSportFromSelect.id)
                  setGenericSportId(null)
                }
                setSelectedSport(value)
              }}
              fieldErrors={[]}
              customError={{}}
            />
          </div>
        ) : null}
        {selectedDaySmartSeason && !isSyncingDaySmart ? (
          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 8px;
              width: 100%;
              align-items: center;
              justify-content: start;
              margin-top: 16px;
            `}
          >
            <CheckBoxInput
              fieldErrors={[]}
              customError={{}}
              disabled={
                isLoadingDaySmart ||
                isSyncingDaySmart ||
                daySmartSuccess ||
                shouldDisableSportSelect
              }
              label='Send Invitations to Players and Team Officials (WARNING)'
              name='send_invitations'
              onChange={(value) =>
                setDaySmartSendInvitation(value.target.checked)
              }
              value={daySmartSendInvitation}
              checkBoxStyle={css`
                width: unset;
              `}
            />
          </div>
        ) : null}
        {selectedDaySmartSeason && !isSyncingDaySmart ? (
          <div
            css={css`
              margin-top: 16px;
              display: flex;
              flex-direction: column;
              padding: 16px;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
              border-radius: 8px;
              background: var(--Foundation-Secondary-secondary-400, #1b222b);
            `}
          >
            <span
              css={css`
                color: var(--Neutrals-White, #fff);
                font-size: 20px;
                font-weight: 600;
                line-height: 120%; /* 24px */
                border-bottom: 1px solid ${'#6A6C70'};
                width: 100%;
              `}
            >
              DaySmart Season
            </span>
            <div css={css``} />
            <div
              css={css`
                display: flex;
                flex-direction: row;
                gap: 8px;
              `}
            >
              <span
                css={css`
                  color: var(--Neutrals-neutrals-0, #fff);
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 145%; /* 20.3px */
                `}
              >
                Name:
              </span>
              <span
                css={css`
                  color: var(--Neutrals-neutrals-0, #fff);
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 145%; /* 20.3px */
                `}
              >
                {
                  daySmartSeasons.find(
                    (season) => season?.season_id === selectedDaySmartSeason
                  )?.name
                }
              </span>
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                gap: 8px;
              `}
            >
              <span
                css={css`
                  color: var(--Neutrals-neutrals-0, #fff);
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 145%; /* 20.3px */
                `}
              >
                Start Date:
              </span>
              <span
                css={css`
                  color: var(--Neutrals-neutrals-0, #fff);
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 145%; /* 20.3px */
                `}
              >
                {
                  daySmartSeasons
                    .find(
                      (season) => season?.season_id === selectedDaySmartSeason
                    )
                    ?.start_date.split(' ')[0]
                }
              </span>
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                gap: 8px;
              `}
            >
              <span
                css={css`
                  color: var(--Neutrals-neutrals-0, #fff);
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 145%; /* 20.3px */
                `}
              >
                End Date:
              </span>
              <span
                css={css`
                  color: var(--Neutrals-neutrals-0, #fff);
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 145%; /* 20.3px */
                `}
              >
                {
                  daySmartSeasons
                    .find(
                      (season) => season?.season_id === selectedDaySmartSeason
                    )
                    ?.end_date.split(' ')[0]
                }
              </span>
            </div>
          </div>
        ) : null}

        {isSyncingDaySmart ? (
          <div
            css={css`
              margin-top: 16px;
              display: flex;
              flex-direction: column;
              padding: 16px;
              align-items: center;
              gap: 8px;
            `}
          >
            <span
              css={css`
                color: var(--Neutrals-neutrals-0, #fff);
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                line-height: 145%; /* 23.2px */
                margin-bottom: 8px;
              `}
            >
              Importing Season
            </span>
            {/* <Spin tip='Loading' /> */}
            <Progress
              percent={progress}
              trailColor={colors.ATTENDANCE_GRAY}
              strokeColor={colors.PRIMARY}
            />
          </div>
        ) : null}
        <div
          css={css`
            margin-top: 20px;
            align-self: flex-end;
            width: 100%;
            position: sticky;
            bottom: 0;
            background-color: ${colors.HEADER};
            z-index: 100;
            padding-top: 16px;
          `}
        >
          {daySmartError ? (
            <Space
              direction='vertical'
              style={{ width: '100%', marginBottom: 16 }}
            >
              <Alert message={daySmartError} type='error' showIcon />
            </Space>
          ) : null}
          {daySmartSuccess ? (
            <Space
              direction='vertical'
              style={{ width: '100%', marginBottom: 16 }}
            >
              <Alert
                message={'Season imported successfully'}
                type='success'
                showIcon
                closable
              />
            </Space>
          ) : null}
          {!isSyncingDaySmart ? (
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 16px;
                width: 100%;
              `}
            >
              <SNButton
                onClick={(e) => {
                  e.preventDefault()
                  daySmartImportModalRef?.current?.closeModal()
                }}
                label={'Cancel'}
                variant='secondary'
                disabled={
                  isLoadingDaySmart || isSyncingDaySmart || daySmartSuccess
                }
              />
              <SNButton
                onClick={async () => {
                  if (daySmartSuccess) {
                    readItem(ENTITY_TYPES.org, ORG_ID)
                    readyEntityCount()
                    daySmartImportModalRef?.current?.closeModal()
                    return
                  }
                  await onSyncDaySmart()
                }}
                type='submit'
                label={
                  daySmartSuccess
                    ? 'Ok'
                    : daySmartError
                    ? 'Try Again'
                    : 'Confirm'
                }
                isLoading={isLoadingDaySmart}
                disabled={
                  isLoadingDaySmart ||
                  !selectedDaySmartSeason ||
                  isSyncingDaySmart ||
                  !selectedSport
                }
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default NewDaySmartForm
