/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { paragraphSmall, weightBold, weightRegular } from '../css'

type EventProgressProps = {
  eventProgress: 1 | 2 | 3 | 4 | 5
}

export const EventProgress = ({ eventProgress = 1 }: EventProgressProps) => {
  return (
    <div
      css={css`
        margin-top: 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        @media (max-width: 768px) {
          flex-direction: column;
          gap: 16px;
          width: 100%;
        }
        padding: 16px;
        border-radius: 8px;
        background: rgba(51, 54, 59, 0.2);
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          flex: 1 0 0;
          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        <span
          css={css`
            ${eventProgress === 1
              ? css`
                  ${paragraphSmall}
                  ${weightBold}
                `
              : css`
                  ${paragraphSmall}
                  ${weightRegular}
                `}
            color: ${eventProgress === 1 ? colors.PRIMARY_300 : colors.WHITE};
          `}
        >
          1. Credentials
        </span>
        <div
          css={css`
            height: 4px;
            align-self: stretch;
            border-radius: 8px;
            background: ${colors.PRIMARY};
          `}
        />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          flex: 1 0 0;
          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        <span
          css={css`
            ${eventProgress === 2
              ? css`
                  ${paragraphSmall}
                  ${weightBold}
                `
              : css`
                  ${paragraphSmall}
                  ${weightRegular}
                `}
            color: ${eventProgress === 2
              ? colors.PRIMARY_300
              : eventProgress >= 2
              ? colors.WHITE
              : colors.STORM_GRAY};
          `}
        >
          2. Register
        </span>
        <div
          css={css`
            height: 4px;
            align-self: stretch;
            border-radius: 8px;
            background: ${eventProgress === 2
              ? colors.PRIMARY_300
              : eventProgress >= 2
              ? colors.PRIMARY
              : colors.CHARADE};
          `}
        />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          flex: 1 0 0;
          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        <span
          css={css`
            ${eventProgress === 3
              ? css`
                  ${paragraphSmall}
                  ${weightBold}
                `
              : css`
                  ${paragraphSmall}
                  ${weightRegular}
                `}
            color: ${eventProgress === 3
              ? colors.PRIMARY_300
              : eventProgress >= 3
              ? colors.WHITE
              : colors.STORM_GRAY};
          `}
        >
          3. Payment
        </span>
        <div
          css={css`
            height: 4px;
            align-self: stretch;
            border-radius: 8px;
            background: ${eventProgress >= 3 ? colors.PRIMARY : colors.CHARADE};
          `}
        />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          flex: 1 0 0;
          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        <span
          css={css`
            ${eventProgress === 4
              ? css`
                  ${paragraphSmall}
                  ${weightBold}
                `
              : css`
                  ${paragraphSmall}
                  ${weightRegular}
                `}
            color: ${eventProgress === 4
              ? colors.PRIMARY_300
              : eventProgress >= 4
              ? colors.WHITE
              : colors.STORM_GRAY};
          `}
        >
          4. Validation
        </span>
        <div
          css={css`
            height: 4px;
            align-self: stretch;
            border-radius: 8px;
            background: ${eventProgress >= 4 ? colors.PRIMARY : colors.CHARADE};
          `}
        />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          flex: 1 0 0;
          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        <span
          css={css`
            ${eventProgress === 5
              ? css`
                  ${paragraphSmall}
                  ${weightBold}
                `
              : css`
                  ${paragraphSmall}
                  ${weightRegular}
                `}
            color: ${eventProgress === 5
              ? colors.PRIMARY_300
              : eventProgress >= 5
              ? colors.WHITE
              : colors.STORM_GRAY};
          `}
        >
          5. Confirmation
        </span>
        <div
          css={css`
            height: 4px;
            align-self: stretch;
            border-radius: 8px;
            background: ${eventProgress >= 5 ? colors.PRIMARY : colors.CHARADE};
          `}
        />
      </div>
    </div>
  )
}
