export enum GameStatus {
  Normal = 1,
  HomeForfeit = 2,
  VisitingForfeit = 3,
  Delayed = 4,
  Postponed = 5,
  Suspended = 6,
  DoubleForfeit = 7,
  PreGame = 8,
  Final = 9,
}
