/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import Icon from '../Icon'
import { Flex } from '../Layout'

import { Pager } from '../Pagination'
import { Mobile } from '../Responsive'
import ListHeader from './Header'
import { FIRST_CELL_WIDTH } from './list-constants'
import ListGroup from './ListGroup'
import ListRow from './Row'
import ListStatusView from './StatusView'
import SuspensionSelect from '../Suspensions/SuspensionSelect'

const List = ({
  headers,
  noItemsText,
  fullNoItemsText,
  options: _options,
  listProps,
  isInFrame,
  headerBorder,
  bgColor,
  hideHeaders = false,
  highlightSorted = false,
  localSort,
  forceMobile = false,
  showSearch = false,
  showSportFilter = false,
  shouldStickyHeader = false,
  removeOverflow = false,
  sportFilterOptions = [],
  setSportFilter,
  sportFilter,
  hideGroupName = false,
  hideStatusView = false,
  alternativeOptionsIcon = false,
}) => {
  const {
    loading,
    error,
    items,
    groups,
    sort,
    setSort,
    direction,
    setDirection,
    getItems,
    pagination,
    getKey,
    searchTerm = '',
    setSearchTerm,
  } = listProps

  const options = isInFrame ? [] : _options

  const width = headers.reduce((collector, header, idx) => {
    return collector + (header.minWidth || (idx === 0 ? FIRST_CELL_WIDTH : 60))
  }, 90 + 16 + 12)

  const [initialDirection] = useState(direction)
  const [isThinking, setIsThinking] = useState(false)
  const lastLoadingState = useRef(loading)

  const isSearching = searchTerm.length > 0
  const hasItems = items.length > 0

  useEffect(() => {
    if (loading) {
      lastLoadingState.current = false
    } else {
      lastLoadingState.current = true
    }
  }, [loading])

  useEffect(() => {
    if (lastLoadingState.current && !loading && isThinking) {
      setIsThinking(false)
      lastLoadingState.current = false
    }
  }, [isThinking, loading, searchTerm])

  return (
    <>
      {showSearch && (
        <Flex
          css={css`
            margin: 20px 0 -8px 8px;
          `}
        >
          <Flex
            className='list-search-input-container'
            noFlex
            alignItems='center'
            justifyContent='space-between'
            css={css`
              input::placeholder {
                color: ${colors.ATTENDANCE_GRAY};
              }

              &:hover {
                input::placeholder {
                  color: white;
                }

                i {
                  color: white;
                }
              }

              @media (max-width: 768px) {
                max-width: 100vw;
                flex-direction: column;
                gap: 16px;
                align-items: flex-start;

                .suspension-select-container {
                  max-width: 100%;
                }

                .list-search-input {
                  width: 90vw;
                }

                .report-select {
                  width: 90vw;
                }
              }
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <input
                className='list-search-input'
                type='text'
                value={searchTerm}
                onChange={({ target }) => {
                  if (!isThinking) {
                    setIsThinking(true)
                  }
                  setSearchTerm(target.value)
                }}
                css={css`
                  border-radius: 4px;
                  border: 2px solid ${colors.SOFT_STEEL};

                  &:active,
                  &:hover {
                    background-color: ${colors.SOFT_STEEL};
                  }
                  color: white;
                  padding: 8px 16px;

                  font-size: 15px;
                  letter-spacing: 1.1px;
                  width: 300px;

                  &:active {
                    i {
                      color: white;
                    }
                  }
                `}
                placeholder='Search'
              />
              <div
                css={css`
                  width: 20px;
                  height: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transform: translateX(-28px);
                  cursor: ${isSearching ? 'pointer' : 'normal'};

                  @keyframes fade-dots {
                    0% {
                      opacity: 1;
                    }

                    50%,
                    100% {
                      opacity: 0.2;
                    }
                  }

                  .dot1,
                  .dot2,
                  .dot3 {
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: white;
                    margin-left: 2px;

                    animation-name: fade-dots;
                    animation-duration: 0.8s;
                    animation-timing-function: ease-in-out;
                    animation-iteration-count: infinite;
                    animation-direction: alternate;
                  }

                  .dot1 {
                    animation-delay: 0.2s;
                  }

                  .dot2 {
                    animation-delay: 0.4s;
                  }

                  .dot3 {
                    animation-delay: 0.6s;
                  }
                `}
                onClick={() => {
                  setIsThinking(true)
                  setSearchTerm('')
                }}
              >
                {isThinking ? (
                  <Flex alignItems='center'>
                    <div className='dot1' />
                    <div className='dot2' />
                    <div className='dot3' />
                  </Flex>
                ) : (
                  <Icon
                    name={isSearching ? 'times' : 'search'}
                    css={css`
                      display: flex;
                    `}
                    color={isSearching ? 'white' : colors.ATTENDANCE_GRAY}
                  />
                )}
              </div>
            </div>
            {showSportFilter ? (
              <SuspensionSelect
                enabledAllItemsOption={false}
                label='Sport'
                placeholder='-'
                value={sportFilter}
                onChange={(e) => setSportFilter(e)}
                showSeparator={false}
                options={sportFilterOptions}
              />
            ) : null}
          </Flex>
        </Flex>
      )}
      {loading || error || !hasItems ? (
        <ListStatusView
          hideStatusView={!hasItems || hideStatusView}
          loading={loading}
          error={error}
          zeroItems={!hasItems}
          noItemsText={noItemsText}
          fullNoItemsText={
            searchTerm.length > 0
              ? `No results found for "${searchTerm}"`
              : fullNoItemsText
          }
        />
      ) : (
        <Mobile>
          {(isMobile) => {
            return (
              <div
                className='list-container'
                css={css`
                  position: relative;
                  ${removeOverflow && 'overflow: visible !important;'}

                  ${window.innerWidth - 32 < width &&
                  css`
                    &::after {
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      right: 0px;
                      content: '';
                      width: 42px;
                      pointer-events: none;
                      background: linear-gradient(
                        to left,
                        ${isInFrame ? 'white' : 'rgba(0, 0, 0, 0.5)'},
                        ${isInFrame ? 'white 20%' : 'transparent 55%'},
                        transparent
                      );
                    }
                  `}
                `}
              >
                <div
                  className='list-inner'
                  css={css`
                    position: relative;
                    margin: 24px 8px ${pagination ? '100px' : '8px'} 8px;

                    ${(isMobile || forceMobile) &&
                    css`
                      overflow-x: auto;
                      margin-left: 0;
                      margin-right: 0;
                    `}
                  `}
                >
                  {!hideHeaders && (
                    <ListHeader
                      alternativeOptionsIcon={alternativeOptionsIcon}
                      options={options}
                      headers={headers}
                      setSort={setSort}
                      setDirection={setDirection}
                      sort={sort}
                      direction={direction}
                      initialDirection={initialDirection}
                      headerBorder={headerBorder}
                      width={width}
                      isMobile={isMobile || forceMobile}
                      shouldStickyHeader={shouldStickyHeader}
                    />
                  )}
                  {groups
                    ? Object.keys(groups).map((groupId) => {
                        const group = groups[groupId]
                        return (
                          <ListGroup
                            key={groupId}
                            width={width}
                            group={group}
                            headers={headers}
                            options={options}
                            sort={sort}
                            direction={direction}
                            bgColor={bgColor}
                            highlightSorted={highlightSorted}
                            getKey={getKey}
                            localSort={localSort}
                            isMobile={isMobile || forceMobile}
                            hideGroupName={hideGroupName}
                          />
                        )
                      })
                    : items.map((item) => {
                        let key = item.id || item.uid
                        if (typeof getKey === 'function') {
                          key = getKey(item)
                        }

                        return (
                          <ListRow
                            alternativeOptionsIcon={alternativeOptionsIcon}
                            key={key}
                            item={item}
                            headers={headers}
                            options={options}
                            sort={sort}
                            bgColor={bgColor}
                            highlightSorted={highlightSorted}
                            width={width}
                            isMobile={isMobile || forceMobile}
                          />
                        )
                      })}
                  {pagination && (
                    <Pager
                      onClick={getItems}
                      page={pagination.current_page}
                      total={pagination.total_pages}
                    />
                  )}
                </div>
              </div>
            )
          }}
        </Mobile>
      )}
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    isInFrame: state.auth.inFrame,
  }
}

export default connect(mapStateToProps)(List)
