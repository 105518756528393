/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { ENTITY_TYPES, ENTITY_TYPE_INFO } from '@sportninja/common/sagas/utils'
import { useContext } from 'react'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { t } from '@sportninja/common/i18n'

import Icon from '../../components/Icon'
import { Flex } from '../../components/Layout'
import { media } from '../../components/Responsive'
import { SearchContext } from './SearchContextProvider'
import {
  clearRecentSearchItem,
  clearRecentSearchTerm,
  storeRecentSearchItem,
} from './recent-search'
import ResultCard from './ResultCard'
import { Title } from './SearchSection'

const SearchRecentPanel = ({ recentSearches, setRecentSearches, onClose }) => {
  const searchContext = useContext(SearchContext)

  return (
    (recentSearches?.terms?.length > 0 ||
      recentSearches?.items?.length > 0) && (
      <Flex
        noFlex
        css={css`
          width: 100%;
          max-width: 1280px;

          ${media.mobile} {
            flex-direction: column;
          }
        `}
      >
        {recentSearches?.terms?.length > 0 && (
          <div
            css={css`
              min-width: 320px;
            `}
          >
            <Title text='Recent Searches' />
            {recentSearches.terms.map((recent, idx) => {
              return (
                <Flex
                  alignItems='center'
                  justifyContent='space-between'
                  key={idx}
                  onClick={() => {
                    searchContext.setText(recent)
                    searchContext.searchInputRef.current.focus()
                  }}
                  css={css`
                    cursor: pointer;
                    padding: 8px 16px 8px 8px;
                    margin: 4px 0;
                    font-size: 16px;
                    font-weight: 500;

                    &:nth-of-type(even) {
                      background-color: #292e35;
                    }

                    transition: background-color 0.2s ease-in-out;

                    &:hover {
                      background-color: rgba(255, 255, 255, 0.1);
                    }
                  `}
                >
                  {recent}
                  <button
                    type='button'
                    css={css`
                      margin-left: 8px;
                      border-radius: 8px;
                      width: 24px;
                      height: 24px;
                      pointer-events: all;

                      transition: all 0.2s ease-in-out;
                      &:hover {
                        background-color: rgba(255, 255, 255, 0.5);
                        i {
                          color: black;
                        }
                      }
                    `}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()

                      const store = clearRecentSearchTerm(recent)
                      setRecentSearches(store)
                    }}
                  >
                    <Icon
                      color={colors.ATTENDANCE_GRAY}
                      faType='fal'
                      name='times'
                      fontSize={16}
                    />
                  </button>
                </Flex>
              )
            })}
          </div>
        )}
        {recentSearches?.items?.length > 0 && (
          <div
            css={css`
              margin-left: 24px;
              flex: 1;

              ${media.mobile} {
                margin-top: 24px;
                margin-left: 0;
              }
            `}
          >
            <Title text='Previous Results' />
            <Flex row flexWrap>
              {recentSearches?.items &&
                recentSearches?.items?.length > 0 &&
                recentSearches?.items.map((item) => {
                  const entityTypeInfo = ENTITY_TYPE_INFO?.[item?.entityType]
                  let subTitle = entityTypeInfo?.fullName || '-'
                  if (isCanlan) {
                    if (item?.entityType === ENTITY_TYPES.schedule) {
                      subTitle = t('common:competition')
                    } else if (item?.entityType === ENTITY_TYPES.org) {
                      subTitle = t('common:organization')
                    }
                  }

                  const onClick = () => {
                    onClose()
                    // Wait until the search menu disappears, so we don't partially
                    // display the result moving to the top of the list
                    setTimeout(() => {
                      if (item) {
                        const store = storeRecentSearchItem(item)
                        setRecentSearches(store)
                      }
                    }, 200)
                  }

                  return (
                    <ResultCard
                      key={item?.id}
                      name={item?.name}
                      iconName={entityTypeInfo?.icon}
                      imageId={item?.imageId}
                      initials={item?.initials}
                      subTitle={subTitle}
                      to={`${entityTypeInfo?.linkRoot}/${item?.id}`}
                      onClick={onClick}
                      onRemove={() => {
                        const store = clearRecentSearchItem(item?.id)
                        setRecentSearches(store)
                      }}
                      wrapCss={css`
                        width: 296px;
                        margin-bottom: 8px;
                      `}
                    />
                  )
                })}
            </Flex>
          </div>
        )}
      </Flex>
    )
  )
}

export default SearchRecentPanel
