/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import { useRef } from 'react'
import PropTypes from 'prop-types'
import i18n from '@sportninja/common/i18n'

import Select from '../Select' 
import { media } from '../Responsive'
import translations from './translations.json'

const YearMonthPicker = ({ date, localeUtils, onChange }) => {
  let monthValues
  if (i18n.locale !== 'en' && Object.prototype.hasOwnProperty.call(translations, i18n.locale)) {
    monthValues = translations[i18n.locale].months
  } else {
    monthValues = localeUtils.getMonths()
  }

  const monthRef = useRef(null)
  const yearRef = useRef(null)
  const shortMonths = monthValues.map((month) => month.substr(0, 3))

  const currYear = new Date().getFullYear()
  const years = []
  for (let i = currYear - 20; i <= currYear + 10; i++) {
    years.push(i)
  }

  const handleChange = () => {
    onChange(new Date(yearRef.current.value, monthRef.current.value))
  }

  return (
    <div className='DayPicker-Caption' role='group'>
      <Select name='month' onChange={handleChange} value={date.getMonth()} ref={monthRef}
        wrapProps={{
          css: css`
            float: left;
            width: 30%;
          `
        }}
        css={css`
          height: 32px;
          ${media.mobile} {
            height: 44px;
          }
        `}>
          {shortMonths.map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
      </Select>
      <Select name='year' onChange={handleChange} value={date.getFullYear()} ref={yearRef}
        wrapProps={{
          css: css`
            float: left;
            width: 35%;
            margin-left: -1px;
          `
        }}
        css={css`
          height: 32px;
          ${media.mobile} {
            height: 44px;
          }
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        `}>
          {years.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
      </Select>
    </div>
  )
}

YearMonthPicker.propTypes = {
  date: PropTypes.object.isRequired,
  localeUtils: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

export default YearMonthPicker