/** @jsxImportSource @emotion/react */
import { Component } from 'react'
import css from '@emotion/css/macro'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'
import { t } from '@sportninja/common/i18n'

import MainNav from './MainNav'
import Helmet from './Helmet'
import { font } from './css'
import Icon from './Icon'
import { Flex } from './Layout'
import { FormButton } from './Form/css'
import { registerError } from 'src/utils/sentry'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { error: false, hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.stack || error.message || error }
  }

  componentDidCatch(error) {
    try {
      registerError(error, 'ErrorBoundary')
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Helmet title='Oops' />
          {!this.props.inFrame && <MainNav minimal />}
          <Flex
            column
            alignItems='center'
            css={css`
              padding: 40px 20px 0;
            `}
          >
            <Icon name='dizzy' fontSize={64} />
            <h1
              css={css`
                ${font.title}
                font-size: 32px;
                font-weight: bold;
                margin: 24px;
              `}
            >
              Oops! {t('errors:somethingWentWrong')}
            </h1>
            <p>Try reloading the page.</p>
            <div
              css={css`
                margin-top: 24px;
                width: 100px;
              `}
            >
              <FormButton
                className='error-boundary-reload'
                isSubmit
                onClick={() => window.location.reload()}
              >
                {t('errors:reload')}
              </FormButton>
            </div>
          </Flex>
        </>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorBoundary
