/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'

import { bindActionToPromise } from '@sportninja/common/actions/utils'
import authActions from '@sportninja/common/actions/auth'

import FormSheet from '../Form/FormSheet'
import { passwordForm } from '../Form/form-configs'
import { Mobile } from '../Responsive'

const AccountSettings = ({ button, changePassword }) => (
  <Mobile>
    {(isMobile) => (
      <FormSheet
        Button={button}
        form={passwordForm()}
        label='Account Settings'
        onSubmit={async (...values) => {
          const response = await changePassword(...values)
          return response
        }}
        position={isMobile ? 'left' : 'right'}
        preForm={
          <h2 css={css`
            font-size: 13px;
            letter-spacing: 1.34px;
            text-transform: uppercase;
          `}>Change Password</h2>
        }
        title='Account Settings' />
    )}
  </Mobile>
)

AccountSettings.propTypes = {
  button: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: bindActionToPromise(dispatch, authActions.change.request)
  }
}

export default connect(null, mapDispatchToProps)(AccountSettings)
