/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useEffect, useState } from 'react'
import { t } from '@sportninja/common/i18n'

import { feedItemForm } from '../../components/Form/form-configs'
import FormSheet from '../../components/Form/FormSheet'
import {
  DisabledButton,
  LoadingButton,
  NewPostButton,
} from './SidebarComponents'
import req from '@sportninja/common/api/request'

const HomeSidebarNewPost = ({
  refreshFeed,
  loading,
  error,
  orgs,
  scheds,
  teams,
}) => {
  if (error) {
    return false
  }

  const hasNoPermissions =
    orgs.length === 0 && teams.length === 0 && scheds.length === 0

  return (
    <div
      css={css`
        margin-top: 16px;
      `}
    >
      <FormSheet
        label={t('Web:createANewPost')}
        title={t('Web:createANewPost')}
        form={feedItemForm(
          {
            orgs,
            teams,
            scheds,
          },
          true
        )}
        onSubmit={async ({ parent_id, entityType, imageData, ...form }) => {
          const body = JSON.stringify({
            ...form,
          })
          const response = await req(
            `/${entityType}/${parent_id}/social-feed`,
            {
              method: 'POST',
              body,
            }
          )
          if (imageData) {
            await req(`/social-feed/${response.data.id}/image`, {
              method: 'POST',
              body: imageData,
            })
          }
          await refreshFeed()
        }}
        Button={
          loading
            ? LoadingButton
            : hasNoPermissions
            ? DisabledButton
            : NewPostButton
        }
      />
    </div>
  )
}

export default HomeSidebarNewPost
