import { useEffect, useRef } from 'react'
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

// As items are added to the list, the first item is the one which should have
// scroll lock applied.
const controller = {
  list: [],
  add(el) {
    if (el === this.list[0]) return disableBodyScroll(el)

    this.list.unshift(el)
    clearAllBodyScrollLocks()
    disableBodyScroll(el)
  },
  remove(el) {
    const idx = this.list.findIndex((item) => item === el)

    if (idx === 0) {
      const el = this.list.shift()
      enableBodyScroll(el)
    } else if (idx !== -1) this.list.splice(idx, 1)

    if (this.list.length > 0) disableBodyScroll(this.list[0])

  }
}

const useBodyScrollLock = (isActive = true) => {
  const ref = useRef(null)

  useEffect(() => {
    const scrollingEl = ref.current
    // Stop the content behind the Sheet from scrolling when we scroll the Sheet
    if (scrollingEl) {
      isActive ?
        controller.add(scrollingEl)
        // disableBodyScroll(scrollingEl, {
        //   allowTouchMove: el => {
        //     while (el && el !== document.body) {
        //       // If an element has this class applied, let it scroll!
        //       if (el.classList.contains('ignore-scroll-lock')) {
        //         return true
        //       }
        
        //       el = el.parentNode
        //     }
        //   }
        // })
      :
        // enableBodyScroll(scrollingEl)
        controller.remove(scrollingEl)
    }

    return () => {
      // clearAllBodyScrollLocks()
      // enableBodyScroll(scrollingEl)
      controller.remove(scrollingEl)
    }
  }, [ ref.current, isActive ])

  return [ ref ]
}

export default useBodyScrollLock