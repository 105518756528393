/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { t } from '@sportninja/common/i18n'
import { REQUIRED_AGE } from '@sportninja/common/constants/app'

import { isUnderAge, toISOString } from '../../utils/utils'
import { inputCss, InputWrapper, Label } from '../Form/css'
import { Flex } from '../Layout'
import './index.scss'

dayjs.extend(utc)

const StyledInput = styled.input`
  ${inputCss}
  width: 25%;
  padding-right: 0;

  &#day {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &#month {
    margin-left: -1px;
    border-radius: 0;
  }

  &#year {
    margin-left: -1px;
    width: 50%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

// eslint-disable-next-line react/display-name
const DateInput = React.forwardRef(
  (
    {
      autoFocus = false,
      disabled,
      error,
      min,
      max,
      maxLength = 2,
      name,
      onChange,
      placeholder,
      value,
      required = true,
    },
    ref
  ) => (
    <StyledInput
      autoFocus={autoFocus}
      hasError={error}
      ref={ref}
      autoCapitalize='none'
      autoCorrect='false'
      disabled={disabled}
      minLength={1}
      maxLength={maxLength}
      min={min}
      max={max}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      type='number'
      value={value}
      id={name}
    />
  )
)

DateInput.propTypes = {
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export const isValidAge = (values = {}) => {
  if (values.birth_date === false) return { birth_date: true }
}

class DOB extends React.Component {
  constructor(props) {
    super(props)

    this.day = React.createRef()
    this.month = React.createRef()
    this.year = React.createRef()

    this.state = {
      day: '',
      month: '',
      year: '',
      error: '',
      fieldError: {},
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      const defaultValue = dayjs(this.props.defaultValue).utc()

      this.setState({
        day: defaultValue.date(),
        month: defaultValue.month() + 1,
        year: defaultValue.year(),
      })
    }
  }

  validateDate = () => {
    const { day, month, year } = this.state
    let fieldError = {}

    if (day && (day < 1 || day > 31)) {
      fieldError.day = true
    }
    if (month && (month < 1 || month > 12)) {
      fieldError.month = true
    }
    if (year && year.length > 3 && (year < 1900 || year > dayjs().year())) {
      fieldError.year = true
    }

    const errors = Object.keys(fieldError)
      .map((keyName) => keyName)
      .join(', ')

    this.setState({
      error: errors.length > 0 && t('errors:pleaseEnterValidX', { x: errors }),
      fieldError,
    })
  }

  handleChange({ target }) {
    this.setState({ [target.name]: target.value }, () => {
      this.validateDate()
      // Typical return value to parent component will be falsy, unless it's a
      // valid birth date over age 13
      let returnValue = false

      // NOTE!! We subtract 1 from month here, because dayjs treats months as
      // 0-indexed!
      const birthDate = dayjs(
        new Date(this.state.year, this.state.month - 1, this.state.day)
      )

      // Only if we have a valid date and valid fields, should we continue to
      // check if the user is to young, or parse it as ISO string.
      if (
        this.day.current.validity.valid &&
        this.month.current.validity.valid &&
        this.year.current.validity.valid
      ) {
        if (this.props.checkAge && isUnderAge(birthDate, REQUIRED_AGE)) {
          // Set an error if user is too young
          const error = t('errors:be13OrOlder')
          const fieldError = { day: true, month: true, year: true }
          this.setState({ error, fieldError })
        } else if (birthDate.isValid()) {
          // otherwise, return a valid birth date string
          returnValue = toISOString(birthDate)
        }
      }

      this.props.onValidDOB(returnValue)
    })
  }

  render() {
    const { error, fieldError, day, month, year } = this.state
    const { className, disabled, placeholders, required = true } = this.props
    const sharedProps = { disabled, required, onChange: this.handleChange }

    return (
      <InputWrapper className={className} disabled={disabled}>
        <Label
          css={css`
            text-transform: uppercase;
            font-weight: bold;
          `}
        >
          {t('Auth:dateOfBirth')} {required ? '*' : ''}
        </Label>
        <Label
          css={css`
            display: flex;
            div {
              padding-left: 1px;
            }
          `}
        >
          <div
            css={css`
              width: 25%;
              text-transform: capitalize;
            `}
          >
            {t('common:day')}
          </div>
          <div
            css={css`
              width: 25%;
              text-transform: capitalize;
            `}
          >
            {t('common:month')}
          </div>
          <div
            css={css`
              width: 50%;
              text-transform: capitalize;
            `}
          >
            {t('common:year')}
          </div>
        </Label>
        <Flex ref={(f) => (this.form = f)}>
          <DateInput
            error={fieldError.day}
            ref={this.day}
            min={1}
            max={31}
            name='day'
            placeholder={placeholders.day}
            value={day}
            {...sharedProps}
          />

          <DateInput
            error={fieldError.month}
            ref={this.month}
            min={1}
            max={12}
            name='month'
            placeholder={placeholders.month}
            value={month}
            {...sharedProps}
          />

          <DateInput
            error={fieldError.year}
            ref={this.year}
            min={1900}
            maxLength={4}
            name='year'
            placeholder={placeholders.year}
            value={year}
            {...sharedProps}
          />
        </Flex>
        {error ||
          (this?.props?.apiError && (
            <div
              className='is--error'
              css={css`
                margin-top: 8px;
              `}
            >
              {error || this?.props?.apiError}
            </div>
          ))}
      </InputWrapper>
    )
  }
}

DOB.defaultProps = {
  checkAge: true,
  placeholders: {
    day: 'D',
    month: 'M',
    year: 'YYYY',
  },
}

DOB.propTypes = {
  checkAge: PropTypes.bool,
  loading: PropTypes.bool,
  onValidDOB: PropTypes.func.isRequired,
  placeholders: PropTypes.shape({
    day: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
  }),
}

export default DOB
