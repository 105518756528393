import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import teamsActions from '@sportninja/common/actions/teams'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import { t } from '@sportninja/common/i18n'
import req from '@sportninja/common/api/request'

import SearchBox from '../../components/SearchBox'

const GameTeamPicker = ({
  errors = {},
  defaultValues = {},
  disabled,
  onChange,
  readTeamsBySchedule,
}) => {
  const { home_team_id, visiting_team_id } = defaultValues
  const [teams, setTeams] = useState([])
  const [homeId, setHomeId] = useState(home_team_id && home_team_id.value)
  const [visitingId, setVisitingId] = useState(
    visiting_team_id && visiting_team_id.value
  )

  useEffect(() => {
    req(`/schedules/${defaultValues.parentId}/teams/simple`).then(
      ({ data = [] }) => {
        setTeams(data)
      }
    )
  }, [])

  const handleChange = (setState, isHome, event) => {
    setState(event.target.value)
    onChange(event)
    onChange({
      target: {
        name: isHome
          ? '_home_team_is_placeholder'
          : '_visiting_team_is_placeholder',
        value: teams.find((t) => t.id === event?.target?.value)?.is_placeholder,
      },
    })
  }

  const homeFilteredTeams = teams && teams.filter((d) => d.id !== visitingId)
  const visitingFilteredTeams = teams && teams.filter((d) => d.id !== homeId)

  return (
    <>
      <SearchBox
        data={homeFilteredTeams}
        defaultValue={homeId}
        disabled={disabled}
        hasError={!!errors.home_team_id}
        name='home_team_id'
        label={t('Web:homeTeam')}
        onChange={handleChange.bind(this, setHomeId, true)}
        placeholder={`${t('Web:selectATeam')}...`}
        required
      />
      <SearchBox
        data={visitingFilteredTeams}
        defaultValue={visitingId}
        disabled={disabled}
        hasError={!!errors.visiting_team_id}
        name='visiting_team_id'
        label={t('Web:visitingTeam')}
        onChange={handleChange.bind(this, setVisitingId, false)}
        placeholder={`${t('Web:selectATeam')}...`}
        required
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    readTeamsBySchedule: bindActionToPromise(
      dispatch,
      teamsActions.readBySchedule.request
    ),
  }
}

export default connect(null, mapDispatchToProps)(GameTeamPicker)
