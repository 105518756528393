/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { t } from '@sportninja/common/i18n'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useEffect, useRef, useState } from 'react'
import req from '@sportninja/common/api/request'
import { isCanlan } from '@sportninja/common/utils/customer-name'

import ActionButton from '../ActionButton'
import DatePicker from '../DatePicker'
import { Flex } from '../Layout'
import Sheet from '../Sheet'
import ImportCSVBanner, { BANNER_TYPES } from '../ImportCSV/ImportCSVBanner'
import DeleteModal from '../Modal/DeleteModal'
import SuspensionSearchSelect from './SuspensionSearchSelect'
import SuspensionSelect from './SuspensionSelect'
import './suspension-search-select.scss'
import SuspensionSubmitButton from './SuspensionSubmitButton'
import NewButton from '../Page/NewButton'

dayjs.extend(utc)

const AddSuspension = ({
  id,
  player: _player = {},
  team: _team = {},
  comp: _comp = {},
  game: _game = {},
  official: _official = {},
  offense: _offense = {},
  toggleOpen,
  // comment: _comment = '',
  description: _description = '',
  onComplete = () => {},
  starts_at: _starts_at,
  ends_at: _ends_at,
  timezone: _timezone = '',
  length: _length = '1',
  isIndefinite: _isIndefinite = false,
}) => {
  const [error, setError] = useState(false)
  const [player, setPlayer] = useState(_player)
  // const [type, setType] = useState('Suspension')
  const [comp, setComp] = useState(_comp)
  const [team, setTeam] = useState(_team)
  const [teamRosterId, setTeamRosterId] = useState(false)
  const [game, setGame] = useState(_game)
  const [official, setOfficial] = useState(_official)
  const [offense, setOffense] = useState(_offense)
  const [dateRange, setDateRange] = useState({
    starts_at: _starts_at
      ? dayjs.utc(_starts_at)?.format('YYYY-MM-DD')
      : undefined,
    ends_at: _ends_at ? dayjs.utc(_ends_at)?.format('YYYY-MM-DD') : undefined,
  })
  const [suspensionType, setSuspensionType] = useState(
    _isIndefinite ? '3' : '1'
  )

  const [timezones, setTimezones] = useState([])
  const [timezone, setTimezone] = useState(_timezone)
  // const [comment, setComment] = useState(_comment)
  const [description, setDescription] = useState(_description)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const mounted = useRef(null)

  useEffect(() => {
    mounted.current = true
    req(`/countries/36/timezones`).then((response) => {
      setTimezones(response.map((item) => ({ text: item, value: item })))
    })

    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    const getTeamRoster = async () => {
      const teamRosterList = await req(`/teams/${team.id}/rosters`)
      const roster = teamRosterList.find((r) => r.schedule_uid === comp.id)
      setTeamRosterId(roster?.uid)
    }

    if (comp?.id && team?.id) {
      getTeamRoster()
    } else {
      setTeamRosterId(false)
    }
  }, [comp?.id, team?.id])

  const handleChange = (method, listOfSettersToClear, value) => {
    if (Array.isArray(listOfSettersToClear)) {
      listOfSettersToClear.forEach((setter) => {
        if (setter === setDateRange) {
          setter({
            starts_at: undefined,
            ends_at: undefined,
          })
        } else if (setter === setTimezone) {
          setter('')
        } else {
          if (typeof setter === 'function') setter({})
        }
      })
      method(value)
    } else {
      method(listOfSettersToClear)
    }
  }

  const stepOneValid = team?.id
  let stepTwoValid = false
  if (suspensionType === '3') {
    stepTwoValid = true
  } else {
    stepTwoValid =
      (typeof dateRange.ends_at === 'object' ||
        typeof dateRange.ends_at === 'string') &&
      (typeof dateRange.starts_at === 'object' ||
        typeof dateRange.starts_at === 'string')
  }

  const submissionValid =
    stepOneValid &&
    stepTwoValid &&
    // typeof length === 'string' &&
    // length?.length > 0 &&
    typeof description === 'string' &&
    description?.length > 0 &&
    // If the game is included, we need the timezone supplied
    (game?.id ? true : timezone)

  const onSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    setError(false)

    const startsAt = new Date(dateRange.starts_at)
    const endsAt = new Date(dateRange.ends_at)
    const isIndefinite = suspensionType === '3'

    if (!isIndefinite) {
      if (isNaN(startsAt) || isNaN(endsAt)) {
        setError({
          message: 'Invalid date format',
        })
        return
      }
      if (endsAt < startsAt) {
        setError({
          message: 'Return date cannot be before the start date',
        })
        return
      }
      if (startsAt > endsAt) {
        setError({
          message: 'Start date cannot be after the return date',
        })
        return
      }
    } else {
      if (isNaN(startsAt)) {
        setError({
          message: 'Invalid date format for start date',
        })
        return
      }
    }
    const form = {
      team_id: team.id,
      start_date:
        typeof dateRange?.starts_at === 'string'
          ? dateRange.starts_at
          : dateRange.starts_at?.format('YYYY-MM-DD[T00:00:00Z]'),
      end_date: isIndefinite
        ? null
        : typeof dateRange?.ends_at === 'string'
        ? dateRange.ends_at
        : dateRange.ends_at?.format('YYYY-MM-DD[T00:00:00Z]'),
      timezone: !game?.id ? timezone : null,
      suspension_enforcement_type_id: suspensionType || '1', // 1 === By Date
      schedule_id: comp.id,
      description,
      player_id: player?.id ? player.id : null,
      game_id: game?.id ? game.id : null,
      offense_id: offense?.id ? offense.id : null,
      // length: typeof length === 'number' ? length : null,
    }

    const body = JSON.stringify(form)
    try {
      setIsSubmitting(true)
      if (id) {
        await req(`/suspensions/${id}`, {
          method: 'PUT',
          body,
        })
      } else {
        await req(`/suspensions/schedule/${comp.id}`, {
          method: 'POST',
          body,
        })
      }

      toggleOpen()
      await onComplete()
    } catch (e) {
      if (typeof e.invalid_fields === 'object') {
        e.message = Object.keys(e.invalid_fields)
          .map((keyName) => {
            return e.invalid_fields[keyName]
          })
          .join(', ')
      }
      setError(e)
    } finally {
      mounted.current && setIsSubmitting(false)
    }
  }

  return (
    <div className={`add-suspension-sheet ${isCanlan ? 'is-canlan' : ''}`}>
      <div className='add-suspension-description'>
        <p>1. Select a team</p>
        <p>2. (Optional) Select the offending player</p>
        <p>3. (Optional) Select the game the suspension occurred in</p>
        <p>
          4. (Optional) If you have selected a player and game, select an
          offense to link to the suspension
        </p>
        <p>5. Set a return date for the suspension</p>
        <p>
          6. If you haven&apos;t selected a game, select the timezone of the
          suspension
        </p>
        {/* <p>4. Enter the number of games the suspension will last</p> */}
        <p>7. Enter a description for the suspension: e.g. Gross Misconduct</p>
      </div>

      <p className='add-suspension-required-fields'>* Required Fields</p>
      <form action='/' method='POST' onSubmit={onSubmit}>
        <fieldset>
          <Flex className='add-report-row'>
            {/* Team Search Input */}
            <SuspensionSearchSelect
              key='team'
              dataMapper={(d) => {
                return {
                  ...d,
                  fullName: d?.name,
                  imageId: getImageThumbnailId(d),
                }
              }}
              squareThumbnail
              iconName='user-friends'
              slug={`/schedules/${comp.id}/teams`}
              label='Team'
              value={team}
              checkPermissions
              placeholder='Select a team'
              noResultsText='No teams found'
              onChange={handleChange.bind(this, setTeam, [
                setPlayer,
                setGame,
                setOffense,
              ])}
              getSubTitle={(item) => `${item?.organization?.name_full || ''}`}
              required
              useInfiniteScrolling
            />

            {/* Suspension Type Select */}
            {/* <SuspensionSelect
          key='type'
          // required
          disabled
          noFlex
          label='Suspension Type'
          placeholder='Select a report type'
          options={[{ value: 'Suspension', text: 'Suspension' }]}
          onChange={handleChange.bind(this, setType)}
          value={type}
        /> */}
          </Flex>

          {stepOneValid && teamRosterId && (
            <Flex className='add-report-row'>
              {/* Player Search Input */}
              {team?.id && (
                <SuspensionSearchSelect
                  key='player'
                  dataTransformer={(data) => {
                    return data?.players || []
                  }}
                  dataMapper={(d) => ({
                    ...d,
                    nameFirst: d.name_first,
                    nameLast: d.name_last,
                    fullName: `${d.name_first} ${d.name_last}`,
                    imageId: getImageThumbnailId(d),
                    teams: d.teams,
                  })}
                  noSearch
                  slug={`/teams/${team.id}/rosters/${teamRosterId}/players`}
                  useInitials
                  label='Player'
                  value={player}
                  onChange={handleChange.bind(this, setPlayer, [
                    setGame,
                    setOffense,
                  ])}
                  placeholder='Select a player'
                  noResultsText='No players found'
                  getSubTitle={(item) =>
                    `#${item?.player_number} - ${item?.player_type?.name_full}`
                  }
                />
              )}

              {/* Game Search Input */}
              {team?.id && (
                <SuspensionSearchSelect
                  key={`date-${team.id}`}
                  dataMapper={(d) => {
                    return {
                      ...d,
                      fullName: d?.home_team_name
                        ? `${d?.home_team_name} vs. ${d?.visiting_team_name}`
                        : `${d?.homeTeam?.name_full} vs. ${d?.visitingTeam?.name_full}`,
                      time: dayjs(d.starts_at)?.format('h:mm a • YYYY-MM-DD'),
                      venue: d?.venue_name
                        ? d?.venue_name
                        : d?.venue?.name_full,
                      gameNumber: d?.league_game_number,
                    }
                  }}
                  noImage
                  noSearch
                  slug={comp?.id ? `/schedules/${comp.id}/games` : ''}
                  queryObject={{
                    team_id: team.id,
                    sort: 'starts_at',
                    order: 'desc',
                  }}
                  label='Game'
                  value={game}
                  placeholder='Select a game'
                  noResultsText='No games found'
                  onChange={handleChange.bind(this, setGame, [
                    setOffense,
                    setTimezone,
                  ])}
                  getSubTitle={(item) =>
                    `${item?.time} - ${item?.venue || item?.venue_name}${
                      item?.gameNumber ? ` - #${item?.gameNumber}` : ''
                    }${
                      item?.league_game_number
                        ? ` - #${item?.league_game_number}`
                        : ''
                    }`
                  }
                  useInfiniteScrolling
                />
              )}

              {/* Competition Search Input */}
              {/* {team?.id && (
            <SuspensionSearchSelect
              key='comp'
              dataMapper={(d) => {
                return {
                  ...d,
                  fullName: d?.parent?.name_full || d.name_full,
                  imageId: getImageThumbnailId(d),
                }
              }}
              noSearch
              squareThumbnail
              iconName='calendar-alt'
              slug={
                comp?.id
                  ? `/schedules/${comp.id}`
                  : team?.id
                  ? `/teams/${team.id}/schedules`
                  : ''
              }
              label='Competition'
              value={comp}
              checkPermissions
              placeholder='Select a competition'
              noResultsText='No Competitions Found'
              onChange={handleChange.bind(this, setComp, [
                setGame,
                setOfficial,
                setDateRange,
              ])}
              getSubTitle={(item) =>
                `${item?.organization?.name_full} • ${item.games_count} games`
              }
              required
            />
          )} */}
            </Flex>
          )}

          {/* Game Official Input */}
          {/* {false && (
          <Flex className='add-report-row'>
            {game?.id && (
              <SuspensionSearchSelect
                key='official'
                dataMapper={(d) => {
                  return {
                    ...d,
                    nameFirst: d.name_first,
                    nameLast: d.name_last,
                    fullName: `${d.name_first} ${d.name_last}`,
                    imageId: getImageThumbnailId(d),
                  }
                }}
                noSearch
                useInitials
                slug={game?.id ? `/games/${game.id}/referees` : ''}
                label='Game Official'
                value={official}
                required
                placeholder='Select a game official'
                noResultsText='No game officials found'
                onChange={handleChange.bind(this, setOfficial, [setDateRange])}
                getSubTitle={() => 'Referee'}
              />
            )}
          </Flex>
        )} */}

          {stepOneValid && player?.id && game?.id && (
            <Flex className='add-report-row'>
              <SuspensionSearchSelect
                key={`offenses-${player.id}-${game.id}`}
                dataTransformer={(d) => {
                  return d.offenses
                    .filter((o) => {
                      return o.player_id === player.id
                    })
                    .map((o) => {
                      return {
                        ...o,
                        period: d.periods.find((p) => p.id === o.period_id),
                      }
                    })
                }}
                dataMapper={(d) => {
                  return {
                    ...d,
                    fullName: d?.offense_type?.name_full,
                    amount: d?.penalty?.amount,
                    clockTime: d?.period_clock_time,
                    periodName: d?.period?.period_type?.name,
                  }
                }}
                noImage
                noSearch
                slug={`/games/${game.id}/timeline`}
                label='Offense'
                value={offense}
                placeholder='Select an offense'
                noResultsText='No offenses found'
                onChange={handleChange.bind(this, setOffense)}
                getSubTitle={(item) =>
                  `${item?.periodName} - ${item?.amount} min - ${item?.clockTime}`
                }
              />
            </Flex>
          )}

          {stepOneValid && (
            <Flex className='add-report-row'>
              <SuspensionSelect
                required
                label='Type of Suspension'
                placeholder='Select a suspension type'
                value={suspensionType}
                options={[
                  { text: 'By Date', value: 1 },
                  { text: 'Indefinite', value: 3 },
                ]}
                onChange={(e) => {
                  setSuspensionType(e)
                }}
              />
            </Flex>
          )}
          {stepOneValid && suspensionType === '1' && (
            <Flex className='add-report-row'>
              <DatePicker
                defaultValue={dateRange.starts_at}
                datePlaceholder='Select a start date'
                label='Start Date'
                hideTime
                required
                name='starts_at'
                useISOString={false}
                changeOnReset
                onChange={({ target }) => {
                  const { name, value } = target
                  setDateRange((prevDateRange) => {
                    return {
                      ...prevDateRange,
                      [name]: value,
                    }
                  })
                }}
              />
              <DatePicker
                defaultValue={dateRange.ends_at}
                datePlaceholder='Select a return date'
                label='Return Date'
                hideTime
                required
                name='ends_at'
                useISOString={false}
                changeOnReset
                onChange={({ target }) => {
                  const { name, value } = target

                  setDateRange((prevDateRange) => {
                    return {
                      ...prevDateRange,
                      [name]: value,
                    }
                  })
                }}
              />
            </Flex>
          )}

          {stepTwoValid && suspensionType === '3' && (
            <Flex className='add-report-row'>
              <DatePicker
                defaultValue={dateRange.starts_at}
                datePlaceholder='Select a start date'
                label='Start Date'
                hideTime
                required
                name='starts_at'
                useISOString={false}
                changeOnReset
                onChange={({ target }) => {
                  const { name, value } = target
                  setDateRange((prevDateRange) => {
                    return {
                      ...prevDateRange,
                      [name]: value,
                    }
                  })
                }}
              />
            </Flex>
          )}

          {stepTwoValid && !game?.id && (
            <Flex className='add-report-row'>
              <SuspensionSelect
                required
                label='Timezone'
                placeholder='Select a suspension timezone'
                value={timezone}
                options={timezones}
                onChange={handleChange.bind(this, setTimezone)}
              />
            </Flex>
          )}

          {stepTwoValid && (
            <Flex className='add-report-row' column>
              <label
                htmlFor='suspension-comment'
                css={css`
                  height: 12px;
                  font-size: 12px;
                  line-height: 12px;
                  margin-bottom: 8px;
                `}
              >
                Description *
              </label>
              <input
                className='suspension-search-input'
                placeholder='Enter a description, e.g. Gross Misconduct'
                css={css`
                  padding: 8px 16px;
                  height: 46px;
                  background: #27303e;
                  border: ${isCanlan ? 2 : 1}px solid #3f4753 !important;
                  border-radius: 4px;
                  letter-spacing: normal;

                  &:focus,
                  &:hover {
                    border-color: white;
                  }
                `}
                id='suspension-comment'
                type='text'
                value={description}
                onChange={({ target }) => setDescription(target.value)}
                required
              />
            </Flex>
          )}
        </fieldset>
        {error && (
          <ImportCSVBanner
            message={error.message}
            type={BANNER_TYPES.error.type}
          />
        )}
        <Flex className='add-report-row add-report-submission-row'>
          <SuspensionSubmitButton
            disabled={isSubmitting}
            busy={isSubmitting}
            className='add-suspension-button is-cancel'
            width='50%'
            height='56px'
            onClick={() => {
              toggleOpen(false)
            }}
            type='button'
          >
            Cancel
          </SuspensionSubmitButton>
          <SuspensionSubmitButton
            busy={isSubmitting}
            disabled={isSubmitting || !submissionValid}
            className='add-suspension-button'
            width='50%'
            height='56px'
            type='submit'
          >
            Submit
          </SuspensionSubmitButton>
        </Flex>
      </form>
      {id && (
        <div className='suspension-delete-container'>
          <DeleteModal
            title='Delete Suspension'
            message={`Are you sure you want to delete this suspension? This cannot be undone.`}
            onDelete={async () => {
              await req(`/suspensions/${id}`, { method: 'DELETE' })
              toggleOpen()
              await onComplete()
            }}
          />
        </div>
      )}
    </div>
  )
}

const AddSuspensionSheet = ({ button, onComplete, id, ...props }) => {
  return (
    <Sheet
      button={
        typeof button === 'function'
          ? button
          : (toggleOpen) => (
              <NewButton onClick={toggleOpen}>
                {t('Web:addNewSuspension')}
              </NewButton>
            )
      }
      title={id ? 'Edit Suspension' : t('Web:addNewSuspension')}
    >
      {(toggleOpen) => {
        return (
          <AddSuspension
            id={id}
            onComplete={onComplete}
            toggleOpen={toggleOpen}
            {...props}
          />
        )
      }}
    </Sheet>
  )
}

export default AddSuspensionSheet
