/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import dayjs from 'dayjs'
import { useState } from 'react'

import { font } from '../css'
import { InputWrapper, Label } from './css'

const TimePicker = ({
  disabled,
  hasError,
  label,
  onChange,
  required,
  name,
  noFlex,
  started_at,
  defaultValue,
}) => {
  const [value, setValue] = useState(
    // The type='time' input wants to see values like 05:00 or 17:05 and this is
    // how we use dayjs to format it like that
    defaultValue ? dayjs(defaultValue).format('HH:mm') : ''
  )

  const handleChange = ({ target: { value } }) => {
    setValue(value)

    const [hour, minute] = value.split(':')
    const dateTime = dayjs(started_at).hour(hour).minute(minute)

    onChange({ target: { name, value: dateTime } })
  }

  return (
    <InputWrapper noFlex={noFlex}>
      <Label disabled={disabled} hasError={hasError}>
        {label}
        {required && ' *'}
      </Label>
      <input
        value={value}
        type='time'
        name='occurred_at'
        onChange={handleChange}
        css={css`
          width: 100%;
          height: 46px;
          padding: 0 12px;
          border: 1px solid rgba(255, 255, 255, 0.2);
          border-radius: 2px;
          ${font.body}
          color: #ffffff;
          letter-spacing: 0.74px;
          line-height: normal;

          &::-webkit-calendar-picker-indicator {
            filter: invert(1);
          }
        `}
      ></input>
    </InputWrapper>
  )
}

export default TimePicker
