import { createSelector } from 'reselect'

import { toArray } from '../utils/utils'
import { t } from '../i18n'
import parsers, {
  countTeamOffenses,
  countTeamShots,
  countTeamGoals,
} from '../reducers/parsers'
import { getBirthDateString, getImageThumbnailId } from '../reducers/helpers'
import { ROUTES } from '../constants/app'
import { getGames, getTeams, getUsers, getSchedules } from './base'

export const getGameById = (state, id) => state.game[id]

// For the Games view (a list of games)
export const getGameList = createSelector(
  getGames,
  getTeams,
  getSchedules,
  (games, teams, schedules) => {
    if (Object.keys(games).length === 0) return false
    return toArray(games, (game) => {
      return {
        ...game,
        schedule: schedules[game.scheduleId],
        homeTeam: teams[game.homeTeamId],
        visitingTeam: teams[game.visitingTeamId],
      }
    })
  }
)

export const getGameListMap = createSelector(
  getGames,
  getTeams,
  getSchedules,
  (games, teams, schedules) => {
    if (Object.keys(games).length === 0) return false

    const newGames = {}

    for (let gameId in games) {
      const game = games[gameId]
      newGames[gameId] = {
        ...game,
        schedule: schedules[game.scheduleId],
        homeTeam: teams[game.homeTeamId],
        visitingTeam: teams[game.visitingTeamId],
      }
    }

    return newGames
  }
)

const getGameRoster = (playerRosters, teamId) => {
  if (!playerRosters || !Array.isArray(playerRosters)) return {}

  const roster = playerRosters.find((r) => r.team_id === teamId)

  if (!roster || !roster.players) return {}

  const players = {}
  roster.players.forEach((player) => {
    const playerInfo = parsers.user(player)

    players[player.id] = {
      ...playerInfo,
      birthDate: getBirthDateString(playerInfo.birthDate),

      // TODO: WE NEED TO FIGURE OUT IF WE CAN REMOVE THIS AND REFERENCE PLAYER IMAGES INSTEAD
      imageId: getImageThumbnailId(player),
      id: player.id,
      number: `${player.player_number}`,
      leagueNumber: player.league_registration_number,
      position: player.player_type.name_full,
      playerTypeId: player.player_type.id,
      positionAbbrev: player.player_type.abbreviation,

      isAffiliate: player.is_affiliate,

      is_injured: player.is_injured,
      is_playing: player.is_playing,
      is_starting: player.is_starting,
      is_suspended: player.is_suspended,
      teamId: roster.team_id,
      to: `${ROUTES.PLAYER_ROOT}/${player.id}`,
      customer_identifier: player.customer_identifier,
      attendance_status: player.attendance_status,
      suspensions: player.suspensions,
      is_signed_waiver: player.is_signed_waiver,
    }
  })

  return players
}

const getGameTeamOfficialRoster = (playerRosters, teamId) => {
  if (!Array.isArray(playerRosters)) return []
  const roster = playerRosters.find((r) => r?.team_id === teamId)
  if (!roster) return []
  return roster?.team_officials?.map(parsers.teamOfficial)
}

const getTeamOfficialRosterId = (playerRosters, teamId) => {
  if (!Array.isArray(playerRosters)) return ''
  const roster = playerRosters?.find((r) => r?.team_id === teamId)
  if (!roster) return ''
  return roster?.id
}

// This is used for a specific game
export const makeGetGame = () => {
  return createSelector(
    getGameById,
    getTeams,
    getUsers,
    (game, teams, users) => {
      if (typeof game === 'undefined') return false

      const homeTeamId = game.homeTeamId
      const visitingTeamId = game.visitingTeamId

      const homeTeam = teams[homeTeamId]
      const visitingTeam = teams[visitingTeamId]

      const [homeGoalCount, visitingGoalCount] = countTeamGoals(
        homeTeamId,
        visitingTeamId,
        game.goals
      )
      const [homeShotCount, visitingShotCount] = countTeamShots(
        homeTeamId,
        visitingTeamId,
        game.shots
      )
      const [homeOffenseCount, visitingOffenseCount] = countTeamOffenses(
        homeTeamId,
        visitingTeamId,
        game.offenses
      )

      return {
        ...game,
        homeTeam,
        visitingTeam,

        homeGoalCount,
        visitingGoalCount,

        homeShotCount,
        visitingShotCount,

        homeOffenseCount,
        visitingOffenseCount,

        homeGameRoster: game.playerRosters
          ? getGameRoster(game.playerRosters, homeTeamId)
          : {},
        visitingGameRoster: game.playerRosters
          ? getGameRoster(game.playerRosters, visitingTeamId)
          : {},

        homeTeamOfficialsRosterId: game.playerRosters
          ? getTeamOfficialRosterId(game.playerRosters, homeTeamId)
          : '',
        visitingTeamOfficialsRosterId: game.playerRosters
          ? getTeamOfficialRosterId(game.playerRosters, visitingTeamId)
          : '',

        homeTeamOfficials: game.playerRosters
          ? getGameTeamOfficialRoster(game.playerRosters, homeTeamId)
          : [],
        visitingTeamOfficials: game.playerRosters
          ? getGameTeamOfficialRoster(game.playerRosters, visitingTeamId)
          : [],

        referees:
          game.referees &&
          game.referees.map((referee) => ({
            ...referee,
            ...users[referee.id],
            description: t('util:capitalize', { text: t('common:referee') }),
          })),

        scorekeepers:
          game.referees &&
          game.scorekeepers.map((scorekeeper) => ({
            ...scorekeeper,
            ...users[scorekeeper.id],
            description: t('util:capitalize', {
              text: t('common:scorekeeper'),
            }),
          })),
      }
    }
  )
}
