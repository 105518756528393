/** @jsxImportSource @emotion/react */
import req from '@sportninja/common/api/request'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { t } from '@sportninja/common/i18n'

import DeleteModal from '../../Modal/DeleteModal'
import { MoreOptionListItem } from './More'
import colors from '@sportninja/common/constants/appColors'

const getForceDeleteText = (entityType) => {
  switch (entityType) {
    case ENTITY_TYPES.org:
      return t('Web:forceDeleteWarningOrg')
    case ENTITY_TYPES.schedule:
      return t('Web:forceDeleteWarningComp')
    case ENTITY_TYPES.team:
      return t('Web:forceDeleteWarningTeam')
    default:
      return ''
  }
}

const OptionButtonDelete = ({
  Button,
  buttonText = 'delete',
  entityType,
  itemId,
  title,
  type,
  onComplete,
  onToggle,
  slug,
  iconName,
  iconColor = colors.ERROR_LIGHT,
  textColor = colors.ERROR_LIGHT,
  useForceDelete,
  unmountOnClose,
}) => {
  const onClick = async (shouldForce) => {
    const query = {}
    if (shouldForce) {
      query.force = 1
    }

    await req(slug ? slug : `/${entityType}/${itemId}`, {
      method: 'DELETE',
      query,
    })
    await onComplete()
  }

  const forceDeleteText = getForceDeleteText(entityType)

  return (
    <DeleteModal
      buttonText={buttonText}
      title={title}
      message={`Are you sure you want to ${buttonText} this ${type}? This cannot be undone.`}
      onDelete={onClick}
      useForceDelete={useForceDelete}
      forceDeleteText={forceDeleteText}
      unmountOnClose={unmountOnClose}
      Button={
        Button
          ? Button
          : ({ onClick }) => (
              <MoreOptionListItem
                onDelete={onClick}
                title={title}
                onClick={() => {
                  onClick()
                  onToggle()
                }}
                iconName={iconName}
                iconColor={iconColor}
                textColor={textColor}
              />
            )
      }
    />
  )
}

export default OptionButtonDelete
