/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import PropTypes from 'prop-types'

import { Column } from '../Layout'
import { cardColor } from '../css'

const CardContainer = ({ children, columns = 2, customCss }) => (
  <Column
    className='card-container'
    as='article'
    columns={columns}
    column
    css={[
      css`
        position: relative;
        background-color: ${cardColor};
        border-radius: 2px;
        margin: 32px 0;
        min-height: 272px;

        transition: box-shadow 0.2s ease-in-out,
          background-color 0.2s ease-in-out;

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
          box-shadow: rgb(0, 0, 0) 0px 4px 35px 0px;

          transition: box-shadow 0.05s ease-in-out,
            background-color 0.05s ease-in-out;
        }
      `,
      customCss,
    ]}
  >
    {children}
  </Column>
)

CardContainer.propTypes = {
  children: PropTypes.node,
  columns: PropTypes.number,
  customCss: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default CardContainer
