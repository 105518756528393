/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useCallback, useEffect, useRef, useState } from 'react'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'

const ProgressBar = ({ completed }) => {
  return (
    <div
      css={css`
        height: 35px;
        width: '100%';
        background-color: #e0e0de;
        border-radius: 5px;
      `}
    >
      <div
        css={css`
          height: 100%;
          width: ${completed}%;
          background-color: ${colors.DEFAULT_FLAIR};
          border-radius: inherit;
          text-align: right;
          transition: width 1s ease-in-out;
          justify-content: center;
          align-items: center;
          display: flex;
        `}
      >
        <span
          css={css`
            font-family: ${isCanlan
              ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
              : 'Rift, Arial, Helvetica, sans-serif'};
            color: white;
            font-size: 15px;
            font-weight: 'bold';
          `}
        >
          {completed > 0 ? `${completed}%` : ''}
        </span>
      </div>
    </div>
  )
}

let gameIndex = 0
let gamesSuccessfullyProcessed = 0
let lastIndexWithError = -1

export default function SignInReportsPrint({ history, location }) {
  const [loading, setLoading] = useState(true)
  const [hasError, setErrorExists] = useState(false)
  const [readyToPrint, setReadyToPrint] = useState(false)
  const [totalGamesProcessed, setTotalGamesProcessed] = useState(-1)
  const [abortControllers, setAbortControllers] = useState<AbortController[]>(
    []
  )
  const [HTMLLength] = useState(
    (location && location.state && location.state.games.length) || 0
  )

  let games = (location && location.state && location.state.games) || []
  const isSoccer = location.state?.isSoccer || false

  const showRosters = location.state.showRosters
  // check if showRosters is undefined
  const isShowRostersUndefined = typeof showRosters === 'undefined'

  const iframeContainer = useRef<HTMLIFrameElement>(null)

  const [fontsLoaded, setFontsLoaded] = useState({
    barlowCondensed: false,
    inter: false,
  })

  useEffect(() => {
    const isFontLoaded = (fontFamily) => {
      return document.fonts.check(`1em ${fontFamily}`)
    }
    const waitForFonts = async (fontFamilies, retries = 5, delay = 1000) => {
      let fontsLoaded = {}
      for (let i = 0; i < retries; i++) {
        const promises = fontFamilies.map((fontFamily) => {
          return document.fonts.load(`1em ${fontFamily}`).then(() => {
            if (isFontLoaded(fontFamily)) {
              console.log(`${fontFamily} is loaded`)
              fontsLoaded[fontFamily] = true
            } else {
              console.error(`${fontFamily} failed to load`)
              fontsLoaded[fontFamily] = false
            }
          })
        })

        await Promise.all(promises)

        if (Object.values(fontsLoaded).every((loaded) => loaded)) {
          break
        } else {
          await new Promise((resolve) => setTimeout(resolve, delay))
        }
      }

      return fontsLoaded
    }

    const checkFonts = async () => {
      const fonts: any = ['Barlow Condensed', 'Inter']
      const loadedFonts = await waitForFonts(fonts)
      setFontsLoaded({
        barlowCondensed: loadedFonts['Barlow Condensed'],
        // eslint-disable-next-line dot-notation
        inter: loadedFonts['Inter'],
      })
    }

    checkFonts()
  }, [])

  const loadGames = useCallback(async () => {
    try {
      for (const i in games) {
        if (Number(i) > lastIndexWithError) {
          setTotalGamesProcessed((prev) => prev + 1)
          const abortController = new AbortController()
          const signal = abortController.signal
          setAbortControllers((state) => {
            state.push(abortController)
            return state
          })

          gameIndex = Number(i)

          const url = isShowRostersUndefined
            ? `/games/${games[Number(i)]}/signin/html`
            : `/games/${games[Number(i)]}/signin/html?show_team_rosters=${
                showRosters ? '1' : '0'
              }`
          const response = await req(url, {
            parseJSON: false,
            signal,
          })

          const html = await response.text()

          // get the content of div with id 'id="report-content"' from the html
          const parser = new DOMParser()
          const doc = parser.parseFromString(html, 'text/html')
          const reportContent = doc.getElementById('report-content')
          // append the content of the div to the iframeContainer, which is a div
          if (reportContent) {
            const div = document.createElement('div')
            div.className = 'iframe-container'
            div.id = `iframe-container-${i}`
            div.style.height = '100%'
            div.style.width = '100%'
            div.style.padding = '0 16px'
            div.style.background = 'white'
            div.appendChild(reportContent)
            iframeContainer.current?.appendChild(div)
          }

          gamesSuccessfullyProcessed += 1
        }
      }

      setTotalGamesProcessed(games.length)
      setReadyToPrint(true)
    } catch (error) {
      if (games.length <= 1) {
        setErrorExists(true)
        setLoading(false)
      } else {
        saveGameWithErrorAndProceedToNextGame()
      }
    }
  }, [isSoccer])

  const saveGameWithErrorAndProceedToNextGame = useCallback(() => {
    lastIndexWithError = gameIndex

    loadGames()
  }, [])

  const setWhiteBackgroud = async () => {
    try {
      const root = document.getElementById('root')
      if (root) {
        root.style.background = 'white'
      }
      document.documentElement.style.background = 'white'
      const style = document.createElement('style')
      document.head.appendChild(style)
      document.body.style.background = 'white'
      return true
    } catch (e) {
      console.error(e)
    }
  }

  const unSetWhiteBackgroud = async () => {
    try {
      console.log('unsetting white background')
      const root = document.getElementById('root')
      document.documentElement.style.background = ''
      document.body.style.background = ''
      if (root) {
        root.style.background = ''
      }
      return true
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    const print = async () => {
      try {
        setLoading(false)
        // await setWhiteBackgroud()
        setTimeout(() => {
          window.print()
          // setTimeout(() => {
          //   unSetWhiteBackgroud()
          // }, 500)
        }, 500)
      } catch (e) {
        console.error(e)
      } finally {
        // setTimeout(() => {
        //   unSetWhiteBackgroud()
        // }, 1000)
      }
    }

    if (
      readyToPrint &&
      !hasError &&
      gamesSuccessfullyProcessed > 0 &&
      fontsLoaded.barlowCondensed &&
      fontsLoaded.inter
    ) {
      print()
    }
  }, [readyToPrint, hasError, fontsLoaded])

  useEffect(() => {
    if (fontsLoaded.barlowCondensed && fontsLoaded.inter) {
      loadGames()
    }
    return () => {
      gameIndex = 0
      gamesSuccessfullyProcessed = 0
      lastIndexWithError = -1

      abortControllers.forEach((controller) => {
        controller.abort()
      })
    }
  }, [fontsLoaded])

  addEventListener('beforeprint', (event) => {
    console.log(`before print ${event}`)
    setWhiteBackgroud()
  })

  addEventListener('afterprint', (event) => {
    console.log(`afterprint ${event}`)
    unSetWhiteBackgroud()
  })

  return (
    <>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;600;700&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap');
        `}
      </style>
      <div
        css={css`
          visibility: hidden;
          display: none;
          height: 100%;
          background: white;
          overflow: auto;
          orientation: portrait;
          @media only print {
            visibility: visible;
            display: block;
            overflow: visible;
            @page {
              margin-top: 0;
              margin-bottom: 0;
              margin-left: 0;
              margin-right: 0;
            }
          }
        `}
        ref={iframeContainer}
      />
      <div
        css={css`
          padding: 50px;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          height: 100vh;
          @media only print {
            visibility: hidden;
            display: none;
            overflow: hidden;
          }
        `}
      >
        {loading ? (
          <>
            <h1
              css={css`
                font-size: 40px;
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'Rift, Arial, Helvetica, sans-serif'};
                margin-bottom: 2em;
                font-weight: bold;
              `}
            >
              Your sign-in sheets are being processed. Please wait.
            </h1>
            <div
              css={css`
                width: 60%;
              `}
            >
              <ProgressBar
                completed={Math.round(
                  (totalGamesProcessed / games.length) * 100
                )}
              />
            </div>
          </>
        ) : (
          <>
            <h1
              css={css`
                font-size: 40px;
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'Rift, Arial, Helvetica, sans-serif'};
                margin-bottom: 2em;
                font-weight: bold;
                text-align: center;
              `}
            >
              {hasError || gamesSuccessfullyProcessed === 0
                ? 'There is a problem processing the scoresheet, please try again later.'
                : 'Your sign-in sheets are ready to download.'}
            </h1>
            <p
              css={css`
                font-size: 14px;
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'Rift, Arial, Helvetica, sans-serif'};
                margin-bottom: 2.5em;
                font-weight: bold;
              `}
            >
              A total of {gamesSuccessfullyProcessed} / {HTMLLength} sign-in
              sheets were generated.
            </p>
            {gamesSuccessfullyProcessed > 0 && (
              <a
                css={css`
                  font-size: 15px;
                  font-weight: bold;
                  color: ${colors.DEFAULT_FLAIR};
                  margin-left: 8px;
                  margin-bottom: 2.5em;
                  font-family: Arial, Helvetica, sans-serif;
                  @media only print {
                    display: none;
                  }
                `}
                onClick={async () => {
                  // await setWhiteBackgroud()
                  setTimeout(() => {
                    window.print()
                    // setTimeout(() => {
                    //   unSetWhiteBackgroud()
                    // }, 500)
                  }, 500)
                }}
              >
                {hasError
                  ? `Show the ${gamesSuccessfullyProcessed} games processed`
                  : 'Click here to open print dialog again.'}
              </a>
            )}
            <button
              type='button'
              onClick={() => {
                games = []
                gamesSuccessfullyProcessed = 0
                gameIndex = 0
                history.goBack()
              }}
              css={css`
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'Rift, Arial, Helvetica, sans-serif'};
                font-size: 24px;
                font-weight: bold;
                color: white;
                margin-left: 8px;
              `}
            >
              {'<'} Back
            </button>
          </>
        )}
      </div>
    </>
  )
}
