import { call, put } from 'redux-saga/effects'
import req from '@sportninja/common/api/request'

import actions from '../actions/feed'
import { readFactory, readWithPagesFactory } from './helpers/read'
import { generateSagas } from './utils'

export const api = {
  read: async (id) => await req(`/social-feed/${id}`),
  readAll: async (page = 1) =>
    await req(`/social-feed?page=${page}&order=desc&per_page=10`),

  like: {
    create: async (id) =>
      await req(`/social-feed/${id}/like`, { method: 'POST' }),
    delete: async (id) =>
      await req(`/social-feed/${id}/like`, { method: 'DELETE' }),
  },
}

const feed = [
  [actions.read, readFactory('social-feed', actions.read)],
  [actions.readAll, readWithPagesFactory(api.readAll, actions.readAll)],

  [
    actions.like.create,
    function* (payload) {
      const { id } = payload
      const response = yield call(api.like.create, id)
      yield put(actions.like.create.success({ id }))
      return response
    },
  ],
  [
    actions.like.delete,
    function* (payload) {
      const { id } = payload
      const response = yield call(api.like.delete, id)
      yield put(actions.like.delete.success({ id }))
      return response
    },
  ],
]

export default generateSagas([...feed])
