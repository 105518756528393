import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import typeActions from '@sportninja/common/actions/types'
import { getCountries } from '@sportninja/common/selectors/types'
import { toArray } from '@sportninja/common/utils/utils'
import { t } from '@sportninja/common/i18n'

import { SelectFormInput } from '.'

const CountryProvinceSelects = ({
  countries,
  disabled,
  defaultValues,
  onChange,
  readCountries,
  readProvinces,
}) => {
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedProvince, setSelectedProvince] = useState('')

  useEffect(() => {
    readCountries().then(() => {
      if (
        defaultValues &&
        defaultValues.country_id &&
        defaultValues.country_id.value
      ) {
        readProvinces(defaultValues.country_id.value).then(() => {
          setSelectedCountry(defaultValues.country_id.value)
          setSelectedProvince(defaultValues.province_id.value)
        })
      }
    })
  }, [])

  const onProvinceChange = ({ target }) => {
    onChange({ target })
    setSelectedProvince(target.value)
  }

  const onCountryChange = ({ target }) => {
    const countryId = target.value
    onChange({ target })

    if (countryId)
      readProvinces(countryId).then(() => {
        // Reset the province when country changes
        onProvinceChange({ target: { name: 'province_id', value: '' } })
      })

    setSelectedCountry(countryId)
  }

  const provinces =
    countries &&
    countries[selectedCountry] &&
    countries[selectedCountry].provinces
  const hasProvinces = Array.isArray(provinces) && provinces.length > 0
  const countryArray = useMemo(() => {
    const countryArray = toArray(countries)

    // Insert Canada and United States at the start
    if (countryArray.length > 0) {
      const canada = countryArray.findIndex((c) => c.name === 'Canada')
      const usa = countryArray.findIndex((c) => c.name === 'United States')

      countryArray.splice(0, 0, countryArray.splice(canada, 1)[0])
      countryArray.splice(1, 0, countryArray.splice(usa, 1)[0])
      countryArray.splice(2, 0, { line: true })
    }

    return countryArray
  }, [countries])

  return (
    <>
      <SelectFormInput
        autoComplete='country'
        defaultValue={selectedCountry}
        disabled={disabled}
        label={t('common:country')}
        name='country_id'
        onChange={onCountryChange}
        required
      >
        <option value=''></option>
        {countryArray &&
          countryArray.map((country) => {
            if (country.line)
              return (
                <option key='line' disabled>
                  -----
                </option>
              )
            return (
              <option key={country.id} value={country.id}>
                {country.name}
              </option>
            )
          })}
      </SelectFormInput>
      <SelectFormInput
        autoComplete='state'
        defaultValue={selectedProvince}
        disabled={!hasProvinces || disabled}
        label={t('common:provinceState')}
        name='province_id'
        onChange={onProvinceChange}
        required={hasProvinces}
      >
        <option value=''>
          {selectedCountry
            ? hasProvinces
              ? ''
              : 'n/a'
            : t('Web:selectACountryFirst')}
        </option>
        {hasProvinces &&
          provinces.map((province) => {
            if (province.line)
              return (
                <option key='line' disabled>
                  -----
                </option>
              )
            return (
              <option key={province.id} value={province.id}>
                {province.name}
              </option>
            )
          })}
      </SelectFormInput>
    </>
  )
}

CountryProvinceSelects.propTypes = {
  countries: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  defaultValues: PropTypes.shape({
    country_id: PropTypes.object,
    province_id: PropTypes.object,
  }),
  onChange: PropTypes.func.isRequired,
  readCountries: PropTypes.func.isRequired,
  readProvinces: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    countries: getCountries(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    readCountries: bindActionToPromise(dispatch, typeActions.countries.request),
    readProvinces: bindActionToPromise(dispatch, typeActions.provinces.request),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryProvinceSelects)
