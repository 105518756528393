import { generateActions } from './utils'

// A list of all available actions for the feed entity. The array describes
// the arguments that are passed to the REQUEST action
const feedActionDefinition = {
  read:   [ 'id' ],
  readAll: [ 'page' ],

  like: {
    create: [ 'id' ],
    delete: [ 'id' ]
  }
}

const feedActions = generateActions(['FEED'], feedActionDefinition)

export default feedActions
