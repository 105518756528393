import { call, put } from 'redux-saga/effects'
import req from '@sportninja/common/api/request'

import actions from '../actions/fav'
import { generateSagas } from './utils'

export const api = {
  create: async (type, id) => await req(`/favorites/${type}/${id}`, { method: 'POST' }),
  readAll: async (type, page = 1, sort, direction) => await req(`/favorites/${type}`, { query: { page, sort, direction } }),
  delete: async (type, id) => await req(`/favorites/${type}/${id}`, { method: 'DELETE' })
}

const readAllFactory = (entityType, action) => function* ({ page, sort, direction }) {
  const response = yield call(api.readAll, entityType, page, sort, direction)
  yield put(action.readAll.success(response))
  return response
}

const org = [
  [actions.org.create, function* (payload) {
    const response = yield call(api.create, 'organizations', payload.id)
    yield put(actions.org.create.success({ id: payload.id, data: response.data }))
    return response
  }],
  [actions.org.readAll, readAllFactory('organizations', actions.org)],
  [actions.org.delete, function* (payload) {
    yield call(api.delete, 'organizations', payload.id)
    yield put(actions.org.delete.success({ id: payload.id }))
    return
  }]
]

const schedule = [
  [actions.schedule.create, function* (payload) {
    const response = yield call(api.create, 'schedules', payload.id)
    yield put(actions.schedule.create.success({ id: payload.id, data: response.data }))
    return response
  }],
  [actions.schedule.readAll, readAllFactory('schedules', actions.schedule)],
  [actions.schedule.delete, function* (payload) {
    yield call(api.delete, 'schedules', payload.id)
    yield put(actions.schedule.delete.success({ id: payload.id }))
    return
  }]
]

const team = [
  [actions.team.create, function* (payload) {
    const response = yield call(api.create, 'teams', payload.id)
    yield put(actions.team.create.success({ id: payload.id, data: response.data }))
    return response
  }],
  [actions.team.readAll, readAllFactory('teams', actions.team)],
  [actions.team.delete, function* (payload) {
    yield call(api.delete, 'teams', payload.id)
    yield put(actions.team.delete.success({ id: payload.id }))
    return
  }]
]

export default generateSagas([
  ...org,
  ...schedule,
  ...team
])
