import { isCanlan } from './customer-name'

/* Felipe Nunes: Updated Wed, 23 Aug 2024 2:41PM ( BRT ) */
// If it fails (like if the previously set hardcoded token is expired)
// try setting the hardcoded token again (hopefully a new one)
// {
//   "access_token": "v2.local.hyL3yk4T4jzhJ6zqznC5ii-KFSuHqz44GqaXGShrIoCDvcBUEyTzJBUKp1sxQYp6uRo7fOwLfmzqKGEMr3P_Iu7i2wAZe86XgqEXm4a6uG92M6F_pUFL5OEUQBNksWBuyAPXTrF9RIju6pIVOPvuz-sXGLK98nRtqvT77LyxeeQlR1xHVPSoH-kl4cqwjhPXC1-Ovd1FRnfmV7e7qu4gTqakSZHK7YEtR3XUyTRlbcKXsVAyowCm6cbi_VkuCu-FyoKBOy9MDpIfG_Mb1ZJH4BDM3pEGOPk",
//   "token_type": "Bearer",
//   "expires_in": 604800,
//   "expires_at": "2024-08-30T17:42:00.261304Z",
//   "user": {
//     "id": "TAcc3KT6ikeaqhvL",
//     "email": "michael+planeticeservice@sportninja.com",
//     "created_at": "2022-08-29T23:14:34+00:00",
//     "updated_at": "2024-08-23T17:41:54+00:00",
//     "validated_at": "2022-08-29T23:14:42+00:00"
//   }
// }
const planetIceToken =
  'v2.local.hyL3yk4T4jzhJ6zqznC5ii-KFSuHqz44GqaXGShrIoCDvcBUEyTzJBUKp1sxQYp6uRo7fOwLfmzqKGEMr3P_Iu7i2wAZe86XgqEXm4a6uG92M6F_pUFL5OEUQBNksWBuyAPXTrF9RIju6pIVOPvuz-sXGLK98nRtqvT77LyxeeQlR1xHVPSoH-kl4cqwjhPXC1-Ovd1FRnfmV7e7qu4gTqakSZHK7YEtR3XUyTRlbcKXsVAyowCm6cbi_VkuCu-FyoKBOy9MDpIfG_Mb1ZJH4BDM3pEGOPk'

// {
//   "access_token": "v2.local.U8s-GpUxPTfJIE2hXmFm_Pf6bNYVwyiQb5wYTLmFq22mLULDwLDP8VPCMReE_UH3aPRnsvbNYHqpHvQHdzVky8Z7k7e3Sg3yxDuB-jchz1I5PaQghNgq5TuXVEz9TFwNnNS6Yq2guNm0tfDxM2H1XZUmNxOjLO37uRTK8Ko8Q-twXRu4EKe-uo55YkW77TtPs7vLeXvqF2J8H9nWrYAV9YL5PwU2R6wz27gNORyqsnqLt3lHg4ETQquOdBiKRbWnNQH04bOfnUtTGpt1IrQLkBmnX6J7M5I",
//   "token_type": "Bearer",
//   "expires_in": 604800,
//   "expires_at": "2024-08-30T17:43:25.934074Z",
//   "user": {
//     "id": "oP37ysYCd57bFlkj",
//     "email": "michael+carhaservice@sportninja.com",
//     "created_at": "2022-05-27T18:35:59+00:00",
//     "updated_at": "2024-08-23T17:43:18+00:00",
//     "validated_at": "2022-05-27T18:35:59+00:00"
//   }
// }
const carhaToken =
  'v2.local.U8s-GpUxPTfJIE2hXmFm_Pf6bNYVwyiQb5wYTLmFq22mLULDwLDP8VPCMReE_UH3aPRnsvbNYHqpHvQHdzVky8Z7k7e3Sg3yxDuB-jchz1I5PaQghNgq5TuXVEz9TFwNnNS6Yq2guNm0tfDxM2H1XZUmNxOjLO37uRTK8Ko8Q-twXRu4EKe-uo55YkW77TtPs7vLeXvqF2J8H9nWrYAV9YL5PwU2R6wz27gNORyqsnqLt3lHg4ETQquOdBiKRbWnNQH04bOfnUtTGpt1IrQLkBmnX6J7M5I'

// {
//   "access_token": "v2.local.GqW2xiQNqhRKy-9szBjp9tkg2ujlY-4Jy2ugEtHzVTisPJm3xE8Uyjvat3roTBhjZxyGQrvIopOKyv7TJZ7owI4GyY4W6_Wpi-WFPkafrNIU-YvTi2lH9GAfZ_vMch0jpseJfdMgD91rPLHIfyXb6hgGCKKPUpMPzmThQROdbfJ9gKzsb4LanslREpzfTOg7FcBRk6I0kUDfGrTG8q3vS3mVdPd-0qgRdpahxTNaaPBKO4P7woZXwOmPhahsAKcoxhM90C6WRNaOf_Niu3MLRGJehBl3",
//   "token_type": "Bearer",
//   "expires_in": 604800,
//   "expires_at": "2024-08-30T17:44:45.180007Z",
//   "user": {
//     "id": "jJkc8qSsffa24rAE",
//     "email": "michael+canlanservice@sportninja.com",
//     "created_at": "2022-05-30T20:39:26+00:00",
//     "updated_at": "2023-05-19T19:59:44+00:00",
//     "validated_at": "2022-05-30T20:39:26+00:00"
//   }
// }
const canlanToken =
  'v2.local.GqW2xiQNqhRKy-9szBjp9tkg2ujlY-4Jy2ugEtHzVTisPJm3xE8Uyjvat3roTBhjZxyGQrvIopOKyv7TJZ7owI4GyY4W6_Wpi-WFPkafrNIU-YvTi2lH9GAfZ_vMch0jpseJfdMgD91rPLHIfyXb6hgGCKKPUpMPzmThQROdbfJ9gKzsb4LanslREpzfTOg7FcBRk6I0kUDfGrTG8q3vS3mVdPd-0qgRdpahxTNaaPBKO4P7woZXwOmPhahsAKcoxhM90C6WRNaOf_Niu3MLRGJehBl3'

export const getIframeToken = () => {
  const parentUrl =
    window.location !== window.parent.location
      ? window?.document?.referrer
      : window?.document?.location?.href

  let isPlanetIce = false
  if (parentUrl && parentUrl.includes('pacificadulthockey.com')) {
    isPlanetIce = true
  }
  return isCanlan ? canlanToken : isPlanetIce ? planetIceToken : carhaToken
}
