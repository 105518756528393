/** @jsxImportSource @emotion/react */

import React, { useState } from 'react' // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types'
import { jsx } from '@emotion/react'
import styled from '@emotion/styled/macro'
import css from '@emotion/css/macro'
import { Waypoint } from 'react-waypoint'

import { Container, Column, Flex } from '../Layout'
import { media, Desktop, Mobile } from '../Responsive'
import MobilePageMenu from './MobilePageMenu'
import MenuLink from '../MenuLink'
import {
  border,
  menuHeight,
  backgroundColorLight,
  zIndex,
  MENU_HEIGHT,
} from '../css'

const stickyCss = css`
  left: 0;
  right: 0;
  padding: 0 24px;
  border-top: ${border};
  border-bottom: ${border};

  background-color: ${backgroundColorLight};
`

const PageMenuColumn = styled(Column)`
  position: relative;
  padding-bottom: 16px;

  ${media.mobile} {
    position: ${({ isSticky }) => (isSticky ? 'fixed' : 'relative')};
    z-index: ${zIndex.header};
    padding-bottom: 0;
    height: ${menuHeight};

    width: 100%;

    ${(props) => props.isSticky && stickyCss}
    top: ${(props) => props.isSticky && 50 + props.extraOffset}px;
  }
`

const Spacer = styled.div`
  ${media.mobile} {
    height: ${(props) => (props.isSticky ? '56px' : 0)};
  }
`

const PageContainer = styled(Container)`
  border-bottom: ${border};
  margin-bottom: 40px;

  ${media.mobile} {
    position: relative;
    border-top: ${border};
    background-color: ${backgroundColorLight};
  }
`

const PageMenu = ({ disabled, extraOffset = 0, inFrame, pages }) => {
  const [isSticky, setIsSticky] = useState(false)
  if (pages.length === 0) return false

  return (
    <>
      <Waypoint
        topOffset={(inFrame ? 0 : MENU_HEIGHT + extraOffset) - 2}
        scrollableAncestor={window}
        onEnter={() => setIsSticky(false)}
        onLeave={({ currentPosition }) => {
          // If we don't do this, it will fire when the page menu is _below_ the
          // bottom of the viewport - which we don't want.
          if (currentPosition === 'above') setIsSticky(true)
        }}
      />
      <Spacer isSticky={isSticky} />
      <PageContainer className='page-container'>
        <PageMenuColumn
          fullWidth
          as='nav'
          aria-labelledby='page-menu'
          isSticky={isSticky}
          extraOffset={extraOffset}
        >
          <h2 id='page-menu' className='sr-only'>
            Page menu
          </h2>
          <Desktop>
            <Flex as='ul'>
              {pages.map(({ to: _to, ...page }) => {
                const to = {
                  pathname: _to,
                  search: window.location.search,
                }
                // In cases where a link has been marked as private, we don't want
                // to show it if the user doesn't have permission to see it.
                // see pages/utils.js:createSubPages
                if (page.isHidden) return false
                return (
                  <MenuLink
                    disabled={disabled}
                    key={page.title}
                    to={to}
                    {...page}
                  />
                )
              })}
            </Flex>
          </Desktop>
          <Mobile>
            <MobilePageMenu
              pages={pages}
              disabled={disabled}
              inFrame={inFrame}
            />
          </Mobile>
        </PageMenuColumn>
      </PageContainer>
    </>
  )
}

PageMenu.propTypes = {
  pages: PropTypes.array.isRequired,
}

export default PageMenu
