/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { font } from '../css'

import { Flex } from '../Layout'
import Picture from '../Picture'

export const InitialsThumbnail = ({ children, size = 44, wrapCss }) => {
  return (
    <div
      className='initials-thumbnail'
      css={css`
        height: ${size}px;
        min-height: ${size}px;
        width: ${size}px;
        min-width: ${size}px;
        border-radius: 50%;
        border: 1px solid white;
        display: flex;
        align-items: center;
        justify-content: center;
        ${font.title};
        font-size: ${size * 0.4}px;
        font-weight: bold;
        letter-spacing: 1.24px;
        padding-left: 2px;
        ${wrapCss}
      `}
    >
      {children}
    </div>
  )
}

const PersonCell = ({ item, subTitle, ...wrapProps }) => {
  const imageId = getImageThumbnailId(item)
  const name =
    item.full_name || item.name_full || `${item.name_first} ${item.name_last}`

  return (
    <Flex alignItems='center' {...wrapProps}>
      {imageId ? (
        <Picture
          size='kxsmall'
          imageId={imageId}
          iconName='user'
          css={css`
            margin-right: 8px;
          `}
        />
      ) : (
        <InitialsThumbnail
          size={40}
          wrapCss={css`
            margin-right: 8px;
          `}
        >
          {item.name_first?.charAt(0)}
          {item.name_last?.charAt(0)}
        </InitialsThumbnail>
      )}
      <div>
        {name}
        {subTitle && (
          <div
            css={css`
              margin-top: 2px;
              font-size: 13px;
              color: gray;
            `}
          >
            {subTitle}
          </div>
        )}
      </div>
    </Flex>
  )
}

export default PersonCell
