export const notificationTypes = {
  TEST: 'TestPushNotification',
  GAME_FINISHED: 'GameFinishedNotification',
  LIVE_STREAM: 'GameLiveStreamNotification',
  INVITATION: 'InvitationNotification',
  ATTENDANCE: 'PlayerAttendanceNotification',
  UPCOMING_GAME: 'UpcomingGameNotification',
  PLAYER_SUSPENDED: 'PlayerSuspendedNotification',
  PLAYER_SUSPENDED_COMPLETED: 'PlayerSuspendedCompletedNotification',
}
