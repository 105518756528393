/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useMemo, useState } from 'react'
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { useHistory, useLocation } from 'react-router-dom'
import Icon from '../../components/Icon'
import stripeLogo from './stripe_logo.svg'
import alreadyPaid from './already_paid.svg'

import { loadStripe } from '@stripe/stripe-js'
import LoadingSpinner from '../../components/LoadingSpinner'
import req from '@sportninja/common/api/request'
import { ROUTES } from '@sportninja/common/constants/app'
import { useI18n } from '@shopify/react-i18n'
import { toJS } from 'mobx'
import {
  createErrorMessage,
  getErrorMessage,
} from '@sportninja/common/utils/utils'

const PAYMENT_STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed',
  PENDING: 'pending',
}

const RegistrationPayment = () => {
  const [division, setDivision] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [paymentIsLoading, setPaymentLoad] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const [isAlreadyPaid, setIsAlreadyPaid] = useState(false)
  const [paymentIntentError, setPaymentIntentError] = useState(false)

  const history = useHistory()
  const [i18n] = useI18n()

  const { search } = useLocation()
  const params = useMemo(() => {
    const divisionRegex = /\?divisionId=([^&]+)&teamId=([^&]+)/
    const divisionMatch = divisionRegex.exec(search)

    if (divisionMatch) {
      const divisionId = divisionMatch[1]
      const teamId = divisionMatch[2]

      return {
        divisionId,
        teamId,
      }
    }

    const billingRegex = /\?teamId=([^&]+)&rosterId=([^&]+)&billingId=([^&]+)/
    const billingMatch = billingRegex.exec(search)

    if (billingMatch) {
      const teamId = billingMatch[1]
      const rosterId = billingMatch[2]
      const billingId = billingMatch[3]

      return {
        teamId,
        rosterId,
        billingId,
      }
    }
  }, [search])

  if (!params) {
    return (
      <div>
        <h1>Not Found. (Err. 17)</h1>
      </div>
    )
  }

  async function getDivision(params) {
    try {
      let response

      if (params?.divisionId) {
        response = await req(
          `/registration/${params.divisionId}/division/team/${params.teamId}`
        )
      }

      if (params?.teamId && params?.rosterId && params?.billingId) {
        response = await req(
          `/teams/${params.teamId}/rosters/${params.rosterId}/billing/${params.billingId}`
        )
      }

      const division = response.data

      const secret = await createPaymentIntent(division)

      return { secret, division }
    } catch (e) {
      console.log('getDivisionError', e)
      throw e
    }
  }

  async function createPaymentIntent(division) {
    try {
      console.log('division', division)
      const clientSecret = await req(
        `/registration/${division?.root_schedule_id}/payment/intent`,
        {
          method: 'POST',
          body: JSON.stringify({
            // Removing this as requested by Jeff
            // team_roster_id: division.team_player_roster_id,
            // division_id: division.schedule_id,
            billing_schedule_id: division.billing[0]?.uid,
            currency_id: division.currency.id,
          }),
        }
      )

      return clientSecret
    } catch (e) {
      if (__DEV__) {
        console.error('createPaymentIntentError', e)
      }
      if (!e?.invalid_fields) {
        setPaymentIntentError(e?.message || 'Something went wrong')
        return
      }
      const errorMessage = getErrorMessage(e?.invalid_fields)
      if (errorMessage) {
        setPaymentIntentError(JSON.stringify(errorMessage))
        return
      } else {
        setPaymentIntentError('Something went wrong')
        return
      }
      // Removed due to https://sportninja.atlassian.net/browse/SN-4134
      // if (e?.statusCode === 400) {
      //   setIsAlreadyPaid(true)
      // } else {
      //   console.error('createPaymentIntentError', e)
      // }
    }
  }

  const bootstrap = useCallback(async () => {
    try {
      const { secret, division } = await getDivision(params)

      if (!secret || !division) {
        console.error('No secret or division found')
        return
      }

      let stripeClient = null

      const host = window.location.hostname || window.location.host
      if (
        __DEV__ ||
        !['app.sportninja.com', 'canlanstats.sportninja.com'].includes(host)
      ) {
        stripeClient = await loadStripe(process.env.REACT_APP_STRIPE_KEY_DEV, {
          stripeAccount: division?.connected_account,
        })
      } else {
        stripeClient = await loadStripe(process.env.REACT_APP_STRIPE_KEY, {
          stripeAccount: division?.connected_account,
        })
      }

      if (!stripeClient) {
        return
      }

      const elements = stripeClient.elements({
        clientSecret: secret,
        appearance: {
          theme: 'night',
        },
      })

      let paymentElement = elements.create('payment', {
        paymentMethodOrder: ['apple_pay', 'google_pay', 'card'],
      })

      const paymentDiv = document.querySelector('#payment-element')

      if (!paymentDiv) {
        return
      }

      paymentElement.mount('#payment-element')

      const stripeForm = document.querySelector('#payment-form')

      stripeForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        setPaymentLoad(true)

        if (!stripeClient) {
          return
        }

        const { error } = await stripeClient.confirmPayment({
          elements,
          redirect: 'if_required',
        })

        if (error) {
          setPaymentLoad(false)
          setPaymentStatus(PAYMENT_STATUS.FAILED)
          setErrorMessage(error.message)
        } else {
          setPaymentLoad(false)
          setPaymentStatus(PAYMENT_STATUS.SUCCESS)
        }
      })

      setDivision(division)
      setLoaded(true)
    } catch (e) {
      console.log('BOOTSTRAP ERROR', e)
      let errorMessage = 'Something went wrong'
      if (e?.invalid_fields) {
        errorMessage = createErrorMessage(e?.invalid_fields)
      } else {
        errorMessage = e?.message || 'Something went wrong'
      }
      setPaymentIntentError(errorMessage)
    }
  }, [params, division?.connected_account, isAlreadyPaid])

  const totalTaxSum = useMemo(() => {
    if (!division) {
      return 0
    }

    return division?.taxes?.reduce((acc, tax) => {
      return acc + Number(tax.tax_rate)
    }, 0)
  }, [division?.taxes, division?.billing])

  useEffect(() => {
    bootstrap()
  }, [])

  if (paymentIntentError) {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          gap: 16px;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            width: 160px;
            height: 200px;
          `}
        >
          <img
            src={alreadyPaid}
            alt='Payment Already Made'
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        </div>
        <h1
          css={css`
            text-align: center;
            font-family: Rift, sans-serif;
            font-size: 32px;
            font-weight: 700;
            color: ${colors.WHITE};
          `}
        >
          Payment Intent Error
        </h1>
        <p
          css={css`
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            color: ${colors.WHITE};
            text-align: center;
          `}
        >
          {paymentIntentError || 'Something went wrong'}
        </p>
        <div
          css={css`
            width: 380px;
          `}
        >
          <button
            onClick={() => history.replace(ROUTES.HOME)}
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 8px 24px;
              border: 2px solid #f2b400;
              border-radius: 4px;
              height: 48px;
              width: 100%;
              color: ${colors.WHITE};
            `}
          >
            BACK TO HOME
          </button>
        </div>
      </div>
    )
  }

  // Removed due to https://sportninja.atlassian.net/browse/SN-4134
  // if (isAlreadyPaid) {
  //   return (
  //     <div
  //       css={css`
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;
  //         height: 100vh;
  //         gap: 16px;
  //         flex-direction: column;
  //       `}
  //     >
  //       <div
  //         css={css`
  //           width: 160px;
  //           height: 200px;
  //         `}
  //       >
  //         <img
  //           src={alreadyPaid}
  //           alt='Payment Already Made'
  //           css={css`
  //             width: 100%;
  //             height: 100%;
  //           `}
  //         />
  //       </div>
  //       <h1
  //         css={css`
  //           text-align: center;
  //           font-family: Rift, sans-serif;
  //           font-size: 32px;
  //           font-weight: 700;
  //           color: ${colors.WHITE};
  //         `}
  //       >
  //         Payment Already Made
  //       </h1>
  //       <p
  //         css={css`
  //           font-size: 18px;
  //           font-style: normal;
  //           font-weight: 500;
  //           color: ${colors.WHITE};
  //           text-align: center;
  //         `}
  //       >
  //         Looks like you’ve already paid for this!
  //       </p>
  //       <div
  //         css={css`
  //           width: 380px;
  //         `}
  //       >
  //         <button
  //           onClick={() => history.replace(ROUTES.HOME)}
  //           css={css`
  //             display: flex;
  //             flex-direction: row;
  //             justify-content: center;
  //             align-items: center;
  //             padding: 8px 24px;
  //             border: 2px solid #f2b400;
  //             border-radius: 4px;
  //             height: 48px;
  //             width: 100%;
  //             color: ${colors.WHITE};
  //           `}
  //         >
  //           BACK TO HOME
  //         </button>
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <div
      css={css`
        display: flex;
        height: 100vh;

        @media (max-width: 768px) {
          flex-direction: column;
          height: 100%;
        }
      `}
    >
      <div
        css={css`
          width: 50%;
          padding-top: 80px;
          padding-left: 106px;
          padding-right: 140px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @media (max-width: 768px) {
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
          }
        `}
      >
        <div
          onClick={() => history.replace(ROUTES.HOME)}
          css={css`
            display: flex;
            align-items: center;

            &:hover {
              cursor: pointer;
            }
          `}
        >
          <Icon name='home' color={colors.DEFAULT_FLAIR} fontSize={12} />
          <p
            css={css`
              font-size: 12px;
              font-weight: 500;
              margin-left: 12px;
              color: ${colors.DEFAULT_FLAIR};
            `}
          >
            GO HOME
          </p>
        </div>

        <div>
          {division?.billing[0].subtotal ? (
            <>
              {division?.organization_image?.full_path && (
                <img
                  src={division?.organization_image?.full_path}
                  alt={division?.organization_name + ' League Logo'}
                  css={css`
                    margin-top: 32px;
                    width: 160px;
                    height: auto;
                    max-height: 160px;
                  `}
                />
              )}
              <div
                css={css`
                  margin-top: 56px;
                `}
              >
                <p
                  css={css`
                    font-weight: 700;
                    font-size: 18px;
                  `}
                >
                  Paying{' '}
                  {division?.single_payment
                    ? ''
                    : division?.billing[0]?.payment_type}
                  {' to '}
                  {division?.organization_name
                    ? division?.organization_name
                    : 'SportNinja'}
                </p>
                <p
                  css={css`
                    font-weight: 700;
                    font-size: 40px;
                    margin-top: 14px;
                  `}
                >
                  {i18n.formatCurrency(division?.billing[0]?.subtotal, {
                    currency: division?.currency?.name,
                    form: 'short',
                  })}
                </p>
              </div>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  margin-top: 56px;
                `}
              >
                {division?.root_schedule_image?.full_path && (
                  <img
                    src={division?.root_schedule_image?.full_path}
                    css={css`
                      width: 50px;
                      height: auto;
                      margin-right: 16px;
                    `}
                  />
                )}
                <p
                  css={css`
                    font-size: 16px;
                    font-weight: 700;
                  `}
                >
                  {`${division?.root_schedule_name} - ${division?.name}`}
                </p>
              </div>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 56px;
                  padding-bottom: 8px;
                `}
              >
                <p
                  css={css`
                    font-size: 16px;
                    text-transform: capitalize;
                    text-align: right;
                    flex: 1;

                    @media (max-width: 768px) {
                      text-align: left;
                    }
                  `}
                >
                  {division?.billing.payment_type
                    ? division?.billing.payment_type
                    : 'Subtotal'}
                </p>
                <p
                  css={css`
                    font-size: 16px;
                    flex: 1;
                    text-align: right;
                  `}
                >
                  {i18n.formatCurrency(division?.billing[0]?.subtotal, {
                    currency: division?.currency?.name,
                    form: 'explicit',
                  })}
                </p>
              </div>
              {division?.taxes.map((tax) => (
                <div
                  key={tax?.uid}
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 8px;
                  `}
                >
                  <p
                    css={css`
                      font-size: 16px;
                      text-transform: capitalize;
                      text-align: right;
                      flex: 1;
                      align-items: center;

                      @media (max-width: 768px) {
                        text-align: left;
                      }
                    `}
                  >
                    {tax?.name} - {tax?.description} (
                    {Number(tax?.tax_rate).toFixed(1)}%)
                  </p>
                  <p
                    css={css`
                      font-size: 16px;
                      flex: 1;
                      text-align: right;
                    `}
                  >
                    {i18n.formatCurrency(
                      division?.billing[0]?.subtotal *
                        (Number(tax?.tax_rate) / 100),
                      {
                        currency: division?.currency?.name,
                        form: 'explicit',
                      }
                    )}
                  </p>
                </div>
              ))}

              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 8px;
                `}
              >
                <p
                  css={css`
                    font-size: 16px;
                    text-transform: capitalize;
                    text-align: right;
                    flex: 1;

                    @media (max-width: 768px) {
                      text-align: left;
                    }
                  `}
                >
                  {division?.billing.payment_type
                    ? division?.billing.payment_type
                    : 'Total Tax'}
                </p>
                <p
                  css={css`
                    font-size: 16px;
                    flex: 1;
                    text-align: right;
                  `}
                >
                  {i18n.formatCurrency(
                    division?.billing[0]?.subtotal * (totalTaxSum / 100),
                    {
                      currency: division?.currency?.name,
                      form: 'explicit',
                    }
                  )}
                </p>
              </div>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 16px;
                  padding-top: 16px;
                  border-top: 1px solid ${colors.SOFT_STEEL};
                `}
              >
                <p
                  css={css`
                    font-size: 16px;
                    font-weight: 700;
                    flex: 1;
                    text-align: right;

                    @media (max-width: 768px) {
                      text-align: left;
                    }
                  `}
                >
                  Total
                </p>
                <p
                  css={css`
                    font-size: 16px;
                    font-weight: 700;
                    flex: 1;
                    text-align: right;
                  `}
                >
                  {i18n.formatCurrency(
                    division?.billing[0]?.subtotal +
                      division?.billing[0]?.subtotal * (totalTaxSum / 100),
                    {
                      currency: division?.currency?.name,
                      form: 'explicit',
                    }
                  )}
                </p>
              </div>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </div>
        <div
          css={css`
            padding-bottom: 106px;
            display: flex;
            align-items: center;

            @media (max-width: 768px) {
              display: none;
            }
          `}
        >
          <p>Powered by</p>
          <img
            src={stripeLogo}
            css={css`
              margin-left: 6px;
            `}
          />
        </div>
      </div>

      <div
        css={css`
          height: 100%;
          width: 1px;
          background-color: ${colors.SOFT_STEEL};
        `}
      />

      <div
        css={css`
          width: 50%;
          padding-top: 80px;
          padding-left: 106px;
          padding-right: 140px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          @media (max-width: 768px) {
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 0px;
          }
        `}
      >
        <div
          css={css`
            margin-top: 32px;
            width: 100%;
          `}
        >
          {paymentStatus === PAYMENT_STATUS.SUCCESS ? (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              `}
            >
              <Icon name='check' color={colors.DEFAULT_FLAIR} fontSize={48} />
              <p
                css={css`
                  margin-top: 32px;
                  font-size: 24px;
                  font-weight: 700;
                `}
              >
                Payment Successful
              </p>
              <button
                onClick={() => history.replace(ROUTES.HOME)}
                css={css`
                  margin-top: 100px;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  padding: 8px 24px;
                  border: 2px solid #f2b400;
                  border-radius: 4px;
                  height: 48px;
                  width: 100%;
                  color: ${colors.WHITE};
                `}
              >
                GO BACK TO HOME
              </button>
            </div>
          ) : paymentStatus === PAYMENT_STATUS.FAILED ? (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              `}
            >
              <Icon name='times' color={colors.ERROR} fontSize={48} />
              <p
                css={css`
                  margin-top: 32px;
                  font-size: 24px;
                  font-weight: 700;
                `}
              >
                Payment Failed
              </p>
              <span
                css={css`
                  margin-top: 20px;
                `}
              >
                {errorMessage}
              </span>
              <button
                onClick={() => window.location.reload()}
                css={css`
                  margin-top: 100px;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  padding: 8px 24px;
                  border: 2px solid #f2b400;
                  border-radius: 4px;
                  height: 48px;
                  width: 100%;
                  color: ${colors.WHITE};
                `}
              >
                Try Again
              </button>
            </div>
          ) : (
            <>
              <div
                css={css`
                  visibility: ${loaded ? 'visible' : 'hidden'};
                `}
              >
                <form id='payment-form'>
                  <div id='card-element' />
                  <div id='payment-element' />
                  <button
                    disabled={paymentIsLoading}
                    id='submit'
                    css={css`
                      margin-top: 32px;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      padding: 8px 24px;
                      border: 2px solid #f2b400;
                      border-radius: 4px;
                      height: 48px;
                      width: 100%;
                      color: ${colors.WHITE};
                    `}
                  >
                    {paymentIsLoading ? <LoadingSpinner size={2} /> : 'PAY NOW'}
                  </button>
                </form>
              </div>
              <div
                css={css`
                  visibility: ${loaded ? 'hidden' : 'visible'};
                `}
              >
                <LoadingSpinner />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default RegistrationPayment
