import { all } from 'redux-saga/effects'

import org from './org'
import game from './game'
import team from './team'
import teams from './teams'
import schedule from './schedule'

import auth from './auth'
import user from './user'
import users from './users'
import fav from './fav'
import invite from './invite'
import types from './types'
import search from './search'
import scoring from './scoring'
import feed from './feed'
import notifications from './notifications'
import appError from './appError'
import shootout from './shootout'
import chat from './chat'

const rootSagaCreator = () =>
  function* rootSaga() {
    const sagas = [
      ...org,
      ...game,
      ...team,
      ...teams,
      ...schedule,

      ...auth,
      ...user,
      ...users,
      ...fav,
      ...invite,
      ...types,
      ...search,
      ...scoring,
      ...feed,
      ...notifications,
      ...appError,
      ...shootout,
      ...chat,
    ]

    yield all(sagas)
  }

export default rootSagaCreator
