import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toArray } from '@sportninja/common/utils/utils'
import { getPlayerTypesBySportId } from '@sportninja/common/selectors/types'
import { t } from '@sportninja/common/i18n'

import { SelectFormInput } from '../Select'

const PositionSelect = ({
  playerTypes,
  sportName = 'hockey',
  ...selectFormProps
}) => {
  const positions = playerTypes
  return (
    <SelectFormInput {...selectFormProps}>
      <option value='' disabled>
        {t('Web:selectAPosition')}:
      </option>
      <option disabled>-----</option>
      {positions &&
        positions.map((p) => {
          return (
            <option key={p.id} value={p.id}>
              {p.name_full}
            </option>
          )
        })}
    </SelectFormInput>
  )
}

const mapStateToProps = (state, { sportId }) => {
  return {
    playerTypes: getPlayerTypesBySportId(state, sportId),
  }
}

export default connect(mapStateToProps)(PositionSelect)
