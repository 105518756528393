/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'

import Icon from '../../components/Icon'
import { getAttributes } from './MergePlayersItem'
import { font } from '../../components/css'

const PlayerCard = ({
  className,
  main,
  player,
  leftArrow,
  rightArrow,
  title,
}) => {
  const attributeKeys = getAttributes()

  return (
    <div
      css={[
        css`
          display: flex;
          flex-direction: column;
          position: relative;
          width: 140px;
          min-height: 140px;
          ${rightArrow && `margin-right: 24px`};
          ${leftArrow && `margin-left: 24px`};
        `,
        className,
      ]}
    >
      <div
        css={css`
          ${font.title} text-align: center;
          font-weight: bold;
          height: 18px;
          margin-bottom: 2px;
        `}
      >
        {title}
      </div>
      <div
        css={css`
          border: ${main ? 2 : 1}px solid rgba(255, 255, 255, 0.7);
          border-radius: 2px;
          flex: 1;
        `}
      >
        <div
          css={css`
            font-weight: bold;
            padding: 8px;
            word-break: break-word;
          `}
        >
          {player.fullName}
        </div>
        {Array.isArray(player?.properties) && player.properties.length > 0 && (
          <div
            css={css`
              padding: 8px;
              border-top: 1px solid rgba(255, 255, 255, 0.7);
            `}
          >
            <div
              css={css`
                font-weight: bold;
                margin-bottom: 2px;
              `}
            >
              Keeping:
            </div>
            {player?.properties?.map((propertyName) => {
              return <div key={propertyName}>{attributeKeys[propertyName]}</div>
            })}
          </div>
        )}
      </div>
      {(rightArrow || leftArrow) && (
        <div
          css={css`
            position: absolute;
            /* Top needs to account for the title area */
            top: 18px;
            bottom: 0;
            ${rightArrow && `right: -20px`};
            ${leftArrow && `left: -18px`};
            display: flex;
            align-items: center;
          `}
        >
          <Icon name={rightArrow ? 'arrow-right' : 'arrow-left'} />
        </div>
      )}
    </div>
  )
}

export default PlayerCard
