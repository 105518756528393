/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useState } from 'react'

import { font } from '../css'
import Icon from '../Icon'
import { Flex } from '../Layout'
import ListCell from './Cell'
import ListRow from './Row'

const ListGroup = ({
  group,
  width,
  headers,
  options,
  sort,
  direction,
  bgColor,
  highlightSorted,
  getKey,
  localSort,
  isMobile,
  hideGroupName = false,
}) => {
  const [isOpen, toggleOpen] = useState(true)

  const sorted =
    typeof localSort === 'function'
      ? localSort(sort, direction, group.rows)
      : group.rows

  return (
    <div>
      {!hideGroupName ? (
        <Flex
          noFlex
          row
          alignItems='center'
          justifyContent='space-between'
          className='list-row'
          css={css`
            position: relative;
            height: 56px;
            background-color: transparent;
            width: ${width}px;
          `}
        >
          <ListCell
            isFirstCell
            minWidth={200}
            listCellCss={css`
              ${font.title}
              background-color: transparent !important;
              font-weight: 700;
              font-size: 20px !important;
              cursor: pointer;

              &:hover span {
                text-decoration: underline;
              }
            `}
            onClick={() => {
              toggleOpen((prev) => !prev)
            }}
            isMobile={isMobile}
          >
            <span>{group.name}</span>
            <Icon
              name={isOpen ? 'caret-down' : 'caret-up'}
              fontSize={14}
              css={css`
                margin-left: 8px;
              `}
            />
          </ListCell>
        </Flex>
      ) : null}
      {isOpen &&
        sorted.map((item) => {
          let key = item.id
          if (typeof getKey === 'function') {
            key = getKey(item)
          }
          return (
            <ListRow
              key={key}
              item={item}
              headers={headers}
              options={options}
              sort={sort}
              bgColor={bgColor}
              highlightSorted={highlightSorted}
              width={width}
              isMobile={isMobile}
            />
          )
        })}
    </div>
  )
}

export default ListGroup
