import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { RootStore } from '..'
import { PaymentService } from './service'

type Payment = {
  id: string
  card_type: string
  last_four: string
  exp_month: number
  exp_year: number
  type: string
  default: boolean
}

export class PaymentStore {
  rootStore: RootStore
  paymentList: Payment[] = []
  loading: boolean = false
  accountId: string = ''

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore

    makeObservable(this, {
      paymentList: observable,
      loading: observable,
      accountId: observable,

      fetchPaymentList: action,
      deletePaymentMethod: action,

      state: computed,
    })
  }

  setAccountId = (id: string) => {
    runInAction(() => {
      this.accountId = id
    })
  }

  fetchPaymentList = () => {
    if (!this.accountId) {
      const accountId = this.rootStore.me.state.me?.account_id

      if (accountId) {
        runInAction(() => {
          this.accountId = accountId
          this.fetchPaymentList()
        })
      } else {
        console.error('PMS: No account id')
      }
    }

    runInAction(() => {
      this.loading = true
    })

    PaymentService.fetchPaymentMethods(this.accountId)
      .then((response) => {
        runInAction(() => {
          this.paymentList = response
        })
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false
        })
      })
  }

  updateDefaultMethod = (paymentId: string) => {
    runInAction(() => {
      this.loading = true
    })

    PaymentService.updateDefaultCard(this.accountId, paymentId)
      .then(() => {
        runInAction(() => {
          this.fetchPaymentList()
        })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  deletePaymentMethod = (paymentId: string) => {
    runInAction(() => {
      this.loading = true
    })

    PaymentService.deleteCard(this.accountId, paymentId)
      .then(() => {
        runInAction(() => {
          this.fetchPaymentList()
        })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  createNewCard = (stripePaymentId: string) => {
    runInAction(() => {
      this.loading = true
    })

    PaymentService.createNewCard(this.accountId, stripePaymentId)
      .then(() => {
        runInAction(() => {
          this.fetchPaymentList()
        })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  get state() {
    return {
      paymentList: this.paymentList,
      loading: this.loading,
    }
  }
}
