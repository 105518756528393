/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { t } from '@sportninja/common/i18n'

import { font } from '../../components/css'
import { Flex } from '../../components/Layout'
import { Mobile } from '../../components/Responsive'

export const Title = ({ isSeeAllActive, text, onClick, numResults }) => {
  return (
    <Flex
      alignItems='center'
      justifyContent='space-between'
      css={css`
        min-height: 45px;
        ${font.title}
        font-weight: 700;
        font-size: 24px;
        line-height: 100%;
        letter-spacing: 0.015em;
        text-transform: uppercase;
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid ${colors.SOFT_STEEL};
      `}
    >
      {text}{' '}
      {isSeeAllActive && typeof numResults !== 'undefined' && `(${numResults})`}
      {typeof onClick === 'function' && (
        <button
          type='button'
          onClick={onClick}
          css={css`
            color: white;
            ${font.title}
            font-weight: bold;
            letter-spacing: 1.1px;
            font-size: 14px;
            padding: 4px 8px;
            border-radius: 4px;
            background-color: ${colors.SOFT_STEEL};

            transition: background-color 0.1s ease-in-out,
              color 0.1s ease-in-out;
            &:hover {
              background-color: white;
              color: black;
            }
          `}
        >
          {isSeeAllActive ? 'See all' : 'Back'}
        </button>
      )}
    </Flex>
  )
}

const SearchSection = ({
  results,
  onResultClick,
  onSeeAll,
  isSeeAllActive,
  RowComponent,
  titlei18nKey,
  wrapCss,
  isFullWidth = false,
  totalResults,
}) => {
  const count = results?.length || 0
  if (count === 0) return false

  return (
    <div
      css={css`
        flex: 1;
        flex-basis: 33%;
        margin-top: 40px;
        margin-left: 40px;

        &:nth-of-type(2) {
          flex-basis: 50%;
        }

        ${isSeeAllActive &&
        isFullWidth &&
        css`
          flex-basis: 100%;

          .results-container {
            overflow: hidden;
            height: 96px;
          }
        `}

        ${wrapCss}
      `}
    >
      <Title
        text={t(`common:${titlei18nKey}`, { count: 2 })}
        onClick={
          typeof onSeeAll === 'function'
            ? onSeeAll.bind(this, isSeeAllActive)
            : undefined
        }
        isSeeAllActive={isSeeAllActive}
        numResults={totalResults}
      />
      <Mobile>
        {(isMobile) => (
          <Flex
            className='results-container'
            column={!isFullWidth}
            flexWrap={isFullWidth}
          >
            {results.slice(0, isSeeAllActive ? 4 : count).map((item) => {
              return (
                <RowComponent
                  key={item.id}
                  item={item}
                  onClick={onResultClick}
                  isMobile={isMobile}
                />
              )
            })}
          </Flex>
        )}
      </Mobile>
    </div>
  )
}

export default SearchSection
