export const entityImageDefinition = {
  image: {
    create: ['id', 'imageData'],
    delete: ['id'],
  },
}

export const venuesDefinition = {
  venues: {
    create: ['id', 'form'],
    read: ['id', 'venueId'],
    update: ['id', 'venueId', 'form'],
    delete: ['id', 'venueId'],

    // DEPRECATED
    updatePrimary: [],
  },
}

export const usersDefinition = {
  users: {
    create: ['id', 'form'],
    read: ['id', 'page', 'sort', 'direction'],
    update: ['id', 'userId', 'form'],
    updatePrimary: [],
    delete: ['id', 'userId'],
  },
}

export const feedDefinition = {
  feed: {
    create: ['id', 'form'],
    read: ['id', 'page'],
    update: ['id', 'feedItemId', 'form'],
    delete: ['id', 'feedItemId'],

    image: {
      create: ['id', 'feedItemId', 'imageData'],
      delete: ['id', 'feedItemId', 'imageId'],
    },
  },
}

export const officialsDefinition = {
  officials: {
    create: ['id', 'form'],
    read: ['id', 'page', 'sort', 'direction'],
    update: ['id', 'officialId', 'form'],
    delete: ['id', 'officialId'],
  },
}
