/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import authActions from '@sportninja/common/actions/auth'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import colors from '@sportninja/common/constants/appColors'
import { t } from '@sportninja/common/i18n'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { Alert, Input } from 'antd'
import { useContext, useState } from 'react'
import { connect } from 'react-redux'
import { EventCard } from 'src/components/RegistrationEventCard'
import { EventProgress } from 'src/components/RegistrationEventProgress'
import { RegistrationContext } from '../RegistrationContext'
import {
  PageTitle,
  REGISTRATION_STEPS,
  alternateButtonStyle,
  buttonStyle,
  disabledButtonStyle,
  headingSixStyle,
  paragraphSmallStyle,
} from './Main'

const Login = ({ login }) => {
  const { setStep, registration, isLogged, email, setEmail } =
    useContext(RegistrationContext)
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  if (isLogged) {
    setStep(REGISTRATION_STEPS.TEAM_REGISTER)
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        background-color: ${colors.REGISTRATION_SECOND_BACKGROUND};
        width: 1080px;
        padding: 0 35px 35px 35px;
        border-radius: 16px 16px 0px 0px;
        @media (max-width: 768px) {
          min-width: 0;
          width: 100%;
        }
      `}
    >
      <EventCard eventInfo={registration} />
      <EventProgress eventProgress={1} />
      <PageTitle title='Login' />
      <div
        css={css`
          width: 100%;
          margin-bottom: 16px;
        `}
      >
        <p
          css={css`
            margin-bottom: 8px;
            color: ${colors.WHITE};
            ${paragraphSmallStyle}
          `}
        >
          Email *
        </p>
        <Input
          name='email'
          placeholder='Email'
          type='email'
          autoComplete='email'
          autoCorrect='off'
          autoCapitalize='none'
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div
        css={css`
          width: 100%;
          margin-bottom: 16px;
        `}
      >
        <p
          css={css`
            margin-bottom: 8px;
            color: ${colors.WHITE};
            ${paragraphSmallStyle}
          `}
        >
          Password *
        </p>
        <Input.Password
          name='password'
          placeholder='Password'
          type='password'
          autoComplete='password'
          autoCorrect='off'
          autoCapitalize='none'
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      {error ? (
        <div
          css={css`
            margin-bottom: 16px;
          `}
        >
          <Alert message={error} type='error' showIcon />
        </div>
      ) : null}
      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 8px;
          width: 100%;
        `}
      >
        <button
          onClick={() => {
            setStep(REGISTRATION_STEPS.SIGN_UP)
          }}
          css={css`
            ${headingSixStyle}
            ${alternateButtonStyle}
          `}
        >
          BACK
        </button>
        <button
          onClick={async () => {
            try {
              setLoading(true)
              await login({ email, password })
            } catch (e) {
              if (e?.status === 401) {
                setError(t('errors:invalidLogin'))
                return
              }
              const erroMessage = getErrorMessage(e)
              setError(erroMessage)
            } finally {
              setLoading(false)
            }
          }}
          disabled={email === '' || password === ''}
          css={css`
            ${headingSixStyle}
            ${email === '' || password === ''
              ? disabledButtonStyle
              : buttonStyle}
          `}
        >
          NEXT
        </button>
      </div>
      <div
        css={css`
          display: flex;
          flex: 1;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 16px;
        `}
      >
        <img
          src={'/images/powered_by_sportninja.png'}
          css={css`
            height: 50px;
            max-width: 300px;
          `}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: bindActionToPromise(dispatch, authActions.login.request),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
