/* eslint-disable react-native/no-inline-styles */
/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { Alert, Badge, Space, Spin, Table, TableProps, Tooltip } from 'antd'
import React, { useEffect } from 'react'
import {
  captionLarge,
  heading5,
  paragraphSmall,
  paragraphXSmall,
  weightRegular,
  weightSemiBold,
} from 'src/components/css'
import { TextInput } from 'src/components/ts-components/TextInput'
import banners from '../../Form/banner-helpers'
import { Separator } from '../GeneralForm'
import { useScheduleContext } from '../ScheduleContext'
import '../styles.scss'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { SelectInput } from 'src/components/ts-components/SelectInput'
import { SyncOutlined } from '@ant-design/icons'
import { DaysmartSyncIntervalEnum } from '@sportninja/common/constants/DaysmartSyncInterval'
import req from '@sportninja/common/api/request'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { CheckBoxInput } from 'src/components/ts-components/CheckBoxInput'

type DaysmartFormProps = {
  onCancelPressed: () => void
  triggerRefresh: () => void
}

interface DataType {
  id: string
  title: string
  details: string
  is_success: boolean
  created_at: string
}

const columns: TableProps<DataType>['columns'] = [
  {
    title: 'Message',
    dataIndex: 'title',
    key: 'title',
    render: (title: string, record: DataType) => {
      return (
        <Tooltip title={record?.details} mouseEnterDelay={1}>
          <p>{title}</p>
        </Tooltip>
      )
    },
  },
  {
    title: 'Status',
    dataIndex: 'is_success',
    key: 'is_success',
    render: (isSuccess: boolean) => {
      return isSuccess ? (
        <Badge status='success' text='Success' />
      ) : (
        <Badge status='error' text='Failed' />
      )
    },
  },
  {
    title: 'Date/Time',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (date: string) => {
      return new Date(date).toLocaleString()
    },
  },
]

const DaysmartForm: React.FC<DaysmartFormProps> = ({
  onCancelPressed,
  triggerRefresh,
}) => {
  const {
    id,
    daySmartSeasonId,
    sportId,
    genericSportId,
    isEdit,
    fieldErrors,
    customError,
    error,
    setError,
    isLoading,
    onSubmit,
    onCancel,
    daySmartSeasonName,
    daySmartSyncInterval,
    setDaySmartSyncInterval,
    daySmartSendInvitation,
    setDaySmartSendInvitation,
  } = useScheduleContext()

  const [isSyncing, setIsSyncing] = React.useState(false)
  const [isLoadingDaysmartLogs, setIsLoadingDaysmartLogs] =
    React.useState(false)
  const [daysmartLogs, setDaysmartLogs] = React.useState<DataType[]>([])

  const fetchDaysmartLogs = async () => {
    try {
      setIsLoadingDaysmartLogs(true)
      const { data } = await req(`/schedules/${id}/daysmart/log`)
      setDaysmartLogs(data)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setError(errorMessage)
    } finally {
      setIsLoadingDaysmartLogs(false)
    }
  }

  const syncNow = async () => {
    try {
      setIsSyncing(true)
      await req(`/schedules/${id}/daysmart/import`, {
        method: 'POST',
        body: JSON.stringify({
          season_id: daySmartSeasonId,
          sport_id: sportId,
          generic_sport_id: genericSportId,
        }),
      })
      await fetchDaysmartLogs()
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setError(errorMessage)
    } finally {
      setIsSyncing(false)
    }
  }

  useEffect(() => {
    fetchDaysmartLogs()
  }, [])

  return (
    <div
      css={css`
        padding-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        max-height: 550px;
        gap: 16px;
      `}
    >
      <style>
        {`
          .ant-tooltip {
            z-index: 20000 !important;
          }
          .ant-tooltip-inner {
            z-index: 20000 !important;
          }
        `}
      </style>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 16px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <p
              css={css`
                ${heading5}
                ${weightSemiBold}
                color: ${colors.WHITE};
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
              `}
            >
              Daysmart Sync Service
            </p>
            <p
              css={css`
                ${paragraphSmall}
                ${weightRegular}
                color: ${colors.SECONDARY_75};
              `}
            >
              The Daysmart Sync Service allows you to sync your competition with
              a pre-determined frequency.
            </p>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <TextInput
              name={'daysmartSeason'}
              value={daySmartSeasonName}
              setValue={(value) => console.log('Daysmart Season value:', value)}
              fieldErrors={fieldErrors}
              customError={customError}
              label='Daysmart Season'
              type='text'
              disabled={true}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
              align-self: stretch;
            `}
          >
            <SelectInput
              label={'Automatic Sync:'}
              options={[
                {
                  label: 'None',
                  value: DaysmartSyncIntervalEnum.NONE,
                },
                {
                  label: 'Every 24 hours',
                  value: DaysmartSyncIntervalEnum.TWENTY_FOUR_HOURS,
                },
                {
                  label: 'Every 48 hours',
                  value: DaysmartSyncIntervalEnum.FOURTY_EIGHT_HOURS,
                },
                {
                  label: 'Every 72 hours',
                  value: DaysmartSyncIntervalEnum.SEVENTY_TWO_HOURS,
                },
              ]}
              name={'daySmartSyncInterval'}
              value={daySmartSyncInterval}
              showSearch={false}
              onChange={(value) => {
                setDaySmartSyncInterval(value as DaysmartSyncIntervalEnum)
              }}
              fieldErrors={fieldErrors}
              customError={customError}
              disabled={false}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 8px;
              width: 100%;
              align-items: center;
              justify-content: start;
            `}
          >
            <CheckBoxInput
              customError={customError}
              fieldErrors={fieldErrors}
              disabled={false}
              label='Send Invitations to Players/Team Officials during Sync'
              name='send_invitations'
              onChange={(value) =>
                setDaySmartSendInvitation(value.target.checked)
              }
              value={daySmartSendInvitation}
              checkBoxStyle={css`
                width: unset;
              `}
            />
          </div>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              align-self: stretch;
            `}
          >
            {isSyncing ? (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  cursor: pointer;
                `}
                onClick={() => {
                  setIsSyncing(true)
                  setTimeout(() => {
                    setIsSyncing(false)
                  }, 2000)
                }}
              >
                <Spin
                  size='small'
                  style={{
                    color: colors.PRIMARY_300,
                  }}
                />
              </div>
            ) : (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  cursor: pointer;
                `}
                onClick={async () => {
                  await syncNow()
                }}
              >
                <SyncOutlined
                  style={{
                    fontSize: '16px',
                    color: colors.PRIMARY_300,
                  }}
                />
                <span
                  css={css`
                    ${paragraphXSmall}
                    ${weightRegular}
                  color: ${colors.PRIMARY_300};
                  `}
                >
                  Sync Now
                </span>
              </div>
            )}
          </div>
          <Table
            size='small'
            bordered={true}
            columns={columns}
            dataSource={daysmartLogs}
            style={{
              alignSelf: 'stretch',
            }}
            loading={isLoadingDaysmartLogs}
          />
        </div>
        <Separator />
      </div>
      <div
        css={css`
          position: sticky;
          bottom: 0;
          background-color: ${colors.HEADER};
          z-index: 100;
          padding-bottom: 16px;
          padding-top: 16px;
        `}
      >
        {error ? (
          <Space
            direction='vertical'
            style={{ width: '100%', marginBottom: 8 }}
          >
            <Alert
              message={error || 'An error occurred. Please try again.'}
              type='error'
              showIcon
              closable
            />
          </Space>
        ) : null}
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 16px;
            align-self: stretch;
          `}
        >
          {/* Cancel Button */}
          <div
            css={css`
              max-width: 80px;
              display: flex;
              padding: 8px 24px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              border-radius: 8px;
              border: 1px solid var(--Neutrals-neutrals-0, #fff);
              cursor: ${isLoading || isSyncing ? 'not-allowed' : 'pointer'};
              opacity: ${isLoading || isSyncing ? 0.7 : 1};
            `}
            onClick={() => {
              if (isLoading || isSyncing) {
                return
              }
              // Clear the state
              onCancel()
              //   and close the modal
              onCancelPressed()
            }}
          >
            <p
              css={css`
                ${captionLarge}
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                color: ${colors.WHITE};
              `}
            >
              Cancel
            </p>
          </div>
          <div
            css={css`
              max-width: 80px;
              display: flex;
              padding: 8px 24px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              border-radius: 8px;
              background: ${colors.PRIMARY_300};
              cursor: ${isLoading || isSyncing ? 'not-allowed' : 'pointer'};
              opacity: ${isLoading || isSyncing ? 0.7 : 1};
            `}
            onClick={async () => {
              try {
                if (isLoading || isSyncing) {
                  return
                }
                const response = await onSubmit()
                if (isEdit) {
                  // @ts-ignore
                  banners.entityUpdate(ENTITY_TYPES.schedule)(response)
                } else {
                  banners.entityCreate(ENTITY_TYPES.schedule)(response)
                }
                triggerRefresh()
                onCancelPressed()
                onCancel()
              } catch (e: any) {
                const errorMessage = getErrorMessage(e)
                if (e?.invalid_fields) {
                  if (
                    e?.invalid_fields?.name ||
                    e?.invalid_fields?.abbreviation
                  ) {
                    setError(errorMessage)
                  }
                }
              }
            }}
          >
            <>
              {isLoading ? (
                <Spin
                  size='small'
                  css={css`
                    color: ${colors.WHITE};
                  `}
                />
              ) : (
                <p
                  css={css`
                    ${captionLarge}
                    font-family: ${isCanlan
                      ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                      : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                    color: ${colors.WHITE};
                  `}
                >
                  Save
                </p>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DaysmartForm
