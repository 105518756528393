/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import req from '@sportninja/common/api/request'
import { ROUTES } from '@sportninja/common/constants/app'
import { t } from '@sportninja/common/i18n'
import parsers from '@sportninja/common/reducers/parsers'

import { font } from '../../components/css'
import { scheduleCloneForm } from '../../components/Form/form-configs'
import FormSheet from '../../components/Form/FormSheet'
import TextModal from '../../components/Modal/TextModal'
import BaseActionButton from '../../components/Page/BaseActionButton'
import { Flex } from '../../components/Layout'
import SuspensionSubmitButton from '../../components/Suspensions/SuspensionSubmitButton'
import { playoffsIds } from '@sportninja/common/constants/playoffTypes'

const SECONDS_BEFORE_REDIRECT = 10
const SECONDS_BEFORE_REDIRECT_MS = SECONDS_BEFORE_REDIRECT * 1000
// Open an info modal, that displays info about the new cloned competition, but
// also informs the user they will be redirected to that competition in 5 seconds
// Also, give them an option to dismiss the modal and stay where they are
const CloneSchedule = ({
  Button,
  schedule,
  scheduleId,
  onToggle = () => {},
  shouldEnablePlayoffs,
}) => {
  const history = useHistory()

  const [isOpen, setIsOpen] = useState(false)
  const timeout = useRef(null)
  const interval = useRef(null)
  const [countdown, setCountdown] = useState(SECONDS_BEFORE_REDIRECT)
  const [clonedSchedule, setClonedSchedule] = useState({})

  useEffect(() => {
    if (!isOpen) {
      clearTimeout(timeout.current)
      clearInterval(interval.current)
      setClonedSchedule({})
      setCountdown(SECONDS_BEFORE_REDIRECT)
    }
  }, [isOpen])

  const goToSchedule = (comp) => {
    // If the new competition is a tournament, send user to game slots instead
    history.push(
      `${ROUTES.SCHEDULE_ROOT}/${comp.id}/${
        comp.is_tournament ? 'gameslots' : 'setup'
      }`
    )
  }


/**
 * Transforms a given bracket template id string to its corresponding id value from an object of ids.
 *
 * @param {string} bracketTemplateAsString - The bracket template id string to transform.
 * @param {Object.<string, *>} ids - The object of ids to search for a matching id value.
 * @returns {number|null} - The corresponding id value if the id was found, otherwise returns null.
 */
const transformBracketTemplateId = (bracketTemplateAsString, ids) => {
  if (!bracketTemplateAsString || !ids) {
    return null
  }
  if (typeof bracketTemplateAsString !== 'string') {
    return null
  }
  if (typeof ids !== 'object') {
    return null
  }
  if (!ids[bracketTemplateAsString]) {
    return null
  }
  return ids[bracketTemplateAsString]
}

  return (
    <>
      <FormSheet
        key='clone'
        icon='clone'
        form={scheduleCloneForm(parsers.schedules(schedule), shouldEnablePlayoffs)}
        label={t('Web:cloneCompetition')}
        onSubmit={async ({ game_cutoff, ...form }) => {
          if (game_cutoff) form.game_cutoff = parseInt(game_cutoff, 10)
          form.name = form.name_full
          if (form?.is_tournament) {
            form.bracket_template_id = transformBracketTemplateId(form.bracket_template_id, playoffsIds)
            if (form.bracket_template_id === null) {
              delete form.bracket_template_id
            }
          } else {
            delete form.bracket_template_id
          }
          const body = JSON.stringify(form)
          const response = await req(`/schedules/${scheduleId}/clone`, {
            method: 'POST',
            body,
          })
          setClonedSchedule(response.data)
          setIsOpen(true)

          timeout.current = setTimeout(() => {
            goToSchedule(response.data)
          }, SECONDS_BEFORE_REDIRECT_MS)
          interval.current = setInterval(() => {
            setCountdown((s) => {
              return s === 0 ? 0 : --s
            })
          }, 1000)
          return { data: {} }
        }}
        title={t('Web:cloneCompetition')}
        Button={
          Button
            ? ({ onClick }) => <Button onClick={onClick} onToggle={onToggle} />
            : ({ onClick }) => (
                <BaseActionButton
                  title={t('Web:cloneCompetition')}
                  name='clone'
                  onClick={onClick}
                />
              )
        }
      />
      <TextModal
        unmountOnClose
        hideClose
        isOpen={isOpen}
        title={`${t('common:competition')} Cloned Successfully`}
      >
        <div>
          <div
            css={css`
              border: 1px solid rgba(255, 255, 255, 0.1);
              border-radius: 4px;
              padding: 12px;
              margin-bottom: 24px;
            `}
          >
            <div
              css={css`
                ${font.title}
                font-weight: 700;
                font-size: 20px;
              `}
            >
              {clonedSchedule?.name_full}
            </div>
            <div
              css={css`
                opacity: 0.7;
                margin-top: 4px;
              `}
            >
              {dayjs(clonedSchedule?.starts_at).format('MMM D, YYYY')} -{' '}
              {dayjs(clonedSchedule?.ends_at).format('MMM D, YYYY')}
            </div>
          </div>
          <p>
            Redirecting to your new {t('common:competition')} in {countdown}{' '}
            seconds...
          </p>
          <Flex alignItems='center'>
            <SuspensionSubmitButton
              isSubdued
              onClick={() => {
                setIsOpen(false)
              }}
            >
              Dismiss
            </SuspensionSubmitButton>
            <SuspensionSubmitButton
              onClick={() => {
                clearTimeout(timeout.current)
                setIsOpen(false)
                goToSchedule(clonedSchedule)
              }}
              css={css`
                margin-left: 16px;
              `}
            >
              Go Now
            </SuspensionSubmitButton>
          </Flex>
        </div>
      </TextModal>
    </>
  )
}

export default CloneSchedule
