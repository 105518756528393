import { bracketType } from '../constants/playoffTypes'

// This file contains all the utility functions used for the stats page

/**
This function takes a response from the API and aggregates the stats and schedules for each team.
It then returns an array of objects, each object containing information about one team
including the team name, the pool they are in, their stats, and their schedule.
@param {object} response - API response containing data, an array of objects representing team and pool statistics
@returns {Array} Returns an array of objects representing the aggregated statistics for each team and pool
 */
export const translateStatsResponse = (response) => {
  if (!response?.data) {
    return []
  }
  const aggregatedStats = []
  response.data.forEach((teamPerPool) => {
    if (!teamPerPool.team || !teamPerPool.team.id) {
      return
    }
    if (!teamPerPool.pool || !teamPerPool.pool.id) {
      return
    }
    if (!teamPerPool.stats) {
      return
    }
    const existingStat = aggregatedStats.find((stat) => {
      return (
        stat.team.id === teamPerPool.team.id &&
        stat.pool.id === teamPerPool.pool.id
      )
    })
    if (existingStat) {
      existingStat.stats.push(teamPerPool.stats)
    } else {
      const { team, stats, pool, schedule } = teamPerPool
      aggregatedStats.push({
        team,
        stats: [stats],
        pool,
        schedule,
      })
    }
  })
  return aggregatedStats
}

/**
 * Finds the value of a specific stat in an array of stats.
 * @param {Array<Object>} stats - An array of stat objects.
 * @param {string} statName - The name of the stat to find.
 * @returns {number} The value of the stat, or 0 if it cannot be found.
 */
export const findStats = (stats, statName) => {
  try {
    const stat = stats?.find((s) => s?.name?.toLowerCase() === statName)
    return stat?.value ?? 0
  } catch (error) {
    return 0
  }
}
/**
Separates upper and lower pools of each round in the input rounds array based on bracket position.
@function
@param {Array} rounds - An array of round objects.
@returns {Array} - An array of round objects with upperPools and lowerPools properties.
 */
export const separateUpperAndLowerPools = (rounds = []) => {
  return rounds.map((round) => {
    const upperPools = round.pools.filter(
      (pool) => pool.bracket_position === bracketType.UPPER
    )
    const lowerPools = round.pools.filter(
      (pool) => pool.bracket_position === bracketType.LOWER
    )
    const upperPoolsExist = upperPools.length > 0
    const lowerPoolsExist = lowerPools.length > 0
    if (upperPoolsExist) {
      round.upperPools = upperPools
    } else {
      round.upperPools = []
    }
    if (lowerPoolsExist) {
      round.lowerPools = lowerPools
    } else {
      round.lowerPools = []
    }
    return round
  })
}
