/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled/macro'
import css from '@emotion/css/macro'
import { t } from '@sportninja/common/i18n'

import { Flex } from '../../../components/Layout'
import { flairColor, font } from '../../../components/css'
import FormSheet from '../../../components/Form/FormSheet'
import {
  scoringGoalsForm,
  scoringShotForm,
} from '../../../components/Form/form-configs'
import { SidewayText } from '../GamePageHeaderMatchupCss'
import { Pencil } from './TimelineEvent'

const Wrap = styled(Flex)`
  margin-top: ${(props) => !props.isFinal && '28px'};
`

const Number = styled(Flex)`
  min-width: 141px;
  min-height: 76px;
  text-align: center;
  ${font.title}
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 2.07px;
  padding: 12px;
  cursor: ${(props) => props.isEditMode && 'pointer'};

  span {
    opacity: 0.5;
  }

  transition: background-color 0.2s ease-in;

  ${(props) =>
    props.isEditMode &&
    css`
      background-color: rgba(255, 255, 255, 0.05);
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);

        .pencil-icon {
          background-color: rgba(255, 255, 255, 0.08);
        }
      }
    `}
`

const TimelineGoals = ({
  className,
  homeShots,
  visitingShots,
  homeGoals,
  visitingGoals,
  isFinal,
  isEditMode,
  periodName,
  onSubmitHome,
  onSubmitVisiting,
}) => {
  const HomeNumber = ({ onClick }) => (
    <Number
      justifyContent='center'
      alignItems='center'
      noFlex
      isEditMode={isEditMode && !isFinal}
      onClick={!isEditMode ? null : onClick}
    >
      {isEditMode && <SidewayText>Home</SidewayText>}
      {isEditMode && !isFinal && (
        <Pencil
          css={css`
            display: flex;
          `}
        />
      )}
      <span
        css={css`
          margin-left: 12px;
        `}
      >
        {homeShots}
      </span>
    </Number>
  )

  const VisitingNumber = ({ onClick }) => (
    <Number
      justifyContent='center'
      alignItems='center'
      noFlex
      isEditMode={isEditMode && !isFinal}
      onClick={!isEditMode ? null : onClick}
    >
      <span
        css={css`
          margin-right: 12px;
        `}
      >
        {visitingShots}
      </span>
      {isEditMode && !isFinal && (
        <Pencil
          css={css`
            display: flex;
          `}
        />
      )}
      {isEditMode && <SidewayText right>Visiting</SidewayText>}
    </Number>
  )

  return (
    <Wrap
      fullWidth
      row
      alignItems='center'
      justifyContent='center'
      isFinal={isFinal}
      css={className}
    >
      {!isFinal ? (
        <FormSheet
          form={scoringGoalsForm({ amount: homeShots, min: 0 })}
          label='Edit Home Goals'
          onSubmit={onSubmitHome}
          title={`Edit Home Goals - ${periodName}`}
          Button={({ onClick }) => <HomeNumber onClick={onClick} />}
        />
      ) : (
        <HomeNumber />
      )}

      <div
        css={css`
          margin-top: 3px;
          font-size: 16px;
          letter-spacing: 0.92px;
          text-align: center;
          text-transform: uppercase;
          min-width: 108px;
        `}
      >
        {'Goals'}
        {isFinal && (
          <div
            className='timeline-shots-final-text'
            css={css`
              margin-top: 6px;
              color: ${flairColor};
            `}
          >
            {t('util:capitalize', { text: t('common:final') })}
          </div>
        )}
      </div>
      {!isFinal ? (
        <FormSheet
          form={scoringGoalsForm({ amount: visitingShots, min: 0 })}
          label='Edit Visiting Goals'
          onSubmit={onSubmitVisiting}
          title={`Edit Visiting Goals - ${periodName}`}
          Button={({ onClick }) => <VisitingNumber onClick={onClick} />}
        />
      ) : (
        <VisitingNumber />
      )}
    </Wrap>
  )
}

export default TimelineGoals
