/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'

import { Flex } from '../Layout'

const ImportCSVContainer = ({ children, hasImportErrors }) => {
  return (
    <Flex
      column
      noFlex
      css={css`
        margin: 40px 0;
        padding: 16px;

        width: ${hasImportErrors ? 860 : 596}px;

        background: linear-gradient(180deg, #282e38 0%, #181a1d 100%);
        border-radius: 8px;

        ${hasImportErrors &&
        css`
          max-height: calc(100vh - 80px);
        `}
      `}
    >
      {children}
    </Flex>
  )
}

export default ImportCSVContainer
