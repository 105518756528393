import { createSelector } from 'reselect'

import { getOrgs, getSchedules, getTeams, getAuth, getGames } from './base'
import { createChildListSelector } from './utils'

export const getOrgById = (state, id) => state.org[id] || {}
export const makeGetOrg = () => createSelector(getOrgById, (org) => org || {})

export const makeGetRelatedOrgs = () => createChildListSelector(
  getOrgs, getAuth, 'relatedOrgs'
)

export const makeGetOrgChildren = () => createChildListSelector(
  getOrgs, getOrgById, 'children'
)

export const makeGetOrgTeams = () => createChildListSelector(
  getTeams, getOrgById, 'teams', undefined, (_, org) => ({ parentName: org.fullName })
)

export const makeGetOrgSchedules = () => createChildListSelector(
  getSchedules, getOrgById, 'schedules', undefined, (_, org) => ({ parentName: org.fullName })
)

export const makeGetOrgGames = () => {
  return createSelector(
    [ getGames, getTeams, getSchedules, getOrgById ],
    (games, teams, schedules, org) => {
      if (!Array.isArray(org.games)) return false
      return org.games.reduce((collector, gameId) => {
        const game = games[gameId]
        if (!game) return collector

        collector.push({
          ...game,
          schedule: schedules[game.scheduleId],
          homeTeam: teams[game.homeTeamId],
          visitingTeam: teams[game.visitingTeamId]
        })

        return collector
      }, [])
    }
  )
}
