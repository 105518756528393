import { generateActions } from './utils'

const typeDefinition = {
  countries: [],
  provinces: ['countryId'],
  timezones: ['countryId'],

  sports: [],
  typesBySport: ['sportId'],
  allTypes: [],
}

export const typeActions = generateActions(['TYPE'], typeDefinition)

export default typeActions
