/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { status } from '@sportninja/common/constants/attendanceStatus'
import { t } from '@sportninja/common/i18n'
import {
  getPlayerTypesBySportId,
  getTeamOfficialTypesBySportId,
} from '@sportninja/common/selectors/types'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Icon from 'src/components/Icon'
import { Container } from '../../components/Layout'
import List from '../../components/List'
import PersonCell from '../../components/List/PersonCell'
import { playerSort } from '../../components/List/PlayerList'
import { Alert, Space } from 'antd'

const getAttendanceString = (statusNumber) => {
  switch (statusNumber) {
    case status.ATTENDING:
      return 'Attending'
    case status.MAYBE:
      return 'Maybe Attending'
    case status.NOT_ATTENDING:
      return 'Not Attending'
    default:
      return ''
  }
}

const GameRoster = ({
  isPublicRoute,
  gameId,
  isGameStarted,
  rosterId,
  isPlaceholder = false,
  isEmptyRoster = false,
  // Redux
  isInFrame,
  playerTypes,
  teamOfficialTypes,
}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [players, setPlayers] = useState([])
  const [officials, setOfficials] = useState([])

  const [sort, setSort] = useState('name_last')
  const [direction, setDirection] = useState('asc')

  useEffect(() => {
    const read = async () => {
      setLoading(true)
      try {
        const response = await req(
          `${
            isPublicRoute ? '/public' : ''
          }/games/${gameId}/rosters/${rosterId}`
        )
        setPlayers(response.data.players)
        setOfficials(response.data.team_officials)
      } catch (e) {
        console.error(e)
        setError(e?.message)
      } finally {
        setLoading(false)
      }
    }

    read()
  }, [isPublicRoute, gameId, rosterId])

  const regulars = [],
    affiliates = []

  players?.forEach((_player) => {
    const player = {
      ..._player,
      isGameStarted,
    }
    if (player.is_affiliate) {
      affiliates.push(player)
    } else {
      regulars.push(player)
    }
  })

  const sorter = playerSort(sort, direction)

  const headers = [
    {
      text: t('common:name'),
      sizePercent: 34,
      sort: 'name_last',
      Cell: ({ item }) => {
        let to
        if (item?.id) {
          to = `${ROUTES.PLAYER_ROOT}/${item.id}`
        }
        return (
          <PersonCell
            className='player-list-player'
            item={item}
            subTitle={item?.customer_identifier}
            as={to ? Link : undefined}
            to={to}
          />
        )
      },
    },
    {
      text: '#',
      sizePercent: 6,
      sort: 'player_number',
      accessor: 'player_number',
      minWidth: 50,
    },
    {
      text: t('common:position'),
      sizePercent: 16,
      sort: 'player_type.id',
      minWidth: 90,
      accessor: (item) => {
        return (
          playerTypes?.find((t) => t.id === parseInt(item?.player_type?.id, 10))
            ?.name_full || '-'
        )
      },
    },
    !isInFrame && {
      text: 'DOB',
      sizePercent: 17,
      minWidth: 100,
      accessor: (item) => {
        return item?.birth_date
          ? dayjs.utc(item.birth_date).format('YYYY-MM-DD')
          : ''
      },
    },
    {
      text: 'Status',
      sizePercent: 17,
      minWidth: 120,
      accessor: (item) => {
        if (item?.is_suspended && item?.suspension) {
          const suspension = item?.suspension
          const startDate = suspension?.start_date
            ? dayjs.utc(suspension.start_date).format('MMM D')
            : ''
          const endDate = suspension?.end_date
            ? dayjs.utc(suspension.end_date).format('MMM D')
            : 'Indefinitely'

          return (
            <div>
              <div
                style={{
                  textTransform: 'uppercase',
                  color: colors.FIERY_RED,
                  fontSize: 14,
                }}
              >
                Suspended
              </div>
              <div
                css={{
                  marginTop: 2,
                  fontSize: 13,
                }}
              >
                {startDate} - {endDate}
              </div>
            </div>
          )
        }

        if (item.is_injured) {
          return <div>{item.is_injured && <div>Injured</div>}</div>
        }

        if (isGameStarted) {
          return (
            <div>
              {item.is_starting
                ? 'Starting'
                : item.is_playing
                ? 'Playing'
                : 'Away'}
            </div>
          )
        }

        const attendanceString = getAttendanceString(item?.attendance_status)
        return (
          <div>{attendanceString?.length > 0 ? attendanceString : '-'}</div>
        )
      },
    },
  ].filter((o) => typeof o !== 'undefined' && typeof o !== 'boolean')

  if (isPlaceholder) {
    return (
      <Container
        noWrap
        column
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100vw;
          margin: 0 8px;
          .list-container {
            flex: 1;
          }

          .list-inner {
            margin-left: 0;
            margin-right: 0;
          }
        `}
      >
        <Alert
          message='Informational Notes'
          description='Placeholder Teams do not have Rosters. They will be populated once the playoff schedule is set. Please check back later for updates.'
          type='info'
          showIcon
        />
      </Container>
    )
  }

  if (isEmptyRoster) {
    return (
      <Container
        noWrap
        column
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100vw;
          margin: 0 8px;
          .list-container {
            flex: 1;
          }

          .list-inner {
            margin-left: 0;
            margin-right: 0;
          }
        `}
      >
        <Alert
          message='Informational Notes'
          description='Looks like this team has no players or officials rostered for this game. Please contact your league administrator for more information.'
          type='warning'
          showIcon
        />
      </Container>
    )
  }

  return (
    <Container
      noWrap
      column
      css={css`
        min-height: 100vh;
        width: 100vw;
        margin: 0 8px 24px 8px;
        .list-container {
          flex: 1;
        }

        .list-inner {
          margin-left: 0;
          margin-right: 0;
        }
      `}
    >
      {error && !loading ? (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            gap: 16px;
          `}
        >
          <Icon
            name='exclamation-circle'
            css={css`
              height: 40px;
              width: 40px;
            `}
            color={colors.FIERY_RED}
            fontSize={32}
          />
          <p
            css={css`
              color: ${colors.WHITE};
              font-size: 16px;
              margin-top: 10px;
              text-align: center;
              text-decoration: underline;
            `}
          >
            {error}
          </p>
        </div>
      ) : (
        <>
          <p
            className='player-list-affiliates-title'
            css={css`
              font-weight: 700;
              color: ${isCanlan ? colors.CYAN_BLUE : colors.AMERICAN_YELLOW};
              text-transform: uppercase;
            `}
          >
            Players
          </p>

          <List
            key='players'
            headers={headers}
            listProps={{
              loading,
              items: regulars.sort(sorter),
              sort,
              direction,
              setSort: (s) => {
                setSort(s)
              },
              setDirection: (d) => {
                setDirection(d)
              },
            }}
            noItemsText={t('common:player', { count: 2 })}
          />

          {affiliates.length > 0 && (
            <>
              <p
                className='player-list-affiliates-title'
                css={css`
                  margin-top: 24px;
                  font-weight: 700;
                  color: ${isCanlan
                    ? colors.CYAN_BLUE
                    : colors.AMERICAN_YELLOW};
                  text-transform: uppercase;
                `}
              >
                Affiliates
              </p>
              <List
                key='affiliates'
                hideHeaders
                headers={headers}
                listProps={{
                  items: affiliates.sort(sorter),
                  sort,
                  direction,
                }}
                noItemsText={t('common:affiliate', { count: 2 })}
              />
            </>
          )}
          {officials.length > 0 && (
            <>
              <div
                css={css`
                  margin-top: 26px;
                  margin-bottom: 10px;
                  border-top: 1px solid rgba(255, 255, 255, 0.1);
                `}
              />
              <p
                className='player-list-affiliates-title'
                css={css`
                  margin-top: 24px;
                  font-weight: 700;
                  color: ${isCanlan
                    ? colors.CYAN_BLUE
                    : colors.AMERICAN_YELLOW};
                  text-transform: uppercase;
                `}
              >
                Team Officials
              </p>
              <List
                key='officials'
                headers={[
                  {
                    text: t('common:name'),
                    sizePercent: 20,
                    Cell: PersonCell,
                  },
                  {
                    text: t('common:role'),
                    sizePercent: 20,
                    minWidth: 90,
                    accessor: (item) => {
                      return teamOfficialTypes.find(
                        (t) => t.id === item.team_official_type
                      )?.name_full
                    },
                  },
                  {
                    text: 'Attending',
                    sizePercent: 27,
                    minWidth: 108,
                    accessor: (item) => {
                      return item?.is_attending ? 'Attending' : 'Not Attending'
                    },
                  },
                ]}
                listProps={{
                  items: officials.sort(sorter),
                  sort,
                  direction,
                }}
                noItemsText={t('common:official', { count: 2 })}
              />
            </>
          )}
        </>
      )}
    </Container>
  )
}

const mapStateToProps = (state, { sportId }) => {
  return {
    isInFrame: state.auth.inFrame,
    playerTypes: getPlayerTypesBySportId(state, sportId),
    teamOfficialTypes: getTeamOfficialTypesBySportId(state, sportId),
  }
}

export default connect(mapStateToProps)(GameRoster)
