// @ts-nocheck
/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Button } from 'src/components/Button'
import Icon from 'src/components/Icon'
import { Input } from 'src/components/Input'
import { NewModal } from 'src/components/NewModal'
import { SideModal } from 'src/components/SideModal'
import { useMobxStore } from 'src/state'
import { ModalHeader } from './UpgradeModal'
import { NewUserAddressSelector } from 'src/components/NewUserAddressSelector'
import PhoneInputComponent from 'src/components/PhoneFormComponent'

// TODO: Remove this mock
const additionalContacts: any[] = []

interface EditContactFormFields {
  fullName: string
  title: string
  email: string
  companyName: string
}

const initialContactValues: EditContactFormFields = {
  fullName: '',
  title: '',
  email: '',
  companyName: '',
}

interface ActionButtonProps {
  iconName: string
  label: string
  onClick: () => void
  removeMargin?: boolean
}

export const ActionButton = ({
  iconName,
  label,
  onClick,
  removeMargin,
}: ActionButtonProps) => (
  <button
    onClick={onClick}
    css={css`
      margin-left: ${removeMargin ? 0 : '20px'};
    `}
  >
    <Icon name={iconName} color={colors.DEFAULT_FLAIR} fontSize={14} />
    <span
      css={css`
        color: ${colors.DEFAULT_FLAIR};
        margin-left: 8px;
        font-size: 15px;
      `}
    >
      {label}
    </span>
  </button>
)

export const Details = observer(() => {
  const {
    me: {
      updateAccount,
      state: { account },
    },
  } = useMobxStore()
  const editContactRef = useRef<NewModal>(null)
  const editAddressRef = useRef<NewModal>(null)
  const editAdditionalContactRef = useRef<SideModal>(null)
  const [fieldErrors, setFieldErrors] = useState<string[]>([])
  const [customError, setCustomError] = useState(initialContactValues)

  const setContactInfoValues = useCallback(() => {
    return {
      email: account?.email,
      fullName: account?.full_name,
      title: account?.title,
      companyName: account?.company_name,
    }
  }, [account])

  const [phoneNumber, setPhoneNumber] = useState(account?.phone_number ?? '')

  const { control: contactControl, handleSubmit: handleContactSubmit } =
    useForm<EditContactFormFields>({
      defaultValues: {
        ...setContactInfoValues(),
      },
    })

  const [contactId, setContactId] = useState('')

  const handleEditContact: SubmitHandler<EditContactFormFields> = useCallback(
    (data) => {
      editContactRef.current?.closeModal()

      updateAccount({
        address_city: account?.address_city || '',
        address_country: account?.address_country || '',
        address_postal_code: account?.address_postal_code || '',
        address_state_province: account?.address_state_province || '',
        address_street: account?.address_street || '',
        company_name: data.companyName,
        email: data.email,
        full_name: data.fullName,
        phone_number: phoneNumber,
        title: data.title,
      })
    },
    [account, phoneNumber]
  )

  // const handleEditAddress: SubmitHandler<EditAddressFormFields> = (data) => {
  //   editAddressRef.current?.closeModal()

  //   updateAccount({
  //     address_city: data.city,
  //     address_country: data.country,
  //     address_postal_code: data.zipCode,
  //     address_state_province: data.state,
  //     address_street: data.street,
  //     email: account?.email || '',
  //     full_name: account?.full_name || '',
  //     phone_number: account?.phone_number || '',
  //     title: account?.title || '',
  //     company_name: account?.company_name || '',
  //   })
  // }

  const onError = (errors: any) => {
    setFieldErrors([])
    setCustomError(initialContactValues)
    setFieldErrors(Object.keys(errors))

    if (errors.email?.type === 'pattern') {
      setCustomError((prev) => ({ ...prev, email: errors.email.message }))
    }

    if (errors.phone?.type === 'pattern') {
      setCustomError((prev) => ({ ...prev, phone: errors.phone.message }))
    }

    if (errors.phone?.type === 'minLength') {
      setCustomError((prev) => ({
        ...prev,
        phone: 'This phone number is invalid',
      }))
    }
  }

  const phoneNumberFormatted = useMemo(() => {
    // Remove non-numeric characters from the input
    const numericValue = account?.phone_number?.replace(/\D/g, '') || ''

    // Format the numeric value with the desired mask
    let formattedValue = ''
    if (numericValue.length <= 10) {
      // For US and Canada numbers without the country code
      formattedValue =
        numericValue?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') || ''
    } else {
      // Handle numbers with country code, adjust the logic as needed
      // Example: +1 (123) 456-7890
      formattedValue =
        numericValue?.replace(
          /(\d{1})(\d{3})(\d{3})(\d{4})/,
          '+$1 ($2) $3-$4'
        ) || ''
    }

    return formattedValue
  }, [account?.phone_number])

  return (
    <>
      <div
        css={css`
          background-color: ${colors.HEADER};
          padding: 16px;
          border-radius: 8px;
          border: 1px solid ${colors.SOFT_STEEL};
          max-width: 820px;
          margin: 0 auto;
          margin-top: 16px;
        `}
      >
        {/* CONTACT INFO */}
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <p
            css={css`
              font-family: 'Rift', sans-serif;
              font-size: 24px;
              font-weight: 500;
            `}
          >
            CONTACT INFO
          </p>
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
            `}
          >
            {/* <ActionButton
              iconName='plus-square'
              label='Add another contact'
              onClick={() => {}}
            /> */}
            <ActionButton
              iconName='pen'
              label='Edit'
              onClick={() => editContactRef.current?.openModal()}
            />
          </div>
        </div>
        <div
          css={css`
            border-bottom: 1px solid ${colors.SOFT_STEEL};
            padding-bottom: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              margin-top: 16px;
            `}
          >
            <Input
              fieldName='fullName'
              disabled
              isMockInput
              mockValue={account?.full_name}
              label='Full Name'
            />
            <Input
              fieldName='title'
              disabled
              isMockInput
              mockValue={account?.title}
              label='Title'
            />
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              margin-top: 16px;
            `}
          >
            <Input
              fieldName='phone'
              disabled
              isMockInput
              mockValue={phoneNumberFormatted}
              label='Phone Number'
              type='phone'
            />
            <Input
              fieldName='email'
              disabled
              isMockInput
              mockValue={account?.email}
              label='Email'
              type='email'
            />
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              margin-top: 16px;
            `}
          >
            <Input
              fieldName='companyName'
              disabled
              isMockInput
              mockValue={account?.company_name}
              label='Company Name'
            />
          </div>
        </div>

        {/* ADDRESS */}
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 16px;
          `}
        >
          <p
            css={css`
              font-family: 'Rift', sans-serif;
              font-size: 24px;
              font-weight: 500;
            `}
          >
            ADDRESS
          </p>
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
            `}
          >
            <ActionButton
              iconName='pen'
              label='Edit'
              onClick={() => editAddressRef.current?.openModal()}
            />
          </div>
        </div>
        <div
          css={css`
            margin-bottom: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              margin-top: 16px;
            `}
          >
            <Input
              fieldName='street'
              disabled
              isMockInput
              mockValue={account?.address_street}
              label='Street'
            />
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              margin-top: 16px;
            `}
          >
            <Input
              fieldName='country'
              disabled
              isMockInput
              mockValue={account?.address_country}
              label='Country'
            />
            <Input
              fieldName='state'
              disabled
              isMockInput
              mockValue={account?.address_state_province}
              label='State/Province'
            />
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              margin-top: 16px;
            `}
          >
            <Input
              fieldName='city'
              disabled
              isMockInput
              mockValue={account?.address_city}
              label='City'
            />
            <Input
              fieldName='zip'
              disabled
              isMockInput
              mockValue={account?.address_postal_code}
              label='ZIP/Postal Code'
            />
          </div>
        </div>
        {/* ADDITIONAL CONTACTS */}
        {additionalContacts &&
          additionalContacts.length > 0 &&
          additionalContacts.map((contact, index) => (
            <div key={contact.email}>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding-top: 16px;
                  border-top: 1px solid ${colors.SOFT_STEEL};
                `}
              >
                <p
                  css={css`
                    font-family: 'Rift', sans-serif;
                    font-size: 24px;
                    font-weight: 500;
                  `}
                >
                  ADDITIONAL CONTACT {index + 1}
                </p>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  `}
                >
                  <ActionButton
                    iconName='pen'
                    label='Edit'
                    onClick={() => {
                      setContactId(contact.id)
                      editAdditionalContactRef.current?.handleOpen()
                    }}
                  />
                </div>
              </div>
              <div
                css={css`
                  padding-bottom: 16px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    margin-top: 16px;
                  `}
                >
                  <Input
                    fieldName='fullName'
                    disabled
                    isMockInput
                    mockValue={contact.fullName}
                    label='Full Name'
                  />
                  <Input
                    fieldName='title'
                    disabled
                    isMockInput
                    mockValue={contact.title}
                    label='Title'
                  />
                </div>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    margin-top: 16px;
                  `}
                >
                  <Input
                    fieldName='phone'
                    disabled
                    isMockInput
                    mockValue={contact.phone}
                    label='Phone Number'
                    type='phone'
                  />
                  <Input
                    fieldName='email'
                    disabled
                    isMockInput
                    mockValue={contact.email}
                    label='Email'
                    type='email'
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
      {/* MODALS */}
      <NewModal ref={editContactRef}>
        <div
          css={css`
            width: 512px;
            padding: 16px;
            background: no-repeat fixed linear-gradient(#282e38, #181a1d);
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            max-height: 80vh;
            overflow-y: auto;
          `}
        >
          <ModalHeader
            onCloseClick={() => {
              editContactRef.current?.closeModal()
            }}
            hideSupportButton
            title='Edit Contact'
          />
          <div>
            <div
              css={css`
                margin-top: 16px;
              `}
            >
              <Input
                control={contactControl}
                fieldName='fullName'
                label='Full Name'
                required
                invalidMessage={
                  customError.fullName || 'This field is mandatory'
                }
                invalid={fieldErrors.includes('fullName')}
              />
            </div>
            <div
              css={css`
                margin-top: 8px;
              `}
            >
              <Input
                control={contactControl}
                fieldName='title'
                label='Title'
                required
                invalidMessage={customError.title || 'This field is mandatory'}
                invalid={fieldErrors.includes('title')}
              />
            </div>
            <div
              css={css`
                margin-top: 8px;
                margin-bottom: 20px;
              `}
            >
              <style>
                {`
                  .flag-dropdown {
                    height: 40px!important;
                  }
                `}
              </style>
              <p
                tabIndex={-1}
                css={css`
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 12px;
                  color: ${colors.WHITE};
                  margin-bottom: 8px;
                `}
              >
                Phone Number *
              </p>
              <PhoneInputComponent
                phoneValue={phoneNumber}
                namePhone={'phoneNumber'}
                onChangePhone={(e) => setPhoneNumber(e.target.value)}
                setPhoneValue={setPhoneNumber}
                containerStyle={{
                  width: '100%',
                  height: 40,
                }}
                inputStyle={{
                  borderWidth: 1,
                  borderColor: '#3F4753',
                  height: 40,
                  borderRadius: 4,
                  display: 'flex',
                  backgroundColor: 'transparent',
                  color: 'rgba(255, 255, 255, 0.85)',
                  fontSize: 16,
                  letterSpacing: 0.74,
                  width: '100%',
                }}
                disableDropdown={true}
              />
            </div>
            <div
              css={css`
                margin-top: 8px;
              `}
            >
              <Input
                control={contactControl}
                fieldName='email'
                label='Email'
                type='email'
                required
                disabled
                invalidMessage={customError.email || 'This field is mandatory'}
                invalid={fieldErrors.includes('email')}
              />
            </div>
            <div
              css={css`
                margin-top: 8px;
              `}
            >
              <Input
                control={contactControl}
                fieldName='companyName'
                label='Company Name'
                required
              />
            </div>
          </div>
          <div
            css={css`
              margin-top: 32px;
            `}
          >
            <Button
              label='Submit'
              onClick={handleContactSubmit(handleEditContact, onError)}
            />
          </div>
        </div>
      </NewModal>
      <NewModal ref={editAddressRef}>
        <div
          css={css`
            width: 512px;
            padding: 16px;
            background: no-repeat fixed linear-gradient(#282e38, #181a1d);
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            max-height: 80vh;
            overflow-y: auto;
            gap: 16px;
          `}
        >
          <ModalHeader
            onCloseClick={() => {
              editAddressRef.current?.closeModal()
            }}
            hideSupportButton
            title='Edit Address'
          />
          <NewUserAddressSelector
            requiredFields={{
              city: true,
              country: true,
              postcode: true,
              state: true,
              street: true,
            }}
            initialState={{
              address_line1: '',
              address_line2: '',
              city: account?.address_city || '',
              country: account?.address_country || '',
              state: account?.address_state_province || '',
              postcode: account?.address_postal_code || '',
              street: account?.address_street || '',
            }}
            onCancel={() => editAddressRef.current?.closeModal()}
            onSubmit={({ args }) => {
              updateAccount({
                address_city: args.city,
                address_country: args.country,
                address_postal_code: args.postcode,
                address_state_province: args.state,
                address_street: args.street,
                email: account?.email || '',
                full_name: account?.full_name || '',
                phone_number: account?.phone_number || '',
                title: account?.title || '',
                company_name: account?.company_name || '',
              })

              editAddressRef.current?.closeModal()
            }}
          />
        </div>
        {/* <div
          css={css`
            width: 512px;
            padding: 16px;
            background: no-repeat fixed linear-gradient(#282e38, #181a1d);
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            max-height: 80vh;
            overflow-y: auto;
          `}
        >
          <ModalHeader
            onCloseClick={() => {
              editAddressRef.current?.closeModal()
            }}
            hideSupportButton
            title='Edit Address'
          />
          <div>
            <div
              css={css`
                margin-top: 16px;
              `}
            >
              <Input
                control={addressControl}
                fieldName='street'
                label='Street'
                required
              />
            </div>
            <div
              css={css`
                margin-top: 8px;
              `}
            >
              <Input
                control={addressControl}
                fieldName='country'
                label='Country'
                required
              />
            </div>
            <div
              css={css`
                margin-top: 8px;
              `}
            >
              <Input
                control={addressControl}
                fieldName='state'
                label='State/Province'
                required
              />
            </div>
            <div
              css={css`
                margin-top: 8px;
              `}
            >
              <Input
                control={addressControl}
                fieldName='city'
                label='City'
                required
              />
            </div>
            <div
              css={css`
                margin-top: 8px;
              `}
            >
              <Input
                control={addressControl}
                fieldName='zipCode'
                label='ZIP/Postal Code'
                required
              />
            </div>
          </div>
          <div css={css`
            margin-top: 32px;
          `}>
            <Button
              label='Submit'
              onClick={handleAddressSubmit(handleEditAddress)}
            />
          </div>
        </div> */}
      </NewModal>

      <SideModal ref={editAdditionalContactRef} title='Edit Additional Contact'>
        <p>id: {contactId}</p>
      </SideModal>
    </>
  )
})
