import debounce from 'lodash.debounce'
import parsers from '@sportninja/common/reducers/parsers'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { t } from '@sportninja/common/i18n'
import { getBirthDateString } from '@sportninja/common/reducers/helpers'

const playerSearchResultMapper = (input) => {
  const result = {
    ...parsers.user(input),
    // Return the player id
    id: input.id,
    a: input?.a,
    birthDate: getBirthDateString(input.birth_date),
    leaguestat_id: input?.leaguestat_id,
    salesforce_id: input?.salesforce_id,
  }

  if (input.teams && Array.isArray(input.teams) && input.teams.length > 0) {
    result.teams = input.teams.map((team) => ({
      teamName: team.name_full || team.name,
      orgName: team.organization,
    }))
  }

  return result
}

export const getSearchTypes = () => ({
  [ENTITY_TYPES.player]: {
    iconName: 'user',
    imageIsPortrait: true,
    button: {
      short: t('common:player', { count: 2 }),
      long: t('common:player', { count: 2 }),
    },
    mapper: playerSearchResultMapper,
    searchText: t('Web:searchForAPlayer'),
    slug: 'search/players',
    to: '/player',
  },
  [ENTITY_TYPES.team]: {
    iconName: 'user-friends',
    button: {
      short: t('common:team', { count: 2 }),
      long: t('common:team', { count: 2 }),
    },
    mapper: parsers[ENTITY_TYPES.team],
    searchText: t('Web:searchForATeam'),
    slug: 'search/teams',
    to: '/team',
  },
  [ENTITY_TYPES.schedule]: {
    iconName: 'calendar-alt',
    button: {
      short: t('common:compShort', { count: 2 }),
      long: t('common:competition', { count: 2 }),
    },
    mapper: parsers[ENTITY_TYPES.schedule],
    searchText: t('Web:searchForACompetition'),
    slug: 'search/schedules',
    to: '/schedule',
  },
  [ENTITY_TYPES.org]: {
    iconName: 'users',
    button: {
      short: t('common:org', { count: 2 }),
      long: t('common:organization', { count: 2 }),
    },
    mapper: parsers[ENTITY_TYPES.org],
    searchText: t('Web:searchForAnOrganization'),
    slug: 'search/organizations',
    to: '/org',
  },
})

export const makeSearchRequest = debounce(
  async (
    method,
    term,
    searchTypeKey,
    searchTypes,
    currPage,
    callback,
    error,
    sportId = null,
    genericSportId = null
  ) => {
    const searchType = searchTypes[searchTypeKey]
    const queryKey = searchType.queryKey || 'query'
    const additionalQuery = searchType.query || ''

    try {
      const response = await method(
        `/${searchType.slug}?page=${currPage}${
          sportId ? `&sport_id=${sportId} ` : ''
        }${genericSportId ? `&generic_sport_id=${genericSportId} ` : ''}${
          term.length > 0 ? `&${queryKey}=${encodeURIComponent(term)}` : ''
        }${additionalQuery}`,
        searchTypeKey
      )
      callback(response)
    } catch (e) {
      error(e)
    }
  },
  250
)
