import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './index.scss'

const PageButton = ({
  active = false,
  className,
  current = false,
  icon,
  onClick,
  text,
}) => {
  const classes = cn(
    'page-button flex',
    className,
    active ? 'is--active' : 'is--disabled is--clickable',
    { 'is--current': current }
  )

  return (
    <li onClick={onClick} className={className}>
      <button className={classes} type='button'>
        {icon && (
          <span aria-hidden>
            <i className={`fas fa-${icon}`} />
          </span>
        )}
        <span className={`${icon ? 'sr-only' : ''}`}>{text}</span>
      </button>
    </li>
  )
}

PageButton.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  current: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

const NUM_ADJACENT_PAGES = 3
const NUM_PAGES_SHOWN = NUM_ADJACENT_PAGES * 2

export const Pager = ({
  disabled = false,
  onClick = () => {},
  page,
  total,
  noBackground = false,
  noBorder = false,
  noPositionFixed = false,
  alwaysShowPager = false,
  numAdjacentPages = NUM_ADJACENT_PAGES,
}) => {
  if (!alwaysShowPager && (typeof total !== 'number' || total < 2)) return false
  let pages = []

  // Show NUM_ADJACENT_PAGES before and after the current page
  let min = Math.max(1, page - numAdjacentPages)
  let max = Math.min(total, page + numAdjacentPages)
  const range = max - min
  const numPagesShown = numAdjacentPages * 2

  // However, if we aren't showing NUM_PAGES_SHOWN (in addition to current page)
  // Add on extra adjacent pages to either the left or right of the current page
  if (range !== numPagesShown) {
    if (min === 1) {
      max = Math.min(total, max + (numPagesShown - range))
    } else if (max === total) {
      min = Math.max(1, min - (numPagesShown - range))
    }
  }

  for (let i = min; i <= max; i++) {
    pages.push(
      <PageButton
        active={!disabled}
        current={page === i}
        key={i}
        onClick={!disabled ? () => onClick(i) : undefined}
        text={i}
      />
    )
  }

  const hasPages = total > 0
  const isPageOne = page === 1
  const isLastPage = page === total

  return (
    <nav
      className={cn('sn-pagination flex', {
        'no-position-fixed': noPositionFixed,
        'no-border': noBorder,
        'no-background': noBackground,
      })}
      aria-label='table list navigation'
    >
      <ul className='page-buttons flex'>
        <PageButton
          active={!isPageOne && hasPages && !disabled}
          className='smaller-text'
          icon='backward'
          onClick={
            !isPageOne && hasPages && !disabled ? () => onClick(1) : undefined
          }
          text='First page'
        />
        <PageButton
          active={!isPageOne && hasPages && !disabled}
          className='small-text'
          icon='caret-left'
          onClick={
            !isPageOne && hasPages && !disabled
              ? () => onClick(page - 1)
              : undefined
          }
          text='Previous'
        />
        {pages}
        <PageButton
          active={!isLastPage && !disabled}
          className='small-text'
          icon='caret-right'
          onClick={
            !isLastPage && !disabled ? () => onClick(page + 1) : undefined
          }
          text='Next'
        />
        <PageButton
          active={!isLastPage && !disabled}
          className='smaller-text'
          icon='forward'
          onClick={!isLastPage && !disabled ? () => onClick(total) : undefined}
          text='Last page'
        />
      </ul>
    </nav>
  )
}
