import { t } from '../i18n'

export const USERNAME_LENGTH_MIN = 4
export const USERNAME_LENGTH_MAX = 64
export const PASSWORD_LENGTH_MIN = 8
export const EMAIL_LENGTH_MIN = 5
export const EMAIL_LENGTH_MAX = 128

const OPEN_ROUTES = {
  // Auth
  LOGIN: '/login',
  REGISTER: '/register',
  REGISTER_SUCCESS: '/register/success',
  FORGOT_USERNAME: '/forgot-username',
  FORGOT_PASSWORD: '/forgot-password',
  VERIFY_EMAIL: '/verify-email',
  VERIFY_EMAIL_2: '/verifyEmail',
  PASSWORD_RESET: '/password-reset',
  INVITE_REGISTER_ROOT: '/invitations/',
  INVITE_REGISTER: '/invitations/:inviteId/register',

  // New Routes, we should depreciate the old ones '/register'
  SIGNUP: '/signup',
  SIGNUP_SUCCESS: '/signup/success',
  SIGNUP_REGISTER: '/invitations/:inviteId/signup',

  PUBLIC_GAME_ROOT: '/public/game',
  PUBLIC_GAME_PAGES: '/public/game/:id',

  PUBLIC_PLAYER_ROOT: '/public/player',
  PUBLIC_PLAYER_PAGES: '/public/player/:id',

  REGISTRATION_INVITE: '/registration/:id',
}

const PRIVATE_ROUTES = {
  HOME: '/',
  HOME_ROOT: '/home',

  // Invitations
  INVITE_V2_ROOT: '/invitations/',
  INVITE_V2: '/invitations/:inviteId',

  // PROFILE
  PROFILE: {
    ROOT: '/profile',
    FOLLOWING: '/profile/following',
    FOLLOWERS: '/profile/followers',
    STATS: '/profile/statistics',
    SUSPENSIONS: '/profile/suspensions',
    MEMBERSHIP: '/profile/membership',
    HIGHLIGHTS: '/profile/highlights',
    REGISTRATIONS: '/profile/registrations',
    WAIVERS: '/profile/waivers',
  },

  // USER
  USER_ROOT: '/user',
  USER_PAGES: '/user/:id',

  // Games
  GAMES: {
    ROOT: '/games',
    COMPLETED: '/games/completed',
    MANAGED: '/games/managed',
    FOLLOWING: '/games/following',
  },

  GAME_ROOT: '/game',
  GAME_PAGES: '/game/:id',
  GAME_SCORING: '/game/:id/scoring',

  // Teams
  TEAMS: {
    ROOT: '/teams',
    MINE: '/teams/mine',
    FOLLOWING: '/teams/following',
    FAVOURITES: '/teams/following',
  },

  TEAM_ROOT: '/team',
  TEAM_PAGES: '/team/:id',

  // Orgs
  ORGS: {
    ROOT: '/orgs',
    MINE: '/orgs/mine',
    MANAGED: '/orgs/managed',
    FAVOURITES: '/orgs/favourites',
  },

  SANDBOX: '/sandbox',

  ORG_ROOT: '/org',
  ORG_PAGES: '/org/:id',

  // Schedules
  SCHEDULES: {
    ROOT: '/schedules',
    MINE: '/schedules/mine',
    COMPLETED: '/schedules/completed',
    FOLLOWING: '/schedules/following',
    FAVOURITES: '/schedules/favourites',
  },

  SCHEDULE_ROOT: '/schedule',
  SCHEDULE_PAGES: '/schedule/:id',

  // Players
  PLAYER_ROOT: '/player',
  PLAYER_PAGES: '/player/:id',

  SCORESHEETS: '/scoresheets',
  SIGNIN_REPORTS_PRINT: '/sign-report-print',
  MERGE_PLAYERS: '/merge-players',

  SETUP_REGISTRATION: '/setup-registration',
  REGISTRATION_PAYMENT: '/pay',

  GENERAL_SETTINGS: '/account',
}

export const ROUTES = { ...OPEN_ROUTES, ...PRIVATE_ROUTES }

// Nested routes need to be flattened into an array so we can use them to test
// route pathnames against.
export const AUTHENTICATED_ROUTES = (() => {
  const flatten = (object) => {
    const arr = []

    // I don't know why eslint complains about this...

    for (let key in object) {
      if (typeof object[key] !== 'string') {
        arr.push(...flatten(object[key]))
      } else {
        arr.push(object[key])
      }
    }
    return arr
  }
  return flatten(PRIVATE_ROUTES)
})()

export const REQUIRED_AGE = 13

// `to` is the actual url we should navigate to
// `match` is a list of other urls we should display an active indicator for (i.e. for a nested game under 'Games')
export const NAV_LINKS = () => [
  {
    title: t('util:capitalize', { text: t('common:home') }),
    to: ROUTES.HOME_ROOT,
  },
  {
    title: t('util:capitalize', {
      text: t('common:organization', { count: 2 }),
    }),
    to: ROUTES.ORGS.ROOT,
    match: [ROUTES.ORG_PAGES],
  },
  {
    title: t('util:capitalize', {
      text: t('common:competition', { count: 2 }),
    }),
    to: ROUTES.SCHEDULES.ROOT,
    match: [ROUTES.SCHEDULE_PAGES],
  },
  {
    title: t('util:capitalize', { text: t('common:team', { count: 2 }) }),
    to: ROUTES.TEAMS.ROOT,
    match: [ROUTES.TEAM_PAGES],
  },
]

export const DEFAULT_SORT_COLUMN = 'name_full'
export const DEFAULT_SORT_DIRECTION = 'desc'
export const TABLE_TYPES = {
  org: 'org',
  users: 'users',
  player: 'player',
  teamPlayer: 'teamPlayer',
  schedule: 'schedule',
  orgSchedule: 'orgSchedule',
  team: 'team',
  teamOfficials: 'teamOfficials',
  officials: 'officials',
}

export const baseRoles = {
  0: 'Owner',
  1: 'Admin',
  2: 'League Manager',
  3: 'Referee',
  4: 'Scorekeeper',
  5: 'Videographer',
  6: 'Player',
  7: 'Team Manager',
  8: 'Timekeeper',
}

const getUserRoles = () => {
  return Object.keys(baseRoles)
    .filter((roleKey) => {
      const roleName = baseRoles[roleKey]

      return [
        'Owner',
        'Admin',
        'League Manager',
        'Videographer',
        'Team Manager',
      ].includes(roleName)
    })
    .reduce((collector, roleKey) => {
      return {
        ...collector,
        [roleKey]: baseRoles[roleKey],
      }
    }, {})
}

const userRoles = getUserRoles()

// {
//   0: 'Owner',
//   1: 'Admin',
//   etc...
// }
export const ROLE_TYPE_ID_TO_STRING = userRoles

// {
//   'Owner': 0,
//   'Admin': 1,
//   etc...
// }
export const ROLE_TYPE_STRING_TO_ID = Object.keys(userRoles).reduce(
  (collector, baseRoleId) => {
    const baseRoleName = userRoles[baseRoleId]
    return {
      ...collector,
      [baseRoleName]: parseInt(baseRoleId, 10),
    }
  },
  {}
)

// [
//   { id: 0, name: 'Owner' },
//   { id: 1, name: 'Admin' },
//   etc...
// ]
export const ROLE_TYPE_LIST = Object.keys(userRoles).reduce(
  (collector, baseRoleId) => {
    const baseRoleName = userRoles[baseRoleId]

    return [
      ...collector,
      {
        id: parseInt(baseRoleId, 10),
        name: baseRoleName,
      },
    ]
  },
  []
)

// NOTE: Turn this flag to "true" to enable registration
export const ENABLE_REGISTRATION = true
export const ENABLE_SUBSCRIPTION = true
