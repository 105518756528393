/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import type { Action } from '../stepsController'
import colors from '@sportninja/common/constants/appColors'
import {
  paragraphLarge,
  paragraphSmall,
  weightBold,
  weightRegular,
} from 'src/components/css'

export interface StepProps {
  stepDispatcher: React.Dispatch<Action>
}

export const UserSelection = ({ stepDispatcher }: StepProps) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 16px;
      `}
    >
      <div
        onClick={() => stepDispatcher({ type: 'set', payload: 1 })}
        css={css`
          display: flex;
          padding-top: 16px;
          padding-bottom: 16px;
          padding-left: 56px;
          padding-right: 56px;
          justify-content: center;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          border-radius: 8px;
          border: 2px solid ${colors.PRIMARY_300};
          cursor: pointer;
          height: 90px;
          &:hover {
            background-color: ${colors.PRIMARY_300};
            #join {
              color: ${colors.HEADER};
            }
            #existing {
              color: ${colors.HEADER};
            }
            #divider-1 {
              border-color: ${colors.HEADER};
            }
          }
        `}
      >
        <p
          id='join'
          css={css`
            ${paragraphLarge}
            ${weightBold}
            color: ${colors.WHITE};
            text-align: right;
            width: 80px;
          `}
        >
          JOIN
        </p>
        <div
          id='divider-1'
          css={css`
            height: 50px;
            border: 1px solid ${colors.WHITE};
          `}
        />
        <p
          id='existing'
          css={css`
            ${paragraphSmall}
            ${weightRegular}
            color: ${colors.WHITE};
            width: 220px;
          `}
        >
          an EXISTING Organization,
          <br /> League or Team
        </p>
      </div>
      <div
        onClick={() => stepDispatcher({ type: 'set', payload: 2 })}
        css={css`
          display: flex;
          padding-top: 16px;
          padding-bottom: 16px;
          padding-left: 56px;
          padding-right: 56px;
          justify-content: center;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          border-radius: 8px;
          border: 2px solid ${colors.PRIMARY_300};
          cursor: pointer;
          height: 90px;
          &:hover {
            background-color: ${colors.PRIMARY_300};
            #create {
              color: ${colors.HEADER};
            }
            #new-org {
              color: ${colors.HEADER};
            }
            #divider-2 {
              border-color: ${colors.HEADER};
            }
          }
        `}
      >
        <p
          id='create'
          css={css`
            ${paragraphLarge}
            ${weightBold}
            color: ${colors.WHITE};
            text-align: right;
            width: 80px;
          `}
        >
          CREATE
        </p>
        <div
          id='divider-2'
          css={css`
            height: 50px;
            border: 1px solid ${colors.WHITE};
          `}
        />
        <p
          id='new-org'
          css={css`
            ${paragraphSmall}
            ${weightRegular}
            color: ${colors.WHITE};
            width: 220px;
          `}
        >
          a NEW Organization
          <br /> or League
        </p>
      </div>
    </div>
  )
}
