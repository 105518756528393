/** @jsxImportSource @emotion/react */
import { CheckOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons'
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { formatCurrency, getErrorMessage } from '@sportninja/common/utils/utils'
import { Alert, Image, Input, Select } from 'antd'
import dayjs from 'dayjs'
import { useCallback, useContext, useEffect, useState } from 'react'
import { EventCard } from 'src/components/RegistrationEventCard'
import { EventProgress } from 'src/components/RegistrationEventProgress'
import { RegistrationContext } from '../RegistrationContext'
import {
  PageTitle,
  REGISTRATION_STEPS,
  alternateButtonStyle,
  buttonStyle,
  headingSixStyle,
  loadingButtonStyle,
  paragraphMediumBoldStyle,
  paragraphMediumStyle,
  paragraphSmallStyle,
  paragraphXSmallStyle,
} from './Main'

const initialValues = {
  additionalTeamOfficialsFirstName: '',
  additionalTeamOfficialsLastName: '',
  additionalTeamOfficialsEmail: '',
  additionalTeamOfficialsBirthDate: '',
}

const TeamRegister = () => {
  const {
    userId,
    session,
    updateSessionPayment,
    updateSessionTeamRegister,
    registration,
    setStep,
    division,
    selectedTeam,
    setSelectedTeam,
    userTeams,
    countries,
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    birthDate,
    setBirthDate,
    country,
    setCountry,
    zipCode,
    setZipCode,
    promoCode,
    setPromoCode,
    additionalTeamOfficials,
    setAdditionalTeamOfficials,
    couponInfo,
    setCouponInfo,
    deleteSession,
  } = useContext(RegistrationContext)

  const currency_id = division?.currency?.id
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showPromoCode, setShowPromoCode] = useState(false)
  const [isPromoCodeValid, setIsPromoCodeValid] = useState(false)
  const [promoCodeError, setPromoCodeError] = useState(null)
  const [isAddingNewTeam, setIsAddingNewTeam] = useState(false)
  const [isAddingTeamOfficial, setIsAddingTeamOfficial] = useState(false)
  const [newTeamOfficial, setNewTeamOfficial] = useState({
    firstName: '',
    lastName: '',
    email: '',
    birthDateDay: '',
    birthDateMonth: '',
    birthDateYear: '',
  })
  const [newTeamName, setNewTeamName] = useState('')
  const teamImage = selectedTeam ? getImageThumbnailId(selectedTeam) : null
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  const [fieldErrors, setFieldErrors] = useState([])
  const [customError, setCustomError] = useState(initialValues)

  const setCustomErrors = (invalid_fields) => {
    if (invalid_fields.team_name) {
      setCustomErroByField('team_name', invalid_fields.team_name)
    }
    if (invalid_fields.team_id) {
      setCustomErroByField('team_id', invalid_fields.team_id)
    }
    if (invalid_fields.country_code) {
      setCustomErroByField('country_code', invalid_fields.country_code)
    }
    if (invalid_fields.postal_code) {
      setCustomErroByField('postal_code', invalid_fields.postal_code)
    }
    if (invalid_fields.officials) {
      setCustomErroByField('officials', invalid_fields.officials)
    }
    if (invalid_fields.additional_team_official_first_name) {
      setCustomErroByField(
        'additionalTeamOfficialsFirstName',
        invalid_fields.additional_team_official_first_name
      )
    }
    if (invalid_fields.additional_team_official_last_name) {
      setCustomErroByField(
        'additionalTeamOfficialsLastName',
        invalid_fields.additional_team_official_last_name
      )
    }
    if (invalid_fields.additional_team_official_email) {
      setCustomErroByField(
        'additionalTeamOfficialsEmail',
        invalid_fields.additional_team_official_email
      )
    }
    if (invalid_fields.additional_team_official_birth_date) {
      setCustomErroByField(
        'additionalTeamOfficialsBirthDate',
        invalid_fields.additional_team_official_birth_date
      )
    }
    if (invalid_fields['team_official_array.0.team_official_type_id']) {
      setCustomErroByField(
        'team_official_array.0.team_official_type_id',
        invalid_fields['team_official_array.0.team_official_type_id']
      )
    }
    additionalTeamOfficials?.forEach((el, index) => {
      if (invalid_fields[`team_official_array.${index + 1}.first_name`]) {
        setCustomErroByField(
          'additionalTeamOfficialsFirstName',
          invalid_fields[`team_official_array.${index + 1}.first_name`]
        )
      }
      if (invalid_fields[`team_official_array.${index + 1}.last_name`]) {
        setCustomErroByField(
          'additionalTeamOfficialsLastName',
          invalid_fields[`team_official_array.${index + 1}.last_name`]
        )
      }
      if (invalid_fields[`team_official_array.${index + 1}.email`]) {
        setCustomErroByField(
          'additionalTeamOfficialsEmail',
          invalid_fields[`team_official_array.${index + 1}.email`]
        )
      }
      if (invalid_fields[`team_official_array.${index + 1}.birth_date`]) {
        setCustomErroByField(
          'additionalTeamOfficialsBirthDate',
          invalid_fields[`team_official_array.${index + 1}.birth_date`]
        )
      }
    })
  }

  const setCustomErroByField = (field, message) => {
    setCustomError((prev) => ({ ...prev, [field]: message }))
    setFieldErrors((prevErrors) => [...new Set([...prevErrors, field])])
  }

  useEffect(() => {
    console.log({
      session,
    })
    if (session && session?.team_name && !session?.team_id) {
      setNewTeamName(session?.team_name)
      setIsAddingNewTeam(true)
    } else if (session && session?.team_id) {
      const team = userTeams.find((el) => el.uid === session?.team_id)
      setSelectedTeam(team)
    }
  }, [session, userTeams])

  useEffect(() => {
    if (userTeams && userTeams.length <= 0) {
      setIsAddingNewTeam(true)
    }
  }, [userTeams])

  const validateCoupon = async () => {
    try {
      setPromoCodeError(null)
      setIsPromoCodeValid(false)
      const res = await req(`/registration/${registration?.id}/coupon/verify`, {
        method: 'POST',
        body: JSON.stringify({
          code: promoCode,
        }),
      })
      setCouponInfo({
        discount: res?.data?.discount,
        type: res?.data?.discount_type,
        id: res?.data?.id,
      })
      setIsPromoCodeValid(true)
    } catch (error) {
      setPromoCodeError(getErrorMessage(error))
    }
  }

  const handleAddTeamOfficial = async () => {
    try {
      setFieldErrors([])
      setCustomError(initialValues)
      if (!newTeamOfficial.firstName) {
        setCustomErroByField(
          'additionalTeamOfficialsFirstName',
          'First name is required'
        )
        return
      }
      if (!newTeamOfficial.lastName) {
        setCustomErroByField(
          'additionalTeamOfficialsLastName',
          'Last name is required'
        )
        return
      }
      if (!newTeamOfficial.email) {
        setCustomErroByField(
          'additionalTeamOfficialsEmail',
          'Email is required'
        )
        return
      }
      if (!newTeamOfficial.email.includes('@')) {
        setCustomErroByField(
          'additionalTeamOfficialsEmail',
          'Email is not valid'
        )
        return
      }
      if (!newTeamOfficial.birthDateDay) {
        setCustomErroByField(
          'additionalTeamOfficialsBirthDate',
          'Birthdate is required'
        )
        return
      }
      if (!newTeamOfficial.birthDateMonth) {
        setCustomErroByField(
          'additionalTeamOfficialsBirthDate',
          'Birthdate is required'
        )
        return
      }
      if (!newTeamOfficial.birthDateYear) {
        setCustomErroByField(
          'additionalTeamOfficialsBirthDate',
          'Birthdate is required'
        )
        return
      }
      const birthDate = `${newTeamOfficial.birthDateYear}-${newTeamOfficial.birthDateMonth}-${newTeamOfficial.birthDateDay}`
      setAdditionalTeamOfficials([
        ...additionalTeamOfficials,
        {
          firstName: newTeamOfficial.firstName,
          lastName: newTeamOfficial.lastName,
          email: newTeamOfficial.email,
          birthDate,
        },
      ])
      setIsAddingTeamOfficial(false)
      setNewTeamOfficial({
        firstName: '',
        lastName: '',
        email: '',
        birthDateDay: '',
        birthDateMonth: '',
        birthDateYear: '',
      })
    } catch (e) {
      console.error(e)
    }
  }

  const submitRegistration = async () => {
    try {
      setLoading(true)
      setError(null)
      setCustomErrors({})
      setFieldErrors([])
      const composeTeamOfficials = [
        {
          email,
          name_first: firstName,
          name_last: lastName,
          is_treasurer: true,
          phone: '+1234567890',
        },
        ...additionalTeamOfficials.map((el) => {
          return {
            email: el.email,
            name_first: el.firstName,
            name_last: el.lastName,
            is_treasurer: false,
            phone: '+1234567890',
          }
        }),
      ]
      const teamId = !newTeamName ? selectedTeam?.uid || selectedTeam?.id : null
      const teamName = selectedTeam?.name || newTeamName
      await updateSessionTeamRegister({
        team_official_array: composeTeamOfficials,
        team_id: teamId,
        team_name: teamName,
        coupon_code_id: couponInfo?.id,
        country_code: country,
        postal_code: zipCode,
        user_id: userId,
        currency_id: currency_id,
      })
      await updateSessionPayment()
      setStep(REGISTRATION_STEPS.REGISTRATION_PAYMENT)
    } catch (e) {
      if (e?.status === 410) {
        // localStorage.removeItem('registration_session')
        deleteSession()
        window.location.reload()
        return
      }
      if (e?.invalid_fields) {
        setCustomErrors(e.invalid_fields)
        return
      }
      const errorMessages = getErrorMessage(e)
      setError(errorMessages)
    } finally {
      setLoading(false)
    }
  }

  const teamDropDownItem = useCallback(
    ({ value }) => {
      if (value === 'create_new_team') {
        return null
      }
      const team = userTeams.find((el) => el.uid === value || el.id === value)
      const imageId = getImageThumbnailId(team)
      if (!team || !imageId) {
        return (
          <Image
            src={'/images/generic_placeholders/placeholder_team.png'}
            width={24}
            height={24}
            preview={false}
          />
        )
      }
      return (
        <Image
          src={imageId}
          width={24}
          height={24}
          preview={false}
          onError={(e) => {
            e.target.onerror = null
            e.target.src = '/images/generic_placeholders/placeholder_team.png'
          }}
        />
      )
    },
    [userTeams]
  )

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        background-color: ${colors.REGISTRATION_SECOND_BACKGROUND};
        width: 1080px;
        padding: 0 35px 35px 35px;
        border-radius: 16px 16px 0px 0px;
        @media (max-width: 768px) {
          min-width: 0;
          width: 100%;
        }
      `}
    >
      <EventCard eventInfo={registration} />
      <EventProgress eventProgress={2} />
      <PageTitle
        title='Register Your Team'
        subtitle={'Review your info and move forward with your registration.'}
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          align-self: stretch;
        `}
      >
        <p
          css={css`
            ${paragraphMediumBoldStyle}
            color: ${colors.WHITE};
            align-self: stretch;
          `}
        >
          Division Info
        </p>
        <div
          css={css`
            display: flex;
            padding: 8px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid ${colors.VERY_DARK_GRAYISH_BLUE};
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex: 1 0 0;
                align-self: stretch;
              `}
            >
              <p
                css={css`
                  ${paragraphSmallStyle}
                  color: ${colors.WHITE};
                  align-self: stretch;
                `}
              >
                {division?.name}
              </p>
              <p
                css={css`
                  ${paragraphXSmallStyle}
                  color: ${colors.MAGNESIUM};
                `}
              >
                Age: {division?.division_age?.name}
              </p>
              <span
                css={css`
                  ${paragraphXSmallStyle}
                  color: ${colors.MAGNESIUM};
                `}
              >
                Gender: {division?.division_gender?.name}
              </span>
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
              `}
            >
              <p
                css={css`
                  ${paragraphXSmallStyle}
                  color: ${colors.MAGNESIUM};
                `}
              >
                Cost per Team
              </p>
              <p
                css={css`
                  color: ${colors.WHITE};
                  ${headingSixStyle}
                `}
              >
                {formatCurrency(division?.team_cost, division?.currency?.name)}
              </p>
              <p
                css={css`
                  ${paragraphXSmallStyle}
                  color: ${colors.MAGNESIUM};
                `}
              >
                Deposit:{' '}
                {formatCurrency(division?.deposit, division?.currency?.name)}
              </p>
            </div>
          </div>
          <div
            css={css`
              width: 100%;
              height: 1px;
              background: ${colors.VERY_DARK_GRAYISH_BLUE};
            `}
          />
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              align-self: stretch;
            `}
          >
            <p
              onClick={() => {
                setStep(REGISTRATION_STEPS.SELECT_YOUR_DIVISION)
              }}
              css={css`
                color: ${colors.PRIMARY};
                ${paragraphXSmallStyle}
                cursor: pointer;
                opacity: ${registration?.divisions?.length === 1 ? 0 : 1};
              `}
            >
              Change Division
            </p>
            <p
              onClick={() => {
                setShowPromoCode(!showPromoCode)
                if (showPromoCode === false) {
                  setPromoCode('')
                }
              }}
              css={css`
                color: ${colors.PRIMARY};
                ${paragraphXSmallStyle}
                cursor: pointer;
              `}
            >
              Add Coupon Code
            </p>
          </div>
        </div>
      </div>
      {/* Promocode */}
      {showPromoCode ? (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            margin-top: 16px;
          `}
        >
          <p
            css={css`
              ${paragraphSmallStyle}
              color: ${colors.WHITE};
              align-self: stretch;
            `}
          >
            Coupon Code
          </p>
          <div
            css={css`
              display: flex;
              align-items: flex-end;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <Input
              name='promoCode'
              placeholder='SUMMER10'
              type='text'
              autoComplete='off'
              autoCorrect='off'
              autoCapitalize='true'
              required
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              disabled={isPromoCodeValid}
            />
            <div
              css={css`
                display: flex;
                padding: 8px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 4px;
                background: ${colors.PRIMARY};
                height: 40px;
                cursor: ${isPromoCodeValid ? 'not-allowed' : 'pointer'};
                margin-bottom: 2px;
              `}
              disabled={isPromoCodeValid}
              onClick={async () => {
                if (!isPromoCodeValid) {
                  await validateCoupon()
                }
              }}
            >
              <CheckOutlined />
            </div>
          </div>
          {promoCodeError ? (
            <div
              css={css`
                width: 100%;
              `}
            >
              <Alert message={promoCodeError} type='error' />
            </div>
          ) : null}
          {isPromoCodeValid ? (
            <div
              css={css`
                width: 100%;
              `}
            >
              <Alert message={'Promo code validated'} type='success' />
            </div>
          ) : null}
        </div>
      ) : null}
      <div
        css={css`
          width: 100%;
          height: 1px;
          background: ${colors.VERY_DARK_GRAYISH_BLUE};
          margin-top: 16px;
        `}
      />
      {/* Team Info */}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          margin-top: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: stretch;
          `}
        >
          <p
            css={css`
              ${paragraphMediumBoldStyle}
              color: ${colors.WHITE};
              align-self: stretch;
              flex: 1 0 0;
            `}
          >
            Team Info
          </p>
          {isAddingNewTeam && userTeams?.length > 0 ? (
            <p
              onClick={() => {
                setIsAddingNewTeam(false)
                setSelectedTeam(null)
              }}
              css={css`
                ${paragraphXSmallStyle}
                color: ${colors.PRIMARY};
                cursor: pointer;
              `}
            >
              Select Existing Team
            </p>
          ) : null}
          {!isAddingNewTeam && userTeams?.length > 0 ? (
            <p
              onClick={() => {
                setSelectedTeam(null)
                setIsAddingNewTeam(true)
              }}
              css={css`
                ${paragraphXSmallStyle}
                color: ${colors.PRIMARY};
                cursor: pointer;
              `}
            >
              Create New Team
            </p>
          ) : null}
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;
          `}
        >
          {!isAddingNewTeam ? (
            <p
              css={css`
                color: ${colors.WHITE};
                ${paragraphSmallStyle}
              `}
            >
              Team
            </p>
          ) : null}
          {isAddingNewTeam ? (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  gap: 16px;
                  width: 100%;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 4px;
                    flex: 1 0 0;
                  `}
                >
                  <p
                    css={css`
                      ${paragraphSmallStyle}
                      color: ${colors.WHITE};
                    `}
                  >
                    Team Name *
                  </p>
                  <Input
                    status={
                      fieldErrors.includes('team_name') ? 'error' : 'default'
                    }
                    name='teamName'
                    placeholder='The Sport Ninjas'
                    type='text'
                    autoComplete='off'
                    autoCorrect='off'
                    autoCapitalize='none'
                    value={newTeamName}
                    onChange={(e) => setNewTeamName(e.target.value)}
                  />
                  <div
                    tabIndex={-1}
                    css={css`
                      transition: opacity 0.1s ease-in-out;
                      opacity: ${fieldErrors.includes('team_name') ? 1 : 0};
                      font-size: 12px;
                      color: ${colors.ERROR_LIGHT};
                      align-self: stretch;
                      margin-top: 8px;
                    `}
                  >
                    <span>{customError.team_name}</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <Select
                css={css`
                  width: 100%;
                `}
                status={
                  fieldErrors.includes('team_id') ||
                  fieldErrors.includes('team_name')
                    ? 'error'
                    : 'default'
                }
                suffixIcon={
                  selectedTeam && teamImage ? (
                    <Image
                      src={teamImage}
                      width={24}
                      height={24}
                      preview={false}
                    />
                  ) : (
                    <DownOutlined />
                  )
                }
                menuItemSelectedIcon={teamDropDownItem}
                placeholder='Select a team'
                defaultValue={selectedTeam?.uid || selectedTeam?.id}
                onChange={(e) => {
                  if (e === 'create_new_team') {
                    setIsAddingNewTeam(true)
                    setSelectedTeam(null)
                    return
                  }
                  const theTeam = userTeams.find(
                    (team) => team.uid === e || team.id === e
                  )
                  setSelectedTeam({ ...theTeam })
                  setIsAddingNewTeam(false)
                }}
                options={
                  userTeams
                    ? [
                        ...userTeams.map((el) => {
                          return {
                            label: el.name,
                            value: el.uid || el.id,
                          }
                        }),
                        { label: 'Create new team', value: 'create_new_team' },
                      ]
                    : [{ label: 'Create new team', value: 'create_new_team' }]
                }
              />
              <div
                tabIndex={-1}
                css={css`
                  transition: opacity 0.1s ease-in-out;
                  opacity: ${fieldErrors.includes('team_name') ? 1 : 0};
                  font-size: 12px;
                  color: ${colors.ERROR_LIGHT};
                  align-self: stretch;
                  margin-top: 8px;
                `}
              >
                <span>{customError.team_name}</span>
              </div>
            </>
          )}
        </div>
        <div
          tabIndex={-1}
          css={css`
            transition: opacity 0.1s ease-in-out;
            opacity: ${fieldErrors.includes('team_id') ? 1 : 0};
            font-size: 12px;
            color: ${colors.ERROR_LIGHT};
            align-self: stretch;
          `}
        >
          <span>{customError.team_id}</span>
        </div>
        <div
          css={css`
            width: 100%;
            height: 1px;
            background: ${colors.VERY_DARK_GRAYISH_BLUE};
          `}
        />
      </div>

      {/* Team Rep */}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          align-self: stretch;
          margin-top: 16px;
          gap: 16px;
        `}
      >
        <p
          css={css`
            ${paragraphMediumBoldStyle}
            color: ${colors.WHITE};
            align-self: stretch;
          `}
        >
          Team Official Info
        </p>
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            /* on mobile, change direction */
            @media (max-width: 768px) {
              flex-direction: column;
              width: 100%;
              align-self: stretch;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <p
              css={css`
                ${paragraphSmallStyle}
                color: ${colors.WHITE};
                align-self: stretch;
              `}
            >
              First Name *
            </p>
            <Input
              name='firstName'
              placeholder='John'
              type='text'
              autoComplete='given-name'
              autoCorrect='off'
              autoCapitalize='none'
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              disabled={true}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <p
              css={css`
                ${paragraphSmallStyle}
                color: ${colors.WHITE};
                align-self: stretch;
              `}
            >
              Last Name *
            </p>
            <Input
              name='lastName'
              placeholder='Doe'
              type='text'
              autoComplete='family-name'
              autoCorrect='off'
              autoCapitalize='none'
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              disabled={true}
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            @media (max-width: 768px) {
              flex-direction: column;
              width: 100%;
              align-self: stretch;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <p
              css={css`
                ${paragraphSmallStyle}
                color: ${colors.WHITE};
                align-self: stretch;
              `}
            >
              Email *
            </p>
            <Input
              name='email'
              placeholder='john.doe@me.com'
              type='email'
              autoComplete='email'
              autoCorrect='off'
              autoCapitalize='none'
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={true}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <p
              css={css`
                ${paragraphSmallStyle}
                color: ${colors.WHITE};
                align-self: stretch;
              `}
            >
              Birthdate *
            </p>
            <Input
              name='birthdate'
              placeholder='01/01/2000'
              type='text'
              autoComplete='birthdate'
              autoCorrect='off'
              autoCapitalize='none'
              required
              value={
                birthDate
                  ? `${String(new Date(birthDate).getUTCDate()).padStart(
                      2,
                      '0'
                    )}-${String(new Date(birthDate).getUTCMonth() + 1).padStart(
                      2,
                      '0'
                    )}-${new Date(birthDate).getUTCFullYear()}`
                  : '-'
              }
              onChange={(e) => setBirthDate(e.target.value)}
              disabled={true}
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            @media (max-width: 768px) {
              flex-direction: column;
              width: 100%;
              align-self: stretch;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <p
              css={css`
                ${paragraphSmallStyle}
                color: ${colors.WHITE};
                align-self: stretch;
              `}
            >
              Country *
            </p>
            <Select
              css={css`
                width: 100%;
                height: 43px;
              `}
              status={
                fieldErrors.includes('country_code') ? 'error' : 'default'
              }
              showSearch
              filterOption={filterOption}
              placeholder='Select a country'
              onChange={(e) => {
                setCountry(e)
              }}
              value={country}
              options={
                countries
                  ? countries.map((el) => {
                      return {
                        label: el?.name,
                        value: el?.iso_3166_2,
                      }
                    })
                  : []
              }
            />
            <div
              tabIndex={-1}
              css={css`
                transition: opacity 0.1s ease-in-out;
                opacity: ${fieldErrors.includes('country_code') ? 1 : 0};
                font-size: 12px;
                color: ${colors.ERROR_LIGHT};
                align-self: stretch;
                margin-top: 8px;
              `}
            >
              <span>{customError.country_code}</span>
            </div>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
              @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                align-self: stretch;
              }
            `}
          >
            <p
              css={css`
                ${paragraphSmallStyle}
                color: ${colors.WHITE};
                align-self: stretch;
              `}
            >
              Zip/Postal Code *
            </p>
            <Input
              status={fieldErrors.includes('postal_code') ? 'error' : 'default'}
              name='zipCode'
              placeholder=''
              type='text'
              autoCorrect='off'
              autoCapitalize='none'
              required
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
            <div
              tabIndex={-1}
              css={css`
                transition: opacity 0.1s ease-in-out;
                opacity: ${fieldErrors.includes('postal_code') ? 1 : 0};
                font-size: 12px;
                color: ${colors.ERROR_LIGHT};
                align-self: stretch;
                margin-top: 8px;
              `}
            >
              <span>{customError.postal_code}</span>
            </div>
          </div>
        </div>
        {fieldErrors.includes('team_official_array.0.team_official_type_id') ? (
          <div
            tabIndex={-1}
            css={css`
              transition: opacity 0.1s ease-in-out;
              opacity: ${fieldErrors.includes(
                'team_official_array.0.team_official_type_id'
              )
                ? 1
                : 0};
              font-size: 12px;
              color: ${colors.ERROR_LIGHT};
              align-self: stretch;
            `}
          >
            <span>
              {customError['team_official_array.0.team_official_type_id']}
            </span>
          </div>
        ) : null}
        <div
          css={css`
            width: 100%;
            height: 1px;
            background: ${colors.VERY_DARK_GRAYISH_BLUE};
          `}
        />
      </div>
      {/* Additional Team Officials */}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          margin-top: 16px;
          margin-bottom: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            justify-content: space-between;
          `}
        >
          <p
            css={css`
              ${paragraphMediumBoldStyle}
              color: ${colors.WHITE};
              align-self: stretch;
            `}
          >
            Additional Team Officials
          </p>
          <p
            css={css`
              ${paragraphXSmallStyle}
              color: ${colors.PRIMARY};
              cursor: pointer;
              text-transform: capitalize;
            `}
            onClick={() => {
              setIsAddingTeamOfficial(!isAddingTeamOfficial)
            }}
          >
            {!isAddingTeamOfficial ? 'Add Team Official' : 'Cancel'}
          </p>
        </div>
        {!isAddingTeamOfficial && additionalTeamOfficials?.length === 0 ? (
          <p
            css={css`
              color: ${colors.WHITE};
              ${paragraphSmallStyle}
              width: 100%;
              height: 150px;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            No Additional Team Officials
          </p>
        ) : null}
        {!isAddingTeamOfficial && additionalTeamOfficials?.length > 0 ? (
          <div
            css={css`
              display: flex;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            `}
          >
            {additionalTeamOfficials.map((official, index) => (
              <div
                key={index}
                css={css`
                  display: flex;
                  padding: 8px 16px;
                  justify-content: space-between;
                  align-items: center;
                  flex: 1 0 0;
                  border-radius: 4px;
                  background: ${colors.VERY_DARK_GRAYISH_BLUE};
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 4px;
                  `}
                >
                  <p
                    css={css`
                      ${paragraphMediumStyle}
                      color: ${colors.WHITE};
                    `}
                  >
                    {official.firstName} {official.lastName}
                  </p>
                  <p
                    css={css`
                      ${paragraphXSmallStyle}
                      color: ${colors.WHITE};
                    `}
                  >
                    Team Official
                  </p>
                </div>
                <DeleteOutlined
                  onClick={() => {
                    setAdditionalTeamOfficials(
                      additionalTeamOfficials.filter((el, i) => i !== index)
                    )
                  }}
                  css={css`
                    color: ${colors.ERROR_LIGHT};
                    cursor: pointer;
                  `}
                  size={24}
                />
              </div>
            ))}
          </div>
        ) : null}
        {isAddingTeamOfficial ? (
          <>
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: flex-start;
                gap: 8px;
                align-self: stretch;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 4px;
                  flex: 1 0 0;
                  @media (max-width: 768px) {
                    flex-direction: column;
                    width: 100%;
                    align-self: stretch;
                  }
                `}
              >
                <p
                  css={css`
                    ${paragraphSmallStyle}
                    color: ${colors.WHITE};
                    align-self: stretch;
                  `}
                >
                  First Name *
                </p>
                <Input
                  name='newTeamOfficialFirstName'
                  placeholder='John'
                  type='text'
                  autoComplete='given-name'
                  autoCorrect='off'
                  autoCapitalize='none'
                  required
                  value={newTeamOfficial.firstName}
                  onChange={(e) =>
                    setNewTeamOfficial({
                      ...newTeamOfficial,
                      firstName: e.target.value,
                    })
                  }
                  status={
                    fieldErrors.includes('additionalTeamOfficialsFirstName')
                      ? 'error'
                      : undefined
                  }
                />
                <div
                  tabIndex={-1}
                  css={css`
                    margin-top: 8px;
                    transition: opacity 0.1s ease-in-out;
                    opacity: ${fieldErrors.includes(
                      'additionalTeamOfficialsFirstName'
                    )
                      ? 1
                      : 0};
                    font-size: 12px;
                    color: ${colors.ERROR_LIGHT};
                  `}
                >
                  <span>{customError.additionalTeamOfficialsFirstName}</span>
                </div>
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 4px;
                  flex: 1 0 0;
                  @media (max-width: 768px) {
                    flex-direction: column;
                    width: 100%;
                    align-self: stretch;
                  }
                `}
              >
                <p
                  css={css`
                    ${paragraphSmallStyle}
                    color: ${colors.WHITE};
                    align-self: stretch;
                  `}
                >
                  Last Name *
                </p>
                <Input
                  name='newTeamOfficialLastName'
                  placeholder='Doe'
                  type='text'
                  autoComplete='family-name'
                  autoCorrect='off'
                  autoCapitalize='none'
                  required
                  value={newTeamOfficial.lastName}
                  onChange={(e) =>
                    setNewTeamOfficial({
                      ...newTeamOfficial,
                      lastName: e.target.value,
                    })
                  }
                  status={
                    fieldErrors.includes('additionalTeamOfficialsLastName')
                      ? 'error'
                      : undefined
                  }
                />
                <div
                  tabIndex={-1}
                  css={css`
                    margin-top: 8px;
                    transition: opacity 0.1s ease-in-out;
                    opacity: ${fieldErrors.includes(
                      'additionalTeamOfficialsLastName'
                    )
                      ? 1
                      : 0};
                    font-size: 12px;
                    color: ${colors.ERROR_LIGHT};
                  `}
                >
                  <span>{customError.additionalTeamOfficialsLastName}</span>
                </div>
              </div>
            </div>
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: flex-start;
                gap: 8px;
                align-self: stretch;
                /* on mobile, change the direction */
                @media (max-width: 768px) {
                  flex-direction: column;
                  align-items: stretch;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 4px;
                  flex: 1 0 0;
                `}
              >
                <p
                  css={css`
                    ${paragraphSmallStyle}
                    color: ${colors.WHITE};
                    align-self: stretch;
                  `}
                >
                  Email *
                </p>
                <Input
                  name='newTeamOfficialEmail'
                  placeholder='john.doe@me.com'
                  type='email'
                  autoComplete='email'
                  autoCorrect='off'
                  autoCapitalize='none'
                  required
                  value={newTeamOfficial.email}
                  onChange={(e) =>
                    setNewTeamOfficial({
                      ...newTeamOfficial,
                      email: e.target.value,
                    })
                  }
                  status={
                    fieldErrors.includes('additionalTeamOfficialsEmail')
                      ? 'error'
                      : undefined
                  }
                />
                <div
                  tabIndex={-1}
                  css={css`
                    margin-top: 8px;
                    transition: opacity 0.1s ease-in-out;
                    opacity: ${fieldErrors.includes(
                      'additionalTeamOfficialsEmail'
                    )
                      ? 1
                      : 0};
                    font-size: 12px;
                    color: ${colors.ERROR_LIGHT};
                  `}
                >
                  <span>{customError.additionalTeamOfficialsEmail}</span>
                </div>
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 4px;
                  flex: 1 0 0;
                `}
              >
                <p
                  css={css`
                    ${paragraphSmallStyle}
                    color: ${colors.WHITE};
                    align-self: stretch;
                  `}
                >
                  Birthdate *
                </p>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    flex: 1;
                    height: 40px;
                    width: 100%;
                    flex-direction: row;
                    @media (max-width: 600px) {
                      display: flex;
                      flex-direction: column;
                      width: 100%;
                      align-self: stretch;
                    }
                  `}
                >
                  <Input
                    name='dateOfBirthDay'
                    placeholder='DD'
                    type='number'
                    autoComplete='bday-day'
                    autoCorrect='off'
                    autoCapitalize='none'
                    required
                    value={newTeamOfficial.birthDateDay}
                    onChange={(e) =>
                      setNewTeamOfficial({
                        ...newTeamOfficial,
                        birthDateDay: e.target.value,
                      })
                    }
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 2)
                    }}
                    status={
                      fieldErrors.includes('additionalTeamOfficialsBirthDate')
                        ? 'error'
                        : undefined
                    }
                    maxLength={2}
                    min={1}
                    max={31}
                  />
                  <Input
                    name='dateOfBirthMonth'
                    placeholder='MM'
                    type='number'
                    autoComplete='bday-month'
                    autoCorrect='off'
                    autoCapitalize='none'
                    required
                    value={newTeamOfficial.birthDateMonth}
                    onChange={(e) =>
                      setNewTeamOfficial({
                        ...newTeamOfficial,
                        birthDateMonth: e.target.value,
                      })
                    }
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 2)
                    }}
                    status={
                      fieldErrors.includes('additionalTeamOfficialsBirthDate')
                        ? 'error'
                        : undefined
                    }
                    maxLength={2}
                    min={1}
                    max={12}
                  />

                  <Input
                    name='dateOfBirthYear'
                    placeholder='YYYY'
                    type='number'
                    autoComplete='bday-year'
                    autoCorrect='off'
                    autoCapitalize='none'
                    required
                    value={newTeamOfficial.birthDateYear}
                    onChange={(e) =>
                      setNewTeamOfficial({
                        ...newTeamOfficial,
                        birthDateYear: e.target.value,
                      })
                    }
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 4)
                    }}
                    status={
                      fieldErrors.includes('additionalTeamOfficialsBirthDate')
                        ? 'error'
                        : undefined
                    }
                    maxLength={4}
                    min={1900}
                    max={dayjs().year()}
                  />
                </div>
                <div
                  css={css`
                    transition: opacity 0.1s ease-in-out;
                    opacity: ${fieldErrors.includes(
                      'additionalTeamOfficialsBirthDate'
                    )
                      ? 1
                      : 0};
                    font-size: 12px;
                    color: ${colors.ERROR_LIGHT};
                  `}
                >
                  <span>{customError.additionalTeamOfficialsBirthDate}</span>
                </div>
              </div>
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                gap: 8px;
                width: 25%;
                align-self: flex-end;
                @media (max-width: 768px) {
                  width: 100%;
                }
              `}
            >
              <button
                disabled={false}
                onClick={async () => {
                  handleAddTeamOfficial()
                }}
                css={css`
                  ${headingSixStyle}
                  ${buttonStyle}
                `}
              >
                Add Team Official
              </button>
            </div>
            <div
              css={css`
                width: 100%;
                height: 1px;
                background: ${colors.VERY_DARK_GRAYISH_BLUE};
              `}
            />
          </>
        ) : null}
        {fieldErrors.includes('additionalTeamOfficialsFirstName') ? (
          <div
            css={css`
              margin-bottom: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              max-width: 500px;
              align-self: center;
              flex: 1;
            `}
          >
            <Alert
              message={customError.additionalTeamOfficialsFirstName}
              type='error'
              showIcon
            />
          </div>
        ) : null}
        {fieldErrors.includes('additionalTeamOfficialsLastName') ? (
          <div
            css={css`
              margin-bottom: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              max-width: 500px;
              align-self: center;
              flex: 1;
            `}
          >
            <Alert
              message={customError.additionalTeamOfficialsLastName}
              type='error'
              showIcon
            />
          </div>
        ) : null}
        {fieldErrors.includes('additionalTeamOfficialsEmail') ? (
          <div
            css={css`
              margin-bottom: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              align-self: center;
              flex: 1;
            `}
          >
            <Alert
              message={customError.additionalTeamOfficialsEmail}
              type='error'
              showIcon
            />
          </div>
        ) : null}
        {fieldErrors.includes('additionalTeamOfficialsBirthDate') ? (
          <div
            css={css`
              margin-bottom: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              max-width: 500px;
              align-self: center;
              flex: 1;
            `}
          >
            <Alert
              message={customError.additionalTeamOfficialsBirthDate}
              type='error'
              showIcon
            />
          </div>
        ) : null}
      </div>
      {error ? (
        <div
          css={css`
            margin-bottom: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 500px;
            align-self: center;
            flex: 1;
          `}
        >
          <Alert message={error} type='error' showIcon />
        </div>
      ) : null}
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          gap: 8px;
          width: 100%;
        `}
      >
        <button
          onClick={async () => {
            setStep(REGISTRATION_STEPS.SELECT_YOUR_DIVISION)
          }}
          css={css`
            ${headingSixStyle}
            ${alternateButtonStyle}
          `}
          disabled={loading}
        >
          Back
        </button>
        <button
          onClick={async () => {
            await submitRegistration()
          }}
          css={css`
            ${headingSixStyle}
            ${loading ? loadingButtonStyle : buttonStyle}
          `}
          disabled={loading}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default TeamRegister
