import { call, put } from 'redux-saga/effects'
import req from '@sportninja/common/api/request'

import { readWithPagesFactory } from './read'

const api = (entityType) => {
  return {
    read: async (id, page = 1, sort, direction) =>
      await req(`/${entityType}/${id}/officials`, {
        query: {
          page,
          sort,
          direction,
        },
      }),
    create: async (id, body) =>
      await req(`/${entityType}/${id}/officials`, { method: 'POST', body }),
    update: async (id, officialId, body) =>
      await req(`/${entityType}/${id}/officials/${officialId}`, {
        method: 'PUT',
        body,
      }),
    delete: async (id, officialId) =>
      await req(`/${entityType}/${id}/officials/${officialId}`, {
        method: 'DELETE',
      }),
  }
}

export default (entityType, actions) => {
  return [
    [actions.read, readWithPagesFactory(api(entityType).read, actions.read)],

    [
      actions.create,
      function* (payload) {
        const { id, form: _form } = payload
        const { message_text, official_type_id, ...form } = _form

        // If we name this `message` in the form, then normal 500 errors from the API
        // will highlight the form field, since that's the key the API uses for the error text.
        if (typeof message_text === 'string') form.message = message_text

        if (typeof official_type_id === 'string') {
          form.official_type_id = parseInt(official_type_id, 10)
        } else {
          form.official_type_id = official_type_id
        }

        const body = JSON.stringify(form)

        const response = yield call(api(entityType).create, id, body)
        yield put(actions.create.success({ id, data: response.data }))
        return response
      },
    ],

    [
      actions.update,
      function* (payload) {
        const { id, officialId, form: _form } = payload
        const { message_text, official_type_id, ...form } = _form

        // If we name this `message` in the form, then normal 500 errors from the API
        // will highlight the form field, since that's the key the API uses for the error text.
        if (typeof message_text === 'string') form.message = message_text

        if (typeof official_type_id === 'string') {
          form.official_type_id = parseInt(official_type_id, 10)
        } else {
          form.official_type_id = official_type_id
        }

        const body = JSON.stringify(form)
        const response = yield call(
          api(entityType).update,
          id,
          officialId,
          body
        )

        yield put(
          actions.update.success({ id, officialId, data: response.data })
        )
        return response
      },
    ],

    [
      actions.delete,
      function* (payload) {
        const { id, officialId } = payload

        const response = yield call(api(entityType).delete, id, officialId)
        yield put(actions.delete.success({ id, officialId }))
        return response
      },
    ],
  ]
}
