import { generateActions } from './utils'
import * as shared from './shared-definitions'
import merge from 'lodash.merge'

// A list of all available actions for the team entity. The array describes
// the arguments that are passed to the REQUEST action
const offlineDefinition = {
  roster: {
    player: {
      create: ['id', 'rosterId', 'form'],
    },
  },
}

const onlineDefinition = {
  create: ['form'],
  read: ['id'],
  update: ['id', 'form'],
  delete: ['id', 'parentId', 'forceDelete'],

  schedules: {
    read: ['id', 'page'],
  },

  games: {
    read: ['id', 'page', 'starts_at', 'ends_at', 'sort', 'direction'],
  },

  roster: {
    readPlayersBySeason: ['id', 'seasonId'],
    readPlayers: ['id'],
    readTeamOfficials: ['id', 'page', 'sort', 'direction'],

    player: {
      create: ['id', 'rosterId', 'form'],
      read: ['id'],
      update: ['id', 'rosterId', 'playerId', 'form', 'gameId'],
      delete: ['id', 'rosterId', 'playerId'],

      image: {
        create: ['teamId', 'playerId', 'imageData'],
        delete: ['teamId', 'playerId'],
      },
    },

    teamOfficial: {
      create: ['team', 'form', 'rosterId'],
      read: ['id'],
      update: ['team', 'id', 'form', 'rosterId'],
      delete: ['team', 'rosterId', 'id'],
    },
  },

  ...shared.entityImageDefinition,
  ...shared.venuesDefinition,
  ...shared.usersDefinition,
  ...shared.feedDefinition,
}

export const definition = merge({}, onlineDefinition, offlineDefinition)

const onlineActions = generateActions(['TEAM'], onlineDefinition)
const offlineActions = generateActions(['TEAM'], offlineDefinition, true)
const teamActions = merge({}, onlineActions, offlineActions)

export default teamActions
