/** @jsxImportSource @emotion/react */
import React, { useMemo, useState } from 'react'
import css from '@emotion/css/macro'
import { useTable } from 'react-table'
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis, snapCenterToCursor } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { DraggableTableRow } from './DraggableTableRow'
import { StaticTableRow } from './StaticTableRow'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import colors from '@sportninja/common/constants/appColors'
import { font } from '../../../components/css'

const STANDINGS_WIDTH = 524

const Styles = styled.div`
  padding: 1rem;

  table {
    width: 100%;
    margin-top: 16px;
    tr {
      td:last-child {
        color: ${colors.AMERICAN_YELLOW};
      }
      td:first-child {
        color: ${colors.ATTENDANCE_GRAY};
      }
      td {
        font-size: 16px;
        color: white;
        font-weight: 500;
        vertical-align: middle;
      }
    }
    th:last-child {
      color: ${colors.AMERICAN_YELLOW};
    }
    th,
    td {
      text-align: left;
      height: 40px;
      font-size: 14px;
      font-weight: 500;
      color: white;
      margin: 0;
      padding: 8px;
    }
  }
`

const PlacementOrderTable = ({ columns, data, setData }) => {
  const [activeId, setActiveId] = useState()
  const items = useMemo(() => data?.map(({ id }) => id), [data])
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    })

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  )

  const handleDragStart = (event) => {
    setActiveId(event.active.id)
  }

  const handleDragEnd = (event) => {
    const { active, over } = event
    if (active?.id && over?.id) {
      if (active?.id !== over?.id) {
        setData((data) => {
          const oldIndex = items.indexOf(active.id)
          const newIndex = items.indexOf(over.id)
          return arrayMove(data, oldIndex, newIndex)
        })
      }
    }

    setActiveId(null)
  }

  const handleDragCancel = () => {
    setActiveId(null)
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null
    }
    const row = rows.find(({ original }) => original.id === activeId)
    prepareRow(row)
    return row
  }, [activeId, rows, prepareRow])

  const element = document.querySelector('.modal-container')

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <table
        {...getTableProps()}
        css={css`
          tbody tr:nth-child(even) {
            background-color: linear-gradient(#282e38, #181a1d);
          }
          tbody tr:nth-child(odd) {
            background-color: #292e35;
          }
        `}
      >
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, i) => (
                <th key={i} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {rows.map((row, i) => {
              prepareRow(row)
              return <DraggableTableRow key={row.original.id} row={row} />
            })}
          </SortableContext>
        </tbody>
      </table>
      {element &&
        createPortal(
          <DragOverlay
            modifiers={[snapCenterToCursor]}
            dropAnimation={{
              duration: 200,
              easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
              keyframes: function keyframes({ translateX, translateY }) {
                return [
                  {
                    transform: `translate3d(${translateX}px, ${translateY}px, 0)`,
                  },
                ]
              },
            }}
          >
            <Styles>
              {activeId && (
                <div
                  css={css`
                    position: absolute;
                    left: -200px;
                    width: ${STANDINGS_WIDTH}px;
                  `}
                >
                  <table {...getTableProps()}>
                    <tbody {...getTableBodyProps()}>
                      <StaticTableRow row={selectedRow} />
                    </tbody>
                  </table>
                </div>
              )}
            </Styles>
          </DragOverlay>,
          element
        )}
    </DndContext>
  )
}

export default PlacementOrderTable
