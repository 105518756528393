/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { Alert, Space, Spin } from 'antd'
import React from 'react'
import { captionLarge, heading5, weightSemiBold } from 'src/components/css'
import { SwitchInput } from 'src/components/ts-components/SwitchInput'
import { TextInput } from 'src/components/ts-components/TextInput'
import banners from '../../Form/banner-helpers'
import { Separator } from '../GeneralForm'
import { useScheduleContext } from '../ScheduleContext'
import '../styles.scss'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { isCanlan } from '@sportninja/common/utils/customer-name'

type SettingsFormProps = {
  onCancelPressed: () => void
  triggerRefresh: () => void
}

const SettingsForm: React.FC<SettingsFormProps> = ({
  onCancelPressed,
  triggerRefresh,
}) => {
  const {
    isEdit,
    fieldErrors,
    customError,
    error,
    setError,
    isLoading,
    skipTeamOfficialsSignatures,
    setSkipTeamOfficialsSignatures,
    skipOfficialsSignatures,
    setSkipOfficialsSignatures,
    timingPeriodLength,
    setTimingPeriodLength,
    timingExtraPeriodLength,
    setTimingExtraPeriodLength,
    requireGameOfficials,
    setRequireGameOfficials,
    goalsPerForfeit,
    wins,
    ties,
    losses,
    overtimerWin,
    overtimeLoss,
    shootoutLoss,
    setGoalsPerForfeit,
    setWins,
    setTies,
    setLosses,
    setOvertimeWin,
    setOvertimeLoss,
    setShootoutLoss,
    isRegistrationEnabled,
    setIsRegistrationEnabled,
    onSubmit,
    onCancel,
  } = useScheduleContext()

  return (
    <div
      css={css`
        padding-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        max-height: 550px;
        gap: 16px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 16px;
        `}
      >
        {/* Registration ON/OFF */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 16px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <p
              css={css`
                ${heading5}
                ${weightSemiBold}
                color: ${colors.WHITE};
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'BarlowCondensed'};
              `}
            >
              Registration
            </p>
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <SwitchInput
              title='Turn ON Registration'
              subtitle='   When turned ON, users can use the registration flow to create
              their teams for this competition.'
              value={isRegistrationEnabled}
              onChange={(value) => {
                setIsRegistrationEnabled(value)
              }}
            />
          </div>
        </div>
        <Separator />
        {/* Game Setup Settings */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 16px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <p
              css={css`
                ${heading5}
                ${weightSemiBold}
                color: ${colors.WHITE};
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'BarlowCondensed'};
              `}
            >
              Game Setup Settings
            </p>
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <SwitchInput
              title='Skip Team Official Setup'
              subtitle='This option allows you to skip the setup of team officials.'
              value={skipTeamOfficialsSignatures}
              onChange={setSkipTeamOfficialsSignatures}
            />
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <SwitchInput
              title='Require Game Officials'
              subtitle='This option makes at least one game official required for all games.'
              value={requireGameOfficials}
              onChange={setRequireGameOfficials}
            />
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <SwitchInput
              title='Skip Game Officials Signatures'
              subtitle='This option allows you to skip the signatures of officials.'
              value={skipOfficialsSignatures}
              onChange={setSkipOfficialsSignatures}
            />
          </div>
          {/* For now, this is being disabled */}
          {/* <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <SwitchInput
              title='Skip Starting Line-up Selection'
              subtitle='This option allows you to skip the starting line-up.'
              value={skipStartingLineupSelection}
              onChange={setSkipStartingLineupSelection}
            />
          </div> */}
        </div>
        <Separator />
        {/* Timing Settings */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 16px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <p
              css={css`
                ${heading5}
                ${weightSemiBold}
                color: ${colors.WHITE};
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'BarlowCondensed'};
              `}
            >
              Timing Settings
            </p>
          </div>
          <div
            css={css`
              display: flex;
              align-items: flex-start;
              gap: 16px;
              align-self: stretch;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <TextInput
                name={'timingPeriodLength'}
                value={
                  timingPeriodLength !== null
                    ? timingPeriodLength.toString()
                    : ''
                }
                setValue={(value) =>
                  setTimingPeriodLength(value ? parseFloat(value) : null)
                }
                fieldErrors={fieldErrors}
                customError={customError}
                label='Default Period Length'
                type='number'
              />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <TextInput
                name={'timingExtraPeriodLength'}
                value={
                  timingExtraPeriodLength !== null
                    ? timingExtraPeriodLength.toString()
                    : ''
                }
                setValue={(value) =>
                  setTimingExtraPeriodLength(value ? parseFloat(value) : null)
                }
                fieldErrors={fieldErrors}
                customError={customError}
                label='Default Overtime Length'
                type='number'
              />
            </div>
          </div>
        </div>
        {/* Point System */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 16px;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <p
              css={css`
                ${heading5}
                ${weightSemiBold}
                color: ${colors.WHITE};
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'BarlowCondensed'};
              `}
            >
              Point System
            </p>
          </div>
          <div
            css={css`
              display: flex;
              align-items: flex-start;
              gap: 16px;
              align-self: stretch;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <TextInput
                name={'wins'}
                value={wins !== null ? wins.toString() : ''}
                setValue={(value) => setWins(value ? parseFloat(value) : null)}
                fieldErrors={fieldErrors}
                customError={customError}
                label='Wins'
                type='number'
              />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <TextInput
                name={'ties'}
                value={ties !== null ? ties.toString() : ''}
                setValue={(value) => setTies(value ? parseFloat(value) : null)}
                fieldErrors={fieldErrors}
                customError={customError}
                label='Ties'
                type='number'
              />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <TextInput
                name={'losses'}
                value={losses !== null ? losses.toString() : ''}
                setValue={(value) =>
                  setLosses(value ? parseFloat(value) : null)
                }
                fieldErrors={fieldErrors}
                customError={customError}
                label='Losses'
                type='number'
              />
            </div>
          </div>
          <div
            css={css`
              display: flex;
              align-items: flex-start;
              gap: 16px;
              align-self: stretch;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <TextInput
                name={'overtimerWin'}
                value={overtimerWin !== null ? overtimerWin.toString() : ''}
                setValue={(value) =>
                  setOvertimeWin(value ? parseFloat(value) : null)
                }
                fieldErrors={fieldErrors}
                customError={customError}
                label='Overtime Win'
                type='number'
              />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <TextInput
                name={'overtimeLoss'}
                value={overtimeLoss !== null ? overtimeLoss.toString() : ''}
                setValue={(value) =>
                  setOvertimeLoss(value ? parseFloat(value) : null)
                }
                fieldErrors={fieldErrors}
                customError={customError}
                label='Overtime Losses'
                type='number'
              />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <TextInput
                name={'shootoutLoss'}
                value={shootoutLoss !== null ? shootoutLoss.toString() : ''}
                setValue={(value) =>
                  setShootoutLoss(value ? parseFloat(value) : null)
                }
                fieldErrors={fieldErrors}
                customError={customError}
                label='Shootout Losses'
                type='number'
              />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                width: 100%;
              `}
            >
              <TextInput
                name={'goalsPerForfeit'}
                value={
                  goalsPerForfeit !== null ? goalsPerForfeit.toString() : ''
                }
                setValue={(value) =>
                  setGoalsPerForfeit(value ? parseFloat(value) : null)
                }
                fieldErrors={fieldErrors}
                customError={customError}
                label='Goals Per Forfeit'
                type='number'
              />
            </div>
          </div>
        </div>
      </div>
      <div
        css={css`
          position: sticky;
          bottom: 0;
          background-color: ${colors.HEADER};
          z-index: 100;
          padding-bottom: 16px;
          padding-top: 16px;
        `}
      >
        {error ? (
          <Space
            direction='vertical'
            style={{ width: '100%', marginBottom: 8 }}
          >
            <Alert
              message={error || 'An error occurred. Please try again.'}
              type='error'
              showIcon
              closable
            />
          </Space>
        ) : null}
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 16px;
            align-self: stretch;
          `}
        >
          {/* Cancel Button */}
          <div
            css={css`
              max-width: 80px;
              display: flex;
              padding: 8px 24px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              border-radius: 8px;
              border: 1px solid var(--Neutrals-neutrals-0, #fff);
              cursor: ${isLoading ? 'not-allowed' : 'pointer'};
              opacity: ${isLoading ? 0.7 : 1};
            `}
            onClick={() => {
              // Clear the state
              onCancel()
              //   and close the modal
              onCancelPressed()
            }}
          >
            <p
              css={css`
                ${captionLarge}
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'BarlowCondensed'};
                color: ${colors.WHITE};
              `}
            >
              Cancel
            </p>
          </div>
          <div
            css={css`
              max-width: 80px;
              display: flex;
              padding: 8px 24px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              border-radius: 8px;
              background: ${colors.PRIMARY};
              cursor: ${isLoading ? 'not-allowed' : 'pointer'};
              opacity: ${isLoading ? 0.7 : 1};
            `}
            onClick={async () => {
              try {
                if (isLoading) {
                  return
                }
                const response = await onSubmit()
                if (isEdit) {
                  // @ts-ignore
                  banners.entityUpdate(ENTITY_TYPES.schedule)(response)
                } else {
                  banners.entityCreate(ENTITY_TYPES.schedule)(response)
                }
                triggerRefresh()
                onCancelPressed()
                onCancel()
              } catch (e: any) {
                const errorMessage = getErrorMessage(e)
                if (e?.invalid_fields) {
                  if (
                    e?.invalid_fields?.name ||
                    e?.invalid_fields?.abbreviation
                  ) {
                    setError(errorMessage)
                  }
                }
              }
            }}
          >
            <>
              {isLoading ? (
                <Spin
                  size='small'
                  css={css`
                    color: ${colors.WHITE};
                  `}
                />
              ) : (
                <p
                  css={css`
                    ${captionLarge}
                    font-family: ${isCanlan
                      ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                      : 'BarlowCondensed'};
                    color: ${colors.WHITE};
                  `}
                >
                  Save
                </p>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsForm
