import { produce } from 'immer'
import merge from 'lodash.merge'
import mergeWith from 'lodash.mergewith'

import game from '../actions/game'
import team from '../actions/team'
import schedule from '../actions/schedule'
import org from '../actions/org'
import auth from '../actions/auth'
import scoring from '../actions/scoring'

import { arrayToObject } from '../utils/utils'
import { ENTITY_TYPES } from '../sagas/utils'
import { prepareOfflineTransformer, str } from './utils'
import parsers from './parsers'
import scoringReducer from './sub-reducers/scoring'
import gameOfflineTransformer from './game-offline-handler'

const reducer = (draft, { type: _type, payload: _payload, meta }) => {
  const gameParser = parsers[ENTITY_TYPES.game]
  const timelineParser = parsers.timeline

  const { type, payload } = prepareOfflineTransformer(
    _type,
    _payload,
    meta,
    game,
    gameOfflineTransformer,
    draft
  )

  switch (
    type // eslint-disable-line default-case
  ) {
    /* ACTIONS FROM OTHER REDUCERS */
    case str(team.games.read.success):
    case str(schedule.games.read.success):
    case str(org.games.read.success):
      return merge(draft, arrayToObject(payload.data, gameParser))

    case str(auth.logout.success):
      return {}
    /* END ACTIONS FROM OTHER REDUCERS */

    case str(game.readAll.success):
      // Reset the game list if there's nothing there!
      if (payload.data.length === 0) return {}
      // Overwrite the game list always
      return arrayToObject(payload.data, gameParser)

    case str(game.readCustom.success):
    case str(game.readPast.success):
    case str(game.readUpcoming.success):
      return merge(draft, arrayToObject(payload.data, gameParser))

    case str(game.read.success): {
      const playerRosters = draft[payload.data.id]?.playerRosters
      draft[payload.data.id] = { ...gameParser(payload.data), playerRosters }
      return
    }

    case str(game.create.success):
      draft[payload.data.id] = gameParser(payload.data)
      return

    case str(game.readPublic.success):
    case str(game.update.success):
      draft[payload.data.id] = merge(
        draft[payload.data.id],
        gameParser(payload.data)
      )
      return

    case str(game.delete.success):
      delete draft[payload.gameId]
      return

    case str(game.readGameRoster.success): {
      const { id, rosterId, data } = payload

      draft[id] = draft[id] || {}
      draft[id].playerRosters = draft[id].playerRosters || []
      const rosterIdx = draft[id].playerRosters.findIndex(
        (r) => r.id === rosterId
      )

      if (rosterIdx > -1) {
        draft[id].playerRosters[rosterIdx] = data
      } else {
        draft[payload.id].playerRosters.push(data)
      }
      return
    }

    case str(game.readGameRosters.success): {
      draft[payload.id] = draft[payload.id] || {}
      draft[payload.id].playerRosters = payload.data
      return
    }

    case str(game.updateGameRoster.success): {
      const game = draft[payload.id]
      if (game && Array.isArray(game.playerRosters)) {
        let roster = game.playerRosters.find(
          (roster) => roster.id === payload.rosterId
        )
        if (roster) {
          roster = merge(roster, payload.data)
          // roster = mergeWith(
          //   roster,
          //   payload.data,
          //   (objValue, srcValue, key) => {
          //     if (key !== 'players') return undefined
          //     return mergeWith(objValue, srcValue, (obj, src) => {
          //       console.log(obj, src)
          //       return { ...obj, ...src }
          //     })
          //   }
          // )
        } else {
          game.playerRosters.push(payload.data)
        }
      } else {
        game.playerRosters = payload.data
      }
      return
    }

    case str(game.updateGameRosterPlayer.success): {
      const game = draft[payload.id]
      if (game && Array.isArray(game.playerRosters)) {
        const roster = game.playerRosters.find((r) => r.id === payload.rosterId)
        if (roster) {
          const playerIndex = roster?.players?.findIndex(
            (p) => p.id === payload.playerId
          )
          if (playerIndex > -1) {
            merge(roster.players[playerIndex], payload.data)
          }
        }
      }
      return
    }

    case str(game.createGameRosterPlayer.success): {
      const game = draft[payload.id]
      if (game && Array.isArray(game.playerRosters)) {
        const roster = game.playerRosters.find((r) => r.id === payload.rosterId)
        if (roster) {
          const playerIndex = roster?.players?.findIndex(
            (p) => p.id === payload.playerId
          )
          if (playerIndex > -1) {
            merge(roster.players[playerIndex], payload.data)
          }
        }
      }
      return
    }

    case str(game.deleteGameRoster.success): {
      const { id, rosterId } = payload
      const game = draft[id]

      if (game && Array.isArray(game.playerRosters)) {
        let rosterIdx = game.playerRosters.findIndex(
          (roster) => roster.id === rosterId
        )

        if (rosterIdx > -1) draft[id].playerRosters.splice(rosterIdx, 1)
      }
      return
    }

    case str(game.readPublicTimeline.success):
    case str(game.readTimeline.success): {
      const { id, data } = payload
      draft[id] = mergeWith(
        draft[id],
        timelineParser(data),
        (objValue, srcValue) => {
          return typeof srcValue !== 'undefined' ? srcValue : objValue
        }
      )
      return
    }

    case str(game.readSignatures.success): {
      const { id, data } = payload
      draft[id].signatures = data
      return
    }
  }
}

export default (state = {}, action) =>
  produce(state, (draft) => {
    // We want to handle scoring actions here, in the game reducer.
    scoringReducer(scoring)(draft, action)

    return reducer(draft, action)
  })
