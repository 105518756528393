import { call, put, takeEvery } from 'redux-saga/effects'
import dayjs from 'dayjs'

import { toISOString } from '../utils/utils'
import { ROUTES } from '../constants/app'
import appErrorActions from '../actions/appError'

export const ENTITY_TYPES = {
  org: 'organizations',
  team: 'teams',
  schedule: 'schedules',
  game: 'games',

  users: 'users',
  user: 'user',
  invite: 'invitations',
  player: 'players',
  salesforce: 'salesforce',
  division: 'division',
  conference: 'conference',
  venue: 'venues',
  roster: 'roster',
  gameSlots: 'gameSlots',
  officials: 'officials',
}

export const ENTITY_TYPE_INFO = {
  [ENTITY_TYPES.org]: {
    linkRoot: ROUTES.ORG_ROOT,
    name: 'org',
    fullName: 'organization',
    icon: 'users',
  },
  [ENTITY_TYPES.team]: {
    linkRoot: ROUTES.TEAM_ROOT,
    name: 'team',
    fullName: 'team',
    icon: 'user-friends',
  },
  [ENTITY_TYPES.schedule]: {
    linkRoot: ROUTES.SCHEDULE_ROOT,
    name: 'comp',
    fullName: 'competition',
    icon: 'calendar-alt',
  },
  [ENTITY_TYPES.game]: {
    linkRoot: ROUTES.GAME_ROOT,
    name: 'game',
    fullName: 'game',
  },
  [ENTITY_TYPES.player]: {
    linkRoot: ROUTES.PLAYER_ROOT,
    name: 'player',
    fullName: 'player',
    icon: 'user',
  },
  [ENTITY_TYPES.venue]: {
    linkRoot: ROUTES.SCHEDULES,
    name: 'venue',
    fullName: 'venue',
    icon: 'user',
  },
}

// Generates a saga which responds to a promiseAction. Automatically calls the
// Promise's resolve or reject depending on success of the API call.
// Resolves to whatever you return from your provided saga
const sagaFactory = (actionSet, saga) =>
  function* ({ payload, meta }) {
    try {
      const successPayload = yield call(saga, payload)
      if (typeof meta?.resolve === 'function') {
        yield call(meta.resolve, successPayload)
      }
    } catch (error) {
      console.log('Saga error:', error)
      yield put(actionSet.failure(error, error))
      if (typeof meta?.reject === 'function') {
        yield call(meta.reject, error, error)
      }
    }
  }

export const generateSagas = (methods) => {
  return methods.map(([actions, sagaHandler]) => {
    return takeEvery(actions.request, sagaFactory(actions, sagaHandler))
  })
}

export const processForm = (form) => {
  const {
    primaryId,
    primaryLocation,
    season_currency,
    season_type,
    tax_rate,
    online_registration_open,
    ...body
  } = form
  const settings = {}

  season_currency && (settings.season_currency = season_currency)
  tax_rate && (settings.tax_rate = tax_rate)
  if (typeof online_registration_open !== 'undefined')
    settings.online_registration_open = online_registration_open
  season_type && (settings.season_type = season_type)

  return { body: { ...body, settings }, primaryId, primaryLocation }
}

// The `request` actions we generate programatically expect to be wrapped in a
// promise via `bindActionToPromise` (see src/actions/utils.js)
// Since we don't have use of that in a saga context, use this method to do the
// same thing if you need to dispatch a `request` action from within a saga.
export const wait = function* (action, ...args) {
  let res, rej
  const promise = new Promise((resolve, reject) => {
    res = resolve
    rej = reject
  })

  yield put(action(res, rej, ...args))
  yield promise
}

export const getYearInTheFuture = () => toISOString(dayjs().add(1, 'year'))
export const getThisVeryMoment = () => toISOString(dayjs())
