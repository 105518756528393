/* globals __DEV__ */
/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import gameActions from '@sportninja/common/actions/game'
import orgActions from '@sportninja/common/actions/org'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import { ROUTES } from '@sportninja/common/constants/app'
import { t } from '@sportninja/common/i18n'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { makeGetGame } from '@sportninja/common/selectors/game'
import {
  getAllGenericSportProperties,
  getGameStatusTypesBySportId,
  getGameTypesBySportId,
  getSoccerSport,
  getSportById,
} from '@sportninja/common/selectors/types'
import { useReadWithPagination } from '@sportninja/common/utils/utils'
import { push } from 'connected-react-router'
import { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import ActionButton from 'src/components/ActionButton'
import { NewModal } from 'src/components/NewModal'
import FormSheet from '../../components/Form/FormSheet'
import { gameForm } from '../../components/Form/form-configs'
import Highlights from '../../components/Highlights'
import { Column, Container } from '../../components/Layout'
import PageWrap from '../../components/PageWrap'
import { Mobile } from '../../components/Responsive'
import { backgroundColorLight } from '../../components/css'
import EditEntitySubTitle from '../EditEntitySubTitle'
import { createSubPages } from '../utils'
import BambuserPlayer from './BambuserPlayer'
import Details from './Details'
import GamePageHeader from './GamePageHeader'
import GameRoster from './GameRoster'
import LiveVideo from './LiveVideo'
import PlayerStatistics from '../../components/v2/PlayerStatistics'
import Scoresheet from './Scoresheet'
import Timeline from './Timeline'

import { tutorialKeys } from '@sportninja/common/constants/userGuiding'
import {
  getTutorial,
  setTutorial,
} from '@sportninja/common/utils/TutorialHelper'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { driver } from 'driver.js'
import DownloadAppModal from 'src/components/DownloadAppModal'
import OpenInAppModal from 'src/components/OpenInAppModal'
import { getGameDetailsScreenSteps } from 'src/constants/TutorialSteps'
import SignReport from './SignInReport'
import GameStatusModal from 'src/components/GameStatusModal'
import UploadVideoModal from 'src/components/UploadVideoModal'
import NewGamePageHeader from './NewGamePageHeader'
import org from '@sportninja/common/reducers/org'
import NewMobileGamePageHeader from './NewMobileGamePageHeader'

const driverObj = driver({
  allowClose: false,
  popoverClass: 'driverjs-theme',
  showProgress: false,
})

const Game = ({
  isPublicRoute,
  backToParentSchedule,
  game,
  gameTypes,
  inFrame,
  match: {
    params: { id },
  },
  readGame,
  updateGame,
  deleteGame,
  readPublicGame,
  readOrg,
  isSoccer = false,
  genericSportProperties,
  sport,
  gameStatusTypes,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const isMobile = windowWidth < 768
  const scoreModalRef = useRef(null)
  const openInAppModalRef = useRef(null)
  const gameStatusModalRef = useRef(null)
  const videoUploadModalRef = useRef(null)
  const [disableTabNavigation, setDisableTabNavigation] = useState(false)
  const [ifNecessaryStatus, setIfNecessaryStatus] = useState(false)
  const hasPermission =
    game?.permission?.update || game?.permission?.admin || false
  const canScore = game?.permission?.score || game?.permission?.admin
  const genericSportForGame = useMemo(() => {
    if (game?.generic_sport_id) {
      return genericSportProperties.find((s) => s.id === game.generic_sport_id)
    }
    return null
  }, [game, genericSportProperties])
  const isGenericGame =
    game?.generic_sport_id !== null && game?.generic_sport_id !== undefined
  const PAGES = {
    details: t('common:details'),
    'home-roster': t('Web:homeRoster'),
    'visitor-roster': t('Web:visitorRoster'),
  }

  const isHomeTeamEmptyRoster = !game.homeGameRosterId
  const isVisitingTeamEmptyRoster = !game.visitingGameRosterId

  const isHomeTeamPlaceholder =
    game &&
    game?.homeTeamSlot &&
    game?.homeTeamSlot.id &&
    game?.homeTeamSlot.team_id === null
  const isVisitingTeamPlaceholder =
    game &&
    game?.visitingTeamSlot &&
    game?.visitingTeamSlot.id &&
    game?.visitingTeamSlot.team_id === null

  if (!isPublicRoute) {
    PAGES.highlights = 'Highlights'
  }

  if (!isPublicRoute && game.completed && !isGenericGame) {
    PAGES.statistics = t('Web:statistics')
  }

  if (game.started) {
    PAGES.timeline = t('routeNames:timeline')
  }

  // PAGES.watch = 'Watch'

  if (!isPublicRoute) {
    PAGES.scoresheet = t('Web:scoresheet')
    if (isCanlan) {
      PAGES.signin = 'Sign-In Sheet'
    }
  }

  // if (__DEV__) {
  //   PAGES.signatures = 'Signatures'
  // }

  const [subPages, routes] = createSubPages(
    isPublicRoute ? ROUTES.PUBLIC_GAME_ROOT : ROUTES.GAME_ROOT,
    id,
    PAGES,
    {
      hasPermission: hasPermission || game.permission?.admin,
      privatePageCount: isPublicRoute ? 0 : 1,
    }
  )
  const [loaded, error] = useReadWithPagination(
    isPublicRoute ? readPublicGame : readGame,
    id
  )

  let shouldDisplayGameStatus = game && hasPermission

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const actions = [
    shouldDisplayGameStatus && (
      <ActionButton
        id='game-status-button'
        key='game-status'
        label={'Game Status'}
        icon='tasks'
        onClick={async () => {
          gameStatusModalRef?.current?.openModal()
        }}
        alwaysShowLabel={true}
      />
    ),
    game && (
      <ActionButton
        id='video-upload-button'
        key='video-upload-button'
        label={'Video Upload'}
        icon='video'
        onClick={async () => {
          videoUploadModalRef?.current?.openModal()
        }}
        alwaysShowLabel={true}
      />
    ),
    game && (
      <ActionButton
        id='open-in-app-button'
        key='open-in-app'
        label={'Open in App'}
        icon='qrcode'
        onClick={async () => {
          openInAppModalRef?.current?.openModal()
        }}
        alwaysShowLabel={true}
      />
    ),
    game && canScore && !isCanlan && (
      <ActionButton
        id='tutorial-score-button'
        key='score'
        label={t('Web:score')}
        icon='mobile-alt'
        onClick={async () => {
          await setTutorial(tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_3, false)
          driverObj?.destroy()
          scoreModalRef?.current?.openModal()
        }}
        alwaysShowLabel={true}
      />
    ),
    game && hasPermission && (
      <Route
        key='edit'
        // exact
        // path={routes.details}
        render={() => (
          <FormSheet
            alwaysShowIconLabel={true}
            key='edit-game'
            icon='pencil-alt'
            form={gameForm(
              {
                ...game,
                ifNecessaryStatus,
                onSelectIfNecessary: () =>
                  setIfNecessaryStatus(!ifNecessaryStatus),
              },
              false,
              game.sport_id
            )}
            label={t('Web:editGame')}
            onDelete={
              game.permission?.delete && !game?.rootSchedule?.is_tournament
                ? async (...args) => {
                    await deleteGame(game.parentId, game.id, ...args)
                    backToParentSchedule(game.rootSchedule?.id)
                  }
                : undefined
            }
            onSubmit={async (...args) => {
              try {
                const obj =
                  args.length === 1
                    ? args[0]
                    : args.find((el) => el.if_necessary_status !== undefined)
                const rest = args.filter((el) => el !== obj)
                const result = [
                  ...rest,
                  {
                    ...obj,
                    venue_id: obj?.venue_id ?? null,
                    if_necessary_status: ifNecessaryStatus,
                  },
                ]
                // If the TeamSlot exist, thats  tournament
                // and we should prevent the update of the team_id
                // if the team_id is the same as the teamSlot id
                if (result && result.length > 0) {
                  if (game?.homeTeamSlot && game?.homeTeamSlot?.id) {
                    if (result[0]?.home_team_id === game?.homeTeamSlot?.id) {
                      delete result[0].home_team_id
                    }
                  }
                  if (game?.visitingTeamSlot && game?.visitingTeamSlot?.id) {
                    if (
                      result[0]?.visiting_team_id === game?.visitingTeamSlot?.id
                    ) {
                      delete result[0].visiting_team_id
                    }
                  }
                }
                return await updateGame(game.id, ...result)
              } finally {
                readGame(game.id)
              }
            }}
            title={t('Web:editGame')}
            subTitle={
              <EditEntitySubTitle id={game.id} entityType={ENTITY_TYPES.game} />
            }
          />
        )}
      />
    ),
  ]

  const fullGameType = gameTypes?.find((gameType) => {
    return parseInt(gameType?.id, 10) === game?.gameType
  })

  useEffect(() => {
    if (game?.ifNecessaryStatus) {
      setIfNecessaryStatus(game.ifNecessaryStatus)
    }
  }, [game?.ifNecessaryStatus])

  const isCanlanVideo = game?.rootSchedule?.id === '1TNsv6KEoPSNtjem'
  const isPlanetIceVideo = game?.rootSchedule?.id === 'pVPyixy0KqSfOJdT'
  // ReferenceError: Cannot access before initialization for useState variable
  // const [isTutorial, setIsTutorial] = useState(false)

  useEffect(() => {
    const readTutorial = async () => {
      try {
        const tutorialInfo = await getTutorial(
          tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_3
        )
        if (tutorialInfo.tutorialStep.value === 'true') {
          // setIsTutorial(true)
          setTimeout(() => {
            const steps = getGameDetailsScreenSteps(driverObj)
            driverObj.setSteps(steps)
            driverObj.drive()
          }, 2000)
        }
      } catch (e) {
        console.error(`Failed to read tutorial: ${e}`)
      }
    }

    readTutorial()
  }, [])

  console.log('game', game)

  return (
    <>
      <PageWrap
        extraOffset={54}
        error={error}
        loaded={loaded}
        pages={subPages}
        navigationDisabled={disableTabNavigation}
        pageHeader={
          game?.broadcast_video_url && !isMobile && !inFrame ? (
            <NewGamePageHeader
              videoUrl={game?.broadcast_video_url}
              game={game}
              organization={
                game?.rootSchedule?.organization_id
                  ? {
                      id: game?.rootSchedule?.organization_id,
                      name: game?.organization_name,
                    }
                  : null
              }
              actions={inFrame ? [] : actions}
              fullGameType={fullGameType}
              isMobile={isMobile}
              windowWidth={windowWidth}
            />
          ) : game?.broadcast_video_url && isMobile && !inFrame ? (
            <NewMobileGamePageHeader
              videoUrl={game?.broadcast_video_url}
              game={game}
              organization={
                game?.rootSchedule?.organization_id
                  ? {
                      id: game?.rootSchedule?.organization_id,
                      name: game?.organization_name,
                    }
                  : null
              }
              actions={inFrame ? [] : actions}
              fullGameType={fullGameType}
              isMobile={isMobile}
              windowWidth={windowWidth}
            />
          ) : (
            <GamePageHeader
              genericSportForGame={genericSportForGame}
              error={error}
              actions={inFrame ? [] : actions}
              game={game}
              fullGameType={fullGameType}
              inFrame={inFrame}
              organization={
                game?.rootSchedule?.organization_id
                  ? {
                      id: game?.rootSchedule?.organization_id,
                      name: game?.organization_name,
                    }
                  : null
              }
              disabled={disableTabNavigation}
              isSoccer={isSoccer}
              sport={sport}
            />
          )
        }
      >
        <Mobile>
          {(isMobile) => {
            return (
              <div
                css={
                  isMobile &&
                  css`
                    position: relative;
                    margin-top: -40px;
                    padding-top: 40px;
                    background-color: ${inFrame
                      ? 'transparent'
                      : backgroundColorLight};
                  `
                }
              >
                <Switch>
                  <Route
                    exact
                    path={routes.details}
                    render={(props) => (
                      <Details
                        game={game}
                        {...props}
                        isSoccer={isSoccer}
                        isGenericGame={isGenericGame}
                      />
                    )}
                  />

                  {game.started && (
                    <Route
                      exact
                      path={routes.timeline}
                      render={(props) => (
                        <Timeline
                          gameId={game.id}
                          isPublicRoute={isPublicRoute}
                          setDisableTabNavigation={setDisableTabNavigation}
                          isSoccer={isSoccer}
                          sportId={game?.sport_id}
                          isGenericGame={isGenericGame}
                          {...props}
                        />
                      )}
                    />
                  )}

                  <Route
                    exact
                    path={routes['home-roster']}
                    render={(props) => (
                      <GameRoster
                        key='home'
                        isPublicRoute={isPublicRoute}
                        gameId={game.id}
                        isGameStarted={game.started}
                        rosterId={game.homeGameRosterId}
                        isPlaceholder={isHomeTeamPlaceholder}
                        isEmptyRoster={isHomeTeamEmptyRoster}
                        sportId={game.sport_id}
                        {...props}
                      />
                    )}
                  />

                  <Route
                    exact
                    path={routes['visitor-roster']}
                    render={(props) => (
                      <GameRoster
                        key='visiting'
                        isPublicRoute={isPublicRoute}
                        gameId={game.id}
                        isGameStarted={game.started}
                        rosterId={game.visitingGameRosterId}
                        isPlaceholder={isVisitingTeamPlaceholder}
                        isEmptyRoster={isVisitingTeamEmptyRoster}
                        sportId={game.sport_id}
                        {...props}
                      />
                    )}
                  />

                  {!isPublicRoute && (
                    <Route
                      exact
                      path={routes.scoresheet}
                      render={() => (
                        <Scoresheet gameNumber={game.gameNumber} id={id} />
                      )}
                    />
                  )}

                  {!isPublicRoute && isCanlan && (
                    <Route
                      exact
                      path={routes.signin}
                      render={() => (
                        <SignReport gameNumber={game.gameNumber} id={id} />
                      )}
                    />
                  )}

                  {!isPublicRoute && game.completed && !isGenericGame && (
                    <Route
                      exact
                      path={routes.statistics}
                      render={() => (
                        <PlayerStatistics
                          id={id}
                          homeTeamId={game.homeTeamId}
                          visitingTeamId={game.visitingTeamId}
                          isSoccer={isSoccer}
                          defaultSortOrder={isSoccer ? 'G' : 'P'}
                          isInFrame={inFrame}
                          sport={sport}
                        />
                      )}
                    />
                  )}

                  <Route
                    exact
                    path={routes.watch}
                    render={() => {
                      if (isCanlanVideo || isPlanetIceVideo) {
                        return (
                          <LiveVideo
                            gameId={game.id}
                            startedAt={game.startedAt}
                            hasCompleted={game.completed}
                          />
                        )
                      }

                      return (
                        <BambuserPlayer
                          game={game}
                          isPublicRoute={isPublicRoute}
                        />
                      )
                    }}
                  />

                  {/* {__DEV__ && (
                    <Route
                      exact
                      path={routes.signatures}
                      render={() => {
                        return <DevSignatureList game={game} />
                      }}
                    />
                  )} */}

                  {!isPublicRoute && (
                    <Route
                      exact
                      path={routes.highlights}
                      render={() => {
                        return (
                          <Container
                            css={css`
                              min-height: 100vh;
                            `}
                          >
                            <Column
                              fullWidth
                              column
                              // eslint-disable-next-line react-native/no-inline-styles
                              style={{
                                width: '100%',
                                marginBottom: 40,
                                marginTop: -24,
                              }}
                            >
                              <Highlights requestUrl={`games/${id}`} />
                            </Column>
                          </Container>
                        )
                      }}
                    />
                  )}
                </Switch>
              </div>
            )
          }}
        </Mobile>
      </PageWrap>
      <NewModal ref={openInAppModalRef} shouldCloseOnOverlayClick={true}>
        <OpenInAppModal
          onPressClose={() => {
            openInAppModalRef?.current?.closeModal()
          }}
          gameId={game?.id}
          isTutorial={false}
        />
      </NewModal>
      <NewModal ref={scoreModalRef} shouldCloseOnOverlayClick={true}>
        <DownloadAppModal
          onPressClose={() => {
            scoreModalRef?.current?.closeModal()
          }}
          gameId={game?.id}
          isTutorial={true}
        />
      </NewModal>
      <NewModal ref={gameStatusModalRef} shouldCloseOnOverlayClick={true}>
        <GameStatusModal
          onPressClose={() => {
            gameStatusModalRef?.current?.closeModal()
          }}
          gameCompleted={game?.completed}
          gameId={game?.id}
          gameStatus={game?.gameStatus}
          gameStatusTypes={gameStatusTypes}
          readGame={readGame}
        />
      </NewModal>
      <NewModal ref={videoUploadModalRef} shouldCloseOnOverlayClick={false}>
        <UploadVideoModal
          onPressClose={() => {
            videoUploadModalRef?.current?.closeModal()
          }}
          gameId={game?.id}
          isTutorial={false}
          readGame={async () => await readGame(game.id)}
          broadcastVideoUrl={game?.broadcast_video_url}
        />
      </NewModal>
    </>
  )
}

const makeMapStateToProps = () => {
  const getGameById = makeGetGame()

  const mapStateToProps = (state, props) => {
    const game = getGameById(
      state,
      props.match.params.id?.replace('?sandbox_tour=1', '')
    )
    const sportId = game?.sport_id
    const gameTypes = getGameTypesBySportId(state, sportId)
    const isSoccer = game?.sport_id === getSoccerSport(state)?.id
    const sport = getSportById(state, sportId)
    return {
      inFrame: state.auth.inFrame,
      game,
      gameTypes,
      isSoccer,
      genericSportProperties: getAllGenericSportProperties(state),
      sport,
      gameStatusTypes: getGameStatusTypesBySportId(state, sportId),
    }
  }

  return mapStateToProps
}

const mapDispatchToProps = (dispatch) => {
  return {
    backToParentSchedule: (scheduleId) =>
      dispatch(push(`${ROUTES.SCHEDULE_ROOT}/${scheduleId}`)),
    readGame: bindActionToPromise(dispatch, gameActions.read.request),
    readPublicGame: bindActionToPromise(
      dispatch,
      gameActions.readPublic.request
    ),
    updateGame: bindActionToPromise(dispatch, gameActions.update.request),
    deleteGame: bindActionToPromise(dispatch, gameActions.delete.request),
    readOrg: bindActionToPromise(dispatch, orgActions.read.request),
  }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(Game)
