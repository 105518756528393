/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { font } from '../css'
import Icon from '../Icon'

export const BANNER_TYPES = {
  error: { type: 'error', message: 'Oops! Unable to finish your import.' },
  info: { type: 'info' },
  success: { type: 'success', message: 'Your import is complete.' },
}

export const TYPES = {
  [BANNER_TYPES.error.type]: {
    bgColor: '#FCDEDE',
    textColor: '#860000',
    iconName: 'times-circle',
    title: 'Error: ',
  },
  [BANNER_TYPES.info.type]: {
    bgColor: '#E6F2FD',
    textColor: '#1966B5',
    iconName: 'info-circle',
    title: 'Info: ',
  },
  [BANNER_TYPES.success.type]: {
    bgColor: '#F2FAF3',
    textColor: '#048039',
    iconName: 'check-circle',
    title: 'Success: ',
  },
}

const ImportCSVBanner = ({ message, type }) => {
  const bannerType = TYPES[type]
  if (!bannerType) {
    return false
  }

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        border-radius: 8px;
        background-color: ${bannerType.bgColor};
        color: ${bannerType.textColor};
        padding: 10px 0;
        margin-bottom: 16px;
      `}
    >
      <Icon
        css={css`
          padding: 0 10px;
        `}
        name={bannerType.iconName}
        color={bannerType.textColor}
        fontSize={20}
      />
      <div
        css={css`
          padding-right: 4px;

          span {
            font-weight: bold;
          }
        `}
      >
        <span>{bannerType.title}</span> {message}
      </div>
    </div>
  )
}

export default ImportCSVBanner
