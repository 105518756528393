import styled from '@emotion/styled/macro'

import { flairColor, flairColorLight, font } from '../css'
import { media } from '../Responsive'

export const InviteWrap = styled.div`
  ${media.mobile} {
    margin: unset;
  }

  margin-left: 8px;

  .notification-list {
    margin-right: 8px;
  }
`

export const Invite = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  .pip-container {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -18px;

    ${media.mobile} {
      left: -13px;
    }
  }

  .pip {
    height: 6px;
    width: 6px;
    min-width: 6px;
    min-height: 6px;

    background: linear-gradient(
      0deg,
      ${flairColor} 0%,
      ${flairColorLight} 100%
    );
    border-radius: 50%;
  }

  .nav-link {
    ${font.body}
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0.32px;
    line-height: 16px;
    text-transform: initial;
  }

  .title {
    position: relative;
    font-size: 14px;
    font-weight: bold;
  }

  .type {
    font-size: 13px;
    opacity: 0.6;
    margin: 2px 0;
  }

  .date {
    font-size: 12px;
    opacity: 0.4;
    margin-right: 6px;
  }
`

export const Refresh = styled.div`
  margin-top: 20px;

  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  text-transform: capitalize;
`
