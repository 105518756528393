/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import { forwardRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { downArrowCss, backgroundColorLight } from '../css'
import { inputCss, InputWrapper, Label } from '../Form/css'
import Icon from '../Icon'
import { ReactComponent as DropdownArrow } from '../Suspensions/dropdown.svg'

const Wrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`

const StyledSelect = styled.select`
  ${inputCss}
  padding-right: 30px;
  cursor: pointer;

  option {
    background: ${backgroundColorLight};
  }

  /* We want the placeholder text to be grey, but selected text to be white */
  :required:invalid {
    color: rgba(255, 255, 255, 0.6);
  }
`

// eslint-disable-next-line react/display-name
const Select = forwardRef(({ children, wrapProps, ...props }, ref) => (
  <Wrap {...wrapProps}>
    <StyledSelect ref={ref} {...props}>
      {children}
    </StyledSelect>
    <div
      css={css`
        pointer-events: none;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        min-width: 24px;
        min-height: 24px;
        margin-right: 8px;
        cursor: pointer;

        ${props.disabled &&
        css`
          cursor: auto;
          path {
            fill: rgba(255, 255, 255, 0.7);
          }
        `}
      `}
    >
      <DropdownArrow />
    </div>
  </Wrap>
))

export const SelectFormInput = ({
  autoComplete,
  children,
  className,
  defaultValue,
  disabled,
  hasError,
  label,
  name,
  noFlex,
  onChange,
  readOnly,
  required,
  value,
}) => {
  const isUncontrolled = typeof value === 'undefined'
  const [controlledValue, setValue] = useState(defaultValue || '')

  const handleChange = (event) => {
    setValue(event.target.value)
    onChange(event)
  }

  // Update the component if the value supplied changes (which it often does)
  useEffect(() => {
    if (isUncontrolled) setValue(defaultValue)
  }, [defaultValue, isUncontrolled])

  return (
    <InputWrapper disabled={disabled} className={className} noFlex={noFlex}>
      <Label hasError={hasError} htmlFor={name}>
        {label}
        {required && ' *'}
      </Label>
      <Select
        autoComplete={autoComplete}
        disabled={disabled}
        hasError={hasError}
        id={name}
        name={name}
        onChange={isUncontrolled ? handleChange : onChange}
        readOnly={readOnly}
        required={required}
        value={isUncontrolled ? controlledValue : value}
      >
        {children}
      </Select>
    </InputWrapper>
  )
}

SelectFormInput.propTypes = {
  autoComplete: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  noFlex: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.any,
}

export default Select
