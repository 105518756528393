/** @jsxImportSource @emotion/react */

import {
  createRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import Modal from 'react-modal'
import type { EmotionJSX } from '@emotion/react/types/jsx-namespace'

Modal.setAppElement('#root')

interface Props {
  children: React.ReactNode | EmotionJSX.Element
  shouldCloseOnOverlayClick?: boolean
}

type NewModal = {
  openModal: () => void
  closeModal: () => void
}

const modalRef = createRef<NewModal>()

const NewModal = forwardRef(
  ({ children, shouldCloseOnOverlayClick = true }: Props, ref) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = useCallback(() => {
      setIsOpen(true)
    }, [])

    const closeModal = useCallback(() => {
      setIsOpen(false)
    }, [])

    useImperativeHandle(ref, () => ({
      openModal,
      closeModal,
    }))

    return (
      <Modal
        shouldCloseOnEsc={false}
        isOpen={isOpen}
        closeTimeoutMS={300}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            transition: 'all 0.3s ease',
            backgroundColor: 'transparent',
            border: 0,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            zIndex: 10000,
            transition: 'all 0.3s ease',
          },
        }}
        contentLabel='SportNinja Modal'
      >
        {children}
      </Modal>
    )
  }
)

NewModal.displayName = 'NewModal'

export { NewModal, modalRef }
