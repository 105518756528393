import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'

import './index.scss'

class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.root = document.getElementById('modal-root')
  }

  componentDidMount() {
    document.addEventListener('keyup', this.dismissModal)
    this.root.addEventListener('click', this.dismissModal)
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.dismissModal)
    this.root.removeEventListener('click', this.dismissModal)
  }

  dismissModal = (event) => {
    if (!this.props.shouldCloseOnOverlayClick) {
      return
    }

    const { keyCode, target, type } = event
    if (
      this.props.isOpen &&
      ((type === 'click' && target === this.container) ||
        (type === 'keyup' && keyCode === 27)) // Escape key
    ) {
      event.preventDefault()
      event.stopPropagation()
      this.props.onClose()
    }
  }

  render() {
    const content = (
      <CSSTransition
        classNames='modal'
        timeout={0}
        in={this.props.isOpen}
        mountOnEnter
        unmountOnExit
      >
        {(transitionState) => {
          return (
            <div
              className={`modal-container flex ${transitionState}`}
              ref={(r) => (this.container = r)}
            >
              {this.props.children}
            </div>
          )
        }}
      </CSSTransition>
    )
    return ReactDOM.createPortal(content, this.root)
  }
}

Modal.defaultProps = {
  onClose: () => {},
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  shouldCloseOnOverlayClick: PropTypes.bool,
}

export default Modal
