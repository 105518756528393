import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { TypesService } from './service'

export class RegistrationTypesStore {
  rootStore

  condition_type = []
  gender_type = []
  age_range_type = []
  currency_type = []
  day_of_week_type = []
  registration_status_type = []
  registration_payment_issue_type = []
  registration_schedule_type = []

  constructor(rootStore) {
    this.rootStore = rootStore

    makeObservable(this, {
      condition_type: observable,
      gender_type: observable,
      age_range_type: observable,
      currency_type: observable,
      day_of_week_type: observable,
      registration_status_type: observable,
      registration_payment_issue_type: observable,
      registration_schedule_type: observable,
      fetchTypes: action,
      state: computed,
    })
  }

  fetchTypes = () => {
    TypesService.fetchTypes()
      .then((types) => {
        for (let i = 0; i < types.length; i++) {
          const item = types[i]

          if (Object.prototype.hasOwnProperty.call(item, 'condition_type')) {
            runInAction(() => {
              this.condition_type = item.condition_type
            })
          }

          if (Object.prototype.hasOwnProperty.call(item, 'gender_type')) {
            runInAction(() => {
              this.gender_type = item.gender_type
            })
          }

          if (Object.prototype.hasOwnProperty.call(item, 'age_range_type')) {
            runInAction(() => {
              this.age_range_type = item.age_range_type
            })
          }

          if (Object.prototype.hasOwnProperty.call(item, 'currency_type')) {
            runInAction(() => {
              this.currency_type = item.currency_type
            })
          }

          if (Object.prototype.hasOwnProperty.call(item, 'day_of_week_type')) {
            runInAction(() => {
              this.day_of_week_type = item.day_of_week_type
            })
          }

          if (
            Object.prototype.hasOwnProperty.call(
              item,
              'registration_status_type'
            )
          ) {
            runInAction(() => {
              this.registration_status_type = item.registration_status_type
            })
          }

          if (
            Object.prototype.hasOwnProperty.call(
              item,
              'registration_payment_issue_type'
            )
          ) {
            runInAction(() => {
              this.registration_payment_issue_type =
                item.registration_payment_issue_type
            })
          }

          if (
            Object.prototype.hasOwnProperty.call(
              item,
              'registration_schedule_type'
            )
          ) {
            runInAction(() => {
              this.registration_schedule_type = item.registration_schedule_type
            })
          }
        }
      })
      .catch((error) => console.error('Error fetching types', error))
  }

  get state() {
    return {
      condition_type: this.condition_type,
      gender_type: this.gender_type,
      age_range_type: this.age_range_type,
      currency_type: this.currency_type,
      day_of_week_type: this.day_of_week_type,
      registration_status_type: this.registration_status_type,
      registration_payment_issue_type: this.registration_payment_issue_type,
      registration_schedule_type: this.registration_schedule_type,
    }
  }
}
