import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { ImageWrap, Image } from './css'
import Icon from '../Icon'

const iconFontSize = {
  xxxsmall: 10,
  kxxsmall: 12,
  skxxsmall: 14,
  xxsmall: 14,
  kxsmall: 14,
  xsmall: 14,
  ksmall: 18,
  small: 20,
  'sm-md': 25,
  medium: 30,
  'md-lg': 35,
  large: 40,
  xlarge: 50,
}
const dimensions = {
  xxxsmall: 24,
  kxxsmall: 30,
  skxxsmall: 32,
  xxsmall: 34,
  kxsmall: 40,
  xsmall: 44,
  ksmall: 48,
  small: 54,
  'sm-md': 64,
  medium: 80,
  'md-lg': 96,
  large: 108,
  xlarge: 122,
}

const Picture = ({
  alt,
  className,
  fill,
  fit,
  iconName,
  imageId,
  imageData,
  noBorder,
  size,
  square,
}) => {
  const mounted = useRef(false)
  const [lastImageId, setLastImageId] = useState()
  const [isLoading, setIsLoading] = useState(!imageData)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    if (imageId !== lastImageId) {
      mounted.current && setIsLoading(true)
      const img = new window.Image()
      img.onload = () => {
        mounted.current && setIsLoading(false)
        mounted.current && setLastImageId(imageId)
      }
      img.src = imageId
    }
  }, [imageId, mounted.current, lastImageId])

  const imageSize = dimensions[size]

  const imageProps = {
    size: imageSize * (fit && !fill ? 0.8 : 1),
    noBorder,
    noFlex: fit && !fill ? true : false,
    square,
  }

  return (
    <ImageWrap
      fit={fit}
      fill={fill}
      className={cn('image-wrap', className)}
      size={imageSize}
      square={square}
      title={alt}
      noBorder={noBorder}
    >
      {/* This is the actual image */}
      <Image
        className='actual-image'
        bg={
          !isLoading
            ? imageId
              ? imageId
              : imageData
              ? imageData
              : undefined
            : false
        }
        {...imageProps}
      />

      {/* A placeholder icon - once data is valid, this will fade out */}
      <Image
        bg={!imageData && isLoading}
        {...imageProps}
        isPlaceholder
        className={cn('placeholder', { fit, fill, square })}
      >
        <Icon
          name={iconName}
          fontSize={iconFontSize[size]}
          color={fit || fill ? 'black' : undefined}
          title={!imageId ? 'Placeholder image' : undefined}
        />
      </Image>
    </ImageWrap>
  )
}

Picture.defaultProps = {
  fill: false,
  fit: false,
  iconName: 'user',
  noBorder: false,
  size: 'small',
  square: false,
}

Picture.propTypes = {
  alt: PropTypes.string,
  baseUrl: PropTypes.string,
  className: PropTypes.string,
  fit: PropTypes.bool,
  fill: PropTypes.bool,
  iconName: PropTypes.string,
  imageData: PropTypes.string,
  imageId: PropTypes.string,
  noBorder: PropTypes.bool,
  size: PropTypes.string,
  square: PropTypes.bool,
}

export default Picture
