// @ts-nocheck
/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useEffect, useState } from 'react'
import colors from '@sportninja/common/constants/appColors'
import { Select } from 'antd'
import { Controller, set, useForm } from 'react-hook-form'
import {
  deleteFacility,
  getCoord,
  readCountries,
  readProvinces,
  readTimezones,
} from 'src/helpers/EntityCreationHelper'
import { Button } from '../Button'
import Icon from '../Icon'
import { Input } from '../Input'
import {
  LoadingOutlined,
  PictureOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { init } from '@sentry/react'
import { getErrorMessageFromFormError } from '@sportninja/common/utils/utils'

type InputFields = {
  name: string
  address_line1: string
  address_line2: string
  city: string
  postcode: string
  country: string
  state: string
}

type ReturnArgs = InputFields & {
  timezone: string
  name: string
  latitude: number
  longitude: number
  state_code: string
}

type Facility = {
  id?: string
  name: string
}

interface Props {
  initialState?: ReturnArgs
  initialFacilities?: Facility[]
  onCancel: () => void
  onSubmit: ({
    args,
    facilities,
  }: {
    args: ReturnArgs
    facilities: Facility[]
  }) => void
}

export const NewLocationAddressSelector = ({
  onCancel,
  onSubmit,
  initialState,
  initialFacilities = [],
  readReduxProvinces = readProvinces,
}: Props) => {
  const [error, setError] = useState(null)
  const [countries, setCountries] = useState([])
  const [provinces, setProvinces] = useState([])
  const [timezones, setTimezones] = useState([])
  const [selectedProvince, setSelectedProvince] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedTimezone, setSelectedTimezone] = useState(null)
  const [value, setValue] = useState(initialState?.name || '')
  const [newFacilityName, setNewFacilityName] = useState('')
  const [facilities, setFacilities] = useState<Facility[]>([])
  const [showNewFacilityInput, setShowNewFacilityInput] = useState(false)
  const [editFacility, setEditFacility] = useState(false)
  const [facilityToEdit, setFacilityToEdit] = useState<Facility>({
    name: '',
    id: undefined,
  })
  const [deletedFacilities, setDeletedFacilities] = useState([])

  useEffect(() => {
    if (selectedProvince) {
      readReduxProvinces(selectedCountry)
    }
  }, [selectedProvince])

  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => {
        setError(null)
      }, 5000)
      return () => clearTimeout(timeout)
    }
  }, [error])

  useEffect(() => {
    const read = async () => {
      const result = await readTimezones(selectedCountry)
      if (result) {
        setSelectedTimezone(null)
        setTimezones(
          result.map((t) => {
            return {
              label: t,
              value: t,
            }
          })
        )
        if (initialState?.timezone) {
          const initialTimezone = result.find((c) => {
            const timezoneName = c.toLowerCase()
            return timezoneName === initialState?.timezone.toLowerCase()
          })
          if (initialTimezone) {
            setSelectedTimezone(initialTimezone)
          }
        }
      }
    }
    if (selectedCountry) {
      read()
    }
  }, [selectedCountry, initialState])

  useEffect(() => {
    const read = async () => {
      const result = await readProvinces(selectedCountry)
      if (result) {
        setSelectedProvince(null)
        setProvinces(
          result?.map((c) => {
            return {
              label: c.name,
              value: c.id,
            }
          })
        )
        if (initialState?.state) {
          const initialProvince = result.find((c) => {
            const provinceName = c.name.toLowerCase()
            return provinceName === initialState?.state.toLowerCase()
          })
          if (initialProvince) {
            setSelectedProvince(initialProvince?.id)
          }
        }
      }
    }
    if (selectedCountry) {
      read()
    }
  }, [selectedCountry, initialState])

  useEffect(() => {
    const read = async () => {
      const result = await readCountries()
      if (result) {
        const sortedResult = result.sort((a, b) => {
          if (a.id === 36 || a.id === 226) {
            return -1
          } else if (b.id === 36 || b.id === 226) {
            return 1
          } else {
            return 0
          }
        })
        setCountries(
          sortedResult?.map((c) => {
            return {
              label: c.name,
              value: c.id,
            }
          })
        )
        if (initialState?.country) {
          const initialCountry = result.find((c) => {
            const countryName = c.name.toLowerCase()
            return countryName === initialState?.country.toLowerCase()
          })
          if (initialCountry) {
            setSelectedCountry(initialCountry?.id)
            readProvinces(initialCountry?.id)
          }
        }
      }
    }
    read()
  }, [initialState])

  useEffect(() => {
    if (initialFacilities?.length > 0) {
      initialFacilities.forEach((facility) => {
        setFacilities((prev) => [...prev, facility])
      })
    }
  }, [initialFacilities])

  const {
    control,
    setValue: setFormValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      address_line1: initialState?.address_line1 || '',
      address_line2: initialState?.address_line2 || '',
      city: initialState?.city || '',
      country: initialState?.country || '',
      postcode: initialState?.postcode || '',
      state: initialState?.state || '',
      name: initialState?.name || '',
      timezone: initialState?.timezone || '',
    },
  })

  const handleCountryChange = (value: string) => {
    setSelectedCountry(value)
    setFormValue('country', value)
    readProvinces(value)
  }

  const handleProvinceChange = (value: string) => {
    setSelectedProvince(value)
    setFormValue('state', value)
  }

  const handleTimezoneChange = (value: string) => {
    setSelectedTimezone(value)
    setFormValue('timezone', value)
  }

  const onSubmitPressed = async (data: InputFields) => {
    if (!data?.name) {
      setError('Name is required')
      return
    }
    if (!data?.address_line1) {
      setError('Address line 1 is required')
      return
    }
    if (!data?.city) {
      setError('City is required')
      return
    }
    if (!data?.postcode) {
      setError('Postcode is required')
      return
    }
    if (!selectedCountry) {
      setError('Country is required')
      return
    }
    if (!selectedProvince) {
      setError('Province is required')
      return
    }
    if (!selectedTimezone) {
      setError('Timezone is required')
      return
    }
    const country = countries.find((c) => c.value === selectedCountry)
    const province = provinces.find((p) => p.value === selectedProvince)
    const countryObject = {
      id: country?.value,
      name_full: country?.label,
    }
    const provinceObject = {
      id: province?.value,
      name: province?.label,
    }
    const coordinates = await getCoord({
      street_1: data?.address_line1,
      city: data?.city,
      province: provinceObject,
      country: countryObject,
    })
    const dataToSubmit = {
      name: data?.name,
      name_full: data?.name,
      timezone: selectedTimezone,
      address: {
        street_1: data?.address_line1,
        street_2: data?.address_line2,
        city: data?.city,
        postal_code: data?.postcode,
        country_id: selectedCountry,
        province_id: selectedProvince,
        facilities: [],
        settings: {},
        latitude: coordinates?.latitude,
        longitude: coordinates?.longitude,
        address_type_id: 5,
      },
    }
    let facilitiesToSubmit = []
    if (showNewFacilityInput) {
      facilitiesToSubmit = [...facilities, { name: newFacilityName, id: '' }]
    } else {
      facilitiesToSubmit = facilities
    }
    if (deletedFacilities.length > 0) {
      try {
        await Promise.all(
          deletedFacilities.map((facilityId) => {
            return deleteFacility({
              entityId: initialState?.entityId,
              entityType: initialState?.entityType,
              facilityId: facilityId,
              venueId: initialState?.selectedVenue?.id,
            })
          })
        )
      } catch (e) {
        console.error(e)
      }
    }
    if (!initialState?.entityId) {
      onSubmit(dataToSubmit, facilitiesToSubmit)
    } else {
      onSubmit(
        dataToSubmit,
        facilitiesToSubmit,
        initialState?.entityId,
        initialState?.entityType,
        initialState?.selectedVenue
      )
    }
  }

  const onError = (error) => {
    const errorMessage = getErrorMessageFromFormError(error)
    setError(errorMessage?.replace('Address_line1', 'Address line 1'))
  }

  const onCancelPressed = () => {
    if (value && !initialState) {
      setValue('')
      setFormValue('name', '')
      setFormValue('address_line1', '')
      setFormValue('address_line2', '')
      setFormValue('city', '')
      setFormValue('country', '')
      setFormValue('postcode', '')
      setFormValue('state', '')
    } else {
      onCancel()
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmitPressed, onError)}
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
      `}
    >
      <div
        css={css`
          display: flex;
          flex: 1;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 8px;
          `}
        >
          <Input
            fieldName='name'
            control={control}
            label='Name'
            tabIndex={1}
            required={true}
          />
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 8px;
          `}
        >
          <Input
            fieldName='address_line1'
            control={control}
            label='Address Line 1'
            tabIndex={1}
            required={true}
          />
          <Input
            fieldName='address_line2'
            control={control}
            label='Address Line 2'
            tabIndex={2}
          />
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 8px;
          `}
        >
          <Input
            fieldName='city'
            control={control}
            label='City'
            tabIndex={3}
            required={true}
          />
          <Input
            fieldName='postcode'
            control={control}
            label='Postal Code'
            tabIndex={4}
            required={true}
          />
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 8px;
          `}
        >
          <div style={{ width: '100%' }} id='country'>
            <p
              css={css`
                font-weight: 500;
                font-size: 12px;
                line-height: 12px;
                color: ${colors.WHITE};
                margin-bottom: 8px;
              `}
            >
              Country *
            </p>
            <Controller
              name='country'
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => {
                return (
                  <Select
                    style={{ width: '100%' }}
                    onChange={handleCountryChange}
                    options={countries}
                    value={selectedCountry}
                  />
                )
              }}
            />
          </div>
          <div style={{ width: '100%' }} id='province'>
            <p
              css={css`
                font-weight: 500;
                font-size: 12px;
                line-height: 12px;
                color: ${colors.WHITE};
                margin-bottom: 8px;
              `}
            >
              Province / State *
            </p>
            <Controller
              name='country'
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    style={{ width: '100%' }}
                    onChange={handleProvinceChange}
                    options={provinces}
                    value={selectedProvince}
                  />
                )
              }}
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 8px;
          `}
        >
          <div style={{ width: '100%' }} id='timezone'>
            <p
              css={css`
                font-weight: 500;
                font-size: 12px;
                line-height: 12px;
                color: ${colors.WHITE};
                margin-bottom: 8px;
              `}
            >
              Timezone *
            </p>
            <Controller
              name='timezone'
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => {
                return (
                  <Select
                    style={{ width: '100%' }}
                    onChange={handleTimezoneChange}
                    options={timezones}
                    value={selectedTimezone}
                  />
                )
              }}
            />
          </div>
        </div>
      </div>
      <div
        css={css`
          padding: 16px 0;
          border-top: 1px solid ${colors.SOFT_STEEL};
          margin-top: 16px;
          border-bottom: 1px solid ${colors.SOFT_STEEL};
          margin-bottom: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          {facilities.map((item, index) => (
            <div
              key={index.toString()}
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: ${editFacility && facilityToEdit.id === item.id
                  ? '8px 0'
                  : '8px'};
                border-radius: 4px;
                background-color: ${index % 2 === 0
                  ? 'transparent'
                  : editFacility && facilityToEdit.id === item.id
                  ? 'transparent'
                  : colors.HEADER};
              `}
            >
              {editFacility && facilityToEdit.id === item.id ? (
                <div
                  css={css`
                    display: flex;
                    flex: 1;
                    align-items: center;
                    justify-content: space-between;
                    border-width: 1px;
                    border-style: solid;
                    border-color: ${colors.SOFT_STEEL};
                    border-radius: 4px;
                    padding: 8px;
                    max-height: 40px;
                    transition: all 0.1s ease-in-out;

                    &:focus-within {
                      border-color: ${colors.WHITE};
                    }
                  `}
                >
                  <input
                    css={css`
                      display: flex;
                      border: none;
                      width: 100%;
                      flex: 1;
                      font-weight: 500;
                      font-size: 16px;
                      line-height: 16px;
                      color: ${colors.WHITE};
                      background-color: transparent;
                    `}
                    name='facilities-input-creator'
                    type='text'
                    value={facilityToEdit.name}
                    onBlur={() => {
                      setEditFacility(false)
                      setFacilityToEdit({
                        name: '',
                        id: undefined,
                      })
                    }}
                    placeholder='Enter the facility name'
                    onChange={(e) =>
                      setFacilityToEdit({
                        ...facilityToEdit,
                        name: e.target.value,
                      })
                    }
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()

                        const newFacilitiesArray = facilities.filter(
                          (_, i) => i !== index
                        )
                        newFacilitiesArray.push(facilityToEdit)
                        setEditFacility(false)
                        setFacilities(newFacilitiesArray)
                        setFacilityToEdit({
                          name: '',
                          id: undefined,
                        })
                      }

                      if (e.key === 'Escape') {
                        setEditFacility(false)
                        setFacilityToEdit({
                          name: '',
                          id: undefined,
                        })
                      }
                    }}
                  />
                  <button
                    onClick={(e) => {
                      e.preventDefault()

                      setEditFacility(false)
                      setFacilityToEdit({
                        name: '',
                        id: undefined,
                      })
                    }}
                  >
                    <Icon
                      name='times'
                      color={colors.DEFAULT_FLAIR}
                      fontSize={18}
                    />
                  </button>
                </div>
              ) : (
                <>
                  <div
                    onClick={() => {
                      setFacilityToEdit(item)
                      setEditFacility(true)
                    }}
                    css={css`
                      display: flex;
                      flex: 1;
                      padding: 8px 0;
                    `}
                  >
                    <span>{item.name}</span>
                  </div>
                  <button
                    onClick={(e) => {
                      e.preventDefault()

                      setFacilities((prev) => {
                        const facility = prev.find((_, i) => i === index)
                        if (facility.id) {
                          setDeletedFacilities((p) => [...p, facility.id])
                        }
                        return prev.filter((_, i) => i !== index)
                      })
                    }}
                  >
                    <Icon name='trash' color={colors.DEFAULT_FLAIR} />
                  </button>
                </>
              )}
            </div>
          ))}
          {showNewFacilityInput && (
            <div
              css={css`
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: space-between;
                border-width: 1px;
                border-style: solid;
                border-color: ${colors.SOFT_STEEL};
                border-radius: 4px;
                padding: 8px;
                max-height: 40px;
                transition: all 0.1s ease-in-out;
                margin-top: 16px;

                &:focus-within {
                  border-color: ${colors.WHITE};
                }
              `}
            >
              <input
                css={css`
                  display: flex;
                  border: none;
                  width: 100%;
                  flex: 1;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 16px;
                  color: ${colors.WHITE};
                  background-color: transparent;
                `}
                name='facilities-input-creator'
                type='text'
                value={newFacilityName}
                placeholder='Enter the facility name'
                onChange={(e) => setNewFacilityName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()

                    setShowNewFacilityInput(false)
                    setFacilities((prev) => [
                      ...prev,
                      { name: newFacilityName, id: '' },
                    ])
                    setNewFacilityName('')
                  }
                }}
              />
              <button
                type='reset'
                onClick={(e) => {
                  e.preventDefault()

                  setShowNewFacilityInput(false)
                  setNewFacilityName('')
                }}
              >
                <Icon
                  name='times'
                  color={colors.ATTENDANCE_GRAY}
                  fontSize={18}
                />
              </button>
            </div>
          )}
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin-top: ${facilities.length > 0 || showNewFacilityInput
                ? '16px'
                : '0'};
            `}
          >
            <Button
              onClick={(e) => {
                e.preventDefault()
                if (!showNewFacilityInput) {
                  setShowNewFacilityInput(true)
                } else {
                  if (newFacilityName && newFacilityName.length > 0) {
                    setFacilities((prev) => [
                      ...prev,
                      { name: newFacilityName, id: '' },
                    ])
                    setNewFacilityName('')
                  }
                }
              }}
              label='+ Add Facility'
              variant='secondary'
            />
          </div>
        </div>
      </div>
      {error ? (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            margin-top: 20px;
          `}
        >
          <ExclamationCircleOutlined
            style={{
              color: colors.FIERY_RED,
              fontSize: 16,
            }}
          />
          <p
            tabIndex={-1}
            css={css`
              font-weight: 500;
              font-size: 14px;
              line-height: 14px;
              color: ${colors.FIERY_RED};
            `}
          >
            {error}
          </p>
        </div>
      ) : null}
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
          margin-top: 20px;
        `}
      >
        <Button
          onClick={(e) => {
            e.preventDefault()

            onCancelPressed()
          }}
          label={value && !initialState ? 'Clear' : 'Cancel'}
          variant='secondary'
        />
        <Button
          id='submit'
          onClick={handleSubmit(onSubmitPressed, onError)}
          type='submit'
          label='Submit'
        />
      </div>
    </form>
  )
}
