import React from 'react'
import PropTypes from 'prop-types'
import { divIcon } from 'leaflet'
import { Map as M, TileLayer, Marker } from 'react-leaflet'

const icon = divIcon({ className: 'fas fa-map-marker-alt', iconSize: [28, 36], iconAnchor: [14, 36] })

// What is the 0.0003 for? We hide the bottom copyright banner, but leaflet
// assumes its existence when calculating center position. So, we'll manually
// fudge it a bit so the marker is centered.
const Map = ({ position: [ lat, lng ], zoom = 17, ...props }) => (
  <M center={[ lat + 0.0003, lng ]} zoom={zoom} {...props}>
    <TileLayer
      attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <Marker position={[ lat, lng ]} icon={icon} />
  </M>
)

Map.propTypes = {
  position: PropTypes.array.isRequired,
  zoom: PropTypes.number
}

export default Map
