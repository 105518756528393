/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { t } from '@sportninja/common/i18n'

import { font } from '../css'
import Icon from '../Icon'
import { Flex } from '../Layout'

const ImportCSVTitleRight = ({ importTemplate, title }) => {
  if (!importTemplate?.SNHelpLink) return false

  return (
    <a
      title='Support Documentation - Imports'
      target='_blank'
      rel='noopener noreferrer'
      href={importTemplate.SNHelpLink}
      css={css`
        &:hover {
          i {
            color: white;
          }
        }
      `}
    >
      <Icon fontSize={21} faType='far' name='question-circle' color='#9FA3A9' />
    </a>
  )
}

const ImportCSVTitle = ({ importTemplate, success, onClose, title }) => {
  return (
    <Flex
      noFlex
      justifyContent='space-between'
      css={css`
        border-bottom: 1px solid #3f4753;
        padding-bottom: 16px;
        margin-bottom: 16px;
      `}
    >
      <h2
        css={css`
          ${font.title}
          font-weight: 700;
          font-size: 32px;
          line-height: 100%;

          text-transform: uppercase;
        `}
      >
        {success
          ? t('common:success')
          : title
          ? title
          : importTemplate?.title
          ? importTemplate?.title
          : importTemplate?.label}
      </h2>
      <Flex noFlex>
        <ImportCSVTitleRight importTemplate={importTemplate} />
        <button
          type='button'
          onClick={onClose}
          css={css`
            display: flex;
            margin-left: 16px;
          `}
          title='Close'
        >
          <Icon fontSize={21} faType='far' name='times' />
        </button>
      </Flex>
    </Flex>
  )
}

export default ImportCSVTitle
