import React, { useRef, useState } from 'react'
import { t } from '@sportninja/common/i18n'
import { PASSWORD_LENGTH_MIN } from '@sportninja/common/constants/app'

import PasswordReveal from '../../components/PasswordReveal'
import FormInput from './FormInput'

export const passwordsMatch = (values) => {
  if (values.password !== values.confirm_password) {
    return { password: t('errors:passwordsDoNotMatch'), confirm_password: true }
  }

  return false
}

const ConfirmPassword = ({ autoFocus, disabled, errors = {}, onChange, passwordLabel = t('common:password'), passwordOnly = false, name, label }) => {
  const passwordEl = useRef(null)
  const confirmPasswordEl = useRef(null)

  const [ shown, setShown ] = useState(false)
  const [ matches, setMatches ] = useState(null)

  // Basically, we just want to compare values to see if they match. If so, change
  // the icon to a check mark
  const handleChange = (e) => {
    if (!passwordOnly) {
      const passVal = passwordEl.current.value
      const confirmPassVal = confirmPasswordEl.current.value

      // We'll use a value of null to not show the icon at all, in cases where:
      // a) User has not filled out anything
      // b) User hasn't filled out confirm password
      // c) User filled out confirm password, but not password
      if (confirmPassVal.length === 0 || (confirmPassVal.length > 0 && passVal.length === 0)) {
        setMatches(null)
      } else {
        // Otherwise, display a check if they match, otherwise an x
        setMatches(passVal === confirmPassVal)
      }
    }

    // Pass the change on to the Form handler
    onChange(e)
  }

  const sharedProps = {
    autoCapitalize: 'none',
    autoComplete: 'new-password',
    autoCorrect: 'off',
    minLength: PASSWORD_LENGTH_MIN,
    onChange: handleChange,
    required: true,
    type: shown ? 'text' : 'password'
  }

  return <>
    <FormInput
      disabled={disabled}
      hasError={errors.password}
      input={{
        autoFocus,
        name: name || 'password',
        label: label || passwordLabel,
        ref: passwordEl,
        right: <PasswordReveal onClick={() => setShown(!shown)} passwordRevealed={shown} />,
        ...sharedProps }} />
    {!passwordOnly &&
      <FormInput
        disabled={disabled}
        hasError={errors.confirm_password}
        input={{
          name: 'confirm_password',
          label: t('AccountScreen:confirmPassword'),
          ref: confirmPasswordEl,
          right: matches !== null && (
            <span className={`icon ${matches ? 'green' : 'red'}`}>
              <i className={`fas fa-${matches ? 'check' : 'times'}`} />
            </span>
          ),
          ...sharedProps }} />
    }
  </>
}

export const PasswordOnly = ({ ...props }) => <ConfirmPassword {...props} passwordOnly />

export default ConfirmPassword
