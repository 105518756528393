/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { ActionButton } from './Details'
import Icon from 'src/components/Icon'
import { NewModal } from 'src/components/NewModal'

import { UpgradeModal } from './UpgradeModal'
import { ComparePlansModal } from './ComparePlansModal'
import { useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useMobxStore } from 'src/state'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { useI18n } from '@shopify/react-i18n'
import { handlePlanName } from './utils'
import { PaymentManagerModal } from './PaymentManagerModal'

const Item = ({ label }: { label: string }) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: flex-start;
        margin-bottom: 8px;
      `}
    >
      <Icon name={'check-circle'} color={colors.ATTENDANCE_GRAY} />
      <p
        css={css`
          font-size: 12px;
          margin-left: 6px;
          color: ${colors.WHITE};
        `}
      >
        {label}
      </p>
    </div>
  )
}

export const Subscription = observer(() => {
  const {
    me: {
      state: { account },
    },
  } = useMobxStore()
  const upgradePlanModal = useRef<NewModal>()
  const comparePlanModal = useRef<NewModal>()
  const paymentManagerModal = useRef<NewModal>()

  const [i18n] = useI18n()

  const [isUltimate, setIsUltimate] = useState(false)
  const [isFree, setIsFree] = useState(false)

  if (!account?.subscription_type?.features) {
    return (
      <div
        css={css`
          background-color: ${colors.HEADER};
          padding: 16px;
          border-radius: 8px;
          border: 1px solid ${colors.SOFT_STEEL};
          max-width: 820px;
          margin: 0 auto;
          margin-top: 16px;
        `}
      >
        <LoadingSpinner size={6} />
      </div>
    )
  }

  const firstHalf = account?.subscription_type?.features.slice(
    0,
    account?.subscription_type?.features.length / 2
  )
  const secondHalf = account?.subscription_type?.features.slice(
    account?.subscription_type?.features.length / 2
  )

  return (
    <>
      <div
        css={css`
          background-color: ${colors.HEADER};
          padding: 16px;
          border-radius: 8px;
          border: 1px solid ${colors.SOFT_STEEL};
          max-width: 820px;
          margin: 0 auto;
          margin-top: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 16px;
            border-bottom: 1px solid ${colors.SOFT_STEEL};
          `}
        >
          <h1
            css={css`
              font-family: 'Rift', sans-serif;
              font-size: 24px;
              font-weight: 700;
            `}
          >
            Subscription
          </h1>
          <div>
            {account?.subscription?.interval === 'year' &&
            account?.subscription_type?.id === 3 ? (
              <div />
            ) : (
              <ActionButton
                label='Upgrade Your Plan'
                onClick={() => upgradePlanModal.current?.openModal()}
                iconName='dollar-sign'
              />
            )}
            <ActionButton
              label='Compare Plans'
              onClick={() => comparePlanModal.current?.openModal()}
              iconName='retweet'
            />
          </div>
        </div>
        {Number(account?.participants) >= 50 &&
          account?.subscription_type?.id === 1 && (
            <div
              css={css`
                padding: 16px;
                margin-top: 16px;
                background-color: ${colors.FIERY_RED};
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 11px;
                border-radius: 8px;
              `}
            >
              <div
                css={css`
                  display: flex;
                  gap: 16px;
                  align-items: center;
                `}
              >
                <Icon
                  name='exclamation-triangle'
                  color={colors.WHITE}
                  fontSize={18}
                />
                <span
                  css={css`
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    color: ${colors.WHITE};
                  `}
                >
                  Participant Limit Reached
                </span>
              </div>
              <span
                css={css`
                  text-align: center;
                  color: ${colors.WHITE};
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 500;
                `}
              >
                {
                  'This account has reached it’s limit of 50 participants. Please upgrade your subscription.'
                }
              </span>
            </div>
          )}
        <div
          css={css`
            border: 1px dashed ${colors.SOFT_STEEL};
            border-radius: 4px;
            padding: 16px;
            margin-top: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <div
              css={css`
                flex: 1.5;
              `}
            >
              <p
                css={css`
                  font-size: 26px;
                  font-weight: 600;
                  text-transform: capitalize;
                `}
              >
                {handlePlanName(account?.subscription_type?.name)}
              </p>
              <div
                css={css`
                  display: inline-flex;
                  padding: 4px 8px;
                  border-radius: 4px;
                  margin-top: 5px;
                  background-color: ${colors.SOFT_STEEL};
                `}
              >
                <span>Current Plan</span>
              </div>
              <p
                css={css`
                  color: ${colors.WHITE};
                  font-size: 14px;
                  margin: 8px 0;
                `}
              >
                {i18n.formatCurrency(
                  Number(
                    account?.subscription_upcoming_invoice?.amount_due || 0
                  ),
                  {
                    currency: (
                      account?.subscription_upcoming_invoice?.currency || 'USD'
                    ).toUpperCase(),
                    form: 'short',
                  }
                )}
                /{account.subscription?.interval === 'year' ? 'Year' : 'Month'}
              </p>
              <p
                css={css`
                  color: ${Number(account?.participants) >= 50 &&
                  account?.subscription_type?.id === 1
                    ? colors.ERROR
                    : colors.DEFAULT_FLAIR};
                  font-size: 14px;
                  margin-top: 5px;
                  text-transform: capitalize;
                `}
              >
                {`${account?.participants || '0'}`}
                {account?.subscription_type?.id === 1 ? '/50 ' : ' '}
                participants
              </p>
            </div>
            <div
              css={css`
                flex: 2;
                padding-right: 12px;
              `}
            >
              {firstHalf.map((feature) => {
                if (feature.has_feature) {
                  return (
                    <div key={feature.name}>
                      <Item label={feature.name} />
                    </div>
                  )
                }
              })}
            </div>
            <div
              css={css`
                flex: 2;
              `}
            >
              {secondHalf.map((feature) => {
                if (feature.has_feature) {
                  return (
                    <div key={feature.name}>
                      <Item label={feature.name} />
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </div>
        {/* {account?.subscription?.interval === 'month' && (
          <div
            onClick={() => upgradePlanModal.current?.openModal()}
            css={css`
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              background-color: #3894ff;
              border-radius: 4px;
              padding: 16px;
              margin-top: 16px;
            `}
          >
            <p
              css={css`
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                font-family: 'Rift', sans-serif;
                margin-bottom: 8px;
              `}
            >
              SAVE YOUR MONEY
            </p>
            <p
              css={css`
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
              `}
            >
              Switch your plan to a yearly plan and gets 16% discount
            </p>
          </div>
        )} */}
      </div>
      <NewModal ref={upgradePlanModal} shouldCloseOnOverlayClick={false}>
        <UpgradeModal
          userHasCard={!!account?.payment_method?.id}
          onCompareClick={() => {
            comparePlanModal.current?.openModal()
            upgradePlanModal.current?.closeModal()
          }}
          isFree={isFree}
          onCloseModal={() => upgradePlanModal?.current?.closeModal()}
          isUltimate={isUltimate || Number(account?.subscription_type?.id) > 1}
          onChangeCardsPress={() => {
            upgradePlanModal?.current?.closeModal()
            paymentManagerModal?.current?.openModal()
          }}
        />
      </NewModal>
      <NewModal ref={comparePlanModal} shouldCloseOnOverlayClick={false}>
        <ComparePlansModal
          onProfessionalClick={() => {
            setIsUltimate(false)
            setIsFree(false)
            upgradePlanModal.current?.openModal()
            comparePlanModal.current?.closeModal()
          }}
          onFreeClick={() => {
            setIsFree(true)
            setIsUltimate(false)
            upgradePlanModal.current?.openModal()
            comparePlanModal.current?.closeModal()
          }}
          onUltimateClick={() => {
            setIsUltimate(true)
            setIsFree(false)
            upgradePlanModal.current?.openModal()
            comparePlanModal.current?.closeModal()
          }}
          onCloseModal={() => comparePlanModal.current?.closeModal()}
        />
      </NewModal>
      <NewModal ref={paymentManagerModal} shouldCloseOnOverlayClick={false}>
        <PaymentManagerModal
          onClose={() => paymentManagerModal?.current?.closeModal()}
        />
      </NewModal>
    </>
  )
})
