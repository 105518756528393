import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import Pusher from 'pusher-js'
import { getHeaders } from '@sportninja/common/api/request'
import EnvHandler from '@sportninja/common/utils/env-handler-setup'
import { isCanlan } from '@sportninja/common/utils/customer-name'

export const noop = () => undefined

/**
 * Checks if localStorage is available in the current environment.
 *
 * @returns {boolean} True if localStorage is available, false otherwise.
 */
export const isLocalStorageAvailable = () => {
  const testKey = 'testKey'
  try {
    localStorage.setItem(testKey, testKey)
    localStorage.removeItem(testKey)
    return true
  } catch (e) {
    return false
  }
}

/**
 * Retrieve data from a given row object
 *
 * @param {object} row - The object at an array index containing information
 * @param {string|function} accessor - A period separated nested key, or custom function
 * @returns {*} The value of row as defined by accessor
 */
export const getNestedData = (row, accessor) => {
  if (!row || !accessor) {
    return {}
  }

  if (typeof accessor === 'function') {
    return accessor(row)
  } else if (typeof accessor === 'string') {
    const accessorList = accessor.split('.')

    let data = Object.assign({}, row)
    for (let i = 0; i < accessorList.length - 1; i++) {
      data = data[accessorList[i]]

      if (!data) {
        break
      }
    }

    if (!data) {
      return {}
    }

    return { value: data[accessorList.slice(-1)] }
  }
}

export const arrayToObject = (array, mapper = (o) => o, idName = 'id') => {
  // Probably just gonna result in weird bugs - but this will prevent the app
  // from crashing in the case of applying this transformation to something that
  // doesn't support it.
  if (typeof array.reduce === 'undefined') {
    return array
  }

  return array.reduce((returnedObject, currentArrayItem) => {
    if (currentArrayItem) {
      const mappedObject = mapper(currentArrayItem)
      const keyName = currentArrayItem[idName]
      if (mappedObject && keyName) {
        returnedObject[keyName] = mappedObject
      }
    }

    return returnedObject
  }, {})
}

// Deprecated
export const actionPrefixer = (orig, prefix) => {
  const obj = { ...orig }
  const keys = Object.keys(obj)

  keys.forEach((key) => {
    if (typeof obj[key] === 'object') {
      obj[key] = actionPrefixer(obj[key], `${prefix ? `${prefix}.` : ''}${key}`)
    } else {
      obj[key] = `${prefix}.${obj[key]}`
    }
  })

  return obj
}

/**
 * @param {object} day - Expects a dayjs object
 * @returns {string} - An ISO formatted datetime string
 */
export const toISOString = (day) => {
  if (!day.toISOString) {
    return day
  }
  return day.toISOString().replace(/\.\d+Z/, 'Z')
}

export const isUnderAge = (age, requiredAge) => {
  return dayjs().diff(age, 'year') < requiredAge
}

export const formatURL = (string) => {
  const hasHttp = /^http(s?):\/\//i.test(string)
  return encodeURI(hasHttp ? string : `//${string}`)
}

// Monkey-patch an api call dispatch to also hold state for the `total` value
// from the pagination meta object. Typically used to display a "count" in a menu,
// like in web/src/pages/Org/index.jsx
export const usePaginationTotalCount = (fcn) => {
  const [count, setCount] = useState(null)

  const method = useCallback(
    async (...args) => {
      const response = await fcn(...args)

      if (
        response &&
        response.meta &&
        response.meta.pagination &&
        typeof response.meta.pagination.total === 'number'
      ) {
        setCount(response.meta.pagination.total)
      }

      return response
      // purposefully not include `fcn` in dependency array, because this argument
      // should never change.
    },
    [setCount]
  )

  return { count, method }
}

// If you need to enforce the order that items are returned from the API - provide
// a list of ordered IDs, and the list, and this will combine the two.
export const getPaginatedList = (pages, list) => {
  if (pages && Array.isArray(pages.ids) && Array.isArray(list)) {
    return pages.ids
      .filter((id) => list.findIndex((item) => item && item.id === id) !== -1)
      .map((id) => list.find((item) => item && item.id === id))
  }

  return list
}

export const times = (n, iteratee = (n) => n) => {
  const ret = []
  for (let i = 0; i < n; i++) {
    ret.push(iteratee(i))
  }
  return ret
}

const DEV_HOSTS = [
  'client-develop.sportninja.com',
  'client-test.sportninja.com',
  'localhost',
]

export const IS_DEVELOPMENT = (() => {
  const host = window.location.hostname || window.location.host
  if (DEV_HOSTS.includes(host)) {
    return true
  }

  return false
})()
