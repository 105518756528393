/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import PropTypes from 'prop-types'

import { Container, Column } from '../../components/Layout'
import { media } from '../../components/Responsive'
import { borderColor } from '../../components/css'
import Helmet from '../../components/Helmet'

// LoginColumn will be 5 columns, but we want a border at 6 columns
const SpacerColumn = styled(Column)`
  border-right: 1px solid ${borderColor};
  ${media.mobile} {
    border-right: 0;
  }
`
const AuthWrap = ({ children, title }) => (
  <Container
    css={css`
      min-height: calc(100vh - 56px);
      overflow-y: auto;
    `}
    noWrap
  >
    {title && <Helmet title={title} />}
    <SpacerColumn
      noFlex
      css={css`
        border-right: 0;
      `}
    />
    <Column
      justifyContent='center'
      column
      columns={5}
      css={css`
        padding: 40px 0;
      `}
    >
      {children}
    </Column>
    <SpacerColumn noFlex />
  </Container>
)

AuthWrap.propTypes = {
  children: PropTypes.node.isRequired,
  css: PropTypes.object,
  title: PropTypes.string,
}

export default AuthWrap
