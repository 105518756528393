import { makeObservable, observable, action, runInAction, computed } from 'mobx'
import { RootStore } from '..'

import { SubscriptionService } from './service'
const SESSION_TOKEN = 'session_token'

type Feature = {
  id: number | string
  name: string
  rule: null | string
  has_feature: boolean
  created_at: string
  updated_at: string
}

export type Plan = {
  id: number | string
  name: string
  price_monthly: number
  price_yearly: number
  created_at: string
  updated_at: string
  features: Feature[]
}

export type Invoice = {
  id: string
  amount_due: number
  amount_paid: number
  currency: string
  billing_reason: string
  effective_at: string
  invoice_pdf: string
}

export class SubscriptionStore {
  rootStore: RootStore
  plans: Plan[] | null = null
  loading = false
  invoiceList: Invoice[] = []

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore

    const existingToken = localStorage.getItem(SESSION_TOKEN)

    if (existingToken) {
      this.bootstrap()
    }

    makeObservable(this, {
      plans: observable,
      invoiceList: observable,
      loading: observable,
      getPlans: action,
      loadInvoices: action,
      state: computed,
    })
  }

  private bootstrap() {
    this.getPlans()
  }

  getPlans = () => {
    runInAction(() => {
      this.loading = true
    })

    SubscriptionService.getAllPlans()
      .then((response) => {
        runInAction(() => {
          this.plans = response.data
        })
      })
      .catch((error) => {
        console.error('ERROR FETCH PLANS', error)
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false
        })
      })
  }

  loadInvoices = (accountId: string) => {
    SubscriptionService.getInvoices(accountId)
      .then((response) => {
        runInAction(() => {
          this.invoiceList = response || []
        })
      })
      .catch((error) => {
        console.error('ERROR FETCH INVOICES', error)
      })
  }

  get state() {
    return {
      plans: this.plans,
      loading: this.loading,
      invoiceList: this.invoiceList,
    }
  }
}
