import { call, put, select } from 'redux-saga/effects'

import { processForm } from './utils'

export const DEFAULT_PUBLIC_SETTING = true

export function* createEntity(
  method,
  successAction,
  form,
  settings,
  parentOrgId
) {
  const { addArgs = [], bodyTransform = {} } = settings

  // Transform the body that we'll post to API, if present
  let { body } = processForm(form)

  // Privacy Settings is a toggle, with a visual default option. However, that
  // option does not appear as a "change" in the form, so it won't show up here
  // unless the user actively toggles it.
  if (typeof form.is_public === 'undefined') {
    // Set it to true by default
    let defaultSetting = true

    // But, if there is a parent org, use the setting from the parent org instead
    if (typeof parentOrgId === 'string') {
      const parentOrg = yield select((state) => state?.org?.[parentOrgId])
      if (parentOrg && typeof parentOrg?.isPublic === 'boolean') {
        defaultSetting = parentOrg.isPublic
      }
    }

    body.is_public = defaultSetting
  }

  Object.assign(body, bodyTransform)

  // Create the entity
  const response = yield call(method, JSON.stringify(body), ...addArgs)
  yield put(successAction({ data: response.data }))

  return response
}
