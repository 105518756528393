/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import { Fragment, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import { Overlay } from '../Sheet/css'
import {  Panel, PANEL_FADE_DURATION_MS } from './css'
import { createHandler } from './utils'
import { RemoveAction, UpdateAction, InviteAction } from './ActionPanelActions'

const ActionPanelContents = ({
  customButton,
  deleteButtonText,
  deleteMessage,
  deleteTitle,
  fitsBottom,
  fitsRight,
  followButton,
  invite,
  isOpen,
  onUpdate,
  onUpdateOpen,
  onDelete,
  setMenuOpen,
  updateForm,
  updateTitle
}) => {
  const [ customLoading, setCustomLoading ] = useState(false)
  const [ panelBusy, setPanelBusy ] = useState(false)
  const loading = customLoading

  const sharedTransitionSettings = {
    in: isOpen,
    mountOnEnter: true,
    unmountOnExit: true,
    timeout: PANEL_FADE_DURATION_MS
  }

  return (
    <Fragment>
      <CSSTransition classNames='overlay' {...sharedTransitionSettings}>
        {() => (
          <Overlay
            onClick={createHandler.bind(this, () => setMenuOpen(false))}
            css={css`background-color: transparent; cursor: initial;`} />
        )}
      </CSSTransition>
      <CSSTransition classNames='panel' {...sharedTransitionSettings}>
        {() => (
          <Panel column fitsBottom={fitsBottom} fitsRight={fitsRight}>
            {followButton}

            {/* Opens a sheet - doesn't need to worry about parent loading state */}
            {onUpdate && updateForm &&
              <UpdateAction
                deleteButtonText={deleteButtonText}
                deleteMessage={deleteMessage}
                deleteTitle={deleteTitle}
                onUpdate={onUpdate}
                onDelete={onDelete}
                onClick={onUpdateOpen}
                panelBusy={panelBusy}
                setPanelBusy={setPanelBusy}
                updateForm={updateForm}
                updateTitle={updateTitle}
                setMenuOpen={setMenuOpen} />
            }

            {/* Opens a modal - doesn't need to worry about parent loading state */}
            {onDelete &&
              <RemoveAction
                deleteButtonText={deleteButtonText}
                deleteMessage={deleteMessage}
                deleteTitle={deleteTitle}
                onDelete={onDelete}
                panelBusy={panelBusy} />
            }

            {invite && !invite.accepted &&
              <InviteAction
                invite={invite}
                onUpdate={onUpdate}
                panelBusy={panelBusy} />
            }

            {customButton && customButton(loading, setCustomLoading)}
          </Panel>
        )}
      </CSSTransition>
    </Fragment>
  )
}

export default ActionPanelContents
