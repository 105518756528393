import colors from '@sportninja/common/constants/appColors'
import React from 'react'
import styled from 'styled-components'

const HandleWrapper = styled.div`
  height: 1rem;
  vertical-align: bottom;
  display: inline-block;
  margin-right: 0.5rem;
  svg {
    width: 100%;
    height: 100%;
  }
  cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'grab')};
`

export const DragHandle = (props) => {
  return (
    <HandleWrapper
      {...props}
      style={{
        width: 24,
        height: 24,
        marginBottom: 4,
      }}
    >
      {/* <svg
        aria-hidden='true'
        focusable='false'
        data-prefix='fas'
        data-icon='grip-vertical'
        role='img'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 320 512'
      >
        <path
          fill={colors.ATTENDANCE_GRAY}
          d='M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z'
        ></path>
      </svg> */}
      <svg
        width='50'
        height='50'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='Drag handle' clip-path='url(#clip0_3354_10868)'>
          <path
            id='Vector'
            d='M19 9H5C4.45 9 4 9.45 4 10C4 10.55 4.45 11 5 11H19C19.55 11 20 10.55 20 10C20 9.45 19.55 9 19 9ZM5 15H19C19.55 15 20 14.55 20 14C20 13.45 19.55 13 19 13H5C4.45 13 4 13.45 4 14C4 14.55 4.45 15 5 15Z'
            fill={colors.ATTENDANCE_GRAY}
          />
        </g>
        <defs>
          <clipPath id='clip0_3354_10868'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </HandleWrapper>
  )
}
