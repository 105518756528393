/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import colors from '@sportninja/common/constants/appColors'

import { font } from '../css'
import { Mobile } from '../Responsive'
import Icon from '../Icon'

const NewButton = ({ onClick }) => {
  return (
    <Mobile>
      {(isMobile) => {
        return (
          <button
            className='header-action'
            type='button'
            onClick={onClick}
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              width: ${isMobile ? 32 : 64}px;
              height: 32px;

              border: ${isCanlan ? 2 : 1}px solid ${colors.DEFAULT_FLAIR};
              border-radius: 4px;

              ${font.title}
              font-weight: 600;
              font-size: 18px;

              letter-spacing: 0.015em;
              text-transform: uppercase;
              color: white;

              ${isCanlan && 'padding-bottom: 2px;'}

              transition: border-color 0.1s ease-in-out;

              &:hover {
                border-color: white;
              }
            `}
          >
            {isMobile ? <Icon name='plus' fontSize={12} /> : '+ NEW'}
          </button>
        )
      }}
    </Mobile>
  )
}

export default NewButton
