/** @jsxImportSource @emotion/react */
import { Global } from '@emotion/react'
import css from '@emotion/css/macro'
import { useEffect, useState } from 'react'
import queryString from 'query-string'

const hexToRgb = (hex) =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => '#' + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16))
    .join(',')

const FrameStyles = () => {
  const [styles, setStyles] = useState({
    backgroundColor: 'white',
    mainColor: 'red',
    textColor: 'black',
    borderColor: 'black',
    spinnerBackgroundRgb: '255, 0, 0',
  })

  useEffect(() => {
    const decodedHash = decodeURIComponent(window.location.hash)
    const parsedHash = queryString.parse(decodedHash)
    if (typeof parsedHash?.styles === 'string') {
      try {
        const customStyles = JSON.parse(parsedHash.styles)

        const { backgroundColor, primaryColor, textColor, borderColor } =
          customStyles
        const newStyles = {}
        if (backgroundColor) newStyles.backgroundColor = backgroundColor
        if (primaryColor) {
          newStyles.mainColor = primaryColor

          if (primaryColor?.charAt(0) === '#') {
            const rgb = hexToRgb(primaryColor)
            newStyles.spinnerBackgroundRgb = `${rgb}`
          }
        }
        if (textColor) newStyles.textColor = textColor
        if (borderColor) newStyles.borderColor = borderColor

        setStyles((s) => ({ ...s, ...newStyles }))
      } catch (e) {
        console.log('There was a problem parsing custom styles', e)
      }
    }
  }, [])

  const {
    backgroundColor,
    mainColor,
    textColor,
    borderColor,
    spinnerBackgroundRgb,
  } = styles

  return (
    <Global
      styles={css`
        body {
          color: ${textColor};
          background: ${backgroundColor};
        }

        a,
        h1,
        i {
          color: ${textColor} !important;
        }

        select option {
          background: ${backgroundColor} !important;
          color: ${textColor} !important;
        }

        .sn-pagination {
          background: ${backgroundColor};
        }

        .page-button {
          color: ${textColor} !important;
          border-color: ${borderColor};

          &.is--active:hover,
          &.is--current {
            border-color: ${mainColor} !important;
          }
        }

        .indicator-line {
          background-color: ${mainColor} !important;
        }

        .game-list-item-final-text {
          color: ${mainColor};
        }

        .game-list-item-sub-text,
        .game-list-item-game-status-text {
          color: ${textColor} !important;
        }

        .mobile-game-list-row {
          border: 1px solid rgba(0, 0, 0, 0.7);
        }

        .mobile-game-list-row-time {
          border-top-color: rgba(0, 0, 0, 0.3) !important;
        }

        .page-container {
          background-color: transparent !important;
          border-bottom-color: rgba(0, 0, 0, 0.3) !important;

          nav {
            background-color: ${backgroundColor} !important;
            top: 0 !important;
            border-bottom-color: rgba(0, 0, 0, 0.3) !important;
          }
        }

        .mobile-page-menu-item {
          color: ${textColor} !important;
        }

        .mobile-page-menu-sheet {
          background-color: ${backgroundColor};
        }

        .indicator-line,
        .active-page-indicator {
          background: ${mainColor} !important;
          height: 2px !important;
        }

        .image-wrap {
          border-color: ${borderColor} !important;
        }

        .card-container,
        .page-title-image,
        .label-cell-row,
        .cell.is-name-col {
          & div.placeholder:not(.fit):not(.fill) {
            border-color: ${borderColor} !important;
          }
        }

        .player-page .page-title-image {
          border: unset !important;
        }

        .player-portrait {
          border: unset !important;
        }

        .player-portrait .actual-image {
          border: unset !important;
        }

        .sn-table-title {
          color: ${mainColor} !important;
        }

        .loading-spinner:after {
          border-color: rgba(${spinnerBackgroundRgb}, 0.25) !important;
          border-top-color: ${mainColor} !important;
        }

        .filter-button {
          border-color: rgba(0, 0, 0, 0.2) !important;
          color: rgba(0, 0, 0, 0.6) !important;

          &.active,
          &:hover {
            border-color: ${borderColor} !important;
            color: ${textColor} !important;
          }
        }

        .card-container {
          border: 1px solid black;

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 8px 0px !important;
          }
        }

        .game-page-header-matchup {
          background: none !important;
          color: ${textColor} !important;

          .winner {
            color: ${textColor} !important;
          }

          .final-string {
            color: ${mainColor} !important;
          }
        }

        .stats-table {
          border-color: ${borderColor} !important;

          .header-group {
            top: 0 !important;
          }

          .mobile & .header-group {
            top: 56px !important;
          }

          .is-name-col {
            border-right-color: black !important;
          }

          .header {
            border-bottom-color: black !important;
          }

          .header,
          .row:nth-of-type(odd) .cell {
            background-color: ${backgroundColor} !important;
          }

          .row:nth-of-type(even) .cell {
            background-color: #e8e8e8 !important;
          }

          .cell.is-sorted {
            color: ${mainColor} !important;

            a {
              color: ${mainColor} !important;
            }
          }

          .statistics-player-row-number {
            color: ${textColor};
          }
        }

        select {
          color: ${textColor} !important;
          border-color: rgba(0, 0, 0, 0.3) !important;

          &:hover {
            border-color: ${borderColor} !important;
          }
        }

        .select-down-arrow {
          color: rgba(0, 0, 0, 0.3) !important;
        }

        *:hover ~ .select-down-arrow {
          color: ${textColor} !important;
        }

        .picker-value-container {
          color: ${textColor};
          border-color: rgba(0, 0, 0, 0.3) !important;
        }

        .picker-placeholder {
          color: ${textColor} !important;
        }

        .schedule-filter-submit {
          border: 1px solid ${mainColor} !important;
          background-image: unset !important;
          color: ${textColor} !important;

          &:hover {
            border-width: 2px !important;
          }
        }

        .schedule-filter-skater-type .toggler-container {
          border-color: rgba(0, 0, 0, 0.3) !important;

          button {
            &.active,
            &:hover {
              color: ${textColor} !important;
            }
          }

          .toggler-active-indicator {
            border-color: ${borderColor};
          }
        }

        .picker-wrapper {
          .picker-value-container:hover {
            border-color: ${borderColor} !important;
          }

          .picker-clear-button {
            color: ${textColor} !important;
          }

          .date-picker-contents {
            background-color: ${backgroundColor} !important;
            border-color: ${borderColor} !important;
          }

          .date-picker-icon {
            color: ${textColor} !important;
          }

          .DayPicker-NavButton:hover {
            filter: brightness(-200%) !important;
          }

          .mobile.DayPicker-wrapper {
            border-color: ${borderColor} !important;
          }

          .DayPicker-Day {
            &:hover {
              color: white !important;
            }

            &.DayPicker-Day--selected:not(.DayPicker-Day--outside) {
              color: white !important;
              background-color: black !important;

              &:hover {
                background-color: grey !important;
              }
            }

            &.DayPicker-Day--outside {
              &:hover {
                color: #f0f8ff;
              }
            }

            &:not(.DayPicker--interactionDisabled):not(
                .DayPicker-Day--disabled
              ):not(.DayPicker-Day--selected):not(
                .DayPicker-Day--outside
              ):hover {
              background-color: grey !important;
            }
          }

          .range {
            .DayPicker-Day--selected {
              &:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
                background-color: grey !important;
              }
            }
          }

          .picker-value {
            color: ${textColor} !important;
          }
        }

        .timeline-period-line {
          background-color: rgba(0, 0, 0, 0.2) !important;
        }

        .timeline-edit-btn-text {
          color: ${textColor} !important;
        }

        .timeline-event {
          background-color: rgba(0, 0, 0, 0.05) !important;

          .image-wrap .placeholder {
            border-color: ${borderColor} !important;
          }
        }

        .timeline-action-text.goal {
          color: ${mainColor} !important;
        }

        .timeline-shots-final-text {
          color: ${mainColor} !important;
        }

        .map-card-text {
          background: unset !important;
          text-shadow: unset !important;

          h2,
          h3,
          h4 {
            color: ${textColor} !important;
          }
        }

        .game-details-score-box {
          .final-text,
          .final-score-cell {
            color: ${textColor} !important;
          }
        }

        .game-details-stats-bar {
          &.left {
            background-color: rgba(0, 0, 0, 0.5);
          }
          &.right {
            background-color: ${mainColor};
          }
        }

        .score.is-winner {
          color: ${textColor} !important;
        }

        .initials-thumbnail {
          border-color: ${borderColor} !important;
        }

        .game-scoresheet-download-button {
          background-color: ${mainColor} !important;
          color: white !important;
        }

        .thumbnail-and-name-subtitle-text {
          color: rgba(0, 0, 0, 0.5) !important;
        }

        .outdated-handler-container .outdated-handler {
          background-color: rgba(255, 255, 255, 0.85);
          box-shadow: 0 2px 10px 0 rgba(24, 26, 29, 0.7);
        }

        .outdated-handler-container .outdated-handler-refresh-icon {
          color: white !important;
        }

        .day-of-games {
          background-color: rgba(0, 0, 0, 0.2) !important;
        }

        .score-indicator {
          background-color: ${mainColor} !important;
        }

        .game-row-final-text {
          color: ${mainColor} !important;
        }

        .game-row-current-period-time,
        .game-row-game-time {
          color: ${textColor} !important;
        }

        .mobile-game-header {
          background-color: rgba(0, 0, 0, 0.1) !important;
        }

        .mobile-game-row-final {
          color: ${mainColor} !important;
        }

        .mobile-game-body {
          background-color: white !important;
          border: 1px solid lightgray;
          border-width: 0px 1px 1px 1px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        .mobile-game-list-team-home-away {
          color: darkgray !important;
        }

        .report-select,
        .picker-value-container {
          background-color: white !important;
        }

        .report-select-icon path {
          fill: ${textColor} !important;
        }

        .list-row:nth-of-type(even) {
          background-color: white !important;

          .left-buffer,
          .right-buffer {
            background-color: white !important;
          }
        }

        .error-boundary-reload {
          background-image: none !important;
          color: ${textColor} !important;
          border: 1px solid ${mainColor} !important;
        }

        #game-sort-asc,
        #game-sort-desc,
        #skater-stats,
        #goalie-stats {
          background-color: white;
          color: ${textColor} !important;

          &:hover,
          &.sorted {
            border-color: ${mainColor} !important;
          }
        }

        .list-cell-sorted {
          color: ${mainColor} !important;
        }

        .breadcrumb-go-back-to,
        .player-list-affiliates-title {
          color: ${mainColor} !important;
        }

        .profile-top .actual-image,
        .player-list-player .actual-image,
        .player-statistics-player .actual-image,
        .profile-top .placeholder,
        .player-list-player .placeholder,
        .player-statistics-player .placeholder {
          border-color: black !important;
        }

        .profile-top,
        .profile-menu,
        .stats-by-season {
          background-color: white !important;
        }

        .mobile-page-header-back {
          color: ${mainColor} !important;
        }

        .header-first-cell,
        .first-cell,
        .list-row,
        .list-header,
        .left-buffer,
        .right-buffer {
          background-color: white !important;
        }

        #game-list-todays-game {
          background-color: white;
          color: black;

          &:hover {
            border-color: ${mainColor};
          }
        }

        #game-list-todays-game-toggle {
          border-color: black;

          &.active {
            background-color: ${mainColor};
          }
        }

        #game-list-todays-game-toggle-pip {
          background-color: black;
        }

        .team-roster-select {
          background-color: white !important;

          &:hover {
            border-color: black !important;
          }
        }

        .team-roster-select-dropdown {
          background-color: white !important;
        }

        .game-list-filters-competitions.is-selected {
          label {
            color: ${mainColor} !important;
          }

          select {
            border-color: ${mainColor} !important;
          }
        }

        .list-search-input-container {
          &:hover {
            input::placeholder {
              color: black !important;
            }
          }
        }

        .list-search-input {
          color: black !important;

          &:active,
          &:hover {
            background-color: transparent !important;
          }
        }

        .home-post {
          background: white !important;
        }

        .home-post-body,
        .home-post-url {
          color: black !important;
        }

        #schedule-bracket {
          .spacer-header,
          .left,
          .right {
            background-color: transparent;
            border-color: black;
            border-style: solid;
          }

          .spacer-header {
            border-width: 1px 0px 1px 1px;

            &.spacer-header-right {
              border-width: 1px 1px 1px 0px;
            }
          }

          .left {
            border-width: 1px 1px 1px 0px;
          }

          .right {
            border-width: 1px 0px 1px 1px;
          }

          .round-name {
            background-color: transparent;
            border-top: 1px solid black;
            border-bottom: 1px solid black;
          }

          .pool-bar,
          .slot,
          .slot-points {
            background-color: transparent;
          }

          .slot-points {
            border-left: 1px solid black;
          }

          .games-button {
            color: ${mainColor};
          }

          .game-info-popout {
            background-color: white;

            polygon {
              fill: white;
              stroke: white;
            }
          }

          .line-column {
            &:not(.highlighted) {
              path,
              line {
                stroke: black !important;
              }
            }

            &.highlighted {
              path,
              line {
                stroke: ${mainColor} !important;
              }
            }
          }
        }
      `}
    />
  )
}

export default FrameStyles
