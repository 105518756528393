import { call, put } from 'redux-saga/effects'

import actions from '../actions/users'
import req from '../api/request'
import entityImageSagas from './helpers/entity-image'
import { readFactory } from './helpers/read'
import { api as authApi } from './auth'
import { ENTITY_TYPES, generateSagas, wait } from './utils'

const ENTITY_TYPE = ENTITY_TYPES.users

export const api = {
  read: async (id) => await req(`/users/${id}`),
  readRoles: async (id) => await req(`/users/${id}/roles`),
  update: async (id, body) =>
    await req(`/users/${id}`, { method: 'PUT', body }),
}

const users = [
  [actions.read, readFactory('users', actions.read)],

  [
    actions.readRoles,
    function* ({ id }) {
      const { data } = yield call(api.readRoles, id)
      yield put(actions.readRoles.success({ id, data }))
      return data
    },
  ],

  [
    actions.update,
    function* ({ id, form }) {
      // We don't require confirm_password here, but we destructure it so we can
      // easily pass the remainder to user update endpoint
      const {
        password_old,
        password,
        confirm_password,
        imageData,
        delete_image,
        ...details
      } = form // eslint-disable-line no-unused-vars

      // i.e. if not just "{}"
      let response
      if (Object.keys(details).length > 0) {
        const userBody = JSON.stringify(details)
        response = yield call(api.update, id, userBody)
        yield put(actions.update.success({ id, data: response.data }))
      }

      if (typeof password !== 'undefined') {
        const passwordBody = JSON.stringify({ password_old, password })
        yield call(authApi.changePassword, passwordBody)
      }

      if (typeof imageData !== 'undefined') {
        yield wait(actions.image.create.request, id, imageData)
      } else if (delete_image) {
        yield wait(actions.image.delete.request, id)
      }

      return response
    },
  ],

  ...entityImageSagas(ENTITY_TYPE, actions.image),
]

export default generateSagas([...users])
