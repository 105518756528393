import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import req from '@sportninja/common/api/request'
import deepEqual from 'fast-deep-equal'

import { FormButton, FormRow } from '../Form/css'
import FormInput from '../Form/FormInput'
import { Flex } from '../Layout'
import Icon from '../Icon'
import TextModal from '../Modal/TextModal'

const ClearButton = ({ onClick }) => (
  <Icon
    name='times'
    fontSize={20}
    onClick={onClick}
    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
  />
)

const FacilityList = ({
  facilities = [],
  onChange,
  parentId,
  parentEntityType,
  venueId,
}) => {
  const facilityObject = useMemo(() => {
    return facilities.reduce((collector, facility) => {
      collector[facility.id] = facility
      return collector
    }, {})
  }, [facilities])
  const [rows, setRows] = useState(facilityObject)
  const [currentFacility, setCurrentFacility] = useState(false)
  const [deleteInProgress, setDeleteInProgress] = useState(false)
  const [error, setError] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [isSet, setIsSet] = useState(false)

  useEffect(() => {
    if (!dirty) {
      const isEqual = deepEqual(facilityObject, rows)
      if (!isEqual) setDirty(true)
    }
  }, [dirty, facilityObject, rows])

  useEffect(() => {
    if (dirty) {
      onChange({
        target: {
          name: 'newFacilities',
          value: rows,
        },
      })
      if (!isSet) {
        onChange({
          target: {
            name: 'facilities',
            value: facilities,
          },
        })
        setIsSet(true)
      }
    }
  }, [dirty, rows, isSet])

  return (
    <Flex column style={{ marginBottom: -20 }}>
      <TextModal
        title='Are you sure?'
        isOpen={currentFacility && Object.keys(currentFacility).length > 0}
        toggle={() => {
          if (!deleteInProgress) setCurrentFacility(false)
        }}
      >
        {() => {
          return (
            <div>
              <div>
                Press &apos;Confirm&apos; to permanently delete this facility:
              </div>
              <div style={{ marginTop: 8, fontWeight: 'bold' }}>
                {currentFacility.name}.
              </div>
              {error && (
                <div
                  style={{
                    color: 'red',
                    marginTop: 28,
                    wordBreak: 'break-word',
                  }}
                >
                  {error}
                </div>
              )}
              <div style={{ display: 'flex', marginTop: 28 }}>
                <FormButton
                  disabled={deleteInProgress}
                  onClick={() => setCurrentFacility(false)}
                >
                  Cancel
                </FormButton>
                <FormButton
                  disabled={deleteInProgress}
                  isSubmit
                  onClick={async () => {
                    setDeleteInProgress(true)
                    try {
                      await req(
                        `/${parentEntityType}/${parentId}/venues/${venueId}/facilities/${currentFacility.id}`,
                        { method: 'DELETE' }
                      )
                      setRows((state) => {
                        const newState = { ...state }
                        delete newState[currentFacility.id]
                        return newState
                      })
                      setCurrentFacility(false)
                    } catch (e) {
                      console.error(e)
                      setError(e.message)
                    } finally {
                      setDeleteInProgress(false)
                    }
                  }}
                >
                  Confirm
                </FormButton>
              </div>
            </div>
          )
        }}
      </TextModal>
      <p style={{ marginBottom: 12 }}>Facilities:</p>
      {Object.keys(rows).map((rowKey, idx) => {
        const row = rows[rowKey]
        return (
          <FormRow key={row.id || idx}>
            <FormInput
              input={{
                right: (
                  <ClearButton
                    onClick={(e) => {
                      e.preventDefault()
                      if (rowKey === row.id) {
                        setCurrentFacility(row)
                      } else {
                        setRows((state) => {
                          const newState = { ...state }
                          delete newState[rowKey]
                          return newState
                        })
                      }
                    }}
                  />
                ),
                autoComplete: 'chrome-off',
                name: row.id || `facility_${idx + 1}`,
                placeholder: 'Enter a facility name',
                value: row.name || '',
              }}
              onChange={({ target }) => {
                setRows((state) => {
                  return {
                    ...state,
                    [row.id || `facility_${idx}`]: {
                      ...row,
                      name: target.value,
                    },
                  }
                })
              }}
            />
          </FormRow>
        )
      })}
      <FormButton
        as='div'
        onClick={() => {
          setRows((state) => ({
            ...state,
            [`facility_${Object.keys(rows).length}`]: {},
          }))
        }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 32,
          width: 120,
          fontSize: 14,
          cursor: 'pointer',
          marginLeft: 0,
        }}
      >
        + Add Facility
      </FormButton>
    </Flex>
  )
}

FacilityList.propTypes = {
  facilities: PropTypes.array,
}

export default FacilityList
