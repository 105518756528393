import { isCanlan } from './customer-name'
import storage from './storage'

const _safeGet = async (key) => {
  try {
    return await storage.getItem(key)
  } catch (e) {
    return false
  }
}

export const Environments = {
  LOCAL: 'http://localhost:8080/v1',
  DEV: 'https://api-dev.sportninja.com/v1',
  PROD: isCanlan
    ? 'https://canlan2-api.sportninja.net/v1'
    : 'https://metal-api.sportninja.net/v1',
}

const ENV_STRING = 'sn:custom_env'
// We use this to allow the developer to toggle which API environment is used -
// we need to persist the change to local storage because a refresh will be
// required.
const EnvHandler = {
  value: null,

  async clear() {
    await storage.removeItem(ENV_STRING)
  },

  async get() {
    const storedValue = await _safeGet(ENV_STRING)

    if (__DEV__ || storedValue) {
      return storedValue || Environments.LOCAL
    } else {
      // if (window?.location?.hostname === 'client-develop.sportninja.com') {
      //   return Environments.DEV
      // }
      return Environments.PROD
    }
  },

  // Be careful with this - if there is a value detected in local storage / AsyncStorage
  // then it will be used instead of the default value!
  async set(value) {
    if (__DEV__ && value === Environments.LOCAL) {
      await storage.removeItem(ENV_STRING)
    } else if (window?.location?.hostname === 'client-develop.sportninja.com') {
      await storage.setItem(ENV_STRING, value)
    } else if (!__DEV__ && value === Environments.PROD) {
      await storage.removeItem(ENV_STRING)
    } else {
      await storage.setItem(ENV_STRING, value)
    }
    return value
  },
}

export default EnvHandler
