import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled/macro'
import { isCanlan } from '@sportninja/common/utils/customer-name'

import { Flex } from '../Layout'
import Icon from '../Icon'
import { loadingSpinner } from '../css'
import gradient from './gradient.svg'
import gradientHover from './gradient-hover.svg'
import gradientCanlan from './gradient.canlan.svg'
import gradientHoverCanlan from './gradient-hover.canlan.svg'
import { media } from '../Responsive'

const Button = styled(Flex)`
  min-height: 44px;
  height: 44px;
  width: 44px;

  background-image: url(${isCanlan ? gradientCanlan : gradient});
  background-repeat: no-repeat;
  background-position: center;

  font-size: 14px;

  opacity: ${(props) => props.disabled && '0.5'};
  cursor: ${(props) => props.disabled && 'not-allowed'};
`

const Label = styled.div`
  position: absolute;
  top: calc(100% + 4px);

  font-size: 10px;
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;

  ${media.mobile} {
    top: 50px;
  }
`

export const ActionButtonWrapper = styled(Flex)`
  justify-content: center;
  position: relative;
  cursor: pointer;

  opacity: ${(props) => props.busy && 0.6};

  &:hover {
    ${Button} {
      background-image: url(${isCanlan ? gradientHoverCanlan : gradientHover});
    }
  }

  @media (hover: hover) {
    ${Label} {
      ${(props) => !props.alwaysShowLabel && 'opacity: 0'};
      transition: opacity 0.1s ease-in-out;
    }

    &:hover ${Label} {
      opacity: 1;

      &[disabled] {
        opacity: 0.5;
      }
    }
  }
`

const Loader = styled.div`
  ${loadingSpinner}
`

const ActionButton = ({
  alwaysShowLabel = false,
  busy,
  className,
  disabled,
  faType,
  icon = 'pencil-alt',
  label,
  onClick,
  title,
  id,
}) => {
  return (
    <ActionButtonWrapper
      noFlex
      onClick={busy ? undefined : onClick}
      className={className}
      busy={busy}
      alwaysShowLabel={alwaysShowLabel}
    >
      <Button
        as='button'
        noFlex
        alignItems='center'
        justifyContent='center'
        disabled={disabled}
        id={id}
      >
        <span className='sr-only'>{title}</span>
        {busy ? <Loader /> : <Icon faType={faType} name={icon} />}
      </Button>
      {label && <Label disabled={disabled}>{label}</Label>}
    </ActionButtonWrapper>
  )
}

ActionButton.propTypes = {
  alwaysShowLabel: PropTypes.bool,
  busy: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
}

export default ActionButton
