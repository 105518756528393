export const getShootoutByGameId = (state, id) => {
  if (
    state.shootout &&
    Object.prototype.hasOwnProperty.call(state.shootout, id)
  ) {
    return state.shootout[id]
  }

  return false
}
