import { makeObservable, observable, runInAction, action, computed } from 'mobx'
import { RootStore } from '..'

type DownloadBannerOptions = {
  isBannerVisible?: boolean
  isSmall?: boolean
  isBig?: boolean
}
export class AppStore {
  rootStore: RootStore
  isDarkMode: boolean = true
  showDownloadBanner: DownloadBannerOptions = {
    isBannerVisible: true,
    isSmall: false,
    isBig: true,
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore

    makeObservable(this, {
      isDarkMode: observable,
      showDownloadBanner: observable,
      setDarkMode: action,
      setLightMode: action,
      setDownloadBannerSize: action,
      setDownloadBannerVisibility: action,
      state: computed,
    })
  }

  setDarkMode = () => {
    runInAction(() => {
      this.isDarkMode = true
    })
  }

  setLightMode = () => {
    runInAction(() => {
      this.isDarkMode = false
    })
  }

  setDownloadBannerSize = (size: 'small' | 'big') => {
    runInAction(() => {
      this.showDownloadBanner.isSmall = size === 'small'
      this.showDownloadBanner.isBig = size === 'big'
    })
  }

  setDownloadBannerVisibility = (isVisible: boolean) => {
    runInAction(() => {
      this.showDownloadBanner.isBannerVisible = isVisible
    })
  }

  get state() {
    return {
      isDarkMode: this.isDarkMode,
      showDownloadBanner: this.showDownloadBanner,
    }
  }
}
