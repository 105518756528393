/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { ColorPicker } from 'antd'
import React from 'react'
import { paragraphSmall, weightRegular } from 'src/components/css'
import { ErrorLabel } from '../ErrorLabel'

type ColorPickerInputProps = {
  name: string
  value: string
  setValue: (value: string) => void
  fieldErrors: any
  customError: any
  label: string
  type?: string
  maxLength?: number
}

export const ColorPickerInput: React.FC<ColorPickerInputProps> = ({
  name,
  value,
  setValue,
  fieldErrors,
  customError,
  label,
}) => {
  return (
    <>
      <style>
        {`
          .ant-popover {
            z-index: 100005 !important;
          }
          .ant-color-picker-trigger {
            justify-content: space-between;
            padding-left: 8px!important;
            padding-right: 8px!important;
            padding-top: 8px!important;
            padding-bottom: 8px!important;
            border-radius: 4px!important;
            background-color: ${colors.HEADER}!important;
          }
          .ant-color-picker-color-block {
            width: 24px!important;
            height: 24px!important;
            border-radius: 4px!important;
          }
        `}
      </style>
      <p
        css={css`
          ${paragraphSmall}
          ${weightRegular}
          color: ${colors.WHITE};
          align-self: stretch;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        `}
      >
        {label}
      </p>
      <ColorPicker
        defaultValue={value || '#000000'}
        size='large'
        showText
        placement='bottom'
        css={css`
          align-self: stretch;
        `}
        onChange={(v) => setValue(v.toHex())}
        disabledAlpha={true}
      />
      <ErrorLabel
        fieldErrors={fieldErrors}
        customError={customError}
        name={name}
      />
    </>
  )
}
