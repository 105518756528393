import { entityImageDefinition } from './shared-definitions'
import { generateActions } from './utils'

const userDefinition = {
  read: ['id'],
  readRoles: ['id'],
  update: ['id', 'form'],

  ...entityImageDefinition,
}

const usersActions = generateActions(['USERS'], userDefinition)

export default usersActions
