import { useCallback, useEffect, useMemo, useState } from 'react'

import req from '../api/request'
import { ROUTES } from '../constants/app'
import { ENTITY_TYPES } from '../sagas/utils'

export const playerTeamParser = (team, playerTypes) => {
  let position = []

  if (team.player_number) {
    position.push(`#${team.player_number}`)
  }

  let isGoalie
  if (team.player_type_id && playerTypes) {
    const playerType = playerTypes.find((t) => t.id === team.player_type_id)
    isGoalie = playerType?.name_full === 'Goalie'

    position.push(playerType?.name_full)
  }

  return {
    id: team.team_id,
    fullName: team.team_name,
    imageId: team?.team_image?.full_path,
    playerId: team.player_id,
    position: position.length > 0 ? position.join(' - ') : 'Player',
    to: `${ROUTES.TEAM_ROOT}/${team.team_id}`,
    rosterId: team.team_player_roster_id,
    entityType: ENTITY_TYPES.team,
    isGoalie,
    suspensions: team?.suspensions,
  }
}

const useGetPlayerTeams = (playerTypes, isPublicRoute) => {
  const [playerTeams, setPlayerTeams] = useState([])

  const request = useCallback(async () => {
    if (!isPublicRoute) {
      const { data: listOfTeams } = await req('/users/me/players')
      const teams = listOfTeams.map((team) => {
        return playerTeamParser(team, playerTypes)
      })
      setPlayerTeams(teams)
    }
  }, [isPublicRoute, playerTypes])

  useEffect(() => {
    request()
  }, [])

  const memoizedResponse = useMemo(
    () => [playerTeams, request],
    [playerTeams, request]
  )

  return memoizedResponse
}

export default useGetPlayerTeams
