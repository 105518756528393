/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { Spin, Table, Popconfirm } from 'antd'
import { observer } from 'mobx-react-lite'
import { Suspense, useCallback, useEffect, useRef, useState } from 'react'
import Icon from 'src/components/Icon'
import { NewModal } from 'src/components/NewModal'
import { Input } from 'src/components/Input'
import { Button } from 'src/components/Button'
import { useMobxStore } from 'src/state'
import { ModalHeader } from './UpgradeModal'
import { useForm } from 'react-hook-form'
import { SNTextArea } from 'src/components/TextArea'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    align: 'left' as const,
  },
  {
    title: 'Waiver Content',
    dataIndex: 'waiver_content',
    key: 'description',
    render: (text: string) => (
      <div style={{ whiteSpace: 'pre-line' }}>
        {text?.length > 100 ? `${text?.slice(0, 400)}...` : text}
      </div>
    ),
  },
  {
    title: 'Options',
    dataIndex: 'options',
    key: 'options',
  },
]

type WaiverType = {
  name: string
  waiver_content: string
}

export const Waivers = observer(() => {
  const {
    me: {
      state: { account, loading },
    },
  } = useMobxStore()
  const { control, handleSubmit, setValue } = useForm<WaiverType>({
    defaultValues: {
      name: '',
      waiver_content: '',
    },
  })
  const waiverModalRef = useRef<NewModal>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [selectedWaiverId, setSelectedWaiverId] = useState('')
  const [waiverError, setWaiverError] = useState(false)
  const [dataSource, setDataSource] = useState<any[]>([])
  const [error, setError] = useState(false)

  const fetchData = useCallback(async () => {
    try {
      const { data } = await req(`/account/${account?.uid}/waiver/`)
      if (data) {
        const updatedData = data.map((item: any) => ({
          name: item?.name,
          waiver_content: item?.waiver_content,
          options: getOptions(item),
        }))
        setDataSource(updatedData)
      }
    } catch (e: any) {
      console.error(e)
    }
  }, [account?.uid, setValue, setIsUpdate, setSelectedWaiverId, waiverModalRef])

  const getOptions = (item: any) => (
    <div
      css={css`
        display: flex;
        gap: 16px;
      `}
    >
      <button
        onClick={() => {
          setValue('name', item?.name)
          setValue('waiver_content', item?.waiver_content || '')
          setIsUpdate(true)
          setSelectedWaiverId(item?.id)
          waiverModalRef.current?.openModal()
        }}
      >
        <Icon name='pen' color={colors.DEFAULT_FLAIR} fontSize={16} />
      </button>
      <Popconfirm
        title='Delete this waiver?'
        description='Are you sure you want to delete this waiver?'
        onConfirm={async () => {
          await req(`/account/${account?.uid}/waiver/${item?.id}/`, {
            method: 'DELETE',
          })
          fetchData()
        }}
        okText='Yes'
        cancelText='No'
      >
        <button>
          <Icon name='trash' color={colors.ERROR} fontSize={16} />
        </button>
      </Popconfirm>
    </div>
  )

  useEffect(() => {
    fetchData()
  }, [])

  const onSubmit = useCallback(
    async (data: WaiverType) => {
      try {
        setError(false)
        setWaiverError(false)
        setIsLoading(true)

        if (data.name === '') {
          setWaiverError(true)
          setIsLoading(false)
          return
        }

        if (data.waiver_content === '') {
          setWaiverError(true)
          setIsLoading(false)
          return
        }

        await req(
          isUpdate
            ? `/account/${account?.uid}/waiver/${selectedWaiverId}`
            : `/account/${account?.uid}/waiver`,
          {
            method: isUpdate ? 'PUT' : 'POST',
            body: JSON.stringify({
              name: data.name,
              waiver_content: data.waiver_content,
            }),
          }
        )

        setIsLoading(false)
        fetchData()
        waiverModalRef.current?.closeModal()
      } catch (e: any) {
        console.error(e)
        setIsLoading(false)
        setError(e?.message)
      }
    },
    [account, isLoading, isUpdate, selectedWaiverId]
  )

  return (
    <>
      <div
        css={css`
          background-color: ${colors.HEADER};
          padding: 16px;
          border-radius: 8px;
          border: 1px solid ${colors.SOFT_STEEL};
          max-width: 820px;
          margin: 0 auto;
          margin-top: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <div>
            <p
              css={css`
                font-family: 'Rift', sans-serif;
                font-size: 24px;
                font-weight: 700;
              `}
            >
              Waivers
            </p>
            <p
              css={css`
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 12px */
                margin-top: 8px;
                color: ${colors.ATTENDANCE_GRAY};
              `}
            >
              Setup your waivers
            </p>
          </div>
          <button
            onClick={() => {
              setValue('name', '')
              setValue('waiver_content', '')
              setIsUpdate(false)
              setWaiverError(false)
              setIsLoading(false)
              setError(false)
              waiverModalRef.current?.openModal()
            }}
            css={css`
              display: flex;
              gap: 8px;
              align-items: center;
              color: ${colors.DEFAULT_FLAIR};
            `}
          >
            <p>Add</p>
            <Icon
              name='plus-square'
              color={colors.DEFAULT_FLAIR}
              fontSize={16}
            />
          </button>
        </div>

        <Suspense fallback={<Spin />}>
          <div
            css={css`
              margin-top: 16px;
              padding-top: 16px;
              border-top: 1px solid ${colors.SOFT_STEEL};

              * {
                border-color: ${colors.SOFT_STEEL} !important;
              }
            `}
          >
            <Table
              loading={loading}
              bordered
              pagination={false}
              columns={columns}
              dataSource={dataSource}
              locale={{
                emptyText: 'No Waivers Registered',
              }}
            />
          </div>
        </Suspense>
      </div>
      <NewModal ref={waiverModalRef} shouldCloseOnOverlayClick={false}>
        <div
          css={css`
            padding: 16px;
            background: no-repeat fixed linear-gradient(#282e38, #181a1d);
            display: flex;
            flex-direction: column;
            max-height: 90vh;
            overflow-y: auto;
            width: 600px;
            /* Removing this due to the blur content issue reported on: https://sportninja.atlassian.net/browse/SN-3839 */
            /* border-radius: 8px; */
          `}
        >
          <ModalHeader
            title={isUpdate ? 'Update Waiver' : 'Add Waiver'}
            onCloseClick={() => {
              setValue('name', '')
              setValue('waiver_content', '')
              setIsUpdate(false)
              setWaiverError(false)
              setIsLoading(false)

              waiverModalRef.current?.closeModal()
            }}
            hideSupportButton
          />
          <div
            css={css`
              margin-top: 16px;
            `}
          >
            <div
              css={css`
                .ant-input {
                  background-color: ${colors.HEADER};
                }
              `}
            >
              <Input
                control={control}
                fieldName='name'
                label='Waiver Name'
                invalid={waiverError}
                invalidMessage='Name is required'
              />
              <SNTextArea
                control={control}
                fieldName='waiver_content'
                label='Waiver Content'
                invalid={waiverError}
                invalidMessage='Content is required'
              />
            </div>
            <div
              css={css`
                color: ${colors.ERROR};
                margin-bottom: 8px;
              `}
            >
              {error}
            </div>
            <div
              css={css`
                display: flex;
                gap: 16px;
                align-items: center;
                margin-top: 16px;
              `}
            >
              <Button
                label='Cancel'
                disabled={isLoading}
                variant='secondary'
                onClick={() => {
                  setWaiverError(false)
                  waiverModalRef.current?.closeModal()
                }}
              />

              <Button
                label={isUpdate ? 'Update' : 'Confirm'}
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </NewModal>
    </>
  )
})
