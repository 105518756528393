import { put } from 'redux-saga/effects'
import req from '@sportninja/common/api/request'

import actions from '../actions/search'
import { generateSagas } from './utils'

const search = [
  [actions.search, function* (payload) {
    const response = yield req(payload.url)
    const permissions = response && response.meta && response.meta.permissions

    let data = response.data
    if (permissions) {
      data = data.map((d) => {
        const permission = permissions[d.id]
        if (permissions) d.permission = permission
        return d
      })
    }

    yield put(actions.search.success({ data, entityType: payload.entityType }))
    return response
  }]
]

export default generateSagas([
  ...search
])
