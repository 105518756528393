/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'

const DivisionToolbarItem = ({ title, subTitle }) => {
  return (
    <div
      css={css`
        padding-right: 8px;
        width: 100%;
      `}
    >
      <div
        css={css`
          font-weight: 700;
          font-size: 14px;
          text-transform: capitalize;
          color: ${colors.LINK_WATER};
        `}
      >
        {title}
      </div>
      {subTitle && (
        <div
          css={css`
            font-weight: 500;
            font-size: 14px;
            color: ${colors.PALE_SKY};
            margin-top: 4px;
          `}
        >
          {subTitle}
        </div>
      )}
    </div>
  )
}

export default DivisionToolbarItem
