/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import colors from '@sportninja/common/constants/appColors'
import { t } from '@sportninja/common/i18n'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import cn from 'classnames'

import { font } from '../../css'
import DatePicker from '../../DatePicker'
import { Flex } from '../../Layout'
import { media } from '../../Responsive'
import SuspensionSelect from '../../Suspensions/SuspensionSelect'

export const SortOrderButton = styled.button`
  ${font.body}
  height: 40px;
  background: #27303e;
  border: ${isCanlan ? 2 : 1}px solid #3f4753;
  border-radius: 4px;
  padding: 0 8px;
  color: white;

  &[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &:hover:not([disabled]) {
    border-color: white;
  }

  &.sorted {
    border-color: white;
  }
`

export const InFrameSortOrderButton = styled.button`
  ${font.body}
  height: 40px;
  background: transparent;
  border: ${isCanlan ? 2 : 1}px solid #3f4753;
  border-radius: 4px;
  padding: 0 8px;
  color: ${colors.HEADER};

  &[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &:hover:not([disabled]) {
    border-color: #3f4753;
  }

  &.sorted {
    border-color: ${colors.HEADER};
    border-width: 2px;
  }
`

const GameListFilters = ({
  filters,
  dateRange,
  team_id,
  onTeamChange,
  onScheduleChange,
  setDateRange,
  state,
  setState,
  teams,
  team,
  sort,
  setSort,
  loading,
  useDateRange = false,
  useGameOrder = false,
  children,
  isHidden = false,
}) => {
  if (
    isHidden ||
    (filters?.length === 0 && !useDateRange && !useGameOrder && !children)
  ) {
    return false
  }

  return (
    <Flex
      flexWrap
      css={css`
        border-bottom: 1px solid #3f4753;
        margin-bottom: 16px;

        ${media.mobile} {
          padding: 0 8px;
        }

        .game-list-filters-team,
        .game-list-filters-competitions {
          max-width: 260px;
          width: 260px;

          select {
            padding-right: 34px;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
          }

          &.is-selected {
            select {
              border-color: ${colors.PRIMARY};
            }

            label {
              color: ${colors.PRIMARY};
            }
          }
        }

        .game-list-filters-team {
          max-width: 220px;
          width: 220px;
        }
      `}
    >
      {filters.map(({ name, schedules = [] }, idx) => {
        let placeholderValue
        const rootScheduleIsSelected = schedules.find(
          (s) => s.id === team_id
        )?.id
        if (rootScheduleIsSelected) placeholderValue = rootScheduleIsSelected

        const nextFilter = filters[idx + 1]

        if (nextFilter) {
          const parentIdOfSelectedSchedule = nextFilter?.schedules?.find(
            (s) => {
              return s.id === state[nextFilter.name]
            }
          )?.parent?.id

          if (
            parentIdOfSelectedSchedule &&
            schedules.find((s) => s.id === parentIdOfSelectedSchedule)
          )
            placeholderValue = parentIdOfSelectedSchedule
        }

        return (
          <SuspensionSelect
            className={cn('game-list-filters-competitions', {
              'is-selected': state[name],
            })}
            disabled={loading}
            enabledAllItemsOption
            label={name}
            key={idx}
            value={
              state[name] ||
              (!Object.prototype.hasOwnProperty.call(state, name) &&
                placeholderValue)
            }
            onChange={(scheduleId) => {
              const filterThatChanged = filters.findIndex(
                (filter) => filter.name === name
              )
              const filtersToReset = filters.slice(
                filterThatChanged + 1,
                filters.length
              )
              const keysToReset = filtersToReset.map((filter) => filter.name)

              let newState = { ...state, [name]: scheduleId }
              keysToReset.forEach((keyName) => {
                newState[keyName] = ''
              })

              setState(newState)
              onScheduleChange(scheduleId, name)
            }}
            options={schedules
              .filter((schedule) => {
                const previousFilters = filters.slice(0, idx)
                let parentIdToCheck = schedule.parent?.id

                for (let i = previousFilters.length - 1; i >= 0; i--) {
                  const previousFilter = previousFilters[i]
                  const previousFilterName = previousFilter.name

                  if (
                    state[previousFilterName] &&
                    state[previousFilterName].length > 0
                  ) {
                    return parentIdToCheck === state[previousFilterName]
                  } else {
                    const parentSchedule = previousFilter.schedules.find(
                      (s) => s.id === parentIdToCheck
                    )
                    if (parentSchedule)
                      parentIdToCheck = parentSchedule.parent?.id
                  }
                }

                return true
              })
              .map((schedule) => {
                return {
                  text: schedule.name_full,
                  value: schedule.id,
                }
              })}
          />
        )
      })}

      {Array.isArray(teams) && teams.length > 0 && (
        <SuspensionSelect
          className={cn('game-list-filters-team', {
            'is-selected': !!team,
          })}
          disabled={loading}
          enabledAllItemsOption
          label={t('util:capitalize', { text: t('common:team') })}
          value={team}
          onChange={(teamId) => {
            onTeamChange(teamId)
            // handleQueryStateChange({ ...state, Team: target.value })
          }}
          options={teams
            .filter((team) => {
              let parentIdToCheck = team.schedule_id

              for (let i = filters.length - 1; i >= 0; i--) {
                const previousFilter = filters[i]
                const previousFilterName = previousFilter.name

                if (
                  state[previousFilterName] &&
                  state[previousFilterName].length > 0
                ) {
                  return parentIdToCheck === state[previousFilterName]
                } else {
                  const parentSchedule = previousFilter.schedules.find(
                    (s) => s.id === parentIdToCheck
                  )
                  if (parentSchedule)
                    parentIdToCheck = parentSchedule.parent?.id
                }
              }

              return true
            })
            .map((team) => {
              return {
                text: team.name,
                value: team.id,
              }
            })}
        />
      )}

      {useDateRange && (
        <div
          css={css`
            margin: 0 16px 16px 0;
            width: 260px;
            min-width: 260px;

            .picker-label {
              height: 12px;
              font-size: 12px;
              line-height: 12px;
              margin-bottom: 8px;
              letter-spacing: 1.04px;
            }

            .picker-value-container {
              background: #27303e;
              border: ${isCanlan ? 2 : 1}px solid #3f4753;
              border-radius: 4px;
              height: 40px;
            }
          `}
        >
          <DatePicker
            datePlaceholder='Select a date range'
            defaultValues={(() => {
              const values = {}
              if (dateRange.starts_at)
                values.starts_at = { value: dateRange.starts_at }
              if (dateRange.ends_at)
                values.ends_at = { value: dateRange.ends_at }
              return values
            })()}
            disabled={loading}
            hideTime
            label='Date Range'
            onChange={({ target }) => {
              const { name, value } = target

              setDateRange((prevDateRange) => {
                return {
                  ...prevDateRange,
                  [name]: value,
                }
              })
            }}
            useRange
          />
        </div>
      )}
      {useGameOrder && (
        <Flex column noFlex id='sandbox-filters-sort-order'>
          <label
            htmlFor={sort === 'asc' ? 'game-sort-desc' : 'game-sort-asc'}
            css={css`
              height: 12px;
              font-size: 12px;
              line-height: 12px;
              color: ${loading && 'rgba(255, 255, 255, 0.7)'};
            `}
          >
            Sort
          </label>
          <Flex
            css={css`
              margin-top: 8px;
              padding: 0 16px 16px 0;

              button:last-of-type {
                margin-left: 8px;
              }
            `}
          >
            <SortOrderButton
              id='game-sort-asc'
              disabled={loading}
              type='button'
              className={cn({ sorted: sort === 'asc' })}
              onClick={() => {
                setSort('asc')
              }}
            >
              Oldest
            </SortOrderButton>

            <SortOrderButton
              id='game-sort-desc'
              disabled={loading}
              type='button'
              className={cn({ sorted: sort === 'desc' })}
              onClick={() => {
                setSort('desc')
              }}
            >
              Newest
            </SortOrderButton>
          </Flex>
        </Flex>
      )}
      {children}
    </Flex>
  )
}

export default GameListFilters
