/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import React from 'react'
import styled from '@emotion/styled'
import colors from '@sportninja/common/constants/appColors'

interface SliderProps {
  min: number
  max: number
  value: number
  onChange: (value: number) => void
  hideValue?: boolean
  threshold?: number
}

type InputRangeProps = {
  max: number
  value: number
  min: number
}

const calculatePercentage = (value: number, min: number, max: number) =>
  ((value - min) / (max - min)) * 100

const SliderContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`

const SliderInput = styled.input<InputRangeProps>(
  ({ value, min, max }) => css`
    width: 100%;
    -webkit-appearance: none;
    background: linear-gradient(
      to right,
      ${colors.DEFAULT_FLAIR} 0%,
      ${colors.DEFAULT_FLAIR} ${calculatePercentage(value, min, max)}%,
      ${colors.HEADER_BG_2} ${calculatePercentage(value, min, max)}%,
      ${colors.HEADER_BG_2} 100%
    );
    border-radius: 8px;
    height: 8px;
    outline: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 16px;
      height: 16px;
      background: ${colors.WHITE};
      border-radius: 50%;
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  `
)

export const Slider = ({
  min,
  max,
  value,
  onChange,
  hideValue = false,
  threshold = 0,
}: SliderProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10)
    const diff = newValue - value
    if (Math.abs(diff) >= threshold) {
      onChange(newValue)
    }
  }

  return (
    <SliderContainer>
      <SliderInput
        type='range'
        min={min}
        max={max}
        value={value}
        onChange={handleChange}
      />
      {!hideValue && (
        <p
          css={css`
            font-size: 14px;
            font-weight: 500;
            margin-top: 8px;
            color: ${colors.ATTENDANCE_GRAY};
          `}
        >
          Value: {value}
        </p>
      )}
    </SliderContainer>
  )
}
