import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { t } from '@sportninja/common/i18n'
import {
  cleanPhoneNumber,
  formatInternationalPhoneNumber,
  isValidEmail,
} from '@sportninja/common/utils/utils'
import React, { useEffect } from 'react'
import FormInput from '../Form/FormInput'
import PhoneInputComponent from '../PhoneFormComponent'

const phoneTitleEnabled = {
  fontSize: 11,
  letterSpacing: 0.29,
  textTransform: 'capitalize',
}

const phoneTitleDisabled = {
  fontSize: 11,
  letterSpacing: 0.29,
  textTransform: 'capitalize',
  color: 'rgba(255, 255, 255, 0.5)',
  opacity: 0.5,
}

const InvitationFormField = ({
  customHelpPhone,
  customHelpEmail,
  defaultValuePhone,
  defaultValueEmail,
  disabledPhone,
  disabledEmail,
  hasErrorPhone,
  hasErrorEmail,
  hideHelpPhone,
  hideHelpEmail,
  namePhone,
  nameEmail,
  requiredPhone,
  requiredEmail,
  onChange,
}) => {
  const [isPhoneFocused, setIsPhoneFocused] = React.useState(false)
  const [phoneValue, setPhoneValue] = React.useState(
    defaultValuePhone
      ?.replace(/\D/g, '')
      ?.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4')
  )

  const [emailValue, setEmailValue] = React.useState(defaultValueEmail)

  const [invalidPhoneError, setInvalidPhoneError] = React.useState(false)

  const [invalidEmailError, setInvalidEmailError] = React.useState(false)

  useEffect(() => {
    if (phoneValue && phoneValue?.length > 0 && phoneValue?.length < 11) {
      setInvalidPhoneError(true)
    } else {
      setInvalidPhoneError(false)
    }
  }, [phoneValue])

  useEffect(() => {
    if (emailValue) {
      setInvalidEmailError(!isValidEmail(emailValue))
    }
  }, [emailValue])

  const handlePhoneChange = ({ target: { value } }) => {
    let cleanedValue = cleanPhoneNumber(value)
    cleanedValue = formatInternationalPhoneNumber(cleanedValue)
    setPhoneValue(cleanedValue)
    onChangePhone({ target: { name: namePhone, value: cleanedValue } })
  }

  const onChangePhone = ({ target }) => {
    onChange({ target })
  }

  const onChangeEmail = ({ target }) => {
    onChange({ target })
    setEmailValue(target.value)
  }

  return (
    <>
      <style>
        {`
          .react-tel-input .country-list .country.hover {
            background-color: red;
          }
          .react-tel-input .flag-dropdown:disabled {
            cursor: default;
            background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
            color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
            border-color: rgba(118, 118, 118, 0.3);
            opacity: 0.5;
          }
          .react-tel-input .flag-dropdown.open .selected-flag {
            background-color: transparent;
          }
          .react-tel-input .selected-flag .flag:disabled {
            cursor: default;
            background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
            color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
            border-color: rgba(118, 118, 118, 0.3);
            opacity: 0.5; 
          }
          .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
            background-color: transparent;
          }
          .react-tel-input .country-list .country.highlight {
            background-color: ${colors.HEADER};
          }
          .react-tel-input .country-list .country:hover {
            background-color: ${colors.HEADER};
          }  
          .react-tel-input .form-control:disabled {
            cursor: default;
            background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
            color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
            border-color: rgba(118, 118, 118, 0.3);
          }
      `}
      </style>
      <FormInput
        onChange={onChangeEmail}
        disabled={disabledEmail}
        hasError={hasErrorEmail || invalidEmailError}
        input={{
          defaultValue: defaultValueEmail,
          name: nameEmail,
          label: t('common:email'),
          placeholder: 'patq@sportninja.com',
          type: 'email',
          required: requiredEmail,
        }}
      >
        {!hideHelpEmail && (
          <p style={{ marginTop: 16, fontSize: 13 }}>
            {t('Web:inviteToJoinDescription')} {t('Web:invitedToRegister')}
          </p>
        )}
        {customHelpEmail}
      </FormInput>
      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          position: 'relative',
          gap: 4,
          marginTop: 4,
        }}
        css={css`
          .react-tel-input .country-list .country.highlight {
            background-color: rgba(255, 255, 255, 0.1);
          }
        `}
      >
        <p
          style={
            disabledPhone || !emailValue || !isValidEmail(emailValue)
              ? phoneTitleDisabled
              : phoneTitleEnabled
          }
        >
          {t('common:phone')}
        </p>
        <PhoneInputComponent
          disabled={disabledPhone || !emailValue || !isValidEmail(emailValue)}
          namePhone={namePhone}
          phoneValue={phoneValue}
          setPhoneValue={setPhoneValue}
          onChangePhone={onChangePhone}
        />
      </div>
      {!hideHelpPhone && (
        <p style={{ marginTop: 16, fontSize: 13 }}>
          {'Add your phone number to receive SMS notifications.'}
        </p>
      )}
      {customHelpPhone} */}
    </>
  )
}

export default InvitationFormField
