import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { t } from '@sportninja/common/i18n'
import cn from 'classnames'
import { getDayjsTZDate } from '@sportninja/common/utils/utils'

import { Container, Column, Flex } from '../../components/Layout'
import Picture from '../../components/Picture'

import './desktop-game-list-item.scss'
import { isCanlan } from '@sportninja/common/utils/customer-name'

export const getGameStatusLabel = (gameStatus, statusTypes) => {
  if (!statusTypes || !Array.isArray(statusTypes)) return ''
  const status = statusTypes.find((type) => type?.id === gameStatus)
  if (status?.name) return status.name
  return ''
}

const Team = ({ team, isAway, isRight }) => (
  <Link to={team.to} className={cn('team', { 'is-right': isRight })}>
    <div className={cn('sideway-text', { right: isAway })}>
      {t(isAway ? 'common:visitor' : 'common:home')}
    </div>
    <Picture
      className='team-logo'
      iconName='user-friends'
      imageId={team.imageId}
      size='medium'
      square
      fit
    />
    <div className='team-name'>{team.fullName}</div>
  </Link>
)

Team.propTypes = {
  team: PropTypes.shape({
    fullName: PropTypes.string,
    imageId: PropTypes.string,
  }),
}

const DesktopGameListItem = ({ game, gameStatusTypes, gameTypes }) => {
  if (!game.homeTeam || !game.visitingTeam) return false

  const localDate = dayjs(game.startsAt)
  const date = getDayjsTZDate(game.startsAt, game?.location?.timezone)
  const displayGameTime = date.format('z') !== localDate.format('z')

  const homeTeamWon =
    game.homeTeamScore > game.visitingTeamScore || game.gameStatus === 3
  const visitingTeamWon =
    game.homeTeamScore < game.visitingTeamScore || game.gameStatus === 2

  let currentPeriod = {}
  if (game?.current_period) {
    currentPeriod = {
      fullName: game.current_period.period_type.name_full,
      name: game.current_period.period_type.name,
      isOvertime: game?.current_period?.period_type?.is_overtime || false,
    }

    if (game.current_period.clock_time) {
      currentPeriod.clockTime = dayjs(
        game.current_period.clock_time,
        'hh:mm:ss'
      ).format('m:ss')
    }
  }

  const fullGameType = gameTypes?.find((gameType) => {
    return parseInt(gameType?.id, 10) === game?.gameType
  })

  return (
    <Container
      forceDesktop
      as={Link}
      to={game.to}
      className={'dgli' + (isCanlan ? ' is-canlan' : '')}
    >
      <Column columns={[8, 8, 10, 12]} className='game-info'>
        <Team team={game.homeTeam} />
        <div className='center-section'>
          <div
            className={cn('score align-center align-left', {
              'is-winner': homeTeamWon,
            })}
          >
            <span style={{ position: 'relative' }}>
              {game.homeTeamScore}
              {homeTeamWon && <div className='indicator indicator-line' />}
            </span>
          </div>
          <div
            className={cn('align-center center-section-text', {
              completed: game.completed,
            })}
          >
            <div className='game-number'>Game #{game.gameNumber}</div>
            {game.completed ? (
              <>
                <div className='game-list-item-final-text'>
                  {t('util:capitalize', { text: t('common:final') })}
                </div>
                <div
                  style={{ color: 'white' }}
                  className='game-list-item-sub-text'
                >
                  {game.shootout ? 'SO' : currentPeriod.isOvertime && 'OT'}
                </div>
                <div
                  style={{ color: 'white', lineHeight: 1, fontSize: 14 }}
                  className='game-list-item-game-status-text'
                >
                  {game.gameStatus > 1 &&
                    getGameStatusLabel(game.gameStatus, gameStatusTypes)}
                </div>
              </>
            ) : game.started ? (
              <>
                {currentPeriod.clockTime
                  ? currentPeriod.fullName
                  : currentPeriod.name}{' '}
                <p className='game-list-item-time'>
                  {currentPeriod.clockTime
                    ? currentPeriod.clockTime
                    : t('util:capitalize', {
                        text: t('GameScreen:intermission'),
                      })}
                </p>
              </>
            ) : (
              <>
                <Flex
                  column
                  alignItems='center'
                  style={{
                    position: 'relative',
                    minHeight: 18,
                    paddingTop: 1,
                  }}
                >
                  <div className='game-list-item-date align-center'>
                    {localDate.format('MMM D - h:mm a z')}
                  </div>
                </Flex>
                {displayGameTime && (
                  <Flex
                    column
                    alignItems='center'
                    style={{
                      position: 'relative',
                      minHeight: 14,
                      paddingTop: 1,
                    }}
                  >
                    <div className='game-list-local-date'>
                      (
                      {date.format(
                        localDate.day() !== date.day()
                          ? 'MMM D - h:mm a z'
                          : 'h:mm a z'
                      )}
                      )
                    </div>
                  </Flex>
                )}
              </>
            )}
            <div className='game-type'>{fullGameType?.name}</div>
          </div>
          <div
            className={cn('score align-right', {
              'is-winner': visitingTeamWon,
            })}
          >
            <span style={{ position: 'relative' }}>
              {game.visitingTeamScore}
              {visitingTeamWon && <div className='indicator indicator-line' />}
            </span>
          </div>
        </div>
        <Team isAway isRight team={game.visitingTeam} />
      </Column>
      <Column columns={2}>
        <div className='align-center align-right game-details'>
          {game.parentSchedule && <p>{game.parentSchedule}</p>}
          <p>{game.location && game.location.fullName}</p>
          <p>{game.facility}</p>
        </div>
      </Column>
    </Container>
  )
}

DesktopGameListItem.propTypes = {
  game: PropTypes.object.isRequired,
  gameStatusTypes: PropTypes.array,
}

export default DesktopGameListItem
