import req from '../api/request'

/**
 * Retrieves a tutorial based on the tutorial key.
 *
 * @param {string} tutorialKey - The key of the tutorial to retrieve.
 * @return {Promise<Object>} - The result of the tutorial retrieval.
 */
export const getTutorial = async (tutorialKey) => {
  try {
    if (!tutorialKey) {
      return null
    }
    const result = await req(`/tutorial/${tutorialKey}`)
    return result
  } catch (e) {
    console.error(e)
    if (e?.status === 404) {
      return null
    }
    throw e
  }
}

/**
 * Updates a tutorial in the database.
 *
 * @param {string} tutorialKey - The key for the tutorial in the database.
 * @param {Object} value - The value to set.
 * @returns {Promise<void>} A promise that resolves when the tutorial has been updated.
 */
export const setTutorial = async (tutorialKey, value) => {
  try {
    if (!tutorialKey) {
      return null
    }
    const params = {
      name: tutorialKey,
      value: String(value),
      description: String(value),
    }
    await req('/tutorial', { method: 'PUT', body: JSON.stringify(params) })
  } catch (e) {
    console.error(e)
    throw e
  }
}
