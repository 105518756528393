import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import colors from '@sportninja/common/constants/appColors'

import { media, COL_WIDTH, COL_GUTTER } from '../Responsive'
import { font, loadingSpinner, border } from '../css'
import { Flex } from '../Layout'

export const FollowButton = styled.button`
  align-self: center;
  height: 24px;
  padding: 0 22px;
  border: 1px solid ${border};
  border-radius: 2px;

  :not([disabled]):hover {
    border: 1px solid ${colors.WHITE};
  }

  color: ${colors.WHITE};
  ${font.body}
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 2.24px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;

  ${(props) => props.busy && loadingSpinner}

  ${media.mobile} {
    padding: 0 10px;
  }
`

export const DoubleButtons = styled(Flex)`
  width: 156px;
  button {
    flex: 1 0 50%;
    padding: 0;

    &:first-of-type {
      /* Listen, I've tried to do this in a more elegant way, (i.e. removing
       * border-radius on each button individually, etc.) but this works just
       * as well.
       */
      margin-right: -1px;
    }
  }
`

export const sectionAndSubTitleText = css`
  color: ${colors.LIGHT_GRAY};
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1.64px;
  text-transform: uppercase;
`

export const SectionTitle = styled.div`
  ${sectionAndSubTitleText}
  position: absolute;
  top: -32px;
`

export const backgroundCss = css`
  width: ${COL_WIDTH * 2 + COL_GUTTER}px;
  overflow: hidden;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.05);
  ${font.title}
  font-size: 200px;
  font-weight: bold;
  letter-spacing: 5.33px;
  line-height: 22px;
  text-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.22);
  pointer-events: none;
`
