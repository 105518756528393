/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useContext, useEffect, useRef, useState } from 'react'
import req from '@sportninja/common/api/request'
import debounce from 'lodash.debounce'

import { Flex } from '../../components/Layout'
import { media } from '../../components/Responsive'
import LoadingSpinner from '../../components/LoadingSpinner'
import { Pager } from '../../components/Pagination'
import { SearchContext } from './SearchContextProvider'
import SearchTop from './SearchTop'
import SearchSection from './SearchSection'
import SearchTopResult from './SearchTopResult'
import { OrgRow, PlayerRow, SchedRow, TeamRow, VenueRow } from './SearchRow'
import SearchContainer from './SearchContainer'
import {
  clearRecentSearches,
  getRecentSearches,
  initialRecentsState,
  storeRecentSearchItem,
  storeRecentSearchTerm,
} from './recent-search'
import SearchRecentPanel from './SearchRecentPanel'
import { isCanlan } from '@sportninja/common/utils/customer-name'

const searchRequest = debounce(
  async (text, page, filter, abortSignal, callback, error) => {
    const query = { auto: filter ? 0 : 1, query: text, page }
    if (filter.length > 0) {
      query.filter = filter
    }
    try {
      const response = await req('/search/omni', {
        query,
        signal: abortSignal,
      })
      callback(response)
    } catch (e) {
      // Specifically ignore abort-related errors, to avoid showing that type
      // of error to the user.
      if (abortSignal.aborted) {
        return
      }
      error(e.message)
    }
  },
  400
)

const Search = ({ userId }) => {
  const searchContext = useContext(SearchContext)

  const [isTeamOrSchedulePage, setIsTeamOrSchedulePage] = useState(
    /team\/|schedule\//.test(location?.pathname)
  )

  const [displayContents, setDisplayContents] = useState(false)
  const [loadingResults, setLoadingResults] = useState(false)
  const [results, setResults] = useState({})
  const [numResults, setNumResults] = useState(false)
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState({})
  const [error, setError] = useState('')
  const [topResult, setTopResult] = useState({})
  const [totalResults, setTotalResults] = useState({})
  const [isSeeAllActive, setIsSeeAllActive] = useState(
    searchContext.filter.length === 0
  )
  const [recentSearches, setRecentSearches] = useState(initialRecentsState)

  const abortControllers = useRef([])

  useEffect(() => {
    if (userId) {
      const store = getRecentSearches()
      if (store && store?.uid === userId) {
        setRecentSearches(store)
      } else {
        clearRecentSearches(userId)
      }
    }
  }, [userId])

  useEffect(() => {
    const newValue = /team\/|schedule\//.test(location?.pathname)
    setIsTeamOrSchedulePage(newValue)
  }, [location?.pathname])

  useEffect(() => {
    if (searchContext.isActive) {
      if (
        recentSearches?.terms?.length > 0 ||
        recentSearches?.items?.length > 0 ||
        searchContext.searchText.length > 0
      ) {
        setDisplayContents(true)

        if (searchContext.searchText.length > 0) {
          // If the search term changes, we should reset the pagination
          setPage(1)
        }
      } else {
        // If the user removes all their prev. searches and clicked items, then
        // go ahead and hide the search window
        searchContext.setIsActive(false)
      }
    } else {
      setDisplayContents(false)
    }
  }, [
    searchContext.isActive,
    recentSearches?.terms?.length,
    recentSearches?.items?.length,
    searchContext.searchText.length,
  ])

  useEffect(() => {
    // If the search filter changes, we should reset the pagination
    setLoadingResults(true)
    setPage(1)
    setIsSeeAllActive(searchContext.filter.length === 0)
  }, [searchContext.filter])

  useEffect(() => {
    if (searchContext.searchText.length > 0) {
      setLoadingResults(true)
      setNumResults(false)
      setTopResult({})
      setTotalResults({})

      // We store an AbortController for each request, so upon a new search term,
      // signal an abort for any stale requests in the list
      abortControllers.current.forEach((controller) => {
        controller.abort()
      })
      abortControllers.current = []
      const abortController = new AbortController()
      abortControllers.current.push(abortController)

      searchRequest(
        searchContext.searchText,
        page,
        searchContext.filter,
        abortController.signal,
        ({ data, meta }) => {
          const players = [],
            teams = [],
            scheds = [],
            orgs = [],
            venues = []

          data.forEach((d) => {
            if (d.type === 'player') {
              players.push(d)
            } else if (d.type === 'team') {
              teams.push(d)
            } else if (d.type === 'schedule') {
              scheds.push(d)
            } else if (d.type === 'organization') {
              orgs.push(d)
            } else if (d.type === 'venue') {
              venues.push(d)
            }
          })

          if (data.length > 0) {
            setTopResult(data[0])
            const store = storeRecentSearchTerm(searchContext.searchText)
            setRecentSearches(store)
          }
          setPagination(meta)
          setNumResults(meta.total)
          setResults({ players, teams, scheds, orgs, venues })
          setTotalResults(meta.count || {})
          setLoadingResults(false)
        },
        (errorMessage) => {
          setError(errorMessage)
        }
      )
    } else {
      // Reset everything, otherwise we get flashes of stale data when search text
      // changes from 0 to > 0
      setLoadingResults(false)
      setNumResults(false)
      setTopResult({})
      setTotalResults({})
      setPagination({})
      setResults({})
    }
  }, [searchContext.searchText, searchContext.filter, page])

  const onClose = () => {
    searchContext.setIsActive(false)
    setDisplayContents(false)
  }

  const onResultClick = (item) => {
    onClose()
    const store = storeRecentSearchItem(item)
    setRecentSearches(store)
  }

  const orgCount = results?.orgs?.length || 0
  const teamCount = results?.teams?.length || 0
  const schedCount = results?.scheds?.length || 0
  const playerCount = results?.players?.length || 0
  const venuesCount = results?.venues?.length || 0

  const handleSeeAllButton = (type, shouldSetFilter) => {
    searchContext.setFilter(shouldSetFilter ? type : '')
  }

  const players = playerCount > 0 && (
    <SearchSection
      key='players'
      results={results.players}
      onResultClick={onResultClick}
      onSeeAll={handleSeeAllButton.bind(this, 'player')}
      isSeeAllActive={isSeeAllActive}
      RowComponent={PlayerRow}
      titlei18nKey='player'
      totalResults={totalResults.player}
    />
  )

  const schedules = schedCount > 0 && (
    <SearchSection
      key='schedules'
      results={results.scheds}
      onResultClick={onResultClick}
      onSeeAll={handleSeeAllButton.bind(this, 'schedule')}
      isSeeAllActive={isSeeAllActive}
      RowComponent={SchedRow}
      titlei18nKey='competition'
      isFullWidth
      totalResults={totalResults.schedule}
    />
  )

  const orgs = orgCount > 0 && (
    <SearchSection
      key='orgs'
      results={results.orgs}
      onResultClick={onResultClick}
      onSeeAll={handleSeeAllButton.bind(this, 'organization')}
      isSeeAllActive={isSeeAllActive}
      RowComponent={OrgRow}
      titlei18nKey='organization'
      totalResults={totalResults.organization}
    />
  )

  const teams = teamCount > 0 && (
    <SearchSection
      key='teams'
      results={results.teams}
      onResultClick={onResultClick}
      onSeeAll={handleSeeAllButton.bind(this, 'team')}
      isSeeAllActive={isSeeAllActive}
      RowComponent={TeamRow}
      titlei18nKey='team'
      totalResults={totalResults.team}
    />
  )

  const venues = venuesCount > 0 && (
    <SearchSection
      key='venues'
      results={results.venues}
      onResultClick={onResultClick}
      onSeeAll={handleSeeAllButton.bind(this, 'venue')}
      isSeeAllActive={isSeeAllActive}
      RowComponent={VenueRow}
      titlei18nKey={isCanlan ? 'Sports Complexes' : 'locations'}
      totalResults={totalResults.venue}
    />
  )

  let firstResult = false
  const theList = [players, teams, orgs, venues]
  let theRemainder = []

  for (let i = 0; i < theList.length; i++) {
    if (theList[i]) {
      firstResult = theList[i]
      theRemainder = theList.slice(i + 1)
      break
    }
  }

  return (
    <SearchContainer
      displayContents={displayContents}
      isTeamOrSchedulePage={isTeamOrSchedulePage}
    >
      {searchContext.searchText.length === 0 ? (
        <SearchRecentPanel
          recentSearches={recentSearches}
          setRecentSearches={setRecentSearches}
          onClose={onClose}
        />
      ) : (
        <div
          css={css`
            width: 100%;
            max-width: 1280px;
            height: 100%;
          `}
        >
          <SearchTop
            numResults={numResults}
            onClose={onClose}
            searchText={searchContext.searchText}
            isFilterActive={!isSeeAllActive}
            onResetFilter={() => {
              searchContext.setFilter('')
            }}
          />
          {error ? (
            <div
              css={css`
                color: red;
                word-break: break-word;
                max-width: 800px;
                margin: 64px auto 0;
                text-align: center;
              `}
            >
              {error}
            </div>
          ) : loadingResults ? (
            <LoadingSpinner
              css={css`
                margin-top: 64px;
              `}
            />
          ) : (
            <div
              css={css`
                height: calc(100% - 80px);
                overflow: auto;
              `}
            >
              <Flex
                noFlex
                id='search-item-list'
                css={css`
                  flex-wrap: wrap;
                  margin-top: -25px;
                  margin-left: -40px;
                  padding-right: 16px;
                  padding-bottom: 50px;

                  ${media.mobile} {
                    flex-direction: column;
                    flex-wrap: nowrap;
                  }
                `}
              >
                {isSeeAllActive ? (
                  <>
                    {Object.keys(topResult).length > 0 && (
                      <SearchTopResult
                        item={topResult}
                        onClick={() => {
                          if (
                            topResult.type !== 'venue' &&
                            topResult.type !== 'team_official'
                          ) {
                            onResultClick()
                          }
                        }}
                      />
                    )}
                    {firstResult}
                    {schedules}
                    {theRemainder}
                  </>
                ) : (
                  [...theList, schedules]
                )}
              </Flex>
            </div>
          )}

          {pagination && !isSeeAllActive && (
            <Pager
              onClick={(p) => setPage(p)}
              page={pagination.current_page}
              total={pagination.last_page}
            />
          )}
        </div>
      )}
    </SearchContainer>
  )
}

export default Search
