import { createAction } from 'redux-actions'

export const noop = () => undefined
const metaCreator = (_, resolve = noop, reject = noop) => ({ resolve, reject })
const metaCreator2 = (resolve = noop, reject = noop) => ({ resolve, reject })
const metaCreatorWithOfflineQueue = (resolve = noop, reject = noop) => ({ resolve, reject, retry: true })
const STATE = [ 'FAILURE', 'REQUEST', 'SUCCESS' ]

// a promise action creator; each action contains a meta object with resolve and
// reject methods which will resolve or reject a promise that can be awaited
export const promiseAction = (type) => createAction(type, null, metaCreator)

export const getPayloadReducer = (argNames) => {
  let payloadReducer

  if (argNames.length) {
    // Ignore the resolve, and reject arguments (they appear first and second)
    payloadReducer = (_, __, ...args) => {
      const payload = {}
      argNames.forEach((arg, index) => payload[arg] = args[index])
      return payload
    }
  }

  return payloadReducer
}

export const promiseActionWithArgs = (type, ...argNames) => {
  const payloadReducer = getPayloadReducer(argNames)
  return createAction(type, payloadReducer, metaCreator2)
}

export const promiseActionWithArgsAndOffline = (type, ...argNames) => {
  const payloadReducer = getPayloadReducer(argNames)
  return createAction(type, payloadReducer, metaCreatorWithOfflineQueue)
}

export const createActionWithArgs = (type, ...argNames) => {
  const payloadReducer = getPayloadReducer(argNames)
  return createAction(type, payloadReducer)
}

export const generateActionString = (prefixes, verb) => {
  return STATE
    .map((state) => ({
      [state]: `${prefixes.join('.').toUpperCase()}.${verb.toUpperCase()}.${state}`
    }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {})
}

/**
 * Generates a set of { failure, request, success } Redux actions from a definitions object
 * @param {array} prefixes - Generally the logical grouping or Redux state slice name, e.g. 'ORG', 'TEAM'
 * @param {object} definition - A definition object; see ./org.js for an example
 */
export const generateActions = (prefixes, definition, useOfflineQueue = false) => {
  if (!prefixes || typeof prefixes === 'undefined' || prefixes.length === 0 || typeof prefixes[0] !== 'string') {
    const message = 'You must provide an array of prefix string(s) as the first argument!'
    throw new TypeError(message)
  }
  const actionObject = { ...definition }

  for (const actionName in actionObject) {
    // If it's an object, call this method again on that object
    if (typeof actionObject[actionName].length === 'undefined') {
      actionObject[actionName] = generateActions([ ...prefixes, actionName ], actionObject[actionName], useOfflineQueue)
    } else {
      // Otherwise, create FAILURE, REQUEST, and SUCCESS action creators for each action name
      const actionStrings = generateActionString(prefixes, actionName)

      actionObject[actionName] = {
        failure: createActionWithArgs(actionStrings.FAILURE, 'error'),
        request: (useOfflineQueue ? promiseActionWithArgsAndOffline : promiseActionWithArgs)(actionStrings.REQUEST, ...actionObject[actionName]),
        success: createAction(actionStrings.SUCCESS)
      }
    }
  }

  return actionObject
}

// Return as the prop inside an object that maps dispatch to props
export const bindActionToPromise = (dispatch, actionCreator) => (...payload) => {
  return new Promise((resolve, reject) => {
    return dispatch(actionCreator(resolve, reject, ...payload))
  })
}
