import merge from 'lodash.merge'

import actions from '../actions/search'
import { arrayToObject } from '../utils/utils'
import { str } from './utils'
import parsers from './parsers'

const searchReducer = (entityType) => (draft, { type, payload }) => {
  switch (type) { // eslint-disable-line default-case
    case str(actions.search.success):
      if (entityType === payload.entityType) {
        return merge(draft, arrayToObject(payload.data, parsers[entityType]))
      } else return
  }
}

export default searchReducer
