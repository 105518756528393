import React, { useState, ChangeEvent, useMemo } from 'react'
import { Input } from 'antd'

interface PhoneInputProps {
  value?: string
  onChange?: (value: string) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  placeholder?: string
  disabled?: boolean
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  value = '',
  onChange,
  onBlur,
  disabled,
  placeholder = 'Enter phone number',
}) => {
  const initialValue = useMemo(() => {
    // Remove non-numeric characters from the input
    const numericValue = value?.replace(/\D/g, '') || ''

    // Format the numeric value with the desired mask
    let formattedValue = ''
    if (numericValue.length <= 10) {
      // For US and Canada numbers without the country code
      formattedValue =
        numericValue?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') || ''
    } else {
      // Handle numbers with country code, adjust the logic as needed
      // Example: +1 (123) 456-7890
      formattedValue =
        numericValue?.replace(
          /(\d{1})(\d{3})(\d{3})(\d{4})/,
          '+$1 ($2) $3-$4'
        ) || ''
    }

    return formattedValue
  }, [value])

  const [phoneNumber, setPhoneNumber] = useState(initialValue)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value || ''

    // Remove non-numeric characters from the input
    const numericValue = inputValue?.replace(/\D/g, '') || ''

    // Format the numeric value with the desired mask
    let formattedValue = ''
    if (numericValue.length <= 10) {
      // For US and Canada numbers without the country code
      formattedValue =
        numericValue?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') || ''
    } else {
      // Handle numbers with country code, adjust the logic as needed
      // Example: +1 (123) 456-7890
      formattedValue =
        numericValue?.replace(
          /(\d{1})(\d{3})(\d{3})(\d{4})/,
          '+$1 ($2) $3-$4'
        ) || ''
    }

    setPhoneNumber(formattedValue)

    if (onChange) {
      // Pass the raw numeric value to the parent component
      onChange(numericValue || '')
    }
  }

  return (
    <Input
      maxLength={16}
      type='text'
      disabled={disabled}
      value={phoneNumber || ''}
      onChange={handleInputChange}
      placeholder={placeholder}
      onBlur={onBlur}
    />
  )
}

export default PhoneInput
