import { createSelector } from 'reselect'

import { getGames, getTeams, getSchedules, getAuth } from './base'
import { createChildListSelector } from './utils'

export const getTeamById = (state, id) => state.team[id] || {}
export const makeGetTeam = () => createSelector(getTeamById, (team) => team)

export const makeGetRelatedTeams = () =>
  createChildListSelector(getTeams, getAuth, 'relatedTeams')

export const makeGetTeamGames = () => {
  return createSelector(
    [getGames, getTeams, getSchedules, getTeamById],
    (games, teams, schedules, team) => {
      if (!Array.isArray(team.games)) return false
      return team.games.reduce((collector, gameId) => {
        const game = games[gameId]
        if (!game) return collector

        collector.push({
          ...game,
          schedule: schedules[game.scheduleId],
          homeTeam: teams[game.homeTeamId],
          visitingTeam: teams[game.visitingTeamId],
        })

        return collector
      }, [])
    }
  )
}

export const makeGetTeamSchedules = () =>
  createChildListSelector(getSchedules, getTeamById, 'schedules')

export const makeGetTeamOfficials = () => {
  return createSelector([getTeamById], (team) => {
    return team.officials
  })
}
