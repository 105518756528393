/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import type { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import { Button as AntButton, ButtonProps } from 'antd'
import LoadingSpinner from '../LoadingSpinner'
import type { SerializedStyles } from '@emotion/react'
import colors from '@sportninja/common/constants/appColors'

interface Props {
  variant?: 'primary' | 'secondary' | 'tertiary'
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  label?: string
  icon?: React.ReactNode | JSX.Element | EmotionJSX.Element
  disabled?: boolean
  isLoading?: boolean
  small?: boolean
  labelColor?: string
  useAltFontFamily?: boolean
  id?: string
  useReturnKey?: boolean
  type?: 'button' | 'submit' | 'reset'
  tabIndex?: number
  labelStyle?: string
  antVariant?: ButtonProps['type']
  customCss?: SerializedStyles
}

export const Button = ({
  variant = 'primary',
  onClick,
  label,
  icon,
  disabled,
  isLoading,
  small,
  labelColor,
  useAltFontFamily,
  id,
  type,
  tabIndex,
  labelStyle,
  antVariant,
  customCss,
}: Props) => {
  return (
    <AntButton
      tabIndex={tabIndex}
      htmlType={type}
      id={id}
      disabled={disabled}
      onClick={onClick}
      icon={icon}
      type={
        antVariant
          ? antVariant
          : variant === 'primary'
          ? 'primary'
          : variant === 'secondary'
          ? 'default'
          : 'dashed'
      }
      css={css`
        width: 100%;
        padding: ${small ? '6px' : '8px'};
        gap: ${small ? '6px' : '8px'};
        align-items: center;
        display: flex;
        justify-content: space-around;

        ${variant === 'tertiary' &&
        `
          background-color: ${colors.HEADER};
          border-color: ${colors.SOFT_STEEL};

          &:hover {
            background-color: ${colors.HEADER_BG_2} !important;
            border-color: ${colors.SOFT_STEEL};
          }
        `}
        ${customCss};
      `}
    >
      {isLoading ? (
        <LoadingSpinner size={2} />
      ) : (
        label && (
          <p
            css={css`
           color: ${labelColor || 'white'};
           font-family: ${
             useAltFontFamily
               ? "'Maison Neue', sans-serif;"
               : "'Rift', sans-serif;"
           }
           font-size: ${small ? '14px' : '18px'};
           font-weight: 500;
           ${labelStyle};
         `}
          >
            {label}
          </p>
        )
      )}
    </AntButton>
  )
}
