/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import { t } from '@sportninja/common/i18n'

import Picture from '../../components/Picture'
import { SectionTitle } from '../../components/Card/css'
import Periods from '@sportninja/common/constants/periodTypes'

const Cell = styled.div`
  width: 30px;
  color: #909090;
  font-size: 12px;
  letter-spacing: 0.61px;
  text-align: center;
  margin: 0 7px 8px;
`

const ScoreCell = styled(Cell)`
  margin-bottom: 0;
  height: 44px;
  font-size: 18px;
  line-height: 44px;
  letter-spacing: 0.91px;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const TeamRow = ({ game, team, predicate, remainingPeriods }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        margin-top: 6px;
        margin-right: auto;
      `}
    >
      <div
        css={css`
          padding-left: 4px;
          width: 68px;
        `}
      >
        <Picture
          square
          fit
          size='xsmall'
          imageId={team.imageId}
          iconName='user-friends'
        />
      </div>
      {game.periods.map((period) => {
        const score = predicate
          ? period.goals_home_count
          : period.goals_visiting_count
        return <ScoreCell key={period.id}>{score}</ScoreCell>
      })}
      {game.periods.length < 3 &&
        remainingPeriods.slice(game.periods.length - 1).map((period) => {
          return <ScoreCell key={period.id}>-</ScoreCell>
        })}
      <ScoreCell
        className='final-score-cell'
        css={css`
          color: white;
          margin-left: 24px;
        `}
      >
        {predicate ? game.homeTeamScore : game.visitingTeamScore}
      </ScoreCell>
    </div>
  )
}

const GameDetailsScoreBox = ({
  game,
  isMobile,
  isSoccer = false,
  isGenericGame = false,
}) => {
  if (!game.started) {
    return false
  }

  const remainingPeriods = isSoccer
    ? [
        { id: '2', name: '2nd' },
        { id: '3', name: 'ET1' },
        { id: '4', name: 'ET2' },
      ]
    : isGenericGame
    ? []
    : [
        { id: '2', name: '2' },
        { id: '3', name: '3' },
      ]

  const isHomeWinning = game.homeTeamScore > game.visitingTeamScore
  const teamOne = isHomeWinning ? game.homeTeam : game.visitingTeam
  const teamTwo = isHomeWinning ? game.visitingTeam : game.homeTeam

  return (
    <div
      className='game-details-score-box'
      css={css`
        ${isMobile &&
        `
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;
      `}
      `}
    >
      <SectionTitle
        css={css`
          position: unset;
          margin-bottom: 20px;
          ${isMobile && `padding: 0 24px;`}
        `}
      >
        {isSoccer ? 'Goals by Half' : t('GameScreen:goalsByPeriod')}
      </SectionTitle>

      <div
        css={css`
          margin: 0 auto;
          position: relative;
        `}
      >
        <div
          css={css`
            display: flex;
            padding-left: 68px;
            flex-direction: row;
          `}
        >
          {game.periods.map((period, idx) => {
            if (isSoccer) {
              return (
                <Cell key={period.id}>{Periods[period?.period_type?.id]}</Cell>
              )
            }
            const name = idx > 2 ? 'OT' : idx + 1
            return <Cell key={period.id}>{name}</Cell>
          })}
          {game.periods.length < 3 &&
            remainingPeriods.slice(game.periods.length - 1).map((period) => {
              return <Cell key={period.id}>{period.name}</Cell>
            })}
          <Cell
            className='final-text'
            css={css`
              width: 64px;
              margin-right: 0;
              color: white;
              text-transform: uppercase;
            `}
          >
            {t('common:final')}
          </Cell>
          {/* A fake border */}
          <div
            css={css`
              position: absolute;
              top: 28px;
              left: 0;
              right: 12px;
              height: 1px;
              background-color: #909090;
            `}
          />
        </div>

        <TeamRow
          game={game}
          team={teamOne}
          predicate={isHomeWinning}
          remainingPeriods={remainingPeriods}
        />
        <TeamRow
          game={game}
          team={teamTwo}
          predicate={!isHomeWinning}
          remainingPeriods={remainingPeriods}
        />
      </div>
    </div>
  )
}

export default GameDetailsScoreBox
