import type { Reducer } from 'react'

export type State = {
  user: {
    firstName: string
    lastName: string
    email: string
    dateOfBirth: string
    password: string
  }
  contactInfo: {
    fullName: string
    title: string
    email: string
    phone: string
    companyName: string
  }
  address: {
    street: string
    country: string
    state: string
    city: string
    zipCode: string
  }
}

type ActionTypes = 'setUser' | 'setContactInfo' | 'setAddress'

type Action = {
  type: ActionTypes
  payload: any
}

export interface FormDispatcher {
  formDispatcher: React.Dispatch<Action>
}

export const initialState: State = {
  user: {
    firstName: '',
    lastName: '',
    email: '',
    dateOfBirth: '',
    password: '',
  },
  contactInfo: {
    fullName: '',
    title: '',
    email: '',
    phone: '',
    companyName: '',
  },
  address: {
    street: '',
    country: '',
    state: '',
    city: '',
    zipCode: '',
  },
}

export const formController: Reducer<State, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'setUser':
      return { ...state, user: action.payload }
    case 'setContactInfo':
      return { ...state, contactInfo: action.payload }
    case 'setAddress':
      return { ...state, address: action.payload }
    default:
      return state
  }
}
