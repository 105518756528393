import styled from '@emotion/styled/macro'
import { media } from '../../components/Responsive'
import { font } from '../../components/css'

export const H1 = styled.h1`
  ${font.title}
  font-size: 80px;
  font-weight: bold;
  letter-spacing: 1.16px;
  line-height: 1;
  margin-bottom: 60px;

  ${media.mobile} {
    font-size: 44px;
    letter-spacing: 0.64px;
    margin-bottom: 42px;
  }
`

export const ForgotText = styled.div`
  display: block;
  ${font.body}
  font-size: 12px;

  margin-bottom: 36px;
`

export const ForgotTextLightMode = styled.div`
  display: block;
  ${font.body}
  font-size: 12px;
  color: #000;
  margin-bottom: 36px;
`

export const BodyText = styled.div`
  letter-spacing: 1.24px;
  line-height: 1.3;

  p,
  .body-text-step {
    margin-bottom: 16px;
  }

  .small {
    font-size: 12px;
  }

  .double-margin {
    margin-top: 32px;
  }

  .bold {
    font-weight: bold;
  }

  .error {
    color: red;
  }
`
