import storage from '../utils/storage'
import { getIframeToken } from '../utils/iframe-tokens-utils'

export const SESSION_TOKEN = 'session_token'
export const SESSION_TOKEN_IFRAME = 'session_token_iframe'
export const ACCOUNT_VERIFIED = 'account_verified'
export const LOGOUT_SIGNAL = 'sn-logout-event'

export const SN_STORAGE_EVENT_LISTENER = 'snStorageEventListener'

// Temporary solution for user data storage
export const LOGGED_IN_USER = 'logged_in_user'

export const getSessionToken = async () => await storage.getItem(SESSION_TOKEN)
export const setSessionToken = async (token) =>
  await storage.setItem(SESSION_TOKEN, token)

export const getIframeSessionToken = async () => {
  const token = await storage.getItem(SESSION_TOKEN_IFRAME)
  if (token && token !== '') {
    return token
  } else {
    return getIframeToken()
  }
}
export const setIframeSessionToken = async (token) => {
  return await storage.setItem(SESSION_TOKEN_IFRAME, token)
}

export const clearSessionData = async () => {
  await storage.removeItem(SESSION_TOKEN)
  await storage.removeItem(ACCOUNT_VERIFIED)
  await storage.removeItem(LOGGED_IN_USER)
}

export const setAccountVerified = async (isVerified) =>
  await storage.setItem(ACCOUNT_VERIFIED, isVerified)

export const sendLogoutSignal = async () => {
  window &&
    window.removeEventListener &&
    window.removeEventListener('storage', window[SN_STORAGE_EVENT_LISTENER])
  await storage.setItem(LOGOUT_SIGNAL, `logout:${Math.random()}`)
}

export const removeLogoutSignal = async () => {
  await storage.removeItem(LOGOUT_SIGNAL)
}
