/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { useEffect, useState } from 'react'

import { font } from '../../../components/css'
import Icon from '../../../components/Icon'
import { Flex } from '../../../components/Layout'

const PlayerNumber = ({
  player,
  onPlayerChange,
  isEditing,
  setIsEditing,
  // typeof existingPlayerNums === number
  existingPlayerNums,
}) => {
  const [editMode, setIsEditMode] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  // Ensure we're dealing with numbers
  const existingPlayerNum =
    typeof player.player_number === 'string'
      ? parseInt(player.player_number, 10)
      : player.player_number
  const [value, setValue] = useState(existingPlayerNum)

  const onSubmit = async (e) => {
    e.preventDefault()
    setIsSaving(true)
    try {
      await onPlayerChange(player.id, { player_number: value })
      setIsEditMode(false)
      setIsEditing(false)
    } finally {
      setIsSaving(false)
    }
  }

  useEffect(() => {
    if (!isEditing) {
      setIsEditMode(false)
      setValue(existingPlayerNum)
    }
  }, [isEditing])

  // Don't allow the setting of an existing player number
  const convertToNumber =
    typeof value === 'string' ? parseInt(value, 10) : value
  const isNumberInUse =
    (player.is_starting || player.is_playing) &&
    existingPlayerNums
      .filter((n) => n !== player.player_number)
      .includes(convertToNumber)

  return editMode ? (
    <form
      css={css`
        position: relative;
        height: inherit;
        z-index: ${editMode ? 2 : -1};
        pointer-events: all;
      `}
      action='/'
      method='GET'
      onSubmit={onSubmit}
    >
      <input
        autoFocus
        type='number'
        min={0}
        max={999}
        step={1}
        css={css`
          ${font.body}
          width: 100%;
          padding: 0 0 0 10px;
          height: inherit;
          font-size: 14px;
          color: white;
          height: calc(100% + 2px);
          margin: -1px;
          border: 1px solid ${isNumberInUse ? colors.FIERY_RED : 'transparent'};
          background-color: rgba(255, 255, 255, 0.1);

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          -moz-appearance: textfield;
        `}
        value={value}
        onChange={({ target }) => {
          const num = parseInt(target.value, 10)
          if (num > 999) {
            return
          } else if (num < 0) {
            setValue('0')
          }
          setValue(target.value)
        }}
      />

      {!isSaving && (
        <button
          type='button'
          css={css`
            position: absolute;
            height: 50px;
            left: -60px;
            background-color: gray;
            color: black;
            ${font.title}
            font-weight: bold;
            letter-spacing: 1.2px;
            font-size: 15px;
            width: 60px;
            margin-right: 4px;
          `}
          onClick={() => {
            setIsEditMode(false)
            setIsEditing(false)
          }}
        >
          Cancel
        </button>
      )}

      <button
        type='submit'
        disabled={
          isSaving ||
          isNumberInUse ||
          isNaN(convertToNumber) ||
          convertToNumber === existingPlayerNum
        }
        css={css`
          position: absolute;
          height: 50px;
          right: -${isNumberInUse ? 120 : 60}px;
          background-color: ${isNumberInUse
            ? colors.FIERY_RED
            : colors.PRIMARY};
          color: white;
          ${font.title}
          font-weight: bold;
          letter-spacing: 1.2px;
          font-size: 15px;
          width: ${isNumberInUse ? 120 : 60}px;
          margin-left: 4px;

          &[disabled] {
            cursor: not-allowed;
            filter: brightness(0.8);

            span {
              opacity: 0.7;
            }
          }
        `}
      >
        <span>
          {isNumberInUse ? 'Number is in use' : isSaving ? 'Saving...' : 'Save'}
        </span>
      </button>
    </form>
  ) : (
    <Flex
      alignItems='center'
      justifyContent='space-between'
      css={css`
        height: inherit;
        padding: 0 10px;
        &:hover {
          cursor: pointer;
          background-color: rgba(255, 255, 255, 0.1);

          i {
            color: white;
          }
        }
      `}
      onClick={() => {
        setIsEditing(true)
        setIsEditMode(true)
      }}
    >
      {player.player_number}
      <Icon
        color={colors.DEFAULT_FLAIR}
        name='pencil-alt'
        css={css`
          margin-left: 4px;
          opacity: ${isEditing ? 0 : 1};
        `}
      />
    </Flex>
  )
}

export default PlayerNumber
