import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import typeActions from '@sportninja/common/actions/types'
import { getTimezones } from '@sportninja/common/selectors/types'
import { t } from '@sportninja/common/i18n'

import { SelectFormInput } from '../Select'

const TimezoneSelect = ({
  timezones,
  disabled,
  defaultValue,
  noFlex,
  onChange,
  readTimezones,
  values,
}) => {
  const [selectedTimezone, setSelectedTimezone] = useState(defaultValue)

  useEffect(() => {
    if (values?.country_id) {
      readTimezones(values?.country_id).then((timezones) => {
        if (timezones?.length === 1) {
          onTimezoneChange({
            target: { name: 'timezone', value: timezones[0] },
          })
        }
      })
    }
  }, [values?.country_id])

  const onTimezoneChange = ({ target }) => {
    onChange({ target })
    setSelectedTimezone(target.value)
  }

  const timezonesForCountry = timezones?.[values?.country_id] || []

  return (
    <SelectFormInput
      autoComplete='timezone'
      defaultValue={selectedTimezone}
      disabled={timezonesForCountry.length === 0 || disabled}
      label={t('common:timezone')}
      name='timezone'
      noFlex={noFlex}
      onChange={onTimezoneChange}
      required
    >
      <option key='empty' value=''>
        {timezonesForCountry.length === 0 ? 'n/a' : ''}
      </option>
      {timezonesForCountry.map((timezone) => {
        return (
          <option key={timezone} value={timezone}>
            {timezone}
          </option>
        )
      })}
    </SelectFormInput>
  )
}

TimezoneSelect.propTypes = {
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  noFlex: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  readTimezones: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    timezones: getTimezones(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    readTimezones: bindActionToPromise(dispatch, typeActions.timezones.request),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimezoneSelect)
