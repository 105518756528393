/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'

const ImportCSVErrorTable = ({ header, body, wrapCss }) => {
  return (
    <div
      css={css`
        border: 1px solid ${colors.SOFT_STEEL};
        border-radius: 4px;
        overflow: hidden;

        table {
          width: 100%;
        }

        .col-1 {
          vertical-align: middle;
          text-align: right;
          width: 68px;
          min-width: 68px;
        }

        th,
        td {
          padding: 6px;

          .message {
            margin: 2px 0;
          }
        }

        thead {
          font-size: 16px;
          font-weight: bold;
          color: white;
          height: 32px;

          th {
            vertical-align: middle;
          }

          .description {
            text-align: left;
          }
        }

        tbody {
          font-size: 16px;
          tr:nth-of-type(odd) {
            background-color: ${colors.HEADER};
          }

          td {
            padding: 8px;
          }
        }

        ${wrapCss}
      `}
    >
      <table>
        {header && <thead>{header}</thead>}
        <tbody>{body}</tbody>
      </table>
    </div>
  )
}

export default ImportCSVErrorTable
