/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import { Card, Switch, theme } from 'antd'

interface Props {
  isActive?: boolean
  label: string
  onClick: () => void
}

export const ButtonToggle = ({ isActive, label, onClick }: Props) => {
  const { token } = theme.useToken()

  return (
    <Card
      css={css`
        height: 40px;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: ${token.borderRadius}px;
        border: 1px solid ${isActive ? token.colorPrimary : token.colorBorder};
        transition: border-color 0.2s ease-in-out;

        &:hover {
          border-color: ${token.colorPrimaryHover};
        }

        .ant-card-body {
          padding: 0 16px;
        }
      `}
    >
      <div
        onClick={onClick}
        css={css`
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 8px;

          .ant-switch {
            background-color: ${isActive
              ? token.colorPrimary
              : 'rgba(0, 0, 0, 0.25) !important'};
          }
        `}
      >
        <p
          css={css`
            margin: 0 !important;
            color: white;
            font-size: 14px;
          `}
        >
          {label}
        </p>
        <Switch defaultChecked={isActive} size='small' checked={isActive} />
      </div>
    </Card>
  )
}
