import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'

import { Mobile } from '../Responsive'
import {
  CloseButton,
  Overlay,
  ScrollingContainer,
  SheetContainer,
  SheetTitle,
} from './css'
import useBodyScrollLock from './useBodyScrollLock'
import Icon from '../Icon'

const useOpenClose = (initialState) => {
  const [isDirty, setDirty] = useState(false)
  const [open, setOpen] = useState(initialState)
  const mounted = useRef(true)

  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  const toggleOpen = useCallback(
    (options = {}) => {
      if (options.forceClose) {
        mounted.current && setDirty(false)
        mounted.current && setOpen(false)
        return
      }

      if (isDirty) {
        if (
          window.confirm(
            'You have unsaved changes. Press "OK" to proceed, or cancel to ' +
              'continue editing.'
          )
        ) {
          setOpen(!open)
          setDirty(false)
        }
      } else setOpen(!open)
    },
    [open, isDirty]
  )

  const handler = useCallback(
    (event) => {
      if (!open || event.keyCode !== 27) return // Escape key
      event.preventDefault()
      toggleOpen()
    },
    [open, toggleOpen]
  )

  // Handle external control of open/close
  useEffect(() => {
    if (open !== initialState) toggleOpen()
  }, [initialState])

  useEffect(() => {
    if (open) document.addEventListener('keyup', handler)

    return () => {
      document.removeEventListener('keyup', handler)
    }
  }, [open, handler])

  return {
    open,
    toggleOpen,
    setDirty: (value) =>
      setDirty(typeof value !== 'undefined' ? value : !isDirty),
  }
}

const Sheet = ({
  blockBodyScroll = true,
  button,
  children,
  className,
  height,
  hideButton = false,
  hideClose = false,
  isOpen = false,
  noGradient = false,
  position = 'left',
  duration = 300,
  title,
  subTitle,
  width,
}) => {
  const { open, toggleOpen, setDirty } = useOpenClose(isOpen)

  let sheetRef
  if (blockBodyScroll) {
    // eslint-disable-next-line no-extra-semi
    ;[sheetRef] = useBodyScrollLock(open)
  }

  // Scroll to the last position when Sheet is closed - mostly useful for mobile
  const [scroll, setScroll] = useState(null)

  useEffect(() => {
    if (open) {
      const scrollOffset =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (scrollOffset > 0) setScroll(scrollOffset)
    } else if (!open && scroll !== null) {
      window.scrollTo(0, scroll)
    }
  }, [open, scroll])

  const sharedTransitionSettings = {
    in: open,
    timeout: duration,
    mountOnEnter: true,
    unmountOnExit: true,
  }

  const group = (
    <Mobile>
      {(isMobile) => (
        <>
          <CSSTransition classNames='overlay' {...sharedTransitionSettings}>
            {() => <Overlay duration={duration} onClick={toggleOpen} />}
          </CSSTransition>
          <CSSTransition classNames='sheet' {...sharedTransitionSettings}>
            {() => (
              <SheetContainer
                className={className}
                duration={duration}
                height={height}
                isMobile={isMobile}
                noGradient={noGradient}
                position={position}
                width={width}
              >
                {!hideClose && (
                  <CloseButton onClick={toggleOpen}>
                    <Icon name='times' color='white' fontSize={17} />
                  </CloseButton>
                )}
                <ScrollingContainer
                  ref={sheetRef}
                  className='scrolling-container'
                  column
                  open={open}
                  isMobile={isMobile}
                >
                  {title && (
                    <SheetTitle
                      className='scrolling-container-title'
                      isMobile={isMobile}
                    >
                      {title}
                      {subTitle}
                    </SheetTitle>
                  )}
                  {typeof children === 'function'
                    ? children(toggleOpen, setDirty, open)
                    : children}
                </ScrollingContainer>
              </SheetContainer>
            )}
          </CSSTransition>
        </>
      )}
    </Mobile>
  )

  return (
    <>
      {ReactDOM.createPortal(group, document.getElementById('modal-root'))}
      {!hideButton && button(toggleOpen, open)}
    </>
  )
}

Sheet.propTypes = {
  button: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  height: PropTypes.string,
  hideClose: PropTypes.bool,
  isOpen: PropTypes.bool,
  position: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
}

export default Sheet
