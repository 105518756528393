/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import type { EmotionJSX } from '@emotion/react/types/jsx-namespace'

type Alignments = 'center' | 'flex-start' | 'flex-end'

interface Props {
  headers: string[]
  headersFlexSize?: number[]
  content: () => React.ReactNode | EmotionJSX.Element
  headerFlexAlignment?: Alignments[]
}

export const GenericTable = ({
  headers,
  headersFlexSize,
  content,
  headerFlexAlignment,
}: Props) => {
  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          flex-direction: row;
          padding: 0 8px;
        `}
      >
        {headers.map((header, index) => (
          <div
            key={header}
            css={css`
              flex: ${headersFlexSize ? headersFlexSize[index] : 1};
              display: flex;
              justify-content: ${headerFlexAlignment
                ? headerFlexAlignment[index]
                : 'flex-start'};
            `}
          >
            <span
              css={css`
                font-weight: 700;
                font-size: 14px;
                text-transform: uppercase;
              `}
            >
              {header}
            </span>
          </div>
        ))}
      </div>
      <div
        css={css`
          margin-top: 16px;
        `}
      >
        {content()}
      </div>
    </div>
  )
}

export default GenericTable
