/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { Switch } from 'antd'
import React from 'react'

type SwitchInputProps = {
  title: string
  subtitle?: string
  value: boolean
  onChange: (value: boolean) => void
}

export const SwitchInput: React.FC<SwitchInputProps> = ({
  title,
  subtitle,
  value,
  onChange,
}) => {
  return (
    <>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          flex: 1 0 0;
        `}
      >
        <p
          css={css`
            align-self: stretch;
            color: var(--Neutrals-White, #fff);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          `}
        >
          {title}
        </p>
        {subtitle ? (
          <p
            css={css`
              align-self: stretch;
              color: var(--Neutrals-Gray40, #9fa3a9);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%; /* 16.8px */
            `}
          >
            {subtitle}
          </p>
        ) : null}
      </div>
      <Switch onChange={onChange} value={value} size='small' />
    </>
  )
}
