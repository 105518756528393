/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { Fragment, useEffect, useRef, useState } from 'react'
import { t } from '@sportninja/common/i18n'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'

import FormSheet from '../Form/FormSheet'
import DeleteModal from '../Modal/DeleteModal'
import TextModal from '../Modal/TextModal'
import Form from '../Form'
import BannerController from '../Banner/BannerController'
import Banner from '../Banner'
import InviteFormField from '../InviteFormField'
import Icon from '../Icon'
import { Button } from './css'
import OptionButtonIcon from '../List/OptionButtons/Icon'
import { FormButton } from '../Form/css'
import { font } from '../css'

const useButtonLoader = (method) => {
  if (!method) return []

  const mounted = useRef(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    return () => {
      if (mounted) mounted.current = false
    }
  }, [])

  const action = async (...args) => {
    setLoading(true)
    try {
      const response = await method(...args)
      mounted.current === true && setLoading(false)
      return response
    } catch (e) {
      mounted.current === true && setLoading(false)
      throw e
    }
  }

  return [loading, action]
}

export const RemoveAction = ({
  deleteButtonText = t('common:delete'),
  deleteMessage,
  deleteTitle,
  onDelete,
  panelBusy,
}) => {
  const [loading, onClick] = useButtonLoader(onDelete)
  const loadingState = {
    disabled: loading || panelBusy,
    loading: loading ? loading : undefined,
  }
  return (
    <DeleteModal
      title={deleteTitle}
      message={deleteMessage}
      onDelete={onClick}
      Button={({ ...props }) => (
        <Button type='button' {...loadingState} {...props}>
          {deleteButtonText}
        </Button>
      )}
    />
  )
}

export const UpdateAction = ({
  deleteButtonText,
  deleteMessage,
  deleteTitle,
  onClick: _onClick,
  onUpdate,
  onDelete,
  panelBusy,
  setPanelBusy,
  updateForm,
  updateTitle,
  setMenuOpen,
}) => {
  const mounted = useRef(null)
  const [loading, onSubmit] = useButtonLoader(onUpdate)
  const [isUpdateOpenLoading, setIsUpdateOpenLoading] = useState(false)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const isLoading = loading || isUpdateOpenLoading
  const loadingState = {
    disabled: loading || panelBusy,
    loading: isLoading ? isLoading : undefined,
  }

  const onEditClick = async (onClick) => {
    if (typeof _onClick === 'function') {
      setPanelBusy(true)
      setIsUpdateOpenLoading(true)
      try {
        await _onClick()
      } finally {
        setPanelBusy(false)
        setIsUpdateOpenLoading(false)
      }
    }
    onClick()
  }

  const button = ({ onClick, ...props }) => (
    <Button
      type='button'
      onClick={onEditClick.bind(this, onClick)}
      {...loadingState}
      {...props}
    >
      {t('common:edit')}
    </Button>
  )

  return (
    <FormSheet
      Button={button}
      deleteButtonText={deleteButtonText}
      deleteMessage={deleteMessage}
      deleteTitle={deleteTitle}
      form={updateForm}
      label={updateTitle}
      onDelete={onDelete}
      onSubmit={async (...props) => {
        const response = await onSubmit(...props)
        if (mounted.current) setMenuOpen(false)
        return response
      }}
      title={updateTitle}
    />
  )
}

export const InviteAction = ({ invite, onComplete, panelBusy, user, slug }) => {
  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const mounted = useRef(true)

  useEffect(() => {
    return () => (mounted.current = false)
  }, [])

  const resendInvitation = async (callback) => {
    const invitationId = invite?.invitation_id || user?.id

    setLoading(true)
    try {
      await req(`/invitations/${invitationId}/resend`)
      setShowSuccess(true)
      setTimeout(() => {
        callback(false)
        setShowSuccess(false)
        setLoading(false)
      }, 3000)
    } catch (e) {
      setLoading(false)
    }
  }

  const onInvite = async (body) => {
    const copy = { ...body }
    // hack bc for some reason the api wants this even tho it knows it already
    copy.official_type_id = user.official_type_id
    copy.role_type_id = user.role_type_id
    copy.team_official_type_id = user.team_official_type_id
    copy.player_type_id = user.player_type_id
    copy.name_first = user.name_first
    copy.name_last = user.name_last

    const response = await req(slug, {
      method: 'PUT',
      body: JSON.stringify(copy),
    })

    BannerController.add(({ ...props }) => (
      <Banner {...props}>{t('Web:invitationSentSuccessfully')}</Banner>
    ))

    await onComplete()
    return response
  }

  return invite.is_invited ? (
    !invite.is_accepted && (
      <TextModal
        title='Resend Invitation'
        link={(toggleOpen) => (
          <div
            css={css`
              position: relative;

              &:hover {
                i {
                  color: white;
                }
              }
            `}
          >
            <OptionButtonIcon
              disabled={panelBusy}
              name='envelope'
              onClick={toggleOpen}
              title='Resend Invitation'
            />
            <Icon
              name='redo'
              css={css`
                position: absolute;
                bottom: -4px;
                right: -6px;
                filter: drop-shadow(1px 1px 1px black) brightness(1.3);
                pointer-events: none;
              `}
              color={colors.DEFAULT_FLAIR}
            />
          </div>
        )}
      >
        {(toggleOpen) => (
          <Fragment>
            <div
              css={css`
                margin-bottom: 20px;
              `}
            >
              Resend an invitation to {user.name_first} {user.name_last}?
            </div>
            {showSuccess ? (
              <div
                css={css`
                  ${font.title}
                  font-size: 18px;
                  font-weight: 700;
                  text-align: center;
                  padding: 16px 0;
                  color: ${colors.GAME_SETUP_GREEN};
                `}
              >
                Success!
              </div>
            ) : (
              <FormButton
                disabled={loading}
                busy={loading}
                isSubmit
                onClick={async () => {
                  await resendInvitation(toggleOpen)
                }}
              >
                Resend
              </FormButton>
            )}
          </Fragment>
        )}
      </TextModal>
    )
  ) : (
    <TextModal
      title={`${t('Web:sendAnInvite')} to ${user.name_first} ${user.name_last}`}
      link={(toggleOpen) => (
        <OptionButtonIcon
          disabled={panelBusy}
          name='envelope'
          onClick={toggleOpen}
        />
      )}
    >
      {(toggleOpen) => (
        <Fragment>
          <div
            css={css`
              margin-bottom: 20px;
            `}
          >
            {t('Web:enterEmailToInvite')}
            <div
              css={css`
                margin-top: 8px;
              `}
            >
              {t('Web:invitedToRegister')}
            </div>
          </div>
          <Form
            form={[
              [
                {
                  name: 'email',
                  component: InviteFormField,
                  hideHelp: true,
                  required: true,
                  defaultValue: user?.email,
                },
              ],
            ]}
            onCancel={toggleOpen}
            onSubmit={onInvite}
            doNotDisable={user?.email ? true : false}
          />
        </Fragment>
      )}
    </TextModal>
  )
}
