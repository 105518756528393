import React from 'react'
import PropTypes from 'prop-types'
import { t } from '@sportninja/common/i18n'

import FormInput from './Form/FormInput'

const InviteFormField = ({
  customHelp,
  defaultValue,
  disabled,
  hasError,
  hideHelp = false,
  name,
  onChange,
  required,
}) => {
  return (
    <FormInput
      onChange={onChange}
      disabled={disabled}
      hasError={hasError}
      input={{
        defaultValue,
        name,
        label: t('common:email'),
        placeholder: 'patq@sportninja.com',
        type: 'email',
        required,
      }}
    >
      {!hideHelp && (
        <p style={{ marginTop: 16, fontSize: 13 }}>
          {t('Web:inviteToJoinDescription')} {t('Web:invitedToRegister')}
        </p>
      )}
      {customHelp}
    </FormInput>
  )
}

InviteFormField.propTypes = {
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  entityType: PropTypes.string,
  hasError: PropTypes.bool,
  hideHelp: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
}

export default InviteFormField
