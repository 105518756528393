/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import cn from 'classnames'

const Toggle = ({ isEnabled }) => {
  return (
    <div
      css={css`
        height: 20px;
        width: 36px;
        flex-shrink: 0;
      `}
    >
      <div
        className={cn('toggle-container', { 'is-enabled': isEnabled })}
        css={css`
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          position: relative;
          border-radius: 10px;
          border: 3px solid white;
          transition: background-color 0.15s ease-in-out;

          &.is-enabled {
            border-color: transparent;
            background-color: ${colors.DEFAULT_FLAIR};
          }

          .toggle {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: white;
            transform: translateX(2px);
            transition: transform 0.15s ease-in-out,
              background-color 0.15s ease-in-out;
          }

          &.is-enabled .toggle {
            background-color: ${colors.HEADER};
            transform: translateX(20px);
          }
        `}
      >
        <div className='toggle' />
      </div>
    </div>
  )
}

export default Toggle
