/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { formatCurrency, getErrorMessage } from '@sportninja/common/utils/utils'
import { loadStripe } from '@stripe/stripe-js'
import { useContext, useEffect, useState } from 'react'
import Icon from 'src/components/Icon'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { EventCard } from 'src/components/RegistrationEventCard'
import { EventProgress } from 'src/components/RegistrationEventProgress'
import { RegistrationContext } from '../RegistrationContext'
import {
  PageTitle,
  REGISTRATION_STEPS,
  alternateButtonStyle,
  buttonStyle,
  headingButtonStyle,
  headingSixStyle,
  paragraphMediumBoldStyle,
  paragraphMediumStyle,
  paragraphSmallBoldStyle,
  paragraphSmallStyle,
} from './Main'
import { Spin } from 'antd'

const PAYMENT_STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed',
  PENDING: 'pending',
}

const RegistrationPayment = () => {
  const {
    session,
    setStep,
    division,
    registration,
    updateSessionVerifyEmail,
    deleteSession,
  } = useContext(RegistrationContext)
  const [error, setError] = useState()
  const [stripeClient, setStripeClient] = useState(null)
  const [paymentIsLoading, setPaymentLoad] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState(null)
  const {
    total,
    tax,
    subtotal,
    client_secret,
    root_schedule_name,
    connected_account_id,
  } = session
  const clientSecret = client_secret
  const connect_account = connected_account_id

  useEffect(() => {
    const fetchStripeClient = async () => {
      try {
        let localStripeClient = null
        const host = window.location.hostname || window.location.host
        if (
          __DEV__ ||
          !['app.sportninja.com', 'canlanstats.sportninja.com'].includes(host)
        ) {
          localStripeClient = await loadStripe(
            process.env.REACT_APP_STRIPE_KEY_DEV,
            {
              stripeAccount: connect_account,
            }
          )
        } else {
          localStripeClient = await loadStripe(
            process.env.REACT_APP_STRIPE_KEY,
            {
              stripeAccount: connect_account,
            }
          )
        }

        if (!localStripeClient) {
          return
        }

        const elements = localStripeClient.elements({
          clientSecret: clientSecret,
          appearance: {
            theme: 'night',
          },
        })

        let paymentElement = elements.create('payment', {
          paymentMethodOrder: ['apple_pay', 'google_pay', 'card'],
        })

        const paymentDiv = document.querySelector('#payment-element')

        if (!paymentDiv) {
          return
        }

        paymentElement.mount('#payment-element')

        const stripeForm = document.querySelector('#payment-form')

        setStripeClient(localStripeClient)

        stripeForm.addEventListener('submit', async (e) => {
          e.preventDefault()
          setPaymentLoad(true)
          if (!localStripeClient) {
            return
          }
          const { error } = await localStripeClient.confirmPayment({
            elements,
            redirect: 'if_required',
          })
          if (error) {
            setError(error?.message || 'Something went wrong')
            setPaymentLoad(false)
            setPaymentStatus(PAYMENT_STATUS.FAILED)
          } else {
            updateSessionVerifyEmail()
              .then(() => {
                setPaymentLoad(false)
                setPaymentStatus(PAYMENT_STATUS.SUCCESS)
                setTimeout(() => {
                  setStep(REGISTRATION_STEPS.CONFIRM_YOUR_EMAIL)
                }, 3000)
              })
              .catch((session_error) => {
                if (session_error?.status === 410) {
                  setPaymentLoad(false)
                  setPaymentStatus(PAYMENT_STATUS.FAILED)
                  setError('Session has expired. Please try again.')
                  setTimeout(() => {
                    // localStorage.removeItem('registration_session')
                    deleteSession()
                    window.location.reload()
                  }, 1500)
                } else {
                  const errorMessage = getErrorMessage(session_error)
                  setError(errorMessage)
                  setPaymentLoad(false)
                  setPaymentStatus(PAYMENT_STATUS.FAILED)
                }
              })
          }
        })
      } catch (e) {
        const errorMessage = getErrorMessage(e)
        setError(errorMessage)
        setPaymentLoad(false)
      }
    }
    if (clientSecret && connect_account) {
      fetchStripeClient()
    }
  }, [connect_account, clientSecret])

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        background-color: ${colors.REGISTRATION_SECOND_BACKGROUND};
        width: 1080px;
        padding: 0 35px 35px 35px;
        border-radius: 16px 16px 0px 0px;
        @media (max-width: 768px) {
          min-width: 0;
          width: 100%;
        }
      `}
    >
      <EventCard eventInfo={registration} />
      {/*  Progress */}
      <EventProgress eventProgress={3} />
      <PageTitle
        title='Pay Your Deposit'
        subtitle={
          'Please make a deposit payment to complete your registration for the competition.'
        }
      />
      <div
        css={css`
          max-height: 334px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;
          /* change the flex direction on mobile */
          @media (max-width: 768px) {
            flex-direction: column;
            gap: 0px;
            align-items: center;
            max-height: unset;
          }
        `}
      >
        {/* Payment Info - SportNinja Data */}
        <div
          css={css`
            display: flex;
            /* padding: 16px 0px; */
            flex-direction: column;
            align-items: center;
            gap: 16px;
            flex: 1 0 0;
            @media (max-width: 768px) {
              margin-bottom: 16px;
              width: 100%;
            }
          `}
        >
          <p
            css={css`
              ${paragraphMediumBoldStyle}
              color: ${colors.WHITE};
              align-self: stretch;
            `}
          >
            Payment Info
          </p>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              align-self: stretch;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                flex: 1 0 0;
              `}
            >
              <p
                css={css`
                  ${paragraphSmallStyle}
                  color: ${colors.WHITE};
                `}
              >
                Deposit Fee
              </p>
              <p
                css={css`
                  ${paragraphMediumBoldStyle}
                  color: ${colors.WHITE};
                `}
              >
                {root_schedule_name}
              </p>
            </div>
            <p
              css={css`
                ${paragraphMediumStyle}
                color: ${colors.WHITE};
              `}
            >
              {formatCurrency(subtotal, division?.currency?.name)}
            </p>
          </div>
          {/* Separator */}
          <div
            css={css`
              width: 100%;
              border-color: ${colors.VERY_DARK_GRAYISH_BLUE};
              border-bottom-width: 1px;
              border-bottom-style: solid;
            `}
          />
          <div
            css={css`
              display: flex;
              align-items: flex-start;
              gap: 16px;
              align-self: stretch;
            `}
          >
            <p
              css={css`
                ${paragraphMediumStyle}
                color: ${colors.WHITE};
                flex: 1 0 0;
              `}
            >
              Subtotal
            </p>
            <p
              css={css`
                ${paragraphMediumStyle}
                color: ${colors.WHITE};
              `}
            >
              {formatCurrency(subtotal, division?.currency?.name)}
            </p>
          </div>

          <div
            css={css`
              display: flex;
              align-items: flex-start;
              gap: 16px;
              align-self: stretch;
            `}
          >
            <p
              css={css`
                ${paragraphMediumStyle}
                color: ${colors.WHITE};
                flex: 1 0 0;
              `}
            >
              Total Tax
            </p>
            <p
              css={css`
                ${paragraphMediumStyle}
                color: ${colors.WHITE};
              `}
            >
              {formatCurrency(tax, division?.currency?.name)}
            </p>
          </div>
          {/* Separator */}
          <div
            css={css`
              width: 100%;
              border-color: ${colors.VERY_DARK_GRAYISH_BLUE};
              border-bottom-width: 1px;
              border-bottom-style: solid;
            `}
          />
          <div
            css={css`
              display: flex;
              align-items: flex-start;
              gap: 22px;
              align-self: stretch;
            `}
          >
            <p
              css={css`
                ${headingButtonStyle}
                color: ${colors.WHITE};
                flex: 1 0 0;
              `}
            >
              Total
            </p>
            <p
              css={css`
                ${headingButtonStyle}
                color: ${colors.WHITE};
              `}
            >
              {formatCurrency(total, division?.currency?.name)}
            </p>
          </div>
        </div>
        {/* Separator Between SportNinja and Stripe Stuff */}
        <div
          css={css`
            height: 100%;
            width: 1px;
            background-color: ${colors.VERY_DARK_GRAYISH_BLUE};
          `}
        />
        {/* Stripe Stuff */}
        <div
          css={css`
            /* padding: 16px 0px; */
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            @media (max-width: 768px) {
              width: 100%;
              padding-top: 0px;
            }
            /* border: 1px solid ${colors.GREEN_GOBLIN}; */
          `}
        >
          <div
            css={css`
              width: 100%;
            `}
          >
            {paymentStatus === PAYMENT_STATUS.SUCCESS ? (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  height: 250px;
                `}
              >
                <Icon name='check' color={colors.PRIMARY} fontSize={24} />
                <p
                  css={css`
                    ${headingButtonStyle}
                    color: ${colors.WHITE};
                    margin-top: 32px;
                  `}
                >
                  Payment Successful. <br />
                  You will be redirected shortly.
                </p>
                <Spin
                  css={css`
                    margin-top: 32px;
                    color: ${colors.PRIMARY_300};
                  `}
                />
              </div>
            ) : paymentStatus === PAYMENT_STATUS.FAILED ? (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  height: 300px;
                `}
              >
                <Icon name='times' color={colors.ERROR_LIGHT} fontSize={24} />
                <p
                  css={css`
                    ${headingButtonStyle}
                    color: ${colors.WHITE};
                    margin-top: 32px;
                  `}
                >
                  Payment Failed
                </p>
                <span
                  css={css`
                    ${paragraphSmallBoldStyle}
                    color: ${colors.WHITE};
                    margin-top: 16px;
                    margin-bottom: 16px;
                  `}
                >
                  {error}
                </span>
              </div>
            ) : (
              <>
                <div
                  css={css`
                    /* border: 1px solid ${colors.CYAN_BLUE}; */
                  `}
                >
                  <form id='payment-form'>
                    <div id='card-element' />
                    <div id='payment-element' />
                    <div
                      css={css`
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        gap: 16px;
                        margin-top: 16px;
                      `}
                    >
                      <button
                        type='button'
                        disabled={paymentIsLoading}
                        onClick={async () => {
                          if (
                            window.confirm('Are you sure you want to go back?')
                          ) {
                            setStep(REGISTRATION_STEPS.TEAM_REGISTER)
                          }
                        }}
                        css={css`
                          ${alternateButtonStyle}
                          ${headingSixStyle}
                          width: 100%;
                          max-height: 40px;
                          /* block the cursor if the payment if loading */
                          cursor: ${paymentIsLoading
                            ? 'not-allowed'
                            : 'pointer'};
                        `}
                      >
                        Back
                      </button>
                      <button
                        disabled={paymentIsLoading}
                        id='submit'
                        css={css`
                          ${buttonStyle}
                          ${headingSixStyle}
                          width: 100%;
                          max-height: 40px;
                          cursor: ${paymentIsLoading
                            ? 'not-allowed'
                            : 'pointer'};
                        `}
                      >
                        {paymentIsLoading ? (
                          <LoadingSpinner size={1} />
                        ) : (
                          'PAY NOW'
                        )}
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  css={css`
                    visibility: ${'hidden'};
                  `}
                >
                  <LoadingSpinner />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegistrationPayment
