import { getImageThumbnailId } from '../helpers'
import { str } from '../utils'

const entityImageReducer = (actions) => (draft, { type, payload }) => {
  switch (type) { // eslint-disable-line default-case
    case str(actions.create.success):
      draft[payload.id].imageId = getImageThumbnailId({ image: payload.data })
      return

    case str(actions.delete.success):
      delete draft[payload.id].imageId
      return
  }
}

export default entityImageReducer
