import { produce } from 'immer'
import merge from 'lodash.merge'

import auth from '../actions/auth'
import user from '../actions/user'
import org from '../actions/org'
import schedule from '../actions/schedule'
import team from '../actions/team'
import game from '../actions/game'
import fav from '../actions/fav'
import usersActions from '../actions/users'
import { ENTITY_TYPES } from '../sagas/utils'
import { arrayToObject } from '../utils/utils'
import entityImageReducer from './sub-reducers/entity-image'
import venuesReducer from './sub-reducers/venues'
import usersReducer from './sub-reducers/users'
import { getSharedReducer as feedReducer } from './feed'
import { getSharedReducer as favReducer } from './fav'
import searchReducer from './search'
import { setChildListById, roleToEntity } from './helpers'
import { str } from './utils'
import parsers from './parsers'
import officialsReducer from './sub-reducers/officials'

const reducer = (draft, { type, payload }) => {
  const scheduleParser = parsers[ENTITY_TYPES.schedule]

  switch (
    type // eslint-disable-line default-case
  ) {
    /* ACTIONS FROM OTHER REDUCERS */
    case str(org.read.success):
      if (!payload.data || !Array.isArray(payload.data.schedules)) return
      return merge(draft, arrayToObject(payload.data.schedules, scheduleParser))

    case str(game.readPast.success):
    case str(game.readUpcoming.success):
    case str(game.readCustom.success):
    case str(org.games.read.success): {
      const schedules = arrayToObject(payload.data, (o) => {
        const schedule = o.schedule
        if (!schedule) return
        return scheduleParser(schedule)
      })
      return merge(draft, schedules)
    }

    case str(org.schedules.read.success):
    case str(team.schedules.read.success):
      return merge(draft, arrayToObject(payload.data, scheduleParser))

    case str(user.readRoles.success):
    case str(usersActions.readRoles.success):
      return merge(draft, roleToEntity('schedule_id', payload, scheduleParser))

    case str(user.readSchedules.success):
      return merge(draft, arrayToObject(payload.data, scheduleParser))

    case str(game.create.success):
      if (draft && draft[payload.id] && draft[payload.id] !== undefined) {
        draft[payload.id].games = draft[payload.id].games || []
        draft[payload.id].games.push(payload.data.id)
      }
      return

    case str(game.delete.success): {
      const schedule = draft[payload.id]

      if (schedule && Array.isArray(schedule.games)) {
        const gameIndex = schedule.games.findIndex(
          (m) => m.id === payload.gameId
        )
        draft[payload.id].games.splice(gameIndex, 1)
      }

      return
    }

    case str(team.delete.success): {
      // Unfortunately, we aren't provided the list of schedules that a team was
      // added to (and this isn't really stored anywhere anyway)
      // So, iterate over all schedules, search their list of teams, and remove
      // the deleted team from each one.

      for (const scheduleId in draft) {
        // The teams list, which is just team IDs
        const teams = draft[scheduleId].teams

        if (Array.isArray(teams)) {
          // The deleted team
          const teamIndex = teams.findIndex((teamId) => teamId === payload.id)
          // If it exists, remove it
          if (teamIndex > -1) {
            draft[scheduleId].teams.splice(teamIndex, 1)
          }
        }
      }

      return
    }

    case str(auth.logout.success):
      return {}

    /* END ACTIONS FROM OTHER REDUCERS */

    case str(schedule.create.success):
      draft[payload.data.id] = scheduleParser(payload.data)
      return

    case str(schedule.read.success):
    case str(schedule.update.success):
      draft[payload.id] = merge(draft[payload.id], scheduleParser(payload.data))
      return

    case str(schedule.delete.success):
      delete draft[payload.id]
      return

    case str(schedule.teams.create.success):
      draft[payload.id].teams = draft[payload.id].teams || []
      draft[payload.id].teams.push(payload.data.id)
      return

    case str(schedule.teams.delete.success): {
      const teams = draft[payload.id].teams
      const teamIndex = teams.findIndex((id) => id === payload.team_id)
      teams.splice(teamIndex, 1)
      return
    }

    case str(schedule.teams.read.success):
      return setChildListById(draft, payload, 'teams')

    case str(team.games.read.success):
    case str(schedule.games.read.success): {
      merge(
        draft,
        arrayToObject(payload.data, (game) => {
          return scheduleParser(game.schedule)
        })
      )
      setChildListById(draft, payload, 'games')
      return
    }
  }
}

export default (state = {}, action) =>
  produce(state, (draft) => {
    entityImageReducer(schedule.image)(draft, action)
    venuesReducer(schedule.venues)(draft, action)
    usersReducer(schedule.users)(draft, action)
    searchReducer(ENTITY_TYPES.schedule)(draft, action)
    favReducer(fav.schedule, ENTITY_TYPES.schedule)(draft, action)
    feedReducer(schedule.feed)(draft, action)
    officialsReducer(schedule.officials)(draft, action)

    return reducer(draft, action)
  })
