/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import {
  createRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import Select from 'react-select'
import Icon from '../../../../components/Icon'
import { isCanlan } from '@sportninja/common/utils/customer-name'

export const sideModalRef = createRef()

const conditionOptions = (divisionLength, conditionType) => {
  let options = []

  conditionType.forEach((item) => {
    if (Number(item.id) === 2 && divisionLength > 1) {
      options.push({
        label: item.name,
        value: item.id,
      })
    } else {
      options.push({
        label: item.name,
        value: item.id,
      })
    }
  })

  return [...options]
}

let scheduleId
let scheduleRegistrationId

export const SideModal = forwardRef(
  ({ onConfirm, divisions, conditionType }, ref) => {
    const [isVisible, setIsVisible] = useState(false)
    const [currentTeam, setCurrentTeam] = useState(null)
    const [comment, setComment] = useState('')
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [status, setStatus] = useState(null)
    const [allDivisions, setAllDivisions] = useState([])
    const [error, setError] = useState('')

    const handleOpen = useCallback(
      (team, divisionId, recScheduleId, recScheduleRegistrationId) => {
        scheduleId = recScheduleId
        scheduleRegistrationId = recScheduleRegistrationId

        setCurrentTeam(team)

        divisions.forEach((division) => {
          if (division.id !== divisionId) {
            setAllDivisions((prev) => [
              ...prev,
              { label: division.name, value: division.id },
            ])
          }
        })

        setIsVisible(true)
      },
      []
    )

    const handleClose = useCallback(() => {
      setCurrentTeam(null)
      setComment(' ')
      setStatus(null)
      setSelectedDivision(null)
      setAllDivisions([])
      setIsVisible(false)
    }, [])

    const confirmExit = () => {
      if (window.confirm('Are you sure you want to exit?')) {
        handleClose()
      }
    }

    const onRegStatusChange = useCallback(() => {
      if (comment.length < 1) {
        setError(true)

        return
      }
      if (Number(status?.value) === 1) {
        onConfirm({
          scheduleId: scheduleId,
          scheduleRegistrationId: scheduleRegistrationId,
          status: 4,
          teamRosterId: currentTeam.team_roster_id,
          teamId: currentTeam.uid,
          conditions: comment.trim(),
          conditionType: 1,
        })
      } else if (Number(status?.value) === 2) {
        onConfirm({
          scheduleId: scheduleId,
          scheduleRegistrationId: scheduleRegistrationId,
          status: 4,
          teamRosterId: currentTeam.team_roster_id,
          teamId: currentTeam.uid,
          conditions: comment.trim(),
          conditionType: 2,
          divisionId: selectedDivision.value,
        })
      } else {
        console.log('status', status)
      }

      handleClose()
    }, [selectedDivision, status?.value, status, currentTeam, comment])

    useImperativeHandle(ref, () => ({
      handleOpen,
      handleClose,
    }))

    return (
      <div
        className={isVisible ? 'isContainerVisible' : ''}
        css={css`
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          width: 100vw;
          height: 100vh;
          background-color: transparent;
          z-index: -1;
          transition: all 0.3s ease-in-out;

          &.isContainerVisible {
            background-color: rgba(0, 0, 0, 0.85);
            z-index: 9999;
          }
        `}
      >
        <div
          className={isVisible ? 'isVisible' : ''}
          css={css`
            background: no-repeat fixed linear-gradient(#282e38, #181a1d);
            width: 30%;
            height: 100vh;
            display: flex;
            padding: 20px 30px;
            flex-direction: column;
            justify-content: space-between;
            transition: all 0.3s ease-in-out;
            transform: translate(-1000px);

            &.isVisible {
              transform: translate(0);
            }
          `}
        >
          <div
            css={css`
              width: 100%;
              display: flex;
              flex-flow: column;
              margin-bottom: 20px;
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: flex-end;
              `}
            >
              <button onClick={confirmExit}>
                <Icon name='times' color={colors.WHITE} fontSize={24} />
              </button>
            </div>
            <div
              css={css`
                margin-top: 50px;
                font-size: 48px;
                font-weight: bold;
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'Rift, Arial, Helvetica, sans-serif'};
              `}
            >
              <p css={css``}>Approve with conditions</p>
            </div>
          </div>
          <div
            css={css`
              width: 100%;
              margin-top: -150px;
            `}
          >
            <p
              css={css`
                color: ${colors.WHITE};
                margin-bottom: 10px;
              `}
            >
              Condition Type
            </p>
            <Select
              options={conditionOptions(divisions.length, conditionType)}
              value={status}
              onChange={setStatus}
              styles={{
                container: (baseStyles) => ({
                  ...baseStyles,
                  width: '100%',
                }),
                control: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: colors.HEADER,
                  borderColor: colors.SOFT_STEEL,
                }),
                singleValue: (baseStyles) => ({
                  ...baseStyles,
                  color: colors.WHITE,
                }),
                menu: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: colors.HEADER,
                  borderColor: colors.SOFT_STEEL,
                  zIndex: 999999999999999,
                }),
                option: (baseStyles, { isFocused }) => ({
                  ...baseStyles,
                  backgroundColor: isFocused ? colors.DEFAULT_FLAIR : undefined,
                }),
              }}
            />
            {status && Number(status.value) === 2 && (
              <div>
                <p
                  css={css`
                    color: ${colors.WHITE};
                    margin-bottom: 10px;
                    margin-top: 20px;
                  `}
                >
                  Select the Division
                </p>
                <Select
                  options={allDivisions}
                  onChange={setSelectedDivision}
                  styles={{
                    container: (baseStyles) => ({
                      ...baseStyles,
                      width: '100%',
                    }),
                    control: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: colors.HEADER,
                      borderColor: colors.SOFT_STEEL,
                    }),
                    singleValue: (baseStyles) => ({
                      ...baseStyles,
                      color: colors.WHITE,
                    }),
                    menu: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: colors.HEADER,
                      borderColor: colors.SOFT_STEEL,
                      zIndex: 999999999999999,
                    }),
                    option: (baseStyles, { isFocused }) => ({
                      ...baseStyles,
                      backgroundColor: isFocused
                        ? colors.DEFAULT_FLAIR
                        : undefined,
                    }),
                  }}
                />
              </div>
            )}
            <div>
              <p
                css={css`
                  color: ${colors.WHITE};
                  margin-bottom: 10px;
                  margin-top: 20px;
                `}
              >
                Condition Description *
              </p>
              <textarea
                value={comment}
                onChange={(e) => {
                  setError(false)
                  setComment(e.target.value)
                }}
                placeholder='Type the condition here...'
                css={css`
                  color: ${colors.WHITE};
                  background-color: ${colors.HEADER};
                  border: 1px solid ${colors.SOFT_STEEL};
                  padding: 12px;
                  border-radius: 5px;
                  width: 100%;
                  font-family: 'MaisonNeue', sans-serif !important;
                  font-size: 14px;
                `}
              />
              {error && (
                <p
                  css={css`
                    color: red;
                    margin-top: 10px;
                  `}
                >
                  this field cannot be blank
                </p>
              )}
            </div>
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 20px;
            `}
          >
            <button
              onClick={confirmExit}
              css={css`
                width: 100%;
                background-color: transparent;
                color: ${colors.WHITE};
                border: 1px solid ${colors.WHITE};
                padding: 12px;
                border-radius: 5px;
                margin-right: 10px;
                font-size: 18px;
                font-weight: 700;
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'Rift, Arial, Helvetica, sans-serif'};
              `}
            >
              CANCEL
            </button>
            <button
              onClick={onRegStatusChange}
              css={css`
                width: 100%;
                background-color: ${colors.DEFAULT_FLAIR};
                color: ${colors.WHITE};
                padding: 12px;
                border-radius: 5px;
                margin-left: 10px;
                font-size: 18px;
                font-weight: 700;
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'Rift, Arial, Helvetica, sans-serif'};
              `}
            >
              CONFIRM
            </button>
          </div>
        </div>
      </div>
    )
  }
)

SideModal.displayName = 'SideModal'
