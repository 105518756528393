/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import Icon from '../Icon'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import type { EmotionJSX } from '@emotion/react/types/jsx-namespace'

interface Props {
  children: React.ReactNode | JSX.Element | EmotionJSX.Element
  title: string
}

export interface SideModal {
  handleOpen: () => void
  handleClose: () => void
}

export const SideModal = forwardRef(({ children, title }: Props, ref) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleOpen = useCallback(() => setIsVisible(true), [])
  const handleClose = useCallback(() => setIsVisible(false), [])

  const confirmExit = () => {
    if (window.confirm('Are you sure you want to exit?')) {
      handleClose()
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
  }))

  return (
    <div
      className={isVisible ? 'isContainerVisible' : ''}
      css={css`
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background-color: transparent;
        z-index: -1;
        transition: all 0.3s ease-in-out;

        &.isContainerVisible {
          background-color: rgba(0, 0, 0, 0.85);
          z-index: 9999;
        }
      `}
    >
      <div
        className={isVisible ? 'isVisible' : ''}
        css={css`
          background-color: ${colors.HEADER};
          width: 30%;
          height: 100vh;
          display: flex;
          padding: 20px 30px;
          flex-direction: column;
          justify-content: space-between;
          transition: all 0.3s ease-in-out;
          transform: translate(-1000px);

          &.isVisible {
            transform: translate(0);
          }
        `}
      >
        <div
          css={css`
            width: 100%;
            display: flex;
            flex-flow: column;
            margin-bottom: 20px;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: flex-end;
            `}
          >
            <button onClick={confirmExit}>
              <Icon name='times-square' color={colors.WHITE} fontSize={30} />
            </button>
          </div>
          <div
            css={css`
              margin-top: 50px;
              font-size: 65px;
              font-weight: bold;
              font-family: ${isCanlan
                ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                : 'Rift, Arial, Helvetica, sans-serif'};
            `}
          >
            <p css={css``}>{title}</p>
          </div>
        </div>

        {children}
      </div>
    </div>
  )
})

SideModal.displayName = 'SideModal'
