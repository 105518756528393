/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'

import { Flex } from '../Layout'
import Picture from '../Picture'
import FollowBtn from '../FollowBtn'

const SearchMenuResult = ({
  iconName,
  imageIsPortrait,
  onClick,
  result,
  ResultButton,
  searchType,
  to,
  enableGlobalSearch = false,
}) => {
  let subText = '',
    query = '',
    id = result?.id

  switch (searchType) {
    case ENTITY_TYPES.player: {
      let hasOrgName = false
      subText = result?.teams
        ?.map(({ teamName, orgName }) => {
          let str = teamName
          if (orgName) {
            str = `${orgName}: ${str}`
            hasOrgName = true
          }
          return str
        })
        .join(hasOrgName ? ' - ' : ', ')
      break
    }
    case ENTITY_TYPES.team:
      subText = result?.hierarchy?.map((i) => i?.name).join(' > ')
      if (!subText) {
        subText = result?.id || ''
      } else {
        subText = `${subText} > ${result?.id}`
      }
      break
    case ENTITY_TYPES.org:
      subText = result?.hierarchy?.map((i) => i.name).join(' > ')
      break
    case ENTITY_TYPES.schedule:
      if (result.type === 'Season') {
        break
      }
      subText = result?.rootSchedule?.fullName
      id = result?.rootSchedule?.id
      query = `?${result.type}=${result.id}`
      break
    default:
      break
  }

  return (
    <Flex
      key={id}
      row
      justifyContent='space-between'
      alignItems='center'
      css={css`
        margin-bottom: 20px;
      `}
    >
      <Flex
        as={typeof to === 'string' ? Link : Flex}
        row
        alignItems='center'
        to={typeof to === 'string' ? `${to}/${id}${query}` : undefined}
        onClick={onClick.bind(this, { result })}
        css={css`
          padding-right: 8px;
          cursor: pointer;
        `}
        className='search-menu-result'
      >
        <Picture
          square={!imageIsPortrait}
          fit={!imageIsPortrait}
          size='xsmall'
          imageId={result.imageId}
          iconName={result?.isTournament ? 'trophy' : iconName}
        />
        <div
          css={css`
            padding-left: 12px;
            > div {
              line-height: 1;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
            `}
          >
            <div
              css={css`
                margin-right: 8px;
              `}
            >
              {result.fullName}{' '}
              {!enableGlobalSearch && result?.email ? `(${result?.email})` : ''}
            </div>
            {searchType === ENTITY_TYPES.player && result.birthDate && (
              <div
                css={css`
                  margin-top: 1px;
                  opacity: 0.7;
                  font-size: 14px;
                `}
              >
                ({result.birthDate})
              </div>
            )}
          </div>
          <div
            css={css`
              opacity: 0.6;
              font-size: 14px;
              margin-top: 4px;
            `}
          >
            {subText}
          </div>
        </div>
      </Flex>
      {ResultButton ? (
        <ResultButton result={result} />
      ) : result.permission?.update || result.isPublic ? (
        <FollowBtn
          id={id}
          initialValue={result.isFollowing}
          entityType={searchType}
        />
      ) : (
        false
      )}
    </Flex>
  )
}

SearchMenuResult.propTypes = {
  iconName: PropTypes.string,
  imageIsPortrait: PropTypes.bool,
  onClick: PropTypes.func,
  result: PropTypes.object.isRequired,
  ResultButton: PropTypes.func,
  searchType: PropTypes.string.isRequired,
  to: PropTypes.string,
}

export default SearchMenuResult
