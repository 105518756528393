import { call, put } from 'redux-saga/effects'

import req from '@sportninja/common/api/request'

const deleteApi = (entityType) => async (id, forceDelete) => {
  let query = {}
  if (forceDelete) query.force = '1'
  return await req(`/${entityType}/${id}`, { method: 'DELETE', query })
}

export const deleteEntityFactory = (entityType, action) => function* (payload) {
  const { forceDelete = false, id, parentId } = payload
  yield call(deleteApi(entityType), id, forceDelete)
  yield put(action.success({ id, parentId }))
}
