import { useState } from 'react'
import { saveAs } from 'file-saver'
import req from '@sportninja/common/api/request'

import ActionButton from './ActionButton'

const FileDownload = ({ button, fileName = 'export', label, url, query = {} }) => {
  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    try {
      setLoading(true)
      const response = await req(url, {
        method: 'GET',
        parseJSON: false,
        skipAutoContentType: true,
        headers: {
          Accept: 'text/csv',
        },
        query
      })
      const blob = await response.blob()
      saveAs(blob, `${fileName}.csv`)
    } catch (e) {
      console.error(e)
      alert(`Error downloading file. Please contact SportNinja support. Info: ${e && e?.message ? e?.message : 'Unknown error'}`)
    } finally {
      setLoading(false)
    }
  }

  return typeof button === 'function' ? (
    button(loading, onClick)
  ) : (
    <ActionButton
      busy={loading}
      icon='download'
      onClick={onClick}
      label={label}
      title={label}
    />
  )
}

export default FileDownload
