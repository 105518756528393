/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { t } from '@sportninja/common/i18n'
import req from '@sportninja/common/api/request'

import ImportCSVError from './ImportCSVError'
import ImportCSVBanner, { BANNER_TYPES } from './ImportCSVBanner'
import ImportCSVContentUploadForm from './ImportCSVContentUploadForm'
import ImportCSVTitle from './ImportCSVTitle'
import ImportCSVContainer from './ImportCSVContainer'
import colors from '@sportninja/common/constants/appColors'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'

const ImportCSVContent = ({
  entityType,
  id,
  importType,
  loading,
  setLoading,
  supportsXML = false,
  toggle,
  importTemplate,
  onSuccess = () => {},
  customOnSuccessMessage = null,
}) => {
  const [success, setSuccess] = useState(false)
  const [partialSuccess, setPartialSuccess] = useState(false)
  const [importError, setImportError] = useState(false)
  const [file, setFile] = useState(null)
  const [error, setError] = useState(false)
  const [errorCSV, setErrorCSV] = useState(false)
  const [clearRosterBeforeImport, setClearRosterBeforeImport] = useState(false)

  const hasImportErrors = Array.isArray(importError) && importError.length > 0

  useEffect(() => {
    return () => {
      setLoading(false)
      setSuccess(false)
      setPartialSuccess(false)
      setErrorCSV(false)
    }
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault()

    if (!file) return

    setError(false)
    setErrorCSV(false)
    setPartialSuccess(false)
    setLoading(true)

    const body = new FormData()
    body.append('file', file)
    body.append('conflicts', 'ignore')

    let endpointType
    if (/csv|xml/.test(file.type)) {
      endpointType = file.type === 'text/csv' ? 'csv' : 'xml'
    } else {
      const fileSplit = file.name.split('.')
      const extension = fileSplit[fileSplit.length - 1]
      endpointType = extension
    }

    try {
      const url =
        entityType === ENTITY_TYPES.gameSlots
          ? `/schedules/${id}/gameslots/import/csv`
          : `/${entityType}/${id}${
              importType ? `/${importType}` : ''
            }/import/${endpointType}`

      const response = await req(url, {
        body,
        method: 'POST',
        skipAutoContentType: true,
      })

      setSuccess(response)
      setLoading(false)
      onSuccess()
      setTimeout(toggle, 10000)
    } catch (e) {
      setFile(null)
      setLoading(false)
      // We specifically call the success handler here in the case of error, because
      // sometimes the API will report an error but still process an upload? I know.
      onSuccess()

      if (e.statusCode === 409) {
        setErrorCSV(e.file)
        setImportError(e.failed.errors)
        if (e.success.count > 0) {
          setPartialSuccess(e.success.count)
        }
      } else {
        console.log('error', e)
        setError(
          `There was a problem importing your file | ${
            e?.message || JSON.stringify(e)
          }`
        )
      }
    }
  }

  const onFileSelect = ({ target }) => {
    if (!target || !target.files || target.files.length === 0) return

    const file = target.files[0]

    if (
      /image\/svg\+xml/.test(file?.type) ||
      (!/csv|xml/.test(file?.type) && !/\.(csv|xml)$/.test(file?.name))
    ) {
      setError('Please select a CSV or XML file.')
      setTimeout(() => {
        setError(false)
      }, 5000)
      return
    }

    setError(false)
    setImportError(false)
    setPartialSuccess(false)
    setErrorCSV(false)
    setFile(file)
  }

  return (
    <ImportCSVContainer hasImportErrors={hasImportErrors}>
      <ImportCSVTitle
        importTemplate={importTemplate}
        success={success}
        onClose={toggle}
      />

      {typeof partialSuccess === 'number' && (
        <ImportCSVBanner
          type={BANNER_TYPES.success.type}
          message={`${partialSuccess} item${
            partialSuccess > 1 ? 's were' : ' was'
          } imported successfully.`}
        />
      )}

      {error && (
        <ImportCSVBanner type={BANNER_TYPES.error.type} message={error} />
      )}

      {hasImportErrors && (
        <>
          <ImportCSVBanner
            type={BANNER_TYPES.error.type}
            message={
              BANNER_TYPES.error.message +
              ` There are problems with ${importError.length} line${
                importError.length > 1 ? 's' : ''
              } in your import file.`
            }
          />
          {errorCSV && (
            <div
              css={css`
                display: flex;
                margin-bottom: 16px;
              `}
            >
              <a
                css={css`
                  border-radius: 4px;
                  border: 2px solid ${colors.DEFAULT_FLAIR};
                  padding: 8px 16px;
                `}
                href={`data:text/csv;charset=utf-8,${errorCSV.rows}`}
                download={errorCSV.name}
              >
                Download CSV with failed rows
              </a>
            </div>
          )}
          <ImportCSVError errors={importError} />
        </>
      )}

      {success ? (
        <>
          <ImportCSVBanner
            type={BANNER_TYPES.success.type}
            message={`${t('Web:fileUploadedSuccessfully')} ${
              customOnSuccessMessage
                ? customOnSuccessMessage
                : 'Dismissing this modal in 10 seconds...'
            }`}
          />
          <div
            css={css`
              border: 1px solid #3f4753;
              border-radius: 4px;
              padding: 10px;
            `}
          >
            {success?.success?.count} {importType} were imported.
          </div>
        </>
      ) : (
        <ImportCSVContentUploadForm
          onSubmit={onSubmit}
          loading={loading}
          supportsXML={supportsXML}
          onFileSelect={onFileSelect}
          resetFile={() => setFile(null)}
          file={file}
          importTemplate={importTemplate}
          error={error}
          hasImportErrors={hasImportErrors}
          clearRosterBeforeImport={clearRosterBeforeImport}
          setClearRosterBeforeImport={setClearRosterBeforeImport}
        />
      )}
    </ImportCSVContainer>
  )
}

ImportCSVContent.propTypes = {
  id: PropTypes.string.isRequired,
  importType: PropTypes.string,
  entityType: PropTypes.string,
  supportsXML: PropTypes.bool,
}

export default ImportCSVContent
