/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import deepEqual from 'fast-deep-equal'
import { useEffect, useRef, useState, useCallback } from 'react'
import { font } from '../../../components/css'
import Icon from '../../../components/Icon'

import { Flex } from '../../../components/Layout'
import { BAR_HEIGHT, POOL_TYPE } from './constants'
import GameInfoPopout from './GameInfoPopout'
import Slot from './Slot'
import { isCanlan } from '@sportninja/common/utils/customer-name'

const Pool = ({
  pool,
  roundNumber,
  roundType,
  selected,
  setSelected,
  stats,
  isLowerPool = false,
  shouldDisplayScoreInsteadOfPoints = false,
  hasLowerPool = false,
}) => {
  const popoutRef = useRef(null)
  const [isPopoutOpen, setIsPopoutOpen] = useState(false)
  const [isMouseOver, setIsMouseOver] = useState(false)

  useEffect(() => {
    const handler = (e) => {
      try {
        const element = popoutRef.current
        if (element instanceof HTMLElement) {
          if (
            element &&
            e?.target !== element &&
            !element?.contains(e?.target)
          ) {
            setIsPopoutOpen(false)
          }
        } else {
          setIsPopoutOpen(false)
        }
      } catch (e) {
        console.error(e)
        setIsPopoutOpen(false)
      }
    }

    if (isPopoutOpen) {
      setTimeout(() => {
        document.addEventListener('click', handler)
      }, 0)
    } else {
      document.removeEventListener('click', handler)
    }

    return () => {
      document.removeEventListener('click', handler)
    }
  }, [isPopoutOpen])

  const {
    id: poolId,
    name_full: poolName,
    children,
    gameSlots,
    pool_type_id,
    ref,
    teamSlots: { data: teams },
  } = pool

  const games = gameSlots?.data || []
  const advance = children?.data?.length

  const gameMap = new Map()
  const gameEndMap = new Map()

  games?.forEach((game) => {
    if (game?.homeTeamSlot?.id) {
      gameMap.set(game.homeTeamSlot.id, game.home_team_score)
      if (game.ended_at === null) {
        gameEndMap.set(game.homeTeamSlot.id, true)
      }
    }
    if (game?.visitingTeamSlot?.id) {
      gameMap.set(game.visitingTeamSlot.id, game.visiting_team_score)
      if (game.ended_at === null) {
        gameEndMap.set(game.visitingTeamSlot.id, true)
      }
    }
  })
  teams?.forEach((team) => {
    if (team?.id) {
      if (gameMap.has(team.id)) {
        team.score = gameMap.get(team.id)
      }
      if (gameEndMap.has(team.id)) {
        team.gameDidntEnd = true
      }
      if (team.team_id === null) {
        team.isMockTeam = true
      }
    }
  })
  const isMockPool = teams.some((team) => team.isMockTeam)

  const poolStats = stats[poolId]

  // Sort teams by number of points, descending
  const sorted =
    poolStats &&
    Object.keys(poolStats).sort((a, b) => {
      const aTeam = poolStats[a],
        bTeam = poolStats[b]
      const aPoints = aTeam.points,
        bPoints = bTeam.points
      if (aPoints > bPoints) return -1
      else if (aPoints < bPoints) return 1
      else return 0
    })

  // We've sorted the pool by points, so take the first index as our de facto
  // top scorer.
  // Second, verify there are no ties by checking for other entries that have the
  // same points.
  // Finally, if we have no ties, set this variable to the ID of the top scorer.
  let potentialTopScore = poolStats?.[sorted?.[0]]
  if (potentialTopScore) {
    const ties = sorted
      .slice(1)
      .find((teamId) => poolStats[teamId].points === potentialTopScore.points)
    if (ties) potentialTopScore = false
    else potentialTopScore = sorted?.[0]
  }

  const handleMouseEnter = useCallback(() => {
    setIsPopoutOpen(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setIsPopoutOpen(false)
  }, [])

  return (
    <Flex
      noFlex
      ref={ref}
      css={css`
        position: relative;
      `}
    >
      <GameInfoPopout
        ref={popoutRef}
        slots={games}
        isOpen={isPopoutOpen}
        setIsMouseOver={setIsMouseOver}
      >
        <Flex
          onClick={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          className='pool'
          column
          css={css`
            border: 1px solid ${colors.PALE_SKY};
            border-radius: 8px;
            margin-bottom: 44px;
          `}
        >
          <Flex
            className='pool-bar'
            alignItems='center'
            justifyContent='space-between'
            css={css`
              padding: 0 8px;
              font-size: 13px;
              background-color: ${selected?.roundNumber === roundNumber &&
              deepEqual(selected?.id, poolId)
                ? 'rgba(255, 255, 255, 0.1)'
                : isLowerPool
                ? `${colors.SLATE_GRAY};`
                : `${colors.HEADER_BG_2};`};
              cursor: pointer;
              min-height: ${BAR_HEIGHT}px;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            `}
            onClick={() => {
              if (selected?.id !== poolId) {
                setSelected({ type: 'pool', roundNumber, id: poolId })
              }
            }}
          >
            <Flex
              noFlex
              column
              justifyContent='center'
              css={css`
                gap: 2px;
              `}
            >
              {poolName && shouldDisplayScoreInsteadOfPoints && (
                <div
                  css={css`
                    font-weight: 700;
                    font-size: 16px;
                    font-family: ${isCanlan
                      ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                      : 'Rift, Arial, Helvetica, sans-serif'};
                  `}
                >
                  {poolName ? poolName : '-'}
                </div>
              )}
              {poolName && !shouldDisplayScoreInsteadOfPoints && (
                <div
                  css={css`
                    font-weight: 700;
                    font-size: 16px;
                    font-family: ${isCanlan
                      ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                      : 'Rift, Arial, Helvetica, sans-serif'};
                  `}
                >
                  {roundType === 1 ? poolName : '-'}
                </div>
              )}
              {isLowerPool
                ? shouldDisplayScoreInsteadOfPoints && (
                    <div
                      css={css`
                        font-weight: 500;
                        font-size: 10px;
                        margin-top: 2px;
                      `}
                    >
                      Lower Bracket
                    </div>
                  )
                : shouldDisplayScoreInsteadOfPoints &&
                  hasLowerPool && (
                    <div
                      css={css`
                        font-weight: 500;
                        font-size: 10px;
                        margin-top: 2px;
                      `}
                    >
                      Upper Bracket
                    </div>
                  )}
              {games.length > 0 && [1, 2].includes(pool_type_id) && (
                <div
                  css={css`
                    margin-top: 4px;
                    font-weight: 500;
                    font-size: 12px;
                  `}
                >
                  {/* If series */}
                  {pool_type_id === 1 && `${games.length}-Game `}
                  {[1, 2].includes(pool_type_id) &&
                    POOL_TYPE[pool_type_id]}{' '}
                </div>
              )}
            </Flex>

            <Flex noFlex column justifyContent='center' alignItems='flex-end'>
              <div
                className='games-button'
                css={css`
                  ${font.body}
                  font-weight: 500;
                  font-size: 14px;
                  display: flex;
                  align-items: center;
                  transform: translateY(-1px);

                  &:hover {
                    color: white;

                    i {
                      color: white;
                    }
                  }

                  &,
                  i {
                    color: ${colors.DEFAULT_FLAIR};
                  }

                  i {
                    margin-left: 6px;
                  }
                `}
                // onClick={(e) => {
                //   setIsPopoutOpen((o) => !o)
                // }}
              >
                Games <Icon name='caret-right' />
              </div>

              <div
                css={css`
                  margin-top: 1px;
                  font-size: 12px;
                  font-weight: 500;
                `}
              >
                {/* Not finals */}
                {roundType !== 4
                  ? `${advance} Advance${advance > 1 ? 's' : ''}`
                  : '1 Winner'}
              </div>
            </Flex>
          </Flex>
          <div
            css={css`
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
              overflow: hidden;
            `}
          >
            {teams.map((team) => {
              const teamStats = poolStats?.[team.team_id]

              return (
                <Slot
                  isMockPool={isMockPool}
                  shouldDisplayScoreInsteadOfPoints={
                    shouldDisplayScoreInsteadOfPoints
                  }
                  key={team.id}
                  team={team}
                  isTopScore={potentialTopScore === team.team_id}
                  selected={selected}
                  teamStats={teamStats}
                  onClick={() => {
                    const idToUse = team.team_id || team.id

                    if (selected?.id === idToUse) {
                      setSelected({})
                    } else {
                      setSelected({ type: 'slot', id: idToUse })
                    }
                  }}
                />
              )
            })}
          </div>
        </Flex>
      </GameInfoPopout>
    </Flex>
  )
}

export default Pool
