import { call, put } from 'redux-saga/effects'
import req from '@sportninja/common/api/request'

import { t } from '../../i18n'

const api = (entityType) => ({
  create: async (id, body) => await req(`/${entityType}/${id}/image`, { method: 'POST', body }),
  delete: async (id) => await req(`/${entityType}/${id}/image`, { method: 'DELETE' })
})

export default (entityType, actions) => ([
  [actions.create, function* (payload) {
    const { id, imageData } = payload
    try {
      const response = yield call(api(entityType).create, id, imageData)
      yield put(actions.create.success({ id, data: response.data }))
      return response
    } catch(e) {
      throw new Error(t('errors:problemUploadingImage'))
    }
  }],

  [actions.delete, function* (payload) {
    const { id } = payload
    const response = yield call(api(entityType).delete, id)
    yield put(actions.delete.success({ id }))
    return response
  }]
])
