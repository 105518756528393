/** @jsxImportSource @emotion/react */

import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import { Link } from 'react-router-dom'
import { ENTITY_TYPE_INFO } from '@sportninja/common/sagas/utils'
import { t } from '@sportninja/common/i18n'
import { ROUTES } from '@sportninja/common/constants/app'

import BannerController from '../Banner/BannerController'
import Banner from '../Banner'

const View = ({ onClick, to }) => (
  <Fragment>
    <Link
      onClick={onClick}
      to={to}
      css={css`
        padding-left: 4px;
        text-decoration: underline;
      `}
    >
      {t('common:view')}
    </Link>
    ?
  </Fragment>
)

View.propTypes = {
  onClick: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
}

const entityCreate = (entityType) => (response) => {
  if (!response) {
    return
  }
  BannerController.add(({ onClose, ...props }) => (
    <Banner onClose={onClose} {...props}>
      {t('Web:newXCreated', {
        x: t(`Web:${ENTITY_TYPE_INFO[entityType].fullName}`),
        interpolation: { escapeValue: false },
      })}
      : {response?.data?.name_full || response?.data?.name}.
      <View
        onClick={onClose}
        to={`${ENTITY_TYPE_INFO[entityType].linkRoot}/${response?.data?.id}`}
      />
      {response.error && (
        <div
          css={css`
            font-weight: bold;
            margin-top: 4px;
          `}
        >
          {response.error}
        </div>
      )}
    </Banner>
  ))
}

const entityUpdate =
  () =>
  (response = {}) => {
    const data = response.data || {}
    BannerController.add(({ ...props }) => (
      <Banner {...props}>
        {t('Web:xEditedSuccessfully', {
          x: data.name_full || data.name,
          interpolation: { escapeValue: false },
        })}
        .
      </Banner>
    ))
  }

const gameCreate =
  () =>
  ({ data }) => {
    BannerController.add(({ onClose, ...props }) => (
      <Banner onClose={onClose} {...props}>
        {t('Web:newXCreated', { x: t('common:game') })}: #
        {data.league_game_number}.
        <View onClick={onClose} to={`${ROUTES.GAME_ROOT}/${data.id}`} />
      </Banner>
    ))
  }

const gameUpdate =
  () =>
  ({ data }) => {
    BannerController.add(({ ...props }) => (
      <Banner {...props}>
        {t('Web:xEditedSuccessfully', {
          x: `${t('util:capitalize', { text: t('common:game') })} #${
            data.league_game_number
          }`,
        })}
        .
      </Banner>
    ))
  }

const passwordUpdate = () => () => {
  BannerController.add(({ ...props }) => (
    <Banner {...props}>
      {t('Web:xEditedSuccessfully', { x: t('common:password') })}.
    </Banner>
  ))
}

const userCreate =
  () =>
  ({ data }) => {
    BannerController.add(({ ...props }) => (
      <Banner {...props}>
        {t('Web:newXCreated', { x: t('common:user') })}: {data.name_first}{' '}
        {data.name_last}.
      </Banner>
    ))
  }

const userUpdate =
  () =>
  (response = {}) => {
    const data = response.data || {}
    BannerController.add(({ ...props }) => (
      <Banner {...props}>
        {t('Web:xEditedSuccessfully', {
          x: `${data.name_first} ${data.name_last}`,
        })}
        .
      </Banner>
    ))
  }

const playerCreate =
  () =>
  ({ data }) => {
    BannerController.add(({ ...props }) => (
      <Banner {...props}>
        {t('Web:newXCreated', { x: t('common:player') })}: {data.name_first}{' '}
        {data.name_last}.
      </Banner>
    ))
  }

const playerUpdate =
  () =>
  ({ data }) => {
    BannerController.add(({ ...props }) => (
      <Banner {...props}>
        {t('Web:xEditedSuccessfully', {
          x: `${data.name_first} ${data.name_last}`,
        })}
        .
      </Banner>
    ))
  }

const SelfUpdate = () => () => {
  BannerController.add(({ ...props }) => (
    <Banner {...props}>
      {t('Web:xEditedSuccessfully', {
        x: t('util:capitalize', { text: t('routeNames:profile') }),
      })}
      .
    </Banner>
  ))
}

const venueCreate = () => (response) => {
  const data = response.data || {}
  BannerController.add(({ ...props }) => (
    <Banner {...props}>
      {t('Web:newXCreated', {
        x: t('util:capitalize', { text: t('common:location') }),
      })}
      : {data.name_full}.
      {response.error && (
        <div
          css={css`
            font-weight: bold;
            margin-top: 4px;
          `}
        >
          {response.error}
        </div>
      )}
    </Banner>
  ))
}

const venueUpdate =
  () =>
  (response = {}) => {
    const data = response.data || {}
    BannerController.add(({ ...props }) => (
      <Banner {...props}>
        {t('Web:xEditedSuccessfully', {
          x: `${t('util:capitalize', { text: t('common:location') })} ${
            data.name_full
          }`,
        })}
        .
        {response.error && (
          <div
            css={css`
              font-weight: bold;
              margin-top: 4px;
            `}
          >
            {response.error}
          </div>
        )}
      </Banner>
    ))
  }

const scheduleCloned =
  (entityType) =>
  (response = {}) => {
    BannerController.add(({ onClose, ...props }) => (
      <Banner onClose={onClose} {...props}>
        {t('Web:newXCreated', { x: 'tournament' })}:{' '}
        {response.data.name_full || response.data.name}.
        <View
          onClick={onClose}
          to={`${ENTITY_TYPE_INFO[entityType].linkRoot}/${response.data.id}`}
        />
        {response.error && (
          <div
            css={css`
              font-weight: bold;
              margin-top: 4px;
            `}
          >
            {response.error}
          </div>
        )}
      </Banner>
    ))
  }

const teamOfficialCreate =
  () =>
  ({ data }) => {
    BannerController.add(({ ...props }) => (
      <Banner {...props}>
        {t('Web:newXCreated', { x: t('common:teamOfficial') })}:{' '}
        {data.name_first} {data.name_last}.
      </Banner>
    ))
  }

const teamOfficialUpdate =
  () =>
  (response = {}) => {
    const data = response.data || {}
    BannerController.add(({ ...props }) => (
      <Banner {...props}>
        {t('Web:xEditedSuccessfully', {
          x: `${data.name_first} ${data.name_last}`,
        })}
        .
      </Banner>
    ))
  }

const officialCreate =
  () =>
  ({ data }) => {
    BannerController.add(({ ...props }) => (
      <Banner {...props}>
        {t('Web:newXCreated', { x: t('common:official') })}: {data.name_first}{' '}
        {data.name_last}.
      </Banner>
    ))
  }

const officialUpdate =
  () =>
  (response = {}) => {
    const data = response.data || {}
    BannerController.add(({ ...props }) => (
      <Banner {...props}>
        {t('Web:xEditedSuccessfully', {
          x: `${data.name_first} ${data.name_last}`,
        })}
        .
      </Banner>
    ))
  }

export default {
  entityCreate,
  entityUpdate,
  gameCreate,
  gameUpdate,
  passwordUpdate,
  userCreate,
  userUpdate,
  playerCreate,
  playerUpdate,
  profile: SelfUpdate,
  venueCreate,
  venueUpdate,
  scheduleCloned,
  teamOfficialCreate,
  teamOfficialUpdate,
  officialCreate,
  officialUpdate,
}
