/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import PropTypes from 'prop-types'
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'

import { Flex } from '../Layout'
import { bannerCss, Timer } from './css'
import Icon from '../Icon'

const BANNER_TYPES = {
  success: 'success',
  failed: 'failed',
  warning: 'warning',
}

const Banner = ({
  children,
  duration,
  onClose,
  onClick,
  subChildren,
  type = BANNER_TYPES.success,
  ...props
}) => {
  const [timerShown, setTimerShown] = useState(true)

  return (
    <Flex
      className={type}
      justifyContent='center'
      css={bannerCss}
      onClick={(e) => {
        if (onClick) onClick(e)
        setTimerShown(false)
      }}
      {...props}
    >
      <Flex alignItems='center'>
        <Icon
          className='banner-icon'
          name={
            type === 'failed'
              ? 'times-circle'
              : type === 'warning'
              ? 'exclamation-triangle'
              : 'check-circle'
          }
          fontSize={20}
          color={
            type === 'failed'
              ? colors.ERROR
              : type === 'warning'
              ? colors.AMERICAN_YELLOW
              : colors.SUCCESS_GREEN
          }
        />
        <div>
          <div className='banner-title'>{type}</div>
          {children && <div className='banner-message'>{children}</div>}
        </div>
      </Flex>
      <Timer duration={duration} onClick={onClose}>
        <img
          alt='Close banner'
          src='/images/close.svg'
          title='Close banner'
          css={css`
            height: 14px;
            width: 14px;
            filter: brightness(0.7);
          `}
        />
        <svg
          css={css`
            transition: opacity 0.2s ease-in-out;
            opacity: ${timerShown ? 1 : 0};
          `}
        >
          <circle r='14' cx='15' cy='15'></circle>
        </svg>
      </Timer>
    </Flex>
  )
}

Banner.propTypes = {
  children: PropTypes.node.isRequired,
  duration: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
}

export default Banner
