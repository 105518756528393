/** @jsxImportSource @emotion/react */

import { Fragment, lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'

import CardContainer from '../Card/CardContainer'
import LoadingSpinner from '../LoadingSpinner'
import Picture from '../Picture'
import { backgroundColor, cardColor, font } from '../css'
import { Flex } from '../Layout'
import { SectionTitle, sectionAndSubTitleText } from '../Card/css'
import ActionPanel from '../ActionPanel'
import { Mobile } from '../Responsive'

const Map = lazy(() => import('./Map'))

const mapCss = css`
  & {
    flex: 1;
    border-radius: 4px; /* TODO: Fix this in Safari/iOS */
  }

  .leaflet-pane,
  .leaflet-top,
  .leaflet-control {
    z-index: unset;
  }

  .leaflet-marker-icon {
    font-size: 35px;
    color: ${backgroundColor};
  }

  .leaflet-bottom {
    display: none;
  }

  .leaflet-touch .leaflet-bar a {
    width: 25px;
    height: 25px;
    line-height: 20px;
  }
`

const textCss = css`
  position: absolute;
  right: 8px;
  bottom: 8px;
  left: 8px;

  padding: 12px;
  text-shadow: -1px -1px 5px black;

  height: 50%;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );

  h2 {
    color: white;
    ${font.title}
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1.15px;

    margin-bottom: 4px;
  }

  h3 {
    color: white;
    ${font.body}
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 18px;
  }

  h4 {
    color: white;
    ${font.body}
    font-size: 13px;
    letter-spacing: 0.5px;
    line-height: 16px;
  }
`

const MapCard = ({
  noTitle = false,
  venue,
  facility,
  onEdit,
  onUpdate,
  onUpdateOpen,
  onDelete,
  updateForm,
  updateTitle,
  title,
  disableMobileVersion,
}) => {
  if (!venue) return false

  const fallback = (
    <LoadingSpinner
      css={css`
        height: 100%;
        flex: 1;
        display: flex;
        align-items: center;
      `}
    />
  )

  return (
    <Mobile>
      {(isMobile) => (
        <Fragment>
          {isMobile && !disableMobileVersion && (
            <div css={sectionAndSubTitleText}>{title || 'Locations'}</div>
          )}
          <Flex
            column
            as='article'
            css={css`
              position: relative;
              background-color: ${cardColor};
              border-radius: 2px;
              margin: 16px 16px 16px 0;

              &:first-of-type {
                margin-left: 0;
              }
              min-width: 192px;
              max-width: 192px;
              min-height: 272px;
              margin-right: 16px;

              transition: box-shadow 0.2s ease-in-out,
                background-color 0.2s ease-in-out;

              &:hover {
                background-color: rgba(255, 255, 255, 0.1);
                box-shadow: rgb(0, 0, 0) 0px 4px 35px 0px;

                transition: box-shadow 0.05s ease-in-out,
                  background-color 0.05s ease-in-out;
              }
              padding: 8px;
              ${isMobile && !disableMobileVersion && 'margin-top: 20px;'}

              ${isMobile &&
              'min-width: 100%; max-width: 100%; min-height: 200px;'}
            `}
          >
            {!isMobile && !noTitle && (
              <SectionTitle>{title || 'Locations'}</SectionTitle>
            )}
            {venue.coords ? (
              <Suspense fallback={fallback}>
                <Map
                  css={mapCss}
                  position={venue.coords}
                  zoom={15}
                  scrollWheelZoom={false}
                />
              </Suspense>
            ) : (
              <Picture fill square iconName='map-marked-alt' />
            )}
            <Flex
              column
              justifyContent='flex-end'
              css={textCss}
              className='map-card-text'
            >
              <h2>{venue.fullName}</h2>
              {facility && <h3>{facility}</h3>}
              {facility ? (
                <h4>{venue.addressString}</h4>
              ) : (
                <h3>{venue.addressString}</h3>
              )}
              <ActionPanel
                noFollow
                onUpdate={onUpdate}
                onUpdateOpen={onUpdateOpen}
                onDelete={onDelete}
                onEdit={onEdit}
                updateForm={updateForm}
                updateTitle={updateTitle}
                customCss={css`
                  align-items: flex-end;
                  align-self: center;
                  min-height: 24px;
                  margin-bottom: -2px; /* Align buttons in MapCard with regular card */
                `}
              />
            </Flex>
          </Flex>
        </Fragment>
      )}
    </Mobile>
  )
}

MapCard.propTypes = {
  noTitle: PropTypes.bool,
  venue: PropTypes.shape({
    address: PropTypes.object.isRequired,
    coords: PropTypes.array.isRequired,
    title: PropTypes.string,
  }).isRequired,

  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  updateForm: PropTypes.object,
  updateTitle: PropTypes.string,
}

export default MapCard
