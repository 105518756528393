/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import { t } from '@sportninja/common/i18n'
import { useEffect, useState } from 'react'
import { FormButton } from '../../components/Form/css'
import { Flex } from '../../components/Layout'
import LoadingSpinner from '../../components/LoadingSpinner'
import { ROUTES } from '@sportninja/common/constants/app'
import { useHistory } from 'react-router-dom'
import { SwitchInput } from 'src/components/ts-components/SwitchInput'

const SignReport = ({ gameNumber, id }) => {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)
  const [download, setDownload] = useState(false)
  const [scoresheetUrl, setScoresheetUrl] = useState(null)
  const [showPlayersNumbers, setShowPlayersNumbers] = useState(true)
  const history = useHistory()

  useEffect(() => {
    const openScoresheet = async () => {
      try {
        setLoaded(false)
        const response = await req(
          `/games/${id}/signin/html?show_team_rosters=${
            showPlayersNumbers ? '1' : '0'
          }`,
          {
            parseJSON: false,
          }
        )
        if (!response || !response.url) {
          throw new Error(
            'An error occurred while opening the signin. Please contact SportNinja support.'
          )
        }
        setScoresheetUrl(response?.url)
      } catch (e) {
        setError(
          e?.message ||
            'An error occurred while opening the signin sheet. Contact SportNinja support.'
        )
      } finally {
        setLoaded(true)
      }
    }
    if (id) {
      openScoresheet()
    }
  }, [id, showPlayersNumbers])

  const handleOpen = async () => {
    try {
      setDownload(true)
      const response = await req(
        `/games/${id}/signin/pdf?show_team_rosters=${
          showPlayersNumbers ? '1' : '0'
        }`,
        {
          parseJSON: false,
        }
      )
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `Game #${gameNumber} signin.pdf`
      link.click()
    } catch (e) {
      setError(
        e?.message || 'An error occurred while downloading the signin sheet.'
      )
    } finally {
      setDownload(false)
    }
  }

  return (
    <Flex
      column
      alignItems='center'
      css={css`
        margin: 0 20px 20px;
        overflow: auto;
      `}
    >
      {error ? (
        <div
          css={css`
            text-align: center;
          `}
        >
          There was a problem displaying the sign-in sheet.
        </div>
      ) : loaded ? (
        <>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 16px;
              justify-content: center;
              align-items: center;
            `}
          >
            <Flex
              justifyContent='center'
              css={css`
                width: 300px;
                margin: 0 auto 20px;
                overflow: auto;
              `}
            >
              <FormButton
                disabled={download || !loaded}
                busy={download}
                type='button'
                onClick={async () => await handleOpen()}
                css={css`
                  background-image: none;
                  background-color: black;
                `}
              >
                {t('common:download')}
              </FormButton>
              <FormButton
                disabled={download || !loaded}
                // busy={download}
                type='button'
                onClick={async () => {
                  history.push({
                    pathname: ROUTES.SIGNIN_REPORTS_PRINT,
                    state: {
                      games: [id],
                      search: location.search,
                      isSoccer: false,
                      showRosters: showPlayersNumbers,
                    },
                  })
                }}
                css={css`
                  background-image: none;
                  background-color: black;
                `}
              >
                {t('common:print')}
              </FormButton>
            </Flex>
            <Flex
              justifyContent='center'
              css={css`
                width: 300px;
                margin: 0 auto 20px;
                overflow: auto;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  align-self: stretch;
                `}
              >
                <SwitchInput
                  title='Display Players/Team Officials'
                  subtitle='This will display the players and team officials on the sign-in sheet.'
                  value={showPlayersNumbers}
                  onChange={(value) => {
                    setShowPlayersNumbers(value)
                  }}
                />
              </div>
            </Flex>
          </div>
          <iframe
            style={{
              background: 'white',
              overflow: 'auto',
            }}
            height={1280}
            width={720}
            src={scoresheetUrl}
          />
        </>
      ) : (
        <LoadingSpinner />
      )}
    </Flex>
  )
}

export default SignReport
