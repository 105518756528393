/** @jsxImportSource @emotion/react */
import { Component, createRef, Fragment } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from '@emotion/styled/macro'
import isPropValid from '@emotion/is-prop-valid'
import throttle from 'lodash.throttle'

const Wrap = styled.div``

const TooltipText = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop) && !['x', 'y'].includes(prop),
})`
  position: fixed;
  opacity: ${(props) => (props.isShown ? 1 : 0)};
  pointer-events: ${(props) => (props.isShown ? 'auto' : 'none')};
  transition: opacity 0.1s ease-in-out;

  color: white;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 8px;

  word-break: normal;
  text-align: center;
  min-width: 56px;
  max-width: 128px;
  padding: 8px;

  top: ${(props) => props.y - props.tipHeight - 6 - 16}px;
  left: ${(props) => props.x - props.tipWidth / 2}px;

  z-index: 9999;

  :after {
    border-top-color: rgba(0, 0, 0, 0.4);
    border-top-style: solid;
    border-top-width: 6px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -6px;
    left: 50%;
    margin-left: -8px;
    content: '';
    width: 0;
    height: 0;
    position: absolute;
  }
`

class Tooltip extends Component {
  state = {
    childTop: 0,
    isShown: false,
    x: 0,
    y: 0,
    tipWidth: 0,
    tipHeight: 0,
  }

  childRef = createRef()
  tipRef = createRef()
  root = document.getElementById('tooltip-root')

  componentDidMount() {
    const box = this.childRef.current.getBoundingClientRect()
    this.setState({ childTop: box.top })
  }

  componentDidUpdate(_, prevState) {
    if (this.state.isShown && !prevState.isShown) {
      const tip = this.tipRef.current.getBoundingClientRect()
      this.setState({ tipHeight: tip.height, tipWidth: tip.width })
    }
  }

  setCoords = throttle((x, y) => {
    this.setState({ x, y })
  }, 25)

  onMouseMove = (e) => {
    this.setCoords(e.clientX, e.clientY)
  }

  onMouseEnter = () => {
    this.setState({ isShown: true })
    document.addEventListener('mousemove', this.onMouseMove)
  }

  onMouseLeave = () => {
    this.setState({ isShown: false })
    document.removeEventListener('mousemove', this.onMouseMove)
  }

  getContent = (className) => {
    return (
      <TooltipText
        childTop={this.state.childTop}
        css={className}
        isShown={this.state.isShown}
        x={this.state.x}
        y={this.state.y}
        ref={this.tipRef}
        tipWidth={this.state.tipWidth}
        tipHeight={this.state.tipHeight}
      >
        {this.props.text}
      </TooltipText>
    )
  }

  render() {
    const { component, children, className, onClick, textClassName } =
      this.props

    return (
      <Fragment>
        <Wrap
          as={component ? component : undefined}
          className={className}
          onClick={onClick}
        >
          <div
            className='trigger-container'
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            ref={this.childRef}
          >
            {children}
          </div>
        </Wrap>
        {ReactDOM.createPortal(this.getContent(textClassName), this.root)}
      </Fragment>
    )
  }
}

Tooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.string,
  onClick: PropTypes.func,
  textClassName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  text: PropTypes.string.isRequired,
}

export default Tooltip
