/** @jsxImportSource @emotion/react */
// @ts-nocheck
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { Spin, Input as AntInput, Checkbox, Alert, Space } from 'antd'
import { observer } from 'mobx-react-lite'
import { Suspense, useEffect, useMemo, useRef, useState } from 'react'
import { NewModal } from 'src/components/NewModal'
import { useMobxStore } from 'src/state'
import { set, useForm } from 'react-hook-form'
import type { SearchProps } from 'antd/es/input/Search'
import DaySmartLogo from './daysmartlogo.png'
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { Input } from 'src/components/Input'
import ToggleSection from '../Schedule/EditRegistration/ToggleSection'
import { Button } from 'src/components/Button'
import { getErrorMessage } from '@sportninja/common/utils/utils'

const { Search } = AntInput

export const Integrations = observer(({ setCurrentPage }) => {
  const {
    me: {
      state: { account },
      fetchMe,
    },
  } = useMobxStore()
  // const isUltimateAccount = useMemo(
  //   () => account?.subscription_type?.id === 3,
  //   [account]
  // )
  const isUltimateAccount = true

  const {
    control,
    setValue: setFormValue,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {},
  })

  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingTest, setIsLoadingTest] = useState(false)
  const [testSuccess, setTestSuccess] = useState(false)
  const [testSubmitted, setTestSubmitted] = useState(false)
  const [apiKey, setApiKey] = useState('')
  const [apiKeyError, setApiKeyError] = useState('')
  const [secretKey, setSecretKey] = useState('')
  const [secretKeyError, setSecretKeyError] = useState('')
  const [error, setError] = useState('')

  const integrationModalRef = useRef(null)

  const onSearch: SearchProps['onSearch'] = (value, _e, info) =>
    console.log(info?.source, value)

  useEffect(() => {
    const fetchKeys = async () => {
      try {
        const result = await req(`/account/${account?.uid}/daysmart-api-keys`)
        const { data } = result
        const { api_key, api_secret } = data
        if (!api_key || !api_secret) {
          return
        }
        setFormValue('daysmart_api_key', api_key)
        setFormValue('daysmart_secret_key', api_secret)
        setApiKey(api_key)
        setSecretKey(api_secret)
        setAcceptedTerms(true)
      } catch (e) {
        const errorMessage = getErrorMessage(e)
        setError(errorMessage)
      }
    }
    if (account?.uid) {
      fetchKeys()
    }
  }, [account?.uid])

  const setCustomErrors = (invalid_fields: any) => {
    if (invalid_fields.api_secret) {
      setSecretKeyError(invalid_fields.api_secret)
    }
    if (invalid_fields.api_key) {
      setApiKeyError(invalid_fields.api_key)
    }
  }

  const onPressTest = async () => {
    try {
      setError('')
      setIsLoadingTest(true)
      const result = await req(
        `/account/${account?.uid}/daysmart-authentication-test`,
        {
          method: 'POST',
          body: JSON.stringify({
            api_key: apiKey,
            api_secret: secretKey,
          }),
        }
      )
      setTestSuccess(true)
      setTestSubmitted(true)
    } catch (e) {
      if (e?.invalid_fields) {
        setCustomErrors(e.invalid_fields)
        return
      }

      const errorMessage = getErrorMessage(e)
      console.log(e)
      console.log(errorMessage)
      setError(errorMessage)
    } finally {
      setIsLoadingTest(false)
    }
  }

  const onPressSubmit = async () => {
    try {
      setError('')
      setIsLoading(true)
      const result = await req(`/account/${account?.uid}/daysmart-api-keys`, {
        method: 'POST',
        body: JSON.stringify({
          api_key: apiKey,
          api_secret: secretKey,
        }),
      })
      fetchMe()
      integrationModalRef?.current?.closeModal()
      setTestSuccess(false)
      setTestSubmitted(false)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setError(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    watch((value, { name, type }) => {
      if (name === 'daysmart_api_key') {
        setApiKey(value?.daysmart_api_key)
      }
      if (name === 'daysmart_secret_key') {
        setSecretKey(value?.daysmart_secret_key)
      }
    })
  }, [watch])

  return (
    <>
      <div
        css={css`
          background-color: ${colors.HEADER};
          padding: 16px;
          border-radius: 8px;
          border: 1px solid ${colors.SOFT_STEEL};
          max-width: 820px;
          margin: 0 auto;
          margin-top: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <div>
            <p
              css={css`
                font-family: 'Rift', sans-serif;
                font-size: 24px;
                font-weight: 700;
              `}
            >
              Integrations
            </p>
            <p
              css={css`
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 12px */
                margin-top: 8px;
                color: ${colors.ATTENDANCE_GRAY};
              `}
            >
              Connect with our partners and enhance your experience.
            </p>
          </div>
          {/* <Search
            placeholder='Search'
            allowClear
            onSearch={onSearch}
            style={{ width: 200 }}
          /> */}
        </div>
        <Suspense fallback={<Spin />}>
          <div
            css={css`
              margin-top: 16px;
              padding-top: 16px;
              border-top: 1px solid ${colors.SOFT_STEEL};
              * {
                border-color: ${colors.SOFT_STEEL} !important;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: space-between;
              `}
            >
              <div>
                <p
                  css={css`
                    font-family: 'Rift', sans-serif;
                    font-size: 24px;
                    font-weight: 700;
                  `}
                >
                  Schedule
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                if (!isUltimateAccount) {
                  setCurrentPage(1)
                  return
                }
                integrationModalRef?.current?.openModal()
              }}
              css={css`
                display: flex;
                padding: 16px;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
                border-radius: 4px;
                border: ${apiKey
                  ? '1px dashed var(--Neutrals-Gray60, #3f4753)'
                  : '1px solid var(--Neutrals-Gray60, #3f4753)'};
                margin-top: 16px;
                width: 50%;
                gap: 16px;
                cursor: ${isUltimateAccount ? 'pointer' : 'pointer'};
                background-color: ${'transparent'};
                &:hover {
                  background-color: #323a48; // change this to the color you want
                }
              `}
            >
              <div
                css={css`
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  align-items: flex-start;
                `}
              >
                <img
                  src={DaySmartLogo}
                  alt='placeholder'
                  css={css`
                    width: 180px;
                  `}
                />
                <span
                  css={css`
                    color: ${isUltimateAccount ? '#F3F3F4' : '#f2b400'};
                    text-align: right;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 100%; /* 12px */
                  `}
                >
                  Included in Ultimate
                </span>
              </div>
              {!account?.is_daysmart_connected ? (
                <span
                  css={css`
                    align-self: stretch;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 120%; /* 14.4px */
                    width: 100%;
                  `}
                >
                  Sync or import an entire season’s data, update game schedules,
                  and manage player info and team rosters.
                </span>
              ) : (
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                  `}
                >
                  <span
                    css={css`
                      color: var(--Foundation-Secondary-secondary-25, #a6aab0);
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 145%;
                    `}
                  >
                    Account Details
                  </span>
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                    `}
                  >
                    <span
                      css={css`
                        color: #fff;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 145%; /* 20.3px */
                      `}
                    >
                      API Key:
                    </span>
                    <span
                      css={css`
                        color: #fff;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 145%; /* 20.3px */
                      `}
                    >
                      {apiKey}
                    </span>
                  </div>
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                    `}
                  >
                    <span
                      css={css`
                        color: #fff;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 145%; /* 20.3px */
                      `}
                    >
                      API Secret:
                    </span>
                    <span
                      css={css`
                        color: #fff;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 145%; /* 20.3px */
                      `}
                    >
                      {secretKey.slice(0, 5) + '*****'}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Suspense>
      </div>
      <NewModal ref={integrationModalRef} shouldCloseOnOverlayClick={true}>
        <div
          css={css`
            width: 45vw;
            background: #26303e;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            transition: all 0.1s ease-in-out;
            border-radius: 8px;
            padding-top: 16px;
          `}
        >
          <button
            onClick={() => {
              integrationModalRef?.current?.closeModal()
            }}
            css={css`
              position: fixed;
              right: 40px;
              top: 40px;
              background: none;
              border: none;
            `}
          >
            <CloseOutlined
              fontSize={20}
              style={{
                color: colors.WHITE,
                fontSize: 20,
              }}
            />
          </button>
          <button
            onClick={() => {
              window.open(
                'https://sportninja.zendesk.com/hc/en-us/articles/25378814243988-DaySmart-Integration-NEW',
                '_blank'
              )
            }}
            css={css`
              position: fixed;
              right: 70px;
              top: 40px;
              background: none;
              border: none;
            `}
          >
            <QuestionCircleOutlined
              fontSize={20}
              style={{
                color: colors.WHITE,
                fontSize: 20,
              }}
            />
          </button>
          <div
            css={css`
              padding-left: 16px;
              padding-right: 16px;
              padding-bottom: 16px;
              display: flex;
              flex: 1;
              flex-direction: column;
              height: 100%;
              overflow-y: auto;
              max-height: 550px;
            `}
          >
            {/* Title */}
            <div
              css={css`
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                align-items: center;
              `}
            >
              <p
                css={css`
                  font-size: 36px;
                  font-family: ${isCanlan
                    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                    : 'Rift, Arial, Helvetica, sans-serif'};
                  font-weight: 700;
                `}
              >
                {'DaySmart Integration'}
              </p>
            </div>
            <div
              css={css`
                border-bottom: 1px solid ${colors.SOFT_STEEL};
                margin-bottom: 16px;
                margin-top: 16px;
              `}
            />
            <div
              css={css`
                gap: 16px;
                display: flex;
                flex-direction: column;
              `}
            >
              <p
                css={css`
                  color: var(--Neutrals-neutrals-0, #fff);
                  font-size: 18px;
                  font-weight: 500;
                  line-height: 145%; /* 26.1px */
                `}
              >
                Please enter your credentials before proceeding:
              </p>
              <Input
                fieldName='daysmart_api_key'
                control={control}
                label='DaySmart API Key *'
                required={false}
                invalidMessage={apiKeyError}
                invalid={apiKeyError}
                autoComplete='off'
              />
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                `}
              >
                <Input
                  fieldName='daysmart_secret_key'
                  control={control}
                  label='API Secret *'
                  required={false}
                  type='password'
                  invalidMessage={secretKeyError}
                  invalid={secretKeyError}
                  autoComplete='off'
                />
                <p
                  css={css`
                    color: var(--Foundation-Primary-primary-300, #f2b400);
                    text-align: right;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 145%; /* 17.4px */
                    cursor: pointer;
                  `}
                  onClick={() => {
                    window.open(
                      'https://sportninja.zendesk.com/hc/en-us/articles/25659641630484-Creating-a-DaySmart-API-Key',
                      '_blank'
                    )
                  }}
                >
                  Where to find this in DaySmart?
                </p>
              </div>
              <div
                css={css`
                  border-bottom: 1px solid ${colors.SOFT_STEEL};
                `}
              />
              <Checkbox
                onChange={() => setAcceptedTerms(!acceptedTerms)}
                checked={acceptedTerms}
              >
                By proceeding with this process, you agree to the{' '}
                <a
                  href='https://www.sportninja.com/integration-terms'
                  target='_blank'
                  rel='noopener noreferrer'
                  css={css`
                    color: #f2b400;
                  `}
                >
                  Terms and Conditions
                </a>{' '}
                of the DaySmart Integration.
              </Checkbox>

              <div
                css={css`
                  margin-top: 20px;
                  align-self: flex-end;
                  width: 100%;
                  position: sticky;
                  bottom: 0;
                  background-color: ${colors.HEADER};
                  z-index: 100;
                  padding-top: 16px;
                `}
              >
                {testSubmitted ? (
                  <Space
                    direction='vertical'
                    style={{ width: '100%', marginBottom: 16 }}
                  >
                    <Alert
                      message={
                        'API key validated. You can now connect your account.'
                      }
                      type='info'
                      showIcon
                      closable
                    />
                  </Space>
                ) : null}
                {error ? (
                  <Space
                    direction='vertical'
                    style={{ width: '100%', marginBottom: 16 }}
                  >
                    <Alert message={error} type='error' showIcon closable />
                  </Space>
                ) : null}
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    width: 100%;
                  `}
                >
                  <Button
                    onClick={(e) => {
                      e.preventDefault()
                      integrationModalRef?.current?.closeModal()
                    }}
                    label={'Cancel'}
                    variant='secondary'
                    disabled={isLoading || isLoadingTest}
                  />
                  <Button
                    onClick={async () => {
                      await onPressTest()
                    }}
                    label={'Test'}
                    variant='secondary'
                    isLoading={isLoadingTest}
                    disabled={isLoading || isLoadingTest || !acceptedTerms}
                  />
                  <Button
                    onClick={async () => {
                      await onPressSubmit()
                    }}
                    type='submit'
                    label='Connect'
                    isLoading={isLoading}
                    disabled={isLoading || !acceptedTerms || !testSuccess}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </NewModal>
    </>
  )
})
