/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Flex } from '../Layout'
import Picture from '../Picture'
import { font } from '../css'

export const InitialsThumbnail = ({ children, size = 44, wrapCss }) => {
  return (
    <div
      className='initials-thumbnail'
      css={css`
        height: ${size}px;
        min-height: ${size}px;
        width: ${size}px;
        min-width: ${size}px;
        border-radius: 50%;
        border: 1px solid white;
        display: flex;
        align-items: center;
        justify-content: center;
        ${font.title};
        font-size: ${size * 0.4}px;
        font-weight: bold;
        letter-spacing: 1.24px;
        padding-left: 2px;
        ${wrapCss}
      `}
    >
      {children}
    </div>
  )
}

const ThumbnailAndName = ({
  subTitle,
  row = {},
  fit = true,
  iconName,
  initialsSize,
  square = true,
  size = 'xsmall',
  to,
  useInitials = false,
  textClassName,
}) => {
  return (
    <Flex alignItems='center' as={to ? Link : undefined} to={to}>
      {useInitials && !row.imageId ? (
        <InitialsThumbnail size={initialsSize}>
          {row.nameFirst?.charAt(0)}
          {row.nameLast?.charAt(0)}
        </InitialsThumbnail>
      ) : (
        <Picture
          size={size}
          square={square}
          fit={fit}
          iconName={row.isTournament ? 'trophy' : iconName}
          imageId={row.imageId}
          className={useInitials ? 'player-portrait' : ''}
        />
      )}
      <div
        className='thumbnail-and-name-text-wrap'
        css={css`
          margin-left: 12px;
        `}
      >
        <div className={textClassName}>{row.fullName}</div>
        {subTitle}
      </div>
    </Flex>
  )
}

ThumbnailAndName.propTypes = {
  fit: PropTypes.bool,
  iconName: PropTypes.string,
  row: PropTypes.shape({
    imageId: PropTypes.string,
    fullName: PropTypes.string.isRequired,
  }),
  square: PropTypes.bool,
  subTitle: PropTypes.node,
  to: PropTypes.string,
}

export const CircleThumbnailAndName = ({ ...props }) => (
  <ThumbnailAndName square={false} fit={false} useInitials {...props} />
)

export default ThumbnailAndName
