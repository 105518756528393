import styled from '@emotion/styled/macro'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'

import { Flex } from '../Layout'
import { ActivePageIndicator } from '../MenuLink'
import { font, zIndex } from '../css'
import { media } from '../Responsive'

export const Header = styled(Flex)`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  height: ${(props) => props.menuHeight}px;
  border-bottom: 1px solid ${colors.SOFT_STEEL};

  padding: 0 24px;

  z-index: ${zIndex.header};

  ${media.mobile} {
    height: 48px;
    padding: 0 16px;
    flex-direction: row;
  }

  /* We need this set, otherwise body content will appear underneath as we scroll */
  background-color: ${colors.HEADER};

  ${ActivePageIndicator} {
    bottom: ${isCanlan
      ? -17
      : -16}px; /* The indicator is misaligned here for some reason */
  }

  .nav {
    margin-left: 24px;

    .page-menu-nav-link {
      transition: 0.1s ease-in-out;
    }

    .page-menu-nav-link.active {
      color: ${colors.DEFAULT_FLAIR};
      font-weight: 600;
    }

    opacity: 1;

    transition: opacity 0.2s ease-in-out;

    &.search-active {
      opacity: 0;
      pointer-events: none;
    }
  }

  .main-nav-search-container {
    align-items: center;
    justify-content: center;
    background-color: ${colors.HEADER};
    position: relative;
    height: 48px;
    width: 300px;
    margin-left: 24px;
    border-bottom: 1px solid ${colors.SOFT_STEEL};

    display: flex;
    opacity: 0;
    position: absolute;
    left: 64px;
    top: 2px;
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;

    &.is-filter-active {
      width: 449px;
    }

    &.is-filter-active-canlan {
      width: 520px;
    }

    &.active {
      opacity: 1;
      pointer-events: all;
      z-index: ${zIndex.overlay + 20};
    }

    &.is-team-or-schedule {
      height: 62px;
    }

    .mobile-search-close-btn {
      display: none;
    }

    ${media.mobile} {
      flex: 1;
      height: 46px !important;
      margin-left: 0;
      left: 0;
      width: 100%;
      padding-right: 16px;

      .mobile-search-close-btn {
        display: flex;
      }

      &.is-filter-active {
        width: 100%;
      }
    }
  }

  .main-nav-search-input {
    width: 100%;
    height: 32px;
    padding: 0 12px 0 40px;
    border: ${isCanlan ? 2 : 1}px solid ${colors.SOFT_STEEL};
    border-radius: ${isCanlan ? 0 : 8}px;

    ${font.body}
    font-size: 16px;
    color: white;

    &:active,
    &:focus,
    &:hover {
      border-color: white;

      & ~ button i {
        color: white;
      }
    }

    &:focus {
      border-color: ${colors.DEFAULT_FLAIR};
    }

    &::placeholder {
      color: ${colors.ATTENDANCE_GRAY};
      opacity: 1;
    }

    ${media.mobile} {
      border-width: 0;
    }
  }

  .search-filter-container {
    ${media.mobile} {
      max-width: 126px;
      select {
        padding: 0 20px 0 6px;
      }

      .search-filter-icon {
        right: 6px;
      }
    }
  }
`

export const Logo = styled.div`
  transform: translateY(2px);
`

export const SiteButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  padding: 8px;
  margin-left: -8px;
`

export const Wrap = styled.div`
  position: relative;
  max-width: 1600px;
  height: ${(props) => props.menuHeight}px;

  ${media.mobile} {
    height: 48px;
  }
`
