import styled from '@emotion/styled/macro'
import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './index.scss'
import { inputCss } from '../Form/css'

const StyledInput = styled.input`
  ${inputCss}
`

const LabeledInput = ({
  className,
  children,
  error,
  label,
  right,
  type = 'text',
  ...inputProps
}) => {
  return (
    <label className={cn('labeled-input flex flex-dir-col', { 'is--disabled': inputProps.disabled }, className)}>
      {label && <span className='labeled-input-label'>{label}</span>}
      <div className='labeled-input-wrap'>
        {children ?
          children
          :
          <StyledInput hasError={error} type={type} {...inputProps} />
        }
        {right && <span className='right'>{right}</span>}
      </div>
      {error && error.length > 0 && <span className='labeled-input-error is--error'>{error}</span>}
    </label>
  )
}

LabeledInput.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  right: PropTypes.node,
  type: PropTypes.string,
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

export default LabeledInput
