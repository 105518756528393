import { t } from '@sportninja/common/i18n'
import React from 'react'

import ActionButton from '../ActionButton'
import OptionButtonDelete from '../List/OptionButtons/Delete'

const EntityFormDelete = ({ entityType, itemId, title, type, onComplete }) => {
  return (
    <OptionButtonDelete
      Button={({ onClick }) => (
        <ActionButton
          alwaysShowLabel
          icon='trash'
          title={t('Web:permanentlyDelete')}
          label={t('common:delete')}
          onClick={onClick}
        />
      )}
      entityType={entityType}
      itemId={itemId}
      title={title}
      type={type}
      onComplete={onComplete}
      useForceDelete
      unmountOnClose
    />
  )
}

export default EntityFormDelete
