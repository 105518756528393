import actions from '../actions/appError'
import { put } from 'redux-saga/effects'
import { generateSagas } from './utils'

const appErrors = [
  [
    actions.setError,
    function* ({ error }) {
      try {
        yield put(actions.setError.success({ error: error }))
        return error
      } catch (e) {
        return e
      }
    },
  ],
]

export default generateSagas([...appErrors])
