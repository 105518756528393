/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { connect } from 'react-redux'
import { t } from '@sportninja/common/i18n'

import PageMenu from './PageMenu'
import PageTitle from './PageTitle'
import Helmet from './Helmet'
import LoadingSpinner from './LoadingSpinner'
import { Container, Column } from './Layout'
import Icon from './Icon'

// List of sub-pages allowed in iframe mode
const IFRAME_PAGE_FILTER = [
  'bracket',
  'schedule',
  'standings',
  'statistics',
  'teams',
  'details',
  'home roster',
  'visitor roster',
  'timeline',
  'scoresheet',
  'sub orgs',
  'competitions',
  'roster',

  // Tricksy Canlan translation values...
  'sub leagues',
  'seasons',
]

const PageWrap = ({
  actions,
  bottomTitle,
  children,
  className,
  error,
  extraOffset,
  inFrame,
  isPrivate,
  loaded,
  pages: _pages,
  pageHeader,
  picture,
  pictureSettings,
  secondPicture,
  showPicturePlaceholder,
  title,
  topTitle,
  navigationDisabled,
}) => {
  const privateEntityError =
    (error && error.status === 403) ||
    (error && error.status !== 404 && isPrivate)

  let titleString = title || `${t('common:loading')}...`
  if (error && privateEntityError && !title) {
    titleString = t('Web:private')
  }

  const pages = inFrame
    ? _pages.filter((p) => {
        return !p.isOrange && IFRAME_PAGE_FILTER.includes(p.title.toLowerCase())
      })
    : _pages

  return (
    <div className={className}>
      {error && !privateEntityError ? (
        <Container>
          <Helmet
            title={
              (error && error.status && `HTTP ${error.status}`) ||
              t('common:error')
            }
          />
          <Column
            fullWidth
            justifyContent='center'
            alignItems='center'
            style={{ paddingTop: 40, wordBreak: 'break-word' }}
          >
            {error.message}
          </Column>
        </Container>
      ) : (
        <Fragment>
          {titleString && <Helmet title={titleString} />}
          {pageHeader || (
            <PageTitle
              actions={inFrame ? [] : actions}
              bottomTitle={bottomTitle}
              picture={picture}
              pictureSettings={pictureSettings}
              secondPicture={secondPicture}
              showPicturePlaceholder={showPicturePlaceholder}
              title={titleString}
              topTitle={topTitle}
            />
          )}
          {pages && (
            <PageMenu
              inFrame={inFrame}
              extraOffset={extraOffset}
              pages={pages}
              disabled={privateEntityError || navigationDisabled}
            />
          )}
          {loaded || error ? (
            privateEntityError ? (
              <div
                css={css`
                  text-align: center;
                `}
              >
                <Icon
                  name='lock'
                  color='rgba(255, 255, 255, 0.14)'
                  fontSize={100}
                />
                <p
                  css={css`
                    font-size: 16px;
                    margin-top: 16px;
                  `}
                >
                  {t('Web:thisPageIsPrivate')}
                </p>
              </div>
            ) : (
              children
            )
          ) : (
            <LoadingSpinner />
          )}
        </Fragment>
      )}
    </div>
  )
}

PageWrap.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  bottomTitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.node.isRequired,
  css: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  extraOffset: PropTypes.number,
  inFrame: PropTypes.bool,
  isPrivate: PropTypes.bool,
  loaded: PropTypes.bool.isRequired,
  pages: PropTypes.array,
  pageHeader: PropTypes.node,
  pageTitle: PropTypes.string,
  picture: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  pictureSettings: PropTypes.object, // Settings for the Picture component
  secondPicture: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  showPicturePlaceholder: PropTypes.bool,
  title: PropTypes.string,
  topTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

const mapStateToProps = (state) => {
  return {
    inFrame: state.auth.inFrame,
  }
}

export default connect(mapStateToProps)(PageWrap)
