/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import dayjs from 'dayjs'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { ROUTES } from '@sportninja/common/constants/app'
import { Link } from 'react-router-dom'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import Linkify from 'react-linkify'

import { Flex } from '../Layout'
import Picture from '../Picture'
import { InitialsThumbnail } from '../ThumbnailAndName'

const getInitials = (name = '') => {
  const [first = '', last = ''] = name.split(' ')
  return [first.charAt(0), last.charAt(0)]
}

const ChatMessage = ({
  message,
  loggedInUserId,
  previousUserId,
  nextUserId,
}) => {
  const isSelf = loggedInUserId === message.user_id
  const isSameUserAsBefore = previousUserId === message.user_id
  const isSameUserAsNext = nextUserId === message.user_id
  const isPlayer =
    typeof message?.player_id === 'string' && message.player_id.length > 0
  const [firstInitial, lastInitial] = getInitials(message?.user?.name)

  return (
    <Flex
      noFlex
      flexDirection={isSelf ? 'row-reverse' : 'row'}
      css={css`
        margin-top: ${isSameUserAsBefore ? 3 : 10}px;
      `}
    >
      <div>
        {!isSameUserAsBefore && !isSelf && (
          <div
            css={css`
              text-align: left;
              font-size: 11px;
              color: ${colors.ATTENDANCE_GRAY};
              margin-bottom: 4px;
              margin-left: 42px;
            `}
          >
            {message?.user?.name}
          </div>
        )}
        <Flex alignItems='flex-end'>
          {!isSelf && !isSameUserAsNext && (
            <Flex
              as={isPlayer ? Link : undefined}
              to={
                isPlayer
                  ? `${ROUTES.PLAYER_ROOT}/${message.player_id}`
                  : undefined
              }
              css={
                isPlayer &&
                css`
                  &:hover .initials-thumbnail {
                    border-color: ${colors.DEFAULT_FLAIR};
                    color: ${colors.DEFAULT_FLAIR};
                  }
                `
              }
            >
              {message?.user?.image?.filename ? (
                <Picture
                  size='kxxsmall'
                  imageId={getImageThumbnailId(message?.user)}
                />
              ) : (
                <InitialsThumbnail size={30}>
                  {firstInitial}
                  {lastInitial}
                </InitialsThumbnail>
              )}
            </Flex>
          )}
          <div
            css={css`
              min-width: 108px;
              max-width: 200px;
              padding: 8px 12px;
              border-radius: 12px;

              ${isSameUserAsBefore &&
              (isSelf
                ? 'border-top-right-radius: 4px;'
                : 'border-top-left-radius: 4px;')}

              ${isSameUserAsNext &&
              (isSelf
                ? 'border-bottom-right-radius: 4px;'
                : 'border-bottom-left-radius: 4px;')}

              background-color: ${isSelf
                ? isCanlan
                  ? '#113EEE'
                  : colors.AMERICAN_YELLOW
                : colors.HEADER};

              margin-left: ${!isSelf && !isSameUserAsNext ? 6 : 36}px;
            `}
          >
            <div
              css={css`
                color: ${!isSelf || isCanlan ? 'white' : colors.HEADER};
                letter-spacing: 1px;
                line-height: 16px;
                text-align: left;
                word-break: break-word;

                a {
                  text-decoration: underline;
                  color: ${!isSelf || isCanlan ? 'white' : colors.HEADER};
                }
              `}
            >
              {message?.image_url ? (
                <img
                  src={message?.image_url}
                  alt='chat-image'
                  css={css`
                    max-width: 100%;
                    max-height: 200px;
                  `}
                />
              ) : (
                <Linkify
                  properties={{
                    target: '_blank',
                    rel: 'noopener noreferrer',
                  }}
                >
                  {message?.message}
                </Linkify>
              )}
            </div>
            <div
              css={css`
                font-size: 11px;
                color: ${isSelf
                  ? isCanlan
                    ? '#38d1ff'
                    : '#ac590b'
                  : colors.ATTENDANCE_GRAY};
                text-align: right;
                margin-top: 8px;
              `}
            >
              {dayjs.utc(message?.created_at).local().format('h:mm a')}
            </div>
          </div>
        </Flex>
      </div>
    </Flex>
  )
}

export default ChatMessage
