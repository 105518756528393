/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'

import { ActionsWrapper, BottomTitle, pageTitleCss, H1, FixedTitle, TopTitle } from './css'
import { MENU_HEIGHT } from '../css'
import { Column, Container, Flex } from '../Layout'
import { Desktop, Mobile } from '../Responsive'
import Picture from '../Picture'

const PageTitle = ({ actions = false, bottomTitle, picture, pictureSettings, secondPicture, showPicturePlaceholder, title, topTitle }) => {
  const [ titleVisible, setTitleVisible ] = useState(false)

  return (
    <Container>
      <Column fullWidth column css={pageTitleCss}>
        <Desktop>
          <TopTitle>{topTitle}</TopTitle>
          <Flex alignItems='center' css={css`min-height: 52px;`}>
            <Flex css={css`min-height: 54px;`}>
              {(picture || showPicturePlaceholder) &&
                <Picture imageId={picture} size='small' css={css`margin-right: 16px; align-self: center;`} {...pictureSettings} className='page-title-image' />
              }
              <H1>{title}</H1>
            </Flex>
            {actions && <ActionsWrapper>{actions}</ActionsWrapper>}
          </Flex>
          <BottomTitle isHidden={!bottomTitle}>{bottomTitle || 'n/a'}</BottomTitle>
        </Desktop>
        <Mobile>
          <FixedTitle noFlex justifyContent='center' visible={titleVisible}>
            <div><h2>{title}</h2></div>
          </FixedTitle>
          {topTitle && <TopTitle>{topTitle}</TopTitle>}
          <Flex isRelative column alignItems='center'>
            <Flex>
              {picture && <Picture imageId={picture} size={secondPicture ? 'sm-md' : 'xlarge'} css={{ marginBottom: '24px' }} {...pictureSettings} className='page-title-image' />}
              {secondPicture && <Picture imageId={secondPicture} size={secondPicture ? 'sm-md' : 'xlarge'} css={{ marginBottom: '24px' }} {...pictureSettings} />}
            </Flex>
            <H1>{title}</H1>
            {actions && <ActionsWrapper>{actions}</ActionsWrapper>}
            {bottomTitle && <BottomTitle>{bottomTitle}</BottomTitle>}
          </Flex>
          {/* We set the "duplicate" title as visible when the title card is scrolled off-screen */}
          <Waypoint
            topOffset={(MENU_HEIGHT / 4) + 20}
            scrollableAncestor={window}
            onEnter={() => setTitleVisible(false)}
            onLeave={() => setTitleVisible(true)} />
        </Mobile>
      </Column>
    </Container>
  )
}

PageTitle.propTypes = {
  actions: PropTypes.oneOfType([ PropTypes.bool, PropTypes.node ]),
  bio: PropTypes.object,
  picture: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  secondPicture: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  showPicturePlaceholder: PropTypes.bool,
  title: PropTypes.string.isRequired,
  topTitle: PropTypes.oneOfType([ PropTypes.node, PropTypes.string ])
}

export default PageTitle
