/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'

import React, { useEffect, useState } from 'react'
import GenericSelect from '../Select/GenericSelect'
import { connect } from 'react-redux'
import { getAllGenericSportProperties } from '@sportninja/common/selectors/types'

const ScheduleTypeFormField = ({
  default_sport_type,
  default_generic_sport_type,
  disabled,
  onChange,
  sportTypeOptions,
  genericSportTypeOptions,
}) => {
  const [defaultValue, setDefaultValue] = useState('')

  const options = [
    ...sportTypeOptions
      .map((option) => ({
        ...option,
        name: `${option.name_full} (Full Stats)`,
        is_generic: false,
      }))
      .filter((option) => !option.name.toLowerCase().includes('generic')),
    ...genericSportTypeOptions.map((option) => ({
      ...option,
      name: `${option.name} (Basic Stats)`,
      is_generic: true,
    })),
  ]

  const optionsNames = options.map((option) => option.name).sort()

  useEffect(() => {
    // If we only have a default sport type, we set the default value to that
    if (default_sport_type && !default_generic_sport_type) {
      const defaultSportType = sportTypeOptions.find(
        (option) => option.id === default_sport_type
      )
      setDefaultValue(`${defaultSportType.name_full} (Full Stats)`)
      onChange({
        target: {
          name: 'sport_id',
          value: defaultSportType.id,
        },
      })
    } else {
      // If we have a generic sport type, we set the default value to that
      const defaultGenericSportType = genericSportTypeOptions.find(
        (option) => option.id === default_generic_sport_type
      )
      setDefaultValue(`${defaultGenericSportType?.name} (Basic Stats)`)
    }
  }, [default_sport_type, default_generic_sport_type])

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        width: 100%;
      `}
    >
      <GenericSelect
        label='Sport Type'
        options={optionsNames}
        disabled={disabled}
        onChange={(event) => {
          // First, lets find the item in the options array
          const selectedOption = options.find(
            (option) => option.name === event.target.value
          )
          // if is generic is equals true, we first retrieve the generic sport type
          // by searching into sportTypeOptions by the name === generic
          if (selectedOption.is_generic) {
            const genericSportType = sportTypeOptions.find(
              (option) => option.name === 'generic'
            )
            console.log('genericSportType', genericSportType)
            onChange({
              target: {
                name: 'sport_id',
                value: genericSportType?.id,
              },
            })
            onChange({
              target: {
                name: 'generic_sport_id',
                value: selectedOption?.id,
              },
            })
          } else {
            onChange({
              target: {
                name: 'sport_id',
                value: selectedOption?.id,
              },
            })
            onChange({
              target: {
                name: 'generic_sport_id',
                value: null,
              },
            })
          }
        }}
        defaultValue={defaultValue}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    genericSportTypeOptions: getAllGenericSportProperties(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleTypeFormField)
