/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import colors from '@sportninja/common/constants/appColors'
import PropTypes from 'prop-types'
import { NavLink, Route } from 'react-router-dom'

import { font, flairColorRgb } from '../css'

export const ActivePageIndicator = styled.span`
  opacity: 0;
  transition: opacity 0.2s;

  .active & {
    opacity: 1;
  }

  position: absolute;
  bottom: -16px;

  left: 50%;

  height: 2px;
  width: 24px;
  background-color: ${colors.DEFAULT_FLAIR};

  transform: translateX(-55%);
`

export const menuLinkFontStyles = css`
  ${font.title}
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.9px;
  color: ${colors.ATTENDANCE_GRAY};
  text-align: center;
`

const PageMenuNavLink = styled(NavLink, {
  shouldForwardProp: (prop) => prop !== 'isOrange',
})`
  display: flex;
  align-items: center;
  position: relative;

  ${menuLinkFontStyles}
  transition: opacity 0.2s;

  :hover {
    ${ActivePageIndicator} {
      opacity: 1;
    }
  }

  color: ${(props) => props.isOrange && `rgba(${flairColorRgb}, 0.6)`};

  :hover,
  &.active {
    color: white;
  }
`

export const generatePathStrings = (list) => {
  if (Array.isArray(list)) {
    if (list.length > 1) return `/(${list.join('|')})`
    else return list[0]
  } else return list
}

const MenuLink = ({
  disabled,
  count,
  exact = false,
  isOrange,
  match: otherMatches,
  title,
  to,
}) => (
  <Route
    key={title}
    path={
      otherMatches
        ? generatePathStrings(otherMatches)
        : 'some-completely-never-used-string'
    }
    // eslint-disable-next-line react/no-children-prop
    children={({ match }) => (
      <li
        css={css`
          display: flex;
          margin-right: 32px;
          opacity: ${disabled && 0.6};
        `}
      >
        <PageMenuNavLink
          exact={exact}
          key={title}
          to={to}
          className={`page-menu-nav-link ${match ? 'active' : ''}`}
          isOrange={isOrange}
          css={css`
            ${disabled &&
            `
              cursor: not-allowed;
              &:hover:not(.active) {
                color: #9fa3a9;

                ${ActivePageIndicator} {
                  opacity: 0;
                }
              }
            `}
          `}
          onClick={(e) => {
            if (disabled) {
              e.preventDefault()
            }
          }}
        >
          {title} {typeof count === 'number' ? `(${count})` : ''}
          <ActivePageIndicator className='active-page-indicator' />
        </PageMenuNavLink>
      </li>
    )}
  />
)

MenuLink.propTypes = {
  exact: PropTypes.bool,
  isOrange: PropTypes.bool,
  match: PropTypes.bool,
  title: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
}

export default MenuLink
