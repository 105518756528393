import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'

import { border, flairColor, font } from '../../../components/css'

const styles = css`
  margin-top: 24px;
  flex-direction: column;
  height: calc(100vh - 200px);

  .rst__tree {
    width: inherit;
    border: 1px solid ${colors.SOFT_STEEL};
    border-radius: 8px;
  }

  .rst__node {
    height: 68px !important;
  }

  .rst__moveHandle {
    position: absolute;
    width: 40px;
    background: ${colors.PALE_SKY}
      url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMTUgMEgxQy40NSAwIDAgLjQ1IDAgMXMuNDUgMSAxIDFoMTRjLjU1IDAgMS0uNDUgMS0xcy0uNDUtMS0xLTFaTTEgNmgxNGMuNTUgMCAxLS40NSAxLTFzLS40NS0xLTEtMUgxYy0uNTUgMC0xIC40NS0xIDFzLjQ1IDEgMSAxWiIgZmlsbD0iIzZGNzU3RSIvPgo8L3N2Zz4=)
      no-repeat center;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    border: 0;
    z-index: 2;
    transition: filter 0.15s ease-in-out;

    &:hover {
      filter: brightness(0) invert(1);
    }

    & ~ .rst__rowContents {
      padding-left: 40px;
    }
  }

  .rst__rowContents {
    background-color: transparent;
    box-shadow: none;
    transition: background-color 0.15s, border-color 0.15s ease-in-out;

    &:hover {
      border-color: white;
    }
  }

  .rst__row {
    min-width: 220px;
    max-width: 220px;

    &.is-team {
      min-width: 260px;
      max-width: 260px;
    }
  }

  .rst__rowTitle {
    font-size: 12px;
    font-weight: 500;
    white-space: normal; /* Change to allow text to break to the second line */
    overflow-wrap: break-word; /* Add this property to enable word wrapping */
  }

  .rst__rowWrapper {
    padding: 8px 8px 8px 0px;
  }

  .rst__rowSubtitle {
    display: block;
    font-size: 12px;
    margin-top: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .rst__rowTitleWithSubtitle {
    height: unset;
  }

  .rst__rowContents {
    cursor: pointer;
    min-width: unset;
    max-width: inherit;
    border-left: 1px solid ${colors.PALE_SKY};
    border: 1px solid ${colors.PALE_SKY};
    border-radius: 8px;
    padding: 0 8px 0 8px;
    display: flex;
  }

  .rst__rowContents:hover {
    border-color: white;
    border-left: 1px solid white;
  }

  .rst__rowLabel {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 8px;
  }

  .rst__collapseButton {
    background: #000
      url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDBDNC40OCAwIDAgNC40OCAwIDEwQzAgMTUuNTIgNC40OCAyMCAxMCAyMEMxNS41MiAyMCAyMCAxNS41MiAyMCAxMEMyMCA0LjQ4IDE1LjUyIDAgMTAgMFpNOS42NSAxMi42NUw2Ljg2IDkuODZDNi41NCA5LjU0IDYuNzYgOSA3LjIxIDlIMTIuOEMxMy4yNSA5IDEzLjQ3IDkuNTQgMTMuMTUgOS44NUwxMC4zNiAxMi42NEMxMC4xNiAxMi44NCA5Ljg0IDEyLjg0IDkuNjUgMTIuNjVaIiBmaWxsPSIjNkY3NTdFIi8+Cjwvc3ZnPgo=)
      no-repeat center;
  }

  .rst__expandButton {
    background: #000
      url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDBDNC40OCAwIDAgNC40OCAwIDEwQzAgMTUuNTIgNC40OCAyMCAxMCAyMEMxNS41MiAyMCAyMCAxNS41MiAyMCAxMEMyMCA0LjQ4IDE1LjUyIDAgMTAgMFpNOS42NSAxMi42NUw2Ljg2IDkuODZDNi41NCA5LjU0IDYuNzYgOSA3LjIxIDlIMTIuOEMxMy4yNSA5IDEzLjQ3IDkuNTQgMTMuMTUgOS44NUwxMC4zNiAxMi42NEMxMC4xNiAxMi44NCA5Ljg0IDEyLjg0IDkuNjUgMTIuNjVaIiBmaWxsPSIjNkY3NTdFIi8+Cjwvc3ZnPgo=)
      no-repeat center;
    transform: translate(-50%, -50%) rotate(180deg);
  }

  .is-team.rst__row {
    position: relative;
  }

  .is-team .rst__rowContents {
    cursor: pointer;

    &:hover {
      z-index: 1;
    }
  }

  .rst__rowToolbar {
    align-items: center;
  }

  .rst__lineChildren,
  .rst__lineBlock {
    &:before,
    &:after {
      background-color: ${colors.PALE_SKY};
    }
  }

  .rst__rowLandingPad {
    &::before {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  .rst__rowCancelPad {
    &::before {
      background-color: rgba(255, 0, 0, 0.3);
    }
  }

  .rst__highlightLineVertical {
    &::before,
    &::after {
      display: none;
      background-color: ${flairColor};
    }
  }

  .rst__highlightTopLeftCorner {
    &::before,
    &::after {
      display: none;
      border-top-color: ${flairColor};
      border-left-color: ${flairColor};
    }
  }

  .rst__highlightBottomLeftCorner {
    &::before {
      display: none;
      border-bottom-color: ${flairColor};
      border-left-color: ${flairColor};
    }

    &::after {
      display: none;
      border-left-color: ${flairColor};
    }
  }

  .rst__rowSearchMatch {
    outline: none;

    .rst__rowContents {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .seed {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    ${font.body}
    color: ${colors.PALE_SKY};
    letter-spacing: normal;

    .title {
      margin-bottom: 2px;
    }
  }
`

export default styles
