/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled/macro'
import isPropValid from '@emotion/is-prop-valid'

import { Flex } from '../Layout'

const layout = ({ fill, size }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: fill ? '128px' : size,
  maxHeight: fill ? '128px' : size,
  minWidth: !fill && size,
  maxWidth: !fill && size,
})

export const ImageWrap = styled(Flex, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'fill',
})`
  ${layout}
  position: relative;

  width: ${(props) => props.fill && '100%'};

  background-color: ${(props) =>
    props.fit || props.fill ? 'white' : props.backgroundColor};
  border-radius: ${(props) =>
    props.noBorder ? 0 : props.square ? '4px' : '50%'};
  border: ${(props) => !props.noBorder && '1px solid #fff'};
`

export const Image = styled(Flex)`
  ${layout}
  position: absolute;

  background: url(${(props) => props.bg});
  background-size: ${(props) => (props.square ? 'contain' : 'cover')};
  background-repeat: no-repeat;
  background-position: center;

  border-radius: ${(props) =>
    props.noBorder ? 0 : props.square ? '4px' : '50%'};
  border: ${(props) => !props.noBorder && '1px solid #fff'};

  transition: opacity 0.2s;
  opacity: ${(props) => (props.bg ? 1 : 0)};

  .fas {
    transition: opacity 0s;
  }
`
