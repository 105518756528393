/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { useEffect, useRef, useState } from 'react'

import req from '@sportninja/common/api/request'
import Icon from '../Icon'
import { Flex } from '../Layout'
import Picture from '../Picture'
import { ReactComponent as DropdownArrow } from '../Suspensions/dropdown.svg'
import { font, zIndex } from '../css'
import { InputWrapper, Label } from './css'

const Items = ({ onClick, title, items = [], searchTerm, iconName }) => {
  const filteredItems =
    searchTerm?.length > 0
      ? items.filter((i) => {
          return i?.name_full?.toLowerCase().includes(searchTerm.toLowerCase())
        })
      : items

  return (
    <>
      {items?.length > 0 && (
        <p
          css={css`
            ${font.title}
            font-size: 15px;
            font-weight: 700;
            margin: 18px 8px 8px;
          `}
        >
          {title}
        </p>
      )}
      {filteredItems?.length === 0 ? (
        <div
          css={css`
            color: gray;
            padding: 8px;
          `}
        >
          No results for &quot;{searchTerm}&quot;
        </div>
      ) : (
        filteredItems.map((item) => {
          return (
            <Flex
              key={item.id}
              alignItems='center'
              css={css`
                padding: 8px;
                cursor: pointer;

                &:hover {
                  border-radius: 8px;
                  background-color: rgba(255, 255, 255, 0.1);
                }
              `}
              onClick={() => {
                onClick(item)
              }}
            >
              <Picture
                square
                fit
                imageId={getImageThumbnailId(item)}
                size='xxsmall'
                iconName={iconName}
              />
              <div
                css={css`
                  margin-left: 16px;
                `}
              >
                {item.name_full}
              </div>
            </Flex>
          )
        })
      )}
    </>
  )
}

const FeedItemParentPicker = ({
  hasError,
  defaultValue,
  onChange,
  orgs = [],
  teams = [],
  scheds = [],
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const inputRef = useRef(null)
  const [selected, setSelected] = useState(defaultValue)
  const [selectedType, setSelectedType] = useState(null)
  const [targetAudience, setTargetAudience] = useState(null)

  useEffect(() => {
    const getTargetAudience = async () => {
      try {
        let type = ''
        if (selectedType === 'organizations') {
          type = 'organization'
        } else if (selectedType === 'teams') {
          type = 'team'
        } else if (selectedType === 'schedules') {
          type = 'competition'
        }
        const { data } = await req(`/social-feed/${selected?.id}/${type}`)
        if (data && data?.total_users && data?.total_users > 0) {
          setTargetAudience(data?.total_users)
        }
      } catch (e) {
        console.error(e)
        setTargetAudience(null)
      }
    }
    if (selectedType && selected) {
      getTargetAudience()
    } else {
      setTargetAudience(null)
    }
  }, [selected, selectedType])

  useEffect(() => {
    const handler = (e) => {
      if (!inputRef?.current?.contains(e.target)) {
        setIsOpen(false)
        document.removeEventListener('click', handler)
      }
    }
    if (isOpen) document.addEventListener('click', handler)
    return () => {
      document.removeEventListener('click', handler)
    }
  }, [isOpen])

  const handleChange = (entityType, item) => {
    setSelected(item)
    setSelectedType(entityType)
    onChange({
      target: {
        name: 'parent_id',
        value: item.id,
      },
    })
    onChange({
      target: {
        name: 'entityType',
        value: entityType,
      },
    })
  }

  return (
    <InputWrapper>
      <Label>Announce to *</Label>
      <Flex
        alignItems='center'
        css={css`
          position: relative;
          min-height: 40px;

          padding: 8px 16px;
          width: 100%;
          background: #27303e;
          border: 1px solid ${hasError ? 'red' : '#3f4753'};
          border-radius: 4px;

          &:hover {
            border-color: white;
          }
        `}
      >
        {selected ? (
          <Flex row>
            {targetAudience ? (
              <div>
                {selected.name_full} | Audience: {targetAudience || 0}
              </div>
            ) : (
              <div>{selected.name_full}</div>
            )}
          </Flex>
        ) : (
          <input
            ref={inputRef}
            onChange={({ target }) => {
              setSearchTerm(target.value)
            }}
            value={searchTerm}
            type='text'
            onFocus={() => {
              setIsOpen(true)
            }}
            css={css`
              width: 100%;
              border: 0;

              ${font.body}
              font-size: 15px;
              letter-spacing: 0.74px;
              color: white;
            `}
          />
        )}
        {isOpen && (
          <div
            css={css`
              left: 0;
              right: 0;
              top: 40px;
              z-index: ${zIndex.modal};
              padding: 0 8px 16px;
              position: absolute;
              background: #27303e;
              border: 1px solid #3f4753;
              border-radius: 4px;
              max-height: 500px;
              overflow: auto;
            `}
          >
            <Items
              onClick={handleChange.bind(this, ENTITY_TYPES.org)}
              title='Organizations'
              items={orgs}
              searchTerm={searchTerm}
              iconName='users'
            />
            <Items
              onClick={handleChange.bind(this, ENTITY_TYPES.schedule)}
              title='Competitions'
              items={scheds}
              searchTerm={searchTerm}
              iconName='calendar-alt'
            />
            <Items
              onClick={handleChange.bind(this, ENTITY_TYPES.team)}
              title='Teams'
              items={teams}
              searchTerm={searchTerm}
              iconName='user-friends'
            />
          </div>
        )}
        {selected ? (
          <Icon
            fontSize={15}
            name='times'
            css={css`
              position: absolute;
              right: 22px;
              top: 12px;
              cursor: pointer;
            `}
            onClick={() => {
              setSelected(false)
              onChange({
                target: {
                  name: 'parent_id',
                  value: undefined,
                },
              })
              setTimeout(() => {
                inputRef?.current.focus()
              }, 0)
            }}
          />
        ) : (
          <DropdownArrow
            css={css`
              position: absolute;
              right: 16px;
              top: 7px;
              pointer-events: none;
            `}
          />
        )}
      </Flex>
    </InputWrapper>
  )
}

export default FeedItemParentPicker
