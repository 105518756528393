/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { t } from '@sportninja/common/i18n'
import { ROUTES } from '@sportninja/common/constants/app'

import ResendVerifyEmail from '../../components/ResendVerifyEmail'
import { BodyText, H1 } from '../../components/AuthWrap/css'
import AuthWrap from '../../components/AuthWrap'
import { FormButton } from '../../components/Form/css'
import Helmet from '../../components/Helmet'

const RegisterSuccess = ({ location: { state = {} } }) => (
  <AuthWrap title={t('util:capitalize', { text: t('common:register') })}>
    <Helmet title={t('util:capitalize', { text: t('common:register') })} />
    <H1>
      {state.email ?
        `${t('Auth:almostReady')}!`
        :
        'Oops...'
      }
    </H1>
    {state.email ?
      <BodyText>
        <p>
          {t('Web:inviteRegisterVerify')}
        </p>
        <p>
          {t('Web:pleaseClickButtonInEmail')}
        </p>
        <p className='double-margin small'>
          {t('Web:ifYouCantFindItHint')}
        </p>
        <ResendVerifyEmail email={state.email} />
      </BodyText>
      :
      <BodyText>
        <p>
          {t('Web:sentToWrongPlace')}
        </p>
        <FormButton as={Link} to={ROUTES.HOME} css={css`display: flex; width: 50%; margin: 32px auto 0;`}>
          {t('Web:goHome')}
        </FormButton>
      </BodyText>
    }
  </AuthWrap>
)

RegisterSuccess.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      email: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}

export default RegisterSuccess
