import { generateActions } from './utils'

const definition = {
  read: ['id'],
  create: ['id', 'isHome', 'form'],
  update: ['id', 'shotId', 'form'],
  delete: ['id', 'shotId'],
  end: ['id'],
}

const shootoutActions = generateActions(['SHOOTOUT'], definition)

export default shootoutActions
