import React from 'react'
import Responsive from 'react-responsive'

export const COL_GUTTER_UNIT = 8
export const COL_GUTTER = COL_GUTTER_UNIT * 2
export const COL_WIDTH = 88
export const COL_PADDING = 24

// Maximum width for each column layout is determined by:
// (# of columns * column width) + ((# of columns - 1) * column gutter * 2) + (outside margin * 2)
// e.g. 14 columns: (14 * 88) + ((14 - 1) * (8 * 2)) + (24 * 2) = 1488
const _calculateWidth = (c) => {
  return (c * COL_WIDTH) + ((c - 1) * COL_GUTTER) + (COL_PADDING * 2)
}

export const COLS = (() => {
  const COLUMNS = {
    fourteen: 14, // 1488
    twelve:   12, // 1280
    ten:      10, // 1072
    eight:     8, //  864
  }

  for (const colName in COLUMNS) {
    COLUMNS[colName] = _calculateWidth(COLUMNS[colName])
  }

  // Mobile is 1 pixel less than the last breakpoint
  COLUMNS.mobile = COLUMNS.eight - 1
  return COLUMNS
})()

const columnKeys = Object.keys(COLS)

export const breakpoints = columnKeys.map((keyName) => COLS[keyName])

// Produces media query shorthands for emotion-js, which can be used like:
// ${media.mobile} {
//   <rules only for screen widths matching mobile>
// }
export const media = columnKeys.reduce((acc, label) => {
  acc[label] = `@media only screen and (max-width: ${COLS[label]}px)`
  return acc
}, {})

export const minMedia = columnKeys.reduce((acc, label) => {
  acc[label] = `@media only screen and (min-width: ${COLS[label]}px)`
  return acc
}, {})

export const Mobile = (props) => <Responsive {...props} maxWidth={COLS.mobile} />
export const Desktop = (props) => <Responsive {...props} minWidth={COLS.mobile + 1} />
