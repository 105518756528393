/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Input } from 'antd'
import { useMemo } from 'react'
// import { useI18n } from '@shopify/react-i18n'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
// import SuspensionInput from '../Suspensions/SuspensionInput'

const CurrencyInput = ({
  label = '',
  required,
  value,
  currency,
  ...inputProps
}) => {
  // const theValue = useMemo(() => value || 0, [value])

  function currencyMask(rawValue) {
    let numberMask = createNumberMask({
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: ',',
      allowDecimal: true,
      decimalSymbol: '.',
      decimalLimit: 2,
      allowNegative: false,
      allowLeadingZeroes: false,
      suffix: currency ? ' ' + currency : undefined,
      prefix: '$',
    })
    let mask = numberMask(rawValue)

    // if (theValue === 0) {
    //   return mask
    // }

    // let decimalsRegex = /\.([0-9]{1,2})/
    // let result = decimalsRegex.exec(rawValue)
    // // In case there is only one decimal
    // if (result && result[1].length < 2) {
    //   mask.push('0')
    // } else if (!result) {
    //   mask.push('0')
    //   mask.push('0')
    // }

    return mask
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 8px;
      `}
    >
      <p>{label + (required ? ' *' : '')}</p>
      <MaskedInput
        required={required}
        css={css`
          border: 1px solid ${colors.SOFT_STEEL};
          background-color: ${colors.HEADER};
          border-radius: 4px;
          font-size: 16px;
          padding: 10px;
          height: 41px;
          color: ${colors.WHITE};
          transition: all 0.1s ease-in-out;

          &:focus {
            outline: none;
            border: 1px solid ${colors.DEFAULT_FLAIR};
          }

          &:hover {
            border: 1px solid ${colors.DEFAULT_FLAIR};
          }
        `}
        value={value}
        mask={currencyMask}
        {...inputProps}
      />
    </div>
  )
}

export default CurrencyInput
