import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { t } from '@sportninja/common/i18n'
import req from '@sportninja/common/api/request'

import { SelectFormInput } from '../Select'
import { FormRow } from './css'

const GameScheduleSelect = ({
  disabled,
  defaultValue,
  noFlex,
  onChange,
  gameInfo,
  values: formValues,
}) => {
  const [schedules, setSchedules] = useState([])
  const [selectedSchedule, setSelectedSchedule] = useState(defaultValue || '')
  const [scheduleType, setScheduleType] = useState(t('common:competition'))

  useEffect(() => {
    if (
      !formValues?._home_team_is_placeholder &&
      !formValues?._visiting_team_is_placeholder &&
      !gameInfo?.homeTeam?.isPlaceholder &&
      !gameInfo?.visitingTeam?.isPlaceholder
    ) {
      setSelectedSchedule('')
      onChange({ target: { name: 'schedule_id', value: undefined } })
    }
  }, [
    formValues?._home_team_is_placeholder,
    formValues?._visiting_team_is_placeholder,
    gameInfo?.homeTeam?.isPlaceholder,
    gameInfo?.visitingTeam?.isPlaceholder,
  ])

  useEffect(() => {
    req(`/schedules/${gameInfo?.rootSchedule?.id}/children/dropdown`).then(
      ({ data }) => {
        if (data && data.length > 0 && data[data.length - 1]?.schedules) {
          setSchedules(data[data.length - 1]?.schedules)
          setScheduleType(data[data.length - 1]?.name)
        }
      }
    )
  }, [gameInfo?.rootSchedule?.id])

  const onScheduleChange = ({ target }) => {
    onChange({ target })
    setSelectedSchedule(target.value)
  }

  const label = `${gameInfo?.parentScheduleType || scheduleType}`

  if (
    !formValues?._home_team_is_placeholder &&
    !formValues?._visiting_team_is_placeholder &&
    !gameInfo?.homeTeam?.isPlaceholder &&
    !gameInfo?.visitingTeam?.isPlaceholder
  ) {
    return false
  }

  return (
    <FormRow>
      <SelectFormInput
        defaultValue={selectedSchedule}
        disabled={schedules.length === 0 || disabled}
        label={label}
        name='schedule_id'
        onChange={onScheduleChange}
        required
      >
        <option disabled value=''>
          Select a {label}
        </option>
        <option disabled>---</option>
        {schedules.map((schedule) => {
          return (
            <option key={schedule?.id} value={schedule?.id}>
              {schedule?.name_full}
            </option>
          )
        })}
      </SelectFormInput>
      <div
        style={{
          marginTop: 28,
          paddingLeft: 8,
          flex: 1,
        }}
      >
        Placeholder teams do not have a {label}; please select one manually.
      </div>
    </FormRow>
  )
}

GameScheduleSelect.propTypes = {
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  noFlex: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default GameScheduleSelect
