import { useState, useEffect, useMemo } from 'react'
import req from '../api/request'

interface Sport {
  id: number
  name: string
  name_full: string
}

interface UseSportsResult {
  sports: Sport[] | null
  loading: boolean
  error: any
  sportOptions: string[] | undefined
}

export const useSports = (): UseSportsResult => {
  const [sports, setSports] = useState<Sport[] | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)
  const sportOptions = useMemo(() => {
    return sports?.map((sport) => sport.name_full)
  }, [sports])

  useEffect(() => {
    const fetchSports = async () => {
      setLoading(true)
      try {
        const { data } = await req('/sports')
        setSports(data)
      } catch (err: any) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    fetchSports()
  }, [])

  return { sports, loading, error, sportOptions }
}
