/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { t } from '@sportninja/common/i18n'
import dayjs from 'dayjs'
import { useI18n } from '@shopify/react-i18n'

import List from '../../../../components/List'
import DivisionTeamsTotalPaid from './DivisionTeamsTotalPaid'
import { observer } from 'mobx-react-lite'
import { useMobxStore } from '../../../../state'
import { useCallback, useMemo, useRef, useState } from 'react'
import Icon from '../../../../components/Icon'
import Select from 'react-select'
import { SideModal, sideModalRef } from './SideModal'
import DeleteModal from 'src/components/Modal/DeleteModal'
import { Flex } from 'src/components/Layout'
import Picture from 'src/components/Picture'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { Button, Dropdown, Alert, Space, Popover } from 'antd'
import { NewModal } from 'src/components/NewModal'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import GenericTable from 'src/components/GenericTable'
import {
  StatusBadge,
  calculatePastDues,
  fetchBillingInfo,
} from 'src/components/List/FinancialList'
import RegistrationIndicatorCard from '../RegistrationIndicatorCard'
import CustomRegistrationIndicatorCard from '../CustomRegistrationIndicatorCard'
import { CloseOutlined } from '@ant-design/icons'
import LoadingSpinner from 'src/components/LoadingSpinner'
import req from '@sportninja/common/api/request'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { useHistory } from 'react-router-dom'

const registrationOptions = [
  {
    label: 'Submitted',
    value: 1,
  },
  {
    label: 'Pending',
    value: 2,
  },
  {
    label: 'Approved',
    value: 3,
  },
  {
    label: 'Approved w/ conditions',
    value: 4,
  },
  {
    label: 'Cancelled',
    value: 5,
  },
]

const registrationOptionsSubmitted = [
  {
    label: 'Submitted',
    value: 1,
  },
  {
    label: 'Cancelled',
    value: 5,
  },
]

const registrationOptionsPending = [
  {
    label: 'Pending',
    value: 2,
  },
  {
    label: 'Approved',
    value: 3,
  },
  {
    label: 'Approved w/ conditions',
    value: 4,
  },
  {
    label: 'Cancelled',
    value: 5,
  },
]

const registrationOptionsApproved = [
  {
    label: 'Approved',
    value: 3,
  },
  {
    label: 'Cancelled',
    value: 5,
  },
]

const registrationOptionsApprovedWithConditions = [
  {
    label: 'Approved w/ conditions',
    value: 4,
  },
  {
    label: 'Cancelled',
    value: 5,
  },
]

const registrationOptionsCancelled = [
  {
    label: 'Cancelled',
    value: 5,
  },
]

const InfoButton = ({ key, item }) => {
  const [showInfo, setShowInfo] = useState(false)
  return (
    <>
      <div
        onClick={() => {
          if (showInfo) {
            setShowInfo(false)
          }
        }}
        css={css`
          background-color: ${showInfo && 'rgba(0,0,0,0.5)'};
          position: ${showInfo && 'fixed'};
          z-index: ${showInfo && '999999999'};
          top: ${showInfo && '0'};
          right: ${showInfo && '0'};
          bottom: ${showInfo && '0'};
          left: ${showInfo && '0'};
          transition: background-color 0.09s ease-in-out;
        `}
      />
      <button
        key={key}
        onClick={() => setShowInfo(!showInfo)}
        css={css`
          position: relative;
        `}
      >
        <Icon name='info-circle' color={colors.DEFAULT_FLAIR} fontSize={18} />
        <div
          css={css`
            display: ${showInfo ? 'flex' : 'none'};
            position: absolute;
            flex-direction: column;
            right: 20px;
            top: 10px;
            min-width: 220px;
            min-height: 100px;
            background-color: ${colors.HEADER};
            border-radius: 5px 0px 5px 5px;
            border: 1px solid ${colors.SOFT_STEEL};
            padding: 10px;
            z-index: 999999999;
            box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
            align-items: flex-start;
          `}
        >
          <p
            css={css`
              color: ${colors.WHITE};
              font-weight: bold;
              padding-bottom: 5px;
            `}
          >
            Condition Description:
          </p>

          <p
            css={css`
              color: ${colors.WHITE};
            `}
          >
            {item?.conditions}
          </p>
        </div>
      </button>
    </>
  )
}

const InfoText = ({ key, item }) => {
  const content = (
    <>
      <div>
        <p
          css={css`
            color: ${colors.WHITE};
          `}
        >
          {item?.conditions}
        </p>
      </div>
    </>
  )
  return (
    <Popover
      zIndex={99999}
      content={content}
      title='Condition:'
      trigger='hover'
      placement='bottom'
    >
      <span>Approval Conditions</span>
    </Popover>
  )
}

const TeamCell = ({ item }) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      max-width: 180px;
    `}
  >
    <Picture
      size='kxsmall'
      square
      fit
      imageId={getImageThumbnailId(item)}
      iconName='user-friends'
      css={css`
        margin-right: 8px;
      `}
    />
    {item.name}
  </div>
)

const defaultCurrencies = {
  1: 'CAD',
  2: 'USD',
}

const DivisionTeams = observer(
  ({ teams, divisionId, isClosed, showCancelledTeams = false }) => {
    const {
      me: {
        state: { me },
      },
      registrationTypes: {
        state: { condition_type },
      },
      registration: {
        setTeamToCancel,
        state: { divisions, registration, teamToCancel },
        updateTeamRegStatus,
      },
    } = useMobxStore()
    const billingScheduleModalRef = useRef(null)
    const receiptModalRef = useRef(null)
    const downloadRef = useRef(null)
    const [selectedBillingSchedule, setSelectedBillingSchedule] = useState(null)
    const [pdfIsLoading, setPdfIsLoading] = useState(false)
    const [billingId, setBillingId] = useState(undefined)
    const [receiptIsLoading, setReceiptIsLoading] = useState(false)
    const [selectedReceipt, setSelectedReceipt] = useState(null)
    const [downloadReceiptError, setDownloadReceiptError] = useState(false)
    const history = useHistory()

    const onView = useCallback(async () => {
      setPdfIsLoading(true)
      try {
        const pdf = await req(`/billing/schedule/${billingId}/receipt/pdf`, {
          parseJSON: false,
        })
        const normalizePdf = await pdf.blob()
        const link = document.createElement('a')
        link.href = URL.createObjectURL(normalizePdf)
        link.download = `${selectedReceipt?.item?.charge?.stripe_id}.pdf`
        link.click()
        setPdfIsLoading(false)
      } catch (e) {
        console.error(e)
        const errorMessage = getErrorMessage(e)
        setDownloadReceiptError(errorMessage)
        setPdfIsLoading(false)
      }
    }, [selectedReceipt, billingId])

    const headerLength = useMemo(() => {
      if (
        selectedBillingSchedule?.billing_schedule &&
        selectedBillingSchedule?.billing_schedule?.length > 0 &&
        selectedBillingSchedule?.billing_schedule[0]?.user_id === me?.id
      ) {
        return [1.5, 1, 1, 1, 1.3, 1]
      }

      return [1.5, 1, 1, 1, 1.3]
    }, [me, selectedBillingSchedule])

    const currency = useMemo(() => {
      if (!selectedBillingSchedule) {
        return 'USD'
      }

      return defaultCurrencies[
        selectedBillingSchedule?.billing_schedule[0]?.currency
      ]
    }, [selectedBillingSchedule])

    const totalOutstanding = useMemo(() => {
      if (!selectedBillingSchedule?.billing_schedule) {
        return 0
      }

      let total = 0

      selectedBillingSchedule?.billing_schedule
        .filter((el) => !el.paid)
        .forEach((el) => {
          total += el.subtotal
        })

      return total
    }, [selectedBillingSchedule])

    const totalPaid = useMemo(() => {
      if (!selectedBillingSchedule?.billing_schedule) return 0

      let total = 0

      selectedBillingSchedule?.billing_schedule
        .filter((el) => el.paid)
        .forEach((el) => {
          total += el.subtotal
        })

      return total
    }, [selectedBillingSchedule])

    const [i18n] = useI18n()

    const currentDivision = useMemo(() => {
      return divisions.find((d) => d.id === divisionId)
    }, [divisionId])

    const onRegStatusChange = useCallback(({ value, team }) => {
      updateTeamRegStatus({
        scheduleId: registration?.schedule_id,
        scheduleRegistrationId: currentDivision?.registration?.id,
        status: value,
        teamId: team.uid,
        conditions: null,
        conditionType: null,
        teamRosterId: team.team_roster_id,
      })
    }, [])

    const loadReceipt = useCallback((billingId, index) => {
      setReceiptIsLoading(true)
      receiptModalRef.current.openModal()

      fetchBillingInfo(billingId)
        .then((response) => {
          setSelectedReceipt({ item: response, index })
          setReceiptIsLoading(false)
        })
        .catch((err) => {
          console.error(err)
          setDownloadReceiptError(getErrorMessage(err))
          setReceiptIsLoading(false)
        })
    }, [])

    const getRegistrationOptions = useCallback((status) => {
      switch (status) {
        case 1:
          return registrationOptionsSubmitted
        case 2:
          return registrationOptionsPending
        case 3:
          return registrationOptionsApproved
        case 4:
          return registrationOptionsApprovedWithConditions
        case 5:
          return registrationOptionsCancelled
        default:
          return registrationOptions
      }
    }, [])

    const headers = [
      {
        text: t('common:team'),
        sizePercent: 22,
        sort: 'team.name',
        Cell: TeamCell,
        to: (item) => `${ROUTES.TEAM_ROOT}/${item.uid}/roster`,
      },
      {
        text: t('common:player', { count: 2 }),
        sizePercent: 12,
        minWidth: 70,
        accessor: (item) =>
          `${item?.players_count}/${currentDivision?.registration?.max_players_per_team}`,
      },
      {
        text: 'Curr. Div.',
        sizePercent: 12,
        minWidth: 60,
        accessor: (item) => {
          return (
            <div>
              {(item?.schedules &&
                item?.schedules.length > 0 &&
                item?.schedules[0]?.name) ||
                '-'}
            </div>
          )
        },
      },
      {
        text: 'Due',
        sizePercent: 12,
        minWidth: 70,
        accessor: (item) => {
          if (item?.is_comp) {
            return (
              <span
                css={css`
                  font-size: 14px;
                `}
              >
                Complimentary
              </span>
            )
          }

          const nextInstallmentToPay = item?.billing_schedule?.find((item) => {
            return item.paid === false
          })

          const due = nextInstallmentToPay?.due_date
          const day = dayjs(due, 'YYYY-MM-DD')
          const isAfter = dayjs().isAfter(day)
          return (
            <div
              title={day.format('D MMMM, YYYY')}
              css={css`
                ${isAfter && `color: ${colors.ERROR_LIGHT};`}
              `}
            >
              {dayjs(nextInstallmentToPay?.due_date).format('MMM D, YYYY')}
            </div>
          )
        },
      },
      !currentDivision?.registration?.single_payment && {
        text: 'Dep. Paid',
        sizePercent: 12,
        minWidth: 20,
        accessor: (item) => {
          if (item?.is_comp) {
            return null
          }

          const deposit = item.billing_schedule.find((item) => {
            return item.payment_type === 'deposit'
          })

          return (
            <Icon
              name={deposit?.paid ? 'check' : 'times'}
              color={deposit?.paid ? colors.GREEN : colors.RED}
            />
          )
        },
      },
      {
        text: 'Outstanding',
        sizePercent: 12,
        minWidth: 70,
        accessor: (item) => {
          if (item?.is_comp) {
            return (
              <span>
                {i18n.formatCurrency(0, {
                  currency: currentDivision?.registration?.currency?.name,
                  form: 'short',
                })}
              </span>
            )
          }
          const teamCost = currentDivision?.registration?.team_cost ?? 0
          const deposit = currentDivision?.registration?.deposit ?? 0

          let totalPaid = 0

          item.billing_schedule.forEach((item) => {
            if (item.paid) {
              totalPaid += item.subtotal
            }
          })

          return (
            <span>
              {i18n.formatCurrency(teamCost - totalPaid, {
                currency: currentDivision?.registration?.currency?.name,
                form: 'short',
              })}
            </span>
          )
        },
      },
      {
        text: 'Paid',
        sizePercent: 12,
        minWidth: 100,
        Cell: ({ item }) => {
          if (item.is_comp) {
            return <span>--</span>
          }

          return (
            <DivisionTeamsTotalPaid
              item={item}
              currentDivision={currentDivision}
            />
          )
        },
      },
      {
        text: 'Status',
        sizePercent: 12,
        sort: 'registration_status',
        minWidth: 98,
        accessor: (item) => {
          return (
            <Select
              isSearchable={false}
              options={getRegistrationOptions(item?.registration_status)}
              defaultValue={registrationOptions.find(
                (i) => i.value === item?.registration_status
              )}
              value={registrationOptions.find(
                (i) => i.value === item?.registration_status
              )}
              onChange={({ value }) => {
                const currentValue = registrationOptions.find(
                  (i) => i.value === item?.registration_status
                )?.value
                if (value === currentValue) {
                  return
                }
                // 5 is cancelled
                if (value === 5) {
                  setTeamToCancel(item)
                  // click the div with id delete-button
                  document.getElementById('delete-button').click()
                  // 4 is approved with conditions
                } else if (value === 4) {
                  sideModalRef.current.handleOpen(
                    item,
                    divisionId,
                    registration?.schedule_id,
                    currentDivision?.registration?.id
                  )
                } else {
                  onRegStatusChange({ value, team: item })
                }
              }}
              styles={{
                container: (baseStyles) => ({
                  ...baseStyles,
                  width: 150,
                }),
                control: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: colors.HEADER,
                  borderColor: colors.SOFT_STEEL,
                  maxWidth: '100%',
                }),
                singleValue: (baseStyles) => ({
                  ...baseStyles,
                  color: colors.WHITE,
                  fontSize: 12,
                }),
                menu: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: colors.HEADER,
                  borderColor: colors.SOFT_STEEL,
                  zIndex: 999999999999999,
                }),
                option: (baseStyles, { isFocused }) => ({
                  ...baseStyles,
                  backgroundColor: isFocused ? colors.DEFAULT_FLAIR : undefined,
                  fontSize: 14,
                }),
              }}
            />
          )
        },
      },
    ]

    const options = (item) => {
      const items = [
        {
          key: '1',
          label: (
            <span
              onClick={() => {
                setSelectedBillingSchedule(item)
                billingScheduleModalRef?.current?.openModal()
              }}
            >
              View Billing Schedule
            </span>
          ),
        },
      ]
      const itemsForBothScenarios = [
        {
          key: '1',
          label: (
            <span
              onClick={() => {
                setSelectedBillingSchedule(item)
                billingScheduleModalRef?.current?.openModal()
              }}
            >
              View Billing Schedule
            </span>
          ),
        },
        {
          key: '2',
          label: (
            <div>
              <InfoText key={Math.random().toString()} item={item} />
            </div>
          ),
        },
      ]
      if (item?.conditions && item?.billing_schedule?.length > 0) {
        return [
          <Dropdown
            menu={{ items: itemsForBothScenarios }}
            placement='bottomRight'
            key={item?.uid}
          >
            <Icon
              name={'ellipsis-v'}
              css={css`
                margin-left: 3px;
              `}
              color={colors.PRIMARY}
            />
          </Dropdown>,
        ]
      }
      if (item?.conditions) {
        return [<InfoButton key={Math.random().toString()} item={item} />]
      }
      if (item?.billing_schedule?.length > 0) {
        return [
          <Dropdown menu={{ items }} placement='bottomRight' key={item?.uid}>
            <Icon
              name={'ellipsis-v'}
              css={css`
                margin-left: 3px;
              `}
              color={colors.PRIMARY}
            />
          </Dropdown>,
        ]
      }
      return [
        <div
          key={Math.random().toString()}
          css={css`
            width: 30px;
          `}
        />,
      ]
    }

    return (
      <>
        <style>
          {`
          .list-cell {
            padding-right: 0px;
          }
        `}
        </style>
        <List
          hideStatusView={isClosed}
          headers={headers}
          removeOverflow
          alternativeOptionsIcon={true}
          options={options}
          listProps={{
            items: showCancelledTeams
              ? teams
              : teams.filter((t) => t.registration_status !== 5),
          }}
          fullNoItemsText={' '}
        />
        <SideModal
          onConfirm={updateTeamRegStatus}
          divisions={divisions}
          ref={sideModalRef}
          conditionType={condition_type}
        />
        <DeleteModal
          title='Cancel this registration?'
          message={`Are you sure you want to cancel ${
            teamToCancel?.name || teamToCancel?.name_full
          } registration? \n This cannot be undone.`}
          onDelete={async (shouldForce = false) => {
            onRegStatusChange({ value: 5, team: teamToCancel })
          }}
          Button={({ ...props }) => <div id='delete-button' {...props} />}
          useForceDelete={true}
          displayBanner={false}
        />
        <NewModal
          ref={billingScheduleModalRef}
          shouldCloseOnOverlayClick={true}
        >
          <div
            css={css`
              width: 55vw;
              background: #26303e;
              display: flex;
              flex-direction: column;
              overflow-y: auto;
              transition: all 0.1s ease-in-out;
              /* Removing this due to the blur content issue reported on: https://sportninja.atlassian.net/browse/SN-3839 */
              /* border-radius: 8px; */
            `}
          >
            <button
              onClick={() => {
                billingScheduleModalRef.current.closeModal()
              }}
              css={css`
                position: fixed;
                right: 40px;
                top: 40px;
                background: none;
                border: none;
              `}
            >
              <CloseOutlined
                fontSize={20}
                style={{
                  color: colors.WHITE,
                  fontSize: 20,
                }}
              />
            </button>
            <div
              css={css`
                padding: 16px;
                display: flex;
                flex: 1;
                flex-direction: column;
                height: 100%;
                overflow-y: auto;
                max-height: 525px;
              `}
            >
              {/* Title */}
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  width: 100%;
                  align-items: center;
                  margin-bottom: 16px;
                  border-bottom: 1px solid ${colors.SOFT_STEEL};
                `}
              >
                <p
                  css={css`
                    color: var(--Neutrals-White, #fff);
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 120%; /* 28.8px */
                    font-family: ${isCanlan
                      ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                      : 'Rift, Arial, Helvetica, sans-serif'};
                    font-weight: 700;
                  `}
                >
                  {`${selectedBillingSchedule?.name} Billing Schedule`}
                </p>
              </div>
              {/* Cards */}
              <div
                css={css`
                  flex: 1;
                  display: flex;
                  flex-direction: row;
                  gap: 16px;
                  margin-bottom: 16px;
                `}
              >
                <CustomRegistrationIndicatorCard
                  iconName='wallet'
                  title='Total Paid'
                  renderContent={() => (
                    <div
                      css={css`
                        margin-top: 2px;
                      `}
                    >
                      <span
                        css={css`
                          font-weight: 700;
                          font-size: 24px;
                          letter-spacing: 0.05em;
                          font-family: ${isCanlan
                            ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                            : 'Rift, Arial, Helvetica, sans-serif'};
                        `}
                      >
                        {currency &&
                          i18n.formatCurrency(totalPaid, {
                            currency: currency,
                            form: 'explicit',
                          }) +
                            ' / ' +
                            i18n.formatCurrency(totalOutstanding + totalPaid, {
                              currency: currency,
                              form: 'explicit',
                            })}
                      </span>
                      <div
                        css={css`
                          background-color: ${colors.INFO_DARK};
                          width: 100%;
                          position: relative;
                          margin-top: 8px;
                          overflow: hidden;
                          border-radius: 2px;
                          height: 11px;
                        `}
                      >
                        <div
                          css={css`
                            background-color: ${colors.INFO_LIGHT};
                            position: absolute;
                            height: 11px;
                            width: ${(totalPaid / totalOutstanding) * 100}%;
                          `}
                        />
                      </div>
                      <p
                        css={css`
                          color: ${colors.ATTENDANCE_GRAY};
                          font-size: 16px;
                          font-size: 12px;
                          margin-top: 5px;
                          line-height: 15px;
                        `}
                      >
                        Total Outstanding:{' '}
                        {currency &&
                          i18n.formatCurrency(totalOutstanding, {
                            currency: currency,
                            form: 'explicit',
                          })}
                      </p>
                    </div>
                  )}
                />
                {!!selectedBillingSchedule?.billing_schedule?.find(
                  (el) => !el.paid
                )?.subtotal && (
                  <CustomRegistrationIndicatorCard
                    iconName='wallet'
                    title='TEAM NEXT PAYMENT'
                    renderContent={() => (
                      <div
                        css={css`
                          margin-top: 2px;
                        `}
                      >
                        <span
                          css={css`
                            font-weight: 700;
                            font-size: 24px;
                            letter-spacing: 0.05em;
                            font-family: ${isCanlan
                              ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                              : 'Rift, Arial, Helvetica, sans-serif'};
                          `}
                        >
                          {currency &&
                            i18n.formatCurrency(
                              selectedBillingSchedule?.billing_schedule?.find(
                                (el) => !el.paid
                              )?.subtotal,
                              {
                                currency: currency,
                                form: 'explicit',
                              }
                            )}
                        </span>
                        <p
                          css={css`
                            color: ${colors.ATTENDANCE_GRAY};
                            font-size: 16px;
                            font-size: 12px;
                            margin-top: 5px;
                          `}
                        >
                          {dayjs(
                            selectedBillingSchedule?.billing_schedule?.find(
                              (el) => !el.paid
                            )?.due_date
                          ).format('MMMM DD, YYYY')}
                        </p>
                      </div>
                    )}
                  />
                )}

                <CustomRegistrationIndicatorCard
                  iconName='wallet'
                  title='Total Past Due'
                  renderContent={() => (
                    <div
                      css={css`
                        margin-top: 2px;
                        display: block;
                      `}
                    >
                      <span
                        css={css`
                          font-weight: 700;
                          font-size: 24px;
                          letter-spacing: 0.05em;
                          font-family: ${isCanlan
                            ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                            : 'Rift, Arial, Helvetica, sans-serif'};
                        `}
                      >
                        {currency &&
                          i18n.formatCurrency(
                            calculatePastDues(
                              selectedBillingSchedule?.billing_schedule
                            ),
                            {
                              currency: currency,
                              form: 'explicit',
                            }
                          )}
                      </span>
                    </div>
                  )}
                />
              </div>
              {/* Content */}
              <GenericTable
                data={selectedBillingSchedule?.billing_schedule || []}
                headers={[
                  'Transaction',
                  'Due',
                  'subtotal',
                  'Installment',
                  'Payment Status',
                  selectedBillingSchedule?.billing_schedule[0].user_id ===
                    me?.id && 'Options',
                ]}
                headersFlexSize={headerLength}
                content={() =>
                  selectedBillingSchedule?.billing_schedule?.map(
                    (item, index) => (
                      <div
                        key={String(index)}
                        css={css`
                          flex: 1;
                          background-color: ${index % 2 === 0
                            ? colors.HEADER
                            : '#1a222b'};
                          display: flex;
                          align-items: center;
                          flex-direction: row;
                          height: 50px;
                          padding: 8px;
                        `}
                      >
                        <div
                          css={css`
                            display: flex;
                            flex: 1.5;
                            align-items: center;
                            flex-direction: row;
                          `}
                        >
                          <span>
                            {item?.single_payment
                              ? 'One Time Payment'
                              : item?.payment_type === 'deposit'
                              ? 'Deposit'
                              : 'Installment Payment'}
                          </span>
                        </div>
                        <div
                          css={css`
                            flex: 1;
                          `}
                        >
                          <span
                            css={css`
                              color: ${dayjs(item.due_date).isBefore(dayjs()) &&
                              !item.paid
                                ? colors.ERROR_LIGHT
                                : colors.WHITE};
                            `}
                          >
                            {dayjs(item.due_date).format('MMMM DD, YYYY')}
                          </span>
                        </div>
                        <div
                          css={css`
                            flex: 1;
                          `}
                        >
                          <span>
                            {currency &&
                              i18n.formatCurrency(item.subtotal, {
                                currency: currency,
                                form: 'short',
                              })}
                          </span>
                        </div>
                        <div
                          css={css`
                            flex: 1;
                            display: flex;
                          `}
                        >
                          {index > 0
                            ? index +
                              '/' +
                              (selectedBillingSchedule?.billing_schedule
                                ?.length -
                                1)
                            : '--'}
                        </div>
                        <div
                          css={css`
                            flex: 1.3;
                            display: flex;
                          `}
                        >
                          <StatusBadge
                            outline
                            status={
                              item.paid
                                ? 'green'
                                : dayjs(item.due_date).isBefore(dayjs()) &&
                                  !item.paid
                                ? 'red'
                                : 'yellow'
                            }
                            options={{
                              green: 'green',
                              red: 'red',
                              yellow: 'yellow',
                            }}
                            optionsValues={{
                              green: 'Paid',
                              red: 'Past Due',
                              yellow: 'Pending',
                            }}
                          />
                        </div>
                        {me.id === item?.user_id && (
                          <div
                            css={css`
                              flex: 1;
                              display: flex;
                            `}
                          >
                            <button
                              disabled={!item.paid}
                              css={css`
                                cursor: ${!item.paid
                                  ? 'not-allowed'
                                  : 'pointer'};
                                opacity: ${!item.paid ? 0.3 : 1};
                              `}
                              onClick={() => {
                                loadReceipt(item?.uid, index)
                                setBillingId(item?.uid)
                              }}
                            >
                              <Icon
                                name='receipt'
                                color={
                                  item.paid
                                    ? colors.DEFAULT_FLAIR
                                    : colors.ATTENDANCE_GRAY
                                }
                                fontSize={18}
                              />
                            </button>
                            <button
                              disabled={item.paid}
                              css={css`
                                cursor: ${item.paid
                                  ? 'not-allowed'
                                  : 'pointer'};
                                opacity: ${item.paid ? 0.3 : 1};
                                margin-left: 15px;
                              `}
                              onClick={() =>
                                history.push(
                                  `${ROUTES.REGISTRATION_PAYMENT}?teamId=${selectedBillingSchedule?.uid}&rosterId=${selectedBillingSchedule?.team_roster_id}&billingId=${item.uid}`
                                )
                              }
                            >
                              <Icon
                                name='money-bill'
                                color={
                                  !item.paid
                                    ? colors.DEFAULT_FLAIR
                                    : colors.ATTENDANCE_GRAY
                                }
                                fontSize={18}
                              />
                            </button>
                          </div>
                        )}
                      </div>
                    )
                  )
                }
              />
            </div>
          </div>
        </NewModal>
        <NewModal ref={receiptModalRef} shouldCloseOnOverlayClick={false}>
          <div
            css={css`
              background-color: ${colors.HEADER};
              padding: 40px 32px 40px 32px;
              transition: all 0.3s ease;
            `}
          >
            {receiptIsLoading ? (
              <div
                css={css`
                  height: 200px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 200px;
                `}
              >
                <LoadingSpinner />
              </div>
            ) : (
              <>
                <p
                  css={css`
                    font-family: ${isCanlan
                      ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                      : 'Rift, Arial, Helvetica, sans-serif'};
                    color: white;
                    font-size: 48px;
                    font-weight: 700;
                    margin-bottom: 40px;
                  `}
                >
                  RECEIPT
                </p>
                <div
                  css={css`
                    display: flex;
                    margin-bottom: 24px;
                  `}
                >
                  <div
                    css={css`
                      min-width: 150px;
                      * {
                        color: ${colors.ATTENDANCE_GRAY};
                        font-size: 16px;
                      }
                    `}
                  >
                    <span>Transaction ID</span>
                  </div>
                  <span
                    css={css`
                      font-size: 16px;
                      color: ${colors.WHITE};
                    `}
                  >
                    {selectedReceipt?.item?.charge?.stripe_id}
                  </span>
                </div>
                <div
                  css={css`
                    display: flex;
                    margin-bottom: 24px;
                  `}
                >
                  <div
                    css={css`
                      min-width: 150px;
                      * {
                        color: ${colors.ATTENDANCE_GRAY};
                        font-size: 16px;
                      }
                    `}
                  >
                    <span>Subtotal Sent</span>
                  </div>
                  <span
                    css={css`
                      font-size: 16px;
                      color: ${colors.WHITE};
                    `}
                  >
                    {selectedReceipt?.item?.paid
                      ? currency &&
                        i18n.formatCurrency(selectedReceipt?.item?.subtotal, {
                          currency: currency,
                          form: 'explicit',
                        })
                      : currency &&
                        i18n.formatCurrency(0, {
                          currency: currency,
                          form: 'short',
                        })}
                  </span>
                </div>
                <div
                  css={css`
                    display: flex;
                    margin-bottom: 24px;
                  `}
                >
                  <div
                    css={css`
                      min-width: 150px;
                      * {
                        color: ${colors.ATTENDANCE_GRAY};
                        font-size: 16px;
                      }
                    `}
                  >
                    <span>Date Paid</span>
                  </div>
                  <span
                    css={css`
                      font-size: 16px;
                      color: ${colors.WHITE};
                    `}
                  >
                    {dayjs(selectedReceipt?.item?.charge?.created_at).format(
                      'MMMM DD, YYYY'
                    )}
                  </span>
                </div>
                <div
                  css={css`
                    display: flex;
                    margin-bottom: 24px;
                  `}
                >
                  <div
                    css={css`
                      min-width: 150px;
                      * {
                        color: ${colors.ATTENDANCE_GRAY};
                        font-size: 16px;
                      }
                    `}
                  >
                    <span>Installment</span>
                  </div>
                  <span
                    css={css`
                      font-size: 16px;
                      color: ${colors.WHITE};
                    `}
                  >
                    {selectedReceipt?.index > 0
                      ? selectedReceipt?.index +
                        '/' +
                        (billingDetails.length - 1)
                      : '--'}
                  </span>
                </div>
                <div
                  css={css`
                    display: flex;
                    margin-bottom: 24px;
                  `}
                >
                  <div
                    css={css`
                      min-width: 150px;
                      * {
                        color: ${colors.ATTENDANCE_GRAY};
                        font-size: 16px;
                      }
                    `}
                  >
                    <span>Description</span>
                  </div>
                  <span
                    css={css`
                      font-size: 16px;
                      color: ${colors.WHITE};
                    `}
                  >
                    {selectedReceipt?.item?.single_payment
                      ? 'One Time Payment'
                      : selectedReceipt?.item?.payment_type === 'deposit'
                      ? 'Deposit'
                      : 'Installment Payment'}
                  </span>
                </div>
                {downloadReceiptError ? (
                  <Space direction='vertical' style={{ width: '100%' }}>
                    <Alert
                      message={downloadReceiptError}
                      type='error'
                      showIcon
                    />
                  </Space>
                ) : null}
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 40px;
                  `}
                >
                  <button
                    onClick={() => {
                      receiptModalRef?.current?.closeModal()
                      setBillingId(null)
                    }}
                    css={css`
                      border: 1px solid ${colors.WHITE};
                      border-radius: 4px;
                      padding: 8px 25px;
                      font-size: 18px;
                      color: ${colors.WHITE};
                      font-family: ${isCanlan
                        ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                        : 'Rift, Arial, Helvetica, sans-serif'};
                    `}
                  >
                    CLOSE
                  </button>
                  <button
                    ref={downloadRef}
                    disabled={pdfIsLoading}
                    onClick={onView}
                    css={css`
                      border: 1px solid ${colors.DEFAULT_FLAIR};
                      border-radius: 4px;
                      padding: 8px 0px;
                      align-items: center;
                      justify-content: center;
                      flex: 1;
                      display: flex;
                      margin-left: 20px;
                    `}
                  >
                    {pdfIsLoading ? (
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                        `}
                      >
                        <div
                          css={css`
                            width: 10px;
                            margin-right: 12px;
                          `}
                        >
                          <LoadingSpinner size={1.5} />
                        </div>
                        <span
                          css={css`
                            color: ${colors.WHITE};
                            font-size: 18px;
                            font-family: ${isCanlan
                              ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                              : 'Rift, Arial, Helvetica, sans-serif'};
                          `}
                        >
                          PROCESSING...
                        </span>
                      </div>
                    ) : (
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                        `}
                      >
                        <Icon
                          color={colors.WHITE}
                          fontSize={18}
                          name='file-pdf'
                        />
                        <span
                          css={css`
                            color: ${colors.WHITE};
                            font-size: 18px;
                            margin-left: 10px;
                            font-family: ${isCanlan
                              ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                              : 'Rift, Arial, Helvetica, sans-serif'};
                          `}
                        >
                          DOWNLOAD PDF
                        </span>
                      </div>
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </NewModal>
      </>
    )
  }
)

export default DivisionTeams
