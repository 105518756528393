import { action, computed, makeObservable, observable } from 'mobx'

export class RegistrationTeamStore {
  rootStore
  team = {}

  constructor(rootStore) {
    this.rootStore = rootStore

    makeObservable(this, {
      team: observable,
      setTeam: action,
      state: computed,
    })
  }

  setTeam = (team) => {
    this.team = team
  }

  get state() {
    return {
      team: this.team,
    }
  }
}
