import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

export const init = () => {
  try {
    const packageJson = require('./../../package.json')
    const version = packageJson.version
    Sentry.init({
      enabled: __DEV__ ? false : true,

      environment: __DEV__ ? 'development' : 'production',
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new BrowserTracing()],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0.1,
      release: version,
      // https://github.com/getsentry/sentry-javascript/issues/1866#issuecomment-460984819
      ignoreErrors: ['AbortError'],
    })
  } catch (e) {
    console.error('Error initializing Sentry', e)
  }
}

/**
 * Registers an error with Sentry.
 *
 * @param {Error} errorObject - The error object to be captured by Sentry.
 * @param {string} customMessage - A custom message to be associated with the error.
 * @param {Object} extraContext - Additional context to be captured along with the error.
 */
export const registerError = (
  errorObject,
  customMessage,
  extraContext = {}
) => {
  try {
    if (__DEV__) {
      console.error(`${customMessage}`, errorObject)
      return
    }
    Sentry.withScope((scope) => {
      scope.setExtra('error_object', errorObject)
      if (errorObject?.name) {
        scope.setTag('error_type', errorObject.name)
      }
      if (errorObject?.stack) {
        scope.setExtra('stack', errorObject.stack)
      }
      if (errorObject?.url) {
        scope.setExtra('url', errorObject.url)
      }
      if (errorObject?.status) {
        scope.setExtra('status', errorObject.status)
      }
      if (customMessage) {
        scope.setExtra('custom_message', customMessage)
      }
      if (extraContext) {
        scope.setContext(extraContext)
      }
      Sentry.captureException(new Error(customMessage))
    })
  } catch (e) {
    if (__DEV__) {
      console.error('Error registering error on Sentry', e)
    } else {
      Sentry.captureException(e)
    }
  }
}

/**
 * Registers a message to Sentry for error tracking.
 * @param {string} message - The message to be sent to Sentry.
 */
export const registerMessage = (message) => {
  try {
    if (__DEV__) {
      console.log(`Sent message to Sentry: ${message}`)
    } else {
      Sentry.captureMessage(`Sent message to Sentry: ${message}`)
    }
  } catch (e) {
    if (__DEV__) {
      console.error('Error registering message on Sentry', e)
    } else {
      Sentry.captureException(e)
    }
  }
}
