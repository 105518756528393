import { generateActions } from './utils'
import { prefixes } from '../constants/actionPrefixes'

const definitions = {
  listAll: [],
  readNotification: ['notificationId'],
  readAll: [],
}

export default generateActions([prefixes.NOTIFICATIONS], definitions)
