import { call, put, select } from 'redux-saga/effects'
import req from '@sportninja/common/api/request'

import { t } from '../i18n'
import actions from '../actions/team'
import { createEntity } from './helpers'
import { readFactory, readWithPagesFactory } from './helpers/read'
import { updateEntityFactory } from './helpers/update'
import { deleteEntityFactory } from './helpers/delete'
import entityImageSagas from './helpers/entity-image'
import venuesSagas from './helpers/venues'
import usersSagas from './helpers/users'
import feedSagas from './helpers/feed'
import attemptScoringRequest from './attempt-scoring-request'
import { ENTITY_TYPES, generateSagas, getYearInTheFuture, wait } from './utils'

const ENTITY_TYPE = ENTITY_TYPES.team
const nextYearOfGames = getYearInTheFuture()

export const api = {
  create: async (body) => await req('/teams', { method: 'POST', body }),

  schedules: {
    read: async (id, page = 1, sort, direction) =>
      await req(`/teams/${id}/schedules`, { query: { page, sort, direction } }),
  },

  games: {
    read: async (
      id,
      page = 1,
      starts_at,
      ends_at = nextYearOfGames,
      sort,
      direction
    ) => {
      const query = {
        order: direction,
        page,
        team_id: id,
        exclude_cancelled_games: 1,
      }
      if (starts_at) {
        query.starts_after = starts_at
      }
      if (ends_at) {
        query.starts_before = ends_at
      }

      return await req('/games', { query })
    },
  },

  roster: {
    readPlayers: async (id) =>
      await req(`/teams/${id}/players`, { method: 'GET' }),
    readPlayersBySeason: async (id, seasonId) =>
      await req(`/teams/${id}/players`, {
        method: 'GET',
        query: { season_id: seasonId },
      }),
    readOfficials: async (id, page = 1, sort, direction) =>
      await req(`/teams/${id}/team-officials`, {
        method: 'GET',
        query: {
          page,
          sort,
          direction,
        },
      }),
  },

  player: {
    create: async (id, rosterId, body) =>
      await req(`/teams/${id}/rosters/${rosterId}/players`, {
        method: 'POST',
        body,
        timeout: 10000,
      }),
    update: async (id, rosterId, playerId, body, game_id) =>
      await req(
        `/teams/${id}/rosters/${rosterId}/players/${playerId}?game_id=${game_id}`,
        {
          method: 'PUT',
          body,
        }
      ),
    delete: async (id, rosterId, playerId) =>
      await req(`/teams/${id}/rosters/${rosterId}/players/${playerId}`, {
        method: 'DELETE',
      }),

    image: {
      create: async (teamId, playerId, body) =>
        await req(`/teams/${teamId}/players/${playerId}/image`, {
          method: 'POST',
          body,
        }),
      delete: async (teamId, playerId) =>
        await req(`/teams/${teamId}/players/${playerId}/image`, {
          method: 'DELETE',
        }),
    },
  },

  teamOfficial: {
    create: async (id, rosterId, body) =>
      await req(`/teams/${id}/rosters/${rosterId}/team-officials`, {
        method: 'POST',
        body,
      }),
    update: async (id, rosterId, teamOfficialId, body) =>
      await req(
        `/teams/${id}/rosters/${rosterId}/team-officials/${teamOfficialId}`,
        {
          method: 'PUT',
          body,
        }
      ),
    delete: async (id, rosterId, teamOfficialId) =>
      await req(
        `/teams/${id}/rosters/${rosterId}/team-officials/${teamOfficialId}`,
        {
          method: 'DELETE',
        }
      ),
  },
}

const team = [
  [
    actions.create,
    function* ({ form }) {
      const { imageData, parent_id, ...body } = form

      const sportsTypes = yield select((state) => state.types.sports)
      let sport_id

      for (let key in sportsTypes) {
        if (sportsTypes[key].name === 'hockey') {
          sport_id = key
          break
        }
      }

      const setup = {
        bodyTransform: {
          organization_id: parent_id,
          sport_id,
          name: body.name_full,
        },
      }

      const response = yield call(
        createEntity,
        api.create,
        actions.create.success,
        body,
        setup,
        parent_id
      )

      if (typeof imageData !== 'undefined') {
        try {
          yield wait(actions.image.create.request, response.data.id, imageData)
        } catch (e) {
          response.error = `${t('errors:imageUploadProblem')}.`
        }
      }

      return response
    },
  ],
  [actions.read, readFactory(ENTITY_TYPE, actions.read)],
  [actions.update, updateEntityFactory(ENTITY_TYPE, actions)],
  [actions.delete, deleteEntityFactory(ENTITY_TYPE, actions.delete)],

  [
    actions.games.read,
    function* (payload) {
      const { id, page, starts_at, ends_at, sort, direction } = payload
      const response = yield call(
        api.games.read,
        id,
        page,
        starts_at,
        ends_at,
        sort,
        direction
      )

      yield put(actions.games.read.success({ id, data: response.data }))
      return response
    },
  ],

  [
    actions.schedules.read,
    readWithPagesFactory(api.schedules.read, actions.schedules.read),
  ],
]

const roster = [
  [
    actions.roster.readPlayersBySeason,
    function* (payload) {
      const { id, seasonId } = payload

      const response = yield call(api.roster.readPlayersBySeason, id, seasonId)
      yield put(actions.roster.readPlayers.success({ id, data: response.data }))
      return response
    },
  ],
  [
    actions.roster.readPlayers,
    function* (payload) {
      const { id } = payload

      const response = yield call(api.roster.readPlayers, id)
      yield put(actions.roster.readPlayers.success({ id, data: response.data }))
      return response
    },
  ],

  [
    actions.roster.player.create,
    function* (payload) {
      const { id, rosterId, form: _form } = payload
      const { imageData, queueForOffline, ...form } = _form
      // The API will barf if we have an empty email string here (i.e. someone entered
      // one and then deleted it)
      // For now, all other form values are required so no further checking required
      if (form.email && form.email.length === 0) {
        delete form.email
      }
      // Change related to: SN-4217
      if (form.link_to_existing_player) {
        form.relationship = form.link_to_existing_player?.selectedRelationship
        form.link_to_existing_player =
          form?.link_to_existing_player?.linkPlayerToParent
      }
      const method = function* () {
        const response = yield call(
          api.player.create,
          id,
          rosterId,
          JSON.stringify(form)
        )
        yield put(
          actions.roster.player.create.success({ id, data: response.data })
        )

        return response
      }

      let response

      if (queueForOffline) {
        response = yield attemptScoringRequest(
          function* () {
            return yield method()
          },
          {
            type: actions.roster.player.create.request,
            payload,
          }
        )
      } else {
        response = yield method()
      }

      if (response && typeof imageData !== 'undefined') {
        try {
          yield wait(
            actions.roster.player.image.create.request,
            id,
            response?.data?.id,
            imageData
          )
        } catch (e) {
          response.error = `${t('errors:imageUploadProblem')}.`
        }
      }

      return response
    },
  ],

  [
    actions.roster.player.update,
    function* (payload) {
      const { id, rosterId, playerId, form: _form, gameId } = payload
      const { imageData, delete_image, ...form } = _form

      // delete the phone_number property before sending the request
      if (form.phone_number) {
        delete form.phone_number
      }

      const response = yield call(
        api.player.update,
        id,
        rosterId,
        playerId,
        JSON.stringify(form),
        gameId
      )
      yield put(
        actions.roster.player.update.success({
          id,
          playerId,
          data: response.data,
        })
      )

      if (typeof imageData !== 'undefined') {
        try {
          yield wait(
            actions.roster.player.image.create.request,
            id,
            playerId,
            imageData
          )
        } catch (e) {
          response.error = `${t('errors:imageUploadProblem')}.`
        }
      } else if (delete_image) {
        yield wait(actions.roster.player.image.delete.request, id, playerId)
      }

      return response
    },
  ],

  [
    actions.roster.player.delete,
    function* (payload) {
      const { id, rosterId, playerId } = payload

      const response = yield call(api.player.delete, id, rosterId, playerId)
      yield put(actions.roster.player.delete.success({ id, playerId }))
      return response
    },
  ],

  [
    actions.roster.player.image.create,
    function* (payload) {
      const { teamId, playerId, imageData } = payload

      const response = yield call(
        api.player.image.create,
        teamId,
        playerId,
        imageData
      )
      yield put(
        actions.roster.player.image.create.success({
          teamId,
          playerId,
          data: response.data,
        })
      )
      return response
    },
  ],

  [
    actions.roster.player.image.delete,
    function* (payload) {
      const { teamId, playerId } = payload

      const response = yield call(api.player.image.delete, teamId, playerId)
      yield put(
        actions.roster.player.image.delete.success({ teamId, playerId })
      )
      return response
    },
  ],
]

const teamOfficials = [
  [
    actions.roster.readTeamOfficials,
    function* (payload) {
      const { id, page, sort, direction } = payload

      const response = yield call(
        api.roster.readOfficials,
        id,
        page,
        sort,
        direction
      )
      yield put(
        actions.roster.readTeamOfficials.success({ id, data: response.data })
      )
      return response
    },
  ],

  [
    actions.roster.teamOfficial.create,
    function* (payload) {
      const { team, form: _form, rosterId } = payload
      const { imageData, ...form } = _form
      // The API will barf if we have an empty email string here (i.e. someone entered
      // one and then deleted it)
      // For now, all other form values are required so no further checking required
      if (form.email && form.email.length === 0) {
        delete form.email
      }

      const id = team.id

      const response = yield call(
        api.teamOfficial.create,
        id,
        rosterId,
        JSON.stringify(form)
      )
      yield put(
        actions.roster.teamOfficial.create.success({ id, data: response.data })
      )

      if (typeof imageData !== 'undefined') {
        try {
          yield wait(
            actions.roster.teamOfficial.image.create.request,
            id,
            response.data.id,
            imageData
          )
        } catch (e) {
          response.error = `${t('errors:imageUploadProblem')}.`
        }
      }

      return response
    },
  ],

  [
    actions.roster.teamOfficial.update,
    function* (payload) {
      const { team, id: teamOfficialId, form: _form, rosterId } = payload
      const { imageData, delete_image, ...form } = _form

      const id = team.id

      const response = yield call(
        api.teamOfficial.update,
        id,
        rosterId,
        teamOfficialId,
        JSON.stringify(form)
      )
      yield put(
        actions.roster.teamOfficial.update.success({
          id,
          teamOfficialId,
          data: response.data,
        })
      )

      if (typeof imageData !== 'undefined') {
        try {
          yield wait(
            actions.roster.teamOfficial.image.create.request,
            id,
            teamOfficialId,
            imageData
          )
        } catch (e) {
          response.error = `${t('errors:imageUploadProblem')}.`
        }
      } else if (delete_image) {
        yield wait(
          actions.roster.teamOfficial.image.delete.request,
          id,
          teamOfficialId
        )
      }

      return response
    },
  ],

  [
    actions.roster.teamOfficial.delete,
    function* (payload) {
      const { team: teamObject, rosterId, id: teamOfficialId } = payload
      const id = teamObject.id

      const response = yield call(
        api.teamOfficial.delete,
        id,
        rosterId,
        teamOfficialId
      )
      yield put(
        actions.roster.teamOfficial.delete.success({ id, teamOfficialId })
      )
      return response
    },
  ],
]

export default generateSagas([
  ...team,
  ...roster,
  ...teamOfficials,
  ...entityImageSagas(ENTITY_TYPE, actions.image),
  ...venuesSagas(ENTITY_TYPE, actions.venues),
  ...usersSagas(ENTITY_TYPE, actions.users),
  ...feedSagas(ENTITY_TYPE, actions.feed),
])
