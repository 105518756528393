import styled from '@emotion/styled'
import colors from '@sportninja/common/constants/appColors'
import { media, minMedia } from '../Responsive'

const ReportsTableComponent = styled.table`
  border: 1px solid #3f4753;

  thead {
    tr {
      height: 44px;
    }
  }

  tr.is-inactive {
    opacity: 0.6;
  }

  tbody tr {
    height: 56px;
    border-top: 2px solid #3f4753;
    border-bottom: 2px solid #3f4753;

    &:nth-of-type(even) {
      background-color: #292e35;
    }

    &:last-of-type {
      border-bottom: 0;
    }
  }

  th {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
  }

  td {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  th,
  td {
    vertical-align: middle;
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;

    &.comment {
      max-width: 300px;
    }

    &.edit {
      min-width: 64px;
      max-width: 64px;
    }

    .comment-inner {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    ${media.ten} {
      width: 150px;
      // min-width: 150px;
      // max-width: 200px;

      .initials-thumbnail,
      .image-wrap {
        display: none;
      }

      .thumbnail-and-name-text-wrap {
        margin-left: 0;
      }
    }
  }

  .col-1 {
    border-right: 1px solid #3f4753;
  }

  .attachment {
    position: relative;
    color: ${colors.PRIMARY};

    &.has-attachment {
      padding-left: 42px;
    }
  }

  .attachment-svg {
    left: 16px;
    top: 16px;
    position: absolute;
  }

  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .suspensions-edit-pencil {
    path {
      fill: ${colors.PRIMARY};
    }
    cursor: pointer;

    &:hover {
      filter: brightness(2);
    }
  }

  .no-results {
    text-align: center;

    &.loading {
      padding: 20px 0;
    }
  }
`

export default ReportsTableComponent
