import req from '@sportninja/common/api/request'
import { useEffect, useRef, useState } from 'react'

const useGetListItems = (slug, options) => {
  const {
    onComplete = () => {},
    sortDefault = 'name_full',
    directionDefault = 'asc',
    query,
    dataTransformer,
  } = options || {}

  const mounted = useRef(true)

  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [error, setError] = useState(false)

  const [permissions, setPermissions] = useState({})
  const [features, setFeatures] = useState({})
  const [pagination, setPagination] = useState({})
  const [sort, setSort] = useState(sortDefault)
  const [direction, setDirection] = useState(directionDefault)
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm)

  const abortControllers = useRef([])

  useEffect(() => {
    let timeout

    const debounce = () => {
      timeout = setTimeout(() => {
        setDebouncedSearchTerm(searchTerm)
      }, 400)
    }

    clearTimeout(timeout)
    debounce()

    return () => {
      clearTimeout(timeout)
    }
  }, [searchTerm])

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const getItems = async (page = 1, query) => {
    setLoading(true)
    setError(false)

    // We store an AbortController for each request, so upon a new search term,
    // signal an abort for any stale requests in the list
    abortControllers.current.forEach((controller) => {
      controller.abort()
    })
    abortControllers.current = []
    const abortController = new AbortController()
    abortControllers.current.push(abortController)

    try {
      const queryObject = { page, ...query }
      if (sort?.length > 0) {
        queryObject.sort = sort
      }
      if (direction?.length > 0) {
        queryObject.direction = direction
      }

      const search = {}

      if (searchTerm.length > 0) {
        search.query = searchTerm
      }

      const response = await req(slug, {
        query: { ...queryObject, ...query, ...search },
        signal: abortController.signal,
      })

      const { data: _data, meta } = response

      let data = _data
      if (typeof dataTransformer === 'function') {
        data = dataTransformer(data)
      }

      if (mounted.current) {
        setPermissions(meta?.permissions)
        setFeatures(meta?.features)
        setPagination(meta?.pagination)
        setItems(data)
        onComplete(response)
        setLoading(false)
      }
    } catch (e) {
      if (!abortController.signal.aborted) {
        setError(e.message)
        setLoading(false)
      }
    }
  }

  return {
    loading,
    error,
    items,
    sort,
    setSort,
    direction,
    setDirection,
    getItems,
    pagination,
    permissions,
    searchTerm,
    setSearchTerm,
    debouncedSearchTerm,
    features,
  }
}

export default useGetListItems
