import { useContext, useEffect, useState } from 'react'
import req from '@sportninja/common/api/request'

import BaseActionButton from '../../components/Page/BaseActionButton'
import { ScheduleReprocessingContext } from '../../components/ScheduleReprocessingContext'
import { t } from '@sportninja/common/i18n'
import { FormButton } from '../../components/Form/css'
import TextModal from '../../components/Modal/TextModal'
import { Flex } from '../../components/Layout'

const ReprocessStats = ({ id, onSuccess }) => {
  const { listeners, addPending } = useContext(ScheduleReprocessingContext)
  const [localLoading, setLocalLoading] = useState(false)
  const [isPending, setIsPending] = useState(!!listeners[id])

  useEffect(() => {
    if (isPending && !listeners[id]) {
      onSuccess()
    }
  }, [listeners[id]])

  const title = `Reprocess ${t('util:capitalize', {
    text: t('common:competition'),
  })} Statistics`

  return (
    <TextModal
      title={title}
      link={(toggleOpen) => (
        <BaseActionButton
          disabled={localLoading || listeners[id]}
          loading={localLoading || listeners[id]}
          name='sync'
          title={title}
          onClick={toggleOpen}
        />
      )}
    >
      {(toggleOpen) => {
        return (
          <div>
            <p>
              Reprocess statistics when you have made edits to a game, and want
              to update the statistics/standings for the{' '}
              {t('common:competition')}.
            </p>
            <p>
              <span style={{ fontWeight: 700 }}>NOTE:</span> Depending on the
              number of games, this can take several minutes to complete.
            </p>
            <Flex>
              <FormButton onClick={toggleOpen}>Cancel</FormButton>
              <FormButton
                isSubmit
                onClick={async () => {
                  toggleOpen()
                  setLocalLoading(true)
                  try {
                    await req(`/schedules/${id}/reprocess`)
                    addPending(id)
                    setIsPending(true)
                  } finally {
                    setLocalLoading(false)
                  }
                }}
              >
                Reprocess
              </FormButton>
            </Flex>
          </div>
        )
      }}
    </TextModal>
  )
}

export default ReprocessStats
