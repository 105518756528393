/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import req from '@sportninja/common/api/request'
import { ENABLE_REGISTRATION, ROUTES } from '@sportninja/common/constants/app'
import { t } from '@sportninja/common/i18n'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import parsers from '@sportninja/common/reducers/parsers'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import Page from '../../components/Page'
import FormSheet from '../../components/Form/FormSheet'
import { feedItemForm, teamForm } from '../../components/Form/form-configs'
import UserList, { AddUser } from '../../components/List/UserList'
import FavouriteButton from '../../components/Page/FavouriteButton'
import PlayerList from '../../components/List/PlayerList'
import TeamOfficialList, {
  AddTeamOfficial,
} from '../../components/List/TeamOfficialList'
import GameList from '../../components/List/GameList'
import NewButton from '../../components/Page/NewButton'
import FileDownload from '../../components/FileDownload'
import BaseActionButton from '../../components/Page/BaseActionButton'
import { Mobile } from '../../components/Responsive'
import EditButton from '../../components/Page/EditButton'
import EntityFormDelete from '../../components/Form/EntityFormDelete'
import ImportTeamRoster from '../../components/ImportCSV/ImportTeamRoster'
import { getBreadcrumbs, useReadItem, useStoreRecent } from '../utils'
import Home from '../Home'
import EditEntitySubTitle from '../EditEntitySubTitle'
import CreatePlayerSheet from './CreatePlayerSheet'
import FinancialList from '../../components/List/FinancialList'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import {
  getHockeySport,
  getSoccerSport,
} from '@sportninja/common/selectors/types'
import { NewModal } from 'src/components/NewModal'
import { Button } from 'src/components/Button'
import calendarImg from './calendar.svg'
import outlookIcon from './outlook-icon.svg'
import calendarIcon from './calendar-icon.svg'
import colors from '@sportninja/common/constants/appColors'
import Icon from 'src/components/Icon'
import { Input } from 'src/components/Input'
import TeamStatistics from 'src/components/v2/TeamStatistics'
import { useSports } from '@sportninja/common/hooks/useSports'
import GeneratePluginModal from 'src/components/GeneralPluginModal'

/**
 * Determines whether a player can be added based on the provided parameters.
 *
 * @param {Object} permission - The permission object.
 * @param {string} rosterId - The ID of the roster.
 * @param {boolean} isCanlan - Indicates if it is a Canlan roster.
 * @param {boolean} sync_salesforce - Indicates if Salesforce sync is enabled.
 * @param {string} organization - The organization.
 * @param {boolean} isCurrentDateAfterDivisionOrCompetitionLockDate - Indicates if the current date is after the division or competition lock date.
 * @returns {boolean} - Returns true if the player can be added, otherwise false.
 */
const shouldAllowAddPlayer = (
  permission,
  rosterId,
  isCanlan,
  sync_salesforce,
  organization,
  isCurrentDateAfterDivisionOrCompetitionLockDate = false
) => {
  if (!isCanlan) {
    if (permission.admin) {
      return true
    }
    return (
      permission.update &&
      rosterId &&
      organization &&
      !isCurrentDateAfterDivisionOrCompetitionLockDate
    )
  }
  if (sync_salesforce && permission.admin === false) {
    return false
  }
  return permission.update && rosterId && organization
}

/**
 * Determines whether to allow adding an import roster based on the provided parameters.
 *
 * @param {Object} permission - The permission object.
 * @param {string} rosterId - The roster ID.
 * @param {boolean} isCanlan - Indicates if it is Canlan.
 * @param {boolean} sync_salesforce - Indicates if Salesforce sync is enabled.
 * @param {boolean} isCurrentDateAfterDivisionOrCompetitionLockDate - Indicates if the current date is after the division or competition lock date.
 * @returns {boolean} - Returns true if adding an import roster is allowed, otherwise false.
 */
const shouldAllowAddImportRoster = (
  permission,
  rosterId,
  isCanlan,
  sync_salesforce,
  isCurrentDateAfterDivisionOrCompetitionLockDate = false
) => {
  if (!isCanlan) {
    if (permission.admin) {
      return true
    }
    return (
      permission.update &&
      rosterId &&
      !isCurrentDateAfterDivisionOrCompetitionLockDate
    )
  }
  if (sync_salesforce && permission.admin === false) {
    return false
  }
  return permission.update && rosterId
}

/**
 * Determines whether adding team officials is allowed based on the provided parameters.
 *
 * @param {Object} permission - The permission object.
 * @param {string} rosterId - The roster ID.
 * @param {boolean} isCanlan - Indicates if it is Canlan.
 * @param {boolean} sync_salesforce - Indicates if Salesforce sync is enabled.
 * @param {string} organization - The organization.
 * @returns {boolean} - Returns true if adding team officials is allowed, otherwise false.
 */
const shouldAllowAddTeamOfficials = (
  permission,
  rosterId,
  isCanlan,
  sync_salesforce,
  organization
) => {
  if (!isCanlan) {
    return permission.update && rosterId && organization
  }
  if (sync_salesforce && permission.admin === false) {
    return false
  }
  return permission.update && rosterId && organization
}

const Team = ({ match, isInFrame, soccerSportId, hockeySportId }) => {
  const history = useHistory()
  const [rosterId, setRosterId] = useState('')
  const [players, setPlayers] = useState([])
  const [creatingPlayersRequiresEmail, setCreatingPlayersRequiresEmail] =
    useState(false)
  const [areFiltersHidden, setAreFiltersHidden] = useState(false)
  const [filterForExporting, setFilterForExporting] = useState(null)
  const [calendarBlob, setCalendarBlob] = useState(null)
  const [calendarURL, setCalendarURL] = useState(null)
  const [hasCopiedLink, setHasCopiedLink] = useState(false)
  const { sports, sportOptions } = useSports()
  const [lockRosterDate, setLockRosterDate] = useState(null)
  const isCurrentDateAfterDivisionOrCompetitionLockDate = useMemo(() => {
    if (!lockRosterDate) {
      return false
    }
    if (lockRosterDate) {
      return new Date(lockRosterDate) < new Date()
    }
  }, [lockRosterDate])

  const {
    loading,
    error,
    item,
    hierarchy,
    permission,
    readItem,
    refreshKey,
    triggerRefresh,
  } = useReadItem()

  const sync_salesforce = item?.organization?.sync_salesforce || false

  const itemSportFullName = useMemo(() => {
    return sports?.find((sport) => sport.id === item?.sport?.id)?.name_full
  }, [sports, item?.sport?.id])

  const loadCalendarICS = useCallback(async () => {
    const res = await req(`/calendar/${TEAM_ID}/games`, {
      parseJSON: false,
    })

    if (res?.url) {
      setCalendarURL(res.url)
      setCalendarBlob(await res.blob())
    }
  }, [])

  const TEAM_ID = match.params.id?.replace('?sandbox_tour=1', '')
  const BASE_ROUTE = `${ROUTES.TEAM_ROOT}/${TEAM_ID}`
  const IS_PUBLIC = item.is_public
  const isSoccer = item?.sport?.id === soccerSportId
  const isHockey = item?.sport?.id === hockeySportId

  const calendarSyncModalRef = useRef()
  const generatePluginTagModalRef = useRef(null)

  useStoreRecent(ENTITY_TYPES.team, item, error, TEAM_ID, isInFrame)

  useEffect(() => {
    loadCalendarICS()
    readItem(ENTITY_TYPES.team, TEAM_ID)
  }, [TEAM_ID])

  const onEditTeam = async (values) => {
    const { imageData, ...form } = values

    if (form?.name_full) {
      form.name = form.name_full
    }

    if (form?.delete_image) {
      await req(`/teams/${item.id}/image`, {
        method: 'DELETE',
      })
    }

    const body = JSON.stringify(form)
    const response = await req(`/teams/${item.id}`, {
      method: 'PUT',
      body,
    })
    if (imageData) {
      await req(`/teams/${item.id}/image`, {
        method: 'POST',
        body: imageData,
      })
    }
    await readItem(ENTITY_TYPES.team, TEAM_ID)
    return response
  }

  const titleActions = item?.id
    ? [
        <FavouriteButton
          key='favourite'
          initialState={item.is_favorite}
          entityType={ENTITY_TYPES.team}
          entityId={TEAM_ID}
        />,
        permission.update && (
          <FormSheet
            customDeleteModal={
              permission.delete && (
                <EntityFormDelete
                  entityType={ENTITY_TYPES.team}
                  itemId={TEAM_ID}
                  title={`Delete ${item.name_full}`}
                  type={t('common:team')}
                  onComplete={() => {
                    history.push(`${ROUTES.TEAMS.ROOT}`)
                  }}
                />
              )
            }
            key='edit'
            Button={({ onClick }) => (
              <EditButton onClick={onClick} title={item?.name_full} />
            )}
            form={teamForm(
              parsers[ENTITY_TYPES.team]({ ...item, permission }),
              false,
              itemSportFullName,
              sportOptions,
              sports,
              parsers[ENTITY_TYPES.team]({ ...item, permission })?.sport_id
            )}
            label={t('Web:editTeam')}
            onSubmit={onEditTeam}
            title={t('Web:editTeam')}
            subTitle={
              <EditEntitySubTitle id={TEAM_ID} entityType={ENTITY_TYPES.team} />
            }
          />
        ),
      ]
    : []

  const baseActions = []

  const filterToggleButton = (
    <BaseActionButton
      faType={areFiltersHidden ? 'far' : 'fas'}
      key='toggle-filter'
      name='filter'
      title={`Toggle Filters Visibility ${areFiltersHidden ? 'on' : 'off'}`}
      onClick={() => {
        setAreFiltersHidden((v) => !v)
      }}
    />
  )

  const calendarSyncButton = calendarBlob ? (
    <BaseActionButton
      key='toggle-calendar-sync'
      customIcon={
        <img
          src={calendarIcon}
          css={css`
            width: 16px;
            height: 16px;
          `}
        />
      }
      title='Schedule Calendar Sync'
      onClick={() => {
        calendarSyncModalRef.current?.openModal()
      }}
    />
  ) : null

  const generatePluginModalButton = permission?.update ? (
    <BaseActionButton
      key='plug'
      name='plug'
      title={'Generate JS Plugin Tag'}
      onClick={() => {
        generatePluginTagModalRef?.current?.openModal()
      }}
    />
  ) : null

  const pages = [
    {
      to: `${ROUTES.TEAM_ROOT}/${TEAM_ID}/schedule`,
      text: t('common:schedule'),
      component: (
        <GameList
          team_id={TEAM_ID}
          areFiltersHidden={areFiltersHidden}
          setFilterForExporting={setFilterForExporting}
          sportId={item?.sport?.id}
          isSoccer={isSoccer}
          isHockey={isHockey}
        />
      ),
      actions: [
        generatePluginModalButton,
        calendarSyncButton,
        filterToggleButton,
      ],
    },
    {
      to: `${ROUTES.TEAM_ROOT}/${TEAM_ID}/statistics`,
      text: t('common:statistics'),
      component: (
        <TeamStatistics
          defaultSort={isSoccer ? 'G' : 'P'}
          entityType={ENTITY_TYPES.team}
          entityId={TEAM_ID}
          areFiltersHidden={areFiltersHidden}
          autoSelectMostRecentComp
          isSoccer={item?.sport?.id === soccerSportId}
          displaySearch={false}
        />
      ),
      actions: [filterToggleButton],
    },
    !item?.is_placeholder && {
      to: `${ROUTES.TEAM_ROOT}/${TEAM_ID}/roster`,
      text: t('common:roster'),
      component: (
        <PlayerList
          refreshKey={refreshKey}
          teamId={TEAM_ID}
          teamName={item?.name_full}
          permission={permission}
          onComplete={(data) => {
            setLockRosterDate(data.lock_roster_date)
            setRosterId(data.id)
            setPlayers(data.players)
            setCreatingPlayersRequiresEmail(data.require_email)
          }}
          shouldShowWaiverStatus={item?.should_show_waiver}
          isSoccer={item?.sport?.id === soccerSportId}
          sportName={item?.sport?.name}
          sportId={item?.sport?.id}
        />
      ),
      actions: [
        permission.update && rosterId && (
          <FileDownload
            key='download-roster'
            button={(loading, onClick) => {
              return (
                <BaseActionButton
                  loading={loading}
                  onClick={onClick}
                  name='download'
                  title='Export Roster'
                />
              )
            }}
            label={t('common:exportRoster')}
            url={`/teams/${TEAM_ID}/rosters/${rosterId}/players/export/csv`}
            fileName={item.name_full}
            query={filterForExporting}
          />
        ),
        shouldAllowAddImportRoster(
          permission,
          rosterId,
          isCanlan,
          sync_salesforce,
          isCurrentDateAfterDivisionOrCompetitionLockDate
        ) && (
          <ImportTeamRoster
            key='import-roster'
            teamId={TEAM_ID}
            teamName={item.name_full}
            teamRosterId={rosterId}
            onSuccess={triggerRefresh}
          />
        ),
        shouldAllowAddPlayer(
          permission,
          rosterId,
          isCanlan,
          sync_salesforce,
          item.organization,
          isCurrentDateAfterDivisionOrCompetitionLockDate
        ) && (
          <CreatePlayerSheet
            key='add-player'
            teamId={TEAM_ID}
            orgId={item.organization.id}
            orgName={item.organization.name_full}
            rosterId={rosterId}
            players={players}
            hasAdminPermission={permission?.admin}
            creatingPlayersRequiresEmail={creatingPlayersRequiresEmail}
            onSuccess={triggerRefresh}
            sportName={item?.sport?.name}
            sportId={item?.sport?.id}
          />
        ),
      ],
    },
    {
      to: `${ROUTES.TEAM_ROOT}/${TEAM_ID}/feed`,
      text: t('common:feed'),
      component: (
        <Home
          isEntityFeed
          entity={item}
          entityType={ENTITY_TYPES.team}
          entityId={TEAM_ID}
          refreshKey={refreshKey}
          hideSidebar={!permission.update}
        />
      ),
      actions: [
        permission.admin && (
          <Mobile key='add-post'>
            <FormSheet
              label={t('Web:createANewPost')}
              title={t('Web:createANewPost')}
              form={feedItemForm({}, false)}
              onSubmit={async ({ imageData, ...form }) => {
                const body = JSON.stringify(form)
                const response = await req(`/teams/${TEAM_ID}/social-feed`, {
                  method: 'POST',
                  body,
                })
                if (imageData) {
                  await req(`/social-feed/${response.data.id}/image`, {
                    method: 'POST',
                    body: imageData,
                  })
                }
                triggerRefresh()
              }}
              Button={({ onClick }) => <NewButton onClick={onClick} />}
            />
          </Mobile>
        ),
      ],
    },
    !isInFrame &&
      ENABLE_REGISTRATION &&
      !isCanlan &&
      permission.update &&
      !item?.is_placeholder && {
        to: `${ROUTES.TEAM_ROOT}/${TEAM_ID}/billing`,
        text: t('common:billing'),
        privatePage: true,
        component: (
          <FinancialList canEdit={permission.update} teamId={TEAM_ID} />
        ),
        actions: [],
      },
    !isInFrame &&
      permission.update &&
      !item?.is_placeholder && {
        to: `${ROUTES.TEAM_ROOT}/${TEAM_ID}/team-officials`,
        text: t('common:teamOfficial', { count: 2 }),
        privatePage: true,
        component: (
          <TeamOfficialList
            key='team-officials'
            teamId={TEAM_ID}
            teamName={item?.name_full}
            permission={permission}
            onComplete={(data) => {
              setRosterId(data.id)
            }}
            refreshKey={refreshKey}
            sportName={item?.sport?.name}
            sportId={item?.sport?.id}
          />
        ),
        actions: [
          shouldAllowAddTeamOfficials(
            permission,
            rosterId,
            isCanlan,
            sync_salesforce,
            item.organization
          ) && (
            <AddTeamOfficial
              key='add-team-official'
              teamId={TEAM_ID}
              rosterId={rosterId}
              permission={permission}
              onComplete={triggerRefresh}
              sportName={item?.sport?.name}
              sportId={item?.sport?.id}
            />
          ),
        ],
      },
    !isInFrame &&
      permission.admin && {
        to: `${ROUTES.TEAM_ROOT}/${TEAM_ID}/users`,
        text: t('common:user', { count: 2 }),
        privatePage: true,
        component: (
          <UserList
            key='users'
            entityType={ENTITY_TYPES.team}
            entityId={TEAM_ID}
            slug={`/teams/${TEAM_ID}/permissions`}
            refreshKey={refreshKey}
          />
        ),
        actions: [
          permission.admin && (
            <AddUser
              key='add-user'
              entityType={ENTITY_TYPES.team}
              entityId={TEAM_ID}
              permission={permission}
              onComplete={triggerRefresh}
            />
          ),
        ],
      },
  ]

  return (
    <>
      <Page
        isTeamOrSchedule
        error={error}
        loading={loading}
        baseCrumb={
          error
            ? ROUTES.TEAMS.ROOT
            : `${ROUTES.ORG_ROOT}/${item?.organization?.id}/teams`
        }
        baseRoute={BASE_ROUTE}
        baseTitle={item?.organization?.name_full}
        breadcrumbs={getBreadcrumbs(ROUTES.TEAM_ROOT, item, hierarchy)}
        baseActions={baseActions}
        pages={pages}
        isPrivate={!IS_PUBLIC}
        title={
          item?.generic_sport_name
            ? `${item.name_full} (${item?.generic_sport_name})`
            : item.name_full
        }
        iconName='user-friends'
        imageId={getImageThumbnailId(item)}
        titleActions={titleActions}
      />
      <NewModal shouldCloseOnOverlayClick ref={calendarSyncModalRef}>
        <div
          css={css`
            width: 700px;
            padding: 16px;
            background: no-repeat fixed linear-gradient(#282e38, #181a1d);
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            max-height: 80vh;

            @media (max-width: 500px) {
              width: 480px;
            }
          `}
        >
          <button
            onClick={(e) => {
              e.preventDefault()

              calendarSyncModalRef.current?.closeModal()
            }}
            css={css`
              position: absolute;
              top: 30px;
              right: 35px;
            `}
          >
            <Icon name='times' color={colors.ATTENDANCE_GRAY} fontSize={16} />
          </button>
          <div
            css={css`
              display: flex;
              width: 190px;
              height: 130px;
              align-self: center;
            `}
          >
            <img
              src={calendarImg}
              css={css`
                width: 100%;
                height: 100%;
              `}
            />
          </div>
          <div
            css={css`
              align-self: center;
            `}
          >
            <p
              css={css`
                color: ${colors.DEFAULT_FLAIR};
                text-align: center;
                margin: 24px 0;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
              `}
            >
              Subscribe to Calendar
            </p>
            <p
              css={css`
                color: ${colors.WHITE};
                text-align: center;
                font-size: 14px;
                font-style: normal;
                margin-bottom: 16px;
                font-weight: 500;
              `}
            >
              Bring your team calendar to your phone calendar and get all your
              games/events/practices to show up automatically in one place.
            </p>
            <p
              css={css`
                color: ${colors.ATTENDANCE_GRAY};
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
              `}
            >
              To subscribe, select one of the following options, or copy the
              address and subscribe with your favourite email client.
            </p>
          </div>
          <div
            css={css`
              margin-top: 24px;
              display: flex;
              align-items: center;
              align-self: center;
              gap: 24px;

              button {
                gap: 0;
                width: 200px;
                justify-content: center;
              }
            `}
          >
            <Button
              label='GOOGLE'
              labelStyle={css`
                font-family: Rift;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 0.9px;
                text-transform: uppercase;
              `}
              labelColor={colors.HEADER}
              onClick={() => {
                const link = document.createElement('a')
                link.href = `https://calendar.google.com/calendar/r?cid=webcal%3A%2F%2F${calendarURL.replace(
                  'https://',
                  ''
                )}`
                link.target = '_blank'
                link.click()
              }}
              icon={
                <Icon
                  name='google'
                  fontSize={18}
                  color={colors.HEADER}
                  faType='fab'
                />
              }
            />
            <Button
              label='APPLE'
              labelStyle={css`
                font-family: Rift;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 0.9px;
                text-transform: uppercase;
              `}
              labelColor={colors.HEADER}
              onClick={() => {
                const link = document.createElement('a')
                link.href = `webcal://${calendarURL.replace('https://', '')}`
                link.target = '_self'
                link.click()
              }}
              icon={
                <Icon
                  name='apple'
                  fontSize={18}
                  color={colors.HEADER}
                  faType='fab'
                />
              }
            />
            <Button
              label='OUTLOOK'
              labelStyle={css`
                font-family: Rift;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 0.9px;
                text-transform: uppercase;
              `}
              labelColor={colors.HEADER}
              onClick={() => {
                const link = document.createElement('a')
                link.href = `webcal://${calendarURL.replace('https://', '')}`
                link.target = '_self'
                link.click()
              }}
              icon={
                <img
                  src={outlookIcon}
                  css={css`
                    width: 20px;
                    height: 20px;
                  `}
                />
              }
            />
          </div>
          <div
            css={css`
              background-color: ${colors.SOFT_STEEL};
              height: 1px;
              margin: 24px 0;
            `}
          />
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
            `}
          >
            <div
              css={css`
                flex: 6;
              `}
            >
              <Input isMockInput mockValue={String(calendarURL)} />
            </div>
            <div
              css={css`
                flex: 1;
              `}
            >
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(calendarURL)
                  setHasCopiedLink(true)
                  setTimeout(() => {
                    setHasCopiedLink(false)
                  }, 2000)
                }}
                label={hasCopiedLink ? 'Copied!' : 'Copy'}
                labelColor={colors.HEADER}
              />
            </div>
          </div>
          <div
            css={css`
              margin-top: 8px;
            `}
          >
            <Button
              antVariant='link'
              label={`or download the ${item?.name_full
                ?.replace(' ', '-')
                ?.toLowerCase()}-calendar.ics file`}
              labelColor={colors.DEFAULT_FLAIR}
              labelStyle={css`
                font-family: Maison Neue;
                font-size: 14px;
                text-transform: none;
              `}
              onClick={() => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(calendarBlob)
                link.download = `${item?.name_full
                  ?.replace(' ', '-')
                  ?.toLowerCase()}-calendar.ics`
                link.click()
              }}
            />
          </div>
        </div>
      </NewModal>
      <NewModal
        ref={generatePluginTagModalRef}
        shouldCloseOnOverlayClick={false}
      >
        <GeneratePluginModal
          onPressClose={() => {
            generatePluginTagModalRef?.current?.closeModal()
          }}
          scheduleId={item?.id}
          isOrganization={false}
          isTeam={true}
        />
      </NewModal>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isInFrame: state.auth.inFrame,
    soccerSportId: getSoccerSport(state)?.id,
    hockeySportId: getHockeySport(state)?.id,
  }
}

export default connect(mapStateToProps)(Team)
