/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Link } from 'react-router-dom'
import Icon from '../../components/Icon'

import { Flex } from '../../components/Layout'
import { InitialsThumbnail } from '../../components/List/PersonCell'
import Picture from '../../components/Picture'

const ResultCard = ({
  iconName,
  imageId,
  initials,
  name,
  subTitle,
  to,
  onClick,
  onRemove,
  wrapCss,
}) => {
  return (
    <Flex
      noFlex
      as={Link}
      to={to}
      alignItems='center'
      onClick={onClick}
      css={css`
        position: relative;
        height: 88px;
        border: 1px solid ${colors.SOFT_STEEL};
        border-radius: 8px;
        background-color: ${colors.HEADER};
        padding: 16px;
        width: 300px;
        margin-top: 8px;
        margin-right: 16px;

        transition: all 0.1s ease-in-out;

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }

        &:last-of-type {
          margin-right: 0;
        }

        ${wrapCss}
      `}
    >
      {initials ? (
        <InitialsThumbnail size={54}>{initials}</InitialsThumbnail>
      ) : (
        <Picture
          size='small'
          imageId={imageId}
          square
          fit
          iconName={iconName}
        />
      )}
      <div
        css={css`
          margin-left: 8px;
        `}
      >
        <div
          css={css`
            font-size: 16px;
            font-weight: 700;
          `}
        >
          {name}
        </div>
        <div
          css={css`
            font-size: 12px;
            font-weight: 500;
            color: ${colors.LINK_WATER};
            text-transform: capitalize;
            margin-top: 4px;
          `}
        >
          {subTitle}
        </div>
      </div>

      {typeof onRemove === 'function' && (
        <button
          type='button'
          css={css`
            position: absolute;
            top: 0px;
            right: 0px;
            z-index: 1;
            border-radius: 8px;
            width: 30px;
            height: 30px;

            transition: all 0.2s ease-in-out;
            &:hover {
              background-color: rgba(255, 255, 255, 0.5);
              i {
                color: black;
              }
            }
          `}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onRemove()
          }}
        >
          <Icon
            color={colors.ATTENDANCE_GRAY}
            faType='fal'
            name='times'
            fontSize={18}
          />
        </button>
      )}
    </Flex>
  )
}

export default ResultCard
