import { produce } from 'immer'
import chatNotificationActions from '../actions/chatNotification'
import { str } from './utils'

const initialState = {
  read: false,
}

const chatNotificationsReducer = (
  draft = initialState,
  { type, payload, meta }
) => {
  switch (type) {
    case str(chatNotificationActions.read.success): {
      return draft?.read
    }

    case str(chatNotificationActions.create.success): {
      draft.read = true
      return
    }

    case str(chatNotificationActions.update.success): {
      draft.read = true
      return
    }

    case str(chatNotificationActions.delete.success): {
      draft.read = undefined
      return
    }
  }
}

export default (state = {}, action) =>
  produce(state, (draft) => {
    return chatNotificationsReducer(draft, action)
  })
