/** @jsxImportSource @emotion/react */
import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons'
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { formatCurrency } from '@sportninja/common/utils/utils'
import { Alert, Select } from 'antd'
import { useContext, useEffect, useMemo, useState } from 'react'
import { EventCard } from 'src/components/RegistrationEventCard'
import { RegistrationContext } from '../RegistrationContext'
import {
  PageTitle,
  REGISTRATION_STEPS,
  buttonStyle,
  headingStyle,
  paragraphSmallStyle,
  paragraphXSmallStyle,
} from './Main'
import { paragraphLarge } from 'src/components/css'

const selectStyle = css`
  border-radius: 8px;
  border: 1px solid ${colors.STORM_GRAY};
  background: ${colors.VERY_DARK_GRAYISH_BLUE};
  display: flex;
  overflow: hidden;
  color: ${colors.WHITE};
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 145%; /* 20.3px */
  min-width: 150px;
`

const SelectYourDivision = () => {
  const {
    registration,
    setStep,
    setDivision,
    isLogged,
    genderFilters,
    ageFilters,
    updateSessionDivision,
    session,
    deleteSession,
  } = useContext(RegistrationContext)
  const [error, setError] = useState(null)
  const [selectedDivision, setSelectedDivision] = useState(null)
  const [selectedAge, setSelectedAge] = useState(null)
  const [selectedGender, setSelectedGender] = useState(null)
  const divisions = useMemo(() => {
    if (!registration?.divisions) {
      return []
    }
    let memoizedDivision = registration?.divisions
    if (selectedGender && !selectedAge) {
      memoizedDivision = registration?.divisions?.filter(
        (division) => division?.division_gender?.name === selectedGender
      )
    }
    if (selectedAge && !selectedGender) {
      memoizedDivision = registration?.divisions?.filter(
        (division) => division?.division_age?.name === selectedAge
      )
    }
    if (selectedAge && selectedGender) {
      memoizedDivision = registration?.divisions?.filter(
        (division) =>
          division?.division_age?.name === selectedAge &&
          division?.division_gender?.name === selectedGender
      )
    }
    return memoizedDivision
  }, [registration?.divisions, selectedAge, selectedGender])

  const divisionsAvailable = divisions?.length > 0

  const handleAgeChange = (value) => {
    setSelectedAge(value)
  }

  const handleGenderChange = (value) => {
    setSelectedGender(value)
  }

  useEffect(() => {
    if (session && session?.division_id) {
      setSelectedDivision(
        divisions?.find(
          (division) => division?.schedule_id === session?.division_id
        )
      )
    }
  }, [session, divisions])

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        background-color: ${colors.REGISTRATION_SECOND_BACKGROUND};
        width: 1080px;
        padding: 0 35px 35px 35px;
        border-radius: 16px 16px 0px 0px;
        @media (max-width: 768px) {
          min-width: 0;
          width: 100%;
        }
      `}
    >
      <style>
        {`
          .card-container {
            display: grid;
            justify-content: center;
            grid-template-columns: repeat(
              auto-fill,
              minmax(300px, 1fr)
            ); /* Creates as many columns as possible with a minimum of 300px */
            gap: 16px; /* Spacing between cards */
          }
          
          /* Media query for desktops */
          @media (min-width: 768px) {
            .card-container {
              grid-template-columns: repeat(3, 1fr); /* 3 columns on desktop */
            }
          }          
        `}
      </style>
      <EventCard eventInfo={registration} />
      <PageTitle
        title='Choose a Division'
        subtitle={
          'Start your team’s registration by choosing the appropriate division. '
        }
      />
      <>
        {ageFilters?.length > 1 || genderFilters?.length > 1 ? (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
              margin-bottom: 16px;
            `}
          >
            {/* Age */}
            <Select
              defaultValue={''}
              css={selectStyle}
              bordered={false}
              onChange={handleAgeChange}
              options={[
                { value: '', label: 'Select an age' },
                ...ageFilters.map((age) => ({
                  value: age,
                  label: age,
                })),
              ]}
            />
            <Select
              defaultValue={''}
              css={selectStyle}
              bordered={false}
              onChange={handleGenderChange}
              options={[
                { value: '', label: 'Select a gender' },
                ...genderFilters.map((gender) => ({
                  value: gender,
                  label: gender,
                })),
              ]}
            />
          </div>
        ) : null}
      </>

      {!divisionsAvailable ? (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            height: 500px;
            width: 100%;
            align-self: center;
          `}
        >
          <p
            css={css`
              ${paragraphLarge}
              color: ${colors.WHITE};
            `}
          >
            No divisions available
          </p>
        </div>
      ) : (
        <div className='card-container'>
          {divisions &&
            divisions?.length > 0 &&
            divisions?.map((division, index) => {
              const totalSpots = division?.max_teams
              const teamRegistered = division?.team_count_enrolled
              const spotsLeft = totalSpots - teamRegistered
              const areLessThanOneThirdSpotsLeft = spotsLeft < totalSpots / 3
              const fullDivision = spotsLeft <= 0

              return (
                <div
                  onClick={() => {
                    if (fullDivision) {
                      return
                    }
                    if (selectedDivision?.id === division?.id) {
                      setSelectedDivision(null)
                    } else {
                      setSelectedDivision(division)
                    }
                  }}
                  key={index}
                  css={css`
                    width: 100%;
                    display: flex;
                    padding: 8px 16px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 8px;
                    align-self: stretch;
                    border-radius: 8px;
                    border: 1px solid ${colors.VERY_DARK_GRAYISH_BLUE};
                    cursor: ${fullDivision ? 'not-allowed' : 'pointer'};
                    ${selectedDivision?.id === division?.id
                      ? css`
                          border: 2px solid ${colors.PRIMARY_300};
                        `
                      : null}
                    opacity: ${fullDivision ? 0.7 : 1};
                  `}
                >
                  <span
                    css={css`
                      ${headingStyle}
                      font-family: 'BarlowCondensed', sans-serif;
                      ${selectedDivision?.id === division?.id
                        ? css`
                            color: ${colors.PRIMARY_300};
                          `
                        : css`
                            color: ${colors.WHITE};
                          `}
                    `}
                  >
                    {division?.name}
                  </span>
                  <div
                    css={css`
                      display: flex;
                      align-items: flex-start;
                      gap: 8px;
                      align-self: stretch;
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        gap: 4px;
                        flex: 1 0 0;
                      `}
                    >
                      <span
                        css={css`
                          ${selectedDivision?.id === division?.id
                            ? css`
                                color: ${colors.WHITE};
                              `
                            : css`
                                color: ${colors.WHITE};
                              `}
                          ${paragraphSmallStyle}
                        `}
                      >
                        Age: {division?.division_age?.name}
                      </span>
                      <span
                        css={css`
                          ${selectedDivision?.id === division?.id
                            ? css`
                                color: ${colors.WHITE};
                              `
                            : css`
                                color: ${colors.WHITE};
                              `}
                          ${paragraphSmallStyle}
                        `}
                      >
                        Gender: {division?.division_gender?.name}
                      </span>
                    </div>
                    <div
                      css={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-end;
                        gap: 4px;
                      `}
                    >
                      <span
                        css={css`
                          ${selectedDivision?.id === division?.id
                            ? css`
                                color: ${colors.WHITE};
                              `
                            : css`
                                color: ${colors.WHITE};
                              `}
                          ${paragraphSmallStyle}
                        `}
                      >
                        Cost per Team
                      </span>
                      <span
                        css={css`
                          ${selectedDivision?.id === division?.id
                            ? css`
                                color: ${colors.PRIMARY_300};
                              `
                            : css`
                                color: ${colors.WHITE};
                              `}
                          ${headingStyle}
                          font-family: 'BarlowCondensed', sans-serif;
                        `}
                      >
                        {formatCurrency(
                          division?.team_cost,
                          division?.currency?.name
                        )}{' '}
                      </span>
                      {division?.deposit && division?.deposit > 0 ? (
                        <span
                          css={css`
                            ${selectedDivision?.id === division?.id
                              ? css`
                                  color: ${colors.WHITE};
                                `
                              : css`
                                  color: ${colors.WHITE};
                                `}
                            ${paragraphSmallStyle}
                          `}
                        >
                          Deposit:{' '}
                          {formatCurrency(
                            division?.deposit,
                            division?.currency?.name
                          )}{' '}
                        </span>
                      ) : (
                        <span
                          css={css`
                            ${selectedDivision?.id === division?.id
                              ? css`
                                  color: ${colors.WHITE};
                                `
                              : css`
                                  color: ${colors.WHITE};
                                `}
                            ${paragraphSmallStyle}
                          `}
                        >
                          No Deposit Required
                        </span>
                      )}
                    </div>
                  </div>
                  {/* <div
                    css={css`
                      width: 100%;
                      border-color: ${colors.VERY_DARK_GRAYISH_BLUE};
                      border-bottom-width: 1px;
                      border-bottom-style: solid;
                    `}
                  /> */}
                  <div
                    css={css`
                      display: flex;
                      justify-content: space-between;
                      width: 100%;
                      gap: 8px;
                      flex: 1;
                      flex-wrap: wrap;
                    `}
                  >
                    {/* Teams Registered */}
                    {!fullDivision ? (
                      <div
                        css={css`
                          /* if the team count is equals 0, change the opacity */
                          opacity: ${division?.team_count_enrolled === 0
                            ? 0
                            : 1};
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          gap: 4px;
                          padding-left: 8px;
                          padding-right: 8px;
                          padding-top: 4px;
                          padding-bottom: 4px;
                          border-radius: 4px;
                          ${selectedDivision?.id === division?.id
                            ? css`
                                background-color: ${colors.GREEN_APPLE};
                              `
                            : css`
                                background-color: ${colors.GREEN_APPLE};
                              `}
                        `}
                      >
                        <CheckCircleOutlined
                          style={{
                            color: colors.WHITE,
                          }}
                        />
                        <span
                          css={css`
                            ${paragraphXSmallStyle}
                            color: ${colors.WHITE};
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          `}
                        >
                          {division?.team_count_enrolled} Teams{' '}
                          {areLessThanOneThirdSpotsLeft &&
                          areLessThanOneThirdSpotsLeft &&
                          spotsLeft > 0
                            ? 'Regd.'
                            : 'Registered'}
                        </span>
                      </div>
                    ) : null}
                    {fullDivision ? (
                      <>
                        <div
                          css={css`
                            /* if the team count is equals 0, change the opacity */
                            opacity: ${division?.team_count_enrolled === 0
                              ? 0
                              : 1};
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 4px;
                            padding-left: 8px;
                            padding-right: 8px;
                            padding-top: 4px;
                            padding-bottom: 4px;
                            border-radius: 4px;
                            background-color: ${colors.NEUTRAL_500};
                          `}
                        >
                          <CheckCircleOutlined
                            style={{
                              color: colors.WHITE,
                            }}
                          />
                          <span
                            css={css`
                              ${paragraphXSmallStyle}
                              color: ${colors.WHITE};
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            `}
                          >
                            Division Full
                          </span>
                        </div>
                        <div
                          css={css`
                            align-self: flex-end;
                            display: flex;
                            align-items: center;
                            gap: 4px;
                            padding-left: 8px;
                            padding-right: 8px;
                            padding-top: 4px;
                            padding-bottom: 4px;
                            border-radius: 4px;
                            background-color: ${colors.NEUTRAL_500};
                          `}
                        >
                          <ClockCircleOutlined
                            style={{
                              color: colors.WHITE,
                            }}
                          />
                          <span
                            css={css`
                              ${paragraphXSmallStyle}
                              color: ${colors.WHITE};
                              text-align: right;
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            `}
                          >
                            0 Spots Left
                          </span>
                        </div>
                      </>
                    ) : null}
                    {areLessThanOneThirdSpotsLeft && spotsLeft > 0 ? (
                      <div
                        css={css`
                          align-self: flex-end;
                          display: flex;
                          align-items: center;
                          gap: 4px;
                          padding-left: 8px;
                          padding-right: 8px;
                          padding-top: 4px;
                          padding-bottom: 4px;
                          border-radius: 4px;
                          ${selectedDivision?.id === division?.id
                            ? css`
                                background-color: ${colors.RED_APPLE};
                              `
                            : css`
                                background-color: ${colors.RED_APPLE};
                              `}
                        `}
                      >
                        <ClockCircleOutlined
                          style={{
                            color: colors.WHITE,
                          }}
                        />
                        <span
                          css={css`
                            ${paragraphXSmallStyle}
                            color: ${colors.WHITE};
                            text-align: right;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          `}
                        >
                          {division?.max_teams - division?.team_count_enrolled}{' '}
                          Spots Left
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              )
            })}
        </div>
      )}
      {selectedDivision ? (
        <div
          css={css`
            position: sticky;
            bottom: 0;
            background-color: ${colors.REGISTRATION_SECOND_BACKGROUND};
            z-index: 100;
            width: 100%;
            padding-top: 16px;
            padding-bottom: 16px;
            display: flex;
            flex-direction: column;
            gap: 8px;
          `}
        >
          {error ? <Alert message={error} type='error' showIcon /> : null}
          <button
            onClick={async () => {
              try {
                setError(null)
                await updateSessionDivision(selectedDivision?.schedule_id)
                setDivision(selectedDivision)
                if (isLogged) {
                  setStep(REGISTRATION_STEPS.TEAM_REGISTER)
                } else {
                  setStep(REGISTRATION_STEPS.SIGN_UP)
                }
              } catch (e) {
                if (e?.status === 410) {
                  // localStorage.removeItem('registration_session')
                  deleteSession()
                  window.location.reload()
                  return
                }
                setError(
                  e?.message ||
                    e?.invalid_fields?.registration_step ||
                    JSON.stringify(e)
                )
              }
            }}
            disabled={selectedDivision === null}
            css={css`
              ${buttonStyle}
              align-self: flex-end;
            `}
          >
            NEXT
          </button>
          <span
            css={css`
              ${paragraphXSmallStyle}
              color: ${colors.WHITE};
              text-align: center;
              align-self: flex-end;
            `}
          >
            *Applicable taxes will be applied to all payments
          </span>
        </div>
      ) : null}
    </div>
  )
}

export default SelectYourDivision
