import { select } from 'redux-saga/effects'

import { ENTITY_TYPES } from '../utils'
import { getOrgById } from '../../selectors/org'
import { getTeamById } from '../../selectors/team'
import { getScheduleById } from '../../selectors/schedule'

export const selectorsByEntityType = {
  [ENTITY_TYPES.org]: getOrgById,
  [ENTITY_TYPES.team]: getTeamById,
  [ENTITY_TYPES.schedule]: getScheduleById,
}

export const getInviteStatus = function* (entityType, id, userId) {
  const user = yield select((state) => {
    const entity = selectorsByEntityType[entityType](state, id)
    return entity?.users?.find((u) => u.id === userId)
  })
  return user?.invite
}
