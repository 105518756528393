import { createSelector } from 'reselect'

import { combineRolesAndEntities } from './utils'
import { getAuth, getOrgs, getUsers, getSchedules, getTeams } from './base'

export const getUserById = (state, id) => state.users[id] || false

export const getSelfUser = createSelector(
  [getAuth, getUsers],
  (auth, users) => users[auth?.user?.id]
)

export const getManagedUsers = createSelector(
  [getAuth, getUsers],
  (auth, users) => {
    const managedList = auth.managedUsers
    if (!Array.isArray(managedList)) return false

    return managedList.map((userId) => users[userId] || {})
  }
)

export const makeGetUserRoles = (userByIdSelector) => {
  return createSelector(
    [userByIdSelector, getOrgs, getTeams, getSchedules],
    (user = {}, orgs, teams, schedules) => {
      return combineRolesAndEntities(user.roles, orgs, teams, schedules)
    }
  )
}

/**
 * A reusable method for all entity types, to retrieve the information for their
 * lists of users; if the entity has a `users` or `members` key, then this is
 * how you get that data.
 *
 * @param {function} entityByIdSelector - The selector to retrieve entity info. by id (i.e. getOrgById)
 * @returns {array} - The list of users for the entity
 */
export const makeGetUsers = (entityByIdSelector) => {
  return createSelector([entityByIdSelector, getUsers], (entity, users) => {
    if (!users || !Object.hasOwnProperty.call(entity, 'users')) return []

    const baseUsers = entity.users
    return baseUsers.map((base) => {
      return {
        ...base,
        ...users[base.id],
      }
    })
  })
}
