/**
 * Since we have use for this history instance in places other than just App, and
 * exporting from the same module that calls ReactDOM.render will cause Jest tests
 * to fail (see https://stackoverflow.com/a/42611909) we instantiate it here
 * instead :)
 */
import { createBrowserHistory } from 'history'
import { init } from './utils/sentry'

const history = createBrowserHistory()

init()

export default history
