import { createContext, useEffect, useState } from 'react'
import req from '@sportninja/common/api/request'

type Country = {
  id: number
  name: string
  name_full: string
  iso_3166_2: string
  iso_3166_3: string
  capital: string
  citizenship: string
  currency_symbol: string
  is_visible: boolean
}

async function getCountryList() {
  try {
    const response = await req('/countries')
    const removeCanada = response.filter(
      (item: Country) => item.name !== 'Canada'
    )
    const newList = removeCanada.filter(
      (item: Country) => item.name !== 'United States'
    )

    newList.unshift({
      id: 226,
      name: 'United States',
      name_full: 'United States of America',
      iso_3166_2: 'US',
      iso_3166_3: 'USA',
      capital: 'Washington DC',
      citizenship: 'American',
      currency_symbol: '$',
      is_visible: true,
    })

    newList.unshift({
      id: 36,
      name: 'Canada',
      name_full: 'Canada',
      iso_3166_2: 'CA',
      iso_3166_3: 'CAN',
      capital: 'Ottawa',
      citizenship: 'Canadian',
      currency_symbol: '$',
      is_visible: true,
    })

    return newList
  } catch (e) {
    console.error(e)
    return []
  }
}

export const CountryContext = createContext<Country[]>([])

export function CountryProvider({ children }: { children: React.ReactNode }) {
  const [countryList, setCountryList] = useState<Country[]>([])

  async function fetchCountryList() {
    const list = await getCountryList()
    setCountryList(list)
  }

  useEffect(() => {
    if (countryList.length < 1) {
      fetchCountryList()
    }
  }, [])

  return (
    <CountryContext.Provider value={countryList}>
      {children}
    </CountryContext.Provider>
  )
}
