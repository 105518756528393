import { call, put, select } from 'redux-saga/effects'
import req from '@sportninja/common/api/request'

import { getCountries } from '../../selectors/types'
import { toArray } from '../../utils/utils'
import { processForm } from '../utils'

const api = (entityType) => ({
  create: async (id, body) =>
    await req(`/${entityType}/${id}/venues`, { method: 'POST', body }),
  update: async (id, venueId, body) =>
    await req(`/${entityType}/${id}/venues/${venueId}`, {
      method: 'PUT',
      body,
    }),
  delete: async (id, venueId) =>
    await req(`/${entityType}/${id}/venues/${venueId}`, { method: 'DELETE' }),
  createFacility: async (id, venueId, body) =>
    await req(`/${entityType}/${id}/venues/${venueId}/facilities`, {
      method: 'POST',
      body,
    }),
  updateFacility: async (id, venueId, facilityId, body) =>
    await req(
      `/${entityType}/${id}/venues/${venueId}/facilities/${facilityId}`,
      { method: 'PUT', body }
    ),
  deleteFacility: async (id, venueId, facilityId) =>
    await req(
      `/${entityType}/${id}/venues/${venueId}/facilities/${facilityId}`,
      { method: 'DELETE' }
    ),
})

export function* setAddressHelper(id, venueId, form, entityType) {
  if (Object.keys(form).length === 0) return {}

  const { name_full, timezone, ...address } = form
  let body = {}

  // API wants a name field, so we'll give it one
  if (name_full) {
    body = { name: name_full, name_full }
  }

  body.timezone = timezone

  // Avoid adding an empty address object to the payload
  if (Object.keys(address).length > 0) {
    body.address = address

    // Null condition means we are a new address
    if (venueId === null) {
      body.address.address_type_id = '5'
    } // 'Other'
  }

  // Only if we've constructed a payload should we add or set an address
  if (Object.keys(body).length === 0) return {}

  const json = JSON.stringify(body)
  return venueId
    ? yield call(api(entityType).update, id, venueId, json)
    : yield call(api(entityType).create, id, json)
}

const _getCoords = async ({ street_1, city, province = {}, country }) => {
  try {
    if (!process.env.REACT_APP_GOOGLE_API_KEY) {
      console.log('No google api key found')
      return {}
    }
    await window.GMWait
    window.geocoder = window.geocoder || new window.google.maps.Geocoder()
    const address = `${street_1}, ${city}, ${
      province.name ? `${province.name}, ` : ''
    }${country?.name_full}`
    const { results } = await window.geocoder.geocode({ address })
    if (results && results.length > 0) {
      return {
        latitude: results[0].geometry.location.lat(),
        longitude: results[0].geometry.location.lng(),
      }
    }
    return {}
  } catch (e) {
    console.log(e)
    return {}
  }
}

const createOrUpdate = (actions, entityType) =>
  function* (payload) {
    const { id, venueId, form: _form } = payload
    const { newFacilities, ...form } = _form
    const { body } = processForm(form)

    const countries = yield select(getCountries)
    const country = countries[form.country_id]
    const province = country && country.provinces[form.province_id]

    const coords = yield call(_getCoords, { ...form, country, province })

    if (coords && Object.keys(coords).length > 0) {
      Object.assign(body, coords)
    }

    const response = yield call(
      setAddressHelper,
      id,
      venueId ? venueId : null,
      body,
      entityType
    )

    yield put(actions.update.success({ id, data: response.data }))

    if (newFacilities && Object.keys(newFacilities).length > 0) {
      toArray(newFacilities).forEach(async (newFacility) => {
        if (newFacility?.name?.length > 0) {
          const existingFacility = form.facilities.find(
            (f) => f.id === newFacility.id
          )

          if (existingFacility) {
            if (existingFacility.name !== newFacility.name) {
              try {
                await api(entityType).updateFacility(
                  id,
                  venueId,
                  newFacility.id,
                  JSON.stringify({ name: newFacility.name })
                )
              } catch (e) {
                response.error =
                  'but there was a problem updating one or more facility(s).'
              }
            }
          } else {
            try {
              await api(entityType).createFacility(
                id,
                venueId || response?.data?.id,
                JSON.stringify({ name: newFacility.name })
              )
            } catch (e) {
              response.error =
                'but there was a problem creating your facility(s).'
            }
          }
        }
      })
    }

    return response
  }

export default (entityType, actions) => [
  [actions.create, createOrUpdate(actions, entityType)],
  [actions.update, createOrUpdate(actions, entityType)],

  [
    actions.delete,
    function* (payload) {
      const { id, venueId } = payload
      yield call(api(entityType).delete, id, venueId)
      yield put(actions.delete.success({ id, venueId }))
    },
  ],
]
