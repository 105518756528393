/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import dayjs from 'dayjs'
import { Fragment } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { t } from '@sportninja/common/i18n'

import Picture from '../../components/Picture'
import { zIndex, border, font } from '../../components/css'

const mobilePicCss = css`
  box-shadow: 0 0 8px 0 rgba(18,27,38,0.27), 0 2px 4px 0 rgba(0,0,0,0.5);
`

const MobileMiniMatchup = ({ css: customCss, game, isSticky }) => {
  if (!game) return false

  const { completed, started } = game

  return (
    <div css={css`position: relative;`}>
      <div css={[customCss, css`
        position: fixed;
        width: 100%;
        height: 56px;
        display: flex;
        flex: 1;
        overflow-x: hidden;
        color: ${game.started && !game.completed ? 'black' : 'white'};
        opacity: ${isSticky ? 1 : 0};
        transition: opacity 0.3s;
        background: linear-gradient(180deg, #576072 0%, #444B56 100%);
        z-index: ${zIndex.header};
        overflow-x: hidden;
        pointer-events: none;

        .cell {
          display: flex;
          flex: 1 1 33%;
          align-items: center;
        }

        .content {
          display: flex;
        }

        .score {
          display: flex;
          align-items: center;
          ${font.title}
          font-size: 36px;
          font-weight: bold;
          letter-spacing: 0.54px;
          line-height: 44px;
          opacity: 0.6;

          &.winner {
            opacity: 1;
          }
        }

        .left {
          transform: skew(6deg) translateX(-4px);
          transform-origin: top-right;
          justify-content: flex-start;
          padding-left: 24px;

          background: ${completed ?
            '#303339'
            :
            started ?
              'linear-gradient(180deg, #D6D6D6 0%, #EAEAEA 100%)'
              :
              'linear-gradient(180deg, #576072 0%, #444B56 100%)'
          };

          border-right: ${completed && border};

          .content {
            transform: skew(-6deg) translateX(4px) translateY(-1px);
          }

          .score {
            padding-left: 12px;
          }
        }

        .mid {
          flex-direction: column;
          margin: 0 -8px;

          font-size: ${completed || started ? 14 : 12}px;
          font-weight: ${completed && 'bold'};
          letter-spacing: 0.61px;
          text-align: center;
          justify-content: center;

          background: ${completed ?
            '#303339'
            :
            started ?
              '#B0BABF'
              :
              '#6C747D'
          };
        }

        .right {
          transform: skew(-6deg) translateX(4px);
          justify-content: flex-end;
          padding-right: 24px;

          background: ${completed ?
            '#303339'
            :
            started ?
              'linear-gradient(180deg, #D6D6D6 0%, #EAEAEA 100%)'
              :
              'linear-gradient(180deg, #576072 0%, #444B56 100%)'
          };

          border-left: ${completed && border};

          .content {
            transform: skew(6deg) translateX(-4px) translateY(-1px);
          }

          .score {
            padding-right: 12px;
          }
        }
      `]}>
        <div className='cell left'>
          <div className='content'>
            <Picture size='xsmall' square fit imageId={game?.homeTeam?.imageId} css={mobilePicCss} iconName='user-friends' />
            <div className={cn('score', { winner: game.visitingTeamScore < game.homeTeamScore })}>
              {game.homeTeamScore}
            </div>
          </div>
        </div>
        <div className='cell mid'>
          {completed ?
            <div css={css`text-transform: uppercase;`}>
              <div>{t('common:final')}</div>
              <div>{t('common:ot')}</div>
            </div>
            :
            started ?
              <Fragment>
                <div>
                  {game.currentPeriodTime}
                </div>
                <div css={css`text-transform: uppercase; margin-top: 2px;`}>
                  {game.currentPeriodName}
                </div>
              </Fragment>
              :
              <Fragment>
                <div>{dayjs(game.startsAt).format('h:mm A')}</div>
                <div css={css`margin-top: 2px;`}>{dayjs(game.startsAt).format('MMM DD, YYYY')}</div>
              </Fragment>
          }
        </div>
        <div className='cell right'>
          <div className='content'>
            <div className={cn('score', { winner: game.visitingTeamScore > game.homeTeamScore })}>
              {game.visitingTeamScore}
            </div>
            <Picture size='xsmall' square fit imageId={game?.visitingTeam?.imageId} css={mobilePicCss} iconName='user-friends' />
          </div>
        </div>
      </div>
    </div>
  )
}

MobileMiniMatchup.propTypes = {
  css: PropTypes.object,
  game: PropTypes.oneOfType([ PropTypes.bool, PropTypes.object ]).isRequired,
  isSticky: PropTypes.bool.isRequired
}

export default MobileMiniMatchup
