/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Select } from 'antd'
import React from 'react'
import { paragraphSmall, weightRegular } from 'src/components/css'
import { ErrorLabel } from '../ErrorLabel'

type SelectInputProps = {
  label: string
  disabled: boolean
  options: any[]
  name: string
  value: string
  onChange: (value: string) => void
  fieldErrors: any
  customError: any
  showSearch?: boolean
  filterOption?: (input: string, option: any) => boolean
  style?: any
}

export const SelectInput: React.FC<SelectInputProps> = ({
  label,
  disabled,
  options,
  name,
  value,
  onChange,
  fieldErrors,
  customError,
  showSearch = false,
  filterOption,
  style,
}) => {
  return (
    <>
      <p
        css={css`
          ${paragraphSmall}
          ${weightRegular}
                color: ${colors.WHITE};
          align-self: stretch;
        `}
      >
        {label}
      </p>
      <Select
        disabled={disabled}
        showSearch={showSearch}
        onChange={(e) => onChange(e)}
        options={options}
        value={value}
        css={css`
          width: 100%;
          ${style}
        `}
        filterOption={filterOption}
      />
      <ErrorLabel
        fieldErrors={fieldErrors}
        customError={customError}
        name={name}
      />
    </>
  )
}
