/** @jsxImportSource @emotion/react */

import { Fragment, useEffect, useRef, useState } from 'react'
import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import isPropValid from '@emotion/is-prop-valid'
import { Link, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import throttle from 'lodash.throttle'
import { t } from '@sportninja/common/i18n'
import { ROUTES } from '@sportninja/common/constants/app'
import { Helmet } from 'react-helmet'
import colors from '@sportninja/common/constants/appColors'
import { useTimezoneManager } from '@sportninja/common/hooks/useTimezoneManager'

import {
  flairColorLight,
  font,
  heading1,
  MENU_HEIGHT,
  paragraphMedium,
  paragraphXSmall,
  weightBold,
  weightMedium,
  zIndex,
} from '../../components/css'
import { Container, Column, Flex } from '../../components/Layout'
import { ActionsWrapper, FixedTitle } from '../../components/PageTitle/css'
import { Desktop, media } from '../../components/Responsive'
import GamePageHeaderMatchup from './GamePageHeaderMatchup'
import MobileMiniMatchup from './MobileMiniMatchup'
import Icon from '../../components/Icon'

const MatchDateTime = styled.div`
  ${paragraphXSmall}
  ${weightBold}
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${colors.ATTENDANCE_GRAY};
  ${(props) =>
    props.isDesktop
      ? css`
          padding-left: 2px;
          font-weight: bold;
          text-transform: uppercase;
        `
      : css`
          text-align: center;
        `}
`

const ScheduleLink = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop),
})`
  ${paragraphXSmall};
  ${weightMedium}
  color: ${colors.PRIMARY_300};
  text-transform: uppercase;
`

const ArrowRight = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <g clip-path='url(#clip0_6717_844)'>
      <path
        d='M5.72656 11.06L8.7799 8L5.72656 4.94L6.66656 4L10.6666 8L6.66656 12L5.72656 11.06Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_6717_844'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

const getBackgroundImage = (
  genericSportForGame,
  isSoccer,
  imageName,
  isDesktop,
  sport = null
) => {
  if (!genericSportForGame) {
    if (isSoccer) {
      return '/images/soccer_game_background'
    } else {
      if (sport && sport?.name) {
        if (sport?.name === 'lacrosse') {
          return '/images/grass'
        }
        if (sport?.name === 'fieldhockey') {
          return '/images/field'
        }
        if (sport?.name === 'floorball') {
          return '/images/floorball'
        }
      }
      return `/images/game_hockey`
    }
  } else {
    return isDesktop
      ? genericSportForGame?.background_web_image?.replace('.png', '')
      : genericSportForGame?.background_mobile_image?.replace('.png', '')
  }
}

const GamePageHeader = ({
  actions: _actions,
  error,
  fullGameType,
  game,
  inFrame,
  organization,
  disabled,
  isSoccer = false,
  genericSportForGame = null,
  sport = null,
}) => {
  const headerRef = useRef(null)
  const [titleVisible, setTitleVisible] = useState(false)
  const HEIGHT = !game.permission?.update ? 224 : 322

  const actions = inFrame
    ? _actions && _actions.filter((action) => action && action.key === 'list')
    : _actions

  const { locationDate, getDateString } = useTimezoneManager({
    date: game.startsAt,
    timezone: game?.location?.timezone,
  })

  useEffect(() => {
    const listener = throttle(() => {
      const header = headerRef.current

      if (header) {
        const currScrollPos =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop
        let value = 1,
          threshold = HEIGHT - MENU_HEIGHT

        if (currScrollPos >= threshold) {
          value = 0
        } else if (currScrollPos > 0) {
          value = Math.min(
            1,
            Math.max(0, threshold - currScrollPos) / (1 * currScrollPos)
          )
        }

        header.style = `opacity: ${value}`

        // Also manage the visibility of the fixed title, because we broke the way
        // it normally works in PageHeader
        if (currScrollPos >= threshold) {
          setTitleVisible(true)
        } else {
          setTitleVisible(false)
        }
      }
    }, 10)

    document.addEventListener('scroll', listener)

    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [])

  let imageName = 'upcoming'
  if (game.completed) imageName = 'completed'
  else if (game.started) imageName = 'in-progress'

  const privateEntityError =
    (error && error.status === 403) || (error && error.status !== 404)

  let title = game.gameNumber
    ? `${t('util:capitalize', { text: t('common:game') })} #${game.gameNumber}`
    : game.gameNumber === null || game.gameNumber?.length === 0
    ? t('util:capitalize', { text: t('common:game') })
    : `${t('common:loading')}...`
  if (error && privateEntityError) {
    title = t('Web:private')
  }

  return (
    <Desktop>
      {(isDesktop) => {
        let imageUrl = ''
        if (!inFrame) {
          const backgroudImage = getBackgroundImage(
            genericSportForGame,
            isSoccer,
            imageName,
            isDesktop,
            sport
          )
          if (title !== `${t('common:loading')}...`) {
            imageUrl = backgroudImage
          }
          // if (title !== `${t('common:loading')}...`) {
          //   if (isSoccer) {
          //     imageUrl = '/images/soccer_game_background'
          //   } else {
          //     imageUrl = `/images/game_${imageName}${
          //       isDesktop ? '' : '_mobile'
          //     }`
          //   }
          // }
        }
        return (
          <Container
            css={css`
              min-height: ${isDesktop ? 282 : HEIGHT}px;
              background: linear-gradient(
                  180deg,
                  #060a10 0%,
                  rgba(6, 10, 16, 0.9) 37.29%,
                  rgba(6, 10, 16, 0.5) 100%
                ),
                no-repeat center center / cover url('${imageUrl}.png');
              ${isDesktop
                ? css`
                    margin-bottom: 16px;
                  `
                : css`
                    position: relative;
                    padding: 0 0 ${HEIGHT}px;
                  `}
            `}
            wrapCss={css`
              position: relative;
            `}
          >
            <Helmet title={title} />
            {!isDesktop && (
              <FixedTitle noFlex justifyContent='center' visible={titleVisible}>
                <div>
                  <h2>{title}</h2>
                </div>
              </FixedTitle>
            )}

            {isDesktop && disabled && (
              <Route exact path='/game/:game/timeline'>
                <div
                  css={css`
                    position: fixed;
                    top: 0;
                    height: 50px;
                    left: 0;
                    right: 0;
                    z-index: ${zIndex.header + 1};
                    background-color: rgba(0, 0, 0, 0.77);
                    cursor: not-allowed;
                  `}
                ></div>

                <Flex
                  column
                  alignItems='center'
                  justifyContent='center'
                  className='disabled-overlay'
                  css={css`
                    position: absolute;
                    top: -50px;
                    left: -75%;
                    right: -75%;
                    bottom: -48px;
                    z-index: ${zIndex.header - 1};
                    background-color: rgba(0, 0, 0, 0.77);
                    cursor: not-allowed;
                    font-size: 20px;
                    color: ${colors.DEFAULT_FLAIR};

                    ${media.ten} {
                      bottom: -64px;
                    }
                  `}
                >
                  <Icon
                    name='exclamation-triangle'
                    fontSize={40}
                    css={css`
                      margin-bottom: 20px;
                    `}
                    color={colors.DEFAULT_FLAIR}
                  />
                  Please do not leave the page while editing the game timeline.
                  <p
                    css={css`
                      margin-top: 4px;
                    `}
                  >
                    Press the &apos;Save Timeline&apos; button to exit.
                  </p>
                </Flex>
              </Route>
            )}
            <div
              ref={headerRef}
              css={css`
                margin-left: 88px;
                margin-right: 88px;
                width: 100%;
                ${!isDesktop &&
                css`
                  position: fixed;
                  padding: 0 8px 16px;
                  margin-left: 0;
                  margin-right: 0;
                `};
              `}
            >
              <Column column fullWidth>
                <div
                  css={css`
                    margin-bottom: 8px;
                    margin-top: ${isDesktop ? 20 : 16}px;

                    ${isDesktop
                      ? css`
                          min-height: 14px;
                        `
                      : css`
                          text-align: center;
                        `}
                  `}
                >
                  {game.rootSchedule && (
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <Link to={ROUTES.HOME}>
                        <Icon
                          name='home'
                          fontSize={14}
                          color={colors.PALE_SKY}
                        />
                      </Link>
                      <ArrowRight />
                      <ScheduleLink
                        as={Link}
                        isDesktop={isDesktop}
                        to={`${ROUTES.ORG_ROOT}/${organization?.id}`}
                      >
                        {organization && organization?.name ? (
                          <Fragment>{organization.name}</Fragment>
                        ) : null}
                      </ScheduleLink>
                      <ArrowRight />
                      <ScheduleLink
                        as={Link}
                        isDesktop={isDesktop}
                        to={`${ROUTES.SCHEDULE_ROOT}/${game.rootSchedule.id}`}
                      >
                        {game.rootSchedule.name_full || game.rootSchedule.name}
                      </ScheduleLink>
                      {game.parentId !== game?.rootSchedule?.id && (
                        <>
                          <ArrowRight />
                          <ScheduleLink
                            as={Link}
                            isDesktop={isDesktop}
                            to={`${ROUTES.SCHEDULE_ROOT}/${game.rootSchedule.id}?scheduleType=${game.parentScheduleType}&schedule_id=${game.parentId}`}
                          >
                            {game.parentSchedule}
                          </ScheduleLink>
                        </>
                      )}
                    </div>
                  )}
                </div>
                <Flex>
                  <div
                    css={css`
                      flex: 1;
                      ${font.title}
                      ${heading1}
                      ${weightBold}
                      ${!isDesktop &&
                      css`
                        text-align: center;
                      `}
                    `}
                  >
                    {title}
                  </div>
                  {isDesktop && actions && (
                    <ActionsWrapper>{actions}</ActionsWrapper>
                  )}
                </Flex>
                {game.startsAt && (
                  <MatchDateTime isDesktop={isDesktop}>
                    {getDateString(
                      locationDate,
                      isDesktop
                        ? 'h:mm a ZZZZ - cccc LLL dd/y'
                        : 'h:mm a ZZZZ - LLL dd, y'
                    )}
                    {game.startsAt && fullGameType && (
                      <span> • {fullGameType.name}</span>
                    )}
                  </MatchDateTime>
                )}
              </Column>

              {!error && (
                <Column column fullWidth>
                  <GamePageHeaderMatchup
                    isDesktop={isDesktop}
                    game={game}
                    isSoccer={isSoccer}
                  />
                </Column>
              )}

              {!isDesktop && actions && (
                <Flex justifyContent='center'>
                  <ActionsWrapper>{actions}</ActionsWrapper>
                </Flex>
              )}
            </div>
            {!isDesktop && (
              <MobileMiniMatchup game={game} isSticky={titleVisible} />
            )}
          </Container>
        )
      }}
    </Desktop>
  )
}

GamePageHeader.propTypes = {
  actions: PropTypes.node,
  game: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
}

export default GamePageHeader
