import { StatTypes } from '../constants/StatsTypes'

/**
 * Creates table columns based on the provided stats.
 *
 * @param {Array} stats - The array of stats.
 * @param {Function} setSortBy - The function to set the sort by value.
 * @param {Function} setDirection - The function to set the sort direction.
 * @returns {Array} The array of table columns.
 */
export const createTableColumns = (
  stats,
  setSortBy = () => {},
  setDirection = () => {}
) => {
  return stats.map((stat) => ({
    title: stat.abbr,
    key: stat.abbr,
    sorter: (a, b, sortOrder) => {
      setSortBy(stat.abbr)
      const direction = sortOrder === 'ascend' ? 'asc' : 'desc'
      setDirection(direction)
    },
    sortDirections: ['ascend', 'descend', 'ascend'],
    dataIndex: stat.abbr,
    showSorterTooltip: {
      title: stat.name,
    },
  }))
}

/**
 * Creates table columns configuration from an API response data.
 *
 * @param {Array} apiResponseData - The API response data containing stats.
 * @param {Function} setSortBy - The function to set the sort by value.
 * @param {Function} setDirection - The function to set the sort direction.
 * @returns {Array} The table columns configuration.
 */
export const createTableColumnsFromApiResponse = (
  apiResponseData,
  setSortBy = () => {},
  setDirection = () => {}
) => {
  // Flatten all stats from all items in the response data into a single array
  const allStats = apiResponseData.reduce(
    (acc, item) => [...acc, ...item.stats],
    []
  )

  // Extract unique stats based on the 'abbr' property to avoid duplicates
  const uniqueStats = Array.from(
    new Map(allStats.map((stat) => [stat.abbr, stat])).values()
  )

  // Generate columns configuration from the unique stats
  return uniqueStats.map((stat) => ({
    title: stat.abbr === 'P' ? 'PTS' : stat.abbr,
    key: stat.abbr,
    sorter: (a, b, sortOrder) => {
      setSortBy(stat.abbr)
      const direction = sortOrder === 'ascend' ? 'asc' : 'desc'
      setDirection(direction)
    },
    sortDirections: ['ascend', 'descend', 'ascend'],
    dataIndex: stat.abbr,
    showSorterTooltip: {
      title: stat.name,
    },
  }))
}

/**
 * Filters the columns based on the requested stats.
 *
 * @param {Array} columns - The array of columns to filter.
 * @param {Array} requestedStats - The array of requested stats.
 * @returns {Array} - The filtered array of columns.
 */
export const filterColumns = (columns, requestedStats) => {
  // Filter columns based on whether their key (abbreviation) is included in requestedStats
  return requestedStats
    .map((key) => columns.find((column) => column.key === key))
    .filter(Boolean)
}

export const findStatByAbbreviation = (abbreviation, sport = 'hockey') => {
  for (const key in StatTypes) {
    if (
      StatTypes[key].abbreviation === abbreviation &&
      StatTypes[key].sportId === sport
    ) {
      return StatTypes[key]
    }
  }
  return null // Not found
}

/**
 * Builds an array of columns for displaying statistics.
 *
 * @param {string[]} statsArray - An array of statistic keys.
 * @returns {Object[]} - An array of column objects.
 */
export const buildStatsColumns = (statsArray, sport = 'hockey') => {
  const columnWidthPercentage = 100 / statsArray.length // Calculate equal width
  return statsArray.map((key) => {
    const statType = findStatByAbbreviation(key, sport)
    return {
      title: statType?.altAbbreviation || statType?.abbreviation || key,
      key,
      sorter: (a, b, sortOrder) => {},
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: key,
      showSorterTooltip: {
        title: statType?.name || key,
      },
      width: `${columnWidthPercentage}%`, // Add the width property
    }
  })
}

/**
 * Transforms player data based on filtered columns.
 * @param {Array} apiResponseData - The API response data containing player information.
 * @param {Array} filteredColumnKeys - The keys of the filtered columns.
 * @returns {Array} - The transformed player data.
 */
export const transformPlayerData = (apiResponseData, filteredColumnKeys) => {
  // Dynamically build player data based on filtered columns
  return apiResponseData.map((item, index) => {
    const playerData = {
      key: index,
      player: item,
      team: item,
      season: item?.season, // Assuming there's a 'season' field directly under each item
      PN: item?.player?.player_number, // Assuming there's a 'player_number' field under 'player'
    }

    // Iterate over each stat in the filtered columns and add it to the player data
    filteredColumnKeys.forEach((key) => {
      const stat = item.stats.find((s) => s.abbr === key)
      playerData[key] = stat ? stat.value : 0 // Set to 0 if not found
    })

    return playerData
  })
}
