import req from '@sportninja/common/api/request'
import { RegistrationSteps } from '@sportninja/common/types'
import { set } from 'mobx'
import React, { createContext, useEffect, useMemo, useState } from 'react'

export const RegistrationContext = createContext({})

const getUniqueDivisionGenders = (events) => {
  if (!events) {
    return []
  }
  const uniqueGenders = new Set()
  events.forEach((event) => {
    if (event.division_gender && event.division_gender.name) {
      uniqueGenders.add(event.division_gender.name)
    }
  })
  return Array.from(uniqueGenders)
}

const getUniqueDivisionAges = (events) => {
  if (!events) {
    return []
  }
  const uniqueAges = new Set()
  events.forEach((event) => {
    if (event.division_age && event.division_age.name) {
      uniqueAges.add(event.division_age.name)
    }
  })
  return Array.from(uniqueAges)
}

const RegistrationContextWrapper = ({
  children,
  login,
  isLogged,
  loginRegistration,
  selfUser,
}) => {
  const [session, setSession] = useState(null)
  const [registration, setRegistration] = useState(null)
  const [billing, setBilling] = useState(null)
  const [step, setStep] = useState(0)
  const [division, setDivision] = useState(null)
  const [selectedTeam, setSelectedTeam] = useState(null)
  const [userTeams, setUserTeams] = useState([])
  const [countries, setCountries] = useState([])
  const [userId, setUserId] = useState(null)
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [birthDate, setBirthDate] = useState(null)
  const [password, setPassword] = useState('')
  const [country, setCountry] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [promoCode, setPromoCode] = useState('')
  const [couponInfo, setCouponInfo] = useState({
    discount: 0,
    type: '',
    id: '',
  })
  const [additionalTeamOfficials, setAdditionalTeamOfficials] = useState([])
  const genderFilters = useMemo(
    () => getUniqueDivisionGenders(registration?.divisions),
    [registration?.divisions]
  )
  const ageFilters = useMemo(
    () => getUniqueDivisionAges(registration?.divisions),
    [registration?.divisions]
  )

  const updateSessionDivision = async (divisionId) => {
    const { data } = await req(`/registration/session/${session?.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        division_id: divisionId,
        registration_step: RegistrationSteps.SELECT_YOUR_DIVISION,
      }),
    })
    setSession(data)
  }

  /**
   * Updates the session team registration information.
   *
   * @param {Object} params - The parameters for updating the session team registration.
   * @param {string} params.coupon_code_id - The ID of the coupon code.
   * @param {string} params.team_official_id - The ID of the team official.
   * @param {string} params.team_name - The name of the team.
   * @param {string} params.team_id - The ID of the team.
   * @param {string} params.postal_code - The postal code of the team.
   * @param {string} params.country_code - The country code of the team.
   * @param {Array} params.team_official_array - An array of team officials.
   * @param {string} params.user_id - The ID of the user.
   * @param {string} params.currency_id - The ID of the currency.
   * @returns {Promise<void>} - A promise that resolves when the session is updated.
   */
  const updateSessionTeamRegister = async ({
    coupon_code_id,
    team_official_id,
    team_name,
    team_id,
    postal_code,
    country_code,
    team_official_array,
    user_id,
    currency_id,
  }) => {
    const { data } = await req(`/registration/session/${session?.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        registration_step: RegistrationSteps.TEAM_REGISTER,
        coupon_code_id,
        team_official_id,
        team_name,
        team_id,
        postal_code,
        country_code,
        team_official_array,
        user_id,
        currency_id,
      }),
    })
    setSession(data)
  }

  const updateSessionPayment = async () => {
    const { data } = await req(`/registration/session/${session?.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        registration_step: RegistrationSteps.REGISTRATION_PAYMENT,
      }),
    })
    setSession(data)
  }

  const updateSessionVerifyEmail = async () => {
    const { data } = await req(`/registration/session/${session?.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        registration_step: RegistrationSteps.VALIDATE_EMAIL,
      }),
    })
    setSession(data)
  }

  const updateSessionConfirmSubmission = async () => {
    const { data } = await req(`/registration/session/${session?.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        registration_step: RegistrationSteps.CONFIRM,
      }),
    })
    // remove the session from local storage at the end of the registration
    // localStorage.removeItem('registration_session')
    setSession(data)
  }

  const deleteSession = async () => {
    try {
      localStorage.removeItem('registration_session')
      setSession(null)
      setStep(0)
      setSelectedTeam(null)
      setZipCode('')
      setCountry('')
      setDivision(null)
      setAdditionalTeamOfficials([])
      await req(`/registration/session/${session?.id}`, {
        method: 'DELETE',
      })
    } catch (e) {
      console.error('Error deleting session', e)
    }
  }

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const { data } = await req('/users/me')
        setUserId(data?.id)
        setFirstName(data?.name_first)
        setLastName(data?.name_last)
        setEmail(data?.email)
        setBirthDate(data?.birth_date?.split('T')[0])
      } catch (e) {
        setFirstName('')
        setLastName('')
        setEmail('')
        setBirthDate(null)
      }
    }
    if (selfUser && selfUser?.id && isLogged) {
      fetchUserInfo()
    }
  }, [selfUser])

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countryList = await req('/countries')
        const us = countryList.find((el) => el.iso_3166_2 === 'US')
        const cad = countryList.find((el) => el.iso_3166_2 === 'CA')
        if (us && cad) {
          countryList.unshift(us, cad)
        }
        setCountry(cad?.iso_3166_2)
        setCountries(countryList)
      } catch (e) {
        setCountries([])
      }
    }
    if (isLogged) {
      fetchCountries()
    }
  }, [isLogged])

  useEffect(() => {
    const fetchUserTeams = async () => {
      try {
        const teams = await req(`/users/me/registrations/${registration?.id}`)
        setUserTeams(teams?.data)
      } catch (e) {
        setUserTeams([])
      }
    }
    if (isLogged && registration?.id) {
      fetchUserTeams()
    }
  }, [isLogged, registration])

  const value = {
    updateSessionConfirmSubmission,
    updateSessionVerifyEmail,
    updateSessionPayment,
    updateSessionTeamRegister,
    updateSessionDivision,
    session,
    setSession,
    registration,
    setRegistration,
    selectedTeam,
    setSelectedTeam,
    division,
    setDivision,
    userTeams,
    countries,
    step,
    setStep,
    login,
    isLogged,
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    birthDate,
    setBirthDate,
    password,
    setPassword,
    country,
    setCountry,
    zipCode,
    setZipCode,
    genderFilters,
    ageFilters,
    promoCode,
    setPromoCode,
    loginRegistration,
    additionalTeamOfficials,
    setAdditionalTeamOfficials,
    billing,
    setBilling,
    couponInfo,
    setCouponInfo,
    userId,
    deleteSession,
  }

  return (
    <RegistrationContext.Provider value={value}>
      {children}
    </RegistrationContext.Provider>
  )
}

export default RegistrationContextWrapper
