/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { isCanlan } from '@sportninja/common/utils/customer-name'

import { font } from '../css'
import { Flex } from '../Layout'
import { ReactComponent as DropdownArrow } from './dropdown.svg'

const SuspensionSelect = ({
  children,
  className = 'suspension-select-container',
  disabled,
  icon,
  label,
  placeholder,
  required,
  noFlex,
  onChange,
  options = [],
  value,
  enabledAllItemsOption = false,
  useFullWidth = false,
  showSeparator = true
}) => {
  return (
    <Flex
      noFlex={noFlex}
      column
      className={className}
      css={css`
        max-width: ${useFullWidth ? '100%' : '228px'};
        padding-right: ${useFullWidth ? 0 : '16px'};
        padding-bottom: 16px;
      `}
    >
      <label
        htmlFor={label}
        css={css`
          height: 12px;
          font-size: 12px;
          line-height: 12px;
          margin-bottom: 8px;
          color: ${disabled && 'rgba(255, 255, 255, 0.7)'};
          text-transform: capitalize;
        `}
      >
        {label}
        {required ? ' *' : ''}
      </label>
      <Flex
        justifyContent='flex-end'
        alignItems='center'
        css={css`
          position: relative;

          .report-select-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            min-width: 24px;
            min-height: 24px;
            margin-right: 8px;
            cursor: pointer;

            ${disabled &&
            css`
              cursor: auto;
              path {
                fill: rgba(255, 255, 255, 0.7);
              }
            `}
          }
        `}
      >
        {children ? (
          children
        ) : (
          <select
            disabled={disabled}
            onChange={({ target }) => {
              onChange(target.value)
            }}
            required={required}
            id={label}
            className='report-select'
            value={value}
            css={css`
              cursor: ${disabled ? 'not-allowed' : 'pointer'};
              &:hover:not(:disabled) {
                border-color: white;
              }
              min-width: 212px;
              padding: 8px 16px;
              height: 40px;
              background: #27303e;
              border: ${isCanlan ? 2 : 1}px solid #3f4753;
              border-radius: 4px;

              ${useFullWidth && 'width: 100%;'}

              ${font.body}
              font-size: 16px;
              line-height: 18px;
              color: #ffffff;

              /* We want the placeholder text to be grey, but selected text to be white */
              &:required:invalid {
                color: rgba(255, 255, 255, 0.5);
              }
            `}
          >
            <option value='' disabled={!enabledAllItemsOption}>
              {placeholder ||
                `All ${label}${
                  label.charAt(label.length - 1) === 's' ? 'es' : 's'
                }`}
            </option>
            {showSeparator && <option disabled>-----</option>}
            {options.map((o) => {
              return (
                <option key={o.value} value={o.value}>
                  {o.text}
                </option>
              )
            })}
          </select>
        )}
        {!disabled && icon ? (
          icon
        ) : (
          <div
            className='report-select-icon'
            css={css`
              pointer-events: none;
            `}
          >
            <DropdownArrow />
          </div>
        )}
      </Flex>
    </Flex>
  )
}

export default SuspensionSelect
