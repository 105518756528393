import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getGameTypesBySportId } from '@sportninja/common/selectors/types'
import { t } from '@sportninja/common/i18n'

import { SelectFormInput } from '../Select'

const GameTypeSelect = ({
  gameTypes,
  disabled,
  defaultValue,
  noFlex,
  onChange,
}) => {
  const defaultGameType = gameTypes?.find((t) => t?.id === '2')
  const [selectedGameType, setSelectedGameType] = useState(
    defaultValue || defaultGameType?.id
  )

  const onGameTypeChange = ({ target }) => {
    onChange({ target })
    setSelectedGameType(target.value)
  }

  return (
    <SelectFormInput
      defaultValue={selectedGameType}
      disabled={gameTypes.length === 0 || disabled}
      label={t('common:gameType')}
      name='game_type_id'
      noFlex={noFlex}
      onChange={onGameTypeChange}
    >
      {gameTypes.map((gameType) => {
        return (
          <option key={gameType?.id} value={gameType?.id}>
            {gameType?.name}
          </option>
        )
      })}
    </SelectFormInput>
  )
}

const mapStateToProps = (state, { sportId }) => {
  return {
    gameTypes: getGameTypesBySportId(state, sportId),
  }
}

export default connect(mapStateToProps)(GameTypeSelect)
