import { t } from '../../i18n'

const _getImageId = (data = {}) => data.image && data.image.id
const _getNames = (data = {}) => ({
  nameFirst: data.name_first,
  nameLast: data.name_last,
  fullName: data.name_full,
})
const _getResource = (resource = {}) => ({
  id: resource.id,
  fullName: resource.name_full || resource.name,
  name: resource.name,
})

const getInvitee = (data = {}) => {
  const returnValue = {
    player_id: data?.player_id,
    birth_date: data?.birth_date,
    createdAt: data?.created_at,
    email: data?.email,
    id: data?.invitee?.id,
    ..._getNames(data),
  }

  if (!data.type) {
    return returnValue
  }

  switch (data.type.id) {
    // Player
    case '1':
      return {
        ...returnValue,
        type: t('util:capitalize', { text: t('common:player') }),
      }

    // Official/Staff
    case '2':
    case '4':
    case '6':
      return {
        ...returnValue,
        type: t('util:capitalize', { text: t('common:user') }),
      }

    case '7':
    case '8':
    case '9':
      return {
        ...returnValue,
        type: t('util:capitalize', { text: t('common:official') }),
      }

    // Team invitation to competition - not used yet
    case '3':
    case '5':
    default:
      return returnValue
  }
}

const getInviter = (data = {}) => {
  if (!data.type || !data.destination) {
    return {}
  }

  const resource = data.destination

  switch (data.type.id) {
    // Team
    case '1':
    case '2':
    case '9':
      return {
        image: _getImageId(resource),
        type: 'Team',
        ..._getResource(resource),
        sender: data.sender,
      }

    // Competition
    case '3':
    case '4':
    case '8':
      return {
        image: _getImageId(resource),
        type: 'Competition',
        ..._getResource(resource),
        sender: data.sender,
      }

    // Organization
    case '5':
    case '6':
    case '7':
      return {
        image: _getImageId(resource),
        type: 'Organization',
        ..._getResource(resource),
        sender: data.sender,
      }

    default:
      return {
        image: _getImageId(resource),
        ..._getResource(resource),
        sender: data.sender,
      }
  }
}

export const getInviteObject = (data) => ({
  invitee: (data && getInvitee(data)) || {},
  inviter: (data && getInviter(data)) || {},
  message: data?.message,
  roleTypeId: data?.role_type_id,
  show_underage_workflow: data?.show_underage_workflow,
  sport_id: data?.sport_id,
  player_id: data?.player_id,
  ask_position: data?.ask_position,
  team_official_id: data?.team_official_id,
  team_official_type_id: data?.team_official_type_id,
  player_type_id: data?.player_type_id,
  destination: data?.destination,
})

export const onInviteMount = async (inviteId, getInvite, getUserDetails) => {
  if (typeof inviteId !== 'string') {
    throw new Error('Invalid invitation. Please check your email again.')
  }

  try {
    const data = await getInvite(inviteId)

    // This indicates that this invitation has already been accepted
    if (data.accepted_at) {
      const error = new Error()
      error.title = t('errors:invitationAlreadyAccepted')
      error.invite = data
      throw error
    }

    getUserDetails && (await getUserDetails())
    return data
  } catch (e) {
    const error = { ...e }

    // Some little tweaks to server error messages from `getInvite`
    if (e.message === 'The requested invitation was not found') {
      error.title = 'Invitation not found'
      error.message = 'Please check your email and try again.'
    }

    throw error
  }
}

export const letterAorAn = (type) => {
  if (typeof type === 'string' && type.charAt(0).toLowerCase() === 'a') {
    return 'an'
  }
  return 'a'
}
