/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'src/components/Button'
import { CheckBox } from 'src/components/Checkbox'
import { Input } from 'src/components/Input'
import type { StepProps } from './userSelection'

// import dayjs from 'dayjs'
import Icon from 'src/components/Icon'
import { FormDispatcher, State } from '../formController'

import req from '@sportninja/common/api/request'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { useHistory } from 'react-router-dom'
import Banner from 'src/components/Banner'
import BannerController from 'src/components/Banner/BannerController'
import { RequestsService } from '../requests/index'
import { InputSingleDate } from 'src/components/InputSingleDate'

interface Props extends StepProps, FormDispatcher {
  isBusiness?: boolean
  form?: State
  context: {
    name: string
    id: string
  }
}

export interface ProfileInputFields {
  firstName: string
  lastName: string
  email: string
  dateOfBirthYear: string
  dateOfBirthMonth: string
  dateOfBirthDay: string
  password: string
  confirmPassword: string
}

export const validateFormUsingApi = async (data: any) => {
  try {
    const result = await req('/signup/signup-validate', {
      method: 'POST',
      body: JSON.stringify(data),
    })
    return result
  } catch (e) {
    console.error('ERROR', e)
    throw e
  }
}

export type SignUpData = {
  dateOfBirth: string
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
  userSignUpType?: string | null
  context: string
  contextId: string
}

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  dateOfBirthYear: '',
  dateOfBirthMonth: '',
  dateOfBirthDay: '',
  password: '',
  confirmPassword: '',
  dateOfBirth: '',
}

const trySignUp = async (data: SignUpData): Promise<boolean | unknown> => {
  const res = await RequestsService.signUpMember(data)

  if (res === 'ERROR') {
    throw new Error('An error happened (ERR: 5)')
  }

  if (res.user && typeof res.user.validated_at === 'string') {
    return true
  }

  return false
}

export const NewMember = ({
  stepDispatcher,
  isBusiness = false,
  formDispatcher,
  context,
  form,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const [fieldErrors, setFieldErrors] = useState<string[]>([])
  const [customError, setCustomError] = useState(initialValues)
  const [isPlayer, setIsPlayer] = useState(false)
  const [isTeamOfficial, setIsTeamOfficial] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (window.location.search.includes('shouldRefresh=true')) {
      // create a URL object from the current location
      let url = new URL(window.location.href)
      // get the query parameters
      let params = new URLSearchParams(url.search)
      // remove the shouldRefresh parameter
      params.delete('shouldRefresh')
      // set the new search parameters
      url.search = params.toString()
      // replace the current history entry
      window.history.replaceState({}, document.title, url.toString())
      // refresh the page
      window.location.reload()
    }
  }, [])

  const { control, handleSubmit } = useForm<ProfileInputFields>({
    defaultValues: {
      email: form?.user.email || '',
      firstName: form?.user.firstName || '',
      lastName: form?.user.lastName || '',
      dateOfBirthYear: String(form?.user.dateOfBirth).split('-')[0] || '',
      dateOfBirthMonth: String(form?.user.dateOfBirth).split('-')[1] || '',
      dateOfBirthDay: String(form?.user.dateOfBirth).split('-')[2] || '',
    },
  })

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  })

  const setCustomErrors = (invalid_fields: any) => {
    if (invalid_fields.name_first) {
      setCustomErroByField('firstName', invalid_fields.name_first)
    }
    if (invalid_fields.name_last) {
      setCustomErroByField('lastName', invalid_fields.name_last)
    }
    if (invalid_fields.email) {
      setCustomErroByField('email', invalid_fields.email)
    }
    if (invalid_fields.birth_date) {
      setCustomErroByField('dateOfBirth', invalid_fields.birth_date)
    }
    if (invalid_fields.password) {
      setCustomErroByField('password', invalid_fields.password)
    }
    if (invalid_fields.confirm_password) {
      setCustomErroByField('confirmPassword', invalid_fields.confirm_password)
    }
  }

  const setCustomErroByField = (field: string, message: string) => {
    setCustomError((prev) => ({ ...prev, [field]: message }))
    setFieldErrors((prevErrors) => [...new Set([...prevErrors, field])])
  }

  const onSubmit = useCallback(
    (data: ProfileInputFields) => {
      setFieldErrors([])
      setCustomError(initialValues)
      setLoading(true)

      const dateOfBirth = `${data.dateOfBirthYear}-${data.dateOfBirthMonth}-${data.dateOfBirthDay}`
      const data_to_validate = {
        name_first: data.firstName,
        name_last: data.lastName,
        email: data.email,
        birth_date: dateOfBirth,
        password: data.password,
        confirm_password: data.confirmPassword,
      }
      validateFormUsingApi(data_to_validate)
        .then(() => {
          if (isBusiness) {
            formDispatcher({
              type: 'setUser',
              payload: {
                dateOfBirth,
                confirmPassword: data.confirmPassword,
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                password: data.password,
              },
            })
            stepDispatcher({ type: 'set', payload: 3 })
            return
          }
          let arr: string[] = []
          if (isPlayer) {
            arr.push('player')
          }
          if (isAdmin) {
            arr.push('admin')
          }
          if (isTeamOfficial) {
            arr.push('team_rep')
          }
          const value = arr.join(',') || null
          const body: SignUpData = {
            dateOfBirth,
            confirmPassword: data.confirmPassword,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            password: data.password,
            userSignUpType: value,
            context: context.name,
            contextId: context.id,
          }
          trySignUp(body)
            .then(() => {
              history.push(ROUTES.SIGNUP_SUCCESS, { email: data.email })
            })
            .catch((e) => {
              console.error('ERROR', e)
              setLoading(false)
            })
        })
        .catch((e) => {
          if (e?.invalid_fields) {
            setCustomErrors(e.invalid_fields)
            setLoading(false)
            return
          }
          setLoading(false)
          const errorMessage = getErrorMessage(e)
          BannerController.add(({ ...props }) => (
            <Banner
              duration={undefined}
              onClose={undefined}
              onClick={undefined}
              subChildren={undefined}
              {...props}
              type='failed'
            >
              {errorMessage}
            </Banner>
          ))
        })
    },
    [isPlayer, isAdmin, isTeamOfficial]
  )

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit(onSubmit)
      }}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
        `}
      >
        <p
          css={css`
            font-size: 24px;
            font-weight: 700;
          `}
        >
          Let’s Create Your Profile! 👋
        </p>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          margin: 32px 0;
        `}
      >
        <p
          css={css`
            color: ${colors.ATTENDANCE_GRAY};
            font-size: 14px;
          `}
        >
          *Mandatory field(s)
        </p>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;

          @media (max-width: 768px) {
            flex-direction: column;

            > div {
              width: 100%;
            }
          }
        `}
      >
        <Input
          autoComplete='off'
          control={control}
          fieldName='firstName'
          label='First Name *'
          // required
          invalidMessage={customError.firstName}
          invalid={fieldErrors.includes('firstName')}
        />
        <Input
          autoComplete='off'
          control={control}
          fieldName='lastName'
          label='Last Name *'
          // required
          invalidMessage={customError.lastName}
          invalid={fieldErrors.includes('lastName')}
        />
      </div>
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          gap: 16px;
          margin-top: 16px;

          @media (max-width: 768px) {
            flex-direction: column;

            > div {
              width: 100%;
            }
          }
        `}
      >
        <Input
          autoComplete='off'
          control={control}
          fieldName='email'
          label='Email *'
          // required
          invalidMessage={customError.email}
          invalid={fieldErrors.includes('email')}
        />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 8px;
            flex: 1;
          `}
        >
          <p
            tabIndex={-1}
            css={css`
              font-weight: 500;
              font-size: 14px;
              line-height: 14px;
              color: ${colors.WHITE};
            `}
          >
            Birth Date (DD/MM/YYYY) *
          </p>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 4px;
              flex: 1;
              flex-wrap: wrap;
              height: 40px;
            `}
          >
            <InputSingleDate
              type='number'
              autoComplete='off'
              control={control}
              fieldName='dateOfBirthDay'
              placeholder='Day'
              customWidth='32%'
              invalid={
                fieldErrors.includes('dateOfBirthDay') ||
                fieldErrors.includes('dateOfBirth')
              }
              maxLength={2}
            />
            <InputSingleDate
              type='number'
              autoComplete='off'
              control={control}
              fieldName='dateOfBirthMonth'
              placeholder='Month'
              customWidth='32%'
              invalid={
                fieldErrors.includes('dateOfBirthMonth') ||
                fieldErrors.includes('dateOfBirth')
              }
              maxLength={2}
            />
            <InputSingleDate
              type='number'
              autoComplete='off'
              control={control}
              fieldName='dateOfBirthYear'
              placeholder='Year'
              customWidth='32%'
              invalid={
                fieldErrors.includes('dateOfBirthYear') ||
                fieldErrors.includes('dateOfBirth')
              }
            />
          </div>
          <div
            css={css`
              transition: opacity 0.1s ease-in-out;
              opacity: ${fieldErrors.includes('dateOfBirth') ? 1 : 0};
              font-size: 12px;
              color: ${colors.ERROR_LIGHT};
              margin-top: -8px;
            `}
          >
            <span>{customError.dateOfBirth}</span>
          </div>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
          margin-top: 16px;

          @media (max-width: 768px) {
            flex-direction: column;

            > div {
              width: 100%;
            }
          }
        `}
      >
        <Input
          autoComplete='off'
          control={control}
          fieldName='password'
          label='Password *'
          secureTextEntry={!showPassword.password}
          icon={
            <button
              onClick={() =>
                setShowPassword({
                  ...showPassword,
                  password: !showPassword.password,
                })
              }
            >
              <Icon
                name={!showPassword.password ? 'eye' : 'eye-slash'}
                color={colors.ATTENDANCE_GRAY}
                fontSize={16}
              />
            </button>
          }
          // required
          invalidMessage={customError.password}
          invalid={fieldErrors.includes('password')}
        />
        <Input
          autoComplete='off'
          control={control}
          fieldName='confirmPassword'
          label='Confirm Password *'
          secureTextEntry={!showPassword.confirmPassword}
          icon={
            <button
              onClick={() =>
                setShowPassword({
                  ...showPassword,
                  confirmPassword: !showPassword.confirmPassword,
                })
              }
            >
              <Icon
                name={!showPassword.confirmPassword ? 'eye' : 'eye-slash'}
                color={colors.ATTENDANCE_GRAY}
                fontSize={16}
              />
            </button>
          }
          // required
          invalidMessage={customError.confirmPassword || customError.password}
          invalid={
            fieldErrors.includes('confirmPassword') ||
            fieldErrors.includes('password')
          }
        />
      </div>
      {!isBusiness && (
        <div
          css={css`
            margin: 16px 0;
            border-bottom: 1px solid ${colors.ATTENDANCE_GRAY};
            border-top: 1px solid ${colors.ATTENDANCE_GRAY};
            padding: 16px 0;
          `}
        >
          <p
            css={css`
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 12px;
              color: ${colors.ATTENDANCE_GRAY};
            `}
          >
            You are (fill all that apply):
          </p>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              justify-content: space-between;
              /* if mobile, change the flex direction */
              @media (max-width: 768px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
              }
            `}
          >
            <CheckBox onChange={(value) => setIsPlayer(value)} label='Player' />
            <CheckBox
              onChange={(value) => setIsTeamOfficial(value)}
              label='Team or Game Official'
            />
            <CheckBox
              onChange={(value) => setIsAdmin(value)}
              label='Administrator'
            />
          </div>
        </div>
      )}
      <div
        css={css`
          padding-top: 16px;
          display: flex;
        `}
      >
        <Button
          tabIndex={1}
          type='submit'
          disabled={loading}
          isLoading={loading}
          label={isBusiness ? 'NEXT' : 'CREATE ACCOUNT'}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </form>
  )
}
