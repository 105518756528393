/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled/macro'
import css from '@emotion/css/macro'
import PropTypes from 'prop-types'
import { NavLink, withRouter } from 'react-router-dom'
import { useState } from 'react'

import { ActivePageIndicator, menuLinkFontStyles } from '../MenuLink'
import Sheet from '../Sheet'
import { Flex } from '../Layout'
import Icon from '../Icon'
import { flairColor } from '../css'

const selectCss = css`
  ${menuLinkFontStyles}

  font-size: 18px;

  color: white;
  cursor: pointer;
`

const StyledNavLink = styled(NavLink, {
  shouldForwardProp: (prop) => prop !== 'isOrange',
})`
  position: relative;
  display: block;
  margin: 30px 0;

  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.64px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;

  /* Used for the text color */
  color: ${(props) => props.isOrange && flairColor};
  opacity: 0.6;
  &.active {
    opacity: 1;
  }

  /* Active page indicator */
  .indicator {
    height: 2px;
    width: 12px;
    bottom: -8px;

    .active & {
      display: block;
    }
  }
`

const Button = ({ onClick, page }) => {
  return (
    <Flex
      alignItems='center'
      onClick={onClick}
      className='mobile-page-menu-item'
      css={selectCss}
    >
      <Icon
        name='bars'
        css={css`
          margin-right: 18px;
        `}
        title='Open page menu'
      />
      {page.title} {typeof page.count === 'number' ? `(${page.count})` : ''}
    </Flex>
  )
}

const Page = ({ onClick, page }) => {
  return (
    <StyledNavLink
      exact
      key={page.title}
      isOrange={page.isOrange}
      onClick={onClick}
      to={page.to}
    >
      {page.title} {typeof page.count === 'number' ? `(${page.count})` : ''}
      <ActivePageIndicator className='indicator indicator-line' />
    </StyledNavLink>
  )
}

const MobilePageMenu = ({ inFrame, location, pages }) => {
  const [isOpen, setIsOpen] = useState(false)
  const page = pages.find(({ to }) => to === location.pathname) || {}

  return inFrame ? (
    <Flex>
      <Button
        page={page}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      />
      {isOpen && (
        <Flex
          column
          className='mobile-page-menu-sheet'
          css={css`
            position: absolute;
            top: 56px;
            left: -24px;
            right: -24px;
          `}
        >
          {pages.map((page) => {
            if (page.isHidden) return false
            return (
              <Page
                key={page.title}
                page={page}
                onClick={() => setIsOpen(!isOpen)}
              />
            )
          })}
          <div
            className='mobile-page-menu-sheet'
            css={css`
              height: 12px;
              box-shadow: 0px 12px 12px -2px rgba(0, 0, 0, 0.3);
              transform: translateY(-12px);
            `}
          />
        </Flex>
      )}
    </Flex>
  ) : (
    <Sheet
      css={css`
        .scrolling-container {
          padding: 0 !important;
        }
      `}
      height='auto'
      width='100%'
      hideClose
      noGradient
      position='bottom'
      button={(toggleOpen) => <Button page={page} onClick={toggleOpen} />}
    >
      {(toggleOpen) => {
        return pages.map((page) => {
          if (page.isHidden) return false
          return <Page key={page.title} page={page} onClick={toggleOpen} />
        })
      }}
    </Sheet>
  )
}

MobilePageMenu.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pages: PropTypes.array.isRequired,
}

export default withRouter(MobilePageMenu)
