/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import sportninjaLogo from './sportninja_dark.svg'
import canlanLogo from '../../RegistrationPayment/canlan_logo.svg'
import { useForm } from 'react-hook-form'
import { Input } from 'src/components/Input'
import { Button } from 'src/components/Button'
import { useState } from 'react'
import Icon from 'src/components/Icon'
import colors from '@sportninja/common/constants/appColors'
import { t } from '@sportninja/common/i18n'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '@sportninja/common/constants/app'
import TextModal from 'src/components/Modal/TextModal'
import ResendVerifyEmail from 'src/components/ResendVerifyEmail'

interface LoginFormData {
  email: string
  password: string
  'bot-field': string
}

interface Props {
  login: (values: { email: string; password: string }) => Promise<void>
}

export const Login = ({ login }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<LoginFormData>({
    defaultValues: {
      'bot-field': '',
      email: '',
      password: '',
    },
  })
  const [emailError, setEmailError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loginError, setLoginError] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const history = useHistory()

  const onSubmit = async (data: LoginFormData) => {
    if (data['bot-field'] !== '') {
      return
    }

    const emailCheck =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi

    if (!emailCheck.test(data.email)) {
      setEmailError('Please enter a valid email address')

      return
    }

    setUserEmail(data.email)

    setIsLoading(true)
    setEmailError('')
    setLoginError('')

    try {
      await login({
        email: data.email,
        password: data.password,
      })
    } catch (e: any) {
      setIsLoading(false)

      switch (e.status) {
        case 401:
          setLoginError(t('errors:invalidLogin', {}))
          break
        case 403:
          setLoginError(`${t('Auth:youNeedToVerify', {})}!`)
          break
        default:
          setLoginError(e.message)
      }
    }
  }

  const onError = (errors: any) => {
    console.error(errors)
  }

  return (
    <div
      css={css`
        display: flex;
        flex: 1;
      `}
    >
      <div
        css={css`
          display: flex;
          flex: 2.5;
          overflow: hidden;
          justify-content: center;
          height: 100vh;

          @media (max-width: 768px) {
            display: none;
          }
        `}
      >
        {!isMobile ? (
          <video
            autoPlay={true}
            controls={false}
            width={window.innerWidth}
            loop
            muted
          >
            <source
              src={isCanlan ? 'video/sn-web.mp4' : 'video/sn-video-updated.mp4'}
            />
          </video>
        ) : null}
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.7);
          `}
        />
      </div>
      <div
        css={css`
          flex-direction: column;
          flex: 1;
          box-shadow: -15px 0px 18px 0px rgba(0, 0, 0, 0.75);
          padding: 40px;
          height: 100vh;
          min-width: 320px; // Adjust the minimum width to fit smaller screens
          overflow-y: scroll;
          z-index: 1;
          background: no-repeat fixed linear-gradient(#282e38, #181a1d);
          @media (max-width: 768px) {
            padding: 20px; // Reduce padding on smaller screens
            min-width: 0; // Remove minimum width on smaller screens
          }
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
            padding-top: 100px;
            padding-bottom: 50px;
          `}
        >
          <img
            src={isCanlan ? canlanLogo : sportninjaLogo}
            css={css`
              width: 20em;
            `}
          />
        </div>
        <div
          css={css`
            margin: 35px 0;
          `}
        >
          <p
            css={css`
              font-size: 24px;
              font-weight: 700;
              color: ${colors.WHITE};
            `}
          >
            Hi, Welcome Back! 👋
          </p>
        </div>
        <div>
          <p
            css={css`
              color: ${colors.ERROR};
              font-size: 16px;
            `}
          >
            {loginError &&
            loginError.includes(t('Auth:youNeedToVerify', {})) ? (
              <>
                <p>{t('Auth:youNeedToVerify', {})}!</p>
                <TextModal
                  title={t('Auth:resendEmail', {})}
                  link={(onClick) => (
                    <p
                      css={css`
                        cursor: pointer;
                        color: ${colors.ERROR};
                        font-size: 14px;
                        margin-top: 10px;
                        text-decoration: underline;
                      `}
                      onClick={onClick}
                    >
                      {t('Auth:resendVerificationEmail', {})}?
                    </p>
                  )}
                >
                  <ResendVerifyEmail email={userEmail} />
                </TextModal>
              </>
            ) : (
              <span>{loginError}</span>
            )}
          </p>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          css={css`
            margin-top: 32px;
          `}
        >
          <Input
            label='Email'
            control={control}
            fieldName='email'
            required
            invalid={emailError !== ''}
            invalidMessage={emailError}
          />
          <div
            css={css`
              margin: 20px 0;
            `}
          />
          <Input
            label='Password'
            control={control}
            fieldName='password'
            required
            secureTextEntry={!showPassword}
            icon={
              <button onClick={() => setShowPassword(!showPassword)}>
                <Icon
                  name={!showPassword ? 'eye' : 'eye-slash'}
                  color={colors.ATTENDANCE_GRAY}
                  fontSize={16}
                />
              </button>
            }
          />
          <Input fieldName='bot-field' type='hidden' control={control} />
          <div
            css={css`
              margin: 20px 0;
            `}
          />
          <div
            css={css`
              justify-content: flex-end;
              display: flex;
            `}
          >
            <button onClick={() => history.push(ROUTES.FORGOT_PASSWORD)}>
              <p
                css={css`
                  color: ${colors.ATTENDANCE_GRAY};
                  font-size: 16px;
                `}
              >
                Forgot password
              </p>
            </button>
          </div>
          <div
            css={css`
              margin: 32px 0;
            `}
          />
          <Button
            isLoading={isLoading}
            type='submit'
            onClick={handleSubmit(onSubmit)}
            label='Login'
            variant='primary'
            disabled={!isValid || isLoading}
          />
        </form>
        <div
          css={css`
            width: 100%;
            height: 1px;
            background-color: ${colors.ATTENDANCE_GRAY};
            margin: 35px 0;
          `}
        />
        <div
          css={css`
            justify-content: center;
            display: flex;
          `}
        >
          <p
            css={css`
              color: ${colors.WHITE};
              font-size: 16px;
            `}
          >
            {"Don't have an account? "}
            <button
              css={css`
                color: ${colors.DEFAULT_FLAIR};
                font-size: 16px;
              `}
              onClick={() => history.push(ROUTES.SIGNUP)}
            >
              <span>Sign up</span>
            </button>
          </p>
        </div>
      </div>
    </div>
  )
}
