/** @jsxImportSource @emotion/react */

import { Fragment, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import css from '@emotion/css/macro'
import { connect } from 'react-redux'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import actions from '@sportninja/common/actions/search'
import { t } from '@sportninja/common/i18n'
import cn from 'classnames'

import Sheet from '../Sheet'
import { InputWrapper, Label } from '../Form/css'
import { Flex } from '../Layout'
import FormInput from '../Form/FormInput'
import Icon from '../Icon'
import LoadingSpinner from '../LoadingSpinner'
import { Pager } from '../Pagination'
import { media } from '../Responsive'
import useBodyScrollLock from '../Sheet/useBodyScrollLock'
import { zIndex } from '../css'
import { makeSearchRequest, getSearchTypes } from './search-menu-helpers'
import { FilterButtons } from './SearchFilterButtons'
import SearchMenuResult from './SearchMenuResult'

const _SearchMenu = ({
  alwaysShowPager,
  autoComplete,
  disabled,
  filter = () => true,
  hideLabel = false,
  isOpen,
  name = 'search',
  noEmptySearch,
  onBlur = () => {},
  onFocus = () => {},
  requestSearch,
  ResultButton,
  searchTypes = getSearchTypes(),
  onResultClick,
  toggleOpen = () => {},
  noResultsText = t('DiscoverScreen:noResults'),
  refreshOnFocus = false,
  sportId = null,
  genericSportId = null,
  enableGlobalSearch = false,
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [results, setResults] = useState(false)
  const fetchNumber = useRef(0)

  const [currPage, setCurrPage] = useState(1)
  const [pages, setPages] = useState(false)

  const searchTypeKeys = Object.keys(searchTypes)
  const [searchType, setSearchType] = useState(searchTypeKeys[0])
  const currentSearchType = searchTypes[searchType]

  const [sheetRef] = useBodyScrollLock(!!isOpen)

  useEffect(() => {
    setError(false)
    if (noEmptySearch && searchTerm.length === 0) return

    setLoading(true)

    const currentFetch = ++fetchNumber.current
    makeSearchRequest(
      requestSearch,
      searchTerm,
      searchType,
      searchTypes,
      currPage,
      (response) => {
        if (currentFetch < fetchNumber.current) return

        const results = response.data.reduce((collector, result) => {
          const permission =
            response.meta && response.meta.permissions
              ? response.meta.permissions[result.id]
              : undefined
          const parsed = currentSearchType.mapper({ ...result, permission })
          collector.push(parsed)
          return collector
        }, [])

        setLoading(false)
        setResults(results)
        setPages(response.meta && response.meta.pagination)
        fetchNumber.current++
      },
      (error) => {
        setResults(false)
        setLoading(false)
        setError(error)
      },
      sportId,
      genericSportId
    )
  }, [currPage, noEmptySearch, searchTerm, searchType, enableGlobalSearch])

  const handleChange = ({ target }) => {
    setSearchTerm(target.value)
    setCurrPage(1)
    setPages(false)
    if (noEmptySearch) setResults([])
  }
  const handlePageChange = (newPage) => setCurrPage(newPage)

  const searchBarIcon = (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        margin-right: -12px;
        z-index: ${zIndex.base};
        cursor: ${searchTerm.length > 0 ? 'pointer' : 'auto'};
        opacity: ${disabled ? 0.5 : 1};
      `}
      onClick={
        searchTerm.length > 0 && !disabled ? () => setSearchTerm('') : undefined
      }
    >
      <Icon name={searchTerm.length > 0 ? 'times' : 'search'} />
    </div>
  )

  const filteredResults = results
    ? filter
      ? results.filter(filter)
      : results
    : []

  const showPager = (pages && pages.total_pages > 1) || alwaysShowPager

  return (
    <Fragment>
      {/* If there's more than one searchType, display toggle buttons */}
      {searchTypeKeys.length > 1 && (
        <div
          css={css`
            margin-bottom: 20px;
          `}
        >
          <InputWrapper>
            <Label>{t('common:type')}</Label>
            <FilterButtons
              onClick={(type) => {
                setCurrPage(1)
                setSearchType(type)
                setResults(false)
                setPages(false)
                setLoading(true)
              }}
              selectedType={searchType}
              types={searchTypes}
            />
          </InputWrapper>
        </div>
      )}
      <form
        action='/'
        method='GET'
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <FormInput
          inputStyles={css`
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          `}
          input={{
            disabled,
            autoComplete,
            label: !hideLabel ? t('common:search') : undefined,
            onFocus: () => {
              onFocus()
              const currentTerm = searchTerm
              if (refreshOnFocus && currentTerm.length > 0) {
                setSearchTerm(`${currentTerm} `)
                setTimeout(() => {
                  setSearchTerm(currentTerm)
                }, 0)
              }
            },
            onBlur,
            onChange: handleChange,
            placeholder: currentSearchType.searchText,
            name,
            right: searchBarIcon,
            type: 'search',
            value: searchTerm,
          }}
        />
      </form>
      <div
        ref={sheetRef}
        className={cn('search-menu-results ignore-scroll-lock', {
          'is-active': loading || Array.isArray(results),
        })}
        css={css`
          flex: 1;
          border: 1px solid rgba(255, 255, 255, 0.2);
          border-top: 0;
          border-radius: 2px;
          overflow-x: auto;
          margin-top: -1px;
          padding: 20px 20px 0;

          font-size: 16px;
          letter-spacing: 0.26px;
          line-height: 20px;
        `}
      >
        {error ? (
          <Flex
            column
            alignItems='center'
            justifyContent='center'
            css={css`
              text-align: center;
              height: 100%;
            `}
          >
            {t('Web:problemPerformingSearch')}
            <p
              css={css`
                margin-top: 8px;
              `}
            >
              {t('errors:pleaseTryRefreshingThePage')}.
            </p>
          </Flex>
        ) : loading ? (
          <LoadingSpinner
            css={css`
              flex: 1;
              margin: 40px 0 60px;
            `}
          />
        ) : filteredResults.length > 0 ? (
          filteredResults.map((result) => {
            return (
              <SearchMenuResult
                enableGlobalSearch={enableGlobalSearch}
                key={result.id}
                iconName={currentSearchType.iconName}
                imageIsPortrait={currentSearchType.imageIsPortrait}
                onClick={() => {
                  typeof onResultClick === 'function'
                    ? onResultClick(result)
                    : toggleOpen()
                }}
                result={result}
                ResultButton={ResultButton}
                searchType={searchType}
                to={
                  typeof onResultClick === 'function'
                    ? undefined
                    : currentSearchType.to
                }
              />
            )
          })
        ) : (
          <Flex
            className='search-menu-no-results'
            alignItems='center'
            justifyContent='center'
            css={css`
              text-align: center;
              height: 100%;
            `}
          >
            {noEmptySearch && searchTerm.length === 0
              ? noResultsText
              : t('DiscoverScreen:noResults')}
          </Flex>
        )}
      </div>

      <div
        css={css`
          ${media.mobile} {
            margin-bottom: 50px;
          }
          min-height: 52px;
        `}
      >
        {showPager && (
          <Pager
            disabled={disabled}
            alwaysShowPager={alwaysShowPager}
            page={pages.current_page}
            total={pages.total_pages}
            noBackground
            noBorder
            noPositionFixed
            onClick={handlePageChange}
          />
        )}
      </div>
    </Fragment>
  )
}

_SearchMenu.propTypes = {
  filter: PropTypes.func,
  requestSearch: PropTypes.func.isRequired,
  ResultButton: PropTypes.func,
  searchTypes: PropTypes.object,
  toggleOpen: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestSearch: bindActionToPromise(dispatch, actions.search.request),
  }
}

export const SearchMenu = connect(null, mapDispatchToProps)(_SearchMenu)

const SearchWrapper = ({ Button }) => {
  const customClass = css`
    ${media.mobile} {
      .scrolling-container {
        padding: 60px 24px 0px;
        /* overflow: unset; */
      }

      .scrolling-container-title {
        margin-bottom: 20px;
      }
    }
  `

  return (
    <Sheet
      blockBodyScroll={false}
      button={Button}
      position='right'
      title={t('common:search')}
      css={customClass}
    >
      {(toggleOpen, isOpen) => (
        <SearchMenu isOpen={isOpen} toggleOpen={toggleOpen} />
      )}
    </Sheet>
  )
}

SearchWrapper.propTypes = {
  Button: PropTypes.func.isRequired,
}

export default SearchWrapper
