import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import colors from '@sportninja/common/constants/appColors'

import { media } from '../Responsive'

const BANNER_HEIGHT_PX = '54px'
const BANNER_WIDTH_PX = '415px'
const COUNTDOWN_DIMENSION = 30
// I don't actually understand how this works - but it ensures that the countdown
// circle completes just as the display duration ends.
const STROKE_FACTOR = 2.93
export const TRANSITION_DURATION_MS = 200

export const Timer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 6px 0 16px;

  height: ${COUNTDOWN_DIMENSION}px;
  width: ${COUNTDOWN_DIMENSION}px;
  text-align: center;
  cursor: pointer;

  &:hover img {
    filter: brightness(1);
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    height: ${COUNTDOWN_DIMENSION}px;
    width: ${COUNTDOWN_DIMENSION}px;
    transform: rotateY(-180deg) rotateZ(-90deg);
  }

  svg circle {
    stroke-dasharray: ${COUNTDOWN_DIMENSION * STROKE_FACTOR}px;
    stroke-dashoffset: 0px;
    stroke-linecap: round;
    stroke-width: 2px;
    stroke: ${colors.ATTENDANCE_GRAY};
    fill: none;
    animation: countdown ${(props) => props.duration}ms linear forwards;
  }

  @keyframes countdown {
    from {
      stroke-dashoffset: 0px;
    }
    to {
      stroke-dashoffset: ${COUNTDOWN_DIMENSION * STROKE_FACTOR}px;
    }
  }
`

export const bannerCss = css`
  display: flex;
  align-items: center;

  min-height: ${BANNER_HEIGHT_PX};
  width: ${BANNER_WIDTH_PX};

  ${media.mobile} {
    width: 100%;
  }

  margin-top: 16px;
  padding: 8px 0;

  border-radius: 4px;
  border-left-width: 4px;
  border-left-style: solid;

  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15);

  &.success {
    border-left-color: ${colors.SUCCESS_GREEN};
  }

  &.failed {
    border-left-color: ${colors.ERROR};
  }

  &.warning {
    border-left-color: ${colors.AMERICAN_YELLOW};
  }

  .banner-icon {
    padding: 10px;
  }

  .banner-title {
    text-transform: capitalize;
    font-weight: bold;
    color: white;
    font-size: 16px;
    line-height: 16px;
  }

  .banner-message {
    color: ${colors.SOLITUDE};
    margin-top: 8px;
    line-height: 14px;
  }

  line-height: 1.2;
  background-color: ${colors.SOFT_STEEL};

  transition: opacity ${TRANSITION_DURATION_MS}ms ease-in-out,
    transform ${TRANSITION_DURATION_MS}ms ease-in-out;

  &.banner-enter {
    opacity: 0;
    transform: translateX(${BANNER_WIDTH_PX});
  }

  &.banner-enter-done {
    opacity: 1;
    transform: translateX(0);
  }

  &.banner-exit {
    opacity: 1;
    transform: translateX(0);
  }

  &.banner-exit-active {
    opacity: 0;
    transform: translateX(${BANNER_WIDTH_PX});
  }

  :hover ${Timer} svg circle {
    animation-play-state: paused;
  }
`
