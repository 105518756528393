/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useMemo } from 'react'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { CloseOutlined } from '@ant-design/icons'
import { SelectInput } from '../ts-components/SelectInput'
import { TextAreaInput } from '../ts-components/TextAreaInput'
import { captionLarge } from '../css'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { Alert, Space } from 'antd'
import { CheckBoxInput } from '../ts-components/AlternateCheckBoxInput'
import req from '@sportninja/common/api/request'
import dayjs from 'dayjs'
import DeleteModal from '../Modal/DeleteModal'
import { GameStatus } from '@sportninja/common/constants/TypedGameStatus'

const SaveButton = ({ ...props }) => {
  return (
    <div
      css={css`
        max-width: 80px;
        display: flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
        border-radius: 8px;
        background: ${colors.PRIMARY_300};
        cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
        opacity: ${props.disabled ? 0.7 : 1};
      `}
      {...props}
    >
      <>
        <p
          css={css`
            ${captionLarge}
            font-family: ${isCanlan
              ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
              : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
            color: ${colors.WHITE};
          `}
        >
          Save
        </p>
      </>
    </div>
  )
}

const closeStyle = {
  color: colors.WHITE,
  fontSize: 20,
}

interface GameStatusModalProps {
  onPressClose?: () => void
  gameId?: string
  readGame: (gameId: string) => void
  gameStatus?: number
  gameCompleted?: boolean
}

const nonCompletedGameStatus = [
  {
    id: 1,
    name: 'Normal',
  },
  {
    id: 9,
    name: 'Final',
  },
  {
    id: 2,
    name: 'Home Forfeit',
  },
  {
    id: 3,
    name: 'Visiting Forfeit',
  },
  {
    id: 5,
    name: 'Postponed',
  },
  {
    id: 6,
    name: 'Suspended',
  },
  {
    id: 7,
    name: 'Double Forfeit',
  },
]

const completedGameStatus = [
  {
    id: 1,
    name: 'Re-Open',
  },
  {
    id: 9,
    name: 'Final',
  },
  {
    id: 2,
    name: 'Home Forfeit',
  },
  {
    id: 3,
    name: 'Visiting Forfeit',
  },
  {
    id: 5,
    name: 'Postponed',
  },
  {
    id: 6,
    name: 'Suspended',
  },
  {
    id: 7,
    name: 'Double Forfeit',
  },
]

const getGameStatus = (gameStatus, gameCompleted, filteredGameStatusTypes) => {
  if (gameStatus) {
    if (gameCompleted && gameStatus === GameStatus.Normal) {
      return GameStatus.Final
    }
    return gameStatus
  }
  return filteredGameStatusTypes[0]?.id
}

const GameStatusModal: React.FC<GameStatusModalProps> = ({
  onPressClose = () => {},
  gameId = null,
  readGame = () => {},
  gameStatus = GameStatus.Normal,
  gameCompleted = false,
}) => {
  const filteredGameStatusTypes = gameCompleted
    ? completedGameStatus
    : nonCompletedGameStatus

  const [selectedGameStatus, setSelectedGameStatus] = React.useState<
    number | null
  >(getGameStatus(gameStatus, gameCompleted, filteredGameStatusTypes))

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>('')
  const [additionalNotes, setAdditionalNotes] = React.useState<string>('')
  const [isNotificationEnabled, setIsNotificationEnabled] =
    React.useState<boolean>(false)
  const gameStatusName = filteredGameStatusTypes.find(
    (t) => t.id === selectedGameStatus
  )?.name
  const title =
    selectedGameStatus === 1
      ? 'Are you sure you want to update the game status'
      : 'Are you sure you want to end the game?'

  const subTitle =
    selectedGameStatus === 1
      ? `This will update the game with status:\n${gameStatusName}.`
      : `This will end the game with status:\n${gameStatusName}.`

  const actionButtonText = selectedGameStatus === 1 ? 'Update Game' : 'End Game'

  const isDirty = useMemo(() => {
    if (gameCompleted && gameStatus === GameStatus.Normal) {
      return selectedGameStatus !== GameStatus.Final
    } else {
      return selectedGameStatus !== gameStatus
    }
  }, [gameCompleted, selectedGameStatus, gameStatus])

  return (
    <div
      css={css`
        background-color: ${colors.SECONDARY_300};
        min-width: 40vw;
        max-width: 700px;
        border-radius: 8px;
        padding: 16px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          gap: 8px;
          align-self: stretch;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
          `}
        >
          <h1
            css={css`
              font-size: 32px;
              font-family: ${isCanlan
                ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
              font-weight: 600;
              color: ${colors.WHITE};
            `}
          >
            {'Change Game Status'}
          </h1>
          <div
            css={css`
              display: flex;
              gap: 16px;
            `}
          >
            <button
              disabled={isLoading}
              onClick={() => {
                setAdditionalNotes('')
                setError('')
                setIsLoading(false)
                setSelectedGameStatus(null)
                onPressClose()
              }}
            >
              <CloseOutlined style={closeStyle} />
            </button>
          </div>
        </div>
      </div>
      <div
        css={css`
          border-bottom: 1px solid ${colors.SOFT_STEEL};
          margin-top: 16px;
          margin-bottom: 16px;
        `}
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
        `}
      >
        <SelectInput
          label={'Game Status'}
          disabled={false}
          options={filteredGameStatusTypes.map((type) => {
            return {
              value: type.id.toString(),
              label: type.name,
            }
          })}
          name={'gameStatus'}
          value={selectedGameStatus ? selectedGameStatus.toString() : ''}
          showSearch={false}
          onChange={(value) => {
            setSelectedGameStatus(Number(value))
          }}
          fieldErrors={[]}
          customError={{}}
        />
        <TextAreaInput
          customError={{}}
          fieldErrors={[]}
          label='Additional Notes'
          name='additionalNotes'
          value={additionalNotes}
          setValue={(value) => setAdditionalNotes(value)}
          maxLength={255}
          placeholder='Add any additional info about why are you changing the game status'
        />
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: stretch;
          `}
        >
          <CheckBoxInput
            title='Notify all Players, Team Managers and Game Officials associated with this game of this change.'
            value={isNotificationEnabled}
            onChange={(value) => {
              setIsNotificationEnabled(value.target.checked)
            }}
          />
        </div>
        <div
          css={css`
            border-bottom: 1px solid ${colors.SOFT_STEEL};
            width: 100%;
          `}
        />
        {error ? (
          <Space
            direction='vertical'
            // eslint-disable-next-line react-native/no-inline-styles
            style={{ width: '100%', marginBottom: 8 }}
          >
            <Alert
              message={error || 'An error occurred. Please try again.'}
              type='error'
              showIcon
              closable
            />
          </Space>
        ) : null}

        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 16px;
            align-self: stretch;
          `}
        >
          {/* Cancel Button */}
          <div
            css={css`
              max-width: 80px;
              display: flex;
              padding: 8px 24px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              border-radius: 8px;
              border: 1px solid var(--Neutrals-neutrals-0, #fff);
              cursor: ${isLoading ? 'not-allowed' : 'pointer'};
              opacity: ${isLoading ? 0.7 : 1};
            `}
            onClick={() => {
              if (isLoading) {
                return
              }
              setAdditionalNotes('')
              setError('')
              setIsLoading(false)
              setSelectedGameStatus(null)
              onPressClose()
            }}
          >
            <p
              css={css`
                ${captionLarge}
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                color: ${colors.WHITE};
              `}
            >
              Cancel
            </p>
          </div>
          <DeleteModal
            title={title}
            message={subTitle}
            onDelete={async (shouldForce = false) => {
              try {
                if (isLoading || isDirty === false) {
                  return
                }
                setIsLoading(true)
                await req(`/games/${gameId}/status`, {
                  method: 'PUT',
                  body: JSON.stringify({
                    game_status_id: selectedGameStatus,
                    send_notification: isNotificationEnabled,
                    additional_notes: additionalNotes,
                    ended_at: dayjs(),
                    should_force: shouldForce,
                  }),
                })
                readGame(gameId || '')
                onPressClose()
              } catch (e: any) {
                const errorMessage = getErrorMessage(e)
                setError(errorMessage)
              } finally {
                setIsLoading(false)
              }
            }}
            Button={SaveButton}
            useForceDelete={false}
            forceDeleteText={undefined}
            displayBanner={false}
            buttonText={actionButtonText}
            shouldCloseModalAfterDelete={true}
            disabled={isDirty === false}
          />
        </div>
      </div>
    </div>
  )
}

export default GameStatusModal
