import { useCallback, useEffect, useState } from 'react'
import { DateTime } from 'luxon'

interface Props {
  timezone?: string
  date?: string
}

type TDate = DateTime<true> | DateTime<false> | null

interface HookReturn {
  userDate: TDate
  locationDate: TDate
  utcDate: TDate
  checkLocationDateAndUserDateAreSame: () => boolean
  getDateString: (date: TDate, format: string) => string
  getDateISOString: (date: TDate) => string
  DateTime: typeof DateTime
}

export const useTimezoneManager = ({ date, timezone }: Props): HookReturn => {
  const [userDate, setUserDate] = useState<TDate>(null)
  const [locationDate, setLocationDate] = useState<TDate>(null)
  const [utcDate, setUtcDate] = useState<TDate>(null)

  const checkLocationDateAndUserDateAreSame = useCallback(
    () => userDate?.toISO() === locationDate?.toISO(),
    [locationDate, userDate]
  )

  const getDateString = useCallback((dateToString: TDate, format: string) => {
    return dateToString?.toFormat(format) ?? ''
  }, [])

  const getDateISOString = useCallback((dateToISO: TDate | string) => {
    if (typeof dateToISO === 'string') {
      const d = DateTime.fromISO(dateToISO)

      return d.toISO() || ''
    }

    return dateToISO?.toISO() || ''
  }, [])

  useEffect(() => {
    if (date && timezone) {
      const user = DateTime.fromISO(date).setLocale('en-US')
      const location = DateTime.fromISO(date)
        .setZone(timezone)
        .setLocale('en-US')
      const utc = DateTime.fromISO(date).toUTC().setLocale('en-US')

      setUserDate(user)
      setLocationDate(location)
      setUtcDate(utc)
    }
  }, [date, timezone])

  return {
    checkLocationDateAndUserDateAreSame,
    userDate,
    locationDate,
    utcDate,
    getDateString,
    getDateISOString,
    DateTime,
  }
}
