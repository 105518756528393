/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import dayjs from 'dayjs'
import { Fragment, useEffect, useRef, useState } from 'react'
import { nanoid } from 'nanoid'
import { Link } from 'react-router-dom'
import { ROUTES } from '@sportninja/common/constants/app'
import cn from 'classnames'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { toISOString } from '@sportninja/common/utils/utils'

import { font } from '../css'
import Icon from '../Icon'
import { Flex } from '../Layout'
import Picture from '../Picture'
import LoadingSpinner from '../LoadingSpinner'
import { media } from '../Responsive'
import ChatMessage from './ChatMessage'

const ChatWindow = ({
  client,
  team,
  loggedInUserId,
  onClose,
  onMinimize,
  isMinimized,
  onNewNotification,
}) => {
  const scrollerRef = useRef(null)
  const inputRef = useRef(null)
  const [text, setText] = useState('')
  const [messagesByDay, setMessagesByDay] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [isDisplayingScrollToBottom, setIsDisplayingScrollToBottom] =
    useState(false)

  const onError = (message) => {
    setError(message)
    setTimeout(() => {
      setError(false)
    }, 3000)
  }

  useEffect(() => {
    if (isMinimized) {
      setIsDisplayingScrollToBottom(false)
    }
  }, [isMinimized])

  useEffect(() => {
    setLoading(true)
    req(`/chat/team/${team.id}`).then((response) => {
      const days = {}
      const messages = response.data.reverse()
      for (let i = messages.length - 1; i >= 0; i--) {
        const message = messages[i]
        const date = dayjs(message.created_at).format('MMM D, YYYY')
        if (days[date]) {
          days[date].push(message)
        } else {
          days[date] = [message]
        }
      }

      setMessagesByDay(days)
      setLoading(false)
    })
  }, [team.id])

  useEffect(() => {
    const channel = client.subscribe(`private-team-chat.${team.id}`)

    channel.bind('pusher:subscription_error', (message) => {
      // if (message?.error !== 'Client connection error') {
      //   registerError(
      //     message,
      //     'TeamChat Error - Failed to subscribe to the room'
      //   )
      // }
      // eslint-disable-next-line no-undef
      if (__DEV__) {
        console.log('subscription error', message)
      }
      onError(message?.error || 'Client connection error')
    })

    channel.bind('pusher:subscription_succeeded', (message) => {
      // eslint-disable-next-line no-undef
      if (__DEV__) {
        console.log(
          `[ws] subscribed to channel: private-team-chat.${team.id}`,
          message
        )
      }
    })

    channel.bind('team-chat', (data) => {
      const today = dayjs().format('MMM D, YYYY')
      setMessagesByDay((days) => {
        const copy = { ...days }
        copy[today] = copy[today] || []
        const existingMessageIndex = copy[today].findIndex((m) => {
          return m.id === data.local_id
        })

        if (existingMessageIndex === -1) {
          copy[today].push({
            ...data,
            id: data.local_id,
            user_id: data.user.uid,
            // Inject a timestamp since the WS api does not provide one
            created_at: toISOString(dayjs()),
          })
        }

        return copy
      })
      onNewNotification()
    })

    return () => {
      channel?.unbind('pusher:subscription_error')
      channel?.unbind('pusher:subscription_succeeded')
      channel?.unbind('team-chat')

      if (client.connection.state === 'connected') {
        channel?.unsubscribe(`private-team-chat.${team.id}`)
        // eslint-disable-next-line no-undef
        if (__DEV__) {
          console.log(
            '[ws] disconnected from channel',
            `private-team-chat.${team.id}`
          )
        }
      }
    }
  }, [client, client?.connection?.state, team.id])

  const onSend = async () => {
    const today = dayjs().format('MMM D, YYYY')
    const local_id = nanoid()
    const copyOfMessage = text

    try {
      setMessagesByDay((days) => {
        const copy = { ...days }
        copy[today] = copy[today] || []
        copy[today].push({
          message: text,
          id: local_id,
          player_id: team.player_id,
          user_id: loggedInUserId,
          // Inject our own timestamp in the local copy
          created_at: toISOString(dayjs()),
        })
        return copy
      })
      setText('')
      await req(`/chat/team/${team.id}/message`, {
        method: 'POST',
        body: JSON.stringify({
          message: text,
          local_id,
          player_id: team.player_id,
          // Not required to send a timestamp to the API
        }),
      })
    } catch (e) {
      setText(copyOfMessage)
      setMessagesByDay((days) => {
        const copy = { ...days }
        copy[today] = copy[today] || []
        const messageIndex = copy[today].findIndex((m) => m.id === local_id)
        copy[today].splice(messageIndex, 1)
        return copy
      })
      onError(e.message)
    }
  }

  if (isMinimized) return false

  return (
    <Flex
      column
      css={css`
        position: relative;

        height: 570px;
        width: 328px;
        background: linear-gradient(#282e38, #181a1d);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        box-shadow: 0 12px 28px rgba(0, 0, 0, 0.2),
          0 2px 4px 0 rgba(0, 0, 0, 0.1);
        margin-left: 12px;

        ${media.mobile} {
          position: absolute;
          bottom: 0;
          right: 0px;
        }
      `}
    >
      <Flex
        noFlex
        alignItems='center'
        justifyContent='space-between'
        css={css`
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          padding: 6px 12px 6px 10px;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1),
            0 -1px rgba(255, 255, 255, 0.05) inset,
            0 2px 1px -1px rgba(255, 255, 255, 0.05) inset;
        `}
      >
        <Flex
          as={Link}
          noFlex
          alignItems='center'
          to={`${ROUTES.TEAM_ROOT}/${team.id}`}
          css={css`
            padding: 6px;
            border-radius: 8px;
            transition: background-color 0.2s ease-in-out;
            &:hover {
              background-color: rgba(255, 255, 255, 0.2);
            }
          `}
        >
          <Picture
            square
            fit
            size='kxxsmall'
            imageId={team?.image?.full_path}
            iconName='user-friends'
            css={css`
              margin-right: 8px;
            `}
          />
          <div
            css={css`
              ${font.title}
              font-size: 18px;
              font-weight: 600;
              color: white;
            `}
          >
            {team.team_name}
          </div>
        </Flex>
        <Flex noFlex>
          <button
            type='button'
            onClick={onMinimize}
            title='Minimize Chat'
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              height: 28px;
              width: 28px;
              border-radius: 50%;
              transition: background-color 0.2s ease-in-out;
              &:hover {
                background-color: rgba(255, 255, 255, 0.2);
              }
            `}
          >
            <Icon name='minus' fontSize={18} />
          </button>
          <button
            type='button'
            onClick={onClose}
            title='Close Chat'
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              height: 28px;
              width: 28px;
              border-radius: 50%;
              transition: background-color 0.2s ease-in-out;
              &:hover {
                background-color: rgba(255, 255, 255, 0.2);
              }
            `}
          >
            <Icon name='times' fontSize={18} />
          </button>
        </Flex>
      </Flex>
      <Flex
        css={css`
          flex-direction: column-reverse;
          overflow: auto;
          padding: 8px;
        `}
        ref={scrollerRef}
        onScroll={({ target }) => {
          if (target.scrollTop < -300) {
            setIsDisplayingScrollToBottom(true)
          } else {
            setIsDisplayingScrollToBottom(false)
          }
        }}
      >
        <div>
          {loading ? (
            <LoadingSpinner
              size={3}
              borderSize={3}
              css={css`
                margin-bottom: 24px;
              `}
            />
          ) : Object.keys(messagesByDay).length === 0 ? (
            <Flex justifyContent='center'>
              <div
                css={css`
                  padding: 6px 12px;
                  background-color: ${colors.SOFT_STEEL};
                  color: ${colors.ATTENDANCE_GRAY};
                  border-radius: 4px;
                  text-align: center;
                  margin-bottom: 36px;
                  font-size: 12px;
                `}
              >
                There are no chat messages yet.
                <div
                  css={css`
                    margin-top: 2px;
                  `}
                >
                  Send one!
                </div>
              </div>
            </Flex>
          ) : (
            Object.keys(messagesByDay).map((day) => {
              const messages = messagesByDay[day]
              return (
                <Fragment key={day}>
                  <Flex
                    justifyContent='center'
                    css={css`
                      margin: 12px 0 4px;
                      position: sticky;
                      top: -10px;
                      z-index: 1;
                    `}
                  >
                    <div
                      css={css`
                        position: sticky;
                        font-size: 12px;
                        text-align: center;
                        padding: 6px 12px;
                        margin: 12px 0 16px;
                        background-color: ${colors.SOFT_STEEL};
                        color: ${colors.ATTENDANCE_GRAY};
                        border-radius: 4px;
                        box-shadow: 0 12px 28px rgba(0, 0, 0, 0.1),
                          0 2px 4px 0 rgba(0, 0, 0, 0.05);
                      `}
                    >
                      {day}
                    </div>
                  </Flex>
                  {messages.map((message, idx) => {
                    return (
                      <ChatMessage
                        key={message.id}
                        message={message}
                        previousUserId={idx !== 0 && messages[idx - 1].user_id}
                        nextUserId={
                          idx !== messages.length - 1 &&
                          messages[idx + 1].user_id
                        }
                        loggedInUserId={loggedInUserId}
                      />
                    )
                  })}
                </Fragment>
              )
            })
          )}
        </div>
      </Flex>

      {error && (
        <Flex
          noFlex
          alignItems='center'
          justifyContent='center'
          css={css`
            position: absolute;
            bottom: 104px;
            left: 12px;
            right: 0;
          `}
        >
          <div
            css={css`
              padding: 6px 12px;
              background-color: rgba(255, 0, 0, 0.8);
              border-radius: 4px;
              text-align: center;
              margin-bottom: 36px;
              font-size: 12px;
            `}
          >
            There was a problem:
            <div
              css={css`
                margin-top: 2px;
              `}
            >
              {error}
            </div>
          </div>
        </Flex>
      )}

      <Flex
        noFlex
        alignItems='center'
        justifyContent='center'
        className={cn('bottom-scroller-btn', {
          'is-shown': isDisplayingScrollToBottom,
        })}
        css={css`
          position: absolute;
          bottom: 20px;
          left: 12px;
          right: 0;

          transition: bottom 0.2s ease-in-out, opacity 0.2s ease-in-out;
          opacity: 0;

          &.is-shown {
            opacity: 1;
            bottom: 104px;
          }
        `}
      >
        <button
          type='button'
          onClick={() => {
            scrollerRef.current.scroll({ top: 0, behavior: 'smooth' })
          }}
          css={css`
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            box-shadow: 0 12px 28px rgba(0, 0, 0, 0.3),
              0 2px 4px 0 rgba(0, 0, 0, 0.2);
            background-color: ${colors.SOFT_STEEL};
            transition: all 0.2s ease-in-out;

            &:hover {
              background-color: ${colors.ATTENDANCE_GRAY};
              i {
                color: black;
              }
            }
          `}
        >
          <Icon name='arrow-down' color={colors.DEFAULT_FLAIR} fontSize={15} />
        </button>
      </Flex>

      <form
        css={css`
          display: flex;
          align-items: center;
          height: 60px;
          padding: 0 16px;

          input {
            transition: width 0.1s ease-in-out;
            width: 296px;

            ${text.length > 0 &&
            css`
              width: 252px;
            `}
          }
          z-index: 1;
        `}
        action='/'
        method='get'
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <input
          ref={inputRef}
          type='text'
          css={css`
            height: 36px;
            border: 0;
            background-color: ${colors.SOFT_STEEL};
            border-radius: 18px;
            color: white;
            padding: 0 16px;
            font-size: 15px;
            ${font.body}
            letter-spacing: 1px;
          `}
          value={text}
          onChange={({ target }) => setText(target.value)}
          placeholder='Aa'
        />
        <button
          type='submit'
          disabled={text.length === 0}
          onClick={onSend}
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            transition: all 0.1s ease-in-out;
            scale: 1;
            margin-left: 8px;

            &[disabled] {
              width: 0;
              opacity: 0.7;
              scale: 0;
              margin-left: 0px;
            }

            &:hover {
              i {
                color: ${isCanlan ? '#113EEE' : colors.AMERICAN_YELLOW};
              }
            }
          `}
        >
          <Icon name='paper-plane' fontSize={20} color='white' />
        </button>
      </form>
    </Flex>
  )
}

export default ChatWindow
