/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'

import { border, font } from '../../../components/css'
import Icon from '../../../components/Icon'
import { Flex } from '../../../components/Layout'
import EditEntitySubTitle from '../../EditEntitySubTitle'

const TeamInfoPanel = ({ teamId, title, selectedTeamData }) => {
  const { organization } = selectedTeamData || {}

  return (
    <div
      css={css`
        border: ${border};
        padding: 20px;
        margin-left: 8px;

        .edit-entity-sub-title-label {
          ${font.body}
          font-size: 14px;
        }
      `}
    >
      <Flex
        alignItems='center'
        justifyContent='space-between'
        css={css`
          margin-bottom: 16px;
        `}
      >
        <h2
          css={css`
            ${font.title}
            font-size: 24px;
            font-weight: bold;
          `}
        >
          {title}
        </h2>
      </Flex>
      {organization && organization?.name ? (
        <Flex
          alignItems='center'
          justifyContent='flex-start'
          css={css`
            margin-bottom: 16px;
            margin-top: 8px;
          `}
        >
          <div
            css={css`
              font-size: 14px;
              font-family: ${font.body};
              font-weight: normal;
              color: ${colors.WHITE};
            `}
          >
            Organization:
          </div>
          <div
            css={css`
              font-size: 14px;
              font-family: ${font.body};
              font-weight: normal;
              color: ${colors.WHITE};
              opacity: 0.8;
              margin-left: 8px;
            `}
          >
            {organization?.name}
          </div>
        </Flex>
      ) : null}
      {organization && organization?.id ? (
        <EditEntitySubTitle id={organization?.id} entityType={ENTITY_TYPES.org} />
      ) : null}
      <EditEntitySubTitle id={teamId} entityType={ENTITY_TYPES.team} />
      <Flex
        justifyContent='center'
        css={css`
          margin-top: 24px;
          border-top: 1px solid ${colors.SOFT_STEEL};
          padding-top: 20px;
        `}
      >
        <a
          href={`${ROUTES.TEAM_ROOT}/${teamId}/roster`}
          rel='noreferrer'
          target='_blank'
          title={`Open ${title} in new tab`}
          css={css`
            ${font.body}
            padding: 10px 12px;
            border-radius: 4px;
            background-color: ${colors.SOFT_STEEL};
            &:hover {
              color: ${colors.DEFAULT_FLAIR};
              i {
                color: ${colors.DEFAULT_FLAIR};
              }
            }
          `}
        >
          <span
            css={css`
              margin-right: 10px;
            `}
          >
            Open in new tab
          </span>
          <Icon faType='far' name='external-link' fontSize={16} />
        </a>
      </Flex>
    </div>
  )
}

export default TeamInfoPanel
