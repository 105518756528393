import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from '@emotion/styled/macro'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import favActions from '@sportninja/common/actions/fav'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { t } from '@sportninja/common/i18n'

import { loadingSpinner } from '../css'

export const FollowBtnButton = styled.button`
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1.3px;
  color: white;
  height: 28px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  text-transform: uppercase;

  padding: 0 6px;

  &[disabled] {
    cursor: not-allowed;
  }

  :hover:not([disabled]) {
    border-color: white;
  }

  opacity: ${props => (props.busy || props.disabled) && 0.6};
  ${props => props.busy && loadingSpinner};

  border-color: ${props => props.isFollowing && props.activeBorderColor};
`

const FollowBtn = ({ activeBorderColor = 'white', className, createFollowing, deleteFollowing, disabled, id, initialValue, render }) => {
  if (!createFollowing || !deleteFollowing) return false

  const mounted = useRef(true)
  const [ loading, setLoading ] = useState(false)
  // Use initial data from search result, but then use our own local state once
  // the user decides to click button
  const [ isFollowing, setIsFollowing ] = useState(initialValue)

  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    if (mounted.current) setIsFollowing(initialValue)
  }, [ initialValue ])

  const handleClick = async () => {
    setLoading(true)
    try {
      if (isFollowing) {
        await deleteFollowing(id)
      } else {
        await createFollowing(id)
      }

      mounted.current && setIsFollowing(!isFollowing)
    } catch(e) {
      console.error('There was a problem:', e) // eslint-disable-line
    }

    mounted.current && setLoading(false)
  }

  return render ? render(handleClick, loading, isFollowing) : (
    <FollowBtnButton
      activeBorderColor={activeBorderColor}
      busy={loading}
      className={className}
      disabled={disabled}
      isFollowing={isFollowing}
      onClick={loading ? undefined : handleClick}
      type='button'>
        {isFollowing ? t('common:following') : t('common:follow')}
    </FollowBtnButton>
  )
}

FollowBtn.propTypes = {
  activeBorderColor: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  className: PropTypes.string,
  createFollowing: PropTypes.func,
  deleteFollowing: PropTypes.func,
  disabled: PropTypes.bool,
  entityType: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  initialValue: PropTypes.bool,
  render: PropTypes.func
}

const mapDispatchToProps = (dispatch, { entityType }) => {
  let actions
  if (entityType === ENTITY_TYPES.org) {
    actions = favActions.org
  } else if (entityType === ENTITY_TYPES.schedule) {
    actions = favActions.schedule
  } else if (entityType === ENTITY_TYPES.team) {
    actions = favActions.team
  } else {
    return {}
  }

  return {
    createFollowing: bindActionToPromise(dispatch, actions.create.request),
    deleteFollowing: bindActionToPromise(dispatch, actions.delete.request)
  }
}

export default connect(null, mapDispatchToProps)(FollowBtn)
