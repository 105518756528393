/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import styled from '@emotion/styled'
import colors from '@sportninja/common/constants/appColors'
import { Link } from 'react-router-dom'

import { Flex } from '../../components/Layout'
import Picture from '../../components/Picture'

const Divider = styled.div`
  height: 2px;
  background-color: ${colors.SOFT_STEEL};
  margin: 16px 0;
`

const HomeSidebarRecent = ({
  type,
  baseRoute,
  seeMoreRoute,
  fullRoute,
  recents = [],
  iconName,
  hideDefaultTitle = false,
  displayGetStarted = false,
  tutorialId = null,
}) => {
  return (
    <>
      <Divider />
      <Flex
        row
        justifyContent='space-between'
        css={css`
          margin-bottom: 16px;
        `}
      >
        <p
          css={css`
            font-weight: 400;
            font-size: 12px;
            color: ${colors.ATTENDANCE_GRAY};
            text-transform: capitalize;
          `}
        >
          {hideDefaultTitle ? `${type}` : `Recent ${type}s`}
        </p>
        {!hideDefaultTitle && (
          <Link
            to={seeMoreRoute}
            css={css`
              font-weight: 500;
              font-size: 12px;
              color: ${colors.DEFAULT_FLAIR};
            `}
          >
            See more
          </Link>
        )}
      </Flex>
      {recents.map((item) => {
        return (
          <Flex
            as={Link}
            row
            key={item.id}
            id={item.id}
            alignItems='center'
            to={`${baseRoute}/${item.id}${
              displayGetStarted ? '?getting_started=1' : ''
            }`}
            css={css`
              margin-top: 16px;
            `}
            onClick={() => {
              if (item?.onClick) {
                item.onClick()
              }
            }}
            className={tutorialId}
          >
            <Picture
              size='kxsmall'
              fit
              square
              imageId={item.imageId}
              iconName={iconName}
            />
            <div
              css={css`
                margin-left: 8px;
              `}
            >
              <p
                className='line-clamp'
                css={css`
                  font-weight: 700;
                  font-size: 14px;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                `}
              >
                {item.fullName}
              </p>
              {item.subTitle && (
                <p
                  css={css`
                    font-weight: 500;
                    font-size: 12px;
                    color: ${colors.LINK_WATER};
                    margin-top: 5px;
                  `}
                >
                  {item.subTitle}
                </p>
              )}
            </div>
          </Flex>
        )
      })}
    </>
  )
}

export default HomeSidebarRecent
