import { produce } from 'immer'
import merge from 'lodash.merge'

import { arrayToObject } from '../utils/utils'
import fav from '../actions/fav'
import authActions from '../actions/auth'
import org from '../actions/org'
import schedule from '../actions/schedule'
import team from '../actions/team'
import { str } from './utils'
import parsers from './parsers'

export const getSharedReducer = (actions, entityType) => (draft, { type, payload }) => {
  switch (type) { // eslint-disable-line default-case
    case str(actions.create.success):
      draft[payload.id] = merge(draft[payload.id], parsers[entityType](payload.data))
      return

    case str(actions.readAll.success): {
      const permissions = payload.meta && payload.meta.permissions || {}
      const data = payload.data.reduce((collector, datum) => {
        const permission = permissions[datum.id]
        collector.push({ ...datum, permission })
        return collector
      }, [])
      return merge(draft, arrayToObject(data, parsers[entityType]))
    }

    case str(actions.delete.success):
      draft[payload.id] = draft[payload.id] || {}
      draft[payload.id].isFollowing = false
      return
  }
}

const initialState = {
  orgs: [],
  schedules: [],
  teams: []
}

const reducer = (draft, { type, payload }) => {
  switch (type) { // eslint-disable-line default-case
    case str(authActions.logout.success):
      return initialState

    case str(org.create.success):
      draft.orgs.push(payload.data.id)
      return

    case str(schedule.create.success):
      draft.schedules.push(payload.data.id)
      return

    case str(team.create.success):
      draft.teams.push(payload.data.id)
      return

    case str(fav.org.create.success):
      draft.orgs.push(payload.id)
      return

    case str(fav.org.readAll.success):
      draft.orgs = payload.data.map((d) => d.id)
      return

    case str(fav.org.delete.success): {
      const idx = draft.orgs.findIndex((id) => id === payload.id)
      draft.orgs.splice(idx, 1)
      return
    }

    case str(fav.schedule.create.success):
      draft.schedules.push(payload.id)
      return

    case str(fav.schedule.readAll.success):
      draft.schedules = payload.data.map((d) => d.id)
      return

    case str(fav.schedule.delete.success): {
      const idx = draft.schedules.findIndex((id) => id === payload.id)
      draft.schedules.splice(idx, 1)
      return
    }

    case str(fav.team.create.success):
      draft.teams.push(payload.id)
      return

    case str(fav.team.readAll.success):
      draft.teams = payload.data.map((d) => d.id)
      return

    case str(fav.team.delete.success): {
      const idx = draft.teams.findIndex((id) => id === payload.id)
      draft.teams.splice(idx, 1)
      return
    }
  }
}

export default (state = initialState, action) => produce(state, (draft) => {
  return reducer(draft, action)
})
