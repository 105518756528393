import { call, put, select } from 'redux-saga/effects'

import req from '../../api/request'
import { t } from '../../i18n'
import { wait } from '../utils'
import { readWithPagesFactory } from './read'
import { getFeedItemById } from '../../selectors/feed'

const api = (entityType) => ({
  read: async (id, page = 1) =>
    await req(`/${entityType}/${id}/social-feed`, {
      query: { order: 'desc', method: 'GET', page, per_page: 10 },
    }),
  create: async (id, body) =>
    await req(`/${entityType}/${id}/social-feed`, { method: 'POST', body }),
  update: async (id, feedItemId, body) =>
    await req(`/${entityType}/${id}/social-feed/${feedItemId}`, {
      method: 'PUT',
      body,
    }),
  delete: async (id, feedItemId) =>
    await req(`/${entityType}/${id}/social-feed/${feedItemId}`, {
      method: 'DELETE',
    }),

  image: {
    // This is the only endpoint not entity-based because who cares about anything anymore???
    create: async (_, feedItemId, body) =>
      await req(`/social-feed/${feedItemId}/image`, { method: 'POST', body }),
    delete: async (id, feedItemId, imageId) =>
      await req(
        `/${entityType}/${id}/social-feed/${feedItemId}/images/${imageId}`,
        { method: 'DELETE' }
      ),
  },
})

export default (entityType, actions) => {
  return [
    [actions.read, readWithPagesFactory(api(entityType).read, actions.read)],
    [
      actions.create,
      function* (payload) {
        const { id, form } = payload
        const { imageData, ...body } = form

        const response = yield call(
          api(entityType).create,
          id,
          JSON.stringify(body)
        )
        yield put(actions.create.success({ id, data: response.data }))

        if (typeof imageData !== 'undefined') {
          try {
            yield wait(
              actions.image.create.request,
              id,
              response.data.id,
              imageData
            )
          } catch (e) {
            response.error = `${t('errors:imageUploadProblem')}.`
          }
        }

        return response
      },
    ],

    [
      actions.update,
      function* (payload) {
        const { id, feedItemId, form } = payload
        const { imageData, ...body } = form

        const response = yield call(
          api(entityType).update,
          id,
          feedItemId,
          JSON.stringify(body)
        )
        yield put(
          actions.update.success({ id: feedItemId, data: response.data })
        )

        if (typeof imageData !== 'undefined') {
          const feedItem = yield select((state) =>
            getFeedItemById(state, feedItemId)
          )
          try {
            if (feedItem.imageId)
              yield wait(
                actions.image.delete.request,
                id,
                feedItemId,
                feedItem.imageId
              )
            yield wait(actions.image.create.request, id, feedItemId, imageData)
          } catch (e) {
            console.error(e)
            response.error = `${t('errors:imageUploadProblem')}.`
          }
        }

        return response
      },
    ],

    [
      actions.delete,
      function* (payload) {
        const { id, feedItemId } = payload

        const response = yield call(api(entityType).delete, id, feedItemId)
        yield put(actions.delete.success({ id, feedItemId }))

        return response
      },
    ],

    // feed item image sagas - but for a given entity.. confusing, I know.
    [
      actions.image.create,
      function* (payload) {
        const { id, feedItemId, imageData } = payload
        const response = yield call(
          api(entityType).image.create,
          id,
          feedItemId,
          imageData
        )
        yield put(
          actions.image.create.success({ feedItemId, data: response.data })
        )
        return response
      },
    ],

    [
      actions.image.delete,
      function* (payload) {
        const { id, feedItemId, imageId } = payload
        const response = yield call(
          api(entityType).image.delete,
          id,
          feedItemId,
          imageId
        )
        yield put(actions.image.delete.success({ feedItemId }))
        return response
      },
    ],
  ]
}
