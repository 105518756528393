/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import React, { useLayoutEffect, useState } from 'react'
import { Flex } from '../../../components/Layout'

import RegistrationDivision from './Division'
import RegistrationIndicatorCard from './RegistrationIndicatorCard'
import { observer } from 'mobx-react-lite'
import { useMobxStore } from '../../../state'
import LoadingSpinner from '../../../components/LoadingSpinner'

import emptyRegistration from './empty_registration.png'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { ROUTES } from '@sportninja/common/constants/app'
import { useHistory } from 'react-router-dom'
import req from '@sportninja/common/api/request'
import { useI18n } from '@shopify/react-i18n'
import Icon from 'src/components/Icon'
import { Button } from 'src/components/Button'
import { ButtonToggle } from 'src/components/ButtonToggle'

const generateHref = (string, reportName, ref) => {
  var blob = new Blob([string], { type: 'text/csv;charset=utf-8;' })

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, reportName)
  } else {
    var a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    var csvUrl = URL.createObjectURL(blob)
    a.href = csvUrl
    a.download = reportName
    a.click()
    URL.revokeObjectURL(a.href)
    a.remove()
  }
}

dayjs.extend(relativeTime)

const Registration = ({ scheduleId, sportId, accountOwnerId }) => {
  const history = useHistory()
  const downloadRef = React.useRef(null)
  const [loading, setLoading] = React.useState(false)
  const [hasCopiedLink, setHasCopiedLink] = useState(false)
  const [isAllOpen, setIsAllOpen] = useState(true)
  const [showCancelledTeams, setShowCancelledTeams] = useState(false)

  const [i18n] = useI18n()

  const onView = async () => {
    try {
      const response = await req(
        `/reports/${scheduleId}/registration?format=csv`,
        { parseJSON: false }
      )
      const text = await response.text()
      generateHref(text, 'registration', downloadRef)
    } catch (e) {
      console.error(e)
      alert(
        `There was an error generating the report. ${
          e?.message || ''
        } Please try again.`
      )
    }
  }

  const {
    registrationTypes: { fetchTypes },
    me: {
      state: { me },
    },
    registration: {
      loadDivisions,
      setScheduleOwner,
      state: {
        divisions,
        isLoading,
        error,
        registration,
        analytics,
        enableRegistrationSetup,
      },
    },
  } = useMobxStore()

  useLayoutEffect(() => {
    fetchTypes()
    loadDivisions(scheduleId)
    setScheduleOwner(accountOwnerId)
  }, [accountOwnerId, scheduleId])

  if (isLoading) {
    return (
      <div
        css={css`
          padding-top: 20%;
        `}
      >
        <LoadingSpinner />
      </div>
    )
  }

  if (error) {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
          padding-top: 10%;
        `}
      >
        <span>{error}</span>
      </div>
    )
  }

  if (!registration || divisions.length === 0) {
    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-top: 150px;
        `}
      >
        <div
          css={css`
            width: 426px;
            height: 251px;
          `}
        >
          <img
            src={emptyRegistration}
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        </div>
        <p
          css={css`
            margin-top: 10px;
            font-weight: 600;
            font-size: 24px;
            color: ${colors.WHITE};
            font-family: ${isCanlan
              ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
              : 'Rift, Arial, Helvetica, sans-serif'};
          `}
        >
          WOULD YOU LIKE TO SETUP REGISTRATION FOR THIS COMPETITION?
        </p>
        {enableRegistrationSetup ? (
          <button
            onClick={() =>
              history.push(`${ROUTES.SETUP_REGISTRATION}`, { scheduleId })
            }
          >
            <p
              css={css`
                color: ${colors.DEFAULT_FLAIR};
                margin-top: 10px;
                font-size: 16px;
              `}
            >
              Set up Registration
            </p>
          </button>
        ) : (
          <button
            onClick={() =>
              history.push(`${ROUTES.SCHEDULE_ROOT}/${scheduleId}/setup`)
            }
          >
            <p
              css={css`
                color: ${colors.DEFAULT_FLAIR};
                margin-top: 10px;
                font-size: 16px;
              `}
            >
              Go to Setup Tab
            </p>
          </button>
        )}
      </div>
    )
  }

  return (
    <>
      <div
        css={css`
          display: none;
          @media (min-width: 1160px) {
            display: flex;
            flex-direction: row;
            flex: 1.1;
            gap: 16px;
            margin-top: 16px;
          }
        `}
      >
        <RegistrationIndicatorCard
          iconName='user-plus'
          title='Registrations'
          mainText={analytics?.registrations?.total || 0}
          subText={`${(
            (analytics?.registrations?.last_7_days_trend_percentage || 0) * 100
          ).toFixed(2)}% in last 7 days`}
          hasIncreased={analytics?.registrations?.last_7_days_trend === 'up'}
        />
        <RegistrationIndicatorCard
          iconName='dollar-sign'
          title='Revenue'
          mainText={
            i18n.formatCurrency(analytics?.revenue?.total, {
              currency: registration?.currency?.name,
              form: 'explicit',
            }) || 0
          }
          subText={`${(
            (analytics?.revenue?.last_7_days_trend_percentage || 0) * 100
          ).toFixed(2)}% in last 7 days`}
          hasIncreased={analytics?.revenue?.last_7_days_trend === 'up'}
        />
        {registration?.custom_link && (
          <RegistrationIndicatorCard
            iconName='link'
            title='Registration Link'
            renderContent={() => (
              <div
                css={css`
                  font-weight: 700;
                  font-size: 24px;
                  letter-spacing: 0.05em;
                  margin-top: 4px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 85%;
                `}
              >
                <p
                  css={css`
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-size: 12px;
                    font-style: normal;
                    line-height: 100%;
                    letter-spacing: 0.8px;
                    color: ${colors.WHITE};
                    margin-bottom: 10px;
                    white-space: nowrap;
                  `}
                >
                  {registration?.custom_link}
                </p>
                <button
                  disabled={hasCopiedLink}
                  onClick={(e) => {
                    e.preventDefault()
                    navigator.clipboard.writeText(registration?.custom_link)
                    setHasCopiedLink(true)
                    setTimeout(() => {
                      setHasCopiedLink(false)
                    }, 2000)
                  }}
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 8px;
                  `}
                >
                  {hasCopiedLink ? (
                    <p
                      css={css`
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        color: ${colors.DEFAULT_FLAIR};
                      `}
                    >
                      Copied!
                    </p>
                  ) : (
                    <>
                      <Icon
                        name='copy'
                        faType='far'
                        color={colors.DEFAULT_FLAIR}
                      />
                      <p
                        css={css`
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          color: ${colors.DEFAULT_FLAIR};
                        `}
                      >
                        Copy
                      </p>
                    </>
                  )}
                </button>
              </div>
            )}
          />
        )}
        {/* Hide this button for now */}
        {/* <RegistrationIndicatorCard
          iconName='download'
          title='Report'
          renderContent={() => {
            return (
              <div
                css={css`
                  margin-top: 8px;
                `}
              >
                <a
                  css={css`
                    font-weight: 700;
                    font-size: 16px;
                    letter-spacing: 0.05em;
                    margin-top: 8px;
                    text-decoration: underline;
                  `}
                  ref={downloadRef}
                  onClick={onView}
                >
                  Download CSV
                </a>
              </div>
            )
          }}
        /> */}
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          border-bottom: 1px solid ${colors.SOFT_STEEL};
          margin: 16px 0;
          padding-bottom: 16px;
          gap: 16px;
        `}
      >
        <div>
          <ButtonToggle
            label='Display Cancelled'
            isActive={showCancelledTeams}
            onClick={() => setShowCancelledTeams((e) => !e)}
          />
        </div>
        <div
          css={css`
            min-width: 150px;
          `}
        >
          <Button
            label={isAllOpen ? 'Collapse All' : 'Expand All'}
            onClick={() => setIsAllOpen((e) => !e)}
            variant='tertiary'
            useAltFontFamily
            small
          />
        </div>
      </div>
      <Flex
        css={css`
          margin-top: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            flex: 5;
          `}
        >
          {divisions.map((division) => {
            return (
              <RegistrationDivision
                key={division.id}
                division={division}
                scheduleId={scheduleId}
                sportId={sportId}
                isExpanded={isAllOpen}
                showCancelledTeams={showCancelledTeams}
              />
            )
          })}
        </div>
      </Flex>
    </>
  )
}

export default observer(Registration)
