/** @jsxImportSource @emotion/react */

import React from 'react'
import css from '@emotion/css/macro'
import { Controller } from 'react-hook-form'
import type { Control, RegisterOptions } from 'react-hook-form'
import colors from '@sportninja/common/constants/appColors'
import type { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import ReactInputMask from 'react-input-mask'
import { Input as AntInput } from 'antd'

interface Props {
  control?: Control<any>
  fieldName: string
  required?: boolean
  placeholder?: string
  invalid?: boolean
  invalidMessage?: string
  type?: React.HTMLInputTypeAttribute
  icon?: React.ReactNode | JSX.Element | EmotionJSX.Element
  onIconPress?: () => void
  label?: string
  disabled?: boolean
  isMockInput?: boolean
  mockValue?: string
  secureTextEntry?: boolean
  mask?: 'date'
  useReturnKey?: boolean
  onReturnPress?: () => void
  autoComplete?: 'on' | 'off'
  isLightMode?: boolean
  customWidth?: string
  customRules?: Omit<
    RegisterOptions<any, string>,
    'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  tabIndex?: number
  min?: number
  maxLength?: number
}

export const Input = ({
  control,
  fieldName,
  invalid,
  invalidMessage,
  placeholder,
  required,
  type = 'text',
  icon,
  label,
  disabled,
  isMockInput,
  mockValue,
  secureTextEntry,
  mask,
  onIconPress,
  autoComplete = 'on',
  isLightMode = false,
  customWidth,
  customRules = {},
  tabIndex = 1,
  min,
  maxLength = 500,
}: Props) => {
  if (isMockInput) {
    return (
      <div
        css={css`
          max-width: ${customWidth || '100%'};
          display: flex;
          flex-flow: column;
          flex: 1;
        `}
      >
        {label && (
          <p
            tabIndex={-1}
            css={css`
              font-weight: 500;
              font-size: 14px;
              line-height: 14px;
              color: ${colors.WHITE};
              margin-bottom: 8px;
            `}
          >
            {label}
            {required ? ' *' : ''}
          </p>
        )}
        <div
          tabIndex={-1}
          css={css`
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: space-between;
            transition: all 0.1s ease-in-out;
            ${isLightMode && `.ant-input { color: ${colors.BLACK}!important;}`}

            &:focus-within {
              border-color: ${disabled
                ? colors.ATTENDANCE_GRAY
                : isLightMode
                ? colors.ATTENDANCE_GRAY
                : colors.WHITE};
            }
          `}
        >
          <AntInput
            maxLength={maxLength}
            tabIndex={1}
            disabled={disabled}
            placeholder={placeholder}
            required={required}
            min={min}
            type={type}
            value={mockValue}
            suffix={
              !!icon && (
                <button tabIndex={-1} onClick={onIconPress}>
                  {icon}
                </button>
              )
            }
            status={invalid ? 'error' : undefined}
          />
        </div>
      </div>
    )
  }

  if (mask === 'date') {
    return (
      <div
        tabIndex={-1}
        css={css`
          max-width: ${customWidth || '100%'};
          display: ${type === 'hidden' ? 'none' : 'flex'};
          flex-flow: column;
          flex: 1;
        `}
      >
        {label && (
          <p
            tabIndex={-1}
            css={css`
              font-weight: 500;
              font-size: 14px;
              line-height: 14px;
              color: ${isLightMode ? colors.ATTENDANCE_GRAY : colors.WHITE};
              margin-bottom: 8px;
            `}
          >
            {label}
            {required ? ' *' : ''}
          </p>
        )}
        <Controller
          control={control}
          name={fieldName}
          rules={{ ...customRules, required }}
          render={({ field }) => (
            <div
              tabIndex={-1}
              css={css`
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: space-between;
                transition: all 0.1s ease-in-out;

                &:focus-within {
                  border-color: ${disabled
                    ? colors.SOFT_STEEL
                    : isLightMode
                    ? colors.ATTENDANCE_GRAY
                    : colors.WHITE};
                }
              `}
            >
              <ReactInputMask
                tabIndex={1}
                mask='9999-99-99'
                disabled={disabled}
                autoComplete={autoComplete}
                css={css`
                  display: flex;
                  border: none;
                  width: 100%;
                  flex: 1;
                  color: ${disabled
                    ? colors.SOFT_STEEL
                    : isLightMode
                    ? colors.BLACK
                    : colors.WHITE};
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 16px;
                `}
                required={required}
                placeholder='yyyy-mm-dd'
                type='text'
                {...field}
              />
            </div>
          )}
        />
        <div
          tabIndex={-1}
          css={css`
            margin-top: 8px;
            transition: opacity 0.1s ease-in-out;
            opacity: ${invalid ? 1 : 0};
            font-size: 12px;
            color: ${colors.ERROR_LIGHT};
          `}
        >
          <span>{invalidMessage}</span>
        </div>
      </div>
    )
  }

  return (
    <div
      tabIndex={-1}
      css={css`
        max-width: ${customWidth || '100%'};
        display: ${type === 'hidden' ? 'none' : 'flex'};
        flex-flow: column;
        flex: 1;
      `}
    >
      {label && (
        <p
          tabIndex={-1}
          css={css`
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            color: ${colors.WHITE};
            margin-bottom: 8px;
          `}
        >
          {label}
          {required ? ' *' : ''}
        </p>
      )}
      <Controller
        control={control}
        name={fieldName}
        rules={{
          required,
          pattern:
            type === 'email'
              ? {
                  value: /\S+@\S+\.\S+/,
                  message: 'Entered value does not match email format',
                }
              : undefined,
          ...customRules,
        }}
        render={({ field }) => (
          <div
            tabIndex={-1}
            css={css`
              display: flex;
              flex: 1;
              align-items: center;
              justify-content: space-between;
              min-height: 40px;
              transition: all 0.1s ease-in-out;

              ${isLightMode &&
              `.ant-input { color: ${colors.BLACK}!important;}`}

              &:focus-within {
                border-color: ${disabled
                  ? colors.SOFT_STEEL
                  : isLightMode
                  ? colors.ATTENDANCE_GRAY
                  : colors.WHITE};
              }
            `}
          >
            <AntInput
              maxLength={maxLength}
              id={fieldName}
              disabled={disabled}
              tabIndex={tabIndex}
              required={required}
              autoComplete={autoComplete}
              placeholder={placeholder}
              min={min}
              type={secureTextEntry ? 'password' : type}
              {...field}
              suffix={
                !!icon && (
                  <button tabIndex={-1} onClick={onIconPress}>
                    {icon}
                  </button>
                )
              }
              status={invalid ? 'error' : undefined}
            />
          </div>
        )}
      />
      <div
        tabIndex={-1}
        css={css`
          margin-top: 8px;
          transition: opacity 0.1s ease-in-out;
          opacity: ${invalid ? 1 : 0};
          font-size: 12px;
          color: ${colors.ERROR_LIGHT};
        `}
      >
        <span>{invalidMessage || '-'}</span>
      </div>
    </div>
  )
}
