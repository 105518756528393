/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import cn from 'classnames'

import { font } from '../css'
import { Flex } from '../Layout'

export const filterButtonBaseCss = css`
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.2);
  ${font.body}
  font-size: 13px;
  letter-spacing: 1.24px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%; /* Safari wants this */

  &:first-of-type {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;

    margin-right: -1px;
  }

  &:last-of-type {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  /* If there are three buttons, fix the border alignment between the 2nd and 3rd */
  &:nth-of-type(3),
  &:last-of-type {
    margin-left: -1px;
  }
`

export const FilterButton = styled(Flex)`
  ${filterButtonBaseCss}

  :hover:not([disabled]) {
    border-color: white;
  }

  &.active {
    border-color: white;
    color: white;
  }
`

export const FilterButtons = ({
  hideWhenSingleTab,
  onClick,
  selectedType,
  types = {},
}) => {
  if (hideWhenSingleTab && Object.keys(types)?.length === 1) {
    return false
  }

  return (
    <Flex
      noFlex
      row
      css={css`
        min-height: 46px;
      `}
    >
      <MediaQuery query='(max-width: 550px)'>
        {(shouldTruncate) => {
          return Object.keys(types).map((keyName, idx) => {
            const type = types[keyName]
            return (
              <FilterButton
                key={idx}
                as='button'
                className={cn('filter-button', {
                  active: keyName === selectedType,
                })}
                onClick={() => {
                  onClick(keyName)
                }}
              >
                {shouldTruncate ? type.button.short : type.button.long}
              </FilterButton>
            )
          })
        }}
      </MediaQuery>
    </Flex>
  )
}

FilterButtons.propTypes = {
  hideWhenSingleTab: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  selectedType: PropTypes.string.isRequired,
  types: PropTypes.object.isRequired,
}
