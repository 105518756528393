import { generateActions } from './utils'

const definition = {
  assists: {
    create: ['id', 'goalId', 'form', 'assistType'],
    update: ['id', 'goalId', 'form'],
    delete: ['id', 'goalId', 'assistId'],
  },
  periods: {
    create: ['id', 'form'],
    update: ['id', 'periodId', 'form'],
    delete: ['id', 'periodId'],
  },
  penalties: {
    create: ['id', 'periodId', 'form'],
    update: ['id', 'penaltyId', 'form'],
    delete: ['id', 'teamId', 'penaltyId'],
  },
  goals: {
    create: ['id', 'periodId', 'form'],
    update: ['id', 'goalId', 'form'],
    delete: ['id', 'goalId', 'occurredAt'],
  },
  goalieChanges: {
    create: ['id', 'periodId', 'form'],
    update: ['id', 'goalieChangeId', 'form'],
    delete: ['id', 'goalieChangeId'],
  },
  shots: {
    create: [
      'id',
      'periodId',
      'teamId',
      'occurredAt',
      'opposingGoalieId',
      'uid',
      'period_clock_time',
    ],
    delete: ['id', 'shotId'],
  },
  signatures: {
    create: ['id', 'userId', 'data'],
  },
}

export default generateActions(['SCORING'], definition, true)
