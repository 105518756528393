/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import debounce from 'lodash.debounce'
import req from '@sportninja/common/api/request'
import { useEffect, useRef, useState } from 'react'

import Icon from '../../Icon'
import ThumbnailAndName from '../../ThumbnailAndName'
import LoadingSpinner from '../../LoadingSpinner'
import SuspensionSelect from '../SuspensionSelect'
import { ReactComponent as DropdownArrow } from '../dropdown.svg'
import SuspensionSearchSelectResults from './SuspensionSearchSelectResults'

const NUM_ITEMS_PER_PAGE = 10

const SuspensionSearchSelect = ({
  dataMapper,
  dataTransformer,
  label,
  checkPermissions,
  getSubTitle = () => '',
  slug,
  queryObject = {},
  noFlex,
  noImage,
  noSearch,
  noResultsText,
  onChange,
  placeholder,
  iconName = 'user',
  useInitials = false,
  required = false,
  squareThumbnail = false,
  value,
  useInfiniteScrolling = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [items, setItems] = useState([])
  const [meta, setMeta] = useState({})
  const [loadingResults, setLoadingResults] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef(null)
  const containerRef = useRef(null)
  const currSearchTerm = useRef(null)
  const searchRequest = useRef(
    debounce(async (slug, searchTerm, page, callback) => {
      const response = await req(slug, {
        query: {
          ...queryObject,
          query: searchTerm,
          page,
          per_page: NUM_ITEMS_PER_PAGE,
        },
      })
      callback({ ...response, requestedSearchTerm: searchTerm })
    }, 250)
  ).current

  useEffect(() => {
    const handler = (e) => {
      if (!containerRef?.current?.contains(e.target)) {
        setIsOpen(false)
        document.removeEventListener('click', handler)
      }
    }
    if (isOpen) document.addEventListener('click', handler)
    return () => {
      document.removeEventListener('click', handler)
    }
  }, [isOpen])

  const hasPermission = (item, permissions) => {
    if (!permissions) return true

    const permissionObject = permissions[item.id]
    const permissionValues = Object.values(permissionObject)
    let hasPermission = false
    for (let i = 0; i < permissionValues.length; i++) {
      if (permissionValues[i] === true) {
        hasPermission = true
        break
      }
    }

    return hasPermission
  }

  useEffect(() => {
    currSearchTerm.current = searchTerm
    if (slug?.length > 0 && (noSearch || searchTerm?.length > 0 || isFocused)) {
      !noSearch && setIsOpen(true)
      setLoadingResults(true)
      setItems([])
      setMeta({})

      searchRequest(
        slug,
        searchTerm,
        1,
        ({ data: _data, meta, requestedSearchTerm }) => {
          let data = _data || []
          if (typeof dataTransformer == 'function') {
            data = dataTransformer(_data)
          }
          setLoadingResults(false)

          if (requestedSearchTerm === currSearchTerm.current) {
            setMeta(meta?.pagination)
            let filteredData = data

            if (checkPermissions) {
              filteredData = data?.filter((d) =>
                hasPermission(d, meta?.permissions)
              )
            }

            const mappedData = filteredData?.map(dataMapper)
            setItems(mappedData)
          }
        }
      )
    } else {
      setLoadingResults(false)
      setMeta({})
      setItems([])
      setIsOpen(false)
    }
  }, [isFocused, checkPermissions, slug, noSearch, searchTerm])

  const onPageChange = async (page) => {
    try {
      if (!useInfiniteScrolling) {
        setLoadingResults(true)
      }
      const response = await req(slug, {
        query: {
          ...queryObject,
          query: searchTerm,
          page,
          per_page: NUM_ITEMS_PER_PAGE,
        },
      })
      setMeta(response?.meta?.pagination)
      if (useInfiniteScrolling) {
        const newResults = response.data.map(dataMapper)
        setItems((prev) => {
          return [...prev, ...newResults]
        })
      } else {
        setItems(response?.data?.map(dataMapper))
      }
    } finally {
      setLoadingResults(false)
    }
  }

  const isItemSelected = value ? Object.keys(value).length > 0 : false
  let selectedItem = items.find((item) => item?.id === value?.id)

  if (!selectedItem) {
    if (typeof dataMapper === 'function' && value) {
      selectedItem = dataMapper(value)
    } else {
      selectedItem = value
    }
  }

  // let selectedItem = items.find((item) => item.id === value.id)

  // if (!selectedItem && value) {
  //   if (typeof dataMapper === 'function') {
  //     selectedItem = dataMapper(value)
  //   } else {
  //     selectedItem = value
  //   }
  // }

  return (
    <div
      ref={containerRef}
      className='suspension-select-container suspension-search-select-container'
    >
      <SuspensionSelect
        className='suspension-search-select'
        required={required}
        noFlex={noFlex}
        label={label}
        icon={
          noSearch ? (
            loadingResults ? (
              <div className='report-select-icon'>
                <LoadingSpinner size={1.5} borderSize={2} />
              </div>
            ) : isItemSelected ? (
              <Icon
                className='report-select-icon'
                name='times'
                onClick={() => {
                  if (isItemSelected) {
                    onChange({})
                    setTimeout(() => {
                      inputRef?.current?.focus()
                      setIsOpen(false)
                    }, 0)
                  }
                }}
              />
            ) : (
              false
            )
          ) : (
            <Icon
              className='report-select-icon'
              name={isItemSelected ? 'times' : 'search'}
              onClick={() => {
                if (isItemSelected) {
                  setSearchTerm('')
                  onChange({})
                  setTimeout(() => {
                    inputRef?.current?.focus()
                    setIsFocused(true)
                  }, 0)
                }
              }}
            />
          )
        }
      >
        <div
          className='suspension-search-input-container'
          onClick={() => {
            if (isItemSelected || (noSearch && items?.length > 0))
              setIsOpen(true)
          }}
          css={css`
            ${(isItemSelected || (noSearch && items?.length > 0)) &&
            'cursor: pointer;'}
          `}
        >
          {isItemSelected ? (
            noImage ? (
              <div className='selected-item-name-container'>
                <div className='selected-item-name'>
                  {selectedItem?.fullName}
                </div>
              </div>
            ) : (
              <ThumbnailAndName
                size='xxxsmall'
                row={selectedItem}
                square={squareThumbnail}
                fit={squareThumbnail}
                useInitials={useInitials}
                iconName={iconName}
                initialsSize={24}
                textClassName='selected-item-name'
              />
            )
          ) : noSearch ? (
            <span className='suspension-search-no-search-text'>
              {items?.length === 0
                ? loadingResults
                  ? 'Loading...'
                  : noResultsText
                : placeholder}
            </span>
          ) : (
            <input
              className='suspension-search-input'
              ref={inputRef}
              placeholder='Search'
              onFocus={() => {
                setIsFocused(true)
                setIsOpen(true)
              }}
              type='text'
              onChange={({ target }) => {
                setSearchTerm(target.value)
              }}
            />
          )}
        </div>
        <SuspensionSearchSelectResults
          getSubTitle={getSubTitle}
          isOpen={isOpen}
          items={items}
          meta={meta}
          noImage={noImage}
          noSearch={noSearch}
          loadingResults={loadingResults}
          searchTerm={searchTerm}
          onChange={onChange}
          onPageChange={onPageChange}
          setIsOpen={setIsOpen}
          iconName={iconName}
          useInitials={useInitials}
          squareThumbnail={squareThumbnail}
          isFocused={isFocused}
          useInfiniteScrolling={useInfiniteScrolling}
        />
      </SuspensionSelect>
    </div>
  )
}

export default SuspensionSearchSelect
