/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types'
import css from '@emotion/css/macro'
import { timeStringFromMS } from '@sportninja/common/utils/utils'
import { t } from '@sportninja/common/i18n'

import { Flex } from '../../../components/Layout'
import { COL_GUTTER_UNIT } from '../../../components/Responsive'
import FormSheet from '../../../components/Form/FormSheet'
import { scoringPeriodForm } from '../../../components/Form/form-configs'
import { font } from '../../../components/css'
import ActionButton from '../../../components/ActionButton'
import { Pencil } from './TimelineEvent'

const line = css`
  flex: 1;
  height: 1px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.1);
`

const TimelinePeriod = ({
  isEditMode,
  isFirst,
  isLast,
  onEdit,
  onDelete,
  periodName,
  periodLength,
}) => {
  const Button = ({ onClick }) => (
    <Flex
      fullWidth
      noFlex
      row
      alignItems='center'
      justifyContent='center'
      className='timeline-period'
      css={css`
        position: relative;
        height: ${isEditMode ? 75 : 48}px;
        margin: 0 ${COL_GUTTER_UNIT}px ${isFirst && !isEditMode ? 42 : 21}px;

        background-color: ${isEditMode && 'rgba(255, 255, 255, 0.05)'};
        overflow-y: hidden;

        &,
        .pencil-icon {
          transition: background-color 0.2s ease-in-out;
        }

        ${isEditMode &&
        css`
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
            cursor: pointer;
            box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.25);

            .pencil-icon {
              background-color: rgba(255, 255, 255, 0.08);
            }
          }
        `}
      `}
      onClick={isEditMode ? onClick : undefined}
    >
      {!isEditMode && <div className='timeline-period-line' css={line} />}

      <div
        css={css`
          display: flex;
          flex-direction: row;
          margin: 0 20px;
          position: relative;
        `}
      >
        <div
          css={css`
            ${font.body};
            font-size: 16px;
            letter-spacing: 0.92px;
            line-height: 19px;
            text-transform: uppercase;
            text-align: center;
          `}
        >
          {periodName}
          <div>{timeStringFromMS(periodLength)}</div>
        </div>
        {isEditMode && (
          <Pencil
            css={css`
              position: absolute;
              right: -60px;
              top: -4px;
            `}
          />
        )}
      </div>

      {!isEditMode && <div className='timeline-period-line' css={line} />}
    </Flex>
  )

  return (
    <FormSheet
      form={scoringPeriodForm({ duration: periodLength })}
      label={t('common:edit')}
      onSubmit={onEdit}
      title={`${t('common:edit')} ${periodName}`}
      Button={Button}
      deleteTitle='Delete Period'
      deleteMessage={`Are you sure you want to delete ${periodName}? ${t(
        'Web:deletionCantBeUndone'
      )}`}
      customDeleteModal={
        isLast ? null : (
          <Flex
            alignItems='center'
            css={css`
              p {
                opacity: 0;
                margin-right: 8px;
                transition: opacity 0.1s ease-in-out;
                width: 200px;
                text-align: center;
              }
              &:hover {
                p {
                  opacity: 0.8;
                }
              }
            `}
          >
            <p>You must delete periods in order from last to first.</p>
            <ActionButton
              icon='trash'
              disabled
              title='Delete'
              label='Delete'
              onClick={() => {}}
            />
          </Flex>
        )
      }
      onDelete={onDelete}
    />
  )
}

TimelinePeriod.propTypes = {
  isEditMode: PropTypes.bool,
  isFirst: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  periodName: PropTypes.string.isRequired,
  periodLength: PropTypes.number.isRequired,
}

export default TimelinePeriod
