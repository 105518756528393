import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import unionWith from 'lodash.unionwith'
import deepEqual from 'fast-deep-equal'

import { arrayToObject } from '../utils/utils'
import { isCanlan } from '../utils/customer-name'

dayjs.extend(utc)

// Given the immer draft, use the id to find the right key, then use keyName to
// find the right child list.
// Given the mapper, map the data to the child list key.
// Given the comparator, use it to compare arrA and arrB values one by one to
// determine which should be included in the new merged array.
//
// If draft[id][keyName] doesn't exist, create an empty array.
export const setChildListById = (
  draft,
  { id, data },
  keyName,
  mapper = (o) => o.id,
  comparator = deepEqual
) => {
  if (typeof keyName !== 'undefined') {
    if (!draft[id]) draft[id] = {}

    if (data.length === 0) {
      draft[id][keyName] = []
      return
    }

    draft[id][keyName] = unionWith(
      draft[id][keyName] || [],
      data.map(mapper),
      comparator
    )
  } else {
    if (!draft[id]) draft[id] = []

    if (!data || data.length === 0) {
      return
    }

    draft[id] = unionWith(draft[id], data.map(mapper), comparator)
  }
  return
}

/**
 * Given a list of roles from the API, extract entity data so that it can be
 * stored in the respective entity state slice.
 *
 * @param {string} entityIdKeyName - e.g. `schedule_id`, `organization_id`; This is the only way to identify what type of entity the role is for
 * @param {array} payload.data - A list of roles
 * @returns {object} - The relevant entity data
 */
export const roleToEntity = (entityIdKeyName, payload, parser) => {
  if (!payload || !payload.data) return {}

  const entitiesFromUser = payload.data.filter((role) =>
    Object.prototype.hasOwnProperty.call(role, entityIdKeyName)
  )

  return arrayToObject(
    entitiesFromUser,
    parser
      ? parser
      : (entity) => {
          const {
            [entityIdKeyName]: omit,
            description,
            role,
            is_primary,
            ...data
          } = entity // eslint-disable-line no-unused-vars
          return data
        }
  )
}

/**
 * Given the list of user's roles, reduce and add it to the provided Immer state object
 *
 * @param {object} draft - Immer state from reducer
 * @param {array} roles - List of user's roles
 */
export const getUserRoles = (roles) => {
  const org = [],
    schedule = [],
    team = []
  let target

  if (Array.isArray(roles)) {
    roles.forEach((role) => {
      if (role.hasOwnProperty('schedule_id')) {
        target = schedule
      } else if (role.hasOwnProperty('organization_id')) {
        target = org
      } else if (role.hasOwnProperty('team_id')) {
        target = team
      }

      target.push({
        id: role.id,
        description: role.description,
        roleTypeId: role.role_type_id,
      })
    })
  }

  return { org, schedule, team }
}

// Dates are stored as UTC by the API, so parse as UTC. For birth dates in
// particular, we want to display the 'absolute' UTC date that was selected by
// the user (i.e. we don't want to convert to a locale time-zone string)
export const getBirthDateString = (data, format = 'YYYY-MM-DD') => {
  let alternativeDateFormat = 'DD/MM/YYYY'
  const isBirthDateString = typeof data === 'string'
  if (!isBirthDateString && (!data || !data.birth_date)) {
    return false
  }
  const birthDate = isBirthDateString ? data : data.birth_date
  let result = dayjs.utc(birthDate).format(format)
  if (result?.toUpperCase()?.includes('INVALID DATE')) {
    // Let's try to parse it with another format
    result = dayjs.utc(birthDate).format(alternativeDateFormat)
  }
  return result
}

export const getImageThumbnailId = (data) => {
  if (!data || (!data.image && !Array.isArray(data.images))) return

  // Return an 80x80 image if available
  if (data.image) {
    // Why not add even more variations of a schema, thanks API devs
    if (typeof data.image === 'string') {
      return `https://${
        isCanlan ? 'canlan-images-production' : 'sportninja-images-production'
      }.s3.amazonaws.com/original/${data.image}`
    } else if (
      Array.isArray(data.image.formats) &&
      data.image.formats.length === 3
    ) {
      if (data.image.formats[0].full_path)
        return data.image.formats[0].full_path
      else if (data.image.formats[0].filename)
        return `https://${
          isCanlan ? 'canlan-images-production' : 'sportninja-images-production'
        }.s3.amazonaws.com/resized/${data.image.formats[0].filename}`
    } else {
      if (data.image.full_path) return data.image.full_path
      else if (data.image.filename)
        return `https://${
          isCanlan ? 'canlan-images-production' : 'sportninja-images-production'
        }.s3.amazonaws.com/original/${data.image.filename}`
    }
  } else if (Array.isArray(data.images) && data.images.length > 0) {
    return data.images[0].full_path
  } else if (data.team_image) {
    return data.team_image.full_path
  }

  return ''
}

export const getAddress = (data) => {
  const addr = data.primary_venue && data.primary_venue.address

  if (!addr) {
    return {}
  }

  let address = []
  const parts = [
    'street_1',
    'street_2',
    'city',
    'province',
    'postal_code',
    'country',
  ]
  parts.forEach((part) => {
    let value = addr[part]
    if (!value) return
    else if (part === 'province' || part === 'country') value = value.name

    address.push(value)
  })

  return { value: address.join(', ') }
}

export const convertToImperial = (heightInMetres) => {
  if (heightInMetres === null || typeof heightInMetres === 'undefined')
    return ['', '']

  const metresInDecimalFeet = heightInMetres * 3.2808
  let feet = Math.floor(metresInDecimalFeet)
  let inches = Math.round(12 * (metresInDecimalFeet % 1))

  // By convention, no one says they're 4' 12". So set inches to 0, and add a
  // foot to feet.
  if (inches === 12) {
    feet++
    inches = 0
  }

  return [feet, inches]
}

export const convertToMetres = (heightInImperial) => {
  const { ft, inches } = heightInImperial
  if (ft === '' || inches === '') return false

  const feet = parseInt(ft, 10)
  const inch = parseInt(inches, 10) / 12

  return (feet + inch) / 3.2808
}
