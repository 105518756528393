/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import cn from 'classnames'

import { font } from '../../../components/css'
import { Flex } from '../../../components/Layout'
import Picture from '../../../components/Picture'
import { SLOT_HEIGHT } from './constants'

const Slot = ({
  team,
  isTopScore,
  selected,
  teamStats = {},
  onClick = () => {},
  shouldDisplayScoreInsteadOfPoints = false,
  isMockPool = false,
}) => {
  const {
    id,
    team_id,
    seed,
    name_full,
    team_slot_type_id,
    score,
    isMockTeam,
    gameDidntEnd,
  } = team

  const imageId = getImageThumbnailId(team)
  const isSelected = selected?.id === team_id || selected?.id === id

  return (
    <Flex
      className={cn('slot', {
        highlighted: isSelected,
      })}
      alignItems='center'
      justifyContent='space-between'
      css={css`
        cursor: pointer;
        height: ${SLOT_HEIGHT}px;
        max-height: ${SLOT_HEIGHT}px;
        position: relative;
        padding-left: 8px;
        border-top: 1px solid ${colors.PALE_SKY};
        background-color: ${isSelected
          ? 'rgba(255, 255, 255, 0.1)'
          : colors.HEADER};
      `}
      onClick={onClick}
    >
      {team_slot_type_id !== 2 && (
        <Picture
          square
          fit
          size='skxxsmall'
          iconName='user-friends'
          imageId={imageId}
          css={css`
            margin-right: 12px;
          `}
        />
      )}
      <Flex
        column
        justifyContent='center'
        css={css`
          margin-right: 12px;
        `}
      >
        <div
          css={css`
            font-weight: 700;
            font-size: 12px;
            margin-bottom: 4px;
          `}
        >
          {name_full}
        </div>
        {
          <div
            css={css`
              font-size: 12px;
              color: ${colors.ATTENDANCE_GRAY};
            `}
          >
            {teamStats?.scoreString || ''}
          </div>
        }
      </Flex>

      {shouldDisplayScoreInsteadOfPoints ? (
        <Flex
          noFlex
          alignItems='center'
          justifyContent='center'
          className='slot-points'
          css={css`
            width: 40px;
            height: 100%;
            ${font.title}
            font-weight: 700;
            font-size: 24px;
            text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            background-color: ${isTopScore
              ? colors.DEFAULT_FLAIR
              : colors.SOFT_STEEL};
          `}
        >
          {isMockPool || gameDidntEnd ? '-' : score || '0'}
        </Flex>
      ) : (
        <Flex
          noFlex
          alignItems='center'
          justifyContent='center'
          className='slot-points'
          css={css`
            width: 40px;
            height: 100%;
            ${font.title}
            font-weight: 700;
            font-size: 24px;
            text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            background-color: ${isTopScore
              ? colors.DEFAULT_FLAIR
              : colors.SOFT_STEEL};
          `}
        >
          {typeof teamStats?.points === 'string' ? teamStats.points : '-'}
        </Flex>
      )}

      {seed > 0 && (
        <Flex
          noFlex
          alignItems='center'
          justifyContent='center'
          className='slot-seed'
          css={css`
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 16px;
            height: 16px;
            z-index: 1;

            color: ${colors.LINK_WATER};
            background-color: #1a2332;
            font-size: 10px;
            font-weight: 500;
          `}
        >
          {seed}
        </Flex>
      )}
    </Flex>
  )
}

export default Slot
