/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import { getPeriodTypesBySportId } from '@sportninja/common/selectors/types'
import { toISOString, getPeriodFullName } from '@sportninja/common/utils/utils'
import Periods from '@sportninja/common/constants/periodTypes'
import dayjs from 'dayjs'
import { useState } from 'react'
import { connect } from 'react-redux'

import { FormButton, FormRow } from '../../../components/Form/css'
import Icon from '../../../components/Icon'
import PeriodClockTime from '../../../components/PeriodClockTime'
import Sheet from '../../../components/Sheet'

const HOCKEY_PERIOD_DEFAULT_DURATION = 1200000
const SOCCER_PERIOD_DEFAULT_DURATION = 2700000
const SOCCER_EXTRA_TIME_DURATION = 900000

const isSoccerExtraTime = (period) => {
  if (!period || !period?.id) {
    return false
  }
  if (Number(period?.id) > 15) {
    return true
  }
  return false
}

const AddNewPeriod = ({
  gameId,
  periods,
  periodTypes,
  disabled,
  onComplete,
  isSoccer = false,
}) => {
  let nextPeriodIndex = 0

  if (periods.length > 0) {
    const lastPeriod = periods[periods.length - 1]
    const indexOfLastPeriodInPeriodTypes = periodTypes?.findIndex(
      (t) => t.id === lastPeriod.period_type.id
    )
    nextPeriodIndex = indexOfLastPeriodInPeriodTypes + 1
  }

  const nextPeriod = periodTypes[nextPeriodIndex]

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [duration, setDuration] = useState(
    isSoccer
      ? isSoccerExtraTime(nextPeriod)
        ? SOCCER_EXTRA_TIME_DURATION
        : SOCCER_PERIOD_DEFAULT_DURATION
      : HOCKEY_PERIOD_DEFAULT_DURATION
  )

  const onSubmit = async (toggleOpen) => {
    setLoading(true)
    const body = JSON.stringify({
      period_type_id: nextPeriod?.id,
      duration,
      started_at: toISOString(dayjs()),
    })
    try {
      await req(`/games/${gameId}/periods`, { method: 'POST', body })
      await onComplete()
      toggleOpen({ forceClose: true })
    } catch (e) {
      setError(e?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Sheet
      button={(toggleOpen) => (
        <div
          css={css`
            width: 200px;
            min-width: 125px;
            margin-left: 8px;
          `}
        >
          {!nextPeriod?.id ? null : (
            <FormButton
              disabled={disabled || !nextPeriod?.id}
              onClick={toggleOpen}
              css={css`
                height: 40px;
              `}
            >
              {isSoccer
                ? `Add ${getPeriodFullName(Periods[nextPeriod?.id], isSoccer)}`
                : `Add ${nextPeriod?.name} Period`}
              <Icon
                name='plus'
                css={css`
                  margin-left: 12px;
                `}
              />
            </FormButton>
          )}
        </div>
      )}
      title={
        isSoccer
          ? `Add ${getPeriodFullName(Periods[nextPeriod?.id], isSoccer)}`
          : `Add ${nextPeriod?.name} Period`
      }
    >
      {(toggleOpen) => {
        return (
          <>
            {error && (
              <div
                css={css`
                  color: red;
                  margin-bottom: 16px;
                `}
              >
                {error}
              </div>
            )}
            <FormRow noFlex>
              <PeriodClockTime
                noFlex
                label={
                  isSoccer ? 'Half Length (Min/Sec)' : 'Period Length (Min/Sec)'
                }
                required
                isDuration
                defaultValue={duration}
                onChange={({ target }) => {
                  setDuration(target.value)
                }}
              />
            </FormRow>
            <FormRow>
              <FormButton disabled={loading} onClick={toggleOpen}>
                Cancel
              </FormButton>
              <FormButton
                disabled={loading}
                busy={loading}
                isSubmit
                onClick={onSubmit.bind(this, toggleOpen)}
              >
                Submit
              </FormButton>
            </FormRow>
          </>
        )
      }}
    </Sheet>
  )
}

const mapStateToProps = (state, { sportId }) => {
  return {
    periodTypes: getPeriodTypesBySportId(state, sportId),
  }
}

export default connect(mapStateToProps)(AddNewPeriod)
