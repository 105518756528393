/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { connect } from 'react-redux'
import { getSelfUser } from '@sportninja/common/selectors/users'
import { useCallback, useEffect, useRef, useState } from 'react'
import req from '@sportninja/common/api/request'
import { throttle } from 'lodash'
import { t } from '@sportninja/common/i18n'
import uniqBy from 'lodash.uniqby'

import { Flex } from '../../components/Layout'
import LoadingSpinner from '../../components/LoadingSpinner'
import { FormButton } from '../../components/Form/css'
import { media, Mobile } from '../../components/Responsive'
import HomeSidebar from './HomeSidebar'
import HomePost from './HomePost'
import Helmet from '../../components/Helmet'
import ListStatusView from '../../components/List/StatusView'
import EntitySidebar from './EntitySidebar'
import { HomePaymentBanner } from '../RegistrationPayment/HomePaymentBanner'
import { HomeBannerParticipantsLimit } from '../../components/HomeBannerParticipantsLimit'

const Home = ({
  user = {},
  entity = {},
  isEntityFeed,
  entityType,
  entityId,
  refreshKey,
  hideSidebar = false,
  isInFrame,
  isLoggedIn,
}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(true)
  const [feed, setFeed] = useState([])
  const [pagination, setPagination] = useState({})
  const [permission, setPermission] = useState({})
  const [refreshing, setRefreshing] = useState(false)
  const currentPage = useRef(1)

  const slug = isEntityFeed
    ? `/${entityType}/${entityId}/social-feed`
    : '/social-feed'

  const loadFeed = useCallback(
    (page, options) => {
      const { appendMode = false, reset = false } = options || {}
      setError(false)

      if (appendMode) {
        setRefreshing(true)
      } else {
        setLoading(true)
      }

      if (!appendMode || page !== currentPage?.current) {
        currentPage.current = page

        req(slug, { query: { order: 'desc', page, per_page: 10 } })
          .then(({ data: _data, meta }) => {
            setPagination(meta.pagination)
            const data = _data.map((d) => {
              const img = d?.images?.[0]

              return {
                ...d,
                imageHeight: img?.height,
                imageWidth: img?.width,
                imagePath: img?.full_path,
              }
            })
            setFeed((existingFeed) => {
              return reset ? data : uniqBy([...existingFeed, ...data], 'id')
            })
            setPermission((p) => ({ ...p, ...meta.permissions }))
            setLoading(false)
            setRefreshing(false)
          })
          .catch((e) => {
            setLoading(false)
            setRefreshing(false)
            setError(e)
          })
      }
    },
    [slug]
  )

  useEffect(() => {
    const options = {}
    if (refreshKey > 0) {
      options.reset = true
    }
    loadFeed(1, options)
  }, [refreshKey])

  useEffect(() => {
    const handler = throttle(() => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        const nextPage = pagination.current_page + 1

        if (nextPage <= pagination.total_pages) {
          loadFeed(nextPage, { appendMode: true })
        }
      }
    }, 60)

    document.addEventListener('scroll', handler)

    return () => {
      document.removeEventListener('scroll', handler)
    }
  }, [loadFeed, pagination.total_pages, pagination.current_page])

  useEffect(() => {
    localStorage.removeItem('isLightMode')
  }, [])

  const refreshFeed = async () => {
    loadFeed(1, { reset: true })
  }

  return (
    <Flex
      justifyContent='center'
      css={css`
        width: 100%;
        padding-top: 32px;

        ${media.mobile} {
          padding-top: 16px;
        }
      `}
    >
      {!isEntityFeed && (
        <Helmet
          title={t('util:capitalize', {
            text: t('common:home'),
          })}
        />
      )}
      <Mobile>
        {(isMobile) => {
          const width = isMobile ? `calc(100% - 32px)` : 'inherit'

          return (
            <>
              {!hideSidebar && !isMobile ? (
                isEntityFeed ? (
                  <EntitySidebar
                    item={entity}
                    entityType={entityType}
                    loadFeed={loadFeed}
                  />
                ) : (
                  <HomeSidebar
                    user={user}
                    refreshFeed={refreshFeed}
                    isLoggedIn={isLoggedIn}
                  />
                )
              ) : (
                false
              )}
              <div
                css={css`
                  margin: 0 16px 64px;
                  width: ${width};
                  max-width: 800px;

                  ${media.mobile} {
                    margin-left: 0;
                    margin-right: 0;
                  }
                `}
              >
                {error ? (
                  <div
                    css={css`
                      text-align: center;
                      margin-top: 40px;
                    `}
                  >
                    <p>There was a problem loading the feed.</p>
                    <FormButton
                      onClick={loadFeed}
                      type='button'
                      css={css`
                        margin-top: 16px;
                        height: 46px;
                        width: 180px;
                      `}
                    >
                      Reload
                    </FormButton>
                  </div>
                ) : loading ? (
                  <div
                    css={css`
                      margin-top: 40px;
                    `}
                  >
                    <LoadingSpinner />
                  </div>
                ) : feed?.length === 0 ? (
                  <>
                    <HomeBannerParticipantsLimit />
                    <HomePaymentBanner isInFrame={isInFrame} />
                    <ListStatusView
                      zeroItems
                      noItemsText='feed items'
                      css={css`
                        margin-top: 58px;
                      `}
                    />
                  </>
                ) : (
                  <>
                    <HomeBannerParticipantsLimit />
                    <HomePaymentBanner isInFrame={isInFrame} />
                    {feed.map((post) => (
                      <HomePost
                        isInFrame={isInFrame}
                        isMobile={isMobile}
                        key={post.id}
                        post={post}
                        refreshFeed={refreshFeed}
                        entityType={entityType}
                        entityId={entityId}
                        canEdit={permission?.[entityId]?.update}
                        canDelete={permission?.[entityId]?.update}
                      />
                    ))}
                    {refreshing && (
                      <LoadingSpinner
                        css={css`
                          margin-top: 40px;
                        `}
                      />
                    )}
                  </>
                )}
              </div>
            </>
          )
        }}
      </Mobile>
    </Flex>
  )
}

const mapStateToProps = (state) => {
  return {
    user: getSelfUser(state),
    isInFrame: state.auth.inFrame,
    isLoggedIn: state.auth.login.success,
  }
}

export default connect(mapStateToProps)(Home)
