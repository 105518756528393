/** @jsxImportSource @emotion/react */
import { useRef } from 'react'
import css from '@emotion/css/macro'
import { useEffect, useState } from 'react'
import req from '@sportninja/common/api/request'
import { t } from '@sportninja/common/i18n'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import scheduleActions from '@sportninja/common/actions/schedule'
import orgActions from '@sportninja/common/actions/org'

import SearchBox from '../SearchBox'
import FormSheet from '../Form/FormSheet'
import { venueForm } from '../Form/form-configs'
import { connect } from 'react-redux'
// import { FormButton, inputCss, InputWrapper, Label } from '../Form/css'
import { Flex } from '../Layout'
import Icon from '../Icon'
import { NewModal } from '../NewModal'

const VenueFacilityPicker = ({
  createVenue,
  createVenueUnderOrg,
  defaultValues = {},
  onChange,
  ...props
}) => {
  const { facilityId, location = {}, parentId } = defaultValues
  const { id: locationId } = location
  const { organization_id } = props

  const initialLoad = useRef(true)
  const [venues, setVenues] = useState([])
  const [selectedVenue, setSelectedVenue] = useState(locationId || '')
  const [facilities, setFacilities] = useState([])
  const [selectedFacility, setSelectedFacility] = useState(facilityId || '')
  const [locationTimezone, setLocationTimezone] = useState('')

  useEffect(() => {
    setSelectedVenue(locationId)
  }, [locationId])

  useEffect(() => {
    setSelectedFacility(facilityId)
  }, [facilityId])

  useEffect(() => {
    req(
      `/venues${typeof parentId === 'string' && `?schedule_id=${parentId}`}`
    ).then((response) => {
      setVenues(response.data)
      initialLoad.current = false
    })
  }, [parentId])

  useEffect(() => {
    if (selectedVenue && selectedVenue.length > 0) {
      req(`/venues/${selectedVenue}/facilities`).then((response) => {
        setFacilities(response.data)
      })
    } else {
      setFacilities([])
      setSelectedFacility('')
      if (!initialLoad)
        onChange({ target: { name: 'facility_id', value: undefined } })
    }
  }, [selectedVenue])

  useEffect(() => {
    if (selectedVenue) {
      const selectedVenueTimezone = venues.find(
        (v) => v.id === selectedVenue
      )?.timezone

      if (selectedVenueTimezone) {
        onChange({
          target: { name: 'location_timezone', value: selectedVenueTimezone },
        })
      }
    }
  }, [selectedVenue, venues])

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <FormSheet
        hideButton
        isOpen={isOpen}
        form={venueForm({}, parentId, ENTITY_TYPES.schedule)}
        label={t('Web:createLoc')}
        onCancel={() => setIsOpen(false)}
        onSubmit={async (...args) => {
          let response
          if (organization_id) {
            response = await createVenueUnderOrg(organization_id, ...args)
          } else {
            response = await createVenue(parentId, ...args)
          }
          setTimeout(() => {
            req(
              `/venues${
                typeof parentId === 'string' && `?schedule_id=${parentId}`
              }`
            ).then((response) => {
              const value = response.data[response.data.length - 1].id
              setSelectedVenue(value)
              setVenues(response.data)
              onChange({ target: { name: 'venue_id', value } })
              setIsOpen(false)
            })
          }, 300)

          return response
        }}
        title={t('Web:createANewLocation')}
      />
      <SearchBox
        bottomContent={
          <Flex
            className='item'
            alignItems='center'
            justifyContent='space-between'
            onClick={() => {
              setIsOpen(true)
              // locationModalRef.current.openModal()
            }}
            css={css`
              padding: 12px;
              margin-top: 1px;
              height: 38px;
              cursor: pointer;
              border: 1px solid white;
              background-color: rgba(24, 26, 29, 1);
            `}
          >
            Create a new location
            <Icon
              fontSize={20}
              name='map-marker-plus'
              css={css`
                margin-left: 8px;
              `}
            />
          </Flex>
        }
        data={venues}
        defaultValue={selectedVenue}
        label={t('util:capitalize', { text: t('common:location') })}
        name='venue_id'
        onChange={(e) => {
          setSelectedVenue(e?.target?.value)
          onChange(e)
        }}
        placeholder={`${t('Web:selectALocation')}...`}
        required
        {...props}
      />

      <SearchBox
        data={facilities}
        defaultValue={selectedFacility}
        disabled={facilities.length === 0}
        displayString='name'
        label={t('util:capitalize', { text: t('common:facility') })}
        name='facility_id'
        onChange={onChange}
        placeholder={`${t('Web:selectAFacility')}...`}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    createVenue: bindActionToPromise(
      dispatch,
      scheduleActions.venues.create.request
    ),
    createVenueUnderOrg: bindActionToPromise(
      dispatch,
      orgActions.venues.create.request
    ),
  }
}

export default connect(null, mapDispatchToProps)(VenueFacilityPicker)
