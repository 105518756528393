import { produce } from 'immer'
import merge from 'lodash.merge'

import feed from '../actions/feed'
import org from '../actions/org'
import schedule from '../actions/schedule'
import team from '../actions/team'
import auth from '../actions/auth'
import { arrayToObject } from '../utils/utils'

import { str } from './utils'
import { setChildListById, getImageThumbnailId } from './helpers'
import parsers from './parsers'

// Shared reducer meant to be included in other entities, when they manipulate
// their own feed(s)
// i.e. this will modify the state slice for an org, comp, team, etc.
export const getSharedReducer = (actions) => (draft, { type, payload }) => {
  switch (type) { // eslint-disable-line default-case
    case str(actions.create.success):
      draft[payload.id].feed = draft[payload.id].feed || []
      draft[payload.id].feed.push(payload.data.id)
      return

    case str(actions.read.success):
      return setChildListById(draft, payload, 'feed')

    case str(actions.delete.success): {
      const parent = draft[payload.id]

      if (parent && Array.isArray(parent.feed)) {
        const childIndex = parent.feed.findIndex((i) => i === payload.feedItemId)
        parent.feed.splice(childIndex, 1)
      }

      return
    }
  }
}

// Reducer for the feed state slice; represents all the feed posts in the store
const reducer = (draft, { type, payload }) => {
  switch (type) { // eslint-disable-line default-case
    /* ACTIONS FROM OTHER REDUCERS */
    case str(org.feed.create.success):
    case str(schedule.feed.create.success):
    case str(team.feed.create.success):
      draft[payload.data.id] = parsers.feedItem(payload.data)
      return

    case str(org.feed.read.success):
    case str(schedule.feed.read.success):
    case str(team.feed.read.success):
    case str(feed.readAll.success):
      return merge(draft, arrayToObject(payload.data, parsers.feedItem))

    case str(org.feed.update.success):
    case str(schedule.feed.update.success):
    case str(team.feed.update.success):
    case str(feed.read.success):
      draft[payload.id] = merge(draft[payload.id], parsers.feedItem(payload.data))
      return

    case str(org.feed.delete.success):
    case str(schedule.feed.delete.success):
    case str(team.feed.delete.success):
      delete draft[payload.feedItemId]
      return

    case str(auth.logout.success):
      return {}


    case str(org.feed.image.create.success):
    case str(schedule.feed.image.create.success):
    case str(team.feed.image.create.success):
      draft[payload.feedItemId].imagePath = getImageThumbnailId({ image: payload.data })
      draft[payload.feedItemId].imageId = payload.data.id
      return

    case str(org.feed.image.delete.success):
    case str(schedule.feed.image.delete.success):
    case str(team.feed.image.delete.success):
      delete draft[payload.feedItemId].imageId
      delete draft[payload.feedItemId].imagePath
      return
    /* END ACTIONS FROM OTHER REDUCERS */
    case str(feed.like.create.success):
      draft[payload.id].is_liked = true
      return

    case str(feed.like.delete.success):
      draft[payload.id].is_liked = false
      return
  }
}

export default (state = {}, action) => produce(state, (draft) => {
  return reducer(draft, action)
})
