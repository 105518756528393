/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { Fragment, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import req from '@sportninja/common/api/request'
import searchActions from '@sportninja/common/actions/search'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import { t } from '@sportninja/common/i18n'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import colors from '@sportninja/common/constants/appColors'

import { backgroundColor, border, zIndex } from '../../components/css'
import { FollowBtnButton } from '../../components/FollowBtn'
import { Container, Flex } from '../../components/Layout'
import PageWrap from '../../components/PageWrap'
import { SearchMenu } from '../../components/SearchMenu'
import { getSearchTypes } from '../../components/SearchMenu/search-menu-helpers'
import { FormButton } from '../../components/Form/css'
import { media } from '../../components/Responsive'
import TextModal from '../../components/Modal/TextModal'
import BannerController from '../../components/Banner/BannerController'
import Banner from '../../components/Banner'
import FormInput from '../../components/Form/FormInput'
import MergePlayersItem from './MergePlayersItem'
import PlayerCard from './PlayerCard'
import { isCanlan } from '@sportninja/common/utils/customer-name'

const ResultButton = ({ maxPlayersReached, isSelected, onClick, result }) => {
  return (
    <FollowBtnButton
      disabled={!isSelected && maxPlayersReached}
      onClick={onClick.bind(this, result)}
      css={css`
        ${isSelected && `border-color: ${colors.PRIMARY};`}
      `}
    >
      {isSelected ? (
        t('common:selected')
      ) : maxPlayersReached ? (
        <div>
          <div>Max players</div>
          <div>Selected</div>
        </div>
      ) : (
        t('common:select')
      )}
    </FollowBtnButton>
  )
}

const MergePlayers = ({ location, maxPlayers = 3, requestSearch }) => {
  const [focused, setFocused] = useState(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [error, setError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [isDebug, setIsDebug] = useState(false)

  const searchTypes = getSearchTypes()
  const { to, ...playerSearchType } = searchTypes[ENTITY_TYPES.player]
  const [players, setPlayers] = useState({})
  const [selectedPlayerId, setSelectedPlayerId] = useState('')

  const playerKeys = Object.keys(players)
  const playerCount = playerKeys.length
  const maxPlayersReached = playerCount === maxPlayers

  const onRowClick = (id, key, shouldRemove) => {
    setPlayers((players) => {
      const player = players[id]
      const properties = player?.properties || []

      if (shouldRemove) {
        const idx = properties.findIndex((keyName) => keyName === key)
        if (idx > -1) properties.splice(idx, 1)
      } else {
        playerKeys.forEach((id) => {
          if (players[id].properties) {
            const idx = players[id].properties.findIndex(
              (keyName) => keyName === key
            )
            if (idx > -1) {
              players[id].properties.splice(idx, 1)
            }
          }
        })
        properties.push(key)
      }

      return { ...players, [player.id]: { ...player, properties } }
    })
  }

  const handleClick = (result) => {
    const isSelected = Object.prototype.hasOwnProperty.call(players, result.id)
    if (!isSelected && maxPlayersReached) return false
    setPlayers((players) => {
      const copy = { ...players }
      if (isSelected) {
        delete copy[result.id]
        return copy
      }
      return { ...copy, [result.id]: result }
    })
  }

  const onRemove = (id) => {
    setPlayers((players) => {
      const copy = { ...players }
      delete copy[id]
      return copy
    })
  }

  const organizationName = location?.state?.organizationName
  const organizationId = location?.state?.organizationId

  const searchMenu = useMemo(() => {
    const playerSearch = {
      [ENTITY_TYPES.player]: {
        ...playerSearchType,
        slug: 'search/admin/players',
        query: organizationId ? `&organization=${organizationId}` : '',
      },
    }
    return (
      <div
        css={css`
          position: relative;
          width: 400px;
          height: 64px;

          ${media.mobile} {
            width: 320px;
          }
        `}
      >
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: ${focused ? 500 : 0}px;
            z-index: ${zIndex.base};

            .search-menu-results {
              min-height: unset;
              padding: 0;
              height: 0;
              opacity: 0;
              transition: height 0.2s, opacity 0.2s, padding 0.2s ease-in-out;
              background: ${backgroundColor};
            }

            .sn-pagination {
              display: none;
              background: ${backgroundColor};
              border: ${border};
              bottom: 9px;
            }

            &.is-focused {
              .search-menu-results.is-active {
                padding: 20px;
                height: 448px;
                opacity: 1;

                ${media.mobile} {
                  height: 300px;
                }
              }

              .sn-pagination {
                display: flex;
              }
            }

            #merge-players-search {
              border-bottom-left-radius: 2px;
              border-bottom-right-radius: 2px;
            }
          `}
          className={focused ? 'is-focused' : ''}
        >
          <SearchMenu
            autoComplete='off'
            name='merge-players-search'
            onFocus={() => setFocused(true)}
            requestSearch={requestSearch}
            searchTypes={playerSearch}
            noEmptySearch
            refreshOnFocus
            onResultClick={(result) => {
              handleClick(result)
            }}
            ResultButton={({ result, ...props }) => {
              const isSelected = Object.prototype.hasOwnProperty.call(
                players,
                result.id
              )
              return (
                <ResultButton
                  result={result}
                  {...props}
                  onClick={handleClick}
                  maxPlayersReached={maxPlayersReached}
                  isSelected={isSelected}
                />
              )
            }}
          />
        </div>
      </div>
    )
  }, [focused, players, maxPlayersReached, organizationId])

  const onSubmit = async () => {
    setIsConfirmOpen(true)
  }

  const keyNameToAPIKey = {
    nameFirst: 'name_first',
    nameLast: 'name_last',
    nameMiddle: 'name_middle',
    birthDate: 'birth_date',
    height: 'height',
    weight: 'weight',
    email: 'email',
  }

  const onConfirm = async () => {
    const players_merge_from = playerKeys.reduce((collector, id) => {
      const player = players[id]
      const properties = player.properties || []
      const transformedProperties = properties.map((value) => {
        return keyNameToAPIKey[value]
      })
      return {
        ...collector,
        [player.id]: { properties: transformedProperties },
      }
    }, {})

    const body = JSON.stringify({ players_merge_from })
    try {
      setSubmitting(true)
      await req(`/players/${selectedPlayerId}/merge`, {
        method: 'PUT',
        body,
        query: { debug: isDebug ? 1 : 0 },
      })
      setPlayers({})
      setSelectedPlayerId(false)
      setIsConfirmOpen(false)
      setIsDebug(false)
      BannerController.add(({ ...props }) => (
        <Banner {...props}>Players merged successfully.</Banner>
      ))
    } catch (e) {
      setError(e.message)
    } finally {
      setSubmitting(false)
    }
  }

  const selectedPlayer = players[selectedPlayerId]
  const remainingPlayers = playerKeys.filter((pId) => pId !== selectedPlayerId)

  return (
    <Fragment>
      <TextModal
        css={css`
          width: unset;
          max-width: unset;
        `}
        title='Review'
        isOpen={isConfirmOpen}
        toggle={() => {
          if (!submitting) setIsConfirmOpen(!isConfirmOpen)
        }}
      >
        {isCanlan ? (
          <div
            css={css`
              color: red;
              margin: 8px 0 20px;
              font-size: 16px;
              line-height: 24px;
              max-width: 300px;
              text-align: center;
              font-weight: 600;
            `}
          >
            WARNING! Before merging you must ensure the SFID aligns with
            Salesforce / PlayerBench
          </div>
        ) : null}
        <Flex
          justifyContent='center'
          css={css`
            margin-bottom: 40px;
          `}
        >
          {remainingPlayers.length > 0 && (
            <PlayerCard
              player={players[remainingPlayers[0]]}
              rightArrow
              title='Merged Player'
            />
          )}
          {selectedPlayer && (
            <PlayerCard main title='Selected Player' player={selectedPlayer} />
          )}
          {remainingPlayers.length > 1 && (
            <PlayerCard
              player={players[remainingPlayers[1]]}
              leftArrow
              title='Merged Player'
            />
          )}
        </Flex>
        {error && (
          <div
            css={css`
              color: red;
              margin: 8px 0 20px;
            `}
          >
            {error}
          </div>
        )}
        <FormButton
          isSubmit
          onClick={onConfirm}
          disabled={submitting}
          busy={submitting}
        >
          {isDebug ? 'Simulate' : 'Merge'}
        </FormButton>
        <div
          css={css`
            margin-top: 16px;
          `}
        >
          <FormInput
            onChange={() => {
              setIsDebug(!isDebug)
            }}
            disabled={submitting}
            input={{
              name: 'is_debug',
              label: 'Dry Run?',
              type: 'checkbox',
              checked: isDebug,
            }}
          />
        </div>
      </TextModal>
      <div
        css={css`
          display: ${focused ? 'block' : 'none'};
          position: fixed;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          z-index: ${zIndex.base};
        `}
        onClick={() => {
          setFocused(false)
        }}
      />
      <PageWrap
        bottomTitle={organizationName}
        loaded
        title={t('Web:mergePlayers')}
        actions={searchMenu}
      >
        <Container
          css={css`
            margin-top: -20px;
            padding-bottom: 20px;
            .wrap {
              justify-content: center;
            }
          `}
        >
          {playerCount === 0 ? (
            <div
              css={css`
                margin: 40px auto;
              `}
            >
              {t('Web:mergePlayersNoPlayers')}
            </div>
          ) : (
            playerKeys.map((id, idx) => {
              const player = players[id]
              return (
                <MergePlayersItem
                  key={id}
                  idx={idx}
                  isSelectedPlayer={selectedPlayerId === id}
                  player={player}
                  setSelectedPlayer={setSelectedPlayerId}
                  onRemove={onRemove.bind(this, player.id)}
                  onRowClick={onRowClick}
                />
              )
            })
          )}
        </Container>
        <Container>
          <Flex
            justifyContent='center'
            row
            css={css`
              margin-bottom: 40px;
            `}
          >
            <FormButton
              isSubmit
              disabled={!selectedPlayerId || playerCount <= 1}
              css={css`
                width: 200px;
              `}
              onClick={onSubmit}
            >
              Review
            </FormButton>
          </Flex>
        </Container>
      </PageWrap>
    </Fragment>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestSearch: bindActionToPromise(dispatch, searchActions.search.request),
  }
}

export default connect(null, mapDispatchToProps)(MergePlayers)
