import { generateActions } from './utils'

const definition = {
  read: [],
  create: [],
  update: [],
  delete: [],
}

const chatNotificationActions = generateActions(['CHAT'], definition)

export default chatNotificationActions
