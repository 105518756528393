import { produce } from 'immer'
import merge from 'lodash.merge'
import authActions from '../actions/auth'
import userActions from '../actions/user'

import { arrayToObject } from '../utils/utils'
import org from '../actions/org'
import schedule from '../actions/schedule'
import team from '../actions/team'
import usersActions from '../actions/users'
import game from '../actions/game'
import { ROUTES } from '../constants/app'
import { str } from './utils'
import parsers from './parsers'
import { getUserRoles } from './helpers'

export default (state = {}, { type, payload }) =>
  produce(state, (draft) => {
    switch (
      type // eslint-disable-line default-case
    ) {
      case str(authActions.login.success): {
        draft[payload.id] = parsers.user(payload)
        return
      }

      case str(authActions.logout.success):
        return {}

      case str(userActions.read.success): {
        const self = payload.data
        draft[self.id] = merge(draft[self.id], parsers.user(self))

        return
      }

      case str(userActions.readRoles.success):
      case str(usersActions.readRoles.success): {
        const user = draft[payload.id] || {}
        const pagination = payload?.pagination || {}
        user.roles = getUserRoles(payload.data)
        user.rolesPagination = pagination || {}
        return
      }

      case str(userActions.readManagedUsers.success):
        draft = merge(draft, arrayToObject(payload.data, parsers.user))
        return

      case str(game.read.success): {
        const {
          homeTeam = {},
          visitingTeam = {},
          referees = [],
          scorekeepers = [],
        } = payload.data
        if (homeTeam === null || visitingTeam === null) return

        draft = merge(draft, arrayToObject(referees, parsers.user))
        draft = merge(draft, arrayToObject(scorekeepers, parsers.user))
        return
      }

      case str(org.users.read.success):
      case str(schedule.users.read.success):
      case str(team.users.read.success):
        return merge(draft, arrayToObject(payload.data, parsers.user))

      case str(org.users.create.success):
      case str(schedule.users.create.success):
      case str(team.users.create.success):
        draft[payload.data.id] = parsers.user(payload.data)
        return

      case str(usersActions.read.success):
      case str(usersActions.update.success):
        draft[payload.id] = merge(draft[payload.id], parsers.user(payload.data))
        return

      case str(org.users.update.success):
      case str(schedule.users.update.success):
      case str(team.users.update.success): {
        const user = draft[payload.data.id]
        draft[payload.data.id] = merge(user, parsers.user(payload.data))
        return
      }

      case str(org.users.delete.success):
      case str(schedule.users.delete.success):
      case str(team.users.delete.success): {
        const user = draft[payload.userId]
        if (user && user.roles) {
          for (const roleType in user.roles) {
            const roleList = user.roles[roleType]
            const roleIdx = roleList.findIndex((role) => role.id === payload.id)
            if (roleIdx > -1) roleList.splice(roleIdx, 1)
          }
        }

        return
      }

      case str(usersActions.image.create.success):
        draft[payload.id].imageId = payload.data.full_path
        return

      case str(usersActions.image.delete.success):
        draft[payload.id].imageId = undefined
        return
    }
  })
