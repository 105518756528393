/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

import { Flex } from '../Layout'
import { InputWrapper, Label, Right, inputCss } from './css'
import { isCanlan } from '@sportninja/common/utils/customer-name'

// Note that `defaultValue` can be passed directly from the form configuration array
// and is what populates the initial value for an input
// eslint-disable-next-line react/display-name
const FormInput = forwardRef(
  (
    {
      children,
      disabled,
      hasError,
      input = {},
      inputStyles,
      onChange,
      onBlur,
      wrapperClass,
      fixBorder = false,
    },
    ref
  ) => {
    const { defaultValue, noFlex, node = 'input', right, ...inputProps } = input
    const Node = node
    const isCheckbox = inputProps.type === 'checkbox'

    const inputElement = (
      <Node
        ref={ref}
        css={[inputCss({ disabled, hasError, fixBorder, ...inputProps }), inputStyles]}
        disabled={disabled}
        id={input.name}
        onChange={onChange}
        onBlur={onBlur}
        {...inputProps}
        {...(isCheckbox ? { defaultChecked: defaultValue } : { defaultValue })}
      />
    )

    return (
      // An individual input for a given row
      <InputWrapper
        disabled={disabled}
        noFlex={noFlex}
        hasError={hasError}
        readOnly={input.readOnly}
        css={wrapperClass}
        justifyContent={isCheckbox && 'center'}
      >
        {isCheckbox && inputElement}
        {input.label && (
          <Label
            disabled={disabled || input.disabled}
            htmlFor={input.name}
            hasError={hasError}
          >
            {input.label}
            {input.required && ' *'}
          </Label>
        )}
        {!isCheckbox && (
          <Flex
            css={css`
              position: relative;
              border: ${isCanlan ? 2 : 1}px solid
                ${isCanlan
                  ? 'rgba(255, 255, 255, 1)'
                  : 'rgba(255, 255, 255, 0.2)'};
              border-radius: ${isCanlan ? 0 : 2}px;
            `}
          >
            {inputElement}
            {right && <Right>{right}</Right>}
          </Flex>
        )}
        {children}
      </InputWrapper>
    )
  }
)

FormInput.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  input: PropTypes.object.isRequired,
  inputStyles: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.any,
}

export default FormInput
