export const defaultSportIdForHockey = 'rRfa3rzHWt9acZbg'

export const defaultSportsResponse = {
  data: [
    {
      id: 'rRfa3rzHWt9acZbg',
      name: 'hockey',
      name_full: 'Ice Hockey',
    },
  ],
}

export const defaultTypesForHockey = [
  {
    assist_types: [
      {
        name_full: 'First Assist',
        name: 'Assist',
        id: '1',
      },
      {
        name_full: 'Second Assist',
        name: 'Second',
        id: '2',
      },
    ],
  },
  {
    penalty_types: [
      {
        name_full: 'Time Out',
        name: 'Time Out',
        id: '1',
      },
      {
        name_full: 'Penalty Shot',
        name: 'Penalty Shot',
        id: '2',
      },
      {
        name_full: 'Suspension',
        name: 'Suspension',
        id: '3',
      },
    ],
  },
  {
    offense_types: [
      {
        name_full: 'Abuse',
        name: 'Abuse',
        id: '53',
      },
      {
        name_full: 'Abuse of Equipment',
        name: 'Abuse of Equipm',
        id: '48',
      },
      {
        name_full: 'Abuse of Official',
        name: 'Abuse of Official',
        id: '55',
      },
      {
        name_full: 'Attempt to Injure',
        name: 'Attmpt Injr',
        id: '25',
      },
      {
        name_full: 'Bench',
        name: 'Bench',
        id: '56',
      },
      {
        name_full: 'Blocking',
        name: 'Blocking',
        id: '49',
      },
      {
        name_full: 'Boarding',
        name: 'Boarding',
        id: '14',
      },
      {
        name_full: 'Body Checking',
        name: 'Body Chk',
        id: '15',
      },
      {
        name_full: 'Butt-Ending',
        name: 'Butt-Ending',
        id: '17',
      },
      {
        name_full: 'Charging',
        name: 'Charging',
        id: '16',
      },
      {
        name_full: 'Checking from Behind',
        name: 'Chk Behind',
        id: '22',
      },
      {
        name_full: 'Checking to the Head',
        name: 'Chk Head',
        id: '23',
      },
      {
        name_full: 'Cross-Checking',
        name: 'Cross-Chk',
        id: '18',
      },
      {
        name_full: 'Delay of Game',
        name: 'Delay',
        id: '10',
      },
      {
        name_full: 'Discriminatory Slur',
        name: 'Dscrmntry Slr',
        id: '26',
      },
      {
        name_full: 'Elbowing',
        name: 'Elbowing',
        id: '1',
      },
      {
        name_full: 'Eye Gouge',
        name: 'Eye Gouge',
        id: '37',
      },
      {
        name_full: 'Fighting (Off playing Surface)',
        name: 'Fight off Ice',
        id: '41',
      },
      {
        name_full: 'Fighting',
        name: 'Fighting',
        id: '38',
      },
      {
        name_full: 'Game Ejection',
        name: 'Game Ejection',
        id: '42',
      },
      {
        name_full: 'Game Misconduct',
        name: 'Game Misconduct',
        id: '46',
      },
      {
        name_full: 'Goaltender Interference',
        name: 'Goaltender Interference',
        id: '57',
      },
      {
        name_full: 'Grabbing Face Mask',
        name: 'Grab Mask',
        id: '27',
      },
      {
        name_full: 'Gross Misconduct',
        name: 'Gross Misconduct',
        id: '47',
      },
      {
        name_full: 'Hair-pulling',
        name: 'Hair-pull',
        id: '28',
      },
      {
        name_full: 'Head Checking',
        name: 'Head Checking',
        id: '30',
      },
      {
        name_full: 'Head Contact',
        name: 'Head Contact',
        id: '58',
      },
      {
        name_full: 'Head-Butting',
        name: 'Head-Butting',
        id: '31',
      },
      {
        name_full: 'High Sticking - Accidental',
        name: 'High Sticking - Accidental',
        id: '59',
      },
      {
        name_full: 'High-Sticking',
        name: 'High-Stk',
        id: '19',
      },
      {
        name_full: 'Holding',
        name: 'Holding',
        id: '3',
      },
      {
        name_full: 'Holding the Stick',
        name: 'Holding Stk',
        id: '4',
      },
      {
        name_full: 'Hooking',
        name: 'Hooking',
        id: '5',
      },
      {
        name_full: 'Harassment of Official',
        name: 'Hrssmnt of Offcl',
        id: '29',
      },
      {
        name_full: 'Illegal Equipment',
        name: 'Illegal Eqpmnt',
        id: '32',
      },
      {
        name_full: 'Illegal Stick',
        name: 'Illegal Stick',
        id: '33',
      },
      {
        name_full: 'Illegal Team Official',
        name: 'Illegal Team Official',
        id: '54',
      },
      {
        name_full: 'Instigator',
        name: 'Instigator',
        id: '61',
      },
      {
        name_full: 'Interference',
        name: 'Interference',
        id: '6',
      },
      {
        name_full: 'Kicking Player',
        name: 'Kicking Player',
        id: '34',
      },
      {
        name_full: 'Kneeing',
        name: 'Kneeing',
        id: '2',
      },
      {
        name_full: 'Leaving the Bench',
        name: 'Leave Bench',
        id: '35',
      },
      {
        name_full: 'Lifting the Stick',
        name: 'Lifting the Stick',
        id: '50',
      },
      {
        name_full: 'Match',
        name: 'Match',
        id: '43',
      },
      {
        name_full: 'Misconduct',
        name: 'Misconduct',
        id: '45',
      },
      {
        name_full: 'Over Center Interference',
        name: 'Over Center Inter',
        id: '51',
      },
      {
        name_full: 'Referee Abuse',
        name: 'Referee Abuse',
        id: '44',
      },
      {
        name_full: 'Roughing after the Whistle',
        name: 'Rough aft Whst',
        id: '13',
      },
      {
        name_full: 'Roughing (head contact)',
        name: 'Rough Head',
        id: '39',
      },
      {
        name_full: 'Roughing',
        name: 'Roughing',
        id: '7',
      },
      {
        name_full: 'Slap shot/Fake Slap Shot',
        name: 'Slap Shot',
        id: '60',
      },
      {
        name_full: 'Slashing',
        name: 'Slashing',
        id: '20',
      },
      {
        name_full: 'Slew-Footing',
        name: 'Slew',
        id: '24',
      },
      {
        name_full: 'Sliding',
        name: 'Sliding',
        id: '52',
      },
      {
        name_full: 'Spearing',
        name: 'Spearing',
        id: '21',
      },
      {
        name_full: 'Spitting',
        name: 'Spitting',
        id: '36',
      },
      {
        name_full: 'Third Man In',
        name: 'Third Man In',
        id: '12',
      },
      {
        name_full: 'Too Many Men',
        name: 'Too Many Men',
        id: '11',
      },
      {
        name_full: 'Tripping',
        name: 'Tripping',
        id: '8',
      },
      {
        name_full: 'Unsportsmanlike Conduct',
        name: 'Unsport',
        id: '9',
      },
      {
        name_full: 'Unsportsmanlike Conduct (Unnecessary Involvement)',
        name: 'Unsport Involv',
        id: '40',
      },
    ],
  },
  {
    player_types: [
      {
        name: 'GOAL',
        is_goalie: 1,
        deleted_at: null,
        id: 1,
        sport_id: 1,
        name_full: 'Goalie',
        is_default: false,
        abbreviation: 'G',
      },
      {
        name: 'DEF',
        is_goalie: 0,
        deleted_at: null,
        id: 2,
        sport_id: 1,
        name_full: 'Defense',
        is_default: false,
        abbreviation: 'D',
      },
      {
        name: 'FWD',
        is_goalie: 0,
        deleted_at: null,
        id: 3,
        sport_id: 1,
        name_full: 'Forward',
        is_default: false,
        abbreviation: 'F',
      },
      {
        name: 'SKT',
        is_goalie: 0,
        deleted_at: null,
        id: 4,
        sport_id: 1,
        name_full: 'Skater',
        is_default: true,
        abbreviation: 'D/F',
      },
    ],
  },
  {
    official_types: [
      {
        name: 'Referee',
        is_team_official: false,
        id: 1,
        sport_id: 1,
        name_full: 'Referee',
        is_default: 0,
        abbreviation: 'REF',
      },
      {
        name: 'Scorekeeper',
        is_team_official: false,
        id: 2,
        sport_id: 1,
        name_full: 'Scorekeeper',
        is_default: 0,
        abbreviation: 'SKO ',
      },
      {
        name: 'Timekeeper',
        is_team_official: false,
        id: 3,
        sport_id: 1,
        name_full: 'Timekeeper',
        is_default: 0,
        abbreviation: 'TC',
      },
    ],
  },
  {
    team_official_types: [
      {
        name: 'Manager',
        name_full: 'Manager',
        is_default: 0,
        sport_id: 1,
        id: 1,
        abbreviation: 'MAN',
      },
      {
        name: 'Trainer',
        name_full: 'Trainer',
        is_default: 0,
        sport_id: 1,
        id: 2,
        abbreviation: 'TRA',
      },
      {
        name: 'Team Rep',
        name_full: 'Team Rep',
        is_default: 0,
        sport_id: 1,
        id: 3,
        abbreviation: 'TREP',
      },
      {
        name: 'Coach',
        name_full: 'Coach',
        is_default: 0,
        sport_id: 1,
        id: 4,
        abbreviation: 'COA',
      },
    ],
  },
  {
    period_types: [
      {
        name_full: '1st Period',
        name: '1st',
        id: '1',
      },
      {
        name_full: '2nd Period',
        name: '2nd',
        id: '2',
      },
      {
        name_full: '3rd Period',
        name: '3rd',
        id: '3',
      },
      {
        name_full: 'Overtime 1',
        name: 'OT1',
        id: '4',
      },
      {
        name_full: 'Overtime 2',
        name: 'OT2',
        id: '5',
      },
      {
        name_full: 'Overtime 3',
        name: 'OT3',
        id: '6',
      },
      {
        name_full: 'Overtime 4',
        name: 'OT4',
        id: '7',
      },
      {
        name_full: 'Overtime 5',
        name: 'OT5',
        id: '8',
      },
      {
        name_full: 'Overtime 6',
        name: 'OT6',
        id: '9',
      },
      {
        name_full: 'Overtime 7',
        name: 'OT7',
        id: '10',
      },
      {
        name_full: 'Overtime 8',
        name: 'OT8',
        id: '11',
      },
      {
        name_full: 'Overtime 9',
        name: 'OT9',
        id: '12',
      },
      {
        name_full: 'Penalty Shots',
        name: 'PS',
        id: '13',
      },
    ],
  },
  {
    game_type: [
      {
        name_full: 'EXHIBITION',
        name: 'Exhibition',
        id: '1',
      },
      {
        name_full: 'REGULAR_SEASON',
        name: 'Regular Season',
        id: '2',
      },
      {
        name_full: 'TOURNAMENT',
        name: 'Tournament',
        id: '3',
      },
      {
        name_full: 'PLAYOFF',
        name: 'Playoff',
        id: '4',
      },
      {
        name_full: 'PRELIMINARY',
        name: 'Preliminary',
        id: '5',
      },
      {
        name_full: 'ROUND_ROBIN',
        name: 'Round Robin',
        id: '6',
      },
      {
        name_full: 'QUARTERFINAL',
        name: 'Quarterfinal',
        id: '7',
      },
      {
        name_full: 'SEMIFINAL',
        name: 'Semifinal',
        id: '8',
      },
      {
        name_full: 'FINAL',
        name: 'Final',
        id: '9',
      },
      {
        name_full: 'CONSOLATION_FINAL',
        name: 'Consolation Final',
        id: '10',
      },
    ],
  },
  {
    goal_type: [
      {
        name: 'Even Strength',
        name_full: '',
        id: '1',
        abbreviation: 'ES',
      },
      {
        name: 'Power Play',
        name_full: '',
        id: '2',
        abbreviation: 'PP',
      },
      {
        name: 'Shorthanded',
        name_full: '',
        id: '3',
        abbreviation: 'SH',
      },
      {
        name: 'Empty Net',
        name_full: '',
        id: '4',
        abbreviation: 'EN',
      },
      {
        name: 'Penalty Shot',
        name_full: '',
        id: '5',
        abbreviation: 'PS',
      },
    ],
  },
]
