/** @jsxImportSource @emotion/react */
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import css from '@emotion/css/macro'
import {
  getGameTimeline,
  ACTIONS,
} from '@sportninja/common/reducers/game-timeline-parser'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import actions from '@sportninja/common/actions/scoring'
import { t } from '@sportninja/common/i18n'
import { getGoalTypesBySportId } from '@sportninja/common/selectors/types'
import req from '@sportninja/common/api/request'
import * as Sentry from '@sentry/react'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'

import { Flex, Container, Column } from '../../../components/Layout'
import { Desktop, Mobile } from '../../../components/Responsive'
import {
  scoringGoalieChangeForm,
  scoringGoalForm,
  scoringPenaltyForm,
} from '../../../components/Form/form-configs'
import TimelinePeriod from './TimelinePeriod'
import TimelineEvent from './TimelineEvent'
import TimelineShots from './TimelineShots'
import MobileTimeline from './MobileTimeline'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { FormButton } from '../../../components/Form/css'
import Icon from '../../../components/Icon'
import AddNewTimelineEvent from './AddNewTimelineEvent'
import AddNewPeriod from './AddNewPeriod'
import RosterEditing from '../RosterEditing'
import TimelineGoals from './TimelineGoals'
import TimelineFouls from './TimelineFouls'

export const getPlayingRoster = (roster) => {
  // Only players which are "playing" can be shown in this screen
  let availableRoster = [],
    fullRoster = roster ? roster : {}
  for (const id in fullRoster) {
    const player = fullRoster[id]
    if (player.is_playing) {
      availableRoster[id] = player
    }
  }

  return availableRoster
}

const playerParser = (teamId, collector, player) => {
  const nameFirst = player.name_first
  const nameLast = player.name_last

  const info = {
    id: player.id,
    fullName: `${nameFirst} ${nameLast}`,
    nameFirst,
    nameLast,
    imageId: getImageThumbnailId(player),

    /*
     * Simplifies prop checking in React components if this is a string value
     * In particular, if this is 0 and we have a check like prop && <div>prop</div>
     * it will resolve to just 0 instead.
     */
    number: `${player.player_number}`,
    position: player?.player_type?.name_full,
    playerTypeId: player?.player_type?.id,
    positionAbbrev: player?.player_type?.abbreviation,

    is_playing: player?.is_playing,
    teamId,
  }
  collector[info.id] = info
  return collector
}

const Timeline = ({
  sportId,
  gameId,
  setDisableTabNavigation,
  isSoccer = false,
  // Redux
  updateGoal,
  deleteGoal,
  updateGoalieChange,
  deleteGoalieChange,
  updatePenalty,
  deletePenalty,
  updatePeriod,
  inFrame,
  isPublicRoute,
  goalTypes,
  isGenericGame = false,
}) => {
  const [isLoadingGame, setIsLoadingGame] = useState(true)
  const [isRefreshing, setIsRefreshing] = useState(false)

  const [game, setGame] = useState({})
  const [homeGameRoster, setHomeGameRoster] = useState({})
  const [homeGameRosterId, setHomeGameRosterId] = useState('')
  const [visitingGameRoster, setVisitingGameRoster] = useState({})
  const [visitingGameRosterId, setVisitingGameRosterId] = useState('')
  const [hasPermission, setHasPermission] = useState(false)

  const interval = useRef(null)

  const {
    homeTeam,
    visitingTeam,
    started_at,
    ended_at,
    stats_rolledback: areStatsRolledBack,

    periods,
    shots,

    // Specifically for adding new timeline events:
    league_game_number,

    goals,
    offenses,
    goalieChanges,
  } = game

  const isEditingAllowed = !inFrame && started_at && ended_at && hasPermission
  const isEditMode = isEditingAllowed && !isRefreshing && areStatsRolledBack

  useEffect(() => {
    const unloadListener = (event) => {
      if (isEditingAllowed && areStatsRolledBack) {
        event.preventDefault()
        event.returnValue =
          'Please do not leave the page while editing the game timeline.'
      }
    }
    window.addEventListener('beforeunload', unloadListener)

    if (isEditingAllowed && areStatsRolledBack) {
      setDisableTabNavigation(true)
    } else {
      setDisableTabNavigation(false)
    }

    return () => {
      window.removeEventListener('beforeunload', unloadListener)
      setDisableTabNavigation(false)
    }
  }, [isEditingAllowed, areStatsRolledBack])

  const refreshGameObject = useCallback(async () => {
    setIsRefreshing(true)

    try {
      const { data: newGame } = await req(`/games/${gameId}/timeline`)
      setGame((g) => ({ ...g, ...newGame }))
    } finally {
      setIsRefreshing(false)
    }
  }, [gameId])

  const read = useCallback(async () => {
    setIsLoadingGame(true)

    try {
      const { data: g, meta } = await req(
        `${isPublicRoute ? '/public' : ''}/games/${gameId}`
      )
      setGame(g)
      setHasPermission(
        meta?.permissions?.[gameId]?.update ||
          meta?.permissions?.[gameId]?.admin
      )

      const homeTeamRosterId = g.playerRosters.find(
        (r) => r.team_id === g.homeTeam.id
      )?.id
      const { data: homeRoster } = await req(
        `${
          isPublicRoute ? '/public' : ''
        }/games/${gameId}/rosters/${homeTeamRosterId}/players`
      )
      setHomeGameRosterId(homeRoster.id)
      setHomeGameRoster(
        homeRoster.players.reduce(playerParser.bind(this, g.homeTeam.id), {})
      )

      const visitingTeamRosterId = g.playerRosters.find(
        (r) => r.team_id === g.visitingTeam.id
      )?.id
      const { data: visitingRoster } = await req(
        `${
          isPublicRoute ? '/public' : ''
        }/games/${gameId}/rosters/${visitingTeamRosterId}/players`
      )
      setVisitingGameRosterId(visitingRoster.id)
      setVisitingGameRoster(
        visitingRoster.players.reduce(
          playerParser.bind(this, g.visitingTeam.id),
          {}
        )
      )
    } finally {
      setIsLoadingGame(false)
    }
  }, [isPublicRoute, gameId])

  useEffect(() => {
    if (started_at && !ended_at && !interval.current) {
      interval.current = setInterval(refreshGameObject, 10000)
    }

    return () => {
      clearInterval(interval.current)
    }
  }, [started_at, ended_at, refreshGameObject])

  useEffect(() => {
    read()
  }, [read])

  if (isLoadingGame) {
    return (
      <LoadingSpinner
        css={css`
          min-height: 100vh;
        `}
      />
    )
  }

  const onEditTimeline = async () => {
    try {
      setIsRefreshing(true)

      await req(
        `/games/${gameId}/${areStatsRolledBack ? 'process' : 'rollback'}`,
        {
          method: 'PUT',
        }
      )
    } catch (e) {
      const customError = new Error(e.message)
      Sentry.captureException(customError)
    }

    try {
      await refreshGameObject()
    } finally {
      setIsRefreshing(false)
    }
  }

  const editPeriodDuration = async (periodId, form) => {
    await updatePeriod(gameId, periodId, form)
    await refreshGameObject()
  }

  const getSheetInfo = (event) => {
    const roster = getPlayingRoster(
      event.teamId === homeTeam.id ? homeGameRoster : visitingGameRoster
    )

    switch (event.action) {
      case ACTIONS.goalieChange:
        return {
          form: scoringGoalieChangeForm(
            { roster, ...event },
            {},
            isSoccer,
            sportId
          ),
          onDelete: async () => {
            await deleteGoalieChange(gameId, event.id)
            await refreshGameObject()
          },
          onSubmit: async (form) => {
            await updateGoalieChange(gameId, event.id, form)
            await refreshGameObject()
          },
          title: isSoccer ? 'Edit Substitution' : t('Web:editGoalieChange'),
        }

      case 'Goal':
        return {
          form: scoringGoalForm({ roster, ...event }, {}, isSoccer, sportId),
          onDelete: async () => {
            await deleteGoal(gameId, event.id)
            await refreshGameObject()
          },
          onSubmit: async (form) => {
            await updateGoal(gameId, event.id, form)
            await refreshGameObject()
          },
          title: t('Web:editGoal'),
        }

      case 'Penalty':
        return {
          form: scoringPenaltyForm({ roster, ...event }, {}, isSoccer, sportId),
          onDelete: async () => {
            await deletePenalty(gameId, event.teamId, event.id)
            await refreshGameObject()
          },
          onSubmit: async (form) => {
            await updatePenalty(gameId, event.id, {
              amount: event.duration,
              served_by_player_id: event.serverId,
              player_id: event.playerId,
              team_id: event.teamId,
              ...form,
            })
            await refreshGameObject()
          },
          title: isSoccer ? 'Edit Foul' : t('Web:editPenalty'),
        }

      default:
        return { form: {} }
    }
  }

  const onSubmitShots = async (periodId, teamId, { amount }) => {
    await req(`/games/${gameId}/periods/${periodId}/shots/bulk`, {
      method: 'PUT',
      body: JSON.stringify({ shots: amount, team_id: teamId }),
    })
    await refreshGameObject()
  }

  const onSubmitGoals = async (periodId, teamId, { amount }) => {
    await req(`/games/${gameId}/periods/${periodId}/goals/bulk`, {
      method: 'PUT',
      body: JSON.stringify({ goals: amount, team_id: teamId }),
    })
    await refreshGameObject()
  }

  const onSubmitFouls = async (periodId, teamId, { amount }) => {
    await req(`/games/${gameId}/periods/${periodId}/fouls/bulk`, {
      method: 'PUT',
      body: JSON.stringify({ fouls: amount, team_id: teamId }),
    })
    await refreshGameObject()
  }

  const onDeletePeriod = async (period_id) => {
    await req(`/games/${gameId}/periods/${period_id}`, {
      method: 'DELETE',
    })
    await refreshGameObject()
  }

  const timeline = getGameTimeline(
    game,
    homeGameRoster,
    visitingGameRoster,
    isSoccer
  )

  console.log({ timeline })

  if (isSoccer) {
    timeline.reverse()
    timeline.forEach((period) => {
      period.events.reverse()
    })
  }

  let homeScore = 0,
    visitingScore = 0,
    homeTotalShots = 0,
    visitingTotalShots = 0

  shots?.forEach((shot) => {
    if (shot.team_id === visitingTeam.id) {
      visitingTotalShots++
    } else if (shot.team_id === homeTeam.id) {
      homeTotalShots++
    }
  })

  return (
    <Mobile>
      {(isMobile) =>
        started_at ? (
          isMobile ? (
            <MobileTimeline
              finalShots={{
                home: homeTotalShots,
                visiting: visitingTotalShots,
              }}
              getSheetInfo={getSheetInfo}
              images={{
                home: homeTeam.imageId,
                visiting: visitingTeam.imageId,
              }}
              isEditMode={isEditMode}
              onEditPeriodDuration={editPeriodDuration}
              onDeletePeriod={onDeletePeriod}
              timeline={timeline}
              visitingId={visitingTeam.id}
              goalTypes={goalTypes}
              isSoccer={isSoccer}
              isGenericGame={isGenericGame}
            />
          ) : (
            <Container
              as='main'
              noWrap
              column
              css={css`
                margin-bottom: 44px;
                min-height: 100vh;
              `}
              className='timeline-container'
            >
              {isEditingAllowed && (
                <Column fullWidth>
                  <Flex
                    alignItems='center'
                    justifyContent='center'
                    css={css`
                      margin-bottom: 32px;
                    `}
                  >
                    <div
                      css={css`
                        flex: 1;
                        text-align: right;
                        margin-right: 16px;
                      `}
                    >
                      {areStatsRolledBack ? (
                        <div>
                          When you are done, you must press the &apos;Save
                          Timeline&apos; button to save your changes!
                          <p
                            css={css`
                              margin-top: 4px;
                            `}
                          >
                            Leaving the page will result in data loss.
                          </p>
                        </div>
                      ) : (
                        <p>
                          To begin editing the game, press the &apos;Edit
                          Timeline&apos; button.
                        </p>
                      )}
                    </div>
                    <Flex
                      css={css`
                        min-width: 360px;
                      `}
                    >
                      <div
                        css={css`
                          width: 170px;
                          min-width: 150px;
                          margin-right: 8px;
                        `}
                      >
                        <FormButton
                          disabled={isRefreshing}
                          busy={isRefreshing}
                          isSubmit
                          onClick={onEditTimeline}
                          css={css`
                            height: 40px;
                          `}
                        >
                          {!isRefreshing && (
                            <Icon
                              name={areStatsRolledBack ? 'save' : 'edit'}
                              css={css`
                                margin-right: 9px;
                              `}
                            />
                          )}
                          {areStatsRolledBack ? 'Save' : 'Edit'} Timeline
                        </FormButton>
                      </div>
                      {!areStatsRolledBack && isEditingAllowed && (
                        <Desktop>
                          <RosterEditing
                            gameId={gameId}
                            homeGameRosterId={homeGameRosterId}
                            visitingGameRosterId={visitingGameRosterId}
                            onExitRosterEditing={read}
                            goals={goals}
                            offenses={offenses}
                            goalieChanges={goalieChanges}
                            button={(onOpen) => (
                              <div
                                css={css`
                                  width: 170px;
                                  min-width: 150px;
                                  margin-right: 8px;
                                `}
                              >
                                <FormButton
                                  disabled={isRefreshing}
                                  isSubmit
                                  onClick={onOpen}
                                  css={css`
                                    height: 40px;
                                  `}
                                >
                                  <Icon
                                    name='user-edit'
                                    css={css`
                                      margin-right: 9px;
                                    `}
                                  />
                                  Edit Roster
                                </FormButton>
                              </div>
                            )}
                            isSoccer={isSoccer}
                            sportId={sportId}
                          />
                        </Desktop>
                      )}
                      {areStatsRolledBack && !isGenericGame && (
                        <AddNewTimelineEvent
                          key='add-event'
                          gameId={gameId}
                          periods={periods}
                          homeTeam={homeTeam}
                          visitingTeam={visitingTeam}
                          homeGameRoster={homeGameRoster}
                          visitingGameRoster={visitingGameRoster}
                          disabled={isRefreshing}
                          league_game_number={league_game_number}
                          started_at={started_at}
                          ended_at={ended_at}
                          onComplete={refreshGameObject}
                          isSoccer={isSoccer}
                          sportId={sportId}
                        />
                      )}
                      {areStatsRolledBack && (
                        <AddNewPeriod
                          key='add-period'
                          gameId={gameId}
                          periods={periods}
                          disabled={isRefreshing}
                          onComplete={refreshGameObject}
                          isSoccer={isSoccer}
                          sportId={sportId}
                        />
                      )}
                    </Flex>
                  </Flex>
                </Column>
              )}

              {timeline.map((period, idx) => (
                <Fragment key={period.id}>
                  <TimelinePeriod
                    onEdit={editPeriodDuration.bind(this, period.id)}
                    isEditMode={isEditMode}
                    onDelete={onDeletePeriod.bind(this, period.id)}
                    isFirst={idx === 0}
                    isLast={idx === timeline.length - 1}
                    periodName={period.period_type.name_full}
                    periodLength={period.duration}
                  />
                  {!isGenericGame &&
                    period.events.map((event) => {
                      const isVisitingTeam = event.teamId === visitingTeam.id
                      const isGoal = event.action === ACTIONS.goal

                      if (isGoal) {
                        if (isVisitingTeam) {
                          visitingScore++
                        } else {
                          homeScore++
                        }
                      }

                      const { form, onDelete, onSubmit, title } =
                        getSheetInfo(event)
                      return (
                        <TimelineEvent
                          key={event.id}
                          editForm={form}
                          onDelete={onDelete}
                          onEdit={onSubmit}
                          editTitle={title}
                          event={event}
                          localHomeScore={homeScore}
                          localVisitingScore={visitingScore}
                          isEditMode={isEditMode}
                          right={isVisitingTeam}
                          goalTypes={goalTypes}
                          teamImageId={
                            isVisitingTeam
                              ? getImageThumbnailId(visitingTeam)
                              : getImageThumbnailId(homeTeam)
                          }
                          isSoccer={isSoccer}
                        />
                      )
                    })}
                  {isGenericGame ? (
                    <>
                      <TimelineGoals
                        isEditMode={isEditMode}
                        homeGoals={period.goals_home_count}
                        visitingGoals={period.goals_visiting_count}
                        homeShots={period.goals_home_count}
                        visitingShots={period.goals_visiting_count}
                        onSubmitHome={onSubmitGoals.bind(
                          this,
                          period.id,
                          homeTeam.id
                        )}
                        onSubmitVisiting={onSubmitGoals.bind(
                          this,
                          period.id,
                          visitingTeam.id
                        )}
                        css={css`
                          margin-bottom: 46px;
                        `}
                        periodName={period.period_type.name_full}
                      />
                      <TimelineFouls
                        isEditMode={isEditMode}
                        homeGoals={period.homeOffenseCount}
                        visitingGoals={period.visitingOffenseCount}
                        homeShots={period.homeOffenseCount}
                        visitingShots={period.visitingOffenseCount}
                        onSubmitHome={onSubmitFouls.bind(
                          this,
                          period.id,
                          homeTeam.id
                        )}
                        onSubmitVisiting={onSubmitFouls.bind(
                          this,
                          period.id,
                          visitingTeam.id
                        )}
                        css={css`
                          margin-bottom: 46px;
                        `}
                        periodName={period.period_type.name_full}
                      />
                    </>
                  ) : null}
                  <TimelineShots
                    isEditMode={isEditMode}
                    homeGoals={period.goals_home_count}
                    visitingGoals={period.goals_visiting_count}
                    homeShots={period.homeShotCount}
                    visitingShots={period.visitingShotCount}
                    onSubmitHome={onSubmitShots.bind(
                      this,
                      period.id,
                      homeTeam.id
                    )}
                    onSubmitVisiting={onSubmitShots.bind(
                      this,
                      period.id,
                      visitingTeam.id
                    )}
                    css={css`
                      margin-bottom: 46px;
                    `}
                    periodName={period.period_type.name_full}
                  />
                </Fragment>
              ))}
              {ended_at && (
                <TimelineShots
                  isFinal
                  isEditMode={isEditMode}
                  homeShots={homeTotalShots}
                  visitingShots={visitingTotalShots}
                />
              )}
            </Container>
          )
        ) : (
          <Flex justifyContent='center'>{t('Web:theGameHasntStarted')}</Flex>
        )
      }
    </Mobile>
  )
}

const mapStateToProps = (state, { sportId }) => {
  return {
    inFrame: state.auth.inFrame,
    goalTypes: getGoalTypesBySportId(state, sportId),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateGoal: bindActionToPromise(dispatch, actions.goals.update.request),
    deleteGoal: bindActionToPromise(dispatch, actions.goals.delete.request),
    updateGoalieChange: bindActionToPromise(
      dispatch,
      actions.goalieChanges.update.request
    ),
    deleteGoalieChange: bindActionToPromise(
      dispatch,
      actions.goalieChanges.delete.request
    ),
    updatePenalty: bindActionToPromise(
      dispatch,
      actions.penalties.update.request
    ),
    deletePenalty: bindActionToPromise(
      dispatch,
      actions.penalties.delete.request
    ),
    updatePeriod: bindActionToPromise(dispatch, actions.periods.update.request),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Timeline)
