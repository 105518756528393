/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import ReprocessingOverlay from '../../../pages/Schedule/ReprocessingOverlay'
import { Flex } from '../../Layout'
import List from '../../List'
import GameListFilters from '../../List/GameList/GameListFilters'
import useGetScheduleFiltering from '../../List/useGetScheduleFiltering'
import Picture from '../../Picture'
import { media } from '../../Responsive'
import { Avatar, Table, Typography, Collapse, Button, Spin, Empty } from 'antd'
import Icon from '../../Icon'

const { Panel } = Collapse

const commonColumns = [
  {
    title: 'TEAM',
    dataIndex: 'team',
    render: (item) => {
      return (
        <Link
          to={`/team/${item?.team?.id}?schedule_id=${item?.schedule?.id}`}
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          <Avatar
            src={
              item?.team?.image?.full_path ||
              '/images/placeholders/hockey/team.png'
            }
            shape='square'
            icon={<Icon name='user-friends' fontSize={14} />}
            css={css`
              border: 1px solid #dbdbdb;
              background-color: white;
              padding: 2px;
            `}
          />
          <Typography.Text strong>{item?.team?.name_full}</Typography.Text>
        </Link>
      )
    },
    key: 'team',
    fixed: 'left',
    width: 300,
  },
]

const hockeyColumns = [
  {
    key: 'GP',
    sorter: (a, b) => a.GP - b.GP,
    title: 'GP',
    dataIndex: 'GP',
    showSorterTooltip: {
      title: 'Games Played',
    },
  },
  {
    key: 'W',
    sorter: (a, b) => a.W - b.W,
    title: 'W',
    dataIndex: 'W',
    showSorterTooltip: {
      title: 'Wins',
    },
  },
  {
    key: 'L',
    sorter: (a, b) => a.L - b.L,
    title: 'L',
    dataIndex: 'L',
    showSorterTooltip: {
      title: 'Losses',
    },
  },
  {
    key: 'T',
    sorter: (a, b) => a.T - b.T,
    title: 'T',
    dataIndex: 'T',
    showSorterTooltip: {
      title: 'Ties',
    },
  },
  {
    key: 'OTL',
    sorter: (a, b) => a.OTL - b.OTL,
    title: 'OTL',
    dataIndex: 'OTL',
    showSorterTooltip: {
      title: 'Overtime Losses',
    },
  },
  {
    key: 'SOL',
    sorter: (a, b) => a.SOL - b.SOL,
    title: 'SOL',
    dataIndex: 'SOL',
    showSorterTooltip: {
      title: 'Shootout Losses',
    },
  },
  {
    key: 'PTS',
    sorter: (a, b) => a.PTS - b.PTS,
    title: 'PTS',
    dataIndex: 'PTS',
    showSorterTooltip: {
      title: 'Points',
    },
  },
  {
    key: 'WPCT',
    sorter: (a, b) => a.WPCT - b.WPCT,
    title: 'WPCT',
    dataIndex: 'WPCT',
    showSorterTooltip: {
      title: 'Winning Percentage',
    },
  },
  {
    key: 'WPCTD',
    sorter: (a, b) => a.WPCTD - b.WPCTD,
    title: 'WPCTD',
    dataIndex: 'WPCTD',
    defaultSortOrder: 'descend',
    showSorterTooltip: {
      title: 'In-Division Winning Percentage',
    },
  },
  {
    key: 'GF',
    sorter: (a, b) => a.GF - b.GF,
    title: 'GF',
    dataIndex: 'GF',
    showSorterTooltip: {
      title: 'Goals For',
    },
  },
  {
    key: 'GA',
    sorter: (a, b) => a.GA - b.GA,
    title: 'GA',
    dataIndex: 'GA',
    showSorterTooltip: {
      title: 'Goals Against',
    },
  },
  {
    key: 'GPCT',
    sorter: (a, b) => a.GPCT - b.GPCT,
    title: 'GPCT',
    dataIndex: 'GPCT',
    showSorterTooltip: {
      title: 'Game Percentage',
    },
  },
  {
    key: 'PIM',
    sorter: (a, b) => a.PIM - b.PIM,
    title: 'PIM',
    dataIndex: 'PIM',
    showSorterTooltip: {
      title: 'Penalty Minutes',
    },
  },
  {
    key: 'OTW',
    sorter: (a, b) => a.OTW - b.OTW,
    title: 'OTW',
    dataIndex: 'OTW',
    showSorterTooltip: {
      title: 'Overtime Wins',
    },
  },
]

const soccerColumns = [
  {
    key: 'MP',
    sorter: (a, b) => a.MP - b.MP,
    title: 'MP',
    dataIndex: 'MP',
    showSorterTooltip: {
      title: 'Matches Played',
    },
  },
  {
    key: 'W',
    sorter: (a, b) => a.W - b.W,
    title: 'W',
    dataIndex: 'W',
    showSorterTooltip: {
      title: 'Wins',
    },
  },
  {
    key: 'L',
    sorter: (a, b) => a.L - b.L,
    title: 'L',
    dataIndex: 'L',
    showSorterTooltip: {
      title: 'Losses',
    },
  },
  {
    key: 'D',
    sorter: (a, b) => a.D - b.D,
    title: 'D',
    dataIndex: 'D',
    showSorterTooltip: {
      title: 'Draws',
    },
  },
  {
    key: 'GF',
    sorter: (a, b) => a.GF - b.GF,
    title: 'GF',
    dataIndex: 'GF',
    showSorterTooltip: {
      title: 'Goals For',
    },
  },
  {
    key: 'GA',
    sorter: (a, b) => a.GC - b.GC,
    title: 'GA',
    dataIndex: 'GA',
    showSorterTooltip: {
      title: 'Goals Against',
    },
  },
  {
    key: 'GD',
    sorter: (a, b) => a.GC - b.GC,
    title: 'GD',
    dataIndex: 'GD',
    showSorterTooltip: {
      title: 'Goals Difference',
    },
  },
  {
    key: 'W%',
    sorter: (a, b) => a.WPCT - b.WPCT,
    title: 'W%',
    dataIndex: 'W%',
    showSorterTooltip: {
      title: 'Winning Percentage',
    },
  },
  {
    key: 'DW%',
    sorter: (a, b) => a.WPCTD - b.WPCTD,
    title: 'DW%',
    dataIndex: 'DW%',
    showSorterTooltip: {
      title: 'In-Division Winning Percentage',
    },
  },
  {
    key: 'PTS',
    sorter: (a, b) => a.PTS - b.PTS,
    title: 'PTS',
    dataIndex: 'PTS',
    defaultSortOrder: 'descend',
    showSorterTooltip: {
      title: 'Points',
    },
  },
]

const Standings = ({
  areFiltersHidden,
  setIsFilterToggleDisabled = () => {},
  id,
  defaultSort,
  refreshKey,
  scheduleReprocessingListener,
  statWhitelist = [],
  shouldStickyHeader = true,
  hideGroupName = false,
  isSoccer = false,
  isInFrame = false,
}) => {
  const { filters, state, setState, loaded } = useGetScheduleFiltering(
    { schedule_id: id },
    { defaultSort: 'desc' }
  )

  const [scheduleId, setScheduleId] = useState(id)
  const [grouping, setGrouping] = useState(filters?.[filters?.length - 1]?.name)
  const [ready, setReady] = useState(false)

  const [loading, setLoading] = useState(true)
  const [sortBy, setSortBy] = useState(defaultSort)
  const [direction, setDirection] = useState('desc')
  const [items, setItems] = useState([])
  const [groups, setGroups] = useState([])
  const groupList = useMemo(() => Object.keys(groups), [groups])
  const activeListItems = useMemo(
    () => groupList.map((_, i) => i.toString()),
    [groupList]
  )
  const [expandAllItems, setExpandAllItems] = useState(true)
  const [activeKey, setActiveKey] = useState(null)

  useEffect(() => {
    return () => {
      setIsFilterToggleDisabled(false)
    }
  }, [])

  useEffect(() => {
    // Wait for schedule filters to finish loading, then set the grouping and load statistics
    if (loaded) {
      setGrouping(filters?.[filters?.length - 1]?.name || '')
      setReady(true)
      if (filters?.length === 0) {
        setIsFilterToggleDisabled(true)
      }
    }
  }, [loaded, filters?.length])

  const getItems = async (page) => {
    setLoading(true)
    let groupBy
    if (grouping) groupBy = grouping

    const slug = `/schedules/${scheduleId}/stats/byType`

    const response = await req(slug, {
      query: { groupBy, page },
    })

    const g = {}
    response.data.forEach((row) => {
      g[row.schedule.id] = g[row.schedule.id] || {}
      g[row.schedule.id].rows = g[row.schedule.id].rows || []
      g[row.schedule.id].name = row.schedule.name
      if (row?.team !== null) {
        g[row.schedule.id].rows.push(row)
      }
    })

    setItems(response.data)
    setGroups(g)
    setLoading(false)
  }

  useEffect(() => {
    ready && getItems()
  }, [ready, scheduleId, grouping, refreshKey])

  const collapseItems = useMemo(
    () => [
      ...groupList.map((groupId, index) => {
        const group = groups[groupId]
        return {
          key: String(index),
          label: group?.name,
          children: (
            <Table
              columns={commonColumns.concat(
                isSoccer ? soccerColumns : hockeyColumns
              )}
              bordered
              size='small'
              scroll={{ x: 1300 }}
              pagination={false}
              dataSource={[
                ...group.rows.map((item, index) => ({
                  key: index,
                  team: item,
                  GP: item?.stats?.find((s) => s?.abbr === 'GP')?.value ?? 0,
                  W: item?.stats?.find((s) => s?.abbr === 'W')?.value ?? 0,
                  L: item?.stats?.find((s) => s?.abbr === 'L')?.value ?? 0,
                  T: item?.stats?.find((s) => s?.abbr === 'T')?.value ?? 0,
                  OTL: item?.stats?.find((s) => s?.abbr === 'OTL')?.value ?? 0,
                  SOL: item?.stats?.find((s) => s?.abbr === 'SOL')?.value ?? 0,
                  PTS: item?.stats?.find((s) => s?.abbr === 'PTS')?.value ?? 0,
                  WPCT:
                    item?.stats?.find((s) => s?.abbr === 'WPCT')?.value ?? 0,
                  WPCTD:
                    item?.stats?.find((s) => s?.abbr === 'WPCTD')?.value ?? 0,
                  GF: item?.stats?.find((s) => s?.abbr === 'GF')?.value ?? 0,
                  GA: item?.stats?.find((s) => s?.abbr === 'GA')?.value ?? 0,
                  DIFF:
                    item?.stats?.find((s) => s?.abbr === 'DIFF')?.value ?? 0,
                  GPCT:
                    item?.stats?.find((s) => s?.abbr === 'GPCT')?.value ?? 0,
                  PIM: item?.stats?.find((s) => s?.abbr === 'PiM')?.value ?? 0,
                  OTW: item?.stats?.find((s) => s?.abbr === 'OTW')?.value ?? 0,
                  MP: item?.stats?.find((s) => s?.abbr === 'MP')?.value ?? 0,
                  D: item?.stats?.find((s) => s?.abbr === 'D')?.value ?? 0,
                  ETL: item?.stats?.find((s) => s?.abbr === 'ETL')?.value ?? 0,
                  PSL: item?.stats?.find((s) => s?.abbr === 'PSL')?.value ?? 0,
                  GC: item?.stats?.find((s) => s?.abbr === 'GC')?.value ?? 0,
                  MPCT:
                    item?.stats?.find((s) => s?.abbr === 'MPCT')?.value ?? 0,
                  ETW: item?.stats?.find((s) => s?.abbr === 'ETW')?.value ?? 0,
                  RC: item?.stats?.find((s) => s?.abbr === 'RC')?.value ?? 0,
                  YC: item?.stats?.find((s) => s?.abbr === 'YC')?.value ?? 0,
                  'W%': item?.stats?.find((s) => s?.abbr === 'W%')?.value ?? 0,
                  'DW%':
                    item?.stats?.find((s) => s?.abbr === 'DW%')?.value ?? 0,
                  GD: item?.stats?.find((s) => s?.abbr === 'GD')?.value ?? 0,
                })),
              ]}
            />
          ),
        }
      }),
    ],
    [groupList, groups]
  )

  return (
    <>
      <ReprocessingOverlay
        scheduleReprocessingListener={scheduleReprocessingListener}
      />
      <div
        css={css`
          margin-top: 24px;

          ${scheduleReprocessingListener &&
          css`
            opacity: 0.7;
            filter: blur(2px);
            pointer-events: none;
          `}
        `}
      >
        <GameListFilters
          filters={filters}
          state={state}
          setState={setState}
          loading={loading}
          onScheduleChange={(scheduleId, scheduleName) => {
            if (scheduleId) {
              setScheduleId(scheduleId)
              setGrouping(scheduleName)
            } else {
              setScheduleId(id)
              setGrouping(filters?.[filters?.length - 1]?.name || '')
            }
          }}
          isHidden={areFiltersHidden}
        >
          <div
            css={css`
              display: flex;
              justify-items: center;
              flex-direction: column;
            `}
          >
            <p
              css={css`
                height: 12px;
                font-size: 12px;
                line-height: 12px;
                margin-bottom: 8px;
                text-transform: capitalize;
              `}
            />
            <Button
              css={
                !isInFrame
                  ? css``
                  : css`
                      /* border should be : rgba(0, 0, 0, 0.3) */
                      border: 1px solid rgba(0, 0, 0, 0.3);
                      color: ${colors.HEADER};
                    `
              }
              type='default'
              onClick={() => {
                if (expandAllItems) {
                  setExpandAllItems(false)
                  setActiveKey(null)
                } else {
                  setExpandAllItems(true)
                  setActiveKey(activeListItems)
                }
              }}
            >
              <span>{expandAllItems ? 'Collapse All' : 'Expand All'}</span>
            </Button>
          </div>
        </GameListFilters>
        {items?.length === 0 && !loading ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={css`
              margin-top: 24px;
            `}
          />
        ) : null}
        {!ready ||
          (loading && (
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                height: 400px;
              `}
            >
              <Spin />
            </div>
          ))}
        {ready && groupList.length > 0 && !loading && (
          <Collapse
            css={
              !isInFrame
                ? css``
                : css`
                    /* if is inFrame, we should change ant-collapse-header-text color to colors.HEADER */
                    .ant-collapse-header-text {
                      color: ${colors.HEADER};
                    }
                    .ant-collapse-expand-icon {
                      color: ${colors.HEADER};
                    }
                  `
            }
            items={collapseItems}
            activeKey={expandAllItems ? activeListItems : activeKey}
            onChange={(e) => {
              setExpandAllItems(false)
              setActiveKey(e)
            }}
            style={{ marginBottom: 24 }}
          />
        )}
      </div>
    </>
  )
}

export default Standings
