/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { observer } from 'mobx-react-lite'
import { useMobxStore } from 'src/state'
import Icon from '../Icon'
import { Button } from '../Button'
import {
  ModalHeader,
  UpgradeModal,
} from 'src/pages/GeneralSettings/UpgradeModal'
import { NewModal } from '../NewModal'
import { useRef, useState } from 'react'
import { ComparePlansModal } from 'src/pages/GeneralSettings/ComparePlansModal'

import upgradePlanModalImage from './home_upgrade_modal.svg'

enum ParticipantsLimits {
  AccountId = 1,
  MaximumFreePlanParticipants = 50,
}

export const HomeBannerParticipantsLimit = observer(() => {
  const {
    me: {
      state: { account },
    },
  } = useMobxStore()
  const [isUltimate, setIsUltimate] = useState(false)

  const upgradePlanModal = useRef<NewModal>()
  const comparePlanModal = useRef<NewModal>()
  const upgradeModalMessage = useRef<NewModal>()

  if (
    Number(account?.subscription_type?.id) === ParticipantsLimits.AccountId &&
    Number(account?.participants) >=
      ParticipantsLimits.MaximumFreePlanParticipants
  ) {
    return (
      <>
        <div
          css={css`
            background-color: ${colors.FLORAL_WHITE};
            border: 1px solid ${colors.AMERICAN_YELLOW};
            display: flex;
            padding: 16px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 16px;
            border-radius: 8px;
            margin-bottom: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 8px;
              align-items: baseline;
            `}
          >
            <Icon
              name='exclamation-triangle'
              color={colors.DARK_RED}
              fontSize={20}
            />
            <p
              css={css`
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                color: ${colors.DARK_RED};
              `}
            >
              Participant Limit Reached
            </p>
          </div>
          <div>
            <p
              css={css`
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                color: ${colors.HEADER};
                line-height: 120%;
              `}
            >
              {
                'This account has reached it’s limit of 50 participants. Please upgrade your subscription.'
              }
            </p>
          </div>
          <div
            css={css`
              width: 100%;
            `}
          >
            <Button
              onClick={() => upgradeModalMessage.current?.openModal()}
              label='UPGRADE PLAN'
            />
          </div>
        </div>
        <NewModal ref={upgradeModalMessage} shouldCloseOnOverlayClick={false}>
          <div
            css={css`
              width: 820px;
              height: 768px;
              padding: 16px;
              background: no-repeat fixed linear-gradient(#282e38, #181a1d);
              border-radius: 8px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              max-height: 80vh;
              overflow-y: auto;
            `}
          >
            <div>
              <ModalHeader
                onCloseClick={() => {
                  upgradeModalMessage.current?.closeModal()
                }}
                title='Upgrade now'
              />
              <div
                css={css`
                  display: flex;
                  margin-top: 32px;
                  padding: 0 18%;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <img
                  src={upgradePlanModalImage}
                  alt='Upgrade Now Image'
                  css={css`
                    width: 100%;
                    height: 100%;
                  `}
                />
              </div>
              <div
                css={css`
                  margin-top: 7%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  gap: 16px;
                `}
              >
                <p
                  css={css`
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    color: ${colors.DEFAULT_FLAIR};
                  `}
                >
                  Participant Limit Reached
                </p>
                <p
                  css={css`
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                  `}
                >
                  {`We're sorry, but your account has already reached the limit of ${ParticipantsLimits.MaximumFreePlanParticipants} participants.`}
                </p>
                <p
                  css={css`
                    text-align: center;
                    color: ${colors.ATTENDANCE_GRAY};
                    font-size: 14px;
                    padding-bottom: 16px;
                  `}
                >
                  Upgrade your plan to add more players, users, and officials
                </p>
              </div>
            </div>
            <div>
              <div
                css={css`
                  display: flex;
                  width: 100%;
                  height: 1px;
                  background-color: ${colors.SOFT_STEEL};
                `}
              />
              <div
                css={css`
                  margin-top: 32px;
                `}
              >
                <Button
                  onClick={() => {
                    upgradePlanModal.current?.openModal()
                    upgradeModalMessage.current?.closeModal()
                  }}
                  label='Upgrade Plan'
                />
              </div>
            </div>
          </div>
        </NewModal>
        <NewModal ref={upgradePlanModal} shouldCloseOnOverlayClick={false}>
          <UpgradeModal
            userHasCard={!!account?.payment_method?.id}
            onCompareClick={() => {
              comparePlanModal.current?.openModal()
              upgradePlanModal.current?.closeModal()
            }}
            onCloseModal={() => upgradePlanModal?.current?.closeModal()}
            isUltimate={isUltimate}
          />
        </NewModal>
        <NewModal ref={comparePlanModal} shouldCloseOnOverlayClick={false}>
          <ComparePlansModal
            onProfessionalClick={() => {
              upgradePlanModal.current?.openModal()
              comparePlanModal.current?.closeModal()
            }}
            onFreeClick={() => {}}
            onUltimateClick={() => {
              setIsUltimate(true)
              upgradePlanModal.current?.openModal()
              comparePlanModal.current?.closeModal()
            }}
            onCloseModal={() => comparePlanModal.current?.closeModal()}
          />
        </NewModal>
      </>
    )
  }

  return null
})
