/** @jsxImportSource @emotion/react */
import React from 'react'
import { css } from '@emotion/react'
import { CloseOutlined } from '@ant-design/icons'
import headerImage from './download_app_header.png'
import colors from '@sportninja/common/constants/appColors'
import QRCode from 'react-qr-code'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { heading5, paragraphLarge, weightBold, weightRegular } from '../css'

const OpenInAppModal = ({
  onPressClose = () => {},
  gameId = null,
  isTutorial = false,
}) => {
  return (
    <div
      css={css`
        background: var(
          --Gradient1,
          linear-gradient(180deg, #282e38 0%, #181a1d 100%)
        );
        min-height: 40vh;
        width: 600px;
        border-radius: 16px;
      `}
    >
      <style>
        {`
          .ant-input {
            background-color: ${colors.HEADER};
          }
        `}
      </style>
      <button onClick={onPressClose}>
        <CloseOutlined
          fontSize={20}
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            color: colors.WHITE,
            fontSize: 20,
            position: 'absolute',
            top: 35,
            right: 35,
            cursor: 'pointer',
            zIndex: 1,
            transition: 'all 0.2s ease-in-out',
            opacity: 0.8,
            ':hover': {
              opacity: 1,
            },
          }}
        />
      </button>
      <img
        src={headerImage}
        alt='score'
        css={css`
          width: 100%;
          height: 220px;
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
        `}
      />
      <div
        css={{
          padding: '24px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '16px',
        }}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
          `}
        >
          <span
            css={css`
              ${heading5}
              ${weightBold}
              color: ${colors.PRIMARY_300};
              font-family: 'BarlowCondensed', sans-serif !important;
              text-align: left;
            `}
          >
            Quick Game Access
          </span>
          <span
            css={css`
              color: ${colors.WHITE};
              ${paragraphLarge}
              ${weightRegular}
            `}
          >
            If you have the SportNinja app on your phone, you can scan the QR
            code with your camera. Then, tap the pop-up to open the game in the
            app.
          </span>
        </div>
        <div
          css={css`
            border: 8px solid ${colors.WHITE};
            border-radius: 8px;
          `}
        >
          <QRCode
            size={256}
            css={css`
              width: 144px;
              height: 144px;
            `}
            value={
              isCanlan
                ? `https://canlanstats.sportninja.com/game/${gameId}`
                : `https://app.sportninja.com/game/${gameId}`
            }
          />
        </div>
      </div>
    </div>
  )
}

export default OpenInAppModal
