function isLocalStorageAvailable() {
  var test = 'test'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

const storage = {
  async getItem(key) {
    try {
      let result = null
      if (isLocalStorageAvailable()) {
        result = window.localStorage.getItem(key)
      } else {
        result = window.sessionStorage.getItem(key)
      }
      return Promise.resolve(result)
    } catch (e) {
      return Promise.resolve('')
    }
  },

  async setItem(key, value) {
    try {
      let result = null
      if (isLocalStorageAvailable()) {
        result = window.localStorage.setItem(key, value)
      } else {
        result = window.sessionStorage.setItem(key)
      }
      return Promise.resolve(result)
    } catch (e) {
      return Promise.resolve('')
    }
  },

  async removeItem(key) {
    try {
      let result = null
      if (isLocalStorageAvailable()) {
        result = window.localStorage.removeItem(key)
      } else {
        result = window.sessionStorage.removeItem(key)
      }
      return Promise.resolve(result)
    } catch (e) {
      return Promise.resolve('')
    }
  },
}

export default storage
