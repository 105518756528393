import { generateActions } from './utils'

// An individual invitation
const invitationDefinition = {
  read: ['id'],
  accept: [
    'id',
    'user_id',
    'accepted_format',
    'selected_position_id',
    'selected_position_type',
    'ask_position',
  ],
}

const invitationActions = generateActions(['INVITE'], invitationDefinition)

export default invitationActions
