/* eslint-disable react-native/no-inline-styles */
/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react'
import {
  InfoCircleOutlined,
  UploadOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import {
  Alert,
  Button as AntButton,
  DatePicker,
  Image,
  Radio,
  Space,
  Spin,
  Switch,
  Upload,
} from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import {
  captionLarge,
  heading6,
  paragraphMedium,
  paragraphSmall,
  paragraphXSmall,
  weightRegular,
  weightSemiBold,
} from 'src/components/css'
import { ErrorLabel } from 'src/components/ts-components/ErrorLabel'
import { SelectInput } from 'src/components/ts-components/SelectInput'
import { TextInput } from 'src/components/ts-components/TextInput'
import CopyComponent from '../../CopyComponent'
import banners from '../../Form/banner-helpers'
import { useScheduleContext } from '../ScheduleContext'
import '../styles.scss'
import DeleteModal from 'src/components/Modal/DeleteModal'
import req from '@sportninja/common/api/request'
import { paragraphSmallStyle } from 'src/pages/RegistrationInviteV2/steps/Main'
import { isCanlan } from '@sportninja/common/utils/customer-name'

const seasonTypes = [
  {
    label: 'Spring / Summer',
    value: 'Spring / Summer',
  },
  {
    label: 'Fall / Winter',
    value: 'Fall / Winter',
  },
  {
    label: 'Tournament',
    value: 'Tournament',
  },
]

const seasonCurrencies = [
  {
    label: 'USD',
    value: 'USD',
  },
  {
    label: 'CAD',
    value: 'CAD',
  },
]

const AntButtonStyle = css`
  color: ${colors.WHITE};
  font-family: ${isCanlan
    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
    : 'BarlowCondensed'};
  font-size: 16px;
  font-weight: 600;
  line-height: 100%; /* 16px */
  text-transform: uppercase;
`

export const Separator = () => {
  return (
    <div
      css={css`
        border-bottom: 1px solid #3f4753;
      `}
    />
  )
}

const getDate = (
  start_at: Dayjs | null | undefined | string,
  end_at: Dayjs | null | undefined | string
): [Dayjs | null | undefined, Dayjs | null | undefined] => {
  if (!start_at && !end_at) {
    return [undefined, undefined]
  }
  if (start_at && !end_at) {
    return [dayjs(start_at), undefined]
  }
  if (!start_at && end_at) {
    return [undefined, dayjs(end_at)]
  }
  return [dayjs(start_at), dayjs(end_at)]
}

const DeleteButton = ({ ...props }) => {
  return (
    <button
      css={css`
        display: flex;
        padding: 8px 16px 8px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid ${colors.ERROR_200};
        box-shadow: 0px 2px 0px 0px rgba(5, 145, 255, 0.1);
      `}
      {...props}
    >
      <DeleteOutlined
        css={css`
          color: ${colors.ERROR_200};
        `}
        width={24}
        height={24}
      />
      <p
        css={css`
          ${paragraphMedium}
          ${weightSemiBold}
          font-family: ${isCanlan
            ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
            : 'BarlowCondensed'};
          color: ${colors.WHITE};
        `}
      >
        DELETE {isCanlan ? 'SEASON' : 'COMPETITION'}
      </p>
    </button>
  )
}

type GeneralFormProps = {
  onCancelPressed: () => void
  triggerRefresh: () => void
  setTab: (tab: string) => void
}

const GeneralForm: React.FC<GeneralFormProps> = ({
  onCancelPressed,
  triggerRefresh,
  setTab,
}) => {
  const {
    id,
    isEdit,
    imageUrl,
    setImageUrl,
    fieldErrors,
    customError,
    name,
    setName,
    abbreviation,
    setAbbreviation,
    sports,
    genericSports,
    selectedSport,
    setSelectedSport,
    competitionDateRange,
    setCompetitionDateRange,
    displayCanlanForm,
    setSelectedSeasonType,
    setSelectedSeasonCurrency,
    selectedSeasonType,
    selectedSeasonCurrency,
    taxRate,
    setTaxRate,
    setPlayoffCompetition,
    playoffCompetition,
    privacySettings,
    setPrivacySettings,
    error,
    isLoading,
    setSportId,
    setGenericSportId,
    genericId,
    onCancel,
    onSubmit,
    sportOptions,
    beforeUpload,
    handleChange,
    onlineRegistrationOpen,
    setOnlineRegistrationOpen,
  } = useScheduleContext()

  const hasFilledNameAndAbbreviationAndDates =
    name &&
    abbreviation &&
    name.length > 0 &&
    abbreviation.length > 0 &&
    competitionDateRange.starts_at &&
    competitionDateRange.ends_at

  const selectOptions = [
    {
      label: (
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <span
            css={css`
              ${paragraphSmallStyle}
              ${weightSemiBold}
              color: ${colors.PRIMARY_300};
              text-transform: uppercase;
            `}
          >
            Full Stats
          </span>
          <QuestionCircleOutlined
            style={{
              fontSize: 16,
              color: colors.PRIMARY_300,
              cursor: 'pointer',
            }}
            onClick={() => {
              window.open(
                'https://sportninja.zendesk.com/hc/en-us/articles/6347822302484-Scoring-Score-a-Game-on-SportNinja-Mobile-only',
                '_blank'
              )
            }}
          />
        </div>
      ),
      title: 'fullStats',
      options: sports
        ?.map((option) => ({
          value: option.id,
          label: `${option.name_full}`,
          is_generic: false,
        }))
        .filter((option) => !option.label.toLowerCase().includes('generic'))
        ?.sort((a, b) => {
          return a?.label?.localeCompare(b?.label)
        }),
    },
    {
      label: (
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <span
            css={css`
              ${paragraphSmallStyle}
              ${weightSemiBold}
              color: ${colors.PRIMARY_300};
              text-transform: uppercase;
            `}
          >
            Basic Stats
          </span>
          <QuestionCircleOutlined
            style={{
              fontSize: 16,
              color: colors.PRIMARY_300,
              cursor: 'pointer',
            }}
            onClick={() => {
              window.open(
                'https://sportninja.zendesk.com/hc/en-us/articles/28411302723476-Scoring-Basic-Stats-Score-a-Game-on-SportNinja-Mobile-only',
                '_blank'
              )
            }}
          />
        </div>
      ),
      title: 'basicStats',
      options: genericSports
        ?.map((option) => ({
          value: option.id,
          label: `${option.name}`,
          is_generic: true,
        }))
        ?.sort((a, b) => {
          return a?.label?.localeCompare(b?.label)
        }),
    },
  ]

  // This function is used to filter the options in the select dropdown
  // when we group the options
  const filterOptionGroup = useCallback((search, optionOrGroup) => {
    const isGroup = Array.isArray(optionOrGroup.options)
    if (isGroup) {
      return false
    }
    return optionOrGroup.label.toLowerCase().includes(search.toLowerCase())
  }, [])

  return (
    <div
      css={css`
        padding-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        max-height: 550px;
        gap: 16px;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
        `}
      >
        <p
          css={css`
            ${heading6}
            ${weightSemiBold}
            color: ${colors.WHITE};
            font-family: ${isCanlan
              ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
              : 'BarlowCondensed'};
          `}
        >
          General Info
        </p>
        {isEdit ? (
          <div>
            <CopyComponent id={id} />
          </div>
        ) : null}
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
          align-self: stretch;
        `}
      >
        <Image
          width={56}
          height={56}
          css={css`
            border-radius: 120px;
            border: 1px solid ${colors.WHITE};
            background: #323a48;
          `}
          src={
            imageUrl ||
            '/images/generic_placeholders/placeholder_competition.png'
          }
          preview={false}
        />
        <Upload
          beforeUpload={beforeUpload}
          onChange={handleChange}
          showUploadList={false}
          action={undefined}
        >
          <AntButton
            icon={<UploadOutlined />}
            css={css`
              ${AntButtonStyle}
              border-radius: 8px;
              background: ${colors.PRIMARY};
              box-shadow: 0px 2px 0px 0px rgba(5, 145, 255, 0.1);
              color: ${colors.WHITE};
            `}
          >
            Upload
          </AntButton>
        </Upload>
        {imageUrl ? (
          <AntButton
            css={css`
              ${AntButtonStyle}
              border-radius: 8px;
              border: 1px solid ${colors.PRIMARY_300};
              box-shadow: 0px 2px 0px 0px rgba(5, 145, 255, 0.1);
              color: ${colors.WHITE};
            `}
            onClick={() => {
              setImageUrl(null)
            }}
          >
            Remove
          </AntButton>
        ) : null}
      </div>
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
        `}
      >
        {/* Competition Name */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
          `}
        >
          <TextInput
            name={'name'}
            value={name}
            setValue={setName}
            fieldErrors={fieldErrors}
            customError={customError}
            label={'Competition Name*'}
          />
        </div>
        {/* Abbreviation (up to 8 characters)* */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
          `}
        >
          <TextInput
            name={'abbreviation'}
            value={abbreviation}
            setValue={setAbbreviation}
            fieldErrors={fieldErrors}
            customError={customError}
            label={'Abbreviation (up to 8 characters)*'}
            maxLength={8}
          />
        </div>
      </div>
      {/* Sport And Start / End Date */}
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
        `}
      >
        {/* Sport */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
          `}
        >
          <SelectInput
            label={'Sport*'}
            disabled={isEdit}
            options={selectOptions}
            filterOption={filterOptionGroup}
            name={'sport'}
            value={selectedSport || sports?.[0]?.id}
            showSearch={true}
            onChange={(value) => {
              const selectedSportFromSelect = sportOptions?.find(
                (s) => s.id === value
              )
              if (selectedSportFromSelect.is_generic) {
                setSportId(genericId)
                setGenericSportId(selectedSportFromSelect.id)
              } else {
                setSportId(selectedSportFromSelect.id)
                setGenericSportId(null)
              }
              setSelectedSport(value)
            }}
            fieldErrors={fieldErrors}
            customError={customError}
          />
        </div>
        {/* Start / End Date */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
          `}
        >
          <p
            css={css`
              ${paragraphSmall}
              ${weightRegular}
              color: ${colors.WHITE};
              align-self: stretch;
            `}
          >
            Start and End Date*
          </p>
          <DatePicker.RangePicker
            format={{
              format: 'DD-MM-YYYY',
              type: 'mask',
            }}
            allowEmpty={[false, false]}
            allowClear={false}
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentNode as HTMLElement
            }}
            style={{ width: '100%' }}
            size='large'
            placeholder={['Start', 'End']}
            onChange={(e) => {
              if (e) {
                const [starts_at, ends_at] = e
                setCompetitionDateRange({
                  starts_at: dayjs(starts_at).utc().format('YYYY-MM-DD'),
                  ends_at: dayjs(ends_at).utc().format('YYYY-MM-DD'),
                })
              }
            }}
            value={getDate(
              competitionDateRange.starts_at,
              competitionDateRange.ends_at
            )}
          />
          <ErrorLabel
            fieldErrors={fieldErrors}
            customError={customError}
            name={'competitionDateRange'}
          />
        </div>
      </div>
      <Separator />
      {/* Canlan Form, PT I */}
      {displayCanlanForm ? (
        <>
          <div
            css={css`
              display: flex;
              align-items: flex-start;
              gap: 16px;
              align-self: stretch;
            `}
          >
            {/* Season Type */}
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
              `}
            >
              <SelectInput
                label={'Season Type*'}
                disabled={false}
                options={seasonTypes}
                name={'season_type'}
                value={selectedSeasonType}
                onChange={setSelectedSeasonType}
                fieldErrors={fieldErrors}
                customError={customError}
              />
            </div>
            {/* Season Currency */}
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
              `}
            >
              <SelectInput
                label={'Season Currency*'}
                disabled={false}
                options={seasonCurrencies}
                name={'season_currency'}
                value={selectedSeasonCurrency}
                onChange={setSelectedSeasonCurrency}
                fieldErrors={fieldErrors}
                customError={customError}
              />
            </div>
          </div>
          <div
            css={css`
              display: flex;
              align-items: flex-start;
              gap: 16px;
              align-self: stretch;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                flex: 1 0 0;
              `}
            >
              <TextInput
                name={'tax_rate'}
                value={taxRate !== null ? taxRate.toString() : ''}
                setValue={(value) =>
                  setTaxRate(value ? parseFloat(value) : null)
                }
                fieldErrors={fieldErrors}
                customError={customError}
                label={'Tax Rate*'}
                type={'number'}
              />
            </div>
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            {/* Online Registration Open - Canlan Salesforce Settings */}
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 8px;
                flex: 1 0 0;
                opacity: 0.7;
              `}
            >
              <InfoCircleOutlined
                width={16}
                height={16}
                css={css`
                  color: ${colors.PRIMARY_300};
                `}
              />
              <p
                css={css`
                  ${paragraphSmall}
                  ${weightRegular}
              color: ${colors.WHITE};
                `}
              >
                Online Registration Open?
              </p>
            </div>
            <Switch
              defaultChecked
              onChange={(checked) => {
                setOnlineRegistrationOpen(checked)
              }}
              value={onlineRegistrationOpen}
              size='small'
            />
          </div>
        </>
      ) : null}
      {displayCanlanForm ? <Separator /> : null}
      {/* Playoff Competition */}
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        `}
      >
        {/* Icon and text */}
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            opacity: 0.7;
          `}
        >
          <InfoCircleOutlined
            width={16}
            height={16}
            css={css`
              color: ${colors.PRIMARY_300};
            `}
          />
          <p
            css={css`
              ${paragraphSmall}
              ${weightRegular}
              color: ${colors.WHITE};
            `}
          >
            Playoff Competition
          </p>
        </div>
        <Switch
          defaultChecked
          onChange={(checked) => {
            setPlayoffCompetition(checked)
          }}
          value={playoffCompetition}
          size='small'
          disabled={true}
        />
      </div>
      <Separator />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          flex: 1 0 0;
        `}
      >
        <p
          css={css`
            ${paragraphSmall}
            ${weightRegular}
            color: ${colors.WHITE};
          `}
        >
          Visibility
        </p>
        <Radio.Group
          value={privacySettings}
          defaultValue={'public'}
          onChange={(e) => {
            setPrivacySettings(e.target.value)
          }}
        >
          <Radio.Button value='public'>Public</Radio.Button>
          <Radio.Button value='private'>Private</Radio.Button>
        </Radio.Group>
        {privacySettings && privacySettings === 'public' ? (
          <p
            tabIndex={-1}
            css={css`
              font-weight: 500;
              font-size: 12px;
              line-height: 12px;
              color: ${colors.WHITE};
              margin-bottom: 8px;
            `}
          >
            Everyone can search, view details, and follow your Organization.
          </p>
        ) : privacySettings && privacySettings === 'private' ? (
          <p
            tabIndex={-1}
            css={css`
              font-weight: 500;
              font-size: 12px;
              line-height: 145%; /* 17.5px */
              color: ${colors.WHITE};
              margin-bottom: 8px;
            `}
          >
            Private Competitions can only be viewed and followed by people that
            have been invited by admins.
          </p>
        ) : null}
      </div>
      {isEdit ? (
        <>
          <Separator />
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
            `}
          >
            <p
              css={css`
                ${paragraphSmall}
                ${weightRegular}
            color: ${colors.WHITE};
              `}
            >
              Danger Zone
            </p>
            <p
              tabIndex={-1}
              css={css`
                ${paragraphXSmall}
                ${weightRegular}
                color: ${colors.WHITE};
              `}
            >
              Permanently delete the competition and all info related.
            </p>
            <DeleteModal
              title='Delete Competition'
              message={`Are you sure you want to delete ${name}? This cannot be undone.`}
              onDelete={async (shouldForce = false) => {
                const query: any = {}
                if (shouldForce) {
                  query.force = 1
                }
                await req(`/${ENTITY_TYPES.schedule}/${id}`, {
                  method: 'DELETE',
                  query,
                })
                triggerRefresh()
                onCancel()
                onCancelPressed()
              }}
              Button={DeleteButton}
              useForceDelete={true}
              forceDeleteText={undefined}
            />
          </div>
          <Separator />
        </>
      ) : null}
      <div
        css={css`
          position: sticky;
          bottom: 0;
          background-color: ${colors.HEADER};
          z-index: 100;
          padding-bottom: 16px;
          padding-top: 16px;
        `}
      >
        {error ? (
          <Space
            direction='vertical'
            style={{ width: '100%', marginBottom: 8 }}
          >
            <Alert
              message={error || 'An error occurred. Please try again.'}
              type='error'
              showIcon
              closable
            />
          </Space>
        ) : null}
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 16px;
            align-self: stretch;
          `}
        >
          {/* Cancel Button */}
          <div
            css={css`
              max-width: 80px;
              display: flex;
              padding: 8px 24px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              border-radius: 8px;
              border: 1px solid var(--Neutrals-neutrals-0, #fff);
              cursor: ${isLoading ? 'not-allowed' : 'pointer'};
              opacity: ${isLoading ? 0.7 : 1};
            `}
            onClick={() => {
              // Clear the state
              onCancel()
              //   and close the modal
              onCancelPressed()
            }}
          >
            <p
              css={css`
                ${captionLarge}
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'BarlowCondensed'};
                color: ${colors.WHITE};
              `}
            >
              Cancel
            </p>
          </div>
          <div
            css={css`
              max-width: 80px;
              display: flex;
              padding: 8px 24px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              border-radius: 8px;
              background: ${colors.PRIMARY};
              cursor: ${isLoading || !hasFilledNameAndAbbreviationAndDates
                ? 'not-allowed'
                : 'pointer'};
              opacity: ${isLoading || !hasFilledNameAndAbbreviationAndDates
                ? 0.7
                : 1};
            `}
            onClick={async () => {
              try {
                if (isLoading) {
                  return
                }
                if (!isEdit && hasFilledNameAndAbbreviationAndDates) {
                  setTab('1')
                  return
                }
                if (!isEdit && !hasFilledNameAndAbbreviationAndDates) {
                  return
                }
                if (isEdit && !hasFilledNameAndAbbreviationAndDates) {
                  return
                }
                const response = await onSubmit()
                if (isEdit) {
                  // @ts-ignore
                  banners.entityUpdate(ENTITY_TYPES.schedule)(response)
                } else {
                  banners.entityCreate(ENTITY_TYPES.schedule)(response)
                }
                triggerRefresh()
                onCancelPressed()
                onCancel()
              } catch (e) {
                console.error('Error submitting form', e)
              }
            }}
          >
            <>
              {isLoading ? (
                <Spin
                  size='small'
                  css={css`
                    color: ${colors.WHITE};
                  `}
                />
              ) : (
                <p
                  css={css`
                    ${captionLarge}
                    font-family: ${isCanlan
                      ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                      : 'BarlowCondensed'};
                    color: ${colors.WHITE};
                  `}
                >
                  {isEdit ? 'Save' : 'Next'}
                </p>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralForm
