/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { useCallback, useMemo, useState } from 'react'
// import Select from 'react-select'
// import makeAnimated from 'react-select/animated'

import Icon from '../../../components/Icon'
import { Flex } from '../../../components/Layout'
import SuspensionInput from '../../../components/Suspensions/SuspensionInput'
import SuspensionSelect from '../../../components/Suspensions/SuspensionSelect'
import SuspensionSubmitButton from '../../../components/Suspensions/SuspensionSubmitButton'
import SearchContainer from '../../Search/SearchContainer'
import { RegistrationSettingsTitle } from '../EditRegistration'
import ToolbarButton from '../Registration/Division/ToolbarButton'
import { observer } from 'mobx-react-lite'
import { useMobxStore } from '../../../state'
import CurrencyInput from '../../../components/CurrencyInput'
import ToggleSection from '../EditRegistration/ToggleSection'
import { Select, Input, DatePicker } from 'antd'
import dayjs from 'dayjs'

const installmentsOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
]

const frequencyOptions = [
  { label: 'Weekly', value: 0 },
  { label: 'Bi-Weekly', value: 1 },
  { label: 'Monthly', value: 2 },
  { label: 'Bi-Monthly', value: 3 },
  { label: 'Quarterly', value: 4 },
  { label: 'Semi-Annually', value: 5 },
  { label: 'Annually', value: 6 },
]

const InnerDivisionSettings = observer(
  ({ onClose, divisionId, divisionName, scheduleId }) => {
    const {
      registrationTypes: {
        state: { age_range_type, gender_type, day_of_week_type, currency_type },
      },
      registration: {
        createRegistration,
        updateDivision,
        state: { divisions },
      },
    } = useMobxStore()

    const currentDivision = useMemo(() => {
      return divisions.find((division) => division.id === divisionId)
    }, [divisionId])

    const [maxTeams, setMaxTeams] = useState(
      currentDivision?.registration?.max_teams ?? 0
    )
    const [maxPlayers, setMaxPlayers] = useState(
      currentDivision?.registration?.max_players_per_team ?? 0
    )
    const [costPerReg, setCostPerReg] = useState(
      currentDivision?.registration?.team_cost ?? 0
    )
    const [deposit, setDeposit] = useState(
      currentDivision?.registration?.deposit ?? 0
    )

    const [dayOfWeek, setDayOfWeek] = useState(
      Number(currentDivision?.registration?.day_of_week?.id) ?? 1
    )
    const [gender, setGender] = useState(
      Number(currentDivision?.registration?.division_gender?.id) ?? 1
    )
    const [currency, setCurrency] = useState(
      Number(currentDivision?.registration?.currency?.id) ?? 1
    )
    const [age, setAge] = useState(
      Number(currentDivision?.registration?.division_age?.id) ?? 1
    )
    const [installments, setInstallments] = useState(
      currentDivision?.registration?.installments ?? 1
    )
    const [forcePayment, setForcePayment] = useState(
      currentDivision?.registration?.deposit_required ?? false
    )
    const [singlePayment, setSinglePayment] = useState(
      currentDivision?.registration?.single_payment ?? false
    )
    const [frequency, setFrequency] = useState(
      currentDivision?.registration?.installment_frequency
        ? frequencyOptions.find(
            (el) =>
              el.label.toLowerCase() ===
              currentDivision?.registration?.installment_frequency
          ).value
        : 0
    )
    const [installmentStartDate, setInstallmentStartDate] = useState(
      currentDivision?.registration?.installment_start_date
        ? dayjs(currentDivision?.registration?.installment_start_date).utc()
        : dayjs().add(30, 'day')
    )

    const handleSubmit = useCallback(() => {
      if (Number(costPerReg) < Number(deposit)) {
        return alert('Cost per registration must be greater than deposit')
      }

      if (!installmentStartDate && !singlePayment) {
        return alert('You must select an installment start date')
      }

      const body = {
        currency: currency || 1,
        day_of_week: dayOfWeek || 1,
        division_gender: gender || 1,
        max_teams: maxTeams || 0,
        deposit: singlePayment ? 0 : deposit,
        team_cost: costPerReg,
        division_age: age || 1,
        max_players_per_team: maxPlayers,
        single_payment: singlePayment,
        max_team_rep_per_team: 1,
        installments: singlePayment ? 1 : installments,
        installment_start_date: singlePayment
          ? null
          : dayjs(installmentStartDate).format('YYYY-MM-DD'),
        installment_frequency: singlePayment
          ? null
          : installments > 1
          ? frequencyOptions[frequency].label.toLowerCase()
          : null,
        deposit_required: singlePayment
          ? false
          : deposit > 0
          ? forcePayment
          : false,
      }

      if (currentDivision?.registration?.id) {
        updateDivision({
          scheduleId: divisionId,
          info: body,
        })
      } else {
        createRegistration({
          level: 'division',
          scheduleId: divisionId,
          info: body,
        })
      }
    }, [
      age,
      singlePayment,
      currency,
      dayOfWeek,
      gender,
      maxTeams,
      deposit,
      costPerReg,
      maxPlayers,
      installments,
      forcePayment,
      frequency,
      installmentStartDate,
    ])

    return (
      <div
        css={css`
          margin-top: 32px;
          min-width: 45%;
          max-width: 45%;
          .add-report-row {
            margin-bottom: 16px;

            &:not(:last-of-type) {
              margin-right: 16px;
            }
          }

          .suspension-select-container {
            max-width: unset;
            &:last-of-type {
              padding-right: 0;
            }
          }
        `}
      >
        <RegistrationSettingsTitle>Division Settings</RegistrationSettingsTitle>

        <Flex>
          <SuspensionInput
            height={40}
            label='Division Name'
            id='name'
            disabled
            type='text'
            value={divisionName}
            required
          />
        </Flex>

        <Flex
          css={css`
            margin-bottom: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              flex: 1;
              gap: 8px;

              .ant-select {
                background-color: ${colors.HEADER};
              }
            `}
          >
            <p>Day of Week</p>
            <Select
              value={dayOfWeek || 1}
              placeholder='Select...'
              onChange={(e) => setDayOfWeek(e)}
              options={day_of_week_type.map((el) => ({
                label: el.name,
                value: Number(el.id),
              }))}
            />
          </div>
          {/* <SuspensionSelect
            useFullWidth
            enabledAllItemsOption={false}
            required
            label='Day of Week'
            placeholder='-'
            value={dayOfWeek}
            onChange={(e) => setDayOfWeek(e)}
            options={day_of_week_type.map((el) => ({
              text: el.name,
              value: Number(el.id),
            }))}
          /> */}
          <div
            css={css`
              margin: 0 8px;
            `}
          />
          <div
            css={css`
              display: flex;
              flex-direction: column;
              flex: 1;
              gap: 8px;

              .ant-select {
                background-color: ${colors.HEADER};
              }
            `}
          >
            <p>Division Gender</p>
            <Select
              value={gender || 1}
              placeholder='Select...'
              onChange={(e) => {
                setGender(e)
              }}
              options={gender_type.map((el) => ({
                label: el.name,
                value: Number(el.id),
              }))}
            />
          </div>
          {/* <SuspensionSelect
            enabledAllItemsOption={false}
            required
            useFullWidth
            label='Division Gender'
            placeholder='-'
            value={gender}
            onChange={(e) => {
              console.log('GENDER', e)
              setGender(e)
            }}
            options={gender_type.map((el) => ({
              text: el.name,
              value: Number(el.id),
            }))}
          /> */}
        </Flex>

        <Flex noFlex>
          <div
            css={css`
              width: 100%;
              margin-bottom: 16px;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                flex: 1;
                gap: 8px;

                .ant-select {
                  background-color: ${colors.HEADER};
                }
              `}
            >
              <p>Age Range</p>
              <Select
                value={age || 1}
                placeholder='Select...'
                onChange={(e) => setAge(e)}
                options={age_range_type.map((el) => ({
                  label: el.name,
                  value: Number(el.id),
                }))}
              />
            </div>
            {/* <SuspensionSelect
              enabledAllItemsOption={false}
              required
              useFullWidth
              label='Age Range'
              placeholder='-'
              value={age}
              onChange={(e) => setAge(e)}
              options={age_range_type.map((el) => ({
                text: el.name,
                value: Number(el.id),
              }))}
            /> */}
          </div>
        </Flex>

        <Flex
          css={css`
            margin-bottom: 16px;
            gap: 16px;
          `}
        >
          {/* <SuspensionInput
            height={40}
            label='Max Players per Team'
            type='number'
            min={0}
            step={1}
            value={maxPlayers}
            onChange={({ target }) => setMaxPlayers(target.value)}
            required
          /> */}
          <div
            css={css`
              display: flex;
              flex-direction: column;
              flex: 1;
              gap: 8px;

              .ant-input {
                background-color: ${colors.HEADER};
              }
            `}
          >
            <p>Max Players per Team</p>
            <Input
              type='number'
              min={0}
              step={1}
              value={maxPlayers}
              onChange={({ target }) => setMaxPlayers(target.value)}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              flex: 1;
              gap: 8px;

              .ant-input {
                background-color: ${colors.HEADER};
              }
            `}
          >
            <p>Max Number of Teams</p>
            <Input
              type='number'
              min={0}
              step={1}
              value={maxTeams}
              onChange={({ target }) => setMaxTeams(target.value)}
            />
          </div>
          {/* <SuspensionInput
            height={40}
            label='Max Number of Teams'
            id='team_slots'
            type='number'
            min={0}
            step={1}
            value={maxTeams}
            onChange={({ target }) => setMaxTeams(target.value)}
          /> */}
        </Flex>
        <Flex noFlex>
          <div
            css={css`
              width: 100%;
              margin-bottom: 16px;
              margin-top: 10px;
            `}
          >
            <ToggleSection
              title='One Time Payment'
              description='Users will have to pay in full at the registration time.'
              isEnabled={singlePayment}
              onClick={() => setSinglePayment(!singlePayment)}
            />
          </div>
        </Flex>
        <Flex
          css={css`
            margin-bottom: 16px;
          `}
        >
          {/* <div
            css={css`
              display: flex;
              flex-direction: column;
              flex: 1;
              gap: 8px;

              .ant-select {
                background-color: ${colors.HEADER};
              }
            `}
          >
            <p>Currency</p>
            <Select
              value={currency || 1}
              placeholder='Select...'
              onChange={(e) => setCurrency(e)}
              options={currency_type.map((el) => ({
                label: el.name,
                value: Number(el.id),
              }))}
            />
          </div> */}
          {/* <SuspensionSelect
            enabledAllItemsOption={false}
            required
            useFullWidth
            label='Currency'
            placeholder='-'
            value={currency}
            onChange={(e) => setCurrency(e)}
            options={currency_type.map((el) => ({
              text: el.name,
              value: Number(el.id),
            }))}
          /> */}
          <div css={css``} />
          <CurrencyInput
            placeholder={0.0}
            type='text'
            onChange={({ target }) =>
              setCostPerReg(target.value?.replace(/[^0-9.]/g, ''))
            }
            value={costPerReg}
            label='Cost per Registration'
            required
          />
          {!singlePayment && (
            <>
              <div
                css={css`
                  margin: 0 8px;
                `}
              />
              <CurrencyInput
                placeholder={0.0}
                type='text'
                onChange={({ target }) =>
                  setDeposit(target.value?.replace(/[^0-9.]/g, ''))
                }
                value={deposit}
                label='Deposit'
              />
            </>
          )}
        </Flex>
        {/* <div css={css`
          margin: 0px 0 26px 0;
          span {
            color: ${colors.ATTENDANCE_GRAY};
            font-size: 12px;
          }
        `}>
          <span>* The total cost of registration will be the cost per registration amount - the deposit amount.</span>
        </div> */}
        {deposit > 0 && !singlePayment && (
          <Flex noFlex>
            <div
              css={css`
                width: 100%;
                margin-bottom: 16px;
              `}
            >
              <ToggleSection
                title='Required Deposit'
                description='Turn this ON/OFF to make the Deposit Required. If OFF, the Deposit payment can be deferred.'
                isEnabled={forcePayment}
                onClick={() => setForcePayment(!forcePayment)}
              />
            </div>
          </Flex>
        )}

        {!singlePayment && (
          <div
            css={css`
              width: 100%;
              display: flex;
              gap: 16px;
              align-items: center;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                flex: 1;
                gap: 8px;

                .ant-select {
                  background-color: ${colors.HEADER};
                }
              `}
            >
              <p>Number of Installments</p>
              <Select
                value={installments}
                onChange={(e) => setInstallments(e)}
                options={installmentsOptions}
              />
            </div>
            {installments > 1 && (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  flex: 1;
                  gap: 8px;

                  .ant-select {
                    background-color: ${colors.HEADER};
                  }
                `}
              >
                <p>Installment Frequency</p>
                <Select
                  value={frequency}
                  onChange={(e) => setFrequency(e)}
                  options={frequencyOptions}
                />
              </div>
            )}
            <div
              css={css`
                display: flex;
                flex-direction: column;
                flex: 1;
                gap: 8px;

                .ant-picker {
                  background-color: ${colors.HEADER};
                }
              `}
            >
              <p>Installments Start Date</p>
              <DatePicker
                size='large'
                value={installmentStartDate}
                onChange={(e) => setInstallmentStartDate(e)}
              />
            </div>
          </div>
        )}
        <Flex
          alignItems='center'
          justifyContent='center'
          css={css`
            margin-top: 64px;
          `}
        >
          <SuspensionSubmitButton onClick={onClose}>
            Cancel
          </SuspensionSubmitButton>
          <SuspensionSubmitButton
            onClick={handleSubmit}
            isSolid
            css={css`
              margin-left: 40px;
            `}
          >
            Confirm
          </SuspensionSubmitButton>
        </Flex>
      </div>
    )
  }
)

const DivisionSettings = ({ divisionId, divisionName, scheduleId }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <ToolbarButton
        onClick={() => {
          setIsOpen(true)
        }}
      >
        <Icon name='cog' color={colors.DEFAULT_FLAIR} fontSize={19} />
      </ToolbarButton>

      <div
        css={css`
          .search-container {
            z-index: 1000;
          }
        `}
      >
        <SearchContainer displayContents={isOpen} isTeamOrSchedulePage>
          {isOpen && (
            <Flex
              noFlex
              alignItems='center'
              justifyContent='space-around'
              css={css`
                width: 100%;
                overflow-y: scroll; // Enable vertical scroll
              `}
            >
              <InnerDivisionSettings
                divisionId={divisionId}
                scheduleId={scheduleId}
                divisionName={divisionName}
                onClose={() => {
                  setIsOpen(false)
                }}
              />
            </Flex>
          )}
        </SearchContainer>
      </div>
    </>
  )
}

export default DivisionSettings
