import { isCanlan } from '../utils/customer-name'

export const fallback = 'en'

export const supportedLocales = {
  en: {
    name: 'English',
    translationFileLoader: () => {
      const canlanEn = require('./lang/canlan.en.json')
      const en = require('./lang/en.json')
      return isCanlan ? canlanEn : en
    },

    // en is default dayjs locale
    momentLocaleLoader: () => Promise.resolve(),
  },
  fr: {
    name: 'Francais',
    translationFileLoader: () => require('./lang/fr.json'),

    momentLocaleLoader: () => import('dayjs/locale/fr'),
  },
}

export const defaultNamespace = 'routeNames'

// If you don't add a new key from a translation .json file here, it won't be
// accessible at all.
export const namespaces = [
  'common',
  'errors',
  'util',
  'routeNames',
  'DiscoverScreen',
  'GamesScreen',
  'GameScreen',
  'AccountScreen',
  'GameSetup',
  'Scoring',
  'Auth',
  'Web',
  'WebPrivacySettings',
  'TeamScreen',
  'InviteScreen',
  'Notifications',
  'LocationsScreen',
  'QuickGame',
]
