import i18next from 'i18next'

import * as config from './config'
import date from './date'
import languageDetector from './language-detector'
import translationLoader from './translation-loader'

const i18n = {
  /**
   * @returns {Promise}
   */
  init: () => {
    return new Promise((resolve, reject) => {
      i18next
        .use(languageDetector)
        .use(translationLoader)
        .init(
          {
            fallbackLng: config.fallback,
            ns: config.namespaces,
            defaultNS: config.defaultNamespace,
            interpolation: {
              format(value, format) {
                if (format === 'capitalize') {
                  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`
                }

                if (format === 'uppercase') return value.toUpperCase()
                // if (value instanceof Date) {
                //     return date.format(value, format);
                // }
                return value
              },
            },
          },
          (error) => {
            if (error) return reject(error)
            resolve()
            date
              .init(i18next.language)
              .then(resolve)
              .catch((error) => reject(error))
          }
        )
    })
  },

  /**
   * @param {string} key
   * @param {Object} options
   * @returns {string}
   */
  t: (key, options) => i18next.t(key, options),

  /**
   * @returns {string}
   */
  get locale() {
    return i18next.language
  },
}

export const t = i18n.t

export default i18n
