/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'

import { font } from '../css'
import LoadingSpinner from '../LoadingSpinner'

const ListStatusView = ({
  className,
  loading,
  error,
  zeroItems,
  noItemsText = 'items',
  fullNoItemsText,
  hideStatusView,
}) => {
  if (hideStatusView) {
    return null
  }
  
  return (
    <div
      className={className}
      css={css`
        margin-top: 90px;
        text-align: center;
      `}
    >
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <div>
          <p
            css={css`
              ${font.title}
              font-weight: 700;
              font-size: 20px;
              margin-bottom: 16px;
            `}
          >
            Error
          </p>
          {error}
        </div>
      ) : fullNoItemsText || zeroItems ? (
        <div>
          {fullNoItemsText
            ? fullNoItemsText
            : `There are no ${noItemsText} yet`}
        </div>
      ) : (
        false
      )}
    </div>
  )
}

export default ListStatusView
