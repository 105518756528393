/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import LoadingSpinner from '../../components/LoadingSpinner'
import { RegistrationContext } from './RegistrationContext'
import { Main } from './steps'
import { RegistrationSteps } from '@sportninja/common/types'
import { REGISTRATION_STEPS } from './steps/Main'

const RegistrationInvite = ({ login, isLogged }) => {
  const { id } = useParams()
  const {
    session,
    setSession,
    registration,
    setRegistration,
    setStep,
    setZipCode,
    setCountry,
    setDivision,
    setAdditionalTeamOfficials,
    userId,
  } = useContext(RegistrationContext)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setError(null)
        setLoading(true)
        const { data } = await req(`/registration/${id}`)
        setRegistration(data)
      } catch (e) {
        const errorMessage = new Error(
          `Registration not found for id: ${id} | e: ${e?.message}`
        )
        setError(errorMessage)
      } finally {
        setLoading(false)
      }
    }
    if (id) {
      fetchData()
    }
  }, [id, setRegistration])

  useEffect(() => {
    const createSession = async () => {
      try {
        setError(null)
        setLoading(true)
        const { data } = await req(`/registration/${id}/session/`, {
          method: 'POST',
        })
        localStorage.setItem('registration_session', JSON.stringify(data?.id))
        setSession(data)
      } catch (e) {
        const errorMessage = new Error(
          `Registration not found for id: ${id} | e: ${e?.message}`
        )
        setError(errorMessage)
      } finally {
        setLoading(false)
      }
    }
    const setSessionData = async (data) => {
      const step = data?.registration_step
      switch (step) {
        case RegistrationSteps.SELECT_YOUR_DIVISION:
          // If we already selected the division, we should just go to the next step
          if (data?.division_id && userId) {
            setStep(REGISTRATION_STEPS.TEAM_REGISTER)
            break
          }
          setStep(REGISTRATION_STEPS.SELECT_YOUR_DIVISION)
          break
        case RegistrationSteps.TEAM_REGISTER:
          setStep(REGISTRATION_STEPS.TEAM_REGISTER)
          break
        case RegistrationSteps.REGISTRATION_PAYMENT:
          setStep(REGISTRATION_STEPS.REGISTRATION_PAYMENT)
          break
        case RegistrationSteps.VALIDATE_EMAIL:
          setStep(REGISTRATION_STEPS.CONFIRM_YOUR_EMAIL)
          break
        case RegistrationSteps.CONFIRM:
          setStep(REGISTRATION_STEPS.REGISTER_REVIEW)
          break
        default:
          setStep(REGISTRATION_STEPS.SELECT_YOUR_DIVISION)
          break
      }
      if (data?.postal_code) {
        setZipCode(data?.postal_code)
      }
      if (data?.country_code) {
        setCountry(data?.country_code)
      }
      if (data?.division_id) {
        const selectedDivision = registration?.divisions?.find((d) => {
          return d?.schedule_id === data?.division_id
        })
        setDivision(selectedDivision)
      }
      // We should set the additional team officials if they exist
      // the first one is the main team official, the logged in user
      if (data?.team_official_array && data?.team_official_array.length > 1) {
        const teamOfficials = data?.team_official_array
          .map((official) => {
            return {
              email: official?.email,
              firstName: official?.name_first,
              lastName: official?.name_last,
              teamOfficialTypeId: official?.team_official_type_id,
              isTreasurer: official?.is_treasurer,
              phone: official?.phone,
            }
          })
          .slice(1)
        setAdditionalTeamOfficials(teamOfficials)
      }
    }
    const fetchPreviousSession = async (sessionId) => {
      try {
        setError(null)
        setLoading(true)
        const { data } = await req(`/registration/session/${sessionId}`)
        // if (data?.registration_step === RegistrationSteps.CONFIRM) {
        //   localStorage.removeItem('registration_session')
        //   createSession()
        //   return
        // }
        setSession(data)
        setSessionData(data)
      } catch (e) {
        localStorage.removeItem('registration_session')
        createSession()
      } finally {
        setLoading(false)
      }
    }
    if (registration && !session) {
      const previousSession = localStorage.getItem('registration_session')
      if (!previousSession) {
        createSession()
      } else {
        fetchPreviousSession(previousSession.split('"').join(''))
      }
    }
  }, [id, registration, session, userId])

  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        overflow-y: scroll;
        /* Adjustment for the Background issue */
        background-color: ${colors.REGISTRATION_BACKGROUND};
      `}
    >
      {loading ? (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
          `}
        >
          <LoadingSpinner />
        </div>
      ) : null}
      {error ? (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
          `}
        >
          <h1>Error: {error?.message}</h1>
        </div>
      ) : null}
      {!loading && !error && session ? <Main /> : null}
    </div>
  )
}

export default RegistrationInvite
