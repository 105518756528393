import React from 'react'
import PropTypes from 'prop-types'
import { Helmet as H } from 'react-helmet'
import { isCanlan } from '@sportninja/common/utils/customer-name'

const Helmet = ({ ...props }) => {
  return (
    <H
      defaultTitle={isCanlan ? 'Canlan Stats' : 'SportNinja'}
      titleTemplate={`${isCanlan ? 'Canlan Stats' : 'SportNinja'} | %s`}
      {...props}
    />
  )
}

Helmet.propTypes = {
  children: PropTypes.node,
}

export default Helmet
