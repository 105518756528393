import styled from '@emotion/styled/macro'

import { inputCss } from '../Form/css'
import { backgroundColorLight, zIndex, backgroundColorRgb } from '../css'

export const SCROLLER_ANIMATION_DURATION = 100

export const SearchBoxInput = styled.input`
  ${inputCss}
  padding-right: 76px;
  text-overflow: ellipsis;

  &[disabled],
  &[readonly] {
    cursor: default;
  }
`

export const Scroller = styled.div`
  position: absolute;
  top: 47px;
  width: 100%;
  height: 200px;
  z-index: ${zIndex.modal + 1};

  .outer {
    max-height: 202px;
    width: 100%;

    background-color: rgba(${backgroundColorRgb}, 1);
    border-radius: 2px;
    border: 1px solid white;

    -webkit-overflow-scrolling: touch;
  }

  .inner {
    max-height: 200px;
    overflow-y: auto;
  }

  .label {
    padding: 15px 0 12px 12px;
    font-size: 11px;
    text-transform: uppercase;
  }

  .item {
    padding: 12px;

    &.nested {
      padding-left: 22px;
    }

    @media (hover: hover) {
      :hover:not(.none-found),
      &.active {
        cursor: pointer;
        background-color: ${backgroundColorLight};
      }
    }
  }
`
