import dayjs from 'dayjs'
import { customAlphabet } from 'nanoid'

export const str = (actionCreator) => actionCreator.toString()

const keyNames = ['clock_time', 'period_clock_time']

export const parseScoringEventClockTime = (draft, input) => {
  for (let i = 0; i < keyNames.length; i++) {
    const keyName = keyNames[i]
    if (Object.prototype.hasOwnProperty.call(input, keyName)) {
      if (typeof input[keyName] !== 'undefined') {
        draft.currentPeriodTime = dayjs(input[keyName], 'hh:mm:ss').format(
          'm:ss'
        )
        break
      }
    }
  }
}

const alphabet =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
const nanoid = customAlphabet(alphabet, 16)
export const getOfflineId = () => nanoid()

export const prepareOfflineTransformer = (
  _type,
  _payload,
  meta,
  actions,
  transformer,
  draft
) => {
  let type = _type,
    payload = _payload

  if (
    _type === 'FETCH_OFFLINE_MODE' &&
    Object.prototype.hasOwnProperty.call(payload, 'prevAction')
  ) {
    if (
      !Object.prototype.hasOwnProperty.call(payload.prevAction, 'type') ||
      !typeof payload.prevAction.type === 'object'
    ) {
      throw new Error(
        'You must define a valid `type` when configuring an offline scoring request!'
      )
    }
    const transformerResult = transformer(
      actions,
      str(payload.prevAction.type),
      payload.prevAction.payload,
      draft
    )

    if (typeof transformerResult === 'undefined') {
      throw new Error(
        'There was a problem running the offline transformer for ' +
          str(payload.prevAction.type) +
          '!'
      )
    }

    const { type: successType, payload: successPayload } = transformerResult

    type = successType
    payload = successPayload

    meta?.resolve(payload)
  }

  return { type, payload }
}
