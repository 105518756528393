import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  getAllOfficialTypes,
  getOfficialTypesForGeneric,
  getOfficialTypesForHockey,
  getOfficialTypesForSoccer,
  getTeamOfficialTypesBySportId,
} from '@sportninja/common/selectors/types'
import { t } from '@sportninja/common/i18n'
import { SelectFormInput } from '../Select'
import { isCanlan } from '@sportninja/common/utils/customer-name'

const OfficialSelect = ({
  officialTypes,
  teamOfficialTypes,
  officialTypesForSoccer,
  officialTypesForHockey,
  officialTypesForGeneric,
  disabled,
  defaultValue,
  label = t('common:role'),
  noFlex,
  onChange,
  name,
  isTeamOfficialType = false,
  hasError,
  types: _types,
  isSoccer = false,
  isOrg = false,
  required = false,
}) => {
  if (isOrg && !isCanlan) {
    const officialTypesForSoccerWithModifiedName = officialTypesForSoccer.map(
      (type) => {
        return {
          ...type,
          // name_full: `${type?.name_full} (Soccer)`,
          // name: `${type?.name} (Soccer)`,
          name_full: `${type?.name_full}`,
          name: `${type?.name}`,
        }
      }
    )
    const officialTypesForHockeyWithModifiedName = officialTypesForHockey.map(
      (type) => {
        return {
          ...type,
          // name_full: `${type?.name_full} (Hockey)`,
          // name: `${type?.name} (Hockey)`,
          name_full: `${type?.name_full}`,
          name: `${type?.name}`,
        }
      }
    )
    const officialTypesForGenericWithModifiedName = officialTypesForGeneric.map(
      (type) => {
        return {
          ...type,
          // name_full: `${type?.name_full} (Basic Stats)`,
          // name: `${type?.name} (Basic Stats)`,
          name_full: `${type?.name_full}`,
          name: `${type?.name}`,
        }
      }
    )
    officialTypes = [
      ...officialTypesForSoccerWithModifiedName,
      ...officialTypesForHockeyWithModifiedName,
      ...officialTypesForGenericWithModifiedName,
    ]
  }

  const types =
    Array.isArray(_types) && _types.length > 0
      ? _types
      : isTeamOfficialType
      ? teamOfficialTypes
      : officialTypes
  const [selectedType, setSelectedType] = useState(defaultValue || types[0]?.id)

  useEffect(() => {
    if (!defaultValue) {
      setSelectedType(types[0]?.id)
      onChange({ target: { name, value: types[0]?.id } })
    }
  }, [])

  const onSelect = ({ target }) => {
    onChange({ target })
    setSelectedType(target.value)
  }

  return (
    <SelectFormInput
      defaultValue={selectedType}
      disabled={types.length === 0 || disabled}
      label={`${t('util:capitalize', { text: label })}`}
      name={name}
      noFlex={noFlex}
      onChange={onSelect}
      hasError={hasError}
      required={required}
    >
      {/* <option key='line'></option> */}
      {Array.isArray(types) &&
        types.map((type) => {
          return (
            <option key={type?.id} value={type?.id}>
              {type?.name}
            </option>
          )
        })}
    </SelectFormInput>
  )
}

const mapStateToProps = (state, { sportId }) => {
  return {
    officialTypes: getAllOfficialTypes(state),
    teamOfficialTypes: getTeamOfficialTypesBySportId(state, sportId),
    officialTypesForSoccer: getOfficialTypesForSoccer(state),
    officialTypesForHockey: getOfficialTypesForHockey(state),
    officialTypesForGeneric: getOfficialTypesForGeneric(state),
  }
}

export default connect(mapStateToProps)(OfficialSelect)
