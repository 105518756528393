/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { useEffect, useState } from 'react'
import deepEqual from 'fast-deep-equal'

import {
  BAR_HEIGHT,
  COL_WIDTH,
  ROUND_LABEL_HEIGHT,
  SLOT_HEIGHT,
} from './constants'
import { bracketType } from '@sportninja/common/constants/playoffTypes'

const CURVE_RADIUS = 8

const LineColumn = ({ column, left = {}, right = {}, parentRef, selected, rounds }) => {
  const [shouldRender, setShouldRender] = useState(false)
  const [tryAgain, setTryAgain] = useState(false)

  useEffect(() => {
    if (tryAgain) {
      setTimeout(() => {
        setTryAgain(false)
      }, 0)
    }
  }, [tryAgain])

  useEffect(() => {
    if (left?.pools?.length > 0 && right?.pools?.length > 0) {
      setShouldRender(true)
    } else {
      setShouldRender(false)
    }
  }, [left, right])

  return (
    <div
      css={css`
        min-width: ${COL_WIDTH}px;
        width: ${COL_WIDTH}px;

        svg {
          overflow: visible;
          width: ${COL_WIDTH}px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          height: ${ROUND_LABEL_HEIGHT}px;

          .left,
          .right {
            background-color: ${colors.HEADER};
            flex-basis: 48%;
          }
        `}
      >
        <div className='left' />
        <div className='right' />
      </div>
      {shouldRender ? (
        <svg>
          {left.pools.map((leftPool, idx) => {
            const { ref, teamSlots, children } = leftPool
            const numTeams = teamSlots?.data?.length
            const advance = leftPool?.children?.data?.length
            const isUpperPool = leftPool?.bracket_position === bracketType.UPPER

            return children?.data?.map((pointer, advIdx) => {
              let rightPool = right.pools?.find((p) => p.id === pointer.id)

              let isSkipping = false
              // We only want to connect the pools if they are in the same bracket position
              if (isUpperPool) {                
                if (rightPool && rightPool?.bracket_position === bracketType.LOWER) {
                  return null
                } else {
                  if (!rightPool) {
                    rounds.forEach((round) => {
                      round.upperPools.forEach((pool) => {
                        if (pool.id === pointer.id && pool?.bracket_position === bracketType.UPPER) {
                          rightPool = pool
                        }
                      })
                    })
                    if (rightPool) {
                      isSkipping = true
                    }
                  }
                }
              } 

              const startRect = ref?.current?.getBoundingClientRect()
              const endRect = rightPool?.ref?.current?.getBoundingClientRect()
              const parentPosition = parentRef?.current?.getBoundingClientRect()

              if (!startRect || !endRect || !parentPosition) {
                setTimeout(() => {
                  setTryAgain(true)
                }, 0)
                return false
              }

              const startX = COL_WIDTH / 2
              const endX = isSkipping ? COL_WIDTH * 8 : COL_WIDTH 

              let startYSlotFactor = 0

              // For now, we're just going to center the line vertically within each slot
              // if (advance === 1) {
              //   // Smack dab in the middle of the pool
              //   startYSlotFactor = (numTeams * SLOT_HEIGHT) / 2
              // } else if (advance === numTeams) {
              //   // We want the line centered vertically within each slot
              //   startYSlotFactor =
              //     (SLOT_HEIGHT / 2) * (advIdx + 1) + (SLOT_HEIGHT * advIdx) / 2
              // } else if (numTeams - advance === 1) {
              //   // We want the line lined up with the bottom border of each slot
              //   startYSlotFactor = SLOT_HEIGHT * (advIdx + 1)
              // }
              startYSlotFactor = (numTeams * SLOT_HEIGHT) / 2

              let startY =
                startRect.top +
                BAR_HEIGHT -
                ROUND_LABEL_HEIGHT +
                startYSlotFactor -
                parentPosition.top
              let endY =
                endRect.top +
                BAR_HEIGHT -
                ROUND_LABEL_HEIGHT +
                (rightPool?.teamSlots?.data?.length * SLOT_HEIGHT) / 2 -
                parentPosition.top

              // End is above start, basically
              let endAboveStart = false
              if (endY < startY) {
                endAboveStart = true
                const temp = endY
                endY = startY
                startY = temp
              }

              const isHighlighted =
                deepEqual(leftPool?.id, selected?.id) ||
                deepEqual(rightPool?.id, selected?.id)
              const style = {
                stroke: isHighlighted ? colors.PRIMARY : 'rgb(255, 255, 255)',
                strokeWidth: isHighlighted ? 4 : 2,
                zIndex: isHighlighted ? 2 : 1,
                fill: 'none',
              }

              // If we have no vertical difference, then there are no curves to render
              const radius = startY === endY ? 0 : CURVE_RADIUS

              return (
                <g
                  className={`line-column ${
                    isHighlighted ? 'highlighted' : ''
                  }`}
                  id={`${column}-${idx}-${advIdx}`}
                  key={`${idx}-${advIdx}`}
                >
                  <path
                    d={`M0,${endAboveStart ? endY : startY} h${
                      startX - radius
                    } ${
                      endAboveStart
                        ? `a${radius},${radius} 180 0 0 ${radius},-${radius}`
                        : `a${radius},${radius} 0 0 1 ${radius},${radius}`
                    } 
                    `}
                    style={style}
                  />
                  <path
                    d={`M${endAboveStart ? startX : startX},${
                      endAboveStart ? startY + radius : endY - radius
                    } ${
                      endAboveStart
                        ? `a${radius},${radius} 0 0 1 ${radius},-${radius}`
                        : `a${radius},${radius} 180 0 0 ${radius},${radius}`
                    }`}
                    style={style}
                  />
                  <line
                    x1={startX}
                    y1={startY + radius}
                    x2={startX}
                    y2={endY - radius}
                    style={style}
                  />
                  <line
                    x1={startX + (isHighlighted ? -2 : 0) + radius}
                    y1={endAboveStart ? startY : endY}
                    x2={endX}
                    y2={endAboveStart ? startY : endY}
                    style={style}
                  />
                </g>
              )
            })
          })}
          {left.pools.map(({ id, children }, idx) => {
            return children?.data?.map((pointer, advIdx) => {
              const rightPool = right.pools?.find((p) => p.id === pointer.id)
              const isHighlighted =
                deepEqual(id, selected?.id) ||
                deepEqual(rightPool?.id, selected?.id)
              return isHighlighted ? (
                <use
                  xlinkHref={`#${column}-${idx}-${advIdx}`}
                  key={`use-${idx}-${advIdx}`}
                />
              ) : (
                false
              )
            })
          })}
        </svg>
      ) : (
        false
      )}
    </div>
  )
}

export default LineColumn
