/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import scheduleActions from '@sportninja/common/actions/schedule'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import req from '@sportninja/common/api/request'
import { ENABLE_REGISTRATION, ROUTES } from '@sportninja/common/constants/app'
import { t } from '@sportninja/common/i18n'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { convertTimesToISOStrings } from '@sportninja/common/sagas/game'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import FileDownload from '../../components/FileDownload'
import FormSheet from '../../components/Form/FormSheet'
import {
  feedItemForm,
  gameForm,
  venueForm,
} from '../../components/Form/form-configs'
import ImportCSV from '../../components/ImportCSV'
import GameList from '../../components/List/GameList'
import OfficialList, { AddOfficial } from '../../components/List/OfficialList'
import UserList, { AddUser } from '../../components/List/UserList'
import Page from '../../components/Page'
import BaseActionButton from '../../components/Page/BaseActionButton'
import EditButton from '../../components/Page/EditButton'
import FavouriteButton from '../../components/Page/FavouriteButton'
import NewButton from '../../components/Page/NewButton'
import { Mobile } from '../../components/Responsive'
import { ScheduleReprocessingContext } from '../../components/ScheduleReprocessingContext'
// If for any reason we need to rollback to the old standings, we can use the following import:
// import Standings from '../../components/Standings'
import Standings from '../../components/v2/Standings'
// import Statistics from '../../components/Statistics'
import Suspensions from '../../components/Suspensions'
import AddSuspensionSheet from '../../components/Suspensions/AddSuspensionSheet'
import useGetSuspensions from '../../components/Suspensions/useGetSuspensions'
import Venues from '../../components/Venues'
import Statistics from '../../components/v2/Statistics'
import { FrameContext } from '../App'
import Home from '../Home'
import { getBreadcrumbs, useReadItem, useStoreRecent } from '../utils'
import GameSlots from './GameSlots'
import ScheduleBracket from './ScheduleBracket'
import ScheduleTree from './ScheduleTree'
// import CreateBrackets from './CreateBrackets'
import { PrinterOutlined } from '@ant-design/icons'
import orgActions from '@sportninja/common/actions/org'
import colors from '@sportninja/common/constants/appColors'
import { tutorialKeys } from '@sportninja/common/constants/userGuiding'
import { useSports } from '@sportninja/common/hooks/useSports'
import { useTimezoneManager } from '@sportninja/common/hooks/useTimezoneManager'
import { getTutorial } from '@sportninja/common/utils/TutorialHelper'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { Dropdown } from 'antd'
import { driver } from 'driver.js'
import GeneratePluginModal from 'src/components/GeneralPluginModal'
import { NewModal } from 'src/components/NewModal'
import NewScheduleForm from 'src/components/NewScheduleForm'
import PrintSignSheetsModal from 'src/components/PrintSignInSheetsModal'
import { getScheduleScreenSteps } from 'src/constants/TutorialSteps'
import NewReports from '../../components/NewReports'
import CloneSchedule from './CloneSchedule'
import EditRegistration from './EditRegistration'
import { EmptySchedule } from './EmptySchedule'
import Registration from './Registration'
import ReprocessStats from './ReprocessStats'

const generateHrefAndDownloadCsv = (string, reportName) => {
  var blob = new Blob([string], { type: 'text/csv;charset=utf-8;' })

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, reportName)
  } else {
    var a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    var csvUrl = URL.createObjectURL(blob)
    a.href = csvUrl
    a.download = reportName
    a.click()
    URL.revokeObjectURL(a.href)
    a.remove()
  }
}

/**
 * Generates a registration name from a schedule object.
 *
 * @param {Object} schedule - The schedule object.
 * @param {string} schedule.name - The name of the competition.
 * @param {string} schedule.starts_at - The start date of the competition.
 * @param {string} schedule.ends_at - The end date of the competition.
 * @returns {string} The registration name in the format: Registrations_${Competition Name}_${DateStartsAt}_${DateEndsAt}.
 */
const generateRegistrationName = (schedule) => {
  const competitionName = schedule.name || 'Unknown'
  const dateStartsAt = schedule.starts_at
    ? new Date(schedule.starts_at).toISOString().split('T')[0].replace(/-/g, '')
    : 'Unknown'
  const dateEndsAt = schedule.ends_at
    ? new Date(schedule.ends_at).toISOString().split('T')[0].replace(/-/g, '')
    : 'Unknown'

  return `Registrations_${competitionName}_${dateStartsAt}_${dateEndsAt}`
}

const driverObj = driver({
  allowClose: false,
  popoverClass: 'driverjs-theme',
  showProgress: true,
})

const Schedule = ({ match, createVenue, isInFrame, createVenueUnderOrg }) => {
  const frameConfig = useContext(FrameContext)
  const history = useHistory()

  const {
    loading,
    error,
    item,
    hierarchy,
    permission,
    readItem,
    refreshKey,
    triggerRefresh,
    features,
  } = useReadItem()

  const competitionModal = useRef(null)
  const [initialState, setInitialState] = useState(null)
  const SCHEDULE_ID = match.params.id?.replace('?sandbox_tour=1', '')
  const BASE_ROUTE = `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}`
  const IS_PUBLIC = item.is_public
  const TOURNAMENT_READY = item.is_tournament
  const syncSalesforce = item?.organization?.sync_salesforce || false
  const canSyncGameSlots = item?.tournament_can_sync_regular_season

  const [visibleGameIds, setVisibleGameIds] = useState([])
  const [areFiltersHidden, setAreFiltersHidden] = useState(false)
  const [isFilterToggleDisabled, setIsFilterToggleDisabled] = useState(false)
  const [filterForExporting, setFilterForExporting] = useState(null)
  const [ifNecessaryStatus, setIfNecessaryStatus] = useState(false)
  const [selectedGameSlotDivision, setSelectedGameSlotDivision] = useState(null)
  const [selectedGameSlotDivisionName, setSelectedGameSlotDivisionName] =
    useState(null)
  const [gamesCreated, setGamesCreated] = useState(false)
  const [hasTournamentStarted, setHasTournamentStarted] = useState(false)
  const [onFinishUploadGameslots, setOnFinishUploadGameslots] = useState(false)
  const [hasTeams, setHasTeams] = useState(false)
  const [loadingTeams, setLoadingTeams] = useState(true)
  const { sports, sportOptions } = useSports()
  const itemSportFullName = useMemo(() => {
    return sports?.find((sport) => sport.id === item?.sport_id)?.name_full
  }, [sports, item?.sport_id])
  const isSoccer =
    item?.sport_id === sports?.find((sport) => sport.name_full === 'Soccer')?.id
  const isHockey =
    item?.sport_id === sports?.find((sport) => sport.name === 'hockey')?.id

  const generatePluginTagModalRef = useRef(null)
  const printSignInSheetModalRef = useRef(null)
  // const isProfessionalOrUltimateAccount =
  //   item?.organization?.account_subscription_type_id ===
  //     SubscriptionType.PROFESSIONAL ||
  //   item?.organization?.account_subscription_type_id ===
  //     SubscriptionType.ULTIMATE
  const isProfessionalOrUltimateAccount = true

  useStoreRecent(ENTITY_TYPES.schedule, item, error, SCHEDULE_ID, isInFrame)

  // Removed by Jeff on 2024.04.30 - This doesnt make sense
  // useEffect(() => {
  //   if (item && item?.is_registration_on && permission?.admin) {
  //     // If registration is on, we should by default redirect to the registration tab
  //     // and if the user is at the root of the schedule, we should redirect to the registration tab
  //     if (
  //       history.location.pathname === `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}`
  //     ) {
  //       history.replace({
  //         pathname: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/setup`,
  //         search: history.location.search,
  //       })
  //     }
  //   }
  // }, [item, permission])

  useEffect(() => {
    const readTutorial = async () => {
      try {
        const tutorialInfo = await getTutorial(
          tutorialKeys.NEW_ACCOUNT_PRODUCT_TOUR_STEP_2
        )
        if (tutorialInfo?.tutorialStep?.value === 'true') {
          setTimeout(() => {
            const steps = getScheduleScreenSteps(driverObj)
            driverObj.setSteps(steps)
            driverObj.drive()
          }, 2000)
        }
      } catch (e) {
        console.error(`Failed to read tutorial: ${e}`)
      }
    }

    readTutorial()
  }, [])

  useEffect(() => {
    readItem(ENTITY_TYPES.schedule, SCHEDULE_ID)
  }, [SCHEDULE_ID])

  // const locationModalRef = useRef(null)

  const { listeners = {} } = useContext(ScheduleReprocessingContext)
  const scheduleReprocessingListener = listeners[SCHEDULE_ID]

  const {
    loading: isSuspensionsLoading,
    suspensions,
    refresh,
  } = useGetSuspensions(SCHEDULE_ID)

  useEffect(() => {
    const checkTeams = async () => {
      try {
        const response = await req(`/schedules/${SCHEDULE_ID}/teams/simple`)
        if (response?.data?.length > 0) {
          setHasTeams(true)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoadingTeams(false)
      }
    }

    if (
      (!hasTeams &&
        window?.location?.pathname === `/schedule/${SCHEDULE_ID}`) ||
      window?.location?.pathname === `/schedule/${SCHEDULE_ID}/schedule`
    ) {
      checkTeams()
    }
  }, [hasTeams, window?.location?.pathname])

  const onEditSchedule = async (...args) => {
    const [values] = args
    const { imageData, ...form } = values

    const shouldDeleteImage = values?.delete_image ?? false

    if (form?.name_full) {
      form.name = form.name_full
    }

    const body = JSON.stringify(form)
    const response = await req(`/schedules/${item.id}`, {
      method: 'PUT',
      body,
    })

    if (shouldDeleteImage) {
      await req(`/schedules/${item.id}/image`, {
        method: 'DELETE',
      })
    }

    if (imageData) {
      await req(`/schedules/${item.id}/image`, {
        method: 'POST',
        body: imageData,
      })
    }
    await readItem(ENTITY_TYPES.schedule, SCHEDULE_ID)
    return response
  }

  const onAddVenue = async (...args) => {
    // SN-4038 - We are not creating the venues under the parent organization.
    // so they can be shared across multiple schedules.
    // const response = await createVenue(SCHEDULE_ID, ...args)
    const response = await createVenueUnderOrg(item?.organization_id, ...args)
    triggerRefresh()
    return response
  }

  const { DateTime } = useTimezoneManager({
    date: null,
    timezone: null,
  })

  const onAddGame = async ({ ...form }) => {
    const body = convertTimesToISOStrings(form, ['started_at', 'ended_at'])

    const userTimeStartDate = DateTime.fromISO(form?.starts_at)
    const locationStartDate = userTimeStartDate.setZone(
      form?.location_timezone,
      {
        keepLocalTime: true,
      }
    )

    const userTimeEndDate = DateTime.fromISO(form?.ends_at)
    const locationEndDate = userTimeEndDate.setZone(form?.location_timezone, {
      keepLocalTime: true,
    })

    // For web: if no game_type_id was selected, set the default value as 2 (i.e. regular season)
    if (!body.game_type_id) {
      body.game_type_id = 2
    } else if (Object.prototype.hasOwnProperty.call(body, 'game_type_id')) {
      body.game_type_id = parseInt(body.game_type_id, 10)
    }

    const response = await req(`/schedules/${SCHEDULE_ID}/games`, {
      method: 'POST',
      body: JSON.stringify({
        ...body,
        if_necessary_status: ifNecessaryStatus,
        starts_at: locationStartDate.toUTC().toISO(),
        ends_at: locationEndDate.toUTC().toISO(),
      }),
    })

    triggerRefresh()
    return response
  }

  const titleActions = item?.id
    ? [
        <FavouriteButton
          key='favourite'
          initialState={item.is_favorite}
          entityType={ENTITY_TYPES.schedule}
          entityId={SCHEDULE_ID}
        />,
        // permission.update && (
        //   <FormSheet
        //     customDeleteModal={
        //       permission.delete && (
        //         <EntityFormDelete
        //           entityType={ENTITY_TYPES.schedule}
        //           itemId={SCHEDULE_ID}
        //           title={`Delete ${item.name_full}`}
        //           type={t('common:competition')}
        //           onComplete={() => {
        //             history.push(`${ROUTES.SCHEDULES.ROOT}`)
        //           }}
        //         />
        //       )
        //     }
        //     key='edit'
        //     Button={({ onClick }) => (
        //       <EditButton onClick={onClick} title={item?.name_full} />
        //     )}
        //     form={scheduleForm(
        //       parsers[ENTITY_TYPES.schedule](item),
        //       false,
        //       syncSalesforce,
        //       itemSportFullName,
        //       false,
        //       false,
        //       sportOptions,
        //       parsers[ENTITY_TYPES.schedule](item)?.sport_id,
        //       sports
        //     )}
        //     label={t('Web:editCompetition')}
        //     onSubmit={onEditSchedule}
        //     title={t('Web:editCompetition')}
        //     subTitle={
        //       <div>
        //         <EditEntitySubTitle
        //           id={SCHEDULE_ID}
        //           entityType={ENTITY_TYPES.schedule}
        //         />
        //         {isCanlan && syncSalesforce && item?.salesforce_id ? (
        //           <EditEntitySubTitle
        //             id={item?.salesforce_id}
        //             entityType={ENTITY_TYPES.salesforce}
        //           />
        //         ) : null}
        //       </div>
        //     }
        //   />
        // ),
        permission.update && (
          <EditButton
            onClick={() => {
              setInitialState(item)
              competitionModal?.current?.openModal()
            }}
            title={item?.name_full}
          />
        ),
      ]
    : []

  const baseActions = []

  const filterToggleButton = !isFilterToggleDisabled && (
    <BaseActionButton
      faType={areFiltersHidden ? 'far' : 'fas'}
      key='toggle-filter'
      name='filter'
      title={`Toggle Filters Visibility ${areFiltersHidden ? 'on' : 'off'}`}
      onClick={() => {
        setAreFiltersHidden((v) => !v)
      }}
    />
  )

  const reprocessStatsButton = permission.update && (
    <ReprocessStats
      key='reprocess'
      id={SCHEDULE_ID}
      onSuccess={triggerRefresh}
    />
  )

  const onPressPrint = async () => {
    try {
      const response = await req(
        `/reports/${SCHEDULE_ID}/registration?format=csv`,
        { parseJSON: false }
      )
      const text = await response.text()
      generateHrefAndDownloadCsv(text, `${generateRegistrationName(item)}`)
    } catch (e) {
      console.error(e)
      alert(
        `There was an error generating the report. ${
          e?.message || ''
        } Please try again.`
      )
    }
  }

  const items = [
    {
      key: '1',
      label: (
        <a
          id='location-tutorial'
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => {
            if (visibleGameIds?.length > 0) {
              history.push({
                pathname: ROUTES.SCORESHEETS,
                state: {
                  games: visibleGameIds,
                  search: location.search,
                  isSoccer,
                },
              })
            }
          }}
        >
          Print Scoresheets
        </a>
      ),
    },
    isCanlan && {
      key: '2',
      label: (
        <a
          id='location-tutorial'
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => {
            printSignInSheetModalRef?.current?.openModal()
          }}
        >
          Print Sign-in Sheets
        </a>
      ),
    },
  ]

  const pages = [
    TOURNAMENT_READY && {
      to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/bracket`,
      text: t('common:playoff', { count: 2 }),
      component: (
        <ScheduleBracket id={SCHEDULE_ID} canUpdate={permission.update} />
      ),
    },
    {
      to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/schedule`,
      text: `${t('common:schedule')} (${item?.games_count || 0})`,
      tutorialId: 'sandbox-tabs-id-schedule',
      component:
        !loadingTeams && !hasTeams && item?.games_count < 1 ? (
          <EmptySchedule
            isPlayoff={TOURNAMENT_READY}
            canUpdate={permission.update}
            scheduleId={SCHEDULE_ID}
          />
        ) : (
          <GameList
            sportId={item.sport_id}
            schedule_id={SCHEDULE_ID}
            slug={`/schedules/${SCHEDULE_ID}/games`}
            refreshKey={refreshKey}
            setVisibleGameIds={(ids) => setVisibleGameIds(ids)}
            areFiltersHidden={areFiltersHidden}
            setFilterForExporting={setFilterForExporting}
            isSoccer={isSoccer}
            isHockey={isHockey}
            onClickFirstGame={async () => {
              try {
                driverObj?.destroy()
              } catch (e) {
                console.error(`Failed to set tutorial: ${e}`)
              }
            }}
          />
        ),
      actions: [
        filterToggleButton,
        permission.update && isProfessionalOrUltimateAccount && (
          <BaseActionButton
            key='plug'
            name='plug'
            title={'Generate JS Plugin Tag'}
            onClick={() => {
              generatePluginTagModalRef?.current?.openModal()
            }}
          />
        ),
        // <BaseActionButton
        //   key='print'
        //   disabled={visibleGameIds?.length === 0}
        //   name='print'
        //   title='Print Scoresheets'
        //   onClick={() => {
        //     if (visibleGameIds?.length > 0) {
        //       history.push({
        //         pathname: ROUTES.SCORESHEETS,
        //         state: {
        //           games: visibleGameIds,
        //           search: location.search,
        //           isSoccer,
        //           showRosters: true,
        //         },
        //       })
        //     }
        //   }}
        // />,
        // <BaseActionButton
        //   key='clipboard-user'
        //   disabled={visibleGameIds?.length === 0}
        //   name='clipboard-user'
        //   title='Print Sign-in Sheets'
        //   onClick={() => {
        //     if (visibleGameIds?.length > 0) {
        //       history.push({
        //         pathname: ROUTES.SIGNIN_REPORTS_PRINT,
        //         state: {
        //           games: visibleGameIds,
        //           search: location.search,
        //           isSoccer: false,
        //         },
        //       })
        //     }
        //   }}
        // />,
        <Dropdown
          menu={{ items }}
          placement='bottomLeft'
          trigger={['click']}
          key={'dropdown'}
        >
          <div
            css={css`
              cursor: pointer;
              border: 1px solid ${colors.DEFAULT_FLAIR};
              display: flex;
              justify-content: center;
              align-items: center;
              padding-top: 4px;
              padding-bottom: 4px;
              padding-left: 8px;
              padding-right: 8px;
              margin-left: 16px;
              border-radius: 4px;
            `}
          >
            <PrinterOutlined
              style={{
                color: colors.WHITE,
              }}
            />
          </div>
        </Dropdown>,
        permission.update && (
          <FileDownload
            key='download-comp'
            button={(loading, onClick) => {
              return (
                <BaseActionButton
                  loading={loading}
                  onClick={onClick}
                  name='download'
                  title={t('common:exportComp')}
                />
              )
            }}
            url={`/schedules/${SCHEDULE_ID}/export/csv`}
            fileName={item.name_full}
            query={filterForExporting}
          />
        ),
        permission.update && (
          <ImportCSV
            key='upload-comp'
            button={(label, onClick) => {
              return (
                <BaseActionButton
                  name='upload'
                  title={label}
                  onClick={onClick}
                />
              )
            }}
            entityType={ENTITY_TYPES.schedule}
            id={SCHEDULE_ID}
            supportsXML
            onSuccess={triggerRefresh}
          />
        ),
        permission.update && (
          <FormSheet
            key='add-game'
            Button={({ onClick }) => <NewButton onClick={onClick} />}
            form={gameForm(
              {
                rootSchedule: item,
                parentId: SCHEDULE_ID,
                onSelectIfNecessary: () =>
                  setIfNecessaryStatus(!ifNecessaryStatus),
                ifNecessaryStatus,
                organization_id: item?.organization_id,
              },
              true,
              item?.sport_id
            )}
            label={t('Web:createGame')}
            onSubmit={onAddGame}
            title={t('Web:createANewGame')}
          />
        ),
      ],
    },
    (isCanlan || features?.statistics) && {
      to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/standings`,
      text: t('common:standings'),
      tutorialId: 'sandbox-tabs-id-standings',
      component: (
        <Standings
          scheduleReprocessingListener={scheduleReprocessingListener}
          id={SCHEDULE_ID}
          defaultSort={isSoccer ? 'PTS' : 'WPCTD'}
          areFiltersHidden={areFiltersHidden}
          setIsFilterToggleDisabled={setIsFilterToggleDisabled}
          refreshKey={refreshKey}
          isSoccer={isSoccer}
          isInFrame={isInFrame}
        />
      ),
      actions: [
        permission.update && (
          <BaseActionButton
            key='plug'
            name='plug'
            title={'Generate JS Plugin Tag'}
            onClick={() => {
              generatePluginTagModalRef?.current?.openModal()
            }}
          />
        ),
        filterToggleButton,
        reprocessStatsButton,
      ],
    },
    (isCanlan || features?.statistics) && {
      to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/statistics`,
      text: t('common:statistics'),
      tutorialId: 'sandbox-tabs-id-statistics',
      component: (
        <Statistics
          scheduleReprocessingListener={scheduleReprocessingListener}
          defaultSort={isSoccer ? 'G' : 'P'}
          goalieDefaultSort={isSoccer ? 'GAA' : 'GAA'}
          entityType={ENTITY_TYPES.schedule}
          entityId={SCHEDULE_ID}
          useTeamColumn
          areFiltersHidden={areFiltersHidden}
          setIsFilterToggleDisabled={setIsFilterToggleDisabled}
          refreshKey={refreshKey}
          isSoccer={isSoccer}
          isHockey={isHockey}
          isInFrame={isInFrame}
        />
      ),
      actions: [
        permission.update && (
          <BaseActionButton
            key='plug'
            name='plug'
            title={'Generate JS Plugin Tag'}
            onClick={() => {
              generatePluginTagModalRef?.current?.openModal()
            }}
          />
        ),
        filterToggleButton,
        reprocessStatsButton,
      ],
    },
    !isInFrame &&
      (isCanlan || features?.suspensions) && {
        to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/suspensions`,
        text: t('common:suspension', { count: 2 }),
        tutorialId: 'sandbox-tabs-id-suspensions',
        component: (
          <Suspensions
            key={refreshKey}
            exportId={SCHEDULE_ID}
            exportFileName={item?.name_full}
            loading={isSuspensionsLoading}
            suspensions={suspensions}
            refresh={refresh}
            scheduleId={SCHEDULE_ID}
            hasPermission={permission.admin}
            onlyDisplayActive={!permission.admin}
          />
        ),
        actions: [
          permission.admin && (
            <AddSuspensionSheet
              key='add-suspension'
              comp={item}
              onComplete={refresh}
            />
          ),
        ],
      },

    {
      to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/locations`,
      text: t('common:location', { count: 2 }),
      component: (
        <Venues
          entityType={ENTITY_TYPES.schedule}
          id={SCHEDULE_ID}
          slug={'/venues'}
          query={{ schedule_id: SCHEDULE_ID }}
          refreshKey={refreshKey}
          triggerRefresh={triggerRefresh}
          parentPermission={permission}
        />
      ),
      actions: [
        permission.update && (
          <FormSheet
            key='add-venue'
            Button={({ onClick }) => <NewButton onClick={onClick} />}
            form={venueForm({}, SCHEDULE_ID, ENTITY_TYPES.schedule)}
            label={t('Web:createLoc')}
            onSubmit={onAddVenue}
            title={t('Web:createANewLocation')}
          />
          // <NewButton onClick={() => locationModalRef.current.openModal()} />
        ),
      ],
    },
    (isCanlan || features?.announcements) && {
      to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/feed`,
      text: t('common:feed'),
      tutorialId: 'sandbox-tabs-id-feed',
      component: (
        <Home
          isEntityFeed
          entity={item}
          entityType={ENTITY_TYPES.schedule}
          entityId={SCHEDULE_ID}
          refreshKey={refreshKey}
          hideSidebar={!permission.update}
        />
      ),
      actions: [
        permission.update && (
          <Mobile key='add-post'>
            <FormSheet
              label={t('Web:createANewPost')}
              title={t('Web:createANewPost')}
              form={feedItemForm({}, false)}
              onSubmit={async ({ imageData, ...form }) => {
                const body = JSON.stringify(form)
                const response = await req(
                  `/schedules/${SCHEDULE_ID}/social-feed`,
                  {
                    method: 'POST',
                    body,
                  }
                )
                if (imageData) {
                  await req(`/social-feed/${response.data.id}/image`, {
                    method: 'POST',
                    body: imageData,
                  })
                }
                triggerRefresh()
              }}
              Button={({ onClick }) => <NewButton onClick={onClick} />}
            />
          </Mobile>
        ),
      ],
    },
    permission.update && {
      to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/reports`,
      text: 'Reports',
      tutorialId: 'sandbox-tabs-id-reports',
      privatePage: true,
      component: (
        <NewReports
          entity={item}
          entityType={ENTITY_TYPES.schedule}
          entityId={SCHEDULE_ID}
        />
      ),
      actions: [],
    },
    !isInFrame &&
      permission.update && {
        to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/setup`,
        text: t('common:setup'),
        tutorialId: 'sandbox-tabs-id-setup',
        privatePage: true,
        component: (
          <ScheduleTree
            isSoccer={isSoccer}
            id={SCHEDULE_ID}
            orgId={item.organization.id}
            schedule={item}
            showSalesforceSettings={syncSalesforce}
            is_registration_on={item.is_registration_on}
            sportId={item.sport_id}
            genericSportId={item?.generic_sport_id}
          />
        ),
        actions: [
          permission.update && (
            <CloneSchedule
              key='clone'
              schedule={item}
              scheduleId={SCHEDULE_ID}
            />
          ),
          // item.is_tournament && !item.brackets_created && permission.update && (
          //   <CreateBrackets
          //     key='create-brackets'
          //     onCreate={async () => {
          //       await req(`/schedules/${SCHEDULE_ID}/tournaments/create`)
          //       await readItem(ENTITY_TYPES.schedule, SCHEDULE_ID)
          //       setTimeout(() => {
          //         history.push(
          //           `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/gameslots`
          //         )
          //       }, 2000)
          //     }}
          //   />
          // ),
        ],
      },
    !isInFrame &&
      TOURNAMENT_READY &&
      permission.update && {
        to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/gameslots`,
        text: t('common:gameSlot', { count: 2 }),
        tutorialId: 'sandbox-tabs-id-gameslots',
        privatePage: true,
        component: (
          <GameSlots
            id={SCHEDULE_ID}
            canSyncGameSlots={canSyncGameSlots}
            bracketsCreated={item?.brackets_created}
            readItem={readItem}
            setSelectedGameSlotDivision={setSelectedGameSlotDivision}
            setSelectedGameSlotDivisionName={setSelectedGameSlotDivisionName}
            setGamesCreated={setGamesCreated}
            setHasTournamentStarted={setHasTournamentStarted}
            setOnFinishUploadGameslots={setOnFinishUploadGameslots}
            sportId={item.sport_id}
          />
        ),
        actions: [
          permission.update && selectedGameSlotDivision && (
            <FileDownload
              key='download-comp'
              button={(loading, onClick) => {
                return (
                  <BaseActionButton
                    disabled={!gamesCreated || hasTournamentStarted}
                    loading={loading}
                    onClick={onClick}
                    name='download'
                    title={t('common:exportComp')}
                  />
                )
              }}
              url={`/schedules/${selectedGameSlotDivision}/gameslots/export/csv`}
              fileName={`${selectedGameSlotDivisionName}`}
              query={filterForExporting}
            />
          ),
          permission.update && selectedGameSlotDivision && (
            <ImportCSV
              key='upload-game-slots'
              button={(label, onClick) => {
                return (
                  <BaseActionButton
                    disabled={!gamesCreated || hasTournamentStarted}
                    name='upload'
                    title={label}
                    onClick={onClick}
                  />
                )
              }}
              entityType={ENTITY_TYPES.gameSlots}
              id={selectedGameSlotDivision}
              supportsXML
              onSuccess={() => {
                triggerRefresh()
                onFinishUploadGameslots()
              }}
              // customOnSuccessMessage={'Refreshing page in 2 seconds...'}
            />
          ),
          <BaseActionButton
            key='question'
            name='question'
            title='Help'
            onClick={async () => {
              window.open(
                'https://sportninja.zendesk.com/hc/en-us/articles/7953207325588-Playoffs-Tournaments-Set-up-Enhanced-version-',
                '_blank'
              )
            }}
          />,
        ],
      },
    !isInFrame &&
      permission.admin &&
      ENABLE_REGISTRATION &&
      features?.registration &&
      item?.organization.account_subscription_type_id >= 2 &&
      item?.is_registration_on && {
        to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/registration`,
        tutorialId: 'sandbox-tabs-id-registration',
        text: 'Registration',
        privatePage: true,
        component: (
          <Registration
            accountOwnerId={item?.owned_by_account_id}
            isAdmin={permission?.admin}
            sportId={item?.sport_id}
            key='registration'
            scheduleId={SCHEDULE_ID}
            refreshKey={refreshKey}
          />
        ),
        actions: [
          <BaseActionButton
            key='download'
            name='download'
            title='Download CSV'
            onClick={async () => {
              await onPressPrint()
            }}
          />,
          permission.admin && (
            <EditRegistration
              key='edit-registration'
              scheduleId={SCHEDULE_ID}
            />
          ),
        ],
      },
    !isInFrame &&
      permission.update && {
        to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/officials`,
        text: t('common:official', { count: 2 }),
        tutorialId: 'sandbox-tabs-id-officials',
        privatePage: true,
        component: (
          <OfficialList
            key='officials'
            entityType={ENTITY_TYPES.schedule}
            entityId={SCHEDULE_ID}
            slug={`/schedules/${SCHEDULE_ID}/officials`}
            refreshKey={refreshKey}
            isSoccer={isSoccer}
            isOrg={false}
            sportId={item.sport_id}
          />
        ),
        actions: (isCanlan || features?.rolesPermissions) && [
          <AddOfficial
            key='add-official'
            entityType={ENTITY_TYPES.schedule}
            entityId={SCHEDULE_ID}
            permission={permission}
            onComplete={triggerRefresh}
            isSoccer={isSoccer}
            sportId={item.sport_id}
          />,
        ],
      },

    !isInFrame &&
      permission.admin && {
        to: `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/users`,
        text: t('common:user', { count: 2 }),
        tutorialId: 'sandbox-tabs-id-users',
        privatePage: true,
        component: (
          <UserList
            key='users'
            entityType={ENTITY_TYPES.schedule}
            entityId={SCHEDULE_ID}
            slug={`/schedules/${SCHEDULE_ID}/permissions`}
            refreshKey={refreshKey}
          />
        ),
        actions: (isCanlan || features?.rolesPermissions) && [
          <AddUser
            key='add-user'
            entityType={ENTITY_TYPES.schedule}
            entityId={SCHEDULE_ID}
            permission={permission}
            onComplete={triggerRefresh}
          />,
        ],
      },
  ]

  let filteredPages = pages

  if (frameConfig?.pages) {
    filteredPages = pages.filter((page) => {
      return frameConfig.pages.includes(page?.text)
    })
  }

  return (
    <>
      <Page
        isTeamOrSchedule
        isSchedule
        error={error}
        loading={loading}
        baseCrumb={
          error
            ? ROUTES.SCHEDULES.ROOT
            : `${ROUTES.ORG_ROOT}/${item?.organization?.id}/schedules`
        }
        baseRoute={BASE_ROUTE}
        baseTitle={item?.organization?.name_full}
        breadcrumbs={getBreadcrumbs(ROUTES.SCHEDULE_ROOT, item, hierarchy)}
        baseActions={baseActions}
        pages={filteredPages}
        isPrivate={!IS_PUBLIC}
        title={
          item?.generic_sport_name
            ? `${item?.name_full} (${item?.generic_sport_name})`
            : item?.name_full || ''
        }
        iconName={item.is_tournament ? 'trophy' : 'calendar-alt'}
        imageId={getImageThumbnailId(item)}
        titleActions={titleActions}
        preserveQueryFor={[
          `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/bracket`,
          `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/setup`,
          `${ROUTES.SCHEDULE_ROOT}/${SCHEDULE_ID}/gameslots`,
        ]}
      />
      <NewModal ref={competitionModal} shouldCloseOnOverlayClick={true}>
        <div
          css={css`
            width: 55vw;
            background: #26303e;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            transition: all 0.1s ease-in-out;
            /* border-radius: 8px; */
            padding-top: 16px;
          `}
        >
          <NewScheduleForm
            initialState={initialState || null}
            onCancel={() => {
              setInitialState(null)
              competitionModal.current.closeModal()
            }}
            triggerRefresh={() => {
              readItem(ENTITY_TYPES.schedule, SCHEDULE_ID)
            }}
            displayCanlanForm={syncSalesforce}
          />
        </div>
      </NewModal>
      <NewModal
        ref={generatePluginTagModalRef}
        shouldCloseOnOverlayClick={false}
      >
        <GeneratePluginModal
          onPressClose={() => {
            generatePluginTagModalRef?.current?.closeModal()
          }}
          scheduleId={item?.id}
          isOrganization={false}
        />
      </NewModal>
      <NewModal
        ref={printSignInSheetModalRef}
        shouldCloseOnOverlayClick={false}
      >
        <PrintSignSheetsModal
          onPressClose={() => {
            printSignInSheetModalRef?.current?.closeModal()
          }}
          visibleGameIds={visibleGameIds}
          history={history}
        />
      </NewModal>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isInFrame: state.auth.inFrame,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createVenue: bindActionToPromise(
      dispatch,
      scheduleActions.venues.create.request
    ),
    createVenueUnderOrg: bindActionToPromise(
      dispatch,
      orgActions.venues.create.request
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule)
