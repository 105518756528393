/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import React, { useState } from 'react'
import { Alert, Empty } from 'antd'
import ReactPlayer from 'react-player'

const BambuserPlayer = ({ game, isPublicRoute }) => {
  const [error, setError] = useState(null)
  const resourceUri = game?.broadcast_video_url
  const isMobile = window.innerWidth < 768

  if (!resourceUri) {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        `}
      >
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={'No Broadcast Available'}
        />
      </div>
    )
  }

  if (error) {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        `}
      >
        <Alert
          message='Error'
          description={error || 'An error occurred'}
          type='error'
          showIcon
          css={css`
            width: 50%;
          `}
        />
      </div>
    )
  }

  return (
    <div
      css={css`
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        @media (max-width: 768px) {
          padding-bottom: 56.25%;
        }
      `}
    >
      <ReactPlayer
        url={resourceUri}
        pip={false}
        playing={false}
        controls={true}
        light={false}
        volume={0.8}
        muted={false}
        played={0}
        loaded={0}
        duration={0}
        playbackRate={1.0}
        loop={false}
        onError={(e) => {
          console.log('error', e)
          setError('An error occurred')
        }}
        width={isMobile ? 320 : 1280}
        height={isMobile ? 180 : 720}
      />
    </div>
  )
}

export default BambuserPlayer
