/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import { getErrorMessage } from '@sportninja/common/utils/utils'
import { Alert, Input } from 'antd'
import dayjs from 'dayjs'
import { useContext, useState } from 'react'
import { EventCard } from 'src/components/RegistrationEventCard'
import { EventProgress } from 'src/components/RegistrationEventProgress'
import { RegistrationContext } from '../RegistrationContext'
import {
  PageTitle,
  REGISTRATION_STEPS,
  alternateButtonStyle,
  buttonStyle,
  headingSixStyle,
  paragraphSmallStyle,
  paragraphXSmallStyle,
} from './Main'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  dateOfBirthYear: '',
  dateOfBirthMonth: '',
  dateOfBirthDay: '',
  password: '',
  confirmPassword: '',
  dateOfBirth: '',
}

export const SignUp = () => {
  const {
    setStep,
    registration,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    loginRegistration,
    isLogged,
    setBirthDate,
  } = useContext(RegistrationContext)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [dateOfBirthYear, setDateOfBirthYear] = useState('')
  const [dateOfBirthMonth, setDateOfBirthMonth] = useState('')
  const [dateOfBirthDay, setDateOfBirthDay] = useState('')
  const [verifyError, setVerifyError] = useState(false)
  const [verifySuccess, setVerifySuccess] = useState(false)
  const [fieldErrors, setFieldErrors] = useState([])
  const [customError, setCustomError] = useState(initialValues)

  if (isLogged) {
    setStep(REGISTRATION_STEPS.TEAM_REGISTER)
  }

  const verifyEmail = async () => {
    try {
      setVerifyError(false)
      setVerifySuccess(false)
      await req('/auth/exists', {
        method: 'POST',
        requiresAuth: false,
        body: JSON.stringify({
          email,
        }),
      })
      setVerifySuccess(true)
      setEmail(email)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      if (errorMessage.includes('The e-mail address has already been taken')) {
        setStep(REGISTRATION_STEPS.LOGIN)
        return
      }
      setVerifyError(errorMessage)
      setVerifySuccess(false)
    }
  }

  const signUp = async () => {
    try {
      setVerifyError(false)
      setFieldErrors([])
      setCustomError(initialValues)
      const dateOfBirth = `${dateOfBirthYear}-${dateOfBirthMonth}-${dateOfBirthDay}`
      const body = JSON.stringify({
        name_first: firstName,
        name_last: lastName,
        email: email,
        password: password,
        confirm_password: confirmPassword,
        birth_date: dateOfBirth,
        user_signup_type: 'registration',
      })
      await req('/signup', {
        method: 'POST',
        requiresAuth: false,
        body,
      })
      setBirthDate(dateOfBirth)
      await loginRegistration({ email, password })
    } catch (e) {
      if (e?.invalid_fields) {
        setCustomErrors(e.invalid_fields)
        return
      }
      const errorMessage = getErrorMessage(e)
      setVerifyError(errorMessage)
    }
  }

  const setCustomErrors = (invalid_fields) => {
    if (invalid_fields.name_first) {
      setCustomErroByField('firstName', invalid_fields.name_first)
    }
    if (invalid_fields.name_last) {
      setCustomErroByField('lastName', invalid_fields.name_last)
    }
    if (invalid_fields.email) {
      setCustomErroByField('email', invalid_fields.email)
    }
    if (invalid_fields.birth_date) {
      setCustomErroByField('dateOfBirth', invalid_fields.birth_date)
    }
    if (invalid_fields.password) {
      setCustomErroByField('password', invalid_fields.password)
    }
    if (invalid_fields.confirm_password) {
      setCustomErroByField('confirmPassword', invalid_fields.confirm_password)
    }
  }

  const setCustomErroByField = (field, message) => {
    setCustomError((prev) => ({ ...prev, [field]: message }))
    setFieldErrors((prevErrors) => [...new Set([...prevErrors, field])])
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        background-color: ${colors.REGISTRATION_SECOND_BACKGROUND};
        width: 1080px;
        padding: 0 35px 35px 35px;
        border-radius: 16px 16px 0px 0px;
        @media (max-width: 768px) {
          min-width: 0;
          width: 100%;
        }
      `}
    >
      <EventCard eventInfo={registration} />
      <EventProgress eventProgress={1} />
      {!verifySuccess ? (
        <div>
          {/* separator */}
          {/* <div
            css={css`
              width: 100%;
              height: 1px;
              background-color: ${colors.NEUTRAL_600};
              margin: 16px 0;
            `}
          /> */}
          <div
            css={css`
              display: flex;
              flex: 2;
              flex-direction: row;
              gap: 16px;
              margin-bottom: 24px;
              margin-top: 24px;
              /* on mobile, change the flex direction */
              @media (max-width: 600px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                align-self: stretch;
                gap: 16px;
              }
            `}
          >
            {/* message */}
            <div
              css={css`
                display: flex;
                flex: 1;
                flex-direction: column;
                gap: 16px;
              `}
            >
              <p
                css={css`
                  color: ${colors.WHITE};
                  font-family: 'BarlowCondensed', sans-serif;
                  font-size: 32px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 120%; /* 38.4px */
                `}
              >
                {"Let's Get Started!"}
              </p>
              <p
                css={css`
                  color: ${colors.WHITE};
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 145%;
                `}
              >
                Enter your email to access your account. New to SportNinja? No
                worries! Sign up for a free account using your email address.
              </p>
            </div>
            {/* input */}
            <div
              css={css`
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                /* on mobile, set the width to 100% */
                @media (max-width: 600px) {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  align-self: stretch;
                }
              `}
            >
              <p
                css={css`
                  margin-bottom: 8px;
                  color: ${colors.WHITE};
                  ${paragraphSmallStyle}
                `}
              >
                Email *
              </p>
              <Input
                name='email'
                placeholder='Email'
                type='email'
                autoComplete='email'
                autoCorrect='off'
                autoCapitalize='none'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={verifySuccess}
              />
            </div>
          </div>
          <div
            css={css`
              display: flex;
              justify-content: flex-end;
              align-items: center;
              align-self: stretch;
              gap: 16px;
              /* on mobile change the direction */
              @media (max-width: 600px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                align-self: stretch;
                gap: 16px;
              }
            `}
          >
            <button
              onClick={() => {
                if (!verifySuccess) {
                  setStep(REGISTRATION_STEPS.SELECT_YOUR_DIVISION)
                  return
                }
                setVerifySuccess(false)
              }}
              css={css`
                ${alternateButtonStyle}
              `}
            >
              BACK
            </button>
            <button
              onClick={async () =>
                !verifySuccess ? await verifyEmail() : await signUp()
              }
              disabled={email === ''}
              css={css`
                ${buttonStyle}
              `}
            >
              {'NEXT'}
            </button>
          </div>
        </div>
      ) : (
        <>
          <PageTitle
            title={"Let's Get Started!"}
            subtitle={
              verifySuccess
                ? "It appears that you don't have an account yet. Let's create one now."
                : 'Enter your email to access your account. New to SportNinja? No worries! Sign up for a free account using your email address.'
            }
          />
          <div
            css={css`
              width: 100%;
              margin-bottom: 16px;
            `}
          >
            <p
              css={css`
                margin-bottom: 8px;
                color: ${colors.WHITE};
                ${paragraphSmallStyle}
              `}
            >
              Email *
            </p>
            <Input
              name='email'
              placeholder='Email'
              type='email'
              autoComplete='email'
              autoCorrect='off'
              autoCapitalize='none'
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={verifySuccess}
            />
            {verifySuccess ? (
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  margin-top: 16px;
                  /* on mobile, change the orientation */
                  @media (max-width: 600px) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    align-self: stretch;
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 4px;
                    flex: 1 0 0;
                    @media (max-width: 600px) {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      width: 100%;
                      align-self: stretch;
                    }
                  `}
                >
                  <p
                    css={css`
                      ${paragraphSmallStyle}
                      color: ${colors.WHITE};
                      align-self: stretch;
                    `}
                  >
                    First Name *
                  </p>
                  <Input
                    name='firstName'
                    placeholder='John'
                    type='text'
                    autoComplete='given-name'
                    autoCorrect='off'
                    autoCapitalize='none'
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    status={
                      fieldErrors.includes('firstName') ? 'error' : undefined
                    }
                  />
                  <div
                    tabIndex={-1}
                    css={css`
                      margin-top: 8px;
                      transition: opacity 0.1s ease-in-out;
                      opacity: ${fieldErrors.includes('firstName') ? 1 : 0};
                      font-size: 12px;
                      color: ${colors.ERROR_LIGHT};
                    `}
                  >
                    <span>{customError.firstName}</span>
                  </div>
                </div>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 4px;
                    flex: 1 0 0;
                    @media (max-width: 600px) {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      width: 100%;
                      align-self: stretch;
                    }
                  `}
                >
                  <p
                    css={css`
                      ${paragraphSmallStyle}
                      color: ${colors.WHITE};
                      align-self: stretch;
                    `}
                  >
                    Last Name *
                  </p>
                  <Input
                    name='firstName'
                    placeholder='Doe'
                    type='text'
                    autoComplete='family-name'
                    autoCorrect='off'
                    autoCapitalize='none'
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    status={
                      fieldErrors.includes('lastName') ? 'error' : undefined
                    }
                  />
                  <div
                    tabIndex={-1}
                    css={css`
                      margin-top: 8px;
                      transition: opacity 0.1s ease-in-out;
                      opacity: ${fieldErrors.includes('lastName') ? 1 : 0};
                      font-size: 12px;
                      color: ${colors.ERROR_LIGHT};
                    `}
                  >
                    <span>{customError.lastName}</span>
                  </div>
                </div>
              </div>
            ) : null}
            {verifySuccess ? (
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  margin-top: 16px;
                  /* on mobile, change the orientation */
                  @media (max-width: 600px) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    align-self: stretch;
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 4px;
                    flex: 1 0 0;
                    @media (max-width: 600px) {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      width: 100%;
                      align-self: stretch;
                    }
                  `}
                >
                  <p
                    css={css`
                      ${paragraphSmallStyle}
                      color: ${colors.WHITE};
                      align-self: stretch;
                    `}
                  >
                    Password *
                  </p>
                  <Input.Password
                    name='password'
                    placeholder='Password'
                    type='password'
                    autoComplete='password'
                    autoCorrect='off'
                    autoCapitalize='none'
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    status={
                      fieldErrors.includes('password') ? 'error' : undefined
                    }
                  />
                  <div
                    tabIndex={-1}
                    css={css`
                      margin-top: 8px;
                      transition: opacity 0.1s ease-in-out;
                      opacity: ${fieldErrors.includes('password') ? 1 : 0};
                      font-size: 12px;
                      color: ${colors.ERROR_LIGHT};
                    `}
                  >
                    <span>{customError.password}</span>
                  </div>
                </div>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 4px;
                    flex: 1 0 0;
                    @media (max-width: 600px) {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      width: 100%;
                      align-self: stretch;
                    }
                  `}
                >
                  <p
                    css={css`
                      ${paragraphSmallStyle}
                      color: ${colors.WHITE};
                      align-self: stretch;
                    `}
                  >
                    Confirm Password *
                  </p>
                  <Input.Password
                    name='confirmPassword'
                    placeholder='Confirm Password'
                    type='password'
                    autoComplete='password'
                    autoCorrect='off'
                    autoCapitalize='none'
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    status={
                      fieldErrors.includes('confirmPassword')
                        ? 'error'
                        : undefined
                    }
                  />
                  <div
                    tabIndex={-1}
                    css={css`
                      margin-top: 8px;
                      transition: opacity 0.1s ease-in-out;
                      opacity: ${fieldErrors.includes('confirmPassword')
                        ? 1
                        : 0};
                      font-size: 12px;
                      color: ${colors.ERROR_LIGHT};
                    `}
                  >
                    <span>{customError.confirmPassword}</span>
                  </div>
                </div>
              </div>
            ) : null}
            {verifySuccess ? (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  flex: 1;
                  width: 100%;
                  margin-top: 8px;
                  /* if mobile, change the direction */
                  @media (max-width: 600px) {
                    display: flex;
                    flex-direction: column;
                    /* align-items: center; */
                    width: 100%;
                    align-self: stretch;
                  }
                `}
              >
                <p
                  tabIndex={-1}
                  css={css`
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 14px;
                    color: ${colors.WHITE};
                  `}
                >
                  Birth Date *
                </p>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    flex: 1;
                    height: 40px;
                    width: 50%;
                    flex-direction: row;
                    @media (max-width: 600px) {
                      display: flex;
                      flex-direction: column;
                      /* align-items: center; */
                      width: 100%;
                      align-self: stretch;
                    }
                  `}
                >
                  <Input
                    name='dateOfBirthDay'
                    placeholder='DD'
                    type='number'
                    autoComplete='bday-day'
                    autoCorrect='off'
                    autoCapitalize='none'
                    required
                    value={dateOfBirthDay}
                    onChange={(e) => setDateOfBirthDay(e.target.value)}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 2)
                    }}
                    status={
                      fieldErrors.includes('dateOfBirth') ? 'error' : undefined
                    }
                    maxLength={2}
                    min={1}
                    max={31}
                  />
                  <Input
                    name='dateOfBirthMonth'
                    placeholder='MM'
                    type='number'
                    autoComplete='bday-month'
                    autoCorrect='off'
                    autoCapitalize='none'
                    required
                    value={dateOfBirthMonth}
                    onChange={(e) => setDateOfBirthMonth(e.target.value)}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 2)
                    }}
                    status={
                      fieldErrors.includes('dateOfBirth') ? 'error' : undefined
                    }
                    maxLength={2}
                    min={1}
                    max={12}
                  />

                  <Input
                    name='dateOfBirthYear'
                    placeholder='YYYY'
                    type='number'
                    autoComplete='bday-year'
                    autoCorrect='off'
                    autoCapitalize='none'
                    required
                    value={dateOfBirthYear}
                    onChange={(e) => setDateOfBirthYear(e.target.value)}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 4)
                    }}
                    status={
                      fieldErrors.includes('dateOfBirth') ? 'error' : undefined
                    }
                    maxLength={4}
                    min={1900}
                    max={dayjs().year()}
                  />
                </div>
                <div
                  css={css`
                    transition: opacity 0.1s ease-in-out;
                    opacity: ${fieldErrors.includes('dateOfBirth') ? 1 : 0};
                    font-size: 12px;
                    color: ${colors.ERROR_LIGHT};
                  `}
                >
                  <span>{customError.dateOfBirth}</span>
                </div>
              </div>
            ) : null}
          </div>
          {verifyError ? (
            <Alert
              message='Error'
              description={verifyError}
              type='error'
              showIcon
              css={css`
                width: 100%;
                max-width: 1000px;
                margin-bottom: 16px;
                /* limit the max-width on mobile */
                @media (max-width: 600px) {
                  max-width: 100%;
                }
              `}
            />
          ) : null}
          <div
            css={css`
              display: flex;
              justify-content: flex-end;
              align-items: center;
              align-self: stretch;
              gap: 16px;
              /* on mobile change the direction */
              @media (max-width: 600px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                align-self: stretch;
                gap: 16px;
              }
            `}
          >
            <button
              onClick={() => {
                if (!verifySuccess) {
                  setStep(REGISTRATION_STEPS.SELECT_YOUR_DIVISION)
                  return
                }
                setVerifySuccess(false)
              }}
              css={css`
                ${alternateButtonStyle}
              `}
            >
              BACK
            </button>
            <button
              onClick={async () =>
                !verifySuccess ? await verifyEmail() : await signUp()
              }
              disabled={email === ''}
              css={css`
                ${buttonStyle}
              `}
            >
              {'NEXT'}
            </button>
          </div>
          {verifySuccess ? (
            <p
              css={css`
                ${paragraphXSmallStyle}
                color: ${colors.WHITE};
                align-self: stretch;
                margin-top: 16px;
              `}
            >
              By proceeding you agree to the{' '}
              <a
                css={css`
                  color: ${colors.PRIMARY};
                `}
                href='https://www.sportninja.com/privacy-policy'
                target='_blank'
                rel='noreferrer'
              >
                Terms & Conditions
              </a>{' '}
              and the{' '}
              <a
                href='https://www.sportninja.com/terms-of-use'
                target='_blank'
                rel='noreferrer'
                css={css`
                  color: ${colors.PRIMARY};
                `}
              >
                Privacy Policy
              </a>
              .
            </p>
          ) : null}
        </>
      )}
    </div>
  )
}
