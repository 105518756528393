export const GameStatus = {
  1: 'Normal',
  2: 'Home Forfeit',
  3: 'Visiting Forfeit',
  4: 'Delayed',
  5: 'Postponed',
  6: 'Suspended',
  7: 'Double Forfeit',
  8: 'Pre-Game',
  9: 'FINAL',
}
