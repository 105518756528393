/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'

import Icon from '../../Icon'

const OptionButtonIcon = ({
  name,
  faType = 'fas',
  fontSize = 18,
  ...props
}) => {
  return (
    <Icon
      css={css`
        cursor: pointer;
        &:hover {
          color: white;
        }
      `}
      faType={faType}
      fontSize={fontSize}
      name={name}
      color={colors.DEFAULT_FLAIR}
      {...props}
    />
  )
}

export default OptionButtonIcon
