/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { ModalHeader } from './UpgradeModal'
import { observer } from 'mobx-react-lite'
import { useMobxStore } from 'src/state'
import GenericTable from 'src/components/GenericTable'
import { useEffect, useMemo } from 'react'
import colors from '@sportninja/common/constants/appColors'
import Icon from 'src/components/Icon'
import { Button } from 'src/components/Button'

interface Props {
  onCloseModal: () => void
  onProfessionalClick: () => void
  onFreeClick: () => void
  onUltimateClick: () => void
}

export const ComparePlansModal = observer(
  ({
    onCloseModal,
    onFreeClick,
    onProfessionalClick,
    onUltimateClick,
  }: Props) => {
    const {
      subscription: {
        state: { plans, loading },
        getPlans,
      },
      me: {
        state: { account },
      },
    } = useMobxStore()

    useEffect(() => {
      if (plans === null) {
        getPlans()
      }
    }, [])

    const freePlan = useMemo(
      () => plans?.find((plan) => plan.name === 'free'),
      [plans]
    )
    const professionalPlan = useMemo(
      () => plans?.find((plan) => plan.name === 'professional'),
      [plans]
    )
    const ultimatePlan = useMemo(
      () => plans?.find((plan) => plan.name === 'ultimate'),
      [plans]
    )

    const currentPlan = useMemo(
      () => plans?.find((plan) => plan.id === account?.subscription_type?.id),
      [plans]
    )

    return (
      <div
        css={css`
          width: 1024px;
          height: 768px;
          padding: 16px;
          background: no-repeat fixed linear-gradient(#282e38, #181a1d);
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          max-height: 80vh;

          @media (max-width: 801px) {
            width: 700px;
          }
        `}
      >
        {loading ? (
          <div
            css={css`
              display: flex;
              flex: 1;
              justify-content: center;
              align-items: center;
            `}
          >
            <LoadingSpinner size={5} />
          </div>
        ) : (
          <>
            <ModalHeader onCloseClick={onCloseModal} title='Compare Plans' />
            <div
              css={css`
                margin-top: 16px;
                overflow-y: scroll;
                &::-webkit-scrollbar {
                  width: 0.6em;
                }

                &::-webkit-scrollbar-track {
                  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                }

                &::-webkit-scrollbar-thumb {
                  background-color: ${colors.SOFT_STEEL};
                }
              `}
            >
              <GenericTable
                headers={[
                  'Organization/League Features',
                  'Free',
                  'Pro',
                  'Ultimate',
                ]}
                headersFlexSize={[2.5, 1, 1, 1]}
                headerFlexAlignment={[
                  'flex-start',
                  'center',
                  'center',
                  'center',
                ]}
                content={() =>
                  !freePlan ? (
                    <p>An error occurred</p>
                  ) : (
                    professionalPlan?.features.map((feature, index) => (
                      <div
                        key={feature.name}
                        css={css`
                          display: flex;
                          height: 40px;
                          padding: 0px 8px;
                          align-items: center;
                          background-color: ${index % 2 === 0
                            ? colors.HEADER
                            : 'transparent'};
                        `}
                      >
                        <div
                          css={css`
                            display: flex;
                            flex: 2.5;
                            align-items: center;
                          `}
                        >
                          <p
                            css={css`
                              font-size: 14px;

                              @media (max-width: 801px) {
                                font-size: 12px;
                              }
                            `}
                          >
                            {feature.name}
                          </p>
                        </div>
                        <div
                          css={css`
                            display: flex;
                            flex: 1;
                            justify-content: center;
                          `}
                        >
                          <Icon
                            name={
                              freePlan?.features[index].has_feature
                                ? 'check-circle'
                                : 'times'
                            }
                            color={
                              freePlan?.features[index]?.has_feature
                                ? colors.SUCCESS_GREEN
                                : colors.ERROR_LIGHT
                            }
                          />
                        </div>
                        <div
                          css={css`
                            display: flex;
                            flex: 1;
                            justify-content: center;
                          `}
                        >
                          {professionalPlan?.features[index].has_feature ? (
                            professionalPlan?.features[index].rule ? (
                              <p
                                css={css`
                                  text-align: center;
                                `}
                              >
                                {professionalPlan?.features[index].rule}
                              </p>
                            ) : (
                              <Icon
                                name={'check-circle'}
                                color={colors.SUCCESS_GREEN}
                              />
                            )
                          ) : (
                            <Icon name={'times'} color={colors.ERROR_LIGHT} />
                          )}
                        </div>
                        <div
                          css={css`
                            display: flex;
                            flex: 1;
                            justify-content: center;
                          `}
                        >
                          {ultimatePlan?.features[index].has_feature ? (
                            ultimatePlan?.features[index].rule ? (
                              <p
                                css={css`
                                  text-align: center;
                                `}
                              >
                                {ultimatePlan?.features[index].rule}
                              </p>
                            ) : (
                              <Icon
                                name={'check-circle'}
                                color={colors.SUCCESS_GREEN}
                              />
                            )
                          ) : (
                            <Icon name={'times'} color={colors.ERROR_LIGHT} />
                          )}
                        </div>
                      </div>
                    ))
                  )
                }
              />
            </div>
            <div
              css={css`
                padding: 16px 0.6em 0 0;
                display: flex;
                border-top: 1px solid ${colors.SOFT_STEEL};
                align-items: center;
                margin-top: 4px;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex: 2.5;
                `}
              >
                <p
                  css={css`
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                  `}
                >
                  License Price
                </p>
              </div>
              <div
                css={css`
                  flex: 1;
                  display: flex;
                  justify-content: center;
                `}
              >
                <div
                  css={css`
                    width: 130px;
                  `}
                >
                  <Button
                    small
                    useAltFontFamily
                    onClick={onFreeClick}
                    label={currentPlan?.id === 1 ? 'Current Plan' : 'Free'}
                    labelColor={
                      currentPlan?.id === 1
                        ? colors.ATTENDANCE_GRAY
                        : colors.HEADER_TINT
                    }
                    variant={currentPlan?.id === 1 ? 'secondary' : 'primary'}
                    disabled={currentPlan?.id === 1}
                  />
                </div>
              </div>
              <div
                css={css`
                  flex: 1;
                  display: flex;
                  justify-content: center;
                `}
              >
                <div
                  css={css`
                    width: 130px;
                  `}
                >
                  <Button
                    small
                    useAltFontFamily
                    onClick={onProfessionalClick}
                    label={
                      currentPlan?.id === 2
                        ? 'Current Plan'
                        : professionalPlan?.price_monthly
                        ? `$${professionalPlan?.price_monthly} / monthly`
                        : 'Contact Us'
                    }
                    labelColor={
                      currentPlan?.id === 2
                        ? colors.ATTENDANCE_GRAY
                        : colors.HEADER_TINT
                    }
                    variant={currentPlan?.id === 2 ? 'secondary' : 'primary'}
                    disabled={currentPlan?.id === 2}
                  />
                </div>
              </div>
              <div
                css={css`
                  flex: 1;
                  display: flex;
                  justify-content: center;
                `}
              >
                <div
                  css={css`
                    width: 130px;
                  `}
                >
                  <Button
                    small
                    useAltFontFamily
                    onClick={onUltimateClick}
                    label={
                      currentPlan?.id === 3
                        ? 'Current Plan'
                        : ultimatePlan?.price_monthly
                        ? `$${ultimatePlan?.price_monthly} / monthly`
                        : 'Contact Us'
                    }
                    labelColor={
                      currentPlan?.id === 3
                        ? colors.ATTENDANCE_GRAY
                        : colors.HEADER_TINT
                    }
                    variant={currentPlan?.id === 3 ? 'secondary' : 'primary'}
                    disabled={currentPlan?.id === 3}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
)
