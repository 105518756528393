/* @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import req from '@sportninja/common/api/request'
import {
  getAllPlayerTypes,
  getHockeySport,
  getSoccerSport,
} from '@sportninja/common/selectors/types'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { t } from '@sportninja/common/i18n'
import { Select } from 'antd'

import LoadingSpinner from '../../components/LoadingSpinner'
import { Flex } from '../../components/Layout'
import { font } from '../../components/css'
import Picture from '../../components/Picture'
import List from '../../components/List'
import { media } from '../../components/Responsive'

export const skaterStats = [
  {
    bigText: 'Games Played',
    text: 'GP',
    accessor: 'GP',
    toolTip: 'Games Played',
  },
  {
    bigText: 'Goals',
    text: 'G',
    accessor: 'G',
    toolTip: 'Goals',
  },
  {
    bigText: 'Assists',
    text: 'A',
    accessor: 'A',
    toolTip: 'Assists',
  },
  {
    bigText: 'Points',
    text: 'P',
    accessor: 'P',
    toolTip: 'Points',
  },
  {
    text: 'PiM',
    accessor: 'PiM',
    toolTip: 'Penalty Minutes',
  },
  {
    text: 'PTS/G',
    accessor: 'PTS/G',
    toolTip: 'Average Points Per Game',
  },
]

export const goalieStats = [
  {
    text: 'GP',
    toolTip: 'Games Played',
  },
  {
    text: 'W',
    toolTip: 'Wins',
  },
  {
    text: 'L',
    toolTip: 'Losses',
  },
  {
    text: 'T',
    toolTip: 'Ties',
  },
  {
    text: 'OTL',
    toolTip: 'Overtime Losses',
  },
  {
    text: 'GAA',
    toolTip: 'Goals Against Average',
  },
  {
    text: 'SV%',
    toolTip: 'Save Percentage',
  },
  {
    text: 'SO',
    toolTip: 'Shutouts',
  },
  {
    text: 'Min',
    accessor: 'MP',
    toolTip: 'Minutes Played',
  },
  {
    text: 'PiM',
    toolTip: 'Penalty Minutes',
  },
  {
    text: 'SA',
    toolTip: 'Shots Against',
  },
  {
    text: 'SV',
    toolTip: 'Saves',
  },
  {
    text: 'GA',
    toolTip: 'Goals Against',
  },
]

const soccerStats = [
  {
    text: 'MP',
    toolTip: 'Matches Played',
  },
  {
    text: 'G',
    accessor: 'G',
    toolTip: 'Goals',
  },
  {
    text: 'A',
    accessor: 'A',
    toolTip: 'Assists',
  },
  {
    text: 'SV',
    toolTip: 'Saves',
  },
  {
    text: 'FC',
    toolTip: 'Fouls Committed',
  },
  {
    text: 'YC',
    toolTip: 'Yellow Cards',
  },
  {
    text: 'RC',
    toolTip: 'Red Cards',
  },
]

// this is an unnecessary function but I don't have too much time
const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

// dataList is an array
// it contains an object of objects (seasonMap)
// each object is an individual season/team pair to list in the table
const dataReducer = (dataList = []) => {
  let isSkater = false
  let isGoalie = false
  let data = []

  dataList.forEach((seasonMap) => {
    const list = Object.keys(seasonMap).filter(
      (key) => key !== 'played_as_skater' && key !== 'played_as_goalie'
    )

    if (list.length > 0) {
      list.forEach((key) => {
        return data.push(seasonMap[key])
      })
    }
  })

  const existsSkater = dataList.find((d) => d.played_as_skater)
  const existsGoalie = dataList.find((d) => d.played_as_goalie)

  return {
    data,
    isSkater: !!existsSkater,
    isGoalie: !!existsGoalie,
  }
}

export const Title = ({ children, trailing }) => {
  return (
    <div
      css={css`
        padding-bottom: 20px;
        margin-bottom: -8px;
        ${font.title}
        font-weight: 700;
        font-size: 24px;
        letter-spacing: 0.015em;
        text-transform: uppercase;
        border-bottom: 1px solid #3f4753;
        display: flex;
        justify-content: space-between;
        align-items: center;

        ${media.mobile} {
          padding-left: 12px;
        }
      `}
    >
      <div
        css={css`
          flex: 8;
        `}
      >
        {children}
      </div>
      {trailing && (
        <div
          css={css`
            flex: 4;
          `}
        >
          {trailing}
        </div>
      )}
    </div>
  )
}

// When player is supplied, this is for a player. Otherwise, it's the user profile
const StatsBySeason = ({
  playerIds,
  isPublicRoute,
  setShowStats = () => {},
  soccerSportId,
  hockeySportId,
}) => {
  const [skaterData, setSkaterData] = useState([])
  const [goalieData, setGoalieData] = useState([])
  const [soccerData, setSoccerData] = useState([])
  const [loading, setLoading] = useState(true)
  const [isSkater, setIsSkater] = useState(false)
  const [isGoalie, setIsGoalie] = useState(false)
  const [isSoccer, setIsSoccer] = useState(false)

  const [availableSports, setAvailableSports] = useState([])
  const [sportToDisplay, setSportToDisplay] = useState(null)
  const [showsSkater, setShowsSkater] = useState(false)
  const [showsGoalie, setShowsGoalie] = useState(false)

  const userHasNoStatsYet = useCallback(
    () => !isSkater && !isGoalie && !isSoccer,
    [isSkater, isGoalie, isSoccer]
  )

  useEffect(() => {
    if (!loading && userHasNoStatsYet()) {
      if (
        skaterData.length === 0 &&
        goalieData.length === 0 &&
        soccerData.length === 0
      ) {
        setShowStats(false)
      } else {
        setShowStats(true)
      }
    }
  }, [skaterData, goalieData, loading, userHasNoStatsYet])

  useEffect(() => {
    if (playerIds?.length > 0) {
      const skaterPromises = []
      const goaliePromises = []

      playerIds.forEach((playerId) => {
        skaterPromises.push(
          req(
            `${
              isPublicRoute ? '/public' : ''
            }/players/${playerId}/stats/profile`,
            {
              query: { goalie: 0 },
            }
          )
        )
        goaliePromises.push(
          req(
            `${
              isPublicRoute ? '/public' : ''
            }/players/${playerId}/stats/profile`,
            {
              query: { goalie: 1 },
            }
          )
        )
      })

      setLoading(true)
      Promise.all(skaterPromises)
        .then((results) => {
          const { data: soccerOrSkaterData } = dataReducer(results)

          const filteredSkaterData = soccerOrSkaterData.filter(
            (el) => el.sport_id === hockeySportId
          )
          const filteredSoccerData = soccerOrSkaterData.filter(
            (el) => el.sport_id === soccerSportId
          )

          setSkaterData(filteredSkaterData)
          setSoccerData(filteredSoccerData)

          if (filteredSkaterData?.length > 0) {
            setIsSkater(true)
            setShowsSkater(true)
          }

          if (filteredSoccerData?.length > 0) {
            setIsSoccer(true)
          }

          Promise.all(goaliePromises).then((goalies) => {
            const { data: goalieData } = dataReducer(goalies)
            setGoalieData(goalieData)
            if (goalieData?.length > 0) {
              setIsGoalie(true)
              !showsSkater && setShowsGoalie(true)
            }
          })
        })
        .catch((error) => {
          setLoading(false)
          console.log('error', error)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [playerIds.length])

  useEffect(() => {
    let sports = []

    if (isSkater || isGoalie) {
      sports.push('hockey')
    }

    if (isSoccer) {
      sports.push('soccer')
    }

    if (!isSkater && !isGoalie && isSoccer) {
      setSportToDisplay('soccer')
    } else if (isSkater || isGoalie) {
      setSportToDisplay('hockey')
    }

    setAvailableSports(sports)
  }, [isSkater, isGoalie, isSoccer])

  const baseHeaders = [
    {
      text: t('common:competition'),
      sizePercent: 35,
      minWidth: 180,
      Cell: ({ item, isSorted }) => {
        let to
        if (item?.season?.id) {
          to = `${ROUTES.SCHEDULE_ROOT}/${item.season.id}/statistics`
          const queries = []
          if (item?.team?.id) {
            queries.push(`team_id=${item.team.id}`)
          }
          if (item?.isGoalie) {
            queries.push('is_goalie=1')
          }
          if (queries.length > 0) {
            to += `?${queries.join('&')}`
          }
        }

        return (
          <Flex
            alignItems='center'
            as={to ? Link : undefined}
            to={to}
            css={css`
              ${isSorted &&
              `
                  color: ${colors.DEFAULT_FLAIR};
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                `}
            `}
            title={item?.season?.name_full}
          >
            {item?.season?.name_full}
          </Flex>
        )
      },
    },
    {
      text: 'Team',
      sizePercent: 35,
      minWidth: 220,
      // sort: 'team.name',
      Cell: ({ item, isSorted }) => {
        let to
        if (item?.team?.id) {
          to = `${ROUTES.TEAM_ROOT}/${item.team.id}/statistics`
          const queries = []
          if (item?.season?.id) {
            queries.push(`schedule_id=${item.season.id}`)
          }
          if (item?.isGoalie) {
            queries.push('is_goalie=1')
          }
          if (queries.length > 0) {
            to += `?${queries.join('&')}`
          }
        }
        return (
          <Flex
            alignItems='center'
            as={to ? Link : undefined}
            to={to}
            css={css`
              font-size: 14px;
              ${isSorted && `color: ${colors.DEFAULT_FLAIR};`}
            `}
            title={item?.team?.name_full}
          >
            <Picture
              square
              fit
              size='xxsmall'
              imageId={getImageThumbnailId(item?.team)}
              iconName='user-friends'
            />
            <div
              css={css`
                margin-left: 8px;

                .team-name,
                .org-name {
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                }
              `}
            >
              <div className='team-name'>{item?.team?.name_full}</div>
              <div
                className='org-name'
                css={css`
                  margin-top: 2px;
                  font-size: 13px;
                  color: lightgray;
                `}
              >
                {item?.team?.organization?.name_full}
              </div>
            </div>
          </Flex>
        )
      },
    },
  ]

  const statMapper = (stat) => {
    const accessor = stat.accessor || stat.text
    return {
      text: stat.text,
      sizePercent: 6,
      minWidth: 60,
      sort: accessor,
      fontSize: 14,
      toolTip: stat.toolTip,
      accessor: (item) => {
        return `${item.stats.find((s) => s.abbr === accessor)?.value || 0}`
      },
    }
  }

  const getStatsHeader = () => {
    if (isSoccer) {
      return soccerStats.map(statMapper)
    }

    return skaterStats.map(statMapper)
  }

  const headers = [...baseHeaders, ...getStatsHeader()]
  const goalieHeaders = [...baseHeaders, ...goalieStats.map(statMapper)]

  const renderDropdown = useCallback(() => {
    if (!sportToDisplay) {
      return null
    }

    const hockeySelectOptions = []

    if (isSkater) {
      hockeySelectOptions.push({
        label: 'Skater',
        value: 'skater',
      })
    }

    if (isGoalie) {
      hockeySelectOptions.push({
        label: 'Goalie',
        value: 'goalie',
      })
    }

    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
        `}
      >
        <Select
          css={css`
            width: 100%;
          `}
          disabled={availableSports?.length <= 1}
          value={sportToDisplay}
          onChange={(value) => setSportToDisplay(value)}
          options={availableSports?.map((sport) => ({
            label: capitalize(sport),
            value: sport,
          }))}
        />
        {sportToDisplay === 'hockey' && (
          <Select
            css={css`
              width: 100%;
            `}
            value={showsGoalie ? 'goalie' : 'skater'}
            disabled={hockeySelectOptions.length <= 1}
            onChange={(value) => {
              if (value === 'goalie') {
                setShowsSkater(false)
                setShowsGoalie(true)
              }

              if (value === 'skater') {
                setShowsSkater(true)
                setShowsGoalie(false)
              }
            }}
            defaultValue={isSkater ? 'skater' : 'goalie'}
            options={hockeySelectOptions}
          />
        )}
      </div>
    )
  }, [
    isSkater,
    isGoalie,
    isSoccer,
    availableSports,
    sportToDisplay,
    showsSkater,
    showsGoalie,
  ])

  return (
    <div
      className='stats-by-season'
      css={css`
        display: flex;
        flex-direction: column;
        min-height: 98px;
        margin-top: 24px;
        padding: 20px 16px;
        background-color: #27303e;
        border: 1px solid #3f4753;
        border-radius: 8px;

        ${media.mobile} {
          padding: 16px 0;
        }
      `}
    >
      {loading && <LoadingSpinner size={4} />}
      {/* {!loading && skaterData?.length === 0 && goalieData?.length === 0 && (
        <Flex alignItems='center' justifyContent='center'>
          There are no statistics available
        </Flex>
      )} */}
      {!loading && (isSkater || isSoccer || isGoalie) && (
        <Title trailing={renderDropdown()}>
          {sportToDisplay === 'soccer'
            ? 'Player'
            : showsGoalie
            ? 'Goalie'
            : 'Player'}{' '}
          Stats
        </Title>
      )}
      {!loading &&
        (isSoccer || userHasNoStatsYet()) &&
        sportToDisplay === 'soccer' && (
          <>
            <List
              forceMobile
              headerBorder
              headers={headers.filter((h) => typeof h !== 'undefined')}
              bgColor='#3F4753'
              listProps={{
                items: soccerData,
                getKey: (item) => `${item?.season?.id}-${item?.team?.id}`,
              }}
            />
          </>
        )}
      {!loading &&
        (isSkater || userHasNoStatsYet()) &&
        sportToDisplay === 'hockey' &&
        showsSkater && (
          <>
            <List
              forceMobile
              headerBorder
              headers={headers.filter((h) => typeof h !== 'undefined')}
              bgColor='#3F4753'
              listProps={{
                items: skaterData,
                getKey: (item) => `${item?.season?.id}-${item?.team?.id}`,
              }}
            />
          </>
        )}
      {!loading &&
        (isGoalie || userHasNoStatsYet()) &&
        sportToDisplay === 'hockey' &&
        showsGoalie && (
          <div
            css={css`
              ${skaterData?.length > 0 && 'margin-top: 24px;'}
            `}
          >
            <List
              forceMobile
              headerBorder
              headers={goalieHeaders.filter((h) => typeof h !== 'undefined')}
              bgColor='#3F4753'
              listProps={{
                items: goalieData,
                getKey: (item) => `${item?.season?.id}-${item?.team?.id}`,
              }}
            />
          </div>
        )}
    </div>
  )
}

const mapStateToProps = (state) => {
  const soccerSportId = getSoccerSport(state)?.id
  const hockeySportId = getHockeySport(state)?.id

  return {
    playerTypes: getAllPlayerTypes(state),
    soccerSportId,
    hockeySportId,
  }
}

export default connect(mapStateToProps, null)(StatsBySeason)
