/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import { useEffect, useState } from 'react'
import uniqBy from 'lodash.uniqby'

import { font, zIndex } from '../css'
import { Flex } from '../Layout'
import LoadingSpinner from '../LoadingSpinner'
import Picture from '../Picture'
import { media } from '../Responsive'
import { isCanlan } from '@sportninja/common/utils/customer-name'

dayjs.extend(isToday)

const ChatListItem = ({
  id,
  onClick,
  // imageId,
  // name,
  loggedInUserId,
  unreadMessages,
  data,
}) => {
  const [loading, setLoading] = useState(true)
  // const [message, setMessage] = useState({})

  // useEffect(() => {
  //   setLoading(true)
  //   req(`/chat/team/${id}?limit=1`).then(({ data }) => {
  //     setMessage(data[0])
  //     setLoading(false)
  //   })
  // }, [id])

  useEffect(() => {
    if (data) {
      setLoading(false)
    }
  }, [data])

  let date = ''
  if (data?.last_message?.created_at) {
    const dayjsDate = dayjs.utc(data?.last_message?.created_at).local()
    if (dayjsDate.isToday()) {
      date = dayjsDate.format('h:mm a')
    } else if (dayjsDate.year() === dayjs().year()) {
      date = dayjsDate.format('MMM D')
    } else {
      date = dayjsDate.format('MM/YYYY')
    }
  }

  if (loading) return false

  return (
    <Flex
      alignItems='center'
      onClick={onClick}
      css={css`
        margin: 4px 0;
        cursor: pointer;
        padding: 8px;
        border-radius: 8px;

        &:hover {
          background-color: rgba(255, 255, 255, 0.05);
        }

        .team-name-container {
          margin-left: 12px;
        }

        .team-name {
          ${font.body}
          font-weight: 500;
          font-size: 17px;
          color: white;
        }

        .team-message {
          margin-top: 4px;
          color: rgba(255, 255, 255, 0.5);
          word-break: break-word;

          display: -webkit-box;
          -webkit-line-clamp: 2;
          -moz-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          max-height: 28px; /* magic number for 2 lines */
        }
      `}
    >
      <Flex alignItems='center'>
        <Picture
          size='xsmall'
          square
          fit
          imageId={data?.team?.image?.full_path}
          iconName='user-friends'
        />
        <div className='team-name-container'>
          <div className='team-name'>{data?.team?.team_name}</div>
          {data?.last_message?.text && (
            <div className='team-message'>
              {loggedInUserId === data?.last_message?.user_id
                ? 'You'
                : data?.last_message?.user}
              : {data?.last_message?.text}
            </div>
          )}
        </div>
      </Flex>
      {date && (
        <div
          css={css`
            padding-left: 8px;
            color: rgba(255, 255, 255, 0.5);
            font-size: 12px;
            display: flex;
            align-items: center;
            gap: 4px;
          `}
        >
          <span>{date}</span>
          {unreadMessages > 0 && (
            <div
              css={css`
                display: flex;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: ${colors.DEFAULT_FLAIR};
              `}
            />
          )}
        </div>
      )}
    </Flex>
  )
}

const ChatList = ({
  error,
  isReady,
  isTeamOrSchedulePage,
  setIsOpen,
  onTeamClick,
  loggedInUserId,
  loadedTeams,
}) => {
  const [loading, setLoading] = useState(true)
  const [data, setChatData] = useState([])

  useEffect(() => {
    // const promises = [

    //   req('/users/me/roles').then(({ data }) => {
    //     return data
    //       .filter((d) => Object.prototype.hasOwnProperty.call(d, 'team_id'))
    //       .map((d) => {
    //         return {
    //           ...d,
    //           team_image: d.image,
    //           team_name: d.name_full,
    //         }
    //       })
    //   }),
    // ]

    // Promise.allSettled(promises).then((results) => {
    //   let teams = []
    //   results.forEach((result) => {
    //     const { status, value } = result
    //     if (status === 'fulfilled') {
    //       teams = teams.concat(value)
    //     }
    //   })

    //   // De-duplicate teams list, because it returns a team for each team roster
    //   // the user is attached to
    //   setAvailableTeams(uniqBy(teams, (t) => t.team_id))
    //   setLoading(false)
    // })

    req('/chat/teams')
      .then(({ data }) => {
        if (data.length > 0) {
          setChatData(data)
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <div
      css={css`
        position: fixed;
        top: ${isTeamOrSchedulePage ? 63 : 49}px;
        right: 88px;
        border-top-width: 0;
        border-radius: 8px;
        background-color: ${colors.HEADER};
        width: 328px;
        box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.75);
        z-index: ${zIndex.header + 1};

        margin-top: 10px;
        max-height: 60vh;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 0.8em;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: ${colors.SOFT_STEEL};
        }

        ${media.mobile} {
          top: 47px;
          right: 0px;
        }
      `}
    >
      <Flex
        column
        css={css`
          padding: 20px 8px 8px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1),
            0 -1px rgba(255, 255, 255, 0.05) inset,
            0 2px 1px -1px rgba(255, 255, 255, 0.05) inset;
        `}
      >
        <h1
          css={css`
            text-align: left;
            ${font.title}
            font-weight: bold;
            color: white;
            font-size: 24px;
            margin-left: 8px;
            margin-bottom: 12px;
          `}
        >
          Chats
        </h1>
        {error ? (
          <div
            css={css`
              padding: 20px 40px;
              text-align: center;
              color: red;
            `}
          >
            There was a problem connecting to chat
          </div>
        ) : loading || !isReady ? (
          <LoadingSpinner
            size={2}
            borderSize={2}
            css={css`
              margin-bottom: 16px;
            `}
          />
        ) : data.length === 0 ? (
          <div
            css={css`
              padding: 20px 40px;
              text-align: center;
            `}
          >
            No team chats available
          </div>
        ) : (
          data.map((messageCtx) => {
            return (
              <ChatListItem
                key={messageCtx?.team?.id}
                id={messageCtx?.team?.id}
                onClick={() => {
                  setIsOpen(false)
                  onTeamClick(messageCtx)
                }}
                data={messageCtx}
                loggedInUserId={loggedInUserId}
                unreadMessages={messageCtx?.unread_messages}
              />
            )
          })
        )}
      </Flex>
    </div>
  )
}

export default ChatList
