/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { font } from '../css'
import LoadingSpinner from '../LoadingSpinner'

const SuspensionSubmitButton = ({
  busy,
  height = '40px',
  width = '200px',
  fontSize = '18px',
  children = 'Filter',
  className,
  onClick,
  disabled,
  type,
  isSolid,
  isSubdued,
  noBorder = false,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={className}
      css={css`
        width: ${width};
        height: ${height};
        ${noBorder
          ? ''
          : `background: linear-gradient(#242831, #242831) padding-box,
          linear-gradient(
              to right,
              ${colors.WEB_INDICATOR},
              ${colors.DEFAULT_FLAIR}
            )
            border-box`};
        border-radius: 4px;
        border: 2px solid transparent;
        color: white;
        ${font.title}
        font-weight: 700;
        font-size: ${fontSize};
        line-height: 25px;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        ${!noBorder &&
        `&:hover {
          background: linear-gradient(#242831, #242831) padding-box,
            linear-gradient(
                to right,
                ${colors.WEB_INDICATOR},
                ${colors.WEB_INDICATOR}
              )
              border-box;
        }`}

        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        opacity: ${disabled ? 0.5 : 1};

        span {
          display: block;
          ${isCanlan &&
          css`
            transform: translateY(-2px);
          `}
        }

        ${isSolid &&
        css`
          span {
            text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
          }
          &,
          &:hover {
            background: ${disabled ? '#27303E' : colors.WEB_INDICATOR};
          }
        `}

        ${isSubdued &&
        css`
          border-color: white;
        `}
      `}
      type={type}
    >
      {busy ? (
        <LoadingSpinner size={1.6} borderSize={2} />
      ) : (
        <span>{children}</span>
      )}
    </button>
  )
}

export default SuspensionSubmitButton
