/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react'
import css from '@emotion/css/macro'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { zIndex } from '../css'
import { media } from '../Responsive'
import controller from './BannerController'
import { TRANSITION_DURATION_MS } from './css'

const useRemaining = (duration) => {
  const remaining = useRef(duration)
  const [start, setStart] = useState(null)
  const [isStopped, setIsStopped] = useState(false)

  return {
    isStopped,
    pause: () => {
      const now = Date.now()
      const elapsed = now - start
      remaining.current = remaining.current - elapsed
    },
    remaining,
    reset: () => {
      setStart(null)
      setIsStopped(false)
      remaining.current = duration
    },
    resume: () => setStart(Date.now()),
    start: () => setStart(Date.now()),
    stop: () => setIsStopped(true),
  }
}

const BannerToast = ({ banner }) => {
  const timeout = useRef(null)
  const duration = (banner.options && banner.options.duration) || 5000
  const { remaining, isStopped, start, stop, pause, reset, resume } =
    useRemaining(duration)

  useEffect(() => {
    start()
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => controller.remove(banner.key), duration)

    return () => {
      reset()
    }
  }, [])

  return (
    <banner.component
      idx={banner.key}
      duration={duration}
      onClose={() => controller.remove(banner.key)}
      onClick={() => {
        clearTimeout(timeout.current)
        stop()
      }}
      onMouseEnter={() => {
        if (isStopped) return

        pause()
        clearTimeout(timeout.current)
      }}
      onMouseLeave={() => {
        if (isStopped) return

        resume()
        timeout.current = setTimeout(
          () => controller.remove(banner.key),
          remaining.current
        )
      }}
    />
  )
}

// Controls the display and hide of banners which are requested to be shown by
// any component in the app.
// Mount this component where you wish for banners to be displayed (i.e. right under the main nav)
const BannerContainer = () => {
  const idx = useRef(0)
  const [banners, setBanners] = useState([])

  useEffect(() => {
    controller.set(
      (component, options) => {
        // Add our own key property to track banners as they're added
        setBanners([{ component, options, key: idx.current++ }, ...banners])
      },
      (key) => {
        const newBanners = banners.filter((banner) => banner.key !== key)
        setBanners(newBanners)
      }
    )
  }, [banners])

  return (
    <div
      css={css`
        padding-right: 16px;
        position: fixed;
        z-index: ${zIndex.header - 1};
        right: 0;

        ${media.mobile} {
          padding-left: 16px;
        }
      `}
    >
      <TransitionGroup
        css={css`
          position: relative;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        `}
      >
        {banners.map((banner) => (
          <CSSTransition
            key={banner.key}
            classNames='banner'
            timeout={TRANSITION_DURATION_MS}
            mountOnEnter
            unmountOnExit
          >
            <BannerToast key={banner.key} banner={banner} />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  )
}

export default BannerContainer
