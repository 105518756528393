/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { useTimezoneManager } from '@sportninja/common/hooks/useTimezoneManager'
import { t } from '@sportninja/common/i18n'
import { getDayjsTZDate } from '@sportninja/common/utils/utils'
import dayjs from 'dayjs'

/**
 * Returns the status of a game based on its properties.
 * @param {Object} game - The game object.
 * @param {Object} currentPeriod - The current period object.
 * @param {boolean} isSoccer - Indicates if the game is a soccer game.
 * @returns {string} The game status.
 */
const getGameStatus = (game, currentPeriod, isSoccer) => {
  if (!game || !currentPeriod) {
    return ''
  }
  if (game.shootout) {
    return 'Shootout'
  } else if (currentPeriod.isOvertime) {
    return isSoccer ? 'Extra Time' : 'Overtime'
  } else {
    return t('util:capitalize', { text: t('common:final') })
  }
}

export const getDateAndGameTime = (game) => {
  const localDate = dayjs(game.starts_at)
  const date = getDayjsTZDate(game.starts_at, game.venue?.timezone)
  const displayGameTime = date.format('z') !== localDate.format('z')
  return { localDate, date, displayGameTime }
}

export const getGameStatusLabel = (game_status_id, statusTypes) => {
  if (!statusTypes || !Array.isArray(statusTypes)) {
    return ''
  }
  const status = statusTypes.find((type) => type?.id === game_status_id)
  if (status?.name) {
    return status.name
  }
  return ''
}

export const getCurrentPeriod = (game, isSoccer = false, isHockey = false) => {
  let currentPeriod = {}
  const isGenericGame =
    game?.generic_sport_id !== null && game?.generic_sport_id !== undefined
  if (game?.current_period) {
    const periodName =
      game?.current_period?.period_type?.name ||
      game.current_period.period_type.name_full
    currentPeriod = {
      fullName: game.current_period.period_type.name_full,
      name: game.current_period.period_type.name,
      isOvertime: game?.current_period?.period_type?.is_overtime || false,
    }

    if (game.current_period.clock_time) {
      currentPeriod.clockTime = dayjs(
        game.current_period.clock_time,
        'hh:mm:ss'
      ).format('m:ss')
    }
  }
  return currentPeriod
}

const GameListRowTime = ({
  game,
  gameStatusTypes,
  isSoccer = false,
  isHockey = false,
}) => {
  const currentPeriod = getCurrentPeriod(game, isSoccer, isHockey)
  const {
    userDate,
    locationDate,
    checkLocationDateAndUserDateAreSame,
    getDateString,
  } = useTimezoneManager({
    date: game.starts_at,
    timezone: game?.venue?.timezone,
  })

  return (
    <>
      <div
        className='game-row-game-time'
        css={css`
          font-weight: 500;
          font-size: 14px;
          color: white;
          margin-top: 8px;
        `}
      >
        <p>
          {userDate && getDateString(userDate, 'MMMM dd, y - h:mm a (ZZZZ)')}
        </p>
        <p
          css={css`
            margin-top: 6px;
          `}
        >
          {locationDate &&
            !checkLocationDateAndUserDateAreSame() &&
            getDateString(
              locationDate,
              userDate.toFormat('dd') !== locationDate.toFormat('dd')
                ? 'MMM d - h:mm a ZZZZ'
                : 'h:mm a ZZZZ'
            )}
        </p>
      </div>
      {game.ended_at ? (
        <div
          className='game-row-final-text'
          css={css`
            font-weight: 500;
            font-size: 14px;
            color: ${colors.DEFAULT_FLAIR};
            text-transform: uppercase;
            margin-top: 8px;
          `}
        >
          {getGameStatus(game, currentPeriod, isSoccer)}
          {game.game_status_id > 1 &&
            ` - ${getGameStatusLabel(game.game_status_id, gameStatusTypes)}`}
          {game.stats_rolledback && (
            <span
              css={css`
                text-transform: capitalize;
              `}
            >
              {' -'} [In Review]
            </span>
          )}
        </div>
      ) : game.started_at ? (
        <div
          className='game-row-current-period-time'
          css={css`
            font-weight: 500;
            font-size: 14px;
            color: white;
            margin-top: 8px;
            text-transform: uppercase;
          `}
        >
          {currentPeriod.clockTime
            ? currentPeriod.fullName
            : currentPeriod.name}{' '}
          {currentPeriod.clockTime
            ? ` - ${currentPeriod.clockTime}`
            : t('util:capitalize', {
                text: t('GameScreen:intermission'),
              })}
        </div>
      ) : (
        false
      )}
    </>
  )
}

export default GameListRowTime
