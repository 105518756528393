// TODO: Redo this set of reducers using new action formatting
import { produce } from 'immer'
import merge from 'lodash.merge'

import { arrayToObject } from '../utils/utils'
import typeActions from '../actions/types'
import { str } from './utils'

const initialState = {
  countries: {},
  timezones: {},
  sports: {},
}

const API_TYPE_TO_TYPE_MAP = {
  assist_types: 'assists',
  game_status: 'gameStatus',
  game_type: 'gameTypes',
  offense_types: 'offenses',
  penalty_types: 'penalties',
  official_types: 'officials',
  period_types: 'periods',
  player_types: 'players',
  team_official_types: 'teamOfficials',
  goal_type: 'goalTypes',
  card_type: 'cardTypes',
  generic_sport_properties: 'genericSportProperties',
  default_schedule_settings: 'defaultScheduleSettings',
}

export default (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case str(typeActions.countries.success):
        draft.countries = arrayToObject(payload.countries)
        return

      case str(typeActions.provinces.success):
        draft.countries[payload.countryId] =
          draft.countries[payload.countryId] || {}
        draft.countries[payload.countryId].provinces = payload.provinces
        return

      case str(typeActions.timezones.success):
        draft.timezones[payload.countryId] = payload.timezones
        return

      case str(typeActions.sports.success):
        draft.sports = arrayToObject(payload.data)
        return

      case str(typeActions.typesBySport.success): {
        const { id, data } = payload
        const typesByKey = data.reduce((collector, typeList) => {
          const typeKey = Object.keys(typeList)?.[0]
          if (!typeKey) {
            return collector
          }

          const key = API_TYPE_TO_TYPE_MAP[typeKey]
          return {
            ...collector,
            [key]: typeList[typeKey],
          }
        }, {})
        typesByKey[API_TYPE_TO_TYPE_MAP.game_status] = [
          { id: 1, name: 'Normal' },
          { id: 2, name: 'Home Forfeit' },
          { id: 3, name: 'Visiting Forfeit' },
          { id: 4, name: 'Delayed' },
          { id: 5, name: 'Postponed' },
          { id: 6, name: 'Suspended' },
          { id: 7, name: 'Double Forfeit' },
          { id: 8, name: 'Pregame' },
        ]
        draft.sports[id] = merge(draft.sports[id], typesByKey)
        return
      }
      case str(typeActions.allTypes.success): {
        const { data } = payload
        data.forEach((sport) => {
          const { sport_id, data: sportData } = sport
          const typesByKey = sportData.reduce((collector, typeList) => {
            const typeKey = Object.keys(typeList)?.[0]
            if (!typeKey) {
              return collector
            }
            const key = API_TYPE_TO_TYPE_MAP[typeKey]
            return {
              ...collector,
              [key]: typeList[typeKey],
            }
          }, {})
          // Maybe we need to change this between sports
          typesByKey[API_TYPE_TO_TYPE_MAP.game_status] = [
            { id: 1, name: 'Normal' },
            { id: 2, name: 'Home Forfeit' },
            { id: 3, name: 'Visiting Forfeit' },
            { id: 4, name: 'Delayed' },
            { id: 5, name: 'Postponed' },
            { id: 6, name: 'Suspended' },
            { id: 7, name: 'Double Forfeit' },
            { id: 8, name: 'Pregame' },
          ]
          draft.sports[sport_id] = merge(draft.sports[sport_id], typesByKey)
        })
        return
      }
    }
  })
