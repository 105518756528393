export const BAR_HEIGHT = 48
export const SLOT_HEIGHT = 48
export const ROUND_LABEL_HEIGHT = 28
export const COL_WIDTH = 100

export const POOL_STATUS = {
  1: 'Not Started',
  2: 'In Progress',
  3: 'Completed',
}

export const POOL_TYPE = {
  1: 'Series',
  2: 'Round Robin',
}

export const ROUND_TYPE = {
  1: 'Normal',
  2: 'Quarterfinals',
  3: 'Semifinals',
  4: 'Finals',
}

export const TOURNAMENT_STATUS = {
  NOT_CONFIGURED: 0,
  PRE_PLACEMENT: 1,
  FULL_PLACEMENT: 2,
  IN_PROGRESS: 3,
}

export const GAME_SLOT_STATUS = {
  None: 0, // Teams slots assigned
  Ready_To_Play: 1, // Real teams assigned, game is ready to play
  Processed: 2, // Game is complete, tournament has progressed past this
}

export const TOURNAMENT_STATUS_TO_STRING = [
  'Unconfigured',
  'Pre-Placement',
  'Full Placement',
  'In Progress',
]
