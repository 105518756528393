import { str } from './utils'
import { produce } from 'immer'
import actions from '../actions/appError'

const initialState = {
  error: null,
}

export default (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case str(actions.setError.success):
        draft.error = payload.error
        break
      default:
        break
    }
  })
