import { call, put } from 'redux-saga/effects'
import req from '@sportninja/common/api/request'

import { t } from '../i18n'
import actions from '../actions/schedule'
import { createEntity } from './helpers'
import { readFactory, readWithPagesFactory } from './helpers/read'
import { updateEntityFactory } from './helpers/update'
import { deleteEntityFactory } from './helpers/delete'
import entityImageSagas from './helpers/entity-image'
import venuesSagas from './helpers/venues'
import usersSagas from './helpers/users'
import feedSagas from './helpers/feed'
import officialsSagas from './helpers/officials'
import { ENTITY_TYPES, generateSagas, wait } from './utils'

const ENTITY_TYPE = ENTITY_TYPES.schedule

export const api = {
  create: async (body, parentId) =>
    await req(`/organizations/${parentId}/schedules`, { method: 'POST', body }),

  games: {
    read: async (teamId, starts_at, ends_at, id, page, sort, order, tz) => {
      const query = { order, page, sort, exclude_cancelled_games: 1 }
      if (teamId !== 'false' && typeof teamId !== 'undefined') {
        query.team_id = teamId
      }
      if (starts_at) query.starts_after = starts_at
      if (ends_at) query.starts_before = ends_at
      if (tz) query.tz = tz
      return await req(`/schedules/${id}/games`, { query })
    },
  },

  teams: {
    create: async (id, body) =>
      await req(`/schedules/${id}/teams`, { method: 'POST', body }),
    read: async (id, page = 1, sort, direction) =>
      await req(`/schedules/${id}/teams`, { query: { page, sort, direction } }),
    delete: async (id, teamId, force) => {
      const query = {}
      if (force) query.force = 1
      return await req(`/schedules/${id}/teams/${teamId}`, {
        query,
        method: 'DELETE',
      })
    },
  },
}

const schedule = [
  [
    actions.create,
    function* ({ form }) {
      const {
        imageData,
        parent_id,
        season_currency,
        season_type,
        tax_rate,
        online_registration_open,
        ...body
      } = form

      const setup = {
        addArgs: [parent_id],
        bodyTransform: {
          name: body.name_full,
          settings: {
            season_currency,
            season_type,
            tax_rate,
            online_registration_open,
          },
        },
      }

      const response = yield call(
        createEntity,
        api.create,
        actions.create.success,
        body,
        setup,
        parent_id
      )

      if (typeof imageData !== 'undefined') {
        try {
          yield wait(actions.image.create.request, response.data.id, imageData)
        } catch (e) {
          response.error = `${t('errors:imageUploadProblem')}.`
        }
      }

      return response
    },
  ],
  [actions.read, readFactory(ENTITY_TYPE, actions.read)],
  [actions.update, updateEntityFactory(ENTITY_TYPE, actions)],
  [actions.delete, deleteEntityFactory(ENTITY_TYPE, actions.delete)],

  [
    actions.games.read,
    function* (payload) {
      const { teamId, id, page, sort, direction, starts_at, ends_at, tz } =
        payload
      const response = yield call(
        api.games.read,
        teamId,
        starts_at,
        ends_at,
        id,
        page,
        sort,
        direction,
        tz
      )

      yield put(actions.games.read.success({ id, data: response.data }))
      return response
    },
  ],
]

const teams = [
  [
    actions.teams.create,
    function* (payload) {
      const { id, form } = payload
      const body = JSON.stringify({ team_id: form.team_id })

      const response = yield call(api.teams.create, id, body)
      yield put(actions.teams.create.success({ id, data: response.data }))
      return response
    },
  ],

  [
    actions.teams.read,
    readWithPagesFactory(api.teams.read, actions.teams.read),
  ],

  [
    actions.teams.delete,
    function* (payload) {
      const { id, team_id, forceDelete } = payload

      const response = yield call(api.teams.delete, id, team_id, forceDelete)
      yield put(actions.teams.delete.success({ id, team_id }))
      return response
    },
  ],
]

export default generateSagas([
  ...schedule,
  ...teams,
  ...entityImageSagas(ENTITY_TYPE, actions.image),
  ...venuesSagas(ENTITY_TYPE, actions.venues),
  ...usersSagas(ENTITY_TYPE, actions.users),
  ...feedSagas(ENTITY_TYPE, actions.feed),
  ...officialsSagas(ENTITY_TYPE, actions.officials),
])
