/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import { useState } from 'react'
import { toArray } from '@sportninja/common/utils/utils'
import { getCardsBySportId } from '@sportninja/common/selectors/types'

import { SelectFormInput } from './Select'
import OffensePicker from './OffensePicker'
import FormInput from './Form/FormInput'
import { FormRow } from './Form/css'
import { t } from '@sportninja/common/i18n'
import { connect } from 'react-redux'

const PenaltyPicker = ({
  defaultValues,
  disabled,
  onChange,
  roster,
  isSoccer = false,
  cardsType,
  sportId,
}) => {
  const {
    amount,
    offense_type_id,
    player_id,
    served_by_player_id,
    card_type_id,
  } = defaultValues
  let players = []
  if (roster) players = toArray(roster)
  const [player, setPlayer] = useState(player_id)
  const [server, setServer] = useState(served_by_player_id)
  const [cardId, setCardId] = useState(card_type_id)

  const handleChange = (setState, event) => {
    const value = event.target.value
    setState(value)

    // Form onChange expects a synthetic-style React event, e.g. { target: { name, value } }
    onChange(event)
  }

  const playerOptions = players.map((player) => (
    <option key={player.id} value={player.id}>
      {player.number} - {player.fullName}
    </option>
  ))

  const cardOptions = cardsType.map((card) => (
    <option key={card.id} value={card.id}>
      {card.name}
    </option>
  ))

  return (
    // FormRows are display: flex so we need to add a single block-level element
    // to make these full width.
    <div
      css={css`
        width: 100%;
      `}
    >
      <FormRow column>
        <SelectFormInput
          css={css`
            padding: 0 !important;
          `}
          defaultValue={player}
          disabled={disabled}
          label={t('Web:penalizedPlayer')}
          name='player_id'
          onChange={handleChange.bind(this, setPlayer)}
          required
        >
          <option value=''>{t('Web:selectPenalizedPlayer')}:</option>
          <option disabled>-----</option>
          {playerOptions}
        </SelectFormInput>
      </FormRow>
      <FormRow column>
        <OffensePicker
          isSoccer={isSoccer}
          defaultValue={offense_type_id}
          disabled={disabled}
          name='offense_type_id'
          label={isSoccer ? 'Foul Type' : t('Web:penaltyType')}
          onChange={onChange}
          sportId={sportId}
          required
        />
      </FormRow>
      {!isSoccer ? (
        <FormRow column>
          <FormInput
            disabled={disabled}
            input={{
              defaultValue: amount,
              type: 'number',
              min: 0,
              step: 1,
              name: 'amount',
              noFlex: true,
              label: `${t('Web:lengthOfPenalty')} (Min)`,
              required: true,
            }}
            onChange={onChange}
          />
        </FormRow>
      ) : null}
      {isSoccer ? (
        <FormRow column>
          <SelectFormInput
            css={css`
              padding: 0 !important;
            `}
            defaultValue={cardId}
            disabled={disabled}
            label={'Card Type'}
            name='card_type_id'
            onChange={handleChange.bind(this, setCardId)}
          >
            <option value=''>{'Select a Card'}:</option>
            <option disabled>-----</option>
            {cardOptions}
          </SelectFormInput>
        </FormRow>
      ) : null}
      {!isSoccer ? (
        <FormRow column>
          <SelectFormInput
            css={css`
              padding: 0 !important;
            `}
            defaultValue={server}
            disabled={disabled}
            label={t('Scoring:penaltyServer')}
            name='served_by_player_id'
            onChange={handleChange.bind(this, setServer)}
            required
          >
            <option value=''>{t('Web:selectServingPlayer')}:</option>
            <option disabled>-----</option>
            {playerOptions}
          </SelectFormInput>
        </FormRow>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state, { sportId }) => {
  return {
    cardsType: getCardsBySportId(state, sportId),
  }
}

export default connect(mapStateToProps)(PenaltyPicker)
