/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useState } from 'react'
import { t } from '@sportninja/common/i18n'
import colors from '@sportninja/common/constants/appColors'

import Icon from '../Icon'
import { FormButton } from '../Form/css'
import { font } from '../css'
import { Flex } from '../Layout'
import FormInput from '../Form/FormInput'

const containsFiles = (event) => {
  let isFile = false

  if (event.dataTransfer.types) {
    for (let i = 0; i < event.dataTransfer.types.length; i++) {
      const type = event.dataTransfer.types[i]
      if (type === 'Files') {
        isFile = true
      } else {
        isFile = false
        break
      }
    }
  }

  return isFile
}

const ImportCSVContentUploadForm = ({
  onSubmit,
  loading,
  supportsXML,
  onFileSelect,
  file,
  resetFile,
  importTemplate,
  error,
  hasImportErrors,
  clearRosterBeforeImport = false,
  setClearRosterBeforeImport,
}) => {
  const [isDraggedOver, setIsDraggedOver] = useState(false)
  const isImportSchedule = importTemplate?.type === 'schedule'

  return (
    <form
      onSubmit={onSubmit}
      action='GET'
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
      `}
    >
      <Flex
        onDrop={(event) => {
          event.preventDefault()
          event.stopPropagation()
          const dt = event.dataTransfer
          const files = dt.files

          setIsDraggedOver(false)

          if (files.length === 0) return

          onFileSelect({
            target: {
              files,
            },
          })
        }}
        onDragOver={(event) => {
          event.preventDefault()
          event.stopPropagation()

          setIsDraggedOver(containsFiles(event))
        }}
        onDragLeave={(event) => {
          event.preventDefault()
          event.stopPropagation()

          setIsDraggedOver(false)
        }}
        noFlex
        column
        alignItems='center'
        justifyContent='center'
        css={css`
          height: ${hasImportErrors ? 150 : 220}px;
          border: 1px dashed ${colors.SOFT_STEEL};
          border-radius: 4px;
          margin-bottom: 16px;
          font-weight: 500;
          font-size: 18px;
          line-height: 150%;
        `}
      >
        {!error && file ? (
          <>
            <Flex
              noFlex
              alignItems='center'
              justifyContent='center'
              css={css`
                height: 84px;
                width: 84px;
                background-color: ${colors.DEFAULT_FLAIR};
                border-radius: 50%;
                margin-bottom: 8px;
              `}
            >
              <Icon name='file-csv' fontSize={36} color={colors.HEADER} />
            </Flex>
            <p>{file?.name}</p>
            <button
              type='button'
              onClick={resetFile}
              css={css`
                display: flex;
                align-items: center;
                font-size: 12px;
                color: ${colors.DEFAULT_FLAIR};
                margin-top: 12px;

                &:hover {
                  color: white;
                  i {
                    color: white;
                  }
                }
              `}
            >
              <Icon
                faType='far'
                name='times'
                fontSize={14}
                color={colors.DEFAULT_FLAIR}
                css={css`
                  margin-right: 8px;
                `}
              />
              Remove
            </button>
          </>
        ) : !error && isDraggedOver ? (
          <div
            css={css`
              pointer-events: none;
              text-align: center;
            `}
          >
            <p
              css={css`
                margin-bottom: 6px;
              `}
            >
              Drop .CSV file to import
            </p>
            <Flex noFlex column alignItems='center'>
              <Icon
                name='long-arrow-alt-down'
                fontSize={28}
                css={css`
                  @keyframes updown {
                    from {
                      transform: translateY(0);
                    }

                    to {
                      transform: translateY(10px);
                    }
                  }

                  animation-name: updown;
                  animation-duration: 0.8s;
                  animation-iteration-count: infinite;
                  animation-timing-function: ease-in-out;
                  animation-direction: alternate;
                `}
              />
              <Icon
                name='box-open'
                css={css`
                  margin-top: 10px;
                `}
                fontSize={28}
              />
            </Flex>
          </div>
        ) : (
          <>
            <p>Drag .CSV file here to import</p>
            <p>or</p>
            <label
              htmlFor='select-csv'
              css={css`
                margin-top: 8px;
                color: white;
                content: 'Choose File';
                display: inline-block;
                background: ${colors.DEFAULT_FLAIR};
                border-radius: 4px;
                outline: none;
                white-space: nowrap;
                -webkit-user-select: none;
                cursor: pointer;
                ${font.title}
                font-weight: 700;
                font-size: 18px;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                padding: 8px 84px;
              `}
            >
              Choose File
            </label>
            <input
              key={file}
              disabled={loading}
              id='select-csv'
              name='file'
              type='file'
              accept={(supportsXML ? '.xml,' : '') + '.csv'}
              onChange={onFileSelect}
              css={css`
                visibility: hidden;
                color: transparent;
              `}
            />
          </>
        )}
      </Flex>
      {/* Removing the Clear roster before import button on all screens as requested */}
      {/* {isImportSchedule ? null : (
        <FormInput
          wrapperClass={css`
            margin: 0px 0 16px;
            padding: 0 !important;
            align-items: flex-start;
          `}
          onChange={() => setClearRosterBeforeImport(!clearRosterBeforeImport)}
          input={{
            name: 'clearRosterBeforeImport',
            label: 'Clear roster before import',
            type: 'checkbox',
            checked: clearRosterBeforeImport,
          }}
        />
      )} */}
      <Flex noFlex justifyContent='space-between'>
        {importTemplate && (
          <a
            title={importTemplate.blurb}
            href={`/templates/${importTemplate.fileName}`}
            target='_blank'
            rel='noopener noreferrer'
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;

              width: 130px;
              height: 40px;

              border: 2px solid ${colors.DEFAULT_FLAIR};
              border-radius: 4px;

              &:hover {
                border-color: white;

                i {
                  color: white;
                }
              }
            `}
          >
            <Icon
              name='cloud-download'
              color={colors.DEFAULT_FLAIR}
              fontSize={18}
            />
            <span
              css={css`
                ${font.title}
                font-weight: 700;
                font-size: 18px;

                letter-spacing: 0.05em;
                text-transform: uppercase;
                margin-left: 8px;
              `}
            >
              Template
            </span>
          </a>
        )}

        <FormButton
          busy={loading}
          disabled={loading || !file}
          isSubmit
          css={css`
            width: 160px;
            height: 40px;
            background-color: ${file ? colors.DEFAULT_FLAIR : colors.HEADER};
            background-image: none;

            &:hover {
              background-image: none !important;
            }
          `}
        >
          {t('common:import')}
        </FormButton>
      </Flex>
    </form>
  )
}

export default ImportCSVContentUploadForm
