/** @jsxImportSource @emotion/react */
import { Fragment, useCallback, useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import css from '@emotion/css/macro'
import queryString from 'query-string'
import { ROUTES } from '@sportninja/common/constants/app'
import { t } from '@sportninja/common/i18n'

import AuthWrap from '../../components/AuthWrap'
import { H1, BodyText } from '../../components/AuthWrap/css'
import Form from '../../components/Form'
import { FormButton } from '../../components/Form/css'
import LoadingSpinner from '../../components/LoadingSpinner'
import ConfirmPassword from '../../components/Form/ConfirmPassword'
import AuthSecondaryLink from '../../components/AuthWrap/AuthSecondaryLink'
import { createErrorMessage } from '@sportninja/common/utils/utils'

const form = [[ConfirmPassword]]

export const getToken = (location) => {
  if (!location || !location.search) return null

  const parsed = queryString.parse(location.search)
  return parsed.token
}

const ResetPassword = ({ history, location, reset }) => {
  const [step, setStep] = useState(0)
  const [error, setError] = useState(null)

  useEffect(() => {
    const token = getToken(location)
    if (typeof token !== 'string') {
      setStep(1)
    } else {
      setStep(2)
    }
  }, [])

  const goToLogin = useCallback(() => history.push(ROUTES.LOGIN))
  const onSubmit = useCallback(async (form) => {
    setError(null)
    // first check if the passwords match
    if (form.password !== form.confirm_password) {
      setError('Passwords do not match')
      return
    }
    try {
      await reset(form.password, getToken(location))
      setStep(3)
      setTimeout(setStep, 2000, 4)
    } catch (e) {
      const errorMessage = createErrorMessage(e?.invalid_fields)
      if (errorMessage) {
        setError(errorMessage)
      } else {
        setStep(1)
      }
    }
  }, [])

  return (
    <AuthWrap title='Reset Password'>
      <H1>
        {
          [
            'Reset Password', // 0
            'Reset Password', // 1
            'Reset Password', // 2
            'Success!', // 3
            'Success!', // 4
          ][step]
        }
      </H1>
      {
        [
          <LoadingSpinner key='step-0' />,

          <BodyText key='step-1'>
            <p>The password reset link you&apos;ve clicked has expired.</p>
            <p>
              {
                'Go to the "Forgot Password" page to request a new password reset email:'
              }
            </p>
            <FormButton
              as={Link}
              to={ROUTES.FORGOT_PASSWORD}
              css={css`
                display: flex;
                width: 50%;
                margin: 32px auto 0;
              `}
            >
              Go To Forgot Password
            </FormButton>
          </BodyText>,

          <Fragment key='step-2'>
            <BodyText>
              <p>
                Choose and retype a new password, then press &apos;Reset
                Password&apos; to log in.
              </p>
            </BodyText>
            {error ? (
              <BodyText style={{ color: 'red' }}>
                <p>{error}</p>
              </BodyText>
            ) : (
              <Fragment />
            )}
            <Form
              css={css`
                flex: initial;
              `} // We don't want the form to flex in height
              form={form}
              hideCancel
              onSubmit={onSubmit}
              submitText='Reset Password'
            />
            <AuthSecondaryLink
              buttonText='Log In'
              onClick={goToLogin}
              text={t('Web:orIfYouRememberPassword')}
            />
          </Fragment>,

          <BodyText key='step-3'>
            <p>
              You&apos;ve successfully changed your password. Logging you in...
            </p>
            <LoadingSpinner />
          </BodyText>,

          <Redirect key='step-4' to={ROUTES.HOME} />,
        ][step]
      }
    </AuthWrap>
  )
}

ResetPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  reset: PropTypes.func.isRequired,
}

export default ResetPassword
