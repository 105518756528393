/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'
import { t } from '@sportninja/common/i18n'

import Picture from '../../components/Picture'
import { SectionTitle } from '../../components/Card/css'
import { connect } from 'react-redux'
import { getShootoutByGameId } from '@sportninja/common/selectors/shootout'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import shootoutActions from '@sportninja/common/actions/shootout'
import { useEffect, useMemo } from 'react'
import LoadingSpinner from '../../components/LoadingSpinner'
import Icon from '../../components/Icon'
import colors from '@sportninja/common/constants/appColors'

const Cell = styled.div`
  width: 30px;
  color: #909090;
  font-size: 12px;
  letter-spacing: 0.61px;
  text-align: center;
  margin: 0 7px 8px;
`

const ScoreCell = styled(Cell)`
  margin-bottom: 0;
  height: 44px;
  font-size: 18px;
  line-height: 44px;
  letter-spacing: 0.91px;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const remainingPeriods = [
  { id: '2', name: '2' },
  { id: '3', name: '3' },
]

const TeamRow = ({ isHome, imageId, rounds }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        margin-top: 6px;
        margin-right: auto;
      `}
    >
      <div
        css={css`
          padding-left: 4px;
          width: 68px;
        `}
      >
        <Picture
          square
          fit
          size='xsmall'
          imageId={imageId}
          iconName='user-friends'
        />
      </div>
      {rounds.map((round) => {
        const data = isHome ? round.home : round.visitor
        return (
          <ScoreCell key={round.id}>
            <Icon
              name={data?.goal ? 'check' : 'times'}
              color={data?.goal ? colors.PRIMARY : colors.FIERY_RED}
            />
          </ScoreCell>
        )
      })}
    </div>
  )
}

const GameShootout = ({ game, gameShootout, readShootout, isMobile }) => {
  useEffect(() => {
    if (game.shootout) {
      readShootout(game.id)
    }
  }, [game.shootout, game.id])

  const rounds = useMemo(() => {
    const shootout = []

    // API does not have a strong concept of "rounds" (i.e. 2 shots per round),
    // and just lists everything out in a flat list. So, go 2 x 2 and collect
    // shots into home and visitor shots per round.
    //
    // We will insert player info. that we have later, in ShootoutPanelRowButton.js
    // by passing along the `roster` variable down through components.
    for (let i = 0; i < gameShootout?.data?.length; i = i + 2) {
      const firstShot = gameShootout?.data[i]
      const secondShot = gameShootout?.data[i + 1]
      const round = { id: `${firstShot?.id}-${secondShot?.id}` }

      if (firstShot?.home) {
        round.home = firstShot
        round.visitor = secondShot
      } else {
        round.visitor = firstShot
        round.home = secondShot
      }
      shootout.push(round)
    }

    return shootout
  }, [gameShootout?.data])

  if (!game.shootout) return false

  if (!gameShootout) {
    return <LoadingSpinner />
  }

  return (
    <div
      className='game-details-score-box'
      css={css`
        ${isMobile
          ? `
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;
      `
          : `margin: 0 24px;`}
      `}
    >
      <SectionTitle
        css={css`
          position: unset;
          margin-bottom: 20px;
          ${isMobile && `padding: 0 24px;`}
        `}
      >
        Shootout
      </SectionTitle>

      <div
        css={css`
          margin: 0 auto;
          position: relative;
        `}
      >
        <div
          css={css`
            display: flex;
            padding-left: 68px;
            flex-direction: row;
          `}
        >
          {rounds.map((round, idx) => {
            const name = idx + 1
            return <Cell key={round.id}>{name}</Cell>
          })}

          {/* A fake border */}
          <div
            css={css`
              position: absolute;
              top: 18px;
              left: 0;
              right: 12px;
              height: 1px;
              background-color: #909090;
            `}
          />
        </div>

        <TeamRow isHome imageId={game.homeTeam.imageId} rounds={rounds} />
        <TeamRow imageId={game.visitingTeam.imageId} rounds={rounds} />
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    gameShootout: getShootoutByGameId(state, props.game.id),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    readShootout: bindActionToPromise(dispatch, shootoutActions.read.request),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GameShootout)
