/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types'
import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import styled from '@emotion/styled/macro'

import { Flex } from './Layout'
import { Label } from './Form/css'

export const TogglerWrap = styled.div`
  transition: opacity 0.1s ease-in-out;
  opacity: ${props => props.disabled && '0.5'};
`

const Toggler = ({ activeBtn, className, disabled, fullWidthRight, left, right, onClick, title }) => (
  <TogglerWrap className={className} disabled={disabled}>
    <Label as='h3'>{title}</Label>

    <Flex className='toggler-container' css={
      css`
        position: relative;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 2px;

        ${fullWidthRight && 'border-color: white;'}

        button {
          height: 44px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          ${fullWidthRight ?
            css`flex: 1 1 100%;`
            :
            css`flex: 1 1 50%;`
          }

          color: #727272;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 0.94px;
          text-transform: uppercase;
          transition: color 0.1s ease-in-out;

          ${disabled ? 
            css`cursor: default;`
            :
            css`
              &:hover, &.active {
                color: white;
              }
            `
          }
        }
      `}>
        {!fullWidthRight &&
          <div className='toggler-active-indicator' css={css`
            position: absolute;
            height: 46px;
            top: -1px;
            left: -1px;
            right: 50%;

            border: 1px solid white;
            border-radius: 2px;
            transition: transform 0.1s ease-in-out;
            ${activeBtn === 'right' && css`transform: translateX(100%);`}
          `} />
        }
        {!fullWidthRight &&
          <button disabled={disabled} type='button' className={activeBtn === 'left' ? 'active' : undefined} onClick={disabled ? undefined : () => onClick(true)}>
            {left}
          </button>
        }
        <button disabled={disabled} type='button' className={activeBtn === 'right' ? 'active' : undefined} onClick={disabled ? undefined : () => onClick(false)}>
          {right}
        </button>
    </Flex>
  </TogglerWrap>
)

Toggler.propTypes = {
  activeBtn: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidthRight: PropTypes.bool,
  left: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]).isRequired,
  onClick: PropTypes.func.isRequired,
  right: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]).isRequired,
  title: PropTypes.string.isRequired
}

export default Toggler
