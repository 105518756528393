/** @jsxImportSource @emotion/react */
import { CloseOutlined } from '@ant-design/icons'
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import colors from '@sportninja/common/constants/appColors'
import {
  getAllDefaultScheduleSettingsTypes,
  getAllGenericSportProperties,
  getSports,
} from '@sportninja/common/selectors/types'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { Tabs } from 'antd'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import weekday from 'dayjs/plugin/weekday'
import { connect } from 'react-redux'
import GeneralForm from './GeneralForm'
import SettingsForm from './SettingsForm'
import './styles.scss'
import { ScheduleContext, ScheduleProvider } from './ScheduleContext'
import { useEffect, useState } from 'react'
import DaysmartForm from './DaysmartForm'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const labelById = {
  0: 'General',
  1: 'Settings',
  2: 'Daysmart',
}

const closeStyle = {
  color: colors.WHITE,
  fontSize: 20,
}

const NewScheduleForm = ({
  initialState = null,
  onCancel,
  triggerRefresh,
  parentOrgId,
  displayCanlanForm = false,
  genericSports,
  sports,
  defaultScheduleSettingsForAllSports,
}) => {
  const isEdit = initialState?.name_full ? true : false
  const [activeTab, setActiveTab] = useState('0')
  const [existingScheduleSettings, setExistingScheduleSettings] = useState(null)

  useEffect(() => {
    const fetchExistingScheduleSettings = async () => {
      try {
        const { data } = await req(`/schedules/${initialState?.id}/settings`)
        setExistingScheduleSettings(data)
      } catch (e) {
        setExistingScheduleSettings(null)
      }
    }
    if (initialState?.id) {
      fetchExistingScheduleSettings()
    }
  }, [initialState?.id])

  const getFormById = (id) => {
    switch (id) {
      case '0':
        return (
          <GeneralForm
            onCancelPressed={onCancel}
            triggerRefresh={triggerRefresh}
            setTab={setActiveTab}
          />
        )
      case '1':
        return (
          <SettingsForm
            onCancelPressed={onCancel}
            triggerRefresh={triggerRefresh}
          />
        )
      case '2':
        return (
          <DaysmartForm
            onCancelPressed={onCancel}
            triggerRefresh={triggerRefresh}
          />
        )
      default:
        return null
    }
  }

  return (
    <ScheduleProvider
      id={initialState?.id}
      initialState={initialState}
      sports={sports}
      genericSports={genericSports}
      closeForm={onCancel}
      parentOrgId={parentOrgId}
      defaultScheduleSettingsForAllSports={defaultScheduleSettingsForAllSports}
      existingScheduleSettings={existingScheduleSettings}
      displayCanlanForm={displayCanlanForm}
    >
      <ScheduleContext.Consumer>
        {({ name, abbreviation, competitionDateRange, daySmartSeasonName }) => {
          const hasFilledNameAndAbbreviationAndDates =
            name &&
            abbreviation &&
            name.length > 0 &&
            abbreviation.length > 0 &&
            competitionDateRange.starts_at &&
            competitionDateRange.ends_at
          const shouldDisableSettingsTab =
            !hasFilledNameAndAbbreviationAndDates && !isEdit
          const shouldDisableDaysmartTab = !daySmartSeasonName || !isEdit

          return (
            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <div
                css={css`
                  display: flex;
                  padding: 0px 16px;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-end;
                  gap: 8px;
                  align-self: stretch;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    align-self: stretch;
                  `}
                >
                  <h1
                    css={css`
                      font-size: 32px;
                      font-family: ${isCanlan
                        ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                        : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                      font-weight: 600;
                      color: ${colors.WHITE};
                    `}
                  >
                    {isEdit
                      ? `${isCanlan ? 'Season' : 'Competition'} Details`
                      : `Create a New ${isCanlan ? 'Season' : 'Competition'}`}
                  </h1>
                  <div
                    css={css`
                      display: flex;
                      gap: 16px;
                    `}
                  >
                    <button onClick={() => onCancel()}>
                      <CloseOutlined fontSize={20} style={closeStyle} />
                    </button>
                  </div>
                </div>
              </div>
              <div
                css={css`
                  border-bottom: 1px solid ${colors.SOFT_STEEL};
                  margin-top: 16px;
                `}
              />
              <Tabs
                activeKey={activeTab}
                defaultActiveKey='0'
                onChange={(key) => setActiveTab(key)}
                tabPosition={'left'}
                css={css`
                  display: flex;
                  flex: 1;
                  height: 100%;
                `}
                items={new Array(3).fill(null).map((_, i) => {
                  const id = String(i)
                  return {
                    label: labelById[id],
                    key: id,
                    children: getFormById(id),
                    disabled:
                      // Disable the Settings tab if you are creating a new schedule and hasn't filled all the required information
                      // for the general tab yet
                      (shouldDisableSettingsTab && id === '1') ||
                      // Disable the Daysmart tab if you are creating a new schedule or if you didn't sync with Daysmart yet
                      (shouldDisableDaysmartTab && id === '2'),
                  }
                })}
              />
            </div>
          )
        }}
      </ScheduleContext.Consumer>
    </ScheduleProvider>
  )
}

const mapStateToProps = (state) => {
  return {
    genericSports: getAllGenericSportProperties(state),
    sports: Object.values(getSports(state)),
    defaultScheduleSettingsForAllSports:
      getAllDefaultScheduleSettingsTypes(state),
  }
}

const mapDispatchToProps = (_) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewScheduleForm)
