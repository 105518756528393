/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { t } from '@sportninja/common/i18n'

import { Flex } from '../Layout'
import DatePicker from '../DatePicker'
import { inputCss, Label, InputWrapper } from '../Form/css'
import { toISOString } from '../../utils/utils'

const getDurationInMinutes = (start, end) => {
  const diff = dayjs(end).diff(dayjs(start), 'minute')
  return diff
}

const DateAndDurationPicker = ({
  errors = {},
  datePlaceholder,
  disabled,
  defaultValues = {},
  onChange,
}) => {
  const firstUpdate = useRef(true)
  const { starts_at = {}, ends_at = {} } = defaultValues

  const [start, setStart] = useState(
    starts_at.value ? new Date(starts_at.value) : undefined
  )
  const [duration, setDuration] = useState(
    ends_at.value ? getDurationInMinutes(start, ends_at.value) : ''
  )

  const handleChange = (method, { target }) => method(target.value)

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    const end = dayjs(start).add(duration, 'minute')
    start &&
      onChange({
        target: {
          name: 'starts_at',
          value: toISOString(dayjs(start)),
        },
      })
    end && onChange({ target: { name: 'ends_at', value: toISOString(end) } })
  }, [start, duration])

  const sharedDurationProps = {
    disabled,
    required: ends_at.required,
    hasError: !!errors.ends_at,
  }

  return (
    <Fragment>
      <DatePicker
        datePlaceholder={datePlaceholder}
        defaultValue={start}
        disabled={disabled}
        hasError={!!errors.starts_at}
        name='starts_at'
        label={starts_at.label}
        onChange={handleChange.bind(this, setStart)}
        required={starts_at.required}
        timezone={starts_at.timezone}
      />
      <InputWrapper {...sharedDurationProps}>
        <Label {...sharedDurationProps}>
          {ends_at.label}
          {ends_at.required ? ' *' : ''}
        </Label>
        <Flex>
          <input
            css={[
              inputCss(sharedDurationProps),
              css`
                padding-right: 88px;
              `,
            ]}
            disabled={disabled}
            min='0'
            onChange={handleChange.bind(this, setDuration)}
            required={ends_at.required}
            step='1'
            style={{ width: 'calc(100% + 100px)' }}
            type='number'
            value={duration}
          />
          <div
            css={css`
              position: absolute;
              top: calc(50% + 3px);
              right: 16px;
            `}
          >
            {t('common:minutes')}
          </div>
        </Flex>
      </InputWrapper>
    </Fragment>
  )
}

DateAndDurationPicker.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  datePlaceholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  defaultValues: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
}

export default DateAndDurationPicker
