import dayjs from 'dayjs'
import merge from 'lodash.merge'

import {
  parseScoringEventClockTime,
  prepareOfflineTransformer,
  str,
} from '../utils'
import scoringTransformer from './scoring-offline-handler'

const scoringReducer =
  (actions) =>
  (draft, { type: _type, payload: _payload, meta }) => {
    const { type, payload } = prepareOfflineTransformer(
      _type,
      _payload,
      meta,
      actions,
      scoringTransformer,
      draft
    )

    switch (
      type // eslint-disable-line default-case
    ) {
      case str(actions.periods.create.success): {
        const { id, data } = payload
        const game = draft[id]
        const periodIdx = game.periods.findIndex((p) => p.id === data.id)
        if (periodIdx !== -1) {
          game.periods.splice(periodIdx, 1)
        }
        game.periods.push(data)
        parseScoringEventClockTime(draft[id], data)
        return
      }

      case str(actions.periods.update.success): {
        const { id, periodId, data } = payload
        const periodIdx = draft[id].periods.findIndex((p) => p.id === periodId)
        if (periodIdx !== -1) {
          merge(draft[id].periods[periodIdx], data)
        }

        parseScoringEventClockTime(draft[id], data)
        return
      }

      case str(actions.periods.delete.success): {
        const { id, periodId } = payload
        const periodIdx = draft[id].periods.findIndex((p) => p.id === periodId)
        if (periodIdx !== -1) {
          draft[id].periods.splice(periodIdx, 1)
        }
        return
      }

      case str(actions.assists.create.success): {
        const { id, goalId, data } = payload
        const goal = draft[id].goals.find((goal) => goal.id === goalId)
        goal.assists = goal.assists || []
        goal.assists.push(data)
        return
      }

      case str(actions.assists.update.success): {
        const { id, goalId, assistId, data } = payload
        const goal = draft[id].goals.find((goal) => goal.id === goalId)
        const assist = goal.assists.find((assist) => assist.id === assistId)
        merge(assist, data)
        return
      }

      case str(actions.assists.delete.success): {
        const { id, goalId, assistId } = payload
        const goal = draft[id].goals.find((goal) => goal.id === goalId)
        const assistIdx = goal.assists.findIndex(
          (assist) => assist.id === assistId
        )
        if (assistIdx !== -1) {
          goal.assists.splice(assistIdx, 1)
        }
        return
      }

      case str(actions.goals.create.success): {
        const { id, data } = payload
        const game = draft[id]

        const goalIdx = game.goals.findIndex((goal) =>
          dayjs(goal.occurred_at).isSame(data.shot.occurred_at)
        )
        if (goalIdx > -1) game.goals.splice(goalIdx, 1)

        const shotIndex = game.shots.findIndex((shot) =>
          dayjs(shot.occurred_at).isSame(data.shot.occurred_at)
        )
        if (shotIndex !== -1) game.shots.splice(shotIndex, 1)

        if (data?.shot?.team_id) {
          if (data?.shot?.team_id === game?.homeTeamId) {
            game.homeTeamScore++
          } else if (data?.shot?.team_id === game?.visitingTeamId) {
            game.visitingTeamScore++
          }
        }

        game.goals.push(data)
        game.shots.push(data.shot)

        parseScoringEventClockTime(game, data)

        return
      }

      case str(actions.goals.update.success): {
        const { id, goalId, data } = payload
        const goalIdx = draft[id].goals.findIndex((p) => p.id === goalId)

        if (goalIdx !== -1) {
          const goal = draft[id].goals[goalIdx]
          merge(goal, data)

          goal.assists = data.assists.map((assist) => {
            const existingAssist = goal.assists.find((a) => a.id === assist.id)
            return {
              ...existingAssist,
              ...assist,
            }
          })
        }
        return
      }

      case str(actions.goals.delete.success): {
        const { id, goalId } = payload
        const game = draft[id]
        const goalIdx = game.goals.findIndex((p) => p.id === goalId)
        if (goalIdx !== -1) {
          const goal = game.goals[goalIdx]

          game.goals.splice(goalIdx, 1)

          const shotIndex = game.shots.findIndex(
            (shot) => shot.id === goal?.shot?.id
          )
          if (shotIndex !== -1) {
            game.shots.splice(shotIndex, 1)
          }
          if (goal?.shot?.team_id) {
            if (goal?.shot?.team_id === game?.homeTeamId) {
              game.homeTeamScore--
            } else if (goal?.shot?.team_id === game?.visitingTeamId) {
              game.visitingTeamScore--
            }
          }
        }
        return
      }

      case str(actions.goalieChanges.create.success): {
        const goalieChanges = draft[payload.id].goalieChanges || []
        const goalieChangeIdx = goalieChanges.findIndex((g) =>
          dayjs(g.occurred_at).isSame(payload.data.occurred_at)
        )
        if (goalieChangeIdx > -1) goalieChanges.splice(goalieChangeIdx, 1)

        goalieChanges.push(payload.data)
        goalieChanges.sort((a, b) => {
          if (a.period_clock_time >= b.period_clock_time) return -1
          else return 1
        })

        parseScoringEventClockTime(draft[payload.id], payload.data)
        return
      }

      case str(actions.goalieChanges.update.success): {
        const { id, goalieChangeId, data } = payload
        const changeIdx = draft[id].goalieChanges.findIndex(
          (p) => p.id === goalieChangeId
        )
        if (changeIdx !== -1) {
          merge(draft[id].goalieChanges[changeIdx], data)
        }
        return
      }

      case str(actions.goalieChanges.delete.success): {
        const { id, goalieChangeId } = payload
        const changeIdx = draft[id].goalieChanges.findIndex(
          (p) => p.id === goalieChangeId
        )
        if (changeIdx !== -1) {
          draft[id].goalieChanges.splice(changeIdx, 1)
        }
        return
      }

      case str(actions.penalties.create.success): {
        const { id, data } = payload

        const game = draft[id]
        const offenseIdx = game.offenses.findIndex((o) => o.id === data.id)
        if (offenseIdx !== -1) game.offenses.splice(offenseIdx, 1)

        game.offenses.push(data)

        parseScoringEventClockTime(game, data)
        return
      }

      case str(actions.penalties.update.success): {
        const { id, penaltyId, data } = payload
        const penaltyIdx = draft[id].offenses.findIndex(
          (p) => p.id === penaltyId
        )
        if (penaltyIdx !== -1) {
          merge(draft[id].offenses[penaltyIdx], data)
        }
        return
      }

      case str(actions.penalties.delete.success): {
        const { id, penaltyId } = payload
        const game = draft[id]
        const penaltyIdx = game.offenses.findIndex((p) => p.id === penaltyId)

        if (penaltyIdx !== -1) game.offenses.splice(penaltyIdx, 1)
        return
      }

      case str(actions.shots.create.success): {
        const { id, data } = payload
        const game = draft[id]
        const shotIndex = game.shots.findIndex((shot) => shot.id === data.id)
        if (shotIndex !== -1) game.shots.splice(shotIndex, 1)
        game.shots.push(data)

        return
      }

      case str(actions.shots.delete.success): {
        const { id, shotId } = payload
        const game = draft[id]
        const shotIndex = game.shots.findIndex((shot) => shot.id === shotId)
        if (shotIndex !== -1) game.shots.splice(shotIndex, 1)
        return
      }

      case str(actions.signatures.create.success): {
        const { id, data } = payload
        const game = draft[id]
        game.signatures = game?.signatures || []
        const signatureIndex = game?.signatures?.findIndex(
          (signature) => signature.id === data.id
        )
        if (signatureIndex !== -1) {
          game?.signatures?.splice(signatureIndex, 1)
        }
        game?.signatures?.push(data)
        return
      }
    }
  }

export default scoringReducer
