import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cn from 'classnames'
import { Link, Redirect, withRouter } from 'react-router-dom'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import inviteActions from '@sportninja/common/actions/invite'
import { authActions } from '@sportninja/common/actions/auth'
import * as utils from '@sportninja/common/components/Invite/utils'
import { t } from '@sportninja/common/i18n'
import {
  ROLE_TYPE_ID_TO_STRING,
  ROUTES,
} from '@sportninja/common/constants/app'

import { UnderageInvitation } from '../UnderageInvitation'

import Helmet from '../../components/Helmet'
import LoadingSpinner from '../../components/LoadingSpinner'
import ResendVerifyEmail from '../../components/ResendVerifyEmail'
import { Desktop, Mobile } from '../../components/Responsive'
import InviteRegisterForm from './InviteRegisterForm'
import './InviteRegister.scss'
import { getAnOrA } from '.'
import req from '@sportninja/common/api/request'
import { getErrorMessage } from '@sportninja/common/utils/utils'

class InviteRegister extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      birthDate: false,
      confirmPassword: '',
      email: '',
      errors: { email: false, password: false },
      loading: { page: true, register: false },
      nameFirst: '',
      nameLast: '',
      origNameFirst: null,
      origNameLast: null,
      pageError: false,
      password: '',
      passwordsMatch: false,
      passwordRevealed: false,
      success: false,
      roleTypeId: false,
      type: '',
      inviterName: '',
      shouldPushToUnderageFlow: false,
      firstNameError: false,
      lastNameError: false,
      passwordError: false,
      dateOfBirthError: false,
      confirmPasswordError: false,
    }

    this.INVITE_ID = this.props.match.params.inviteId
  }

  async componentDidMount() {
    try {
      if (this.props.isLoggedIn) {
        this.props.history.push(`${ROUTES.INVITE_V2_ROOT}${this.INVITE_ID}`)
        return
      }
      const data = await utils.onInviteMount(
        this.INVITE_ID,
        this.props.readInvite
      )

      if (data?.show_underage_workflow) {
        this.setState({
          shouldPushToUnderageFlow: true,
          loading: { page: false },
        })

        return
      }

      const {
        invitee: { email, nameFirst, nameLast, type },
        inviter: { fullName },
        roleTypeId,
      } = utils.getInviteObject(data)
      const { birth_date } = data || false
      this.setState({
        type,
        email,
        inviterName: fullName ? fullName.replace(/$the[/s]/i, '') : '',
        origNameFirst: nameFirst,
        origNameLast: nameLast,
        nameFirst,
        nameLast,
        roleTypeId,
        birthDate: birth_date || false,
      })

      this.props.setNewUserInviteId(this.INVITE_ID)
    } catch (e) {
      console.error(e)
      let error = e
      if (error instanceof TypeError) {
        error = new Error(`${t('errors:pleaseTryRefreshingThePage')}. (E01)`)
      }

      this.setState({ pageError: error })
    } finally {
      this.setLoading('page', false)
    }
  }

  setError = (type, message) =>
    this.setState((prev) => ({
      errors: { ...prev.errors, [type]: message },
    }))

  setLoading = (type, state) =>
    this.setState((prevState) => ({
      loading: { ...prevState.loading, [type]: state },
    }))

  onChange = ({ target }) => {
    this.setError('password', false, false)

    this.setState({ [target.name]: target.value }, () => {
      this.setState({
        passwordsMatch: this.state.password === this.state.confirmPassword,
      })
    })
  }

  validateFormUsingApi = async (data) => {
    const result = await req('/signup/signup-validate-mobile', {
      method: 'POST',
      body: JSON.stringify(data),
    })
    return result
  }

  setCustomErrors = (invalid_fields) => {
    if (invalid_fields.name_first) {
      // this.setError('form', invalid_fields.name_first)
      this.setState({ firstNameError: invalid_fields.name_first })
    }
    if (invalid_fields.name_last) {
      // this.setError('form', invalid_fields.name_last)
      this.setState({ lastNameError: invalid_fields.name_last })
    }
    if (invalid_fields.birth_date) {
      // this.setError('form', invalid_fields.birth_date)
      this.setState({ dateOfBirthError: invalid_fields.birth_date })
    }
    if (invalid_fields.password) {
      // this.setError('form', invalid_fields.password)
      this.setState({ passwordError: invalid_fields.password })
    }
    if (invalid_fields.confirm_password) {
      // this.setError('form', invalid_fields.password)
      this.setState({ confirmPasswordError: invalid_fields.confirm_password })
    }
    if (invalid_fields.email) {
      // this.setError('form', invalid_fields.password)
      this.setError('form', `${invalid_fields.email}`)
    }
  }

  onSubmit = async (event) => {
    event.preventDefault()

    const { birthDate, email, nameFirst, nameLast, password } = this.state

    if (!birthDate) {
      return
    }
    if (!this.state.passwordsMatch) {
      return this.setError('password', t('errors:passwordsDoNotMatch'))
    }

    try {
      this.setLoading('register', true)
      this.setError('form', false)
      this.setError('password', false)
      this.setState({ success: false })
      this.setState({
        firstNameError: false,
        lastNameError: false,
        passwordError: false,
        dateOfBirthError: false,
        confirmPasswordError: false,
      })

      const body = {
        birth_date: birthDate?.split('T')[0],
        email,
        invitation_id: this.INVITE_ID,
        name_first: nameFirst,
        name_last: nameLast,
        password,
        confirm_password: this.state.confirmPassword,
      }

      const data_to_validate = {
        email,
        name_first: nameFirst,
        name_last: nameLast,
        password,
        confirm_password: this.state.confirmPassword,
        birth_date: birthDate?.split('T')[0],
      }

      await this.validateFormUsingApi(data_to_validate)

      const { user } = await this.props.register(body)

      if (user && typeof user.validated_at === 'string') {
        this.setState({ success: 'loading' })
        await this.props.logout()
        await this.props.login({ email, password })
        // Wait a little, so the user can actually see the success message
        setTimeout(async () => {
          this.setState({ success: 'redirect' })
        }, 2000)
      } else {
        this.setState({ success: 'verification' })
      }
    } catch (error) {
      if (error?.invalid_fields) {
        this.setCustomErrors(error.invalid_fields)
        this.setState({ success: false })
        this.setLoading('register', false)
        return
      } else {
        const errorMessage = getErrorMessage(error)
        this.setError(
          'form',
          `${t('errors:somethingWentWrongPleaseTryAgain')} ${errorMessage}`
        )
      }
    }
  }

  renderSubTitle = () => {
    const { email, pageError, success, type } = this.state
    if (pageError) {
      return false
    }

    const getSuccessMessage = () => {
      switch (success) {
        case 'loading':
        case 'redirect':
          return t('Web:inviteRegisterRedirect')
        case 'verification':
          return t('Web:inviteRegisterVerify', { email })
        default:
          return ''
      }
    }

    return (
      <h2 className='invite-sub-title'>
        {success
          ? `${t('Web:thanksForRegistering')} ${getSuccessMessage()}.`
          : // : type === 'player' ? (
            // <p className='invite-link' style={{ marginTop: '8px' }}>
            //   {t('Web:inviteRegisterPleaseNote1')},{' '}
            //   <span style={{ fontWeight: 'bold' }}>
            //     {t('Web:inviteRegisterPleaseNote2')}
            //   </span>
            //   . {t('Web:inviteRegisterPleaseNote3')}.
            // </p>
            // )
            false}
      </h2>
    )
  }

  renderSuccess = () => {
    switch (this.state.success) {
      case 'loading':
      case 'redirect':
        return (
          <>
            <div className='invite-register-success'>
              {t('Web:giveUsAMoment')}...
            </div>
            <LoadingSpinner />
            {this.state.success === 'redirect' && (
              <Redirect to={`${ROUTES.INVITE_V2_ROOT}${this.INVITE_ID}`} />
            )}
          </>
        )

      case 'verification':
        return (
          <div className='invite-register-success'>
            <p>{t('Web:pleaseClickButtonInEmail')}</p>
            <p>{t('Web:ifYouCantFindItHint')}</p>
            <ResendVerifyEmail email={this.state.email} />
          </div>
        )

      default:
        return false
    }
  }

  render() {
    const {
      confirmPassword,
      email,
      errors,
      inviterName,
      loading,
      nameFirst,
      nameLast,
      origNameFirst,
      origNameLast,
      password,
      pageError,
      passwordsMatch,
      success,
      type,
      roleTypeId,
      birthDate,
      shouldPushToUnderageFlow,
    } = this.state

    if (loading.page) {
      return <LoadingSpinner fullScreen />
    }

    if (shouldPushToUnderageFlow) {
      return (
        <UnderageInvitation
          inviteId={this.INVITE_ID}
          login={this.props.login}
          logout={this.props.logout}
        />
      )
    }

    const inviteRegisterProps = {
      confirmPassword,
      email,
      errors,
      loading,
      nameFirst,
      nameLast,
      onChange: this.onChange,
      onValidDOB: (birthDate) => this.setState({ birthDate }),
      onSubmit: this.onSubmit,
      password,
      passwordsMatch,
      birthDate,
      firstNameError: this.state.firstNameError,
      lastNameError: this.state.lastNameError,
      passwordError: this.state.passwordError,
      dateOfBirthError: this.state.dateOfBirthError,
      confirmPasswordError: this.state.confirmPasswordError,
    }

    const removeTheFromName = inviterName.replace(/$the[/s]/i, '')
    const roleName = ROLE_TYPE_ID_TO_STRING?.[roleTypeId]

    const preamble = (
      <div className='invite-copy flex flex-dir-col'>
        {!pageError && !success && (
          <h2 className='invite-sub-title' style={{ marginBottom: '12px' }}>
            <span>
              {origNameFirst} {origNameLast}{' '}
            </span>
            {t('Web:isInvitedToJoin')} {t('common:the')}{' '}
            <span>{removeTheFromName}</span>
            {type && (
              <>
                {' '}
                as {getAnOrA(type)}{' '}
                <span>{type !== 'player' && roleName ? roleName : type}</span>
              </>
            )}
            .
          </h2>
        )}
        {this.renderSubTitle()}
        {this.renderSuccess()}
        {!pageError && !success && (
          <div className='invite-register__sign-in invite-link'>
            {t('Auth:orIfYouAlreadyHaveAnAccount')}{' '}
            <Link
              className='u--underline'
              disabled={loading.register}
              to={`${ROUTES.INVITE_V2_ROOT}${this.INVITE_ID}`}
            >
              {t('Web:logInToAccept')}.
            </Link>
          </div>
        )}
      </div>
    )

    return (
      <div className='invite-v2 invite-register'>
        <Helmet title='Invitation: Register' />
        <div
          className={cn('invite-section left flex flex-dir-col', {
            'remove-border': pageError,
          })}
        >
          <div>
            <h1 className='invite-title'>
              {pageError
                ? pageError.title || t('errors:somethingWentWrong')
                : t('Web:toAcceptInvite')}
            </h1>
            <Mobile>{preamble}</Mobile>
            {pageError && (
              <h2 className='invite-error-message'>{pageError.message}</h2>
            )}
            {!pageError && !success && (
              <InviteRegisterForm {...inviteRegisterProps} />
            )}
          </div>
        </div>
        <Desktop>
          <div className='invite-section right flex flex-dir-col'>
            {preamble}
          </div>
        </Desktop>
      </div>
    )
  }
}

InviteRegister.propTypes = {
  isLoggedIn: PropTypes.bool,
  readInvite: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      inviteId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,

  newUserInviteId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    .isRequired,
  setNewUserInviteId: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    newUserInviteId: state.auth.login.newUserInviteId,
    isLoggedIn: state.auth.login.success,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    readInvite: bindActionToPromise(dispatch, inviteActions.read.request),
    login: bindActionToPromise(dispatch, authActions.login.request),
    logout: bindActionToPromise(dispatch, authActions.logout.request),
    register: bindActionToPromise(dispatch, authActions.register.request),

    setNewUserInviteId: bindActionToPromise(
      dispatch,
      authActions.setNewUserInviteId.request
    ),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InviteRegister)
)
