/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Icon from '../../../../components/Icon'
import { Flex } from '../../../../components/Layout'
import DivisionSettings from '../../DivisionSettings'
import DivisionTeams from './DivisionTeams'

import ToolbarButton from './ToolbarButton'
import DivisionToolbarItem from './ToolbarItem'
import { useI18n } from '@shopify/react-i18n'
import { NewModal } from 'src/components/NewModal'
import { ModalHeader } from 'src/pages/GeneralSettings/UpgradeModal'
import { Switch, Button, Radio, Input } from 'antd'
import { AddTeamModalContent } from './AddTeamModalContent'
import { toJS } from 'mobx'

const RegistrationDivision = ({
  division,
  sportId,
  isExpanded = true,
  showCancelledTeams = false,
  scheduleId = null,
}) => {
  const { id, name, registration, teams } = division
  const [isOpen, setIsOpen] = useState(true)
  const [teamToCancel, setTeamToCancel] = useState(null)

  useEffect(() => {
    setIsOpen(isExpanded)
  }, [isExpanded])

  const addTeamModalRef = useRef()

  const [i18n] = useI18n()
  const teamsLength = useMemo(() => {
    if (!teams) {
      return 0
    }

    return String(teams.filter((team) => team.registration_status !== 5).length)
  }, [teams?.length])

  return (
    <>
      <div
        css={css`
          /* registration?.division_age?.name is null, we should add a margin-bottom of 8px */
          margin-bottom: ${registration?.division_age?.name && !isOpen
            ? '0'
            : '6px'};
        `}
      >
        <Flex
          css={css`
            background: ${colors.HEADER};
            border-radius: 8px;
            padding: 8px;
          `}
        >
          <ToolbarButton onClick={() => setIsOpen((o) => !o)}>
            <Icon
              className={!isOpen ? 'is-closed' : ''}
              name={`angle-down`}
              color={colors.WHITE}
              fontSize={18}
            />
          </ToolbarButton>
          <div
            css={css`
              width: 180px;
              padding-right: 8px;
            `}
          >
            <h2
              css={css`
                font-weight: 700;
                font-size: 16px;
                line-height: 16px;
                text-transform: uppercase;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              `}
            >
              {name}
            </h2>
            <div
              css={css`
                font-weight: 500;
                font-size: 14px;
                line-height: 14px;
                color: ${colors.PALE_SKY};
                margin-top: 4px;
              `}
            >
              {registration?.division_age?.name || '-'}
            </div>
          </div>
          <Flex justifyContent='space-around'>
            <DivisionToolbarItem
              title='GENDER'
              subTitle={registration?.division_gender?.name}
            />
            <DivisionToolbarItem
              title='PLAYERS'
              subTitle={
                registration?.total_players
                  ? String(registration?.total_players)
                  : '0'
              }
            />
            <DivisionToolbarItem title='TEAMS' subTitle={String(teamsLength)} />
            {!registration?.single_payment && (
              <DivisionToolbarItem
                title='DEPOSIT'
                subTitle={
                  typeof registration?.deposit === 'number'
                    ? i18n.formatCurrency(registration.deposit, {
                        currency: registration?.currency?.name,
                        form: 'short',
                      })
                    : ''
                }
              />
            )}
            <DivisionToolbarItem
              title='COST'
              subTitle={
                registration?.team_cost
                  ? i18n.formatCurrency(registration.team_cost, {
                      currency: registration?.currency?.name,
                      form: 'short',
                    }) + '/Team'
                  : ''
              }
            />
            <DivisionToolbarItem
              title='TOTAL REVENUE'
              subTitle={
                registration?.total_paid !== undefined &&
                registration?.team_cost
                  ? i18n.formatCurrency(registration.total_paid, {
                      currency: registration?.currency?.name,
                      form: 'short',
                    })
                  : ''
              }
            />
          </Flex>
          <button
            disabled={!registration}
            onClick={() => addTeamModalRef.current.openModal()}
            css={css`
              margin-right: 8px;
              cursor: ${registration ? 'pointer' : 'not-allowed'};
            `}
          >
            <Icon
              name='plus-square'
              color={registration ? colors.DEFAULT_FLAIR : colors.SOFT_STEEL}
              fontSize={18}
            />
          </button>
          <DivisionSettings
            divisionId={id}
            divisionName={name}
            scheduleId={scheduleId}
          />
        </Flex>
        <div
          css={css`
            padding: 0 16px;

            .list-container {
              .list-header {
                margin-bottom: 0;
              }

              .list-inner {
                margin-bottom: 0;
                margin-top: 6px;
              }
              transition: all 0.15s ease-in-out;
              max-height: ${teams?.length * 75}px;
              overflow: hidden;
              opacity: 1;
            }

            ${!isOpen &&
            `.list-container {
                transition: all 0.15s ease-in-out;
                max-height: 0;
                opacity: 0;
                z-index: -100;
              }`}
          `}
        >
          <DivisionTeams
            teams={teams}
            divisionId={id}
            isClosed={!isOpen}
            showCancelledTeams={showCancelledTeams}
          />
        </div>
      </div>
      <NewModal ref={addTeamModalRef} shouldCloseOnOverlayClick={false}>
        <AddTeamModalContent
          division={division}
          sportId={sportId}
          onCloseClick={() => {
            addTeamModalRef.current.closeModal()
            // Removing, as requested on https://sportninja.atlassian.net/browse/SN-3787
            // window.location.reload()
          }}
          onAddTeamClick={() => {
            addTeamModalRef.current.closeModal()
            window.location.reload()
          }}
        />
      </NewModal>
    </>
  )
}

export default RegistrationDivision
