/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import noTeams from './no_teams.svg'
import colors from '@sportninja/common/constants/appColors'
import { ROUTES } from '@sportninja/common/constants/app'
import { Link } from 'react-router-dom'
import { isCanlan } from '@sportninja/common/utils/customer-name'

export const EmptySchedule = ({
  canUpdate,
  scheduleId,
  isPlayoff,
}: {
  canUpdate?: boolean
  scheduleId?: string
  isPlayoff?: boolean
}) => {
  const renderText = () => {
    if (canUpdate && !isPlayoff) {
      return (
        <>
          <p>
            Please configure Teams in the Set-up tab, to enable them for
            Scheduling
          </p>
          <p
            css={css`
              font-size: 12px;
              color: ${colors.ATTENDANCE_GRAY};
              font-family: Maison Neue, sans-serif;
            `}
          >
            (NOTE: IF USING REGISTRATION, TEAMS MUST REGISTER AND BE APPROVED)
          </p>
        </>
      )
    }

    if (isPlayoff && canUpdate) {
      return (
        <p
          css={css`
            font-family: ${isCanlan
              ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
              : 'Rift, Arial, Helvetica, sans-serif'};
          `}
        >
          PLEASE CONFIGURE YOUR TOURNAMENT IN THE GAME SLOTS TAB
        </p>
      )
    }

    return (
      <p
        css={css`
          font-family: ${isCanlan
            ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
            : 'Rift, Arial, Helvetica, sans-serif'};
        `}
      >
        There are no games yet
      </p>
    )
  }
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding-top: 10%;
        gap: 16px;
      `}
    >
      <div
        css={css`
          width: 250px;
          height: 230px;
        `}
      >
        <img
          src={noTeams}
          alt='No Teams Found'
          css={css`
            width: 100%;
            height: 100%;
          `}
        />
      </div>
      <div
        css={css`
          text-align: center;
          font-family: ${isCanlan
            ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
            : 'Rift, Arial, Helvetica, sans-serif'};
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%; /* 24px */
          letter-spacing: 0.36px;
          text-transform: uppercase;
          color: ${colors.WHITE};
        `}
      >
        {renderText()}
      </div>
      {canUpdate && (
        <div>
          <Link
            to={
              isPlayoff
                ? `${ROUTES.SCHEDULE_ROOT}/${scheduleId}/gameslots`
                : `${ROUTES.SCHEDULE_ROOT}/${scheduleId}/setup`
            }
          >
            <p
              css={css`
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                color: ${colors.DEFAULT_FLAIR};
              `}
            >
              {isPlayoff ? 'Go to Game Slots tab' : 'Go to Setup tab'}
            </p>
          </Link>
        </div>
      )}
    </div>
  )
}
