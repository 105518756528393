/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { t } from '@sportninja/common/i18n'

import Toggler from './Toggler'
import { InputWrapper } from './Form/css'

const YesNoToggler = ({
  bodyText,
  defaultValue = false,
  disabled,
  label,
  name,
  noFlex,
  onChange,
}) => {
  const [isYes, setIsYes] = useState(defaultValue)

  const handleChange = (value) => {
    setIsYes(!value)
    onChange({ target: { name, value: !value } })
  }

  return (
    <InputWrapper style={{ justifyContent: 'flex-end' }}>
      <div
        css={css`
          ${noFlex && 'width: 50%; padding-right: 8px;'}
        `}
      >
        <Toggler
          activeBtn={isYes ? 'right' : 'left'}
          disabled={disabled}
          left={t('common:no')}
          right={t('common:yes')}
          title={label}
          onClick={handleChange}
        />
      </div>
      {bodyText && <p style={{ fontSize: 13, marginTop: 16 }}>{bodyText}</p>}
    </InputWrapper>
  )
}

YesNoToggler.propTypes = {
  bodyText: PropTypes.string,
  defaultValue: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  noFlex: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  titleText: PropTypes.string,
}

export default YesNoToggler
