/** @jsxImportSource @emotion/react */
import React from 'react'
import { t } from '@sportninja/common/i18n'
import { PASSWORD_LENGTH_MIN } from '@sportninja/common/constants/app'

import LabeledInput from '../../components/LabeledInput'
import DOB from '../../components/DOB'
import PasswordReveal from '../../components/PasswordReveal'
import { Desktop } from '../../components/Responsive'
import { FormButton } from '../../components/Form/css'
import css from '@emotion/css/macro'

class InviteRegisterForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { passwordRevealed: false }
  }

  revealPassword = () => {
    this.setState((prev) => ({ passwordRevealed: !prev.passwordRevealed }))
  }

  render() {
    const {
      confirmPassword,
      errors,
      loading,
      nameFirst = '',
      nameLast = '',
      onChange,
      onValidDOB,
      onSubmit,
      password,
      passwordsMatch,
      birthDate,
      firstNameError,
      lastNameError,
      passwordError,
      dateOfBirthError,
      confirmPasswordError,
    } = this.props

    const { passwordRevealed } = this.state

    const defaultInputProps = {
      className: 'invite-input',
      disabled: loading.register,
      onChange,
      required: true,
    }

    const defaultPasswordProps = {
      autoComplete: 'new-password',
      // Need to add to the classname list
      className: `${defaultInputProps.className} invite-password`,
      // minLength: PASSWORD_LENGTH_MIN,
      type: passwordRevealed ? 'text' : 'password',
    }

    return (
      <form
        className='invite-form'
        id='invite-register'
        onSubmit={onSubmit}
        method='GET'
      >
        {errors.form && (
          <div className='is--error' style={{ marginBottom: '20px' }}>
            {errors.form}
          </div>
        )}
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            gap: 16px;
          `}
        >
          <LabeledInput
            autoComplete='first name'
            label={`${t('AccountScreen:firstName')} *`}
            name='nameFirst'
            placeholder='Pat'
            value={nameFirst}
            error={firstNameError}
            {...defaultInputProps}
          />
          <LabeledInput
            autoComplete='last name'
            label={`${t('AccountScreen:lastName')} *`}
            name='nameLast'
            placeholder='Smith'
            value={nameLast}
            error={lastNameError}
            {...defaultInputProps}
          />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            gap: 16px;
          `}
        >
          <LabeledInput
            error={errors.password || passwordError}
            label={`${t('common:password')} *`}
            name='password'
            value={password}
            {...defaultInputProps}
            {...defaultPasswordProps}
            right={
              <PasswordReveal
                onClick={this.revealPassword}
                passwordRevealed={passwordRevealed}
              />
            }
          />
          <LabeledInput
            error={errors.password || confirmPasswordError}
            label={t('AccountScreen:confirmPassword')}
            name='confirmPassword'
            value={confirmPassword}
            {...defaultInputProps}
            {...defaultPasswordProps}
            right={
              password &&
              confirmPassword && (
                <span className='icon'>
                  <i
                    className={`fas fa-${passwordsMatch ? 'check' : 'times'}`}
                  />
                </span>
              )
            }
          />
        </div>
        <DOB
          className='invite-input invite-register-dob'
          disabled={loading.register}
          onValidDOB={onValidDOB}
          title={false}
          defaultValue={birthDate || undefined}
          apiError={dateOfBirthError}
        />
        <div className='flex flex-items-center invite-register__2-up'>
          <Desktop>
            {/*
            On desktop, the button is on the left, terms on the right. On
            mobile, terms on top, button on bottom
          */}
            {(isDesktop) => {
              const terms = (
                <div className='invite-link'>
                  {t('Auth:byCreatingAnAccount')}
                  <a
                    className='u--underline'
                    href='/docs/eula.pdf'
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    {t('Auth:termsOfUse')}
                  </a>
                </div>
              )
              const button = (
                <FormButton
                  className='invite-register-btn'
                  isSubmit
                  busy={loading.register}
                  disabled={loading.register}
                  form='invite-register'
                  type='submit'
                >
                  {t('Auth:createAccount')}
                </FormButton>
              )

              return (
                <>
                  {isDesktop ? button : terms}
                  {isDesktop ? terms : button}
                </>
              )
            }}
          </Desktop>
        </div>
      </form>
    )
  }
}

export default InviteRegisterForm
