/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { Flex } from '../../../components/Layout'

import Toggle from './Toggle'
import { Spin } from 'antd'

const ToggleSection = ({
  title,
  description,
  isEnabled,
  onClick,
  loading,
  disabled,
}) => {
  return (
    <Flex
      noFlex
      alignItems='center'
      css={css`
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        padding-right: 8px;
        opacity: ${disabled ? 0.5 : 1};

        ${!disabled &&
        `
        &:hover .toggle-container {
          background-color: ${colors.ATTENDANCE_GRAY};
        }`}
      `}
      onClick={disabled ? () => {} : onClick}
    >
      <div
        css={css`
          flex: 1;
        `}
      >
        <div
          css={css`
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
          `}
        >
          {title}
        </div>
        <div
          css={css`
            font-weight: 500;
            font-size: 14px;
            line-height: 120%;
            color: ${colors.ATTENDANCE_GRAY};
            margin-top: 8px;
            padding-right: 16px;
          `}
        >
          {description}
        </div>
      </div>
      {loading ? <Spin /> : <Toggle isEnabled={isEnabled} />}
    </Flex>
  )
}

export default ToggleSection
