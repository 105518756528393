import { createSelector } from 'reselect'

import { getGames, getTeams, getAuth, getSchedules } from './base'
import { createChildListSelector } from './utils'

export const getScheduleById = (state, id) => state.schedule[id] || {}
export const makeGetSchedule = () => createSelector(getScheduleById, (schedule) => schedule)

export const makeGetRelatedSchedules = () => createChildListSelector(
  getSchedules, getAuth, 'relatedSchedules'
)

export const makeGetScheduleGames = () => {
  return createSelector(
    [ getGames, getTeams, getScheduleById ],
    (games, teams, schedule) => {
      if (!Array.isArray(schedule.games)) return false
      return schedule.games.reduce((collector, gameId) => {
        const game = games[gameId]
        if (!game) return collector

        collector.push({
          ...game,
          homeTeam: teams[game.homeTeamId],
          visitingTeam: teams[game.visitingTeamId]
        })
        return collector
      }, [])
    }
  )
}

export const makeGetScheduleTeams = () => createChildListSelector(
  getTeams, getScheduleById, 'teams'
)
