/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionToPromise } from '@sportninja/common/actions/utils'
import { t } from '@sportninja/common/i18n'
import { NavLink } from 'react-router-dom'
import dayjs from 'dayjs'
import notificationsActions from '@sportninja/common/actions/notifications'
import { notificationTypes } from '@sportninja/common/constants/notificationsTypes'
import colors from '@sportninja/common/constants/appColors'
import { getFeedPostDate } from '@sportninja/common/utils/utils'
import req from '@sportninja/common/api/request'

import LoadingSpinner from '../LoadingSpinner'
import Sheet from '../Sheet'
import { font } from '../css'
import Icon from '../Icon'
import { Flex } from '../Layout'
import { Mobile } from '../Responsive'
import { Invite, InviteWrap, Refresh } from './css'

const notificationTypesList = Object.values(notificationTypes)

const getUrl = (item = {}) => {
  switch (item.type) {
    case notificationTypes.GAME_FINISHED:
    case notificationTypes.LIVE_STREAM:
    case notificationTypes.UPCOMING_GAME:
    case notificationTypes.ATTENDANCE:
      return `/game/${item?.data?.entity_id}`
    case notificationTypes.PLAYER_SUSPENDED:
    case notificationTypes.PLAYER_SUSPENDED_COMPLETED:
      return '/profile/suspensions'
    case notificationTypes.INVITATION:
      return `/invitations/${item?.data?.invitation_id}`
    default:
      return ''
  }
}

const COLORS = {
  yes: colors.GREEN_GOBLIN,
  maybe: colors.AMERICAN_YELLOW,
  no: colors.FIERY_RED,
}

// TODO: Use the map that exists in common that Felipe made
const TYPE_TO_ATTENDANCE_STATUS = {
  yes: 0,
  maybe: 2,
  no: 1,
}

const AttendanceButton = ({ iconName, loading, onClick, currStatus, type }) => {
  const attendanceStatus = TYPE_TO_ATTENDANCE_STATUS[type]
  const isActiveStatus = currStatus === attendanceStatus
  const color = isActiveStatus ? COLORS[type] : colors.ATTENDANCE_GRAY

  const [clicked, setClicked] = useState(false)

  return (
    <button
      disabled={loading}
      type='button'
      onClick={async () => {
        if (!loading) {
          setClicked(true)
          await onClick(isActiveStatus ? null : attendanceStatus)
          setClicked(false)
        }
      }}
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 90px;
        height: 40px;
        margin-right: 8px;
        border-radius: 4px;
        border: 1px solid ${color};
        color: ${color};
        text-transform: uppercase;
        ${font.body};
        font-weight: 700;
        cursor: pointer;

        ${!isActiveStatus &&
        css`
          &:hover,
          &:hover i {
            border-color: white;
            color: white;
          }
        `}

        opacity: ${loading ? 0.5 : 1};
      `}
    >
      {clicked ? (
        <LoadingSpinner size={2} />
      ) : (
        <>
          <Icon
            name={iconName}
            color={color}
            fontSize={24}
            css={css`
              margin-right: 8px;
            `}
          />
          {type}
        </>
      )}
    </button>
  )
}

const AttendanceSelector = ({
  item: { id, data } = {},
  setNotificationAsRead,
}) => {
  const { attendance_status, entity_id, game_player_roster_uid, player_uid } =
    data || {}

  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(attendance_status)
  const [error, setError] = useState(false)

  const setAttendance = async (newStatus) => {
    setLoading(true)
    try {
      await req(
        `/games/${entity_id}/rosters/${game_player_roster_uid}/players/${player_uid}/attendance`,
        {
          method: 'PUT',
          body: JSON.stringify({
            attendance_status: newStatus,
            notification_id: id,
          }),
        }
      )
      await setNotificationAsRead(id)
      setLoading(false)
      setStatus(newStatus)
    } catch (e) {
      setError(true)
      setTimeout(() => {
        setError(false)
        setLoading(false)
      }, 3000)
    }
  }

  return (
    <Flex
      css={css`
        position: relative;
        margin: 12px 0;
      `}
    >
      <AttendanceButton
        type='yes'
        currStatus={status}
        onClick={setAttendance}
        loading={loading}
        iconName='check-circle'
      />
      <AttendanceButton
        type='maybe'
        currStatus={status}
        onClick={setAttendance}
        loading={loading}
        iconName='question-circle'
      />
      <AttendanceButton
        type='no'
        currStatus={status}
        onClick={setAttendance}
        loading={loading}
        iconName='times-circle'
      />
      <Flex
        alignItems='center'
        justifyContent='center'
        className={error ? 'error' : ''}
        css={css`
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          max-width: 286px;
          background-color: rgba(0, 0, 0, 0.8);
          color: ${colors.ERROR};
          ${font.body}
          font-size: 13px;
          line-height: 12px;
          text-align: center;
          opacity: 0;
          transition: opacity 0.1s ease-in;
          pointer-events: none;

          &.error {
            opacity: 1;
            pointer-events: all;
          }
        `}
      >
        <Icon
          name='exclamation-triangle'
          color={colors.ERROR}
          fontSize={16}
          css={css`
            transform: translateX(18px);
          `}
        />
        <p>Oops - we had trouble setting your attendance.</p>
      </Flex>
    </Flex>
  )
}

const NotificationItem = ({
  item = {},
  onClick,
  setNotificationAsRead,
  onPressDelete,
  setNotificationOpenForDelete = () => {},
  notificationOpenForDelete = null,
}) => {
  const isRecognizedType = notificationTypesList.includes(item?.type)
  const isAttendance = item?.type === notificationTypes.ATTENDANCE
  const LinkComponent = isRecognizedType ? NavLink : 'div'
  const [optionsDivVisible, setOptionsDivVisible] = useState(false)

  useEffect(() => {
    if (notificationOpenForDelete) {
      if (notificationOpenForDelete !== item?.id) {
        setOptionsDivVisible(false)
      }
    }
  }, [notificationOpenForDelete])

  return (
    <Invite>
      <div className='nav-link'>
        <LinkComponent
          to={isRecognizedType ? getUrl(item) : undefined}
          onClick={onClick}
        >
          <div className='title'>
            {item?.data?.title}
            <div className='pip-container'>
              <div className={!item?.read_at ? 'pip' : ''} />
            </div>
          </div>
          <div className='flex type' style={{ alignItems: 'center' }}>
            {item?.data.body}
          </div>
        </LinkComponent>
        {isAttendance && (
          <AttendanceSelector
            item={item}
            setNotificationAsRead={setNotificationAsRead}
          />
        )}
        <Flex>
          <div
            className='date'
            title={dayjs(item.created_at).format('h:mm a - MMM D, YYYY')}
          >
            {getFeedPostDate(item?.created_at)}
          </div>
          {/* This needs a little more work. */}
          {/* {!item?.read_at && (
            <>
              {' - '}
              <button
                css={css`
                  margin-left: 6px;
                  font-size: 12px;
                  opacity: 0.4;
                  color: white;
                  text-decoration: underline;
                `}
                type='button'
                onClick={async () => {
                  await setNotificationAsRead(item.id)
                }}
              >
                Mark as read
              </button>
            </>
          )} */}
        </Flex>
      </div>
      <div style={{ position: 'relative', marginLeft: 'auto' }}>
        <button
          id='notification-options'
          css={css`
            margin-left: auto;
          `}
          type='button'
          onClick={() => {
            setOptionsDivVisible(!optionsDivVisible)
            setNotificationOpenForDelete(item.id)
          }}
        >
          <Icon name='ellipsis-v' fontSize={16} />
        </button>
        {optionsDivVisible && (
          <div
            css={css`
              position: absolute;
              right: 100%; // position to the left of the button
              background-color: ${colors.HEADER};
              display: flex;
              flex-direction: row;
              gap: 8px;
              padding: 12px;
              border-radius: 8px;
              box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15);
              cursor: pointer;
              justify-content: center;
              align-items: center;
            `}
            onClick={async () => {
              setOptionsDivVisible(false)
              await onPressDelete()
            }}
          >
            <Icon name='trash' fontSize={16} />
            <span
              css={css`
                font-size: 14px;
                color: white;
              `}
            >
              Delete
            </span>
          </div>
        )}
      </div>
    </Invite>
  )
}

const sortByDate = (a, b) => {
  const aDate = dayjs(a.created_at)
  const bDate = dayjs(b.created_at)

  if (aDate.isAfter(bDate)) return -1
  if (aDate.isBefore(bDate)) return 1
  return 0
}

// A separate component, so that we can properly manage lifecycle events for
// requesting invitations (i.e. on mount, and when isOpen === true)
let Notifications = ({
  toggle,
  setNotificationAsRead,
  setAllAsRead,
  refreshNotificationCount,
  isOpen,
}) => {
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState(null)
  const [notifications, setNotifications] = useState([])
  const [notificationLoading, setNotificationLoading] = useState(false)
  const [notificationError, setNotificationsError] = useState(null)
  const [notificationOpenForDelete, setNotificationOpenForDelete] =
    useState(null)

  useEffect(() => {
    if (isOpen === false) {
      refreshNotificationCount()
    }
  }, [isOpen])

  const readNotifications = useCallback(
    async (nextPage) => {
      try {
        setNotificationLoading(true)
        setNotificationsError(null)
        const { data, meta } = await req(
          `/notifications?page=${nextPage || page}`
        )
        if (data && data.length > 0) {
          setNotifications(data)
          setPagination(meta?.pagination)
        }
      } catch (e) {
        console.error(e)
        setNotificationsError(e?.message || 'Error loading notifications')
      } finally {
        setNotificationLoading(false)
      }
    },
    [page]
  )

  const readMoreNotifications = useCallback(
    async (nextPage, refresh = false) => {
      try {
        setNotificationsError(null)
        const { data, meta } = await req(
          `/notifications?page=${nextPage || page}`
        )
        if (data && data.length > 0) {
          if (refresh) {
            setNotifications(data)
          } else {
            setNotifications((prev) => [...prev, ...data])
          }
          setPagination(meta?.pagination)
        }
      } catch (e) {
        console.error(e)
        setNotificationsError(e?.message || 'Error loading notifications')
      }
    },
    [page]
  )

  useEffect(() => {
    readNotifications(1)
  }, [])

  useEffect(() => {
    if (notifications && notifications.length > 0) {
      setList(notifications.sort(sortByDate))
    }
  }, [notifications])

  return (
    <InviteWrap>
      <Flex
        row
        alignItems='center'
        css={css`
          margin: 12px 0 28px;
        `}
      >
        <Flex row alignItems='center'>
          <h2
            css={css`
              font-size: 24px;
              ${font.title}
              text-transform: uppercase;
              font-weight: bold;
              letter-spacing: unset;
              align-items: center;
              margin-right: 10px;
            `}
          >
            Notifications
          </h2>
          <button
            onClick={async () => await readNotifications(1)}
            title='Refresh'
          >
            <Icon name='sync' fontSize={14} />
          </button>
        </Flex>
        {list && list?.length > 0 ? (
          <button
            onClick={async () => {
              setNotificationLoading(true)
              await setAllAsRead()
              await readNotifications(1)
            }}
            css={css`
              color: ${colors.BG};
              font-size: 12px;
              text-decoration: underline;
              margin-right: 12px;
            `}
          >
            Mark all as read
          </button>
        ) : null}
      </Flex>
      {notificationLoading ? (
        <LoadingSpinner style={{ marginTop: '36px' }} />
      ) : (
        <ul className='notification-list'>
          {list.length > 0 ? (
            <div>
              {list.sort(sortByDate).map((item) => {
                return (
                  <NotificationItem
                    key={item.id}
                    item={item}
                    setNotificationAsRead={setNotificationAsRead}
                    onClick={async () => {
                      await setNotificationAsRead(item.id)
                      toggle()
                    }}
                    onPressDelete={async () => {
                      try {
                        await req(`/notifications/${item.id}`, {
                          method: 'DELETE',
                        })
                        await readNotifications(1)
                      } catch (e) {
                        console.error(e)
                      }
                    }}
                    setNotificationOpenForDelete={setNotificationOpenForDelete}
                    notificationOpenForDelete={notificationOpenForDelete}
                  />
                )
              })}
              {pagination?.total_pages > page ? (
                <div>
                  <button
                    css={css`
                      display: flex;
                      align-self: center;
                      align-items: center;
                      justify-content: center;
                      width: 30%;
                      height: 36px;
                      margin-top: 24px;
                      border: 2px solid ${colors.DEFAULT_FLAIR};
                      border-radius: 8px;
                      color: ${colors.WHITE};
                      ${font.title}
                      font-weight: 700;
                      font-size: 16px;
                      ${notificationLoading &&
                      css`
                        cursor: wait;
                      `}
                    `}
                    onClick={async () => {
                      await readMoreNotifications(page + 1)
                      setPage(page + 1)
                    }}
                  >
                    Load more
                  </button>
                </div>
              ) : null}
            </div>
          ) : (
            <li>
              <div style={{ textAlign: 'center', marginTop: 56 }}>
                {notificationError
                  ? t('errors:anErrorOccurred')
                  : t('Web:noNotifications')}
              </div>
              <Refresh onClick={async () => await readNotifications(1, true)}>
                {t('common:refresh')}?
              </Refresh>
            </li>
          )}
        </ul>
      )}
    </InviteWrap>
  )
}

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired,
  setNotificationAsRead: PropTypes.func.isRequired,
  setAllAsRead: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}

const mapStateToProps = (_) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    setNotificationAsRead: bindActionToPromise(
      dispatch,
      notificationsActions.readNotification.request
    ),
    setAllAsRead: bindActionToPromise(
      dispatch,
      notificationsActions.readAll.request
    ),
  }
}

Notifications = connect(mapStateToProps, mapDispatchToProps)(Notifications)

const NotificationsWrapper = ({
  Button,
  refreshNotificationCount = () => {},
}) => {
  return (
    <Mobile>
      {(isMobile) => {
        return (
          <Sheet
            button={Button}
            position='right'
            width={isMobile ? '320px' : '400px'}
            css={css`
              .scrolling-container {
                padding: 50px 20px 48px !important;
              }
            `}
          >
            {(toggle, _, isOpen) => (
              <Notifications
                toggle={toggle}
                isOpen={isOpen}
                refreshNotificationCount={refreshNotificationCount}
              />
            )}
          </Sheet>
        )
      }}
    </Mobile>
  )
}

NotificationsWrapper.propTypes = {
  Button: PropTypes.func.isRequired,
}

export default NotificationsWrapper
