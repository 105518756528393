/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import { t } from '@sportninja/common/i18n'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import cn from 'classnames'
import * as csv from 'csvtojson'
import dayjs from 'dayjs'
import React from 'react'
import SuspensionSelect from '../../components/Suspensions/SuspensionSelect'
import DatePicker from '../DatePicker'
import { FormButton } from '../Form/css'
import Icon from '../Icon'
import ReportsTableComponent from './ReportsTableComponent'
import { Table } from 'antd'
import colors from '@sportninja/common/constants/appColors'
import { SearchOutlined } from '@ant-design/icons'

const teamPenaltyMinutesColumns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {dayjs(item).format('YYYY-MM-DD hh:mm A')}
        </span>
      )
    },
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Organization',
    dataIndex: 'organization_name',
    key: 'organization_name',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: isCanlan ? 'Season' : 'Competition',
    dataIndex: 'competition_name',
    key: 'competition_name',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Team',
    dataIndex: 'team_name',
    key: 'team_name',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Player',
    dataIndex: 'player_name',
    key: 'player_name',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },

  {
    title: 'Penalty',
    dataIndex: 'penalty_name',
    key: 'penalty_name',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Offense Type',
    dataIndex: 'offense_type',
    key: 'offense_type',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Period',
    dataIndex: 'period_name',
    key: 'period_name',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Period Clock Time',
    dataIndex: 'period_clock_time',
    key: 'period_clock_time',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Penalty Length',
    dataIndex: 'penalty_length',
    key: 'penalty_length',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'URL',
    dataIndex: 'URL',
    key: 'URL',
    render: (item) => {
      if (!item) {
        return null
      }
      return (
        <a
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
          href={item}
          target='_blank'
          rel='noreferrer'
        >
          <SearchOutlined />
        </a>
      )
    },
  },
]

const registrationColumns = [
  {
    title: '',
    dataIndex: 'summary',
    key: 'summary',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Organization',
    dataIndex: 'organization',
    key: 'organization',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: isCanlan ? 'Season' : 'Competition',
    dataIndex: 'competition',
    key: 'competition',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Division',
    dataIndex: 'division',
    key: 'division',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Team',
    dataIndex: 'team',
    key: 'team',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Team Official',
    dataIndex: 'team_official',
    key: 'team_official',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Team Official Email',
    dataIndex: 'team_official_email',
    key: 'team_official_email',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Players',
    dataIndex: 'players',
    key: 'players',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Deposit',
    dataIndex: 'deposit',
    key: 'deposit',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Cost',
    dataIndex: 'cost',
    key: 'cost',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Payment Date',
    dataIndex: 'payment_date',
    key: 'payment_date',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Subtotal',
    dataIndex: 'subtotal',
    key: 'subtotal',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Tax Total',
    dataIndex: 'tax_total',
    key: 'tax_total',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Payment Status',
    dataIndex: 'payment_status',
    key: 'payment_status',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Paid',
    dataIndex: 'paid',
    key: 'paid',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Outstanding',
    dataIndex: 'outstanding',
    key: 'outstanding',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
]

const gameColumns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {/* also add the time in AM/PM format */}
          {dayjs(item).format('YYYY-MM-DD hh:mm A')}
        </span>
      )
    },
  },
  {
    title: 'Organization',
    dataIndex: 'organization',
    key: 'organization',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: isCanlan ? 'Season' : 'Competition',
    dataIndex: 'competition',
    key: 'competition',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Number',
    dataIndex: 'number',
    key: 'number',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Home',
    dataIndex: 'home',
    key: 'home',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Visitor',
    dataIndex: 'visitor',
    key: 'visitor',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Home Score',
    dataIndex: 'homeScore',
    key: 'homeScore',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Visitor Score',
    dataIndex: 'visitorScore',
    key: 'visitorScore',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Shootout',
    dataIndex: 'shootout',
    key: 'shootout',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  // {
  //   title: 'Time',
  //   dataIndex: 'time',
  //   key: 'time',
  //   render: (item) => {
  //     return (
  //       <span
  //         css={css`
  //           color: #ffffff;
  //           white-space: nowrap;
  //           width: 100%;
  //         `}
  //       >
  //         {item}
  //       </span>
  //     )
  //   },
  // },
  // {
  //   title: 'Timezone',
  //   dataIndex: 'timezone',
  //   key: 'timezone',
  //   render: (item) => {
  //     return (
  //       <span
  //         css={css`
  //           color: #ffffff;
  //           white-space: nowrap;
  //           width: 100%;
  //         `}
  //       >
  //         {item}
  //       </span>
  //     )
  //   },
  // },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Facility',
    dataIndex: 'facility',
    key: 'facility',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  // {
  //   title: 'City',
  //   dataIndex: 'city',
  //   key: 'city',
  //   render: (item) => {
  //     return (
  //       <span
  //         css={css`
  //           color: #ffffff;
  //           white-space: nowrap;
  //           width: 100%;
  //         `}
  //       >
  //         {item}
  //       </span>
  //     )
  //   },
  // },
  {
    title: 'Game Type',
    dataIndex: 'gameType',
    key: 'gameType',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'Hierarchy',
    dataIndex: 'hierarchy',
    key: 'hierarchy',
    render: (item) => {
      return (
        <span
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
        >
          {item}
        </span>
      )
    },
  },
  {
    title: 'URL',
    dataIndex: 'URL',
    key: 'URL',
    render: (item) => {
      return (
        <a
          css={css`
            color: #ffffff;
            white-space: nowrap;
            width: 100%;
          `}
          href={item}
          target='_blank'
          rel='noreferrer'
        >
          <SearchOutlined />
        </a>
      )
    },
  },
]

const transformGameData = (dataArray) => {
  if (!dataArray) {
    return []
  }
  return dataArray.map((game) => ({
    id: game?.['SN Game ID'],
    date: game?.Date,
    time: game?.Time,
    timezone: game?.Timezone,
    organization: game?.Organization,
    competition: game?.Competition,
    number: game?.Number,
    home: game?.Home,
    visitor: game?.Visitor,
    duration: game?.Duration,
    homeScore: game?.['Home Score'],
    visitorScore: game?.['Visitor Score'],
    status: game?.Status,
    shootout: game?.Shootout,
    location: game?.Location,
    facility: game?.Facility,
    city: game?.City,
    gameType: game?.['Game Type'],
    hierarchy: game?.Hierarchy,
    URL: game?.URL,
  }))
}

const transformRegistrationArray = (dataArray) => {
  if (!dataArray) {
    return []
  }
  return dataArray?.map((entry) => ({
    summary: Object.values(entry)[0],
    organization: entry?.Organization,
    competition: entry?.Competition,
    division: entry?.Division,
    team: entry?.Team,
    team_official: entry['Team Official'],
    team_official_email: entry['Team Official Email'],
    players: entry?.Players,
    deposit: entry?.Deposit,
    cost: entry.Cost,
    payment_date: entry['Payment Date'].trim(), // Trimming to remove any stray whitespace
    subtotal: entry?.Subtotal?.trim(),
    tax_total: entry['Tax Total']?.trim(),
    total: entry?.Total?.trim(),
    payment_status: entry['Payment Status']?.trim(),
    paid: entry?.Paid,
    outstanding: entry?.Outstanding,
  }))
}

const transformPenaltyData = (dataArray) => {
  if (!dataArray) {
    return []
  }
  return dataArray.map((item) => ({
    description: item?.Description,
    date: item?.Date,
    organization_name: item?.Organization,
    competition_name: item?.Competition,
    player_name: item?.Player,
    team_name: item?.Team,
    penalty_name: item?.Penalty,
    offense_type: item['Offense Type'],
    period_name: item?.Period,
    period_clock_time: item['Period Clock Time'] || item['Clock Time'],
    penalty_length: item['Penalty Length'],
    URL: item?.URL,
  }))
}

const dropDownOptions = [
  {
    text: 'Penalty Report',
    value: 'penaltiesByTeam',
  },
  {
    text: 'Team Registrations',
    value: 'registrations',
  },
  {
    text: 'Games Played',
    value: 'gamesPlayed',
  },
]

const reportEndpoints = {
  penaltiesByTeam: '/reports/:entityId/team_penalty_minutes',
  registrations: '/reports/:entityId/registration',
  gamesPlayed: '/reports/:entityId/games_played',
}

const reportOrgEndpoints = {
  penaltiesByTeam: '/reports/org/:entityId/team_penalty_minutes',
  registrations: '/reports/org/:entityId/registration',
  gamesPlayed: '/reports/org/:entityId/games_played',
}

const generateHref = (string, reportName, ref) => {
  var blob = new Blob([string], { type: 'text/csv;charset=utf-8;' })

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, reportName)
  } else {
    const link = ref.current
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', reportName)
    }
  }
}

const NewReports = ({ entityId, entityType }) => {
  const [schedules, setSchedules] = React.useState([])
  const [schedule, setSchedule] = React.useState('')
  const [report, setReport] = React.useState('penaltiesByTeam')
  const [loading, setLoading] = React.useState(false)
  const [csvString, setCSVString] = React.useState(false)
  const [data, setData] = React.useState(false)
  const [dateRange, setDateRange] = React.useState({
    starts_at: undefined,
    ends_at: undefined,
  })
  const downloadRef = React.useRef(null)

  const [dataAsJson, setDataAsJson] = React.useState([])

  React.useEffect(() => {
    const getSchedules = async () => {
      try {
        setLoading(true)
        const { data } = await req(
          `/organizations/${entityId}/schedules?sort=starts_at&direction=desc`
        )
        setSchedules(
          data.map((schedule) => ({ text: schedule.name, value: schedule.id }))
        )
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
    if (entityType === ENTITY_TYPES.org) {
      getSchedules()
    }
  }, [entityId, entityType])

  const onView = async () => {
    try {
      const query = {}
      if (dateRange.starts_at && dateRange.ends_at) {
        query.starts_after = dayjs(dateRange.starts_at)
          .hour(0)
          .minute(0)
          .second(0)
          .format()
        query.starts_before = dayjs(dateRange.ends_at)
          .hour(0)
          .minute(0)
          .second(0)
          .add(1, 'day')
          .format()
      }
      query.format = 'csv'

      let url = ''
      if (entityType === ENTITY_TYPES.org) {
        if (schedule === '') {
          url = reportOrgEndpoints[report].replace(':entityId', entityId)
        } else {
          url = reportEndpoints[report].replace(':entityId', schedule)
        }
      } else {
        url = reportEndpoints[report].replace(':entityId', entityId)
      }
      const response = await req(url, { parseJSON: false, query })
      const text = await response.text()
      setCSVString(text)
      generateHref(text, report, downloadRef)
      csv({ output: 'json' })
        .fromString(text)
        .then((result) => {
          if (report === 'penaltiesByTeam') {
            setDataAsJson(transformPenaltyData(result))
          }
          if (report === 'registrations') {
            setDataAsJson(transformRegistrationArray(result))
          }
          if (report === 'gamesPlayed') {
            setDataAsJson(transformGameData(result))
          }
          setData(result)
        })
    } catch (e) {
      console.error(e)
      alert(
        `There was an error generating the report. ${
          e?.message || ''
        } Please try again.`
      )
    }
  }

  return (
    <div
      css={css`
        margin: 24px auto;
        display: flex;
        flex-direction: column;
        /* overflow-x: auto; */
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          align-self: flex-start;
        `}
      >
        <SuspensionSelect
          className={cn('game-list-filters-team', {
            'is-selected': !!report,
          })}
          disabled={loading}
          enabledAllItemsOption={false}
          label={t('util:capitalize', { text: 'Report Type' })}
          value={report}
          onChange={(selectedReport) => {
            setDataAsJson([])
            setReport(selectedReport)
          }}
          options={dropDownOptions}
        />
        {entityType === ENTITY_TYPES.org ? (
          <SuspensionSelect
            className={cn('game-list-filters-team', {
              'is-selected': !!schedule,
            })}
            disabled={loading}
            enabledAllItemsOption={true}
            label={t('util:capitalize', {
              text: isCanlan ? 'Season' : 'Competition',
            })}
            value={schedule}
            onChange={(selectedReport) => {
              setDataAsJson([])
              setSchedule(selectedReport)
            }}
            options={schedules}
          />
        ) : null}
        <div
          css={css`
            margin: 0 16px 16px 0;
            width: 260px;
            min-width: 260px;

            .picker-label {
              height: 12px;
              font-size: 12px;
              line-height: 12px;
              margin-bottom: 8px;
              letter-spacing: 1.04px;
            }

            .picker-value-container {
              background: #27303e;
              border: ${isCanlan ? 2 : 1}px solid #3f4753;
              border-radius: 4px;
              height: 40px;
            }
          `}
        >
          <DatePicker
            datePlaceholder='Select a date range'
            defaultValues={(() => {
              const values = {}
              if (dateRange.starts_at) {
                values.starts_at = { value: dateRange.starts_at }
              }
              if (dateRange.ends_at) {
                values.ends_at = { value: dateRange.ends_at }
              }
              return values
            })()}
            disabled={loading}
            hideTime
            label='Date Range'
            onChange={({ target }) => {
              try {
                const { name, value } = target
                setDateRange((prevDateRange) => {
                  return {
                    ...prevDateRange,
                    [name]: value,
                  }
                })
              } catch (e) {
                console.error(e)
              }
            }}
            useRange
          />
        </div>
        <FormButton
          isSubmit
          css={css`
            height: 46px;
            width: 100px;
          `}
          onClick={async () => {
            try {
              setLoading(true)
              await onView()
            } finally {
              setLoading(false)
            }
          }}
        >
          <Icon
            name='search'
            css={css`
              margin-right: 6px;
              transform: translateY(-1px);
            `}
            fontSize={14}
          />
          View
        </FormButton>
        <FormButton
          as='a'
          disabled={!csvString}
          isSubmit
          css={css`
            width: 200px;
            height: 46px;
            text-align: center;
            padding-top: 12px;
            margin-left: 16px;
          `}
          ref={downloadRef}
        >
          <Icon
            name='download'
            css={css`
              margin-right: 6px;
            `}
            fontSize={14}
          />
          Download CSV
        </FormButton>
      </div>
      <Table
        css={css`
          overflow-x: auto;
          width: 100%;
          background: #27303e;
          border-radius: 8px;
          * {
            border-color: ${colors.SOFT_STEEL} !important;
          }
          .ant-table-column-sorter {
            display: none;
          }
        `}
        columns={
          report === 'penaltiesByTeam'
            ? teamPenaltyMinutesColumns
            : report === 'gamesPlayed'
            ? gameColumns
            : registrationColumns
        }
        dataSource={dataAsJson}
        loading={loading}
        bordered
        size='small'
      />
      {/* {data && data?.length === 0 ? (
        <div
          css={css`
            margin: 24px auto;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #ffffff;
          `}
        >
          No data found for the selected filters.
        </div>
      ) : null}
      {data?.length > 0 ? (
        <ReportsTableComponent>
          <thead>
            <tr>
              {Array.isArray(data) &&
                Object.keys(data?.[0])?.map((keyName) => {
                  return <th key={keyName}>{keyName}</th>
                })}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(data) &&
              data?.map((row) => {
                return (
                  <tr key={row?.id}>
                    {Object.keys(row)?.map((keyName) => {
                      return (
                        <td key={keyName}>
                          {row[keyName] !== '' ? row[keyName] : '-'}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
          </tbody>
        </ReportsTableComponent>
      ) : null} */}
    </div>
  )
}

export default NewReports
