/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import { useI18n } from '@shopify/react-i18n'
import { ROUTES } from '@sportninja/common/constants/app'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { Collapse } from 'antd'
import { observer } from 'mobx-react-lite'
import { useLayoutEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Icon from '../../components/Icon'
import { useMobxStore } from '../../state'

const currency = {
  1: 'CAD',
  2: 'USD',
}

export const HomePaymentBanner = observer(({ isInFrame }) => {
  if (isInFrame) {
    return null
  }

  const location = useLocation()
  const [i18n] = useI18n()
  const isHome = useMemo(() => location.pathname === '/home', [location])

  const {
    registration: {
      checkIfHasPaymentAvailable,
      cleanUpPaymentData,
      state: { hasDepositPaymentAvailable, hasInstallmentsPaymentAvailable },
    },
  } = useMobxStore()
  const history = useHistory()

  useLayoutEffect(() => {
    if (isHome) {
      checkIfHasPaymentAvailable()
    }
  }, [isHome])

  if (
    !isHome ||
    (hasDepositPaymentAvailable.length === 0 &&
      hasInstallmentsPaymentAvailable.length === 0)
  ) {
    return null
  }

  const items = []
  const label = (isDeposit) => {
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
        `}
      >
        <Icon
          name='exclamation-triangle'
          color={colors.CAUTION_DARK}
          fontSize={16}
        />
        <p
          css={css`
            color: ${colors.CAUTION_DARK};
            font-size: 16px;
            font-weight: 700;
            line-height: 145%;
          `}
        >
          {isDeposit ? 'Pay Your Deposit Now' : 'Pay Your Installment Now'}
        </p>
      </div>
    )
  }

  const children = (item, isDeposit) => {
    const dueDate = new Date(item?.due_date)
    const formattedDueDate = `${dueDate.toLocaleString('default', {
      month: 'long',
    })} ${dueDate.getDate()}, ${dueDate.getFullYear()}`
    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          background-color: ${colors.FLORAL_WHITE};
        `}
      >
        <a
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
          `}
          onClick={() => history.push(`/team/${item?.team_id}/billing`)}
        >
          {item?.team_image?.full_path ? (
            <div
              css={css`
                display: flex;
                width: 24px;
                height: 24px;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                background-color: ${colors.WHITE};
              `}
            >
              <img
                src={item?.team_image?.full_path}
                css={css`
                  width: 100%;
                  height: 100%;
                  border-radius: 4px;
                `}
                onError={(e) => {
                  e.target.src =
                    '/images/generic_placeholders/placeholder_team.png'
                }}
              />
            </div>
          ) : (
            <div
              css={css`
                display: flex;
                width: 24px;
                height: 24px;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                background-color: ${colors.WHITE};
              `}
            >
              <img
                src={'/images/generic_placeholders/placeholder_team.png'}
                css={css`
                  width: 100%;
                  height: 100%;
                  border-radius: 4px;
                `}
                onError={(e) => {
                  e.target.src =
                    '/images/generic_placeholders/placeholder_team.png'
                }}
              />
            </div>
          )}
          <p
            css={css`
              color: ${colors.HEADER};
              font-size: 20px;
              font-weight: 600;
              font-family: ${isCanlan
                ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
            `}
          >
            {item?.team_name}
          </p>
        </a>
        <a
          css={css`
            display: flex;
            align-items: center;
          `}
          onClick={() => history.push(`/schedule/${item?.schedule_id}`)}
        >
          <p
            css={css`
              color: ${colors.HEADER};
              font-size: 16px;
              font-weight: 700;
              line-height: 145%;
            `}
          >
            Competition:
          </p>
          <p
            css={css`
              color: ${colors.HEADER};
              font-size: 16px;
              margin-left: 8px;
              line-height: 145%;
            `}
          >
            {item?.root_schedule_name} - {item?.schedule_name}
          </p>
        </a>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          <p
            css={css`
              color: ${colors.HEADER};
              font-size: 16px;
              font-weight: 700;
              line-height: 145%;
            `}
          >
            {isDeposit ? 'Deposit:' : 'Installment:'}
          </p>
          <p
            css={css`
              color: ${colors.HEADER};
              font-size: 16px;
              line-height: 145%;
            `}
          >
            {i18n.formatCurrency(
              isDeposit ? item?.deposit_amount : item?.payment_amount,
              {
                currency: currency[item?.deposit_currency],
                form: 'explicit',
              }
            )}
          </p>
        </div>
        {item?.due_date ? (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
            `}
          >
            <p
              css={css`
                color: ${colors.HEADER};
                font-size: 16px;
                font-weight: 700;
                line-height: 145%;
              `}
            >
              Due Date :
            </p>
            <p
              css={css`
                color: ${colors.HEADER};
                font-size: 16px;
                line-height: 145%;
              `}
            >
              {formattedDueDate}
            </p>
          </div>
        ) : null}
        <button
          css={css`
            display: flex;
            padding: 8px;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            background-color: ${colors.PRIMARY};
            border-radius: 8px;
            color: ${colors.WHITE};
            font-size: 20px;
            font-weight: 600;
            font-family: ${isCanlan
              ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
              : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
          `}
          onClick={() => {
            history.push(
              `${ROUTES.REGISTRATION_PAYMENT}?divisionId=${item?.root_schedule_id}&teamId=${item?.team_id}`
            )
            setTimeout(() => {
              cleanUpPaymentData()
            }, 200)
          }}
        >
          PAY NOW
        </button>
      </div>
    )
  }

  if (hasDepositPaymentAvailable?.length > 0) {
    hasDepositPaymentAvailable.forEach((item, index) => {
      items.push({
        key: `${index}_${item?.id}`,
        label: label(true),
        children: children(item, true),
        style: {
          marginBottom: 16,
          borderRadius: 8,
          backgroundColor: colors.FLORAL_WHITE,
        },
      })
    })
  }

  if (hasInstallmentsPaymentAvailable?.length > 0) {
    hasInstallmentsPaymentAvailable.forEach((item, index) => {
      items.push({
        key: `${index}_${item?.id}`,
        label: label(false),
        children: children(item, false),
        style: {
          marginBottom: 16,
          borderRadius: 8,
          backgroundColor: colors.FLORAL_WHITE,
        },
      })
    })
  }

  // I need to create an array that contains the key of the first item of the deposits and the first item of the payments,
  // so I can use it as the defaultActiveKey
  const defaultActiveKey = []
  if (hasDepositPaymentAvailable?.length > 0) {
    defaultActiveKey.push(`0_${hasDepositPaymentAvailable[0]?.id}`)
  }
  if (hasInstallmentsPaymentAvailable?.length > 0) {
    defaultActiveKey.push(`0_${hasInstallmentsPaymentAvailable[0]?.id}`)
  }

  return (
    <Collapse
      defaultActiveKey={defaultActiveKey}
      accordion
      bordered={true}
      items={items}
      css={css`
        margin-bottom: 16px;
        border: none;
        background-color: transparent;
        .ant-collapse-header {
          color: ${colors.CAUTION_DARK}!important;
          font-weight: 700;
          line-height: 145%;
          font-size: 16px;
        }
        .ant-collapse-item {
          border: 2px solid ${'#FFC107'};
          border-radius: 0 0 8px 8px;
        }
        .ant-collapse-content-box {
          background-color: ${colors.FLORAL_WHITE}!important;
          /* add the border radius of 8px only on the bottom right and the bottom left */
          border-radius: 0 0 8px 8px !important;
          /* padding 16 on all sides besides the top */
          padding: 0 16px 16px 16px !important;
        }
        .ant-collapse-content {
          border-top: none !important;
          border-radius: 0 0 8px 8px !important;
        }
      `}
    />
  )
})
