import req from '@sportninja/common/api/request'

import type { Invoice } from './index'

class Service {
  async getAllPlans() {
    const response = await req('/subscriptions/type')

    return response
  }

  async getInvoices(accountId: string) {
    try {
      const response = await req(`/account/${accountId}/invoices`)

      return response.data as Invoice[]
    } catch (e) {
      console.error('ERROR FETCH INVOICES', e)
    }
  }
}

export const SubscriptionService = new Service()
