/* eslint-disable react-native/no-inline-styles */
/** @jsxImportSource @emotion/react */
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import colors from '@sportninja/common/constants/appColors'
import { isCanlan } from '@sportninja/common/utils/customer-name'
import { Radio, RadioChangeEvent, Space, Tag } from 'antd'
import React from 'react'
import {
  captionLarge,
  paragraphLarge,
  paragraphSmall,
  paragraphXSmall,
  weightMedium,
  weightRegular,
} from '../css'
import { ROUTES } from '@sportninja/common/constants/app'
import { RouterHistory } from '@sentry/react/types/reactrouter'

const closeStyle = {
  color: colors.WHITE,
  fontSize: 20,
}
interface PrintSignSheetsModalProps {
  onPressClose?: () => void
  visibleGameIds?: string[]
  history?: RouterHistory
}

const PrintSignSheetsModal: React.FC<PrintSignSheetsModalProps> = ({
  onPressClose = () => {},
  visibleGameIds = [],
  history,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>('')

  console.log({
    error,
  })

  const [selectedOption, setSelectedOption] = React.useState<string>('display')

  const onChange = (e: RadioChangeEvent) => {
    setSelectedOption(e.target.value)
  }

  return (
    <div
      css={css`
        background-color: ${colors.SECONDARY_300};
        min-width: 30vw;
        max-width: 700px;
        max-height: 80vh;
        /* border-radius: 8px; */
        overflow-y: auto;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          gap: 8px;
          align-self: stretch;
          position: sticky;
          top: 0;
          background-color: ${colors.HEADER};
          padding-top: 16px;
          padding-left: 16px;
          padding-right: 16px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          z-index: 100;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 16px;
            `}
          >
            <h1
              css={css`
                font-size: 32px;
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'BarlowCondensed, Arial, Helvetica, sans-serif;'};
                font-weight: 600;
                color: ${colors.WHITE};
              `}
            >
              {'Print Sign-In Sheets'}
            </h1>
            <Tag
              color={'rgba(242, 180, 0, 0.10)'}
              style={{
                marginTop: 4,
                color: colors.PRIMARY,
              }}
            >
              Beta
            </Tag>
          </div>
          <div
            css={css`
              display: flex;
              gap: 16px;
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 16px;
              `}
            >
              <button
                disabled={isLoading}
                onClick={() => {
                  window.open(
                    'https://sportninja.zendesk.com/hc/en-us/',
                    '_blank'
                  )
                }}
              >
                <QuestionCircleOutlined style={closeStyle} />
              </button>
            </div>
            <div
              css={css`
                display: flex;
                gap: 16px;
              `}
            >
              <button
                disabled={isLoading}
                onClick={() => {
                  setError('')
                  setIsLoading(false)
                  onPressClose()
                }}
              >
                <CloseOutlined style={closeStyle} />
              </button>
            </div>
          </div>
        </div>
        <div
          css={css`
            border-bottom: 1px solid ${colors.SOFT_STEEL};
            width: 100%;
            margin-top: 8px;
            margin-bottom: 8px;
          `}
        />
        <p
          css={css`
            ${paragraphLarge}
            ${weightMedium}
          color: ${colors.WHITE};
            align-self: stretch;
          `}
        >
          What would you like to print:
        </p>
        <Radio.Group
          onChange={onChange}
          defaultValue={selectedOption}
          value={selectedOption}
          style={{
            alignSelf: 'stretch',
            gap: 16,
          }}
        >
          <Space direction='vertical'>
            <Radio value='display'>
              <p
                css={css`
                  ${paragraphSmall}
                  ${weightRegular}
              color: ${colors.WHITE};
                  align-self: stretch;
                `}
              >
                Display Player / Team Officials
              </p>
              <span
                css={css`
                  ${paragraphXSmall}
                  ${weightRegular}
                color: ${colors.SECONDARY_75};
                  align-self: stretch;
                `}
              >
                This option generates sign-in sheets using the full rosters for
                each game
              </span>
            </Radio>
            <Radio value='empty'>
              <p
                css={css`
                  ${paragraphSmall}
                  ${weightRegular}
              color: ${colors.WHITE};
                  align-self: stretch;
                `}
              >
                Empty Report
              </p>
              <span
                css={css`
                  ${paragraphXSmall}
                  ${weightRegular}
                color: ${colors.SECONDARY_75};
                  align-self: stretch;
                `}
              >
                This option generates blank sign-in sheets for manually filling
                out player and goalie information
              </span>
            </Radio>
          </Space>
        </Radio.Group>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 14px;
          align-self: stretch;
          padding: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 16px;
            align-self: stretch;
            position: sticky;
            bottom: 0;
            z-index: 100;
            background-color: ${colors.HEADER};
            padding-top: 16px;
          `}
        >
          {/* Cancel Button */}
          <div
            css={css`
              max-width: 80px;
              display: flex;
              padding: 8px 24px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              border-radius: 8px;
              border: 1px solid var(--Neutrals-neutrals-0, #fff);
              cursor: ${isLoading ? 'not-allowed' : 'pointer'};
              opacity: ${isLoading ? 0.7 : 1};
            `}
            onClick={() => {
              if (isLoading) {
                return
              }

              setError('')
              setIsLoading(false)
              onPressClose()
            }}
          >
            <p
              css={css`
                ${captionLarge}
                font-family: ${isCanlan
                  ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                  : 'BarlowCondensed'};
                color: ${colors.WHITE};
              `}
            >
              Close
            </p>
          </div>
          <div
            css={css`
              max-width: 80px;
              display: flex;
              padding: 8px 24px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              border-radius: 8px;
              background: ${colors.PRIMARY};
              cursor: ${isLoading ? 'not-allowed' : 'pointer'};
              opacity: ${isLoading ? 0.7 : 1};
            `}
            onClick={() => {
              if (visibleGameIds?.length > 0) {
                history?.push({
                  pathname: ROUTES.SIGNIN_REPORTS_PRINT,
                  state: {
                    games: visibleGameIds,
                    search: location.search,
                    isSoccer: false,
                    showRosters: selectedOption === 'display' ? true : false,
                  },
                })
              }
            }}
          >
            <>
              <p
                css={css`
                  ${captionLarge}
                  font-family: ${isCanlan
                    ? 'Neue Plak Condensed, Arial, Helvetica, sans-serif;'
                    : 'BarlowCondensed'};
                  color: ${colors.WHITE};
                `}
              >
                {'Confirm'}
              </p>
            </>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrintSignSheetsModal
