/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'
import { Fragment, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { Flex } from '../Layout'
import FollowBtn from '../FollowBtn'
import { CircleSpan, PANEL_HEIGHT, PANEL_WIDTH, Button } from './css'
import ActionPanelContents from './ActionPanelContents'
import { createHandler } from './utils'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'

const ActionPanel = ({
  customButton,
  customCss,
  entityType,
  id,
  invite,
  isFollowing,
  noFollow,
  onEdit,
  onUpdate,
  onUpdateOpen,
  onDelete,

  ...contentsProps
}) => {
  // In the case of explicitly disabling the follow button, along with no registered
  // handlers for edit and remove, don't show anything!
  if (
    !onUpdate &&
    !onDelete &&
    !customButton &&
    (noFollow || typeof entityType === 'undefined')
  )
    return false
  let followOnly = false
  if (!noFollow && !onUpdate && !onDelete && !customButton) followOnly = true

  const [menuOpen, setMenuOpen] = useState(false)
  const panelRef = useRef(null)

  // If the space between the edges of the '...' button and the viewport doesn't
  // have enough room for the panel, we alter the CSS style so that it will fit.
  let fitsBottom = false
  let fitsRight = false
  if (panelRef.current) {
    const rect = panelRef.current.getBoundingClientRect()
    const { bottom, right } = rect
    const { innerHeight, innerWidth } = window

    fitsBottom = innerHeight - bottom >= PANEL_HEIGHT
    fitsRight = innerWidth - right >= PANEL_WIDTH
  }

  const onCircleSpanClick = createHandler.bind(this, async () => {
    typeof onEdit === 'function' && onEdit()
    setMenuOpen(!menuOpen)
  })

  const followButton = !noFollow &&
    [ENTITY_TYPES.schedule, ENTITY_TYPES.team, ENTITY_TYPES.org].includes(
      entityType
    ) && (
      <FollowBtn
        activeBorderColor={false}
        entityType={entityType}
        id={id}
        initialValue={isFollowing}
        // eslint-disable-next-line react/display-name
        render={
          followOnly
            ? undefined
            : (onClick, isLoading, isFollowing) => (
                <Button
                  type='button'
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={onClick}
                >
                  {isFollowing ? 'Unfollow' : 'Follow'}
                </Button>
              )
        }
      />
    )

  return (
    <Flex
      noFlex
      css={[
        css`
          position: relative;
        `,
        customCss,
      ]}
    >
      {followOnly ? (
        followButton
      ) : (
        <Fragment>
          <button
            onContextMenu={onCircleSpanClick}
            onClick={onCircleSpanClick}
            type='button'
            css={css`
              outline: none;
              line-height: 0;
              padding: 16px 8px;
              border-radius: 3px;
              transition: background-color 0.2s ease-in-out;

              ${menuOpen &&
              css`
                background-color: rgba(255, 255, 255, 0.5);

                ${CircleSpan} {
                  background-color: black;
                }
              `}

              &:hover {
                background-color: rgba(255, 255, 255, 0.3);
              }
            `}
            ref={panelRef}
            title='Open actions menu'
          >
            <span className='sr-only'>Open actions menu</span>
            <CircleSpan />
            <CircleSpan />
            <CircleSpan />
          </button>
          <ActionPanelContents
            customButton={customButton}
            fitsBottom={fitsBottom}
            fitsRight={fitsRight}
            followButton={followButton}
            noFollow={noFollow}
            onUpdate={onUpdate}
            onUpdateOpen={onUpdateOpen}
            onDelete={onDelete}
            invite={invite}
            isOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            {...contentsProps}
          />
        </Fragment>
      )}
    </Flex>
  )
}

ActionPanel.propTypes = {
  customButton: PropTypes.func,
  customCss: PropTypes.object,
  deleteButtonText: PropTypes.string,
  deleteMessage: PropTypes.string,
  deleteTitle: PropTypes.string,
  entityType: PropTypes.string,
  id: PropTypes.string,
  invite: PropTypes.object,
  isFollowing: PropTypes.bool,
  noFollow: PropTypes.bool,
  onDelete: PropTypes.func,

  // For updating the entity
  onUpdate: PropTypes.func,
  updateForm: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  updateTitle: PropTypes.string,
}

export default ActionPanel
