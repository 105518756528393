/** @jsxImportSource @emotion/react */

import css from '@emotion/css/macro'
import { ModalHeader } from './UpgradeModal'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useMobxStore } from 'src/state'
import { observer } from 'mobx-react-lite'
import colors from '@sportninja/common/constants/appColors'
import Icon from 'src/components/Icon'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { Button } from 'src/components/Button'
import { Stripe, loadStripe } from '@stripe/stripe-js'
import { Radio } from 'antd'

interface Props {
  onClose: () => void
}

export const handleCardIcon = (cardName?: string) => {
  switch (cardName) {
    case 'diners':
      return 'cc-diners-club'
    case 'amex':
      return 'cc-amex'
    case 'discover':
      return 'cc-discover'
    case 'jcb':
      return 'cc-jcb'
    case 'mastercard':
      return 'cc-mastercard'
    case 'visa':
      return 'cc-visa'
    default:
      return 'cc-stripe'
  }
}

export const PaymentManagerModal = observer(({ onClose }: Props) => {
  const {
    payment: {
      state: { paymentList, loading },
      deletePaymentMethod,
      updateDefaultMethod,
      fetchPaymentList,
      createNewCard,
    },
  } = useMobxStore()
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState('')
  const [setupNewCard, setSetupNewCard] = useState(false)
  const stripeClient = useRef<Stripe | null>(null)
  const [isLoadingStripe, setIsLoadingStripe] = useState(false)

  const currentDefaultPaymentMethodId = useMemo(
    () => paymentList.find((payment) => payment.default)?.id,
    [paymentList]
  )

  const setupStripeCard = useCallback(async () => {
    const host = window.location.hostname || window.location.host
    if (
      __DEV__ ||
      !['app.sportninja.com', 'canlanstats.sportninja.com'].includes(host)
    ) {
      console.log('LOADING DEV')
      stripeClient.current = await loadStripe(
        process.env.REACT_APP_STRIPE_KEY_DEV as string
      )
    } else {
      stripeClient.current = await loadStripe(
        process.env.REACT_APP_STRIPE_KEY as string
      )
    }

    if (!stripeClient) {
      return
    }

    const elements = stripeClient.current?.elements({
      appearance: {
        theme: 'night',
      },
    })

    const cardElement = elements?.create('card', {
      hidePostalCode: true,
      style: {
        base: {
          color: '#fff',
          fontWeight: 300,
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: '#fce883',
          },
          '::placeholder': {
            color: '#CFD7E0',
          },
        },
        invalid: {
          iconColor: '#FFC7EE',
          color: '#FFC7EE',
        },
      },
    })

    const cardDiv = document.querySelector('#card-element')

    if (!cardDiv) {
      return
    }

    cardElement?.mount('#card-element')

    const newCardForm = document.querySelector('#card-form')

    if (!newCardForm || !cardElement) {
      return
    }

    newCardForm.addEventListener('submit', async (e) => {
      e.preventDefault()
      setIsLoadingStripe(true)

      const res = await stripeClient.current?.createPaymentMethod({
        type: 'card',
        card: cardElement,
      })

      if (res?.error) {
        console.error(res?.error)
      } else {
        const id = res?.paymentMethod?.id
        if (id) {
          setIsLoadingStripe(false)
          createNewCard(id)
          setSetupNewCard(false)
        }
      }
    })
  }, [])

  const confirmDelete = (paymentId: string) => {
    if (window.confirm('Are you sure you want remove this payment method?')) {
      deletePaymentMethod(paymentId)
    }
  }

  useEffect(() => {
    if (setupNewCard) {
      setupStripeCard()
    }
  }, [setupNewCard])

  useLayoutEffect(() => {
    const defaultPaymentMethod = paymentList.find((payment) => payment.default)
    if (defaultPaymentMethod) {
      setDefaultPaymentMethodId(defaultPaymentMethod.id)
    }
  }, [paymentList])

  useLayoutEffect(() => {
    if (paymentList.length === 0) {
      fetchPaymentList()
    }
  }, [])

  if (loading) {
    return (
      <div
        css={css`
          width: 512px;
          padding: 16px;
          background: no-repeat fixed linear-gradient(#282e38, #181a1d);
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          max-height: 80vh;
          overflow-y: auto;
        `}
      >
        <LoadingSpinner size={4} />
      </div>
    )
  }

  return (
    <div
      css={css`
        width: 512px;
        padding: 16px;
        background: no-repeat fixed linear-gradient(#282e38, #181a1d);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        max-height: 90vh;
        overflow-y: auto;
        gap: 16px;
      `}
    >
      <ModalHeader
        title='Change Payment Method'
        onCloseClick={() => {
          setSetupNewCard(false)
          onClose()
        }}
      />

      {paymentList.length > 0 &&
        paymentList.map((payment) => (
          <div
            key={payment.id}
            css={css`
              display: flex;
              justify-content: space-between;
              gap: 8px;
              align-items: center;
            `}
          >
            <Radio
              checked={payment.id === defaultPaymentMethodId}
              onClick={() =>
                payment.id === defaultPaymentMethodId
                  ? null
                  : setDefaultPaymentMethodId(payment.id)
              }
            />
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: 8px;
                border: 1px solid ${colors.SOFT_STEEL};
                border-radius: 8px;
                flex: 1;
              `}
            >
              <div
                css={css`
                  display: flex;
                  gap: 8px;
                `}
              >
                <Icon
                  name={handleCardIcon(payment.card_type)}
                  faType='fab'
                  fontSize={24}
                  color={colors.WHITE}
                />
                <div>
                  <p
                    css={css`
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 700;
                      color: ${colors.WHITE};
                    `}
                  >
                    **** **** **** {payment.last_four}
                  </p>
                  <p
                    css={css`
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      color: ${colors.ATTENDANCE_GRAY};
                    `}
                  >
                    {String(payment.exp_month).padStart(2, '0')}/
                    {payment.exp_year}
                  </p>
                </div>
              </div>
            </div>
            <button
              onClick={(e) => {
                e.preventDefault()
                confirmDelete(payment.id)
              }}
              disabled={payment.id === defaultPaymentMethodId}
              css={css`
                cursor: ${payment.id === defaultPaymentMethodId
                  ? 'not-allowed'
                  : 'pointer'};
              `}
            >
              <Icon
                name='trash'
                fontSize={16}
                color={
                  payment.id === defaultPaymentMethodId
                    ? colors.SOFT_STEEL
                    : colors.ERROR
                }
              />
            </button>
          </div>
        ))}
      <div
        css={css`
          margin-top: 6px;
          margin-bottom: 16px;
        `}
      >
        {!setupNewCard && (
          <button
            onClick={() => setSetupNewCard(true)}
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              color: ${colors.DEFAULT_FLAIR};
              font-size: 14px;
              flex: 1;
            `}
          >
            <Icon name='plus-square' color={colors.DEFAULT_FLAIR} />
            <p>Add New</p>
          </button>
        )}
        <form
          id='card-form'
          css={css`
            opacity: ${setupNewCard ? 1 : 0};
            height: ${setupNewCard ? 'auto' : 0};
            display: flex;
            align-items: center;
            gap: 8px;
            overflow: hidden;
          `}
        >
          <div
            id='card-element'
            css={css`
              border: 1px solid ${colors.SOFT_STEEL};
              padding: 10px;
              flex: 7;
              border-radius: 4px;
              background-color: #30303d;
              box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.75);
            `}
          />
          <div
            css={css`
              flex: 1;
            `}
          >
            <Button
              type='submit'
              label='Save'
              disabled={isLoadingStripe}
              isLoading={isLoadingStripe}
            />
          </div>
        </form>
      </div>
      <Button
        label={
          currentDefaultPaymentMethodId !== defaultPaymentMethodId
            ? 'CHANGE DEFAULT CARD'
            : 'CLOSE'
        }
        disabled={isLoadingStripe || loading}
        onClick={() => {
          if (currentDefaultPaymentMethodId !== defaultPaymentMethodId) {
            updateDefaultMethod(defaultPaymentMethodId)
          }
          onClose()
        }}
      />
    </div>
  )
})
