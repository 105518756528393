import { call, put, select } from 'redux-saga/effects'

import actions from '../actions/user'
import req from '../api/request'
import { getSelfUser } from '../selectors/users'
import { generateSagas } from './utils'
import { readWithPagesFactory } from './helpers/read'

export const api = {
  read: async () => await req('/users/me'),
  readRoles: async (page) => await req(`/users/me/roles?page=${page}`),
  // TODO: Is this the final endpoint url?
  readManagedUsers: async () => await req('/users/me/identities'),
  readOrgs: async (page = 1, sort, direction) =>
    await req('/users/me/organizations', { query: { page, sort, direction } }),
  readSchedules: async (page = 1, sort, direction) =>
    await req('/users/me/schedules', { query: { page, sort, direction } }),
  readTeams: async (page = 1, sort, direction) =>
    await req('/users/me/teams', { query: { page, sort, direction } }),
}

const user = [
  [
    actions.read,
    function* () {
      const { data } = yield call(api.read)
      yield put(actions.read.success({ data }))
      return data
    },
  ],

  [
    actions.readRoles,
    function* ({ page = 1 }) {
      const { data, meta } = yield call(api.readRoles, page)
      // We need to let reducers know which user the roles are for
      const user = yield select((state) => getSelfUser(state))
      // TODO: This is a temporary fix for a bug in the API
      // The API is returning a lot of data for some users
      // This is causing the app to be slow
      if (data && data?.length > 500) {
        yield put(
          actions.readRoles.success({
            id: user.id,
            data: [],
            pagination: meta?.pagination || {},
          })
        )
      } else {
        yield put(
          actions.readRoles.success({
            id: user.id,
            data,
            pagination: meta?.pagination || {},
          })
        )
      }
      return data
    },
  ],

  [
    actions.readManagedUsers,
    function* () {
      const { data } = yield call(api.readManagedUsers)
      yield put(actions.readManagedUsers.success({ data }))
      return data
    },
  ],

  [actions.readOrgs, readWithPagesFactory(api.readOrgs, actions.readOrgs)],
  [
    actions.readSchedules,
    readWithPagesFactory(api.readSchedules, actions.readSchedules),
  ],
  [actions.readTeams, readWithPagesFactory(api.readTeams, actions.readTeams)],
]

export default generateSagas([...user])
