import React, { useEffect, useState } from 'react'
import { ROUTES } from '@sportninja/common/constants/app'
import { t } from '@sportninja/common/i18n'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import req from '@sportninja/common/api/request'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'

import FormSheet from '../../components/Form/FormSheet'
import { feedItemForm } from '../../components/Form/form-configs'
import {
  NewPostButton,
  SidebarHeader,
  SidebarInner,
  SidebarWrap,
} from './SidebarComponents'

const getIcon = (entityType) => {
  switch (entityType) {
    case ENTITY_TYPES.org:
      return 'users'
    case ENTITY_TYPES.schedule:
      return 'calendar-alt'
    case ENTITY_TYPES.team:
      return 'user-friends'
    default:
      return 'user'
  }
}

const EntitySidebar = ({ item, entityType, loadFeed }) => {
  const [targetAudience, setTargetAudience] = useState(null)
  useEffect(() => {
    const getTargetAudience = async () => {
      try {
        let type = ''
        if (entityType === 'organizations') {
          type = 'organization'
        } else if (entityType === 'teams') {
          type = 'team'
        } else if (entityType === 'schedules') {
          type = 'competition'
        }
        const { data } = await req(`/social-feed/${item?.id}/${type}`)
        if (data && data?.total_users && data?.total_users > 0) {
          setTargetAudience(data?.total_users)
        }
      } catch (e) {
        console.error(e)
        setTargetAudience(null)
      }
    }
    if (entityType && item) {
      getTargetAudience()
    }
  }, [entityType, item])

  return (
    <SidebarWrap>
      <SidebarInner>
        <SidebarHeader
          isEntity
          iconName={getIcon(entityType)}
          imageId={getImageThumbnailId(item)}
          name={item?.name_full}
          subTitle={item?.organization?.name_full}
          to={
            item?.organization
              ? `${ROUTES.ORG_ROOT}/${item?.organization.id}`
              : ''
          }
        />
        <FormSheet
          label={t('Web:createANewPost')}
          title={t('Web:createANewPost')}
          form={feedItemForm({}, false, targetAudience)}
          onSubmit={async ({ imageData, ...form }) => {
            const body = JSON.stringify(form)
            const response = await req(
              `/${entityType}/${item.id}/social-feed`,
              {
                method: 'POST',
                body,
              }
            )
            if (imageData) {
              await req(`/social-feed/${response.data.id}/image`, {
                method: 'POST',
                body: imageData,
              })
            }
            await loadFeed(1, { reset: true })
          }}
          Button={NewPostButton}
        />
      </SidebarInner>
    </SidebarWrap>
  )
}

export default EntitySidebar
