import { t } from '@sportninja/common/i18n'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'

export const getConfig = () => ({
  multiTeamRoster: {
    linkText: 'Team Roster Import Template',
    fileName: 'sportninja_multi_team_roster_template.csv',
    blurb:
      "Click here to download the Multi-team Team Roster Import Template, which can be used to upload your team's player roster",
    SNHelpLink:
      'https://sportninja.zendesk.com/hc/en-us/articles/7915585480468-Importing-Rosters',
    label: 'Import Multi-team Roster',
    type: 'multiTeamRoster',
  },
  roster: {
    linkText: 'Team Roster Import Template',
    fileName: 'sportninja_roster_template.csv',
    blurb:
      "Click here to download the Team Roster Import Template, which can be used to upload your team's player roster",
    SNHelpLink:
      'https://sportninja.zendesk.com/hc/en-us/articles/7915585480468-Importing-Rosters',
    label: t('common:importRoster'),
    type: 'roster',
  },
  schedule: {
    linkText: `${t('common:competition')} Import Template`,
    fileName: 'sportninja_schedule_template.csv',
    blurb: `Click here to download the ${t('util:capitalize', {
      text: t('common:competition'),
    })} Import Template, which can be used to upload your ${t(
      'common:competition'
    )} games`,
    SNHelpLink:
      'https://sportninja.zendesk.com/hc/en-us/articles/7915920278932-Importing-Competitions',
    label: t('common:importComp'),
    title: t('common:importCompetition'),
    type: 'schedule',
  },
  team: {
    linkText: 'Team Import Template',
    fileName: 'sportninja_team_template.csv',
    blurb:
      'Click here to download the Team Import Template, which can be used to upload your teams',
    SNHelpLink:
      'https://sportninja.zendesk.com/hc/en-us/articles/7896261627540-How-to-Import-Teams',
    label: t('common:importTeams'),
    type: 'team',
  },
  gameSlots: {
    linkText: 'Game Slots Import Template',
    fileName: 'sportninja_gameslots_template.csv',
    blurb:
      'Click here to download the Game Slots Import Template, which can be used to upload your game slots',
    SNHelpLink: 'https://sportninja.zendesk.com/hc/en-us/',
    label: 'Import Game Slots (Beta)',
    type: 'gameSlots',
  },
  officials: {
    linkText: 'Official Import Template',
    fileName: 'sportninja_official_template.csv',
    blurb:
      'Click here to download the Official Import Template, which can be used to upload your officials',
    SNHelpLink:
      'https://sportninja.zendesk.com/hc/en-us/articles/26274516528532-Importing-Officials',
    label: 'Import Officials',
    type: 'officials',
  },
})

const getImportConfig = (teamRosterId, importType, entityType) => {
  const config = getConfig()
  if (teamRosterId) {
    return config.roster
  }
  if (importType === 'teams') {
    return config.team
  }
  if (importType === ENTITY_TYPES.officials) {
    return config.officials
  }
  if (entityType === ENTITY_TYPES.schedule) {
    return config.schedule
  }
  if (entityType === ENTITY_TYPES.gameSlots) {
    return config.gameSlots
  }
  return null
}

export default getImportConfig
