import merge from 'lodash.merge'

import { str } from '../utils'
import parsers from '../parsers'

const usersReducer = (actions) => (draft, { type, payload }) => {
  switch (type) { // eslint-disable-line default-case
    case str(actions.updatePrimary.success): {
      const id = payload.id
      const users = draft[id].users
      const oldPrimary = users.find((p) => p.is_primary)
      const newPrimary = users.find((p) => p.id === payload.primaryId)

      oldPrimary && (oldPrimary.is_primary = false)
      newPrimary && (newPrimary.is_primary = true)
      return
    }

    case str(actions.create.success):
      draft[payload.id] = draft[payload.id] || {}
      draft[payload.id].users = draft[payload.id].users || []
      draft[payload.id].users.push(parsers.entityUsers(payload.data))
      return

    case str(actions.read.success):
      draft[payload.id] = draft[payload.id] || {}
      draft[payload.id].users = parsers.entityUsers(payload.data)
      return

    case str(actions.update.success): {
      const { data, id, userId } = payload

      draft[id] = draft[id] || {}
      draft[id].users = draft[id].users || []
      let user = draft[id].users.find((p) => p.id === userId)

      if (user) user = merge(user, parsers.entityUsers(data))
      else draft[id].users.push(parsers.entityUsers(data))

      return
    }

    case str(actions.delete.success): {
      const users = draft[payload.id].users

      if (Array.isArray(users)) {
        const userIndex = users.findIndex((p) => p.id === payload.userId)
        if (userIndex > -1) users.splice(userIndex, 1)
      }

      return
    }
  }
}

export default usersReducer
