/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { CopyOutlined } from '@ant-design/icons'
import { PlayerConflict } from '@sportninja/common/types'
import {
  heading6,
  paragraphMedium,
  paragraphXSmall,
  weightRegular,
  weightSemiBold,
} from 'src/components/css'
import { ROUTES } from '@sportninja/common/constants/app'

type PlayerConflictProps = {
  key: string
  player: PlayerConflict
  selectedPlayerConflicts: any
  handlePlayerRadioChange: (daysmartId: number, value: string) => void
  setAllPlayerConflictsToCreate: () => void
  setAllPlayerConflictsToIgnore: () => void
}

const PlayerConflictItem = ({
  key,
  player,
  selectedPlayerConflicts,
  handlePlayerRadioChange,
  setAllPlayerConflictsToCreate,
  setAllPlayerConflictsToIgnore,
}: PlayerConflictProps) => {
  return (
    <div
      key={key}
      css={css`
        display: flex;
        gap: 16px;
        padding: 8px;
        border-radius: 8px;
        background-color: ${colors.SECONDARY_400};
        margin-top: 16px;
        flex: 3;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          border-radius: 4px;
          border: 1px solid ${colors.SECONDARY_300};
          padding: 8px;
          align-self: flex-start;
          flex: 1;
          gap: 8px;
        `}
      >
        <span
          css={css`
            color: ${colors.WHITE};
            ${heading6}
            ${weightSemiBold}
            line-height: 100%;
          `}
        >
          {player?.name_full}
        </span>
        <span
          css={css`
            color: ${colors.ATTENDANCE_GRAY};
            ${paragraphXSmall}
            ${weightRegular}
            line-height: 100%;
          `}
        >
          Daysmart ID: {player?.daysmart_id}
        </span>
        {player?.email && (
          <span
            css={css`
              color: ${colors.ATTENDANCE_GRAY};
              ${paragraphXSmall}
              ${weightRegular}
              line-height: 100%;
            `}
          >
            Email: {player?.email}
          </span>
        )}
        {player?.birth_date && (
          <span
            css={css`
              color: ${colors.ATTENDANCE_GRAY};
              ${paragraphXSmall}
              ${weightRegular}
              line-height: 100%;
            `}
          >
            Birth Date: {player?.birth_date}
          </span>
        )}
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 2;
        `}
      >
        <div
          onChange={(e) =>
            handlePlayerRadioChange(
              player.daysmart_id,
              (e.target as HTMLInputElement).value
            )
          }
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            cursor: pointer;
          `}
        >
          {player.player_conflicts.map((conflict, index) => (
            <label
              key={`${conflict.uid}_${index}`}
              css={css`
                display: flex;
                align-items: center;
                gap: 8px;
                align-self: stretch;
              `}
            >
              <input
                type='radio'
                name={`conflict_${player.daysmart_id}`}
                value={conflict.uid}
                checked={
                  selectedPlayerConflicts[player.daysmart_id] === conflict.uid
                }
                onChange={(e) => {
                  handlePlayerRadioChange(player.daysmart_id, e.target.value)
                }}
                css={css`
                  cursor: pointer;
                  margin-right: 8px;
                  width: 16px;
                  height: 16px;
                  border-radius: 50%;
                  border: 1px solid ${colors.WHITE};
                  /* change the style when checked */
                  &:checked {
                    background-color: ${colors.PRIMARY_300};
                    border: 1px solid ${colors.PRIMARY_300};
                  }
                `}
              />
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 4px;
                `}
              >
                <span
                  css={css`
                    color: ${colors.WHITE};
                    ${paragraphMedium}
                    ${weightSemiBold}
                    line-height: 100%;
                    margin-bottom: ${conflict.team_details ? '2px' : '0'};
                  `}
                >
                  {conflict.name_full}{' '}
                  {conflict?.uid ? (
                    <a
                      css={css`
                        color: ${colors.ATTENDANCE_GRAY};
                        ${paragraphXSmall}
                        ${weightRegular}
                        line-height: 120%; /* 12px */
                        max-width: 400px;
                        margin-bottom: ${conflict.team_details ? '2px' : '0'};
                      `}
                      href={`${ROUTES.PLAYER_ROOT}/${conflict.uid}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      ({conflict.uid})
                    </a>
                  ) : null}
                </span>
                <span
                  css={css`
                    color: ${colors.ATTENDANCE_GRAY};
                    ${paragraphXSmall}
                    ${weightRegular}
                    line-height: 100%; /* 12px */
                    max-width: 400px;
                  `}
                >
                  {conflict.team_details}
                </span>
              </div>
            </label>
          ))}
          <label
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <input
              type='radio'
              name={`conflict_${player.daysmart_id}`}
              value={'998'}
              checked={selectedPlayerConflicts[player.daysmart_id] === '998'}
              onChange={(e) =>
                handlePlayerRadioChange(player.daysmart_id, e.target.value)
              }
              css={css`
                cursor: pointer;
                margin-right: 8px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid ${colors.WHITE};
                /* change the style when checked */
                &:checked {
                  background-color: ${colors.PRIMARY_300};
                  border: 1px solid ${colors.PRIMARY_300};
                }
              `}
            />
            <div
              css={css`
                display: flex;
                flex: 1 0 0;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              `}
            >
              <span
                css={css`
                  ${paragraphMedium}
                  ${weightRegular}
                  color: ${colors.WHITE};
                  line-height: 100%; /* 16px */
                  flex: 1 0 0;
                `}
              >
                Create a New Player
              </span>
              <div
                onClick={() => {
                  setAllPlayerConflictsToCreate()
                }}
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  cursor: pointer;
                  display: ${selectedPlayerConflicts[player.daysmart_id] ===
                  '998'
                    ? 'flex'
                    : 'none'};
                `}
              >
                <CopyOutlined
                  css={css`
                    color: ${colors.PRIMARY_300};
                    font-size: 12px;
                  `}
                />
                <span
                  css={css`
                    ${paragraphXSmall}
                    ${weightRegular}
                    color: ${colors.PRIMARY_300};
                  `}
                >
                  Apply to all
                </span>
              </div>
            </div>
          </label>
          <label
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              align-self: stretch;
            `}
          >
            <input
              type='radio'
              name={`conflict_${player.daysmart_id}`}
              value={'999'}
              checked={selectedPlayerConflicts[player.daysmart_id] === '999'}
              onChange={(e) => {
                handlePlayerRadioChange(player.daysmart_id, e.target.value)
              }}
              css={css`
                margin-right: 8px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid ${colors.WHITE};
                /* change the style when checked */
                &:checked {
                  background-color: ${colors.PRIMARY_300};
                  border: 1px solid ${colors.PRIMARY_300};
                }
                cursor: pointer;
              `}
            />
            <div
              css={css`
                display: flex;
                flex: 1 0 0;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              `}
            >
              <span
                css={css`
                  color: var(--Caution-Light, #fff);
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 100%; /* 16px */
                  flex: 1 0 0;
                `}
              >
                Ignore (do not import)
              </span>
              <div
                onClick={() => {
                  setAllPlayerConflictsToIgnore()
                }}
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  cursor: pointer;
                  display: ${selectedPlayerConflicts[player.daysmart_id] ===
                  '999'
                    ? 'flex'
                    : 'none'};
                `}
              >
                <CopyOutlined
                  css={css`
                    color: ${colors.PRIMARY_300};
                    font-size: 12px;
                  `}
                />
                <span
                  css={css`
                    ${paragraphXSmall}
                    ${weightRegular}
                    color: ${colors.PRIMARY_300};
                  `}
                >
                  Apply to all
                </span>
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
  )
}

export default PlayerConflictItem
