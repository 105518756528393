/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import req from '@sportninja/common/api/request'
import { ROUTES } from '@sportninja/common/constants/app'
import { t } from '@sportninja/common/i18n'
import { getImageThumbnailId } from '@sportninja/common/reducers/helpers'
import { ENTITY_TYPES } from '@sportninja/common/sagas/utils'
import { useEffect } from 'react'

import OptionButtonIcon from 'src/components/List/OptionButtons/Icon'
import { Flex } from '../../components/Layout'
import List from '../../components/List'
import OptionButtonDelete from '../../components/List/OptionButtons/Delete'
import OptionButtonFavourite from '../../components/List/OptionButtons/Favourite'
import MoreOptions, {
  MoreOptionListItem,
} from '../../components/List/OptionButtons/More'
import useGetListItems from '../../components/List/useGetListItems'
import Picture from '../../components/Picture'
import { SubscriptionType } from '@sportninja/common/constants/SubscriptionType'
import { permission } from 'process'

const OrgCell = ({ item }) => (
  <Flex alignItems='center'>
    <Picture
      size='kxsmall'
      square
      fit
      imageId={getImageThumbnailId(item)}
      iconName='users'
      css={css`
        margin-right: 8px;
      `}
    />
    {item.name_full}
  </Flex>
)

const OrgList = ({
  refreshKey,
  setCount = () => {},
  slug,
  setInitialState,
  onEditOrg,
  onClickWebPluginOnScheduleList = () => {},
}) => {
  const listProps = useGetListItems(slug, {
    onComplete: (response) => setCount(response.meta.pagination.count),
  })

  const {
    sort,
    direction,
    getItems,
    permissions,
    debouncedSearchTerm,
    features,
  } = listProps

  useEffect(() => {
    getItems()
  }, [refreshKey, sort, direction, debouncedSearchTerm])

  const headers = [
    {
      text: t('common:name'),
      sizePercent: 37,
      sort: 'name_full',
      Cell: OrgCell,
      to: (item) => `${ROUTES.ORG_ROOT}/${item.id}`,
    },
    {
      text: t('Web:subOrg', { count: 2 }),
      sizePercent: 12,
      minWidth: 90,
      sort: 'children_count',
      accessor: 'children_count',
    },
    {
      text: t('common:competition', { count: 2 }),
      sizePercent: 14,
      minWidth: 120,
      sort: 'schedules_count',
      accessor: 'schedules_count',
    },
    {
      text: t('common:team', { count: 2 }),
      sizePercent: 10,
      sort: 'teams_count',
      accessor: 'teams_count',
    },
  ]

  const renderGeneratePluginTag = ({ onClick }) => {
    return (
      <MoreOptionListItem
        onClick={() => {
          onClick()
        }}
        iconName='plug'
        faType='far'
        title={'Generate JS Plugin Tag'}
      />
    )
  }

  const options = (item) => {
    const permission = permissions[item.id]
    // const isProfessionalOrUltimateAccount =
    //   item?.account_subscription_type_id === SubscriptionType.PROFESSIONAL ||
    //   item?.account_subscription_type_id === SubscriptionType.ULTIMATE
    const isProfessionalOrUltimateAccount = true
    const moreOptions = []
    if (permission.update) {
      moreOptions.push({
        key: 'plugin',
        Component: renderGeneratePluginTag,
        componentProps: {
          Button: renderGeneratePluginTag,
          schedule: item,
          scheduleId: item.id,
          onClick: () => {
            onClickWebPluginOnScheduleList(item)
          },
        },
      })
    }
    if (permission.delete && item.parent_id !== null) {
      moreOptions.push({
        key: 'delete',
        Component: OptionButtonDelete,
        componentProps: {
          useForceDelete: true,
          unmountOnClose: true,
          entityType: ENTITY_TYPES.org,
          itemId: item.id,
          title: `Delete ${item.name_full}`,
          type: t('common:organization'),
          iconName: 'trash',
          // onComplete: getItems,
          // We need to do a complete refresh of the screen so we have
          // the empty state correctly
          onComplete: () => {
            window.location.reload()
          },
        },
      })
    }
    const opts = [
      permission.update && (
        <OptionButtonIcon
          name='pencil'
          onClick={() => {
            setInitialState(item)
            onEditOrg()
          }}
        />
      ),
      <OptionButtonFavourite
        key='fav'
        entityType={ENTITY_TYPES.org}
        item={item}
        isFavourite={item.is_favorite}
      />,
      // We should only allow to delete if this is not a root org
      // (a root org is an org with parent_id === null)
      <MoreOptions key='more' options={moreOptions} />,
    ]
    return opts.filter((o) => o !== undefined)
  }

  return (
    <List
      headers={headers.filter((o) => o !== undefined)}
      options={options}
      listProps={listProps}
      noItemsText={t('common:organization', { count: 2 })}
      showSearch
    />
  )
}

export default OrgList
