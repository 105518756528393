/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react'
import css from '@emotion/css/macro'

import { Flex } from '../Layout'
import { FormButton } from '../Form/css'
import { Mobile } from '../Responsive'

const AuthSecondaryLink = ({ buttonText, onClick, text }) => {
  return (
    <Mobile>
      {(isMobile) => (
        <Flex
          noFlex
          alignItems={isMobile? 'flex-start' : 'center'}
          justifyContent='space-between'
          css={css`margin-top: ${isMobile ? '40' : '100'}px;`}
          column={isMobile}
        >
          <div css={css`
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0.6px;
            width: auto;
            margin-right: auto;
          `}>
            {text}
          </div>
          <FormButton onClick={onClick} css={css`
            height: 42px;
            flex-grow: 1;
            width: ${!isMobile && 'unset'};
            margin-left: ${!isMobile && '28px'};
            margin-top: ${isMobile && '16px'};
          `}>
            {buttonText}
          </FormButton> 
        </Flex>
      )}
    </Mobile>
  )
}

export default AuthSecondaryLink
