/** @jsxImportSource @emotion/react */
import css from '@emotion/css/macro'
import colors from '@sportninja/common/constants/appColors'
import { useState } from 'react'

import { font } from '../../../../components/css'
import { FormButton } from '../../../../components/Form/css'
import { Flex } from '../../../../components/Layout'

const ConfirmChange = ({ onCancel, onConfirm, status = {} }) => {
  if (!status) return false

  const { hasGoalOrAssist, hasOffense, hasGoalieChange, settings } = status
  const proposedStatus = settings?.is_injured ? 'Injured' : 'Not Playing'

  const [isLoading, setIsLoading] = useState(false)

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        position: fixed;
        top: -36px;
        bottom: 0;
        left: 0;
        right: 0;
      `}
    >
      <div
        css={css`
          background-color: ${colors.HEADER};
          margin-right: 1px;
          padding: 30px;
          text-align: center;
          filter: drop-shadow(0px 0px 4px black);
          border-radius: 3px;
        `}
      >
        <h2
          css={css`
            ${font.title}
            font-weight: bold;
            font-size: 24px;
            margin-bottom: 20px;
          `}
        >
          Please Confirm Change
        </h2>
        <div
          css={css`
            margin-bottom: 20px;
            background-color: ${colors.SOFT_STEEL};
            padding: 8px;
            border-radius: 4px;
          `}
        >
          Proposed Change: Playing {'->'} {proposedStatus}
        </div>
        <div>This player has statistics associated with this game:</div>
        <div
          css={css`
            margin-top: 2px;
            font-weight: bold;
          `}
        >
          {[
            hasGoalOrAssist && 'Goals/Assists',
            hasOffense && 'Penalties',
            hasGoalieChange && 'Goalie Changes',
          ]
            .filter((item) => typeof item === 'string')
            .join(', ')}
        </div>
        <div
          css={css`
            margin-top: 12px;
            margin-bottom: 36px;
          `}
        >
          Are you sure you want to perform this change?
        </div>
        <Flex>
          <FormButton onClick={onCancel}>Cancel</FormButton>
          <FormButton
            busy={isLoading}
            disabled={isLoading}
            isSubmit
            onClick={async () => {
              setIsLoading(true)
              try {
                await onConfirm()
              } finally {
                setIsLoading(false)
              }
            }}
          >
            Confirm
          </FormButton>
        </Flex>
      </div>
    </div>
  )
}

export default ConfirmChange
